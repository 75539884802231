import styled from 'styled-components';

interface BarsContainerProps {
  width: string;
}

const BarsContainer = styled.div`
  width: 100%;
  height: 37px;
`;

const ColoredBar = styled.div`
  height: 100%;
  width: calc(100% - 6px);
  background-color: ${(props): string => props.color || 'black'};
  border-radius: 4px;
  display: inline-block;
`;

const ColoredBarContainer = styled.div`
  text-align: center;
  height: 37px;
  width: ${(props: BarsContainerProps): string => props.width || '10'}%;
  &:last-child {
    text-align: end;
    div {
      width: calc(100% - 3px);
    }
  }
  &:first-child {
    text-align: start;
    div {
      width: calc(100% - 3px);
    }
  }
`;
export { BarsContainer, ColoredBar, ColoredBarContainer };
