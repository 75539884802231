import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { compose, map, reduce } from 'lodash/fp';
import TelemedVideoCall from 'telemed-video-call/components/TelemedVideoCall';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const predefineQueryString = [
  'appointmentId',
  'teleMedKey',
  'accessToken',
  'doctorName',
];

interface IQueryParamsList {
  appointmentId: string;
  teleMedKey: string;
  accessToken: string;
  doctorName: string;
}
const TelemedicineVideoCall = () => {
  const queryStringSearch = useQuery();

  const [dataQuery, setDataQuery] = useState<IQueryParamsList>();

  const setDataInitial = compose(
    setDataQuery,
    reduce((acc: any, cur: { name: string; value: string }) => {
      acc[cur.name as string] = cur.value;
      return acc;
    }, {}),
    map((item: string) => ({ value: queryStringSearch.get(item), name: item }))
  );

  useEffect(() => {
    setDataInitial(predefineQueryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {' '}
      {dataQuery !== undefined ? (
        <TelemedVideoCall
          appointmentId={dataQuery.appointmentId}
          teleMedKey={dataQuery.teleMedKey}
          accessToken={dataQuery.accessToken}
          doctorName={dataQuery.doctorName}
        ></TelemedVideoCall>
      ) : null}
    </div>
  );
};

export default TelemedicineVideoCall;
