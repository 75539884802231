import React from 'react';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TaskData } from 'global/requests/ResponseTypes/_Task';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';

interface TaskButtonProps {
  appointmentInformation: AppointmentSummaryEntry,
  taskID: string,
  handleSubmitClick: () => void,
}


const TaskButton = (props: TaskButtonProps) => {

  // const [userClickedComplete, setUserClickedComplete] = React.useState(false);

  // const renderConfirmation = () => {
  //   return ([
  //     (<FontAwesomeIcon
  //       key={"appointment__task-complete-confirm-" + props.taskID}
  //       className="status_select-update confirm"
  //       icon={["far", "check"]}
  //       onClick={() => {completeTask()}}
  //     />),
  //     (<FontAwesomeIcon
  //       key={"appointment__task-complete-cancel-" + props.taskID}
  //       className="status_select-update cancel"
  //       icon={["far", "times"]}
  //       onClick={() => {
  //         setUserClickedComplete(false);
  //       }}
  //     />)
  //   ])
  // };

  const sendUpdateAppointmentTaskRequest = async (tasks: TaskData[]) => {
    try {
      const res = await configuredRequests.PATCH.updateAppointmentCompletedTask(props.appointmentInformation.appointmentId, tasks);
      //TODO confirm success or retry if failure
      if (res) {
        // console.log('response to update appointment req', res)
      }
    } catch (err) {
      console.log('error updating task')
    }
  }

  const completeTask = () => {
    props.handleSubmitClick();
    const tasks: TaskData[] = []
    props.appointmentInformation.tasks?.map((item: TaskData) => {
      if (item.taskId === props.taskID) {
        item.status = 'done';
        tasks.push(item);
      }
      return item;
    });
    // console.log('tasks', tasks);
    sendUpdateAppointmentTaskRequest(tasks);
  }

  const renderCompleteTaskButton = () => {
    return (
      <button
        key={"appointment__task-complete-button" + props.taskID}
        className="appointment__task-complete-button"
        onClick={completeTask}>
        Complete
      </button>
    )
  }

  return (
    <div className="appointment__task-complete-container">
      {/* {userClickedComplete
        ? renderConfirmation()
        : renderCompleteTaskButton()
      } */}
      {renderCompleteTaskButton()}
    </div>
  )
};

export default TaskButton;
