import React, { useMemo, useState } from 'react';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import {
  Column,
  OptionFilterDropdownObject,
} from 'global/requests/ResponseTypes';
import { useRouter } from 'hooks/useRouter';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import Modal from 'components/Global/Modal';
import toast from 'components/Global/Toast';
interface TemplateCommonProps {
  optionsForFilter?: OptionFilterDropdownObject[];
  categoryKind: string;
  dataTable: any;
  isLoading: boolean;
  templateKinds: string;
  reloadData: () => void;
}

const columns: Column[] = [
  { id: "name", label: "Name" },
  { id: 'delete', label: ""}
];

const TemplateCommon = (props: TemplateCommonProps): JSX.Element => {
  const router = useRouter();
  const [openModalDel, setOpenModalDel] = useState<boolean>(false);
  const [errorsDelete, setErrorsDelete] = useState<string[]>([]);
  const [templateDelete, setTemplateDelete] = useState<string>('');

  const routerChange = () => {
    switch (props.templateKinds) {
      case 'email':
        return `${router.match.path}/Email`;
      case 'sms':
        return `${router.match.path}/SMS`;
      case 'call':
        return `${router.match.path}/CALL`;
      default:
        return `${router.match.path}/Postal`;
    }
  };

  const screenChange = () => {
    switch (props.templateKinds) {
      case 'email':
        return 'EMAIL';
      case 'sms':
        return 'SMS';
      case 'call':
        return 'CALL';
      default:
        return 'POSTAL';
    }
  };

  const routerCreationChange = () => {
    switch (props.templateKinds) {
      case 'email':
        return `${router.match.path}/CreateEmail`;
      case 'sms':
        return `${router.match.path}/CreateSMS`;
      case 'call':
        return `${router.match.path}/CreateCall`;
      default:
        return `${router.match.path}/CreatePostal`;
    }
  };

  const handleDeleteTemplate = (id: string) => {
    setTemplateDelete(id);
    setOpenModalDel(true);
  };

  const handleDelete = async () => {
    try {
      const res = await configuredRequests.DEL.deleteTemplate(
        templateDelete ?? ''
      );
      if (res) {
        props.reloadData();
        setOpenModalDel(false);
        toast.success('Deleted successfully');
      }
    } catch (err) {
      setOpenModalDel(false);
      if (err.response?.data?.message === 'This template cannot be deleted because it is being used for automation event.') {
        const listAutomation = Object.values(err.response?.data?.details);
        // @ts-ignore
        setErrorsDelete(listAutomation);
      } else {
        toast.error('Failed to delete.');
      }
    }
  };

  const modalBody = useMemo(() => {
    if (errorsDelete.length === 0) {
      return "";
    } else if (errorsDelete.length === 1) {
      return `This template cannot be deleted because it is being used for the "${errorsDelete[0].trim()}" automation event`;
    } else {
      return (<>
        <span>This template cannot be deleted because it is being used for these automation events:</span>
        <ul className="errors__automation-event">
          {errorsDelete.map(event => <li>{event}</li>)}
        </ul>
      </>)
    }
  }, [errorsDelete]);

  return (
    <>
      <div className='communication-container email list-template'>
        <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
          <CommunicationTable
          columns={columns}
          dataTable={props.dataTable}
          isSort={false}
          isDisplayedSearchAndFilter={true}
          isDisplayedSearch={true}
          isDisplayedFilter={false}
          isDisplayedButton={true}
          keySearch="name"
          placeholderSearch="Search Templates"
          screen={screenChange()}
          router={routerChange()}
          isLoading={props.isLoading}
          category={props.categoryKind}
          routerCreation={routerCreationChange()}
          openModal={handleDeleteTemplate}
        />
        </div>
      </div>

      {openModalDel && (
        <Modal
          isOpenModal={openModalDel}
          handleCloseModal={() => setOpenModalDel(false)}
          title="Delete Template"
          children='Are you sure that you want to delete this template?'
          handleConfirmModal={handleDelete}
        />
      )}

      {!!errorsDelete.length && (
        <Modal
          isOpenModal={!!errorsDelete.length}
          handleCloseModal={() => setErrorsDelete([])}
          title="Cannot Delete Template"
          children={modalBody}
          classMore="custom-scroll error__delete-template"
          handleConfirmModal={() => setErrorsDelete([])}
          isFullAction={false}
        />
      )}
    </>
  );
};

export default TemplateCommon;
