import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Label, Textarea, Main } from './styled-components';

export interface InputTextareaProps {
  id: string;
  label?: string | React.ReactElement;
  initialValue?: string;
  onChange?: (arg0: string) => void;
  rowHeight?: string | number;
  fullWidth?: boolean;
  resize?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
}

const InputTextarea = (props: InputTextareaProps): React.ReactElement => {
  const {
    id,
    label,
    initialValue = '',
    onChange,
    rowHeight = 1,
    fullWidth = true,
    resize = 'none',
    placeholder = '',
    error = false,
    disabled = false,
  } = props;

  const [value, setValue] = useState(initialValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Main>
      {label && typeof label === 'string' && (
        <Label htmlFor={id} data-testid='label'>
          <Typography variant='body2'>{label}</Typography>
        </Label>
      )}
      {label && typeof label !== 'string' && (
        <Label htmlFor={id} data-testid='label'>
          {label}
        </Label>
      )}
      <Textarea
        id={id}
        value={value}
        onChange={handleChange}
        rowsMax={rowHeight}
        rows={rowHeight}
        fullWidth={fullWidth}
        multiline
        style={{ padding: 0 }}
        resize={resize}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
      />
    </Main>
  );
};

export default InputTextarea;
