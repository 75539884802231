const styles = {
  main: {
    color: 'white',
    fontWeight: 'normal',
    textAlign: 'right',
  },
  p: {
    margin: '0',
    lineHeight: '16px',
    fontSize: '12px',
    opacity:'0.8',
  },
};

export default styles;