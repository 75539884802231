/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import {
  SmallTitleText,
  MediumEmphasizedDarkText,
  SecondaryButton,
  InputTextarea,
  AlertSnackBar,
} from 'components/Legwork';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  width: 70%;
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 13px;
  margin: 5px 0 0;
`;

const FormItemShare = props => {
  const [copyToClipboardState, setCopyToClipboardState] = useState('');
  const [errorLocation, setErrorLocation] = useState(0);
  const { embedCode, sharedLink, neverPublish } = props;

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      if (copyToClipboardState !== '') {
        setCopyToClipboardState();
      }
    }, 1000);
  }, [copyToClipboardState]);

  const handleCopyToClipboard = value => {
    try {
      if (neverPublish) {
        navigator.clipboard.writeText("");
        setCopyToClipboardState('error');
      } else {
        navigator.clipboard.writeText(value);
        setCopyToClipboardState('success');
      }
    } catch (err) {
      setCopyToClipboardState('error');
    }
  };

  return (
    <>
      {copyToClipboardState === 'success' && (
        <AlertSnackBar severity='success'>
          <span>Copied!</span>
        </AlertSnackBar>
      )}
      {copyToClipboardState === 'error' && (
        <AlertSnackBar severity='error'>
          <span>Failed</span>
        </AlertSnackBar>
      )}
      <div className='form-item-tab-container with-margin'>
        <div className='row'>
          <div className='control-group'>
            <label htmlFor='shared_link' className='control-label'>
              <MediumEmphasizedDarkText>
                Share Link
              </MediumEmphasizedDarkText>
            </label>
            <InputContainer>
              <InputTextarea
                id='shared-link-textarea'
                initialValue={sharedLink || ''}
                readOnly
                disabled = {sharedLink}
              />
            </InputContainer>
            {neverPublish && errorLocation === 1 && <ErrorMsg>This form has not been publish yet.</ErrorMsg>}
            <SecondaryButton
              icon={<FileCopyRoundedIcon />}
              title="Copy"
              style={{
                marginTop: '15px',
                backgroundColor: 'white',
              }}
              disabled={!sharedLink}
              onClick={() => {
                setErrorLocation(1);
                handleCopyToClipboard(sharedLink);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='control-group'>
            <label htmlFor='embed_code' className='control-label'>
              <MediumEmphasizedDarkText>
                Form Embed Code
              </MediumEmphasizedDarkText>
            </label>
            <InputContainer>
            <SmallTitleText>
              Have your web developer embed this code on your website in order to receive online form submissions.
            </SmallTitleText>
              <InputTextarea
                id='embed-code-textarea'
                initialValue={embedCode || ''}
                resize='vertical'
                disabled = {embedCode}
                readOnly
              />
            </InputContainer>
            {neverPublish && errorLocation === 2 && <ErrorMsg>This form has not been publish yet.</ErrorMsg>}
            <SecondaryButton
              icon={<FileCopyRoundedIcon />}
              title="Copy"
              style={{
                marginTop: '15px',
                backgroundColor: 'white',
              }}
              disabled={!embedCode}
              onClick={() => {
                setErrorLocation(2);
                handleCopyToClipboard(embedCode);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

FormItemShare.propTypes = {
  embedCode: PropTypes.string,
  sharedLink: PropTypes.string,
};

FormItemShare.defaultProps = {
  embedCode: '',
  sharedLink: '',
};

export default FormItemShare;
