import * as t from 'io-ts';
import { AppointmentTypeSetting } from './AppointmentTypeSetting';

const DeliveryHoursType = t.partial({
  patientStatus: t.string,
  practiceStatus: t.string,
});

const DriverType = t.partial({
  id: t.string,
  name: t.string,
});

const GeneralType = t.partial({
  interval: t.string,
  skipAppointmentCodes: t.string,
  startTimePractice: t.string,
  endTimePractice: t.string,
  patientConfirmStatus: t.string,
  practiceConfirmStatus: t.string,
  driver: DriverType,
  allowSchedulingAppointment: t.boolean,
  restartTimePractice: t.string,
});

export type GeneralType = t.TypeOf<typeof GeneralType>;

const SettingSyncerType = t.partial({
  general: GeneralType,
  appointmentStatuses: t.array(DeliveryHoursType),
});

export const SyncerSettingGeneralResponseType = t.partial({
  namespace: t.string,
  settings: SettingSyncerType,
});
export type SyncerSettingGeneralResponseType = t.TypeOf<typeof SyncerSettingGeneralResponseType>;

