import styled from 'styled-components';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const InputDiv = styled.div`
  margin-bottom: 0.5rem;

  .error-text {
    margin-bottom: 10px;
  }
`;

const ErrorText = styled.div<{ className?: string }>`
  color: ${getThemeProp('palette.error.dark', 'red')};
  margin-top: 10px;
  &:empty {
    display: none;
  }
`;

ErrorText.defaultProps = {
  className: 'error-text',
};

export { InputDiv, ErrorText };
