import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { getFullUserName, getSettingByNamespace } from 'utils/convertData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import toast from 'components/Global/Toast';
import {
  UserDataResponse,
  UserSettingObjectType,
} from 'global/requests/ResponseTypes/UserAdministration';

import './SubscriptionSetting.scss';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

type SubscriptionState = typeof initial;
type Property = keyof typeof initial;

type Props = {
  userId: string;
  settings: UserSettingObjectType[] | undefined;
  fullName: string;
  onUpdate: (payload: UserSettingObjectType[]) => void;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const initial = {
  phone: true,
  postal: true,
  email: true,
  sms: true,
  appointment: true,
  birthday: true,
  reminders: true,
};

const stateReceives = [
  {
    label: 'Phone',
    name: 'phone',
  },
  {
    label: 'Text Message',
    name: 'sms',
  },
  {
    label: 'E-mail',
    name: 'email',
  },
  {
    label: 'Postal',
    name: 'postal',
  },
];
const stateSends = [
  {
    label: 'Appointment',
    name: 'appointment',
  },
  {
    label: 'Pets Birthday',
    name: 'birthday',
  },
  {
    label: 'Medical Reminders',
    name: 'reminders',
  },
];

const getCommunicationTypeStatus = (settings: UserSettingObjectType[]) => {
  const setting = settings.find(
    (s) => s.namespace === 'UserSubscriptionSetting'
  );

  return {
    phone: !!setting?.settings?.phone,
    postal: !!setting?.settings?.postal,
    sms: !!setting?.settings?.sms,
    email: !!setting?.settings?.email,
    appointment: !!setting?.settings?.appointment,
    reminders: !!setting?.settings?.reminders,
    birthday: !!setting?.settings?.birthday,
  };
};

const SubscriptionSetting = ({
  settings = [],
  fullName,
  userId,
  onClose,
  onUpdate,
}: Props) => {
  // const fullName = getFullUserName(userInfo);
  const [stateSubscription, setStateSubscription] = useState<SubscriptionState>(
    getCommunicationTypeStatus(settings)
  );

  const checkAll =
    !stateSubscription.email &&
    !stateSubscription.phone &&
    !stateSubscription.postal &&
    !stateSubscription.sms &&
    !stateSubscription.appointment &&
    !stateSubscription.birthday &&
    !stateSubscription.reminders;

  useEffect(() => {
    setStateSubscription(getCommunicationTypeStatus(settings));
  }, [settings]);

  const handleChange = (e: string) => {
    if (e === 'all') {
      setStateSubscription((prev) => ({
        phone: checkAll,
        postal: checkAll,
        email: checkAll,
        sms: checkAll,
        appointment: checkAll,
        birthday: checkAll,
        reminders: checkAll,
      }));
    } else {
      setStateSubscription((prevState) => ({
        ...prevState,
        // @ts-ignore
        [e]: !prevState[e],
      }));
    }
  };

  const changeStateSubscription = async () => {
    try {
      const response = await configuredRequests.POST.updateSubscriptionContact(
        userId,
        {
          namespace: 'UserSubscriptionSetting',
          settings: stateSubscription,
        }
      );
      if (response) {
        onClose(false);
        onUpdate(response.userSettings || []);
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const renderCheckbox = (item: { label: string; name: string }) => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={item.name}
              color='primary'
              checked={stateSubscription[item.name as Property]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(item.name)
              }
            />
          }
          className='checkbox--small'
          label={item.label}
        />
      </div>
    );
  };

  return (
    <>
      <div className='subscription__form'>
        <h3>
          {`Manage ${fullName}'s Subscriptions`}
          <span className='btn-close'>
            <FontAwesomeIcon
              icon={['far', 'times']}
              onClick={() => onClose(false)}
            />
          </span>
        </h3>
        <div className='subscription__warning'>
          <b>
            <FontAwesomeIcon icon={faExclamationTriangle} /> Warning:
          </b>{' '}
          Unsubscribing from any form of communication includes
          appointment-related messaging.
        </div>
        <h4 className='subscription__title-unsubscribe'>Unsubscribe</h4>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name='all'
                color='primary'
                checked={checkAll}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange('all')
                }
              />
            }
            className='checkbox--small'
            label={`Unsubscribe ${fullName} from all`}
          />
        </div>
        <h4 className='subscription__title-information'>
          {`How do you want ${fullName} to receive information`}
        </h4>

        {stateReceives.map((item) => renderCheckbox(item))}

        <h4 className='subscription__title-information'>
          {`Send ${fullName} information on the following`}
        </h4>

        {stateSends.map((item) => renderCheckbox(item))}

        <Button variant='contained' onClick={changeStateSubscription}>
          Save
        </Button>
      </div>
    </>
  );
};

export default SubscriptionSetting;
