const navbarSessionKey = "navbar--is_open";

export const setSessionStateNavbarIsOpen = (newState: boolean) => {
  const stateString = (newState ? "true" : "false");
  // console.log('set nav state', stateString)
  sessionStorage.setItem(navbarSessionKey, stateString);
}

export const getSessionStateNavbarIsOpen = () => {
  // console.log('got nav state', sessionStorage.getItem(navbarSessionKey))
  return sessionStorage.getItem(navbarSessionKey);
}