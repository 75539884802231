/*= ===========================================================
  IMPORTS / SET UP
============================================================ */
import * as React from 'react';

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { PrimaryButton, SecondaryButton } from '../../../Legwork';
import Modal from './modal';
import FormModalTitle from './form-modal-title';
import FormModalHeader from './form-modal-header';

import FormRenderer from 'components/FormBuilder/components/FormBuilder/forms/form-renderer';
import { FORM_MODAL_CONSTANTS } from './constants';
import VerifyPatientModal from './verify-patient-modal';
import ContactUpdateModal from './contact-updated-modal';

const modalViews = {
  DELETE: 'DELETE',
  VERIFY: 'VERIFY',
  UPDATE: 'UPDATE',
  DOWNLOAD: 'DOWNLOAD',
  PENDING: 'PENDING',
};

const DialogModal = ({ title, acceptText, closeText, onClose, children, open }) => {
  return (
    <Dialog open={open} maxWidth='lg' fullWidth>
      <MuiDialogTitle>{title}</MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
      <MuiDialogActions
        style={{
          display: 'flex',
          justifyContent: closeText && acceptText ? 'space-between' : 'flex-end',
          borderTop: '1px solid #e3ebf6',
          marginTop: '8px',
        }}
      >
        {closeText && <SecondaryButton title={closeText} onClick={() => onClose(false)} />}
        {acceptText && <PrimaryButton title={acceptText} onClick={() => onClose(true)} />}
      </MuiDialogActions>
    </Dialog>
  );
};
/*= ===========================================================
  CLASS DEFINITION
============================================================ */
export default class FormModal extends React.Component {
  /*= ================ LIFECYCLE METHODS ==================== */
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      isSaving: false,

      contacts: [],
      contactUpdateSuccess: false,
      currentModal: '',
    };
  }

  get currentSubmission() {
    return this.props.selectable[this.props.currentIndex] || {};
  }

  get isApproved() {
    return this.currentSubmission.is_approved;
  }

  handleShowModal = modal_identifier => {
    this.setState({ currentModal: modal_identifier });
  };

  handleEdit = (isEditing = true) => this.setState({ isEditing });

  handleSaving = () => {
    this.setState({ isSaving: true });
  };

  handleSave = async (validationErrors, submissionObject) => {
    if (validationErrors) return;

    await this.props.onEdit({
      id: this.currentSubmission.id,
      object: submissionObject,
    });

    this.setState({
      isEditing: false,
      isSaving: false,
    });
  };

  handleDownload = () => {
    // any pre-processing before handing off to handler from props?
    this.handleShowModal(modalViews.DOWNLOAD);
  };

  handleDownloadClose = approved => {
    this.handleShowModal('');
    if (approved) {
      this.props.onDownload(this.currentSubmission.id);
    }
  };

  handleDelete = () => {
    this.handleShowModal(modalViews.DELETE);
  };

  handleDeleteClose = approved => {
    this.handleShowModal('');
    if (approved) {
      this.props.onDelete(this.currentSubmission.id, /* isBulk */ false);
      this.props.onClose();
    }
  };

  handleClose = closeAction => {
    // this event handler will get called one of four ways:
    // isEditing (True/False) x closeAction (Cancel/Approve)

    if (closeAction === Modal.APPROVE) return this.handleCloseAccept();
    return this.handleCloseCancel();
  };

  handleCloseAccept = async () => {
    if (this.state.isEditing) return this.handleSaving();
    // Check if the submission contact is a patient
    if (this.currentSubmission.is_approved || !this.props.isFormRestricted) {
      return this.props.onClose();
    }

    if (this.props.hasActions) {
      // replace with check determining if account uses supported Dentrix, EagleSoft, or Open Dental
      await this.getPatientsList(this.currentSubmission);
      return this.handleShowModal(modalViews.VERIFY);
    }
  };

  handleCloseCancel = () => {
    if (this.state.isEditing) this.setState({ isEditing: false });
    else this.props.onClose();
  };

  getPatientsList = async ({ submission_object, submitted_by }) => {
    let error;

    this.setState({ currentModal: modalViews.PENDING });

    // To resolve bug LWS-649, only pass the payload needed for this rpc call
    // There appears to be an out-of-memory issue when including the full submission object which includes the potentially large form_version.object.
    // Possibly connected, the browser devtools would crash when trying to stringify the rpc object here:
    // https://github.com/LegworkSocial/legwork-core-node/blob/dev/client/code/common/socketstream/rpc-responder.js#L40

    // @TODO: fix this
    // const contacts = await ss
    //   .rpc('FormBuilder.submissions.potential_contacts', {
    //     submission_object,
    //     submitted_by,
    //     skipSubmittedBy: false,
    //   })
    //   .catch(e => (error = e));
    if (!error) {
      this.setState({ contacts: [] });
    }
  };

  hasContactMatches = () => {
    return this.state.patients.length > 0;
  };

  handlePatientSelect = selectedPatient => {
    this.setState({ selectedPatient });
  };

  handleVerifyPatientClose = async id => {
    if (id) {
      this.setState({ selectedPatient: id });
      const submission = this.currentSubmission;
      const contactUpdateSuccess = await this.props.onApprove(submission.id, id).catch(_e => false);

      this.setState({ contactUpdateSuccess });
      this.handleShowModal(modalViews.UPDATE);
    } else this.props.onClose();
  };

  getAcceptText = () => {
    if (this.state.isEditing) return 'Save';
    return (
      !this.currentSubmission.is_approved && this.props.isFormRestricted && this.props.acceptText
    );
  };

  getCloseText = () => {
    if (this.state.isEditing) return 'Cancel';
    return 'Close';
  };

  /*= =============== RENDERING FUNCTIONS =================== */
  render() {
    const {
      currentIndex,
      onNextPress,
      onPreviousPress,
      isFormRestricted,
      totalRows,
    } = this.props;
    const { isSaving, contacts, contactUpdateSuccess, currentModal } = this.state;
    const {
      DeleteModalTitle,
      DeleteModalAcceptText,
      DeleteModalCloseText,
      DeleteModalBodyStrings,
      DownloadModalTitle,
      DownloadModalAcceptText,
      DownloadModalCloseText,
      DownloadModalBodyStrings,
      PendingModalTitle,
      PendingModalBodyText,
      PendingModalCloseText,
    } = FORM_MODAL_CONSTANTS;

    const submission = this.currentSubmission;
    return (
      <div>
        {currentModal === modalViews.DELETE && (
          <DialogModal
            open={currentModal === modalViews.DELETE}
            title={DeleteModalTitle}
            acceptText={DeleteModalAcceptText}
            closeText={DeleteModalCloseText}
            onClose={this.handleDeleteClose}
          >
            {DeleteModalBodyStrings.map((string, index) => (
              <p key={index}>{string}</p>
            ))}
          </DialogModal>
        )}

        {currentModal === modalViews.DOWNLOAD && (
          <DialogModal
            open={currentModal === modalViews.DOWNLOAD}
            title={DownloadModalTitle}
            acceptText={DownloadModalAcceptText}
            closeText={DownloadModalCloseText}
            onClose={this.handleDownloadClose}
          >
            {DownloadModalBodyStrings}
          </DialogModal>
        )}

        {currentModal === modalViews.PENDING && (
          <DialogModal
            open={currentModal === modalViews.PENDING}
            title={PendingModalTitle}
            closeText={PendingModalCloseText}
            acceptText={false}
          >
            {PendingModalBodyText}
          </DialogModal>
        )}
        {currentModal === modalViews.VERIFY && (
          <VerifyPatientModal
            contacts={contacts}
            onSelectPatient={this.handlePatientSelect}
            patientName={submission.contact_name}
            onClose={this.handleVerifyPatientClose}
          />
        )}
        {currentModal === modalViews.UPDATE && (
          <ContactUpdateModal isSuccess={!!contactUpdateSuccess} onClose={this.handleClose} />
        )}

        <DialogModal
          open={!Object.values(modalViews).includes(currentModal)}
          title={
            <FormModalTitle
              recordType='Submission'
              label={submission.contact_name}
              currentIndex={currentIndex}
              totalCount={totalRows}
              onNextClick={onNextPress}
              onPreviousClick={onPreviousPress}
              onEdit={this.handleEdit}
            />
          }
          onClose={this.handleClose}
          renderCloseHead={!this.state.isEditing}
          acceptText={this.getAcceptText()}
          closeText={this.getCloseText()}
        >
          <FormModalHeader
            isEdittable={isFormRestricted}
            isApproved={this.isApproved}
            onEdit={this.handleEdit}
            onDownload={this.handleDownload}
            onDelete={this.handleDelete}
            style={{
              marginTop: '10px',
              borderBottom: '1px solid #e3ebf6',
              paddingBottom: '3px',
              marginBottom: '16px',
            }}
          />
          <FormRenderer
            formVersion={{
              ...submission,
              object: submission.form_version_object,
            }}
            submission={submission.submission_object}
            readOnly={!this.state.isEditing}
            allowSubmit={false}
            saveFlag={isSaving}
            onSave={this.handleSave}
          />
        </DialogModal>
      </div>
    );
  }
}

/*
Currently the only forms that go the acceptance are those that are restricted.
as we allow other types of forms to be approved we should update this logic.
*/

FormModal.propTypes = {
  selectable: PropTypes.arrayOf(PropTypes.object),
  currentIndex: PropTypes.number,
  isFormRestricted: PropTypes.bool,
  hasActions: PropTypes.bool,
  acceptText: PropTypes.string,
  closeText: PropTypes.string,
  onNextPress: PropTypes.func,
  onPreviousPress: PropTypes.func,
  onEdit: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onApprove: PropTypes.func,
  onClose: PropTypes.func,
  totalRows: PropTypes.number,
};
