import React from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@material-ui/icons/Create';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { BorderlessButton, SmallDarkText } from 'components/Legwork';
import Styles from './styles';

const FONTSIZE = '0.94rem';

const FormModalHeader = ({ onEdit, onDownload, onDelete, isEdittable, isApproved, style = {} }) => {
  return (
    <div style={{ ...Styles.main, ...style }}>
      {!isApproved && isEdittable && (
        <BorderlessButton
          style={{ borderRight: '1px solid #e3ebf6', paddingTop: '5px', paddingBottom: '2px' }}
          title={<SmallDarkText style={{ fontSize: FONTSIZE }}>Edit</SmallDarkText>}
          onClick={onEdit}
          icon={<CreateIcon style={{ marginTop: '-4px', color: 'black' }} />}
        />
      )}

      <BorderlessButton
        style={{ borderRight: '1px solid #e3ebf6', paddingTop: '5px', paddingBottom: '2px' }}
        title={<SmallDarkText style={{ fontSize: FONTSIZE }}>Download PDF</SmallDarkText>}
        onClick={onDownload}
        icon={<CloudDownloadIcon style={{ marginTop: '-4px', color: 'black' }} />}
      />

      {isApproved || (
        <BorderlessButton
          style={{ paddingTop: '5px', paddingBottom: '2px' }}
          title={<SmallDarkText style={{ fontSize: FONTSIZE }}>Delete</SmallDarkText>}
          onClick={onDelete}
          icon={<DeleteIcon style={{ marginTop: '-4px', color: 'black' }} />}
        />
      )}
    </div>
  );
};

/*
  <BorderlessButton
        style={{ borderRight: '1px solid #e3ebf6', paddingTop: '5px', paddingBottom: '2px' }}
        title={
          isActive() ? (
            <SmallDarkText style={{ fontSize: FONTSIZE }}>{`Mark As ${
              READ_STATE[isRead()]
            }`}</SmallDarkText>
          ) : (
            <SmallTitleText style={{ fontSize: FONTSIZE }}>{`Mark As ${
              READ_STATE[isRead()]
            }`}</SmallTitleText>
          )
        }
        onClick={markAs}
        disabled={!isActive()}
        icon={<Create style={{ marginTop: '-4px', color: isActive() ? 'black' : '#b6c1d1' }} />}
      />
      <BorderlessButton
        style={{ borderRight: `1px solid #e3ebf6`, paddingTop: '5px', paddingBottom: '2px' }}
        title={
          isActive() ? (
            <SmallDarkText style={{ fontSize: FONTSIZE }}>Download PDFs</SmallDarkText>
          ) : (
            <SmallTitleText style={{ fontSize: FONTSIZE }}>Download PDFs</SmallTitleText>
          )
        }
        onClick={openPDF}
        disabled={!isActive()}
        icon={
          <CloudDownload style={{ marginTop: '-4px', color: isActive() ? 'black' : '#b6c1d1' }} />
        }
      />
      <BorderlessButton
        style={{ paddingTop: '5px', paddingBottom: '2px' }}
        title={
          isActive() ? (
            <SmallDarkText style={{ fontSize: FONTSIZE }}>Delete</SmallDarkText>
          ) : (
            <SmallTitleText style={{ fontSize: FONTSIZE }}>Delete</SmallTitleText>
          )
        }
        onClick={openDelete}
        disabled={!isActive()}
        icon={<Delete style={{ marginTop: '-4px', color: isActive() ? 'black' : '#b6c1d1' }} />}
      />
*/

FormModalHeader.propTypes = {
  isEdittable: PropTypes.bool,
  isApproved: PropTypes.bool,

  onEdit: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  style: PropTypes.object,
};

FormModalHeader.defaultProps = {
  isEditing: false,
  isEdittable: false,
  isApproved: false,
};

export default FormModalHeader;
