import styled from 'styled-components';

const ColorContainer = styled.div`
  position: relative;
  &:before {
    position: absolute;
    left: 0px;
    top: -1px;
    background-color: ${(props): string => props.color || 'black'};
    border-style: none;
    border-radius: 1.5px;
    width: 3px;
    height: 37px;
    content: '';
  }

  padding-left: 0.8rem;
`;

// eslint-disable-next-line import/prefer-default-export
export { ColorContainer };
