import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { Column } from 'global/requests/ResponseTypes';
import FilterGlobal from 'components/Global/Filter/Filter';
import Select from 'react-select';
import { getAppBaseUrl } from 'global/constants/url';
import Modal from 'components/Global/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const mock = [
  {
    agreementDetailVersion: 'v1 (Billing Billing Billing Billing)',
    agreementDetailTotal: '$999.00',
    agreementDetailAccount: '1',
    agreementDetailStatus: 'Send 1 hour before',
    agreementDetailItems: [
      'Revoke Signing Link',
      'Send Cancellation Email',
      'Send Cancellation Email',
      'Send Cancellation Email',
      'Send Cancellation Email AAA',
      'Send Cancellation Email Send Cancellation Send Cancellation',
    ],
  },
  {
    agreementDetailVersion: 'v2 (Billing)',
    agreementDetailTotal: '$999.00',
    agreementDetailAccount: '1',
    agreementDetailStatus: 'Unsigned',
    agreementDetailItems: ['Revoke Signing Link'],
  },
  {
    agreementDetailVersion: 'v3 (BWF)',
    agreementDetailTotal: '$999.00',
    agreementDetailAccount: '1',
    agreementDetailStatus: 'Signed in',
    agreementDetailItems: ['Send Cancellation Email'],
  },
];

const columns: Column[] = [
  { id: 'agreementDetailVersion', label: 'Version#' },
  { id: 'agreementDetailTotal', label: 'Total' },
  { id: 'agreementDetailAccount', label: '#Accounts' },
  { id: 'agreementDetailStatus', label: 'Status' },
  { id: 'agreementDetailItems', label: '' },
];

type OptionDropDown = {
  value: string;
  label: string;
};

const AgreementDetail = () => {
  const [modalStyle] = React.useState(getModalStyle);
  const [openModalSelect, setOpenModalSelect] = useState<boolean>(false);
  const [openModalGenerate, setOpenModalGenerate] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = React.useState(
    {} as OptionDropDown
  );
  const [optionsCompany, setOptionsComapny] = React.useState<
    OptionDropDown[]
  >([
    { label: 'First Company', value: 'first' },
    { label: 'Second Company', value: 'second' },
    { label: 'Thirth Company', value: 'thrith' },
  ]);

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setOpenModalSelect(true);
  };

  const handleSelectCompany = (newCompany: OptionDropDown) => {
    setSelectedCompany(newCompany);
    if (newCompany.value === '') {
    } else {
    }
  };

  const renderPopupSelect = () => {
    return (
      <div style={modalStyle} className='insert-modal-style modal-select-company'>
        <h2 id='simple-modal-title'>
          {' '}
          Select HubSpot Company
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            fixedWidth
            onClick={() => {
              setOpenModalSelect(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          <span className='label-select'>HubSpot Company</span>
          <FilterGlobal
            handleSetNewFilterValue={handleSelectCompany}
            optionsForFilter={optionsCompany}
            valueSelected={selectedCompany}
            placeholder={'Select a HubSpot Company'}
          />
        </div>

        <div className='action-modal'>
          <button
            className='btn-cancel'
            onClick={() => {
              setOpenModalSelect(false);
            }}
          >
            Cancel
          </button>
          <button
            className='btn-insert'
            onClick={() => {
              setOpenModalSelect(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='communication-container'>
        <div className='communication-container__table react-modal--settings__content agreement-detail'>
          <div className='setting__content--full-row '>
            <h2 className='practice-title'>Agreement Info</h2>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Practice Name
              </span>
              <span className='agreement-field--content'>
                Northwest Animal Care Hospital - Seattle
              </span>
            </div>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Group
              </span>
              <span className='agreement-field--content'>
                Northwest Animal Care Hospital
              </span>
            </div>
            <div className='width-30'></div>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Agreement ID
              </span>
              <span className='agreement-field--content'>
                5d394928403940s940f
              </span>
            </div>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                HubSpot Company
              </span>
              <a
                className='link--open-modal'
                href='#'
                type='button'
                onClick={handleOpenModal}
              >
                Click here to select a HubSpot Company
              </a>
            </div>
            <div className='width-30'></div>
          </div>

          <div className='setting__content--full-row'>
            <h2 className='practice-title'>Agreement Contact</h2>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Name
              </span>
              <span className='agreement-field--content'>Join Wick</span>
            </div>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Email
              </span>
              <span className='agreement-field--content'>
                joinwick@yopmail.com
              </span>
            </div>
            <div className='width-30'>
              <span className='title-input-form agreement-field--label'>
                Phone Number
              </span>
              <span className='agreement-field--content'>(096) 294-3820</span>
            </div>
          </div>

          <div className='setting__content--full-row'>
            <h2 className='practice-title'>Agreement History</h2>
          </div>

          <CommunicationTable
            columns={columns}
            dataTable={mock}
            isDisablePagination={true}
          />

          <div className='setting__content--full-row'>
            <Button variant='contained' color='primary' onClick={() => setOpenModalGenerate(true)}>
              Generate Signing Link
            </Button>
          </div>
        </div>
      </div>

      <Modal
        isOpenModal={openModalSelect}
        title="Select HubSpot Company"
        handleCloseModal={() => setOpenModalSelect(false)}
        handleConfirmModal={() => setOpenModalSelect(false)}
        textLeftButton="Cancel"
        textRightButton="Save"
      >
        <>
          <p className='modal-body__label'>HubSpot Company</p>
          <FilterGlobal
            handleSetNewFilterValue={handleSelectCompany}
            optionsForFilter={optionsCompany}
            valueSelected={selectedCompany}
            placeholder={'Select a HubSpot Company'}
          />
        </>
      </Modal>

      <Modal
        isOpenModal={openModalGenerate}
        title="Signing Link"
        handleCloseModal={() => setOpenModalGenerate(false)}
        handleConfirmModal={() => setOpenModalGenerate(false)}
        isFullAction={false}
        textRightButton="Close"
        classMore="modal--generate-link"
      >
        <a className="link-generate" href={`${getAppBaseUrl()}/agreement/5`} target="_self" rel="noreferrer">https://projects.invisionapp.com/d/main?origin=v7#/console/19832645/431656896/preview?scrollOffset=0</a>
      </Modal>
    </>
  );
};

export default AgreementDetail;
