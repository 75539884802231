import * as t from 'io-ts';

export const ListDate = t.partial({
  dayOfWeekName: t.string,
  active: t.boolean,
  startTime: t.string,
  endTime: t.string,
  takeLunch: t.boolean,
  startTimeLunch: t.string,
  endTimeLunch: t.string,
  dayOrder: t.number,
});

const HoursOfOperationData = t.partial({
  errorMsg: t.string,
  // externalStatus: TEMP_EXTERNAL_STATUS,
  result: t.type({
    createDate: t.string,
    id: t.string,
    tenantId: t.string,
    updatedDate: t.string,
    value: t.array(ListDate)
  }),
});

export type HoursOfOperationDataType = t.TypeOf<typeof HoursOfOperationData>;
export type ListDateType = t.TypeOf<typeof ListDate>;