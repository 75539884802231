import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { getThemeProp } from 'components/Legwork/utils';

const StyledSwitch = styled(Switch)`
  .MuiButtonBase-root {
    &.Mui-checked {
      color: ${getThemeProp('palette.success.dark', 'blue')};
      &:hover {
        background-color: ${getThemeProp('palette.success.dark', 'blue')}24;
      }
      & + .MuiSwitch-track {
        background-color: ${getThemeProp('palette.success.dark', 'blue')};
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.14);
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledSwitch };
