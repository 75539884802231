/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import ApiService from '../clientApi';
import { ICustomTimeRange, IPageStat, ICurrentMonthCards } from './types';

const Api = ApiService();

export type Granularity = 'day' | 'month';
export const getDateFormat = (granularity?: Granularity): string => {
  if (granularity === 'day') {
    return 'MMM D';
  }
  return `MMM 'YY`;
};

const getThisMonthResults = async (params: Date[]): Promise<ICurrentMonthCards> => {
  const result = await Api.rpc('', {
    method: 'web_stats.getMonthStats',
    params,
  });
  const { avg_visit_time, bounce_rate, total_visits, unique_visits, pages_per_visit } = result.p[1];
  const r: ICurrentMonthCards = {
    avgTimeOnSite: { stat: avg_visit_time.value.toFixed(1) },
    visits: { stat: total_visits.value.toString() },
    uniqueVisitors: { stat: unique_visits.value.toString() },
    pagesPerVisit: { stat: pages_per_visit.value },
    bounceRate: { stat: bounce_rate.value },
  };
  return r;
};

const getTimeRangeStats = async (params: Date[]): Promise<ICustomTimeRange> => {
  const result = await Api.rpc('', {
    method: 'web_stats.getMonthlyStats',
    params,
  });
  const {
    total_visits,
    unique_visits,
    pages_per_visit,
    avg_visit_time,
    bounce_rate,
    pages_visited,
    devices,
    sources,
  } = result.p[1];

  const r: ICustomTimeRange = {
    visitsByMonth: {
      data: total_visits.map((v: number[]): number => v[1]),
      xAxis: total_visits.map((v: number[]): string => moment(v[0]).format(getDateFormat())),
    },
    uniqueVisitorsByMonth: {
      data: unique_visits.map((v: number[]): number => v[1]),
      xAxis: unique_visits.map((v: number[]): string => moment(v[0]).format(getDateFormat())),
    },
    pagesPerVisitByMonth: {
      data: pages_per_visit.map((v: number[]): number => v[1]),
      xAxis: pages_per_visit.map((v: number[]): string => moment(v[0]).format(getDateFormat())),
    },
    averageTimeOnSite: {
      data: avg_visit_time.map((v: number[]): number => v[1]),
      xAxis: avg_visit_time.map((v: number[]): string => moment(v[0]).format(getDateFormat())),
    },
    bounceRateByMonth: {
      data: bounce_rate.map((v: number[]): number => v[1]),
      xAxis: bounce_rate.map((v: number[]): string => moment(v[0]).format(getDateFormat())),
    },
    topVisitedPages: pages_visited.map(
      (v: { title: string; views: number; percent: number }): IPageStat => ({
        page: v.title,
        pageViews: v.views,
        percentOfTraffic: v.percent,
      }),
    ),
    deviceType: {
      titles: devices.value.map((vals: [string, number]): string => vals[0]), // ['desktop', 'mobile', 'tablet'],
      values: devices.value.map((vals: [string, number]): number => vals[1]),
    },
    trafficSource: {
      titles: sources.value.map((vals: [string, number]): string => vals[0]), // ['desktop', 'mobile', 'tablet'],
      values: sources.value.map((vals: [string, number]): number => vals[1]),
    },
  };
  return r;
};

export default { getThisMonthResults, getTimeRangeStats };
