import React from 'react';
import NavigationTopLevelLink from 'components/Global/Navigation/TopLevelLink';
import NavigationTopLevelContainer from 'components/Global/Navigation/TopLevelContainer';
import { NavigationLink } from 'types/NavigationT';

type SidebarProps = {
  sidebarLinkData: NavigationLink[],
  sidebarIsOpen: boolean,
  allowedPath: Array<string>
  setSidebarIsOpen: (isOpen: boolean) => void,
  crmIsOpen: boolean,
  formIsOpen: boolean,
  setCrmIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setFormIsOpen: React.Dispatch<React.SetStateAction<boolean>>
};


const Sidebar = (props: SidebarProps): JSX.Element => {
  return (
    <div
      className={"sidebar" + (props.sidebarIsOpen ? " open" : "")}
    >
      <nav className="sidebar__nav">
        <ul className={"sidebar__list" + (props.sidebarIsOpen ? "sidebar__list--open" : "")}>
          {
            props.sidebarLinkData.map((navElementData, navElementIndex) => {

              const { leftIcon, rightIcon } = navElementData;
              //childLinks only appear on container elements
              if (navElementData.childLinks) {
                const { allowedPath = [] } = props;
                //map the childLinks data into dom elements
                return (
                  <NavigationTopLevelContainer
                    {...navElementData}
                    key={"nav-link-container-" + navElementIndex}
                    innerText={navElementData.innerText}
                    childLinks={navElementData.childLinks}
                    navElementIndex={navElementIndex.toString()}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    shouldRenderOpen={navElementData.preOpen}
                    allowedPath={allowedPath}
                    setSidebarIsOpen={props.setSidebarIsOpen}
                    isCrmOpen={props.crmIsOpen}
                    isFormOpen={props.formIsOpen}
                    setCRMIsOpen={props.setCrmIsOpen}
                    setFormIsOpen={props.setFormIsOpen}
                  />
                );
              }

              // The only navElementData items with linkTo defined are top level links
              if (typeof navElementData.linkTo === "string") {
                const { allowedPath = [] } = props;
                return (
                  allowedPath.includes(navElementData.linkTo) && <NavigationTopLevelLink
                    // <NavigationTopLevelLink
                    linkTo={navElementData.linkTo}
                    innerText={navElementData.innerText}
                    navElementIndex={navElementIndex.toString()}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    key={"nav-top-level-link-" + navElementIndex}
                    setSidebarIsOpen={props.setSidebarIsOpen}
                  />
                );
              }

              // Compiler warns about no guaranteed return without this. No DOM element will be rendered
              return <></>;
            })

          }
        </ul>
      </nav>
    </div>
  ); //end component return
}

export default Sidebar;
