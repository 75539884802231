import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { Column, Role, Screen } from 'global/requests/ResponseTypes';
import ProvidersModal, { Room } from './ProvidersModal';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { ErrorMessage, FastField, Formik } from 'formik';
import styled from 'styled-components';
import { Provider } from 'global/requests/RequestTypes';
import { AnyAaaaRecord } from 'dns';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import listDateInitial from '../Availability/constant/listDateInitial.json';
import toast from 'components/Global/Toast';
import { Autocomplete } from '@material-ui/lab';
import Filter from 'components/Global/Filter/Filter';

const FormProfileWrapper = styled.div`
  .react-modal--settings__content {
    .setting__content--full-row {
      margin: 20px;

      .first-name {
        margin-right: 0;

        .setting__search-form {
          border-color: #d4dbe4;
        }
      }

      .setting__search-form {
        border: 2px solid #d4dbe4;
      }

      .MuiAutocomplete-root {
        width: calc(100% - 140px);
        margin-left: auto;

        .MuiInputBase-root {
          padding: 4px 65px 4px 10px;
        }
      }
    }
  }
`;

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#31CC97',
          borderColor: '#31CC97',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

interface ProviderProfileProps {
  displayRoleModal: boolean;
  setDisplayRoleModal: (arg: boolean) => void;
  titleText?: string;
  providerModalProfileData: ProviderModal;

  sumActiveTabs: number;
  providerProfile: ProviderModal;
  // isEditProvider: boolean;
  openPopup: boolean;
  isEdit: boolean;
  dirtyProfile: boolean;
  dirtyAvailability: boolean;
  dirtyAppointmentType: boolean;
  listRoom: Room[];
  setTitleModal: (name: string) => void;
  setIsEdit: (arg: boolean) => void;
  setDirtyProfile: (arg: boolean) => void;
  // setIsEditProvider: (arg: boolean) => void;
  setProviderProfile: (provider: ProviderModal) => void;
  setOpenPopup: (arg: boolean) => void;
  setSumActiveTabs: (tabs: number) => void;
  setActiveTab: (tab: number) => void;
  getProviderList: () => void;
  setProfile: (profile: Provider) => void;
  handleCloseForm: any;
}

interface OptionType {
  label: string;
  value: string;
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const ProviderValidation = Yup.object().shape({
  name: Yup.string().required(ErrorMessageTypes.required),
  emailAddress: Yup.string()
    .email('Incorrect email format.')
    .max(255, ErrorMessageTypes.incorrectFormat),
  roomId: Yup.string().required(ErrorMessageTypes.required),
});

const ProviderProfile = (props: ProviderProfileProps): JSX.Element => {
  const {
    displayRoleModal,
    titleText,
    providerModalProfileData,
    openPopup,
    handleCloseForm,
    sumActiveTabs,
    providerProfile,
    // isEditProvider,
    isEdit,
    dirtyProfile,
    dirtyAvailability,
    dirtyAppointmentType,
    listRoom,
    setTitleModal,
    setIsEdit,
    setDirtyProfile,
    setProfile,
    // setIsEditProvider,
    setDisplayRoleModal,
    setOpenPopup,
    setSumActiveTabs,
    setActiveTab,
    getProviderList,
    setProviderProfile,
  } = props;

  const [optionRooms, setOptionRooms] = React.useState<OptionType[]>([]);

  const providerInitialValues: Provider | ProviderModal = !isEdit
    ? {
        name: '',
        jobTitle: '',
        emailAddress: '',
        isBookingActive: true,
        roomId: listRoom[0]?.id ?? '',
      }
    : {
        id: providerProfile?.id,
        name: providerProfile?.name,
        jobTitle: providerProfile?.jobTitle,
        emailAddress: providerProfile?.emailAddress,
        isBookingActive: providerProfile?.isBookingActive,
        roomId: providerProfile?.roomId ?? '',
      };

  React.useEffect(() => {
    if (listRoom.length) {
      setOptionRooms(
        listRoom.map((room) => ({ label: room.name, value: room.id }))
      );
    }
  }, [listRoom]);

  const handleSubmitData = async (values: any) => {
    try {
      const submitData = {
        name: values.name,
        jobTitle: values.jobTitle,
        emailAddress: values.emailAddress,
        isBookingActive: values.isBookingActive,
        roomId: values.roomId,
      };
      // debugger;
      const res = !isEdit
        ? await configuredRequests.POST.createProvider(submitData)
        : await configuredRequests.PATCH.updateProvider({
            id: values.id,
            ...submitData,
          });

      // setDisplayRoleModal(false);
      // await props.handleReloadProviderList();
      if (res) {
        // setErrorEmail('');
        setDirtyProfile(false);
        setTitleModal(submitData.name);
        setProviderProfile(
          isEdit
            ? { ...providerProfile, ...submitData }
            : // @ts-ignore
              { ...res.data, hoursOfOperationDto: { value: JSON.parse(res.data.hoursOfOperation.value) } }
        );
        // @ts-ignore
        setProfile({} as ProviderModal);
        if (!isEdit) {
          setSumActiveTabs(3);
          setActiveTab(1);
        } else if (dirtyAvailability) {
          setActiveTab(1);
        } else if (dirtyAppointmentType) {
          setActiveTab(2);
        } else {
          setActiveTab(1);
        }
        getProviderList();
        setIsEdit(true);
        // setIsEditProvider(true);
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      toast.error('Your data has not been saved yet. Please try again.');
      if (error.response) {
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={providerInitialValues}
        validationSchema={ProviderValidation}
        validateOnMount={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const { handleSubmit, handleBlur, setFieldValue, values } =
            formikProps;

          return (
            <div className='react-modal--content'>
              <div className='edit-appointment '>
                <FormProfileWrapper>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row block flex'>
                      <div className='form-input-settings'>
                        <span className='title-input-form'>
                          {' '}
                          Provider Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={255}
                            name='name'
                            required
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'name',
                                e.currentTarget.value.trim()
                              );
                              // @ts-ignore
                              setProfile((prevProfile: Provider) => ({
                                ...prevProfile,
                                name: e.currentTarget.value.trim(),
                              }));
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue('name', e.currentTarget.value);
                              setDirtyProfile(true);
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='name' />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        Provider Job Title
                      </span>
                      <FastField
                        maxLength={255}
                        name='jobTitle'
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'jobTitle',
                            e.currentTarget.value.trim()
                          );
                          // @ts-ignore
                          setProfile((prevProfile) => ({
                            ...prevProfile,
                            jobTitle: e.currentTarget.value.trim(),
                          }));
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('jobTitle', e.currentTarget.value);
                          setDirtyProfile(true);
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='jobTitle' />
                      </p>
                    </div>
                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Provider Email Address
                      </span>
                      <FastField
                        name='emailAddress'
                        maxLength={255}
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'emailAddress',
                            e.currentTarget.value.trim()
                          );
                          // @ts-ignore
                          setProfile((prevProfile) => ({
                            ...prevProfile,
                            emailAddress: e.currentTarget.value.trim(),
                          }));
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('emailAddress', e.currentTarget.value);
                          setDirtyProfile(true);
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='emailAddress' />
                      </p>
                    </div>
                    <div className='setting__content--full-row block'>
                      <FormControlLabel
                        control={
                          <AntSwitch
                            name='isBookingActive'
                            checked={values.isBookingActive}
                            onChange={() => {
                              setFieldValue(
                                'isBookingActive',
                                !values.isBookingActive
                              );
                              // @ts-ignore
                              setProfile((prevProfile) => ({
                                ...prevProfile,
                                isBookingActive: !values.isBookingActive,
                              }));
                              setDirtyProfile(true);
                            }}
                          />
                        }
                        label='Accepting Appointments'
                      />
                    </div>
                    <div className='setting__content--full-row flex'>
                      <h2 className='practice-title'>
                        Room / Resource <b className='require'>*</b>
                      </h2>
                      <Filter
                        optionsForFilter={optionRooms}
                        handleSetNewFilterValue={(newRoom) => {
                          setFieldValue('roomId', newRoom.value);
                          setDirtyProfile(true);
                        }}
                        valueSelected={optionRooms.find(
                          (room) => room.value === values.roomId
                        )}
                      />
                    </div>
                    <p className='error-msg'>
                      <ErrorMessage name='roomId' />
                    </p>
                  </form>
                </FormProfileWrapper>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!(dirtyProfile && formikProps.isValid)}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ProviderProfile;
