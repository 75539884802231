import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import loading_spinner from 'media/images/loading-spinner.svg';

type OpensByPlatformChartDataItem = {
  name: string;
  value: number;
};

type OpensByPlatformChartProps = {
  data: OpensByPlatformChartDataItem[];
  loadingOpenByPlatform: boolean;
};

export const OpensByPlatform = (props: OpensByPlatformChartProps) => {
  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: null,
    },

    tooltip: {
      enabled: false
    },

    xAxis: {
      categories: props.data.map((item) => item.name),
      labels: {
        style: { fontSize: '13px', fontWeight: 'bold' },
        align: 'left',
        reserveSpace: true,
      },
      lineColor: 'transparent'
    },
    yAxis: {
      min: 0,
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        states: {
          inactive: {
            opacity: 0.8,
          },
        },
        dataLabels: {
          enabled: true,
          format: '{y} %',
          style: { fontSize: '13px' },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: props.data.map((item) => item.value),
        color: '#1974FF',
        borderRadius: 10,
        pointWidth: 15,
      },
    ],
  };

  return (
    <div className='w-100'>
      {props.loadingOpenByPlatform ? <img className="loading-open-by-platform" src={loading_spinner} alt={"Loading"} /> : 
        <HighchartsReact highcharts={Highcharts} options={options} />
      }
    </div>
  );
};

export default OpensByPlatform;
