import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { getThemeProp } from 'components/Legwork/utils';

const StyledButton = styled(Button)`
  text-transform: none;
  .MuiButton-startIcon {
    margin-right: 0;
    svg {
      margin-right: 4px;
    }
  }
  .MuiButton-endIcon {
    margin-left: 0;
    svg {
      margin-left: 4px;
    }
  }
  svg {
    fill: ${getThemeProp('palette.text.hint')};
    height: 100%;
    width: 1rem;
  }
  p {
    padding-top: 2px;
  }
` as typeof Button;

export default StyledButton;
