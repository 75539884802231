import { sortBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AntSwitch from 'components/Global/Switch/AntSwitch';
import Table from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';

const CustomTableData = styled.div`
  /* display: flex;
  align-items: center; */
  margin: 0 20px;
  padding-top: 10px;

  .communication-container__table {
    width: 100%;
  }

  .MuiTableContainer-root {
    padding: 20px;

    .MuiTableCell-root {
      padding: 20px;

      &.MuiTablePagination-root {
        padding: 0;
      }
    }

    .MuiTableCell-root {
      &:first-child {
        padding-left: 1px;
      }

      &:last-child {
        text-align: center;
      }
    }

    .MuiTableCell-head {
      padding-top: 0px;
    }
  }
`;

const columns = [
  { key: 'name', title: 'Name' },
  { key: 'externalId', title: 'ID' },
  { key: 'hideInClientPortal', title: 'Display in Online Appointment Booking' },
];

const SpeciesSetting = (): JSX.Element => {
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const fullData = useRef<SpeciesSettingType[]>([]);
  const [speciesDataTable, setSpeciesDataTable] = useState<
    SpeciesSettingType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllSpeciesSetting();
  }, []);

  useEffect(() => {
    const index = queryPagination.limit * queryPagination.offset;
    const data = fullData.current.slice(index, index + queryPagination.limit);
    setSpeciesDataTable(data);
  }, [queryPagination]);

  const getAllSpeciesSetting = async () => {
    try {
      const res = await configuredRequests.GET.allSpeciesByCurrentTenant();

      if (res?.data) {
        // @ts-ignore
        const dataAfterSort = sortBy(res.data.species, [
          function (s) {
            return s.hideInClientPortal;
          },
          'name',
        ]);

        fullData.current = dataAfterSort;

        // @ts-ignore
        setSpeciesDataTable(dataAfterSort.slice(0, queryPagination.limit));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setQueryPagination((preState) => ({
      ...preState,
      offset: newPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage: number = parseInt(event.target.value);
    if (newRowsPerPage) {
      setQueryPagination((prev) => ({
        ...prev,
        limit: newRowsPerPage,
        offset: 0,
      }));
    }
  };

  const handleChangeToggle = async (specie: SpeciesSettingType) => {
    try {
      const res = configuredRequests.POST.updateHideInClientPortal({
        hide: !specie.hideInClientPortal,
        items: [specie.id],
      });
      const dataAfterSwitchStatus = speciesDataTable.map(
        (item: SpeciesSettingType) => {
          if (item.id === specie.id) {
            return { ...item, hideInClientPortal: !item.hideInClientPortal };
          }
          return item;
        }
      );
      setSpeciesDataTable(dataAfterSwitchStatus);
      fullData.current.forEach((item: SpeciesSettingType) => {
        if (item.id === specie.id) {
          item.hideInClientPortal = !specie.hideInClientPortal;
        }
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <CustomTableData className='user-setting w-300 setting-detail setting-scroll--custom'>
      {isLoading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          {speciesDataTable.length > 0 ? (
            <div className='communication-container__table w-table-cell templates-mail-sms m-0 '>
              <Table
                columns={columns}
                // @ts-ignore
                rows={speciesDataTable.map((item) => ({
                  ...item,
                  hideInClientPortal: (
                    <AntSwitch
                      label=''
                      onChange={() => handleChangeToggle(item)}
                      checked={!item.hideInClientPortal}
                    />
                  ),
                }))}
                isHasPagination={true}
                totalAvailable={fullData.current.length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
              />
            </div>
          ) : (
            <p className='no-result'>No results found</p>
          )}
        </>
      )}
    </CustomTableData>
  );
};

export default SpeciesSetting;
