import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Modal } from '@material-ui/core';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { dataStateOfUS } from 'global/localStorage/dataStateOfUS';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { CreateGroupRequest } from 'global/requests/RequestTypes';
import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { phonePattern } from 'utils/regex';
import * as Yup from 'yup';
import { useRouter } from 'hooks/useRouter';
ReactModal.setAppElement('div#root');

interface RightModalSettingUserProps {
  displaySettingUserModal: boolean;
  setDisplaySettingUserModal: (arg: boolean) => void;
  titleText?: string;
  handleReloadGroupList?: any;
  isEdit?: boolean;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const UserValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  status: Yup.string().required(ErrorMessageTypes.required),
  practiceEmailAddress: Yup.string()
    .email('Incorrect email format.')
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  addressLine1: Yup.string()
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  addressLine2: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
  city: Yup.string()
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  postalCode: Yup.number()
    .typeError(ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  givenName: Yup.string().required(ErrorMessageTypes.required),
  lastName: Yup.string().required(ErrorMessageTypes.required),
  number: Yup.string()
    .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  extension: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
  kind: Yup.string(),
});

const GroupModal = (props: RightModalSettingUserProps) => {
  const {
    displaySettingUserModal,
    setDisplaySettingUserModal,
    titleText,
    isEdit,
  } = props;

  const router = useRouter();

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [errorNamePractice, setErrorNamePractice] = React.useState<string>('');
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const groupsInfoData = {
    name: '',
    status: 'Active',
    practiceEmailAddress: '',
    practicePhoneNumbers: [
      {
        phoneNumberId: '',
        extension: '',
        number: '',
        isPrimary: true,
        isValidated: false,
        kind: 'cell',
      },
    ],
    addresses: [
      {
        address: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: dataStateOfUS[0],
          postalCode: '',
          country: '',
        },
        isPrimary: true,
        kind: 'home',
      },
    ],
    givenName: '',
    lastName: '',
    isSinglePractice: false,
  };

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = {
        name: values.name,
        status: values.status,
        practiceEmailAddress: values.practiceEmailAddress,
        practicePersonalDetails: [
          {
            givenName: values.givenName,
            lastName: values.lastName,
          },
        ],
        practicePhoneNumbers: [
          {
            number: values.number,
            extension: values.extension,
            isPrimary: true,
            kind: values.kind ? values.kind : 'cell',
          },
        ],
        addresses: [
          {
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              state: values.state ? values.state.value : dataStateOfUS[0].value,
              postalCode: values.postalCode,
              country: '',
            },
            isPrimary: true,
            kind: 'home',
          },
        ],

        isSinglePractice: values.isSinglePractice,
      };

      const res = await configuredRequests.POST.createGroup(
        submitData as CreateGroupRequest
      );
      setDisplaySettingUserModal(false);
      // await props.handleReloadGroupList();
      if (res) {
        setErrorNamePractice('');
        setDirtyForm(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
        router.push(`/Groups/${res.tenantGroupId}`);
      }
    } catch (error) {
      setDisabledSubmit(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );

      if (error.response) {
        setErrorNamePractice(error.response.data.message);
      }
    }
  };

  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplaySettingUserModal(dirtyForm);
    setErrorNamePractice('');
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Your data will be lost. Do you wish to continue?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
              setDisplaySettingUserModal(true);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setDirtyForm(false);
              setOpenPopup(false);
              setDisplaySettingUserModal(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={displaySettingUserModal}
      onRequestClose={handleCloseModal}
    >
      <Formik
        initialValues={groupsInfoData}
        validationSchema={UserValidationSchema}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
            errors,
            dirty,
            setFieldTouched,
          } = formikProps;
          if (dirty && displaySettingUserModal) {
            setDirtyForm(true);
          }
          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Group' : ''}{' '}
                </p>

                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment roleModal '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Group Information</h2>
                    </div>
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Group Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='name'
                            maxLength={255}
                            placeholder='Group Name'
                            required
                            className='setting__search-form'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                              setErrorNamePractice('');
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'name',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='name' />
                            {!errors.name && errorNamePractice}
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Status <b className='require'>*</b>
                        </span>
                        <Field
                          as='select'
                          name='status'
                          value={values.status}
                          className='full-width custom-color'
                        >
                          <option value='Active'> Active</option>
                          <option value='Inactive'>Inactive</option>
                        </Field>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        Address <b className='require'>*</b>
                      </span>
                      <FastField
                        name='addressLine1'
                        maxLength={50}
                        placeholder='Address'
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addressLine1',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addressLine1' />
                      </p>
                      <span className='title-input-form'>
                        Apt / Suite / Other
                      </span>
                      <FastField
                        name='addressLine2'
                        maxLength={50}
                        className='setting__search-form '
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addressLine2',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addressLine2' />
                      </p>
                      <div className='flex address-2'>
                        <div>
                          <span className='title-input-form'>
                            City <b className='require'>*</b>
                          </span>
                          <FastField
                            name='city'
                            maxLength={50}
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'city',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='city' />
                          </p>
                        </div>

                        <div className='full-width margin-h-5 w-50'>
                          <span className='title-input-form'>
                            State / Province <b className='require'>*</b>
                          </span>
                          <FastField
                            classNamePrefix='search-city'
                            className='option-city'
                            component={Select}
                            //@ts-ignore
                            defaultValue={values.addresses[0].address.state}
                            name='state'
                            options={dataStateOfUS}
                            onBlur={() => {
                              setFieldTouched('state', true);
                            }}
                            onChange={(e: any) => {
                              setFieldValue('state', e);
                            }}
                            maxMenuHeight={150}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='state' />
                          </p>
                        </div>

                        <div>
                          <span className='title-input-form'>
                            Zip / Postal Code <b className='require'>*</b>
                          </span>
                          <FastField
                            name='postalCode'
                            maxLength={50}
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'postalCode',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='postalCode' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Group's Contact</h2>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          First Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='givenName'
                            maxLength={15}
                            required
                            placeholder='First Name'
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'givenName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='givenName' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Last Name <b className='require'>*</b>
                        </span>
                        <FastField
                          name='lastName'
                          maxLength={15}
                          required
                          placeholder='Last Name'
                          className='setting__search-form'
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            setFieldValue(
                              'lastName',
                              e.currentTarget.value.trim()
                            );
                          }}
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='lastName' />
                        </p>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Email Address <b className='require'>*</b>
                      </span>
                      <FastField
                        name='practiceEmailAddress'
                        placeholder='Email Address'
                        required
                        disabled={isEdit}
                        maxLength={50}
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'practiceEmailAddress',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='practiceEmailAddress' />
                      </p>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Phone Number <b className='require'>*</b>
                      </span>
                      <div className='d-flex-wrap'>
                        <div className='margin-r-5 h-60 w-40'>
                          <FastField
                            name={`number`}
                            max={10}
                            placeholder='Phone Number'
                            required
                            className='setting__search-form width-100'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                `number`,
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage
                              className='error-msg'
                              name={`number`}
                            />
                          </p>
                        </div>
                        <div className='margin-r-5 h-60 w-30'>
                          <FastField
                            name={`extension`}
                            maxLength={50}
                            placeholder='Ext.'
                            className='setting__search-form width-50 margin-r-0'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                `extension`,
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage
                              className='error-msg'
                              name={`extension`}
                            />
                          </p>
                        </div>
                        <div className='search-filter__global h-60'>
                          <Field
                            className='filter-form'
                            as='select'
                            name={`kind`}
                          >
                            <option value='cell'>Mobile Phone</option>
                            <option value='landline'>Home Phone</option>
                            <option value='business'>Office Phone</option>
                            <option value='other'>Other Phone</option>
                          </Field>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <div className='d-flex checkbox-single-practices'>
                        <Checkbox
                          checked={values.isSinglePractice}
                          color='primary'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          onChange={() => {
                            setFieldValue(
                              'isSinglePractice',
                              !values.isSinglePractice
                            );
                            setErrorNamePractice('');
                          }}
                          name='isSinglePractice'
                          className='check-schedule'
                        />
                        <span> Single Practice</span>
                      </div>
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseModal}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    !formikProps.isValid ||
                    !!errorNamePractice ||
                    disabledSubmit
                  }
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </ReactModal>
  );
};
export default GroupModal;
