import * as t from 'io-ts';

export const EmitterMethod = t.partial({
  methodId: t.string,
  methodName: t.string,
  methodValue: t.string,
  methodNameAction: t.string,
  methodNameObject: t.string,
});
export type EmitterMethod = t.TypeOf<typeof EmitterMethod>;

export const EmitterMethodResponseType = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  methods: t.array(EmitterMethod),
});
export type EmitterMethodResponseType = t.TypeOf<typeof EmitterMethodResponseType>;