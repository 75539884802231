import React from 'react';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';
import AttributeStatus from 'components/Communications/AttributeStatus/AttributeStatus';
import ProgressBar from 'components/Global/ChartBar/ChartBarCommont';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  AttributeStatusResponse,
  ChartUnsuccessfulAnalyzeTab,
} from 'global/requests/ResponseTypes';
import WithComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import moment from 'moment';
import { convertTimeToSecond } from 'utils/convertData';
import loading_spinner from 'media/images/loading-spinner.svg';

const dataChartMock: ChartUnsuccessfulAnalyzeTab[] = [{ name: '', value: 0, templateId: '' }];

const dataChartReport: ChartUnsuccessfulAnalyzeTab[] = [{ name: '', value: 0, templateId: '' }];

const ComponentizedChartBarUnsubscribes = WithComponentClassAndHeaderText(
  ProgressBar,
  'Emails with the most unsubscribes',
  'flex1140'
);

const ComponentizedChartBarReports = WithComponentClassAndHeaderText(
  ProgressBar,
  'Emails with the most spam reports',
  'flex1140'
);

const Unsuccessful = (): JSX.Element => {
  const [dataAttribute, setDataAttribute] = React.useState<
    Array<AttributeStatusResponse>
  >([]);
  const [dataChartUnsubcribed, setDataChartUnsubcribed] = React.useState<
    Array<ChartUnsuccessfulAnalyzeTab>
  >(dataChartMock);
  const [dataChartReportEmail, setDataChartReportEmail] = React.useState<
    Array<ChartUnsuccessfulAnalyzeTab>
  >(dataChartReport);

  const endDateTime = moment(new Date()).endOf('day').toDate();
  const past30days = moment(endDateTime).subtract(29, 'days').toDate();
  const [startDate, setStartDate] = React.useState<Date | null>(past30days);
  const [endDate, setEndDate] = React.useState<Date | null>(endDateTime);
  const [loading, setLoading] = React.useState<boolean>(true);

  const getUnsuccessfulDeliveries = async (
    kindOfTemplate: string,
    startTime: string,
    endTime: string
  ) => {
    try {
      const res = await configuredRequests.GET.getUnsuccessfulDeliverAnalyze(
        kindOfTemplate,
        {
          startTime,
          endTime
        }
      );

      setLoading(false);
      setDataAttribute([
        { name: 'Unsubscribed', value: res.unsubcribed },
        { name: 'Mark As Spam', value: res.markAsSpam },
        { name: 'Invalid Target', value: res.invalidTarget },
      ]);
      setDataChartUnsubcribed(res.mostunSubcribedEmails.slice(0, 5));
      setDataChartReportEmail(res.mostSpamReportEmails.slice(0, 5));
    } catch (err) {
      setLoading(false);
      console.log('error', err);
    }
  };

  React.useEffect(() => {
    const startTime = startDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    const endTime = endDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    getUnsuccessfulDeliveries('unsuccessfulDeliveries', startTime, endTime);
  }, [startDate, endDate]);

  return (
    <div className='communication-container unsuccessful w-300'>
      {loading ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> : (
          <>
            <div className='communication-select-filter'>
              <DateTimePicker
                showDateRange={true}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                getEndDate
              />
            </div>

            <div className='communication-unsuccess-attribute'>
              {dataAttribute.map((item) => {
                return (
                  <AttributeStatus
                    key={'attribute-item-' + item.name}
                    attribute={item}
                  />
                );
              })}
            </div>
            <div className="communication-unsuccess-chart-container">
              <ComponentizedChartBarUnsubscribes data={dataChartUnsubcribed} />
              <ComponentizedChartBarReports data={dataChartReportEmail} />
            </div>
          </>
        )}
    </div>
  );
};

export default Unsuccessful;
