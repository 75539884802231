import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';

import { getComponent, isExistsBasicField } from './form-field-types/helpers';
import { BASIC_FIELDS } from './constants';

export const WrapperErrorDuplicate = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  box-sizing: border-box;
  width: 350px;
  padding: 20px 20px 10px 20px;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);

  p {
    margin: 0 -20px;
    padding-bottom: 20px;
    padding: 0 20px 15px;
    border-bottom: 1px solid rgb(222, 224, 237);

    font-size: 13px;
    line-height: 1.3;
  }

  button {
    display: block;
    min-width: 65px;
    height: 36px;
    padding: 0 10px;
    margin-top: 10px;
    margin-left: auto;

    color: #fff;
    background: rgb(0, 116, 255);
    border-radius: 4px;
    font-size: 13px;
    text-align: center;
    outline: none;
  }
`;

export default class FormItemBuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverCounter: 0,
      errorDuplicate: false,
    };
  }

  handleDragHover = (e, isOver) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.el) return;

    let { hoverCounter } = this.state;

    if (isOver) ++hoverCounter;
    else --hoverCounter;

    if (hoverCounter > 0) this.el.classList.add('over');
    else this.el.classList.remove('over');

    this.setState({ hoverCounter });
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // make sure to remove hover css class
    if (this.el) this.el.classList.remove('over');
    if (this.state.hoverCounter > 0) this.setState({ hoverCounter: 0 });

    let data = e.dataTransfer.getData('text/plain');

    try {
      // attempt to parse data as JSON
      data = JSON.parse(data);

      if (
        this.props.isDragNewItem &&
        isExistsBasicField(
          this.props.versionObject?.fields ?? [],
          data?.display_name ?? ''
        )
      ) {
        this.setState((prevState) => ({ ...prevState, errorDuplicate: true }));
        return;
      }
      if (this.props.isDragNewItem && data?.type_name == 'Document Upload' && this.props.versionObject?.fields?.some(field => field.type_name === 'Document Upload')) {
        this.setState((prevState) => ({ ...prevState, errorDuplicate: true }));
        return;
      }

      delete data.of_option;
      delete data.uuid_option;
    } catch (e) {
      // if JSON parsing fails, leave data as-is
    }

    const move = e.dataTransfer.getData('text/x-move');
    let source = e.dataTransfer.getData('text/x-source');

    source = source && JSON.parse(source);
    this.props.onDropField(data, move, source);
  };

  handleShowError = () => {
    this.setState((prevState) => ({ ...prevState, errorDuplicate: true }));
  };

  renderEmptyCanvas = () => {
    return (
      <div className='empty-canvas'>
        <h3>Start building your Form!</h3>
        <p>Drag and drop fields from the panel on the left to this area.</p>
      </div>
    );
  };

  render() {
    const { versionObject, onNewPage, ...rest } = this.props;
    const { fields } = versionObject;
    const isEmpty = !fields || fields.length === 0;
    let pageCount = 1;

    return (
      <div
        className={`form-item-build-container ${isEmpty ? 'empty' : ''}`}
        ref={(el) => (this.el = el)}
        onDragEnter={(e) => this.handleDragHover(e, true)}
        onDragLeave={(e) => this.handleDragHover(e, false)}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        {isEmpty && this.renderEmptyCanvas()}
        {!isEmpty && <div className='page'>Page 1</div>}
        {fields &&
          fields.map((field) => {
            const props = {
              key: field.uuid,
              field,
              ...rest,
              versionObject,
              isDragNewItem: this.props.isDragNewItem,
              handleShowErrorDuplicate: this.handleShowError,
            };
            field.validationMessage = null;

            if (field.type_name === 'Page Break') props.pageNum = ++pageCount;

            return getComponent(field, props);
          })}
        {!isEmpty && (
          <div className='new-page separator'>
            <span onClick={onNewPage}>+ Add New Page</span>
          </div>
        )}

        <Modal
          open={this.state.errorDuplicate}
          onClose={() =>
            this.setState((prevState) => ({
              ...prevState,
              errorDuplicate: false,
            }))
          }
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <WrapperErrorDuplicate>
            <p>
              This field cannot be duplicated because it has information that needs to be unique. Please use a generic field or remove the previously existing field.
            </p>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  errorDuplicate: false,
                }))
              }
            >
              OK
            </Button>
          </WrapperErrorDuplicate>
        </Modal>
      </div>
    );
  }
}

FormItemBuild.propTypes = {
  versionObject: PropTypes.object,
  onDropField: PropTypes.func,
  activeField: PropTypes.object,
  activeNestedField: PropTypes.object,
  onFieldClick: PropTypes.func,
  onDeleteField: PropTypes.func,
  hoverField: PropTypes.object,
  hoverLocation: PropTypes.string,
  onFieldHover: PropTypes.func,
  onNestedDrop: PropTypes.func,
  onNewPage: PropTypes.func,
  isDragNewItem: PropTypes.bool,
};

FormItemBuild.defaultProps = {
  versionObject: {},
  activeField: null,
  activeNestedField: null,
  hoverField: null,
  hoverLocation: null,
};
