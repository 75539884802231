import React, { useState } from 'react';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import BookingRequestVM from './BookingRequests/BookingRequestVM';
import FormBuilder from './FormBuilder/FormBuilder';
import Share from 'components/Settings/AppointmentSetting/Share/Share';
import styled from 'styled-components';
import AppointmentSetting from 'components/Settings/AppointmentSetting/AppointmentSetting';

const AppointmentBookingWrapper = styled.div`
  margin: 0;

  .form-item-tab-container {
    height: calc(100vh - 123px);
  }
`;

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

const TabContainer = (props: TabContainerProps) => {
  return <Typography component='div'>{props.children}</Typography>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppointmentBookingVM = () => {
  const [activeComponent, setActiveComponent] = useState<number>(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveComponent(newValue);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 1: {
        return (
          <TabContainer id={1}>
            <FormBuilder />
          </TabContainer>
        );
      }
      case 2:
        return (
          <TabContainer id={2}>
            <Share />
          </TabContainer>
        );
      case 3:
        return (
          <TabContainer id={3}>
            <AppointmentSetting />
          </TabContainer>
        );
      default: {
        return (
          <TabContainer id={0}>
            <BookingRequestVM />
          </TabContainer>
        );
      }
    }
  };

  return (
    <>
      <AppointmentBookingWrapper className='template-appointment-email'>
        <AppBar position='static' className='container__table'>
          <Tabs
            value={activeComponent}
            onChange={handleChangeTab}
            indicatorColor='primary'
          >
            <Tab label='Booking Requests' />
            <Tab label='Form Builder' />
            <Tab label='Share' />
            <Tab label='Settings' />
          </Tabs>
        </AppBar>
      </AppointmentBookingWrapper>
      {renderContainer()}
    </>
  );
};

export default AppointmentBookingVM;
