import React, { useContext, useEffect, useMemo } from 'react';
import { FormFieldBase, FormFieldInput } from '.';
import { EditContext } from '../../form-renderer';
import { getFieldProperties } from './helpers';

export const FormFieldGender = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const isAppointmentBooking = useContext(EditContext).isAppointmentBooking;
  const properties = getFieldProperties(field, ['common', 'specific']);

  const options = useMemo(() => {
    return (field.options ?? []).map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
      };
    });
  }, [field]);

  const label =
    field.label ??
    (options.find((option) => option.id === field.value) ?? { label: '' })
      .label;

  useEffect(() => {
    if (field.value && !field.label) {
      field.label = options.find((o) => o.id === field.value)?.label ?? '';
    }
  }, []);

  const handleChange = (e) => {
    onChange(e);
    if (e.target.value) {
      field.label =
        options.find((option) => option.id === e.target.value)?.label ?? '';
    }
  };

  return (
    <FormFieldInput
      field={field}
      options={{
        properties,
        isRenderedField,
        onChange: handleChange,
        value,
        onBlur,
        validationMessage,
        readOnly,
      }}
    >
      {isRenderedField &&
        (readOnly ? (
          <option>
            {/* {options.find((option) => option.id === value)?.label ?? ''} */}
            {label}
            {/* {field.label} */}
          </option>
        ) : (
          options.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.label}
              </option>
            );
          })
        ))}
    </FormFieldInput>
  );
};

FormFieldGender.defaultProps = {
  isRenderedField: false,
  onChange: () => {},
  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldGender);
