/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import 'components/FormBuilder/components/FormBuilder/form-builder.scss';

const handleDragStart = (e, data, setIsDragNewItem) => {
  setIsDragNewItem(true);
  e.stopPropagation();
  e.dataTransfer.setData('text/plain', typeof data !== 'string' ? JSON.stringify(data) : data);
  e.dataTransfer.dropEffect = 'copy';
};

const handleDragEnd = (e, onHover, setIsDragNewItem) => {
  setIsDragNewItem(false);
  e.stopPropagation();
  onHover(null, null);
};

const FieldItem = props => {
  return (
    <div
      className='field-group-item'
      id="form-item-style"
      draggable
      onDragStart={e => handleDragStart(e, props.field, props.setIsDragNewItem)}
      onDragEnd={e => handleDragEnd(e, props.onHover, props.setIsDragNewItem)}
    >
      <span className='grip-handle' /> {props.label}
    </div>
  );
};

FieldItem.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string.isRequired,
  onHover: PropTypes.func,
  setIsDragNewItem: PropTypes.func
};

FieldItem.defaultProps = {
  field: {},
  onHover: () => { },
};

export default FieldItem;
