import styled from 'styled-components';

const TextIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  svg {
    opacity: 0.49;
    height: 1.34rem;
    width: 1.34rem;
    margin-right: 0.5rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { TextIconContainer };
