import { BreedCollection } from './ResponseTypes/Breed';
import { SexCollection } from './ResponseTypes/Sex';
import { UserKindEnum } from 'global/requests/api-route-types';
import { FullPetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import { TaskResponseData } from 'global/requests/ResponseTypes/_Task';
import * as t from 'io-ts';
import {
  AppointmentInfoResponse,
  AppointmentMultipleInfoResponse,
  AppointmentSummaryEntry,
} from './ResponseTypes/Appointments';
import { LoginResponse } from './ResponseTypes/Authentication';
import { DropdownDataGeneralResponseType } from './ResponseTypes/DropdownDataGeneral';
import { EmitterResponseType } from './ResponseTypes/Emitter';
import { EmitterMethodResponseType } from './ResponseTypes/EmitterMethod';
import { GroupDataResponse, GroupInfoResponse } from './ResponseTypes/Groups';
import { GroupSystemAdminResponse } from './ResponseTypes/GroupSystemAdmin';
import {
  PetInfoResponse,
  PetSummaryCollectionResponse,
} from './ResponseTypes/Pets';
import {
  ServiceLogsResponseType,
  SyncerLogsCollectionType,
} from './ResponseTypes/ServiceLogs';
import { SyncerGeneralResponseType } from './ResponseTypes/SyncerGeneral';
import { SyncerSettingGeneralResponseType } from './ResponseTypes/SyncerSettingGeneral';
import {
  TenantInfoResponse,
  TenantSyncerErrorCollectionType,
  TenantUserDataResponse,
} from './ResponseTypes/Tenants';
import {
  UserDataResponse,
  UserSettingObject,
} from './ResponseTypes/UserAdministration';
import {
  AddressEntryData,
  AddressLocationData,
} from './ResponseTypes/_Address';
import { FullPhoneNumberEntryData } from './ResponseTypes/_PhoneNumber';
import { ResetPasswordResponseType } from './ResponseTypes/ResetPassword';
import { PaySettingResponseType } from './ResponseTypes/PaySetting';
import {
  AppointmentTypeResponse,
  AppointmentTypeSetting,
  AppointmentTypeSettingResponseType,
} from './ResponseTypes/AppointmentTypeSetting';
import { OptionDropdownDataResponseType } from './ResponseTypes/DropdownDataType';
import { LeftMenuSubmissionListResponseType } from './ResponseTypes/LeftMenuSubmissionDataType';
import { SpeciesSettingType } from './ResponseTypes/SpeciesSetting';
import {
  TwilioSettingResponseType,
  TwilioSettingType,
} from './ResponseTypes/TwilioSetting';
import { CollectionMangoPhoneType } from './ResponseTypes/MangoSetting';
import { DriverConfigurationResponseType } from './ResponseTypes/Settings';

// We encapsulate all the library specifics here so that outside of this file we can reference types as normal

// Library basics:
// We create objects representing our types, then we extract and export the type from that object.
// The library uses the base object to compare types against an API response, allowing for a run-time typeguard

// Naming convention: (unenforced, but some naming conventions do follow this scheme)
//   We append "Data" to the end of the type declarations for io-ts
//   When extracting a type, name it the io-ts type's name without "Data" appended
//   Any type that represents a full response should end with "Response" for clarity in the Union type (bottom)
//   Required types should start with "Required"
//   Nullable types should start with "Nullable"
//   Intersected types should start with "Full"

// When declaring a type with required and nullable props you must create two types
// Then you intersect the types to get the final type for io-ts, at which point the static type can be extracted
// use t.type for required properties
// use t.partial for optional properties
// use t.union to combine the required and optional properties into one type

enum keyColumnCommunicationTable {
  externalId,
  groupName,
  templateName,
  practiceName,
  sent,
  deliverRate,
  openRate,
  clickRate,
  name,
  phoneNumber,
  email,
  typeTemplate,
  automationLabel,
  phoneNumbers,
  emailAddress,
  requestDate,
  userFullName,
  value,
  date,
  contactName,
  emailStatus,
  contactId,
  automationTriggerKey,
  automationCategoryId,
  link,
  roleName,
  roleStatus,
  rolePermissions,
  userRoles,
  status,
  action,
  time,
  level,
  logger,
  message,
  lastAuth,
  lastSync,
  livePractice,
  deliveryPaused,
  driver,
  version,
  releasedDate,
  releasedNote,
  driverName,
  actionDownload,
  serviceVersion,
  configuratorVersion,
  downloadFile,
  productName,
  invoiceName,
  defaultMonthlyFee,
  agreementName,
  agreementPracticeName,
  viewDetails,
  agreementDetailVersion,
  agreementDetailTotal,
  agreementDetailAccount,
  agreementDetailStatus,
  agreementDetailItems,
  delete,
  sendEmailForDeals,
  stripePaymentLink,
  isSignedForDeal
}

export const TEMP_EXTERNAL_STATUS = t.union([t.number, t.string]);

export type keyColumnFields = keyof typeof keyColumnCommunicationTable;

export type Column = {
  id: keyColumnFields;
  label: string;
  format?: (value: number) => string;
};

export const topClickedLinks = t.partial({
  link: t.string,
  value: t.number,
});

export const topClickedNotTransform = t.partial({
  name: t.string,
  value: t.number,
});

export const DataRecipientsTable = t.partial({
  date: t.string,
  contactName: t.string,
  emailStatus: t.string,
  phoneNumber: t.string,
  emailAddress: t.string,
  contactId: t.string,
});
export type DataRecipientsTable = t.TypeOf<typeof DataRecipientsTable>;

export const TemplateRecipient = t.partial({
  count: t.number,
  items: t.array(DataRecipientsTable),
  totalAvailable: t.number,
});
export type TemplateRecipient = t.TypeOf<typeof TemplateRecipient>;

//Email Performance Response
const RequiredPerformanceTableInfo = t.type({
  templateId: t.string,
  sent: t.number,
  deliverRate: t.number,
  openRate: t.number,
  clickRate: t.number,
});

const NullablePerformanceTableInfo = t.partial({
  templateName: t.string,
});

const FullPerformanceTableInfo = t.intersection([
  RequiredPerformanceTableInfo,
  NullablePerformanceTableInfo,
]);
const PerformanceEmailsSent = t.type({
  count: t.number,
  items: t.array(FullPerformanceTableInfo),
});
export const PerformanceAnalyzeResponse = t.type({
  sent: t.number,
  delivered: t.number,
  opened: t.number,
  clicked: t.number,
  emailsSent: PerformanceEmailsSent,
});

export type PerformanceAnalyzeResponse = t.TypeOf<
  typeof PerformanceAnalyzeResponse
>;

export const ChartUnsuccessfulAnalyzeTab = t.type({
  templateId: t.string,
  name: t.string,
  value: t.number,
});
export type ChartUnsuccessfulAnalyzeTab = t.TypeOf<
  typeof ChartUnsuccessfulAnalyzeTab
>;

export const UnsuccessfulDeliverAnalyzeResponse = t.type({
  unsubcribed: t.number,
  markAsSpam: t.number,
  invalidTarget: t.number,
  mostunSubcribedEmails: t.array(ChartUnsuccessfulAnalyzeTab),
  mostSpamReportEmails: t.array(ChartUnsuccessfulAnalyzeTab),
});

export type UnsuccessfulDeliverAnalyzeResponse = t.TypeOf<
  typeof UnsuccessfulDeliverAnalyzeResponse
>;
//

export const dataID = t.type({
  id: t.string,
  label: t.string,
  minWidth: t.number,
});

const NoteData = t.type({
  noteId: t.string,
  name: t.string,
  content: t.string,
  createdAt: t.string,
});
export type NoteData = t.TypeOf<typeof NoteData>;

export const AttachmentData = t.type({
  attachmentId: t.string,
  // attachmentKind: t.string,
  contentType: t.string,
  creationDate: t.string,
  description: t.string,
  name: t.string,
  url: t.string,
  urlThumbnail: t.string,
});
export type AttachmentData = t.TypeOf<typeof AttachmentData>;

export const AppointmentNotesResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  notes: t.array(NoteData),
});

export type AppointmentNotesResponse = t.TypeOf<
  typeof AppointmentNotesResponse
>;

export const AppointmentAttachmentsResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  attachments: t.array(AttachmentData),
});

export type AppointmentAttachmentsResponse = t.TypeOf<
  typeof AppointmentAttachmentsResponse
>;

// Setting General Response
export const DeliveryHours = t.type({
  enabled: t.boolean,
  dayName: t.string,
  fromTimeUtc: t.string,
  toTimeUtc: t.string,
});

const NotificationsRequire = t.type({
  notificationUsers: t.array(t.string),
});

const NotificationsNullable = t.partial({
  sendEmailNotificationsWhenTextReceived: t.boolean,
  sendEmailNotificationsWhenAppointmentConfirmed: t.boolean,
  sendEmailNotificationsWhenOabRequested: t.boolean,
  sendDesktopNotificationsForNewMessages: t.boolean,
  sendDesktopNotificationsForNewFormSubmitted: t.boolean,
  sendDesktopNotificationsForOabRequested: t.boolean,
  sendEmailNotificationsWhenNewFormSubmitted: t.boolean,
});

const SystemNotificationsNullable = t.partial({
  sendEmailNotificationsWhenSyncerStops: t.boolean,
});

const Notifications = t.intersection([
  NotificationsNullable,
  NotificationsRequire,
  SystemNotificationsNullable,
]);

export const NotificationsResponse = t.type({
  namespace: t.string,
  settings: Notifications,
});

export type NotificationsResponse = t.TypeOf<typeof NotificationsResponse>;

export const BrandColors = t.type({
  backgroundColor: t.string,
  navigationTextColor: t.string,
  contentBackgroundColor: t.string,
  footerBackgroundColor: t.string,
  buttonOutsideColor: t.string,
  color1: t.string,
  color2: t.string,
  stripeColor: t.string,
  logoTextColor: t.string,
  contentTextColor: t.string,
  buttonInsideColor: t.string,
});

export const GeneralSetting = t.type({
  companyName: t.string,
  websiteUrl: t.string,
  websiteUseInFooters: t.boolean,
  emailReplyToAddress: t.string,
  timeZone: t.string,
  officeNumber: t.string,
  groupAppointmentMessagesByFamily: t.boolean,
  useValidatedPhoneNumbers: t.boolean,
  address: AddressLocationData,
});

export const GeneralSettings = t.type({
  namespace: t.string,
  settings: GeneralSetting,
});

export const ExternalLinkSetting = t.type({
  buttonText: t.string,
  externalUrl: t.string,
  isEnable: t.boolean,
});

export const ExternalLink = t.type({
  namespace: t.string,
  settings: ExternalLinkSetting,
});

export type GeneralSettingResponse = t.TypeOf<typeof GeneralSettings>;
export type ExternalLinkResponse = t.TypeOf<typeof ExternalLink>;
export type ExternalLinkRequest = t.TypeOf<typeof ExternalLinkSetting>;
export const DeliveryData = t.type({
  active: t.boolean,
  day: t.string,
  startTime: t.string,
  endTime: t.string,
});

export type DeliveryData = t.TypeOf<typeof DeliveryData>;

export const SettingData = t.partial({
  websiteUrl: t.string,
  websiteUseInFooters: t.boolean,
  emailReplyToAddress: t.string,
  timeZone: t.string,
  adjustDaylightTimeAutomatically: t.boolean,
  timeZoneOffset: t.string,
  timeZoneId: t.string,
  groupAppointmentMessagesByFamily: t.boolean,
  useValidatedPhoneNumbers: t.boolean,
  deliveryHours: t.array(DeliveryData),
  automationEvent: t.boolean,
  allowEmailSending: t.boolean,
  allowSMSSending: t.boolean,
  allowCallSending: t.boolean,
  allowPostalSending: t.boolean,
});

export type SettingData = t.TypeOf<typeof SettingData>;

export const SystemSettingResponse = t.type({
  namespace: t.string,
  settings: SettingData,
});

export type SystemSettingResponseUpdated = t.TypeOf<typeof SettingData>;

export type SystemSettingResponse = t.TypeOf<typeof SystemSettingResponse>;

export const TimeZoneData = t.partial({
  name: t.string,
  offset: t.string,
  locations: t.array(t.string),
});

export type TimeZoneData = t.TypeOf<typeof TimeZoneData>;

export const TimezoneResponse = t.type({
  timeZones: t.array(TimeZoneData),
});
export type TimezoneResponse = t.TypeOf<typeof TimezoneResponse>;

export const BrandColorsResponse = t.type({
  namespace: t.string,
  settings: BrandColors,
});

export type BrandColorsResponse = t.TypeOf<typeof BrandColorsResponse>;

export const CallInputOption = t.partial({
  inputNumber: t.string,
  tokenKey: t.string,
});

export type CallInputOption = t.TypeOf<typeof CallInputOption>;

export const TemplateData = t.partial({
  name: t.string,
  frontSide: t.string,
  backSide: t.string,
  previewContent: t.string,
  size: t.string,
  categoryKind: t.string,
  templateKind: t.string,
  // targetUserTypeIds: t.array(t.string),
  templateSubject: t.string,
  templateBody: t.string,
  templateId: t.string,
  isLeaveMessage: t.boolean,
  templateMachineBody: t.string,
  callInputOptions: t.array(CallInputOption),
  onOnlyOnceEvery: t.boolean,
  duration: t.number,
  unitOfDuration: t.string,
});

export type TemplateData = t.TypeOf<typeof TemplateData>;

export const TargetUserType = t.partial({
  TargetUserTypeId: t.string,
  Name: t.string,
  Order: t.number,
});

export type TargetUserType = t.TypeOf<typeof TargetUserType>;

export const TemplateResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  items: t.array(TemplateData),
  // targetUserTypes: t.array(TargetUserType),
});
export type TemplateResponse = t.TypeOf<typeof TemplateResponse>;

export const TemplateDataAnalyze = t.type({
  requestDate: t.string,
  userFullName: t.string,
  phoneNumber: t.string,
  email: t.string,
  userId: t.string,
});
export const TemplateAnalyzeResponse = t.type({
  count: t.number,
  // offset: t.number,
  totalAvailable: t.number,
  items: t.array(TemplateDataAnalyze),
});
export type TemplateAnalyzeResponse = t.TypeOf<typeof TemplateAnalyzeResponse>;

export const templateKind = t.type({
  name: t.string,
  value: t.string,
});

export const ListCategoryResponse = t.partial({
  categoryName: t.string,
  categoryKind: t.string,
  templateKinds: t.array(templateKind),
});
export type ListCategoryResponse = t.TypeOf<typeof ListCategoryResponse>;
// User Interaction Types: Email & SMS

const RequiredSharedCommunication = t.type({
  messageId: t.string,
  content: t.string,
  sender: t.string,
  status: t.string,
  receiver: t.string,
  createdDate: t.string,
  createdBy: t.string,
  sentDate: t.string,
  kind: t.number,
});

const OptionalSharedCommunication = t.partial({
  refMessageId: t.string,
  templateId: t.string,
  templateName: t.string,
  scheduleDate: t.string,
  messageKind: t.string,
  contactId: t.string,
  canceledDate: t.string,
  subject: t.string,
  content: t.string,
  mediaUrls: t.Array,
  notes: t.string,
});

const RequiredEmailProperties = t.type({
  subject: t.string,
});

export const FullEmailEntry = t.intersection([
  RequiredEmailProperties,
  RequiredSharedCommunication,
  OptionalSharedCommunication,
]);
export type EmailEntry = t.TypeOf<typeof FullEmailEntry>;
export const UserDetailEmailResponseData = t.array(FullEmailEntry);
export type UserDetailEmailResponse = t.TypeOf<
  typeof UserDetailEmailResponseData
>;

export const FullSMSEntry = t.intersection([
  RequiredSharedCommunication,
  OptionalSharedCommunication,
]);
export type SMSEntry = t.TypeOf<typeof FullSMSEntry>;
export const UserDetailSMSResponseData = t.array(FullSMSEntry);
export type UserDetailSMSResponse = t.TypeOf<typeof UserDetailSMSResponseData>;

export type UserInteractionEntryUnionType = EmailEntry | SMSEntry;
// export type UserInteractionResponseUnionType = (UserDetailEmailResponse | UserDetailSMSResponse);
export type UserInteractionResponseUnionType = UserInteractionEntryUnionType[];

//
export const TokenItem = t.type({
  name: t.string,
  value: t.string,
  description: t.string,
});

export const TokenData = t.type({
  categoryId: t.string,
  categoryName: t.string,
  tokens: t.array(TokenItem),
});

export const PersonalizationSetting = t.type({
  personalizationTokens: t.array(TokenData),
});

export const PersonalizationTokens = t.type({
  namespace: t.string,
  settings: PersonalizationSetting,
});
export const PersonalizationTokensResponse = t.type({
  personalizationTokens: t.array(PersonalizationTokens),
});
export type PersonalizationTokensResponse = t.TypeOf<
  typeof PersonalizationTokensResponse
>;

export const EngagementOverTime = t.type({
  date: t.string,
  opened: t.number,
  clicked: t.number,
});

export const openByPlatform = t.type({
  name: t.string,
  value: t.number,
});

export const openByDeviceType = t.type({
  name: t.string,
  value: t.number,
});

export const TemplatePerformanceDetailResponse = t.type({
  sent: t.number,
  delivered: t.number,
  opened: t.number,
  clicked: t.number,
  unsuccessfulDeliveries: t.number,
  engagementOverTime: t.array(EngagementOverTime),
  // openByPlatform: t.array(openByPlatform),
  // openByDeviceType: t.array(openByDeviceType),
  topClickedLinks: t.array(topClickedNotTransform),
});
export type TemplatePerformanceDetailResponse = t.TypeOf<
  typeof TemplatePerformanceDetailResponse
>;

export const DataTypeOpenByPlatformAndDevice = t.type({
  openByPlatform: t.array(openByPlatform),
  openByDeviceType: t.array(openByDeviceType),
});
export type DataTypeOpenByPlatformAndDevice = t.TypeOf<
  typeof DataTypeOpenByPlatformAndDevice
>;

const CommunicationTemplates = t.type({
  triggerTemplateId: t.string,
  messageKind: t.string,
  messageKindText: t.string,
  ordinal: t.number,
  templateId: t.string,
  templateName: t.string,
});

export const AutomationTrigger = t.type({
  automationTriggerEventId: t.string,
  enabled: t.boolean,
  appointmentTypeApplyKind: t.string,
  appointmentTypes: t.array(AppointmentTypeResponse),
  petSpecies: t.array(SpeciesSettingType),
  allowSendOutsideDeliveryHours: t.boolean,
  categoryKind: t.string,
  automationCategoryId: t.string,
  automationTriggerKey: t.string,
  automationTriggerId: t.string,
  communicationTemplates: t.array(CommunicationTemplates),
  timeQuantity: t.number,
  timeUnit: t.string,
  scheduleKind: t.string,
  timeUnitText: t.string,
  scheduleKindText: t.string,
  automationTriggerName: t.string,
});
export type AutomationTrigger = t.TypeOf<typeof AutomationTrigger>;

export const AutomationTriggerList = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  items: t.array(AutomationTrigger),
});
export type AutomationTriggerList = t.TypeOf<typeof AutomationTriggerList>;

export const CreateAutomationTriggerEventResponse = t.partial({
  automationTriggerEventId: t.string,
  enabled: t.boolean,
  allowSendOutsideDeliveryHours: t.boolean,
  categoryKind: t.string,
  automationTriggerKey: t.string,
  automationTriggerName: t.string,
  automationCategoryId: t.string,
  automationTriggerId: t.string,
  timeQuantity: t.number,
  timeUnit: t.string,
  timeUnitText: t.string,
  scheduleKind: t.string,
  scheduleKindText: t.string,
  communicationTemplates: t.array(CommunicationTemplates),
});

export type CreateAutomationTriggerEventResponse = t.TypeOf<
  typeof CreateAutomationTriggerEventResponse
>;

export const CheckIncallResponse = t.type({
  isInCall: t.boolean,
});
export type CheckIncallResponse = t.TypeOf<typeof CheckIncallResponse>;

const RolePermissionType = t.partial({
  screenId: t.string,
  screenName: t.string,
});

export type RolePermissionType = t.TypeOf<typeof RolePermissionType>;

export const Role = t.partial({
  roleId: t.string,
  roleName: t.string,
  roleStatus: t.string,
  roleDescription: t.string,
  createdDate: t.string,
  rolePermissions: t.array(RolePermissionType),
});
export type Role = t.TypeOf<typeof Role>;

export const RoleResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  roles: t.array(Role),
});
export type RoleResponse = t.TypeOf<typeof RoleResponse>;

export const ContactMessages = t.partial({
  id: t.string,
  externalStatus: t.number,
  pulseUserStatus: t.number,
  name: t.string,
  lastName: t.string,
  isMessageRead: t.boolean,
  latestMesageId: t.string,
  latestMessage: t.string,
  latestMessageDate: t.string,
  searchedMesageId: t.string,
  searchedMessage: t.string,
  searchedMessageDate: t.string,
  phoneNumbers: t.array(FullPhoneNumberEntryData),
  userSettings: t.array(UserSettingObject),
});
export type ContactMessages = t.TypeOf<typeof ContactMessages>;

export const ContactListResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  totalUnreads: t.number,
  users: t.array(ContactMessages),
});
export type ContactListResponse = t.TypeOf<typeof ContactListResponse>;

export const apiCallsByTime = t.partial({
  api_Route: t.string,
  callDateTime: t.string,
  count: t.number,
  locationCode: t.string,
  locationName: t.string,
  tenantId: t.string,
  driver: t.string,
  id: t.string,
});
export type apiCallsByTime = t.TypeOf<typeof apiCalls>;

export const apiCalls = t.partial({
  apiCallsByTime: t.array(apiCallsByTime),
});
export type apiCalls = t.TypeOf<typeof apiCalls>;

export const Version = t.partial({
  versionId: t.string,
  versionName: t.string,
  releasedDate: t.string,
  downloadLink: t.string,
});

export const DriversResponse = t.partial({
  driverId: t.string,
  driverName: t.string,
  releasedDate: t.string,
  downloadLink: t.string,
  versionId: t.string,
  versionName: t.string,
  versions: t.array(Version),
  configurationField: t.string,
});
export type DriversResponse = t.TypeOf<typeof DriversResponse>;

export const Drivers = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  methods: t.array(DriversResponse),
});

export type Drivers = t.TypeOf<typeof Drivers>;

export const ServiceResponse = t.partial({
  id: t.string,
  version: t.string,
  releasedDate: t.string,
  downloadLink: t.string,
  // versionId: t.string,
  // versionName: t.string,
  // versions: t.array(Version),
});
export type ServiceResponse = t.TypeOf<typeof ServiceResponse>;

export const Services = t.type({
  count: t.number,
  totalAvailable: t.number,
  items: t.array(ServiceResponse),
});

export type Services = t.TypeOf<typeof Services>;

export const ConfiguratorVersion = t.partial({
  versionId: t.string,
  versionName: t.string,
  releasedDate: t.string,
  downloadLink: t.string,
  releaseNotes: t.string,
});

export const ConfiguratorResponse = t.partial({
  configuratorId: t.string,
  releasedDate: t.string,
  downloadLink: t.string,
  releaseNotes: t.string,
  versionId: t.string,
  versionName: t.string,
  versions: t.array(ConfiguratorVersion),
});

export type ConfiguratorResponse = t.TypeOf<typeof ConfiguratorResponse>;

export const Configurators = t.type({
  count: t.number,
  totalAvailable: t.number,
  offset: t.number,
  methods: t.array(ConfiguratorResponse),
});

export type Configurators = t.TypeOf<typeof Configurators>;

export const apiCallsResponse = t.type({
  startDateTime: t.string,
  endDateTime: t.string,
  lastUpdateDT: t.string,
  apiCalls: t.array(apiCalls),
});
export type apiCallsResponse = t.TypeOf<typeof apiCallsResponse>;

export const Screen = t.partial({
  screenId: t.string,
  screenName: t.string,
});
export type Screen = t.TypeOf<typeof Screen>;

export const ScreenResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  screens: t.array(Screen),
});
export type ScreenResponse = t.TypeOf<typeof ScreenResponse>;

const LocationCoordinates = t.type({
  lat: t.number,
  lon: t.number,
  tag: t.string,
});

const AdditionalDetailsObject = t.partial({
  likes: t.string,
  'random-detail': t.string,
  'random-object': LocationCoordinates,
});

const GroupNameDetails = t.partial({
  userPersonalDetailsId: t.string,
  givenName: t.string,
  lastName: t.string,
});

export const Group = t.partial({
  tenantGroupId: t.string,
  name: t.string,
  status: t.string,
  practiceEmailAddress: t.string,
  practicePhoneNumbers: t.array(FullPhoneNumberEntryData),
  addresses: t.array(AddressEntryData),
  practicePersonalDetails: t.array(GroupNameDetails),
  isSinglePractice: t.boolean,
  additionalDetails: AdditionalDetailsObject,
});
export type Group = t.TypeOf<typeof Group>;

export const GroupResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  roles: t.array(Group),
});
export type GroupResponse = t.TypeOf<typeof GroupResponse>;

export const PhoneNumber = t.partial({
  phoneNumberId: t.string,
  number: t.string,
  extension: t.unknown,
  isPrimary: t.boolean,
  isValidated: t.boolean,
  kind: t.string,
});

export const Address = t.partial({
  userAddressId: t.string,
  address: t.partial({
    addressLine1: t.string,
    addressLine2: t.string,
    city: t.string,
    state: t.string,
    postalCode: t.string,
  }),
  isPrimary: t.boolean,
  kind: t.string,
});

export type AddressType = t.TypeOf<typeof Address>;

export const TenantResposne = t.partial({
  tenantId: t.string,
  tenantName: t.string,
  tenantGroupId: t.string,
  status: t.string,
  micrositeName: t.string,
  emailAddress: t.string,
  name: t.array(GroupNameDetails),
  phoneNumbers: t.array(PhoneNumber),
  practicePhoneNumbers: t.array(PhoneNumber),
  addresses: t.array(Address),
  emailReplyToAddress: t.string,
  useURLInFooter: t.boolean,
  webUrl: t.string,
  accountReviewLink: t.string,
  createdBy: t.string,
  createdAt: t.string,
  customerSource: t.string,
  vetHeroStatus: t.string,
  usePhoneNumberValidation: t.boolean,
  postalProvider: t.string,
});

export type TenantResposne = t.TypeOf<typeof TenantResposne>;

export const DriverVersion = t.partial({
  versionId: t.string,
  versionName: t.string,
});

export const uploadDriverResponse = t.partial({
  url: t.string,
  version: t.string,
  releasedDate: t.string,
  configurationField: t.string,
});

export type uploadDriverResponse = t.TypeOf<typeof uploadDriverResponse>;

export const uploadServiceResponse = t.partial({
  downloadLink: t.string,
  version: t.string,
  releasedDate: t.string,
});

export type uploadServiceResponse = t.TypeOf<typeof uploadServiceResponse>;

export const uploadConfiguratorResponse = t.partial({
  downloadLink: t.string,
  versionName: t.string,
  releasedDate: t.string,
});

export type uploadConfiguratorResponse = t.TypeOf<
  typeof uploadConfiguratorResponse
>;

export const uploadImageResponse = t.partial({
  url: t.string,
});

export type uploadImageResponse = t.TypeOf<typeof uploadImageResponse>;

export const Driver = t.partial({
  driverId: t.string,
  driverName: t.string,
  versions: t.array(DriverVersion),
});

export const SyncerSortOrderBy = t.partial({
  name: t.string,
  value: t.string,
});

export const SyncerGroupPractice = t.partial({
  groupId: t.string,
  groupName: t.string,
});

export const SyncerFilterOption = t.partial({
  drivers: t.array(Driver),
  sortBys: t.array(SyncerSortOrderBy),
  sortOrders: t.array(SyncerSortOrderBy),
  groups: t.array(SyncerGroupPractice),
});

export const SyncerFilterForm = t.partial({
  driver: t.string,
  driverVersion: t.string,
  sortBy: t.string,
  sortOrder: t.string,
  group: t.string,
  livePractice: t.string,
  deliveryPaused: t.string,
});

export type SyncerFilterFormType = t.TypeOf<typeof SyncerFilterForm>;

export type SyncerFilterOptionType = t.TypeOf<typeof SyncerFilterOption>;

export const SyncerPractice = t.partial({
  tenantName: t.string,
  tenantId: t.string,
  lastAuth: t.string,
  lastSync: t.string,
  livePractice: t.string,
  deliveryPasused: t.string,
  driverName: t.string,
  driverVersion: t.string,
});

export type SyncerPracticeType = t.TypeOf<typeof SyncerPractice>;

export const SyncerPractices = t.partial({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  driverTenants: t.array(SyncerPractice),
});

export type SyncerPracticesType = t.TypeOf<typeof SyncerPractices>;

const RequiredSyncerStopPage = t.type({
  groupId: t.string,
  tenantId: t.string,
});

export const SyncerStopPage = t.intersection([
  SyncerPractice,
  RequiredSyncerStopPage,
]);

export const CollectionSyncerStopPage = t.type({
  count: t.number,
  totalAvailable: t.number,
  driverTenants: t.array(SyncerStopPage),
});

export type SyncerStopPageType = t.TypeOf<typeof SyncerStopPage>;
export type CollectionSyncerStopPageType = t.TypeOf<
  typeof CollectionSyncerStopPage
>;

export const ListTenantResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  tenants: t.array(TenantResposne),
});

export const GroupInfo = t.partial({
  tenantGroupId: t.string,
  name: t.string,
  status: t.string,
  addresses: t.array(Address),
  practicePersonalDetails: t.array(GroupNameDetails),
  practiceEmailAddress: t.string,
  practicePhoneNumbers: t.array(PhoneNumber),
  isSinglePractice: t.boolean,
});

export type GroupInfoType = t.TypeOf<typeof GroupInfo>;

export type ListTenantResponse = t.TypeOf<typeof ListTenantResponse>;

// union type for all possible response types.
// generic request functions expect the response type to be included here.
export type ValidAxiosResponses =
  | SexCollection
  | BreedCollection
  | LoginResponse
  | UserDataResponse
  | GroupInfoResponse
  | SyncerLogsCollectionType
  | CollectionMangoPhoneType
  | GroupDataResponse
  | TenantSyncerErrorCollectionType
  | TenantUserDataResponse
  | TenantInfoResponse
  | AppointmentInfoResponse
  | PetInfoResponse
  | AppointmentMultipleInfoResponse
  | AppointmentSummaryEntry
  | AppointmentNotesResponse
  | AppointmentAttachmentsResponse
  | GeneralSettingResponse
  | SystemSettingResponse
  | TimezoneResponse
  | BrandColorsResponse
  | NotificationsResponse
  | TemplateResponse
  | TemplateAnalyzeResponse
  | PerformanceAnalyzeResponse
  | UnsuccessfulDeliverAnalyzeResponse
  | EmailAnalyticResponse
  | UserDetailEmailResponse
  | UserDetailSMSResponse
  | TemplateData
  | PerformingTemplateResponseData
  | PersonalizationTokensResponse
  | PetSummaryCollectionResponse
  | DataRecipientsTable
  | AutomationSettingResponse
  | AutomationSetting
  | TemplateRecipient
  | AutomationTrigger
  | AutomationTriggerList
  | TemplatePerformanceDetailResponse
  | CreateAutomationTriggerEventResponse
  | DeliverApproximatelyResponse
  | CheckIncallResponse
  | RoleResponse
  | Role
  | GroupSystemAdminResponse
  | Group
  | GroupResponse
  | TenantResposne
  | ListTenantResponse
  | ServiceLogsResponseType
  | apiCallsResponse
  | GroupInfoType
  | SyncerFilterOptionType
  | SyncerPracticesType
  | EmitterResponseType
  | EmitterMethodResponseType
  | SyncerGeneralResponseType
  | DropdownDataGeneralResponseType
  | SyncerSettingGeneralResponseType
  | ResetPasswordResponseType
  | DriversResponse
  | Drivers
  | uploadDriverResponse
  | uploadImageResponse
  | uploadServiceResponse
  | ContactListResponse
  | PaySettingResponseType
  | DataTypeOpenByPlatformAndDevice
  | ListCategoryResponse
  | AppointmentTypeSettingResponseType
  | OptionDropdownDataResponseType
  | LeftMenuSubmissionListResponseType
  | TwilioSettingResponseType
  | DriverConfigurationResponseType;

const PhoneNumberUserSettingData = t.type({
  number: t.string,
  isPrimary: t.boolean,
  kind: t.string,
  isValidated: t.boolean,
});

const RequiredUsersSetting = t.type({
  id: t.string,
  enabled: t.boolean,
  externalStatus: TEMP_EXTERNAL_STATUS,
  pulseUserStatus: t.string,
  userKind: t.keyof(UserKindEnum),
});

const NullableUserSetting = t.partial({
  emailAddress: t.string,
  name: t.string,
  pets: t.array(FullPetInfoResponse),
  phoneNumbers: t.array(PhoneNumberUserSettingData),
  tasks: t.array(TaskResponseData),
});
export const FullUserSettingResponse = t.intersection([
  RequiredUsersSetting,
  NullableUserSetting,
]);

export const EmailAnalyticResponse = t.type({
  sent: t.number,
  delivered: t.number,
  opened: t.number,
});

export type EmailAnalyticResponse = t.TypeOf<typeof EmailAnalyticResponse>;

const PerformingTemplateResponse = t.type({
  templateName: t.string,
  sent: t.number,
  deliverRate: t.number,
  openRate: t.number,
  templateId: t.string,
});

export const PerformingTemplateResponseData = t.array(
  PerformingTemplateResponse
);

export type PerformingTemplateResponseData = t.TypeOf<
  typeof PerformingTemplateResponseData
>;

export const AutomationTriggerData = t.partial({
  automationCategoryId: t.string,
  categoryKind: t.string,
  id: t.string,
  name: t.string,
  automationTriggerKey: t.string,
});

export type AutomationTriggerData = t.TypeOf<typeof AutomationTriggerData>;

export const AutomationTriggerResponse = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  items: t.array(AutomationTriggerData),
});
export type AutomationTriggerResponse = t.TypeOf<
  typeof AutomationTriggerResponse
>;

const AutomationSetting = t.partial({
  isEnable: t.boolean,
  filterReminderByAppointment: t.boolean,
});

export type AutomationSetting = t.TypeOf<typeof AutomationSetting>;

export const AutomationSettingResponse = t.type({
  namespace: t.string,
  settings: AutomationSetting,
});
export type AutomationSettingResponse = t.TypeOf<typeof AutomationSettingResponse>;

const DeliverApproximatelyItemData = t.partial({
  name: t.string,
  value: t.number,
});
export type DeliverApproximatelyItemData = t.TypeOf<
  typeof DeliverApproximatelyItemData
>;

const ScheduledKindsData = t.partial({
  name: t.string,
  value: t.string,
});
export type ScheduledKindsData = t.TypeOf<typeof ScheduledKindsData>;

export const DeliverApproximatelyResponse = t.type({
  definedValues: t.array(DeliverApproximatelyItemData),
  definedMinuteValues: t.array(DeliverApproximatelyItemData),
  unitKinds: t.array(ScheduledKindsData),
  scheduledKinds: t.array(ScheduledKindsData),
  appointmentTypes: t.array(ScheduledKindsData),
  messageKinds: t.array(ScheduledKindsData),
});
export type DeliverApproximatelyResponse = t.TypeOf<
  typeof DeliverApproximatelyResponse
>;

// STATIC TYPE -- NOT A RESPONSE TYPE
const RequireOptionFilter = t.type({
  label: t.string,
  value: t.string,
});
const OptionalOptionFilter = t.partial({
  description: t.string,
  lastName: t.string,
  emailAddress: t.string,
  petName: t.string,
});
const OptionFilterDropdown = t.intersection([
  RequireOptionFilter,
  OptionalOptionFilter,
]);
export type OptionFilterDropdownObject = t.TypeOf<typeof OptionFilterDropdown>;

// STATIC TYPE -- NOT A RESPONSE TYPE
const AttributeStatus = t.partial({
  name: t.string,
  value: t.number,
  ratio: t.string,
});
export type AttributeStatusResponse = t.TypeOf<typeof AttributeStatus>;

// STATIC TYPES -- NOT USED BY ANY RESPONSE TYPE

const DataTableEmailSent = t.partial({
  templateName: t.string,
  sent: t.number,
  deliverRate: t.number,
  openRate: t.number,
  clickRate: t.number,
  id: t.string,
});
const DataTableSpecie = t.partial({
  name: t.string,
  externalId: t.string,
});
const DataTableEmailTemplate = t.partial({
  name: t.string,
  typeTemplate: t.string,
  automationLabel: t.string,
  delete: t.string,
});
const AutomationType = t.partial({
  name: t.string,
  automationTriggerKey: t.string,
  automationCategoryId: t.string,
});
const UserSettingType = t.partial({
  id: t.string,
  pulseUserStatus: t.string,
  name: t.string,
  phoneNumbers: t.string,
  emailAddress: t.string,
  userRoles: t.string,
});
const DataTableAnalyzeTemplate = t.partial({
  requestDate: t.string,
  userFullName: t.string,
  phoneNumber: t.string,
  email: t.string,
});
const DataRoleTable = t.partial({
  roleId: t.string,
  roleName: t.string,
  roleStatus: t.string,
  roleDescription: t.string,
  createdDate: t.string,
  rolePermissions: t.array(RolePermissionType),
});

const groupsTable = t.partial({
  groupName: t.string,
  emailAddress: t.string,
  phoneNumbers: t.string,
  status: t.string,
});

// STATIC TYPE -- NOT A RESPONSE TYPE
export const FullDataTableCustom = t.intersection([
  DataTableEmailSent,
  DataTableEmailTemplate,
  AutomationType,
  UserSettingType,
  DataTableAnalyzeTemplate,
]);

const practiceTable = t.partial({
  practiceName: t.string,
  emailAddress: t.string,
  phoneNumbers: t.string,
  status: t.string,
  action: t.string,
});

const DataTabService = t.partial({
  time: t.string,
  level: t.string,
  logger: t.string,
  message: t.string,
});

const syncerPracticeTable = t.partial({
  practiceName: t.string,
  lastAuth: t.string,
  lastSync: t.string,
  livePractice: t.string,
  deliveryPaused: t.string,
  driver: t.string,
  version: t.string,
  id: t.string,
});
const TenantDataTable = t.partial({
  practiceId: t.string,
  practiceName: t.string,
  emailAddress: t.string,
  phoneNumbers: t.any,
  status: t.string,
  action: t.string,
});

const driverTable = t.partial({
  driverId: t.string,
  driverName: t.string,
  releasedDate: t.string,
  version: t.string,
  actionDownload: t.string,
});

const serviceTable = t.partial({
  id: t.string,
  releasedDate: t.string,
  serviceVersion: t.string,
  actionDownload: t.string,
});

const configuratorTable = t.partial({
  id: t.string,
  releasedDate: t.string,
  configuratorVersion: t.string,
  downloadFile: t.string,
  releasedNote: t.string,
});

const ProductSettingType = t.partial({
  productId: t.string,
  invoiceName: t.string,
  productName: t.string,
  defaultMonthlyFee: t.number,
  isPackageProduct: t.boolean,
});

const AgreementDataTable = t.partial({
  id: t.string,
  agreementName: t.string,
  agreementPracticeName: t.string,
  viewDetails: t.string,
});

const AgreementDetailDataTable = t.partial({
  agreementDetailVersion: t.string,
  agreementDetailTotal: t.string,
  agreementDetailAccount: t.string,
  agreementDetailStatus: t.string,
  agreementDetailItems: t.array(t.string),
});

export const GroupAdminDataTable = t.intersection([
  practiceTable,
  groupsTable,
  ProductSettingType,
  AgreementDataTable,
]);

export const SyncerAdminDataTable = t.intersection([
  syncerPracticeTable,
  driverTable,
  serviceTable,
  configuratorTable,
]);

export const SystemAdminDataTable = t.intersection([
  GroupAdminDataTable,
  SyncerAdminDataTable,
  AgreementDetailDataTable,
]);

export const UserDataCustom = t.intersection([
  DataRoleTable,
  topClickedLinks,
  DataTabService,
  DataRecipientsTable,
]);

// STATIC TYPE -- NOT A RESPONSE TYPE
export const DataTableCommon = t.intersection([
  FullDataTableCustom,
  UserDataCustom,
  SystemAdminDataTable,
  TenantDataTable,
  DataTableSpecie,
]);

// STATIC TYPE -- NOT A RESPONSE TYPE
export type FullDataTableCustomType = t.TypeOf<typeof DataTableCommon>;
