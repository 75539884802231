import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavLinkProps } from 'types/NavigationT';
import { setTabActiveLocalStorage, getTabActiveLocalStorage } from 'global/localStorage/tabActive';

// potentially useful down the line for custom formatting of individual link elements
// type ListItemProps = {
//   path: string,
//   name: string,
//   listItemClassName: string,
//   linkClassName: string
// };

type ListProps = {
  listItemData: NavLinkProps[]
};

const listItemClassName = "title__nav-item";
const ulClassName = "title__nav-list";

const resetActiveTab = (next_link: string) => {
  const tabActive = getTabActiveLocalStorage();
  if (tabActive) {
    // @ts-ignore
    if (tabActive.previous_link !== next_link) {
      setTabActiveLocalStorage(next_link, 0, 0);
    }
  } else {
    setTabActiveLocalStorage(next_link, 0, 0);
  }
}

const renderListItem = (listItem: NavLinkProps, listItemIndex: number) => {

  return (
    <li key={"list-item-" + listItemIndex} className={listItemClassName}>
      {
        <NavLink exact to={listItem.linkTo} className={"title__nav-lnk"} activeClassName={"selected"}
          onClick={() => resetActiveTab(listItem.linkTo)}
        >
          {listItem.innerText}
        </NavLink>
      }
    </li>
  )
};

const List = (props: ListProps): JSX.Element => {
  return (
    <ul className={ulClassName}>
      {
        props.listItemData.length
          ? props.listItemData.map(renderListItem)
          : null /*error handling*/
      }
    </ul>
  );
};

export default List;
