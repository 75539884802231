import React from 'react';
import Grid from '@material-ui/core/Grid';
import { GridJustification } from '@material-ui/core';

import { StyledButton } from './styled-components';

export interface BorderlessButtonProps {
  title: string | React.ReactElement;
  icon?: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  'data-testid'?: string;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  justifyContent?: GridJustification;
}

const BorderlessButton = (props: BorderlessButtonProps): React.ReactElement => {
  const {
    title,
    onClick,
    disabled = false,
    icon,
    'data-testid': dataTestId,
    style = {},
    size = 'medium',
    justifyContent = 'center',
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      style={style}
      size={size}
    >
      <Grid container justify={justifyContent}>
        {icon && (
          <Grid item data-testid='display-icon'>
            {icon}
          </Grid>
        )}
        <Grid item>
          <span>{title}</span>
        </Grid>
      </Grid>
    </StyledButton>
  );
};

export default BorderlessButton;
