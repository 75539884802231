/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { dataStateOfUS } from 'global/localStorage/dataStateOfUS';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Group } from 'global/requests/ResponseTypes';
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { phonePattern } from 'utils/regex';
import * as Yup from 'yup';

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const UserValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  status: Yup.string().required(ErrorMessageTypes.required),
  practiceEmailAddress: Yup.string()
    .email('Incorrect email format.')
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  // state: Yup.object().required(ErrorMessageTypes.required),
  addressLine1: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
  addressLine2: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
  city: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
  postalCode: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
  givenName: Yup.string().required(ErrorMessageTypes.required),
  lastName: Yup.string().required(ErrorMessageTypes.required),
  number: Yup.string()
    .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  extension: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
  kind: Yup.string(),
});

const GroupGeneral = (): JSX.Element => {
  const { groupId } = useParams<{ groupId: string }>();
  const [groupInfo, setGroupInfo] = React.useState<Group>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorEmail, setErrorEmail] = React.useState<string>('');
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const getGroupInfoEdit = async () => {
    try {
      const res = await configuredRequests.GET.singleGroupById(groupId);
      if (res) {
        setGroupInfo(res);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGroupInfoEdit();
  }, []);

  useEffect(() => {
    //@ts-ignore
    localStorage.setItem('isGroupEdited', dirtyForm);
  }, [dirtyForm]);

  const singleGroupData = {
    name: groupInfo?.name,
    status: groupInfo?.status,
    practiceEmailAddress: groupInfo?.practiceEmailAddress,
    number: groupInfo?.practicePhoneNumbers
      ? groupInfo?.practicePhoneNumbers[0].number
      : '',
    extension: groupInfo?.practicePhoneNumbers
      ? groupInfo?.practicePhoneNumbers[0].extension
      : '',
    kind: groupInfo?.practicePhoneNumbers
      ? groupInfo?.practicePhoneNumbers[0].kind
      : '',
    addressLine1: groupInfo?.addresses
      ? groupInfo?.addresses[0].address.addressLine1
      : '',
    addressLine2: groupInfo?.addresses
      ? groupInfo?.addresses[0].address.addressLine2
      : '',
    city: groupInfo?.addresses ? groupInfo?.addresses[0].address.city : '',
    state: groupInfo?.addresses
      ? dataStateOfUS.filter((item) => {
          //@ts-ignore
          return item.value === groupInfo?.addresses[0].address.state;
        })[0]
      : dataStateOfUS[0].value,
    postalCode: groupInfo?.addresses
      ? groupInfo?.addresses[0].address.postalCode
      : '',
    givenName: groupInfo?.practicePersonalDetails
      ? groupInfo?.practicePersonalDetails[0].givenName
      : '',
    lastName: groupInfo?.practicePersonalDetails
      ? groupInfo?.practicePersonalDetails[0].lastName
      : '',
    isSinglePractice: groupInfo?.isSinglePractice,
  };

  const submitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = {
        tenantGroupId: groupInfo?.tenantGroupId,
        name: values.name,
        status: values.status,
        practiceEmailAddress: values.practiceEmailAddress,
        practicePersonalDetails: [
          {
            userPersonalDetailsId: groupInfo?.practicePersonalDetails
              ? groupInfo?.practicePersonalDetails[0].userPersonalDetailsId
              : '',
            givenName: values.givenName,
            lastName: values.lastName,
          },
        ],
        practicePhoneNumbers: [
          {
            phoneNumberId: groupInfo?.practicePhoneNumbers
              ? groupInfo?.practicePhoneNumbers[0].phoneNumberId
              : '',
            number: values.number,
            extension: values.extension,
            isPrimary: true,
            kind: values.kind ? values.kind : 'cell',
          },
        ],
        addresses: [
          {
            userAddressId: groupInfo?.addresses
              ? groupInfo?.addresses[0].userAddressId
              : '',
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              state: values.state ? values.state.value : dataStateOfUS[0].value,
              postalCode: values.postalCode,
              country: '',
            },
            isPrimary: true,
            kind: 'home',
          },
        ],
        isSinglePractice: values.isSinglePractice,
      };
      //@ts-ignore
      const res = await configuredRequests.PATCH.updateGroup(submitData);
      if (res) {
        setGroupInfo(res);
        setDirtyForm(false);
        setDisabledSubmit(false);
        setErrorEmail('');
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (err) {
      setDisabledSubmit(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      if (err.response) {
        setErrorEmail(err.response.data.message);
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={singleGroupData}
      validationSchema={UserValidationSchema}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          errors,
          setFieldTouched,
          dirty,
        } = formikProps;
        if (dirty) {
          setDirtyForm(true);
        }
        return (
          <div className='communication-container  w-300'>
            <div className='communication-container__table'>
              {isLoading ? (
                <b className='loading-data'>Loading...</b>
              ) : (
                <>
                  <div className='react-modal--content'>
                    <div className='edit-appointment roleModal '>
                      <Fragment>
                        <form
                          onSubmit={handleSubmit}
                          className='col-12 form-schedule react-modal--settings__content'
                        >
                          <div className='setting__content--full-row '>
                            <h2 className='practice-title'>
                              Group Information
                            </h2>
                          </div>
                          <div className='d-flex-wrap'>
                            <div className='form-input-settings setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Group Name <b className='require'>*</b>
                              </span>
                              <div className='first-name'>
                                <FastField
                                  name='name'
                                  maxLength={255}
                                  required
                                  className='setting__search-form'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'name',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='name' />
                                </p>
                              </div>
                            </div>
                            <div className='form-input-settings setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Status <b className='require'>*</b>
                              </span>
                              <Field
                                as='select'
                                name='status'
                                value={values.status}
                                className='full-width custom-color'
                              >
                                <option value='Active'>Active </option>
                                <option value='Inactive'>Inactive</option>
                              </Field>
                            </div>
                          </div>

                          <div className='setting__content--full-row block'>
                            <span className='title-input-form'>Address</span>
                            <FastField
                              name='addressLine1'
                              maxLength={50}
                              className='setting__search-form'
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'addressLine1',
                                  e.currentTarget.value.trim()
                                );
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='addressLine1' />
                            </p>
                            <span className='title-input-form'>
                              Apt / Suite / Other
                            </span>
                            <FastField
                              name='addressLine2'
                              maxLength={50}
                              className='setting__search-form '
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'addressLine2',
                                  e.currentTarget.value.trim()
                                );
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='addressLine2' />
                            </p>
                            <div className='d-flex-wrap address-2'>
                              <div className='w-60'>
                                <span className='title-input-form'>City</span>
                                <FastField
                                  name='city'
                                  maxLength={50}
                                  className='field-input'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'city',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='city' />
                                </p>
                              </div>

                              <div className='full-width margin-h-5 w-25'>
                                <span className='title-input-form'>
                                  State / Province
                                </span>
                                <FastField
                                  classNamePrefix='search-city'
                                  className='option-city'
                                  component={Select}
                                  name='state'
                                  value={values.state}
                                  options={dataStateOfUS}
                                  onBlur={() => {
                                    setFieldTouched('state', true);
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue('state', e);
                                  }}
                                  maxMenuHeight={150}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='state' />
                                </p>
                              </div>

                              <div>
                                <span className='title-input-form'>
                                  Zip / Postal Code
                                </span>
                                <FastField
                                  name='postalCode'
                                  maxLength={50}
                                  className='field-input'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'postalCode',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='postalCode' />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className='setting__content--full-row '>
                            <h2 className='practice-title'>Group's Contact</h2>
                          </div>

                          <div className='d-flex-wrap'>
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                First Name <b className='require'>*</b>
                              </span>
                              <div className=' '>
                                <FastField
                                  name='givenName'
                                  maxLength={15}
                                  required
                                  placeholder='First Name'
                                  className='setting__search-form'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'givenName',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='givenName' />
                                </p>
                              </div>
                            </div>
                            <div className='form-input-settings setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Last Name <b className='require'>*</b>
                              </span>
                              <FastField
                                name='lastName'
                                maxLength={15}
                                required
                                placeholder='Last Name'
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'lastName',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='lastName' />
                              </p>
                            </div>
                          </div>
                          <div className='d-flex-wrap'>
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                {' '}
                                Email Address <b className='require'>*</b>
                              </span>
                              <FastField
                                maxLength={50}
                                name='practiceEmailAddress'
                                placeholder='Email Address'
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'practiceEmailAddress',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='practiceEmailAddress' />
                                {!errors.practiceEmailAddress && errorEmail}
                              </p>
                            </div>
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                {' '}
                                Phone Number <b className='require'>*</b>
                              </span>
                              <div className='d-flex'>
                                <div className='margin-r-5 h-60 w-50'>
                                  <FastField
                                    name={`number`}
                                    maxLength={10}
                                    required
                                    className='setting__search-form width-100'
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleBlur(e);
                                      setFieldValue(
                                        `number`,
                                        e.currentTarget.value.trim()
                                      );
                                    }}
                                  />
                                  <p className='error-msg'>
                                    <ErrorMessage
                                      className='error-msg'
                                      name={`number`}
                                    />
                                  </p>
                                </div>
                                <div className='margin-r-5 h-60 w-25'>
                                  <FastField
                                    name={`extension`}
                                    maxLength={50}
                                    placeholder='Ext.'
                                    className='setting__search-form width-50 margin-r-0'
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleBlur(e);
                                      setFieldValue(
                                        `extension`,
                                        e.currentTarget.value.trim()
                                      );
                                    }}
                                  />
                                  <p className='error-msg'>
                                    <ErrorMessage
                                      className='error-msg'
                                      name={`extension`}
                                    />
                                  </p>
                                </div>
                                <div className='search-filter__global h-60'>
                                  <Field
                                    className='filter-form'
                                    as='select'
                                    name={`kind`}
                                  >
                                    <option value='cell'>Mobile Phone</option>
                                    <option value='landline'>Home Phone</option>
                                    <option value='business'>
                                      Office Phone
                                    </option>
                                    <option value='other'>Other Phone</option>
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='setting__content--full-row block'>
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              disabled={!formikProps.isValid || disabledSubmit}
                            >
                              <span>Save</span>
                            </Button>
                          </div>
                        </form>
                      </Fragment>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default GroupGeneral;
