import React from 'react';
import styled from 'styled-components';
import { convertPhoneNumber } from 'utils/convertData';
import { get } from 'lodash';

const Wrapper = styled.div`
  width: 6.25in;
  height: 4.25in;
  margin-top: 28px;
  border: 1px solid #dee0ec;
  border-radius: 6px;
  background-color: #ffffff;

  position: relative;

  padding: 20px 0px 0px 20px;

  .mr-10 {
    margin-right: 10px;
  }

  #safe-area {
    position: absolute;
    width: 5.875in;
    height: 3.875in;
    left: 0.1875in;
    top: 0.1875in;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;

    &.manual {
      width: calc(100% - 20px);
    }

    .header-back-postcard {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-right: 10px;
        font-size: 13px;
        font-weight: bold;
        font-family: 'Nunito Sans';
        letter-spacing: 0;
        line-height: 16px;
        color: #000;
        word-break: break-word;
      }
    }
    .text {
      width: 200px;
      font-size: 13px;
      max-height: 300px;
      word-break: break-word;
    }

    &.template-us {
      width: calc(3.85in - 20px);
      height: calc(3.625in - 20px);

      /* .header-back-postcard {
        justify-content: flex-end;
        flex-direction: row-reverse;
      } */

      .text {
        width: 100%;
      }
    }

    &.template-uk {
      .text {
        width: calc(3.85in - 20px);
        margin-top: 0.8in;
      }
    }
  }

  #ink-free,
  #barcode-zone,
  #address-zone,
  #address-recipient-zone,
  #address-return-zone {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  #ink-free {
    width: 3.2835in;
    height: 2.375in;
    background-color: #ed7d31;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  #barcode-zone,
  #address-zone,
  #address-return-zone,
  #address-recipient-zone {
    padding: 10px;
  }

  #barcode-zone {
    width: 4.75in;
    height: 0.625in;
    background-color: rgba(208, 170, 209, 0.5);
  }

  #address-return-zone {
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
    width: 2.4in;
    height: 1.6in;
    background-color: #ffcccc;
  }

  #address-zone,
  #address-recipient-zone {
    width: 2.4in;
    height: 4.25in;
    background-color: #ffcccc;

    &.manual {
      width: 2in;
    }
  }
`;
interface IBackPostCard {
  generalSetting: any;
  contentBackSide: string;
  isSinglePostal?: boolean;
}

export const cutTemplateBody = (message: string) =>
  message.substring(
    message.indexOf('<body>') + '<body>'.length,
    message.indexOf('</body>') - 1
  );

const BackPostCard: React.FC<IBackPostCard> = ({
  generalSetting,
  contentBackSide,
  isSinglePostal = false,
}) => {
  return (
    <Wrapper>
      <div
        id='safe-area'
        className={`${
          (generalSetting?.postalProvider || '').toLowerCase() === 'postgrid'
            ? 'template-us'
            : ''
        } ${isSinglePostal ? 'manual' : ''}`}
      >
        {/* us  */}
        <div className='header-back-postcard'>
          {(generalSetting?.postalProvider || '').toLowerCase() !==
            'postgrid' && (
            <div>
              <p>{get(generalSetting, 'tenantName', '')}</p>
              <p>
                {convertPhoneNumber(
                  get(generalSetting, 'practicePhoneNumber[0].number', '')
                )}
              </p>
            </div>
          )}
          <img
            alt='practiceLogo'
            src={get(generalSetting, 'logoUrl', '')}
            height='60'
            width='200'
            className='mr-10'
          />
        </div>
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: cutTemplateBody(contentBackSide) }}
        ></div>
      </div>
      {(generalSetting.postalProvider || '').toLowerCase() === 'postgrid' ? (
        <>
          <div
            id='address-zone'
            className={`${isSinglePostal ? 'manual' : ''}`}
          >
            This red area is the address and indicia zone.
            <br />
            <br />
            It is 2.4in wide. <br />
            <br />
            Make sure you do not have any artwork or ink here
          </div>
          <div id='barcode-zone'>
            This is the USPS barcode zone. It is 4.75in wide and 0.625in tall.
            Make sure there is no artwork or ink here.
          </div>
        </>
      ) : (
        <div id='ink-free'>Contact Address</div>
      )}

      {/* uk */}
      {/* <div className='header-back-postcard'>
          <div>
            <p>{get(generalSetting, 'tenantName', '')}</p>
            <p>
              {convertPhoneNumber(
                get(generalSetting, 'practicePhoneNumber[0].number', '')
              )}
            </p>
          </div>
          <img
            alt='pacticeLogo'
            src={get(generalSetting, 'logoUrl', '')}
            height='60'
            width='200'
          />
        </div>
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: cutTemplateBody(contentBackSide) }}
        ></div>
      </div>
      <div id='address-recipient-zone'>Contact Address</div>
      <div id='address-return-zone'>Barcode</div> */}

      {/* old */}
      {/* <div className='header-back-postcard'>
          <div>
            <p>{get(generalSetting, 'tenantName', '')}</p>
            <p>
              {convertPhoneNumber(
                get(generalSetting, 'practicePhoneNumber[0].number', '')
              )}
            </p>
          </div>
          <img
            alt='pacticeLogo'
            src={get(generalSetting, 'logoUrl', '')}
            height='60'
            width='200'
          />
        </div>
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: cutTemplateBody(contentBackSide) }}
        ></div>
      </div>
      <div id='ink-free'>Contact Address</div> */}
    </Wrapper>
  );
};

export default BackPostCard;
