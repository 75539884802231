/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/**
 * getFieldPropertyValues returns object of property values for @propertyNames provided
 * as stored in the @propertyMap provided.
 *
 * @param {Map} propertyMap                 The property map containing the properties we want values for
 * @param {(string|string[])} propertyNames A string or array of strings of the name(s) of properties we want returned
 *                                          E.g.: ['label', 'makeRequired']
 * @param {object} [defaultValues={}]       An object in the shape of key:value pairs of propertyName:defaultValue
 *                                          E.g.: {label: 'someValue', makeRequired: false}
 * @return {object}                         {<key-for-each-@propertyNames>: {<value of any (*) type>}}
 */

const getFieldPropertyValues = (propertyMap, propertyNames, defaultValues = {}) => {
  let property;
  const values = {};
  if (typeof propertyNames === 'string') propertyNames = [propertyNames];
  propertyNames.forEach(propertyName => {
    try {
      property = propertyMap.get(propertyName);
      values[propertyName] = property.value;
    } catch (ex) {
      if (!property || !values[propertyName]) values[propertyName] = defaultValues[propertyName];
    }
  });
  return values;
};

export { getFieldPropertyValues };
