import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { configuredRequests } from '../../global/requests/ConfiguredRequests';
import Highcharts from 'highcharts';
import { round } from 'lodash';
import HighchartsReact from 'highcharts-react-official';
import loading_spinner from 'media/images/loading-spinner.svg';

const calculatePercentage = (
  partialValue: number,
  totalValue: number
): number => {
  if (totalValue === 0) {
    return 0;
  }
  return round((100 * partialValue) / totalValue);
};

interface IEmailResponse {
  sent: number;
  delivered: number;
  opened: number;
}

const EmailAnalytic = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [emailAnalyticData, setEmailAnalyticData] = useState<
    IEmailResponse | any
  >({});
  const [options, setOptions] = useState<any>({
    chart: {
      type: 'pie',
      // height: 300,
      // width: 500,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        cursor: 'default',
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const self: any = this;
        if (!!self.key) {
          return `${self.key}: ${self.y}%`;
        }
        return false;
      },
    },
  });

  const sendGetEmailAnalyticsRequest = async () => {
    try {
      const res = await configuredRequests.GET.getEmailAnalytic();
      setEmailAnalyticData(res);
      setLoading(false);
      setOptions({
        chart: {
          type: 'pie',
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            cursor: 'default',
            dataLabels: {
              enabled: false,
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            const self: any = this;
            if (!!self.key) {
              return `${self.key}: ${self.y}%`;
            }
            return false;
          },
        },
        series: [
          {
            innerSize: 220,
            size: 250,
            data: [
              {
                name: 'Sent',
                y: calculatePercentage(res.sent, res.sent),
                color: '#FFB822',
              },
              {
                name: '',
                y: 100 - calculatePercentage(res.sent, res.sent),
                color: '#C5CEDA',
              },
            ],
          },
          {
            innerSize: 170,
            size: 200,
            data: [
              {
                name: 'Delivered',
                y: calculatePercentage(res.delivered, res.sent),
                color: '#1974ff',
              },
              {
                name: '',
                y: 100 - calculatePercentage(res.delivered, res.sent),
                color: '#C5CEDA',
              },
            ],
          },
          {
            innerSize: 120,
            size: 150,
            data: [
              {
                name: 'Opened',
                y: calculatePercentage(res.opened, res.delivered),
                color: '#1DC9B7',
              },
              {
                name: '',
                y: 100 - calculatePercentage(res.opened, res.delivered),
                color: '#C5CEDA',
              },
            ],
          },
        ],
      });
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    sendGetEmailAnalyticsRequest();
  }, []);

  return (
    loading ?
    <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> :
    (
      <div id='emailAnalytic'>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className='sumaryStyle'>
          <div className='sentAnalyticStyle width-100'>
            <div className='sentStyle'>
              <FontAwesomeIcon
                icon={['fas', 'paper-plane']}
                className='planeStyle'
              />
            </div>
            <div className='valueStyle'>{emailAnalyticData.sent}</div>
            <div className='noticeStyle'>
              <div className='noticeSent noticeCommonStyle'></div>
              <div>Sent</div>
            </div>
          </div>
          <div className='deliveredStyle width-100'>
            <div className='sentStyle'>
              <FontAwesomeIcon icon={['fas', 'flag']} className='flagStyle' />
            </div>
            <div className='valueStyle'>{emailAnalyticData.delivered}</div>
            <div className='noticeStyle'>
              <div className='noticeDelivered noticeCommonStyle'></div>
              <div>Delivered</div>
            </div>
          </div>
          <div className='readStyle width-100'>
            <div className='sentStyle'>
              <FontAwesomeIcon
                icon={['fas', 'envelope-open']}
                className='envelopeOpenStyle'
              />
            </div>
            <div className='valueStyle'>{emailAnalyticData.opened}</div>
            <div className='noticeStyle'>
              <div className='noticeRead noticeCommonStyle'></div>
              <div>Opened</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EmailAnalytic;
