import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { OptionConfigItem } from './option-config-item';

export default class OptionConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragIndex: null,
      dragTarget: null,
      hoverLocation: null,
      placeholderIndex: null,
    };
  }

  handleDragStart = (e, data) => {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', typeof data !== 'string' ? JSON.stringify(data) : data);
    e.dataTransfer.dropEffect = 'move';

    setTimeout(() => this.setState({ dragIndex: data.index }));
  };

  handleDragOver = (target, location) => {
    this.setState({ dragTarget: target, hoverLocation: location });
  };

  handleDragEnd = e => {
    e.stopPropagation();
    const { dragIndex, dragTarget, hoverLocation } = this.state;
    const { options, onChange } = this.props;
    const newOptions = [...options];
    const fromIndex = dragIndex;
    let toIndex = parseInt(dragTarget.id);

    // need to remove option being dragged from list of options
    // then splice it in to the correct location (dragTarget.id plus hoverLocation)
    if (fromIndex < toIndex && hoverLocation === 'top' && toIndex > 0) --toIndex;
    else if (fromIndex > toIndex && hoverLocation === 'bottom' && toIndex < newOptions.length)
      ++toIndex;

    newOptions.splice(toIndex, 0, newOptions.splice(fromIndex, 1)[0]);

    onChange(newOptions);
    this.setState({
      dragIndex: null,
      dragTarget: null,
      hoverLocation: null,
    });
  };

  handleAddOption = index => {
    const { options, onChange } = this.props;
    const newOptions = [...options];

    newOptions.splice(index + 1, 0, '');
    onChange(newOptions, index);
  };

  handleDeleteOption = index => {
    const { options, onChange } = this.props;
    const newOptions = [...options];

    newOptions.splice(index, 1);
    onChange(newOptions, index);
  };

  handleEditOption = (index, label) => {
    const { options, onChange } = this.props;
    const newOptions = [...options];

    newOptions[index] = label;
    onChange(newOptions);
  };

  render() {
    const { options, min, max, disabled } = this.props;
    const isMultiChoices = Array.isArray(options) && min === 1 && max === -1 && !disabled;
    const { dragIndex, dragTarget, hoverLocation } = this.state;
    const placeholderClasses = classNames({
      'drag-placeholder': true,
      hidden: dragIndex === null,
    });

    return (
      <div className='option-config'>
        {options.map((option, index) => {
          const showPlaceholder =
            dragTarget !== null && dragTarget.id === index && dragIndex !== null;

          return (
            <div key={index}>
              {showPlaceholder && hoverLocation === 'top' && <div className={placeholderClasses} />}
              <OptionConfigItem
                key={index}
                index={index}
                label={option}
                dragIndex={dragIndex}
                disableAdd={max !== -1 && options.length >= max}
                disableDelete={min !== 0 && options.length <= min}
                onDragStart={this.handleDragStart}
                onDragOver={this.handleDragOver}
                onDragEnd={this.handleDragEnd}
                onAddOption={this.handleAddOption}
                onDeleteOption={this.handleDeleteOption}
                onEditOption={this.handleEditOption}
                disabled={disabled}
                isMultiChoices={isMultiChoices}
              />
              {showPlaceholder && hoverLocation === 'bottom' && (
                <div className={placeholderClasses} />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

OptionConfig.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OptionConfig.defaultProps = {
  options: [],
  min: 0,
  max: -1,
  onChange: () => { },
  disabled: false,
};
