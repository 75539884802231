import MessengerHistory from 'components/Messenger/MessengerHistory';
import MessengerInput, {
  InfoSending,
} from 'components/Messenger/MessengerInput';
import { convertToTenantUserInfoFromSingleUserData } from 'global/requests/responseFormatConverters';
import {
  ContactMessages,
  UserDetailSMSResponse,
} from 'global/requests/ResponseTypes';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import React, { SetStateAction } from 'react';
import SubscriptionLayer from './SubscriptionLayer';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

interface TextViewContainerProps {
  interactionData: UserDetailSMSResponse | undefined;
  userInfo: UserDataResponse | undefined;
  refreshMessageData?: (id: string) => void;
  setInteractionData: any;
  infoSending: InfoSending;
  setInfoSending: React.Dispatch<React.SetStateAction<InfoSending>>;
  refreshPhoneNumbers: (phoneNumbers: PhoneNumber[]) => void;
  subscription?: boolean;
  viewAllSms: boolean;
  setViewAllSms: React.Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  onSubscriptionClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const TextViewContainer = (props: TextViewContainerProps): JSX.Element => {
  const { subscription } = props;
  const fullTenantInfoData = getSessionTenantInfo();
  const [isHistoryHidden, setIsHistoryHidden] = React.useState(true);
  const [userInfoConverted, setUserInfoConverted] =
    React.useState<ContactMessages>();
  const [allowTextMessageWriteback, setAllowTextMessageWriteback] = React.useState<boolean>(false);

  const userInputMessageBodyRef = React.createRef<HTMLTextAreaElement>();
  const messageHistoryListRef = React.createRef<HTMLUListElement>();

  React.useEffect(() => {
    getDriverConfiguration();
    return () => {
      props.setInfoSending({ sending: false, isError: false });
    };
  }, []);

  React.useEffect(() => {
    if (props.userInfo) {
      setUserInfoConverted(
        convertToTenantUserInfoFromSingleUserData(props.userInfo)
      );
    } else {
      setUserInfoConverted(undefined);
    }
  }, [props.userInfo]);

  // hide until stack clears whenever interaction data changes
  React.useEffect(() => {
    setIsHistoryHidden(true);
    setTimeout(() => setIsHistoryHidden(false), 34); // ~2 render cycles
  }, [props.interactionData]);

  const getDriverConfiguration = async () => {
    try {
      const res = await configuredRequests.GET.getDriverConfiguration(fullTenantInfoData?.tenantId ?? '');
      if (res) {
        setAllowTextMessageWriteback(res.allowTextMessageWriteback);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  return (
    <>
      <SubscriptionLayer
        subscription={!!subscription}
        message={`${
          props.userInfo?.name?.givenName + ' ' + props.userInfo?.name?.lastName
        } has unsubscribed receiving text message.`}
        userId={props.userInfo?.userId ?? ''}
        onSubscriptionClick={props.onSubscriptionClick}
      />

      <span className={'view-contact--overflow-below-nav-container max-width'}>
        <MessengerHistory
          messageHistory={props.interactionData}
          // @ts-ignore
          currentSelectedContactData={props.interactionData}
          currentSelectedContactUUID={props.userInfo?.userId ?? ''}
          messageHistoryListRef={messageHistoryListRef}
          viewIsChangingUsers={isHistoryHidden}
          loading={props.loading}
          infoSending={props.infoSending}
          setInfoSending={props.setInfoSending}
          searchMessageId=''
          allowTextMessageWriteback={allowTextMessageWriteback}
        />

        <MessengerInput
          setMessageDataLookup={props.setInteractionData}
          userInfo={userInfoConverted}
          userInputMessageBodyRef={userInputMessageBodyRef}
          refreshMessageData={props.refreshMessageData}
          setInfoSending={props.setInfoSending}
          refreshPhoneNumbers={props.refreshPhoneNumbers}
          isSmsSubscription={!!subscription}
          viewAllSms={props.viewAllSms}
          setViewAllSms={props.setViewAllSms}
        />
      </span>
    </>
  );
};

export default React.memo(TextViewContainer);
