import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { SmallEmphasizedDarkText, SmallDarkText, SmallTitleText } from 'components/Legwork/typography';

const InputContainer = styled(Grid)`
  padding-bottom: 10px;
`;

const InputGridItem = styled(Grid)`
  padding: 0 8px;
`;

const ResponseTitle = styled(SmallEmphasizedDarkText)`
  margin-bottom: 0;
  padding: 8px 0px 0px 8px;
`;

const Comment = styled(SmallDarkText)`
  padding: 4px 0px 8px 9px;
  color: #606d7e;
`;

const RatingBubble = styled.div`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  margin-right: ${(props: { positive: boolean }): string => (props.positive ? '100px' : '125px')};
  margin-bottom: 10px;
`;

const SpeechBubble = styled.div`
  margin-top: 6px;
  text-align: center;
  position: relative;
  width: 22px;
  height: 13px;
  position: relative;
  background: ${(props: { positive: boolean }): string => (props.positive ? '#ea5e57' : '#ababab')};
  border-radius: 0.2em;
  align-content: center;
  justify-content: center;
  border: 1px solid
    ${(props: { positive: boolean }): string => (props.positive ? '#e7302a' : '#ababab')};

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: ${(props: { positive: boolean }): string =>
      props.positive ? '#e7302a' : '#ababab'};
    border-bottom: 0;
    border-left: 0;
    margin-left: -6.5px;
    margin-bottom: -6px;
  }
`;

const RecommendationText = styled(SmallTitleText)`
  width: 130px;
  position: absolute;
  margin-top: 7px;
  margin-left: 28px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

const StarContainer = styled.p`
  color: #fff;
  margin-top: 2px;
  font-size: 12px;
  line-height: 10px;
`;

const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 8px;
  padding-top: 20px;
`;

export {
  InputContainer,
  InputGridItem,
  ResponseTitle,
  Comment,
  RatingBubble,
  SpeechBubble,
  RecommendationText,
  StarContainer,
  ResponseContainer,
};
