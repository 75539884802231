import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledTabs = styled(Tabs)`
  background-color: white;
  border-bottom: 1px solid ${getThemeProp('palette.divider', '#E3EBF6')};

  .MuiTabs-indicator {
    background-color: ${getThemeProp('palette.primary.main', '#4C7CFD')};
    border-radius: 4px 0px 0px 4px;
  }
  .Mui-selected .MuiTab-wrapper {
    color: ${getThemeProp('palette.text.primary', 'blue')};
  }
  .MuiTab-root {
    padding-left: 0;
    padding-right: 0;
    margin: 0 1rem;
    min-width: 0;
  }
`;

const StyledTab = styled(Tab)`
  span.MuiTab-wrapper {
    color: ${getThemeProp('palette.text.secondary', 'black')};
    text-transform: initial;
    font-weight: 500;
    font-size: 14px;
  }
` as typeof Tab;

const StyledIconTab = styled(StyledTab)`
  .MuiTab-wrapper {
    flex-direction: row-reverse;
    & > *:first-child {
      margin-bottom: 3px;
    }
    svg {
      height: 1rem;
      width: 1rem;
      padding-left: 0.3rem;
    }
  }
`;

export { StyledTab, StyledTabs, StyledIconTab };
