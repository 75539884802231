import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { StyledDiv, StyledInputLabel } from './styled-components';

const TINY_MCE_API_KEY = 'a9f6rtq0r7e1k7cgfmrap8d6a97h8lpbl0hngycy1462j6kw';

const globalStyle = (
  <style
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: `
  .tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
    z-index: 1400;
  }

  .tox-fullscreen .tox.tox-tinymce-aux {
    z-index: 1401;
  }
`,
    }}
  />
);

export interface TinyMCEEditorProps {
  title?: string;
  handleChange: (val: string) => void;
  initialValue?: string;
  height?: number | string;
}


const TinyMCEEditor = (props: TinyMCEEditorProps): React.ReactElement => {
  const { title, handleChange, initialValue = '', height = 500 } = props;

  React.useEffect(() => {
    const viewVerticalScroll = document.getElementById("navigation-main");
    if (viewVerticalScroll) {
      viewVerticalScroll.classList.remove("horizontal-scroll", "vertical-scroll");
      viewVerticalScroll.style.overflow = "hidden";
    }
    return () => {
      if (viewVerticalScroll) {
        viewVerticalScroll.style.overflow = "";
        viewVerticalScroll.classList.add("horizontal-scroll", "vertical-scroll");
      }
    }
  }, []);

  return (
    <StyledDiv>
      {title && <StyledInputLabel>{title}</StyledInputLabel>}
      {globalStyle}
      <Editor
        apiKey={TINY_MCE_API_KEY}
        initialValue={initialValue}
        init={{
          height,
          menubar: false,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          init_instance_callback: (): void => {
            const freeTiny = document.querySelector('.tox-notifications-container') as HTMLElement;
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste imagetools help wordcount emoticons',
          ],
          toolbar: ` fullscreen | formatselect | bold italic underline strikethrough forecolor backcolor |
            alignleft bullist numlist |
            removeformat | link image emoticons table | help`,
        }}
        onEditorChange={handleChange}
      />
    </StyledDiv>
  );
};

export default TinyMCEEditor;
