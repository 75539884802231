import React, { useEffect } from 'react';
import { TextField, Button, Modal } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { configuredRequests } from "global/requests/ConfiguredRequests";
import { NotificationsResponse } from 'global/requests/ResponseTypes';
import { dataMockupNotification } from 'global/requests/dataUserSettingResponseError';
import _ from 'lodash';
import { SummaryItemTypes, UserStatusTypes, UserKindTypes } from 'global/requests/api-route-types';
import loading_spinner from 'media/images/loading-spinner.svg';
import { AppointmentTypeModal } from './ProvidersModal';
import toast from 'components/Global/Toast';
import './ProviderAppointmentType.scss';

type UserListOption = {
  id: string,
  name: string,
}
interface ProviderProfileProps {
  handleCloseForm: any;
  displayRoleModal: boolean;
  providerId: string;
  listAppointmentTypeSelect: AppointmentTypeModal[];
  listAppointmentType: AppointmentTypeModal[];
  dirtyProfile: boolean;
  dirtyAvailability: boolean;
  dirtyAppointmentType: boolean;
  setListAppointmentTypeSelect: (appointmentTypes: AppointmentTypeModal[]) => void;
  setDisplayRoleModal: (arg: boolean) => void;
  setSumActiveTabs: (tabs: number) => void;
  setActiveTab: (tab: number) => void;
  setDirtyAppointmentType: (arg: boolean) => void;
  getProviderList: () => void;
}

const ProviderAppointmentType = (props: ProviderProfileProps): JSX.Element => {
  const {
    handleCloseForm,
    displayRoleModal,
    providerId,
    listAppointmentTypeSelect,
    listAppointmentType,
    dirtyProfile,
    dirtyAvailability,
    dirtyAppointmentType,
    setDirtyAppointmentType,
    setListAppointmentTypeSelect,
    setDisplayRoleModal,
    setSumActiveTabs,
    setActiveTab,
    getProviderList
  } = props;

  // const [notificationSetting, setNotificationSetting] = React.useState<NotificationsResponse>(dataMockupNotification);
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isDisableButton, setDisableButton] = React.useState<boolean>(true);

  // const sendGetAllUsersRequest = async (options?: {
  //   limit?: number;
  //   offset?: number;
  //   summaryItems?: SummaryItemTypes[];
  //   withStatus?: UserStatusTypes;
  //   userKinds: UserKindTypes[];
  // }) => {
  //   try {
  //     const allUserDataResponse = await configuredRequests.GET.allUserInfoByCurrentTenant({
  //       ...options,
  //       userKinds: ["Customer", "User", "Admin"]
  //     });
  //     if (allUserDataResponse) {

  //       const userList = allUserDataResponse.users.map((userEntry) => {
  //         return ({
  //           id: `${userEntry.id}`,
  //           name: `${userEntry.name}`,
  //         })
  //       });

  //       // using the 'as' keyword will bypass the type-checking and could cause a crash.
  //       // const userList = convertData(allUserDataResponse.users as ItemDataUserSetting[]);
  //       const userListSort = _.orderBy(userList, ['name'], ['asc']);
  //       setUserListData(userListSort);
  //     }
  //   } catch (err) {
  //   }
  // }

  const submitData = async () => {
    try {
      const listIdAppointment = listAppointmentTypeSelect.map((item: AppointmentTypeModal) => item.id);
      const res = await configuredRequests.POST.createAppointmentTypesOfProvider(providerId, listIdAppointment);
      if (res) {
        setDirtyAppointmentType(false);
        if (dirtyProfile) {
          setActiveTab(0);
        } else if (dirtyAvailability) {
          setActiveTab(1);
        } else {
          setListAppointmentTypeSelect([]);
          setDisplayRoleModal(false);
        }
        getProviderList();
        // setDisableButton(true);
        // setSumActiveTabs(3);
        // setActiveTab(0);

        toast.success("Your data has been saved successfully!");
      }
    } catch (err) {
      toast.error("Your data has not been saved yet. Please try again.");
    }
  }

  return (
    <div className="react-modal--content">
      {/* {isLoading ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> : ( */}
      <>
        <div className="edit-appointment">
          <div className="react-modal--settings__content">
            <div className="setting__content--full-row block setting__input">
              <div className='form-input-settings'>
                <span className="title-input-form">
                  Set the service that this providers offers.
                  This will allow patients to select specific services for this provider while booking appointments.
                More services can be added in the Appointment Types settings.</span>
                <div className="autocomplete select-appointment-type">

                  <Autocomplete
                    multiple
                    defaultValue={listAppointmentTypeSelect}
                    options={listAppointmentType}
                    getOptionLabel={option => option.appointmentType}
                    disableCloseOnSelect
                    onFocus={() => {
                      // setDisableButton(false);
                      // setDirtyAppointmentType(true);
                    }}
                    onChange={(event, value) => {
                      setDirtyAppointmentType(true);
                      setListAppointmentTypeSelect(value);
                      // setDisableButton(false);
                    }}
                    noOptionsText="No appointment type matched"
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='react-modal-settings-footer'>
          <Button
            variant='contained'
            color='default'
            className='cancel-button'
            onClick={handleCloseForm}
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submitData}
            disabled={!dirtyAppointmentType}
          >
            Save
          </Button>
        </div>
      </>
    </div>
  )
};

export default ProviderAppointmentType;
