import React from 'react';
import classNames from 'classnames';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import styles from './styles';
import { Labels } from '../../constants';
import 'react-day-picker/lib/style.css';
import './appointment-selector.css';

import ValidationMessage from '../../../validation-message';
import TimeSelector from './components/time-selector';

const AppointmentSelector = ({
  openDays,
  timeSlots,
  selectedDay,
  selectedTime,
  onMonthChange,
  onDayClick,
  onTimeClick,
  dayPickerField,
  timeSelectorField,
  readOnly,
}) => {
  const modifiers = { highlighted: selectedDay };

  const disableDaysOfMonth = day => {
    return openDays.indexOf(moment(day).dayOfYear()) === -1;
  };

  const validationClass = classNames({
    'control-group error': !!dayPickerField.validationMessage,
  });

  const readOnlyStyle = readOnly && styles.readOnly;

  return (
    <div style={styles.dateTimeContainer}>
      <div className='form-field-input-container' style={styles.inputContainer}>
        <div className='form-field-label primary'>{`${Labels.AppointmentDate} *`}</div>
        <div style={{ ...styles.calendarContainer, ...readOnlyStyle, ...styles.dayPicker }} className={validationClass}>
          <DayPicker
            selectedDays={selectedDay}
            initialMonth={selectedDay || new Date()}
            disabledDays={[
              {
                before: moment()
                  .startOf('day')
                  .toDate(),
              },
              disableDaysOfMonth,
            ]}
            onMonthChange={onMonthChange}
            onDayClick={onDayClick}
            modifiers={modifiers}
            modifiersStyles={styles.calendarStyles}
            containerProps={{ readOnly, id: dayPickerField.uuid }}
          />
          <ValidationMessage message={dayPickerField.validationMessage} />
        </div>
      </div>

      {selectedDay && (
        <TimeSelector
          id={timeSelectorField.uuid}
          timeSlots={timeSlots}
          selectedTime={selectedTime}
          onTimeClick={onTimeClick}
          validationMessage={timeSelectorField.validationMessage}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

AppointmentSelector.defaultProps = {
  openDays: [],
  timeSlots: [[], []],
  selectedDay: null,
  selectedTime: null,
  readOnly: false,
};

export default AppointmentSelector;
