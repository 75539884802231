import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledButton = styled(Button)`
  padding: 0 !important;
  border: 1px solid #b8c3d2 !important;
  border-radius: 4px;
  text-transform: none !important;
  font-weight: 400;
  color: ${getThemeProp('palette.text.primary', 'black')};
  background-color: white;
  svg {
    margin-right: 5px;
    height: 100%;
    width: 1rem;
  }
  &.MuiButton-root.Mui-disabled {
    opacity: 50%;
    color: ${getThemeProp('palette.text.primary', 'black')};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledButton };
