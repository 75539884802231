import RoleSetting from 'components/Settings/RoleSetting/RoleSetting';
import UsersSetting from 'components/Settings/UsersSetting/UsersSetting';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { default as React, useEffect } from 'react';
import SystemSetting from 'components/Settings/SystemSettings/SystemSettings';
import ProductsSetting from 'components/Settings/ProductsSetting/ProductsSetting';
import SystemNotification from 'components/Settings/NotificationsSetting/SystemNotification';

const SettingSystemAdmin = (): JSX.Element => {
  const [activeComponent, setActiveComponent] = React.useState<number>(1);
  useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    setActiveComponent(
      tabActive.leftMenuContentActive === 0
        ? 1
        : tabActive.leftMenuContentActive
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveLink = (numberActive: number) => {
    setTabActiveLocalStorage('', numberActive, 0);
    setActiveComponent(numberActive);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 1: {
        return <RoleSetting />;
      }
      case 2: {
        return <UsersSetting />;
      }
      case 3: {
        return <SystemSetting />;
      }
      case 4: {
        return <ProductsSetting />;
      }
      case 5: {
        return;
      }
      case 6: {
        return;
      }
      case 7: {
        return <SystemNotification />;
      }
    }
    return <RoleSetting />;
  };

  const componentValues: { innerText: string; switchValue: number }[] = [
    {
      innerText: 'Roles',
      switchValue: 1,
    },
    {
      innerText: 'Users',
      switchValue: 2,
    },
    {
      innerText: 'System',
      switchValue: 3,
    },
    {
      innerText: 'Products',
      switchValue: 4,
    },
    {
      innerText: 'Services',
      switchValue: 5,
    },
    {
      innerText: 'Delivery Schedule',
      switchValue: 6,
    },
    {
      innerText: 'Notifications',
      switchValue: 7,
    },
  ];

  return (
    <div className='communication-analyze settings--main-view-container'>
      <div className='communication-analyze__right-modal'>
        <ul className='analyze-tab__lists'>
          {componentValues.map((componentValue, index) => {
            return (
              <li
                key={'communication-templates-nav-' + index}
                tabIndex={0}
                className={
                  'analyze-tab__item-list ' +
                  (activeComponent === componentValue.switchValue
                    ? 'active'
                    : '')
                }
                onClick={() => {
                  handleActiveLink(componentValue.switchValue);
                }}
              >
                {componentValue.innerText}
              </li>
            );
          })}
        </ul>
      </div>

      {renderContainer()}
    </div>
  );
};

export default SettingSystemAdmin;
