import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column, DriversResponse } from 'global/requests/ResponseTypes';
import React, { Fragment, useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import UploadComponent from './UploadComponent';
import _ from 'lodash';
import moment from 'moment';
import Table from 'components/Global/Table';
import { convertStringToCamelCase } from 'utils/convertData';
ReactModal.setAppElement('div#root');

interface RightModalSettingUserProps {
  displayRoleModal: boolean;
  setDisplayRoleModal: (arg: boolean) => void;
  titleText?: string;
  handleReloadRoleList?: any;
  isEdit?: boolean;
  idValue: string;
}

interface ConfigurationFieldType {
  Key?: string;
  Value?: boolean;
  Configurable?: boolean;
  Description?: string;
}

interface ColumnTableType {
  key: string;
  title: string;
}

const columns: Column[] = [
  { id: 'version', label: 'Version' },
  { id: 'releasedDate', label: 'Released Date' },
  { id: 'actionDownload', label: 'Action' },
];

const columnsSetting = [
  { key: 'key', title: 'Key' },
  { key: 'value', title: 'Value' },
  { key: 'configurable', title: 'Configurable' },
  { key: 'description', title: 'Description' },
];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const DriverValidationSchema = Yup.object().shape({
  driverName: Yup.string().required(ErrorMessageTypes.required),
  version: Yup.string().required(ErrorMessageTypes.incorrectFormat),
  releasedDate: Yup.string().required(ErrorMessageTypes.incorrectFormat),
});

const DriverModal = (props: RightModalSettingUserProps) => {
  const { displayRoleModal, setDisplayRoleModal, titleText, isEdit, idValue } =
    props;

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);

  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const [driverInfoData, setDriverInfoData] = React.useState<DriversResponse>();
  const [driverInfomationDataTable, setDriverInformationDataTable] =
    React.useState<any>();

  const [columnsTable, setColumnsTable] = React.useState<ColumnTableType[]>([
    { key: 'key', title: 'Key' },
    { key: 'value', title: 'Value' },
    { key: 'configurable', title: 'Configurable' },
    { key: 'description', title: 'Description' },
  ]);

  const getColumnsName = useCallback((configurationField: string) => {
    const fields = JSON.parse(configurationField);

    if (fields?.length) {
      const fieldest = { index: 0, length: 0 };
      fields.forEach((field: any, idx: number) => {
        const lengthKeys = Object.keys(field).length;
        if (lengthKeys > fieldest.length) {
          fieldest.index = idx;
          fieldest.length = lengthKeys;
        }
      });
      const keys = Object.keys(fields[fieldest.index]);
      setColumnsTable(
        keys.map((key: string) => ({
          key: key,
          title: convertStringToCamelCase(key, true),
        }))
      );
    }
  }, []);

  const getDriverInformationData = async () => {
    try {
      const res = await configuredRequests.GET.getDriverInformationData(
        idValue
      );
      if (res) {
        setDriverInfoData(res as DriversResponse);
        getColumnsName(res.configurationField ?? '[]');
        const driverInfoDataTable = res?.versions?.map((item: any) => {
          return {
            releasedDate: moment(item.releasedDate).format(
              'MM/DD/YYYY hh:mm A'
            ),
            actionDownload: item.downloadLink,
            version: item.versionName,
          };
        });

        let sortedInfo = driverInfoDataTable?.sort((one, two) => {
          let nums1 = one.version.split('.');
          let nums2 = two.version.split('.');
          for (let i = 0; i < nums1.length; i++) {
            if (nums1[i] != nums2[i]) {
              return +nums1[i] > +nums2[i] ? -1 : 1;
            }
          }
          return 0;
        });

        // const sortDriverInfoDataTable = _.orderBy(
        //   driverInfoDataTable,
        //   [(drivers) => drivers.version.toLowerCase()],
        //   ["desc"]
        // );
        setDriverInformationDataTable(sortedInfo as any);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (idValue && driverInfoData?.driverId !== idValue) {
      getDriverInformationData();
    }
  });

  const driverInitialValues = !isEdit
    ? {
        driverName: '',
        version: 'N/A',
        releasedDate: '',
        downloadLink: '',
        configurationField: [],
      }
    : {
        driverId: driverInfoData?.driverId,
        driverName: driverInfoData?.driverName,
        version: _.get(driverInfoData, 'versionName', 'N/A'),
        releasedDate: moment(driverInfoData?.releasedDate).format(
          'YYYY-MM-DDTHH:mm'
        ),
        downloadLink: driverInfoData?.downloadLink,
        configurationField: JSON.parse(
          driverInfoData?.configurationField ?? '[]'
        ),
      };

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = !isEdit
        ? {
            driverName: values.driverName,
            version: values.version,
            releasedDate: values.releasedDate,
            downloadLink: values.downloadLink,
            configurationField: JSON.stringify(values.configurationField),
          }
        : {
            driverId: driverInfoData?.driverId,
            driverName: values.driverName,
            version: values.version,
            releasedDate: values.releasedDate,
            downloadLink: values.downloadLink,
            configurationField: JSON.stringify(values.configurationField),
          };
      const res = isEdit
        ? await configuredRequests.PATCH.updateDriver(submitData as any)
        : await configuredRequests.POST.createDriver(submitData as any);
      setDisplayRoleModal(false);
      await props.handleReloadRoleList();
      getDriverInformationData();
      if (res) {
        setDirtyForm(false);
        setDisabledSubmit(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (error) {
      setDisabledSubmit(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplayRoleModal(dirtyForm);
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Your data will be lost. Do you wish to continue?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setDirtyForm(false);
              setOpenPopup(false);
              setDisplayRoleModal(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  console.log('edit', isEdit);

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-driver'
      isOpen={displayRoleModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={driverInitialValues}
        validationSchema={DriverValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            setFieldValue,
            handleChange,
            values,
            dirty,
          } = formikProps;
          if (dirty && displayRoleModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Driver' : ''}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Driver Information</h2>
                    </div>
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Driver Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={255}
                            name='driverName'
                            required
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'driverName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='driverName' />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='setting__content--full-row block'>
                      <div className='form-input-settings'>
                        <div className='first-name'>
                          <FastField
                            name='downloadLink'
                            required
                            disabled
                            value={values.downloadLink}
                            className='display-none'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Upload File</h2>
                    </div>
                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        File <b className='require'>*</b>
                      </span>
                      <UploadComponent
                        setFieldValue={setFieldValue}
                        values={values}
                        getColumnsName={getColumnsName}
                      />
                    </div>
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Latest Version <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='version'
                            required
                            disabled
                            value={values.version}
                            className='setting__search-form'
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='version' />
                          </p>
                        </div>
                      </div>

                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Released Date <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            id='datetime-local'
                            type='datetime-local'
                            component={TextField}
                            name='releasedDate'
                            required
                            disabled
                            className='setting__search-form datetime'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e: any) => {
                              handleChange(e);
                              setFieldValue('releasedDate', e.target.value);
                            }}
                            value={values.releasedDate}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='releasedDate' />
                          </p>
                        </div>
                      </div>
                    </div>

                    {values.configurationField.length > 0 && (
                      <>
                        <div className='setting__content--full-row driver__write-back--title'>
                          <h2 className='practice-title'>
                            Configuration Fields
                          </h2>
                        </div>
                        <div className='setting__content--full-row driver__configuration-fields custom-scroll'>
                          <Table
                            columns={columnsTable}
                            // @ts-ignore
                            rows={values.configurationField.map(
                              (item: any, index: number) => ({
                                [columnsTable[0].key]:
                                  item[columnsTable[0].key],
                                [columnsTable[1].key]: (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={`configurationField[${index}][${columnsTable[1].key}]`}
                                        color='primary'
                                        checked={item[columnsTable[1].key]}
                                        onChange={() => {}}
                                        disabled
                                      />
                                    }
                                    className='checkbox--small'
                                    label=''
                                  />
                                ),
                                [columnsTable[2].key]: (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={`configurationField[${index}][${columnsTable[2].key}]`}
                                        color='primary'
                                        checked={item[columnsTable[2].key]}
                                        onChange={() => {}}
                                        disabled
                                      />
                                    }
                                    className='checkbox--small'
                                    label=''
                                  />
                                ),
                                [columnsTable[3].key]: (
                                  <FastField
                                    name={`configurationField[${index}][${columnsTable[3].key}]`}
                                    className='setting__search-form'
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleBlur(e);
                                      setFieldValue(
                                        `configurationField[${index}][${columnsTable[3].key}]`,
                                        e.currentTarget.value.trim()
                                      );
                                    }}
                                  />
                                ),
                              })
                            )}
                            isHasPagination={false}
                          />
                        </div>
                      </>
                    )}
                    {isEdit && (
                      <div className='setting__content--full-row block edit-driver'>
                        <h2 className='practice-title'>Older Versions</h2>
                        <CommunicationTable
                          columns={columns}
                          dataTable={driverInfomationDataTable || []}
                          isSort={false}
                          isDisplayedSearchAndFilter={true}
                          isDisablePagination={true}
                          // isLoading={isLoading}
                          screen={'DRIVERMODAL'}
                        />
                      </div>
                    )}
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    !isEdit
                      ? !formikProps.isValid ||
                        driverInitialValues.version === values.version ||
                        disabledSubmit
                      : !formikProps.isValid || disabledSubmit
                  }
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </ReactModal>
  );
};
export default DriverModal;
