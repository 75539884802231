import React from 'react';
import styled from 'styled-components';

const RowParent = styled.div`
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
`;

const ComponentContainer = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({ noMargin, ...props }): React.ReactElement => <div {...props}>{props.children}</div>,
)`
  margin: ${(props): string => (props.noMargin ? '0' : '1rem')};
`;

export { RowParent, ComponentContainer };
