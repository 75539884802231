import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@material-ui/core';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { dataStateOfUS } from 'global/localStorage/dataStateOfUS';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import toast from 'components/Global/Toast';
import { phonePattern } from 'utils/regex';
import * as Yup from 'yup';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
ReactModal.setAppElement('div#root');

interface PracticeModalProps {
  displayPracticeModal: boolean;
  setDisplayPracticeModal: (arg: boolean) => void;
  titleText?: string;
  userInformation: UserDataResponse | undefined;
  setUserInformationData: React.Dispatch<
    React.SetStateAction<UserDataResponse | undefined>
  >;
  handleReloadTenantList: () => void;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const PracticeValidationSchema = Yup.object().shape({
  tenantName: Yup.string()
    .max(255, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  status: Yup.string().required(ErrorMessageTypes.required),
  emailAddress: Yup.string()
    .email('Incorrect email format.')
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  addresses: Yup.array().of(
    Yup.object().shape({
      address: Yup.object().shape({
        addressLine1: Yup.string()
          .max(50, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        addressLine2: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
        city: Yup.string()
          .max(50, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        state: Yup.object().required(ErrorMessageTypes.required),
        postalCode: Yup.number()
          .typeError(ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
      }),
    })
  ),
  name: Yup.array().of(
    Yup.object().shape({
      givenName: Yup.string()
        .max(15, ErrorMessageTypes.incorrectFormat)
        .required(ErrorMessageTypes.required),
      lastName: Yup.string()
        .max(15, ErrorMessageTypes.incorrectFormat)
        .required(ErrorMessageTypes.required),
    })
  ),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      number: Yup.string()
        .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
        .required(ErrorMessageTypes.required),
      extension: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
      kind: Yup.string(),
    })
  ),
});

const PracticesModal = (props: PracticeModalProps) => {
  const {
    displayPracticeModal,
    setDisplayPracticeModal,
    titleText,
    handleReloadTenantList,
  } = props;

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [allowSubmit, setAllowSubmit] = React.useState(true);
  const params = useParams<{ groupId: string }>();
  const [nameError, setNameError] = React.useState<string>('');

  const cancelPopup = () => {
    props.setDisplayPracticeModal(false);
  };

  const initPracticeInfoData = {
    tenantName: '',
    status: 'active',
    emailAddress: '',
    addresses: [
      {
        address: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: dataStateOfUS[0],
          postalCode: '',
        },
      },
    ],
    name: [
      {
        givenName: '',
        lastName: '',
      },
    ],
    phoneNumbers: [
      {
        number: '',
        extension: '',
        kind: 'cell',
        isPrimary: true,
      },
    ],
  };

  const handleSubmitData = async (values: any) => {
    if (!allowSubmit) {
      return;
    }
    setAllowSubmit(false);
    try {
      const submitData = {
        tenantName: values.tenantName,
        status: values.status,
        addresses: [
          {
            address: {
              addressLine1: values.addresses[0].address.addressLine1,
              addressLine2: values.addresses[0].address.addressLine2,
              city: values.addresses[0].address.city,
              state:
                values.addresses[0].address.state.value ??
                dataStateOfUS[0].value,
              postalCode: values.addresses[0].address.postalCode,
            },
          },
        ],
        name: [
          {
            givenName: values.name[0].givenName,
            lastName: values.name[0].lastName,
          },
        ],
        emailAddress: values.emailAddress,
        phoneNumbers: [
          {
            number: values.phoneNumbers[0].number,
            extension: values.phoneNumbers[0].extension,
            kind: values.phoneNumbers[0].kind,
            isPrimary: true,
          },
        ],
      };
      const res = await configuredRequests.POST.createTenantInGroup(
        params.groupId,
        //@ts-ignore
        submitData
      );
      if (res) {
        setEmailError('');
        toast.success('Your data has been saved successfully!');
        setDisplayPracticeModal(false);
        setAllowSubmit(true);
        handleReloadTenantList();
      }
    } catch (error) {
      if (
        error?.response?.data?.message === 'Practice name is already in use'
      ) {
        setNameError(get(error, 'response.data.message', ''));
      }
      if (
        get(error, 'response.data.details.invalidDataField', '') ===
        'emailAddress'
      ) {
        setEmailError(get(error, 'response.data.message', ''));
      }
      toast.error('Your data has not been saved yet. Please try again.');
      setAllowSubmit(true);
    }
  };

  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (dirtyForm) {
      setOpenPopup(true);
    } else {
      setDisplayPracticeModal(false);
      setNameError('');
    }
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Your data will be lost. Do you wish to continue?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
              setDisplayPracticeModal(true);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setOpenPopup(false);
              setDisplayPracticeModal(false);
              setNameError('');
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={displayPracticeModal}
      onRequestClose={handleCloseModal}
    >
      <Formik
        initialValues={initPracticeInfoData}
        validationSchema={PracticeValidationSchema}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            setFieldTouched,
            dirty,
            errors,
          } = formikProps;
          setDirtyForm(dirty);
          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Practice' : ''}{' '}
                </p>

                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment roleModal '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Practice Information</h2>
                    </div>
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Practice Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='tenantName'
                            maxLength={255}
                            required
                            placeholder='Practice Name'
                            className='setting__search-form'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                              setNameError('');
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'tenantName',
                                e.currentTarget.value.trim()
                              );
                              setNameError('');
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='tenantName' />
                            {!errors.tenantName && nameError}
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Status <b className='require'>*</b>
                        </span>
                        <Field
                          as='select'
                          name='status'
                          value={values.status}
                          className='full-width custom-color'
                        >
                          <option value='active'> Active</option>
                          <option value='inActive'>Inactive</option>
                        </Field>
                        <p className='error-msg'>
                          <ErrorMessage name='status' />
                        </p>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        Address <b className='require'>*</b>
                      </span>
                      <FastField
                        name='addresses[0].address.addressLine1'
                        maxLength={50}
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addresses[0].address.addressLine1',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addresses[0].address.addressLine1' />
                      </p>
                      <span className='title-input-form'>
                        Apt / Suite / Other
                      </span>
                      <FastField
                        name='addresses[0].address.addressLine2'
                        maxLength={50}
                        className='setting__search-form '
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addresses[0].address.addressLine2',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addresses[0].address.addressLine2' />
                      </p>
                      <div className='flex address-2'>
                        <div>
                          <span className='title-input-form'>
                            City <b className='require'>*</b>
                          </span>
                          <FastField
                            name='addresses[0].address.city'
                            maxLength={50}
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'addresses[0].address.city',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.city' />
                          </p>
                        </div>

                        <div className='full-width margin-h-5 w-50'>
                          <span className='title-input-form'>
                            State / Province <b className='require'>*</b>
                          </span>
                          <FastField
                            classNamePrefix='search-city'
                            className='option-city'
                            component={Select}
                            name='addresses[0].address.state'
                            options={dataStateOfUS}
                            defaultValue={dataStateOfUS[0]}
                            onBlur={() => {
                              setFieldTouched(
                                'addresses[0].address.state',
                                true
                              );
                            }}
                            onChange={(e: any) => {
                              setFieldValue('addresses[0].address.state', e);
                            }}
                            maxMenuHeight={150}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.state' />
                          </p>
                        </div>

                        <div>
                          <span className='title-input-form'>
                            Zip / Postal Code <b className='require'>*</b>
                          </span>
                          <FastField
                            name='addresses[0].address.postalCode'
                            maxLength={50}
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'addresses[0].address.postalCode',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.postalCode' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Practice's Contact</h2>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          First Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='name[0].givenName'
                            maxLength={15}
                            required
                            placeholder='First Name'
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'name[0].givenName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='name[0].givenName' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Last Name <b className='require'>*</b>
                        </span>
                        <FastField
                          name='name[0].lastName'
                          maxLength={15}
                          required
                          placeholder='Last Name'
                          className='setting__search-form'
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            setFieldValue(
                              'name[0].lastName',
                              e.currentTarget.value.trim()
                            );
                          }}
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='name[0].lastName' />
                        </p>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Email Address <b className='require'>*</b>
                      </span>
                      <FastField
                        name='emailAddress'
                        maxLength={50}
                        placeholder='Email Address'
                        required
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'emailAddress',
                            e.currentTarget.value.trim()
                          );
                        }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setEmailError('');
                        }}
                      />
                      <p className='error-msg'>
                        {emailError ? (
                          emailError
                        ) : (
                          <ErrorMessage name='emailAddress' />
                        )}
                      </p>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Phone Number <b className='require'>*</b>
                      </span>
                      <div className='d-flex-wrap'>
                        <div className='margin-r-5 h-60 w-40'>
                          <FastField
                            name={`phoneNumbers[0].number`}
                            maxLength={50}
                            placeholder='Phone Number'
                            className='setting__search-form width-100'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                `phoneNumbers[0].number`,
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage
                              className='error-msg'
                              name={`phoneNumbers[0].number`}
                            />
                          </p>
                        </div>
                        <div className='margin-r-5 h-60 w-30'>
                          <FastField
                            name={`phoneNumbers[0].extension`}
                            maxLength={50}
                            placeholder='Ext.'
                            className='setting__search-form width-50 margin-r-0'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                `phoneNumbers[0].extension`,
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage
                              className='error-msg'
                              name={`phoneNumbers[0].extension`}
                            />
                          </p>
                        </div>
                        <div className='search-filter__global h-60'>
                          <Field
                            className='filter-form'
                            as='select'
                            name={`phoneNumbers[0].kind`}
                          >
                            <option value='cell'>Mobile Phone</option>
                            <option value='landline'>Home Phone</option>
                            <option value='business'>Office Phone</option>
                            <option value='other'>Other</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={cancelPopup}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid || !!nameError || !allowSubmit}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </ReactModal>
  );
};
export default PracticesModal;
