import React from 'react';
import './TelemedVideoCall.scss';
import VideoComponent from './video/VideoComponent';
import { Session } from '../types/SessionModel';
import { RoomResponse, SessionsResponse } from 'telemed-video-call/types/Response';
import { baseUrl } from 'telemed-video-call/environment';

const axios = require('axios');
require('dotenv').config();

interface TelemedVideoCallProps {
    appointmentId: string,
    accessToken: string,
    doctorName: string,
    teleMedKey: string | null,
}

const TelemedVideoCall = (props: TelemedVideoCallProps) => {
    const [isMobile] = React.useState<boolean>(false);
    const [isJoined, setIsJoined] = React.useState<boolean>(false);
    const [roomId, setRoomId] = React.useState<string>('');
    const [appointmentId, setAppointmentId] = React.useState<string | null>('');
    const [channelId, setChannelId] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [doctorName, setDoctorName] = React.useState<string | null>('');
    const [accessToken, setAccessToken] = React.useState<string | null>('');
    const [teleMedKey, setTeleMedKey] = React.useState<string | null>('');
    const [isStartRecording, setIsStartRecording] = React.useState<boolean>(false);
    const [isEndRecording, setIsEndRecording] = React.useState<boolean>(false);

    React.useEffect(() => {
        setAppointmentId(props.appointmentId);
        setAccessToken(props.accessToken);
        setDoctorName(props.doctorName);
        setTeleMedKey(props.teleMedKey);
        setIsJoined(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (appointmentId !== '' && accessToken !== '' && doctorName !== '') {
            handleEventJoinRoom();
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, accessToken, doctorName]);

    /**
     * Join room
     */
    const handleEventJoinRoom = () => {
        if (appointmentId) {
            axios.get(`${baseUrl}/appointments/V1/${appointmentId}/telemedDetails?providerName=${doctorName}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            }).then(async (response: RoomResponse) => {
                if (200 === response.status) {
                    setToken(response.data.accessDetails.accessToken);
                    setRoomId(response.data.accessDetails.roomIdentifier);
                    setChannelId(response.data.accessDetails.roomIdentifier);
                    getMeetingSession(response.data.accessDetails.roomIdentifier)
                }
            })
        }
    }

    /**
     * Create new a recordable session
     * @param isRecoding Using for api know create session (Room) have recordable or not
     * @param isEndcall Check event end call when in room recording
     */
    const createsMeetingSession = (isRecoding: boolean, isEndcall: boolean) => {
        // Check end call when not in recordable room, close window and don't create new session
        if (isEndcall && !isStartRecording) {
            window.close();
        } else {
            // If end call when doctor at recordable room, need create new session and close current session (Recording session)
            let data = {
                enableRecording: isRecoding,
            };
            axios.post(`${baseUrl}/telemed/V1/${teleMedKey}/sessions`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then((response: { status: number }) => {
                    if (201 === response.status) {
                        if (isRecoding) {
                            setIsStartRecording(true);
                            setIsEndRecording(false);
                        } else {
                            setIsEndRecording(true);
                            setIsStartRecording(false);

                        }
                        //@ts-ignore
                        localStorage.setItem('oldSessionTelemedicine', localStorage.getItem('currentSessionTelemedicine'));
                        closeSession(isRecoding, isEndcall);
                    }
                })
        }
    }

    /**
     * Get current session when join room or switch to recordable session
     */
    const getMeetingSession = (newRoomId: string) => {
        axios.get(`${baseUrl}/telemed/V1/${teleMedKey}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        }).then((response: SessionsResponse) => {
            if (200 === response.status) {
                let sessionId = response.data.sessions.filter((obj: Session) => { return obj.roomId === newRoomId })[0].sessionId;
                //@ts-ignore
                localStorage.setItem('currentSessionTelemedicine', sessionId);
            }
        })
    }

    /**
     * Close session when change to another session
     * When close success, join room again for change room
     */
    const closeSession = (isRecoding: boolean, isEndcall: boolean) => {
        let data = {
            status: "completed",
        }
        let session = isEndcall ? (localStorage.getItem('currentSessionTelemedicine')) : (localStorage.getItem('oldSessionTelemedicine'));
        console.log(session);
        axios.patch(`${baseUrl}/telemed/V1/${teleMedKey}/sessions/${session}`, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        }).then((response: { status: number }) => {
            if (isRecoding) {
                setIsStartRecording(true);
                setIsEndRecording(false);
            } else {
                setIsEndRecording(true);
                setIsStartRecording(false);

            }
            if (200 === response.status && isEndcall) {
                window.close();
            } else if (200 === response.status) {
                handleEventJoinRoom();
            }

        })
    }

    /**
     * Clear stage when leave room.
     */
    const handleEventLeaveRoom = () => {
        setToken('');
        setRoomId('');
        setAppointmentId('');
        setChannelId('');
    }

    return (
        <div>
            {token && roomId && channelId && isJoined && !isMobile
                ? <VideoComponent roomId={roomId}
                    channelId={channelId}
                    token={token}
                    accessToken={accessToken}
                    appointmentId={appointmentId}
                    eventLeaveRoom={handleEventLeaveRoom}
                    closeSession={closeSession}
                    isStartRecording={isStartRecording}
                    createsMeetingSession={createsMeetingSession}
                    isEndRecording={isEndRecording}
                />
                : null
            }
        </div>
    )
}
export default TelemedVideoCall
