import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { isSafari } from 'react-device-detect';

import TableComponent from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  ServiceLogs,
  SyncerLogsCollectionType,
} from 'global/requests/ResponseTypes/ServiceLogs';
import convertDateToTimestamp from 'utils/convertDateToTimestamp';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import Toast from 'components/Global/Toast';

interface IQuery {
  limit: number;
  offset: number;
  startDate: Date | null;
  endDate: Date | null;
  isReload: boolean;
  isCallApi: boolean;
}

type Props = {
  tenantId: string;
  reloadData: () => void;
};

const columns = [
  { key: 'time', title: 'Time' },
  { key: 'message', title: 'Message' },
  { key: 'action', title: '' },
];

const initialLogs = {
  offset: 0,
  count: 0,
  totalAvailable: 0,
  syncerErrorLogs: [],
  from: '',
  to: '',
};

const ModalError = ({ tenantId, reloadData }: Props) => {
  const [tenantLogs, setTenantLogs] =
    useState<SyncerLogsCollectionType>(initialLogs);
  const [queryPagination, setQueryPagination] = useState<IQuery>({
    limit: 10,
    offset: 0,
    startDate: null,
    endDate: null,
    isReload: false,
    isCallApi: true,
  });
  const [searchMessage, setSearchMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (queryPagination.isCallApi) {
      getTenantErrors(
        queryPagination,
        searchMessage,
        queryPagination.startDate === null
      );
    }
  }, [queryPagination]);

  const getTenantErrors = useCallback(
    async (
      query: IQuery,
      searchText: string,
      isMount?: boolean,
      isReload?: boolean
    ) => {
      try {
        setIsLoading(true);
        const res = await configuredRequests.GET.getPracticeErrors({
          tenantId,
          searchText,
          limit: query.limit,
          offset: query.offset,
          startTime: convertDateToTimestamp(query.startDate).toString(),
          endTime: convertDateToTimestamp(query.endDate).toString(),
        });

        if (res) {
          setTenantLogs(res);
          if (isMount) {
            setQueryPagination((prev) => ({
              ...prev,
              startDate: new Date(res.from),
              endDate: new Date(res.to),
              isCallApi: false,
            }));
          }
          if (query.isReload) {
            setQueryPagination((prev) => ({
              ...prev,
              endDate: new Date(res.to),
              isCallApi: false,
            }));
          }
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('err', err);
      }
    },
    [tenantId]
  );

  const handleReloadData = useCallback(() => {
    setQueryPagination((prevState: IQuery) => ({
      ...prevState,
      offset: 0,
      isReload: true,
      isCallApi: true,
    }));
  }, []);

  const handleSearchMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchMessage(e.target.value);
      setQueryPagination((prev) => ({ ...prev, offset: 0, isCallApi: false }));
      searchErrorsWithMessage(e.target.value);
    },
    [queryPagination]
  );

  const searchErrorsWithMessage = useCallback(
    debounce((name: string) => {
      getTenantErrors(queryPagination, name);
    }, 1000),
    [queryPagination]
  );

  const selectStartDate = useCallback((date: Date | null) => {
    setQueryPagination((preState) => ({
      ...preState,
      isCallApi: true,
      startDate: date,
    }));
  }, []);

  const selectEndDate = useCallback((date: Date | null) => {
    setQueryPagination((preState) => ({
      ...preState,
      isCallApi: true,
      endDate: date,
    }));
  }, []);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({
        ...preState,
        offset: newPage,
        isCallApi: true,
      }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination((prevState) => ({
          ...prevState,
          isCallApi: true,
          limit: newRowsPerPage,
          offset: 0,
        }));
      }
    },
    []
  );

  const handleDeleteError = useCallback(
    async (error: ServiceLogs) => {
      try {
        const res = await configuredRequests.DEL.deleteTenantSyncerError([
          error.serviceLogId ?? '',
        ]);

        if (res) {
          getTenantErrors(queryPagination, searchMessage);
          Toast.success('Deleted successfully!');
          reloadData();
        }
      } catch (err) {
        Toast.error('Failed to delete.');
        console.log('err', err);
      }
    },
    [queryPagination, searchMessage]
  );

  const handleMarkRead = useCallback(
    async (error: ServiceLogs) => {
      try {
        const res = await configuredRequests.PUT.markReadTenantSyncerError(
          !error.isRead,
          [error.serviceLogId ?? '']
        );

        if (res) {
          getTenantErrors(queryPagination, searchMessage);
          Toast.success('Your data has been saved successfully!');
          reloadData();
        }
      } catch (err) {
        Toast.error('Your data has not been saved yet. Please try again.');
        console.log('err', err);
      }
    },
    [queryPagination, searchMessage]
  );

  return (
    <>
      {isLoading ? (
        <Loading className='loading-spinner' />
      ) : (
        <>
          <div className='top-filter'>
            <div>
              <div className='select-date'>
                <DateTimePicker
                  // @ts-ignore
                  setStartDate={selectStartDate}
                  // @ts-ignore
                  setEndDate={selectEndDate}
                  showTimePicker={true}
                  startDate={queryPagination.startDate}
                  endDate={queryPagination.endDate}
                  getEndDate={false}
                />
              </div>
              <Button
                variant='contained'
                color='primary'
                className={'service-log--refresh'}
                onClick={handleReloadData}
              >
                <FontAwesomeIcon
                  icon={faSync}
                  className='service-log--refresh-button'
                />
              </Button>
            </div>
            <div
              className='daily-appointment__filter'
              data-testid='Telemed-define'
            >
              <form
                className={
                  isSafari
                    ? 'search-safari'
                    : 'search-form search-filter__global'
                }
                onSubmit={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  searchErrorsWithMessage(searchMessage);
                }}
              >
                <FontAwesomeIcon icon={['far', 'search']} />
                <input
                  type='search'
                  placeholder='Search by message'
                  className='search-form__input'
                  value={searchMessage}
                  name='message'
                  onChange={handleSearchMessage}
                />
              </form>
            </div>
          </div>

          {tenantLogs.syncerErrorLogs.length > 0 ? (
            <TableComponent
              columns={columns}
              // @ts-ignore
              rows={tenantLogs.syncerErrorLogs.map((item) => ({
                time: moment(new Date(item.createdDate || '')).format(
                  'MM/DD/YYYY hh:mm:ss A'
                ),
                message: item.message,
                action: (
                  <>
                    <button
                      className={`btn-mark ${item.isRead ? 'unread' : 'read'}`}
                      onClick={() => handleMarkRead(item)}
                    >
                      Mark as {item.isRead ? 'unread' : 'read'}
                    </button>
                    <button
                      className='btn-delete'
                      onClick={() => handleDeleteError(item)}
                    >
                      Delete
                    </button>
                  </>
                ),
              }))}
              isHasPagination={true}
              // @ts-ignore
              totalAvailable={tenantLogs.totalAvailable ?? 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryPagination?.limit ?? 10}
              page={queryPagination?.offset ?? 0}
            />
          ) : (
            <p className='no-data-results' style={{ fontWeight: 'bold' }}>
              No results found
            </p>
          )}
        </>
      )}
    </>
  );
};

export default ModalError;
