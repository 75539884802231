import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: inherit;
`;

// eslint-disable-next-line import/prefer-default-export
export { Container };
