import moment from 'moment';
import {
  IEmailStat,
  IPerformanceStats,
  IUnsuccessfulStats,
  IUnsuccessfulTemplate,
  IUnsubscribers,
} from './types';
import ApiService from '../clientApi';

const Api = ApiService();

const getPerformanceStats = async (
  params: (Date | string | undefined)[],
): Promise<IPerformanceStats> => {
  const result = await Api.rpc('performance.email', { method: 'getTouchpointStats', params });
  return result.p[1];
};
// null | string = search paramter
const getEmailTemplates = async (
  params: [
    string | undefined,
    string | undefined,
    null | string | undefined,
    number,
    number,
    { column: string; order: 'ASC' | 'DESC' },
  ],
): Promise<IEmailStat[]> => {
  const result = await Api.rpc('performance.email', { method: 'getEmailStats', params });
  return result.p[1];
};

const getUnsuccessfulStats = async (
  params: (Date | string | undefined)[],
): Promise<IUnsuccessfulStats> => {
  const result = await Api.rpc('performance.email', { method: 'getUnsuccessfulStats', params });
  return result.p[1];
};

const getUnsuccessfulTemplates = async (
  params: (Date | string | undefined)[],
): Promise<IUnsuccessfulTemplate[]> => {
  const result = await Api.rpc('performance.email', { method: 'getUnsuccessfulEmails', params });
  return result.p[1];
};

// number params are pagination.
const getUnsubscribers = async (
  params: [null | Date | string, null | Date | string, number, number],
): Promise<IUnsubscribers[]> => {
  const result = await Api.rpc('performance.email', { method: 'getUnsubscribedEmails', params });
  return result.p[1].sort((x: IUnsubscribers, y: IUnsubscribers) =>
    moment(x.date) < moment(y.date) ? 1 : -1,
  );
};

const getSpamMarkers = async (
  params: [null | Date | string, null | Date | string, number, number],
): Promise<IUnsubscribers[]> => {
  const result = await Api.rpc('performance.email', { method: 'getSpamEmails', params });
  return result.p[1].sort((x: IUnsubscribers, y: IUnsubscribers) =>
    moment(x.date) < moment(y.date) ? 1 : -1,
  );
};

const getInvalidEmails = async (
  params: [null | Date | string, null | Date | string, number, number],
): Promise<IUnsubscribers[]> => {
  const result = await Api.rpc('performance.email', { method: 'getInvalidEmails', params });
  return result.p[1].sort((x: IUnsubscribers, y: IUnsubscribers) =>
    moment(x.date) < moment(y.date) ? 1 : -1,
  );
};

export default {
  getPerformanceStats,
  getEmailTemplates,
  getUnsuccessfulStats,
  getUnsuccessfulTemplates,
  getUnsubscribers,
  getSpamMarkers,
  getInvalidEmails,
};
