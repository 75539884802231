const styles = {
  main: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    flex: '1 1 auto',
  },
  linkIcon: {
    paddingRight: '6px',
  },
  centerContainer: {
    flex: '1 1 auto',
    textAlign: 'center',
  },
  rightContainer: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  noChrome: {
    container: {
      padding: '0 10px',
    },
    title: {
      position: 'absolute',
      width: '100%',
    },
    rightContainer: {
      width: '100%',
      justifyContent: 'flex-end',
      zIndex: 1,
    },
  },
};

export default styles;
