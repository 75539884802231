import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import EditorBox from 'components/Communications/EditorBox';
import ModalInsertPersonalize from 'components/Communications/ModalInsertPersonalize';
import FilterGlobal from 'components/Global/Filter/Filter';
import { characterLimit } from 'components/Messenger/MessengerInput';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { dataTemplate } from 'global/requests/dataUserSettingResponseError';
import {
  MessagePayloadKindsEnum,
  SingleUserSendEmailPayload,
} from 'global/requests/RequestTypes';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
  TemplateResponse,
} from 'global/requests/ResponseTypes';
import { detectRoleAndScreen } from 'hooks/useDecodeToken';
import React, { useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useDecodeToken } from 'hooks/useDecodeToken';

const WrapArea = styled.div`
  display: flex;

  #textArea {
    font-weight: 600;
  }

  .btn-personalize {
    margin-left: 10px;
  }
`;

interface IMessageEditor {
  // receiverUserIDs: string[],
  // checkNewEmail(a: boolean): void,
  // searchValue?: string,
  // filterValue?: string,
  // isSentToAllContact?: boolean,
  // closeModal?: (type: string) => void,

  // emailRecipient?: string,
  // setIsDirtyForm?: React.Dispatch<React.SetStateAction<boolean>>,
  // sendNotifyAppointmentBooking?: (actionKind: number, payload: NotifyBody) => void,
  // setTypeSelect?: React.Dispatch<React.SetStateAction<"accept" | "deny" | "email" | "view" | null>>,
  // sendEmailSupport?: (payload: SendEmailSupportSubmissionPayload) => void,
  payloadToSendMess: SingleUserSendEmailPayload;
  errorMessage: string;
  errorEmailSubject: boolean;
  setPayloadToSendMess: (payload: SingleUserSendEmailPayload) => void;
  setErrorEmailSubject: (arg: boolean) => void;
  setErrorMessage: (payload: string) => void;
  useCustomSubmit?: {
    innerSubmitRefObject: React.RefObject<HTMLButtonElement>;
    setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  };
  screen?: string;
}

const messageBodyCharacterLimit = 999999;

const MessageEditor = (props: IMessageEditor): JSX.Element => {
  const initPayload: SingleUserSendEmailPayload = {
    content: '',
    kind: MessagePayloadKindsEnum.email,
    subject: '',
    templateId: '',
    scheduleDate: '',
  };

  const {
    errorMessage,
    errorEmailSubject,
    payloadToSendMess,
    setPayloadToSendMess,
    setErrorEmailSubject,
    setErrorMessage,
    screen,
  } = props;

  // const { features } = detectRoleAndScreen(
  //   window.sessionStorage.getItem('AuthToken') || ''
  // );

  const { allowedPath } = useDecodeToken();

  const listOptionsSendMess = useMemo(
    () =>
      allowedPath.includes('/Messenger')
        ? [
            { label: 'Email', value: 'email' },
            { label: 'SMS', value: 'sms' },
          ]
        : [{ label: 'Email', value: 'email' }],
    [allowedPath]
  );

  const [optionSendMessage, setOptionSendMessage] = React.useState(
    listOptionsSendMess[0]
  );
  const [optionsTemplate, setOptionsTemplate] = React.useState<
    OptionFilterDropdownObject[]
  >([]);
  const [emailTemplate, setEmailTemplate] =
    React.useState<TemplateResponse>(dataTemplate);
  const [editorHasUnsavedChanges, setEditorHasUnsavedChanges] =
    React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    label: 'Blank Email Template',
    value: '',
  });
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [openPopupInsert, setOpenPopupInsert] = React.useState(false);
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    handleOnChangeEditor(el.value);
    // @ts-ignore
    // setPayloadToSendMess((prev) => ({ ...prev, content: el.value }));
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };

  // useEffect(() => {
  //   if (props.sendNotifyAppointmentBooking || props.sendEmailSupport) {
  //     const isBlankError = !errorEmailSubject && !errorEmptyEmailBody && !errorRecipients && !errorCharacterLimitReached;
  //     const isFullFillData = payloadToSendEmail.content && payloadToSendEmail.subject;
  //     // @ts-ignore
  //     props.useCustomSubmit?.setIsSubmitEnabled(isBlankError && isFullFillData);
  //   }
  // }, [props.useCustomSubmit, props.sendEmailSupport, props.sendNotifyAppointmentBooking, errorEmailSubject, errorEmptyEmailBody, errorRecipients, errorCharacterLimitReached, payloadToSendEmail]);

  const getTemplates = async (option: string) => {
    try {
      const isEmailTemplate = option === 'email';

      const templateResponse = await configuredRequests.GET.templateByKind([
        isEmailTemplate ? TemplateKindsEnum.email : TemplateKindsEnum.sms,
      ]);
      const blankTemplate = isEmailTemplate
        ? {
            categoryKind: 'email',
            name: 'Blank Email Template',
            targetUserTypeIds: [
              '6008b81b-b018-4981-a132-2426b6bf5481',
              'da5094da-3141-4db4-b501-10d8914436fd',
            ],
            templateBody: '',
            templateId: '',
            templateKind: '',
            templateSubject: '',
          }
        : {
            categoryKind: 'sms',
            name: 'Blank SMS Template',
            targetUserTypeIds: [
              '6008b81b-b018-4981-a132-2426b6bf5481',
              'da5094da-3141-4db4-b501-10d8914436fd',
            ],
            templateBody: '',
            templateId: '',
            templateKind: '',
          };
      setEmailTemplate({
        ...templateResponse,
        items: [blankTemplate, ...templateResponse.items],
      });
      // add default value to initial array instead of unshifting, more optimal and uses const :)
      const dataSelected: Array<OptionFilterDropdownObject> = [
        {
          label: `Blank ${isEmailTemplate ? 'Email' : 'SMS'} Template`,
          value: '',
        },
      ];
      templateResponse.items.forEach((item) => {
        dataSelected.push({
          label: item.name ?? '',
          value: item.templateId ?? '',
        });
      });
      setOptionsTemplate(dataSelected);
    } catch (err) {
      console.log(
        `error getting ${option === 'email' ? 'email' : 'sms'} template`,
        err
      );
    }
  };

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // Need to destructure properties so it can compare the primitive values
  // If you give useEffect an object dependency then the callback will run on every render
  const { content, subject } = payloadToSendMess;

  // React.useEffect(() => {
  //   if (errorRecipients.length || !subject.length || !content.length || content.length > messageBodyCharacterLimit) {
  //     setIsDisabled(true);
  //     return;
  //   }
  //   if (content.length && subject.length && !errorRecipients.length) {
  //     setIsDisabled(false);
  //   }
  // }, [subject, content, errorRecipients]);

  // useEffect(() => {
  //   props.setIsDirtyForm && props.setIsDirtyForm(!isEqual(payloadToSendEmail, initPayload));
  // }, [payloadToSendEmail, initPayload, props]);

  const handleSelectTypeMessage = (e: any) => {
    if (optionSendMessage.value !== e.value) {
      setOptionSendMessage(e);
      setSelectedTemplate({ label: `Blank ${e.label} Template`, value: '' });
      setErrorEmailSubject(false);
      setErrorMessage('');
    }
    setPayloadToSendMess({
      content: '',
      kind: e.value,
      subject: '',
      templateId: '',
      scheduleDate: '',
    });
    getTemplates(e.value);
  };

  const handleSelectTemplate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    setSelectedTemplate(newSelectedItem);
    setErrorEmailSubject(false);
    setErrorMessage('');
    // setErrorEmptyEmailBody(false);
    // @ts-ignore
    setPayloadToSendMess((prev: SingleUserSendEmailPayload) => ({
      ...prev,
      templateId: newSelectedItem.value,
    }));
    if (!newSelectedItem.value) {
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    emailTemplate.items.forEach((item) => {
      if (item.templateId === newSelectedItem.value) {
        // setIsDisabled(false);
        // @ts-ignore
        setPayloadToSendMess((prev) => ({
          ...prev,
          subject: item.templateSubject ? item.templateSubject : '',
          content: item.templateBody ? item.templateBody : '',
        }));
      }
    });
  };

  const handleChangeDataEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;
    if (targetValue.length) {
      setEditorHasUnsavedChanges(true);
      setErrorEmailSubject(false);
      // if (payloadToSendEmail.content.length && !errorEmptyEmailBody) {
      //   props.useCustomSubmit?.setIsSubmitEnabled(true);
      // }
    } else {
      setEditorHasUnsavedChanges(false);
      setErrorEmailSubject(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    // @ts-ignore
    setPayloadToSendMess((prev) => ({ ...prev, subject: targetValue }));
  };

  const handleTrimData = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    if (name === 'emailSubject' && !event.target.value.trim().length) {
      setErrorEmailSubject(true);
    }
    // else {
    //   setErrorEmailSubject(false);
    // }
    if (name === 'smsMessage' && !event.target.value.trim().length) {
      setErrorMessage('This field is required.');
    }
    const dataTrim = event.target.value.trim();
    // @ts-ignore
    setPayloadToSendMess((prev) =>
      name === 'emailSubject'
        ? { ...prev, subject: dataTrim }
        : { ...prev, content: dataTrim }
    );
  };

  const handleBlurMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {};

  const warnUserUnsavedChanges = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return true;
  };

  // add beforeunload listener when editorHasUnsavedChanges === true
  React.useEffect(() => {
    if (editorHasUnsavedChanges) {
      window.addEventListener('beforeunload', warnUserUnsavedChanges);
      return () => {
        window.removeEventListener('beforeunload', warnUserUnsavedChanges);
      };
    }
    window.removeEventListener('beforeunload', warnUserUnsavedChanges);
  }, [editorHasUnsavedChanges]);

  React.useEffect(() => {
    getTemplates('email');
    getTokens();
  }, []);

  // This callback is only called after TinyMCE updates the DOM element's content.
  // There is no way to stop the content update inside this callback.
  // Instead we will have to display the error text "Character limit reached"
  // We also need to check the length in the submit function
  const handleOnChangeEditor = (newContent: string) => {
    if (newContent.length > messageBodyCharacterLimit) {
      setErrorMessage(
        `The character limit has been reached ${messageBodyCharacterLimit}`
      );
      // setErrorCharacterLimitReached(true);
    } else if (!newContent) {
      // setErrorCharacterLimitReached(false);
      setErrorMessage('This field is required.');
    } else {
      setErrorMessage('');
    }
    // contains text of length 1+
    // if (newContent.length > 1) {
    //   if (payloadToSendEmail.subject.length && !errorEmptyEmailBody) {
    //     props.useCustomSubmit?.setIsSubmitEnabled(true);
    //   }
    // }

    if (optionSendMessage.value === 'email') {
      const b = '<body>';
      const be = '</body>';
      const messageBody = newContent.substring(
        newContent.indexOf(b) + b.length,
        newContent.indexOf(be) - 1
      );

      if (!messageBody.trim()) {
        setErrorMessage('This field is required.');
        // setIsDisabled(true);
        props.useCustomSubmit?.setIsSubmitEnabled(false);
      } else {
        setErrorMessage('');
      }
    }
    // @ts-ignore
    setPayloadToSendMess((prev) => ({ ...prev, content: newContent }));
  };

  const handleTextAreaInput = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setErrorMessage('');
    const targetValue = event.currentTarget.value;
    if (targetValue.length === characterLimit) {
      setErrorMessage(
        `Message body cannot exceed ${characterLimit} characters.`
      );
    }
    if (targetValue === '') {
      setErrorMessage('This field is required.');
    }
    // setTextBody(targetValue);
    // @ts-ignore
    setPayloadToSendMess((prev) => ({ ...prev, content: targetValue }));
  };

  const handleEmptyContent = () => {
    setErrorMessage('This field is required.');
    props.useCustomSubmit?.setIsSubmitEnabled(false);
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    typeInTextarea(
      document.getElementById('textArea'),
      `{{${personalizationTokenSelected}}}`
    );
    setOpenPopupInsert(false);
  };

  return (
    <div className='contact-email-editor'>
      {/* <Prompt
        when={editorHasUnsavedChanges}
        message={'WARNING! You may have unsaved changes'}
      /> */}

      <p>Primary Message</p>
      <div className='select-option-message'>
        <FilterGlobal
          handleSetNewFilterValue={handleSelectTypeMessage}
          optionsForFilter={listOptionsSendMess}
          valueSelected={optionSendMessage}
        />
        <FilterGlobal
          handleSetNewFilterValue={handleSelectTemplate}
          optionsForFilter={optionsTemplate}
          valueSelected={selectedTemplate}
        />
      </div>

      {optionSendMessage.value === 'email' && (
        <>
          <p>
            Email Subject <b className='require'>*</b>
          </p>
          <input
            className='input-text'
            name='emailSubject'
            value={subject}
            onChange={handleChangeDataEmailSubject}
            onBlur={handleTrimData}
            maxLength={255}
          />
        </>
      )}
      {errorEmailSubject && (
        <p className='error-msg'> This field is required. </p>
      )}

      <p>
        Message Body <b className='require'>*</b>
      </p>
      {optionSendMessage.value === 'email' ? (
        <EditorBox
          value={content}
          handleChangeEditor={handleOnChangeEditor}
          handleEmptyContent={handleEmptyContent}
          token={tokenData}
          screen={screen ?? 'manual'}
        />
      ) : (
        <WrapArea className='row-message-provider'>
          <textarea
            id='textArea'
            value={content}
            // onChange={(e: any) => handleOnChangeEditor(e.target.value)}
            onInput={handleTextAreaInput}
            onBlur={handleTrimData}
            name='smsMessage'
            maxLength={characterLimit}
          />
          <button
            id='personalizeButton'
            className='btn-personalize'
            onClick={() => setOpenPopupInsert(true)}
          >
            {' Personalize '}
          </button>
        </WrapArea>
      )}
      <p className='error-msg'>{errorMessage}</p>
      <ModalInsertPersonalize
        openPopupInsertToken={openPopupInsert}
        setOpenPopupInsertToken={setOpenPopupInsert}
        handleConfirmInsertToken={handleInsertData}
        token={tokenData}
        screen={screen ?? 'manual'}
      />
    </div>
  );
};

export default MessageEditor;
