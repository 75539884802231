import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const { SegmentedMessage } = require('sms-segments-calculator');

import {
  TemplateData,
  PersonalizationTokensResponse,
} from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import InputWithLabel from 'components/Global/InputWithLabel/InputWithLabel';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import ModalInsertPersonalize from './ModalInsertPersonalize';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import Filter from 'components/Global/Filter/Filter';
import {
  initialTemplateValue,
  initialTokenValue,
  optionTimes,
  optionUnitTimes,
} from './EditEmailTemplate';
import { characterLimit } from 'components/Messenger/MessengerInput';

const EditSMSTemplate = (): JSX.Element => {
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    setTemplateData({ ...templateData, templateBody: el.value });
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };

  const [openPopup, setOpenPopup] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [error, setError] = React.useState('');

  const { templateId } = useParams<{ templateId: string }>();
  const { category } = useParams<{ category: string }>();
  const history = useHistory();
  const [templateData, setTemplateData] =
    React.useState<TemplateData>(initialTemplateValue);
  const [loading, setLoading] = React.useState(true);

  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);

  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [errorTemplateName, setErrorTemplateName] =
    React.useState<boolean>(false);
  const [isEdited, setValueEdited] = React.useState('noChange');
  const [title, setTitle] = React.useState<string>('');
  const [optionDurations, setOptionDurations] = useState(optionTimes);
  const [optionUnits, setOptionUnits] = useState(optionUnitTimes);

  const getTemplateAndTokenDataRequest = async () => {
    try {
      if (templateId) {
        const templateResponse = await configuredRequests.GET.templateByID(
          templateId
        );
        setTemplateData((prevTemplate) => ({
          ...prevTemplate,
          ...templateResponse,
        }));
        setTitle(templateResponse.name || '');
      }
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getSettingDurationTimes = async () => {
    try {
      const res = await configuredRequests.GET.getSettingDurationTimes();
      if (res) {
        setOptionDurations(
          // @ts-ignore
          res.definedValues.map((item: any) => ({
            label: item.name,
            value: item.value.toString(),
          }))
        );
        setOptionUnits(
          // @ts-ignore
          res.unitKinds.map((item: any) => ({
            label: item.name,
            value: item.value,
          }))
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  // get personalized token and record data if is editing
  React.useEffect(() => {
    getTemplateAndTokenDataRequest();
    getSettingDurationTimes();
    window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
  }, [isEdited]);

  const handleChangeDataInput = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setError('');
    event.preventDefault();
    const value = event.currentTarget.value;

    const segmentedMessage = new SegmentedMessage(value);

    setValueEdited('hasChanged');

    if (value.length === 0) {
      setError('This field is required.');
    }
    if (value.length >= characterLimit) {
      setError(`Message body cannot exceed ${characterLimit} characters.`);
    }
    if (segmentedMessage.segmentsCount > 1 && templateData.categoryKind != "manual" && category != "manual") {
      setError("More than one segment in the message");
    }

    setTemplateData({
      ...templateData,
      templateBody: value,
    });
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    typeInTextarea(
      document.getElementById('textArea'),
      `{{${personalizationTokenSelected}}}`
    );
    setOpenPopup(false);
  };

  const handleGoBackRouter = () => {
    setValueEdited('noChange');
    setOpenWarningPopup(false);
    history.goBack();
  };

  React.useEffect(() => {
    const segmentedMessage = new SegmentedMessage(templateData.templateBody);
    if (
      !templateData.templateBody ||
      templateData.templateBody.length > characterLimit ||
      !templateData.name
    ) {
      setIsDisabled(true);
    } else if (segmentedMessage.segmentsCount > 1 && templateData.categoryKind != "manual" && category != "manual") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    
  }, [templateData.templateBody, templateData.name]);

  const handleOnClickSave = async () => {
    if (!templateData.templateBody) {
      setError('This field is required.');
      toast.error('Your data has not been saved yet. Please try again.');
      return;
    }
    if (templateData.templateBody.length > characterLimit) {
      setError(`Message body cannot exceed ${characterLimit} characters.`);
      toast.error('Your data has not been saved yet. Please try again.');
      return;
    }
    const segmentedMessage = new SegmentedMessage(templateData.templateBody);
    if (segmentedMessage.segmentsCount > 1 && templateData.categoryKind != "manual" && category != "manual") {
      setError("More than one segment in the message");
      toast.error('Your data has not been saved yet. Please try again.');
      return;
    }
    setIsDisabled(true);

    if (!templateId) {
      const createResponse = await configuredRequests.POST.createTemplate({
        ...templateData,
        name: templateData.name?.trim() ?? '',
        templateBody: templateData.templateBody
          ? templateData.templateBody.trim()
          : '',
        templateKind: TemplateKindsEnum.sms,
        categoryKind: category,
      });
      if (createResponse) {
        setValueEdited('noChange');
        history.goBack();
        toast.success('Your data has been saved successfully!');
      }
    } else {
      const updateResponse = await configuredRequests.PATCH.updateTemplate(
        templateId,
        {
          ...templateData,
          name: templateData.name?.trim() ?? '',
          templateBody: templateData.templateBody
            ? templateData.templateBody.trim()
            : '',
        }
      );
      if (updateResponse) {
        setTitle(updateResponse.name || '');
        setValueEdited('noChange');
        toast.success('Your data has been saved successfully!');
      }
    }
    setIsDisabled(false);
  };

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'templateName':
        if (event.target.value.length) {
          setErrorTemplateName(false);
        } else {
          setErrorTemplateName(true);
          // break; // seems like this should break here
        }
        setTemplateData({ ...templateData, name: event.target.value });
        setValueEdited('hasChanged');
        break;

      default:
        break;
    }
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'templateName':
        if (value.length) {
          setErrorTemplateName(false);
        } else {
          setErrorTemplateName(true);
        }
        break;

      case 'templateBody':
        if (value.length === 0) {
          setError('This field is required.');
        } else if (value.length === characterLimit) {
          setError(`Message body cannot exceed ${characterLimit} characters.`);
        } else {
          setError('');
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <div className='confirmation-email-title'>
            <h2>{templateId ? title : 'Add New Template'}</h2>
            {/* {templateId && ( */}
            <Button
              variant='contained'
              color='default'
              className='button-form cancel-button'
              onClick={() => {
                if (isEdited === 'noChange') {
                  return history.goBack();
                } else {
                  setOpenWarningPopup(true);
                }
              }}
            >
              Cancel
            </Button>
            {/* } */}
            <Button
              variant='contained'
              color='primary'
              className='button-form'
              onClick={handleOnClickSave}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>

          <div className='confirmation-email-container'>
            <div className='flex'>
              <div className='template--name-duration'>
                <InputWithLabel
                  htmlFor='templateName'
                  type='text'
                  labelText='Template Name'
                  value={templateData.name}
                  onChange={handleOnChangeInput}
                  maxLength={255}
                  onBlur={handleOnBlur}
                  isRequired={true}
                  classNames={{
                    section: 'mw-190',
                  }}
                />
                <div className='select--full-width custom-scroll'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='onOnlyOnceEvery'
                        color='primary'
                        checked={templateData.onOnlyOnceEvery}
                        onChange={() => {
                          setTemplateData((prev) => ({
                            ...prev,
                            onOnlyOnceEvery: !prev.onOnlyOnceEvery,
                          }));

                          setValueEdited('hasChanged');
                        }}
                      />
                    }
                    className='checkbox--small'
                    label='Only once every: '
                  />
                  <Filter
                    optionsForFilter={optionDurations}
                    handleSetNewFilterValue={(newTimeValue) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        duration: Number(newTimeValue.value),
                      }));
                      setValueEdited('hasChanged');
                    }}
                    valueSelected={optionDurations.find(
                      (option) =>
                        option.value ===
                        (templateData?.duration?.toString() ?? '1')
                    )}
                  />
                  <Filter
                    optionsForFilter={optionUnits}
                    handleSetNewFilterValue={(newUnitTime) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        unitOfDuration: newUnitTime.value,
                      }));

                      setValueEdited('hasChanged');
                    }}
                    valueSelected={optionUnits.find(
                      (option) =>
                        option.value ===
                        (templateData?.unitOfDuration ?? 'hours')
                    )}
                  />
                </div>
              </div>
              {errorTemplateName && (
                <p className='error-msg'> This field is required. </p>
              )}
            </div>
            <label htmlFor='messageBody'>
              Message Body <b className='require'>*</b>
            </label>
            <div className='message-body'>
              <div className='flex1180'>
                <textarea
                  name='templateBody'
                  id='textArea'
                  onChange={handleChangeDataInput}
                  // defaultValue={templateData.templateBody}
                  onBlur={handleOnBlur}
                  value={templateData.templateBody}
                  maxLength={characterLimit}
                />
                {error && <p className='error-msg'> {error} </p>}
              </div>
              <button
                id='personalizeButton'
                className='btn-personalize'
                onClick={() => setOpenPopup(true)}
              >
                {' Personalize '}
              </button>
              <ModalInsertPersonalize
                openPopupInsertToken={openPopup}
                setOpenPopupInsertToken={setOpenPopup}
                handleConfirmInsertToken={handleInsertData}
                token={tokenData}
                screen={(category ?? templateData.categoryKind)
                  .toLowerCase()
                  .trim()}
              />
            </div>
          </div>
        </>
      )}
      <ModalDiscard
        isOpenModal={openWarningPopup}
        handleCloseModal={setOpenWarningPopup}
        handleConfirmModal={handleGoBackRouter}
      />
    </>
  );
};

export default EditSMSTemplate;
