import styled from 'styled-components';
import getThemeProp from 'components/Legwork/utils/getThemeProp';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  .MuiAvatar-root {
    height: 32px;
    width: 32px;
    font-size: 14px;
    color: #4c7cfd;
  }
`;

const Main = styled.div`
  max-width: ${(props: { width?: number }): string => (props.width ? `${props.width}px` : '300px')};
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 32px;
  margin-bottom: 0.3rem;
`;

const StyledTypographyBase = styled(Typography)`
  margin: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
` as typeof Typography;

const StyledH3 = styled(StyledTypographyBase)`
  font-size: 15px;
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  font-weight: ${getThemeProp('typography.h3.fontWeight', '500')};
  color: ${getThemeProp('typography.h3.color', '#12253F')};
` as typeof Typography;

const StyledP = styled(StyledTypographyBase)`
  font-size: 13px;
  font-weight: 400;
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: #606d7e;
` as typeof Typography;

export { Main, StyledH3, StyledP, Container };
