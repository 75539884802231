import * as t from 'io-ts';

const SexEntry = t.type({
  id: t.string,
  name: t.string,
  externalId: t.string,
});

export const SexCollectionEntry = t.type({
  count: t.number,
  totalAvailable: t.number,
  petSex: t.array(SexEntry),
});

export type SexResponse = t.TypeOf<typeof SexEntry>;
export type SexCollection = t.TypeOf<typeof SexCollectionEntry>;
