import React from 'react';
import Modal from '@material-ui/core/Modal';
import { StyledDiv } from './styled-components';

function getModalStyle(fromTop: number, width: number): { top: string; left: string } {
  const left = (100 - width) / 2;
  return {
    top: `${fromTop}%`,
    left: `${left}%`,
  };
}

export interface GeneralModalProps {
  children: React.ReactElement[];
  fromTop?: number;
  width?: number;
  modalOpen: boolean;
  reportClosed?: (arg0: boolean) => void;
  ariaLabel?: string;
  ariaDescription?: string;
}

const GeneralModal = ({
  ariaLabel,
  ariaDescription,
  children,
  fromTop = 10,
  width = 46,
  modalOpen,
  reportClosed,
}: GeneralModalProps): React.ReactElement => {
  const { top, left } = getModalStyle(fromTop, width);
  const [open, setOpen] = React.useState(!!modalOpen);

  const handleClose = (): void => {
    if (reportClosed) {
      reportClosed(false);
    }
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby={ariaLabel || 'simple-modal-title'}
        aria-describedby={ariaDescription || 'simple-modal-description'}
        open={open}
        onClose={handleClose}
      >
        <StyledDiv top={top} left={left} overrideWidth={width}>
          {children}
        </StyledDiv>
      </Modal>
    </div>
  );
};

export default GeneralModal;
