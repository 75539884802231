import React, { useState, useCallback, useEffect, useContext } from 'react';
import listDateInitial from './constant/listDateInitial.json';
import AvailabilityV from './AvailabilityV';
import { isEmpty } from 'lodash';
import { compareTimePicker } from './helpers/compareTimePicker';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isWindows } from 'react-device-detect';
import { DurationType } from './components/OABIntervalSetting';
import { AvailabilityContext } from './context/Availability';

export interface ListDate {
  dayOfWeekName: string;
  active: boolean;
  startTime: string;
  endTime: string;
  takeLunch: boolean;
  startTimeLunch: string;
  endTimeLunch: string;
  dayOrder: number;
  error?: string;
  errorLunch?: string;
}

export interface OfficeEvent {
  id: string;
  name: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  allDayEvent: boolean;
  repeat: number;
  repeatLabel: string;
  repeatEndDate: string;
  repeatEndDateLabel: string;
  tenantId: string;
  providerId: string;
}

const AvailabilityVM = (): JSX.Element => {
  const { setDisabledSubmit } = useContext(AvailabilityContext);

  const [listDate, setListDate] = useState<ListDate[]>([]);
  const [listOfficeOff, setListOfficeOff] = useState<OfficeEvent[]>([]);
  const [isShowModalAddOfficeEvent, changeShowModalAddOfficeEvent] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<OfficeEvent | null>(null);
  const [isLoadingHourOfOperation, setIsLoadingHourOfOperation] =
    useState<boolean>(false);
  const [isLoadingOABTimeUnits, setIsLoadingOABTimeUnits] =
    useState<boolean>(false);
  const [isLoadingMinimumAvai, setIsLoadingMinimumAvai] = useState(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false);
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [isSelectedDelete, setIsSelectedDelete] = useState<boolean>(false);
  const [errorExistEventName, setErrorExistEventName] =
    React.useState<string>('');
  const [tenantOABTimeUnits, setTenantOABTimeUnits] = useState<number>(10);
  const [minimumAvailability, setMinimumAvailability] = useState('0');
  const [optionsMinimum, setOptionsMinimum] = useState<DurationType[]>([]);

  const onChangeErrorExistEventName = useCallback((error: string) => {
    setErrorExistEventName(error);
  }, []);

  const onChangeIsDirtyForm = useCallback((isDirty: boolean) => {
    setIsDirtyForm(isDirty);
  }, []);

  const getOfficeUnavailabilty = useCallback(async () => {
    setIsLoadingEvent(true);
    try {
      const tenantId = getSessionTenantInfo()?.tenantId ?? '';
      if (tenantId) {
        const response = await configuredRequests.GET.getOfficeUnavailabilty(
          tenantId
        );
        if (response?.result) {
          setIsLoadingEvent(false);
          setListOfficeOff(
            response?.result
              // @ts-ignore
              ?.map((officeEvent: OfficeEvent) => ({
                ...officeEvent,
              }))
              .sort((officeA: OfficeEvent, officeB: OfficeEvent) => {
                let a = new Date(officeA.eventDate);
                let b = new Date(officeB.eventDate);
                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                return 0;
              })
          );
        }
      }
    } catch (error) {
      setIsLoadingEvent(false);
    }
  }, []);

  const deleteOutOfOfficeEvent = useCallback(
    async (id: string) => {
      try {
        const response = await configuredRequests.DEL.deleteOfficeEvent(id);
        // @ts-ignore
        if (response.status === 200) {
          toast.success(
            <div className='notify-success d-flex'>
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className='icon-check-status'
              />
              <div className='success-message'>
                <b>Successfully</b>
                <p>Your data has been deleted successfully!</p>
              </div>
            </div>,
            { className: 'toast-success' }
          );
          setIsSelectedDelete(false);
          setSelectedEvent(null);
          getOfficeUnavailabilty();
        } else {
          toast.error(
            <div className='notify-unsuccess d-flex'>
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                className='icon-check-status'
              />
              <div className='unsuccess-message'>
                <b>Error</b>
                <p>Your data has not been deleted yet. Please try again.</p>
              </div>
            </div>,
            { className: 'toast-unsuccess' }
          );
        }
      } catch (error) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Your data has not been deleted yet. Please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    },
    [getOfficeUnavailabilty]
  );

  const createOutOfOfficeEvent = useCallback(
    async (payload: OutOfOfficeEvent) => {
      try {
        setDisabledSubmit(true);
        const response = await configuredRequests.POST.createOutOfOfficeEvent(
          payload
        );
        // @ts-ignore
        if (response.status === 200) {
          setErrorExistEventName('');
          toast.success(
            <div className='notify-success d-flex'>
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className='icon-check-status'
              />
              <div className='success-message'>
                <b>Successfully</b>
                <p>Your data has been saved successfully!</p>
              </div>
            </div>,
            { className: 'toast-success' }
          );
          getOfficeUnavailabilty();
          changeShowModalAddOfficeEvent(false);
          setIsDirtyForm(false);
          setDisabledSubmit(false);
        } else {
          setDisabledSubmit(false);
          toast.error(
            <div className='notify-unsuccess d-flex'>
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                className='icon-check-status'
              />
              <div className='unsuccess-message'>
                <b>Error</b>
                <p>Your data has not been saved yet. Please try again.</p>
              </div>
            </div>,
            { className: 'toast-unsuccess' }
          );
        }
      } catch (error) {
        setDisabledSubmit(false);
        if (error.response) {
          setErrorExistEventName(error.response.data.errorMsg);
        }
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Your data has not been saved yet. Please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    },
    [getOfficeUnavailabilty]
  );

  const updateOutOfOfficeEvent = useCallback(
    async (payload: OutOfOfficeEvent) => {
      try {
        setDisabledSubmit(true);
        const response = await configuredRequests.PATCH.updateEventOffice(
          payload
        );
        // @ts-ignore
        if (response.status === 200) {
          setErrorExistEventName('');
          toast.success(
            <div className='notify-success d-flex'>
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className='icon-check-status'
              />
              <div className='success-message'>
                <b>Successfully</b>
                <p>Your data has been updated successfully!</p>
              </div>
            </div>,
            { className: 'toast-success' }
          );
          getOfficeUnavailabilty();
          changeShowModalAddOfficeEvent(false);
          setIsDirtyForm(false);
          setSelectedEvent(null);
          setDisabledSubmit(false);
        } else {
          setDisabledSubmit(false);
          toast.error(
            <div className='notify-unsuccess d-flex'>
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                className='icon-check-status'
              />
              <div className='unsuccess-message'>
                <b>Error</b>
                <p>Your data has not been updated yet. Please try again.</p>
              </div>
            </div>,
            { className: 'toast-unsuccess' }
          );
        }
      } catch (error) {
        setDisabledSubmit(false);

        if (error.response) {
          setErrorExistEventName(error.response.data.errorMsg);
        }
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Your data has not been updated yet. Please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    },
    [getOfficeUnavailabilty]
  );

  const getHoursOfOperation = useCallback(async () => {
    try {
      setIsLoadingHourOfOperation(true);
      const tenantId = getSessionTenantInfo()?.tenantId ?? '';
      if (tenantId) {
        const response = await configuredRequests.GET.getHoursOfOperation(
          tenantId
        );
        if (response?.result?.id) {
          setIsLoadingHourOfOperation(false);
          setListDate(
            // @ts-ignore
            response?.result?.value.map((element: ListDate) => ({
              ...element,
              error: '',
              errorLunch: '',
            }))
          );
        } else {
          setListDate(listDateInitial);
        }
      }
    } catch (error) {
      setListDate(listDateInitial);
      setIsLoadingHourOfOperation(false);
    }
  }, []);

  const getOptionsMinimumAvailability = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getListMinimumAvailability();
      setOptionsMinimum(
        // @ts-ignore
        res.definedValues.map((item) => ({ ...item, label: item.name }))
      );
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const getMinimumAvailiability = useCallback(async () => {
    try {
      setIsLoadingMinimumAvai(true);
      const res = await configuredRequests.GET.getMinimumAvai();
      setMinimumAvailability(res.toString());
      setIsLoadingMinimumAvai(false);
    } catch (err) {
      setIsLoadingMinimumAvai(false);
      console.log('err', err);
    }
  }, []);

  const getTenantOABTimeUnits = useCallback(async () => {
    try {
      setIsLoadingOABTimeUnits(true);
      const response = await configuredRequests.GET.getTenantOABTimeUnits();
      setTenantOABTimeUnits(response);
      setIsLoadingOABTimeUnits(false);
    } catch (error) {
      console.log(error);

      setTenantOABTimeUnits(10);
      setIsLoadingOABTimeUnits(false);
    }
  }, []);
  const onChangeDateTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTime)
            : compareTimePicker(selectedDate?.startTime, value);
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [name]: value,
                  error: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
      }
    },
    [listDate]
  );

  const onChangeLucnhTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const convertName =
        name === 'startTime' ? 'startTimeLunch' : 'endTimeLunch';
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTimeLunch)
            : compareTimePicker(selectedDate?.startTimeLunch, value);
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [convertName]: value,
                  errorLunch: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
      }
    },
    [listDate]
  );

  const onShowModalAddOfficeEvent = useCallback(() => {
    changeShowModalAddOfficeEvent(true);
  }, []);

  const onShowModalEditEvent = useCallback(
    (eventOffice: OfficeEvent | null) => {
      changeShowModalAddOfficeEvent(true);
      setSelectedEvent(eventOffice);
    },
    []
  );

  const onShowModalDeleteEvent = useCallback(
    (eventOffice: OfficeEvent | null) => {
      setIsSelectedDelete(true);
      setSelectedEvent(eventOffice);
    },
    []
  );

  const onShowModalCornfirm = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseModalConfirmModal = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const onCloseModalAddOfficeEvent = useCallback(() => {
    changeShowModalAddOfficeEvent(false);
    setSelectedEvent(null);
    setErrorExistEventName('');
  }, []);

  const onChangeToogle = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTime,
          selectedDate?.endTime
        );
        const isStartLunchAfterEndTimeLunch = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  active: !listDate.active,
                  error:
                    !listDate.active && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                  errorLunch:
                    !listDate.active && isStartLunchAfterEndTimeLunch
                      ? 'The end time must be greater than start time.'
                      : '',
                  takeLunch: !listDate.active ? true : false,
                }
              : { ...listDate }
          );
          return newListDate;
        });
      }
    },
    [listDate]
  );

  const onChangeToogleLunch = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  takeLunch: !listDate.takeLunch,
                  errorLunch:
                    !listDate.takeLunch && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                }
              : { ...listDate }
          );
          return newListDate;
        });
      }
    },
    [listDate]
  );

  const onChangeMinimumAvailability = useCallback((value: string) => {
    setMinimumAvailability(value);
  }, []);

  const onChangeTenantOABTimeUnits = useCallback((timeUnits: string) => {
    setTenantOABTimeUnits(Number(timeUnits));
  }, []);

  const updateHoursOfOperation = useCallback(async () => {
    const tenantId = getSessionTenantInfo()?.tenantId ?? '';
    try {
      if (tenantId) {
        const isNotExistedError = listDate.every(
          (date: ListDate) => !date.error && !date.errorLunch
        );
        if (isNotExistedError) {
          const response =
            await configuredRequests.PATCH.updateHoursOfOperation(
              tenantId,
              listDate
            );
          // @ts-ignore
          if (response.status === 200) {
            toast.success(
              <div className='notify-success d-flex'>
                <FontAwesomeIcon
                  icon={['fas', 'check-circle']}
                  className='icon-check-status'
                />
                <div className='success-message'>
                  <b>Successfully</b>
                  <p>Your data has been saved successfully!</p>
                </div>
              </div>,
              { className: 'toast-success' }
            );
          } else {
            toast.error(
              <div className='notify-unsuccess d-flex'>
                <FontAwesomeIcon
                  icon={['fal', 'times-circle']}
                  className='icon-check-status'
                />
                <div className='unsuccess-message'>
                  <b>Error</b>
                  <p>Your data has not been saved yet. Please try again.</p>
                </div>
              </div>,
              { className: 'toast-unsuccess' }
            );
          }
        }
      }
    } catch (error) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }, [listDate]);

  const updateMinimumAvailability = useCallback(async () => {
    try {
      const res = await configuredRequests.PATCH.updateMinimumAvailability(
        minimumAvailability
      );

      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      console.log('err', err);
    }
  }, [minimumAvailability]);

  const updateTenantOABTimeUnits = useCallback(async () => {
    try {
      const response = await configuredRequests.PATCH.updateTenantOABTimeUnits(
        tenantOABTimeUnits
      );
      // @ts-ignore
      if (response.status === 200) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      } else {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Your data has not been saved yet. Please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    } catch (error) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }, [tenantOABTimeUnits]);

  useEffect(() => {
    Promise.all([
      getHoursOfOperation(),
      getOfficeUnavailabilty(),
      getTenantOABTimeUnits(),
      getOptionsMinimumAvailability(),
      getMinimumAvailiability(),
    ]).then(() => {});
  }, [
    getHoursOfOperation,
    getOfficeUnavailabilty,
    getTenantOABTimeUnits,
    getOptionsMinimumAvailability,
    getMinimumAvailiability,
  ]);

  return (
    <AvailabilityV
      onChangeDateTime={onChangeDateTime}
      onChangeLucnhTime={onChangeLucnhTime}
      onChangeToogle={onChangeToogle}
      onShowModalEditEvent={onShowModalEditEvent}
      onShowModalDeleteEvent={onShowModalDeleteEvent}
      onChangeToogleLunch={onChangeToogleLunch}
      onShowModalAddOfficeEvent={onShowModalAddOfficeEvent}
      onCloseModalAddOfficeEvent={onCloseModalAddOfficeEvent}
      updateHoursOfOperation={updateHoursOfOperation}
      onChangeIsDirtyForm={onChangeIsDirtyForm}
      onChangeErrorExistEventName={onChangeErrorExistEventName}
      onShowModalCornfirm={onShowModalCornfirm}
      onCloseModalConfirmModal={onCloseModalConfirmModal}
      onChangeTenantOABTimeUnits={onChangeTenantOABTimeUnits}
      createOutOfOfficeEvent={createOutOfOfficeEvent}
      deleteOutOfOfficeEvent={deleteOutOfOfficeEvent}
      updateOutOfOfficeEvent={updateOutOfOfficeEvent}
      updateTenantOABTimeUnits={updateTenantOABTimeUnits}
      setIsSelectedDelete={setIsSelectedDelete}
      isShowModalAddOfficeEvent={isShowModalAddOfficeEvent}
      listDate={listDate}
      isLoadingHourOfOperation={isLoadingHourOfOperation}
      isLoadingOABTimeUnits={isLoadingOABTimeUnits}
      isLoadingEvent={isLoadingEvent}
      isSelectedDelete={isSelectedDelete}
      selectedEvent={selectedEvent}
      isDirtyForm={isDirtyForm}
      isShowConfirmModal={isShowConfirmModal}
      listOfficeOff={listOfficeOff}
      errorExistEventName={errorExistEventName}
      tenantOABTimeUnits={tenantOABTimeUnits}
      isLoadingMinimumAvailability={isLoadingMinimumAvai}
      onChangeMinimumAvailability={onChangeMinimumAvailability}
      updateMinimumAvailability={updateMinimumAvailability}
      minimumAvailability={minimumAvailability}
      optionsMinimumAvailability={optionsMinimum}
    />
  );
};

export default AvailabilityVM;
