import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Modal from 'components/Global/Modal';
import Table from 'components/Global/Table';
import toast from 'components/Global/Toast';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { isEmpty, sortBy } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { convertMinutesToHours } from 'utils/convertData';
import { ListDate } from '../Availability/AvailabilityVM';
import AddNewAppointmentType from './AddNewAppointmentType/AddNewAppointmentType';
import './AppointmentType.scss';

const AppointmentTypeWrapper = styled.div`
  height: calc(100vh - 163px);
  overflow-y: auto;
  padding-top: 20px;

  .appointment-type-syncer-setting-title {
    margin-top: 18px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
    },
  })
);

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#31CC97',
          borderColor: '#31CC97',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

export interface DurationType {
  value: string;
  label: string;
}

type AppointmentTableDataType = {
  id: string;
  appointmentType: string;
  appointmentDuration: number;
  externalId?: string;
  isBookingActive: boolean;
  isRoundRobinBookingOnly: boolean;
  limitAvailabilityValue?: ListDate[];
};

const titleDeletePopup = 'Delete Appointment Type';
const titleNotifyOfDeletePopup = 'Cannot Delete';
const descriptionDeletePopup =
  'Are you sure that you want to delete this appointment type? Providers may be currently using this appointment type for online appointment booking. Deleting it will remove this option from online booking.';
const descriptionNotifyOfDeletePopup =
  'This Appointment Type is associated to an appointment.';

const columns = [
  { key: 'appointmentType', title: 'Appointment Type' },
  { key: 'appointmentDuration', title: 'Appointment Duration' },
  { key: 'acceptAppointment', title: 'Accepting Appointments' },
  { key: 'roundRobinBookingOnly', title: 'Round-robin Booking Only' },
  { key: 'action', title: '' },
];

const AppointmentType = (): JSX.Element => {
  const classes = useStyles();
  const [isEllipsis, setIsEllipsis] = React.useState<boolean>(false);
  const [listHours, setListHours] = React.useState<DurationType[]>([]);
  const [listMinutes, setListMinutes] = React.useState<DurationType[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [openNotifyOfDeletePopup, setOpenNotifyOfDeletePopup] =
    useState<boolean>(false);
  const [isEditAppointmentTypeModal, setIsEditAppointmentTypeModal] =
    useState<boolean>(false);
  const [displayAppointmentTypeModal, setDisplayAppointmentTypeModal] =
    useState<boolean>(false);
  const [appointmentTypeSelectedData, setAppointmentTypeSelectedData] =
    useState<AppointmentTableDataType>();
  const [appointmentTypeTableData, setAppointmentTypeTableData] = useState<
    AppointmentTableDataType[]
  >([]);

  const handleDeleteAppointmentType = (item: AppointmentTableDataType) => {
    setAppointmentTypeSelectedData(item);
    setOpenDeletePopup(true);
  };

  const handleEditAppointmentType = (item: AppointmentTableDataType) => {
    setIsEditAppointmentTypeModal(true);
    setAppointmentTypeSelectedData(item);
    setDisplayAppointmentTypeModal(true);
  };

  const handleAddNewAppointmentType = () => {
    setIsEditAppointmentTypeModal(false);
    setAppointmentTypeSelectedData({} as AppointmentTableDataType);
    setDisplayAppointmentTypeModal(true);
  };

  const handleConfirmDeleted = async () => {
    try {
      const res = await configuredRequests.DEL.deleteAppointmentType(
        appointmentTypeSelectedData?.id ?? ''
      );
      if (res) {
        getAllAppointmentType();
        setOpenDeletePopup(false);
        toast.success('Deleted successfully');
      }
    } catch (err) {
      setOpenDeletePopup(false);
      if (
        err.response.status === 400 &&
        err.response.data?.error.includes('Appointment Type Id')
      ) {
        setOpenNotifyOfDeletePopup(true);
      } else {
        toast.error('Failed to delete.');
      }
    }
  };

  const getAllAppointmentType = async () => {
    setIsLoading(true);
    try {
      const res = await configuredRequests.GET.getAppointmentType();
      setAppointmentTypeTableData(
        // @ts-ignore
        sortBy(res, [(item) => item.appointmentType.toLowerCase()])
      );
      // setAppointmentTypeTableData(
      //   res.sort(
      //     (
      //       itemA: AppointmentTableDataType,
      //       itemB: AppointmentTableDataType
      //     ) => {
      //       if (
      //         itemA.appointmentType.toLowerCase() >
      //         itemB.appointmentType.toLowerCase()
      //       ) {
      //         return 1;
      //       } else if (
      //         itemA.appointmentType.toLowerCase() <
      //         itemB.appointmentType.toLowerCase()
      //       ) {
      //         return -1;
      //       } else {
      //         return 0;
      //       }
      //     }
      //   )
      // );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getListDuration = async () => {
    try {
      const res = await configuredRequests.GET.getListDuration();
      if (res?.data) {
        setListHours(
          // @ts-ignore
          res.data.hours.map(function (val: string) {
            return { label: val, value: Number(val) };
          })
        );
        setListMinutes(
          // @ts-ignore
          res.data.minutes.map(function (val: string) {
            return { label: val, value: Number(val) };
          })
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    getAllAppointmentType();
    getListDuration();
  }, []);

  const switchStatus = (
    appointmentTypeId: string,
    field: string,
    isBothField?: boolean
  ) => {
    // @ts-ignore
    const dataAfterSwitchStatus = appointmentTypeTableData.map(
      (item: AppointmentTableDataType) => {
        if (item.id === appointmentTypeId) {
          if (isBothField) {
            return {
              ...item,
              isBookingActive: !item.isBookingActive,
              isRoundRobinBookingOnly: !item.isRoundRobinBookingOnly,
            };
          }
          // @ts-ignore
          return { ...item, [field]: !item[field] };
        }
        return item;
      }
    );
    setAppointmentTypeTableData(dataAfterSwitchStatus);
  };

  const handleChangeToggle = async (
    appointmentType: AppointmentTableDataType,
    field: string
  ) => {
    try {
      // @ts-ignore
      const payload = { ...appointmentType, [field]: !appointmentType[field] };
      if (
        field === 'isBookingActive' &&
        appointmentType.isBookingActive &&
        appointmentType.isRoundRobinBookingOnly
      ) {
        switchStatus(appointmentType.id, field, true);
        payload.isRoundRobinBookingOnly = false;
      } else {
        switchStatus(appointmentType.id, field);
      }
      await configuredRequests.PATCH.updateAppointmentType(
        appointmentType.id,
        payload
      );

      // if (res) {
      //   getAllAppointmentType(true);
      // }
    } catch (error) {
      toast.error('Failed to change status.');
      switchStatus(appointmentType.id, field);
      if (
        field === 'isBookingActive' &&
        appointmentType.isBookingActive &&
        appointmentType.isRoundRobinBookingOnly
      ) {
        switchStatus(appointmentType.id, 'isRoundRobinBookingOnly');
      }
    }
  };

  return isLoading ? (
    <div>
      <img
        className='loading-general-syncer'
        src={loading_spinner}
        alt={'Loading'}
      />
    </div>
  ) : (
    <>
      <AppointmentTypeWrapper className='setting__content--full-row appointment-type-syncer-setting setting-appointment-type'>
        <div className='custom-position'>
          <h2 className='appointment-type-syncer-setting-title'>
            Appointment Types
          </h2>
          {/* <Button
            variant='contained'
            color='primary'
            className='btn-add-new-appointment-type'
            onClick={handleAddNewAppointmentType}
          >
            <FontAwesomeIcon
              icon={['far', 'plus']}
              className='icon-add-new-appointment-type'
            />
            New Appointment Type
          </Button> */}
        </div>
        <p className='appointment-type-syncer-setting-description'>
          Specify the services you want to offer through online booking. These
          will appear in a dropdown for patients to select a reason for
          visiting.
        </p>
        {!isEmpty(appointmentTypeTableData) ? (
          <>
            <Table
              columns={columns}
              rows={
                appointmentTypeTableData.map(
                  (item: AppointmentTableDataType, index: number) => {
                    return {
                      appointmentType: (
                        <div className='appointment-type-colunm'>
                          <Tooltip
                            title={item.appointmentType}
                            placement='bottom-start'
                            classes={{ tooltip: classes.tooltip }}
                            disableHoverListener={
                              item.appointmentType.length < 35
                            }
                          >
                            <span>{item.appointmentType}</span>
                          </Tooltip>
                        </div>
                      ),
                      appointmentDuration: convertMinutesToHours(
                        item.appointmentDuration
                      ),
                      acceptAppointment: (
                        <FormControlLabel
                          control={
                            <AntSwitch
                              checked={item.isBookingActive}
                              onChange={() =>
                                handleChangeToggle(item, 'isBookingActive')
                              }
                            />
                          }
                          label=''
                        />
                      ),
                      roundRobinBookingOnly: (
                        <FormControlLabel
                          control={
                            <AntSwitch
                              checked={item.isRoundRobinBookingOnly ?? false}
                              onChange={() =>
                                item.isBookingActive &&
                                handleChangeToggle(
                                  item,
                                  'isRoundRobinBookingOnly'
                                )
                              }
                            />
                          }
                          label=''
                          className={`${!item.isBookingActive && 'disabled'}`}
                        />
                      ),
                      action: (
                        <>
                          <button
                            key={index}
                            className='appointment__task-complete-button edit-appointment-type-button'
                            onClick={() => handleEditAppointmentType(item)}
                          >
                            Edit
                          </button>
                          {!item.externalId && (
                            <button
                              key={index + 1}
                              className='appointment__task-complete-button delete-appointment-type-button'
                              onClick={() => handleDeleteAppointmentType(item)}
                            >
                              Delete
                            </button>
                          )}
                        </>
                      ),
                    };
                  }
                ) || []
              }
              isHasPagination={false}
            />
          </>
        ) : (
          <p
            className='no-data-results'
            style={{ fontWeight: 'bold', marginLeft: '15px' }}
          >
            No results found
          </p>
        )}
      </AppointmentTypeWrapper>

      {openDeletePopup && (
        <Modal
          isOpenModal={openDeletePopup}
          title={titleDeletePopup}
          children={descriptionDeletePopup}
          handleCloseModal={() => setOpenDeletePopup(false)}
          handleConfirmModal={handleConfirmDeleted}
        />
      )}

      {openNotifyOfDeletePopup && (
        <Modal
          isOpenModal={openNotifyOfDeletePopup}
          title={titleNotifyOfDeletePopup}
          children={descriptionNotifyOfDeletePopup}
          isFullAction={false}
          handleCloseModal={() => setOpenNotifyOfDeletePopup(false)}
          handleConfirmModal={() => setOpenNotifyOfDeletePopup(false)}
        />
      )}

      {displayAppointmentTypeModal && (
        <AddNewAppointmentType
          displayAppointmentTypeModal={displayAppointmentTypeModal}
          // @ts-ignore
          appointmentTypeData={appointmentTypeSelectedData}
          isEdit={isEditAppointmentTypeModal}
          listHours={listHours}
          listMinutes={listMinutes}
          setDisplayAppointmentTypeModal={setDisplayAppointmentTypeModal}
          handleReloadAppointmentTypeList={getAllAppointmentType}
        />
      )}
    </>
  );
};

export default AppointmentType;
