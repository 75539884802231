/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TenantResposne } from 'global/requests/ResponseTypes';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PracticeCompany from './PracticeCompany';

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

function TabContainer(props: TabContainerProps) {
  return <Typography component='div'>{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function PracticeGaneral() {
  const [value] = React.useState(0);
  const [generalSetting, setDataGeneralSetting] =
    React.useState<TenantResposne>();
  const [loading, setLoading] = useState(false);
  const params = useParams<{ groupId: string; practiceId: string }>();

  const getInforGeneralSetting = async () => {
    setLoading(true);

    try {
      const res = await configuredRequests.GET.getTenantInfo(
        params.groupId,
        params.practiceId
      );
      setDataGeneralSetting({
        ...res,
        phoneNumbers: res.phoneNumbers?.filter(
          (phoneNumber: any) => !phoneNumber.isPracticePhone
        ),
        // @ts-ignore
        practicePhoneNumber: res.phoneNumbers?.filter(
          (phoneNumber: any) => phoneNumber.isPracticePhone
        ),
      });
    } catch (err) {
      console.log('error', err);
    }
  };

  React.useEffect(() => {
    getInforGeneralSetting();
  }, []);

  useEffect(() => {
    if (generalSetting) {
      setLoading(false);
    }
  }, [generalSetting]);

  return (
    <div className='mt-20'>
      {/* <AppBar position="static" className="container__table">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="Practice Details" /> */}
      {/* <Tab label="Social" /> */}
      {/* </Tabs>
      </AppBar> */}

      {value === 0 && (
        <TabContainer id={1}>
          {loading ? (
            <Loading />
          ) : (
            <PracticeCompany practiceInfo={generalSetting} />
          )}
        </TabContainer>
      )}
      {/* {value === 1
      && <TabContainer id={2}>
            <GaneralSocial/>
        </TabContainer>} */}
    </div>
  );
}
