import React from 'react';
import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 400px;
  padding: 20px;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  svg {
    margin-right: 5px;
    font-size: 0.9rem;
    color: white;
  }

  p {
    margin: 10px;
  }

  span {
    font-size: 13px;

    .red {
      color: #f00;
    }
  }

  .footer-success {
    display: flex;
  }

  .btn {
    display: block;
    padding: 6px 15px;

    border-radius: 4px;
    text-transform: none !important;
    color: #ffffff;
    font-size: 13px;

    &.btn-cancel {
      color: #22334d;
      background-color: #eceef5;

      &.same-width {
        width: 110px;
      }
    }

    &.btn-send {
      background-color: #0074ff;
      /* margin-left: 10px; */
    }
  }
`;

interface Props {
  open: boolean;
  content: React.ReactNode;
  type: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: (arg?: boolean) => void;
}

const BatchPopup = ({ open, content, type, onClose, onSend }: Props) => {
  return (
    <Modal
      open={open}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <Content>
        <span>{content}</span>

        <div className='footer'>
          <Button
            variant='contained'
            className={`btn btn-cancel ${type === 'text' ? 'same-width' : ''}`}
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>

          {/* <div className='footer-success'> */}
          {type === 'text' && (
            <Button
              variant='contained'
              color='primary'
              className='btn btn-send-email'
              onClick={() => {
                onSend(true);
                onClose(false);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'paper-plane']} />
              Send Email
            </Button>
          )}

          <Button
            variant='contained'
            color='primary'
            className='btn btn-send'
            onClick={() => {
              onSend();
              onClose(false);
            }}
          >
            <FontAwesomeIcon icon={['fas', 'paper-plane']} />
            Send {type === 'text' ? 'Text' : 'Postal'}
          </Button>
          {/* </div> */}
        </div>
      </Content>
    </Modal>
  );
};

export default BatchPopup;
