import React, { useMemo, useState } from 'react';

interface AvailabilityContextType {
  disabledSubmit: boolean;
  setDisabledSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvailabilityContext = React.createContext(
  {} as AvailabilityContextType
);

const AvailabilityProvider: React.FC = ({ children }) => {
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const value = useMemo(() => {
    return {
      disabledSubmit,
      setDisabledSubmit,
    };
  }, [disabledSubmit, setDisabledSubmit]);

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};

export default AvailabilityProvider;
