import * as t from 'io-ts';

// AddressLocation
export const AddressLocationData = t.partial({
  addressLine1: t.string,
  addressLine2: t.string,
  city: t.string,
  country: t.string,
  postalCode: t.string,
  state: t.string,
});
export type AddressLocation = t.TypeOf<typeof AddressLocationData>;
//

// AddressEntry
export const AddressEntryData = t.type({
  userAddressId: t.string,
  address: AddressLocationData,
  isPrimary: t.boolean,
  kind: t.string, //TODO standardize ValidKinds types (already exist for frontend data)
});
export type AddressEntry = t.TypeOf<typeof AddressEntryData>;
//
