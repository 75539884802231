import React from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OptionConfigItem = ({
  index,
  label,
  dragIndex,
  disableAdd,
  disableDelete,
  onDragStart,
  onDragOver,
  onDragEnd,
  onEditOption,
  onAddOption,
  onDeleteOption,
  disabled,
  isMultiChoices = false
}) => {
  const recurseOffsetParent = (offsetParent, offsetAttribute, offsetSum) => {
    if (!offsetParent) return offsetSum;
    offsetSum += offsetParent[`offset${offsetAttribute}`];
    return recurseOffsetParent(offsetParent.offsetParent, offsetAttribute, offsetSum);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.currentTarget;
    const { offsetParent, offsetTop, offsetHeight } = target;
    const offsetMidY = recurseOffsetParent(offsetParent, 'Top', 0) + offsetTop + offsetHeight / 2;
    const location = e.clientY < offsetMidY ? 'top' : 'bottom';

    debounceDragOver(target, location);
  };

  let debounceDragOver = (target, location) => {
    onDragOver(target, location);
  };

  debounceDragOver = debounce(debounceDragOver, 100);

  const classes = classNames({
    'option-config-item': true,
    hidden: dragIndex === index,
    'multi-choice': isMultiChoices,
  });

  const iconClassesDelete = classNames({
    icon: true,
    'times-circle': true,
    disabled: disableDelete || disabled,
  });

  const iconClassesAdd = classNames({
    icon: true,
    'icon-plus-circle': true,
    disabled: disableAdd || disabled,
  });

  return (
    <div
      id={index}
      className={classes}
      draggable={!disabled && !isMultiChoices}
      onDragStart={e => onDragStart(e, { index, label })}
      onDragOver={handleDragOver}
      onDragEnd={onDragEnd}
    >
      {!disabled && !isMultiChoices && <span className='grip-handle' />}
      <input
        type='text'
        className='span20'
        value={label}
        onChange={e => onEditOption(index, e.target.value)}
        disabled={disabled}
      />
      <div className='icons'>
        {/* <i
          className={iconClassesDelete}
          title='Remove option'
          onClick={() => !disableDelete && !disabled && onDeleteOption(index)}
        /> */}
        <FontAwesomeIcon
          icon={["fal", "times-circle"]}
          className="icon-check-status" 
          onClick={() => !disableDelete && !disabled && onDeleteOption(index)}
          
        />
        <FontAwesomeIcon
          icon={["fal", "plus-circle"]}
          className="icon-check-status" 
          onClick={() => !disableAdd && !disabled && onAddOption(index)}
          />
      </div>
    </div>
  );
};

OptionConfigItem.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string,
  dragIndex: PropTypes.number,
  disableAdd: PropTypes.bool,
  disableDelete: PropTypes.bool,
  onDragStart: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onEditOption: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
  onDeleteOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OptionConfigItem.defaultProps = {
  label: '',
  disableAdd: true,
  disableDelete: true,
  disabled: false,
};
