import styled from 'styled-components';
import CardContent from '@material-ui/core/CardContent';
import getThemeProp from '../../utils/getThemeProp';
import { MediumDarkText, TinyTitleText } from 'components/Legwork/typography';

import Card from '../Card';

const Title = styled(MediumDarkText)`
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
`;

const Statistic = styled.div`
  padding: 0;
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
`;

const StyledDescription = styled(TinyTitleText)`
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 14px;
  font-weight: 500;
`;

const SizedCard = styled(Card)`
  flex: 1;
  padding: 0;
  margin-right: 1%;
  &:last-child {
    margin-right: 0px;
  }
`;

const StyledCardContent = styled(CardContent)`
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  padding-left: 1.3rem;
  padding-top: 0.5rem;
  &:last-child {
    padding-bottom: 1.2rem;
  }
`;

export { Title, Statistic, StyledDescription, SizedCard, StyledCardContent };
