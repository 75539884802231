import { NavigationLink } from 'types/NavigationT';

// This file contains all the Sidebar data that can appear in the Sidebar.
// It also defines the set of data that is rendered for each user permission level

// Here we define objects that describe the links that we want to see in the NavBar.
// If a link is used by multiple permission levels, then it is defined as 'shared' and
// thanks to pass-by-reference we can save memory by defining shared objects once.
// This also enforces stricter consistency between permission levels

// There are only 3 types of NavBar links: top level links, top level containers, and 'contained' links.

/* Sample container:
  {
    innerText: '',
    iconType: [],
    childLinks: [
      {
        linkTo: '',
        innerText: ''
      }
    ]
  }
*/

/////////////////// Beginning of sidebar data objects

// top level containers should be called with an array of children
// existing valid children are declared below in sharedLinkObjects
const sharedTopLevelContainers = {
  CRM: (
    children?: {
      linkTo: string;
      innerText: string;
    }[]
  ) => {
    return {
      innerText: 'CRM',
      leftIcon: ['fal', 'home'],
      rightIcon: ['fas', 'chevron-down'],
      preOpen: true,
      childLinks: [...(children ? children : [])],
    };
  },

  paperlessForm: (
    children?: {
      linkTo: string;
      innerText: string;
    }[]
  ) => {
    return {
      innerText: 'Paperless Forms',
      leftIcon: ['fal', 'file-alt'],
      rightIcon: ['fas', 'chevron-down'],
      preOpen: true,
      childLinks: [...(children ? children : [])],
    };
  },
};

const sharedTopLevelLinks = {
  messenger: {
    linkTo: '/Messenger',
    innerText: 'Conversations',
    leftIcon: ['fal', 'comment-alt-lines'],
    rightIcon: {
      elementType: 'span',
      className: 'status messenger-status',
    },
  },
  telemedicine: {
    linkTo: '/Telemedicine',
    innerText: 'Telemedicine',
    leftIcon: ['fal', 'video'],
  },
  // invoicing: {
  //   linkTo: '/Invoicing',
  //   innerText: 'Pay',
  //   leftIcon: ['fal', 'file-invoice-dollar'],
  // },
  appointmentBooking: {
    linkTo: '/AppointmentBooking',
    innerText: 'Appointment Booking',
    leftIcon: ['far', 'clock'],
    rightIcon: {
      elementType: 'span',
      className: 'status oab-status',
    },
  },
  //removed from spec
  // calendar: {
  //   linkTo: '/Calendar',
  //   innerText: 'Calendar',
  //   leftIcon: ["far", "calendar-check"]
  // },

  // reviews: {
  //   linkTo: '/Reviews',
  //   innerText: 'Reviews',
  //   leftIcon: ["fal", "star"],
  //   rightIcon: ["fas", "chevron-down"]
  // }
};

// arguments for the functions declared above in sharedTopLevelContainers
const sharedLinkObjects = {
  dashboard: {
    linkTo: '/Dashboard',
    innerText: 'Dashboard',
  },
  appointments: {
    linkTo: '/Appointments',
    innerText: 'Appointments',
  },
  communication: {
    linkTo: '/Communications',
    innerText: 'Communications',
  },
  contacts: {
    linkTo: '/Contacts',
    innerText: 'Contacts',
  },
  groups: {
    linkTo: '/Groups',
    innerText: 'Groups',
    leftIcon: ['fas', 'user'],
  },
  billing: {
    linkTo: '/Billing',
    innerText: 'Billing',
    leftIcon: ['fas', 'credit-card'],
  },
  syncer: {
    linkTo: '/Syncer',
    innerText: 'Syncer',
    leftIcon: ['far', 'sync-alt'],
  },
  alerts: {
    linkTo: '/Alerts',
    innerText: 'Alerts',
    leftIcon: ['far', 'bell'],
    rightIcon: {
      elementType: 'span',
      className: 'status',
    },
  },
  settings: {
    linkTo: '/SettingsAdmin',
    innerText: 'Settings',
    leftIcon: ['far', 'cog'],
  },
  formBuilder: {
    linkTo: '/FormBuilder',
    innerText: 'All Forms',
    leftIcon: ['far', 'sync-alt'],
  },
  allSubmission: {
    linkTo: '/AllSubmissions',
    innerText: 'All Submissions',
    leftIcon: ['far', 'cog'],
    rightIcon: {
      elementType: 'span',
      className: 'status form-status',
    },
  },

  // removed from spec
  // reports: {
  //   linkTo: '/Reports',
  //   innerText: 'Reports'
  // },
};

///////////////////////// End of sidebar data objects

///////////////////////// Beginning of permission specific router data

// Permission levels that are currently in spec are:
// [ Fetch Admin, Fetch Sales, Tenant Admin, Tenant User, Contact ]

// Pages currently in spec and their applicable permission level:
// {
//   System Setting:            Fetch Admin
//   Tenant Management:         Fetch Admin
//   Agreement Management:      Fetch Admin,  Fetch Sales, Tenant Admin
//   Tenant Setting:            Fetch Admin,  Fetch Sales
//   Billing Portal:            Fetch Admin,  Fetch Sales
//   Dashboard:                 Tenant Admin, Tenant User
//   Appointment:               Tenant Admin, Tenant User
//   Telemedicine:              Tenant Admin, Tenant User
//   Communication:             Tenant Admin, Tenant User
//   Contact:                   Tenant Admin, Tenant User
//   Form Builder:              Tenant Admin, Tenant User
//   Reviews:                   Tenant Admin, Tenant User
//   Online Booking Management: Tenant Admin, Tenant User
//   Fetch Online Payment:      Tenant Admin
//   Patient Portal:            Contact
//   Mobile App:                Contact
// }

// export const XXXX: NavigationLink[] = []

export const sideBarData: NavigationLink[] = [
  sharedTopLevelContainers.CRM([
    sharedLinkObjects.dashboard,
    sharedLinkObjects.appointments,
    sharedLinkObjects.contacts,
    sharedLinkObjects.communication,
  ]),
  sharedLinkObjects.groups,
  sharedLinkObjects.billing,
  sharedLinkObjects.syncer,
  sharedLinkObjects.alerts,
  sharedLinkObjects.settings,
  sharedTopLevelLinks.messenger,
  sharedTopLevelLinks.telemedicine,
  sharedTopLevelContainers.paperlessForm([
    sharedLinkObjects.allSubmission,
    sharedLinkObjects.formBuilder,
  ]),
  // sharedTopLevelLinks.invoicing,
  sharedTopLevelLinks.appointmentBooking,
];
