import React, { FC } from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';
import { urlPattern } from 'utils/regex';

interface HighlightTextProps {
  text: string;
  searchValue?: string;
  isJsx?: boolean;
}

const HighlightText: FC<HighlightTextProps> = ({
  text,
  searchValue = '',
  isJsx = false,
}): JSX.Element => {
  const regex = new RegExp(`(${_.escapeRegExp(searchValue)})`, 'gi');
  const parts = text.split(regex);

  const handleLink = React.useMemo((): string => {
    const positions: any[] = [];
    let idx = 0;
    parts
      .filter((part) => part)
      .forEach((part, i) => {
        if (regex.test(part)) {
          positions.push(
            { idx, text: '<mark>' },
            { idx: idx + part.length, text: '</mark>' }
          );
        }
        idx += part.length;
      });
    idx = 0;

    text.split(' ').forEach((subText) => {
      if (
        (subText.toLowerCase().indexOf('https://') === 0 ||
          subText.toLowerCase().indexOf('http://') === 0 ||
          subText.toLowerCase().indexOf('www.') === 0) &&
        urlPattern.test(subText)
      ) {
        positions.push(
          {
            idx,
            text: `<a href="${
              subText.toLowerCase().indexOf('www.') === 0 ? '//' : ''
            }${subText}" target="_blank" rel="noopener noreferrer" class="lnk--in-mess">`,
          },
          { idx: idx + subText.length, text: '</a>' }
        );
      }
      idx += subText.length + 1;
    });

    positions.sort((a, b) => {
      if (a.idx > b.idx) return -1;
      else if (a.idx < b.idx) return 1;
      return 0;
    });

    if (positions.length) {
      const arrText = text.split('');
      positions.forEach((item) => {
        arrText.splice(item.idx, 0, item.text);
      });
      return arrText.join('');
    }
    return text;
  }, [parts, text]);

  return (
    <>
      {searchValue.trim() ? (
        isJsx ? (
          parse(handleLink)
        ) : (
          <span>
            {parts
              .filter((part) => part)
              .map((part, i) =>
                // regex.test(part) ? (
                part.toLowerCase() === searchValue.toLowerCase() ? (
                  <mark key={i}>{part}</mark>
                ) : (
                  <span key={i}>{part}</span>
                )
              )}
          </span>
        )
      ) : text === 'Missing Email Address' ? (
        <span style={{ color: '#F64F45' }}>{text}</span>
      ) : (
        <span>{isJsx ? parse(text) : text}</span>
      )}
    </>
  );
};

export default HighlightText;
