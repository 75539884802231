// @TODO  GENERALIZE -- MAKE THIS A SCROLLABLE LIST!
import React, { useState, useEffect, useRef, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import SelectConversationListElement from '../SelectConversationListElement';

const pageSize = 40;

export interface IConversation {
  messages: { text: string; sentAt: moment.Moment }[];
  contact: { name: string };
  id: number | string;
  read: boolean;
}

export interface SelectConversationListProps {
  getMore: (page: number) => Promise<IConversation[]>;
  onDelete: (id: string | number) => void;
  onMarkRead: (read: boolean, id: string | number) => void;
  onSelect: (conversation: IConversation, index: number) => void;
}

const SelectConversationList = (props: SelectConversationListProps): React.ReactElement => {
  const { getMore, onDelete, onMarkRead, onSelect } = props;
  const [conversations, setConversations] = useState<IConversation[]>([]); // reshape this after we know the shape of the resposne.
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectCallback = useCallback(onSelect, []);
  const chooseSelected = useCallback(
    (conversation: IConversation, i: number): void => {
      setSelectedIndex(i);
      onSelectCallback(conversation, i);
    },
    [onSelectCallback],
  );

  const conversationList = useRef<HTMLUListElement>(null);

  const listener = (event: Event): void => {
    const target = event.target as HTMLDListElement;

    if (loading || !hasMore || !target) {
      return;
    }

    if (target.scrollHeight - window.innerHeight < target.scrollTop + 100) {
      setLoading(true);
      getMore(page + 1).then((result: IConversation[]): void => {
        setConversations([...conversations, ...result]);
        setPage(page + 1);
        if (result.length < pageSize) {
          setHasMore(false);
        }
        setLoading(false);
        setError(false);
      });
    }
  };

  useEffect((): (() => void) => {
    const current = conversationList?.current;

    if (current) {
      current.addEventListener('scroll', listener);
    }

    return (): void => {
      if (current) {
        current.removeEventListener('scroll', listener);
      }
    };
  });

  const doConversationStuff = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const result: IConversation[] = await getMore(page + 1);
      setConversations([...conversations, ...result]);
      if (result.length > 0 && initialLoad) {
        chooseSelected(result[0], 0);
      }
      setPage(page + 1);
      setInitialLoad(false);
      if (result.length < pageSize) {
        setHasMore(false);
      }
      setLoading(false);
      setError(false);
    } catch {
      setError(true);
    }
  }, [chooseSelected, conversations, getMore, initialLoad, page]);

  useEffect((): void => {
    if (initialLoad) {
      doConversationStuff();
    }
  }, [initialLoad, doConversationStuff]);

  if (error) {
    return (
      <List ref={conversationList} id='conversation_select_scroll'>
        <ListItem>
          <ListItemText
            primary='Oops, something went wrong'
            secondary='This might be temporary but if it persists please contact support.'
          />
        </ListItem>
      </List>
    );
  }

  if (conversations.length === 0) {
    return (
      <List ref={conversationList} id='conversation_select_scroll'>
        <ListItem>
          <ListItemText
            primary='No conversations available.'
            secondary='Please change your filter options to find your converations!'
          />
        </ListItem>
      </List>
    );
  }

  return (
    <List
      ref={conversationList}
      id='conversation_select_scroll'
      style={{ maxHeight: window.innerHeight, overflowY: 'scroll' }}
    >
      {conversations.map(
        (conversation, i): React.ReactElement => {
          const deleteConversation = (): void => {
            onDelete(conversation.id);
          };
          const markRead = (): void => {
            onMarkRead(conversation.read, conversation.id);
          };
          const handleSelect = (): void => {
            chooseSelected(conversation, i);
          };

          return (
            <SelectConversationListElement
              key={conversation.messages[0].text + conversation.contact.name}
              selected={selectedIndex === i}
              read={conversation.read}
              onClickDelete={deleteConversation}
              onClickRead={markRead}
              name={conversation.contact.name}
              latestMessageText={conversation.messages[0].text}
              latestMessageTime={conversation.messages[0].sentAt}
              onClickSelect={handleSelect}
            />
          );
        },
      )}
      {hasMore && (
        <ListItem>
          <ListItemText
            primary='Loading Conversations'
            secondary={
              <>
                <CircularProgress />
              </>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

export default SelectConversationList;
