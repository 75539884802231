export const generalSettings = {
  namespace: 'GeneralSettings',
  settings: {
    companyName: '',
    officeNumber: '',
    websiteUrl: '',
    websiteUseInFooters: false,
    emailReplyToAddress: '',
    timeZone: '',
    groupAppointmentMessagesByFamily: false,
    useValidatedPhoneNumbers: false,
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      state: '',
    },
  },
};

export const dataMockupBrandColor = {
  namespace: 'BrandColorSettings',
  settings: {
    backgroundColor: '#f9f9f9',
    navigationTextColor: '#fff',
    contentBackgroundColor: '#fff',
    footerBackgroundColor: '#9dce6e',
    buttonOutsideColor: '#274150',
    color1: '#98cd72',
    color2: '#005f87',
    stripeColor: '#fff',
    logoTextColor: '#29404e',
    contentTextColor: '#555',
    buttonInsideColor: '#3a5a6e',
  },
};

export const dataMockupNotification = {
  namespace: 'NotificationSettings',
  settings: {
    notificationUsers: [],
    sendEmailNotificationsWhenTextReceived: false,
    sendEmailNotificationsWhenAppointmentConfirmed: false,
    sendEmailNotificationsWhenOabRequested: false,
    sendEmailNotificationsWhenNewFormSubmitted: false,
    sendDesktopNotificationsForNewMessages: false,
    sendDesktopNotificationsForNewFormSubmitted: false,
    sendDesktopNotificationsForOabRequested: false,
  },
};

export const dataMockupUserData = {
  userId: '',
  pulseUserStatus: 'active',
  name: {
    givenName: '',
    lastName: '',
  },
  emailAddress: '',
  addresses: [
    {
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      isPrimary: true,
      kind: 'home',
    },
  ],
  phoneNumbers: [
    {
      number: '',
      isPrimary: true,
      isValidated: false,
      kind: 'cell',
      extension: '',
    },
  ],
  enabled: false,
  tenant: '',
  birthDate: new Date() as any,
};

export const dataTemplate = {
  offset: 0,
  count: 0,
  totalAvailable: 0,
  // items: [
  //   {
  //     templateId: "520365b8-0536-4c92-ae65-081085447b90",
  //     name: "test template",
  //     templateKind: 2,
  //     targetUserTypeIds: [
  //       "995f47f5-6859-4e06-ac67-bf8e09fe29c3",
  //       "11705188-5953-4b83-9ede-7f725b8fe764",
  //     ],
  //     templateSubject: "SMS subject",
  //     templateBody: "SMS body",
  //   },
  // ],
  items: [],
  targetUserTypes: [
    {
      TargetUserTypeId: '6008b81b-b018-4981-a132-2426b6bf5481',
      Name: 'Patient',
      Order: 1,
    },
    {
      TargetUserTypeId: 'da5094da-3141-4db4-b501-10d8914436fd',
      Name: 'Fetch User',
      Order: 2,
    },
  ],
};
