import axios from 'axios';
import { RPC_PATH } from 'components/FormBuilder/app-constants';
import UserService from '../userService';

import { getLwsid } from '../utils';

interface IRPC {
  method: string;
  params: any[];
}

let authenticated = true;

type ErrorFunction = (error: string | object | (object | string)[]) => void;
interface ApiInterface {
  authenticate(): Promise<void>;
  isAuthenticated(): boolean;

  rpc: (
    path: string,
    body: IRPC,
    errorFn?: ErrorFunction,
    responsePayloadOnly?: boolean,
  ) => Promise<any>;

  fakeCall: (
    path: string,
    body: IRPC,
    errorFn: ErrorFunction | undefined,
    result?: any,
  ) => Promise<any>;
}

const triggerErrorBoundary = (e: string | object | (string | object)[]): void => {
  throw e;
};
const userService = new UserService();
const ApiService = (): ApiInterface => {
  const authenticate = async (): Promise<void> => {
    authenticated = userService.isValidToken();
  };

  const isAuthenticated = (): boolean => authenticated;
  const rpc = async (
    path = '',
    body: IRPC,
    errorFn?: ErrorFunction,
    responsePayloadOnly?: boolean,
  ): Promise<any> => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${getLwsid()}`,
        'content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(
        RPC_PATH,
        {
          m: path ? `${path}.${body.method}` : body.method,
          p: body.params,
        },
        axiosConfig,
      );

      if (response.status > 399) {
        if (errorFn) {
          return errorFn(response.data);
        }
        return triggerErrorBoundary(response.data.message);
      }
      if (response.data.status) {
        if (errorFn) {
          return errorFn(response.data);
        }
        return triggerErrorBoundary(response.data.message);
      }

      const [err] = response.data.p;
      if (err) {
        if (errorFn) {
          return errorFn(err);
        }
        return triggerErrorBoundary(err);
      }

      return responsePayloadOnly ? response.data.p[1] : response.data;
    } catch (e) {
      if (errorFn) {
        errorFn(e);
      } else {
        triggerErrorBoundary(e);
      }
      return e;
    }
  };

  const fakeCall = async (
    path: string,
    body: IRPC,
    errorFn: ErrorFunction | undefined,
    results?: any,
  ): Promise<any> => {
    console.log(path, body, !!errorFn);
    return { p: [null, { results }] };
  };

  return {
    ...axios,
    authenticate,
    isAuthenticated,
    rpc,
    fakeCall,
  };
};

export default ApiService;
