import React from 'react';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { InputTextarea, SecondaryButton } from 'components/Legwork';
import { ErrorMsg, InputContainer } from './Share';

interface Props {
  shareLink: string;
  isError?: boolean;
  title?: string;
  description: string;
  onCopyLink: (link: string) => void;
}

const ShareLink = ({
  shareLink,
  isError = false,
  title = 'Share Link',
  description,
  onCopyLink,
}: Props) => {
  return (
    <div className='row'>
      <div className='control-group' style={{ marginBottom: '50px' }}>
        <label htmlFor='shared_link' className='control-label share-title'>
          {title}
        </label>
        <InputContainer>
          <span className='sub-label-share'>{description}</span>
          <InputTextarea
            id='shared-link-textarea'
            initialValue={shareLink}
            //@ts-ignore
            readOnly
            disabled={true}
          />
        </InputContainer>
        {isError && <ErrorMsg>This form has not been publish yet.</ErrorMsg>}
        <SecondaryButton
          icon={<FileCopyRoundedIcon />}
          title='Copy'
          style={{
            marginTop: '15px',
            backgroundColor: 'white',
          }}
          onClick={() => onCopyLink(shareLink)}
        />
      </div>
    </div>
  );
};

export default ShareLink;
