import PracticeCompany from 'components/Customers/CustomerDetail/GroupPractices/PracticeCompany';
import React, { useState, useEffect } from 'react';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TenantResposne } from 'global/requests/ResponseTypes';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import { isWindows } from 'react-device-detect';
import './GeneralSetting.scss';

export default function GeneralSetting() {
  const [generalSetting, setDataGeneralSetting] =
    React.useState<TenantResposne>();
  const [loading, setLoading] = useState(false);
  const getInfoGeneralSetting = async () => {
    setLoading(true);
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      const resTenantInfo = await configuredRequests.GET.getTenantInfo(
        res.tenantGroupId,
        res.tenantId
      );
      setDataGeneralSetting({
        ...resTenantInfo,
        phoneNumbers: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => !phoneNumber.isPracticePhone
        ),
        // @ts-ignore
        practicePhoneNumber: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => phoneNumber.isPracticePhone
        ),
      });
    } catch (err) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getInfoGeneralSetting();
    const settingMainView = document.getElementById(
      'settings--main-view-container'
    );
    if (settingMainView && isWindows) settingMainView.style.overflow = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (generalSetting) {
      setLoading(false);
    }
  }, [generalSetting]);

  return (
    <div className={`w-300 ${!loading && 'custom-height'}`}>
      {loading ? (
        <Loading className='loading-spinner-component' />
      ) : (
        <PracticeCompany practiceInfo={generalSetting} />
      )}
    </div>
  );
}
