/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Tabs extends React.Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => { },
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeChild: this.getActiveChild(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      this.setState({
        activeChild: this.getActiveChild(nextProps),
      });
    }
  }

  getActiveChild(props) {
    return (
      props.children.find(child => {
        return child.props.default;
      }) || props.children[0]
    );
  }

  handleTabClick(child) {
    if (child.props.disabled) {
      return;
    }

    this.setState(
      {
        activeChild: child,
      },
      () => this.props.onChange(child),
    );
  }

  render() {
    return (
      <div>
        <ul className='nav nav-tabs'>
          {this.props.children.map((child, idx) => {
            const className = classNames(child.props.className, {
              active: child === this.state.activeChild,
              disabled: child.props.disabled,
            });

            return (
              <li key={idx} className={className}>
                <a href='javascript:void 0' onClick={() => this.handleTabClick(child)}>
                  {child.props.title}
                </a>
              </li>
            );
          })}
        </ul>
        <div className='tab-content'>{this.state.activeChild}</div>
      </div>
    );
  }
}

export default Tabs;
