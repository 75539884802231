import React from 'react';
import { ListDate } from '../AvailabilityVM';
import SelectDateToogle from './SelectDateToogle';
import { Button } from '@material-ui/core';
import loading_spinner from 'media/images/loading-spinner.svg';

interface IHoursOfOperationForDay {
  listDate: ListDate[];
  onChangeDateTime: (dayOrder: number, event: any) => void;
  onChangeLucnhTime: (dayOrder: number, event: any) => void;
  onChangeToogle: (dayOrder: number) => void;
  onChangeToogleLunch: (dayOrder: number) => void;
  isLoadingHourOfOperation: boolean;
  updateHoursOfOperation?: () => void;
  disabledAll?: boolean;
}

const HoursOfOperator = (props: IHoursOfOperationForDay) => {
  const {
    listDate,
    onChangeDateTime,
    onChangeLucnhTime,
    onChangeToogle,
    onChangeToogleLunch,
    isLoadingHourOfOperation,
    updateHoursOfOperation,
    disabledAll,
  } = props;

  return (
    <div className='hours-of-operation'>
      <>
        {isLoadingHourOfOperation ? (
          <div className='loading-hours-info'>
            <img
              className='loading-hours-spin'
              src={loading_spinner}
              alt={'Loading'}
            />
          </div>
        ) : (
          <div className='group-hours-settings'>
            {listDate.map((date: ListDate) => (
              <div className='setting-content' key={date.dayOfWeekName}>
                <SelectDateToogle
                  startTime={date.startTime}
                  endTime={date.endTime}
                  enabled={date.active}
                  onChangePicker={onChangeDateTime}
                  onChangeToogle={onChangeToogle}
                  titleDay={date.dayOfWeekName}
                  dayOrder={date.dayOrder}
                  error={date?.error ?? ''}
                  disabledAll={disabledAll}
                />
                <SelectDateToogle
                  startTime={date.startTimeLunch}
                  endTime={date.endTimeLunch}
                  enabled={date.takeLunch && date.active}
                  onChangePicker={onChangeLucnhTime}
                  onChangeToogle={onChangeToogleLunch}
                  titleDay='Take Lunch'
                  dayOrder={date.dayOrder}
                  error={date?.errorLunch ?? ''}
                  disabledAll={disabledAll}
                />
              </div>
            ))}
          </div>
        )}
      </>
      {updateHoursOfOperation && (
        <Button
          onClick={updateHoursOfOperation}
          variant='contained'
          color='primary'
          disabled={listDate.some(
            (date: ListDate) => date.error || date.errorLunch
          )}
          className='save-button'
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default React.memo(HoursOfOperator);
