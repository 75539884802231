import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Checkbox, TextField } from '@material-ui/core';
import { Role, Screen } from 'global/requests/ResponseTypes';
import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, FastField, ErrorMessage, Field } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import ModalDiscard from '../Modal/ModalDiscard';
ReactModal.setAppElement('div#root');

interface RightModalSettingUserProps {
  displayRoleModal: boolean;
  setDisplayRoleModal: (arg: boolean) => void;
  titleText?: string;
  roleData: Role | undefined;
  screenData: Screen[] | undefined;
  handleReloadRoleList?: any;
  isEdit?: boolean;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const RoleValidationSchema = Yup.object().shape({
  roleName: Yup.string().required(ErrorMessageTypes.required),
  roleDescription: Yup.string().max(500, ErrorMessageTypes.incorrectFormat),
  rolePermissions: Yup.array().required(ErrorMessageTypes.required),
});

const RoleModal = (props: RightModalSettingUserProps) => {
  const {
    displayRoleModal,
    setDisplayRoleModal,
    titleText,
    isEdit,
    roleData,
    screenData,
  } = props;

  const screenOptions = screenData;

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState<string>('');
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(false);

  const roleInitialValues: Role = !isEdit
    ? {
        roleName: '',
        roleDescription: '',
        roleStatus: 'Active',
        rolePermissions: [],
      }
    : {
        roleId: roleData?.roleId,
        roleName: roleData?.roleName,
        roleStatus: roleData?.roleStatus,
        roleDescription: roleData?.roleDescription,
        rolePermissions: roleData?.rolePermissions,
      };

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledButton(true);
      const submitData = {
        roleName: values.roleName,
        roleStatus: values.roleStatus,
        roleDescription: values.roleDescription,
        rolePermissions: values.rolePermissions,
      };
      const res = isEdit
        ? await configuredRequests.PATCH.updateRole(values.roleId, submitData)
        : await configuredRequests.POST.createRole(submitData);
      setDisplayRoleModal(false);
      await props.handleReloadRoleList();
      if (res) {
        setErrorEmail('');
        setDirtyForm(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
      setDisabledButton(false);
    } catch (error) {
      setDisabledButton(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      if (error.response) {
        setErrorEmail(error.response.data.message);
      }
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplayRoleModal(dirtyForm);
    setErrorEmail('');
  };

  const handleCloseAddModal = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    setDisplayRoleModal(false);
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={displayRoleModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={roleInitialValues}
        validationSchema={RoleValidationSchema}
        validateOnMount={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
            dirty,
          } = formikProps;
          if (dirty && displayRoleModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Role' : ''}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Role Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={15}
                            name='roleName'
                            required
                            placeholder='Role Name'
                            className='setting__search-form'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                              setErrorEmail('');
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'roleName',
                                e.currentTarget.value.trim()
                              );
                              // setErrorEmail('');
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='roleName' />
                            {!errors.roleName && errorEmail}
                          </p>
                        </div>
                      </div>

                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Status <b className='require'>*</b>
                        </span>
                        <Field
                          as='select'
                          name='roleStatus'
                          value={values.roleStatus}
                          className='full-width custom-color'
                        >
                          <option value='Active'> Active</option>
                          <option value='Inactive'>Inactive</option>
                        </Field>
                      </div>
                    </div>
                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>Description</span>
                      <FastField
                        maxLength={500}
                        name='roleDescription'
                        required
                        placeholder='Description'
                        component='textarea'
                        className='description-input'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'roleDescription',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='roleDescription' />
                      </p>
                    </div>

                    <div className='setting__content--full-row block autocomplete'>
                      <span className='title-input-form'>
                        Access Permission <b className='require'>*</b>
                      </span>
                      <Field
                        name='rolePermissions'
                        component={Autocomplete}
                        placeholder='Access Permission'
                        multiple
                        id='checkboxes-tags-demo'
                        options={screenOptions}
                        defaultValue={roleInitialValues.rolePermissions}
                        value={values.rolePermissions}
                        defaultChecked={roleInitialValues.rolePermissions}
                        getOptionSelected={(option: Screen, value: Screen) => {
                          return option.screenId === value.screenId;
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option: Screen) => option.screenName}
                        noOptionsText={'No options'}
                        onChange={(e: any, value: Screen[]) => {
                          setFieldValue('rolePermissions', value);
                        }}
                        onBlur={() => {
                          if (!touched.rolePermissions)
                            formikProps.setFieldTouched('rolePermissions');
                        }}
                        renderOption={(option: Screen, { selected }: any) => {
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize='small' />
                                }
                                checkedIcon={<CheckBoxIcon fontSize='small' />}
                                style={{ marginRight: 8, color: '#1974ff' }}
                                checked={selected}
                                className='checked-role'
                              />
                              {option.screenName}
                            </React.Fragment>
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder='Search'
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            fullWidth
                          />
                        )}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='rolePermissions' />
                      </p>
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid || disabledButton}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        title='Confirmation'
        isOpenModal={openPopup}
        handleCloseModal={() => setOpenPopup(false)}
        handleConfirmModal={handleCloseAddModal}
      />
    </ReactModal>
  );
};
export default RoleModal;
