import styled from 'styled-components';
import { Button } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledButton = styled(Button)`
  padding: 4px 8px;
  border: none;
  border-radius: 6px;
  background: #e7eef4;
  height: 36px;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
  text-transform: none;
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};
  font-family: ${getThemeProp('components/Legwork/typography.fontFamily', 'Arial, sans-serif')};
  svg {
    margin-right: ${({ theme }): string => theme.spacing(0.5)}px;
    height: 100%;
    width: 1rem;
  }
  &:hover {
    background: #e7eef4;
    opacity: 0.8;
  }
  &&.Mui-disabled {
    background: #e7eef4;
    opacity: 0.7;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledButton };
