import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import EmailEditor from 'components/Communications/EmailEditor';
import WarningPopup from 'components/Global/WarningPopup/WarningPopup';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { SendEmailSupportSubmissionPayload } from 'global/requests/RequestTypes';
import { get } from 'lodash';
import React, { createRef, useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './EmailSupportSubmissonModal.scss';
interface EmailSupportSubmissionModalPropsType {
  displayEmailSupportModal: boolean;
  setDisplayEmailSupportModal: (arg: boolean) => void;
}

const emailSupport = 'support@thevethero.com';

const EmailSupportSubmissionModal = (props: EmailSupportSubmissionModalPropsType): JSX.Element => {
  const {
    displayEmailSupportModal,
    setDisplayEmailSupportModal,
  } = props;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);
  const emailEditorSubmitRef = createRef<HTMLButtonElement>();

  const customizeBodyMessage = useCallback((content: string) => `
  <!DOCTYPE html>
  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    </head>
    <body>
      <div>
        <p>${content}</p>
      </div>
    </body>
  </html>
  `, []);

  const sendEmailSupport = async (payload: SendEmailSupportSubmissionPayload) => {
    const payloadEmailSupport = {
      kind: 'email',
      recipient: payload.recipient,
      subject: payload.subject,
      content: customizeBodyMessage(payload.content),
      sendLaterDate: '',
    }
    try {
      const response = await configuredRequests.POST.sendEmailSupport(payloadEmailSupport);
      setDisplayEmailSupportModal(false);
      if (get(response, 'status', 0) === 200) {
        toast.success(
          <div className="notify-success d-flex">
            <FontAwesomeIcon icon={["fas", "check-circle"]} className="icon-check-status" />
            <div className="success-message">
              <b>Successfully</b>
              <p>Your email has been sent successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }

    } catch (error) {
      toast.error(
        <div className="notify-unsuccess d-flex">
          <FontAwesomeIcon icon={["fal", "times-circle"]} className="icon-check-status" />
          <div className="unsuccess-message">
            <b>Error</b>
            <p>Your email has not been sent yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplayEmailSupportModal(dirtyForm);
  };

  const handleCloseWarningPopup = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    setDisplayEmailSupportModal(false);
  }

  return (
    <ReactModal
      className='modal-user-settings react-modal email-support-all-submission'
      isOpen={displayEmailSupportModal}
      onRequestClose={handleCloseForm}
    >
      <div className='react-modal--content'>
        <div className='react-modal--settings__title'>
          <p className='title-text title-email-support-all-submission'>New Email</p>
          <button className='react-modal-close-icon close-email-support-modal'
            onClick={handleCloseForm}
          >
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        </div>
        <div className="edit-appointment">
          <EmailEditor
            receiverUserIDs={[]}
            emailRecipient={emailSupport}
            setIsDirtyForm={setDirtyForm}
            checkNewEmail={() => { }}
            hideTemplateName={true}
            hideDeliveryDate={true}
            useCustomSubmit={{
              innerSubmitRefObject: emailEditorSubmitRef,
              setIsSubmitEnabled: setIsSubmitEnabled
            }}
            sendEmailSupport={sendEmailSupport}
          />
        </div>
        <div className='react-modal-settings-footer email-support-all-submission-modal-footer'>
          <Button
            variant='contained'
            color='default'
            className='cancel-button'
            onClick={handleCloseForm}
          >
            <span>Cancel</span>
          </Button>

          <Button
            disabled={!isSubmitEnabled}
            type='submit'
            variant='contained'
            color='primary'
            className='button-send-email-support-submission'
            onClick={() => emailEditorSubmitRef.current?.click()}
          >
            <FontAwesomeIcon className='email-support-submission-icon' icon={['fas', 'paper-plane']} />
            Send Email
          </Button>
        </div>
      </div>
      {openPopup &&
        <WarningPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title='Are you sure?'
          description='You wish to cancel sending the email?'
          handleConfirmPopup={handleCloseWarningPopup}
        />
      }
    </ReactModal>
  )
};

export default EmailSupportSubmissionModal;
