import React from 'react';

export interface CustomCheckboxDiv extends HTMLButtonElement {
  "data-checked": boolean
}

interface CustomCheckboxProps {
  label?: {
    innerText: string,
    customClass?: string
    uniqueValue: string
  },
  checked: boolean,
  clickHandler: (event: React.MouseEvent<CustomCheckboxDiv>) => void 
};

const CustomCheckbox = (props: CustomCheckboxProps) => {

  const inputRef = React.createRef<CustomCheckboxDiv>();

  return (
    <button
      ref={inputRef}
      className="custom-checkbox"
      data-checked={props.checked}
      onClick={props.clickHandler}
    />
  )
};

export default CustomCheckbox;
