export const getRootDomain = (): string => {
  return (window as any)._env_?.VETHERO_API_URL || window.location.origin;
};

export const getAppBaseUrl = (): string => {
  return (window as any)._env_?.VETHERO_UI_ORIGIN || window.location.origin;
};

export const getPatientPortalUrl = (): string => {
  if ((window as any)._env_) {
    const urlPortal = (window as any)._env_?.VETHERO_PATIENT_PORTAL_URL;
    return urlPortal.includes('https://') ? urlPortal.slice(8) : urlPortal;
  }
  return window.location.origin;
};

export const getAutomationApiUrl = (): string => {
  return (
    (window as any)._env_?.VETHERO_AUTOMATION_API_URL ||
    (window as any)._env_?.VETHERO_API_URL ||
    window.location.origin
  );
};

export const getRealTimeUrl = (): string => {
  return (window as any)._env_?.VETHERO_REALTIME_URL || window.location.origin;
};

export const getRedirectUrl = (): string => {
  return (window as any)._env_?.VETHERO_REDIRECT_URL || "";
}