import React, { SetStateAction, useEffect } from 'react';
import GeneralSettings from '../GeneralSettings/GeneralSettingsSyncer';
// import AppointmentStatuses from '../AppointmentStatuses/AppointmentStatuses';
import { Typography, Tabs, AppBar, Tab } from '@material-ui/core';
import ConfigurationSetting from '../ConfigurationSetting/ConfigurationSetting';

interface IProps {
  showConfigurationTab: boolean;
  setShowConfigurationTab: React.Dispatch<SetStateAction<boolean>>;
}

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}
function TabContainer(props: TabContainerProps) {
  return <Typography component='div'>{props.children}</Typography>;
}

function SettingPracticeSyncer({
  showConfigurationTab,
  setShowConfigurationTab,
}: IProps) {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (!showConfigurationTab) {
      setValue(0);
    }
  }, [showConfigurationTab]);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  return (
    <div>
      <AppBar position='static' className='container__table'>
        <Tabs value={value} onChange={handleChange} indicatorColor='primary'>
          <Tab label='General' />
          {showConfigurationTab && <Tab label='Configuration' />}
          {/* <Tab label='Appointment Statuses' /> */}
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer id={1}>
          <GeneralSettings setShowConfigurationTab={setShowConfigurationTab} />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer id={2}>
          <ConfigurationSetting />
        </TabContainer>
      )}
      {/* {value === 1 && (
        <TabContainer id={2}>
          <AppointmentStatuses />
        </TabContainer>
      )} */}
    </div>
  );
}

export default SettingPracticeSyncer;
