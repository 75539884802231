import React from 'react';
import SecondaryButton from 'components/Legwork/buttons/SecondaryButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationBadge from 'components/Legwork/statuses/ConfirmationBadge';
import CenterContainer from './styled-components';

export interface ButtonAndStatusProps {
  actionHandler: () => void;
  buttonText: string;
  loading?: boolean;
  showStatus: boolean;
  statusText?: string;
  'data-testid'?: string;
}

const ButtonAndStatus = (props: ButtonAndStatusProps): React.ReactElement => {
  const {
    actionHandler,
    buttonText,
    loading = false,
    showStatus,
    statusText = '',
    'data-testid': dataTestId = 'status-button',
  } = props;
  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress style={{ height: '1rem', width: '1rem' }} data-testid='spinner' />
      </CenterContainer>
    );
  }
  return (
    <CenterContainer>
      {showStatus ? (
        <ConfirmationBadge label={statusText} />
      ) : (
        <SecondaryButton onClick={actionHandler} title={buttonText} data-testid={dataTestId} />
      )}
    </CenterContainer>
  );
};
export default ButtonAndStatus;
