import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column, ServiceResponse } from 'global/requests/ResponseTypes';
import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import ServiceModal from './ServiceModal';

const columns: Column[] = [
  { id: 'serviceVersion', label: 'Service Version' },
  { id: 'releasedDate', label: 'Released Date' },
  { id: 'actionDownload', label: 'Action' },
];

interface ServiceDataTypes {
  id: string;
  releasedDate: string;
  actionDownload: string;
  serviceVersion: string;
}

const Service = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [displayRoleModal, setDisplayRoleModal] = React.useState(false);
  const [serviceInformationData, setServiceInformationData] =
    React.useState<ServiceDataTypes[]>();
  const [isOpenEditRoleModal, setIsOpenEditRoleModal] =
    React.useState<boolean>(false);
  const [idValue, setIdValue] = React.useState<string>('');

  const getAllServices = async () => {
    try {
      const res = await configuredRequests.GET.getServices();
      if (res) {
        const serviceDataTable = res.items.map((item: ServiceResponse) => {
          return {
            id: item.id,
            releasedDate: moment(item.releasedDate).format(
              'MM/DD/YYYY hh:mm A'
            ),
            actionDownload: item.downloadLink,
            serviceVersion: item.version,
          };
        });
        const sortedServicesData = orderBy(
          serviceDataTable,
          [(services) => services?.serviceVersion?.toLowerCase()],
          ['desc']
        );
        setServiceInformationData(sortedServicesData as ServiceDataTypes[]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllServices();
  }, []);

  const openRoleInformationModal = (idValue: string) => {
    console.log('idValue', idValue);
    setIsOpenEditRoleModal(idValue !== '');
    setIdValue(idValue);
    setDisplayRoleModal(true);
  };

  return (
    <div className='communication-container user-setting w-300 roles-table setting-detail setting-scroll--custom'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        <CommunicationTable
          columns={columns}
          dataTable={serviceInformationData || []}
          isSort={false}
          isDisplayedSearchAndFilter={true}
          isDisplayedSearch={true}
          isDisplayedFilter={false}
          isDisplayedButton={true}
          keySearch='name'
          placeholderSearch='Search by service version'
          buttonName='New Version'
          iconButton={['far', 'plus']}
          isLoading={isLoading}
          screen={'SERVICE'}
          openModal={(idValue: string) => {
            openRoleInformationModal(idValue);
          }}
        />
      </div>

      <ServiceModal
        handleReloadRoleList={getAllServices}
        displayRoleModal={displayRoleModal}
        setDisplayRoleModal={setDisplayRoleModal}
        titleText={isOpenEditRoleModal ? 'Edit' : 'Add New'}
        isEdit={isOpenEditRoleModal}
        idValue={idValue}
      />
    </div>
  );
};

export default Service;
