import * as t from 'io-ts';

export const EmitterRequestType = t.partial({
  methodName: t.string,
  methodId: t.string,
  version: t.string,
  tenantId: t.string,
  url: t.string,
  command: t.string,
});
export type EmitterRequestType = t.TypeOf<typeof EmitterRequestType>;

export const EmitterCreateResponseType = t.partial({
  id: t.string,
  methodId: t.string,
  methodName: t.string,
  emitterStatus: t.string,
  executeContent: t.string,
  tenantId: t.string,
  createdTime: t.string,
  updateTime: t.string,
  tryCount: t.number,
  version: t.string,
  url: t.string,
  milliseconds: t.string,
});
export type EmitterCreateResponseType = t.TypeOf<typeof EmitterCreateResponseType>;

export const EmitterResponseType = t.type({
  emitterExecuteId: t.string,
  methodName: t.string,
  status: t.string,
  executeContent: t.string,
  tenantId: t.string,
  createdDate: t.string,
  updatedDate: t.string,
  tryCount: t.number,
  version: t.string,
  url: t.string,
  milliseconds: t.string
});
export type EmitterResponseType = t.TypeOf<typeof EmitterResponseType>;


export const TextWritebackEmitterRequestType = t.partial({
  methodName: t.string,
  methodId: t.string,
  tenantId: t.string,
  contactId: t.string,
  petId: t.string,
  messageId: t.string,
});
export type TextWritebackEmitterRequestType = t.TypeOf<typeof TextWritebackEmitterRequestType>;
