import { useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';

interface IDecodeToken {
  allowedPath: Array<string>;
  roleUser: Array<string>;
}

export const listMatchRouterWithScreen = {
  Dashboard: '/Dashboard',
  Appointments: '/Appointments',
  Contacts: '/Contacts',
  Communications: '/Communications',
  Conversations: '/Messenger',
  Telemedicine: '/Telemedicine',
  Settings: '/Settings',
  'All Forms': '/FormBuilder',
  'All Submissions': '/AllSubmissions',
  Reviews: '#',
  'Online Appointment Booking': '/AppointmentBooking',
  'Online Payment': '/Invoicing',
  Groups: '/Groups',
  Billing: '/Billing',
  Syncer: '/Syncer',
  Alerts: '/Alerts',
  'FetchPRM Settings': '/SettingsAdmin',
  'Patient Portal': '#',
};

export const listRoleWithFeature = {
  Dashboard: '/Dashboard',
  Appointments: '/Appointments',
  Contacts: '/Contacts',
  Communications: '/Communications',
  Settings: '/Settings',
  Conversations: '/Messenger',
  Telemedicine: '/Telemedicine',
  'All Submissions': '/AllSubmissions',
  'All Forms': '/FormBuilder',
  'Online Appointment Booking': '/AppointmentBooking',
  Groups: '/Groups',
  Billing: '/Billing',
  Syncer: '/Syncer',
  Alerts: '/Alerts',
  'FetchPRM Settings': '/SettingsAdmin',
  'Patient Portal': '#',
};

// This is to make sure jwt's screen will always be array type
export const convertRoleOrScreen = (data: Array<string> | string | undefined) =>
  !data ? [] : typeof data === 'string' ? [data] : data;

// This function is called on component to help it detect role and access right of current user
export const detectRoleAndScreen = (token: string) => {
  const {
    screens,
    features,
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': role,
  } = jwtDecode(token);
  const screensConverted = convertRoleOrScreen(screens);
  const roleUser = convertRoleOrScreen(role);
  const featuresConverted = convertRoleOrScreen(features);

  return { screens: screensConverted, roleUser, features: featuresConverted };
};


// This function returns url paths that an user is allowed to access, based on screens and features
export const getPaths = (screens: string[], features: string[]) => {
  let allowedPath: Array<string> = [];

  // when feature has priority higher than screen
  const disabledPathsByFeature: string[] = [];    
  const keys = Object.keys(listRoleWithFeature);
  const size = keys.length;

  //This for loop is to find an array of restricted paths
  for (let i = 0; i < size; i++) {
    if (features.includes(keys[i])) continue;
    // @ts-ignore
    const restrictedpPaths = listRoleWithFeature[keys[i]];
    if (typeof restrictedpPaths === 'object') {
      disabledPathsByFeature.push(...restrictedpPaths);
    } else {
      disabledPathsByFeature.push(restrictedpPaths);
    }
  }

  // This loop will return an array of allowed paths
  features.forEach((feature) => {
    const path = get(listRoleWithFeature, feature,'');
    if (typeof path === 'object') {
      allowedPath.push(...path);
    } else {
      allowedPath.push(path);
    }
  });
  

  const enabledPathsByScreen = screens.map((screen: string): string =>
    get(listMatchRouterWithScreen, screen, '')
  );

  enabledPathsByScreen.forEach((path) => {
    if (!disabledPathsByFeature.includes(path)) {
      allowedPath.push(path);
    }
  });

  return allowedPath;
};



// This function will get the jwt from session storage and decode it to get allowedPath and roleUser
export function useDecodeToken(): IDecodeToken {
  return useMemo(() => {
    const token: string | null = sessionStorage.getItem('AuthToken');
    let allowedPath: Array<string> = [];
    let roleUser: Array<string> = [];

    if (token) {
      const { screens, roleUser: role, features } = detectRoleAndScreen(token);
      allowedPath = getPaths(screens, features);

      roleUser = role;
    }

    return {
      allowedPath: Array.from(new Set(allowedPath)),
      roleUser,
    };
  }, []);
}
