import React, { useEffect, useState } from "react";
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { useRouter } from 'hooks/useRouter';
import queryString from 'query-string';
import { get } from 'lodash';
import headerLogo from "media/images/Vet-Hero-Frenchie.png";

const ConfirmAppointment = () => {
  const router = useRouter();
  const [messages, setMessages] = useState('');
  const getConfirmAppointment = async (token: string) => {
    try {
      const response = await configuredRequests.POST.confirmAppointment(token);
      if (response.status === 200) {
        if(get(response.data, 'count', 0) === 0) {
          setMessages('The appointment had already been confirmed');
        } else {
          setMessages('Success! Thanks for your confirmation');
        }
      } else {
        setMessages('Confirmation failed for an unknown reason');
      }
    } catch (error) {
      setMessages("Error: " + error.response?.data.message + " Please contact support with this error.");
    }
  }

  useEffect(() => {
    const { token } = queryString.parse(router.location.search);
    if (token && typeof token === 'string') getConfirmAppointment(token);
  }, [router]);

  return <div>
    <>
      <div className="login__container">
        <div className="login__form-container">
          <div className="login__form">
            <div className="login__logo-container" style={{"marginBottom": "18%"}}>
              <img src={headerLogo} alt={"Fetch logo"} />
            </div>
            <div style={{"width": "400px"}}>
              <span style={{ "marginBottom": "10%", "fontSize": "20px" }}>{messages ? messages : 'Loading...'}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  </div>
}

export default ConfirmAppointment;
