import React from 'react';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';

const LoadingPs = () => {
  return (
    <div className='loading-ps-center'>
      <Loading />
    </div>
  );
};

export default LoadingPs;
