import React from 'react';
import TableWithHeader from '../components/TableWithHeader';
import RenderRows from '../components/RenderRows';
import { IRow } from '../components/Row/Row';

export interface StandardTableProps {
  headCells: (string | React.ReactElement)[];
  rows: IRow[];
  generateRow: (row: IRow) => React.ReactElement[];
  dense?: boolean;
  withoutHeader?: boolean;
  hoverable?: boolean;
}

const StandardTable = (props: StandardTableProps): React.ReactElement => {
  const { headCells, rows, generateRow, dense, withoutHeader, hoverable = false } = props;

  return (
    <TableWithHeader headCells={headCells} dense={dense} withoutHeader={withoutHeader}>
      <RenderRows rows={rows} generateRow={generateRow} hoverable={hoverable} />
    </TableWithHeader>
  );
};

StandardTable.defaultProps = {
  dense: false,
};

export default StandardTable;
