import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  label { 
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    color: #6E84A3;
  }
  hr {
    border: 1px dashed #DEE0EC;
  }
  span { 
    color: #2C3742;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }
`;

interface IInforAppointMent {
  title: string;
  content: string;
}

const InforAppointMent: React.FC<IInforAppointMent> = ({ title, content }) => (
  <Wrapper>
    <label>{title}</label>
    <hr />
    <span>{content}</span>
  </Wrapper>
);

export default InforAppointMent;
