import { Button } from '@material-ui/core';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { Field, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  Column,
  SyncerFilterFormType,
  SyncerFilterOptionType,
  SyncerPracticeType,
} from 'global/requests/ResponseTypes';
import { get, sortBy } from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';

const columns: Column[] = [
  { id: 'practiceName', label: 'Group/Practice Name' },
  { id: 'lastAuth', label: 'Last Auth' },
  { id: 'lastSync', label: 'Last Sync' },
  { id: 'livePractice', label: 'Live Practice' },
  { id: 'deliveryPaused', label: 'Delivery Paused' },
  { id: 'driver', label: 'Driver' },
  { id: 'version', label: 'Driver Version' },
];

const optionLivePractices = [
  { name: 'Yes ', value: 'Yes' },
  { name: 'No ', value: 'No' },
];

const optionDeliverPauses = [
  { name: 'Yes ', value: 'Yes' },
  { name: 'No ', value: 'No' },
  { name: 'N/A ', value: 'N/A' },
];

type syncerPracticeDataType = {
  id?: string;
  practiceName?: string;
  lastAuth?: string;
  lastSync?: string;
  livePractice?: string;
  deliveryPaused?: string;
  driver?: string;
  version?: string;
};

let source: CancelTokenSource;

const SyncerAdmin = (): JSX.Element => {
  const [syncerFilterOptions, setSyncerFilterOptions] =
    useState<SyncerFilterOptionType>({});
  const [syncerPractices, setSyncerPractices] =
    useState<SyncerPracticeType[]>();
  const [isLoadingSyncerPractice, setIsLoadingSyncerPractice] =
    useState<boolean>(true);

  const convertSyncerPracticeData = () => {
    let result: syncerPracticeDataType[] = [];
    syncerPractices?.map((item) => {
      result.push({
        id: item.tenantId,
        practiceName: item.tenantName,
        lastAuth: item.lastAuth
          ? moment(item.lastAuth).format('MM/DD/YYYY hh:mm A')
          : '',
        lastSync: item.lastSync
          ? moment(item.lastSync).format('MM/DD/YYYY hh:mm A')
          : '',
        livePractice: item.livePractice,
        deliveryPaused: item.deliveryPasused,
        driver: item.driverName,
        version: item.driverVersion,
      });
    });
    return result;
  };

  const getSyncerFilterOptions = async () => {
    setIsLoadingSyncerPractice(true);
    try {
      const res = await configuredRequests.GET.getSyncerFilterOptions();
      if (res) {
        const drivers = sortBy(res.drivers, 'driverName');
        const groups = sortBy(res.groups, 'groupName');
        setSyncerFilterOptions({ ...res, drivers, groups });
        getSyncerPractices({
          sortBy: get(res, 'sortBys[1].value', ''),
          sortOrder: get(res, 'sortOrders[0].value', ''),
          livePractice: 'Yes',
          deliveryPaused: 'No',
        });
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const getSyncerPractices = async (filterOptions: SyncerFilterFormType) => {
    setIsLoadingSyncerPractice(true);
    try {
      if (source) {
        source.cancel();
      }
      source = axios.CancelToken.source();
      const res = await configuredRequests.GET.getSyncerPractices(
        filterOptions,
        source.token
      );
      setSyncerPractices(res.driverTenants);
      setIsLoadingSyncerPractice(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsLoadingSyncerPractice(false);
      }
      console.log('error', err);
    }
  };

  useEffect(() => {
    getSyncerFilterOptions();
    // getSyncerPractices({sortBy: 'LAST_AUTH', sortOrder: 'ASC', livePractice: 'Yes', deliveryPaused: 'Yes'});
  }, []);

  const submitData = async (values: any) => {
    const submitData: SyncerFilterFormType = {
      driver: values.driver,
      driverVersion: values.driverVersion,
      sortBy: values.sortBy || get(syncerFilterOptions, 'sortBys[1].value', ''),
      sortOrder:
        values.sortOrder || get(syncerFilterOptions, 'sortOrders[0].value', ''),
      group: values.group,
      livePractice: values.livePractice || optionLivePractices[0].value,
      deliveryPaused: values.deliveryPaused || optionDeliverPauses[1].value,
    };
    getSyncerPractices(submitData);
  };

  const renderDriverVersionOptions = (driverIdSelected: string | undefined) => {
    if (!syncerFilterOptions.drivers) return <></>;

    if (driverIdSelected) {
      const driverSelected = syncerFilterOptions.drivers?.filter(
        (item) => item.driverId === driverIdSelected
      )[0];
      return (
        <>
          {driverSelected.versions &&
            driverSelected.versions.map((item) => {
              return (
                <option value={item.versionId} key={item.versionId}>
                  {item.versionName}
                </option>
              );
            })}
        </>
      );
    }
    return <></>;
  };

  return (
    <div className='communication-container w-100 syncer-dashboard setting-scroll--custom'>
      <div className='communication-container__table'>
        <Formik
          initialValues={{
            driver: '',
            sortBy: `LAST_SYNC`,
            deliveryPaused: 'No',
          }}
          onSubmit={(values) => {
            submitData(values);
          }}
        >
          {(formikProps) => {
            const { handleSubmit, values } = formikProps;
            return (
              <div className='edit-appointment '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 react-modal--settings__content syncer-admin'
                  >
                    <div className='setting__content--full-row margin-10-0 d-flex w-100'>
                      <div className='form-input-settings w-25'>
                        <span className='title-input-form'>Driver</span>
                        <div className='mr-15'>
                          <Field
                            className='w-100 driver-style'
                            name='driver'
                            as='select'
                            onChange={(
                              e: React.FocusEvent<HTMLInputElement>
                            ) => {
                              formikProps.handleChange(e);
                              formikProps.setFieldValue('driverVersion', '');
                            }}
                          >
                            <option value=''>All Drivers</option>
                            {syncerFilterOptions &&
                              syncerFilterOptions.drivers?.map((item) => {
                                return (
                                  <option
                                    value={item.driverId}
                                    key={item.driverId}
                                  >
                                    {item.driverName}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>

                      <div className='form-input-settings w-25'>
                        <span className='title-input-form'>Driver Version</span>
                        <div className='mr-15'>
                          <Field
                            className='w-100 driver-style'
                            name='driverVersion'
                            as='select'
                          >
                            <option value=''>All Versions</option>
                            {renderDriverVersionOptions(values.driver)}
                          </Field>
                        </div>
                      </div>

                      <div className='form-input-settings w-25'>
                        <span className='title-input-form'>Sort By</span>
                        <div className='mr-15'>
                          <Field
                            className='w-100 driver-style'
                            name='sortBy'
                            as='select'
                          >
                            {syncerFilterOptions &&
                              syncerFilterOptions.sortBys?.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>

                      <div className='form-input-settings w-25'>
                        <span className='title-input-form'>Sort Order</span>
                        <div>
                          <Field
                            className='w-100 driver-style'
                            name='sortOrder'
                            as='select'
                          >
                            {syncerFilterOptions &&
                              syncerFilterOptions.sortOrders?.map((item) => {
                                return (
                                  <option value={item.value} key={item.value}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex-end'>
                      <div className='setting__content--full-row  margin-10-0 d-flex-end w-100'>
                        <div className='form-input-settings w-50 mr-30'>
                          <span className='title-input-form'>Group</span>
                          <div className=''>
                            <Field
                              className='w-100 driver-style'
                              name='group'
                              as='select'
                            >
                              <option value=''>All Groups</option>
                              {syncerFilterOptions &&
                                syncerFilterOptions.groups?.map((item) => {
                                  return (
                                    <option
                                      value={item.groupId}
                                      key={item.groupId}
                                    >
                                      {item.groupName}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                        </div>

                        <div className='w-50 d-flex-end'>
                          <div className='form-input-settings w-50 _ml-15 mr-15'>
                            <span className='title-input-form'>
                              Live Practice
                            </span>
                            <div>
                              <Field
                                className='w-100 driver-style'
                                name='livePractice'
                                as='select'
                              >
                                {syncerFilterOptions &&
                                  // syncerFilterOptions.livePractices?.map(
                                  optionLivePractices.map((item) => {
                                    return (
                                      <option
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </Field>
                            </div>
                          </div>
                          <div className='form-input-settings w-50 mr-15'>
                            <span className='title-input-form'>
                              Delivery Paused
                            </span>
                            <div>
                              <Field
                                className='w-100 driver-style'
                                name='deliveryPaused'
                                as='select'
                              >
                                {syncerFilterOptions &&
                                  // syncerFilterOptions.deliveryPaused?.map(
                                  optionDeliverPauses.map((item) => {
                                    return (
                                      <option
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </Field>
                            </div>
                          </div>

                          <div className='form-input-settings w-50'>
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              disabled={!formikProps.isValid}
                            >
                              <span>Search</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Fragment>
              </div>
            );
          }}
        </Formik>

        <p className='title-practice'>Practice Name</p>
        <CommunicationTable
          columns={columns}
          dataTable={convertSyncerPracticeData()}
          isSort={false}
          isDisplayedSearchAndFilter={true}
          isDisplayedSearch={true}
          isDisplayedFilter={false}
          isDisplayedButton={false}
          isLoading={isLoadingSyncerPractice}
          keySearch='practiceName'
          placeholderSearch='Search by practice name'
          screen={'SYNCER'}
          router={'/Practices/Syncer'}
          keySort='name'
        />
      </div>
    </div>
  );
};

export default SyncerAdmin;
