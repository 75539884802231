/* eslint-disable import/prefer-default-export */
import axios, { AxiosInstance } from 'axios';
import { getLwsid } from './cookies';

export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const authToken = getLwsid();

  const headers: { [key: string]: string } = {};

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const instance = axios.create({
    baseURL,
    responseType: 'json',
    headers,
  });

  instance.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response): any => {
      if (response.status >= 400) {
        throw new Error(response.data.message);
      }
      return response.data;
    },
    (error): Error => {
      throw new Error(
        error.response?.data?.error ?? error.response?.data?.message ?? error.message ?? error,
      );
    },
  );

  return instance;
};
