import React, { useEffect } from 'react';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Link } from 'react-router-dom';
import JoinAppointmentIcon from 'components/Global/TeleMed/JoinAppointmentIcon';
import moment from 'moment';
import { GetCurrentTenantAppointmentInRangeArguments } from 'global/requests/RequestTypes';
import StatusSelect from 'components/Global/StatusSelect/StatusSelect';
import TaskButton from 'components/Global/TaskButton/TaskButton';
import _ from 'lodash';
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import {
  AppointmentSummaryEntry,
  AppointmentMultipleInfoResponse,
} from 'global/requests/ResponseTypes/Appointments';
import loading_spinner from 'media/images/loading-spinner.svg';

type AppointmentField = {
  children?: {
    before?: JSX.Element[];
    after?: JSX.Element[];
  };
  customClass?: string;
  innerText?: string;
  petName?: JSX.Element;
};

const renderAppointmentEntry = (
  appointmentFields: AppointmentField[],
  rowIndex: number
) => {
  return (
    <tr
      key={'appointment-entry-' + rowIndex}
      className='appointment__entry-row'
    >
      {appointmentFields.map((appointmentFieldData, sectionIndex) => {
        return (
          <td
            className={
              'appointment__entry-row-division' +
              (appointmentFieldData.customClass ?? '')
            }
            key={'appointment-row-' + rowIndex + '-section-' + sectionIndex}
          >
            {appointmentFieldData.children?.before
              ? appointmentFieldData.children?.before
              : null}

            {appointmentFieldData.innerText}

            {appointmentFieldData.petName}

            {appointmentFieldData.children?.after
              ? appointmentFieldData.children?.after
              : null}
          </td>
        );
      })}
    </tr>
  );
};
interface DailyAppointmentProps {
  setDisplayTelemedModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTelemedModalAppointmentData: React.Dispatch<
    React.SetStateAction<AppointmentSummaryEntry>
  >;
  setIsAlreadyInCall(a: boolean): void;
  reload?: boolean;
  setReload?(a: boolean): void;
  settingsTimezoneData: string;
}

const DailyAppointment = (props: DailyAppointmentProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [appointmentInfo, setAppointmentInfo] =
    React.useState<AppointmentMultipleInfoResponse>();

  const [completedTasksLookup, setCompletedTasksLookup] = React.useState<{
    [key: string]: boolean;
  }>({});

  const defaultStartDate = moment(new Date().toISOString()).format(
    'YYYY-MM-DD'
  );
  //TODO determine max date-range for final build
  const defaultEndDate = moment(new Date().toISOString())
    .add(7, 'days')
    .format('YYYY-MM-DD');

  // larger date range for testing when there are no appointments "today"
  // const defaultStartDate = moment(new Date().toISOString()).add(-250, 'days').format('YYYY-MM-DD');
  // const defaultEndDate = moment(new Date().toISOString()).add(250, 'days').format('YYYY-MM-DD');

  // store arguments for the 'daily appointments' component since it will always have the same date range (today)
  // reason for this: Telemedicine and DailyAppointments use <StatusSelect> element
  // but only Telemedicine will use different arguments for infinite scroll
  const [getDailyAppointmentArguments] =
    React.useState<GetCurrentTenantAppointmentInRangeArguments>({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });

  useEffect(() => {
    // The app crashed when this request failed
    // configuredRequests.GET.systemSettings();
    // every request needs to be in a try/catch because of the built in error handling
    // I've added a function to do the try/catch but there is nothing being done with the returned data right now

    if (props.reload) {
      handleSubmitAppointmentGet(getDailyAppointmentArguments).then(() => {
        if (props.setReload) {
          props.setReload(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload]);

  const getCurrentTenantAppointmentsInRange = async (
    startDate: string,
    endDate: string,
    options?: {
      userID?: string;
      limit?: number;
      offset?: number;
      kind?: string;
      withStatus?: string;
      isWithoutEndDate?: boolean;
    }
  ) => {
    try {
      const res =
        await configuredRequests.GET.currentTenantMultipleAppointmentsInRange(
          startDate,
          endDate,
          {
            limit: 5,
            withStatus: 'unconfirmed,confirmed,inprogress',
            isWithoutEndDate: true,
            ...options,
          }
        );

      // const statusList: string[] = [];
      // res.appointments.forEach((item) => {
      //   item.forEach((appointmentTask) => {
      //     statusList.push(appointmentTask.status);
      //   });
      // });
      // const isHaveInCall = statusList.filter((item) => item === 'inProgress');
      // props.setIsAlreadyInCall(isHaveInCall.length > 0)
      setIsLoading(false);
      setAppointmentInfo(res);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const checkIncall = async () => {
    try {
      const res = await configuredRequests.GET.checkIncall();
      if (res) {
        props.setIsAlreadyInCall(res.isInCall);
      }
    } catch (err) {
      props.setIsAlreadyInCall(false);
    }
  };

  const handleSubmitAppointmentGet = async (
    getDailyAppointmentArguments: GetCurrentTenantAppointmentInRangeArguments,
    reloading: boolean = false
  ) => {
    const { startDate, endDate, options } = getDailyAppointmentArguments;

    if (startDate && startDate.length && endDate && endDate.length) {
      if (!reloading) {
        setIsLoading(true);
      }
      await getCurrentTenantAppointmentsInRange(startDate, endDate, options);
      return;
    }
    //TODO remove alert in prod
    alert('error in date range');
  };

  React.useEffect(() => {
    handleSubmitAppointmentGet(getDailyAppointmentArguments);
    // quiets the linter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAppointmentData = (
    appointmentInfo: AppointmentSummaryEntry[]
  ) => {
    if (appointmentInfo.length) {
      const sortedAppointments = appointmentInfo.sort(
        (a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime)
      );
      if (sortedAppointments.length) {
        // this map renders a populated table row element for each appointment object
        return sortedAppointments.map((appointment, rowIndex) => {
          const filteredTasks =
            appointment?.tasks?.filter(
              (taskEntry) => taskEntry.status !== 'done'
            ) ?? [];
          const sortedTask = _.orderBy(filteredTasks, ['name'], ['asc']);
          const contactData = appointment.attendees.users.length
            ? appointment.attendees.users[0]
            : undefined;

          const contactName =
            contactData && contactData.name ? contactData.name : '';

          const contactID = contactData ? contactData.id : '';

          const petData = appointment?.patients?.pets.length
            ? appointment.patients.pets[0]
            : undefined;

          const petName =
            petData && petData.name ? (
              <span>{petData.name}</span>
            ) : (
              <span className='require'>Missing Pet's Name</span>
            );

          // const dateTimeSegmentOptions = {
          //   //  weekday: 'long',
          //   //  year: 'numeric',
          //   //  month: 'long',
          //   //  day: 'numeric',
          //   hour: 'numeric',
          //   minute: 'numeric',
          // };

          // const formattedAppointmentDate = new Intl.DateTimeFormat(
          //   'en-US',
          //   dateTimeSegmentOptions
          // ).format(Date.parse(appointment.dateTime));
          // const formattedAppointmentDate = new Date(appointment.dateTime).toLocaleString("en-US", { timeZone: 'Asia/Bangkok' });
          // const formattedTime24h = (moment(formattedAppointmentDate).format('HH:mm A'));
          const formattedTime24h = moment(appointment.dateTime).format(
            'hh:mm A'
          );

          // each entry in this array represents a <td> within the current <tr>
          const appointmentFieldConfigs: AppointmentField[] = [
            {
              children: {
                before: [
                  appointment.kind === 'telemed' && appointment.teleMedKey ? (
                    <JoinAppointmentIcon
                      key={'join-appointment-icon-' + appointment.appointmentId}
                      checkIncall={checkIncall}
                      setModalIsOpen={(arg: boolean) => {
                        props.setTelemedModalAppointmentData({
                          appointmentId: appointment.appointmentId,
                          dateTime: appointment.dateTime,
                          externalId: appointment.externalId,
                          status: appointment.status,
                          teleMedKey: appointment.teleMedKey,
                          attendees: appointment.attendees,
                          patients: appointment.patients,
                          kind: appointment.kind,
                          durationMinutes: appointment.durationMinutes, // timeSpan?
                          tasks: appointment.tasks,
                          appointmentUpdatedTime:
                            appointment.appointmentUpdatedTime ?? '',
                        });
                        props.setDisplayTelemedModal(arg);
                      }}
                      customClasses={{
                        button: 'telemed-icon-button--join_appointment',
                      }}
                    />
                  ) : (
                    <div
                      key={'non-telemed-appointment--' + appointment.dateTime}
                    ></div>
                  ),
                ],
              },
            },
            {
              innerText: formattedTime24h,
            },
            {
              children: {
                before: [
                  <Link
                    key={'contact-link-' + contactID}
                    to={{
                      pathname: contactID ? '/Contacts/View/' + contactID : '',
                      state: {
                        fromDashboard: true,
                      },
                    }}
                    title={`View ${contactName}'s full details in Contacts`}
                    className='lnk'
                  >
                    {/** Change to link */}
                    {contactName}
                  </Link>,
                ],
              },
            },
            {
              petName: petName,
            },
            {
              children: {
                before: [
                  <StatusSelect
                    key={'status_select--' + appointment.appointmentId}
                    // appointmentInformation={appointment}
                    id={appointment.appointmentId}
                    currentStatus={appointment.status}
                    refreshOnSubmitStatusConfig={{
                      handleSubmitAppointmentGet,
                      getDailyAppointmentArguments,
                    }}
                  />,
                ],
              },
            },
            {
              petName: appointment.providerName ? (
                <span>{appointment.providerName}</span>
              ) : (
                <span className='require'>Missing Provider</span>
              ),
            },
            {
              petName: appointment.appointmentTypeName ? (
                <span>{appointment.appointmentTypeName}</span>
              ) : (
                <span className='require'>Missing Appointment Type</span>
              ),
            },
            // This is an inline render instead of a function because making a function adds
            // excessive type information and decouples the text and button logic
            ...(sortedTask
              ? [
                {
                  children: {
                    before: sortedTask.map((task, index) => {
                      const shouldRenderNothing = task.status === 'done';
                      return (
                        <div
                          key={
                            'appointment__task-id-' +
                            task.taskId +
                            '-' +
                            index
                          }
                          className='appointment__task-text-container'
                        >
                          <div className='appointment__task-text'>
                            {shouldRenderNothing ? ' ' : task.details}
                          </div>
                        </div>
                      );
                    }),
                  },
                },
                {
                  children: {
                    before: sortedTask.map((task, index) => {
                      const shouldRenderNothing = task.status === 'done';
                      return shouldRenderNothing ? (
                        <div
                          className='appointment__task-complete-container opacity-0 visibility-hidden'
                          key={
                            'appointment__task-complete-' +
                            task.taskId +
                            '-' +
                            index
                          }
                        >
                          {' '}
                        </div>
                      ) : (
                          <TaskButton
                            key={
                              'appointment__task-complete-' +
                              task.taskId +
                              '-' +
                              index
                            }
                            appointmentInformation={appointment}
                            taskID={task.taskId}
                            handleSubmitClick={() => {
                              setCompletedTasksLookup({
                                ...completedTasksLookup,
                                [task.taskId]: true,
                              });
                            }}
                          />
                        );
                    }),
                  },
                },
              ]
              : // if there are no tasks then we return empty objects instead of null
              // this makes the row render empty cells and maintains the layout
              [{}, {}]),
          ];

          return renderAppointmentEntry(appointmentFieldConfigs, rowIndex);
        });
      }
    }
  };

  const showTitleDatetime = (data: AppointmentSummaryEntry[]) => {
    if (data.length && props.settingsTimezoneData) {
      const dateNeedToCompare = data[0].dateTime;
      const dateFollowTimezoneSetting = convertLocalTimeToAnotherTimeZone(
        props.settingsTimezoneData
      );
      const getFullDate = dateFollowTimezoneSetting.format('MM/DD/YY');

      if (dateFollowTimezoneSetting.isSame(moment(dateNeedToCompare), 'date')) {
        return { onlyDate: 'Today', fullDate: getFullDate };
      } else if (
        (moment(dateFollowTimezoneSetting), 'date') + 1 ===
        (moment(dateNeedToCompare), 'date')
      ) {
        return { onlyDate: 'Tomorrow', fullDate: getFullDate };
      } else {
        return {
          onlyDate: moment(dateNeedToCompare).format('dddd'),
          fullDate: moment(dateNeedToCompare).format('MM/DD/YY'),
        };
      }
    } else return { onlyDate: '', fullDate: '' };
  };

  const renderAppointmentTable = () => {
    if (isLoading) {
      return [
        <img
          key='loading-spinner'
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />,
      ];
    }
    if (appointmentInfo?.appointments.length === 0) {
      return [
        <div key='appointment-data-loaded-empty'>
          <p className='no-appointments'>No Upcoming Appointments !</p>
        </div>,
      ];
    }
    return [
      appointmentInfo?.appointments.map(
        (appointments: AppointmentSummaryEntry[], index: number) => {
          return (
            <div
              className='appointments-table-element'
              key={'telemed-table' + index}
            >
              <div className='telemed-container-table'>
                <p className='telemed__table-title'>
                  <span className='telemed__table-title-date'>
                    {' '}
                    {showTitleDatetime(appointments).onlyDate}{' '}
                  </span>
                  <span className='telemed__table-full-date'>
                    {' '}
                    {showTitleDatetime(appointments).fullDate}{' '}
                  </span>
                </p>
                {appointments.length > 0 ? (
                  <table className='table daily-appointment__table'>
                    <tbody>
                      <tr className='telemed__table-header-row'>
                        <th className='appointments__table-header telemed'></th>
                        <th className='appointments__table-header time'>
                          Time
                        </th>
                        <th className='appointments__table-header contact'>
                          Contact
                        </th>
                        <th className='appointments__table-header pet'>Pet</th>
                        <th className='appointments__table-header confirmation-status'>
                          Status
                        </th>
                        <th className='appointments__table-header task'>
                          Provider
                        </th>
                        <th className='appointments__table-header actions'>
                          Appointment Type
                        </th>
                        <th className='appointments__table-header tasks'>
                          Tasks
                        </th>
                        <th className='appointments__table-header complete-task'>
                          Complete Task
                        </th>
                      </tr>
                      {renderAppointmentData(appointments)}
                    </tbody>
                  </table>
                ) : (
                  <div key='appointment-data-loaded-empty'>
                    <p className='no-appointments'>
                      No Upcoming Appointments !
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        }
      ),
    ];
  };

  return (
    <>
      <div className={'appointments__current-date-container'}>
        <div className='view-all-dashboard'>
          <Link
            key={'contact-link-view-all-dashboard'}
            to={'/Appointments'}
            className='lnk'
          >
            View all
          </Link>
        </div>
        {renderAppointmentTable()}
      </div>
    </>
  );
};

export default DailyAppointment;
