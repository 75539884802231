import React, { FC, useEffect } from 'react';
import FilterGlobal from 'components/Global/Filter/Filter';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
} from 'global/requests/ResponseTypes';
import Modal from 'components/Global/Modal';

type OptionDropDown = {
  value: string;
  label: string;
};

type OptionDropDownToken = {
  value: string;
  label: string;
  description: string;
};

interface IProps {
  openPopupInsertToken: boolean;
  setOpenPopupInsertToken: (args: boolean) => void;
  handleConfirmInsertToken: (personalizationTokenSelected: string) => void;
  token: PersonalizationTokensResponse;
  screen?: string;
}

const ModalInsertPersonalize: FC<IProps> = ({
  openPopupInsertToken,
  setOpenPopupInsertToken,
  handleConfirmInsertToken,
  token,
  screen = '',
}) => {
  const [personalizationTokenSelected, setPersonalizationTokenSelected] =
    React.useState<OptionDropDownToken>();
  const [categorySelected, setCategorySelected] =
    React.useState<OptionDropDown>();
  const [optionsCategory, setOptionsCategory] = React.useState<
    OptionDropDown[]
  >([{ value: '', label: '' }]);
  const [optionsPersonalizationToken, setOptionsPersonalizationToken] =
    React.useState<OptionDropDownToken[]>([
      { value: '', label: '', description: '' },
    ]);

  useEffect(() => {
    const stubOptionsCategory: OptionDropDown[] = [];
    const allNamespace = token.personalizationTokens;
    const personalizationTokenNamespace = allNamespace.filter(
      (item) => item.namespace === 'personalizationTokens'
    );

    // get list category from token get from api
    if (personalizationTokenNamespace[0]) {
      personalizationTokenNamespace[0].settings.personalizationTokens.forEach(
        (item) => {
          if (
            ['manual', 'contacts postal', 'manual postal', 'booking'].includes(
              screen.toLocaleLowerCase()
            ) &&
            item.categoryName === 'Automation'
          ) {
            return;
          }
          // if (screen === 'booking' && item.categoryName === 'Contact') {
          //   return;
          // }
          stubOptionsCategory.push({
            value: item.categoryId,
            label: item.categoryName,
          });
        }
      );
      if (screen.toLocaleLowerCase() == 'manual') {
        stubOptionsCategory.push({
          value: "Links",
          label: "Links"
        });
      }
      setOptionsCategory(stubOptionsCategory);
      setCategorySelected(stubOptionsCategory?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, screen]);

  useEffect(() => {
    if(categorySelected?.value == "Links") {
      const stubOptionsPersonalizationToken: OptionDropDownToken[] = [];

      stubOptionsPersonalizationToken.push({
        label: "Form Link",
        value: "FormLink",
        description: "This personalization token is used for sending out forms via the Forms tab in a contacts page",
      });

      stubOptionsPersonalizationToken.push({
        label: "Estimate Link",
        value: "EstimateLink",
        description: "This personalization token is used for sending out estimates via the Estimates tab in a contacts page",
      });

      setOptionsPersonalizationToken(stubOptionsPersonalizationToken);
      setPersonalizationTokenSelected(stubOptionsPersonalizationToken?.[0]);
      return;
    }

    const stubOptionsPersonalizationToken: OptionDropDownToken[] = [];
    const currentCategory =
      token.personalizationTokens[0].settings.personalizationTokens.find(
        (item) => {
          return item.categoryId === categorySelected?.value;
        }
      );
    // get list personal tokens follow category selected
    currentCategory?.tokens.forEach((item) => {
      stubOptionsPersonalizationToken.push({
        label: item.name,
        value: item.value,
        description: item.description ?? '',
      });
    });
    setOptionsPersonalizationToken(stubOptionsPersonalizationToken);
    setPersonalizationTokenSelected(stubOptionsPersonalizationToken?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected]);

  useEffect(() => {
    if (openPopupInsertToken) {
      if (categorySelected?.value === optionsCategory[0].value) {
        setPersonalizationTokenSelected(optionsPersonalizationToken?.[0]);
      } else {
        setCategorySelected(optionsCategory[0]);
      }
    }
  }, [openPopupInsertToken]);

  const handleSelectCategory = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    setCategorySelected(newSelectedItem);
  };

  const handleSelectPersonalization = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    // @ts-ignore
    setPersonalizationTokenSelected(newSelectedItem);
  };

  const modalBody = (
    <>
      <p className='modal-body__label'>Category</p>
      <FilterGlobal
        optionsForFilter={optionsCategory}
        handleSetNewFilterValue={handleSelectCategory}
        valueSelected={categorySelected}
      />
      <p className='modal-body__label'>Personalization</p>
      <FilterGlobal
        optionsForFilter={optionsPersonalizationToken}
        handleSetNewFilterValue={handleSelectPersonalization}
        valueSelected={personalizationTokenSelected}
      />
      <p className='modal-body__label'>Description</p>
      <span className='modal-body__text'>
        {personalizationTokenSelected?.description}
      </span>
    </>
  );

  return (
    <>
      <Modal
        isOpenModal={openPopupInsertToken}
        handleCloseModal={() => setOpenPopupInsertToken(false)}
        title={'Insert Personalization Token'}
        handleConfirmModal={() =>
          personalizationTokenSelected?.value
            ? handleConfirmInsertToken(
                personalizationTokenSelected?.value.trim()
              )
            : setOpenPopupInsertToken(false)
        }
        classMore='modal-insert--personalization-token'
        textLeftButton='Cancel'
        textRightButton='Insert'
      >
        {modalBody}
      </Modal>
    </>
  );
};

export default ModalInsertPersonalize;
