import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const MediumEmphasizedDarkText = styled(Typography)`
  font-size: ${sizeConstants.medium.size};
  line-height: ${sizeConstants.medium.height};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: ${getThemeProp('pallette.text.primary', '#12253F')};
  font-weight: 600;
  background-color: unset;
` as typeof Typography;
export default MediumEmphasizedDarkText;
