import * as t from 'io-ts';

const BreedEntry = t.type({
  id: t.string,
  name: t.string,
  externalId: t.string,
});

export const BreedCollectionEntry = t.type({
  count: t.number,
  breeds: t.array(BreedEntry),
});

export type BreedResponse = t.TypeOf<typeof BreedEntry>;
export type BreedCollection = t.TypeOf<typeof BreedCollectionEntry>;
