/* eslint-disable @typescript-eslint/naming-convention */

import ApiService, { PaperlessFormsService } from 'components/FormBuilder/services';
import { get as _get, isEmpty as _isEmtpy } from 'lodash';

export type ISubmission = {
  account_id: string;
  contact_email: string;
  contact_name: string;
  form_id: number;
  form_name: string;
  form_version_id: number;
  form_version_object: { fields: any[] };
  id: number;
  is_approved: boolean;
  is_read: boolean;
  is_synced: boolean;
  secure_account_id: string;
  secure_form_id: string;
  secure_submitted_by: string;
  object: object[];
  submitted_at: string;
  submitted_by: string;
  updated_at: string;
};

const SYNCABILITY = {
  none: 0,
  with_upgrade: 1,
  on_different_pms: 2,
  working: 3,
};

const FILTER_OPTIONS = {
  is_read: 'Read',
  not_read: 'Unread',
  is_approved: 'Approved',
  not_approved: 'Not Approved',
  all: 'All Submissions',
};

const FILTER_ORDER = ['all', 'is_read', 'not_read', 'is_approved', 'not_approved'];

const FILTER_VALUES = {
  read: { is_read: true },
  notRead: { is_read: false },
  approved: { is_approved: true },
  notApproved: { is_approved: false },
  all: undefined,
};

const FORM_RENDER_PATH = 'http://localhost:3001'; // TODO: fix pathing

const SelectMenuItems = [
  {
    value: 'all',
    display:
      'All Submissions       \u00a0 \u00a0 \u00a0 \u00a0 \u00a0\u00a0 \u00a0 \u00a0 \u00a0 \u00a0',
    disabled: false,
  },
  {
    value: 'read',
    display:
      'Read Submissions       \u00a0 \u00a0 \u00a0 \u00a0 \u00a0\u00a0 \u00a0 \u00a0 \u00a0 \u00a0',
    disabled: false,
  },
  {
    value: 'notRead',
    display:
      'Unread Submissions       \u00a0 \u00a0 \u00a0 \u00a0 \u00a0\u00a0 \u00a0 \u00a0 \u00a0 \u00a0',
    disabled: false,
  },
  {
    value: 'approved',
    display:
      'Approved Submissions       \u00a0 \u00a0 \u00a0 \u00a0 \u00a0\u00a0 \u00a0 \u00a0 \u00a0 \u00a0',
    disabled: false,
  },
  {
    value: 'notApproved',
    display:
      'Unapproved Submissions       \u00a0 \u00a0 \u00a0 \u00a0 \u00a0\u00a0 \u00a0 \u00a0 \u00a0 \u00a0',
    disabled: false,
  },
];
const Api = ApiService();

const downloadFile = async (data: any): Promise<void> => {
  const blob = await data.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `patient_form.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const markSubmissionsAsRead = async (
  target: ISubmission | ISubmission[],
  status = true as boolean,
  shouldExclude?: boolean,
): Promise<void> => {
  const id = Array.isArray(target) ? target.map((el: ISubmission): number => el.id) : target.id;

  await PaperlessFormsService.formBuilder.markSubmissionsAsRead([
    'FormSubmissionHistory',
    {
      id,
      is_read: status,
      exclude: shouldExclude,
    },
  ]);
};

const formSubmissionDelete = async (
  target: ISubmission | ISubmission[],
  status = true as boolean,
  shouldExclude?: boolean,
): Promise<void> => {
  const id = Array.isArray(target) ? target.map((el: ISubmission): number => el.id) : target.id;
  const form_id = Array.isArray(target)
    ? target.map((el: ISubmission): number => el.form_id)
    : target.form_id;

  await PaperlessFormsService.formBuilder.formSubmissionDelete([
    'FormSubmissionHistory',
    {
      id,
      is_deleted: status,
      exclude: shouldExclude,
    },
    {
      form_id,
    },
  ]);
};

const mappingSubmissionWithContactsFromFormFields = (submissions: ISubmission[]): ISubmission[] => {
  submissions.forEach((submissionItem, index) => {
    // @ts-ignore
    const { submission_object: formFields } = submissionItem;
    const first_names = [];
    const name_middle = [];
    const last_names = [];
    const emails = [];

    for (let i = 0; i <= formFields.length; i += 1) {
      if (_get(formFields[i], 'legwork_column_name') === 'name_last') {
        last_names.push(_get(formFields[i], 'value'));
      } else if (_get(formFields[i], 'legwork_column_name') === 'name_middle') {
        name_middle.push(_get(formFields[i], 'value'));
      } else if (_get(formFields[i], 'legwork_column_name') === 'name_first') {
        first_names.push(_get(formFields[i], 'value'));
      } else if (_get(formFields[i], 'legwork_column_name') === 'address') {
        emails.push(_get(formFields[i], 'value'));
      }
    }

    const contact_name = !_isEmtpy(name_middle.join('|'))
      ? `${first_names.join('|')} ${name_middle.join('|')} ${last_names.join('|')}`
      : `${first_names.join('|')} ${last_names.join('|')}`;
    const contact_email = emails.join('|');

    Object.assign(submissions[index], { contact_name, contact_email });
  });

  return submissions;
};
// TODO: SyncerStateWarnings

// TODO: All click option for all versus just the page.
// TODO: Internationalization
const ROWS_PER_PAGE = 5;
const sortColumn = 'submitted_at';
const sortOrder = 'DESC';

export {
  SYNCABILITY,
  FORM_RENDER_PATH,
  ROWS_PER_PAGE,
  sortColumn,
  sortOrder,
  SelectMenuItems,
  FILTER_OPTIONS,
  FILTER_VALUES,
  FILTER_ORDER,
  markSubmissionsAsRead,
  downloadFile,
  formSubmissionDelete,
  mappingSubmissionWithContactsFromFormFields,
  Api,
};
