import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const Label = styled.p`
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  font-size: 14px;
  color: ${getThemeProp('palette.text.secondary', 'grey')};
  margin-bottom: 0.2rem;
  margin-top: 0;
`;
const DashedDivider = styled(Divider)`
  height: 0;
  border-top: 1px dashed ${getThemeProp('palette.divider')};
  background-color: initial;
  margin-bottom: 0.5rem;
`;

export { Label, DashedDivider };
