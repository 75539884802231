import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import { getThemeProp } from 'components/Legwork/utils';

const PromoterCell = styled(TableCell)`
  background-color: #4c7cdf;
  border-right: 1px solid white;
  color: white;
`;
const NeutralCell = styled(TableCell)`
  background-color: #12253f;
  border-right: 1px solid white;
  color: white;
`;
const DetractorCell = styled(TableCell)`
  background-color: #ea5e57;
  border-right: 1px solid white;
  color: white;
`;

const TableContainer = styled.div`
  .MuiTableCell-root {
    border-bottom: 1px solid ${getThemeProp('palette.divider', 'lightgray')};
    border-right: 1px solid ${getThemeProp('palette.divider', 'lightgray')};
  }
`;
export { PromoterCell, NeutralCell, DetractorCell, TableContainer };
