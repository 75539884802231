import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import toast from 'components/Global/Toast';

import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import { Medical } from 'global/requests/ResponseTypes/Medical';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import './Portal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingPs from 'components/Global/Loading';

const PortalUI = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<Medical[]>([]);
  const dragItem = useRef<string>();

  useEffect(() => {
    getMedicalServices();
  }, []);

  const getMedicalServices = async () => {
    try {
      const res = await configuredRequests.GET.getMedicalServices({
        isCompliance: true,
      });

      const data = res.medicalServices
        .filter((item) => item.isShowInClientPortal && item.isCompliance)
        .map((item: Medical, index: number) => {
          if (item.order === undefined) {
            item.order = index;
          }
          return item;
        });

      setServices(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleAllowDrop = (e: React.DragEvent<HTMLUListElement>) => {
    e.preventDefault();
  };

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    // @ts-ignore
    const itemId = e.target.id;
    dragItem.current = itemId;

    e.dataTransfer.effectAllowed = 'move';
    // @ts-ignore
    e.target.style.opacity = 0.1;
  };

  const handleDragEnd = (e: React.DragEvent<HTMLLIElement>) => {
    // @ts-ignore
    e.target.style.opacity = 1;

    dragItem.current = undefined;
  };

  const handleDragOver = (e: React.DragEvent<HTMLUListElement>) => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    return false;
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLIElement>) => {
    // @ts-ignore
    const id = e.target.id;
    if (dragItem.current && id !== dragItem.current) {
      const startId = services.findIndex(
        (item) => item.medicalServiceId === dragItem.current
      );
      const toId = services.findIndex((item) => item.medicalServiceId === id);
      const dataAfterOrder = reorder(startId, toId);
      setServices(dataAfterOrder);
    }
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(services);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleSubmitData = async () => {
    try {
      const payload = services.map((item: Medical, index: number) => ({
        medicalServiceId: item.medicalServiceId,
        order: index + 1,
      }));
      //console.log('pay', payload);
      const res = await configuredRequests.PUT.updateOrderMedicalServices(
        payload
      );
      if (res) {
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      console.log('err', err);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  return (
    <>
      {loading ? (
        <LoadingPs />
      ) : (
        <div className='portal-container setting-scroll--custom'>
          {services.length > 0 ? (
            <>
              <h2 className='compliance-title'>
                Compliance Medical Services Order
              </h2>
              <p className='compliance-description'>
                Adjust the order that compliant Medical Services are shown in
                the Client Portal.
              </p>

              <div className='container-no-library'>
                <ul
                  className='box-drop'
                  onDrop={handleDrop}
                  onDragOver={handleAllowDrop}
                >
                  {services.map((item) => (
                    <li
                      key={item.medicalServiceId}
                      id={item.medicalServiceId}
                      className='drag-item'
                      draggable
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      // onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                    >
                      <span>{item.medicalServiceName}</span>
                      <FontAwesomeIcon icon={['fal', 'bars']} />
                    </li>
                  ))}
                </ul>
              </div>

              <Button
                onClick={handleSubmitData}
                type='submit'
                variant='contained'
                color='primary'
              >
                <span>Save</span>
              </Button>
            </>
          ) : (
            <p
              className='no-data-results'
              style={{ fontWeight: 'bold', marginLeft: '15px' }}
            >
              No results found
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default PortalUI;
