/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { isWindows } from 'react-device-detect';
import { LeftMenuSubmissionItemsDataType } from 'global/requests/ResponseTypes/LeftMenuSubmissionDataType';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { get } from 'lodash';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import { default as React, useCallback, useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import './AllSubmissionsContainer.scss';
import AllSubmissionsListTable from './AllSubmissionTable/AllSubmissionListTable';

interface DropdownOptionType {
  label: string;
  value: string;
}

interface Iquery {
  limit: number;
  offset: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
    },
  })
);

const defaultLimitValue = 30;
const defaultOffsetValue = 0;

const AllSubmissionsContainer = (): JSX.Element => {
  const classes = useStyles();
  const fullTenantInfoData = getSessionTenantInfo();
  const [activeItemMenu, setActiveItemMenu] = useState<number>(0);
  const [isLoadingLeftMenu, setIsLoadingLeftMenu] = useState<boolean>(true);
  const [submissionLeftMenuData, setSubmissionLeftMenuData] = useState<
    LeftMenuSubmissionItemsDataType[]
  >([]);
  const [optionDataSubmissionFilter, setOptionDataSubmissionFilter] = useState<
    DropdownOptionType[]
  >([]);
  const [formInfoData, setFormInfoData] =
    useState<LeftMenuSubmissionItemsDataType>();
  const [queryPagination, setQueryPagination] = useState({
    limit: defaultLimitValue,
    offset: defaultOffsetValue,
  });
  const [isMaxLeftMenuItems, setIsMaxLeftMenuItems] = useState<boolean>(false);
  const [submissionInfo, setSubmissionInfo] = useState<string>('');

  useEffect(() => {
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  const getOptionDataSubmissionFilter = async () => {
    try {
      const dropdownData =
        await configuredRequests.GET.getOptionDataSubmissionFilter();
      const optionDataForFilter = dropdownData.map((item) => {
        return {
          label: item.name,
          value: item.value,
        };
      });
      setOptionDataSubmissionFilter(optionDataForFilter);
    } catch (err) {}
  };

  const getLeftMenuData = useCallback(
    async (query: Iquery) => {
      try {
        const res = await configuredRequests.GET.getLeftMenuSubmissionData({
          limit: query?.limit ?? defaultLimitValue,
          offset: query?.offset ?? defaultOffsetValue,
          tenantId: fullTenantInfoData?.tenantId ?? '',
        });
        setIsLoadingLeftMenu(false);
        if (get(res, 'count', 0) === 0) {
          setIsMaxLeftMenuItems(true);
        } else {
          setSubmissionLeftMenuData(
            (preState: LeftMenuSubmissionItemsDataType[]) => [
              ...preState,
              ...get(res, 'items', []),
            ]
          );
        }
      } catch (err) {
        setIsLoadingLeftMenu(false);
      }
    },
    [fullTenantInfoData?.tenantId]
  );

  const getSubmissionData = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getLeftMenuSubmissionData({
        limit: queryPagination.limit * (queryPagination.offset + 1),
        offset: 0,
        tenantId: fullTenantInfoData?.tenantId ?? '',
      });
      setSubmissionLeftMenuData(
        (preState: LeftMenuSubmissionItemsDataType[]) => [
          ...get(res, 'items', []),
        ]
      );
    } catch (err) {
      console.log('err', err);
    }
  }, [fullTenantInfoData?.tenantId]);

  const getAllForm = useCallback(
    async (submissionNoti: string) => {
      try {
        const res = await configuredRequests.GET.getAllForm(
          fullTenantInfoData?.tenantId ?? ''
        );
        setIsLoadingLeftMenu(false);
        setSubmissionLeftMenuData(res?.items ?? []);
        setIsMaxLeftMenuItems(true);
        const submission = JSON.parse(submissionNoti);
        res.items?.forEach(
          (item: LeftMenuSubmissionItemsDataType, index: number) => {
            if (item.id === submission.formId) {
              setFormInfoData(item);
              setActiveItemMenu(index);
              setSubmissionInfo(submissionNoti);
            }
          }
        );
        window.sessionStorage.removeItem('noti-form');
      } catch (err) {
        setIsLoadingLeftMenu(false);
      }
    },
    [fullTenantInfoData?.tenantId]
  );

  useEffect(() => {
    setActiveItemMenu(0);
    getOptionDataSubmissionFilter();

    const submissionNoti = window.sessionStorage.getItem('noti-form');

    if (!fullTenantInfoData?.tenantId) return;

    if (submissionNoti) {
      getAllForm(submissionNoti);
    } else {
      getLeftMenuData({ limit: defaultLimitValue, offset: defaultOffsetValue });
    }
  }, [fullTenantInfoData?.tenantId]);

  useEffect(() => {
    if (submissionLeftMenuData.length <= defaultLimitValue) {
      setFormInfoData(submissionLeftMenuData[0]);
    }
  }, [submissionLeftMenuData.length]);

  const handleSelectFormTab = (
    item: LeftMenuSubmissionItemsDataType,
    index: number
  ) => {
    setFormInfoData(item);
    setActiveItemMenu(index);
  };

  const scrollToBottom = useCallback(() => {
    if (!isMaxLeftMenuItems && !isLoadingLeftMenu) {
      setQueryPagination((preState: any) => ({
        ...preState,
        offset: preState.offset + 1,
      }));
      getLeftMenuData({
        limit: queryPagination.limit,
        offset: queryPagination.offset + 1,
      });
    }
  }, [isMaxLeftMenuItems, queryPagination, isLoadingLeftMenu, getLeftMenuData]);

  const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);

  return (
    <div className='all-submission-container'>
      <div className='all-submission-left-menu-container'>
        {isLoadingLeftMenu ? (
          <Loading className='loading-appointment-info' />
        ) : (
          <ul
            className='all-submission-left-menu-lists'
            //@ts-ignore
            ref={scrollRef}
          >
            {submissionLeftMenuData.length ? (
              submissionLeftMenuData.map((item, index) => {
                return (
                  <li
                    key={index}
                    tabIndex={0}
                    className={
                      'all-submission-left-menu-item-list ' +
                      (index === activeItemMenu ? 'active' : '')
                    }
                    onClick={() => handleSelectFormTab(item, index)}
                  >
                    <div className='all-submission-sync-info'>
                      {item.allowSync && (
                        <SyncAltIcon className='all-submission-sync-icon' />
                      )}
                      <Tooltip
                        title={item.name}
                        placement='bottom-end'
                        classes={{ tooltip: classes.tooltip }}
                        disableHoverListener={item.name.length < 18}
                      >
                        <div className='all-submission-form-name'>
                          {item.name}
                        </div>
                      </Tooltip>
                    </div>
                    {!!item.totalUnreads && (
                      <div className='all-submission-count'>
                        {item.totalUnreads}
                      </div>
                    )}
                  </li>
                );
              })
            ) : (
              <div className='no-submission-available'>
                No Submission Available
              </div>
            )}
          </ul>
        )}
      </div>

      {optionDataSubmissionFilter.length > 0 && formInfoData && (
        <AllSubmissionsListTable
          formInfoData={formInfoData}
          optionDataSubmissionFilter={optionDataSubmissionFilter}
          submissionNoti={submissionInfo}
          setSubmissionNoti={setSubmissionInfo}
          reloadData={getSubmissionData}
        />
      )}
    </div>
  );
};

export default AllSubmissionsContainer;
