import React from 'react';
import PropTypes from 'prop-types';
import { BorderlessButton } from 'components/Legwork';
import LockIcon from '@material-ui/icons/Lock';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import EditIcon from '@material-ui/icons/Edit';

import FormItemStatus from './form-item-status';
import styles from './styles';

const FormItemHeader = ({
  formVersion,
  isRestrictedForm,
  isDirty,
  urlParams,
  onNameClick,
  onPublishClick,
  onSaveClick,
  hideChrome,
  goToOverview,
}) => {
  const noChromeStyles = hideChrome ? styles.noChrome : {};

  return (
    <div
      className='column-header form-item-header app-nav nav-bar'
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '20px',
        ...noChromeStyles.container,
      }}
    >
      {!hideChrome && (
        <BorderlessButton
          style={{ color: 'white', flexShrink: '0' }}
          title={
            <>
              <ArrowBackIcon style={{ marginBottom: '-6px' }} /> Back to All Forms{' '}
            </>
          }
          onClick={goToOverview}
        />
      )}
      <div className='center' style={{ ...styles.centerContainer, ...noChromeStyles.title }}>
        <div
          className={!hideChrome ? 'form-item-title' : ''}
          title='Edit Form Title'
          onClick={onNameClick}
        >
          <h5>
            {formVersion.form_name}&nbsp;&nbsp;
            {isRestrictedForm || hideChrome ? (
              <LockIcon style={{ fontSize: '19px', marginBottom: '-3px' }} />
            ) : (
              <EditIcon style={{ fontSize: '19px', marginBottom: '-3px' }} />
            )}
          </h5>
        </div>
      </div>
      <div style={{ display: 'flex', flexShrink: '0' , ...noChromeStyles.rightContainer }}>
        <FormItemStatus formVersion={formVersion} isDirty={isDirty} />
        <button className='btn btn-secondary pull-right' onClick={onSaveClick}>
          Save
        </button>
        <button className='btn btn-primary pull-right' onClick={onPublishClick}>
          {/* {formVersion.never_published ? 'Publish' : 'Update'} */}
          Publish
        </button>
      </div>
    </div>
  );
};

FormItemHeader.propTypes = {
  formVersion: PropTypes.object.isRequired,
  isRestrictedForm: PropTypes.bool,
  isDirty: PropTypes.bool,
  urlParams: PropTypes.object,
  onNameClick: PropTypes.func,
  onPublishClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  hideChrome: PropTypes.bool,
};

FormItemHeader.defaultProps = {
  isRestrictedForm: false,
  isDirty: false,
  urlParams: {},
  onNameClick: () => {},
  onPublishClick: () => {},
  onSaveClick: () => {},
  hideChrome: false,
};

export default FormItemHeader;
