import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

const formatPhoneNumber = (
  number: string,
  countryCode: CountryCode,
  extension?: string,
): string => {
  const formattedNumber = parsePhoneNumberFromString(number, countryCode);
  let phoneNumber = formattedNumber ? formattedNumber.formatNational() : number;
  phoneNumber = `${phoneNumber}${extension ? ` x${extension}` : ''}`;
  return phoneNumber;
};

export default formatPhoneNumber;
