import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledGridContainer = styled(Grid)`
  height: 100%;
`;
const GridRightBorder = styled(Grid)`
  border-right: solid 1px ${getThemeProp('palette.divider', '#E3EBF6')};
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledGridContainer, GridRightBorder };
