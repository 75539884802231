import * as t from 'io-ts';

const LeftMenuSubmissionItemsDataType = t.type({
  id: t.string,
  allowSync: t.boolean,
  name: t.string,
  totalSubmission: t.number,
  totalUnreads: t.number
});

export const LeftMenuSubmissionListResponseType = t.partial({
  count: t.number,
  offset: t.number,
  totalAvailable: t.number,
  items: t.array(LeftMenuSubmissionItemsDataType),
});

const PayloadToDeleteSubmissionDataType = t.type({
  selectedIdToDelete: t.array(t.string),
});

const PayloadToDeleteSubmissionDocument = t.type({
  documentId: t.string,
});

export type PayloadToDeleteSubmissionDataType = t.TypeOf<typeof PayloadToDeleteSubmissionDataType>;
export type PayloadToDeleteSubmissionDocument = t.TypeOf<typeof PayloadToDeleteSubmissionDocument>;
export type LeftMenuSubmissionItemsDataType = t.TypeOf<typeof LeftMenuSubmissionItemsDataType>;
export type LeftMenuSubmissionListResponseType = t.TypeOf<typeof LeftMenuSubmissionListResponseType>;