import { BreedCollection, BreedCollectionEntry } from './ResponseTypes/Breed';
import { AxiosResponse } from 'axios';
// left indicates an error in this library, right is success
import { isLeft } from 'fp-ts/lib/Either';
// validate api responses
import { Decoder } from 'io-ts';
import { convertDataToMultipleArray } from '../../utils/convertData';
import * as ResponseTypes from './ResponseTypes';
import {
  AppointmentInfoResponse,
  AppointmentMultipleInfoResponse,
  AppointmentSummaryCollection,
  AppointmentSummaryEntry,
  FullAppointmentSummary,
  MyAppointmentSummaryCollection,
} from './ResponseTypes/Appointments';
import {
  LoginResponse,
  LoginResponseData,
} from './ResponseTypes/Authentication';
import { DropdownDataGeneralResponseType } from './ResponseTypes/DropdownDataGeneral';
import {
  EmitterCreateResponseType,
  EmitterResponseType,
} from './ResponseTypes/Emitter';
import {
  EstimatesResponse
} from './ResponseTypes/Estimates';
import { EmitterMethodResponseType } from './ResponseTypes/EmitterMethod';
import {
  ProductCollection,
  ProductCollectionType,
} from './ResponseTypes/Product';
import {
  FullGroupData,
  GroupDataResponse,
  GroupInfoResponse,
  GroupInfoResponseData,
} from './ResponseTypes/Groups';
import {
  FullPetInfoResponse,
  PetInfoResponse,
  PetSummaryCollection,
  PetSummaryCollectionResponse,
} from './ResponseTypes/Pets';
import {
  ServiceLogsResponseType,
  SyncerLogsCollection,
  SyncerLogsCollectionType,
} from './ResponseTypes/ServiceLogs';
import { SyncerGeneralResponseType } from './ResponseTypes/SyncerGeneral';
import { SyncerSettingGeneralResponseType } from './ResponseTypes/SyncerSettingGeneral';
import {
  FullTenantInfoResponse,
  TenantAllUserData,
  TenantInfoResponse,
  TenantUserDataResponse,
  TenantFilteredDataResponse,
  TenantFilteredContactData,
  TenantSyncerErrorCollectionType,
  TenantSyncerErrorCollection,
} from './ResponseTypes/Tenants';
import {
  FullUserData,
  UserDataResponse,
} from './ResponseTypes/UserAdministration';
import { ResetPasswordResponseType } from './ResponseTypes/ResetPassword';
import { PaySettingResponseType } from './ResponseTypes/PaySetting';
import { AppointmentTypeSettingResponseType } from './ResponseTypes/AppointmentTypeSetting';
import { OptionDropdownDataResponseType } from './ResponseTypes/DropdownDataType';
import { LeftMenuSubmissionListResponseType } from './ResponseTypes/LeftMenuSubmissionDataType';
import {
  ReminderInfoResponse,
  ReminderSummaryCollection,
} from './ResponseTypes/Reminders';
import { TwilioSettingResponseType } from './ResponseTypes/TwilioSetting';
import {
  CollectionMangoPhone,
  CollectionMangoPhoneType,
} from './ResponseTypes/MangoSetting';
import {
  MedicalCollection,
  MedicalCollectionEntry,
} from './ResponseTypes/Medical';
import { SexCollection, SexCollectionEntry } from './ResponseTypes/Sex';
import { DriverConfigurationResponseType } from './ResponseTypes/Settings';

// generic helper function that verifies that our data matches any valid response format
// if this check passes then we can run the decoder and verify it matches the specific type
// not strictly a necessary middle step, but it will help with debugging by logging the failure point more specifically
const validateGenericApiResponseData = function (
  responseData: unknown
): responseData is ResponseTypes.ValidAxiosResponses {
  const isFormatValid = responseData as ResponseTypes.ValidAxiosResponses;
  // console.log('generic response is valid: ', !!isFormatValid);
  if (isFormatValid) {
    return true;
  }
  consoleLogFailedRes(true, responseData);
  return false;
};

export const validateSpecificApiResponseType = function <
  T extends ResponseTypes.ValidAxiosResponses
>(
  responseData: ResponseTypes.ValidAxiosResponses,
  decoder: Decoder<ResponseTypes.ValidAxiosResponses, T>
): responseData is T {
  const decodedOutput = decoder.decode(responseData);

  const decodeFailed = isLeft(decodedOutput); // true means failure
  // console.log('response matches expected format', !decodeFailed);
  if (decodeFailed) {
    if (process.env.NODE_ENV !== 'development') {
      return false;
    }
    // This should be logging in production TODO
    // console.log(JSON.stringify(PathReporter.report(decodedOutput), null, 2));
    consoleLogFailedRes(false, responseData);
    return false;
  } else {
    return true;
  }
};

// generic logging function that logs in the console any response that didnt match
const consoleLogFailedRes = (
  failedOnGeneric: boolean,
  data: ResponseTypes.ValidAxiosResponses | unknown
) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.log(
    failedOnGeneric
      ? 'invalid response in generic typeguard.  This means that the format of the response data did not match any known valid response format. More output immediately below'
      : 'invalid response in specific typeguard. This means that the format of the response data matched a known type, but did not match the type that we were expecting. More output immediately below'
  );
  // don't disable this if the console is full of log statements, the log is informing you
  // that the response doesn't match and you probably need to check your type declaration
  // console.log('This is the full response:', JSON.stringify(data, null, 2));
};

//configured typeguards

export const validateSingleUserData = function validateSingleUserData(
  res: AxiosResponse
): UserDataResponse | undefined {
  //this is temporary because of API responses wrong format
  //thank you for the comment! Don't forget to remove the "any" type below when it's fixed :)
  const responseData = {
    ...res.data,
    birthDate: '',
    additionalDetails: {
      likes: '',
      'random-detail': '',
      'random-object': {
        lat: 0,
        lon: 0,
        tag: '',
      },
    },
  };
  const isResValid = validateGenericApiResponseData(responseData);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse =
    responseData as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<UserDataResponse | any>(
      validGenericResponse,
      FullUserData
    )
  ) {
    return validGenericResponse as UserDataResponse;
  }
};

export const validateGroupInfo = function validateGroupInfo(
  res: AxiosResponse
): GroupInfoResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<GroupInfoResponse>(
      validGenericResponse,
      GroupInfoResponseData
    )
  ) {
    return validGenericResponse as GroupInfoResponse;
  }
};

export const validateMultiplePetInfo = function validateSinglePetInfo(
  res: AxiosResponse
): PetSummaryCollectionResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<PetSummaryCollectionResponse>(
      validGenericResponse,
      PetSummaryCollection
    )
  ) {
    return validGenericResponse as PetSummaryCollectionResponse;
  }
};

export const validateProducts = (
  res: AxiosResponse
): ProductCollectionType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validProduct = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ProductCollectionType>(
      validProduct,
      ProductCollection
    )
  ) {
    return validProduct as ProductCollectionType;
  }
};

export const validatePetSex = (
  res: AxiosResponse
): SexCollection | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validPetSex = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<SexCollection>(
      validPetSex,
      SexCollectionEntry
    )
  ) {
    return validPetSex as SexCollection;
  }
};

export const validatePetBreed = (
  res: AxiosResponse
): BreedCollection | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validPetBreed = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<BreedCollection>(
      validPetBreed,
      BreedCollectionEntry
    )
  ) {
    return validPetBreed as BreedCollection;
  }
};

export const validateSinglePetInfo = function validateSinglePetInfo(
  res: AxiosResponse
): PetInfoResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<PetInfoResponse>(
      validGenericResponse,
      FullPetInfoResponse
    )
  ) {
    return validGenericResponse as PetInfoResponse;
  }
};

export const validateAppointmentInfo = function validateAppointmentInfo(
  res: AxiosResponse
): AppointmentInfoResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<AppointmentInfoResponse>(
      validGenericResponse,
      AppointmentSummaryCollection
    )
  ) {
    return validGenericResponse as AppointmentInfoResponse;
  }
};

export const validateReminderInfo = function validateReminderInfo(
  res: AxiosResponse
): ReminderInfoResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ReminderInfoResponse>(
      validGenericResponse,
      ReminderSummaryCollection
    )
  ) {
    return validGenericResponse as ReminderInfoResponse;
  }
};

export const validateMultipleAppointmentInfo =
  function validateMultipleAppointmentInfo(
    res: AxiosResponse
  ): AppointmentMultipleInfoResponse | undefined {
    const isResValid = validateGenericApiResponseData(res.data);
    if (!isResValid) {
      //TODO handle bad response, query again, log malformed data, etc.
      return;
    }
    const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
    // convert to multiple arrr
    // console.log("res.data.appointments before", res.data.appointments)
    res.data.appointments = convertDataToMultipleArray(res.data.appointments);
    // console.log("res.data.appointments after", res.data.appointments)
    if (
      validateSpecificApiResponseType<AppointmentMultipleInfoResponse>(
        validGenericResponse,
        MyAppointmentSummaryCollection
      )
    ) {
      return validGenericResponse as AppointmentMultipleInfoResponse;
    }
  };

export const validateAppointmentNotes = function validateAppointmentInfo(
  res: AxiosResponse
): ResponseTypes.AppointmentNotesResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.AppointmentNotesResponse>(
      validGenericResponse,
      ResponseTypes.AppointmentNotesResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.AppointmentNotesResponse;
  }
};

export const validateAppointmentAttachments = function validateAppointmentInfo(
  res: AxiosResponse
): ResponseTypes.AppointmentAttachmentsResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.AppointmentAttachmentsResponse>(
      validGenericResponse,
      ResponseTypes.AppointmentAttachmentsResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.AppointmentAttachmentsResponse;
  }
};

export const validateSingleGroupData = function validateSingleGroupData(
  res: AxiosResponse
): GroupDataResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<GroupDataResponse>(
      validGenericResponse,
      FullGroupData
    )
  ) {
    return validGenericResponse as GroupDataResponse;
  }
};

export const validateLoginResponse = function validateLoginResponse(
  res: AxiosResponse
): LoginResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<LoginResponse>(
      validGenericResponse,
      LoginResponseData
    )
  ) {
    return validGenericResponse as LoginResponse;
  }
};

export const validateAppointmentEntryResponse =
  function validateAppointmentEntryResponse(
    res: AxiosResponse
  ): AppointmentSummaryEntry | undefined {
    const isResValid = validateGenericApiResponseData(res.data);
    if (!isResValid) {
      //TODO handle bad response, query again, log malformed data, etc.
      return;
    }
    const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
    if (
      validateSpecificApiResponseType<AppointmentSummaryEntry>(
        validGenericResponse,
        FullAppointmentSummary
      )
    ) {
      return validGenericResponse as AppointmentSummaryEntry;
    }
  };

export const validateAllUserInfo = function validateAllUserInfo(
  res: AxiosResponse
): TenantUserDataResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<TenantUserDataResponse>(
      validGenericResponse,
      TenantAllUserData
    )
  ) {
    return validGenericResponse as TenantUserDataResponse;
  }
};

export const validateFilterContactInfo = function validateFilterContactInfo(
  res: AxiosResponse
): TenantFilteredDataResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<TenantFilteredDataResponse>(
      validGenericResponse,
      TenantFilteredContactData
    )
  ) {
    return validGenericResponse as TenantFilteredDataResponse;
  }
};

export const validateSingleTenantInfo = function validateSingleTenantInfo(
  res: AxiosResponse
): TenantInfoResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<TenantInfoResponse>(
      validGenericResponse,
      FullTenantInfoResponse
    )
  ) {
    return validGenericResponse as TenantInfoResponse;
  }
};


export const validateSettingGeneral = function validateSettingGeneral(
  res: AxiosResponse
): ResponseTypes.GeneralSettingResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.GeneralSettingResponse>(
      validGenericResponse,
      ResponseTypes.GeneralSettings
    )
  ) {
    return validGenericResponse as ResponseTypes.GeneralSettingResponse;
  }
};

export const validateExternalLink = function validateExternalLink(
  res: AxiosResponse
): ResponseTypes.ExternalLinkResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ExternalLinkResponse>(
      validGenericResponse,
      ResponseTypes.ExternalLink
    )
  ) {
    return validGenericResponse as ResponseTypes.ExternalLinkResponse;
  }
};

export const validateSystemSettingData = (
  res: AxiosResponse
): ResponseTypes.SystemSettingResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.SystemSettingResponse>(
      validGenericResponse,
      ResponseTypes.SystemSettingResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.SystemSettingResponse;
  }
};

export const validateTimeZoneData = (
  res: AxiosResponse
): ResponseTypes.TimezoneResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TimezoneResponse>(
      validGenericResponse,
      ResponseTypes.TimezoneResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.TimezoneResponse;
  }
};

export const validateBrandColorSetting = (
  res: AxiosResponse
): ResponseTypes.BrandColorsResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.BrandColorsResponse>(
      validGenericResponse,
      ResponseTypes.BrandColorsResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.BrandColorsResponse;
  }
};

export const validateNotificationSetting = (
  res: AxiosResponse
): ResponseTypes.NotificationsResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.NotificationsResponse>(
      validGenericResponse,
      ResponseTypes.NotificationsResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.NotificationsResponse;
  }
};

export const validateTemplate = (
  res: AxiosResponse
): ResponseTypes.TemplateResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplateResponse>(
      validGenericResponse,
      ResponseTypes.TemplateResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplateResponse;
  }
};

export const validateTemplateByID = (
  res: AxiosResponse
): ResponseTypes.TemplateData | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplateData>(
      validGenericResponse,
      ResponseTypes.TemplateData
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplateData;
  }
};

export const validateListCategory = (
  res: AxiosResponse
): ResponseTypes.ListCategoryResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ListCategoryResponse>(
      validGenericResponse,
      ResponseTypes.ListCategoryResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ListCategoryResponse;
  }
};

export const validateTemplateAnalyze = (
  res: AxiosResponse
): ResponseTypes.TemplateAnalyzeResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplateAnalyzeResponse>(
      validGenericResponse,
      ResponseTypes.TemplateAnalyzeResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplateAnalyzeResponse;
  }
};

export const validateTemplatePerformance = (
  res: AxiosResponse
): ResponseTypes.PerformanceAnalyzeResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.PerformanceAnalyzeResponse>(
      validGenericResponse,
      ResponseTypes.PerformanceAnalyzeResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.PerformanceAnalyzeResponse;
  }
};

export const validateTemplateUnsuccessfulDeliver = (
  res: AxiosResponse
): ResponseTypes.UnsuccessfulDeliverAnalyzeResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.UnsuccessfulDeliverAnalyzeResponse>(
      validGenericResponse,
      ResponseTypes.UnsuccessfulDeliverAnalyzeResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.UnsuccessfulDeliverAnalyzeResponse;
  }
};

export const validateEmailAnalytic = (
  res: AxiosResponse
): ResponseTypes.EmailAnalyticResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.EmailAnalyticResponse>(
      validGenericResponse,
      ResponseTypes.EmailAnalyticResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.EmailAnalyticResponse;
  }
};

export const validatePerformingTemplate = (
  res: AxiosResponse
): ResponseTypes.PerformingTemplateResponseData | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.PerformingTemplateResponseData>(
      validGenericResponse,
      ResponseTypes.PerformingTemplateResponseData
    )
  ) {
    return validGenericResponse as ResponseTypes.PerformingTemplateResponseData;
  }
};

export const validateUserDetailEmailResponse = (
  res: AxiosResponse
): ResponseTypes.UserDetailEmailResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.UserDetailEmailResponse>(
      validGenericResponse,
      ResponseTypes.UserDetailEmailResponseData
    )
  ) {
    return validGenericResponse as ResponseTypes.UserDetailEmailResponse;
  }
};

export const validatePersonalizationTokens = (
  res: AxiosResponse
): ResponseTypes.PersonalizationTokensResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.PersonalizationTokensResponse>(
      validGenericResponse,
      ResponseTypes.PersonalizationTokensResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.PersonalizationTokensResponse;
  }
};

export const validateUpdateTemplate = (
  res: AxiosResponse
): ResponseTypes.TemplateData | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplateData>(
      validGenericResponse,
      ResponseTypes.TemplateData
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplateData;
  }
};

export const validateUserDetailSMSResponse = (
  res: AxiosResponse
): ResponseTypes.UserDetailSMSResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.UserDetailSMSResponse>(
      validGenericResponse,
      ResponseTypes.UserDetailSMSResponseData
    )
  ) {
    return validGenericResponse as ResponseTypes.UserDetailSMSResponse;
  }
};

export const validateTemplateRecipients = (
  res: AxiosResponse
): ResponseTypes.TemplateRecipient | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplateRecipient>(
      validGenericResponse,
      ResponseTypes.TemplateRecipient
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplateRecipient;
  }
};

export const validateTemplatePerformanceDetail = (
  res: AxiosResponse
): ResponseTypes.TemplatePerformanceDetailResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TemplatePerformanceDetailResponse>(
      validGenericResponse,
      ResponseTypes.TemplatePerformanceDetailResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.TemplatePerformanceDetailResponse;
  }
};

export const validateAutomationTrigger = (
  res: AxiosResponse
): ResponseTypes.AutomationTriggerResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.AutomationTriggerResponse>(
      validGenericResponse,
      ResponseTypes.AutomationTriggerResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.AutomationTriggerResponse;
  }
};

export const validateAutomationSettingResponse = (
  res: AxiosResponse
): ResponseTypes.AutomationSettingResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.AutomationSettingResponse>(
      validGenericResponse,
      ResponseTypes.AutomationSettingResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.AutomationSettingResponse;
  }
};

export const validateAutomationTriggerList = (
  res: AxiosResponse
): ResponseTypes.AutomationTriggerList | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.AutomationTriggerList>(
      validGenericResponse,
      ResponseTypes.AutomationTriggerList
    )
  ) {
    return validGenericResponse as ResponseTypes.AutomationTriggerList;
  }
};

export const validateDeliverApproximately = (
  res: AxiosResponse
): ResponseTypes.DeliverApproximatelyResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.DeliverApproximatelyResponse>(
      validGenericResponse,
      ResponseTypes.DeliverApproximatelyResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.DeliverApproximatelyResponse;
  }
};

export const validateCreateAutomationTriggerEvent = (
  res: AxiosResponse
): ResponseTypes.CreateAutomationTriggerEventResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.CreateAutomationTriggerEventResponse>(
      validGenericResponse,
      ResponseTypes.CreateAutomationTriggerEventResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.CreateAutomationTriggerEventResponse;
  }
};

export const validateUpdateAutomationTriggerEvent = (
  res: AxiosResponse
): ResponseTypes.CreateAutomationTriggerEventResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.CreateAutomationTriggerEventResponse>(
      validGenericResponse,
      ResponseTypes.CreateAutomationTriggerEventResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.CreateAutomationTriggerEventResponse;
  }
};

export const validateCheckIncall = (
  res: AxiosResponse
): ResponseTypes.CheckIncallResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.CheckIncallResponse>(
      validGenericResponse,
      ResponseTypes.CheckIncallResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.CheckIncallResponse;
  }
};

export const validateGetRole = (
  res: AxiosResponse
): ResponseTypes.RoleResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.RoleResponse>(
      validGenericResponse,
      ResponseTypes.RoleResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.RoleResponse;
  }
};

export const validateGetContactListData = (
  res: AxiosResponse
): ResponseTypes.ContactListResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ContactListResponse>(
      validGenericResponse,
      ResponseTypes.ContactListResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ContactListResponse;
  }
};

export const validateGetDrivers = (
  res: AxiosResponse
): ResponseTypes.Drivers | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.Drivers>(
      validGenericResponse,
      ResponseTypes.Drivers
    )
  ) {
    return validGenericResponse as ResponseTypes.Drivers;
  }
};

export const validateGetServices = (
  res: AxiosResponse
): ResponseTypes.Services | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.Services>(
      validGenericResponse,
      ResponseTypes.Services
    )
  ) {
    return validGenericResponse as ResponseTypes.Services;
  }
};

export const validateGetConfigurators = (
  res: AxiosResponse
): ResponseTypes.Configurators | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.Configurators>(
      validGenericResponse,
      ResponseTypes.Configurators
    )
  ) {
    return validGenericResponse as ResponseTypes.Configurators;
  }
};

export const validateGetDriverInfo = (
  res: AxiosResponse
): ResponseTypes.DriversResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.DriversResponse>(
      validGenericResponse,
      ResponseTypes.DriversResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.DriversResponse;
  }
};

export const validateGetServiceInfo = (
  res: AxiosResponse
): ResponseTypes.ServiceResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ServiceResponse>(
      validGenericResponse,
      ResponseTypes.ServiceResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ServiceResponse;
  }
};

export const validateGetConfiguratorInfo = (
  res: AxiosResponse
): ResponseTypes.ConfiguratorResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ConfiguratorResponse>(
      validGenericResponse,
      ResponseTypes.ConfiguratorResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ConfiguratorResponse;
  }
};

export const validateApiCalls = (
  res: AxiosResponse
): ResponseTypes.apiCallsResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.apiCallsResponse>(
      validGenericResponse,
      ResponseTypes.apiCallsResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.apiCallsResponse;
  }
};

export const validateCreateDriverRequest = (
  res: AxiosResponse
): ResponseTypes.DriversResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.DriversResponse>(
      validGenericResponse,
      ResponseTypes.DriversResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.DriversResponse;
  }
};

export const validateCreateServiceRequest = (
  res: AxiosResponse
): ResponseTypes.ServiceResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ServiceResponse>(
      validGenericResponse,
      ResponseTypes.ServiceResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ServiceResponse;
  }
};

export const validateCreateConfiguratorRequest = (
  res: AxiosResponse
): ResponseTypes.ConfiguratorResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ConfiguratorResponse>(
      validGenericResponse,
      ResponseTypes.ConfiguratorResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ConfiguratorResponse;
  }
};

export const validateUpdateRoleResponse = (
  res: AxiosResponse
): ResponseTypes.Role | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.Role>(
      validGenericResponse,
      ResponseTypes.Role
    )
  ) {
    return validGenericResponse as ResponseTypes.Role;
  }
};

export const validateUpdateDriverResponse = (
  res: AxiosResponse
): ResponseTypes.DriversResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.DriversResponse>(
      validGenericResponse,
      ResponseTypes.DriversResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.DriversResponse;
  }
};

export const validateUpdateServiceResponse = (
  res: AxiosResponse
): ResponseTypes.ServiceResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ServiceResponse>(
      validGenericResponse,
      ResponseTypes.ServiceResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ServiceResponse;
  }
};

export const validateUpdateConfiguratorResponse = (
  res: AxiosResponse
): ResponseTypes.ConfiguratorResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ConfiguratorResponse>(
      validGenericResponse,
      ResponseTypes.ConfiguratorResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ConfiguratorResponse;
  }
};

export const validateGetScreens = (
  res: AxiosResponse
): ResponseTypes.ScreenResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ScreenResponse>(
      validGenericResponse,
      ResponseTypes.ScreenResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ScreenResponse;
  }
};

export const validateTenantResponse = (
  res: AxiosResponse
): ResponseTypes.TenantResposne | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.TenantResposne>(
      validGenericResponse,
      ResponseTypes.TenantResposne
    )
  ) {
    return validGenericResponse as ResponseTypes.TenantResposne;
  }
};

export const validateUploadDriver = (
  res: any
): ResponseTypes.uploadDriverResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.uploadDriverResponse>(
      validGenericResponse,
      ResponseTypes.uploadDriverResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.uploadDriverResponse;
  }
};

export const validateUploadService = (
  res: any
): ResponseTypes.uploadServiceResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.uploadServiceResponse>(
      validGenericResponse,
      ResponseTypes.uploadServiceResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.uploadServiceResponse;
  }
};

export const validateUploadConfigurator = (
  res: any
): ResponseTypes.uploadConfiguratorResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.uploadConfiguratorResponse>(
      validGenericResponse,
      ResponseTypes.uploadConfiguratorResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.uploadConfiguratorResponse;
  }
};

export const validateUploadImage = (
  res: any
): ResponseTypes.uploadImageResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.uploadImageResponse>(
      validGenericResponse,
      ResponseTypes.uploadImageResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.uploadImageResponse;
  }
};

export const validateSyncerStopPages = (
  res: AxiosResponse
): ResponseTypes.CollectionSyncerStopPageType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.CollectionSyncerStopPageType>(
      validGenericResponse,
      ResponseTypes.CollectionSyncerStopPage
    )
  ) {
    return validGenericResponse as ResponseTypes.CollectionSyncerStopPageType;
  }
};

export const validateSyncerFilterOption = (
  res: AxiosResponse
): ResponseTypes.SyncerFilterOptionType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.SyncerFilterOptionType>(
      validGenericResponse,
      ResponseTypes.SyncerFilterOption
    )
  ) {
    return validGenericResponse as ResponseTypes.SyncerFilterOptionType;
  }
};

export const validateSyncerPractice = (
  res: AxiosResponse
): ResponseTypes.SyncerPracticesType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.SyncerPracticesType>(
      validGenericResponse,
      ResponseTypes.SyncerPractices
    )
  ) {
    return validGenericResponse as ResponseTypes.SyncerPracticesType;
  }
};

export const validateListTenantResponse = (
  res: AxiosResponse
): ResponseTypes.ListTenantResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.ListTenantResponse>(
      validGenericResponse,
      ResponseTypes.ListTenantResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.ListTenantResponse;
  }
};

export const validateCreateGroupResponse = (
  res: AxiosResponse
): ResponseTypes.Group | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.Group>(
      validGenericResponse,
      ResponseTypes.Group
    )
  ) {
    return validGenericResponse as ResponseTypes.Group;
  }
};

export const validateGroupResponse = (
  res: AxiosResponse
): ResponseTypes.GroupResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.GroupResponse>(
      validGenericResponse,
      ResponseTypes.GroupResponse
    )
  ) {
    return validGenericResponse as ResponseTypes.GroupResponse;
  }
};

export const validateGroupInfoResponse = (
  res: AxiosResponse
): ResponseTypes.GroupInfoType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.GroupInfoType>(
      validGenericResponse,
      ResponseTypes.GroupInfo
    )
  ) {
    return validGenericResponse as ResponseTypes.GroupInfoType;
  }
};

export const validateGetAdminSyncerErrors = (
  res: AxiosResponse
): TenantSyncerErrorCollectionType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<TenantSyncerErrorCollectionType>(
      validGenericResponse,
      TenantSyncerErrorCollection
    )
  ) {
    return validGenericResponse as TenantSyncerErrorCollectionType;
  }
};

export const validateGetPracticeSyncerErrors = (
  res: AxiosResponse
): SyncerLogsCollectionType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<SyncerLogsCollectionType>(
      validGenericResponse,
      SyncerLogsCollection
    )
  ) {
    return validGenericResponse as SyncerLogsCollectionType;
  }
};

export const validateGetServiceLogs = (
  res: AxiosResponse
): ServiceLogsResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ServiceLogsResponseType>(
      validGenericResponse,
      ServiceLogsResponseType
    )
  ) {
    return validGenericResponse as ServiceLogsResponseType;
  }
};

export const validateGetEmitter = (
  res: AxiosResponse
): EmitterResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<EmitterResponseType>(
      validGenericResponse,
      EmitterResponseType
    )
  ) {
    return validGenericResponse as EmitterResponseType;
  }
};

export const validateGetEmitterMethod = (
  res: AxiosResponse
): EmitterMethodResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<EmitterMethodResponseType>(
      validGenericResponse,
      EmitterMethodResponseType
    )
  ) {
    return validGenericResponse as EmitterMethodResponseType;
  }
};

export const validateGetDriverConfiguration = (
  res: AxiosResponse
): DriverConfigurationResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<DriverConfigurationResponseType>(
      validGenericResponse,
      DriverConfigurationResponseType
    )
  ) {
    return validGenericResponse as DriverConfigurationResponseType;
  }
};

export const validateGetEstimatesResponse = (
  res: AxiosResponse
): EstimatesResponse | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<EstimatesResponse>(
      validGenericResponse,
      EstimatesResponse
    )
  ) {
    return validGenericResponse as EstimatesResponse;
  }
};

export const validateCreateEmitter = (
  res: AxiosResponse
): EmitterCreateResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<EmitterCreateResponseType>(
      validGenericResponse,
      EmitterCreateResponseType
    )
  ) {
    return validGenericResponse as EmitterCreateResponseType;
  }
};

export const validateGetGeneralSyncer = (
  res: AxiosResponse
): SyncerGeneralResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<SyncerGeneralResponseType>(
      validGenericResponse,
      SyncerGeneralResponseType
    )
  ) {
    return validGenericResponse as SyncerGeneralResponseType;
  }
};

export const validateDropdownDataGeneral = (
  res: AxiosResponse
): DropdownDataGeneralResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<DropdownDataGeneralResponseType>(
      validGenericResponse,
      DropdownDataGeneralResponseType
    )
  ) {
    return validGenericResponse as DropdownDataGeneralResponseType;
  }
};

export const validateSyncerSettingGeneral = (
  res: AxiosResponse
): SyncerSettingGeneralResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<SyncerSettingGeneralResponseType>(
      validGenericResponse,
      SyncerSettingGeneralResponseType
    )
  ) {
    return validGenericResponse as SyncerSettingGeneralResponseType;
  }
};

export const validateResetPassword = (
  res: AxiosResponse
): ResetPasswordResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResetPasswordResponseType>(
      validGenericResponse,
      ResetPasswordResponseType
    )
  ) {
    return validGenericResponse as ResetPasswordResponseType;
  }
};

export const validateLeftMenuSubmissionData = (
  res: AxiosResponse
): LeftMenuSubmissionListResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<LeftMenuSubmissionListResponseType>(
      validGenericResponse,
      LeftMenuSubmissionListResponseType
    )
  ) {
    return validGenericResponse as LeftMenuSubmissionListResponseType;
  }
};

export const validateSoftDeleteUser = (
  res: AxiosResponse
): ResetPasswordResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResetPasswordResponseType>(
      validGenericResponse,
      ResetPasswordResponseType
    )
  ) {
    return validGenericResponse as ResetPasswordResponseType;
  }
};

export const validatePaySetting = (
  res: AxiosResponse
): PaySettingResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<PaySettingResponseType>(
      validGenericResponse,
      PaySettingResponseType
    )
  ) {
    return validGenericResponse as PaySettingResponseType;
  }
};

export const validateDataOpenByPlatformAndDevice = (
  res: AxiosResponse
): ResponseTypes.DataTypeOpenByPlatformAndDevice | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) return;
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ResponseTypes.DataTypeOpenByPlatformAndDevice>(
      validGenericResponse,
      ResponseTypes.DataTypeOpenByPlatformAndDevice
    )
  ) {
    return validGenericResponse as ResponseTypes.DataTypeOpenByPlatformAndDevice;
  }
};

export const validateAppointmentTypeSetting = (
  res: AxiosResponse
): AppointmentTypeSettingResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<AppointmentTypeSettingResponseType>(
      validGenericResponse,
      AppointmentTypeSettingResponseType
    )
  ) {
    return validGenericResponse as AppointmentTypeSettingResponseType;
  }
};

export const validateMedicalServices = (
  res: AxiosResponse
): MedicalCollection | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<MedicalCollection>(
      validResponse,
      MedicalCollectionEntry
    )
  ) {
    return validResponse as MedicalCollection;
  }
};

export const validateDropdownDataSubmission = (
  res: AxiosResponse
): OptionDropdownDataResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<OptionDropdownDataResponseType>(
      validGenericResponse,
      OptionDropdownDataResponseType
    )
  ) {
    return validGenericResponse as OptionDropdownDataResponseType;
  }
};

export const validateTwilioSetting = (
  res: AxiosResponse
): TwilioSettingResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }
  const validGenericResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<TwilioSettingResponseType>(
      validGenericResponse,
      TwilioSettingResponseType
    )
  ) {
    return validGenericResponse as TwilioSettingResponseType;
  }
};

export const validateMangoSetting = (
  res: AxiosResponse
): CollectionMangoPhoneType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ResponseTypes.ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<CollectionMangoPhoneType>(
      validResponse,
      CollectionMangoPhone
    )
  ) {
    return validResponse as CollectionMangoPhoneType;
  }
};
