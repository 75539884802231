import { UserDetailSMSResponse } from 'global/requests/ResponseTypes';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import React from 'react';
import SingleEmailEditor from './SingleEmailEditor';
import SubscriptionLayer from './SubscriptionLayer';

interface EmailViewContainerProps {
  interactionData: UserDetailSMSResponse | undefined;
  userInfo: UserDataResponse | undefined;
  subscription?: boolean;
  checkNewEmail(a: boolean): void;
  onSubscriptionClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailViewContainer = (props: EmailViewContainerProps) => {
  return (
    <>
      <SubscriptionLayer
        subscription={!!props.subscription}
        message={`${
          props.userInfo?.name?.givenName + ' ' + props.userInfo?.name?.lastName
        } has unsubscribed receiving Email.`}
        userId={props.userInfo?.userId ?? ''}
        onSubscriptionClick={props.onSubscriptionClick}
      />

      <span className='view-contact--overflow-below-nav-container max-width'>
        <SingleEmailEditor
          receiverUserIDs={
            props.userInfo?.userId ? [props.userInfo.userId] : []
          }
          checkNewEmail={props.checkNewEmail}
          subscription={props.subscription}
          userInfo={props.userInfo}
        />
      </span>
    </>
  );
};

export default EmailViewContainer;
