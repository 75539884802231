import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

export interface DeleteFolderModalProps {
  open: boolean;
  folder: any;
  handleClose: () => void;
  updateFolders: () => void;
  updateForms: () => void;
}

const DeleteFolderModal = (props: DeleteFolderModalProps): React.ReactElement | null => {
  const { open, folder, handleClose, updateFolders, updateForms } = props;

  const deleteFolder = async (folderId: string): Promise<void> => {
    try {
      const response = await configuredRequests.DEL.removeFolder(folderId);
      if (response.status === 200) {
        await updateFolders();
        await updateForms();
      }
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
      // handle error
    }
  };

  const handleSuccess = async (): Promise<void> => {
    if (!folder.id) handleClose(); // do error stuff?
    await deleteFolder(folder.id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-folder-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id="delete-form-modal"
    >
      <DialogContent>
        <DialogContentText className='delete-modal-text' style={{ fontFamily: 'Nunito Sans, Calibri, Arial', fontSize: '16px', color: '#12253F' }}>
          Are you sure you want to delete {folder.folder_name}? This can't be undone
        </DialogContentText>
        <br />
      </DialogContent>
      <DialogActions className="footer-delete-modal">
        <Button onClick={handleClose} style={{ color: '#6E84A3', fontSize: '16px' }}>
          Cancel
        </Button>
        <Button onClick={handleSuccess} style={{ color: '#E03229', fontSize: '16px' }} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFolderModal;
