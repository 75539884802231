import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@material-ui/core';
import toast from 'components/Global/Toast';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import loading_spinner from 'media/images/loading-spinner.svg';
import React, { useCallback, useEffect, useState } from 'react';
import ProviderItem from './components/ProviderItem';
import ProvidersModal from './ProvidersModal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Providers = (): JSX.Element => {
  const [openRightModal, setOpenRightModal] = useState(false);
  const [openEditRightModal, setOpenEditRightModal] = useState(false);
  const [providerModalData, setProviderModalData] = useState<ProviderModal[]>(
    []
  );
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openPopupDelete, setOpenPopupDelete] = React.useState(false);
  const [providerModalProfileData, setProviderModalProfileData] =
    useState<ProviderModal>({} as ProviderModal);
  const [providerId, setProviderId] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  // const [queryPagination, setQueryPagination] = React.useState({ limit: 5, offset: 0 });

  useEffect(() => {
    getProviderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (provider: ProviderModal) => {
    try {
      // @ts-ignore
      switchStatus(provider.id);
      await configuredRequests.PATCH.updateProvider({
        ...provider,
        isBookingActive: !provider.isBookingActive,
      });
    } catch (error) {
      toast.error('Failed to change status.');
      // @ts-ignore
      switchStatus(provider.id);
    }
  };

  const switchStatus = (providerId: string) => {
    // @ts-ignore
    const dataAfterSwitchStatus = providerModalData.map(
      (item: ProviderModal) => {
        if (item.id === providerId) {
          return { ...item, isBookingActive: !item.isBookingActive };
        }
        return item;
      }
    );
    setProviderModalData(dataAfterSwitchStatus);
  };

  const getProviderList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await configuredRequests.GET.getProviders();
      if (response?.data) {
        setProviderModalData(
          response?.data
            ?.map((provider: ProviderModal) => ({
              ...provider,
            }))
            .sort((providerA: ProviderModal, providerB: ProviderModal) => {
              if (
                // @ts-ignore
                providerA?.name?.toLowerCase() > providerB?.name?.toLowerCase()
              ) {
                return 1;
              } else if (
                // @ts-ignore
                providerA?.name?.toLowerCase() < providerB?.name?.toLowerCase()
              ) {
                return -1;
              } else {
                return 0;
              }
            })
        );
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  const getProviderInfoId = (provider: ProviderModal) => {
    setProviderModalProfileData(provider);
    // setIsLoading(false);
  };

  // const [state, setState] = React.useState({
  //   checkedA: false,
  // });
  const [modalStyle] = React.useState(getModalStyle);

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Delete Provider ?
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Are you sure you want to delete this provider?
          <br />
          The process cannot be undone.
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            {' '}
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              handleDeleteProvider();
            }}
          >
            {' '}
            Yes{' '}
          </Button>
        </div>
      </div>
    );
  };

  const renderPopupCannotDelete = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'> Cannot Delete</h2>

        <div className='insert-body'>
          This provider is associated to an appointment
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            color='primary'
            className='btn-ok'
            onClick={() => {
              setOpenPopupDelete(false);
            }}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const handleDeleteProvider = async () => {
    setOpenPopup(false);
    try {
      const res = await configuredRequests.DEL.deleteProvider(providerId);
      if (res.status === 200) {
        getProviderList();
        toast.success('Deleted successfully!');
      }
    } catch (err) {
      if (
        err?.response.data.message ===
        'This provider is associated to an appointment.'
      ) {
        setOpenPopupDelete(true);
      } else {
        toast.error('Failed to delete.');
      }
    }
  };

  const handleOpenPopup = (provider: ProviderModal) => {
    setProviderId(provider.id);
    setOpenPopup(true);
  };

  const handleOpenEditPopup = (provider: ProviderModal) => {
    setOpenEditRightModal(true);
    setProviderId(provider.id);
    setOpenRightModal(true);
    setProviderModalProfileData({ ...provider });
  };

  // const scrollToBottom = useCallback(() => {
  //   if (!isLoading) {
  //     setQueryPagination((preState: any) => ({ ...preState, offset: preState.offset + 1 }));
  //     getProviderList({ ...queryPagination, offset: queryPagination.offset + 1 });
  //     getProviderList();
  //   }
  // }, []);

  // const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);

  return (
    <>
      {isLoading ? (
        <div>
          <img
            className='loading-general-syncer'
            src={loading_spinner}
            alt={'Loading'}
          />
        </div>
      ) : (
        <div className='provider-main'>
          <div className='d-flex mt-20'>
            <h2 className='provider-title'>Providers</h2>
            {/* <Button
              variant="contained"
              color="primary"
              className="btn-add-new-providers"
              onClick={() => {
                setProviderModalProfileData({} as ProviderModal);
                setOpenEditRightModal(false);
                setOpenRightModal(true);
              }}
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
              New Provider
            </Button> */}
          </div>

          <div
            className='provider-div'
            // style={{
            //   overflow: "auto",
            //   maxHeight: "729px",
            // }}
            //@ts-ignore
            // ref={scrollRef}
          >
            <div className='provider-table'>
              {providerModalData.length > 0 ? (
                //@ts-ignore
                providerModalData.map((provider: ProviderModal) => {
                  return (
                    <ProviderItem
                      key={provider.id}
                      provider={provider}
                      handleChange={handleChange}
                      handleOpenEditPopup={handleOpenEditPopup}
                      handleOpenPopup={handleOpenPopup}
                    />
                  );
                })
              ) : (
                <b>No results found</b>
              )}
            </div>
          </div>
        </div>
      )}
      <ProvidersModal
        displayRoleModal={openRightModal}
        setDisplayRoleModal={setOpenRightModal}
        isEdit={openEditRightModal}
        setIsEdit={setOpenEditRightModal}
        // @ts-ignore
        providerModalProfileData={providerModalProfileData}
        getProviderList={getProviderList}
      />

      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>

      <Modal
        open={openPopupDelete}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupCannotDelete()}
      </Modal>
    </>
  );
};

export default Providers;
