/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import FilterGlobal from 'components/Global/Filter/Filter';
import { ErrorMessage, FastField, Field, FieldArray, Formik } from 'formik';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
  TemplateData,
} from 'global/requests/ResponseTypes';
import _, { template, values } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ModalInsertPersonalize from '../ModalInsertPersonalize';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import Filter from 'components/Global/Filter/Filter';
import {
  initialTemplateValue,
  initialTokenValue,
  optionTimes,
  optionUnitTimes,
} from '../EditEmailTemplate';

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const AutomationEventSchema = Yup.object().shape({
  templateName: Yup.string().required(ErrorMessageTypes.required),
  templateBody: Yup.string().required(ErrorMessageTypes.required),
  templateBodyMachine: Yup.string().when('checkedMachineAnswers', {
    is: true,
    then: Yup.string().required(ErrorMessageTypes.required),
  }),
  approx: Yup.array().of(
    Yup.object().shape({
      itemValue: Yup.string().required(ErrorMessageTypes.required),
      listOptions: Yup.string().required(ErrorMessageTypes.required),
    })
  ),
  checkedMachineAnswers: Yup.boolean(),
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const EditCallTemplate = () => {
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openPopupMachine, setOpenPopupMachine] = React.useState(false);
  const [openPopupPreview, setOpenPopupPreview] = React.useState(false);
  const [previewNumber, setPreviewNumber] = React.useState<string>('');
  const [dataCallInputOptions, setDataCallInputOptions] = React.useState<any[]>(
    []
  );
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [indexLevel, setIndexLevel] = React.useState<number[]>([]);
  const [idButton, setIdButton] = React.useState<string>('');
  const { templateId } = useParams<{ templateId: string }>();
  const { category } = useParams<{ category: string }>();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [errorNumber, setErrorNumber] = React.useState(false);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [isEdited, setValueEdited] = React.useState('noChange');
  const [title, setTitle] = React.useState<string>('');

  const [templateData, setTemplateData] =
    useState<TemplateData>(initialTemplateValue);
  const [optionDurations, setOptionDurations] = useState(optionTimes);
  const [optionUnits, setOptionUnits] = useState(optionUnitTimes);

  const mappingApproxFormik = (callInputOptions: any) => {
    return callInputOptions.map((callInput: any, index: number): object => {
      return {
        itemValue: callInput.inputNumber,
        listOptions: callInput.tokenKey,
      };
    });
  };

  const initialValue = {
    templateBodyMachine: templateId ? templateData.templateMachineBody : '',
    templateName: templateId ? templateData.name : '',
    templateBody: templateId ? templateData.templateBody : '',
    approx:
      templateId && templateData.callInputOptions
        ? mappingApproxFormik(templateData.callInputOptions)
        : [],
    checkedMachineAnswers: templateId ? templateData.isLeaveMessage : false,
    onOnlyOnceEvery: templateData?.onOnlyOnceEvery ?? false,
    duration: templateData?.duration ?? 1,
    unitOfDuration: templateData?.unitOfDuration ?? 'hours',
  };

  const getCallInPutOption = async () => {
    const response = await configuredRequests.GET.callInputOption();
    if (response) {
      //@ts-ignore
      setDataCallInputOptions(response.data.listValues);
    }
  };

  const getIndexFromDataCallInputOptions = (value: string) => {
    for (let i = 0; i < dataCallInputOptions.length; i++) {
      if (dataCallInputOptions[i].itemValue.value === Number(value)) {
        return i;
      }
    }
    return 0;
  };

  const getTemplateAndTokenDataRequest = async () => {
    try {
      if (templateId) {
        const templateResponse = await configuredRequests.GET.templateByID(
          templateId
        );

        let newIndexLevel: number[] = [];
        templateResponse.callInputOptions &&
          templateResponse.callInputOptions.map(
            (callInput: any, index: number): void => {
              newIndexLevel[index] = getIndexFromDataCallInputOptions(
                callInput.inputNumber
              );
            }
          );
        setIndexLevel(newIndexLevel);
        setTemplateData((prevTemplate) => ({
          ...prevTemplate,
          ...templateResponse,
        }));
        setTitle(templateResponse.name || '');
      }
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
      setLoading(false);
    } catch (err) {
      // TODO:
      setLoading(false);
    }
  };

  const getSettingDurationTimes = async () => {
    try {
      const res = await configuredRequests.GET.getSettingDurationTimes();
      if (res) {
        setOptionDurations(
          // @ts-ignore
          res.definedValues.map((item: any) => ({
            label: item.name,
            value: item.value.toString(),
          }))
        );
        setOptionUnits(
          // @ts-ignore
          res.unitKinds.map((item: any) => ({
            label: item.name,
            value: item.value,
          }))
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (dataCallInputOptions.length) {
      getTemplateAndTokenDataRequest();
      window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
    }
  }, [dataCallInputOptions]);

  useEffect(() => {
    getCallInPutOption();
    getSettingDurationTimes();
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
  }, [isEdited]);

  const handleOpenPopup = (e: any) => {
    setIdButton(e.target.id);
    if (e.target.id === 'personalizeButton') {
      setOpenPopup(true);
    } else {
      setOpenPopupMachine(true);
    }
  };

  const handleGoBackRouter = () => {
    setValueEdited('noChange');
    setOpenWarningPopup(false);
    history.goBack();
  };

  const submitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submittingData = {
        name: values.templateName,
        categoryKind: category,
        onOnlyOnceEvery: values.onOnlyOnceEvery,
        duration: values.duration,
        unitOfDuration: values.unitOfDuration,
        templateKind: TemplateKindsEnum.call,
        templateSubject: '',
        templateBody: values.templateBody,
        templateMachineBody: values.templateBodyMachine,
        isLeaveMessage: values.checkedMachineAnswers,
        callInputOptions: (() => {
          const returnArray = values.approx.map(
            (item: { itemValue: string; listOptions: string }) => {
              if (!item.itemValue || !item.listOptions) {
                return;
              }
              return {
                inputNumber: item.itemValue,
                tokenKey: item.listOptions,
              };
            }
          );
          return returnArray;
        })(),
      };
      if (!values.templateBody) {
        setDisabledSubmit(false);
        toast.error('Your data has not been saved yet. Please try again.');
        return;
      }
      if (!templateId) {
        const createResponse = await configuredRequests.POST.createTemplate(
          submittingData
        );
        if (createResponse) {
          setDisabledSubmit(false);
          setValueEdited('noChange');
          history.goBack();
          toast.success('Your data has been saved successfully!');
        }
      } else {
        const updateResponse = await configuredRequests.PATCH.updateTemplate(
          templateId,
          submittingData
        );
        if (updateResponse) {
          setDisabledSubmit(false);
          setTitle(updateResponse.name || '');
          setValueEdited('noChange');
          toast.success('Your data has been saved successfully!');
        }
      }
    } catch (error) {
      setDisabledSubmit(false);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    if (idButton === 'personalizeButton') {
      typeInTextarea(
        document.getElementById('textArea'),
        `{{${personalizationTokenSelected}}}`
      );
      setOpenPopup(false);
    } else {
      typeInTextarea(
        document.getElementById('textAreaMachine'),
        `{{${personalizationTokenSelected}}}`
      );
      setOpenPopupMachine(false);
    }
  };

  const sendNumber = async () => {
    setOpenPopupPreview(false);
    try {
      const res = await configuredRequests.PUT.sendPhonePreview(
        templateId,
        previewNumber
      );
      if (res) {
        toast.success(<b>Calling...</b>, true);
      }
    } catch (err) {
      toast.error('Phone number not available.');
    }
  };

  const renderPopupPreview = () => {
    return (
      <div style={modalStyle} className='modal-preview insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Preview Number
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopupPreview(false);
              setErrorNumber(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          <p> Enter your Phone number to send a call preview to yourself :</p>
          <div className='d-flex'>
            <input
              className='preview-number'
              type='text'
              onBlur={(e: any) => {
                if (e.target.value.length !== 10 || !Number(e.target.value)) {
                  setErrorNumber(true);
                } else {
                  setErrorNumber(false);
                }
              }}
              onChange={(e: any) => {
                setPreviewNumber(e.target.value);
                if (e.target.value.length !== 10 || !Number(e.target.value)) {
                  setErrorNumber(true);
                } else {
                  setErrorNumber(false);
                }
              }}
            />
            <Button
              onClick={sendNumber}
              type='button'
              variant='contained'
              color='primary'
              disabled={errorNumber || !previewNumber}
            >
              Send
            </Button>
          </div>
          {errorNumber && <p className='error-msg'>Incorrect format.</p>}
        </div>
      </div>
    );
  };

  return !loading ? (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        onSubmit={(values) => {
          submitData(values);
        }}
        validationSchema={AutomationEventSchema}
        validateOnMount={true}
      >
        {(formikProps) => {
          const { handleBlur, setFieldValue, values } = formikProps;

          return (
            <div className='edit-appointment'>
              <Fragment>
                <form
                  onSubmit={formikProps.handleSubmit}
                  data-testid='form'
                  className='col-12 form-schedule'
                >
                  <div className='confirmation-email-title'>
                    <h2>{templateId ? title : 'Add New Template'}</h2>
                    {templateId && (
                      <Button
                        onClick={() => {
                          setPreviewNumber('');
                          setOpenPopupPreview(true);
                        }}
                        variant='contained'
                        color='default'
                        className='button-form  preview-button'
                      >
                        Preview
                      </Button>
                    )}
                    {/* {templateId && ( */}
                    <Button
                      variant='contained'
                      color='default'
                      className='button-form cancel-button'
                      onClick={() => {
                        if (isEdited === 'noChange') {
                          return history.goBack();
                        } else {
                          setOpenWarningPopup(true);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    {/* } */}
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={!formikProps.isValid || disabledSubmit}
                    >
                      Save
                    </Button>
                  </div>
                  <div className='confirmation-email-container'>
                    <div className='flex'>
                      <div className='template--name-duration'>
                        <div className='form-input-settings mw-190'>
                          <label htmlFor='messageBody'>
                            Template Name <b className='require'>*</b>
                          </label>
                          <FastField
                            name='templateName'
                            maxLength={255}
                            required
                            className='setting__search-form'
                            onChange={(e: any) => {
                              setValueEdited('hasChanged');
                              formikProps.handleChange(e);
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'templateName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                        </div>
                        <div className='select--full-width custom-scroll'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='onOnlyOnceEvery'
                                color='primary'
                                checked={values.onOnlyOnceEvery}
                                onChange={() => {
                                  setFieldValue(
                                    'onOnlyOnceEvery',
                                    !values.onOnlyOnceEvery
                                  );

                                  setValueEdited('hasChanged');
                                }}
                              />
                            }
                            className='checkbox--small'
                            label='Only once every: '
                          />
                          <Filter
                            optionsForFilter={optionDurations}
                            handleSetNewFilterValue={(newTimeValue) => {
                              setFieldValue(
                                'duration',
                                Number(newTimeValue.value)
                              );
                              setValueEdited('hasChanged');
                            }}
                            valueSelected={optionDurations.find(
                              (option) =>
                                option.value ===
                                (values?.duration?.toString() ?? '1')
                            )}
                          />
                          <Filter
                            optionsForFilter={optionUnits}
                            handleSetNewFilterValue={(newUnitTime) => {
                              setFieldValue(
                                'unitOfDuration',
                                newUnitTime.value
                              );

                              setValueEdited('hasChanged');
                            }}
                            valueSelected={optionUnits.find(
                              (option) =>
                                option.value ===
                                (values?.unitOfDuration ?? 'hours')
                            )}
                          />
                        </div>

                        <p className='error-msg'>
                          <ErrorMessage name='templateName' />
                        </p>
                      </div>
                    </div>

                    <label htmlFor='messageBody'>
                      Message Body <b className='require'>*</b>
                    </label>
                    <div className='message-body'>
                      <div className='flex1180'>
                        <FastField
                          component='textarea'
                          name='templateBody'
                          id='textArea'
                          onChange={(e: any) => {
                            setValueEdited('hasChanged');
                            formikProps.handleChange(e);
                          }}
                          onBlur={(e: any) => {
                            handleBlur(e);
                            setFieldValue(
                              'templateBody',
                              e.currentTarget.value.trim()
                            );
                          }}
                        />
                        {/* <span className="preview-audio"><FontAwesomeIcon icon={["fas", "volume"]} className="icon-check-status" /> Preview</span> */}
                        <p className='error-msg'>
                          <ErrorMessage name='templateBody' />
                        </p>
                      </div>
                      <button
                        type='button'
                        id='personalizeButton'
                        className='btn-personalize'
                        onClick={handleOpenPopup}
                      >
                        {' Personalize '}
                      </button>
                      <ModalInsertPersonalize
                        openPopupInsertToken={openPopup}
                        setOpenPopupInsertToken={setOpenPopup}
                        handleConfirmInsertToken={handleInsertData}
                        token={tokenData}
                        screen={(category ?? templateData.categoryKind)
                          .toLowerCase()
                          .trim()}
                      />
                      <Modal
                        open={openPopupPreview}
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                      >
                        {renderPopupPreview()}
                      </Modal>
                    </div>

                    <div className='d-flex leave-message-machine-answers'>
                      <Checkbox
                        checked={formikProps.values.checkedMachineAnswers}
                        color='primary'
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(event: any) => {
                          formikProps.handleChange(event);
                        }}
                        name='checkedMachineAnswers'
                        className='check-schedule'
                      />
                      <span>Leave a message if a machine answers</span>
                    </div>

                    {formikProps.values.checkedMachineAnswers && (
                      <div className='mt-20'>
                        <label htmlFor='messageBody'>
                          Answer Machine Message Body{' '}
                          <b className='require'>*</b>
                        </label>
                        <div className='message-body'>
                          <div className='flex1180'>
                            <FastField
                              component='textarea'
                              name='templateBodyMachine'
                              id='textAreaMachine'
                              onChange={(e: any) => {
                                setValueEdited('hasChanged');
                                formikProps.handleChange(e);
                              }}
                              onBlur={(e: any) => {
                                handleBlur(e);
                                setFieldValue(
                                  'templateBodyMachine',
                                  e.currentTarget.value.trim()
                                );
                              }}
                            />
                            {/* <span className="preview-audio"><FontAwesomeIcon icon={["fas", "volume"]} className="icon-check-status" /> Preview</span> */}
                            <p className='error-msg'>
                              <ErrorMessage name='templateBodyMachine' />
                            </p>
                          </div>
                          <button
                            type='button'
                            id='personalizeButtonMachine'
                            className='btn-personalize'
                            onClick={handleOpenPopup}
                          >
                            {' Personalize '}
                          </button>
                          <ModalInsertPersonalize
                            openPopupInsertToken={openPopupMachine}
                            setOpenPopupInsertToken={setOpenPopupMachine}
                            handleConfirmInsertToken={handleInsertData}
                            token={tokenData}
                            screen={(category ?? templateData.categoryKind)
                              .toLowerCase()
                              .trim()}
                          />
                        </div>
                      </div>
                    )}

                    <div className='call-input-options'>
                      <p>Call Input Options</p>

                      <FieldArray
                        name='approx'
                        render={({ remove, push }) => {
                          return (
                            <>
                              {formikProps.values.approx.length > 0 &&
                                formikProps.values.approx.map(
                                  (item: any, indexInputOption: any) => {
                                    return (
                                      <div
                                        key={indexInputOption + 1}
                                        className='fallback-message'
                                      >
                                        {formikProps.values.approx.length >
                                          0 && (
                                          <div>
                                            <div className='d-flex fallback-message__attempt'>
                                              <Field
                                                name={`approx[${indexInputOption}].itemValue`}
                                                // //@ts-ignore
                                                // value={values.approx[indexInputOption].itemValue}
                                                as='select'
                                                className='method w-15'
                                                onChange={(e: any) => {
                                                  let currentArr = [
                                                    ...indexLevel,
                                                  ];
                                                  currentArr[indexInputOption] =
                                                    _.findIndex(
                                                      dataCallInputOptions,
                                                      (option) => {
                                                        return (
                                                          option.itemValue
                                                            .value ===
                                                          e.target.value
                                                        );
                                                      }
                                                    );
                                                  setIndexLevel(currentArr);
                                                  setFieldValue(
                                                    `approx[${indexInputOption}].itemValue`,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                {dataCallInputOptions.map(
                                                  (item: any, index: any) => (
                                                    <option
                                                      disabled={indexLevel.includes(
                                                        index
                                                      )}
                                                      key={index}
                                                      value={
                                                        item.itemValue.value
                                                      }
                                                    >
                                                      {item.itemValue.value}
                                                    </option>
                                                  )
                                                )}
                                              </Field>

                                              <>
                                                <Field
                                                  name={`approx[${indexInputOption}].listOptions`}
                                                  // //@ts-ignore
                                                  // value={values.approx[indexInputOption].listOptions}
                                                  as='select'
                                                  className='appointment-confirmation-fallbacks'
                                                  onChange={(
                                                    e: React.ChangeEvent
                                                  ) => {
                                                    formikProps.handleChange(e);
                                                  }}
                                                >
                                                  {dataCallInputOptions[
                                                    indexLevel[indexInputOption]
                                                  ].listOptions.map(
                                                    (item: any, index: any) => (
                                                      <option
                                                        key={index}
                                                        value={item.value}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                              </>

                                              <FontAwesomeIcon
                                                icon={['fal', 'times-circle']}
                                                className='icon-remove-fallback'
                                                onClick={(e: any) => {
                                                  if (
                                                    formikProps.values.approx
                                                      .length > 1
                                                  ) {
                                                    let currentArr = [
                                                      ...indexLevel,
                                                    ];
                                                    currentArr.splice(
                                                      indexInputOption,
                                                      1
                                                    );
                                                    setIndexLevel(currentArr);
                                                  } else {
                                                    setIndexLevel([]);
                                                  }
                                                  remove(indexInputOption);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}

                              <button
                                type='button'
                                className='add-call-input'
                                disabled={
                                  indexLevel.length ===
                                  dataCallInputOptions.length
                                }
                                onClick={() => {
                                  const indexLeft =
                                    formikProps.values.approx.length;
                                  let levelValue =
                                    dataCallInputOptions[0].itemValue.value;
                                  let indexLevelLeft = 0;
                                  for (
                                    let i = 0;
                                    i < dataCallInputOptions.length;
                                    i++
                                  ) {
                                    if (
                                      indexLevel.findIndex(
                                        (itemValue) => itemValue === i
                                      ) === -1
                                    ) {
                                      levelValue =
                                        dataCallInputOptions[i].itemValue.value;
                                      indexLevelLeft = i;
                                      break;
                                    }
                                  }
                                  push({
                                    itemValue: levelValue,
                                    listOptions:
                                      dataCallInputOptions[indexLevelLeft]
                                        .listOptions[0].value,
                                  });
                                  let currentArr = [...indexLevel];
                                  currentArr[indexLeft] = indexLevelLeft;
                                  setIndexLevel(currentArr);
                                }}
                              >
                                + Add Call Input Option
                              </button>
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </form>
              </Fragment>
              <ModalDiscard
                isOpenModal={openWarningPopup}
                handleCloseModal={setOpenWarningPopup}
                handleConfirmModal={handleGoBackRouter}
              />
            </div>
          );
        }}
      </Formik>
    </>
  ) : (
    <img
      className='loading-communication-template-call'
      src={loading_spinner}
      alt={'Loading'}
    />
  );
};

export default EditCallTemplate;
