import { LegendOptions } from 'highcharts';

const legend = {
  layout: 'vertical',
  align: 'center',
  verticalAlign: 'middle',
  name: '', // this is just to fix TS!
  labelFormatter(): string {
    // eslint-disable-next-line react/no-this-in-sfc
    return `${this.name}`;
  },
  itemStyle: {
    fontSize: '17px',
    fontWeight: '400',
  },
  symbolHeight: 12,
  symbolRadius: 6,
  itemMarginBottom: 10,
} as LegendOptions;
// eslint-disable-next-line import/prefer-default-export
export { legend };
