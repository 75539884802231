/* eslint-disable no-useless-escape */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import FAIconDropdown from './FAIconDropdown';
import { InfoSending } from './MessengerInput';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { toast } from 'react-toastify';
import { Button, Modal } from '@material-ui/core';
import { ContactMessages } from 'global/requests/ResponseTypes';
import moment from 'moment';

interface MessengerContactListItemFullProps {
  handleNewUserSelected: (newUserID: string) => void;
  currentSelectedContactUUID: string;
  currentSelectedContactData: ContactMessages | undefined;
  searchText: string;
  lastMessageTimestamp: string;
  contactInfo: ContactMessages;
  setInfoSending?: React.Dispatch<React.SetStateAction<InfoSending>>;
  setChangeSelectedContactId: React.Dispatch<React.SetStateAction<string>>;
  getContactListData: (query: any, isNotResetList?: boolean) => Promise<void>;
  setCurrentSelectedContactData?: React.Dispatch<React.SetStateAction<any>>;
  setCurrentSelectedContactUUID?: React.Dispatch<React.SetStateAction<any>>;
  setClickedContactMessenger?: React.Dispatch<React.SetStateAction<any>>;
  setSearchMessageId: React.Dispatch<React.SetStateAction<string>>;
  isSearching: boolean;
  queryPagination: any;
  isListSearching: boolean;
  countClickInputText?: number;
  searchTextMemory: string | undefined;
  setCountClickInputText?: React.Dispatch<React.SetStateAction<number>>;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const MessengerContactListItem = (props: MessengerContactListItemFullProps) => {
  const isCurrentlySelected =
    props.contactInfo.id === props.currentSelectedContactUUID;

  // Need backend to add a way to check user for unread messages.
  // Currently we would need to make a request for every contact.
  // Once something is added you can simply update this value to use some logic
  // and there should be no problems
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const updateContactUnRead = async (
    hasRead: boolean,
    isNotResetList?: boolean,
    contactIdSelected?: string
  ) => {
    try {
      if (props.contactInfo.id) {
        const res = await configuredRequests.POST.updateMarkUnread(
          contactIdSelected || props.contactInfo.id,
          hasRead
        );
        if (res) {
          props.getContactListData(
            {
              limit:
                props?.queryPagination?.limit *
                (props?.queryPagination?.offset + 1),
              offset: 0,
              isSearch: true,
            },
            isNotResetList ?? false
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMessage = async () => {
    try {
      if (props.contactInfo.id) {
        const res = await configuredRequests.DEL.deleteMessage(
          props.contactInfo.id
        );
        if (res) {
          props.getContactListData({
            limit:
              props?.queryPagination?.limit *
              (props?.queryPagination?.offset + 1),
            offset: 0,
            isSearch: true,
          });
          toast.success(
            <div className='notify-success d-flex'>
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className='icon-check-status'
              />
              <div className='success-message'>
                <b>Successfully</b>
                <p>Deleted successfully!</p>
              </div>
            </div>,
            { className: 'toast-success' }
          );
          if (
            props.setCurrentSelectedContactData &&
            props.setCurrentSelectedContactUUID
          ) {
            props.setCurrentSelectedContactData('');
            props.setCurrentSelectedContactUUID('');
          }
        }
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Failed to delete.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  React.useEffect(() => {
    const userMessageString = window.sessionStorage.getItem('message-user');
    if (!!userMessageString) {
      updateContactUnRead(true, false, props.currentSelectedContactUUID);
      window.sessionStorage.removeItem('message-user');
    }
  }, []);

  const handleEllipsisClick = (listItemValue: string) => {
    switch (listItemValue) {
      case 'unread': {
        if (props.setCountClickInputText) {
          props.setCountClickInputText(0);
        }
        updateContactUnRead(false);
        break;
      }
      case 'read': {
        updateContactUnRead(true);
        break;
      }
      case 'delete': {
        setOpenPopup(true);
        break;
      }
    }
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Are you sure you want to delete this message?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={(event: React.SyntheticEvent) => {
              event.stopPropagation();
              deleteMessage();
              setOpenPopup(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  const sliceText = () => {
    if (props.contactInfo.searchedMessage) {
      if (props.contactInfo.searchedMessage && props.searchTextMemory) {
        const searchWordPosition = props.contactInfo.searchedMessage
          .toLocaleLowerCase()
          .indexOf(props.searchTextMemory);
        const stringBeforeSearchWord = props.contactInfo?.searchedMessage.slice(
          0,
          searchWordPosition
        );
        const firstIndexString = stringBeforeSearchWord.charAt(0);

        if (
          searchWordPosition === 0 &&
          props.contactInfo.searchedMessage.length
        ) {
          return props.contactInfo?.searchedMessage.slice(searchWordPosition);
        } else if (
          searchWordPosition === 1 &&
          firstIndexString.match(specialCharacters)
        ) {
          return (
            firstIndexString +
            props.contactInfo?.searchedMessage.slice(searchWordPosition)
          );
        } else if (searchWordPosition > 0) {
          const indexStringBeforeTwoSpace = stringBeforeSearchWord.lastIndexOf(
            ' ',
            stringBeforeSearchWord.lastIndexOf(' ')
          );
          const stringBeforeTwoSpace = stringBeforeSearchWord.substr(
            0,
            indexStringBeforeTwoSpace
          );
          const stringBeforeTwoSpaceSearchWord =
            stringBeforeTwoSpace.lastIndexOf(
              ' ',
              stringBeforeTwoSpace.lastIndexOf(' ') - 1
            );
          const twoStringAfterSlice = stringBeforeTwoSpace.slice(
            stringBeforeTwoSpaceSearchWord
          );
          const showString =
            twoStringAfterSlice +
            ' ' +
            props.contactInfo?.searchedMessage.slice(searchWordPosition);
          const firstIndexString = twoStringAfterSlice.charAt(0);
          if (props.contactInfo?.searchedMessage.length > 60) {
            if (firstIndexString.match(specialCharacters)) {
              return '...' + showString.substring(1);
            }
            return '...' + showString;
          } else {
            return props.contactInfo?.searchedMessage;
          }
        } else {
          return props.contactInfo.searchedMessage;
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    <li
      className={
        'messenger--contact-item' + (isCurrentlySelected ? ' selected' : '')
      }
      onClick={(event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (props.setClickedContactMessenger)
          props.setClickedContactMessenger(true);
        if (props.isListSearching) {
          updateContactUnRead(true, true);
        } else {
          updateContactUnRead(true);
        }
        setIsFocused(false);
        if (props.contactInfo.id) {
          props.setSearchMessageId(props.contactInfo.searchedMesageId ?? '');
          props.setChangeSelectedContactId(props.contactInfo.id);
          props.handleNewUserSelected(
            props.contactInfo?.id === '00000000-0000-0000-0000-000000000000'
              ? props.contactInfo?.name ?? ''
              : props.contactInfo?.id
          );
        }
      }}
    >
      <span
        className={
          'messenger--contact-status-container' +
          (!props.contactInfo.isMessageRead && !props.isListSearching
            ? ''
            : ' hidden')
        }
      >
        {!props.contactInfo.isMessageRead ? (
          <span className={'messenger--contact-status-indicator'} />
        ) : null}
      </span>

      <button
        className={
          'messenger--contact-lnk' + (isCurrentlySelected ? ' selected' : '')
        }
      >
        <span className='messenger--contact-item-name'>
          {props.isListSearching ? (
            <HighlightText
              text={(props.contactInfo?.name?.length
                ? props.contactInfo.name
                : 'Missing Contact Name'
              ).slice(0, 31)}
              searchValue={
                props.searchTextMemory ? props.searchTextMemory.trim() : ''
              }
            />
          ) : (
            <span>
              {(props.contactInfo?.name?.length
                ? props.contactInfo.name
                : 'Missing Contact Name'
              ).slice(0, 31)}{' '}
            </span>
          )}
        </span>

        {props.searchText && (
          <span className='searched-message'>
            <HighlightText
              text={sliceText()}
              searchValue={
                props.searchTextMemory ? props.searchTextMemory.trim() : ''
              }
            />
          </span>
        )}

        {!props.isListSearching && (
          <span className='messenger--contact-item-datetime'>
            {props.lastMessageTimestamp}
          </span>
        )}

        {props.isListSearching && (
          <span className='search-message-date'>
            {moment(props.contactInfo.searchedMessageDate)
              .startOf('hour')
              .fromNow()}
          </span>
        )}
        {isFocused ? (
          <FontAwesomeIcon
            className='messenger--chat-message-accessibility-icon'
            icon={['far', 'chevron-left']}
          />
        ) : undefined}
      </button>

      {!props.isListSearching && (
        <FAIconDropdown
          iconProps={{ icon: ['far', 'ellipsis-v'] }}
          listOptions={[
            {
              label: props.contactInfo.isMessageRead
                ? 'Mark Unread'
                : 'Mark Read',
              value: props.contactInfo.isMessageRead ? 'unread' : 'read',
            },
            { label: 'Delete', value: 'delete' },
          ]}
          handleListItemClick={handleEllipsisClick}
          customClasses={{
            containerDiv: 'messenger--ellipsis-container ',
          }}
        />
      )}

      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </li>
  );
};

export default MessengerContactListItem;
