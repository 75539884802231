import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  Column,
  ConfiguratorResponse,
  ServiceResponse,
} from 'global/requests/ResponseTypes';
import React, { Fragment, useEffect } from 'react';
import ReactModal from 'react-modal';
import * as Yup from 'yup';
import ConfiguratorUpload from './ConfiguratorUpload';
import _ from 'lodash';
import moment from 'moment';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { Editor } from '@tinymce/tinymce-react';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
ReactModal.setAppElement('div#root');

const TINY_MCE_API_KEY = 'a9f6rtq0r7e1k7cgfmrap8d6a97h8lpbl0hngycy1462j6kw';

interface RightModalSettingUserProps {
  displayRoleModal: boolean;
  setDisplayRoleModal: (arg: boolean) => void;
  titleText?: string;
  handleReloadList?: any;
  isEdit?: boolean;
  idValue: string;
  setConfiguratorData?: (arg: any) => void;
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const serviceValidationSchema = Yup.object().shape({
  version: Yup.string().required(ErrorMessageTypes.incorrectFormat),
  releasedDate: Yup.string().required(ErrorMessageTypes.incorrectFormat),
});

const ConfiguratorModal = (props: RightModalSettingUserProps) => {
  const {
    displayRoleModal,
    setDisplayRoleModal,
    titleText,
    isEdit,
    idValue,
    setConfiguratorData,
  } = props;

  const [openPopup, setOpenPopup] = React.useState(false);
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [errorUploadFile, setErrorUploadFile] = React.useState<string>('');
  const [configuratorInfoData, setConfiguratorInfoData] =
    React.useState<ConfiguratorResponse>();
  const [errorReleasedNote, setErrorReleasedNote] = React.useState<string>(
    isEdit ? '' : 'no'
  );

  const getConfiguratorInformationData = async () => {
    try {
      const res = await configuredRequests.GET.getConfiguratorInformationData(
        idValue
      );
      if (res) {
        setConfiguratorInfoData(res as ConfiguratorResponse);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (idValue && configuratorInfoData?.configuratorId !== idValue) {
      getConfiguratorInformationData();
    }
  });

  useEffect(() => {
    if (displayRoleModal) {
      setErrorReleasedNote(idValue ? '' : 'no');
    }
  }, [displayRoleModal]);

  const configuratorInitialValues = !isEdit
    ? {
        version: 'N/A',
        releasedDate: '',
        releasedNote: '',
        downloadLink: '',
      }
    : {
        id: configuratorInfoData?.configuratorId,
        version: _.get(configuratorInfoData, 'versionName', 'N/A'),
        releasedDate: moment(configuratorInfoData?.releasedDate).format(
          'YYYY-MM-DDTHH:mm'
        ),
        releasedNote: configuratorInfoData?.releaseNotes,
        downloadLink: configuratorInfoData?.downloadLink,
      };

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = !isEdit
        ? {
            version: values.version,
            releasedDate: values.releasedDate,
            releaseNotes: values.releasedNote,
            downloadLink: values.downloadLink,
          }
        : {
            configuratorId: configuratorInfoData?.configuratorId,
            version: values.version,
            releasedDate: values.releasedDate,
            releaseNotes: values.releasedNote,
            downloadLink: values.downloadLink,
          };
      const res = isEdit
        ? await configuredRequests.PATCH.updateConfigurator(submitData as any)
        : await configuredRequests.POST.createConfigurator(submitData as any);
      setDisplayRoleModal(false);
      await props.handleReloadList();
      getConfiguratorInformationData();
      if (res) {
        setErrorUploadFile('');
        setDirtyForm(false);

        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      setDisabledSubmit(false);
      // @ts-ignore
      if (error.response) {
        // @ts-ignore
        setErrorUploadFile(error.response.data.message);
      }
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const tinyMCEOption = React.useMemo(
    () => ({
      branding: false,
      height: 420,
      // selector: 'textarea#editor-released-note',
      content_css:
        '/src/components/Communications/PersonalizationPopupCss.scss',
      plugins: [
        'advlist fullpage autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen emoticons insertdatetime media table paste imagetools wordcount',
      ],
      fullpage_default_encoding: 'UTF-8',
      fullpage_hide_in_source_view: false,
      menubar: 'file edit view insert format tools table tc help',
      forced_root_block: false,
      toolbar:
        'fullscreen | styleselect | bold italic underline strikethrough forecolor backcolor alignment list | link image emoticons table | personalization',
      setup: function (editor: any) {
        editor.ui.registry.addGroupToolbarButton('alignment', {
          icon: 'align-left',
          tooltip: 'Alignment',
          items: 'alignleft aligncenter alignright alignjustify',
        });
        editor.ui.registry.addGroupToolbarButton('list', {
          icon: 'list-bull-square',
          tooltip: 'List',
          items: 'bullist numlist',
        });

        editor.on('blur', function (e: any) {
          const b = '<body>';
          const be = '</body>';
          const t = editor.getContent();
          const note = t.substring(t.indexOf(b) + b.length, t.indexOf(be) - 1);
          if (note.length === 1) {
            setErrorReleasedNote('This field is required.');
          } else {
            setErrorReleasedNote('');
          }
        });
        editor.on('keyup', function (e: any) {});
      },
      file_picker_types: 'image',
      file_picker_callback: function (cb: any, value: any, meta: any) {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          //@ts-ignore
          let file = this.files[0];
          let formData = new FormData();
          formData.append('attachment', file);
          let reader = new FileReader();
          reader.onload = async function () {
            try {
              const res = await configuredRequests.POST.uploadImage(formData);
              if (res) {
                cb(res.url, { title: file.name });
                setErrorReleasedNote('');
                toast.success('File uploaded successfully');
              }
            } catch (err) {
              toast.error('File upload failed');
            }
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },
      relative_urls: false,
      remove_script_host: false,
      convert_urls: true,
    }),
    []
  );

  const validateNote = (newNote: string) => {
    const b = '<body>';
    const be = '</body>';
    const note = newNote.substring(
      newNote.indexOf(b) + b.length,
      newNote.indexOf(be) - 1
    );
    if (note.length === 1) {
      setErrorReleasedNote('This field is required.');
    } else {
      setErrorReleasedNote('');
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplayRoleModal(dirtyForm);
    setErrorUploadFile('');
  };

  const handleConfirmModal = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    setDisplayRoleModal(false);
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-driver'
      isOpen={displayRoleModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={configuratorInitialValues}
        validationSchema={serviceValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const { handleSubmit, setFieldValue, handleChange, values, dirty } =
            formikProps;
          if (dirty && displayRoleModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Version' : ''}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Configurator Version <b className='require'>*</b>
                        </span>
                        <div className='form-input-settings'>
                          <div className='first-name'>
                            <FastField
                              name='version'
                              required
                              disabled
                              value={values.version}
                              className='setting__search-form'
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='version' />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Released Date <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            id='datetime-local'
                            type='datetime-local'
                            component={TextField}
                            name='releasedDate'
                            required
                            disabled
                            className='setting__search-form datetime'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e: any) => {
                              handleChange(e);
                              setFieldValue('releasedDate', e.target.value);
                            }}
                            value={values.releasedDate}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='releasedDate' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <div className='form-input-settings'>
                        <span className='title-input-form'>
                          {' '}
                          Released Note <b className='require'>*</b>
                        </span>
                        <Editor
                          // id="editor-released-note"
                          apiKey={TINY_MCE_API_KEY}
                          init={tinyMCEOption}
                          onEditorChange={(newNote: string) => {
                            formikProps.setFieldValue('releasedNote', newNote);
                            validateNote(newNote);
                          }}
                          outputFormat='html' // text | html
                          initialValue={''}
                          textareaName='releasedNote'
                          value={values.releasedNote}
                        />
                        {errorReleasedNote !== '' &&
                          errorReleasedNote !== 'no' && (
                            <p className='error-msg'>{errorReleasedNote}</p>
                          )}
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <div className='form-input-settings'>
                        <div className='first-name'>
                          <FastField
                            name='downloadLink'
                            required
                            disabled
                            value={values.downloadLink}
                            className='display-none'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Upload File</h2>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        File <b className='require'>*</b>
                      </span>
                      <ConfiguratorUpload
                        setFieldValue={setFieldValue}
                        values={values}
                        setErrorUploadFile={setErrorUploadFile}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='emailAddress' />
                        {errorUploadFile}
                      </p>
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    isEdit
                      ? !formikProps.validateField ||
                        !!errorReleasedNote ||
                        disabledSubmit
                      : !formikProps.isValid ||
                        !!errorReleasedNote ||
                        configuratorInitialValues.version === values.version ||
                        disabledSubmit
                  }
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
      <ModalDiscard
        isOpenModal={openPopup}
        title='Confirmation'
        handleCloseModal={setOpenPopup}
        handleConfirmModal={handleConfirmModal}
      />
    </ReactModal>
  );
};
export default ConfiguratorModal;
