import ApiService from '../clientApi';
import { Template } from 'components/FormBuilder/services/templatesService/templateType';

const Api = ApiService();

const getImageLinkFromTemplateData = (templateData: Template): Promise<any> => {
  return Api.rpc('template', {
    method: 'getPreviewImageLinksForTemplateData',
    params: ['MessageTemplate', templateData],
  });
};

const getImageLinkFromTemplateId = (templateId: string | number): Promise<any> => {
  return Api.rpc('template', {
    method: 'getPreviewImageLinksForTemplateData',
    params: [templateId],
  });
};

export default { getImageLinkFromTemplateData, getImageLinkFromTemplateId };
