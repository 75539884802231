import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import Table from 'components/Global/Table';
import TableCell from '@material-ui/core/TableCell';
import { debounce, intersection } from 'lodash';

import CustomCheckbox, {
  CustomCheckboxDiv,
} from 'components/Contacts/CustomCheckbox';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import ModalError from './ModalError';
import ModalDialog from 'components/Global/Modal';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import './SyncerError.scss';
import {
  TenantSyncerErrorCollectionType,
  TenantSyncerErrorType,
} from 'global/requests/ResponseTypes/Tenants';
import Toast from 'components/Global/Toast';
import moment from 'moment';

export interface IQuery {
  limit: number;
  offset: number;
  filter: string;
  isCallApi: boolean;
}

type Props = {
  reloadCountError?: () => void;
};

const optionsFilter = [
  {
    label: 'Error Practices',
    value: 'ErrorPractices',
  },
  {
    label: 'All Practices',
    value: 'AllPractices',
  },
];

const columns = [
  { key: 'checkbox', title: '' },
  { key: 'practiceName', title: 'Practice Name' },
  { key: 'numberErrors', title: 'Errors' },
  { key: 'latestTime', title: 'Last Errors' },
  { key: 'action', title: '' },
];

const initialTenantErrors = {
  count: 0,
  totalAvailable: 0,
  tenants: [],
};

const SyncerError = ({ reloadCountError }: Props) => {
  const [searchName, setSearchName] = useState('');
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
    filter: optionsFilter[0].value,
    isCallApi: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTenantError, setSelectedTenantError] =
    useState<TenantSyncerErrorType | null>(null);
  const [selectedPracticeIds, setSelectedPracticeIds] = useState<{
    [id: string]: boolean;
  }>({});
  const [tenantErrors, setTenantErrors] =
    useState<TenantSyncerErrorCollectionType>(initialTenantErrors);

  useEffect(() => {
    if (queryPagination.isCallApi) {
      getErrorsTenant(queryPagination, searchName);
    }
  }, [queryPagination]);

  const getErrorsTenant = useCallback(
    async (query: IQuery, searchText?: string, disabledLoading?: boolean) => {
      !disabledLoading && setIsLoading(true);
      try {
        const res = await configuredRequests.GET.getSyncerErrors({
          limit: query.limit,
          offset: query.offset,
          filter: query.filter,
          searchText,
        });
        if (res) {
          setTenantErrors(res);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('error: ', err);
      }
    },
    []
  );

  const reloadData = useCallback(() => {
    getErrorsTenant(queryPagination, searchName, true);
    reloadCountError && reloadCountError();
  }, [queryPagination, searchName]);

  const handleMarkReadErrors = useCallback(
    async (isRead: boolean) => {
      try {
        const res = await configuredRequests.PUT.markReadSyncerErrors(
          isRead,
          Object.keys(selectedPracticeIds)
        );

        if (res) {
          setSelectedPracticeIds({});
          getErrorsTenant(queryPagination, searchName);
          reloadCountError && reloadCountError();
          Toast.success('Your data has been saved successfully!');
        }
      } catch (err) {
        Toast.error('Your data has not been saved yet. Please try again.');
        console.log('err', err);
      }
    },
    [selectedPracticeIds, getErrorsTenant]
  );

  const handleDeleteAllErrors = useCallback(async () => {
    try {
      const res = await configuredRequests.DEL.deleteSyncerErrors(
        Object.keys(selectedPracticeIds)
      );

      if (res) {
        setSelectedPracticeIds({});
        getErrorsTenant(queryPagination, searchName);
        reloadCountError && reloadCountError();
        Toast.success('Deleted successfully!');
      }
    } catch (err) {
      Toast.error('Failed to delete.');
      console.log('err', err);
    }
  }, [selectedPracticeIds, getErrorsTenant]);

  const handleSelectFilter = useCallback(
    (filter: string) => {
      if (filter !== queryPagination.filter) {
        setSelectedPracticeIds({});
        setQueryPagination((prev) => ({
          ...prev,
          filter,
          isCallApi: true,
          offset: 0,
        }));
      }
    },
    [queryPagination]
  );

  const handleSearchError = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchName(e.target.value);
      setQueryPagination((prev) => ({ ...prev, offset: 0, isCallApi: false }));
      searchErrorsWithName(e.target.value);
      setSelectedPracticeIds({});
    },
    [queryPagination]
  );

  const searchErrorsWithName = useCallback(
    debounce((name: string) => {
      getErrorsTenant(queryPagination, name);
    }, 1000),
    [queryPagination.filter, queryPagination.offset, queryPagination.limit]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({
        ...preState,
        offset: newPage,
        isCallApi: true,
      }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination((prev) => ({
          ...prev,
          limit: newRowsPerPage,
          offset: 0,
          isCallApi: true,
        }));
      }
    },
    []
  );

  const handleSelectAllCurrentPageCheckboxClick = () => {
    const totalCount = tenantErrors.tenants.length;
    if (!tenantErrors) {
      return;
    }
    if (
      tenantErrors.tenants.filter((item: TenantSyncerErrorType) =>
        selectedPracticeIds.hasOwnProperty(item?.tenantId ?? '')
      ).length === totalCount
    ) {
      const newSelectedPracticeIDs = { ...selectedPracticeIds };
      tenantErrors.tenants.forEach((data: TenantSyncerErrorType) => {
        delete newSelectedPracticeIDs[data?.tenantId ?? ''];
      });
      setSelectedPracticeIds(newSelectedPracticeIDs);
      return;
    }
    const newSelectedPracticeIDs = { ...selectedPracticeIds };
    tenantErrors.tenants.forEach((data: TenantSyncerErrorType) => {
      newSelectedPracticeIDs[data?.tenantId ?? ''] = !data.errorCount;
    });

    setSelectedPracticeIds(newSelectedPracticeIDs);
  };

  const handleAddContactToSelected = (selectedData: TenantSyncerErrorType) => {
    setSelectedPracticeIds({
      ...selectedPracticeIds,
      [selectedData?.tenantId ?? '']: !selectedData.errorCount,
    });
  };

  const handleRemoveContactFromSelected = (
    selectedData: TenantSyncerErrorType
  ) => {
    const newSelectededObject = {
      ...selectedPracticeIds,
    };
    delete newSelectededObject[selectedData?.tenantId ?? ''];
    setSelectedPracticeIds(newSelectededObject);
  };

  const renderSelectAllCurrentPageCheckbox = (): React.ReactElement => {
    if (tenantErrors.tenants.length === 0) {
      return <CustomCheckbox checked={false} clickHandler={() => {}} />;
    }
    const listIdSubmissionViaPage =
      tenantErrors.tenants.map(
        (item: TenantSyncerErrorType) => item.tenantId
      ) || [];
    // @ts-ignore
    const listIdSelected = Object.keys(selectedPracticeIds);
    const listMatch = intersection(listIdSubmissionViaPage, listIdSelected);
    const isListSelected = listMatch.length >= tenantErrors.tenants.length;
    return (
      <CustomCheckbox
        checked={isListSelected}
        clickHandler={handleSelectAllCurrentPageCheckboxClick}
      />
    );
  };

  return (
    <div className='communication-container user-setting w-300 roles-table setting-detail syncer__error setting-scroll--custom'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        <div className='action-table-submission-form-builder'>
          <div className='action-button-submission-form-builder'>
            <button
              disabled={Object.keys(selectedPracticeIds).length === 0}
              onClick={() => handleMarkReadErrors(true)}
              className={
                Object.keys(selectedPracticeIds).length > 0
                  ? 'action-submission-mark-as-read enable'
                  : 'action-submission-mark-as-read disable'
              }
            >
              <Create className='action-submission-mark-as-read-icon' />
              <span className='action-submission-mark-as-read-label'>
                {Object.keys(selectedPracticeIds).length > 0
                  ? 'Mark all as read'
                  : 'Mark as'}
              </span>
            </button>

            {queryPagination.filter === optionsFilter[1].value &&
              Object.values(selectedPracticeIds).includes(true) && (
                <button
                  disabled={Object.keys(selectedPracticeIds).length === 0}
                  onClick={() => handleMarkReadErrors(false)}
                  className='action-submission-mark-as-read enable'
                >
                  <Create className='action-submission-mark-as-read-icon' />
                  <span className='action-submission-mark-as-read-label'>
                    Mark all as unread
                  </span>
                </button>
              )}

            <button
              disabled={Object.keys(selectedPracticeIds).length === 0}
              onClick={handleDeleteAllErrors}
              className={
                Object.keys(selectedPracticeIds).length > 0
                  ? 'action-submission-delete enable'
                  : 'action-submission-delete disable'
              }
            >
              <Delete className='action-submission-delete-icon' />
              <span className='action-submission-delete-label'>Delete all</span>
            </button>
          </div>

          <div className='contacts--filter-container'>
            <TextChevronDropdown
              styleOptions={{
                width: 270,
              }}
              className='contacts--filter--select-container background-gray height-40'
              //@ts-ignore
              currentSelection={
                optionsFilter.find(
                  (option) => option.value === queryPagination.filter
                )?.label
              }
              handleChangeSelection={handleSelectFilter}
              selectionOptions={optionsFilter}
              useBlackText={true}
            />
            <div className='contacts--filter-search-container'>
              <FontAwesomeIcon
                icon={['far', 'search']}
                onClick={() => searchErrorsWithName(searchName)}
              />

              <input
                className='contacts--filter--search-input submission-detail-search-input'
                value={searchName}
                type='search'
                placeholder='Search by name'
                onChange={handleSearchError}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          // <Loading className='loading-spinner' />
          <span className='loading'>Loading...</span>
        ) : (
          <>
            {tenantErrors.tenants.length > 0 ? (
              <Table
                columns={columns}
                tableHeader={
                  <>
                    {columns.map((column, index) => (
                      <TableCell key={column.key}>
                        {index === 0
                          ? renderSelectAllCurrentPageCheckbox()
                          : column.title}
                      </TableCell>
                    ))}
                  </>
                }
                // @ts-ignore
                rows={tenantErrors.tenants.map((item) => ({
                  checkbox: (
                    <CustomCheckbox
                      checked={selectedPracticeIds.hasOwnProperty(
                        item?.tenantId ?? ''
                      )}
                      clickHandler={(
                        event: React.MouseEvent<CustomCheckboxDiv>
                      ) => {
                        const customCheckedValue =
                          event.currentTarget.getAttribute('data-checked');
                        const userWasSelected = customCheckedValue === 'false';
                        if (!customCheckedValue?.length) {
                          console.log('SHOULD NOT HAPPEN');
                          return;
                        }
                        if (userWasSelected) {
                          handleAddContactToSelected(item);
                          return;
                        }
                        if (!userWasSelected) {
                          handleRemoveContactFromSelected(item);
                          return;
                        }
                      }}
                    />
                  ),
                  practiceName: (
                    <span
                      className='practice-name'
                      onClick={() => setSelectedTenantError(item)}
                    >
                      {item.tenantName ?? ''}
                    </span>
                  ),
                  numberErrors: (
                    <span
                      className='number-error'
                      onClick={() => setSelectedTenantError(item)}
                    >
                      {item.errorCount ?? 0}
                    </span>
                  ),
                  latestTime: item.latestErrorDate
                    ? moment(new Date(item.latestErrorDate || '')).format(
                        'MM/DD/YYYY hh:mm:ss A'
                      )
                    : 'N/A',
                  action: (
                    <Button
                      variant='contained'
                      color='primary'
                      className='btn-login-practice'
                      onClick={() =>
                        window.open(
                          `/AdminLoginPractice/group/${item.tenantGroupId}/practice/${item.tenantId}`
                        )
                      }
                    >
                      Login to Practice
                    </Button>
                  ),
                }))}
                isHasPagination={true}
                // @ts-ignore
                totalAvailable={tenantErrors.totalAvailable ?? 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
              />
            ) : (
              <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                No results found
              </p>
            )}
          </>
        )}
      </div>

      {!!selectedTenantError && (
        <ModalDialog
          isOpenModal={!!selectedTenantError}
          title={`${selectedTenantError?.tenantName ?? ''} Errors`}
          handleCloseModal={() => setSelectedTenantError(null)}
          isShowButtonModal={false}
          classMore='modal__syncer-error'
        >
          <div className='communication-container roles-table setting-detail'>
            <div className='communication-container__table w-table-cell templates-mail-sms custom-scroll'>
              <ModalError
                tenantId={selectedTenantError?.tenantId ?? ''}
                reloadData={reloadData}
              />
            </div>
          </div>
        </ModalDialog>
      )}
    </div>
  );
};

export default SyncerError;
