/* eslint-disable no-underscore-dangle */
import React from 'react';
import { get } from 'lodash';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import {
  httpRequest,
  hasSubfields,
  getFieldProperties,
  getFieldPropertyValues,
  Messages,
  fieldIsType,
  getComponent,
} from './components/form-field-types/helpers';
import ThankYou from './thank-you';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import Toast from 'components/Global/Toast';

import { validateField } from './form-validate';
import { default as safeAttributes } from './form-submission-attributes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

import './form-renderer.css';
import { getRootDomain } from 'global/constants/url';
// import '../form-builder.scss';

const reloadPage = () => window.location.reload();

const ErrorMessages = {
  FORM_RETRIEVAL_ERROR:
    'There was an issue loading this form. Please refresh your browser to try again.',
  FORM_SUBMISSION_ERROR:
    'We were unable to process this submission. Please check your entries and try again.',
  SUBMISSION_RETRIEVAL_ERROR:
    'There was an issue loading your previous submission for this form. Please refresh your browser to try again.',
  CONTACT_US: `Please contact us if the problem persists. We apologize for the inconvenience.`,
};

export const EditContext = React.createContext({
  edit: false,
  isAppointmentBooking: false,
});

export default class FormRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.pdfTool = new jsPDF('p', 'px', 'letter', true);

    this.state = {
      formVersion: null,
      fields: {},
      pages: [],
      page: 1,
      pageNumber: 0,
      pagePrinted: [],
      formErrors: 0,
      isSaving: false,
      dataProcessingError: null,
      errorConfirmationAction: null,
      submitted: false,
      submitLoading: false,
      files: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldLoadData(prevProps)) this.loadData();
    if (this.isSaving()) this.handleGetSubmission();
    if (this.props.readOnly) {
      const selects = document.querySelectorAll(
        ".date > .time-picker select[type='number']"
      );
      selects.forEach((select) => select.setAttribute('disabled', true));
    }
  }

  shouldLoadData = (prevProps) => {
    return (
      (!this.state.formVersion && !this.state.dataProcessingError) ||
      (this.hasProp('formVersion') &&
        prevProps.formVersion !== this.props.formVersion)
    );
  };

  isSaving = () => this.canSave() && this.state.isSaving;

  loadData = async () => {
    if (this.canSave() && this.props.saveFlag) {
      this.setState({ isSaving: true });
      return;
    }

    let formVersion = {};
    try {
      if (this.hasProp('formVersion')) {
        formVersion = this.getFormVersionFromProps();
      } else {
        formVersion = this.mapSafeAttributes(
          'formVersion',
          this.props.formVersion
        );
      }
    } catch (ex) {
      this.setState({
        dataProcessingError: ErrorMessages.FORM_RETRIEVAL_ERROR,
        errorConfirmationAction: reloadPage,
      });
      return;
    }

    // map page breaks to state for use in render()
    // to only display fields for the current page
    const pages = this.mapPageBreaks(formVersion);
    // also map formVersion fields to fields array in state
    const fields = this.loadFieldsToState(
      {},
      this.formVersionFields(formVersion)
    );
    this.setState(
      {
        formVersion,
        fields,
        pages,
        page: 1,
      },
      () => {
        // if a submission object or id has been provided in props,
        // then load the input fields with submitted values
        this.loadSubmissionData();
      }
    );
  };

  getFormVersionFromHttp = () => {
    return new Promise((resolve, reject) => {
      httpRequest(this.formsUrl, (err, data) => {
        if (err) {
          // this wasn't always getting hit when it should ( wrongly captured in next if statement );
          reject(err);
          return;
        }

        if (!data || !data.hasOwnProperty('object')) {
          reject('Form is in an unexpected format. Unable to render.');
          return;
        }
        resolve(data);
      });
    });
  };

  getFormVersionFromProps = () => {
    const { formVersion } = this.props;
    if (!formVersion) return;
    return {
      object: {},
      ...this.mapSafeAttributes('formVersion', formVersion),
    };
  };

  hasProp = (propName) => this.props[propName] !== undefined;

  loadSubmissionData = () => {
    if (this.hasProp('submission')) {
      this.loadSubmissionDataFromProps();
    }
  };

  loadSubmissionDataFromProps = () => {
    const { submission: submissionObject = [] } = this.props;
    const fields = { ...this.state.fields };
    // map submission values to fields
    submissionObject.forEach((submittedField) => {
      const matchedField = this.getFirstMatchedField(fields, submittedField);
      if (matchedField) {
        fields[matchedField.uuid].value = submittedField.value;
        fields[matchedField.uuid].label = submittedField.label;
      }
    });

    this.setState({ fields });
  };

  getFirstMatchedField = (fields, fieldToMatch) => {
    const matchedField = fields[fieldToMatch.uuid];
    if (matchedField) return matchedField;

    return this.findFormVersionField(fieldToMatch);
  };

  findFormVersionField = (fieldToMatch) => {
    const allFields = this.formVersionFields();
    return (
      allFields[fieldToMatch.uuid] ||
      this.getMatchingFields(allFields, fieldToMatch)
    );
  };

  getMatchingFields = (fields, fieldToMatch) => {
    // search all top-level (not nested) basic (is_basic = true) fields first, for a match on id,
    // -OR- if still not found, search through fields w/ basic (is_basic = true) subfields
    return (
      this.searchFilteredFields(
        fields,
        fieldToMatch,
        (f) => !hasSubfields(f) && f.is_basic
      ) ||
      this.searchFilteredFields(fields, fieldToMatch, (f) => hasSubfields(f))
    );
  };

  searchFilteredFields = (fields, fieldToMatch, filterFn) => {
    const filteredFields = fields.filter(filterFn);
    let result;

    for (let i = 0; i < filteredFields.length; i++) {
      const field = filteredFields[i];
      if (hasSubfields(field)) {
        result = field.fields.find(
          (f) => f.id === fieldToMatch.id && f.is_basic
        );
      } else {
        result = field.id === fieldToMatch.id ? field : false;
      }
      if (result) break;
    }

    return result;
  };

  mapSafeAttributes = (attributeType, dataObject = {}) => {
    const attributes = safeAttributes[attributeType];
    const result = {};

    if (
      attributeType === 'formVersion' &&
      this.props.populateData &&
      !this.props.submission &&
      dataObject?.object?.fields?.length > 0
    ) {
      dataObject.object.fields = this.populateField(dataObject.object.fields);
    }

    attributes.forEach((attribute) => {
      if (attribute === 'group_name' && dataObject[attribute] === 'Ungrouped') {
        result[attribute] = dataObject.display_name;
      } else {
        result[attribute] = dataObject[attribute];
      }
    });
    // attributes.forEach((attribute) => {
    //   return (result[attribute] = dataObject[attribute]);
    // });
    return result;
  };

  mapPageBreaks = (formVersion) => {
    const pages = [];
    this.formVersionFields(formVersion).forEach((field, index) => {
      if (field.type_name === 'Page Break') {
        const { isForPDF } = this.props;
        if (isForPDF) {
          field.type_name = 'Print Page Break';
        } else {
          pages.push(index);
        }
      }
    });
    return pages;
  };

  loadFieldsToState = (fields, fieldList, parentField) => {
    if (!fieldList) return;

    fieldList.forEach((field) => {
      const hasParent = parentField !== undefined;
      const newField = { ...field };

      if (fieldIsType(field, 'Multiple Choice')) {
        newField.value = this.mapFieldOptions(field);
      } else if (
        fieldIsType(field, 'Dropdown') &&
        !fieldIsType(field, 'Sex', 'display_name')
      ) {
        newField.value = this.getDefaultDropdownValue(field);
      } else if (this.allowsInput(field)) {
        newField.value = '';
        newField.validationMessage = undefined;
      }

      if (hasParent) newField.parent = parentField;
      fields[field.uuid] = newField;
      if (field.fields)
        fields = this.loadFieldsToState(fields, field.fields, field);
    });

    return fields;
  };

  handleUpdate = (id, value) => {
    const fields = { ...this.state.fields };
    localStorage.setItem('isDirty', 'yes');

    fields[id].value = value;

    this.setState(
      {
        fields,
      },
      () => {
        if (this.state.submissionAttempted) {
          const inputs = this.getFormInputs();
          this.validateAllInputs(inputs);
          return;
        }
      }
    );
  };

  handleChange = (
    e,
    forceValidation,
    requiredOverride = false,
    isEdit = true
  ) => {
    const { id } = e.target;
    let { value } = e.target;
    const { otherEl } = e.target; // will be undefined if this is NOT an "Other" type input as part of "Single Choice" and "Multiple Choice"
    const fields = { ...this.state.fields }; // better way to spread this to extract the known key (id) from the fields object?
    isEdit && window.sessionStorage.setItem('isDirty', 'yes');

    // multiple choice field value needs to be in the shape of an array of key<string>:value<bool> objects
    // then update the single value being changed
    if (fieldIsType(fields[id], 'Multiple Choice')) {
      const values = [...fields[id].value];
      const valueIndex = values.findIndex(
        (val) => Object.keys(val)[0] === Object.keys(value)[0]
      );

      // if we couldn't find the value to update, don't try to update state
      if (valueIndex === -1) return;

      values[valueIndex] = value;
      value = values;
    }

    if (fieldIsType(fields[id], 'Document Upload')) {
      value = "";
      // e.target.files IS NOT A FILELIST it is an array of files
      e.target.files.forEach(file => {
        if (file == e.target.files[0]) value += `${file.name}`;
        else value += `|${file.name}`;
      });
    }

    // force DOM element checkbox/radio to be checked
    // TODO: figure out using refs here for more 'standard' DOM manipulation
    if (otherEl)
      otherEl.checked =
        (typeof value === 'object' ? Object.values(value)[0] : value) !== false;

    // if (fieldIsType(fields[id], 'Optional')) {
    //   fields[id].option_value = value;
    // } else {
    //   fields[id].value = value;
    // }
    fields[id].value = value;

    this.setState(
      {
        fields,
      },
      () => {
        if (this.state.submissionAttempted) {
          const inputs = this.getFormInputs();
          this.validateAllInputs(inputs);
          return;
        }

        forceValidation && this.handleBlur(e, requiredOverride);
      }
    );
  };

  setDisplayModeForElements = (elements, displayMode) => {
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].style.display = displayMode;
      }
    }
  };

  printToPDF = async (pageToPrint) => {
    const currentPagePrinted = this.state.pagePrinted;
    if (currentPagePrinted.includes(pageToPrint)) {
      return;
    } else {
      this.setState((preState) => ({
        pagePrinted: [...preState.pagePrinted, pageToPrint],
      }));
      const blockToPrint = document.querySelector('#form-print-pdf');
      if (blockToPrint) {
        blockToPrint.style.width = '1024px';
        blockToPrint.style.maxWidth = '1024px';
        const formFieldFlex = blockToPrint.getElementsByClassName('flex');
        for (let i = 0; i < formFieldFlex.length; i += 1) {
          formFieldFlex[i].style.flexDirection = 'row';
        }

        const listInputTime =
          blockToPrint.querySelectorAll('.time-picker > div');

        listInputTime.forEach((ele) => {
          const select = ele.querySelector('select');
          const replaceElement = document.createElement('input');
          replaceElement.value = select.value;
          select.style.display = 'none';
          ele.prepend(replaceElement);
        });

        const listInput = blockToPrint.querySelectorAll('input');
        const listDiv = blockToPrint.querySelectorAll('div');
        const listSelect = blockToPrint.querySelectorAll('select');
        const replaceSelect = blockToPrint.querySelectorAll('.replace-select');

        listSelect.forEach((s) => {
          s.style.display = 'none';
        });

        replaceSelect.forEach((r) => {
          r.style.display = 'block';
        });

        listInput.forEach((input) => {
          input.style.fontWeight = '700';
          input.style.paddingTop = '5px';
        });

        listDiv.forEach((div) => {
          div.style.fontWeight = '900';
        });

        const listWrapTextarea = blockToPrint.querySelectorAll(
          '.form-field-input.textarea > div'
        );

        listWrapTextarea.forEach((wrapper) => {
          const textArea = wrapper.querySelector('textarea');
          const replaceElement = document.createElement('div');
          replaceElement.innerHTML = textArea.innerHTML;
          replaceElement.className = 'replace-textarea';
          textArea.style.display = 'none';
          wrapper.prepend(replaceElement);
        });

        const buttonSubmit =
          blockToPrint.getElementsByClassName('form-field-submit');
        const buttonNext = blockToPrint.getElementsByClassName('pull-right');
        const buttonPrevious = blockToPrint.getElementsByClassName('pull-left');
        const formFieldFooter =
          blockToPrint.getElementsByClassName('form-field-footer');

        this.setDisplayModeForElements(buttonSubmit, 'none');
        this.setDisplayModeForElements(buttonNext, 'none');
        this.setDisplayModeForElements(buttonPrevious, 'none');
        this.setDisplayModeForElements(formFieldFooter, 'none');

        const formFieldRendered = blockToPrint.querySelectorAll(
          '.form-field.rendered:not(.nested)'
        );
        let formFieldRenderedFrom = 0;
        let formFieldRenderedTo = formFieldRendered.length - 1;

        const pageWidth = this.pdfTool.internal.pageSize.getWidth();
        const pageHeight = this.pdfTool.internal.pageSize.getHeight();
        const canvasWidthInit = blockToPrint.offsetWidth;
        const ratioInit = pageWidth / canvasWidthInit;

        while (formFieldRenderedFrom < formFieldRendered.length) {
          this.setState((prevState) => ({
            ...prevState,
            pageNumber: ++prevState.pageNumber,
          }));
          for (let i = 0; i < formFieldRendered.length; i++) {
            formFieldRendered[i].style.display =
              i < formFieldRenderedFrom ? 'none' : 'flex';
          }
          while (
            blockToPrint.offsetHeight * ratioInit > pageHeight &&
            formFieldRenderedTo > formFieldRenderedFrom
          ) {
            formFieldRendered[formFieldRenderedTo].style.display = 'none';
            formFieldRenderedTo--;
          }

          const canvas = await html2canvas(blockToPrint, {
            useCORS: true,
            allowTaint: true,
            logging: false,
            width: blockToPrint.offsetWidth + 10,
            height: blockToPrint.offsetHeight + 10,
            scrollY: -window.scrollY,
            scale: 1,
          });
          const imgData = canvas.toDataURL('image/png');
          const ratio = pageWidth / canvas.width;
          const canvasHeight = canvas.height * ratio;

          if (currentPagePrinted.length > 0 || formFieldRenderedFrom > 0) {
            this.pdfTool.addPage('letter', 'p');
            this.pdfTool.addImage(
              imgData,
              'PNG',
              2,
              0,
              pageWidth,
              canvasHeight - 10,
              undefined,
              'MEDIUM'
            );
          } else {
            this.pdfTool.addImage(
              imgData,
              'PNG',
              2,
              0,
              pageWidth,
              canvasHeight - 10,
              undefined,
              'MEDIUM'
            );
          }

          this.pdfTool.setFontSize(10);
          this.pdfTool.text(
            this.state.pageNumber.toString(),
            pageWidth - 25,
            pageHeight - 15
          );

          formFieldRenderedFrom = formFieldRenderedTo + 1;
          formFieldRenderedTo = formFieldRendered.length - 1;
        }

        listInputTime.forEach((ele) => {
          const select = ele.querySelector('select');
          const replaceElement = ele.querySelector('input');
          select.style.display = 'inline-block';
          ele.removeChild(replaceElement);
        });

        listSelect.forEach((s) => {
          s.style.display = 'block';
        });

        replaceSelect.forEach((r) => {
          r.style.display = 'none';
        });

        listInput.forEach((input) => {
          input.style.paddingTop = '0';
        });

        listWrapTextarea.forEach((wrapper) => {
          const replaceElement = wrapper.querySelector('.replace-textarea');
          const textArea = wrapper.querySelector('textarea');
          textArea.style.display = 'inline-block';
          wrapper.removeChild(replaceElement);
        });

        this.setDisplayModeForElements(buttonSubmit, 'block');
        this.setDisplayModeForElements(buttonNext, 'block');
        this.setDisplayModeForElements(buttonPrevious, 'block');
        this.setDisplayModeForElements(formFieldFooter, 'block');
        this.setDisplayModeForElements(formFieldRendered, 'flex');

        for (let i = 0; i < formFieldFlex.length; i += 1) {
          formFieldFlex[i].style.flexDirection = '';
        }
        blockToPrint.style.width = '';
        blockToPrint.style.maxWidth = '';
      }
    }
  };

  handleSubmit = async (e) => {
    try {
      this.setState({ submitLoading: true });
      const inputs = this.getFormInputs();
      const { formVersion } = this.state;
      const submissionObject = this.getSubmissionObject(inputs);
      const getValue = (key, condition, group) => {
        for (let i = 0; i < submissionObject.length; i += 1) {
          if (group) {
            if (
              submissionObject[i].group_name === group &&
              submissionObject[i][key] === condition
            ) {
              return submissionObject[i].value;
            }
          } else if (submissionObject[i][key] === condition) {
            return submissionObject[i].value;
          }
        }
        return '';
      };

      const getContactName = () => {
        const firstName = getValue('column_name', 'FirstName', 'Contact Name');
        const lastName = getValue('column_name', 'LastName', 'Contact Name');
        if (firstName.length && lastName.length) {
          return `${firstName} ${lastName}`;
        } else if (firstName.length) {
          return firstName;
        } else if (lastName.length) {
          return lastName;
        }
        return '';
      };

      const uploadFiles = async () => {
        const docUploadFields = Object.values(this.state.fields).filter(field => field.type_name == "Document Upload");
        if (docUploadFields.length == 0) return;
        const fileNames = docUploadFields[0].value;
        const fileLinks = [];
        if (fileNames != "") {
          for (const file of this.state.files) {
            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('fileName', file.name.replace(/\.[^/.]+$/, ""));
            formData.append('micrositeName', 'micrositeName',
              JSON.parse(
                window.sessionStorage.getItem('tenantInfo--full_response') || '{}'
              ).micrositeName);
            const response = await configuredRequests.POST.submitPDF(formData);
            fileLinks.push(get(response, 'data.url', ''));
          }
        }
        return fileLinks;
      };

      const pdfFileName = `${this.props.fullData.formName}_${
        getContactName() === ''
          ? 'ContactNameNotProvided'
          : getContactName()
      }`.replace(/[\/ ]/g, '-');

      let link = '';

      this.setState({ submissionAttempted: true });
      await this.validateAllInputs(inputs);

      if (this.state.formErrors) {
        this.setState({ loading: false });
        return;
      } else {
        /*const { page } = this.state;
        await this.printToPDF(page);
        const file = this.pdfTool.output('blob');
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('fileName', pdfFileName);
        formData.append(
          'micrositeName',
          JSON.parse(
            window.sessionStorage.getItem('tenantInfo--full_response') || '{}'
          ).micrositeName
        );
        this.setState((prev) => ({
          ...prev,
          pagePrinted: [],
          pageNumber: 0,
        }));
        this.pdfTool = new jsPDF('p', 'px', 'letter', true);
        const response = await configuredRequests.POST.submitPdf(formData);
        link = get(response, 'data.url', '');*/
      }

      const submission = this.mapSafeAttributes('submission', {
        ...formVersion,
        object: submissionObject,
      });

      let fileLinks = await uploadFiles();

      const payloadSubmitSubmisson = {
        object: submission.object,
        formVersionId: this.props.fullData ? this.props.fullData.versionId : '',
        appointmentTypeId: getValue(
          'legwork_column_name',
          'appointment_type_id'
        ),
        providerId: getValue(
          'legwork_column_name',
          'appointment_booking_provider_id'
        ),
        startDateTime: getValue('legwork_column_name', 'appointment_time'),
        contactName: getContactName(),
        emailAddress: getValue(
          'column_name',
          'Email Address',
          'Contact Email Address'
        ),
        phoneNumber: getValue(
          'column_name',
          'PhoneNumber',
          'Contact Phone Number'
        ),
        petName: getValue('column_name', 'Name'),
        submittedDate: new Date(),
        petId: null,
        contactId: null,
        id: this.props.submissionId || null,
        fileLinks,
      };

      const response = await configuredRequests.POST.updateFormSubmission(
        payloadSubmitSubmisson
      );

      if (response) {
        this.setState({ submitLoading: false });
        this.props.handleSubmitSuccess &&
          this.props.handleSubmitSuccess(response.data.link, submission.object);
        Toast.success('Your data has been saved successfully!');
        window.sessionStorage.setItem('isDirty', 'no');
      }
    } catch (error) {
      this.setState({ submitLoading: false });
      Toast.error(
        error?.response?.data?.message ??
          (error?.message || ErrorMessages.FORM_SUBMISSION_ERROR)
      );
    }
  };

  handlePageChange = async (page) => {
    if (this.props.submissionId) {
      this.setState({ submitLoading: true });
      if (this.state.pagePrinted.includes(page - 1)) {
        this.setState({ page, submitLoading: false });
      } else {
        // await this.printToPDF(page - 1);
        this.setState({ page, submitLoading: false });
      }
    } else {
      this.setState({ page });
    }
  };

  handleBlur = (e, requiredOverride = false, uuid) => {
    let field = this.getFieldFromTarget(e?.target?.id || uuid);

    field.value = field.value || e?.target?.value;
    if (typeof field.value === 'string') {
      field.value = field.value?.trim();
    }

    if (!isRequired(field) && field.parent && !requiredOverride) {
      field = field.parent;
      field.value = this.getValue(field);
    }

    let validation = validateField(field, requiredOverride);
    const updatedFields = { ...this.state.fields };

    if (fieldIsType(field, 'Appointment Booking', 'group_name')) {
      validation = undefined;
    }

    // validation will be undefined if no errors
    updateFieldValidation(updatedFields, field, validation); // is a mutator;

    this.setState({
      fields: updatedFields,
      formErrors: this.getFormErrorCount(updatedFields),
    });
  };

  getFormInputs = () => {
    const inputs = {};

    Object.keys(this.state.fields).reduce((acc, cur) => {
      const field = this.state.fields[cur];
      if (this.allowsInput(field)) inputs[cur] = field;
      return inputs;
    }, {});

    return inputs;
  };

  getSubmissionObject = (inputs) => {
    inputs = inputs || this.getFormInputs();
    const fields = [];

    Object.keys(inputs).reduce((acc, cur) => {
      const field = inputs[cur];
      if (field?.uuid_option || (field?.parent && field?.parent?.uuid_option)) {
        const optionalField = field?.uuid_option
          ? inputs[field?.uuid_option]
          : inputs[field?.parent?.uuid_option];
        const { options } = getFieldPropertyValues(
          getFieldProperties(optionalField, ['specific']).specific,
          'options'
        );
        const ofOption = field?.uuid_option
          ? field.of_option
          : field?.parent?.of_option;
        if (
          ofOption !== options.findIndex((item) => item === optionalField.value)
        ) {
          field.value = '';
        }
      }
      fields.push(this.mapSafeAttributes('object', field));
      return fields;
    }, fields);

    return fields;
  };

  fieldIsVisibleOnForm = (field) => {
    if (
      field.type_name === 'Single Choice' ||
      field.type_name === 'Multiple Choice'
    ) {
      return !!document.getElementsByName(field.uuid);
    }
    return !!document.getElementById(field.uuid);
  };

  validateAllInputs = (inputs) => {
    return new Promise((resolve, reject) => {
      const updatedFields = { ...this.state.fields };

      Object.keys(inputs).forEach((key) => {
        let input = inputs[key];
        const skipParentValidation =
          input.parent &&
          (fieldIsType(input.parent, 'Section Break', 'type_name') ||
            fieldIsType(input.parent, 'Appointment Booking', 'display_name') ||
            fieldIsType(input.parent, 'Optional', 'display_name'));

        if (input.parent && !skipParentValidation) {
          input = input.parent;
          input.value = this.getValue(input);
        }

        // force all appointment booking fields as required
        // ** if the field is visible on the form **
        const requiredOverride =
          input.parent &&
          input.parent.display_name === 'Appointment Booking' &&
          this.fieldIsVisibleOnForm(input);
        const validation = validateField(input, requiredOverride);

        updatedFields[input.uuid].validationMessage = validation;
      });

      this.setState(
        {
          fields: updatedFields,
          formErrors: this.getFormErrorCount(updatedFields),
        },
        () => (this.state.formErrors > 0 ? reject() : resolve())
      );
    });
  };

  getFieldFromTarget = (id) => {
    return this.state.fields[id];
  };

  getFormErrorCount = (fields) => {
    fields = fields || this.state.fields;
    Object.keys(fields).forEach((key) => {
      let field = fields[key];
      if (field?.parent?.display_name === 'Optional') {
        const parentField = fields[field.parent.uuid];
        const { options } = getFieldPropertyValues(
          getFieldProperties(parentField, ['specific']).specific,
          'options'
        );
        const idxOption = options.findIndex(
          (item) => item === parentField.value
        );
        if (idxOption !== field.of_option) {
          field.validationMessage = '';
        }
      }
    });
    const errorCount = Object.keys(fields).reduce((acc, curKey) => {
      if (
        fields[curKey].hasOwnProperty('validationMessage') &&
        fields[curKey].validationMessage
      )
        acc++;
      return acc;
    }, 0);
    return errorCount;
  };

  getValue = (field) => {
    const safeField = this.safeField(field);

    if (fieldIsType(safeField, 'Section Break') && !hasSubfields(safeField))
      return;

    return this.getFieldValues(safeField);
  };

  getFieldAttribute = (field, attribute) => this.safeField(field)[attribute];

  safeField = (field) => {
    return this.state.fields[field.uuid] || {};
  };

  allowsInput = (field) => {
    if (field.display_name === 'Optional') {
      return true;
    }
    return (
      !['Section Break', 'Page Break', 'Submit'].includes(field.type_name) &&
      !field.hasOwnProperty('fields')
    );
  };

  mapFieldOptions = (field) => {
    const properties = getFieldProperties(field, 'specific');
    const propValues =
      getFieldPropertyValues(properties.specific, [
        'options',
        'displayOther',
        'other',
      ]) || [];
    const options = [...propValues.options];
    if (propValues.displayOther) options.push(propValues.other);
    return options.map((key) => ({ [`${key}`]: false }));
  };

  mapSubfields = (stateField) => {
    const { fields } = this.state;
    return stateField.fields.map(
      (subfield) => fields[subfield.uuid || subfield.id].value
    );
  };

  getFieldValues = (safeField) => {
    // if field has no subfields, then return the field's value
    // otherwise recurse through subfields
    if (!hasSubfields(safeField)) return safeField.value;
    return safeField.fields.map((subfield) =>
      this.getFieldValues(this.safeField(subfield))
    );
  };

  handlePageFields = () => {
    if (this.handlingFirstPage()) return this.currentPageFilter();
    if (this.formVersionHasPages()) return this.nextPageFilter();
    return this.updatedFormVersionFields();
  };

  updatedFormVersionFields = (formVersion = this.state.formVersion) => {
    if (formVersion && formVersion.object && formVersion.object.fields) {
      const fieldClone = deepClone(formVersion.object);

      updateFieldsFromCurrentState(fieldClone, this.state.fields);

      if (this.state.pages.length <= 0) return fieldClone.fields;
      if (this.state.page === 1) return this.currentPageFilter();
      return this.nextPageFilter();
    }
    return [];
  };

  formVersionFields = (formVersion = this.state.formVersion) => {
    return (
      (formVersion && formVersion.object && formVersion.object.fields) || []
    );
  };

  handlingFirstPage = () => {
    const { page } = this.state;
    return this.formVersionHasPages() && page === 1;
  };

  formVersionHasPages = () => {
    const { pages, formVersion, page } = this.state;
    return formVersion && pages && page - pages.length > 0 && pages.length > 0;
  };

  currentPageFilter = () => {
    const { pages, page } = this.state;
    return this.formVersionFields().filter(
      (field, index) => index <= pages[page - 1]
    );
  };

  nextPageFilter = () => {
    const { pages, page } = this.state;
    return this.formVersionFields().filter(
      (field, index) =>
        index > pages[page - 2] && this.lastPageFilter(pages, page, index)
    );
  };

  lastPageFilter = (pages, page, index) => {
    if (pages[page - 1]) return index <= pages[page - 1];
    return true;
  };

  setFieldProps = (field) => {
    const { pages, page, formErrors, formVersion, files } = this.state;
    const { readOnly, allowSubmit, isPreview } = this.props;
    const isReview = !allowSubmit && !isPreview;

    const props = {
      uuid: field.uuid,
      field,
      isRenderedField: true,
      onChange: this.handleChange,
      onUpdate: this.handleUpdate,
      onBlur: this.handleBlur,
      value: this.getValue(field),
      validationMessage: this.getFieldAttribute(field, 'validationMessage'),
      readOnly: readOnly || isReadOnly(field) || (isHidden(field) && isPreview),
      isPreview,
      isReview,
    };

    // the appointment booking field needs the account id in order to properly send API requests
    if (field.type_name === 'Submit') {
      props.onFieldClick = (e) => this.handleSubmit(e);
      props.pageNum = page;
      props.displayPrevious = page > 1;
      props.onPageChange = (page) => this.setState({ page });
      // props.onPageChange = this.handlePageChange;
      props.disabled = formErrors > 0 || !allowSubmit;
    } else if (field.type_name === 'Page Break') {
      props.pageNum = page;
      props.displayPrevious = page > 1;
      props.displayNext = page <= pages.length;
      props.onPageChange = this.handlePageChange;
      props.onPreviousPage = (page) => this.setState({ page });
    }

    if (field.type_name === 'Document Upload') {
      props.files = files;
      props.setFiles = (files) => this.setState({ files });
      props.submitted = this.hasProp('submission');
    }

    return props;
  };

  canSave = () => {
    return (
      this.hasProp('saveFlag') && this.hasProp('onSave') && this.props.saveFlag
    );
  };

  handleGetSubmission = () => {
    this.setState({ isSaving: false });

    // get all input fields
    const inputs = this.getFormInputs();

    // validate fields before allowing submission
    this.validateAllInputs(inputs)
      .then(() =>
        this.props.onSave(undefined, this.getSubmissionObject(inputs))
      )
      .catch(() => this.props.onSave(true));
  };

  isRedirect = () => (this.state.formVersion || {}).is_redirect;

  shouldDisplayThankYou = () => this.state.submitted && !this.isRedirect();

  shouldRedirect = () => this.state.submitted && this.isRedirect();

  handleAcknowledgeError = () => {
    if (this.state.errorConfirmationAction) {
      this.state.errorConfirmationAction();
    } else {
      this.setState({ dataProcessingError: null });
    }
  };

  handleRedirect = () =>
    (window.location =
      this.state.formVersion && this.state.formVersion.redirect_url);

  getDefaultDropdownValue = (field) => {
    const { options } =
      getFieldPropertyValues(
        getFieldProperties(field, 'specific').specific,
        'options'
      ) || [];
    return options && options.length > 0 ? options[0] : '';
  };

  render() {
    const { id, referer } = this.props;
    const { formVersion, formErrors, dataProcessingError } = this.state;
    const { VALIDATION_ERRORS } = Messages;

    if (this.shouldDisplayThankYou())
      return (
        <ThankYou
          message={formVersion && formVersion.thank_you}
          referer={referer}
        />
      );
    if (this.shouldRedirect()) return this.handleRedirect();
    if (dataProcessingError)
      return (
        <ErrorContainer
          message={dataProcessingError}
          onClick={this.handleAcknowledgeError}
        />
      );

    const pageFields = this.handlePageFields();

    return (
      <>
        {this.state.submitLoading && (
          <div className='container-loading'>
            <Loading className='loading-spinner' />
          </div>
        )}
        <EditContext.Provider
          value={{
            edit: !!this.props.allowSubmit,
            isAppointmentBooking: this.props.isFormAppointmentBooking,
          }}
        >
          <div id='form-print-pdf'>
            <form id={id} className={this.props.readOnly && 'form--read-only'}>
              {pageFields.map((field) => {
                const props = this.setFieldProps(field);
                return (
                  <div key={field.uuid || field.id}>
                    {getComponent(field, props)}
                  </div>
                );
              })}
              {formErrors > 0 && (
                <div className='control-group error'>
                  <span className='help-block'>
                    {VALIDATION_ERRORS.replace('{{count}}', formErrors)}
                  </span>
                </div>
              )}
              {!formVersion && <h3>{`Loading Legwork form ${id}. . .`}</h3>}
            </form>
          </div>
        </EditContext.Provider>
      </>
    );
  }
}

FormRenderer.defaultProps = {
  readOnly: false,
  saveFlag: false,
  allowSubmit: true,
  isPreview: false,
};

const isRequired = (field) => {
  return (
    getFieldPropertyValues(
      getFieldProperties(field, 'common').common,
      'makeRequired'
    ).makeRequired === true
  );
};

const isReadOnly = (field) => {
  return (
    getFieldPropertyValues(
      getFieldProperties(field, 'common').common,
      'readOnly'
    ).readOnly === true
  );
};

const isHidden = (field) => {
  return (
    getFieldPropertyValues(
      getFieldProperties(field, 'common').common,
      'makeHidden'
    ).makeHidden === true
  );
};

const updateFieldValidation = (updatedFields, field, validation) => {
  updatedFields[field.uuid].validationMessage = validation;
};

const updateFieldsFromCurrentState = (outdatedFields, fieldDictionary) => {
  if (!outdatedFields || !outdatedFields.fields) {
    return;
  }
  for (let i = 0; i < outdatedFields.fields.length; i++) {
    outdatedFields.fields[i] =
      fieldDictionary[outdatedFields.fields[i].uuid] ||
      outdatedFields.fields[i];
    updateFieldsFromCurrentState(outdatedFields.fields[i], fieldDictionary);
  }
};

const deepClone = (el) => {
  if (typeof el !== 'object') {
    return el;
  }
  if (Array.isArray(el)) {
    return el.map((element) => deepClone(element));
  }
  for (const key in el) {
    if (key === 'fields') {
      el[key] = deepClone(el[key]);
    }
  }
  return { ...el };
};

const ErrorContainer = ({ message, onClick }) => (
  <div style={{ width: '100%' }}>
    <h3 style={{ textAlign: 'center' }}>Oops, something went wrong :(</h3>
    <p style={{ textAlign: 'center' }}>{message}</p>
    <p style={{ textAlign: 'center' }}>{ErrorMessages.CONTACT_US}</p>
    <button
      className='btn btn-primary'
      onClick={onClick}
      style={{ marginLeft: '50%', width: '50px' }}
    >
      {' '}
      OK{' '}
    </button>
  </div>
);
