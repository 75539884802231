import React from 'react';

// use this hook to track the element with focus

export const useActiveElement = () => {
  const [active, setActive] = React.useState(document.activeElement);

  const handleFocusIn = (event: FocusEvent) => {
    // console.log('focus event on', document.activeElement);
    setActive(document.activeElement);
  }

  React.useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, [])

  return active;
};
