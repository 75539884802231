import * as t from 'io-ts';

// PetInfoResponse
const RequiredPetInfoData = t.type({
  petId: t.string,
  name: t.string,
  guardians: t.array(t.string),
});
const NullablePetInfoData = t.partial({
  externalId: t.string,
  species: t.string,
  breed: t.string,
  birthDate: t.string,
  neuteredSpayed: t.boolean,
  sex: t.string,
  status: t.string
});
export const FullPetInfoResponse = t.intersection([
  RequiredPetInfoData,
  NullablePetInfoData,
]);
export type PetInfoResponse = t.TypeOf<typeof FullPetInfoResponse>;
export const PetSummaryCollection = t.type({
  count: t.number,
  pets: t.array(FullPetInfoResponse),
});
export type PetSummaryCollectionResponse = t.TypeOf<
  typeof PetSummaryCollection
>;
//
