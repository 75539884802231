import React from 'react';
import NavigationLinkElement from './NavLinkElement';
import { NavigationElementWithIcons } from 'types/NavigationT';
import { useRouteMatch } from 'react-router-dom';
import { isNavLinkActive } from './TopLevelContainer';

interface NavigationTopLevelLinkProps extends NavigationElementWithIcons {
  linkTo: string,
  innerText: string,
  navElementIndex: string,
  setSidebarIsOpen: (isOpen: boolean) => void,
}

const NavigationTopLevelLink = (props: NavigationTopLevelLinkProps) => {

  const match = useRouteMatch();

  return (
    <>
      <NavigationLinkElement
        linkTo={props.linkTo}
        innerText={props.innerText}
        key={"sidebar-top-level-link-" + props.navElementIndex}
        leftIcon={props.leftIcon}
        rightIcon={props.rightIcon}
        customClasses={[
          "sidebar__top-level"
        ]}
        isNavLinkActive={isNavLinkActive(props.linkTo, match.path)}
        setSidebarIsOpen={props.setSidebarIsOpen}
      />
    </>
  )
}

export default NavigationTopLevelLink
