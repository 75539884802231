import React, { useState, useEffect } from 'react';
import WithComponent from 'components/Wrappers/WithComponentClassAndHeaderText';
import Telemedicine from 'components/Telemedicine/Telemedicine';
import RightModal from 'components/Global/TeleMed/RightModal';
import { SystemSettingResponse } from 'global/requests/ResponseTypes';
import Modal from '@material-ui/core/Modal';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';
import { Button } from '@material-ui/core';

const ComponentizedTelemedicine = WithComponent(Telemedicine, '', 'handlecss');
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const TelemedicineContainer = () => {
  const [displayTelemedModal, setDisplayTelemedModal] = React.useState(false);
  const [telemedModalAppointmentData, setTelemedModalAppointmentData] =
    React.useState<AppointmentSummaryEntry | undefined>();
  const [isAlreadyInCall, setIsAlreadyInCall] = React.useState<boolean>(false);
  const [openModalError, setOpenModalError] = React.useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const [modalStyle] = React.useState(getModalStyle);

  const closeModalError = () => setOpenModalError(false);

  const [systemSetting, setSystemSetting] =
    React.useState<SystemSettingResponse>();

  const getSystemSetting = async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res) {
        setSystemSetting(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSystemSetting();
  }, []);

  const settingsTimezoneData = systemSetting
    ? systemSetting.settings.timeZoneOffset
    : '';

  return (
    <>
      <Modal
        open={openModalError}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        onBackdropClick={closeModalError}
      >
        <div style={modalStyle} className='insert-modal-style'>
          <div className='modal-error-body'>
            <p>
              You have an appointment that is currently in-progress. Please end
              it to start a new appointment!
            </p>
            <div className='button-group'>
              <Button
                variant='contained'
                color='primary'
                className='btn-insert'
                onClick={() => {
                  setOpenModalError(false);
                }}
              >
                {' '}
                OK{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {telemedModalAppointmentData !== undefined ? (
        <RightModal
          appointmentInfo={telemedModalAppointmentData}
          displayTelemedModal={displayTelemedModal}
          setDisplayTelemedModal={setDisplayTelemedModal}
          labelText='Label Text Non-Default'
          isAlreadyInCall={isAlreadyInCall}
          setOpenModalError={setOpenModalError}
          setReload={setReload}
          settingsTimezoneData={settingsTimezoneData || ''}
        />
      ) : null}

      <ComponentizedTelemedicine
        setDisplayTelemedModal={setDisplayTelemedModal}
        setTelemedModalAppointmentData={setTelemedModalAppointmentData}
        setIsAlreadyInCall={setIsAlreadyInCall}
        isAlreadyInCall={isAlreadyInCall}
        setOpenModalError={setOpenModalError}
        setReload={setReload}
        reload={reload}
        settingsTimezoneData={settingsTimezoneData || ''}
      />
    </>
  );
};

export default TelemedicineContainer;
