/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import StatusSelect from '../Global/StatusSelect/StatusSelect';
import SearchAndFilter from '../Global/SearchAndFilter/SearchAndFilter';
import { OptionFilterDropdownObject } from 'global/requests/ResponseTypes';
import { appointmentIdPattern } from '../../utils/regex';
import moment from 'moment';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { GetCurrentTenantAppointmentInRangeArguments } from 'global/requests/RequestTypes';
import PopupWindowWithHooks from "../../hooks/usePopupWindow";
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import { AppointmentSummaryEntry, AppointmentMultipleInfoResponse } from 'global/requests/ResponseTypes/Appointments';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { cloneDeep, debounce } from 'lodash';
import { isWindows } from 'react-device-detect';
import { Modal } from '@material-ui/core';
import loading_spinner from 'media/images/loading-spinner.svg'

type TelemedicineAppointmentField = {
  children?: {
    before?: JSX.Element[],
    after?: JSX.Element[]
  },
  customClass?: string
  innerText?: string,
  petName?: JSX.Element;
};

const renderAppointmentEntry = (telemedicineAppointmentFields: TelemedicineAppointmentField[], rowIndex: number) => {
  return (
    <tr key={"telemed__entry-" + rowIndex}
      className="telemed__entry-row"
    >
      {
        telemedicineAppointmentFields.map((telemedicineAppointmentFields, sectionIndex) => {
          return (
            <td className={"" + (telemedicineAppointmentFields.customClass ?? "")}
              key={"telemed-row-" + rowIndex + "-section-" + sectionIndex}
            >
              {(
                telemedicineAppointmentFields.children?.before
                  ? telemedicineAppointmentFields.children?.before
                  : null)
              }

              {telemedicineAppointmentFields.innerText}

              {telemedicineAppointmentFields.petName}

              {(
                telemedicineAppointmentFields.children?.after
                  ? telemedicineAppointmentFields.children?.after
                  : null)
              }
            </td>
          );
        })
      }
    </tr>
  );
};

const optionsForFilter = [
  { value: '', label: 'Upcoming Appointments' },
  { value: 'complete', label: 'Past Appointments' }
];

interface TelemedicineAppointmentProps {
  setDisplayTelemedModal: React.Dispatch<React.SetStateAction<boolean>>,
  setTelemedModalAppointmentData: React.Dispatch<React.SetStateAction<AppointmentSummaryEntry | undefined>>,
  setIsAlreadyInCall(a: boolean): void
  isAlreadyInCall?: boolean
  setOpenModalError?(a: boolean): void
  setReload?(a: boolean): void
  reload?: boolean
  settingsTimezoneData: string,
};

const Telemedicine = (props: TelemedicineAppointmentProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showNewWindow, setShowNewWindow] = useState<boolean>(false);
  const [newWindowUrl] = useState<string>("");
  const [visibleAppointmentInfo, setVisibleAppointmentInfo] = React.useState<AppointmentMultipleInfoResponse>();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedDataFilter, setSelectedDataFilter] = React.useState<{ value: string, label: string }>({...optionsForFilter[0]});
  const [currentApointment] = useState<any>()
  const [isMaxAppointments, setIsMaxAppointments] = useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const onCloseNewWindow = () => {
    setShowNewWindow(false);
  };

  const defaultStartDate = moment(new Date().toISOString()).format("YYYY-MM-DD");
  const defaultEndDate = moment(new Date().toISOString()).add(7, "days").format("YYYY-MM-DD");

  const [upcomingAppointmentsArguments, setUpcomingAppointmentsArguments] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    isSearch: false,
    options: {
      kind: "telemed",
      isWithoutEndDate: true,
      limit: 50,
      offset: 0,
    },
  });

  const completedAppointmentsArguments: GetCurrentTenantAppointmentInRangeArguments = {
    startDate: moment(new Date().toISOString()).add(-7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date().toISOString()).add(-1, "days").format("YYYY-MM-DD"),
    options: {
      kind: "telemed",
      withStatus: 'complete'
    }
  };

  const getCurrentTenantAppointmentsInRange = async (startDate: string, endDate: string, options?: {
    userID?: string,
    limit?: number,
    offset?: number,
    kind?: string,
    withStatus?: string,
    withName?: string,
    isWithoutEndDate?: boolean
  },
    isSearch?: boolean) => {
    setIsLoading(true);
    setIsWaiting(true);
    try {
      const res = await configuredRequests.GET.currentTenantMultipleAppointmentsInRange(startDate, endDate, {
        kind: "telemed",
        ...options
      });
      setIsLoading(false);
      if (isSearch) {
        setVisibleAppointmentInfo(res);
      } else if (res.count === 0) {
        setIsMaxAppointments(true);
      } else {
        setVisibleAppointmentInfo((preState: any) => {
          if (preState?.appointments) {
            const lastAppointmentPreState = preState?.appointments[preState?.appointments?.length - 1];
            const lastAppointmentPreStateDateTime = lastAppointmentPreState[0].dateTime;
            if (moment(lastAppointmentPreStateDateTime).isSame(moment(res.appointments[0][0].dateTime), 'date')) {
              const appointmentsData = cloneDeep(res.appointments);
              const newPreStateAppoinments = cloneDeep(preState.appointments);
              const sameDateAppointments = appointmentsData.shift();
              const preStateAppointmentsData = newPreStateAppoinments.pop();
              //@ts-ignore
              const newAppointment = [...sameDateAppointments, ...preStateAppointmentsData];
              return ({
                ...res,
                appointments: [
                  ...newPreStateAppoinments, newAppointment, ...appointmentsData
                ]
              });
            }
          }
          return ({ ...res, appointments: preState?.appointments ? [...preState.appointments, ...res.appointments] : [...res.appointments] });
        });
      }
      setIsWaiting(false);
    } catch (err) {
      setIsLoading(false);
      setIsWaiting(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, [])

  const scrollToBottom = useCallback(() => {
    if (!isMaxAppointments && !isWaiting) {
      setUpcomingAppointmentsArguments((preState: any) => ({ ...preState, options: { ...preState.options, offset: preState.options.offset + 1 } }));
      getCurrentTenantAppointmentsInRange(
        upcomingAppointmentsArguments.startDate,
        upcomingAppointmentsArguments.endDate,
        {
          ...upcomingAppointmentsArguments.options,
          offset: upcomingAppointmentsArguments.options?.offset ? upcomingAppointmentsArguments.options.offset + 1 : 1,
          withName: searchValue,
          withStatus: selectedDataFilter.value,
        }
      );
    }
  }, [getCurrentTenantAppointmentsInRange, isMaxAppointments]);

  const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);

  const checkIncall = async () => {
    try {
      const res = await configuredRequests.GET.checkIncall();
      if (res) {
        props.setIsAlreadyInCall(res.isInCall);
      }
    } catch (err) {
      props.setIsAlreadyInCall(false);
    }
  }

  const handleSubmitAppointmentGet = async (getDailyAppointmentArguments: GetCurrentTenantAppointmentInRangeArguments) => {
    const { startDate, endDate, options } = getDailyAppointmentArguments;

    if (startDate && startDate.length && endDate && endDate.length) {
      getCurrentTenantAppointmentsInRange(startDate, endDate, {
        kind: "telemed",
        ...options
      });
      return;
    }
    //TODO remove alert in prod
    alert('error in date range');
  };

  // send request on first render
  React.useEffect(() => {
    getCurrentTenantAppointmentsInRange(
      upcomingAppointmentsArguments.startDate,
      upcomingAppointmentsArguments.endDate,
      upcomingAppointmentsArguments.options,
    );
  }, []);

  React.useEffect(() => {
    if (props.reload) {
      getCurrentTenantAppointmentsInRange(
        upcomingAppointmentsArguments.startDate,
        upcomingAppointmentsArguments.endDate,
        { ...upcomingAppointmentsArguments.options },
        true
      ).then(() => {
        if (props.setReload) {
          props.setReload(false)
        }
      });
    }
  }, [props.reload]);

  const searchByContactName = useCallback(debounce((name: any) => {
    if (name) {
      setUpcomingAppointmentsArguments(preState => ({
        ...preState,
        options: {
          ...preState.options,
          withName: name,
          withStatus: selectedDataFilter,
        }
      }))
      getCurrentTenantAppointmentsInRange(
        upcomingAppointmentsArguments.startDate,
        upcomingAppointmentsArguments.endDate,
        {
          ...upcomingAppointmentsArguments.options,
          withName: name,
          withStatus: selectedDataFilter.value,
          limit: 50,
          offset: 0,
        },
        true,
      );
    } else {
      getCurrentTenantAppointmentsInRange(
        upcomingAppointmentsArguments.startDate,
        upcomingAppointmentsArguments.endDate,
        {
          limit: 50,
          offset: 0,
          withStatus: selectedDataFilter.value,
        },
        true,
      );
    }
  }, 1000), [selectedDataFilter]);

  const handleSearchChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const userInput = event.currentTarget.value.trim();
    setSearchValue(userInput);
    searchByContactName(userInput);
  };

  const handleNewFilteredAppointmentRange = (newSelectedItem: OptionFilterDropdownObject) => {
    setSelectedDataFilter(newSelectedItem);
    getCurrentTenantAppointmentsInRange(
      upcomingAppointmentsArguments.startDate,
      upcomingAppointmentsArguments.endDate,
      {
        ...upcomingAppointmentsArguments.options,
        limit: 50,
        offset: 0,
        withStatus: newSelectedItem.value,
        withName: searchValue,
      },
      true,
    );
  };

  const showTitleDatetime = (data: AppointmentSummaryEntry[]) => {
    if (data.length && props.settingsTimezoneData) {
      const dateNeedToCompare = data[0].dateTime;
      const dateFollowTimezoneSetting = convertLocalTimeToAnotherTimeZone(props.settingsTimezoneData);
      const getFullDate = dateFollowTimezoneSetting.format('MM/DD/YY');

      if (dateFollowTimezoneSetting.isSame(moment(dateNeedToCompare), 'date')) {
        return {
          onlyDate: 'Today',
          fullDate: getFullDate
        };
      } else if ((moment(dateFollowTimezoneSetting), 'date') + 1 === (moment(dateNeedToCompare), 'date')) {
        return {
          onlyDate: 'Tomorrow',
          fullDate: getFullDate
        };
      } else {
        return {
          onlyDate: (moment(dateNeedToCompare).format('dddd')),
          fullDate: moment(dateNeedToCompare).format('MM/DD/YY')
        }
      }
    } else return {
      onlyDate: "",
      fullDate: ""
    };
  };

  const formatAppointmentId = (appointmentID: string) => {
    const result = appointmentID.match(appointmentIdPattern);
    if (result) {
      return result[1] + '-' + result[2] + '-' + result[3];
    };
    return "";
  };

  const renderAppointmentData = (appointments: AppointmentSummaryEntry[]) => {
    if (appointments.length) {
      const sortedAppointments = appointments.sort((a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime));
      if (sortedAppointments.length) {
        return appointments.map((appointment: AppointmentSummaryEntry, rowIndex: number) => {
          const contactData = (
            appointment?.attendees?.users?.length
              ? appointment.attendees.users[0]
              : undefined
          );

          const contactName = (
            contactData && contactData.name
              ? contactData.name
              : ""
          );

          const contactID = (
            contactData
              ? contactData.id
              : ""
          );

          const petData = appointment.patients ? (
            appointment.patients.pets.length
              ? appointment.patients.pets[0]
              : undefined
          ) : undefined;

          const petName = (petData && petData.name) ? <span>{petData.name}</span> : <span className="require">Missing Pet's Name</span>;

          const telemedKey = (
            appointment.teleMedKey
              ? appointment.teleMedKey
              : ""
          );

          const formattedTime24h = moment(appointment.dateTime).format("hh:mm A");

          const appointmentFieldConfigs: TelemedicineAppointmentField[] = [
            {
              innerText: formattedTime24h
            },

            {
              children: {
                before: [
                  <Link
                    key={"contact-link-" + contactID}
                    to={{
                      pathname: contactID ? '/Contacts/View/' + contactID : '',
                      state: {
                        fromTelemedicine: true
                      }
                    }}
                    title={`View ${contactName}'s full details in Contacts`}
                    className="lnk"
                  >
                    <HighlightText text={contactName} searchValue={searchValue} />
                  </Link>
                ]
              },
            },
            {
              petName: petName,
            },

            {
              children: {
                before: [
                  <StatusSelect
                    key={"status_select--" + appointment.appointmentId}
                    currentStatus={appointment.status}
                    // appointmentInformation={appointment}
                    id={appointment.appointmentId}
                    refreshOnSubmitStatusConfig={{
                      handleSubmitAppointmentGet,
                      getDailyAppointmentArguments: {
                        endDate: (!selectedDataFilter.value ? upcomingAppointmentsArguments.endDate : completedAppointmentsArguments.endDate),
                        startDate: (!selectedDataFilter.value ? upcomingAppointmentsArguments.startDate : completedAppointmentsArguments.startDate),
                        options: {
                          ...(!selectedDataFilter.value ? upcomingAppointmentsArguments.options : completedAppointmentsArguments.options)
                        }
                      }
                    }}
                  />
                ]
              },
            },

            {
              children: {
                before: [
                  <span key={"telemed-key" + telemedKey}>
                    {formatAppointmentId(telemedKey)}
                  </span>
                ]
              },
            },

            {
              children: {
                before: [
                  <button
                    key={"contact-link-" + contactID}
                    className="lnk-details"
                    onClick={() => {
                      // checkIncall();
                      props.setTelemedModalAppointmentData({
                        appointmentId: appointment.appointmentId,
                        dateTime: appointment.dateTime,
                        externalId: appointment.externalId,
                        status: appointment.status,
                        teleMedKey: appointment.teleMedKey ?? "",
                        attendees: appointment.attendees ?? [],
                        patients: appointment.patients,
                        kind: appointment.kind,
                        durationMinutes: appointment.durationMinutes ?? 0, // timeSpan?
                        tasks: appointment.tasks ?? [],
                        appointmentUpdatedTime: appointment.appointmentUpdatedTime ?? ''
                      })
                      props.setDisplayTelemedModal(true);
                    }}
                  >
                    Details
                  </button>
                ]
              },
              customClass: "details"
            },
          ];
          return renderAppointmentEntry(appointmentFieldConfigs, rowIndex);
        });
      }

    }

  };

  const renderTable = () => {
    const isShownAppointments = visibleAppointmentInfo?.appointments.map(item => item.length > 0).reduce((prevValue, currentValue) => {
      return prevValue || currentValue;
    }, false);

    if (!isShownAppointments) {
      return [
        (<div key="appointment-data-loaded-empty">
          <p style={{ paddingTop: "20px", fontWeight: "bold" }}>
            No results found
          </p>
        </div>)
      ];
    }
    const appointmentList = visibleAppointmentInfo?.appointments ?
      visibleAppointmentInfo?.appointments.map(data => data.filter(items => items.status !== 'cancelled')).filter(appoinements => appoinements.length) : [];

    return [
      appointmentList.map((appointments: AppointmentSummaryEntry[], index: number) => {
        return (
          <div className="telemed-container-table" key={"telemed-table" + index}>
            {appointments.length &&
              <div className="telemed-container-table">
                <p className="telemed__table-title">
                  <span className="telemed__table-title-date"> {showTitleDatetime(appointments).onlyDate} </span>
                  <span className="telemed__table-full-date"> {showTitleDatetime(appointments).fullDate} </span>
                </p>

                <table className="table daily-appointment__table">
                  <tbody>
                    <tr className="telemed__table-header-row">
                      <th className="telemed__table-header time"       > Time </th>
                      <th className="telemed__table-header contact"    > Contact </th>
                      <th className="telemed__table-header pet"        > Pet </th>
                      <th className="telemed__table-header status"     > Status </th>
                      <th className="telemed__table-header appointment"> Appointment ID </th>
                      {/* <th className="telemed__table-header start_call" > </th> */}
                      <th className="telemed__table-header details"    > </th>
                    </tr>

                    {renderAppointmentData(appointments)}

                  </tbody>
                </table>
              </div>
            }
          </div>
        )
      })
    ];
  };

  return (
    <>
      {showNewWindow && (
        <PopupWindowWithHooks
          url={newWindowUrl}
          closePopupWindowWithHooks={onCloseNewWindow}
          appointmentInfo={currentApointment}
        >
        </PopupWindowWithHooks>
      )}
      <SearchAndFilter
        handleSearchChange={handleSearchChange}
        placeholder="Search by contact name"
        handleNewFilteredAppointmentRange={handleNewFilteredAppointmentRange}
        optionsForFilter={optionsForFilter}
        setSelectedDateFilter={setSelectedDataFilter}
      />
      {/** @ts-ignore */}
      <div ref={scrollRef} id="telemedicine-div" style={{
        overflow: 'auto',
        maxHeight: '800px',
        minHeight: '780px'
      }}>
        {renderTable()}
      </div>
      <Modal
        open={isLoading}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} />
      </Modal>
    </>
  )
};

export default Telemedicine;
