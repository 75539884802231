/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import PracticeLoginContactV from 'containers/PracticeLoginContact/PracticeLoginContactV';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { setSessionJWT } from 'global/sessionStorage/SessionJWT';
import { useParams } from 'react-router-dom';
import { useRouter } from 'hooks/useRouter';
import { getMicrositeName } from 'utils/convertData';
import { getPatientPortalUrl } from 'global/constants/url';

interface IProps {
  setRouterJWT: any;
  isSubscription?: boolean;
}

const PracticeLoginContact: React.FC<IProps> = (props: IProps): JSX.Element => {
  const param: { userId?: string } = useParams();
  const router = useRouter();

  const [loginError, setLoginError] = useState<string>('');
  useEffect(() => {
    handleSubmitData();
  }, []);

  const handleSubmitData = async () => {
    try {
      const userId = param.userId || '';
      if (!userId) throw 'No userId';

      const micrositeName = getMicrositeName();

      const loginResponse = await configuredRequests.GET.practiceLoginContact(
        userId
      );
      if (loginResponse) {
        const url = `https://${getPatientPortalUrl()}/${micrositeName}/practice/${
          props.isSubscription ? 'subscription' : 'dashboard'
        }?token=${loginResponse.access_token}&type=${loginResponse.token_type}`;
        window.location.href = url;
      }
    } catch (err) {
      // Ask BA confirm about error message when login fail
      setLoginError(
        'The user is not available. Please contact your system administrator for support.'
      );
    }
  };

  return (
    <>
      <PracticeLoginContactV loginError={loginError} />
    </>
  );
};

export default PracticeLoginContact;
