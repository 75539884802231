import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { Formik, ErrorMessage, FastField } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { linkHttp } from 'utils/regex';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { phonePattern } from 'utils/regex';
import loading_spinner from 'media/images/loading-spinner.svg';

type TwilioSettingDataType = {
  twilioSID: string;
  smsOutGoingNumber: string;
  callOutGoingNumber: string;
};

const TwilioSetting = (): JSX.Element => {
  const [twilioSettingData, setTwilioSettingData] =
    useState<TwilioSettingDataType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const ErrorMessageTypes = {
    required: 'This field is required.',
    incorrectFormat: 'Incorrect format.',
  };

  const initialValues = {
    twilioSID: twilioSettingData ? twilioSettingData.twilioSID : '',
    smsOutGoingNumber: twilioSettingData
      ? twilioSettingData.smsOutGoingNumber
      : '',
    callOutGoingNumber: twilioSettingData
      ? twilioSettingData.callOutGoingNumber
      : '',
  };

  const UserValidationSchema = Yup.object().shape({
    twilioSID: Yup.string().required(ErrorMessageTypes.required),
    smsOutGoingNumber: Yup.string()
      .required(ErrorMessageTypes.required)
      .matches(phonePattern, ErrorMessageTypes.incorrectFormat),
    callOutGoingNumber: Yup.string()
      .required(ErrorMessageTypes.required)
      .matches(phonePattern, ErrorMessageTypes.incorrectFormat),
  });

  const getDataTwilioSetting = async () => {
    try {
      const res = await configuredRequests.GET.getDataTwilioSetting();
      if (res) {
        setIsLoading(false);
        setTwilioSettingData(res.settings);
      }
    } catch (err) {
      console.log('err', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataTwilioSetting();
  }, []);

  const submitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = {
        twilioSID: values.twilioSID,
        smsOutGoingNumber: values.smsOutGoingNumber,
        callOutGoingNumber: values.callOutGoingNumber,
      };

      const res = await configuredRequests.PUT.updateDataTwilioSetting(
        submitData
      );
      if (res) {
        setDisabledSubmit(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (err) {
      setDisabledSubmit(false);

      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={UserValidationSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, handleBlur, setFieldValue } = formikProps;
        return (
          <div className='w-300'>
            {isLoading ? (
              <img
                className='loading-appointment-info'
                src={loading_spinner}
                alt={'Loading'}
              />
            ) : (
              <div className='react-modal--content'>
                <div className='communication-container'>
                  <div className='communication-container__table'>
                    <div className='edit-appointment roleModal'>
                      <Fragment>
                        <form
                          onSubmit={handleSubmit}
                          className='col-12 form-schedule react-modal--settings__content'
                        >
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Twilio ID <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='twilioSID'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'twilioSID',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='twilioSID' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Outgoing SMS Message Phone number{' '}
                              <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='smsOutGoingNumber'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'smsOutGoingNumber',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='smsOutGoingNumber' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Outgoing Call Message Phone number.{' '}
                              <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='callOutGoingNumber'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'callOutGoingNumber',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='callOutGoingNumber' />
                              </p>
                            </div>
                          </div>
                          <Button
                            style={{ marginLeft: '10px' }}
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={!formikProps.isValid || disabledSubmit}
                          >
                            <span>Save</span>
                          </Button>
                        </form>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default TwilioSetting;
