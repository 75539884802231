import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RadioButtonGroup from '../../../../radio-button-group';
import CheckboxGroup from '../../../../checkbox-group';
import OptionConfig from '../form-field-types/option-config';
import { getMapFromArray, getFieldPropertyValues } from '../form-field-types/helpers';

const commonPropTypes = {
  propertyName: PropTypes.string,
  property: PropTypes.object,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  isLocked: PropTypes.bool,
};

const commonClassNames = classNames({
  'property-container': true,
});

export const Input = ({ propertyName, property, index, onChange, isLocked }) => {
  const { value } = property;
  const classes = classNames({
    [commonClassNames]: true,
    input: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label htmlFor={propertyName} className='control-label'>
        {property.label}
      </label>
      <div className='control'>
        <input
          className='span30'
          type='text'
          id={propertyName}
          name={propertyName}
          placeholder={property.label}
          value={value}
          onChange={onChange}
          disabled={isLocked}
        />
      </div>
    </div>
  );
};

Input.propTypes = commonPropTypes;

export const Checkbox = ({ propertyName, property, index, onChange, isLocked }) => {
  const { value, label } = property;
  const classes = classNames({
    [commonClassNames]: true,
    checkbox: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <input
        type='checkbox'
        id={propertyName}
        name={propertyName}
        checked={value}
        onChange={onChange}
        disabled={isLocked}
      />
      <label htmlFor={propertyName} className='control-label'>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = commonPropTypes;

export const Select = ({ propertyName, property, index, onChange, isLocked }) => {
  const { value, options } = property;
  const classes = classNames({
    [commonClassNames]: true,
    select: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label htmlFor={propertyName} className='control-label'>
        {property.label}
      </label>
      <div className='control'>
        <select
          name={propertyName}
          className='span30'
          onChange={onChange}
          value={value}
          disabled={isLocked}
        >
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = commonPropTypes;

export const Dynamic = ({ propertyName, property, index, field, onChange, isLocked }) => {
  const fields = field.fields || [];
  const propLabel = `${property.label}s`;
  const isCheckbox = property.type === 'checkbox';
  const containerClassNames = classNames({
    [commonClassNames]: true,
    dynamic: !isCheckbox,
    'dynamic-checkbox': isCheckbox,
    locked: isLocked,
  });
  const listClassNames = classNames({
    'dynamic-property-list': !isCheckbox,
    'checkbox-container': isCheckbox,
  });

  return (
    <div key={index} className={containerClassNames}>
      <label className='control-label'>{propLabel}</label>
      <div className={listClassNames}>
        {fields?.filter(item => item.column_name !== 'Country').map((subfield, index) => (
          <DynamicSubfield
            key={`${subfield.id}-${index}`}
            subfield={subfield}
            propertyName={propertyName}
            isCheckbox={isCheckbox}
            isLocked={isLocked}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

Dynamic.propTypes = {
  ...commonPropTypes,
  field: PropTypes.object,
};

const DynamicSubfield = ({ subfield, propertyName, isCheckbox, isLocked, onChange }) => {
  const subfieldProp = getMapFromArray(subfield.properties);
  const { value } = subfieldProp.get(propertyName);
  const propertyId = `${propertyName}-${subfield.id}`;
  const property = {
    label: subfield.display_name,
    value,
  };

  if (subfield.column_name === 'State / Province' && propertyName === 'placeholder') {
    return null;
  }

  if (isCheckbox) {
    return (
      <Checkbox
        propertyName={propertyId}
        property={property}
        index={propertyId}
        onChange={e => onChange(e, subfield)}
        isLocked={isLocked}
      />
    );
  }
  const visibleProp = getFieldPropertyValues(subfieldProp, 'isVisible', { isVisible: true });

  if (visibleProp.isVisible) {
    return (
      <Input
        propertyName={propertyId}
        property={property}
        index={propertyId}
        onChange={e => onChange(e, subfield)}
        isLocked={isLocked}
      />
    );
  }
  return null;
};

DynamicSubfield.propTypes = {
  subfield: PropTypes.object.isRequired,
  propertyName: PropTypes.string,
  isCheckbox: PropTypes.bool,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func,
};

DynamicSubfield.defaultProps = {
  propertyName: '',
  isCheckbox: false,
  isLocked: false,
  onChange: () => { },
};

export const Single = ({ propertyName, property, index, onChange, isLocked }) => {
  const options = property.options || [];
  const classes = classNames({
    [commonClassNames]: true,
    single: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label className='control-label'>{property.label}</label>
      <RadioButtonGroup
        className='checkbox-container'
        group={propertyName}
        itemClassNames='radio pad-right'
        onChange={newValue => onChange(newValue, propertyName)}
        items={options.map(option => {
          return {
            label: option,
            value: option,
          };
        })}
        selected={property.value}
        disabled={isLocked}
      />
    </div>
  );
};

Single.propTypes = commonPropTypes;

export const Multiple = ({ propertyName, property, index, onChange, isLocked }) => {
  const options = property.value || [];
  const classes = classNames({
    [commonClassNames]: true,
    multiple: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label className='control-label'>{property.label}</label>
      <CheckboxGroup
        className='checkbox-container'
        group={propertyName}
        itemClassNames='property-container checkbox'
        onChange={onChange}
        items={options.map(option => ({ label: option[0], checked: option[1] }))}
        selected={property.value}
        disabled={isLocked}
      />
    </div>
  );
};

Multiple.propTypes = commonPropTypes;

export const Group = ({ propertyName, property, index, onChange, getSpecificProps, isLocked }) => {
  const items = property.value || [];
  const classes = classNames({
    [commonClassNames]: true,
    group: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label className='control-label'>{property.label}</label>
      {items.map((item, index) => (
        <GroupItem
          key={index}
          item={item}
          propertyName={propertyName}
          property={property}
          onChange={onChange}
          getSpecificProps={getSpecificProps}
          disabled={isLocked}
        />
      ))}
    </div>
  );
};

Group.propTypes = {
  ...commonPropTypes,
  getSpecificProps: PropTypes.func,
};

const GroupItem = ({ item, propertyName, property, onChange, getSpecificProps, disabled }) => {
  const [itemLabel, itemValue] = item;

  let isVisible = true;

  if (itemValue.hasOwnProperty('visible')) {
    if (
      typeof itemValue.visible === 'object' &&
      itemValue.visible.hasOwnProperty('mappedPropKey')
    ) {
      const { mappedPropKey, mappedPropValue } = itemValue.visible;
      // use mapped property key and property Map to execute custom visible method
      isVisible = getSpecificProps().get(mappedPropKey)[mappedPropValue];
    } else isVisible = itemValue.visible;
  }

  if (!isVisible) return null;

  return (
    <div key={itemLabel} className='flex-container'>
      <label htmlFor={itemLabel} className='control-label'>
        {itemValue.label || itemLabel}
      </label>
      <div className='control'>
        <input
          type={itemValue.type}
          className='span25'
          name={itemLabel}
          id={itemLabel}
          value={itemValue.value}
          onChange={e => {
            // update property appropriate value with new group item value
            const { name, value } = e.target;
            const index = property.value.findIndex(val => val[0] === name);

            const newValue = [...property.value];
            newValue[index][1].value = value;

            onChange(newValue, propertyName);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

GroupItem.propTypes = {
  ...commonPropTypes,
  getSpecificProps: PropTypes.func,
};

export const OptionGroup = ({ propertyName, property, index, onChange, isLocked }) => {
  const options = property.value || [];
  const classes = classNames({
    [commonClassNames]: true,
    'option-group': true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <label className='control-label'>{property.label}</label>
      <OptionConfig
        min={1}
        options={options}
        onChange={(options, index) => onChange(options, propertyName, index)}
        disabled={isLocked}
      />
    </div>
  );
};

OptionGroup.propTypes = commonPropTypes;

export const RichText = ({ propertyName, property, index, showModal, isLocked }) => {
  const { label, value, buttonIcon } = property;
  const classes = classNames({
    [commonClassNames]: true,
    button: true,
    locked: isLocked,
  });

  return (
    <div key={index} className={classes}>
      <button
        className='btn btn-primary'
        onClick={() => showModal(true, propertyName, label, value)}
        disabled={isLocked}
      >
        {buttonIcon && <i className={`icon icon-${buttonIcon}`} />}
        {label}
      </button>
    </div>
  );
};

RichText.propTypes = {
  ...commonPropTypes,
  showModal: PropTypes.func,
};
