/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React, { Fragment, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { orderBy } from 'lodash';
import * as Yup from 'yup';
import './EmitterStyle.scss';
import { EmitterResponseType } from 'global/requests/ResponseTypes/Emitter';
import loading_spinner from 'media/images/loading-spinner.svg';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';

interface EmitterMethodDataType {
  label: string;
  value: string;
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
  numeric: 'Only numeric allowed.',
};

const EmitterValidationSchema = Yup.object().shape({
  methodName: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  // version: Yup.string().when('methodName.label', {
  //   is: 'Update Driver',
  //   then: Yup.string().required(ErrorMessageTypes.required)
  // }),
  // url: Yup.string().required(ErrorMessageTypes.required),
  milliseconds: Yup.number()
    .moreThan(0, 'The sync interval must be greater than 0')
    .typeError(ErrorMessageTypes.numeric),
});

const Emitter = (): JSX.Element => {
  const tenantData = JSON.parse(
    window.sessionStorage.getItem('tenantInfo--full_response') || ''
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [emitterData, setEmitterData] = React.useState<EmitterResponseType>();
  const [emitterMethodData, setEmitterMethodData] = React.useState<
    EmitterMethodDataType[]
  >([]);
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());

  const getEmitterMethodData = async () => {
    try {
      const res = await configuredRequests.GET.getEmitterMethod();
      const emitterMethod = res.methods.map((items) => {
        return {
          label: items.methodName ?? '',
          value: items.methodId ?? '',
        };
      });
      const sortedEmitterMethod = orderBy(emitterMethod, ['label'], ['asc']);
      if (res) {
        setEmitterMethodData(sortedEmitterMethod);
      }
    } catch (err) {}
  };

  const getEmitterData = async () => {
    try {
      // const res = await configuredRequests.GET.getEmitter(tenantData.tenantId);
      // if (res) {
      //   setEmitterData(res);
      // }
    } catch (err) {}
  };

  useEffect(() => {
    Promise.all([getEmitterMethodData(), getEmitterData()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const emitterInitialData = {
    methodName: emitterMethodData[0],
    practiceId: tenantData.tenantId,
    // version: emitterData?.version === '' ? '0.0.0.1' : emitterData?.version,
    // url: emitterData?.url ?? '',
    milliseconds: '',
  };

  const submitData = async (values: any) => {
    try {
      const submitData = {
        methodName: values.methodName.label,
        methodId: values.methodName.value,
        tenantId: values.practiceId,
        // version: values.version,
        // url: values.url,
        command: values.query,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        type: values.type ?? "Syncer",
      };
      const res = await configuredRequests.POST.createEmitter(
        submitData as any
      );
      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={emitterInitialData}
      validationSchema={EmitterValidationSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          setFieldTouched,
          isValid,
        } = formikProps;
        return (
          <div className='syncer-emitter'>
            <div className='communication-container__table'>
              {isLoading ? (
                <img
                  className='loading-general-syncer'
                  src={loading_spinner}
                  alt={'Loading'}
                />
              ) : (
                <>
                  <div className='react-modal--content'>
                    <div className='edit-appointment roleModal '>
                      <Fragment>
                        <form
                          onSubmit={handleSubmit}
                          className='col-12 form-schedule react-modal--settings__content'
                        >
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Emitter Method <b className='require'>*</b>
                            </span>
                            <FastField
                              classNamePrefix='search-city'
                              className='option-city'
                              component={Select}
                              name='methodName'
                              value={values.methodName}
                              options={emitterMethodData}
                              onBlur={() => {
                                setFieldTouched('methodName', true);
                              }}
                              onChange={(e: any) => {
                                setFieldValue('methodName', e);
                              }}
                              maxMenuHeight={150}
                            />
                          </div>

                          <div className='setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Practice ID <b className='require'>*</b>
                            </span>
                            <div className=' '>
                              <FastField
                                disabled={true}
                                name='practiceId'
                                maxLength={50}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'practiceId',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='practiceId' />
                              </p>
                            </div>
                          </div>

                          {/* {values.methodName?.label === 'Update Driver' && (
                              <>
                                <div className='setting__content--full-row block width-50'>
                                  <span className='title-input-form'>Version <b className="require">*</b></span>
                                  <div className=' '>
                                    <FastField
                                      name='version'
                                      maxLength={50}
                                      className='setting__search-form'
                                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        handleBlur(e);
                                        setFieldValue('version', e.currentTarget.value.trim());
                                      }}
                                    />
                                    <p className="error-msg"><ErrorMessage name='version' /></p>
                                  </div>
                                </div>

                                <div className='setting__content--full-row block width-50'>
                                  <span className='title-input-form'>URL <b className="require">*</b> </span>
                                  <div className=' '>
                                    <FastField
                                      name='url'
                                      maxLength={255}
                                      className='setting__search-form'
                                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        handleBlur(e);
                                        setFieldValue('url', e.currentTarget.value.trim());
                                      }}
                                    />
                                    <p className="error-msg"><ErrorMessage name='url' /></p>
                                  </div>
                                </div>
                              </>
                            )} */}

                          {values.methodName?.label.includes('Execute') && (
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                SQL Query{' '}
                              </span>
                              <div className=' '>
                                <FastField
                                  name='query'
                                  className='setting__search-form'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'query',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                />
                                <p className='error-msg'>
                                  <ErrorMessage name='query' />
                                </p>
                              </div>
                            </div>
                          )}

                          {values.methodName?.label.includes('Get Service Logs') && (
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Log Type{' '}
                              </span>
                              <div className=' '>
                                <FastField
                                  name='type'
                                  className='setting__search-form'
                                  style={{"textIndent": 0}}
                                  as="select"
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      'type',
                                      e.currentTarget.value.trim()
                                    );
                                  }}
                                >
                                  <option value="Syncer">Syncer Service</option>
                                  <option value="Manager">Service Manager</option>
                                  <option value="Configurator">Configurator</option>
                                  <option value="SelfUpdater">SelfUpdater</option>
                                </FastField>
                                <p className='error-msg'>
                                  <ErrorMessage name='type' />
                                </p>
                              </div>
                              <span className='title-input-form'>
                                Date Range{' '}
                              </span>
                              <div className="setting__content--emitter-modal-get-logs-picker">
                                <DateTimePicker
                                  // @ts-ignore
                                  setStartDate={(date: Date | null) => setStartDate(date)}
                                  // @ts-ignore
                                  setEndDate={(date: Date | null) => setEndDate(date)}
                                  showTimePicker={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  getEndDate={false}

                                />
                              </div>
                            </div>
                          )}

                          <div className='setting__content--full-row block'>
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              disabled={!isValid}
                            >
                              <span>Execute</span>
                            </Button>
                          </div>
                        </form>
                      </Fragment>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default Emitter;
