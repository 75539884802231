import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-basis: 33.333333%;
  min-width: 48%;
  flex-wrap: wrap;
  justify-content: flex-start;

  border: 1px solid #DEE0EC;
  border-radius: 6px;
  background-color: #FFFFFF;

  padding: 10px;
  overflow: auto;

  max-height: 4.25in;
  img {
    width: auto;
    height: 30%;
    object-fit: cover;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 20px;
    max-width: 190px;

    border: 1px solid #DADADA;
  }
  img.active {
    border: 3px solid #54A0FE;
  }
`;

interface IListImageTemplates {
  listImageTemplates: string[];
  initialSelectedImage: string;
  selectedImage: string;
  setImage: (imageUrl: string) => void;
};

const ListImageTemplates: React.FC<IListImageTemplates> = ({
  listImageTemplates,
  initialSelectedImage,
  selectedImage,
  setImage,
}) => {

  useEffect(() => {
    if (listImageTemplates.length > 0) {
      initialSelectedImage ? setImage(initialSelectedImage) : setImage(listImageTemplates[0]);
    }
  }, [listImageTemplates, initialSelectedImage, setImage]);

  return <Wrapper className="list-image-templates">
    {
      listImageTemplates.map((imageTemplate: string) => <img
        src={imageTemplate}
        alt={imageTemplate}
        onClick={() => setImage(imageTemplate)}
        key={imageTemplate}
        className={(selectedImage && selectedImage === imageTemplate) ? 'active' : ''}
      />)
    }
  </Wrapper>
};

export default ListImageTemplates;
