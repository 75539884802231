import { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { configuredRequests } from '../global/requests/ConfiguredRequests';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';
import { isSafari } from 'react-device-detect';


interface IProps {
  closePopupWindowWithHooks(): void;
  url?: string;
  appointmentInfo?: any;
}

const PopupWindowWithHooks: FC<IProps> = (props) => {
  const [containerEl] = useState(document.createElement('div'));
  let externalWindow: any = null;

  const sendUpdateAppointmentRequest = async (
    appointmentInfo: AppointmentSummaryEntry
  ) => {
    try {
      const res = await configuredRequests.PATCH.updateAppointmentStatus(appointmentInfo.appointmentId, 'complete');
      console.log('res', res);
      if (res) {
        // console.log('response to update appointment req', res)
      }
    } catch (err) {
      console.log('error updating appointment');
    }
  };

  useEffect(
    () => {
      // Assignments to the 'externalWindow' variable from inside React Hook useEffect will be lost after each render.
      // To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property.
      // Otherwise, you can move this variable directly inside useEffect

      // eslint-disable-next-line react-hooks/exhaustive-deps
      externalWindow = isSafari ? window.location.assign(
        //@ts-ignore
        props.url,
      ) : window.open(props.url, 'window', 'width=1200,height=800');

      externalWindow.document.body.appendChild(containerEl);
      externalWindow.addEventListener('beforeunload', () => {
        if (props.appointmentInfo) {
          sendUpdateAppointmentRequest(props.appointmentInfo);
          props.closePopupWindowWithHooks();
        }
      });
      // console.log('Created Popup Window');
      externalWindow.addEventListener('storage', () => {
        const isOpen = localStorage.getItem('isOpen');
        if (isOpen === 'false') {
          if (externalWindow) {
            externalWindow.close();
          }
        }
      });
      return function cleanup() {
        // console.log('Cleaned up Popup Window');
        localStorage.setItem('isOpen', 'false');
        // externalWindow.close();
        externalWindow = null;
      };
    },
    // Only re-renders this component if the variable changes
    []
  );
  return ReactDOM.createPortal(props.children, containerEl);
};

export default PopupWindowWithHooks;
