import React from 'react';
import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 400px;
  padding: 15px 20px;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);
  text-align: center;

  svg {
    font-size: 2rem;
    color: red;
  }

  p {
    margin: 10px;
  }

  span {
    font-size: 13px;
  }

  .btn {
    display: block;
    margin: 15px auto 0;
    padding: 7px 20px;

    border-radius: 4px;
    background-color: #0074ff;
    /* box-shadow: 0 1px 3px 0 rgb(255 255 255 / 55%); */
    text-transform: none !important;
    color: #ffffff;
    font-size: 13px;
  }
`;

interface Props {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarningPopup = ({ open, onClose }: Props) => {
  return (
    <Modal
      open={open}
      // onClose={}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <Content>
        <span>
          It is important to note that selecting this option will queue the
          message(s) for immediate delivery and message delivery could occur
          outside of configured system delivery hours.
        </span>

        <Button
          variant='contained'
          color='primary'
          className='btn'
          onClick={() => onClose(false)}
        >
          OK
        </Button>
      </Content>
    </Modal>
  );
};

export default WarningPopup;
