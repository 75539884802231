import { InterpolationFunction, ThemeProps } from 'styled-components';

export interface ObjectMap {
  [key: string]: string | number;
}

const getPropValue = (baseObject: ObjectMap, path: string): string | undefined => {
  return path.split('.').reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (obj: any, prop: string): any => (obj === undefined ? obj : obj[prop]),
    baseObject,
  );
};

const getThemeProp = (
  attribute: string,
  defaultValue = '',
): InterpolationFunction<ThemeProps<ObjectMap>> => {
  return (props: ThemeProps<ObjectMap>): string => {
    return getPropValue(props.theme, attribute) || defaultValue;
  };
};

export default getThemeProp;
