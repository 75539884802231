const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '-15px',
  },
  dropdown: {
    flex: '1 1 46%',
    marginBottom: '15px',
  },
  message: {
    flex: '1 1 100%',
    margin: 0,
    fontSize: '1.5em',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  messageSmall: {
    fontSize: '1em',
  },
};

export default styles;