import styled from 'styled-components';

import InputLabel from '@material-ui/core/InputLabel';

import OutlinedInput from '@material-ui/core/OutlinedInput';

interface StyleProps {
  padding?: string;
  height?: string;
  adornmentcolor?: string;
  bordercolor?: string;
}

const StyledInput = styled(OutlinedInput)`
  border-radius: 6px;
  border: none;

  .MuiInputBase-input {
    position: relative;
    background-color: white;
    font-size: 0.9rem;
    max-width: 100%;
    border-radius: 6px;

    padding: ${(props: StyleProps): string => props.padding || '11px 13px'};
    height: ${(props: StyleProps): string => props.height || 'auto'};
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${(props: StyleProps): string => props.bordercolor || '#d4dbe4'};
  }
  &.Mui-error {
    border-color: red;
  }
  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #4c7cfd;
    }
  }

  .MuiInputAdornment-root {
    margin: 0;
    color: ${(props: StyleProps): string => props.adornmentcolor || 'lightgrey'};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin: 1rem 0 0.5rem 1px;
`;

const Main = styled.div``;

export { StyledInput, StyledInputLabel, Main };
