// This component's only use is to pass error props from 'containers/AdminLoginPractice/AdminLoginPracticeVM.tsx' to 'components/AdminLoginPractice/AdminLoginPractice.tsx

import React from 'react';

import AdminLoginPractice from 'components/AdminLoginPractice/AdminLoginPractice';

const AdminLoginPracticeV = (props: { loginError: string }): JSX.Element => {

  return (
    <AdminLoginPractice loginError={props.loginError} />
  )
}

export default AdminLoginPracticeV;
