import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptionFilterDropdownObject } from 'global/requests/ResponseTypes';
import { isSafari } from 'react-device-detect';

interface SearchAndFilterProps {
  handleSearchChange: (event: React.SyntheticEvent<HTMLInputElement>) => void,
  handleNewFilteredAppointmentRange: (newSelectedItem: OptionFilterDropdownObject) => void,
  setSelectedDateFilter: React.Dispatch<React.SetStateAction<{ value: string, label: string }>>,
  placeholder: string,
  optionsForFilter: OptionFilterDropdownObject[],
}

const SearchAndFilter = (props: SearchAndFilterProps): JSX.Element => {

  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [valueFilterSelected, setValueFilter] = React.useState<OptionFilterDropdownObject>(props.optionsForFilter[0]);

  const searchInputRef = React.createRef<HTMLInputElement>();

  const handleNewSelectedFilterValue = (newSelectedItem: OptionFilterDropdownObject) => {
    setValueFilter(newSelectedItem);
    props.handleNewFilteredAppointmentRange(newSelectedItem);
    props.setSelectedDateFilter(newSelectedItem)
  };

  const handleSelectionDivClick = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  const handleSelectionDivBlur = (event: React.SyntheticEvent) => {
    // console.log("event target", event.target, event.currentTarget);
    // if (event.currentTarget.className === "filter-form") {
    //   return;
    // }
    setIsDropdownOpened(false);
  };

  // useBlurCustomDropdown(
  //   ["filter-form", "filter-global--dropdown-button"],
  //   () => {
  //     setIsDropdownOpened(false);
  //   }
  // );

  const handleFocusSearchInput = () => {
    searchInputRef.current?.focus()
  }

  const handlePreventFormDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();
  }

  const handleSelectionDivKeypress = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case " ":
      case "Enter": {
        setIsDropdownOpened(true);
        break;
      }
    }
  }

  const renderOptionsFromProps = () => {
    return props.optionsForFilter.map(item => {
      return (
        <li
          key={"item-filter" + item.label}
          className="select-item-filter"
          onClick={(event: React.SyntheticEvent) => {
            event.stopPropagation();
            handleNewSelectedFilterValue(item);
            setIsDropdownOpened(false);
          }}
        >
          {/* <button
            className="filter-global--dropdown-button"
            
          >
          </button> */}
          {item.label}
        </li>
      )
    })
  }

  return (
    <div className="search-filter__global">
      <form
        className={isSafari ? 'search-safari' : 'search-form'}
        onSubmit={handlePreventFormDefault}
      >
        <FontAwesomeIcon
          icon={["far", "search"]}
          onClick={handleFocusSearchInput}
        />
        <input
          type="search"
          ref={searchInputRef}
          placeholder={props.placeholder}
          className="search-form__input"
          onChange={props.handleSearchChange}
        />
      </form>
      <div
        className={"filter-form"}
        tabIndex={0}
        onClick={handleSelectionDivClick}
        onBlur={handleSelectionDivBlur}
        onKeyPress={handleSelectionDivKeypress}
      >
        <span>
          {valueFilterSelected?.label}
        </span>
        <nav className={"filter-nav" + (isDropdownOpened ? " open" : "")}>
          <ul className={"list-select-filter" + (isDropdownOpened ? " open" : "")}>
            {renderOptionsFromProps()}
          </ul>
        </nav>
        <FontAwesomeIcon className="icon-filter-down" icon="chevron-down" style={{ padding: 2 }} />
      </div>
    </div>
  );
};

export default SearchAndFilter;
