const Styles = {
  main: {
    display: 'flex',
    alignContent: 'start',
    margin: '6px 0',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    flex: '0 1 auto',
    border: 'none',
    padding: '0 15px',
  },
  buttonNotFirst: {
    borderLeft: '1px solid grey',
  },
  buttonHighlight: {
    color: 'blue',
  },
};

export default Styles;
