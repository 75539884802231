import axios, { CancelTokenSource } from 'axios';
import Messenger from 'components/Messenger/Messenger';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  ContactListResponse,
  SystemSettingResponse,
  ContactMessages,
} from 'global/requests/ResponseTypes';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import React, { useEffect } from 'react';
// import { configuredRequests } from 'global/requests/ConfiguredRequests';
// import { MessengerContactListEntryProps } from 'components/Messenger/MessengerT';

let source: CancelTokenSource;

const MessengerContainer = () => {
  // const [allContactFullMessengerData, setAllContactFullMessengerData] = React.useState<MessengerContactListEntryProps[]>([]);

  const [tenantUsersResponse, setTenantUsersResponse] =
    React.useState<ContactListResponse>();
  const [queryPagination, setQueryPagination] = React.useState({
    limit: 20,
    offset: 0,
  });
  const [isMaxMessagers, setIsMaxMessagers] = React.useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false); // to handle case scroll then search cause duplicate api 2 times
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorGettingUsers, setErrorGettingUsers] =
    React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isContactListLoading, setIsContactListLoading] =
    React.useState<boolean>(true);
  const [loadingMessenger, setLoadingMessenger] = React.useState(false);

  // store responses using userID as the key
  const [messageDataLookup, setMessageDataLookup] = React.useState([]);

  const [systemSetting, setSystemSetting] =
    React.useState<SystemSettingResponse>();

  const currentRequest = React.useRef<string>();
  const allContacts = React.useRef<ContactMessages[]>([]);

  const getSystemSetting = async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res) {
        setSystemSetting(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSystemSetting();
    // getAllContacts();
  }, []);

  const settingsTimezoneData = systemSetting
    ? systemSetting.settings.timeZoneOffset
    : '';

  const resetNumberContactUnread = (numberMarkUnread: number) => {
    if (numberMarkUnread) {
      document
        .getElementsByClassName('messenger-status')[0]
        .classList.add('status--count');
    } else {
      document
        .getElementsByClassName('messenger-status')[0]
        .classList.remove('status--count');
    }
    sessionStorage.setItem('countContactUnread', numberMarkUnread.toString());
    document.getElementsByClassName('messenger-status')[0].innerHTML =
      numberMarkUnread.toString();
  };

  const getContactListData = async (
    query: any,
    isNotResetList: boolean | undefined
  ) => {
    // const sessionCachedContactResponse = getSessionStorageContactsResponse();
    // if (sessionCachedContactResponse?.users) {
    //   setTenantUsersResponse(sessionCachedContactResponse);
    //   setIsContactListLoading(false);
    //   setErrorGettingUsers(false);
    //   return;
    // }
    // if (numberOfFailedUserDataRequests > 5) {
    //   setIsContactListLoading(false);
    //   setErrorGettingUsers(true);
    //   return;
    // }
    // setIsContactListLoading(true);
    // setErrorGettingUsers(false);

    currentRequest.current = query.searchText ? 'searching' : 'contact';

    // setIsWaiting(true);
    try {
      const res = await configuredRequests.GET.getContactListData({
        userKinds: ['Customer'],
        limit: query?.limit ?? 20,
        offset: query?.offset ?? 0,
        searchText: query?.searchText ?? '',
        isMessageList: true,
      });

      if (currentRequest.current === 'searching') {
        if (res.users.length > 0 && !res.users[0].searchedMesageId) {
          setIsContactListLoading(false);
          // setIsWaiting(false);
          return;
        }
      }
      if (currentRequest.current === 'contact') {
        if (res.users.length > 0 && res.users[0].searchedMesageId) {
          setIsContactListLoading(false);
          // setIsWaiting(false);
          return;
        }
      }

      const countContactUnRead = res.totalUnreads;
      if (!query.searchText) {
        resetNumberContactUnread(countContactUnRead);
      }
      if (query.isSearch) {
        if (!isNotResetList && res.count != tenantUsersResponse?.count) {
          setTenantUsersResponse(res);
        }
        setIsMaxMessagers(false);
      } else if (res.count === 0) {
        setIsMaxMessagers(true);
      } else {
        if (!isNotResetList && res.count != tenantUsersResponse?.count) {
          setTenantUsersResponse((preState) => ({
            ...res,
            users: preState?.users
              ? [...preState.users, ...res.users]
              : [...res.users],
          }));
        }
      }
      setIsContactListLoading(false);
      // setIsWaiting(false);
    } catch (err) {
      setIsContactListLoading(false);
      // setIsWaiting(false);
    }
  };

  const getAllContacts = async () => {
    try {
      const res = await configuredRequests.GET.getContactListData({
        userKinds: ['Customer'],
        offset: 0,
        isMessageList: true,
      });
      allContacts.current = res.users;
    } catch (err) {
      console.log('err', err);
    }
  };

  const getTextMessageData = async (
    userID: string,
    disabledLoading?: boolean,
    allMessages?: boolean
  ) => {
    !disabledLoading && setLoadingMessenger(true);
    try {
      if (source) {
        source.cancel();
      }
      source = axios.CancelToken.source();
      const response = await configuredRequests.GET.getMessagesByUserID(
        userID,
        allMessages,
        source.token
      );
      if (response) {
        if (!disabledLoading || response.length != messageDataLookup.length)
          setMessageDataLookup(response);
        !disabledLoading && setLoadingMessenger(false);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        !disabledLoading && setLoadingMessenger(false);
      }
    }
  };

  return (
    <Messenger
      contactEntries={tenantUsersResponse}
      getTextMessageData={getTextMessageData}
      getContactListData={getContactListData}
      messageDataLookup={messageDataLookup}
      setMessageDataLookup={setMessageDataLookup}
      setQueryPagination={setQueryPagination}
      queryPagination={queryPagination}
      isMaxMessagers={isMaxMessagers}
      isWaiting={isWaiting}
      settingsTimezoneData={settingsTimezoneData}
      loadingMessenger={loadingMessenger}
      isContactListLoading={isContactListLoading}
      setIsContactListLoading={setIsContactListLoading}
    />
  );
};

export default MessengerContainer;
