import { styled as muiStyled } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// Type
type InputProps = {
  widthProp?: string;
  heightProp?: string;
  labelProp?: string;
  variant?: string;
};

// Component container
export const StyledComponentContainer = muiStyled('div')(({}) => ({
  width: '100%',
  height: '100%',
  margin: '0',
  padding: '0',
}));

// Input container
export const StyledInputContainer = muiStyled('div')(({}) => ({
  width: '33%',
  margin: '0',
  padding: '0',
  display: 'flex',
  'justify-content': 'space-between'
}));

// Input & description container
export const StyledInputAndDescriptionContainer = muiStyled('div')(({}) => ({
  display: 'flex',
  'flex-direction': 'column',
  width: '47%',
  margin: '0',
  padding: '0',
}));

// Button and warning container
export const StyledButtonAndWarningContainer = muiStyled('div')(({}) => ({
  display: 'flex',
  width: '10%',
  'justify-content': 'space-between',
  'align-items': 'flex-end',
  margin: '0',
  padding: '0',
}));

// Input
export const StyledInputField = muiStyled('input')(({}) => ({
  margin: '0',
  padding: '5',
  width: '100%',
  height: '2rem',
  'border-radius': '0.3rem',
}));

// Description
export const StyledInputDescription = muiStyled('div')(({}) => ({
  margin: '0',
  padding: '5',
  'font-size': '0.9rem',
  'font-style': 'italic',
  color: 'blue'
}));


// Title
export const StyledComponentTitle = muiStyled('div')(({}) => ({
  width: '100%',
  height: '2rem',
  margin: '0',
  padding: '0',
  'font-size': '1rem',
  'font-weight': 'bold',
}));


// Description
export const StyledDescription = muiStyled('div')(({}) => ({
  color: '#617799',
  'font-family': 'Nunito Sans',
  'font-size': '13px',
  'letter-spacing': '0',
  'line-height': '18px',
  display: 'block',
  'margin-top': '10px',
  'margin-bottom': '13px',
}));


// Invalid input warning
export const StyledInvalidInputWarning = muiStyled('div')(({}) => ({
  margin: '0',
  padding: '5',
  'font-size': '0.9rem',
  'font-style': 'italic',
  color: 'red'
}));