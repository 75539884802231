/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { GetCurrentUserAppointmentInRangeArguments } from 'global/requests/RequestTypes';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';
import useClickOutSide from 'hooks/useClickOutSide';
import toast from 'components/Global/Toast';

// TODO make this shared since it is the same as src/components/Appointments/StatusSelect.tsx
interface ContactAppointmentStatusSelectProps {
  currentStatus: string,
  appointmentInformation: AppointmentSummaryEntry,
  getCurrentUserAppointmentsInRange: (startDate: string, endDate: string, count: number) => void,
  getDailyAppointmentArguments?: GetCurrentUserAppointmentInRangeArguments
}

const listStatus = {
  inProgress: 'In Progress',
  completed: 'Completed',
  confirmed: 'Confirmed',
  unconfirmed: 'Unconfirmed',
  complete: 'Completed',
  cancelled: 'Cancelled'
};

const ContactAppointmentStatusSelect = (props: ContactAppointmentStatusSelectProps) => {
  const clickOutSideRef = useRef();
  useClickOutSide(clickOutSideRef, () => setIsDropdownOpened(false));
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [userSelectedStatusText, setUserSelectedStatusText] = React.useState<string>("");

  const statusOptions = [
    "confirmed",
    "unconfirmed",
    "completed"
  ];

  // const statusOptions = props.currentStatus === 'inProgress' ? [
  //   "completed"
  // ] :
  //   [
  //     "confirmed",
  //     "unconfirmed",
  //   ];

  const submitAppointmentStatusUpdate = async (event: React.SyntheticEvent, statusText: string) => {
    if (props.currentStatus === statusText) {
      setIsDropdownOpened(false);
      setUserSelectedStatusText("");
      return;
    }
    try {
      event.preventDefault();
      setIsDropdownOpened(false);
      let appointmentTypeId = '';
      const appointment = await configuredRequests.GET.getAppointmentById(
        props.appointmentInformation.appointmentId
      );
      if (appointment) {
        let apptTypes = await configuredRequests.GET.getAppointmentType();
        apptTypes = apptTypes.filter(
          //@ts-ignore
          (at) => at.appointmentType === appointment.data?.appointmentTypeName
        );
        //@ts-ignore
        appointmentTypeId = apptTypes[0]?.externalId;
      }
      //TODO: render 'loading' while waiting for confirmation from the server
      const res = await configuredRequests.PATCH.updateAppointmentStatus(props.appointmentInformation.appointmentId, statusText === 'completed' ? 'complete' : statusText, appointmentTypeId);
      if (res) {
        setUserSelectedStatusText(statusText);
        toast.success('Update status successfully!');

        // fetch latest data after update
        if (props.getDailyAppointmentArguments) {
          props.getCurrentUserAppointmentsInRange(
            props.getDailyAppointmentArguments.startDate,
            props.getDailyAppointmentArguments.endDate,
            props.getDailyAppointmentArguments.limit,
          )
        }
      }
    } catch (err) {
      toast.error('Update appointment status failed. Please try again.');
    }
  };

  const handleToggleDropdown = (status: string) => {
    if (['complete', 'completed'].includes(status)) {
      return;
    }
    setIsDropdownOpened(!isDropdownOpened);
  };

  const convertStringStatus = (status: string) => {
    //@ts-ignore
    return status ? listStatus[status] : status
  };

  return (
    <div
      className={"view-contact--status-dropdown-container render-below"}
      //@ts-ignore
      ref={clickOutSideRef}
    >

      <div className="view-contact--status-button-container">

        <button
          className={
            // "appointment__entry-button "
            "view-contact--status-select-current-button "
            + (userSelectedStatusText.length ? userSelectedStatusText : props.currentStatus)
          }
          onClick={() => {
            const currentStatus = userSelectedStatusText === '' ? props.currentStatus : userSelectedStatusText;
            handleToggleDropdown(currentStatus);
          }}
        >
          <div className="view-contact--status-text">
            {(
              userSelectedStatusText.length
                ? convertStringStatus(userSelectedStatusText)
                : convertStringStatus(props.currentStatus)
            )}
          </div>
        </button>

      </div>

      <ul
        className={
          "view-contact--status-select-ul render-below"
          + (isDropdownOpened ? " opened" : "")
        }
      >
        {statusOptions.map((statusText: string, statusIndex: number) => {
          const currentStatus = userSelectedStatusText === '' ? props.currentStatus : userSelectedStatusText;
          const statusIsSelected = statusText === currentStatus ? true : false;
          return [
            (
              statusIsSelected
                ? <FontAwesomeIcon
                  key={"view-contact--fa-icon-" + statusText + "-" + statusIndex}
                  icon={['far', 'check']}
                  style={{ margin: "13px 5px 0 0" }}
                />
                : null
            ),
            (<li key={"view-contact--status-option-" + statusText + "-" + statusIndex}
              className={"view-contact--status-option " + statusText}
              tabIndex={0}
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                submitAppointmentStatusUpdate(event, statusText);
              }}
              data-value={statusText}
            >

              <div className="status-option--inner-text">
                {statusText}
              </div>

            </li>),

            (<li
              key={"view-contact--status-line-break-" + statusText + "-" + statusIndex}
              className="vertical-line-break"
            />)
          ]
        })}
      </ul>

    </div>
  )
};

export default ContactAppointmentStatusSelect;
