import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Collapse } from '@material-ui/core';
import { StyledAlert, CustomActionContainer } from './styled-components';

export interface AlertBannerProps {
  severity: 'error' | 'warning' | 'success' | 'info';
  open?: boolean;
  children: React.ReactElement;
  withClose?: boolean;
  onClose?: () => void;
  actionOverrideElement?: React.ReactElement;
}

const AlertBanner = (props: AlertBannerProps): React.ReactElement => {
  const {
    open = true,
    severity = 'info',
    onClose = (): void => {},
    children,
    withClose = false,
    actionOverrideElement,
  } = props;
  const iconMapping = {
    success: <CheckCircleIcon fontSize='inherit' />,
    warning: <WarningRoundedIcon fontSize='inherit' />,
    error: <InfoIcon fontSize='inherit' />,
    info: <InfoIcon fontSize='inherit' />,
  };
  const defaultAction = withClose && (
    <IconButton
      aria-label='close'
      color='inherit'
      size='medium'
      onClick={onClose}
      style={{ padding: '0.1rem' }}
    >
      <CloseIcon fontSize='inherit' />
    </IconButton>
  );

  const action = actionOverrideElement ? (
    <CustomActionContainer>{actionOverrideElement}</CustomActionContainer>
  ) : (
    defaultAction
  );

  return (
    <Collapse in={open}>
      <StyledAlert severity={severity} iconMapping={iconMapping} action={action}>
        {children}
      </StyledAlert>
    </Collapse>
  );
};

export default AlertBanner;
