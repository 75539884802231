import * as ResponseTypes from './ResponseTypes';

import { AddressEntry } from './ResponseTypes/_Address';
import { AppointmentSummaryEntry } from './ResponseTypes/Appointments';
import {
  RenameFormRequired,
  RenameFolderRequired,
  EditFormBuilderRequired,
  MoveFormRequired,
  CloneFormBuilderRequestType,
  CreateFormBuilderRequestType,
  MarkAsReadSubmissionPayload,
  ApproveSubmissionPayloadType,
} from './ResponseTypes/FormBuilder';
import {
  UserDataResponse,
  NullableUserDataType,
} from './ResponseTypes/UserAdministration';
import { GroupSystemAdminResponse } from './ResponseTypes/GroupSystemAdmin';
import { EmitterRequestType } from './ResponseTypes/Emitter';
import {
  AppointmentTypeResponse,
  AppointmentTypeSettingRequestType,
} from './ResponseTypes/AppointmentTypeSetting';
import { PayloadToDeleteSubmissionDataType, PayloadToDeleteSubmissionDocument } from './ResponseTypes/LeftMenuSubmissionDataType';
import { SpeciesSettingType } from './ResponseTypes/SpeciesSetting';
import { ProductResponseType } from './ResponseTypes/Product';
import { Medical } from './ResponseTypes/Medical';

export enum ValidGrantTypes {
  password = 'password',
}

export interface LoginRequest {
  username: string;
  password: string;
  grantType: ValidGrantTypes;
}

export enum MessagePayloadKindsEnum {
  sms = 'sms',
  email = 'email',
  postal = 'postal',
}

export type MessagePayloadKindsType = keyof typeof MessagePayloadKindsEnum;

interface UserSendCommunicationShared {
  kind: MessagePayloadKindsType; // ["sms" | "email"]
  content: string;
  scheduleDate: string; //Date
  templateId?: string;
  actionType?: string;
}

interface MessageSMSContentsType {
  content: string;
  kind: MessagePayloadKindsType;
  sendLaterDate: string;
  templateId: string;
  actionType?: string;
}
export interface MultipleUserSendTextPayload
  extends UserSendCommunicationShared {
  messageContents: MessageSMSContentsType;
  contactIds: string[];
}

export interface MultipleContactSendTextPayload
  extends UserSendCommunicationShared {
  sendLaterDate: string;
  kind: MessagePayloadKindsEnum.sms;
}
export interface MultipleUserSendEmailPayload
  extends UserSendCommunicationShared {
  sendLaterDate: string;
  contactIds: string[];
  subject: string;
  kind: MessagePayloadKindsEnum.email;
}
export interface SingleUserSendTextPayload extends UserSendCommunicationShared {
  kind: MessagePayloadKindsEnum.sms;
}

export interface SingleSendTextToPhonePayload
  extends SingleUserSendTextPayload {
  recipient: string;
}

export interface PostalPayloadType {
  postcardRequest: UserSendPostalPayload;
  contactIds?: string[];
}

export interface PostalRequestType {
  templateId?: string;
  front: string;
  back: string;
  size: '4x6' | '6x9' | '6x11';
  send_date: string;
  previewContent: string;
  withStatus?: string;
  scheduleDate?: string;
  sendLaterDate?: string;
}

export interface UserSendPostalPayload {
  // kind: MessagePayloadKindsEnum.postal;
  templateId?: string;
  front: string;
  back: string;
  size: '4x6' | '6x9' | '6x11';
  previewContent: string;
  withStatus?: string;
  scheduleDate?: string;
  sendLaterDate?: string;
  contactIds?: string[];
  actionType?: string;
}

interface MessageEmailContentsType {
  content: string;
  kind: MessagePayloadKindsType;
  sendLaterDate: string;
  subject: string;
  templateId: string;
  recipients?: string;
  actionType?: string;
}

export interface SelectedUserSendEmailPayload
  extends UserSendCommunicationShared {
  messageContents: MessageEmailContentsType;
  contactIds: string[];
}
export interface SingleUserSendEmailPayload
  extends UserSendCommunicationShared {
  kind: MessagePayloadKindsEnum.email;
  subject: string;
  recipients?: string;
}

export interface CompletedTasks {
  taskId: string;
  status: string;
}

interface AppointmentStatusPayload {
  status: string;
}

interface TaskStatusPayload {
  tasks: CompletedTask[];
}

interface UserDataPayload extends NullableUserDataType {
  enabled?: boolean;
  password?: string;
}
export interface Provider {
  name: string;
  jobTitle?: string;
  emailAddress: string;
  isBookingActive: boolean;
}
export interface OutOfOfficeEvent {
  name: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  allDayEvent: boolean;
  repeat: number;
  repeatEndDate: string;
  tenantId: string;
}

export interface CompleteUserTaskPayload {
  tasks: {
    taskId: string;
    status: string; //TODO enum
  }[];
}

export interface CreateTenantUserPayload extends UserDataResponse {
  password: string;
}

export interface SoftDeleteUserRequestType extends UserDataResponse {
  userId: string;
}

interface MessageNotePayload {
  notes: string;
}

export interface ToggleSpeciePayload {
  hide: boolean;
  items: string[];
}

// union type of all post request object types
export type AxiosPostRequestDataObjects =
  | LoginRequest
  | SingleSendTextToPhonePayload
  | CreateTenantUserPayload
  | ToggleSpeciePayload
  | ResponseTypes.UserDetailEmailResponse
  | SingleUserSendTextPayload
  | SingleUserSendEmailPayload // This may not need to be here but I would expect our request to fail without it so I'm adding it in
  | ResponseTypes.AutomationSetting
  | GroupSystemAdminResponse
  | CreateGroupRequest
  | EmitterRequestType
  | FormData
  | UserDataResponse
  | SoftDeleteUserRequestType
  | MultipleContactSendTextPayload
  | MultipleUserSendTextPayload
  | SelectedUserSendEmailPayload
  | CreateFormBuilderRequestType
  | CloneFormBuilderRequestType
  | AppointmentTypeSettingRequestType
  | Partial<ProductResponseType>
  | MarkAsReadSubmissionPayload
  | PayloadToDeleteSubmissionDataType
  | PayloadToDeleteSubmissionDocument
  | NotifyBody
  | ApproveSubmissionPayloadType;

// union type of all patch request object types
export type AxiosPatchRequestDataObjects =
  | AppointmentSummaryEntry
  | AppointmentStatusPayload
  | TaskStatusPayload
  | UserDataPayload
  | MessageNotePayload
  | string[]
  | CompleteUserTaskPayload
  | TenantRequest
  | RenameFormRequired
  | Partial<ProductResponseType>
  | RenameFolderRequired
  | EditFormBuilderRequired
  | MoveFormRequired
  | ResponseTypes.TemplateData
  | AppointmentTypeSettingRequestType;

// union type of all put request object types
export type AxiosPutRequestDataObjects = {}; // TODO replace with Union type when we start using this

export interface GetCurrentTenantAppointmentInRangeArguments {
  startDate: string;
  endDate: string;
  options?: {
    userID?: string;
    limit?: number;
    offset?: number;
    kind?: string;
    withStatus?: string;
    withName?: string;
    isWithoutEndDate?: boolean;
  };
  isSearch?: boolean;
}

export interface GetCurrentUserAppointmentInRangeArguments {
  startDate: string;
  endDate: string;
  limit: number;
}

export type AxiosUpdateStatusAppointment = { status: string };

export interface CompletedTask {
  taskId: string;
  status: string;
  details: string;
  name: string;
}
export type AxiosUpdateCompletedTaskAppointment = {
  tasks: Array<CompletedTask>;
};

export type GeneralSettingRequest = {
  companyName: string;
  websiteUrl: string;
  websiteUseInFooters: boolean;
  emailReplyToAddress: string;
  timeZone: string;
  officeNumber: string;
  groupAppointmentMessagesByFamily: boolean;
  useValidatedPhoneNumbers: boolean;
  address: AddressEntry;
};

export type UserInformationType = UserDataResponse;

export type ValidAxiosRequest =
  | AxiosUpdateCompletedTaskAppointment
  | AxiosPatchRequestDataObjects
  | AxiosUpdateStatusAppointment
  | GeneralSettingRequest
  | ResponseTypes.SystemSettingResponseUpdated
  | UserInformationType
  | UserDataResponse;

export interface CommunicationTemplates {
  messageKind: string;
  ordinal: number;
  templateId: string;
  triggerTemplateId?: string;
}

export interface CreateAutomationTriggerEventRequest {
  enabled: boolean;
  appointmentTypes: AppointmentTypeResponse[];
  petSpecies: SpeciesSettingType[];
  allowSendOutsideDeliveryHours: boolean;
  categoryKind: string;
  automationTriggerKey: string;
  automationCategoryId: string;
  automationTriggerId: string;
  timeQuantity: number;
  timeUnit: string;
  scheduleKind: string;
  communicationTemplates: Array<CommunicationTemplates>;
}

interface rolePermissions {
  screenId: string;
  screenName: string;
}
export interface CreateRoleRequest {
  roleName: string;
  roleStatus: boolean;
  roleDescription: string;
  rolePermissions: Array<rolePermissions>;
}

export interface CreateDriverRequest {
  driverName: string;
  downloadLink: string;
  releasedDate: string;
  version: string;
}

export interface updateDriverRequest {
  driverId: string;
  driverName: string;
  downloadLink: string;
  releasedDate: string;
  version: string;
}

export interface CreateServiceRequest {
  downloadLink: string;
  releasedDate: string;
  version: string;
}

export interface updateServiceRequest {
  id: string;
  downloadLink: string;
  releasedDate: string;
  version: string;
}

export interface CreateConfiguratorRequest {
  downloadLink: string;
  releasedDate: string;
  releasedNotes: string;
  version: string;
}

export interface updateConfiguratorRequest {
  configuratorId: string;
  downloadLink: string;
  releasedDate: string;
  releaseNotes: string;
  version: string;
}
export interface SendEmailSupportSubmissionPayload {
  kind?: string;
  subject?: string;
  recipient: string;
  content: string;
  sendLaterDate?: string;
  actionType?: string;
}

// export interface CreateGroupRequest extends GroupSystemAdminResponse {

// }

export interface CreateGroupRequest {
  tenantGroupId?: string;
  name: string;
  status: string;
  practiceEmailAddress: string;
  practicePersonalDetails: [
    {
      userPersonalDetailsId?: string;
      givenName: string;
      lastName: string;
    }
  ];
  practicePhoneNumbers: [
    {
      phoneNumberId?: string;
      number: number;
      extension: string;
      isPrimary: boolean;
      kind: string;
    }
  ];
  addresses: [
    {
      userAddressId?: string;
      address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: number;
        country: string;
      };
      isPrimary: boolean;
      kind: string;
    }
  ];

  isSinglePractice: boolean;
}

export interface SetPasswordRequest {
  token: string;
  password: string;
}

export interface TenantRequest {
  tenantName: string;
  status: string;
  addresses: [
    {
      address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
      };
    }
  ];
  name: [
    {
      givenName: string;
      lastName: string;
    }
  ];
  emailAddress: string;
  phoneNumbers: [
    {
      number: string;
      extension: string;
      isPrimary: boolean;
      kind: string;
    }
  ];
}

export interface NotifyBody {
  kind: string;
  subject?: string;
  recipient: string;
  content: string;
  sendLaterDate?: string;
  templateId?: string;
  actionType?: string;
  appointmentBookingId?: string;
}
