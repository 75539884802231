import * as t from 'io-ts';


// LoginResponse
export const LoginResponseData = t.type({
  access_token: t.string,
  token_type: t.string,
  refresh_token: t.string,
  expires_in: t.number,
});
export type LoginResponse = t.TypeOf<typeof LoginResponseData>;
//

