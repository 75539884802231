const styles = {
  dateTimeContainer: {
    display: 'flex',
    width: '100%',
  },
  inputContainer: {
    flex: '1 0 46.7%',
    marginBottom: '15px',
  },
  calendarContainer: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  calendarStyles: {
    highlighted: {
      color: 'white',
      backgroundColor: '#4C7CFD',
    },
  },
  readOnly: {
    cursor: 'not-allowed',
    backgroundColor: '#d4d4d4',
    pointerEvents: 'none',
  },

  dayPicker: {
    borderRadius: '4px',
    border: '1px solid #ddd',
  }
};

export default styles;
