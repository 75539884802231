import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import SyncerStopPage from 'components/Alerts/SyncerStopPage/SyncerStopPage';
import SyncerError from 'components/SyncerErrors/SyncerError';

import { NotificationContext } from 'contexts/Notification';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

const Badge = styled.span`
  position: absolute;
  right: 5px;
  height: 23px;
  padding: 2px 6px;
  top: 50%;
  transform: translateY(-50%);
`;

const componentValues = [
  {
    innerText: 'Syncer Stoppages',
    switchValue: 1,
  },
  {
    innerText: 'Syncer Errors',
    switchValue: 2,
  },
];

const AlertSystemAdmin = (): JSX.Element => {
  const { countErrors, setCountErrors } = useContext(NotificationContext);

  const [activeComponent, setActiveComponent] = useState<number>(1);
  useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    setActiveComponent(
      tabActive.leftMenuContentActive === 0
        ? 1
        : tabActive.leftMenuContentActive
    );
  }, []);

  useEffect(() => {
    Promise.all([getSyncerAlerts(), getAlertErrors()]);
  }, [activeComponent]);

  const getAlertErrors = async () => {
    try {
      const res = await configuredRequests.GET.getCountAlertErrors();
      if (res) {
        // @ts-ignore
        const countErrorsUnRead = res?.data?.totalUnreads || 0;
        setCountErrors((prev) => ({ ...prev, errors: countErrorsUnRead }));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSyncerAlerts = async () => {
    try {
      const res = await configuredRequests.GET.getCountSyncerStopPages();
      if (res) {
        // @ts-ignore
        const countSyncerAlerts = res.data.totalUnreads || 0;
        setCountErrors((prev) => ({ ...prev, stoppages: countSyncerAlerts }));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleActiveLink = (numberActive: number) => {
    setTabActiveLocalStorage('', numberActive, 0);
    setActiveComponent(numberActive);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 1: {
        return <SyncerStopPage />;
      }
      case 2: {
        return <SyncerError reloadCountError={getAlertErrors} />;
      }
    }
    return <SyncerStopPage />;
  };

  const renderBadge = (index: number) => {
    const count = index === 1 ? countErrors.errors : countErrors.stoppages;

    return (
      <Badge className={`status ${count > 0 ? 'status--count' : ''}`}>
        {count}
      </Badge>
    );
  };

  return (
    <div className='communication-analyze settings--main-view-container'>
      <div className='communication-analyze__right-modal'>
        <ul className='analyze-tab__lists'>
          {componentValues.map((componentValue, index) => {
            return (
              <li
                key={'communication-templates-nav-' + index}
                tabIndex={0}
                className={
                  'analyze-tab__item-list ' +
                  (activeComponent === componentValue.switchValue
                    ? 'active'
                    : '')
                }
                onClick={() => {
                  handleActiveLink(componentValue.switchValue);
                }}
              >
                {componentValue.innerText}
                {renderBadge(index)}
              </li>
            );
          })}
        </ul>
      </div>

      {renderContainer()}
    </div>
  );
};

export default AlertSystemAdmin;
