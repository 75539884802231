const credits = {
  enabled: false,
};
const title = {
  text: '',
};

const pane = {
  startAngle: 0,
  endAngle: 360,
  background: [
    {
      outerRadius: '100%',
      innerRadius: '85%',
      backgroundColor: 'rgba(227,235,246,1)',
      borderWidth: 0,
    },
  ],
};

const chartType = {
  type: 'solidgauge',
  marginTop: 0,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const generateSeries = (): any[] => [
  {
    borderColor: '#000',
    enableMouseTracking: false,
    dataLabels: {
      borderColor: 'white',
      enabled: true,
      useHTML: true,
      style: {
        color: 'black',
        width: '150px',
        border: 'none',
      },
      format: ``,
    },
    data: [
      {
        color: 'rgba(48,104,253,1)',
        radius: '100%',
        innerRadius: '85%',
        y: 35,
        lineCap: 'round',
      },
    ],
  },
];

const plotOptions = {
  solidgauge: {
    dataLabels: {
      enabled: false,
    },
    linecap: 'round',
    stickyTracking: false,
    rounded: true,
  },
};

const yAxis = {
  min: 0,
  max: 100,
  lineWidth: 0,
  tickPositions: [],
};
export { credits, title, chartType, plotOptions, yAxis, pane, generateSeries };
