import styled from 'styled-components';

import { Card } from '../../cards';

const StyledCard = styled(Card)`
  padding: 0;
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledCard };
