import React, { useContext, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { fieldIsType, FormFieldBase, FormFieldInput, getMapFromArray } from '.';
import { getFieldPropertyValues } from './helpers';
import { EditContext } from '../../form-renderer';

export const FormFieldSpecieAndBreed = ({
  field,
  isRenderedField,
  commonProps,
  onChange,
  value,
  className,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const { edit, isAppointmentBooking } = useContext(EditContext);
  const [selectedSpecie, setSelectedSpecie] = useState('');

  const optionSpecies = useMemo(() => {
    return (field.fields[0].options ?? []).sort().map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
      };
    });
  }, [field]);

  const optionBreeds = useMemo(() => {
    return (field.fields[1].options ?? []).sort().map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
        idSpecie: arr[2].trim(),
      };
    });
  }, [field]);

  // useEffect(() => {
  //   console.log('de', optionSpecies);
  //   console.log('value', value[0]);
  //   setSelectedSpecie(value[0]);
  //   // if (isAppointmentBooking) {
  //   // } else {
  //   if (edit) {
  //     console.log('edit');
  //     if (value[0] && !optionSpecies.find(value[0])) {
  //       // setSelectedSpecie(value[0]);
  //       // } else {
  //       console.log('inside');
  //       field.fields[0].value = '';
  //       field.fields[1].value = '';
  //       field.fields[0].label = '';
  //       field.fields[1].label = '';
  //       // }
  //     }
  //   }
  // }, [value]);

  useEffect(() => {
    if (value) {
      setSelectedSpecie(value[0]);
      const specie = optionSpecies.find((item) => item.id === value[0]);
      if (specie) {
        field.fields[0].label = specie?.label ?? '';
        field.fields[0].value = specie?.id ?? '';

        const breeds = optionBreeds.filter(
          (item) => item.idSpecie === value[0] || item.idSpecie === 'null'
        );

        const breed = breeds.find((item) => item.id === value[1]) ?? breeds[0];
        if (breed) {
          field.fields[1].label = breed?.label ?? '';
          field.fields[1].value = breed?.id ?? '';
          onChange(
            {
              target: { id: field.fields[1].uuid, value: breed?.id || '' },
            },
            false,
            false,
            false
          );
        }
      }
    }
  }, [value[0], value[1], optionBreeds, optionSpecies]);

  useEffect(() => {
    if (
      edit &&
      value[0] &&
      !optionSpecies.find((option) => option.id === value[0])
    ) {
      field.fields[0].value = '';
      field.fields[1].value = '';
      field.fields[0].label = '';
      field.fields[1].label = '';
      // }
    }
    // if (edit) {
    //   setSelectedSpecie()
    // }
  }, [edit]);

  const getFieldSelectOptions = (field) => {
    if (!isRenderedField) return;

    if (fieldIsType(field, 'Pet Species', 'display_name')) {
      if (readOnly) {
        return <option>{field.label}</option>;
      }
      const listSpecies = [
        { label: 'Select Pet Species', id: '' },
        ...optionSpecies,
      ];
      return listSpecies.map((specie, idx) => (
        <option hidden={specie.id === ''} key={idx} value={specie.id}>
          {specie.label}
        </option>
      ));
    }
    if (fieldIsType(field, 'Pet Breed', 'display_name')) {
      if (readOnly) {
        return <option>{field.label}</option>;
      }
      return (
        <>
          {optionBreeds
            .filter(
              (breed) =>
                breed.idSpecie === selectedSpecie || breed.idSpecie === 'null'
            )
            .map((item, idx) => (
              <option hidden={item.id === ''} key={idx} value={item.id}>
                {item.label}
              </option>
            ))}
        </>
      );
    }
  };

  const handleChangeSpecies = (e) => {
    onChange(e);
    // if (e.target.value) {
    //   const newSpecies = e.target.value;
    //   const breed = optionBreeds.find(
    //     (item) => item.idSpecie === newSpecies || item.idSpecie === 'null'
    //   );
    //   field.fields[1].value = breed?.id ?? '';
    // }
  };

  const handleChangeBreed = (e) => {
    onChange(e);
  };

  const { fields } = field;
  const fieldMap = new Map();

  fields
    .sort((a, b) => a.sort_order - b.sort_order) // First: make sure sub-fields are properly sorted
    .map((sf, index) => {
      const subfield = { ...sf };
      if (isRenderedField && value[index] !== undefined)
        subfield.value = value[index];
      fieldMap.set(index, [subfield]);
    });

  const classes = classNames({
    [className]: true,
    subfields: true,
    'specie-breed': true,
    'control-group error': validationMessage,
  });

  return (
    <div key={field.uuid || commonProps.label} className={classes}>
      <div
        className='form-field-label primary'
        style={{ paddingLeft: '15px' }}
      >{`${commonProps.label} ${commonProps.makeRequired ? '*' : ''}`}</div>
      <div className='flex'>
        {Array.from(fieldMap).map((fields, key) => {
          return (
            <React.Fragment key={key}>
              {fields[1].map((el) => {
                const properties = getMapFromArray(el.properties);
                const propertyNames = ['sublabel'];
                const defaultValues = { sublabel: '' };
                const propertyValues = getFieldPropertyValues(
                  properties,
                  propertyNames,
                  defaultValues
                );
                return (
                  <FormFieldInput
                    key={el.id}
                    field={el}
                    children={getFieldSelectOptions(el)}
                    options={{
                      properties,
                      sublabel: propertyValues.sublabel,
                      subfield: true,
                      isRenderedField,
                      onChange:
                        el.display_name === 'Pet Breed'
                          ? handleChangeBreed
                          : handleChangeSpecies,
                      value: el.value || '',
                      onBlur,
                      validationMessage,
                      readOnly,
                    }}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

FormFieldSpecieAndBreed.defaultProps = {
  isRenderedField: false,
  onChange: () => {},
  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldSpecieAndBreed);
