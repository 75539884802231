import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const smallWhiteText = styled(Typography)`
  font-size: ${sizeConstants.small.size};
  line-height: ${sizeConstants.small.height};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: rgba(255, 255, 255, 0.87);
` as typeof Typography;
export default smallWhiteText;
