import { AvailabilityWrapper } from '../Availability/AvailabilityWrapper';
import styled from 'styled-components';

export const ProviderAvailabilityWrapper = styled(AvailabilityWrapper)`
  .setting-content {

    > div:first-child {

      .toogle-date-picker {

        .toogle-operation {
          margin-left: -1.5rem;
        }
      }
    }


      .error-text {
        margin-right: -10rem;

    }
  }

  p.title-header {
    margin-top: 0;
  }

  .button-group-add-office {
    .delete-button {
      height: initial;
    }
  }

  .react-modal--content {

  }
`;