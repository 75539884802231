/* eslint-disable no-eval */
import React from 'react';
import update from 'react-addons-update';
import { withRouter, Prompt } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectWithTopLabel, CircleLoadingSpinner } from '../../../../Legwork';
import SubmissionsDetailTab from 'components/FormBuilder/components/specify/SubmittedFormContainer/SubmissionsDetailTab';
import SubmittedFormsContainer from 'components/FormBuilder/components/specify/SubmittedFormContainer';
import CloneFormModal from 'containers/PaperlessForms/FormBuilder/components/CloneFormModal/CloneFormModal';
import MoveFormModal from 'components/FormBuilder/components/specify/MoveFormModal';
import RenameFormModal from 'components/FormBuilder/components/specify/RenameFormModal';
import PropTypes from 'prop-types';
import DeleteFormModal from 'containers/PaperlessForms/FormBuilder/components/DeleteFormModal/DeleteFormModal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';
import utils from '../../../utils';
import { appointmentBookingMockup } from 'utils/mockupData';
import { BASIC_FIELDS } from './components/constants';
import { WrapperErrorDuplicate } from './components/form-item-build';

/* Layout and Structure components */
import { Tabs, Tab } from '../../tabs';
/* Modals components */

/* child components */
// import FormActionsModal from './components/form-actions-modal';
import FormBuildTab from './components/form-build-tab';
import FormItemSettings from './components/form-item-settings';
import FormItemShare from './components/form-item-share';
import * as FormFieldTypes from './components/form-field-types/helpers';
import FormRenderer from './form-renderer';
import FormItemHeader from './form-item-header';
import 'components/FormBuilder/components/FormBuilder/form-builder.scss';
import Result from 'components/FormBuilder/components/FormBuilder/forms/components/temp_formitem_const';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { FormRender, InnerFormRender } from './styled-components';
import { cloneDeep, get } from 'lodash';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import styled from 'styled-components';
import { getPatientPortalUrl, getRootDomain } from 'global/constants/url';

const SelectActions = styled.div`
  .MuiSelect-select {
    &:focus {
      border-radius: 6px;
      background-color: inherit;
    }
  }
`;

const MenuItems = [
  { display: 'Actions', value: 'Actions', disabled: true },
  { display: 'Clone Form', value: 'Clone', disabled: false },
  { display: 'Move Form', value: 'Move', disabled: false },
  { display: 'Delete Form', value: 'Delete', disabled: false },
];

const { origin: baseURL } = window.location;

const getArrayElementIndexByKeyValue = (sourceArray = [], key, value) =>
  sourceArray.findIndex((el) => el[key] === value);

class FormItem extends React.Component {
  constructor(props) {
    super(props);

    const { match, location } = props;

    this.pathname = match.url;
    this.params = match.params.params;

    if (this.params) this.params = JSON.parse(decodeURIComponent(this.params));

    if (location.search && location.search.length > 0) {
      const search = location.search.replace('?', '').split('&');
      const action = search.find((search) => search.split('=')[0] === 'action');
      if (action) {
        this.action = action.split('=')[1];
      }
    }

    this.state = {
      formVersion: {},
      availableFields: [],
      basicFields: [],
      advancedFields: [],
      loadingError: null,
      isDirtyVersion: false,
      isDirtySettings: false,
      showActionModal: false,
      action: null,
      activeField: null,
      activeNestedField: null,
      hoverField: null,
      hoverLocation: null,
      activeTab: this.action || this.props.activeTab || null,
      groups: [],
      contacts: [],
      formGroups: [],
      formContacts: [],
      isLoading: true,
      optionDataSubmissionFilter: [],
      resetSelect: false,
      errorDuplicate: false,
    };

    this.getAdvandeFields = this.getAdvandeFields.bind(this);
    this.getBasicFields = this.getBasicFields.bind(this);
    this.getFormById = this.getFormById.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    // TODO: verify this is acceptable
    if (this.isDirty) window.onbeforeunload = () => this.isDirty;
    else window.onbeforeunload = null;
    if (prevProps.formId !== this.props.formId) {
      this.loadData();
    }
  }

  get formId() {
    const { match, formId } = this.props;
    return match.params.formId || formId;
  }

  get isRestrictedForm() {
    return this.state.formVersion && this.state.formVersion.is_restricted;
  }

  get isDirty() {
    return this.state.isDirtyVersion || this.state.isDirtySettings;
  }
  async getAdvandeFields() {
    try {
      const response = await configuredRequests.GET.getAdvandeFields();

      if (response.status === 200) {
        // add fields for smart form
        const fields = response.data.items;
        const indexOptional = fields.findIndex(
          (item) => item.display_name === 'Optional'
        );
        fields[indexOptional].fields = [];

        return get({ items: fields }, 'items', []);
      }
    } catch (errors) {
      console.log('errors');
    }
  }

  async getBasicFields() {
    try {
      const response = await configuredRequests.GET.getBasicFields();

      if (response.status !== 200) return;

      const items = get(response.data, 'items', []);
      if (this.pathname === '/AppointmentBooking') return items;

      const noteIndex = items.findIndex((_) => _.display_name === 'Note');

      if (noteIndex > -1) {
        items.splice(noteIndex, 1);
      }

      return items;
    } catch (errors) {
      console.log('errors');
    }
  }

  async getFormById(id) {
    try {
      const response = this.props.isAppointmentForm
        ? await configuredRequests.GET.getFormAppointmentBooking()
        : await configuredRequests.GET.getFormById(id);

      if (response.status === 200) return get(response, 'data', {});
    } catch (errors) {
      console.log('errors');
    }
  }

  async getGroups() {
    try {
      const response = await configuredRequests.GET.getDocumentTypes();
      if (response) return get(response, 'data', []);
    } catch (errors) {
      console.log('errors');
    }
  }

  async getContacts() {
    try {
      const allContacts =
        await configuredRequests.GET.allUserInfoByCurrentTenant({
          userKinds: ['Customer', 'User', 'Admin'],
        });
      if (allContacts) return get(allContacts, 'users', []);
    } catch (errors) {
      console.log('errors');
    }
  }

  async getOptionDataSubmissionFilter() {
    try {
      const dropdownData =
        await configuredRequests.GET.getOptionDataSubmissionFilter();
      if (dropdownData) return dropdownData;
    } catch (err) {}
  }

  loadData = async () => {
    const methods = {
      FormVersionsCurrent: 0,
      FormFieldsAvailable: 1,
      FormFieldTypes: 2,
      Groups: 3,
      Contacts: 4,
      OptionDataSubmissionFilter: 5,
      FormGroups: 6,
      FormContacts: 7,
      ShareLink: 8,
      rpc: {
        0: this.getFormById(this.formId),
        1: this.getBasicFields(),
        2: this.getAdvandeFields(),
        3: this.getGroups(),
        4: this.getContacts(),
        5: this.getOptionDataSubmissionFilter(),
        // 5: PaperlessFormsService.formBuilder.getGenericFormBuilderElement([
        //   'FormGroups',
        //   { form_id: this.formId, is_deleted: false },
        // ]),
        // 6: PaperlessFormsService.formBuilder.getGenericFormBuilderElement([
        //   'FormContacts',
        //   { form_id: this.formId, is_deleted: false },
        // ]),
        // // 7: ss.rpc('syncer.getDocumentCenterDocumentTypes'),
      },
    };
    const nextState = {};

    const earlyResults = await Promise.all(Object.values(methods.rpc));
    const results = earlyResults.map((v, i) => {
      if (i === 3) return v;
      return v;
    });
    nextState.formVersion = {
      ...results[0],
      object:
        results[0] && results[0].object ? JSON.parse(results[0].object) : {},
      embed_code: this.getEmbedCode(results[0]?.never_published),
    };
    nextState.basicFields = this.loadBasicFields(
      results[methods.FormFieldsAvailable]
    );
    nextState.advancedFields = this.loadAdvancedFields(
      results[methods.FormFieldTypes]
    );
    nextState.groups = results[methods.Groups].map((group) => ({
      label: group.name,
      value: group.id,
    }));
    nextState.contacts = results[methods.Contacts].map((contact) => ({
      label: contact.name,
      value: contact.id,
    }));
    nextState.formGroups = Result[methods.FormGroups];
    this.replicateFormGroups = Result[methods.FormGroups];
    nextState.formContacts = Result[methods.FormContacts];
    this.replicateFormContacts = Result[methods.FormContacts];
    // nextState.shareLink = Result[methods.ShareLink];
    nextState.isLoading = false;
    nextState.optionDataSubmissionFilter = results[
      methods.OptionDataSubmissionFilter
    ]?.map((item) => ({
      label: item.name,
      value: item.value,
    }));
    this.setState(nextState);
  };

  loadBasicFields = (fields) => {
    return FormFieldTypes.groupFields(fields);
  };

  loadAdvancedFields = (fields) => {
    // sort and map type_name to display_name
    return fields
      .map((field) => {
        field.display_name = field.type_name;
        return field;
      })
      .sort((a, b) => a.sort_order - b.sort_order);
  };

  handleNameClick = (e) => {
    e.preventDefault();
    if (this.isRestrictedForm || this.props.hideChrome) {
      return;
    }
    this.setState({
      action: 'Rename',
    });
  };

  handleFormAction = (form, action, args) => {
    const { formVersion } = this.state;
    this.props.onAction(form, action, args, (newState, row) => {
      const nextState = { showActionModal: false };

      if (row) nextState.formVersion = Object.assign(formVersion, row);
      else if (newState.formError) nextState.loadingError = newState.formError;

      if (action === 'delete' && !nextState.loadingError)
        this.props.history.goBack();
      else this.setState(nextState);
    });
  };

  handleDeleteForm = () => {
    this.props.onDelete(this.state.formVersion, (newState) => {
      if (newState.formError)
        this.setState({
          loadingError: newState.formError.message || newState.formError,
        });
      else this.props.history.goBack();
    });
  };

  saveFormVersion = async (options = {}) => {
    const { formVersion } = this.state;
    const { onVersionSave } = this.props;
    const newVersion = { ...formVersion };
    const versionNumber = formVersion.number || '0.0';

    // use major.minor semantic versioning (1.0) for now
    // remaining incrementing logic will work for other variations of semantic versioning
    const numbers = versionNumber.split('.');

    if (options.publish) {
      // publish should increment the largest version number and reset remaining to 0 (e.g.: 1.1 > 2.0)
      numbers[0] = parseInt(numbers[0], 10) + 1;
      for (let i = 1; i < numbers.length; i++) {
        numbers[i] = 0;
      }
    } else {
      // saving should increment the smallest version number
      //  e.g.: 1.0.0 > 1.0.1; 1.0 > 1.1; 1 > 2
      numbers[numbers.length - 1] = parseInt(numbers[numbers.length - 1]) + 1;
    }
    newVersion.number = numbers.join('.');

    // set is_published flag accordingly
    newVersion.is_published = options.publish;

    if (options.publish && newVersion.never_published)
      newVersion.never_published = false;

    await onVersionSave(newVersion, (result) => {
      this.setState({
        formVersion: result.newVersion,
        isDirtyVersion: false,
      });
    });
  };

  handleSaveClick = async () => {
    // const { isDirtySettings, isDirtyVersion } = this.state;

    // if (!isDirtySettings && !isDirtyVersion) return;
    // if (isDirtySettings) await this.saveSettings();
    // if (isDirtyVersion) {
    //   await this.saveFormVersion();
    // }
    // //reload version
    // const formVersionFromApiCall = await PaperlessFormsService.formBuilder.getGenericFormBuilderElement(
    //   ['FormVersionsCurrent', { id: this.formId }],
    // );
    // const formVersion = formVersionFromApiCall.p[1][0];
    // this.setState({ formVersion });
    this.saveSettings();
  };

  handlePublishClick = async () => {
    // const options = { publish: true };
    // await this.saveSettings(options);
    // await this.saveFormVersion(options);
    // const formVersionFromApiCall = await PaperlessFormsService.formBuilder.getGenericFormBuilderElement(
    //   ['FormVersionsCurrent', { id: this.formId }],
    // );
    // const formVersion = formVersionFromApiCall.p[1][0];

    // this.setState({ formVersion });
    if (!this.state.formVersion.is_published) {
      this.saveSettings(true);
    } else if (this.state.formVersion.is_published && this.isDirty) {
      this.saveSettings(true);
    }
  };

  saveSettings = async (isPublish = false) => {
    try {
      const { formVersion, contacts, formContacts } = this.state;
      let object = cloneDeep(formVersion.object);

      // check duplicate field
      for (let i = 0; i < BASIC_FIELDS.length; i++) {
        const countOfField = object.fields?.filter(
          (field) => field.display_name === BASIC_FIELDS[i]
        );
        if (countOfField.length > 1) {
          this.setState((prevState) => ({
            ...prevState,
            errorDuplicate: true,
          }));
          return;
        }
      }
      
      let countOfUploadField = object.fields?.filter(
        (field) => field.type_name === 'Document Upload'
      );
      if (countOfUploadField.length > 1) {
        this.setState((prevState) => ({
          ...prevState,
          errorDuplicate: true,
        }));
        return;
      }

      // const objectFields = object.fields.map((field) => {
      //   field.options && delete field.options;
      //   field.fields && field.fields.forEach((subF) => subF.options && delete subF.options);
      //   return field;
      // });
      const objectFields = object.fields.map((field) => {
        if (['Section Break', 'Optional'].includes(field.display_name)) {
          const subField = field.fields.map((item) => {
            if (item?.fields?.length > 0) {
              const subItem = item.fields.map((subI) => {
                delete subI.options;
                return subI;
              });
              return { ...item, fields: subItem };
            }
            return item;
          });
          return { ...field, fields: subField };
        } else if (field?.fields?.length > 0) {
          const subField = field.fields.map((subF) => {
            delete subF.options;
            return subF;
          });
          return { ...field, fields: subField };
        }
        return field;
      });
      object = { ...object, fields: objectFields };

      const dataToSave = {
        id: formVersion.id,
        is_publishing: isPublish,
        object: JSON.stringify(object),
        notificationIds: formVersion.IsSendSubmissionEmail
          ? formContacts.length
            ? formContacts
            : [contacts[0].value]
          : [],
        form_name: formVersion.form_name,
        isSyncPatientDocument: formVersion?.isSyncPatientDocument ?? false,
        documentTypeId: formVersion.isSyncPatientDocument
          ? formVersion.documentTypeId
          : undefined,
        IsSendSubmissionEmail: formVersion?.IsSendSubmissionEmail ?? false,
        is_redirect: formVersion?.is_redirect ?? false,
        thank_you: formVersion.is_redirect ? undefined : formVersion.thank_you,
        redirect_url: formVersion.is_redirect
          ? formVersion.redirect_url
          : undefined,
        allowDisplayInMicrosites: formVersion.allowDisplayInMicrosites ?? true,
      };

      const response = this.props.isAppointmentForm
        ? await configuredRequests.PATCH.editFormBooking(dataToSave)
        : await configuredRequests.PATCH.editForm(dataToSave);
      if (response.status === 200) {
        this.setState({
          isDirtyVersion: false,
          isDirtySettings: false,
        });
        this.loadData();
      }
    } catch (errors) {
      console.log('errors: ', errors);
    }

    // const { formVersion, formGroups, formContacts } = this.state;
    // const { onFormSave } = this.props;
    // const newVersion = { ...formVersion };

    // if (options.publish) {
    //   // flip form.is_published to true
    //   newVersion.is_published = true;

    //   // since we're publishing, we need to also ensure we have a valid Embed Code
    //   if (!newVersion.embed_code || newVersion.embed_code !== this.embed_code)
    //     newVersion.embed_code = this.getEmbedCode();
    // }

    // await onFormSave(newVersion, formGroups, formContacts, results => {
    //   // merge new Form object into fromVersion from state

    //   this.setState({
    //     isDirtySettings: false,
    //     formVersion: { ...newVersion, ...results.newData[0] },
    //     formGroups: results.newData.filter(result => result.hasOwnProperty('group_id')),
    //     formContacts: results.newData.filter(result => result.hasOwnProperty('contact_id')),
    //   });
    // });
  };

  getEmbedCode = (neverPublish = false) => {
    const { formVersion } = this.state;
    const hasSecureId = formVersion && formVersion.secure_form_id;
    const tenant = JSON.parse(
      sessionStorage.getItem('tenantInfo--full_response')
    );
    const micrositeName = tenant.micrositeName;

    return (
      '<link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css">' +
      '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap" rel="stylesheet">' +
      `<div id="${this.formId}"${
        hasSecureId ? ` data-checksum="${formVersion.secure_form_id}"` : ''
      } class="form-container ` +
      `${getRootDomain()} ${micrositeName}"></div>` +
      `<script src="${baseURL}/assets/jquery.js"></script>` +
      `<script src="${baseURL}/assets/forms.js" charset="utf-8" defer></script>`
    );
  };

  handleDropField = (field, move, sourceSectionBreak) => {
    // this drop gets called from the main canvas
    // if we have a @sourceSectionBreak object, then we're dragging it UP to the main canvas
    // otherwise we're dragging from and to the main canvas
    let { formVersion } = this.state;
    let fields = [];
    let dropFn;

    if (!formVersion.hasOwnProperty('object') || formVersion.object === null)
      formVersion.object = {};
    if (sourceSectionBreak && move) {
      this.sectionBreakMoveFieldOut(field, sourceSectionBreak);
      return;
    }

    fields = formVersion.object.fields || [];

    if (move) dropFn = this.handleMoveField;
    else dropFn = this.handleNewField;

    const newFields = dropFn(fields, field);

    formVersion = update(formVersion, {
      object: {
        fields: {
          $set: newFields,
        },
      },
    });

    this.setState({
      formVersion,
      isDirtyVersion: true,
      hoverField: null,
      hoverLocation: null,
    });
  };

  handleFieldClick = (field, parent) => {
    const nextState = { hoverField: null, hoverLocation: null };
    if (parent) {
      nextState.activeField = parent;
      nextState.activeNestedField = field;
    } else {
      nextState.activeField = field;
      nextState.activeNestedField = null;
    }
    this.setState(nextState);
  };

  handleDeleteField = (field, parent) => {
    this.setState({
      deleteFormFieldOpen: true,
      activeDeleteField: field,
      activeParent: parent,
    });
  };

  handleAcceptDelete = () => {
    this.deleteField();
    this.closeDeleteModal();
  };

  closeDeleteModal = () => {
    this.setState({
      deleteFormFieldOpen: false,
      activeDeleteField: null,
      activeParent: null,
    });
  };

  deleteField = () => {
    const field = this.state.activeDeleteField;
    const parent = this.state.activeParent;

    const { formVersion } = this.state;
    let index;
    let parentIndex;
    let fields;

    if (!formVersion.hasOwnProperty('object') || !formVersion.object) return;
    if (!formVersion.object.fields || formVersion.object.fields.length === 0)
      return;

    if (parent) {
      fields = parent.fields || [];
      parentIndex = formVersion.object.fields.findIndex(
        (f) => f.uuid === parent.uuid
      );
    } else {
      fields = formVersion.object.fields;
    }

    index = fields.findIndex((f) => f.uuid === field.uuid);
    if (index < 0) return;

    // remove the field
    fields.splice(index, 1);

    // check if only one field remains and if it's the Submit field
    // then empty out our fields array
    if (fields.length === 1 && fields[0].type_name === 'Submit') fields = [];

    // update form version accordingly
    if (parent) formVersion.object.fields[parentIndex].fields = [...fields];
    else formVersion.object.fields = [...fields];

    // update state
    this.setState({
      formVersion,
      isDirtyVersion: true,
      activeField: null,
      activeNestedField: null,
    });
  };

  handleFieldHover = (field, location) => {
    // TODO: figure out scenario where hoverField and hoverLocation
    // are set to values even after a drop event
    this.setState({
      hoverField: field,
      hoverLocation: location,
    });
  };

  handleNestedDrop = (parent, field, move, source) => {
    // this drop gets called from a section break
    // @parent is the destination Section Break
    // @source is the source Section Break
    // @source can be:
    //   equal to @parent: moving the field around within the same Section Break
    //   not equal to @parent: moving the field "across" from one Section Break (@source) to another (@parent)
    //   falsey: moving the field from the main canvas into the destination (@parent)
    let { formVersion } = this.state;

    let dropFn;
    const isNested = true;

    if (move) {
      if (!source) {
        this.sectionBreakMoveFieldIn(field, parent);
        return;
      }
      if (parent.uuid !== source.uuid) {
        this.sectionBreakMoveFieldAcross(field, source, parent);
        return;
      }
    }

    // the rest is either moving an existing field or adding a new field w/in a section break
    if (!formVersion.hasOwnProperty('object') || formVersion.object === null)
      formVersion.object = {};
    const fields = formVersion.object.fields || [];

    const parentIndex = fields.findIndex((f) => f.uuid === parent.uuid);
    const parentFields = parent.fields || [];

    if (move) dropFn = this.handleMoveField;
    else dropFn = this.handleNewField;

    const newFields = dropFn(parentFields, field, isNested);

    formVersion = update(formVersion, {
      object: {
        fields: {
          [eval(parentIndex)]: {
            fields: {
              $set: newFields,
            },
          },
        },
      },
    });

    // update state
    this.setState({
      formVersion,
      isDirtyVersion: true,
      hoverField: null,
      hoverLocation: null,
    });
  };

  handleSectionBreakMove = (field, source, destination) => {
    const sourceIndex = source.findIndex((f) => f.uuid === field.uuid);
    source.splice(sourceIndex, 1);

    const { hoverField, hoverLocation } = this.state;
    let toIndex = hoverField
      ? destination.findIndex((f) => f.uuid === hoverField.uuid)
      : destination.length - 1;

    if (this.shouldPlaceFieldAfterHoveredField(hoverLocation)) ++toIndex;
    destination.splice(toIndex, 0, field);
  };

  sectionBreakMoveFieldOut = (field, sectionBreak) => {
    // add @field to this.state.formVersion.object.fields
    // remove @field from @sectionBreak.fields
    const formVersion = { ...this.state.formVersion };
    const sourceFields = [...sectionBreak.fields];
    const destinationFields = [...formVersion.object.fields];

    this.handleSectionBreakMove(field, sourceFields, destinationFields);

    destinationFields[
      getArrayElementIndexByKeyValue(
        destinationFields,
        'uuid',
        sectionBreak.uuid
      )
    ] = { ...sectionBreak, fields: [...sourceFields] };
    formVersion.object.fields = destinationFields;

    this.setState({
      formVersion,
      isDirtyVersion: true,
      hoverField: null,
      hoverLocation: null,
    });
  };

  sectionBreakMoveFieldIn = (field, sectionBreak) => {
    // add @field to @sectionBreak.fields
    // remove @field from this.state.formVersion.object.fields
    const formVersion = { ...this.state.formVersion };
    const sourceFields = [...formVersion.object.fields];
    const destinationFields = [...sectionBreak.fields];

    this.handleSectionBreakMove(field, sourceFields, destinationFields);

    sourceFields[
      getArrayElementIndexByKeyValue(sourceFields, 'uuid', sectionBreak.uuid)
    ] = {
      ...sectionBreak,
      fields: [...destinationFields],
    };
    formVersion.object.fields = sourceFields;

    this.setState({
      formVersion,
      isDirtyVersion: true,
      hoverField: null,
      hoverLocation: null,
    });
  };

  sectionBreakMoveFieldAcross = (
    field,
    sourceSectionBreak,
    destinationSectionBreak
  ) => {
    // add @field to @destinationSectionBreak.fields
    // remove @field from @sourceSectionBreak.fields
    const formVersion = { ...this.state.formVersion };
    const updateFields = formVersion.object.fields;
    const sourceFields = [...sourceSectionBreak.fields];
    const destinationFields = [...destinationSectionBreak.fields];

    this.handleSectionBreakMove(field, sourceFields, destinationFields);

    updateFields[
      getArrayElementIndexByKeyValue(
        updateFields,
        'uuid',
        sourceSectionBreak.uuid
      )
    ] = {
      ...sourceSectionBreak,
      fields: [...sourceFields],
    };
    updateFields[
      getArrayElementIndexByKeyValue(
        updateFields,
        'uuid',
        destinationSectionBreak.uuid
      )
    ] = { ...destinationSectionBreak, fields: [...destinationFields] };
    formVersion.object.fields = updateFields;

    this.setState({
      formVersion,
      isDirtyVersion: true,
      hoverField: null,
      hoverLocation: null,
    });
  };

  handleMoveField = (fields, field) => {
    const { hoverField, hoverLocation } = this.state;
    let fromIndex;
    let toIndex;

    if (hoverField)
      toIndex = fields.findIndex((f) => f.uuid === hoverField.uuid);
    else toIndex = fields.length; // move to the end

    // if moving to the end, make sure it's before the "Submit" field
    if (toIndex === fields.length) --toIndex;

    // find fromIndex which is the index of field in fields array from state
    fromIndex = fields.findIndex((f) => f.uuid === field.uuid);

    if (fromIndex === -1) return fields;

    if (
      fromIndex < toIndex &&
      this.shouldPlaceFieldBeforeHoveredField(hoverLocation) &&
      toIndex > 0
    )
      --toIndex;
    else if (
      fromIndex > toIndex &&
      this.shouldPlaceFieldAfterHoveredField(hoverLocation) &&
      toIndex < fields.length
    )
      ++toIndex;

    if (fromIndex !== toIndex) {
      fields.splice(fromIndex, 1);
      return update(fields, {
        $splice: [[eval(toIndex), 0, field]],
      });
    }
    return fields;
  };

  handleNewField = (fields, field, isNested) => {
    const { hoverField, hoverLocation } = this.state;
    let toIndex;
    const newFields = !fields ? [] : [...fields];

    // check if this is the first field being added to the form
    // if it is, we must also add a "Submit" field (button) to the fields list
    if (!isNested && newFields.length === 0) {
      newFields.push(FormFieldTypes.createSubmitButton());
    }
    // add uuid to field for better unique id of dropped field
    // also add default properties object which will get updated in mapFieldProps()
    const newField = update(field, {
      $merge: {
        uuid: uuidv4(),
        properties: {},
      },
    });

    FormFieldTypes.mapFieldProps(newField);
    FormFieldTypes.setNewFieldDefaults(newField);

    // figure out appropriate place to add this new field
    if (hoverField)
      toIndex = fields.findIndex((f) => f.uuid === hoverField.uuid);
    else toIndex = fields.length - 1; // insert at the end (but before the "Submit" field)

    if (this.shouldPlaceFieldAfterHoveredField(hoverLocation)) ++toIndex;

    return update(newFields, {
      $splice: [[eval(toIndex), 0, newField]],
    });
  };

  shouldPlaceFieldBeforeHoveredField = (hoverLocation) =>
    ['top', 'left'].includes(hoverLocation);

  shouldPlaceFieldAfterHoveredField = (hoverLocation) =>
    ['bottom', 'right'].includes(hoverLocation);

  handleFieldChange = (
    field,
    parent,
    isCommonProp,
    propertyName,
    newValue,
    index
  ) => {
    const propMap = FormFieldTypes.getMapFromArray(
      isCommonProp ? field.properties.common : field.properties.specific
    );

    let { value, ...rest } = propMap.get(propertyName);
    const { formVersion } = this.state;
    let newField;
    let newSubFields;

    // handle smart from when remove options
    if (field.display_name === 'Optional' && propertyName === 'options') {
      if (newValue.length !== value.length) {
        // these fields need change of_option
        const subFieldsNeedChange = field.fields.filter(
          (item) => item.of_option > index
        );
        const subFieldsAfterChange = subFieldsNeedChange.map((item) => ({
          ...item,
          of_option:
            newValue.length > value.length
              ? item.of_option + 1
              : item.of_option - 1,
        }));
        newSubFields = [
          ...field.fields.filter((item) => {
            if (newValue.length > value.length) return item.of_option <= index;
            return item.of_option < index;
          }),
          ...subFieldsAfterChange,
        ];
      }
    }

    // custom condition if the property being changed is either
    // 'makeRequired' or 'readOnly': both properties cannot be simulataneously true
    // TODO: figure out a more dynamic way of implementing this logic
    if (
      ['makeRequired', 'readOnly'].includes(propertyName) &&
      newValue === true
    ) {
      const otherPropName =
        propertyName === 'makeRequired' ? 'readOnly' : 'makeRequired';
      const { value: otherValue, ...otherProps } = propMap.get(otherPropName);

      // other prop can't *also* be true, so force it to false
      if (otherValue === true)
        propMap.set(otherPropName, { value: false, ...otherProps });
    }

    value = newValue;

    // update appropriate property Map object w/ the new value
    propMap.set(propertyName, { value, ...rest });

    // make sure formVersion has an 'object' object
    if (!formVersion.hasOwnProperty('object') || formVersion.object === null)
      formVersion.object = {};

    // set appropriate properties (common or specific) w/ new Map object
    // convert Map object to array for consistency and to properly support
    // cloning the formVersion object when previewing (Map objects don't get cloned properly)
    if (isCommonProp)
      newField = update(field, {
        properties: { common: { $set: Array.from(propMap) } },
      });
    else {
      if (newSubFields) {
        newField = update(field, {
          properties: { specific: { $set: Array.from(propMap) } },
          fields: { $set: newSubFields },
        });
      } else {
        newField = update(field, {
          properties: { specific: { $set: Array.from(propMap) } },
        });
      }
    }

    // update appropriate fields array in formVersion and state
    this.updateFormField(field, newField, parent);

    // custom logic: if this is a date/time field, and the property being changed
    // is the date separator, then we need to update the date format property
    // value to reflect the correct separator
    if (propertyName === 'separator')
      this.handleDateSeparatorChange(
        field,
        parent,
        propertyName,
        propMap,
        newValue,
        isCommonProp
      );
  };

  handleDateSeparatorChange = (
    field,
    parent,
    sourceProp,
    propMap,
    newValue,
    isCommonProp
  ) => {
    const newMapData = [];

    const { mappedPropKey } = propMap.get(sourceProp);
    let { value, options, ...rest } = propMap.get(mappedPropKey);
    let currentSeparator;
    let newField;

    // determine current separator value stored in dateFormat property
    if (String(value).indexOf('/') >= 0) currentSeparator = '/';
    if (String(value).indexOf('-') >= 0) currentSeparator = '-';
    if (String(value).indexOf('.') >= 0) currentSeparator = /\./;

    const regex = new RegExp(currentSeparator, 'g');

    // update current dateFormat value
    value = value.replace(regex, newValue);

    // update dateFormat options
    options = options.map((option) => option.replace(regex, newValue));

    // update property map with new value
    propMap.set(mappedPropKey, { value, options, ...rest });
    propMap.forEach((key, value) => {
      newMapData.push([value, key]);
    });
    // update appropriate field's properties
    if (isCommonProp)
      newField = update(field, {
        properties: { common: { $set: newMapData } },
      });
    else
      newField = update(field, {
        properties: { specific: { $set: newMapData } },
      });

    this.updateFormField(field, newField, parent);
  };

  handleSubfieldChange = (subfield, field, parent, propertyName, newValue) => {
    const propMap = FormFieldTypes.getMapFromArray(subfield.properties);
    let { value, ...rest } = propMap.get(propertyName);
    const { fields } = field;
    let newSubfield;
    let newField;
    let index;

    value = newValue;

    // update property w/ new value
    propMap.set(propertyName, { value, ...rest });

    // update the sub-field with the newly updated property map object
    // convert Map object to array for consistency and to properly support
    // cloning the formVersion object when previewing (Map objects don't get cloned properly)
    newSubfield = update(subfield, {
      properties: { $set: Array.from(propMap) },
    });

    // update field with updated sub-field
    index = fields.findIndex((f) => f.id === subfield.id);
    newField = update(field, {
      fields: { [eval(index)]: { $set: newSubfield } },
    });

    // update appropriate fields array in formVersion and state
    this.updateFormField(field, newField, parent);
  };

  updateFormField = (field, newField, parent) => {
    const { formVersion } = this.state;
    let newParent;
    let index;
    let parentIndex;
    const nextState = {};
    if (parent) {
      parentIndex = formVersion.object.fields.findIndex(
        (f) => f.uuid === parent.uuid
      );
      index = formVersion.object.fields[parentIndex].fields.findIndex(
        (f) => f.uuid === field.uuid
      );

      nextState.formVersion = update(formVersion, {
        object: {
          fields: {
            [eval(parentIndex)]: {
              fields: {
                [eval(index)]: { $set: newField },
              },
            },
          },
        },
      });
      newParent = update(parent, {
        fields: {
          [eval(index)]: { $set: newField },
        },
      });
      nextState.activeNestedField = update(this.state.activeNestedField, {
        $set: newField,
      });
      nextState.activeField = update(this.state.activeField, {
        $set: newParent,
      });
    } else {
      index = formVersion.object.fields.findIndex((f) => f.uuid === field.uuid);

      nextState.formVersion = update(formVersion, {
        object: {
          fields: {
            [eval(index)]: { $set: newField },
          },
        },
      });
      nextState.activeField = update(this.state.activeField, {
        $set: newField,
      });
    }

    nextState.isDirtyVersion = true;

    // update state
    this.setState(nextState);
  };

  handleNewPage = () => {
    const { formVersion } = this.state;

    // make sure we have at least one field already added to the form,
    // otherwise return without adding a new page
    if (!formVersion.hasOwnProperty('object')) return;
    if (!formVersion.object.hasOwnProperty('fields')) return;
    if (formVersion.object.fields.length === 0) return;

    // the "Submit" button should remain as the last element in the fields array
    const pageIndex = formVersion.object.fields.length - 1;

    this.setState({
      formVersion: update(formVersion, {
        object: {
          fields: {
            $splice: [
              [eval(pageIndex), 0, { uuid: uuidv4(), type_name: 'Page Break' }],
            ],
          },
        },
      }),
      isDirtyVersion: true,
    });
  };

  reportAction = (v) => {
    if (v === 'Clone') {
      this.setState({ action: 'Clone' });
    }
    if (v === 'Delete') {
      this.setState({ action: 'Delete' });
    }
    if (v === 'Move') {
      this.setState({ action: 'Move' });
    }
  };

  handleTabChange = (tab) => this.setState({ activeTab: tab.key });

  handleSettingsChange = (setting, value) => {
    const nextState = { isDirtySettings: true };

    if (['formGroups', 'formContacts'].includes(setting)) {
      nextState[setting] = this.mergeArray(this.state[setting], value);
    } else {
      const { formVersion } = this.state;
      const nextVersion = { ...formVersion };
      nextVersion[setting] = value;
      nextState.formVersion = nextVersion;
    }
    this.setState(nextState);
  };

  handleLockField = (field, parent) => {
    const { formVersion } = this.state;
    let index;
    let parentIndex;
    let fields = [];

    if (parent) {
      parentIndex = formVersion.object.fields.findIndex(
        (f) => f.uuid === parent.uuid
      );
      fields = parent.fields || [];
    } else {
      fields = formVersion.object.fields;
    }

    index = fields.findIndex((f) => f.uuid === field.uuid);

    field.isLocked = this.toggleFieldLock(field);

    fields[index] = field;
    if (parent) formVersion.object.fields[parentIndex].fields = [...fields];
    else formVersion.object.fields = [...fields];

    this.setState({
      formVersion,
      isDirtyVersion: true,
    });

    // lock/unlock all subfields if this is a section break w/ subfields
    if (
      field.type_name === 'Section Break' &&
      FormFieldTypes.hasSubfields(field)
    ) {
      field.fields.forEach((subfield) => this.handleLockField(subfield, field));
    }
  };

  toggleFieldLock = (field) => {
    if (!field.hasOwnProperty('isLocked')) return true;
    return (field.isLocked = !field.isLocked);
  };

  mergeArray = (prevArray, updatedArray) => {
    const results = [...prevArray];
    // find items in prevArray that are not found in updatedArray
    results.forEach((result) => {
      // set is_deleted = true if item not found in updatedArray
      if (updatedArray.findIndex((item) => result.id === item.id) === -1)
        result.is_deleted = true;
    });
    // remove items from updatedArray that already exist in prevArray,
    // what remains should be merged into resulting array
    const newItems = [...updatedArray].filter(
      (item) => !item.hasOwnProperty('id')
    );
    return results.concat(newItems);
  };

  handleResetSelect = (arg) => {
    this.setState((prevState) => ({ ...prevState, resetSelect: arg }));
  };

  render() {
    const {
      loadingError,
      basicFields,
      advancedFields,
      activeField,
      hoverLocation,
      hoverField,
      activeNestedField,
      action,
      formVersion,
      activeTab,
      groups,
      contacts,
      formGroups,
      formContacts,
      isLoading,
      optionDataSubmissionFilter,
    } = this.state;
    const { folders, folder, getAccountGroupByKey, hideChrome } = this.props;
    const clonedFormVersion = utils.shared.deepExtend({}, formVersion);
    const showSpinner = isLoading || !formVersion;
    const { origin: baseURL } = window.location;
    const micrositeName = getSessionTenantInfo()?.micrositeName;

    return (
      <div className='form-item-container' id='form-item-style'>
        <DeleteModal
          visible={!!this.state.activeDeleteField}
          open={this.state.deleteFormFieldOpen}
          onClose={this.closeDeleteModal}
          activeField={this.state.activeDeleteField}
          onAcceptDelete={this.handleAcceptDelete}
        />
        <DeleteFormModal
          open={action && action.toLowerCase() === 'delete'}
          form={formVersion}
          handleClose={() =>
            this.setState({ action: 'Build', resetSelect: true })
          }
          updateForms={this.props.goToOverview}
        />

        <CloneFormModal
          open={action && action.toLowerCase() === 'clone'}
          form={formVersion}
          handleClose={() =>
            this.setState({ action: 'Build', resetSelect: true })
          }
          updateForms={this.props.goToOverview}
          folders={folders}
          folder={folder}
        />

        <MoveFormModal
          open={action && action.toLowerCase() === 'move'}
          form={formVersion}
          handleClose={() =>
            this.setState({ action: 'Build', resetSelect: true })
          }
          folders={folders}
          folder={folder}
          nameClass='move-form-custom'
        />

        <RenameFormModal
          open={action && action.toLowerCase() === 'rename'}
          form={formVersion}
          updateForms={this.loadData}
          handleClose={(v) => {
            this.setState({ action: 'Build' });
          }}
        />

        {/* <Prompt
          when={this.isDirty}
          message="Are you sure you want to leave this form? Changes you made may not be saved. Click 'Cancel' to stay on this form or click 'OK' to continue."
        /> */}
        {!showSpinner && (
          <FormItemHeader
            formVersion={formVersion}
            isRestrictedForm={this.isRestrictedForm}
            isDirty={this.isDirty}
            urlParams={this.params}
            onNameClick={this.handleNameClick}
            onPublishClick={this.handlePublishClick}
            onSaveClick={this.handleSaveClick}
            hideChrome={hideChrome}
            goToOverview={this.props.goToOverview}
          />
        )}
        {loadingError && (
          <div className='alert alert-danger'>
            {loadingError.message || loadingError}
          </div>
        )}

        <div className='form-item-tabs'>
          <div className='pull-right top-7'>
            {!this.isRestrictedForm && !hideChrome && (
              <SelectActions className='btn-group'>
                <SelectWithTopLabel
                  title=''
                  menuItems={MenuItems}
                  reporter={this.reportAction}
                  defaultValue='Actions'
                  Icon={() => (
                    <ExpandMore
                      style={{ color: '#6E84A3', right: '7px', zIndex: '1' }}
                    />
                  )}
                  resetSelect={this.state.resetSelect}
                  setResetSelect={this.handleResetSelect}
                />
              </SelectActions>
            )}
          </div>
          {showSpinner && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircleLoadingSpinner />
            </div>
          )}
          {!showSpinner && !hideChrome && (
            <Tabs onChange={this.handleTabChange}>
              <Tab
                key='edit'
                title='Build'
                default={activeTab === 'edit' || !activeTab}
              >
                <FormBuildTab
                  formVersionObject={formVersion.object || {}}
                  activeField={activeField}
                  activeNestedField={activeNestedField}
                  hoverField={hoverField}
                  hoverLocation={hoverLocation}
                  showConfigPane={!!(activeField || activeNestedField)}
                  basicFields={basicFields}
                  advancedFields={advancedFields}
                  onFieldHover={this.handleFieldHover}
                  onFieldClick={this.handleFieldClick}
                  onFieldChange={this.handleFieldChange}
                  onSubfieldChange={this.handleSubfieldChange}
                  onDropField={this.handleDropField}
                  onDeleteField={this.handleDeleteField}
                  onNestedDrop={this.handleNestedDrop}
                  onNewPage={this.handleNewPage}
                  onLockField={this.handleLockField}
                />
              </Tab>
              <Tab
                key='settings'
                title='Settings'
                default={activeTab === 'settings'}
              >
                <div>
                  <FormItemSettings
                    formVersion={clonedFormVersion}
                    formGroups={formGroups}
                    formContacts={formContacts}
                    groups={groups}
                    contacts={contacts}
                    onChange={this.handleSettingsChange}
                    getAccountGroupByKey={getAccountGroupByKey}
                    form_name={formVersion.form_name}
                    is_redirect={formVersion.is_redirect}
                    thank_you={formVersion.thank_you}
                    redirect_url={formVersion.redirect_url}
                    has_group={formVersion.has_group}
                    has_contact={formVersion.has_contact}
                    upload_pdf={formVersion.upload_pdf}
                    document_center_document_type_id={
                      formVersion.document_center_document_type_id
                    }
                    formId={formVersion.id}
                    isRestricted={formVersion.is_restricted}
                  />
                </div>
              </Tab>
              <Tab
                key='submissions'
                title='Submissions'
                default={activeTab === 'submissions'}
              >
                {/* <SubmittedFormsContainer
                  selectedForm={this.props.form}
                  updateFormCount={() => { }}
                /> */}
                <SubmissionsDetailTab
                  selectedForm={this.props.form}
                  optionDataSubmissionFilter={optionDataSubmissionFilter}
                />
              </Tab>
              <Tab key='share' title='Share' default={activeTab === 'share'}>
                <div>
                  <FormItemShare
                    embedCode={formVersion.embed_code}
                    sharedLink={`https://${micrositeName}.${getPatientPortalUrl()}/forms/${
                      this.formId
                    }`}
                    neverPublish={formVersion.never_published}
                  />
                </div>
              </Tab>
              <Tab
                key='preview'
                title='Preview'
                default={activeTab === 'preview'}
              >
                <FormRender>
                  <InnerFormRender>
                    <FormRenderer
                      formVersion={clonedFormVersion}
                      allowSubmit={false}
                      isPreview
                    />
                  </InnerFormRender>
                </FormRender>
              </Tab>
            </Tabs>
          )}
          {!showSpinner && hideChrome && (
            <div style={{ height: 'calc(100vh - 160px)' }}>
              <FormBuildTab
                formVersionObject={formVersion.object}
                activeField={activeField}
                activeNestedField={activeNestedField}
                hoverField={hoverField}
                hoverLocation={hoverLocation}
                showConfigPane={!!(activeField || activeNestedField)}
                basicFields={basicFields}
                advancedFields={advancedFields}
                onFieldHover={this.handleFieldHover}
                onFieldClick={this.handleFieldClick}
                onFieldChange={this.handleFieldChange}
                onSubfieldChange={this.handleSubfieldChange}
                onDropField={this.handleDropField}
                onDeleteField={this.handleDeleteField}
                onNestedDrop={this.handleNestedDrop}
                onNewPage={this.handleNewPage}
                onLockField={this.handleLockField}
              />
            </div>
          )}
        </div>

        <Modal
          open={this.state.errorDuplicate}
          onClose={() =>
            this.setState((prevState) => ({
              ...prevState,
              errorDuplicate: false,
            }))
          }
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <WrapperErrorDuplicate>
            <p>
              Each basic field will be matched with contact and pet properties,
              so we cannot add duplicated basic fields to a form. Please use
              Advanced Fields if you want to have more fields on your form.
            </p>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  errorDuplicate: false,
                }))
              }
            >
              OK
            </Button>
          </WrapperErrorDuplicate>
        </Modal>
      </div>
    );
  }
}

FormItem.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.object,
  folders: PropTypes.array,
  folder: PropTypes.object,
  onAction: PropTypes.func,
  onDelete: PropTypes.func,
  onVersionSave: PropTypes.func,
  onFormSave: PropTypes.func,
  getAccountGroupByKey: PropTypes.func,
  formId: PropTypes.string,
  hideChrome: PropTypes.bool,
  form: PropTypes.object,
  activeTab: PropTypes.string,
  groups: PropTypes.array,
};

FormItem.defaultProps = {
  match: {
    url: '',
    params: {},
  },
  location: {
    search: '',
  },
  folders: [],
  folder: {},
  formId: null,
  hideChrome: false,
  activeTab: 'edit',
  groups: [],
};

const DeleteModal = ({
  visible,
  open,
  onClose,
  activeField = {},
  onAcceptDelete,
}) => {
  // if (!visible) {
  //   return null;
  // }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent>
        <DialogContentText id='delete-modal'>
          Are you sure you want to delete this form field item?
        </DialogContentText>
        <br />
        <DialogContentText id='delete-modal'>
          You are about to delete{' '}
          {activeField?.display_name || activeField?.type_name}. Any changes
          you've made to this form field will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          No
        </Button>
        <Button onClick={onAcceptDelete} color='primary' autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(FormItem);
