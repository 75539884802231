/* eslint-disable @typescript-eslint/naming-convention */
// @ts-nocheck
import { compose, get, filter, map, split } from 'lodash/fp';
import APIService from '../clientApi';
import { timeGeneratorPattern } from './constants';
import {
  GeneralReportParams,
  ICurentResponse,
  ContactParams,
  IContactResponse,
  ContactCountParams,
  IContactCountResponse,
  ReportFilterParams,
  IFilterResponse,
  IUserReadyResponse,
  IFilter,
  IGetDefaultTemplateResponse,
  GetTemplateSelectOptionsParams,
  IGetTemplateSelectOptionsResponse,
  GetPlaceholdersParams,
  IGetPlaceholdersResponse,
  ISupportedLanguage,
  IGetTemplateResponse,
  GetRecipientCountParams,
  IGetRecipientCountResponse,
  ITouchpointDetailResponse,
  IProofResponse,
  ITemplateTitlesResponse,
  IGetGiftsResponse,
} from './reportsTypes';

const API = APIService();

interface PatternO {
  [branch: string]: any;
}
// a 2-tuple of branch and matching value
type PatternT = [any, any];

// Match literal values
const match = (pattern: PatternO) => (value: string | number) => {
  const cases = Object.keys(pattern);
  const lastIndex = cases.length - 1;
  const hasCorrectCase = (key: string, index: number) => {
    if (String(value) === key) return true;
    if (key === '_') {
      if (index !== lastIndex) {
        throw new Error(`'_' must be the last branch.`);
      }
      return true;
    }
    return false;
  };
  const matchingCase = cases.find(hasCorrectCase);

  if (!matchingCase) {
    throw new ReferenceError(`Match error for value: ${value}`);
  }

  return pattern[matchingCase];
};

const transformFilterTextToObj = compose(
  map((item: string) => {
    if (item[0] === 'columns') {
      return { [item[0]]: item[1].split(',') };
    }
    if (item[0] === 'status') {
      return { [item[0]]: item[1].split(',') };
    }
    return { [item[0]]: item[1] };
  }),
  map(split(':')),
  split('|'),
);

const handleGenerateTime = match(timeGeneratorPattern);

const handleSpecificFilterByType = type => item => {
  const filters = Object.assign(...transformFilterTextToObj(item.filters));
  const time = handleGenerateTime(filters.dateRange);
  switch (type) {
    case 'touchpoint':
      return {
        customFilters: {
          ...Object.assign(...transformFilterTextToObj(item.filters)),
          messageType: ['email', 'sms', 'postal', 'call'],
          dateField: 'send_at',
          ...time,
          templateTitles: [],
          sort: 'desc',
          timezone: 'Asia/Saigon',
        },
        ...item,
      };
    case 'appointment':
      return {
        customFilters: {
          ...Object.assign(...transformFilterTextToObj(item.filters)),
          dateField: 'appointment_time',
          ...time,
          templateTitles: [],
          sort: 'desc',
          timezone: 'Asia/Saigon',
        },
        ...item,
      };
    default:
      return { customFilters: Object.assign(...transformFilterTextToObj(item.filters)), ...item };
  }
};

const getSubMenuByType: (reportType: string) => (data: IFilterResponse) => IFilter[] = (
  reportType: string,
) =>
  compose(
    map(handleSpecificFilterByType(reportType)),
    filter((item: IFilter) => item.report_type === reportType),
    get(['p', '1']),
  );

const generateReportMenu = (reportTypeList: string[]) => (
  data: IFilterResponse,
): { reportType: string; subItems: IFilter[] }[] =>
  reportTypeList.map(item => ({ reportType: item, subItems: getSubMenuByType(item)(data) }));

const reportTypeList = ['contact', 'appointment', 'touchpoint', 'nps'];

const userReady = async (params: []): Promise<IUserReadyResponse> =>
  API.rpc('', { method: 'user.ready', params });

const getTemplate = async (params: [string, string]): Promise<IGetTemplateResponse> =>
  API.rpc('', { method: 'template.findById', params });

const getDefaultTemplate = async (params: [string]): Promise<IGetDefaultTemplateResponse> =>
  API.rpc('', { method: 'message_template.defaultTemplateFor', params });

const getTemplateSelectOptions = async (
  params: GetTemplateSelectOptionsParams,
): Promise<IGetTemplateSelectOptionsResponse> =>
  API.rpc('', { method: 'message_template.getTemplateSelectOptions', params });

const getRecipientCount = async (
  params: GetRecipientCountParams,
): Promise<IGetRecipientCountResponse> =>
  API.rpc('', { method: 'touchpoint.getRecipientCountForMessageType', params });

const getPlaceholders = async (params: GetPlaceholdersParams): Promise<IGetPlaceholdersResponse> =>
  API.rpc('', { method: 'message_template.getPlaceholders', params });

const getSupportedLanguages = async (params: []): Promise<ISupportedLanguage> =>
  API.rpc('', { method: 'account.getSupportedLanguages', params });

const removeAttachment = async (params: [string]): Promise<{ p: [null] }> =>
  API.rpc('', { method: 'fileupload.abortUpload', params });

const sendMail = async (params: any[]): Promise<{ p: [null, string] }> =>
  API.rpc('', { method: 'messages.create', params });

const getGeneralReportData = async (params: GeneralReportParams): Promise<ICurentResponse> =>
  API.rpc('', { method: 'public.getCurrent', params });

const getContactList = async (params: ContactParams): Promise<IContactResponse> =>
  API.rpc('', { method: 'report.search', params });

const getContactCount = async (params: ContactCountParams): Promise<IContactCountResponse> =>
  API.rpc('', { method: 'report.searchCount', params });

const getReportFilters = async (
  params: ReportFilterParams,
): Promise<{ reportType: string; subItems: IFilter[] }[]> =>
  API.rpc('', { method: 'report.getFilters', params }).then(generateReportMenu(reportTypeList));

const saveReportFilters = async (params: ReportFilterParams): Promise<IFilterResponse> =>
  API.rpc('', { method: 'report.saveFilter', params });

const getTouchPointDetail = async (params: [string]): Promise<ITouchpointDetailResponse> =>
  API.rpc('', { method: 'touchpoint.findOne', params });

const getProof = async (params: [string]): Promise<IProofResponse> =>
  API.rpc('', { method: 'touchpoint.getProof', params });

const deleteReportFilter = async (params: [number]): Promise<void> =>
  API.rpc('', { method: 'report.removeFilter', params });

const getReportExport = async (params: [string, IFilter]): Promise<void> =>
  API.rpc('', { method: 'report.export', params });

const getTemplateTitle = async (
  params: [{ limit: number; offset: number; searchText?: string; templateType: string }],
): Promise<ITemplateTitlesResponse> => API.rpc('', { method: 'template.getTitles', params });

const saveFilter = async (params: [string, string, string]): Promise<IFilterResponse> =>
  API.rpc('', { method: 'report.saveFilter', params });

const getGifts = async (params: []): Promise<IGetGiftsResponse> =>
  API.rpc('', { method: 'messages.getGifts', params });

export default {
  userReady,
  getGeneralReportData,
  getContactList,
  getContactCount,
  getReportFilters,
  saveReportFilters,
  timeGeneratorPattern,
  getTemplate,
  getDefaultTemplate,
  getTemplateSelectOptions,
  getPlaceholders,
  getSupportedLanguages,
  getRecipientCount,
  getTouchPointDetail,
  getProof,
  deleteReportFilter,
  getReportExport,
  getTemplateTitle,
  sendMail,
  removeAttachment,
  saveFilter,
  getGifts,
};
