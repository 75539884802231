import React from 'react';

const BillingPortal = (): JSX.Element => {

  return (
    <div className="communication-container user-setting">
      Under construction
    </div>
  );
};

export default BillingPortal;
