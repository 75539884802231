/* eslint-disable array-callback-return */
import { getRootDomain } from 'global/constants/url';
import moment from 'moment';

const ApiBaseUrl = `http://localhost:3001/account/v1/public/appointment-booking`;

const validateArgs = args => {
  let isValid = true;
  Array.from(args).some(arg => {
    if (!arg) {
      isValid = false;
      return !isValid;
    }
  });
  return isValid;
};

const Api = {
  // /appointment-types?accountId=
  async getAppointmentTypes(micrositeName) {
    if (!validateArgs(arguments)) return [];
    const response = await fetch(`${getRootDomain()}/tenants/v1/appointmentSettingType/micrositeName/${micrositeName}?includeDeleted=true`);
    if (response.ok) {
      const results = await response.json();
      return results.appointmentTypes;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },

  async getProvidersByAppointmentType(appointmentTypeId) {
    if (!validateArgs(arguments)) return [];
    const response = await fetch(
      `${getRootDomain()}/apointmentSettingProvider/v1/getAppointmentSettingProviderByAppointmentType/${appointmentTypeId}?getAllProviders=true`,
    );

    if (response.ok) {
      const results = await response.json();
      return results;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },

  // /providers/{providerId}/open-days?accountId=&month=&year=&appointmentLength=
  async getOpenDays(appointmentTypeId, providerId, date) {
    if (!validateArgs(arguments)) return [];
    // const momentDate = moment(date);
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    const response = await fetch(
      `${getRootDomain()}/tenants/v1/patientPortal/appointmentDate?appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&month=${month}&year=${year}`,
    );

    if (response.ok) {
      const results = await response.json();
      return results;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];

  },

  async getOpenTimeSlots(appointmentTypeId, providerId, day, duration) {
    if (!validateArgs(arguments)) return [];
    const date = moment(day).format('YYYY-MM-DD');
    const response = await fetch(
      `${getRootDomain()}/tenants/v1/patientPortal/appointmentTime?appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&date=${date}&duration=${duration}`,
    );
    if (response.ok) {
      const results = await response.json();
      return results.appointmentTimeInfos;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },
};

export default Api;
