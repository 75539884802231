import React, { useState } from 'react';
import { Typography, InputBaseComponentProps } from '@material-ui/core';

import { Main, StyledInput, StyledInputLabel } from './styled-components';

export interface InputWithTopLabelProps {
  title?: string | React.ReactElement;
  value: string;
  reporter: (arg0: string) => unknown;
  placeholder?: string;
  fullWidth?: boolean;
  error: boolean;
  type?: 'email' | 'password' | 'url' | 'text';
  disabled?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  maxLength?: number;
  removeErrors?: () => void;
  required?: boolean;
  focus?: boolean;
  setErrorResponse?: React.Dispatch<React.SetStateAction<string>>;
}

const InputWithTopLabel = (
  props: InputWithTopLabelProps
): React.ReactElement => {
  const {
    title,
    value,
    reporter,
    placeholder,
    fullWidth = true,
    error,
    type = 'text',
    disabled,
    inputComponent,
    maxLength,
    removeErrors,
    setErrorResponse,
    required,
    focus = false,
  } = props;

  const [param, setParam] = useState(value);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    // @ts-ignore
    removeErrors && removeErrors();
    setErrorResponse && setErrorResponse('');
    setParam(event.target.value as string);
    reporter(event.target.value as string);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setParam(e.target.value.trim());
    reporter(e.target.value.trim());
  };

  return (
    <Main>
      {title && (
        <StyledInputLabel>
          <Typography
            variant='body2'
            style={{
              fontFamily: 'Nunito Sans, Calibri, Arial',
              color: '#6E84A3',
              fontSize: '14px',
            }}
          >
            {title} {required && <b className='require'>*</b>}
          </Typography>
        </StyledInputLabel>
      )}
      <StyledInput
        value={param}
        fullWidth={fullWidth}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          'data-testid': 'title-input',
          maxLength,
        }}
        error={error}
        placeholder={placeholder || ''}
        type={type}
        disabled={disabled}
        autoFocus={focus || false}
        inputComponent={inputComponent}
      />
    </Main>
  );
};

export default InputWithTopLabel;
