import React, { useEffect, useState } from 'react';
import TemplateMessage from './TemplateMessage/TemplateMessage';
import {
  setTabActiveLocalStorage,
  getTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import './Template.scss';
import { useDecodeToken } from 'hooks/useDecodeToken'


const TemplateCommunication = (): JSX.Element => {
  const [templateLayout, setTemplateLayout] = useState<any>([]);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const {allowedPath} = useDecodeToken();

  const getListCategory = async () => {
    try {
      const res = await configuredRequests.GET.listCategory(1);
      // @ts-ignore
      setTemplateLayout(allowedPath.includes('/Messenger') ? res?.data : res?.data.filter(a => a.categoryKind == 'manual') ?? []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListCategory();
  }, []);

  useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    if (tabActive) {
      setActiveCategory(Number(tabActive.leftMenuContentActive));
    } else {
      setActiveCategory(0);
    }
  }, []);

  return (
    <div className='communication-analyze'>
      <div className='communication-analyze__right-modal'>
        {isLoading ? (
          <Loading className='loading-communication-left-menu' />
        ) : (
          <ul className='analyze-tab__lists'>
            {templateLayout.length > 0 ? (
              templateLayout.map((category: any, index: number) => {
                return (
                  <li
                    role='button'
                    key={'communication-templates-nav-' + index}
                    tabIndex={0}
                    className={
                      'analyze-tab__item-list ' +
                      (index === activeCategory ? 'active' : '')
                    }
                    onClick={() => {
                      setActiveCategory(index);
                      setTabActiveLocalStorage(null, index, 0);
                    }}
                  >
                    {category.categoryName}
                  </li>
                );
              })
            ) : (
              <span>Loading</span>
            )}
          </ul>
        )}
      </div>

      {!isLoading && templateLayout.length && (
        <TemplateMessage templateLayout={templateLayout[activeCategory]} />
      )}
    </div>
  );
};

export default TemplateCommunication;
