import React, { useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NotificationContext } from 'contexts/Notification';
import { TenantInfoResponse } from 'global/requests/ResponseTypes/Tenants';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import { useBlurCustomDropdown } from 'hooks/useBlurCustomDropdown';
import useClickOutSide from 'hooks/useClickOutSide';
import { useDecodeToken } from 'hooks/useDecodeToken';
//types
import { RouterProps } from 'types/RouterT';

interface AccountProps extends RouterProps {
  customImage?: string;
  storedUserData?: UserDataResponse;
  storedTenantInfo?: TenantInfoResponse;
}

// TODO: determine the structure of data that we will receive for certain
const getInitials = (userFirstName: string, userLastName: string): string => {
  if (!userLastName.length) {
    if (!userFirstName.length) {
      return '';
    }
    return userFirstName[0];
  }
  return userFirstName[0] + userLastName[0];
};

// renders a link for the account dropdown list (list will automatically resize itself)
const renderNavLink = (options: {
  linkTo: string;
  titleText: string;
  visibleText: string;
}) => {
  return (
    <li key={options.linkTo} className='account__nav-item'>
      <Link
        key={options.linkTo}
        to={options.linkTo}
        title={options.titleText}
        className='account__lnk'
      >
        <div className='account__lnk-text'>{options.visibleText}</div>
      </Link>
    </li>
  );
};

// shared links objects. reuse between permissions that may share these
const permissionSpecificAccountLinks = {
  tenantSystemSettings: renderNavLink({
    linkTo: '/Settings',
    titleText: 'Settings',
    visibleText: 'Settings',
  }),
  tenantSystemAdminSettings: renderNavLink({
    linkTo: '/SettingsAdmin',
    titleText: 'Settings',
    visibleText: 'Settings',
  }),
  tenantBilling: renderNavLink({
    linkTo: '/Account/Billing',
    titleText: 'Billing',
    visibleText: 'Billing',
  }),
};

const Account = (props: AccountProps) => {
  const { refInterval, intervalError, settings, setSettings, setCountErrors } =
    useContext(NotificationContext);
  const history = useHistory();
  const [redirect, setRedirect] = React.useState(false);
  const [isAccountDropdownOpened, setIsAccountDropdownOpened] =
    React.useState(false);
  const clickOutSideRef = useRef();
  useClickOutSide(clickOutSideRef, () => setIsAccountDropdownOpened(false));
  const { allowedPath } = useDecodeToken();

  const { routerJWT, setRouterJWT, storedUserData, storedTenantInfo } = props;

  React.useEffect(() => {
    // true when user clicks log out
    if (redirect) {
      // need to clear the session before redirecting in order for Login page to render
      sessionStorage.clear();
      setRouterJWT(undefined);
      // Login will redirect to /Dashboard if: sessionStorage has a valid JWT or the router has a valid JWT
      history.push({ pathname: '/' });
    }
  }, [redirect, setRouterJWT, history]);

  const menuRef = React.createRef<HTMLUListElement>();

  // checks the active (tab highlighted) element
  // if there is no match withing the class array then the callback fires
  useBlurCustomDropdown(['account--dropdown-container', 'account__lnk'], () => {
    setIsAccountDropdownOpened(false);
  });

  const handleChangeTabRealTime = () => {
    const user = getSessionUserData();

    const orderTab = parseInt(window.sessionStorage.getItem('tab') || '-1');
    const tabs: { tabOrder: number; isClosed: boolean }[] = JSON.parse(
      window.localStorage.getItem(`${user?.userId}-tabs`) || '[]'
    );

    if (orderTab === 0) {
      window.localStorage.removeItem(`${user?.userId}`);
    }

    if (tabs.every((tab) => tab.isClosed)) {
      window.localStorage.removeItem(`${user?.userId}-tabs`);
      return;
    }

    const index = tabs.findIndex((tab) => tab.tabOrder === orderTab);
    if (index === -1) {
      return;
    }

    tabs[index].isClosed = true;

    if (tabs.every((tab) => tab.isClosed)) {
      window.localStorage.removeItem(`${user?.userId}-tabs`);
    } else {
      window.localStorage.setItem(`${user?.userId}-tabs`, JSON.stringify(tabs));
    }
  };

  const handleSetRedirect = () => {
    if (refInterval.current) {
      clearInterval(refInterval.current);
      refInterval.current = null;
    }
    if (intervalError.current.error) {
      clearInterval(intervalError.current.error);
      clearInterval(intervalError.current.stoppage);
      intervalError.current.error = null;
      intervalError.current.stoppage = null;
    }
    if (settings) {
      setSettings(undefined);
    }
    handleChangeTabRealTime();
    setCountErrors({ errors: -1, stoppages: -1 });
    setRedirect(true);
  };

  const handleToggleDropdownOpenState = () => {
    setIsAccountDropdownOpened(!isAccountDropdownOpened);
  };

  const handleKeyboardEventClickDropdown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        menuRef?.current?.click();
        break;
      }
    }
  };

  // will only rerender when the routerJWT is updated, which shouldn't happen during regular use of the app
  // useMemo saves the render time by saving and reusing the output given unchanged dependancies
  const renderedPermissionSpecificAccountLinks = React.useMemo(() => {
    const isHasPermissionToAccessSettingPage =
      allowedPath.includes('/Settings');
    if (isHasPermissionToAccessSettingPage) {
      return [permissionSpecificAccountLinks.tenantSystemSettings];
    }
  }, [allowedPath]);

  return (
    <div
      tabIndex={0}
      className={
        'account account--dropdown-container' +
        (isAccountDropdownOpened ? ' open' : '')
      }
      onClick={handleToggleDropdownOpenState}
      onKeyDown={handleKeyboardEventClickDropdown}
      //@ts-ignore
      ref={clickOutSideRef}
    >
      <div className='account__info-lnk'>
        <span className={'account__initials'}>
          <div
            className={
              'initial__text' +
              (storedUserData?.name?.givenName?.length ? ' visible' : '')
            }
          >
            {storedUserData && storedUserData.name
              ? getInitials(
                  storedUserData.name.givenName,
                  storedUserData.name.lastName
                )
              : ''}
          </div>
        </span>
        <span
          className={
            'account__name' + (storedTenantInfo?.tenantName ? ' visible' : '')
          }
        >
          {/* TODO add tenant name here instead of username, should also cache the name to resolve pop-in issues */}
          {/* {storedUserData && storedUserData.name ? (storedUserData.name.givenName + " " + storedUserData.name.lastName) : ""} */}
          {storedTenantInfo?.tenantName
            ? storedTenantInfo.tenantName.slice(0, 50)
            : ''}
        </span>
      </div>

      <FontAwesomeIcon icon='chevron-down' style={{ padding: 2 }} />

      <nav ref={menuRef} className='account__nav'>
        <ul
          className={
            'account__nav-ul' + (isAccountDropdownOpened ? ' open' : '')
          }
        >
          <li className='account__nav-item disabled'>
            <span className='account__lbl'>
              {storedUserData && storedUserData.name
                ? storedUserData.name.givenName +
                  ' ' +
                  storedUserData.name.lastName
                : 'First Last'}
            </span>
          </li>

          {renderedPermissionSpecificAccountLinks}

          <li className='account__nav-item'>
            <button className='account__lnk logout' onClick={handleSetRedirect}>
              <div className='account__lnk-text'>Sign Out</div>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Account;
