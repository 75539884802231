/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Main, StyledInput, StyledInputLabel, StyledSelect } from './styled-components';

type IdObject = { [key: string]: any } & { id: string | number }; // eslint-disable-line @typescript-eslint/no-explicit-any
export interface IMenuItem {
  value: string | IdObject;
  display: string;
  disabled?: boolean;
}

export interface SelectWithTopLabelProps {
  menuItems: IMenuItem[];
  title?: string | React.ReactElement;
  defaultValue?: string | object;
  reporter: (arg0: string | object) => unknown;
  error?: boolean;
  Icon?: React.FunctionComponent;
  disabled?: boolean;
  placeholder?: string;
  resetSelect?: boolean;
  setResetSelect?: (arg: boolean) => void;
}

const SelectWithTopLabel = (props: SelectWithTopLabelProps): React.ReactElement => {
  const {
    resetSelect,
    setResetSelect,
    menuItems,
    title = '',
    defaultValue,
    reporter,
    Icon,
    error,
    disabled: disabledInput = false,
    placeholder = '',
  } = props;

  // This ensures that a placeholder is always added if specified
  if (placeholder && placeholder !== '') {
    // This ensures that the placeholder is only added once
    if ((menuItems.length && menuItems[0].value !== 'xxxx') || !menuItems.length) {
      menuItems.unshift({
        value: 'xxxx',
        display: placeholder,
        disabled: true,
      });
    }
  }
  const [param, setParam] = useState<string | object>(
    defaultValue || (menuItems[0] ? menuItems[0].value : ''),
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setParam(event.target.value as string | object);
    if (!!setResetSelect) {
      setResetSelect(false);
    }
  };

  useEffect(() => {
    if (resetSelect) {
      setParam("Actions");
    }
  }, [resetSelect]);

  useEffect((): void => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }
    const res = menuItems.find((item: IMenuItem): boolean => {
      if (typeof item.value === 'object') {
        const x = item.value as { id: string | number };
        return String(x.id) === param;
      }
      return false;
    });

    if (res) {
      reporter(res.value);
    } else {
      reporter(param);
    }
  }, [param, isInitialLoad, menuItems]);

  const getStringValueFromObject = (value: string | number | IdObject): string => {
    if (typeof value === 'object') {
      return String(value.id);
    }
    if (!value) {
      return '';
    }
    return String(value);
  };

  const remappedMenuItems = menuItems.map((item: IMenuItem): {
    value: string;
    display: string;
    disabled: boolean;
  } => {
    return {
      value: getStringValueFromObject(item.value),
      display: item.display,
      disabled: !!item.disabled,
    };
  });
  return (
    <Main>
      {typeof title === 'string' && title.length > 0 && (
        <StyledInputLabel>{title}</StyledInputLabel>
      )}
      {typeof title !== 'string' && title}
      <StyledSelect
        error={error}
        value={param}
        onChange={handleChange}
        input={<StyledInput error={error} disabled={disabledInput} />}
        IconComponent={Icon || KeyboardArrowDownIcon}
        inputProps={{
          'data-testid': 'select-input',
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          marginThreshold: 0,
        }}
        data-testid='select-parent'
      >
        {remappedMenuItems.map(
          ({
            value,
            display,
            disabled,
          }: {
            value: string;
            display: string;
            disabled: boolean;
          }): React.ReactElement => (
            <MenuItem key={value} value={value} disabled={disabled}>
              <div className={disabled ? 'disabled value-select' : 'value-select'}>{display}</div>
            </MenuItem>
          ),
        )}
      </StyledSelect>
    </Main>
  );
};

export default SelectWithTopLabel;
