import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import MedicalServices from './MedicalServices/MedicalServices';
import PortalUI from './PortalUI/PortalUI';
import CustomExternalLinkButton from './CustomExternalLinkButton/CustomExternalLinkButton'

import Share from './Share/Share';

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

const TabContainer = (props: TabContainerProps) => {
  return <Typography component='div'>{props.children}</Typography>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const ClientPortalSetting = () => {
  const [activeComponent, setActiveComponent] = useState<number>(0);

  useEffect(() => {
    // const settingMainView = document.getElementById(
    //   'settings--main-view-container'
    // );
    // if (settingMainView && isWindows) settingMainView.style.overflow = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveComponent(newValue);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 0: {
        return (
          <TabContainer id={0}>
            <MedicalServices />
          </TabContainer>
        );
      }
      case 1: {
        return (
          <TabContainer id={1}>
            <PortalUI />
          </TabContainer>
        );
      }
      case 2: {
        return (
          <TabContainer id={2}>
            <CustomExternalLinkButton/>
          </TabContainer>
        );
      }
      case 3: {
        return (
          <TabContainer id={3}>
            <Share />
          </TabContainer>
        );
      }
    }
    return (
      <TabContainer id={0}>
        <MedicalServices />
      </TabContainer>
    );
  };

  return (
    <div className='w-300 template-appointment-email'>
      <AppBar position='static' className='container__table'>
        <Tabs
          value={activeComponent}
          onChange={handleChangeTab}
          indicatorColor='primary'
        >
          <Tab label='Medical Services' />
          <Tab label='Portal UI' />
          <Tab label='External Link' />
          <Tab label='Share' />
        </Tabs>
      </AppBar>
      {renderContainer()}
    </div>
  );
};

export default ClientPortalSetting;
