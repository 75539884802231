import React from 'react';
import Switch from 'components/Global/Switch/Switch';


interface IProps {
  handleChangeUnvailability: (event: React.ChangeEvent<HTMLInputElement>) => void,
  statusProviderUnvailability: { accepted: boolean, requested: boolean },
}
const ProviderUnvailability = (props: IProps): JSX.Element => {
  const { handleChangeUnvailability, statusProviderUnvailability } = props;
  return (
    <>
      <div className="mt-20">
        <Switch checked={statusProviderUnvailability.accepted} name="accepted" onChange={handleChangeUnvailability} />
        <span className="title-provider-unavailability">Time become unavailable when they are "Accepted"</span>
      </div>
      <div className="mt-20">
        <Switch checked={statusProviderUnvailability.requested} name="requested" onChange={handleChangeUnvailability} />
        <span className="title-provider-unavailability">Time become unavailable when they are requested</span>
      </div>
    </>
  )
}

export default ProviderUnvailability;
