import React from 'react';
import styles from './styles';

const DefaultThankYouMessage = `The information you've entered was securely sent to our office.`;
const RefererMessage = `Please review and complete any additional documents that may require your attention.`;
const ReviewButtonMessage = `Review My Documents`;

const Referer = ({ referer }) => {
  return (
    <div style={styles.refererBlock}>
      <p style={{ ...styles.message, ...styles.messageSmall }}>{RefererMessage}</p>
      <a href={decodeURIComponent(referer)} className='btn btn-primary' style={styles.refererBtn}>
        {ReviewButtonMessage}
      </a>
    </div>
  );
};

const ThankYou = ({ message, referer }) => {
  return (
    <div style={styles.container}>
      <i style={styles.icon} className='fa fa-check-circle' />
      <h3 style={styles.h3}>Thank You</h3>
      <p style={styles.message}>{message || DefaultThankYouMessage}</p>
      {referer && <Referer referer={referer} />}
    </div>
  );
};

ThankYou.defaultProps = {
  message: DefaultThankYouMessage,
};

export default ThankYou;
