import styled from 'styled-components';
import CardContent from '@material-ui/core/CardContent';
import { MediumEmphasizedDarkText, TinyTitleText } from 'components/Legwork/typography';

import Card from '../Card';

const Title = styled(TinyTitleText)`
  margin-top: 0.5rem;
  margin-bottom: 1.4rem;
  font-weight: 600;
  height: 1rem;
`;

const Description = styled(TinyTitleText)`
  font-size: 0.8rem;
  font-weight: 500;
`;

const Statistic = styled(MediumEmphasizedDarkText)`
  color: #4c506c;
  font-weight: 500;
  font-size: 45px;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: 2rem;
`;

const AlignedChart = styled.div`
  justify-content: flex-end;
  flex: 2.5;
  display: flex;
`;

const SizedCard = styled(Card)`
  width: 100%;
  height: ${(props: { height: string | undefined }): string =>
    props.height ? props.height : '131px'};
  padding: 0;
`;

const StyledCardContent = styled(CardContent)`
  padding: 1rem;
  padding-left: 1.3rem;
  padding-top: 0.6rem;
  &:last-child {
    padding-bottom: 1.2rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;
export {
  Title,
  Statistic,
  SizedCard,
  StyledCardContent,
  AlignedChart,
  Description,
  ContentContainer,
};
