import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const getColorFrom = (status: string | undefined): string => {
  switch (status ? status.toLowerCase() : '') {
    case 'met':
      return '#31CC97';
    case 'canceled':
      return '#EA5E57';
    case 'confirmed':
      return '#4c7cfd';
    case 'scheduled':
      return '#6e84a3';
    default:
      return 'black';
  }
};

const getBackgroundColorFrom = (status: string | undefined): string => {
  switch (status ? status.toLowerCase() : '') {
    case 'met':
      return 'rgba(49,204,151,0.15)';
    case 'canceled':
      return 'rgba(234,94,87,0.15)';
    case 'confirmed':
      return 'rgba(76,124,253,0.15)';
    case 'scheduled':
      return 'rgba(110,132,163,0.15)';
    default:
      return '#dddddd';
  }
};

const Container = styled.div`
  background-color: ${({ status }: { status: string | undefined }): string =>
    getBackgroundColorFrom(status)};
  height: 100%;
  flex: 1;
  overflow: scroll;
  padding: 0.3rem;
  border: 1px solid ${({ status }: { status: string | undefined }): string => getColorFrom(status)};
  border-left: 3px solid
    ${({ status }: { status: string | undefined }): string => getColorFrom(status)};
`;

const TitleP = styled(Typography)`
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  padding-top: -2px;
  margin-top: -2px;
` as typeof Typography;

const NameP = styled(Typography)`
  padding: 0px;
  margin: 0px;
  font-weight: 400;
` as typeof Typography;

export { Container, TitleP, NameP };
