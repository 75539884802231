import styled from 'styled-components';

const Container = styled.div`
  .MuiPaper-elevation8 {
    width: 300px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { Container };
