import React from 'react';
import { IDetailBooking } from '../BookingRequestVM';
import { Button } from '@material-ui/core';
import { ViewSubmissionWrapper as Wrapper } from './Wrapper';
import FormRenderer from 'components/FormBuilder/components/FormBuilder/forms/form-renderer';
interface IViewSubmission {
  currentAppointmentSelected: IDetailBooking | null;
  setTypeSelect: React.Dispatch<
    React.SetStateAction<'accept' | 'deny' | 'email' | 'view' | null>
  >;
  handleApproveAppointment: (selectedBooking: IDetailBooking) => void;
}

const ViewSubmission: React.FC<IViewSubmission> = ({
  setTypeSelect,
  currentAppointmentSelected,
  handleApproveAppointment,
}) => {
  return (
    <Wrapper>
      <div className='view-submission-body' id='form-item-style'>
        <FormRenderer
          submission={
            currentAppointmentSelected?.object
              ? JSON.parse(currentAppointmentSelected?.object ?? '')
              : {}
          }
          formVersion={
            currentAppointmentSelected?.jsonStructure
              ? {
                  object: JSON.parse(
                    currentAppointmentSelected?.jsonStructure ?? ''
                  ),
                }
              : { object: [] }
          }
          readOnly={true}
          allowSubmit={false}
          isFormAppointmentBooking={true}
        />
      </div>
      {currentAppointmentSelected?.status === 'pending' && (
        <div className='view-submission-footer'>
          <Button
            variant='contained'
            color='default'
            className='cancel-button'
            onClick={() => setTypeSelect('deny')}
          >
            Deny
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='save-button'
            onClick={() => {
              setTypeSelect(null);
              handleApproveAppointment(currentAppointmentSelected);
            }}
          >
            Accept
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default ViewSubmission;
