import { UserSendPostalPayload } from 'global/requests/RequestTypes';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import React from 'react';
import styled from 'styled-components';
import SinglePostalEditor from './SinglePostalEditor';
import SubscriptionLayer from './SubscriptionLayer';

const BoxEditorPostal = styled.div`
  height: 100%;

  & > div:first-child {
    height: 100%;
  }

  #postal-body {
    .confirmation-email-container {
      .list-image-templates {
        img {
          max-width: 170px;
        }
      }
    }
  }
`;

interface PostalViewContainerProps {
  userInfo: UserDataResponse | undefined;
  subscription?: boolean;
  onSubscriptionClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostalViewContainer = (props: PostalViewContainerProps) => {
  const [payloadSendToPostal, setPayloadSendToPostal] =
    React.useState<UserSendPostalPayload>({
      templateId: '',
      front: '',
      back: '',
      size: '4x6',
      actionType: 'sendWithInDeliveryHours',
      previewContent: '',
    });
  const { subscription } = props;

  return (
    <>
      <SubscriptionLayer
        subscription={!!subscription}
        message={`${
          props.userInfo?.name?.givenName + ' ' + props.userInfo?.name?.lastName
        } has unsubscribed receiving Postal.`}
        userId={props.userInfo?.userId ?? ''}
        onSubscriptionClick={props.onSubscriptionClick}
      />

      <div className='view-contact--overflow-below-nav-container max-width'>
        <BoxEditorPostal>
          <SinglePostalEditor
            payloadSendToPostal={payloadSendToPostal}
            setPayloadSendToPostal={setPayloadSendToPostal}
            isSendPostal={true}
            userId={props.userInfo?.userId ? props.userInfo.userId : ''}
          />
        </BoxEditorPostal>
      </div>
    </>
  );
};

export default React.memo(PostalViewContainer);
