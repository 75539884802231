import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './router/Router';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';

import { RegisterIcons } from 'containers/Global/FontLibrary';
import NotificationProvider from 'contexts/Notification';

// Initiate Icon library, this is required for our FontAwesomeIcons to appear.
// This was previously an async funciton that ran as part of initiating the app
// but at this time there is no need to do anything after it registers the icons (hence the commented then)
RegisterIcons(); //.then(() => { Promise.resolve(); }).catch(() => { Promise.reject(); });

ReactDOM.render(
  <NotificationProvider>
    <>
      <AppRouter />
      <ToastContainer
        autoClose={5000}
        draggable={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
    </>
  </NotificationProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
