import React from 'react';
import { PetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import { Link } from 'react-router-dom';
import CustomCheckbox, { CustomCheckboxDiv } from './CustomCheckbox';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullUserInfo } from 'global/requests/ResponseTypes/Tenants';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import { convertPhoneNumber } from 'utils/convertData';
import { orderBy } from 'lodash';

interface ContactRowEntryProps {
  contactInfo: FullUserInfo;
  setUserSelectedContactIDs: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  userSelectedContactIDs: { [key: string]: boolean };
  userSearchFilterText: string;
}

const getPrimaryPhoneNumber = (phoneNumbersOfUser: PhoneNumber[]) => {
  const phoneNumber = orderBy(phoneNumbersOfUser, ['ordinal'], ['asc'])
    .map((phoneData) => convertPhoneNumber(phoneData.number))
    .join(', ');
  return phoneNumber ? (
    phoneNumber
  ) : (
    <span style={{ color: '#F64F45' }}>Missing Phone Number</span>
  );
};

const ContactRowEntry = (props: ContactRowEntryProps) => {
  const { contactInfo, setUserSelectedContactIDs, userSelectedContactIDs } =
    props;

  const handleAddContactToSelected = (contactID: string) => {
    setUserSelectedContactIDs({
      ...userSelectedContactIDs,
      [contactID]: true,
    });
  };

  const handleRemoveContactFromSelected = (contactID: string) => {
    const newSelectededObject = {
      ...userSelectedContactIDs,
    };
    delete newSelectededObject[contactID];
    setUserSelectedContactIDs(newSelectededObject);
  };

  // renders pet names until total length >= 15 or number of pets rendered is >= 4
  // then renders hover-ellipsis with a list of the remaining pet names
  const renderPetEntries = (userPets: PetInfoResponse[]) => {
    const allPetNames: string[] = userPets.map((petData) => petData.name);

    let petNameLengths: number = 0;

    const renderVisibleNames: string[] = [];
    const renderListNames: string[] = [];
    allPetNames.forEach((petName, i) => {
      if (i === 0) {
        petNameLengths += petName.length;
        if (petName !== '') {
          renderVisibleNames.push(petName);
        }
        return;
      }
      if (
        petNameLengths + petName.length >= 25 ||
        renderVisibleNames.length >= 3
      ) {
        renderListNames.push(petName);
        return;
      }
      petNameLengths += petName.length;
      if (petName !== '') {
        renderVisibleNames.push(petName);
      }
    });

    return renderVisibleNames.length ? (
      renderVisibleNames
        .map((petName, petIndex) => {
          return (
            <div key={petName + petIndex} className='contacts--table-pet'>
              {petName}
            </div>
          );
        })
        .concat(
          renderListNames.length
            ? [
                <div
                  key={'pet-names-ellipsis-' + props.contactInfo.id}
                  className='contacts--table-pet-ellipsis'
                >
                  <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
                  <span>
                    <ul>
                      {renderListNames.map((petName, petIndex) =>
                        petName ? (
                          <li key={petName + petIndex}>{petName}</li>
                        ) : (
                          <></>
                        )
                      )}
                    </ul>
                  </span>
                </div>,
              ]
            : []
        )
    ) : (
      <span style={{ color: '#F64F45' }}>Missing Pet's Name</span>
    );
  };

  const renderPhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    const allPhoneNumbers = orderBy(phoneNumbers, ['ordinal'], ['asc']).map(
      (phoneData) => convertPhoneNumber(phoneData.number)
    );

    let letters: number = 0;

    const renderVisiblePhones: string[] = [];
    const renderListPhones: string[] = [];

    allPhoneNumbers.forEach((phone, i) => {
      if (i === 0) {
        letters += phone.length;
        if (phone !== '') {
          renderVisiblePhones.push(phone);
        }
        return;
      }
      if (letters + phone.length >= 40 || renderVisiblePhones.length >= 3) {
        renderListPhones.push(phone);
        return;
      }
      letters += phone.length;
      if (phone !== '') {
        renderVisiblePhones.push(phone);
      }
    });

    return renderVisiblePhones.length ? (
      <>
        {renderVisiblePhones.join(', ')}

        {renderListPhones.length ? ',' : null}

        {renderListPhones.length ? (
          <div
            key={'contact-phone-ellipsis-' + props.contactInfo.id}
            className='contacts--table-pet-ellipsis contacts--table-phone-ellipsis'
          >
            <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
            <span>
              <ul>
                {renderListPhones.map((petName, petIndex) =>
                  petName ? <li key={petName + petIndex}>{petName}</li> : <></>
                )}
              </ul>
            </span>
          </div>
        ) : null}
      </>
    ) : (
      <span style={{ color: '#F64F45' }}>Missing Phone Number</span>
    );
  };

  const haveEmailAddress = () => {
    if (contactInfo.emailAddress) {
      if (contactInfo.emailAddress === 'NoEmail@thevethero.com') {
        return 'Missing Email Address';
      }
      return contactInfo.emailAddress;
    } else {
      return 'Missing Email Address';
    }
  };

  return (
    <tr className='contacts--table-row-container'>
      <td className='contacts--table-row-division checkbox'>
        <CustomCheckbox
          checked={userSelectedContactIDs.hasOwnProperty(contactInfo.id)}
          clickHandler={(event: React.MouseEvent<CustomCheckboxDiv>) => {
            const customCheckedValue =
              event.currentTarget.getAttribute('data-checked');
            const userWasSelected = customCheckedValue === 'false';
            if (!customCheckedValue?.length) {
              console.log('SHOULD NOT HAPPEN');
              return;
            }
            if (userWasSelected) {
              handleAddContactToSelected(contactInfo.id);
              return;
            }
            if (!userWasSelected) {
              handleRemoveContactFromSelected(contactInfo.id);
              return;
            }
          }}
        />
      </td>

      <td className='contacts--table-row-division contact'>
        <Link
          to={{
            pathname: '/Contacts/View/' + contactInfo.id,
            state: {
              fromContacts: true,
            },
          }}
        >
          <HighlightText
            text={(contactInfo.name?.length
              ? contactInfo.name
              : 'Missing Contact Name'
            ).slice(0, 31)}
            searchValue={props.userSearchFilterText.trim()}
          />
        </Link>
      </td>

      <td className='contacts--table-row-division pet'>
        {contactInfo.pets?.filter((pet) => pet.status === 'active')?.length ? (
          renderPetEntries(
            contactInfo.pets.filter((pet) => pet.status === 'active')
          )
        ) : (
          <span style={{ color: '#F64F45' }}>No Registered Pets</span>
        )}
      </td>

      <td className='contacts--table-row-division email'>
        {contactInfo.emailAddress === 'NoEmail@thevethero.com' ? (
          <span style={{ color: '#F64F45' }}>Missing Email Address</span>
        ) : contactInfo.emailAddress ? (
          <HighlightText
            text={haveEmailAddress()}
            searchValue={props.userSearchFilterText.trim()}
          />
        ) : (
          ''
        )}
      </td>

      <td className='contacts--table-row-division phone'>
        {renderPhoneNumbers(contactInfo.phoneNumbers ?? [])}
        {/* {getPrimaryPhoneNumber(contactInfo.phoneNumbers ?? [])} */}
      </td>
    </tr>
  );
};

export default ContactRowEntry;
