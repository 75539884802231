/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { get } from 'js-cookie';
import { SESSION_TOKEN, LOGIN_DOMAIN } from 'components/FormBuilder/app-constants';

interface IUserService {
  isValidToken(): boolean;
  redirectToLogin(): void;
}

class UserService implements IUserService {
  isValidToken = (): boolean => {
    return get(SESSION_TOKEN) !== undefined;
  };

  redirectToLogin = (): void => {
    window.location.assign(LOGIN_DOMAIN);
  };
}

export default UserService;
