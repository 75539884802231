import moment from 'moment';
import { getSessionJWT } from "global/sessionStorage/SessionJWT";
import { TenantUserDataResponse } from 'global/requests/ResponseTypes/Tenants';

//TODO replace with env var if needed
const CONTACT_CACHE_EXPIRE_TIME_MINUTES = 5;

const contactsCacheKey = "Contacts--all-contacts-cache";

export const setSessionStorageContactsResponse = (contactResponse: TenantUserDataResponse) => {
  const currentTenantID = getSessionJWT()?.jti;
  const stateString = JSON.stringify(
    {
      storedAt: moment().format(),
      contactResponse
    }
  );
  // console.log('setSessionStorageContactsResponse', stateString);
  sessionStorage.setItem(`${contactsCacheKey}-${currentTenantID}`, stateString);
}

export const getSessionStorageContactsResponse = (): TenantUserDataResponse | null => {
  const currentTenantID = getSessionJWT()?.jti;
  const sessionResponse = sessionStorage.getItem(`${contactsCacheKey}-${currentTenantID}`);
  // console.log('getSessionStorageContactsResponse', sessionResponse);
  if (!sessionResponse) {
    // console.log('invalid session response (it is likely that it hadn\'t been set yet)')
    return null;
  }
  const parsedSessionResponse = JSON.parse(sessionResponse);

  // diff will return the number of milliseconds since createdBy timestamp
  // get absolute value because difference will be negative
  const timeSinceCache = Math.abs(moment(parsedSessionResponse.storedAt).diff(moment()));

  // 5 minutes in milliseconds
  if (timeSinceCache > (1000 * 60 * CONTACT_CACHE_EXPIRE_TIME_MINUTES)) {
    // console.log('cache is expired')
    return null;
  }
  // console.log('found valid, unexpired cache', parsedSessionResponse.contactResponse)
  return parsedSessionResponse.contactResponse;
}
