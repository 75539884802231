import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { getThemeProp } from '../utils';

const CircleLoadingSpinner = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${getThemeProp('palette.text.hint', 'blue')};

    svg {
      cursor: default;
    }
  }
`;

export default CircleLoadingSpinner;
