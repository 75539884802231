import React from 'react';
import { FormFieldBase, FormFieldInput } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';

export const FormFieldLongText = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { limit, max } = getFieldPropertyValues(properties.specific, ['limit', 'max']);
  const options = {
    properties,
    isRenderedField,
    onChange,
    value,
    onBlur,
    validationMessage,
    readOnly,
  };

  if (limit) {
    if (max !== undefined && max !== null) options.maxWords = max;
  }

  return <FormFieldInput field={field} options={options} />;
};

FormFieldLongText.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldLongText);
