import React from 'react';
import { FormFieldBase } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';

export const FormFieldDescriptionArea = ({ field }) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { descriptionContent } = getFieldPropertyValues(properties.specific, 'descriptionContent');

  return (
    <div className='description-area'>
      <div className='rte-content' dangerouslySetInnerHTML={{ __html: descriptionContent }} />
    </div>
  );
};

const controls = {
  edit: true,
  delete: true,
  more: true,
  // more: __DEV__,
};
export default FormFieldBase(FormFieldDescriptionArea, controls);
