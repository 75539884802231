import * as t from 'io-ts';
import { FullUserInfoResponse } from './Tenants';
import { FullPetInfoResponse } from './Pets';

// AppointmentInfoResponse
export const UserSummaryCollection = t.type({
    count: t.number,
    users: t.array(FullUserInfoResponse),
});

const ReminderSummaryRequired = t.type({
    recallType: t.string,
    recallDate: t.string,
    reminderId: t.string,
    pet: FullPetInfoResponse,
    contactId: t.string,
    reminderStatus: t.string,
    reminderItem: t.string,
});

const ReminderSummaryNullable = t.partial({
    note: t.string,
});

export const FullReminderSummary = t.intersection([
    ReminderSummaryRequired,
    ReminderSummaryNullable,
]);

export type ReminderSummaryEntry = t.TypeOf<typeof FullReminderSummary>;

export const ReminderSummaryCollection = t.type({
    offset: t.number,
    count: t.number,
    totalAvailable: t.number,
    reminders: t.array(FullReminderSummary),
});

export type ReminderInfoResponse = t.TypeOf<
    typeof ReminderSummaryCollection
>;
