import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextChevronDropdown from "components/Global/TextChevronDropdown/TextChevronDropdown";
import WarningPopup from "components/Global/WarningPopup/WarningPopup";
import DetailAppointmentBooking from "./components/DetailAppointmentBooking";
import ContentContactAppointment from "./components/ContentContactAppointment";
import EmailContact from "./components/EmailContact";
import ViewSubmission from "./components/ViewSubmission";
import { convertPhoneNumber } from 'utils/convertData';
import ReactModal from "react-modal";
import { IDetailBooking, IListStatus, IPopup, OptionContact, OptionPet } from "./BookingRequestVM";
import { Wrapper } from "./Wrapper";
import { NotifyBody } from "global/requests/RequestTypes";
import ModalDialog from "components/Global/Modal";
import Filter from "components/Global/Filter/Filter";
import TableComponent from "components/Global/Table";
import { TableCell, TableRow } from "@material-ui/core";
import CustomCheckbox, {
  CustomCheckboxDiv,
} from 'components/Contacts/CustomCheckbox';

ReactModal.setAppElement("div#root");
interface IBookingV {
  changeNameAppointmentBooking: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  handleChangeOptionsRequest: (status: string) => void;
  toogleModal: (isOpen: boolean) => void;
  toogleModalUnArchive: (isOpen: boolean) => void;
  toogleConfirmModal: (isOpen: boolean) => void;
  setStatusAppointmentBooking: (actionKind: number) => void;
  sendNotifyAppointmentBooking: (actionKind: number, payload: NotifyBody) => void;
  setCurrentAppointmentSelected: React.Dispatch<React.SetStateAction<IDetailBooking | null>>;
  setTypeSelect: React.Dispatch<React.SetStateAction<"accept" | "deny" | "email" | "view" | null>>;
  setIsDirtyForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleApproveAppointment: (selectedBooking: IDetailBooking) => void;
  handleConfirmCreateNewContact: () => void;
  handleConfirmCreateNewPet: () => void;
  handleContinueApproveWithNew: (isContact: boolean) => void;
  handleContinueApproveWithSelected: (isContact: boolean) => void;
  setSelectedContact: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPet: React.Dispatch<React.SetStateAction<string>>;
  setOpenPopup: React.Dispatch<string>;
  openPopup: IPopup;
  selectedContact: string;
  selectedPet: string;
  optionContacts: OptionContact[];
  optionPets: OptionPet[];
  currentAppointmentSelected: IDetailBooking | null;
  scrollRef: React.RefObject<HTMLElement>;
  nameAppointmentBooking: string;
  isShowConfirmModal: boolean;
  isDirtyForm: boolean;
  statusSelected: string;
  listBookingRequest: IDetailBooking[];
  isOpenArchiveModal: boolean;
  isOpenUnArchiveModal: boolean;
  listStatus: IListStatus[];
  typeSelect: "accept" | "deny" | "email" | "view" | null;
  changedFields: ChangedField[];
  setChangedFields: React.Dispatch<React.SetStateAction<ChangedField[]>>;
  selectedPhoneNumber: string;
  setSelectedPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  handleContinueWithUpdating: () => void;
  handleContinueWithoutUpdating: () => void;
  approvalProcessing: boolean;
}

const styleCellBody: React.CSSProperties = {
  fontFamily: "Nunito Sans, Calibri, Arial",
  fontSize: ".8125rem",
  fontWeight: "normal",
  letterSpacing: "0",
  lineHeight: "18px",
};

const columnsContact = [
  { key: "firstName", title: "First Name" },
  { key: "lastName", title: "Last Name" },
  { key: "phoneNumber", title: "Phone Number" },
  { key: "email", title: "Email" },
  { key: "address", title: "Address" },
  { key: "pets", title: "Pets" },
];

const columnsPet = [
  { key: "name", title: "Name" },
  { key: "sex", title: "Sex" },
  { key: "specie", title: "Species" },
  { key: "breed", title: "Breed" },
];

interface ChangedField {
  fieldName: string;
  formValue: string;
  dbValue: string;
  shouldUpdate: boolean;
}

const columnsChange = [
  { key: 'name', title: 'Field Name' },
  { key: 'formValue', title: 'Form Value' },
  { key: 'systemValue', title: 'System Value' },
  { key: 'shouldUpdate', title: 'Should Update' },
];

interface DropdownOptionType {
  label: string;
  value: string;
}

const BookingRequestV: React.FC<IBookingV> = ({
  changeNameAppointmentBooking,
  handleChangeOptionsRequest,
  toogleModal,
  toogleModalUnArchive,
  setStatusAppointmentBooking,
  setTypeSelect,
  setCurrentAppointmentSelected,
  sendNotifyAppointmentBooking,
  toogleConfirmModal,
  setIsDirtyForm,
  handleApproveAppointment,
  handleConfirmCreateNewContact,
  handleConfirmCreateNewPet,
  handleContinueApproveWithSelected,
  handleContinueApproveWithNew,
  setSelectedContact,
  setSelectedPet,
  setOpenPopup,
  selectedContact,
  selectedPet,
  optionContacts,
  optionPets,
  openPopup,
  nameAppointmentBooking,
  statusSelected,
  typeSelect,
  listStatus,
  listBookingRequest,
  isOpenArchiveModal,
  isOpenUnArchiveModal,
  currentAppointmentSelected,
  isShowConfirmModal,
  scrollRef,
  isDirtyForm,
  changedFields,
  setChangedFields,
  selectedPhoneNumber,
  setSelectedPhoneNumber,
  handleContinueWithUpdating,
  handleContinueWithoutUpdating,
  approvalProcessing,
}) => {
  const contentRightModal = useMemo(() => {
    switch (typeSelect) {
      case "accept":
      case "deny": {
        return (
          <ContentContactAppointment
            type={typeSelect}
            currentAppointmentSelected={currentAppointmentSelected}
            isDirtyForm={isDirtyForm}
            setTypeSelect={setTypeSelect}
            onNotifyContact={setStatusAppointmentBooking}
            sendNotifyAppointmentBooking={sendNotifyAppointmentBooking}
            toogleConfirmModal={toogleConfirmModal}
            setIsDirtyForm={setIsDirtyForm}
          />
        );
      }
      case "email": {
        return (
          <EmailContact
            currentAppointmentSelected={currentAppointmentSelected}
            isDirtyForm={isDirtyForm}
            setTypeSelect={setTypeSelect}
            toogleConfirmModal={toogleConfirmModal}
            setIsDirtyForm={setIsDirtyForm}
            sendNotifyAppointmentBooking={sendNotifyAppointmentBooking}
          />
        );
      }
      case "view": {
        return (
          <ViewSubmission
            currentAppointmentSelected={currentAppointmentSelected}
            setTypeSelect={setTypeSelect}
            handleApproveAppointment={handleApproveAppointment}
          />
        );
      }
      default:
        return null;
    }
  }, [
    typeSelect,
    isDirtyForm,
    currentAppointmentSelected,
    setTypeSelect,
    setStatusAppointmentBooking,
    sendNotifyAppointmentBooking,
    toogleConfirmModal,
    setIsDirtyForm,
  ]);

  const renderDataTableContact = (contact: OptionContact, field: string): string => {
    switch (field) {
      case "firstName":
        return contact.firstName;
      case "lastName":
        return contact.lastName;
      case "phoneNumber":
        return contact.phoneNumber;
      case 'email':
        return contact.emailAddress === "NoEmail@thevethero.com" ? "" : contact.emailAddress;
      case "address":
        return contact.address;
      case "pets":
        return contact.pets;
      default:
        return "";
    }
  };

  const renderDataTablePet = (pet: OptionPet, field: string): string => {
    switch (field) {
      case "name":
        return pet.petName;
      case "sex":
        return pet.sex;
      case "specie":
        return pet.specie;
      case "breed":
        return pet.breed;
      default:
        return "";
    }
  };

  const mapChangedFieldsName = (name: string): string => {
    switch (name) {
      case 'first_name':
        return "Contact First Name";
      case 'last_name':
        return "Contact Last Name";
      case 'email':
        return "Contact Email";
      case 'phonenumber':
        return "Phone Number";
      case 'address1':
        return "Address Line 1";
      case 'address2':
        return "Address Line 2";
      case 'city':
        return "City";
      case 'state':
        return "State/Province";
      case 'postcode':
        return "Zipcode";
      case 'pet_name':
        return "Pet Name";
      case 'pet_sex':
        return "Pet Sex";
      case 'pet_species':
        return "Pet Species";
      case 'pet_breed':
        return "Pet Breed";
      case 'pet_birthdate':
        return "Pet Birthdate";
      default:
        return '';
    }
  };

  const updateChangedFieldCheckbox = (changedFieldName: string): void => {
    var newChangedFields: ChangedField[] = changedFields.map((ncf: ChangedField) => {
      if (ncf.fieldName == changedFieldName) ncf.shouldUpdate = !ncf.shouldUpdate;
      return ncf;
    });
    setChangedFields(newChangedFields);
  }

  const renderDataTableChangedFields = (changedField: ChangedField, field: string): React.ReactElement => {
    switch (field) {
      case 'name':
        return <span style={{ "fontWeight": "bold" }}>{mapChangedFieldsName(changedField.fieldName)}</span>;
      case 'formValue':
        if (changedField.fieldName === "phonenumber")
          return <span>{changedField.formValue ? convertPhoneNumber(changedField.formValue) : ""}</span>
        else
          return <span>{changedField.formValue}</span>;
      case 'systemValue':
        if (changedField.fieldName === "phonenumber") {
          let phoneNumbers: string[] = changedField.dbValue.split(',');
          if (phoneNumbers.length > 0 && changedField.dbValue != "") {
            let phoneNumberOptions: DropdownOptionType[] = phoneNumbers.map((num: string) => { return { label: convertPhoneNumber(num), value: num } })
            phoneNumberOptions.push({ label: "Create New Phone Number", value: "createNew" });
            return (
              <TextChevronDropdown
                styleOptions={{
                  width: 210,
                }}
                className='contacts--filter--select-container background-darkgrey height-40'
                //@ts-ignore
                currentSelection={
                  phoneNumberOptions.find(
                    (status) => status.value === selectedPhoneNumber
                  )?.label
                }
                handleChangeSelection={(newVal: string) => setSelectedPhoneNumber(newVal)}
                selectionOptions={phoneNumberOptions}
                useBlackText={true}
              />)
          }
          else return <span></span>
        }
        else {
          return <span>{changedField.dbValue}</span>;
        }
      case 'shouldUpdate':
        return <CustomCheckbox checked={changedField.shouldUpdate} clickHandler={() => updateChangedFieldCheckbox(changedField.fieldName)} />;
      default:
        return <span></span>;
    }
  };

  const modalBodyMultiContact = (isContact: boolean): React.ReactElement => {
    return (
      <>
        <p className="modal-body__text">
          {`${
            isContact
              ? `We have found ${optionContacts.length} contact(s) that match the form information. Please select the correct contact from the following list and then click "Continue With Selected Contact", or select "Create New Contact & Pet" if the correct contact is not shown/does not exist in your PMS.`
              : `We have found ${optionPets.length} pet(s) for this contact. VetHero has automatically selected the closest match for you.`
          }`}
        </p>
        <TableComponent
          columns={isContact ? columnsContact : columnsPet}
          tableBody={
            <>
              {isContact ? (
                <>
                  {optionContacts.map((contact) => (
                    <TableRow
                      key={contact.id}
                      className={`${contact.id === selectedContact ? "active" : ""}`}
                      onClick={() => setSelectedContact(contact.id)}
                    >
                      {columnsContact.map((column) => (
                        <TableCell key={column.key} style={styleCellBody}>
                          {renderDataTableContact(contact, column.key)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {optionPets.map((pet) => (
                    <TableRow
                      key={pet.id}
                      className={`${pet.id === selectedPet ? "active" : ""}`}
                      onClick={() => setSelectedPet(pet.id)}
                    >
                      {columnsPet.map((column) => (
                        <TableCell key={column.key} style={styleCellBody}>
                          {renderDataTablePet(pet, column.key)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
            </>
          }
        />
        <div className="modal-footer">
          <button className="btn btn-left" onClick={() => handleCloseModalSelectContact(isContact)}>
            Cancel
          </button>
          <div className="btn-group-right">
            <button
              className="btn new-contact-button"
              onClick={() => handleContinueApproveWithNew(isContact)}
              disabled={approvalProcessing}
            >
              {`Create New ${isContact ? "Contact" : "Pet"}`}
            </button>
            <button
              className="btn btn-right"
              onClick={() => handleContinueApproveWithSelected(isContact)}
              disabled={approvalProcessing}
            >
              {`${(changedFields.length == 0 && !isContact) ? 'Approve Form For' : 'Continue With Selected'} ${isContact ? 'Contact' : 'Pet'}`}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modalBodyUpdateOrContinue = (): React.ReactElement => {
    return (
      <>
        <div className='model-body__content custom-scroll'>
          <p className='modal-body__text'>
            VetHero has detected that the information in the form does not match the information in your system. Please select which fields you'd like us to update
          </p>
          <TableComponent
            columns={columnsChange}
            tableBody={
              <>
                {changedFields.map((field: ChangedField) => (
                  <TableRow
                    key={field.fieldName}
                  >
                    {columnsChange.map((column) => (
                      <TableCell key={column.key} style={styleCellBody}>
                        {renderDataTableChangedFields(field, column.key)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            }
            isHasPagination={false}
          />
        </div>

        <div className='modal-footer'>
          <button
            className='btn btn-left'
            onClick={() => handleCloseModalUpdateOrContinue()}
          >
            Cancel
          </button>
          <div className='btn-group-right'>
            <button
              className='btn new-contact-button'
              onClick={() => handleContinueWithoutUpdating()}
              disabled={approvalProcessing}
            >
              Approve Form Without Updating
            </button>
            <button
              className='btn btn-right'
              style={!changedFields.some((cf: ChangedField) => cf.shouldUpdate == true) ? { "backgroundColor": "white", "color": "grey", "border": "1px solid grey" } : {}}
              onClick={() => handleContinueWithUpdating()}
              disabled={!changedFields.some((cf: ChangedField) => cf.shouldUpdate == true) || approvalProcessing}
            >
              Update Selected Information
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleCloseModalSelectContact = (isContact: boolean) => {
    setOpenPopup("");
    setCurrentAppointmentSelected(null);
    setSelectedContact(optionContacts[0].id);
    !isContact && setSelectedPet(optionPets[0].id);
  };

  const handleCloseModalUpdateOrContinue = () => {
    setOpenPopup("");
    setCurrentAppointmentSelected(null);
    setSelectedContact("");
    setSelectedPet("");
  };

  return (
    //@ts-ignore
    <Wrapper ref={scrollRef}>
      <p className="title-appointment">Appointment Booking Requests</p>
      <hr className="line-appointment" />
      <div className="contacts--filter-container">
        <div className="contacts--filter-search-container">
          <FontAwesomeIcon
            icon={["far", "search"]}
            // onClick={changeNameAppointmentBooking}
          />

          <input
            className="contacts--filter--search-input"
            value={nameAppointmentBooking}
            type="search"
            placeholder="Search by name"
            onChange={changeNameAppointmentBooking}
          />
        </div>

        <TextChevronDropdown
          styleOptions={{
            width: 270,
          }}
          className="contacts--filter--select-container background-gray height-40"
          // @ts-ignore
          currentSelection={
            listStatus.find((option) => option.value === statusSelected)?.label ??
            "Pending Requests"
          }
          selectionOptions={listStatus}
          useBlackText={true}
          handleChangeSelection={handleChangeOptionsRequest}
        />
      </div>
      {listBookingRequest.length > 0 ? (
        listBookingRequest.map((detailBooking: IDetailBooking) => (
          <DetailAppointmentBooking
            key={detailBooking.id}
            nameAppointmentBooking={nameAppointmentBooking}
            detailBooking={detailBooking}
            handleApproveAppointment={handleApproveAppointment}
            showPopupArchive={toogleModal}
            showPopupUnArchive={toogleModalUnArchive}
            setTypeSelect={setTypeSelect}
            setCurrentAppointmentSelected={setCurrentAppointmentSelected}
          />
        ))
      ) : (
        <p className="no-data-results" style={{ fontWeight: "bold" }}>
          No results found
        </p>
      )}
      {/* open popup archive and unArchive */}
      {(isOpenArchiveModal || isOpenUnArchiveModal) && (
        <WarningPopup
          openPopup={isOpenArchiveModal || isOpenUnArchiveModal}
          setOpenPopup={isOpenUnArchiveModal ? toogleModalUnArchive : toogleModal}
          title={isOpenUnArchiveModal ? "Confirmation" : "Archive Booking Request"}
          description={
            isOpenUnArchiveModal
              ? "Are you sure you want to revert this booking request?"
              : "Do you wish to archive this appointment?"
          }
          handleConfirmPopup={async () => {
            if (isOpenUnArchiveModal) {
              toogleModalUnArchive(false);
            } else {
              await setStatusAppointmentBooking(3);
              toogleModal(false);
            }
          }}
        />
      )}
      {/* open popup confirm */}
      {isShowConfirmModal && (
        <WarningPopup
          openPopup={isShowConfirmModal}
          setOpenPopup={toogleConfirmModal}
          title="Confirmation"
          description="Your data will be lost. Do you wish to continue?"
          handleConfirmPopup={() => {
            setTypeSelect(null);
            toogleConfirmModal(false);
            setIsDirtyForm(false);
          }}
        />
      )}

      <ReactModal
        isOpen={!!typeSelect}
        onRequestClose={() => (isDirtyForm ? toogleConfirmModal(true) : setTypeSelect(null))}
        className="modal-user-settings react-modal appointment-request"
      >
        <div className="react-modal--settings__title">
          <p className="title-text" style={{ paddingLeft: "20px", fontSize: "20px" }}>
            {typeSelect && typeSelect === "accept"
              ? "Accept Appointment"
              : typeSelect && typeSelect === "deny"
              ? "Deny Appointment"
              : typeSelect && typeSelect === "view"
              ? "Appointment Request Submission"
              : "Email Contact"}
          </p>
          <div
            className="react-modal-close-icon"
            tabIndex={0}
            onClick={() => (isDirtyForm ? toogleConfirmModal(true) : setTypeSelect(null))}
          >
            <FontAwesomeIcon icon={["far", "times"]} />
          </div>
        </div>
        {contentRightModal}
      </ReactModal>

      {openPopup.show && openPopup.type === "miss_field" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Unable to approve"
          children="Please make sure you have Last Name and Pet Name fields on your form. We need these fields to sync contact information into PMS."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}

      {openPopup.show && openPopup.type === "no_exist_contact_and_no_support" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Contact Not Found"
          children="The contact does not exist in the system yet, please create this contact in PMS before you approve the form."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}
      
      {openPopup.show && openPopup.type === "no_exist_room" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Room Not Found"
          children="You must designate a room for this provider."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}

      {openPopup.show && openPopup.type === "no_exist_room" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Room Not Found"
          children="You must designate a room for this provider."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}

      {openPopup.show && openPopup.type === "no_support_create_appointment" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Appointment Not Found"
          children="The appointment does not exist in the system yet, please create this appointment in PMS before you approve the appointment."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}

      {openPopup.show && openPopup.type === "no_exist_contact_support" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Contact Not Found"
          children="System cannot find any contacts with Last Name and Email Address in PMS. Do you want to continue approval with new contact?"
          handleCloseModal={() => setOpenPopup("")}
          handleConfirmModal={handleConfirmCreateNewContact}
          textLeftButton="Cancel"
          textRightButton="Continue"
        />
      )}

      {openPopup.show && openPopup.type === "exist_contact" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Select Contact"
          handleCloseModal={() => setOpenPopup("")}
          isShowButtonModal={false}
          classMore="modal--select-table"
        >
          {modalBodyMultiContact(true)}
        </ModalDialog>
      )}

      {openPopup.show && openPopup.type === "no_exist_pet_and_no_support" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Not Found Pet"
          children="The Pet does not exist in the system yet, please create the Pet for this contact in PMS before you approve the form."
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => setOpenPopup("")}
        />
      )}

      {openPopup.show && openPopup.type === "no_exist_pet" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Not Found Pet"
          children='VetHero cannot find any pets for this contact. Do you want to continue approval and create a new pet for this contact?'
          handleCloseModal={() => setOpenPopup("")}
          isFullAction={false}
          handleConfirmModal={() => handleConfirmCreateNewPet}
        />
      )}

      {openPopup.show && openPopup.type === "exist_multi_pet" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title="Select Pet"
          handleCloseModal={() => setOpenPopup("")}
          isShowButtonModal={false}
          classMore="modal--select-table modal--select-pet"
        >
          {modalBodyMultiContact(false)}
        </ModalDialog>
      )}

      {openPopup.show && openPopup.type === "update_or_continue" && (
        <ModalDialog
          isOpenModal={openPopup.show}
          title='Update Contact Information'
          handleCloseModal={() => setOpenPopup("")}
          isShowButtonModal={false}
          classMore='modal--select-table modal--update-continue'
        >
          {modalBodyUpdateOrContinue()}
        </ModalDialog>
      )}
    </Wrapper>
  );
};

export default BookingRequestV;
