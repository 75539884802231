import React, { FC, SetStateAction, useRef, useState } from 'react';

interface IProps {
  children: React.ReactElement;
}

interface ISettings {
  isLoad: boolean;
  sendDesktopNotificationsForNewMessages: boolean;
  sendDesktopNotificationsForNewFormSubmitted: boolean;
  sendDesktopNotificationsForOabRequested: boolean;
}

type CountError = typeof initialCount;

interface IContext {
  settings: ISettings | undefined;
  setSettings: React.Dispatch<SetStateAction<ISettings | undefined>>;
  refInterval: any;
  intervalError: any;
  countErrors: CountError;
  setCountErrors: React.Dispatch<SetStateAction<CountError>>;
}

const initalSettings = {
  isLoad: false,
  sendDesktopNotificationsForNewMessages: true,
  sendDesktopNotificationsForNewFormSubmitted: true,
  sendDesktopNotificationsForOabRequested: true,
};

const initialCount = {
  errors: -1,
  stoppages: -1,
};

export const NotificationContext = React.createContext<IContext>(
  {} as IContext
);

const Notification: FC<IProps> = ({ children }) => {
  const [settings, setSettings] = useState<ISettings>();
  const [countErrors, setCountErrors] = useState<CountError>(initialCount);
  const refInterval = useRef(null);
  const intervalError = useRef({ error: null, stoppage: null });

  return (
    <NotificationContext.Provider
      value={{
        settings,
        setSettings,
        refInterval,
        intervalError,
        countErrors,
        setCountErrors,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default Notification;
