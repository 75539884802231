import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import FilterGlobal from 'components/Global/Filter/Filter';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
  TemplateResponse,
  TenantResposne,
} from 'global/requests/ResponseTypes';
import { useRouter } from 'hooks/useRouter';
import { get, has, isEqual, last } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isWindows } from 'react-device-detect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  getContentBackPostCard,
  getContentFrontPostCard,
  getContentToSavePreview,
} from 'components/Communications/Templates/PostCard/getContentPostCard';
import BackPostCard, {
  cutTemplateBody,
} from 'components/Communications/Templates/PostCard/BackPostCard';
import ListImageTemplates from 'components/Communications/Templates/PostCard/ListImageTemplates';
import {
  ImageWrapper,
  Wrapper,
} from 'components/Communications/Templates/PostCard/PostalTemplateWrapper';
import PreviewPostCard from 'components/Communications/Templates/PostCard/PreviewPostCard';
import { UserSendPostalPayload } from 'global/requests/RequestTypes';
import styled from 'styled-components';
import { dataTemplate } from 'global/requests/dataUserSettingResponseError';
import EditorBox from 'components/Communications/EditorBox';
import {
  convertDateToTimestamp,
  getTimeNowForPractice,
} from 'utils/convertData';
import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import WarningPopup from '../WarningPopup';
import { optionsDelivery } from './SingleEmailEditor';

const ContainerEditor = styled.div`
  #postal-body {
    padding-bottom: 20px;

    .confirmation-email-container {
      margin: 10px;

      .search-filter__global {
        .filter-form {
          background-color: #ffffff;
          border: 1px solid #dee0ed;
        }
      }

      .front-images > div,
      .back-poscard > div {
        min-width: 49%;
      }

      .front-images > .list-image-templates,
      .back-poscard > .content-back {
        margin-right: 10px;
      }

      .editor-postal {
        margin-top: 13px;
      }

      .back-poscard {
        & > div:last-child {
          /* height: 4.44in; */
          overflow: hidden;
          /* overflow-y: auto;
          overflow-x: hidden; */

          #safe-area {
            /* width: calc(100% - 21px); */

            .text {
              h1 {
                font-size: 2em;
                font-weight: bold;
              }

              h2 {
                font-size: 1.5em;
                font-weight: bold;
              }

              h3 {
                font-size: 1.17em;
                font-weight: bold;
              }

              h4 {
                font-size: 13px;
                font-weight: bold;
              }

              h5 {
                font-size: 0.83em;
                font-weight: bold;
              }

              h6 {
                font-size: 0.67em;
                font-weight: bold;
              }

              em,
              strong {
                font-family: inherit;
              }

              span {
                & > span {
                  font-family: inherit;
                }
              }

              ul {
                list-style: initial;
              }

              p {
                color: initial;
              }
            }
          }
        }

        .content-back {
          & > div:first-of-type {
            border-radius: 6px;
          }

          & > label:last-of-type {
            margin-top: 13px;
          }
        }
      }

      .list-image-templates {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        padding: 15px;
        overflow-x: hidden;
        overflow-y: auto;

        img {
          max-width: initial !important;
          width: 100%;
          margin: 0;
          height: 120px;
          padding: 0;
        }
      }

      .filter-form {
        width: 100%;
      }

      .delivery-send-mail {
        .delivery-date-dropdown {
          align-items: flex-start;

          & > .open {
            margin-bottom: 75px;
          }
        }

        input {
          padding: 0 0 0 20px;
        }

        .send-postal {
          margin-left: auto;

          .btn-send-email {
            height: 36px;
            border-radius: 4px;
            background-color: #0074ff;
            box-shadow: 0 1px 3px 0 rgba(0, 116, 255, 0.55);

            .MuiButton-label {
              text-transform: capitalize;

              svg {
                margin-right: 5px;
              }
            }

            .MuiTouchRipple-root {
              color: #ffffff;
              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 18px;
            }

            &.Mui-disabled {
              /* color: rgba(0, 0, 0, 0.26); */
              background-color: rgba(0, 0, 0, 0.12) !important;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
`;

interface IPostalProps {
  isSendPostal?: boolean;
  payloadSendToPostal: UserSendPostalPayload;
  setPayloadSendToPostal: (payload: UserSendPostalPayload) => void;
  setIsSubmitEnabled?: (arg: boolean) => void;
  userId?: string;
  receiverUserIDs?: string[];
  isSentToAllContact?: boolean;
  setGeneralSetting?: React.Dispatch<
    React.SetStateAction<TenantResposne | undefined>
  >;
}

export const optionsDeliveryDateNow = [
  { value: '0', label: 'Send Within Delivery Hours' },
  { value: '1', label: 'Send Immediately' },
];

export const optionsDeliveryDate = [
  ...optionsDeliveryDateNow,
  // {
  //   value: '2',
  //   label: 'Send Later',
  // },
];

type OptionDropDown = {
  value: string;
  label: string;
};

export const getActionType = (option: string) => {
  switch (option) {
    case '0':
      return 'sendWithInDeliveryHours';
    case '1':
      return 'sendImmediately';
    default:
      return 'sendLater';
  }
};

const SinglePostalEditor: FC<IPostalProps> = ({
  isSendPostal = false,
  payloadSendToPostal,
  setPayloadSendToPostal,
  setIsSubmitEnabled,
  receiverUserIDs = [],
  isSentToAllContact,
  userId,
  setGeneralSetting,
}) => {
  const options =
    receiverUserIDs.length > 1 || isSentToAllContact
      ? setIsSubmitEnabled
        ? optionsDeliveryDateNow
        : [{ value: '0', label: 'Send Now' }]
      : setIsSubmitEnabled
      ? optionsDeliveryDate
      : optionsDelivery;
  const [openDropdown, setOpenDropdown] = React.useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = React.useState<OptionDropDown>(
    options[0]
  );
  const [dateLater, setDateLater] = React.useState<Date | null>(null);
  const [generalSetting, setDataGeneralSetting] =
    React.useState<TenantResposne>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listImageTemplates, setListImagesTemplate] = useState<string[]>([]);
  const [listPostalTemplate, setListPostalTemplate] =
    useState<TemplateResponse>(dataTemplate);
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    label: 'Blank Postal Template',
    value: '',
  });
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [optionsTemplate, setOptionsTemplate] = React.useState<
    OptionDropDown[]
  >([{ label: 'Blank Postal Template', value: '' }]);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const timeLocalRef = React.useRef(new Date());

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    name === 'templateName' &&
      // @ts-ignore
      setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
        ...prev,
        [name]: value,
      }));
  };

  const handleOnChangeBackSide = (data: string) => {
    // @ts-ignore
    setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
      ...prev,
      back: data,
    }));
  };

  const getListImageBySide = useCallback(async () => {
    // setIsLoading(true);
    try {
      const response = await configuredRequests.GET.getListImageBySide(
        payloadSendToPostal.size
      );
      if (get(response, 'status', 0) === 200) {
        // @ts-ignore
        setListImagesTemplate(get(response, 'data', []));
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log('error: ', error);
    }
  }, [payloadSendToPostal.size]);

  const setImage = useCallback((imageUrl: string) => {
    // @ts-ignore
    setPayloadSendToPostal((prev) => ({ ...prev, front: imageUrl }));
  }, []);

  const getInfoGeneralSetting = useCallback(async () => {
    // setIsLoading(true);
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      const resTenantInfo = await configuredRequests.GET.getTenantInfo(
        res.tenantGroupId,
        res.tenantId
      );
      setDataGeneralSetting({
        ...resTenantInfo,
        phoneNumbers: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => !phoneNumber.isPracticePhone
        ),
        // @ts-ignore
        practicePhoneNumber: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => phoneNumber.isPracticePhone
        ),
      });
      setGeneralSetting &&
        setDataGeneralSetting({
          ...resTenantInfo,
          phoneNumbers: resTenantInfo.phoneNumbers?.filter(
            (phoneNumber: any) => !phoneNumber.isPracticePhone
          ),
          // @ts-ignore
          practicePhoneNumber: resTenantInfo.phoneNumbers?.filter(
            (phoneNumber: any) => phoneNumber.isPracticePhone
          ),
        });
    } catch (err) {}
  }, []);

  const getAllTemplates = async () => {
    try {
      const response = await configuredRequests.GET.templateByKind(
        ['postal'],
        ['manual']
      );
      setListPostalTemplate(response);
      const listOption = response.items.map((item) => ({
        label: item.name,
        value: item.templateId,
      }));

      setOptionsTemplate([
        { label: 'Blank Postal Template', value: '' },
        // @ts-ignore
        ...listOption,
      ]);
    } catch (err) {
      console.log('err ', err);
    }
  };

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const sendPostalData = async () => {
    try {
      setDisabledSubmit(true);
      const previewContent = getContentToSavePreview({
        frontSideUrl: payloadSendToPostal.front,
        sizePostCard: payloadSendToPostal.size,
        contentBackSide: cutTemplateBody(payloadSendToPostal.back),
        isPostGrid:
          (generalSetting?.postalProvider || '').toLowerCase() === 'postgrid',
      });
      const dataSend = {
        // @ts-ignore
        front: getContentFrontPostCard(
          payloadSendToPostal.front,
          payloadSendToPostal.size
        ),
        // @ts-ignore
        back: getContentBackPostCard(
          cutTemplateBody(payloadSendToPostal.back),
          payloadSendToPostal.size,
          (generalSetting?.postalProvider || '').toLowerCase() === 'postgrid'
        ),
        size: payloadSendToPostal.size,
        previewContent,
        // actionType: payloadSendToPostal.actionType,
        send_date: payloadSendToPostal.sendLaterDate
          ? payloadSendToPostal.sendLaterDate
          : '',
      };
      payloadSendToPostal.templateId &&
        // @ts-ignore
        (dataSend.templateId = payloadSendToPostal.templateId);
      toast.success(
        <div className='notify-success d-flex'>
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            className='icon-check-status'
          />
          <div className='success-message'>
            <b>Sending to contact</b>
          </div>
        </div>,
        { className: 'toast-success' }
      );
      const response = await configuredRequests.POST.sendSingleUserPostal(
        userId ?? '',
        dataSend
      );
      if (response) {
        setDisabledSubmit(false);
        setSelectedTemplate({ label: 'Blank Postal Template', value: '' });
        setPayloadSendToPostal({
          templateId: '',
          front: listImageTemplates[0],
          back: '',
          size: '4x6',
          previewContent: '',
          actionType: getActionType('0'),
        });
        setDeliveryDate(options[0]);
      }
    } catch (err) {
      setDisabledSubmit(false);
    }
  };

  const getDateTimeNow = async () => {
    try {
      const res = await configuredRequests.GET.getTimeNow();

      if (res) {
        setMinDate(new Date(res.toString()));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    setIsSubmitEnabled && setIsSubmitEnabled(true);
    Promise.all([
      getInfoGeneralSetting(),
      getListImageBySide(),
      getAllTemplates(),
      getDateTimeNow(),
      getTokens(),
    ])
      .then((res) => setIsLoading(false))
      .catch((err) => setIsLoading(false));
  }, []);

  const backContent = useMemo(() => {
    return (
      <BackPostCard
        generalSetting={generalSetting}
        contentBackSide={payloadSendToPostal.back}
        isSinglePostal={isSendPostal}
      />
    );
  }, [generalSetting, payloadSendToPostal.back]);

  const selectedImageList = useMemo(() => {
    return (
      <ListImageTemplates
        listImageTemplates={listImageTemplates}
        setImage={setImage}
        initialSelectedImage={
          payloadSendToPostal?.front || listImageTemplates[0]
        }
        selectedImage={payloadSendToPostal?.front}
      />
    );
  }, [listImageTemplates, payloadSendToPostal, setImage]);

  const handleSelectTemplate = (newTemplateSelect: OptionDropDown) => {
    if (!newTemplateSelect.value) {
      setPayloadSendToPostal({
        templateId: '',
        front: listImageTemplates[0],
        back: '',
        size: '4x6',
        previewContent: '',
      });
    } else {
      listPostalTemplate.items.forEach((item) => {
        if (item.templateId === newTemplateSelect.value) {
          // @ts-ignore
          setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
            ...prev,
            templateId: newTemplateSelect.value,
            // @ts-ignore
            front: item.url ? item.url : '',
            // @ts-ignore
            back: item.backBody ? item.backBody : '',
            size: item.size ? item.size : '4x6',
          }));
        }
      });
    }
    setSelectedTemplate(newTemplateSelect);
  };

  const handleSelectDeliveryDate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    const valueSentNow = setIsSubmitEnabled ? ['0', '1'] : ['0'];
    const valueSentDate = setIsSubmitEnabled ? '2' : '1';
    if (
      valueSentNow.includes(deliveryDate.value) &&
      newSelectedItem.value === valueSentDate
    ) {
      if (minDate) {
        const newMinDate = getTimeNowForPractice(timeLocalRef.current, minDate);
        setDateLater(newMinDate);
        setMinDate(newMinDate);
        //@ts-ignore
        setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
          ...prev,
          sendLaterDate: convertDateToTimestamp(newMinDate),
          actionType: getActionType(newSelectedItem.value),
        }));
      } else {
        setDateLater(new Date());
        setMinDate(new Date());
        //@ts-ignore
        setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
          ...prev,
          sendLaterDate: convertDateToTimestamp(new Date()),
          actionType: getActionType(newSelectedItem.value),
        }));
      }
      timeLocalRef.current = new Date();
    }
    if (valueSentNow.includes(newSelectedItem.value)) {
      // @ts-ignore
      setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
        ...prev,
        sendLaterDate: '',
        actionType: getActionType(newSelectedItem.value),
      }));
      if (setIsSubmitEnabled && newSelectedItem.value === '1') {
        setOpenWarningPopup(true);
      }
    }
    setDeliveryDate(newSelectedItem);
  };

  const handleSelectedDate = (date: Date | null) => {
    if (date) {
      setDateLater(date);
      //@ts-ignore
      setPayloadSendToPostal((prev: UserSendPostalPayload) => ({
        ...prev,
        sendLaterDate: convertDateToTimestamp(date),
      }));
    }
  };

  return (
    <ContainerEditor>
      <Wrapper className='edit-appointment' id='postal-body'>
        {isLoading ? (
          <img
            className='loading-appointment-info'
            src={loading_spinner}
            alt={'Loading'}
          />
        ) : (
          <form data-testid='form' className='col-12 form-schedule'>
            <div className='confirmation-email-container'>
              <div className='flex'>
                <div className='form-input-settings'>
                  <label htmlFor='templateName'>Template</label>
                  <FilterGlobal
                    optionsForFilter={optionsTemplate}
                    handleSetNewFilterValue={handleSelectTemplate}
                    valueSelected={selectedTemplate}
                  />
                </div>
                <div className='form-input-settings editor-postal'>
                  <label htmlFor='messageBody'>Front</label>
                  <div className='front-images'>
                    {selectedImageList}
                    <ImageWrapper className='selected-image'>
                      <img
                        src={payloadSendToPostal.front}
                        alt='front-post-card'
                      />
                    </ImageWrapper>
                  </div>
                </div>
                <div className='form-input-settings editor-postal back-poscard'>
                  <div className='content-back'>
                    <label htmlFor='messageBody'>Back</label>
                    <EditorBox
                      // idTextArea="editor-back-postcard"
                      value={payloadSendToPostal.back}
                      handleChangeEditor={handleOnChangeBackSide}
                      token={tokenData}
                      screen='contacts postal'
                    />
                    <label htmlFor='size'>Size</label>
                    <input
                      className='size-postcard'
                      value={payloadSendToPostal.size}
                      name='size'
                      onChange={onChangeInput}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        const { name, value } = event.target;
                      }}
                      maxLength={255}
                      disabled
                    />
                  </div>
                  {backContent}
                </div>
                <div className='form-input-settings delivery-send-mail'>
                  <p>Delivery Date</p>
                  <div className='d-flex delivery-date-dropdown'>
                    <div
                      className={`delivery-date ${openDropdown ? 'open' : ''}`}
                    >
                      <FilterGlobal
                        handleSetNewFilterValue={handleSelectDeliveryDate}
                        optionsForFilter={options}
                        valueSelected={deliveryDate}
                        setOpenDropdown={setOpenDropdown}
                      />
                    </div>

                    {deliveryDate.value ===
                    `${setIsSubmitEnabled ? '2' : '1'}` ? (
                      <div className='datepicker-email-editor'>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container className='grid-custom'>
                            <FontAwesomeIcon
                              icon={['fas', 'calendar-alt']}
                              className='icon-calendar__communication-end-date'
                            />
                            <DateTimePicker
                              value={dateLater}
                              minDate={minDate}
                              onChange={handleSelectedDate}
                              className='date-picker-custom'
                              format='MMMM do hh:mm a'
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    ) : (
                      ''
                    )}

                    {isSendPostal && (
                      <div className='send-postal'>
                        <Button
                          // ref={props.useCustomSubmit?.innerSubmitRefObject}
                          variant='contained'
                          color='primary'
                          className='btn-send-email'
                          disabled={disabledSubmit}
                          onClick={sendPostalData}
                        >
                          <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                          Send Postal
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Wrapper>

      <WarningPopup open={openWarningPopup} onClose={setOpenWarningPopup} />
    </ContainerEditor>
  );
};

export default React.memo(SinglePostalEditor);
