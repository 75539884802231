import { COLOR_CUTOFFS, ENTER_KEYCODE } from './constants';

const getTypeOfScore = (score: number): 'high' | 'medium' | 'low' => {
  if (score > COLOR_CUTOFFS.high) return 'high';
  if (score > COLOR_CUTOFFS.medium) return 'medium';
  return 'low';
};

const getPercentage = (score: number): string => {
  let modifiedScore = score;
  if (score < 0) {
    modifiedScore = 0;
  }
  if (score > 10) {
    modifiedScore = 10;
  }
  return `${modifiedScore * 10}%`;
};
const isEnterKey = (code: number): boolean => {
  return code === ENTER_KEYCODE;
};

export { getPercentage, getTypeOfScore, isEnterKey };
