const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: '24em',
    margin: '0 25px',
  },
  icon: {
    fontSize: '2.5em',
    flex: '1 1 100%',
    color: 'green',
    textAlign: 'center',
  },
  h3: {
    flex: '1 1 100%',
    textAlign: 'center',
  },
  message: {
    flex: '1 1 100%', 
    margin: 0, 
    fontSize: '1.5em',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  messageSmall: {
    fontSize: '1em',
  },
  refererBlock: {
    textAlign: 'center',
  },
  refererBtn: {
    marginTop: '15px',
  },
};

export default styles;