import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@material-ui/core';
import React from 'react';
import './WarningPopup.scss';

type WarningPopupProps = {
  setOpenPopup: (openPopup: boolean) => void;
  handleConfirmPopup?: () => void;
  openPopup: boolean;
  title: string;
  description: string;
  isNotify?: boolean;
  leftTitleButton?: string;
  rightTitleButton?: string;
};

const WarningPopup = (props: WarningPopupProps): JSX.Element => {
  const {
    setOpenPopup,
    handleConfirmPopup,
    title,
    openPopup,
    description,
    isNotify,
    leftTitleButton,
    rightTitleButton,
  } = props;

  const renderPopupWarning = () => {
    return (
      <div className='insert-modal-style warning-popup-common'>
        <h2 id='simple-modal-title'>
          {' '}
          {title}
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>{description}</div>

        <div className='action-modal'>
          {!isNotify ? (
            <>
              <Button
                variant='contained'
                className='btn-cancel'
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                {' '}
                {leftTitleButton ?? 'No'}{' '}
              </Button>
              <Button
                variant='contained'
                color='primary'
                className='btn-insert'
                onClick={() => {
                  handleConfirmPopup && handleConfirmPopup();
                }}
              >
                {' '}
                {rightTitleButton ?? 'Yes'}{' '}
              </Button>
            </>
          ) : (
            <Button
              variant='contained'
              color='primary'
              className='btn-insert btn-ok'
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              {' '}
              OK{' '}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={openPopup}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {renderPopupWarning()}
    </Modal>
  );
};

export default WarningPopup;
