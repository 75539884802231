import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Field, FieldArray, Formik, ErrorMessage, FastField } from 'formik';
import { Checkbox } from '@material-ui/core';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { OfficeEvent } from '../AvailabilityVM';
import TimePickers from 'components/Global/TimePicker/TimePicker';
import Select from 'react-select';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment, { duration } from 'moment';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import { Button } from '@material-ui/core';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AvailabilityContext } from '../context/Availability';

const Wrapped = styled.div`
  min-width: 500px;

  .form-control__name {
    border-radius: 4px;
  }

  .select-date {
    width: 40%;

    #date {
      text-transform: uppercase;
    }
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }

  .MuiInputBase-input {
    border: 1px solid #dee0ed !important;
    border-radius: 4px;
    background-color: #ffffff;
    height: 37px;
    padding: 0;
  }
  .MuiInputBase-root {
    height: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .title-label {
    color: #6e84a3;

    font-size: 13px;
    font-weight: 600;

    letter-spacing: 0;
    line-height: 18px;
  }

  .event-name {
    padding: 0 20px;

    .setting__search-form {
      width: 100%;
      min-height: 37px;
      padding-left: 7px;
      padding-right: 7px;

      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .date-picker-event {
    padding: 0 20px;

    display: flex;
    align-items: center;

    & > div:nth-child(2) {
      margin: 0 8px;
    }

    .MuiFormControl-root {
      margin: 0;
    }

    input {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 13px;
    }
  }

  .check-box-event {
    padding: 0 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .all-day-event {
      padding: 0;
      margin: 0;

      path {
        color: #1974ff;
      }
    }
    .label-event {
      color: #12253f;
      margin-left: 10px;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
  .option-repeat {
    padding: 0 20px;

    display: flex;
    align-items: center;

    & > div:nth-child(2) {
      margin: 0 8px;
    }

    .MuiFormControl-root {
      margin: 0;
    }

    input {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 13px;
    }
  }

  .option-city {
    .search-city__control {
      box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
      font-size: 13px;
    }

    .search-city__control--menu-is-open,
    .search-city__control--is-focused {
      border-color: hsl(0, 0%, 80%);
    }

    .search-city__menu div {
      // min-height: 160px;
      overflow: visible;
    }

    .search-city__indicator-separator {
      background-color: initial;
    }

    .search-city__indicator {
      right: 0;
    }
  }

  .footer-modal {
    padding: 10px 20px;
    margin-top: 20px;

    border-top: 1px solid #dee0ec;

    display: flex;
    justify-content: flex-end;

    button {
      padding-top: 9px;
      padding-bottom: 9px;

      color: #ffffff;

      font-size: 13px;
      font-weight: 600;

      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      text-transform: unset;

      border-radius: 4px;
      background-color: #0074ff;
      box-shadow: 0 1px 3px 0 rgba(0, 116, 255, 0.55);
    }

    .save-button:disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
      box-shadow: initial;
    }
  }
`;

interface IContentModalAddOfficeEvent {
  detailData: OfficeEvent | null;
  setIsDirtyForm: (isDirty: boolean) => void;
  onChangeErrorExistEventName: (error: string) => void;
  createOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  updateOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  isDirtyForm: boolean;
  errorExistEventName: string;
}

const AddOfficeEventSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  eventDate: Yup.string().required('This field is required.'),
  endTime: Yup.string().test(
    'is-greater',
    'The end time must be greater than start time.',
    function (value) {
      const { startTime, allDayEvent } = this.parent;
      if (allDayEvent || (startTime === undefined && value === undefined))
        return true;
      return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
    }
  ),
});

const listMock = [
  { label: 'Never', value: 0 },
  { label: 'Daily', value: 1 },
  { label: 'Weekly', value: 2 },
  { label: 'Monthly', value: 3 },
  { label: 'Yearly', value: 4 },
];

const ContentModalAddOfficeEvent = (props: IContentModalAddOfficeEvent) => {
  const {
    detailData,
    isDirtyForm,
    createOutOfOfficeEvent,
    updateOutOfOfficeEvent,
    setIsDirtyForm,
    onChangeErrorExistEventName,
    errorExistEventName,
  } = props;
  const { disabledSubmit } = useContext(AvailabilityContext);
  //console.log('a', disabledSubmit);

  const [listRepeats, setListRepeats] = useState(listMock);
  const [errorResponseFromAPI, setErrorResponseFromAPI] = useState('');
  const getListRepeats = useCallback(async () => {
    const response = await configuredRequests.GET.getDailySelect();
    setListRepeats(
      // @ts-ignore
      response?.data?.map((item: any) => ({
        label: item.repeat,
        value: item.id,
      }))
    );
  }, []);

  useEffect(() => {
    getListRepeats();
  }, [getListRepeats]);

  useEffect(() => {
    setErrorResponseFromAPI(errorExistEventName);
  }, [errorExistEventName]);

  return (
    <Wrapped>
      <Formik
        initialValues={
          detailData || {
            name: '',
            eventDate: '',
            startTime: '07:00',
            endTime: '17:00',
            repeatLabel: '',
            repeat: 0,
            repeatEndDate: '',
            repeatEndDateLabel: '',
            allDayEvent: false,
            tenantId: '',
            providerId: '',
            id: '',
          }
        }
        validationSchema={AddOfficeEventSchema}
        validateOnMount={true}
        // validateOnChange={false}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const tenantId = getSessionTenantInfo()?.tenantId ?? '';
          detailData
            ? updateOutOfOfficeEvent({
                name: values.name.trim(),
                eventDate: moment(values.eventDate).format('MM/DD/YYYY'),
                allDayEvent: values.allDayEvent,
                endTime: values.endTime,
                repeat: values.repeat,
                // @ts-ignore
                repeatEndDate:
                  values.eventDate === '' || values.eventDate === 'Invalid date'
                    ? moment(values.eventDate)
                        .add(1, 'years')
                        .format('MM/DD/YYYY')
                    : values.repeatEndDate
                    ? moment(values.repeatEndDate).format('MM/DD/YYYY')
                    : '',
                startTime: values.startTime,
                // @ts-ignore
                id: values.id,
                tenantId,
              })
            : createOutOfOfficeEvent({
                name: values.name.trim(),
                eventDate: moment(values.eventDate).format('MM/DD/YYYY'),
                allDayEvent: values.allDayEvent,
                endTime: values.endTime,
                repeat: values.repeat,
                repeatEndDate:
                  values.eventDate == '' || values.eventDate == 'Invalid date'
                    ? moment(values.eventDate)
                        .add(1, 'years')
                        .format('MM/DD/YYYY')
                    : values.repeatEndDate
                    ? moment(values.repeatEndDate).format('MM/DD/YYYY')
                    : '',
                startTime: values.startTime,
                tenantId,
              });
        }}
      >
        {(formikProps) => {
          const {
            handleBlur,
            setFieldValue,
            values,
            dirty,
            handleChange,
            errors,
          } = formikProps;

          dirty && !isDirtyForm && setIsDirtyForm(true);

          return (
            <form
              onSubmit={formikProps.handleSubmit}
              data-testid='form'
              className='col-12 form-schedule'
            >
              <div className='event-name'>
                <p className='title-label'>
                  Event Name <b className='require'>*</b>
                </p>
                <FastField
                  name='name'
                  maxLength={255}
                  placeholder='Input a event name'
                  required
                  className='setting__search-form form-control__name'
                  onChange={(e: any) => {
                    handleChange(e);
                    setErrorResponseFromAPI('');
                    if (!!errorExistEventName) {
                      onChangeErrorExistEventName('');
                    }
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleBlur(e);
                    setFieldValue('name', e.currentTarget.value.trim());
                    // if (e.currentTarget.value.trim().length === 0) {
                    //   formikProps.setFieldError("name", "This field is required.");
                    // }
                    // setFieldValue('templateName', e.currentTarget.value.trim());
                  }}
                />
                <p className='error-msg'>
                  <ErrorMessage name='name' />
                  {!errors.name && errorResponseFromAPI}
                </p>
              </div>

              <div className='date-picker-event'>
                <div className='select-date flex-col birth-date'>
                  <p className='title-label'>
                    Select a Date <b className='require'>*</b>
                  </p>
                  <TextField
                    id='date'
                    type='date'
                    name='eventDate'
                    // value={moment(values.eventDate).format('YYYY-MM-DD')}
                    defaultValue={moment(values.eventDate).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event: any) => {
                      handleChange(event);
                    }}
                    // inputProps={{ max: moment(new Date().toISOString()).format('YYYY-MM-DD') }}
                  />
                </div>
                <div>
                  {!values.allDayEvent && (
                    <>
                      <p className='title-label'>Start time</p>
                      <TimePickers
                        onChange={(event) =>
                          setFieldValue('startTime', event.target.value)
                        }
                        value={values.startTime}
                        // disabled={!enabled}
                        name='startTime'
                        className='select-time'
                        // showDefault={!values.startTime.length}
                        placeholderContainerClass='placeholder-time d-flex'
                        placeholderText='Start Time'
                        placeholderTextClass='placeholder-text m-0'
                      />
                    </>
                  )}
                </div>
                <div>
                  {!values.allDayEvent && (
                    <>
                      <p className='title-label'>End time</p>
                      <TimePickers
                        onChange={(event) =>
                          setFieldValue('endTime', event.target.value)
                        }
                        value={values.endTime}
                        // disabled={!enabled}
                        name='endTime'
                        className='select-time'
                        // showDefault={item.endTime.length === 0 && !item.active}
                        placeholderContainerClass='placeholder-time d-flex'
                        placeholderText='End Time'
                        placeholderTextClass='placeholder-text m-0'
                      />
                    </>
                  )}
                </div>
              </div>
              <div className='date-picker-event'>
                <div className='select-date flex-col birth-date'>
                  <p className='error-msg'>
                    <ErrorMessage name='eventDate' />
                  </p>
                </div>
                <div>
                  <p className='error-msg' style={{ margin: '5px 10px' }}>
                    {formikProps.errors.endTime}
                  </p>
                </div>
              </div>
              <div className='check-box-event'>
                <Checkbox
                  checked={values.allDayEvent}
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={() =>
                    setFieldValue('allDayEvent', !values.allDayEvent)
                  }
                  name='allDayEvent'
                  className='all-day-event'
                />
                <span className='label-event'>All day event</span>
              </div>
              <div className='option-repeat'>
                <div className='select-date flex-col birth-date'>
                  <p className='title-label'>Repeats</p>
                  <Select
                    classNamePrefix='search-city'
                    //@ts-ignore
                    defaultValue={listRepeats?.find(
                      (item) => item.value === (detailData?.repeat || 0)
                    )}
                    name='repeat'
                    className='full-width option-city margin-h-5 w-100'
                    options={listRepeats}
                    onChange={(e: any) => {
                      setFieldValue('repeat', e?.value);
                      let repeatEndDate = values.repeatEndDate
                        ? moment(values.repeatEndDate).format('YYYY-MM-DD')
                        : moment(values.eventDate)
                            .add(1, 'years')
                            .format('YYYY-MM-DD');
                      setFieldValue('repeatEndDate', repeatEndDate);
                    }}
                    maxMenuHeight={150}
                  />
                </div>
                <div className='select-date flex-col birth-date'>
                  {values.repeat > 0 && (
                    <>
                      <p className='title-label'>Repeats Until</p>
                      <TextField
                        id='date'
                        type='date'
                        name='repeatEndDate'
                        defaultValue={
                          values.repeatEndDate
                            ? moment(values.repeatEndDate).format('YYYY-MM-DD')
                            : moment(values.eventDate)
                                .add(1, 'years')
                                .format('YYYY-MM-DD')
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className='footer-modal'>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className='save-button'
                  disabled={!formikProps.isValid || disabledSubmit}
                >
                  Save
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Wrapped>
  );
};

export default React.memo(ContentModalAddOfficeEvent);
