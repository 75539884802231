/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import ContactRowEntry from './ContactRowEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateResponse } from 'global/requests/ResponseTypes';
import withComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import { Redirect } from 'react-router-dom';
import TextChevronDropdown from '../Global/TextChevronDropdown/TextChevronDropdown';
import CustomCheckbox from './CustomCheckbox';
import { capitalize } from '@material-ui/core';
import SendCommunicationModal from './SendCommunicationModal';
import SendCommunicationFilterModal from './SendCommunicationFilterModal';
import { TenantUserDataResponse } from 'global/requests/ResponseTypes/Tenants';
import { TablePaginationActions } from 'components/Global/CommunicationTable/CommunicationTable';
import TablePagination from '@material-ui/core/TablePagination';
import { isEmpty, upperFirst, debounce, intersection } from 'lodash';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { Query } from 'containers/Contacts/ContactsContainer';
import { detectRoleAndScreen } from 'hooks/useDecodeToken';

const activeFilterOptionValues = ['active', 'inactive', 'all'].map(
  (optionsValue) => {
    return {
      label: optionsValue.toString() + ' Contacts',
      value: optionsValue.toString(),
    };
  }
);

interface ContactsProps {
  pageNumber: string;
  errorGettingUsers: boolean;
  isLoading: boolean;
  numberOfFailedUserDataRequests: number;
  allContactInfo: TenantUserDataResponse | undefined | null;
  userSelectedFilterValue: string;
  searchText: string;
  queryPagination: Query;
  setQueryPagination: React.Dispatch<React.SetStateAction<Query>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setUserSelectedFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRedirect: React.Dispatch<React.SetStateAction<boolean>>;
  sendGetTextTemplateInfoRequest: () => void;
  sendGetEmailTemplateInfoRequest: () => void;
  emailTemplateInfo?: TemplateResponse;
  textTemplateInfo?: TemplateResponse;
  sendGetUsersRequest: (
    query: any,
    isReload?: boolean
  ) => Promise<TenantUserDataResponse>;
}

// this is used to delay the search filter from running on every keypress while the user types multiple characters
// this is done to avoid visual lag for queries with few characters
// an alternative approach is to not search until the string is longer than 2 or 3 characters
// you can also make the search kick off only when the user presses "Enter"

const Contacts = (props: ContactsProps) => {
  const {
    errorGettingUsers,
    isLoading,
    allContactInfo,
    sendGetUsersRequest,
    searchText,
    setSearchText,
    userSelectedFilterValue,
    setUserSelectedFilterValue,
    queryPagination,
    setQueryPagination,
    // pageNumber,
    // currentPageContactInfo,
    // getUsers,
    // setIsLoading,
    // setRedirect,
    // numberOfFailedUserDataRequests
  } = props;

  const { features } = detectRoleAndScreen(
    window.sessionStorage.getItem('AuthToken') || ''
  );
  const [useRedirect] = React.useState<boolean>(false);

  // used for slicing contact list to avoid massive render lag time when showing 1000+ contacts
  const [isSentToAllContact, setIsSentToAllContact] =
    React.useState<boolean>(false);

  const [userSelectedContactIDs, setUserSelectedContactIDs] = React.useState<{
    [key: string]: boolean;
  }>({});
  // if length > 0 then we will render <SendCommunicationModal communicationType={communicationModal} />
  const [communicationModalType, setCommunicationModalType] =
    React.useState<string>('');

  const searchInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    // sendGetUsersRequest({ withStatus: 'Active' }, true);
    // if (!isEmpty(allContactInfo)) {
    //   console.log('in');
    //   //@ts-ignore
    //   setQueryPagination((preState) => ({
    //     ...preState,
    //     offset: allContactInfo?.offset || 0,
    //     limit: allContactInfo?.count || 10,
    //   }));
    // }
  }, []);

  const detectSelectedContact = useCallback(
    (allContactInfo: TenantUserDataResponse) => {
      const listIdContactViaPage =
        allContactInfo?.users.map((user) => user.id) || [];
      // @ts-ignore
      const listIdSelected = Object.keys(userSelectedContactIDs).map(
        (key: string) => userSelectedContactIDs[key] && key
      );
      const listMatch = intersection(listIdContactViaPage, listIdSelected);
      const isListSelected = listMatch.length > 0;
      if (isListSelected) {
        setUserSelectedContactIDs(userSelectedContactIDs);
      }
    },
    [setUserSelectedContactIDs, userSelectedContactIDs]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({ ...preState, offset: newPage }));
      const contactsViaPage = await sendGetUsersRequest({
        ...queryPagination,
        offset: newPage,
        withName: searchText,
        withStatus:
          userSelectedFilterValue === 'all'
            ? ''
            : upperFirst(userSelectedFilterValue),
      });
      detectSelectedContact(contactsViaPage);
    },
    [
      sendGetUsersRequest,
      queryPagination,
      userSelectedFilterValue,
      searchText,
      detectSelectedContact,
    ]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination({ offset: 0, limit: newRowsPerPage });
        sendGetUsersRequest({
          limit: newRowsPerPage,
          offset: 0,
          withName: searchText,
          withStatus:
            userSelectedFilterValue === 'all'
              ? ''
              : upperFirst(userSelectedFilterValue),
        });
      }
    },
    [sendGetUsersRequest, searchText, userSelectedFilterValue]
  );

  const resetSelectionToDefault = (
    event?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event?.preventDefault();
    setUserSelectedContactIDs({});
  };

  // 'current page' should be changed to 'current filter matches' for clarity
  const handleSelectAllCurrentPageCheckboxClick = () => {
    if (!allContactInfo) {
      return;
    }
    const finalFilteredContactCount = allContactInfo.users.length;

    const currentSelectedUserIDs = Object.keys(userSelectedContactIDs);

    // if all users are already selected
    if (
      currentSelectedUserIDs.length === finalFilteredContactCount &&
      userSelectedFilterValue === 'all'
    ) {
      setUserSelectedContactIDs({});
      return;
    }
    // match filter and remove only matches
    if (
      allContactInfo.users.filter((contactEntry) =>
        userSelectedContactIDs.hasOwnProperty(contactEntry.id)
      ).length === finalFilteredContactCount
    ) {
      const newSelectedContactIDs = { ...userSelectedContactIDs };
      allContactInfo.users.forEach((contactData) => {
        delete newSelectedContactIDs[contactData.id];
      });

      setUserSelectedContactIDs(newSelectedContactIDs);
      return;
    }
    // else
    const newSelectedContactIDs = { ...userSelectedContactIDs };
    allContactInfo.users.forEach((contactData) => {
      newSelectedContactIDs[contactData.id] = true;
    });

    setUserSelectedContactIDs(newSelectedContactIDs);
  };

  // 'current page' should be changed to 'current filter matches' for clarity
  const renderSelectAllCurrentPageCheckbox = () => {
    if (isLoading || !allContactInfo || allContactInfo.totalAvailable === 0) {
      return <CustomCheckbox checked={false} clickHandler={() => {}} />;
    }
    const listIdContactViaPage =
      allContactInfo?.users.map((user) => user.id) || [];
    // @ts-ignore
    const listIdSelected = Object.keys(userSelectedContactIDs).map(
      (key: string) => userSelectedContactIDs[key] && key
    );
    const listMatch = intersection(listIdContactViaPage, listIdSelected);
    const isListSelected = listMatch.length >= allContactInfo.users.length;
    return (
      <CustomCheckbox
        checked={isListSelected}
        clickHandler={handleSelectAllCurrentPageCheckboxClick}
      />
    );
  };

  // renders the header row and all of the column names
  const renderTableHeader = () => {
    return (
      <tr className='contacts--table-row-container contacts--table-header-row'>
        {/* add new column headers by adding to this array */}
        {['', 'Contact', 'Pet(s)', 'Email', 'Phone Number'].map(
          (headerText, index) => {
            return (
              <td
                key={headerText + index}
                className={
                  'contacts--table-row-division ' +
                  (headerText.length
                    ? headerText.toLocaleLowerCase()
                    : 'checkbox')
                }
              >
                {headerText.length
                  ? headerText
                  : renderSelectAllCurrentPageCheckbox()}
              </td>
            );
          }
        )}
      </tr>
    );
  };

  const renderContactsTableRows = () => {
    if (errorGettingUsers || !allContactInfo) {
      return (
        <tr
          key='contacts--table-row--error'
          className='contacts--table-row-container error-loading'
        >
          <td>Error loading Contacts</td>
        </tr>
      );
    }
    if (!allContactInfo?.users.length) {
      return (
        <tr
          key='contacts--table-row--no-users'
          className='contacts--table-row-container no-users'
        >
          <td>
            No
            {userSelectedFilterValue !== 'all'
              ? ' ' + userSelectedFilterValue
              : ''}{' '}
            contacts match the search criteria.
          </td>
        </tr>
      );
    }

    return allContactInfo.users.map((contactInfo, index) => {
      return (
        <ContactRowEntry
          key={'contact-row-entry--' + index}
          contactInfo={contactInfo}
          setUserSelectedContactIDs={setUserSelectedContactIDs}
          userSelectedContactIDs={userSelectedContactIDs}
          userSearchFilterText={searchText}
        />
      );
    });
  };

  const renderClearSelectionButton = () => {
    return (
      <button
        key='contact-clear-selection-button'
        className='contacts--selected-select-all-button'
        onClick={resetSelectionToDefault}
      >
        Clear selection
      </button>
    );
  };

  // renders only once there are contacts selected
  // This is where the user can choose between text/email and also Clear selection
  const renderSelectedActionsRow = () => {
    // probably move this outside of this scope to avoid being redeclared on every call
    const renderCurrentSelectionText = () => {
      const numberOfSelectedContacts = Object.keys(
        userSelectedContactIDs
      ).length;
      const totalAvailable = allContactInfo?.totalAvailable ?? 0;

      // if all contacts are selected
      if (numberOfSelectedContacts === totalAvailable) {
        return [
          <div
            className='contacts--selected-select-all-text'
            key='contact-number-selected-container'
          >
            {`All ${numberOfSelectedContacts} contacts are selected.`}
          </div>,
          renderClearSelectionButton(),
        ];
      }

      return [
        <div
          className='contacts--selected-user-selection-text'
          key='contacts--selected-user-selection-text'
        >
          {`${numberOfSelectedContacts} contact${
            numberOfSelectedContacts !== 1 ? 's are' : ' is'
          } selected.`}
        </div>,

        renderClearSelectionButton(),
      ];
    };

    return (
      <tr className={'contacts--selected-container'}>
        <td className={'contacts--selected-actions'}>
          {renderSelectAllCurrentPageCheckbox()}
          <div className='contacts--selected-count'>
            {Object.keys(userSelectedContactIDs).length} selected
          </div>
          <button
            className='contacts--selected-action--email'
            onClick={() => {
              setIsSentToAllContact(false);
              setCommunicationModalType('email');
            }}
          >
            <FontAwesomeIcon icon={['fas', 'paper-plane']} />
            &nbsp;Send Email
          </button>
          {features.includes('Conversations') && (
            <button
              className='contacts--selected-action--text'
              onClick={() => {
                setIsSentToAllContact(false);
                setCommunicationModalType('text');
              }}
            >
              <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} />
              &nbsp;Send Text
            </button>
          )}
          <button
            className='contacts--selected-action--text'
            onClick={() => {
              setIsSentToAllContact(false);
              setCommunicationModalType('postal');
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;Send Postal
          </button>
        </td>

        <td className={'contacts--selected-select-all-container'}>
          {renderCurrentSelectionText()}
        </td>
      </tr>
    );
  };

  const handleActiveFilterChange = useCallback(
    (newValue: string) => {
      // setShowMoreIndex(0);
      setUserSelectedFilterValue(newValue);
      setQueryPagination((preState) => ({ limit: preState.limit, offset: 0 }));
      // setLocalStateContactAcitveFilter(newValue);
      sendGetUsersRequest(
        newValue !== 'all'
          ? {
              limit: queryPagination.limit,
              offset: 0,
              withStatus: upperFirst(newValue),
              withName: searchText,
            }
          : { limit: queryPagination.limit, offset: 0, withName: searchText }
      );
    },
    [sendGetUsersRequest, searchText, queryPagination]
  );

  const searchMessageWithName = useCallback(
    debounce((name: string) => {
      props.sendGetUsersRequest({
        ...queryPagination,
        offset: 0,
        withName: name.trim(),
        withStatus:
          userSelectedFilterValue === 'all'
            ? ''
            : upperFirst(userSelectedFilterValue),
      });
    }, 1000),
    [props.sendGetUsersRequest, userSelectedFilterValue]
  );
  // see the comments above the declaration for the variable lastInputTime for additional info on this
  const handleSearchTextInput = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      //store a copy of the value to prevent stale reference error
      const targetValue = event.currentTarget.value;
      searchMessageWithName(targetValue);
      setQueryPagination((prev) => ({ limit: prev.limit, offset: 0 }));
      setSearchText(targetValue);
    },
    [userSelectedFilterValue]
  );

  const handleFocusSearchInput = () => {
    searchInputRef.current?.focus();
  };

  if (useRedirect) {
    // by omiting the number it will always be caught and redirected to page 1
    // otherwise there are bugs when selecting filter
    return <Redirect to='/Contacts/' />;
  }

  return (
    <>
      {isLoading ? (
        <Loading className='loading-spinner-component' />
      ) : (
        <div className='contacts--container'>
          <SendCommunicationModal
            key={'contacts--send-communication-modal'}
            isSentToAllContact={isSentToAllContact}
            searchValue={searchText}
            filterValue={userSelectedFilterValue}
            communicationModalType={communicationModalType}
            setCommunicationModalType={setCommunicationModalType}
            userSelectedConfig={{
              totalAvailable: allContactInfo?.totalAvailable ?? 0,
              userSelectedContactIDs: userSelectedContactIDs,
            }}
            sendGetTextTemplateInfoRequest={
              props.sendGetTextTemplateInfoRequest
            }
            sendGetEmailTemplateInfoRequest={
              props.sendGetEmailTemplateInfoRequest
            }
            emailTemplateInfo={props.emailTemplateInfo}
            textTemplateInfo={props.textTemplateInfo}
          />

          <SendCommunicationFilterModal
            key={'contacts--send-communication-filter-modal'}
            isSentToAllContact={isSentToAllContact}
            searchValue={searchText}
            filterValue={userSelectedFilterValue}
            communicationModalType={communicationModalType}
            setCommunicationModalType={setCommunicationModalType}
            userSelectedConfig={{
              totalAvailable: allContactInfo?.totalAvailable ?? 0,
              userSelectedContactIDs: userSelectedContactIDs,
            }}
            sendGetTextTemplateInfoRequest={
              props.sendGetTextTemplateInfoRequest
            }
            sendGetEmailTemplateInfoRequest={
              props.sendGetEmailTemplateInfoRequest
            }
            emailTemplateInfo={props.emailTemplateInfo}
            textTemplateInfo={props.textTemplateInfo}
          />

          <span className='contacts--filter-container'>
            <div className='contacts--filter-search-container'>
              <FontAwesomeIcon
                icon={['far', 'search']}
                onClick={handleFocusSearchInput}
              />

              <input
                className='contacts--filter--search-input'
                ref={searchInputRef}
                value={searchText}
                type='search'
                placeholder='Search by Contact or Email'
                onChange={handleSearchTextInput}
              />
            </div>

            <TextChevronDropdown
              styleOptions={{
                width: 270,
              }}
              className='contacts--filter--select-container background-gray height-40'
              currentSelection={
                capitalize(userSelectedFilterValue) + ' Contacts'
              }
              handleChangeSelection={handleActiveFilterChange}
              selectionOptions={activeFilterOptionValues}
              useBlackText={true}
            />
          </span>

          <span>
            <div className={'contacts--selected-container'}>
              <div className={'contacts--selected-actions padding-0'}>
                <div className='contacts--selected-count'>
                  Total: {''}
                  {allContactInfo?.totalAvailable} record(s).
                </div>
                {allContactInfo?.totalAvailable !== 0 && (
                  <>
                    <button
                      className='contacts--selected-action--email'
                      onClick={() => {
                        setIsSentToAllContact(true);
                        setCommunicationModalType('email');
                      }}
                    >
                      <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                      &nbsp;Send Batch Email to Filtered Contact List
                    </button>
                    {features.includes('Conversations') && (
                      <button
                        className='contacts--selected-action--text'
                        onClick={() => {
                          setIsSentToAllContact(true);
                          setCommunicationModalType('text');
                        }}
                      >
                        <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} />
                        &nbsp;Send Batch Text to Filtered Contact List
                      </button>
                    )}
                    <button
                      className='contacts--selected-action--postal'
                      onClick={() => {
                        setIsSentToAllContact(true);
                        setCommunicationModalType('postal');
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                      &nbsp;Send Batch Postal to Filtered Contact List
                    </button>
                  </>
                )}
              </div>
            </div>
          </span>

          <span className='contacts--list-container'>
            <table className='contact--list-table'>
              <tbody>
                {Object.keys(userSelectedContactIDs).length
                  ? renderSelectedActionsRow()
                  : renderTableHeader()}
                {renderContactsTableRows()}
              </tbody>
            </table>
          </span>

          <span className='contacts--page-link-container'>
            <nav>
              <TablePagination
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 200, 300, 400, 500,
                ]}
                count={allContactInfo?.totalAvailable ?? 10}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </nav>
          </span>
        </div>
      )}
    </>
  );
};

const ComponentizedContacts = withComponentClassAndHeaderText(
  Contacts,
  '',
  'padding-0 overflow-unset min-width-1000'
);

export default ComponentizedContacts;
