import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import WarningPopup from 'components/Global/WarningPopup/WarningPopup';
import styled from 'styled-components';
import * as Yup from 'yup';
import { orderBy } from 'lodash';
import toast from 'components/Global/Toast';

const CotentWrapped = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface IFolders {
  Forms: Array<Record<string, unknown>>;
  account_id: string;
  folder_name: string;
  id: number;
  is_deleted: boolean;
  is_restricted: boolean;
  updated_at: string;
  updated_by: string;
}
interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  folders: Array<IFolders>;
  folder: Record<string, unknown>;
  updateForms: () => void;
}

const ErrorMessageTypes = {
  maxLength: 'This field has just allowed 50 characters',
  required: 'This field is required.',
};

const FormCreateValidationSchema = Yup.object().shape({
  formName: Yup.string()
    .max(100, ErrorMessageTypes.maxLength)
    .required(ErrorMessageTypes.required),
  folder: Yup.string().required(ErrorMessageTypes.required),
});

const NewCreateFormModal = ({
  isOpen,
  handleCloseModal,
  folders,
  folder,
  updateForms,
}: IProps) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const dataCreateForm = useMemo(() => {
    return {
      formName: '',
      folder: folder.id,
    };
  }, [folder]);

  const handleCreateForm = useCallback(
    async (data) => {
      try {
        setDisabledSubmit(true);
        const response = await configuredRequests.POST.createForm(
          data.folder,
          data.formName
        );
        if (response.status === 200) {
          await updateForms();
          setDisabledSubmit(false);
          toast.success('Your data has been saved successfully!');
        }
        handleCloseModal();
      } catch (error) {
        console.log('error: ', error);
        setDisabledSubmit(false);
        if (get(error, 'response.data.message', '')) {
          // toast.error(get(error, 'response.data.message', ''));
          setErrorResponse(get(error, 'response.data.message', ''));
        }
      }
    },
    [updateForms, handleCloseModal]
  );

  const toogleConfirmModal = (isOpen: boolean) => {
    setIsOpenConfirmModal(isOpen);
  };

  const handleClose = () => {
    setErrorResponse('');
    handleCloseModal();
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={isOpen}
      onRequestClose={() =>
        isDirtyForm ? setIsOpenConfirmModal(true) : handleClose()
      }
    >
      <WarningPopup
        openPopup={isOpenConfirmModal}
        setOpenPopup={toogleConfirmModal}
        title='Confirmation'
        description='Your data will be lost. Do you wish to continue?'
        handleConfirmPopup={() => {
          setIsOpenConfirmModal(false);
          setIsDirtyForm(false);
          handleClose();
        }}
      />
      <Formik
        initialValues={dataCreateForm}
        validationSchema={FormCreateValidationSchema}
        validateOnMount={true}
        // validateOnChange={false}
        onSubmit={(values, actions) => {
          handleCreateForm(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            values,
            isValid,
            dirty,
          } = formikProps;
          if (dirty && !isDirtyForm) {
            setIsDirtyForm(true);
          }
          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>Create Form</p>
                <button
                  className='react-modal-close-icon'
                  onClick={() =>
                    isDirtyForm ? setIsOpenConfirmModal(true) : handleClose()
                  }
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>

              <CotentWrapped className='create-new-form edit-appointment'>
                <form
                  onSubmit={handleSubmit}
                  className='col-12 form-schedule react-modal--settings__content'
                >
                  <div className='setting__content--full-row block'>
                    <span className='title-input-form'>
                      Form Name <b className='require'>*</b>
                    </span>
                    <div className='first-name'>
                      <FastField
                        name='formName'
                        maxLength={100}
                        required
                        placeholder='Form Name'
                        className='setting__search-form'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          setErrorResponse('');
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'formName',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='formName' />
                        {errorResponse}
                      </p>
                    </div>
                  </div>
                  <div className='setting__content--full-row block'>
                    <p
                      className='title-input-form'
                      style={{ display: 'block', marginBottom: '0px' }}
                    >
                      Add to Folder <b className='require'>*</b>
                    </p>
                    <Field
                      as='select'
                      name='folder'
                      value={values.folder || folders[0]?.id}
                      className='full-width custom-color'
                      placeholder='Folder'
                      style={{ width: '50%' }}
                    >
                      {orderBy(folders, ['folder_name'], ['asc'])
                        .filter((item) => !item.is_deleted)
                        .map((folder) => (
                          <option value={folder.id} key={folder.id}>
                            {folder.folder_name}
                          </option>
                        ))}
                    </Field>
                    <p className='error-msg'>
                      <ErrorMessage name='folder' />
                    </p>
                  </div>
                </form>
                <div className='react-modal-settings-footer'>
                  <Button
                    variant='contained'
                    color='default'
                    className='cancel-button'
                    onClick={() =>
                      isDirtyForm ? setIsOpenConfirmModal(true) : handleClose()
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type='button'
                    onClick={() => handleCreateForm(values)}
                    variant='contained'
                    color='primary'
                    disabled={!isValid || disabledSubmit || !!errorResponse}
                  >
                    Create Form
                  </Button>
                </div>
              </CotentWrapped>
            </div>
          );
        }}
      </Formik>
    </ReactModal>
  );
};

export default NewCreateFormModal;
