import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 6px;
    position: relative;
    background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')};
    &.Mui-disabled {
      background-color: ${getThemeProp('palette.action.disabled', 'rgba(0,0,0,1)')};
      color: ${getThemeProp('palette.text.primary', 'rgba(0,0,0,1)')};
    }
    border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
    font-size: 14px;
    padding: 10px 28px 8px 13px;
    max-width: 100%;
    max-height: 36px;
  }
  &.Mui-error {
    border: 1px solid ${getThemeProp('palette.error.main', 'red')};
    border-radius: 6px;
  }
  .MuiInputBase-input {
    padding: 0 !important;
    &.Mui-disabled {
      background-color: ${getThemeProp('palette.action.disabled', 'rgba(0,0,0,1)')};
    }
  }
  svg.MuiSvgIcon-root {
    right: 8px;
    width: 1.4rem;
    height: 1.4rem;
    color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
  }
  .MuiFilledInput-underline {
    &:after {
      content: none;
    }
    &:before {
      content: none;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledTextField };
