import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledButton = styled(Button)`
  border-radius: 6px;
  position: relative;
  background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')};
  border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
  font-size: 14px;
  max-width: 100%;
  text-transform: none;

  &.Mui-disabled {
    background-color: ${getThemeProp('palette.action.disabled', 'rgba(0,0,0,1)')};
    color: ${getThemeProp('palette.text.primary', 'rgba(0,0,0,1)')};
    cursor: not-allowed;
  }

  .disabled {
    color: #6e84a3;
  }
` as typeof Button;

const Main = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
`;

const StyledMenu = styled(Menu)`` as typeof Menu;

const StyledMenuItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
` as typeof MenuItem;

const StyledResetMenuItem = styled(MenuItem)`
  border-top: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
` as typeof MenuItem;

const useStyles = makeStyles({
  popoverPaper: {
    minWidth: '200px',
  },
});

export { StyledButton, Main, StyledMenu, StyledMenuItem, StyledResetMenuItem, useStyles };
