import * as t from 'io-ts';

const StatusDataType = t.type({
  name: t.string,
  value: t.string,
});

const DriverDataType = t.type({
  driverId: t.string,
  driverName: t.string,
});

export const DropdownDataGeneralResponseType = t.partial({
  statues: t.array(StatusDataType),
  appointmentStatues: t.array(StatusDataType),
  drivers: t.array(DriverDataType),
});
export type DropdownDataGeneralResponseType = t.TypeOf<typeof DropdownDataGeneralResponseType>;