import React from 'react';
import { Container } from './styled-components';

export interface StringsFromArrayProps {
  strings: string[];
  nowrap?: boolean;
  addPadding?: boolean;
}

const StringsFromArray = React.forwardRef(
  (props: StringsFromArrayProps, ref: React.Ref<HTMLDivElement>): React.ReactElement => {
    const { strings, nowrap = true, addPadding = false } = props;

    const cleanedStrings: string = strings.join(', ');

    return (
      <Container ref={ref} nowrap={nowrap} addPadding={addPadding}>
        {cleanedStrings}
      </Container>
    );
  },
);

export default StringsFromArray;
