import * as t from 'io-ts';
import { AddressEntryData } from './_Address';
import { FullPhoneNumberEntryData } from './_PhoneNumber';
import { TaskResponseData } from './_Task';
import { UserKindEnum } from '../api-route-types';

// UserDataResponse

const LocationCoordinates = t.type({
  lat: t.number,
  lon: t.number,
  tag: t.string,
});

const AdditionalDetailsObject = t.partial({
  likes: t.string,
  'random-detail': t.string,
  'random-object': LocationCoordinates,
});

const UserSettingDetail = t.partial({
  email: t.boolean,
  phone: t.boolean,
  postal: t.boolean,
  sms: t.boolean,
  birthday: t.boolean,
  appointment: t.boolean,
  reminders: t.boolean,
});

export const UserSettingObject = t.type({
  namespace: t.string,
  settings: UserSettingDetail,
});

const UserDetails = t.partial({
  birthDate: t.string,
  preferredLanguage: t.string,
});

const RequiredUserData = t.type({
  userId: t.string,
  enabled: t.boolean,
  tenant: t.string,
  userKind: t.keyof(UserKindEnum),
});

const NullableUserData = t.partial({
  externalId: t.string,
  // externalStatus: TEMP_EXTERNAL_STATUS,
  pulseUserStatus: t.string,
  name: t.type({
    givenName: t.string,
    lastName: t.string,
  }),
  roles: t.array(t.object),
  emailAddress: t.string,
  details: UserDetails,
  addresses: t.array(AddressEntryData),
  phoneNumbers: t.array(FullPhoneNumberEntryData),
  policies: t.array(t.string),
  createdBy: t.string,
  createdAt: t.string,
  additionalDetails: AdditionalDetailsObject,
  tasks: t.array(TaskResponseData),
  birthDate: t.string,
  userSettings: t.array(UserSettingObject),
  registered: t.boolean,
});

export type UserSettingObjectType = t.TypeOf<typeof UserSettingObject>;

export type NullableUserDataType = t.TypeOf<typeof NullableUserData>;

export const FullUserData = t.intersection([
  RequiredUserData,
  NullableUserData,
]);
export type UserDataResponse = t.TypeOf<typeof FullUserData>;
//
