import * as t from 'io-ts';

export const SyncerGeneralResponseType = t.partial({
  driver: t.string,
  protocol: t.string,
  latestConfiguratorVersion: t.string,
  latestSyncerVersion: t.string,
  latestDriverVersion: t.string,
  lastAuth: t.string,
  lastSync: t.string,
  location: t.string,
});
export type SyncerGeneralResponseType = t.TypeOf<typeof SyncerGeneralResponseType>;