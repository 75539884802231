import styled from 'styled-components';

interface PropTypes {
  nowrap: boolean;
  addPadding: boolean;
}

const Container = styled.div`
  overflow: ${(props: PropTypes): string => (props.nowrap ? 'hidden' : 'normal')};
  text-overflow: ${(props: PropTypes): string => (props.nowrap ? 'ellipsis' : 'none')};
  white-space: ${(props: PropTypes): string => (props.nowrap ? 'nowrap' : 'normal')};
  padding: ${(props: PropTypes): string => (props.addPadding ? '0' : '0px 5px')};
`;
// eslint-disable-next-line import/prefer-default-export
export { Container };
