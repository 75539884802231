import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useStyles } from './styled-components';

interface SmallTitleProps {
  text: string;
  inline: boolean;
}

const SmallTitle = (props: SmallTitleProps): React.ReactElement => {
  const { text, inline } = props;
  const classes = useStyles();
  return (
    <Typography className={clsx(inline && classes.inline)} variant='h6' id='tableTitle'>
      {text}
    </Typography>
  );
};

SmallTitle.defaultProps = {
  inline: false,
};
export default SmallTitle;
