import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { MediumEmphasizedDarkText } from 'components/Legwork/typography';

import Card from '../Card';

const PaddedTypography = styled(MediumEmphasizedDarkText)`
  padding-left: 10px;
` as typeof Typography;

const StyledCard = styled(Card)`
  padding: 0;

  .MuiCardContent-root:last-child {
    padding-bottom: 0.5rem;
  }
`;

export { PaddedTypography, StyledCard };
