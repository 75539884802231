exports.startsWith = function(str, prefix) {
  if (typeof str !== 'string' || typeof prefix !== 'string') {
    return false;
  }
  return str.slice(0, prefix.length) === prefix;
};

exports.replaceAll = function(text, search, replacement) {
  return text.replace(new RegExp(search, 'g'), replacement);
};

exports.reverse = function(str) {
  return str
    .split('')
    .reverse()
    .join('');
};

exports.truncate = function(str, length = 20, end = '...') {
  if (typeof str !== 'undefined' && str.length > length) {
    str = str.substring(0, length - end.length) + end;
  }

  return str;
};
