import styled from 'styled-components';
import React from 'react';

import { IconButton } from '@material-ui/core';

const OpenDrawerButton = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({ open, ...rest }): React.ReactElement => <IconButton {...rest} />,
)`
  && {
    display: ${({ open }): string => (open ? 'none' : 'inline')};
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  button svg {
    height: 32px;
    width: 32px;
  }
`;

const LogoContainer = styled.div`
  display: inline-flex;
  & a img {
    width: 115px;
    height: 25px;
    padding-right: 1rem;
  }
`;

export { OpenDrawerButton, DrawerHeader, LogoContainer };
