import { Button, Modal } from '@material-ui/core';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import headerLogo from 'media/images/Vet-Hero-Frenchie.png';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { NoPermissionRouterProps } from 'types/RouterT';
import * as Yup from 'yup';
import './ResetPassword.scss';

export interface ResetPasswordProps extends NoPermissionRouterProps {}

const ResetPassword = (props: ResetPasswordProps) => {
  window.document.title = 'VetHero CRM | Reset Password';
  const [redirect, setRedirect] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleSubmitSetPassword = async (values: { email: string }) => {
    try {
      setDisabledSubmit(true);
      let formData = new FormData();
      formData.append('emailAddress', values.email);
      const res = await configuredRequests.POST.resetPassword(formData);
      if (res) {
        setShowSuccessPopup(true);
      }
      setDisabledSubmit(false);
    } catch (err) {
      setDisabledSubmit(false);
      if (err.response.status === 400) {
        setErrorPassword(err.response.data.message);
      }
    }
  };

  if (redirect) {
    return <Redirect to='/' />;
  }

  const renderSuccessPopup = () => {
    return (
      <div className='insert-modal-style modal-reset-password'>
        <div className='insert-body'>
          <p className='success-message-content'>
            Your password reset request was successfully. You will receive an
            email containing a link to reset your password within a few minutes.
          </p>
          <p className='success-message-content'>
            If you do not receive an email, please check your Junk E-mail
            folder, or try by submitting the password reset request again.
          </p>
          <p className='success-message-content'>
            If you have any questions, please give us a call toll-free at (888)
            599-6463.
          </p>
        </div>
        <div className='action-modal success-message-button'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setRedirect(true);
            }}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const ErrorMessageType = {
    required: 'This field is required.',
    incorrectEmailFormat: 'Invalid email address.',
  };

  const ResetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ErrorMessageType.incorrectEmailFormat)
      .required(ErrorMessageType.required),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={ResetPasswordValidationSchema}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        handleSubmitSetPassword(values);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, setFieldValue } = formikProps;

        return (
          <>
            <div className='login__container'>
              <div className='login__form-container'>
                <form onSubmit={handleSubmit} className='login__form'>
                  <div className='login__logo-container reset-password-logo'>
                    <img src={headerLogo} alt={'Fetch logo'} />
                  </div>
                  <div className='login__input-area-container'>
                    <div className='login__input-container reset-password-label'>
                      <label htmlFor={'email'}>
                        <span>
                          Enter your email to begin the password reset process:
                        </span>
                      </label>
                      <FastField
                        type='text'
                        name='email'
                        maxLength={255}
                        placeholder='Email'
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const trimText = event.target.value.trim();
                          setErrorPassword('');
                          setFieldValue('email', trimText);
                        }}
                      />
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          padding: '3px 0 1px',
                        }}
                      >
                        {errorPassword || <ErrorMessage name='email' />}
                      </p>
                    </div>
                  </div>
                  <Button
                    variant='contained'
                    color='primary'
                    className='login__input-submit reset-password'
                    type='submit'
                    disabled={!formikProps.isValid || disabledSubmit}
                  >
                    <span>Reset Password</span>
                  </Button>
                </form>
              </div>
              <Modal
                open={showSuccessPopup}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
              >
                {renderSuccessPopup()}
              </Modal>
            </div>
            <div style={{ width: '100%', height: '10px' }} />
          </>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
