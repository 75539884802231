import * as t from 'io-ts';

const MangoPhone = t.type({
  id: t.string,
  tenantId: t.string,
  phoneNumber: t.string,
  createdAt: t.string,
});

export const CollectionMangoPhone = t.array(MangoPhone);

export type MangoPhoneType = t.TypeOf<typeof MangoPhone>;
export type CollectionMangoPhoneType = t.TypeOf<typeof CollectionMangoPhone>;
