import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: auto;
  overflow-x: hidden;
  overflow-anchor: none;
  padding: 20px;
  background-color: #f8f9ff;
  height: calc(100vh - 125px);

  #title-booking-request {
    padding-left: 25px !important;
  }
  .contacts--filter-container {
    margin-top: 20px;
    margin-left: 0px;
    padding-left: 0px;

    .text-chevron-dropdown--container .text-chevron-dropdown--ul {
      width: 102%;
    }
    .text-chevron-dropdown--chevron-container svg {
      font-size: 10px;
    }

    .contacts--filter-search-container {
      svg {
        cursor: auto;
      }
      input {
        border: 1px solid #e3ebf6;
        border-radius: 6px;
        background-color: #ffffff;

        padding-left: 15px !important;
        &::-webkit-search-decoration:hover,
        &::-webkit-search-cancel-button:hover {
          cursor: pointer;
        }

        &:focus::placeholder {
          color: transparent;
        }

        &::placeholder {
          color: #6e84a3;
          font-size: 13px;
          font-weight: 600;
        }
        color: #6e84a3;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
      }
    }

    .contacts--filter--select-container {
      border: 1px solid #e3ebf6;
      border-radius: 6px;
      background-color: #ffffff;

      .text-chevron-dropdown--list-container {
        .text-chevron-dropdown--current-selected-text,
        ul li {
          color: #2c3742;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
  }
  .title-appointment {
    margin-top: 0px;
    color: #12253f;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  .description-appointment {
    color: #617799;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
  }
  hr.line-appointment {
    border: 1px solid #e3ebf6;
  }
`;
