/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createStyles, Switch, Theme, withStyles } from '@material-ui/core';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import Appointment from './Appointment/Appointment';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#107C71',
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const mapKind = {
  0: 'appointment',
  1: 'newcontacts',
  2: 'marketing',
  3: 'reminder',
};

const Automation = (): JSX.Element => {
  const [activeComponent, setActiveComponent] = useState<number>(0);
  const [kind, setKind] = useState<string>('');
  const [automationLayout, setAutomationLayout] = useState<any>([]);
  const [loadingAutomationLeftMenu, setLoadingAutomationLeftMenus] =
    useState<boolean>(true);

  const getListCategory = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.listCategory(2);
      setAutomationLayout(res?.data ?? []);
      setLoadingAutomationLeftMenus(false);
      let tabActive = getTabActiveLocalStorage();
      if (!kind && Number(tabActive.leftMenuContentActive) === 0) {
        setKind('appointment');
      }
    } catch (err) {
      setLoadingAutomationLeftMenus(false);
    }
  }, [kind]);

  useEffect(() => {
    // getAutomationTriggerSwitchStatus();
    let tabActive = getTabActiveLocalStorage();
    if (tabActive) {
      setActiveComponent(Number(tabActive.leftMenuContentActive));
      // @ts-ignore
      setKind(mapKind[Number(tabActive.leftMenuContentActive)]);
    } else {
      setActiveComponent(0);
      // @ts-ignore
      setKind(mapKind[0]);
    }
    getListCategory();
  }, []);

  const handleChangeTab = (numberActive: number) => {
    setActiveComponent(numberActive);
    setTabActiveLocalStorage(null, numberActive, 0);
  };

  const componentValues: {
    innerText: string;
    switchValue: number;
    kind: string;
  }[] = useMemo(() => {
    return automationLayout?.map((automation: any, index: number) => ({
      innerText: automation.categoryName,
      kind: automation.categoryKind,
      switchValue: index,
    }));
  }, [automationLayout]);

  return (
    <div className='automation-container'>
      <div className='communication-analyze'>
        <div className='communication-analyze__right-modal'>
          {loadingAutomationLeftMenu ? (
            <Loading className='loading-communication-left-menu' />
          ) : (
            <ul className='analyze-tab__lists'>
              {componentValues.map((componentValue, index) => {
                return (
                  <li
                    key={'communication-automation-nav-' + index}
                    tabIndex={0}
                    className={
                      'analyze-tab__item-list ' +
                      (activeComponent === componentValue.switchValue
                        ? 'active'
                        : '')
                    }
                    onClick={() => {
                      handleChangeTab(componentValue.switchValue);
                      setKind(componentValue.kind);
                    }}
                  >
                    {componentValue.innerText}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <Appointment kind={kind} />
      </div>
    </div>
  );
};

export default Automation;
