import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledInput = styled(InputBase)`
  .MuiInputBase-input {
    border-radius: 6px;
    position: relative;
    z-index: 1;
    /* background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')}; */
    &.Mui-disabled {
      background-color: ${getThemeProp('palette.action.disabled', 'rgba(0,0,0,1)')};
      color: ${getThemeProp('palette.text.primary', 'rgba(0,0,0,1)')};
      cursor: not-allowed;
    }
    border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
    font-size: 14px;
    padding: 10px 40px 8px 13px !important;
    max-width: 100%;
    max-height: 17px;
    .disabled {
      color: #6e84a3;
    }
  }
  &.Mui-error {
    border: 1px solid ${getThemeProp('palette.error.main', 'red')};
    border-radius: 6px;
  }
  &.MuiInputBase-input {
    border-radius: 6px;
    position: relative;
    /* background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')}; */
    border: 1px solid #4c7cfd;
    font-size: 14px;
    padding: 10px 10px 8px 13px;
    max-width: 100%;
    max-height: 17px;
    opacity: 0.6;
  }

  svg.MuiSelect-icon {
    right: 8px;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: 0.1rem;
    color:#6E84A3 !important;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
`;

const StyledSelect = styled(Select)`

  .MuiSvgIcon-root {
    position: absolute;
    right: 0px;
  }
  &.Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
  .Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
`;

const Main = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
`;

export { StyledInput, StyledInputLabel, Main, StyledSelect };
