/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TemplateAnalyzeResponse } from 'global/requests/ResponseTypes';
import { debounce, isEmpty, pickBy } from 'lodash';
import moment from "moment";
import React, { useCallback, useState, useEffect } from "react";
import { convertPhoneNumber } from "utils/convertData";
import { RedirectLink } from '../Performance/Performance';
import { useRouter } from 'hooks/useRouter';
import { isWindows } from 'react-device-detect';
import { WrapperTable } from 'components/Communications/Analyze/InvalidTarget/InvalidTarget';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import loading_spinner from 'media/images/loading-spinner.svg';

type TemplateDataAnalyze = {
  requestDate: string,
  userFullName: string,
  phoneNumber: string,
  email: string,
  userId: string
};

const columns = [
  { key: 'requestDate', title: 'Date' },
  { key: 'userFullName', title: 'Name' },
  { key: 'phoneNumber', title: 'Phone Number' },
  { key: 'email', title: 'Email' },
];

const Unsubcribed = (): JSX.Element => {
  const router = useRouter();
  const [dataTable, setDataTable] = React.useState<TemplateAnalyzeResponse>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [queryPagination, setQueryPagination] = useState({ limit: 10, offset: 0 });
  const [textSearch, setTextSearch] = useState('');

  const getTemplateAnalyze = useCallback(async (query: Record<string, unknown>) => {
    try {
      const response = await configuredRequests.GET.getTemplateAnalyze({
        ...query,
        limit: query?.limit ?? 10,
        offset: query?.offset ?? 0,
        kind: query?.kind ?? '1'
      })
      setDataTable(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const handleChangePage = useCallback(async (event: unknown, newPage: number) => {
    setQueryPagination(preState => ({ ...preState, offset: newPage }));
    await getTemplateAnalyze({
      ...queryPagination,
      offset: newPage,
      ...pickBy({ contactName: textSearch.trim() })
    });
  }, [getTemplateAnalyze, queryPagination, textSearch]);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage: number = parseInt(event.target.value);
    if (newRowsPerPage) {
      setQueryPagination({ limit: newRowsPerPage, offset: 0 });
      getTemplateAnalyze({
        ...queryPagination,
        limit: newRowsPerPage,
        offset: 0,
        ...pickBy({ contactName: textSearch.trim() })
      });
    }
  }, [getTemplateAnalyze, textSearch, queryPagination]);

  const searchMessageWithName = useCallback(debounce((name: any) => {
    getTemplateAnalyze({
      limit: 10,
      offset: 0,
      contactName: name ? name.trim() : undefined,
    });
  }, 1000), [getTemplateAnalyze,]);

  const onChangeTextSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setTextSearch(value);
    setQueryPagination({ limit: 10, offset: 0 });
    searchMessageWithName(value);
  }, [searchMessageWithName]);

  useEffect(() => {
    getTemplateAnalyze({ limit: 10, offset: 0 });
  }, []);

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  return (
    <div className="communication-container  w-300">
      {loading ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> :
        <div className="communication-container__table w-table-cell ">
          <div className="email-sent__table-title"> Unsubscribed </div>
          <div className="contacts--filter-search-container">
            <FontAwesomeIcon
              icon={['far', 'search']}
              onClick={() => searchMessageWithName(textSearch)}
            />

            <input
              className="contacts--filter--search-input"
              value={textSearch}
              type="text"
              placeholder="Search by Name"
              onChange={onChangeTextSearch}
            />
          </div>
            {!isEmpty(dataTable?.items) ?
              <WrapperTable>
                <Table
                  columns={columns}
                  // @ts-ignore
                  rows={dataTable.items.map((data: TemplateDataAnalyze) => {
                    return {
                      requestDate: moment(data.requestDate).format('MM-DD-YYYY'),
                      userFullName: <RedirectLink onClick={() => router.push(`/Contacts/View/${data.userId}`)}>
                        <HighlightText
                          text={data.userFullName}
                          searchValue={textSearch.trim()}
                        />
                      </RedirectLink>,
                      phoneNumber: data.phoneNumber ? convertPhoneNumber(data.phoneNumber) : '',
                      email: data.email,
                      id: data.userId,
                    }
                  }) || []}
                  isHasPagination={true}
                  // @ts-ignore
                  totalAvailable={dataTable?.totalAvailable ?? 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={queryPagination?.limit ?? 10}
                  page={queryPagination?.offset ?? 0}
              /></WrapperTable> :
              <p className='no-data-results' style={{ fontWeight: 'bold' }}>No results found</p>
            }
        </div>
      }
    </div>
  );
}

export default Unsubcribed;