import { CSSProperties } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      rounded: {
        color: theme.palette.primary.main,
        backgroundColor: '#E1F0FF',
        fontWeight: 500,
      },
      expandIcon: {
        color: '#aaaaaa',
      },
    }),
);

const defaultButtonOverrides: CSSProperties = {
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  textTransform: 'none',
  marginLeft: '10px',
};

const buttonOverrides = (style: CSSProperties = {}): CSSProperties => {
  return { ...defaultButtonOverrides, ...style };
};

export { useStyles, buttonOverrides };
