import { validateSpecificApiResponseType } from "global/requests/ResponseTypeguards";
import { TenantInfoResponse, FullTenantInfoResponse } from "global/requests/ResponseTypes/Tenants";
import { UserDataResponse, FullUserData } from "global/requests/ResponseTypes/UserAdministration";

const userDataSessionKey = "userData--full_response";
const tenantInfoSessionKey = "tenantInfo--full_response";

export const setSessionUserData = (newState: UserDataResponse) => {
  const stateString = JSON.stringify(newState);
  // console.log('set userData state', stateString);
  sessionStorage.setItem(userDataSessionKey, stateString);
}

export const getSessionUserData = (): UserDataResponse | undefined => {
  const sessionData = sessionStorage.getItem(userDataSessionKey);
  // console.log('got userData for this session:', !!sessionData);
  if (sessionData) {
    const parsedSessionData = JSON.parse(sessionData);
    // console.log('parsed session data', parsedSessionData);
    if (validateSpecificApiResponseType<UserDataResponse>(parsedSessionData, FullUserData)) {
      // console.log('successfully validated stored userData');
      return parsedSessionData as UserDataResponse
    }
  }
  if (sessionData === null) {
    if (process.env.NODE_ENV !== "development") {
      return;
    }
    console.log('New session? Could not validate session data');
    return;
  }
  console.log('user data in session storage was invalid, but not null, something is wrong...');
  //TODO add logging here for PROD
  return;
}

export const setSessionTenantInfo = (newState: TenantInfoResponse) => {
  const stateString = JSON.stringify(newState);
  // console.log('set tenantInfo state', stateString);
  sessionStorage.setItem(tenantInfoSessionKey, stateString);
}

export const getSessionTenantInfo = (): TenantInfoResponse | undefined => {
  const sessionData = sessionStorage.getItem(tenantInfoSessionKey);
  // console.log('got tenantInfo for this session:', !!sessionData);
  if (sessionData) {
    const parsedSessionData = JSON.parse(sessionData);
    // console.log('parsed session data', parsedSessionData);
    if (validateSpecificApiResponseType<TenantInfoResponse>(parsedSessionData, FullTenantInfoResponse)) {
      // console.log('successfully validated stored tenantInfo');
      return parsedSessionData as TenantInfoResponse
    }
  }
  if (sessionData === null) {
    if (process.env.NODE_ENV !== "development") {
      return;
    }
    console.log('New session? Could not validate session data');
    return;
  }
  console.log('tenant info in session storage was invalid, but not null, something is wrong...');
  //TODO add logging here for PROD
  return;
}
