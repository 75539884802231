import React from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';

import { generateSeriesData, generateSeries } from './utils';
import { generateLegend } from './constants';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface PieChartProps {
  outerRadius: number;
  values: number[];
  titles: string[];
  colors: string[];
  legendVerticalShift: number;
  height?: string;
}

const PieChart = (props: PieChartProps): React.ReactElement => {
  const { legendVerticalShift, outerRadius, values, titles, colors, height = null } = props;
  const legend = generateLegend();
  legend.y = legendVerticalShift;
  const generateOptions = (): object => ({
    title: { text: '' },
    legend,
    chart: { height },
    series: generateSeries(generateSeriesData(values, titles, colors), outerRadius),
    credits: {
      enabled: false,
    },
  });
  return <HighchartsReact highcharts={Highcharts} options={generateOptions()} />;
};

PieChart.defaultProps = {
  legendVerticalShift: 0,
  outerRadius: 100,
  values: [1, 2, 3, 4, 5],
  titles: ['a', 'b', 'c', 'd', 'e'],
  colors: ['#023EE3', '#4C7CFD', '#E1F0FF', '#B8C3D2', '#12253F'],
};
export default PieChart;
