import styled from 'styled-components';
import { getThemeProp } from '../../utils';
import { Card } from 'components/Legwork/cards';

const StyledCard = styled(Card)`
  padding: 0;
`;

const WeekdayElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`;

const WeekdayContainer = styled.div`
  border-bottom: 1px solid ${getThemeProp('palette.divider', 'grey')};
  &:last-child {
    border-bottom: none;
  }
`;
export { WeekdayElement, StyledCard, WeekdayContainer };
