import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
  Theme,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import React, { FC, useRef } from 'react';

interface ProviderItemProps {
  provider: ProviderModal;
  handleChange: (provider: ProviderModal) => void;
  handleOpenEditPopup: (provider: ProviderModal) => void;
  handleOpenPopup: (provider: ProviderModal) => void;
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#31CC97',
          borderColor: '#31CC97',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
      padding: '6px 12px'
    },
  })
);

const ProviderItem: FC<ProviderItemProps> = ({
  provider,
  handleChange,
  handleOpenEditPopup,
  handleOpenPopup,
}) => {
  const classes = useStyles();

  return (
    <div className='provider-box'>
      <div className='d-flex'>
        <Tooltip
          // @ts-ignore
          title={provider.name}
          placement="bottom-end"
          classes={{ tooltip: classes.tooltip }}
          // @ts-ignore
          disableHoverListener={provider.name.length < 120}
        >
          <h3 className='provider-name'>{!!provider.name?.trim() ? provider.name : "No Name"}</h3>
        </Tooltip>

        <FormControlLabel
          control={
            <AntSwitch
              checked={provider.isBookingActive}
              onChange={() => handleChange(provider)}
              name='checkedA'
            />
          }
          label='Accepting Appointments'
        />
      </div>

      <Tooltip
        // @ts-ignore
        title={provider.emailAddress}
        placement="bottom-end"
        classes={{ tooltip: classes.tooltip }}
        // @ts-ignore
        disableHoverListener={provider.emailAddress.length < 50}
      >
        <p className='provider-email'>{provider.emailAddress}</p>
      </Tooltip>
      <div className='d-flex'>
        <button
          className='provider-detail'
          onClick={() => {
            handleOpenEditPopup(provider);
          }}
        >
          View Details
        </button>
        {!provider.externalId && <button
          className='provider-delete'
          onClick={() => {
            handleOpenPopup(provider);
          }}
        >
          Delete
        </button>}
      </div>
    </div>
  );
};

export default ProviderItem;
