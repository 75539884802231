import React from 'react';
import {
  createStyles,
  FormControlLabel,
  Theme,
  Switch,
  withStyles,
  FormControlLabelProps,
} from '@material-ui/core';

interface IProps extends Omit<FormControlLabelProps, 'control'> {
  label: string | undefined;
  classMore?: string;
  onChange: () => void;
}

const SmallSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#31CC97',
          borderColor: '#31CC97',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const AntSwitch = (props: IProps) => {
  const { label = '', checked, classMore = '', name = '', onChange } = props;
  return (
    <FormControlLabel
      control={
        <SmallSwitch checked={checked} onChange={onChange} name={name} />
      }
      label={label}
      className={classMore}
      labelPlacement={props.labelPlacement}
    />
  );
};

export default AntSwitch;
