import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import getThemeProp from 'components/Legwork/utils/getThemeProp';
import { KeyboardTimePicker } from '@material-ui/pickers';

const StyledInputLabel = styled(InputLabel)`
  font-size: 0.9rem;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
  margin-left: 2px;
  margin-bottom: 0.5rem;
`;

const StyledTimePicker = styled(KeyboardTimePicker)`
  width: 100%;
  .MuiInputBase-root {
    border-radius: 6px;
    border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
    font-size: 14px;
    padding: 0px 0px 0px 13px;
    max-width: 100%;
    background-color: white;
  }
  .MuiInputBase-input {
    padding: 10px 0 10px 0;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  .MuiPickersClockNumber-clockNumber {
    color: white;
  }
`;
export { StyledInputLabel, StyledTimePicker };
