import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import { getThemeProp } from 'components/Legwork/utils';

export interface ObjectMap {
  [key: string]: string | number;
}

const IconLeftButton = styled(Button)`
  border: 1px solid #d4dbe4;
  border-right: 1px solid #d4dbe4;
  border-radius: 6px 0px 0px 6px;
  background-color: white;
  flex: 1;
  height: 36px;
  width: 36px;
  min-width: 36px; /* This overrides the mui button default */
  box-sizing: border-box;
  margin-right: -1px;
  text-transform: none;
  z-index: 1;
  &.Mui-disabled {
    svg {
      fill: #d4dbe4;
    }
  }
`;

const IconRightButton = styled(Button)`
  border: 1px solid #d4dbe4;
  border-left: none;
  border-radius: 0px 6px 6px 0px;
  background-color: white;
  flex: 1;
  height: 36px;
  width: 36px;
  min-width: 36px; /* This overrides the mui button default */
  margin-right: -1px;
  text-transform: none;
  &.Mui-disabled {
    svg {
      fill: #d4dbe4;
    }
  }
`;

const Main = styled.div`
  padding-right: 20px;
`;

const TextButton = styled(Button)`
  border: 1px solid #d4dbe4;
  border-left: none;
  border-radius: 0px;
  flex: 1;
  height: 36px;
  min-width: 36px;
  color: ${(props: { selected: boolean }): string =>
    props.selected ? `${getThemeProp('palette.text.primary')}` : '#606d7e'};
  background-color: ${(props: { selected: boolean }): string =>
    props.selected ? '#f6f8fc' : 'white'};
  align-items: 'center';
  justify-content: 'center';
  padding-left: 5px;
  padding-right: 5px;
  text-transform: none;
`;

const StyledTablePagination = styled(TablePagination)`
  .MuiTablePagination-toolbar {
    border-bottom: none;
    border-top: 2px;
  }
  .MuiTablePagination-spacer {
    flex: 0;
    visibility: hidden;
  }

  .MuiTablePagination-caption {
    flex: 1;
  }
` as typeof TablePagination;

const StyledFooter = styled.div`
  .MuiTableRow-footer {
    .MuiTableCell-root {
      width: 100vw;
    }
  }

  .MuiToolbar-gutters {
    padding: 0 2px;

    // pagination buttons container
    > div:last-child {
      padding-right: 0;
    }
  }
`;

const Results = styled.span`
  font-size: 14px;
  display: inline-block;
  span ~ span {
    margin-left: 4px;
  }
`;

const ResultsNumber = styled.span`
  font-weight: 500;
`;

export {
  IconLeftButton,
  TextButton,
  StyledTablePagination,
  IconRightButton,
  Main,
  StyledFooter,
  Results,
  ResultsNumber,
};
