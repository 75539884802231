import React, { useEffect, useState } from 'react';
import Table from 'components/Global/Table';
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';
// import { OfficeEvent } from '../AvailabilityVM';
import ContentModalAddOfficeEvent from './ContentModalAddOfficeEvent';
import loading_spinner from 'media/images/loading-spinner.svg';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import { convertTime } from '../helpers/convertTime';
import moment from 'moment';
import Modal from 'components/Global/Modal';
import { OfficeEvent } from '../../Providers/ProviderAvailabilityVM';

const LoadingWrapper = styled.div`
  text-align: center;
  img {
    width: 70px;
  }
`;
const TableWrapper = styled.div`
  table {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    thead > tr > th {
      color: #6e84a3;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
    tbody {
      > tr > td {
        color: #12253f;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;

        &:first-child {
          max-width: 350px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          min-width: 160px;
        }

        &:nth-child(3) {
          min-width: 165px;
        }

        &:last-child {
          min-width: 210px;
        }
      }
      .button-group-add-office {
        text-align: center;

        button {
          min-width: 75px;
        }

        .edit-button {
          border: 1px solid #1974ff;
          border-radius: 3px;

          background-color: #ffffff;
          color: #1974ff;

          text-transform: unset;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;
        }

        .delete-button {
          border: 1px solid #717171;
          border-radius: 3px;

          background-color: #ffffff;
          color: #5f5f5f;

          text-transform: unset;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;

          margin-left: 15px;
        }
      }
    }
  }
`;

interface IHoursOfOperationForDay {
  setIsSelectedDelete: (args: boolean) => void;
  onShowModalAddOfficeEvent: () => void;
  onShowModalEditEvent: (officeEvent: OfficeEvent | null) => void;
  onShowModalDeleteEvent: (officeEvent: OfficeEvent | null) => void;
  onCloseModalAddOfficeEvent: () => void;
  onShowModalCornfirm: () => void;
  onCloseModalConfirmModal: () => void;
  onChangeIsDirtyForm: (isDirty: boolean) => void;
  onChangeErrorExistEventName: (error: string) => void;
  createOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  updateOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  deleteOutOfOfficeEvent: (id: string) => void;
  selectedEvent: OfficeEvent | null;
  listOfficeOff: OfficeEvent[];
  isShowModalAddOfficeEvent: boolean;
  isShowConfirmModal: boolean;
  isDirtyForm: boolean;
  isLoadingEvent: boolean;
  isSelectedDelete: boolean;
  errorExistEventName: string;
  maxSize: number;
}

const columns = [
  { key: 'name', title: 'Event Name' },
  { key: 'eventDate', title: 'Date' },
  { key: 'allDayEvent', title: 'Time Off' },
  { key: 'repeatLabel', title: 'Repeats' },
  { key: 'repeatEndDateLabel', title: 'Repeats Until' },
  { key: 'component', title: '' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
      // position: 'absolute',
      // right: '-500px',
    },
  })
);

const AddOutOfOfficeEvent = (props: IHoursOfOperationForDay) => {
  const {
    isShowModalAddOfficeEvent,
    isDirtyForm,
    selectedEvent,
    isShowConfirmModal,
    isLoadingEvent,
    isSelectedDelete,
    listOfficeOff,
    errorExistEventName,
    maxSize,
    setIsSelectedDelete,
    onShowModalAddOfficeEvent,
    onCloseModalAddOfficeEvent,
    onShowModalEditEvent,
    onShowModalCornfirm,
    onChangeIsDirtyForm,
    onChangeErrorExistEventName,
    onCloseModalConfirmModal,
    onShowModalDeleteEvent,
    createOutOfOfficeEvent,
    updateOutOfOfficeEvent,
    deleteOutOfOfficeEvent,
  } = props;
  const classes = useStyles();

  // useEffect(() => {
  //   listOfficeOff.sort((officeA: OfficeEvent, officeB: OfficeEvent) => {
  //     return new Date(officeA.eventDate).getTime() - new Date(officeB.eventDate).getTime();
  //   })
  // }, [listOfficeOff])

  const formatDate = (eventDate: string): string => {
    let day = new Date(eventDate).toString().slice(0, 3);
    let date = moment(eventDate).format('MMM Do YYYY').split(' ');
    return `${day} ${date[0]} ${date[1]}, ${date[2]}`;
  };

  const handleConfirmModal = () => {
    onCloseModalAddOfficeEvent();
    onChangeIsDirtyForm(false);
    onCloseModalConfirmModal();
  };

  const handleCloseModal = () => {
    if (isDirtyForm) {
      onShowModalCornfirm();
    } else {
      onCloseModalAddOfficeEvent();
      onChangeIsDirtyForm(false);
    }
  };

  return (
    <div className='office-unavailability'>
      <p className='add-out-of-office' onClick={onShowModalAddOfficeEvent}>
        + Add Out of Office Event
      </p>
      <>
        {isLoadingEvent ? (
          <LoadingWrapper>
            <img
              className='loading-hours-spin'
              src={loading_spinner}
              alt={'Loading'}
            />
          </LoadingWrapper>
        ) : (
          <>
            {
              listOfficeOff.length > 0 && (
                <TableWrapper>
                  <Table
                    columns={columns}
                    // @ts-ignore
                    rows={
                      listOfficeOff.map((item: OfficeEvent) => {
                        return {
                          ...item,
                          name: (
                            <Tooltip
                              title={item.name}
                              placement='bottom-start'
                              classes={{ tooltip: classes.tooltip }}
                              disableHoverListener={item.name.length < maxSize}
                            >
                              <span>{item.name}</span>
                            </Tooltip>
                          ),
                          allDayEvent: item.allDayEvent
                            ? 'All Day'
                            : `${convertTime(item.startTime)} - ${convertTime(
                                item.endTime
                              )}`,
                          eventDate: item.eventDate
                            ? formatDate(item.eventDate)
                            : '',
                          component: (
                            <div className='button-group-add-office'>
                              <Button
                                variant='contained'
                                color='default'
                                className='edit-button'
                                onClick={() => onShowModalEditEvent(item)}
                              >
                                {' '}
                                Edit{' '}
                              </Button>
                              <Button
                                onClick={() => onShowModalDeleteEvent(item)}
                                variant='contained'
                                color='primary'
                                className='delete-button'
                              >
                                {' '}
                                Delete{' '}
                              </Button>
                            </div>
                          ),
                        };
                      }) ?? []
                    }
                    isHasPagination={false}
                    // @ts-ignore
                    // totalAvailable={templateRecipients?.totalAvailable ?? 0}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    // rowsPerPage={queryPagination?.limit ?? 10}
                    // page={queryPagination?.offset ?? 0}
                  />
                </TableWrapper>
              )
              // : <p className='no-data-results' style={{ fontWeight: 'bold' }}>
              //     No results found</p>
            }
            <Modal
              isOpenModal={isShowModalAddOfficeEvent}
              title={
                selectedEvent
                  ? 'Edit Out of Office Event'
                  : 'Add Out of Office Event'
              }
              handleCloseModal={handleCloseModal}
              isShowButtonModal={false}
              isHandleClickOutSide={true}
              classMore='modal-add--event'
            >
              <ContentModalAddOfficeEvent
                isDirtyForm={isDirtyForm}
                setIsDirtyForm={onChangeIsDirtyForm}
                onChangeErrorExistEventName={onChangeErrorExistEventName}
                detailData={selectedEvent}
                createOutOfOfficeEvent={createOutOfOfficeEvent}
                updateOutOfOfficeEvent={updateOutOfOfficeEvent}
                errorExistEventName={errorExistEventName}
              />
            </Modal>
            <Modal
              isOpenModal={isSelectedDelete}
              handleCloseModal={() => setIsSelectedDelete(false)}
              title='Delete Out of Office Event'
              handleConfirmModal={() =>
                deleteOutOfOfficeEvent(selectedEvent?.id ?? '')
              }
            >
              <>
                Are you sure you want to delete this{' '}
                <b>{selectedEvent?.name}</b> event? <br /> This action can be
                undone.
              </>
            </Modal>
            <Modal
              isOpenModal={isShowConfirmModal}
              handleCloseModal={onCloseModalConfirmModal}
              title={selectedEvent ? 'Discard Changes?' : 'Confirmation!'}
              children='Your data will be lost. Do you wish to continue?'
              handleConfirmModal={handleConfirmModal}
            />
          </>
        )}
      </>
    </div>
  );
};

export default React.memo(AddOutOfOfficeEvent);
