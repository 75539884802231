import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import StandardTable from 'components/Legwork/tables/StandardTable';
import { SmallLinkText } from 'components/Legwork/typography';
import axios, { AxiosPromise, AxiosResponse, CancelTokenSource, AxiosRequestConfig } from 'axios';
import {
  AttachmentButtonContainer,
  HiddenInput,
  LabelContainer,
  StyledLabel,
  TableContainer,
} from './styled-components';
import {
  attachmentsToRows,
  createNewAttachmentsState,
  processAttachmentByIndex,
  getGenerateRow,
  getIntlFormat,
  getTableHeaderNames,
} from './utils';

export interface IAttachment {
  id: string;
  file: File;
  status: 'notstarted' | 'uploading' | 'complete' | 'cancelled' | 'error';
  progress: number; // This is a percentage
  cancelToken?: CancelTokenSource;
}

export interface AddAttachmentsButtonProps {
  uploadCreateHandler: (attachments: IAttachment[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadSuccessHandler: (attachment: IAttachment, data: any) => void; // callback fired when an attachment is uploaded successfully
  uploadErrorHandler: (error: object) => void; // this will passed the error object for both upload errors and cancellations
  removeAttachmentHandler: (...attachment: IAttachment[]) => void; // This should make the call to the backend.
  uploadRequestConfig: AxiosRequestConfig; // at a minimum: method, url, might also contain headers, etc.
}

const AddAttachmentsButton = (props: AddAttachmentsButtonProps): React.ReactElement => {
  const {
    uploadRequestConfig,
    uploadCreateHandler,
    uploadSuccessHandler,
    uploadErrorHandler,
    removeAttachmentHandler,
  } = props;
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [inputKey, setInputKey] = useState(Date.now);
  const [tableKey, setTableKey] = useState<number>(0);
  const fmPrefix = 'componentLib.attachmentsButton';
  const fm = getIntlFormat(useIntl(), fmPrefix);

  const handleRemoveAndCancel = (attachment: IAttachment): void => {
    if (attachment.cancelToken) {
      attachment.cancelToken.cancel();
    } else {
      const updatedAttachments = [...attachments];
      processAttachmentByIndex(attachment.id, updatedAttachments, index => {
        updatedAttachments.splice(index, 1);
        setAttachments(updatedAttachments);
        removeAttachmentHandler(attachment);
      });
    }
  };

  const uploadErrorHandlerCallback = useCallback(uploadErrorHandler, []);
  const uploadSuccessHandlerCallback = useCallback(uploadSuccessHandler, []);

  const sendRequest = useCallback(
    (attachedFile: IAttachment): AxiosPromise => {
      const data = new FormData();
      data.append('file', attachedFile.file, attachedFile.file.name);

      const source = axios.CancelToken.source();
      const config = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onUploadProgress: (progressEvent: any): void => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const updatedAttachments = [...attachments];

          processAttachmentByIndex(attachedFile.id, updatedAttachments, index => {
            updatedAttachments[index].progress = percentCompleted;
            setAttachments(updatedAttachments);
          });
        },
        cancelToken: source.token,
      };

      const newAttachments = [...attachments];
      processAttachmentByIndex(attachedFile.id, newAttachments, index => {
        newAttachments[index].cancelToken = source;
        setAttachments(newAttachments);
      });

      return (
        axios({ ...uploadRequestConfig, data, ...config })
          .then(
            (result: AxiosResponse): AxiosResponse => {
              const updatedAttachments = [...attachments];
              processAttachmentByIndex(attachedFile.id, updatedAttachments, index => {
                updatedAttachments[index].status = 'complete';
                updatedAttachments[index].cancelToken = undefined;
                setAttachments(updatedAttachments);
                uploadSuccessHandlerCallback(updatedAttachments[index], result.data);
              });
              return result;
            },
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any): any => {
            const updatedAttachments = [...attachments];
            processAttachmentByIndex(attachedFile.id, updatedAttachments, index => {
              if (axios.isCancel(error)) {
                updatedAttachments[index].status = 'cancelled';
              } else {
                updatedAttachments[index].status = 'error';
              }
              updatedAttachments[index].cancelToken = undefined;
              setAttachments(updatedAttachments);
            });
            uploadErrorHandlerCallback(error);
          })
      );
    },
    [attachments, uploadErrorHandlerCallback, uploadRequestConfig, uploadSuccessHandlerCallback],
  );

  const uploadAttachments = useCallback(
    async (uploadingAttachments: IAttachment[]): Promise<void> => {
      const updatedAttachments = [...attachments];
      uploadingAttachments.forEach((upload: IAttachment): void => {
        processAttachmentByIndex(upload.id, updatedAttachments, index => {
          updatedAttachments[index].status = 'uploading';
        });
      });

      setAttachments(updatedAttachments);
      const promises = uploadingAttachments.map(
        (upload: IAttachment): AxiosPromise => sendRequest(upload),
      );
      try {
        await Promise.all(promises);
      } catch (error) {
        uploadErrorHandlerCallback(error);
      }
    },
    [attachments, sendRequest, uploadErrorHandlerCallback],
  );

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // This next line forces the <input> to rerender and so resets it. This allows
    // the selection of the same file twice.
    setInputKey(Date.now());

    const { attachments: newAttachments, toRemove = [] } = createNewAttachmentsState(
      event,
      attachments,
    );

    setAttachments(newAttachments);

    if (toRemove.length) {
      toRemove.forEach(attachment => {
        if (attachment.cancelToken) {
          attachment.cancelToken.cancel();
        }
      });
      removeAttachmentHandler(...toRemove);
    }

    uploadCreateHandler(newAttachments);
  };

  useEffect((): void => {
    const uploadsToStart = attachments.filter(
      (attachment): boolean => attachment.status === 'notstarted',
    );
    if (uploadsToStart.length) {
      uploadAttachments(uploadsToStart);
    }
    const cancelledUploads = attachments.filter(
      (attachment): boolean => attachment.status === 'cancelled',
    );
    if (cancelledUploads && cancelledUploads.length) {
      const updatedAttachments = [...attachments];
      cancelledUploads.forEach((cancelledUpload: IAttachment) => {
        processAttachmentByIndex(cancelledUpload.id, updatedAttachments, index => {
          updatedAttachments.splice(index, 1);
        });
      });
      setAttachments(updatedAttachments);
      // This next line forces the <table> to rerender
      setTableKey(tableKey + 1);
    }
  }, [attachments, uploadAttachments, tableKey]);

  const rows = attachmentsToRows(attachments);
  const showAttachmentsTable = !!(attachments && attachments.length);

  return (
    <AttachmentButtonContainer>
      {showAttachmentsTable && (
        <TableContainer key={tableKey}>
          <StandardTable
            headCells={getTableHeaderNames(fm)}
            rows={rows}
            generateRow={getGenerateRow(handleRemoveAndCancel, fm)}
            dense
          />
        </TableContainer>
      )}
      <HiddenInput
        type='file'
        id='email_attachments'
        name='email_attachments'
        key={inputKey}
        onChange={handleFileUpload}
        multiple
      />
      <LabelContainer>
        <StyledLabel htmlFor='email_attachments'>
          <SmallLinkText>{`+ ${fm('attachments')}`}</SmallLinkText>
        </StyledLabel>
      </LabelContainer>
    </AttachmentButtonContainer>
  );
};

export default AddAttachmentsButton;
