import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import React from 'react';

interface SubscriptionLayerProps {
  subscription: boolean;
  message: string;
  userId: string;
  inMessenger?: boolean;
  onSubscriptionClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionLayer = (props: SubscriptionLayerProps): JSX.Element => {
  return (
    <div
      className={`${!props.subscription ? 'gray-out' : 'gray-hide'} ${
        !!props.inMessenger ? 'in-messenger' : ''
      }`}
    >
      <p className='error-msg'>{props.message}</p>
      <Button
        variant='contained'
        endIcon={
          <FontAwesomeIcon
            icon={['far', 'edit']}
            className='icon--edit-phone'
          />
        }
        onClick={() => props.onSubscriptionClick(true)}
      >
        Edit Client Subscriptions
      </Button>
    </div>
  );
};

export default SubscriptionLayer;
