import React from 'react';
import styled from 'styled-components';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

interface TextareaProps extends InputBaseProps {
  resize: string;
}

const Label = styled(InputLabel)`
  margin: 1rem 0 0.5rem 1px;
`;
const ResizeArea = ({ resize, ...rest }: TextareaProps): React.ReactElement => (
  <InputBase {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
);

const Textarea = styled(ResizeArea)`
  padding: 0;
  border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffffff;
  .MuiInputBase-input {
    font-size: 0.9rem;
    padding: 0;
  }

  .MuiInputBase-inputMultiline {
    padding: 9px 13px;
    resize: ${({ resize }: TextareaProps): string => resize};
  }
  &.Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
  .Mui-disabled {
    background-color: #e3ebf6;
    color: #12253f;
  }
`;

const Main = styled.div``;

export { Label, Textarea, Main };
