import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
// eslint-disable react/jsx-props-no-spreading
const StyledComponents = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '2px',
    paddingRight: '3px',
    paddingLeft: '3px',
  },
})(
  (props: MenuProps): React.ReactElement => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  ),
);

export default StyledComponents;
