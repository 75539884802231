import { useEffect } from 'react';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core';
import MIC_ON_ICON from '../../../images/endcall.svg';
import axios, { AxiosResponse } from 'axios';
import './EndCall.scss'
import { baseUrl } from "telemed-video-call/environment";
const END_CALL_STT = "complete"

interface IProps {
  appointmentId: string | null;
  accessToken: string | null;
  onClickEndCall(): void;
  userJoinRoom?: boolean;
}

interface IResponseAppointment {
  status: number;
  error: string;
}

const EndCallControl: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [disableEndCall, setDisableEndCall] = React.useState(false);

  const eventBeforeUnload = (event: BeforeUnloadEvent) => {
    // check open state in event handler callback to avoid adding/removing handler based on state
    if (!open) {
      // call preventDefault to show dialog in modern browser
      event.preventDefault();
      // older browsers don't support preventDefault showing dialog, they expect a returned string
      // event.returnValue is deprecated, you can just return the string to support old browsers
      return 'It looks like you have been editing something. If you leave before saving, your changes will be lost.';
    }
  };

  useEffect(() => {
    if (!props.userJoinRoom) {
        setDisableEndCall(true);
      } else {
        setDisableEndCall(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userJoinRoom]);

  // add the eventListener on load and return the cleanup handler to prevent memory leaks
  useEffect(() => {
    window.addEventListener('beforeunload', eventBeforeUnload);
    return () => window.removeEventListener('beforeunload', eventBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatus = () => {
    let data = {
      status: END_CALL_STT,
    };
    disableButton(true);
    axios.patch(`${baseUrl}/appointments/V1/${props.appointmentId}`, data, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }
    ).then((response: AxiosResponse<IResponseAppointment>) => {
      if (200 === response.status) {
        props.onClickEndCall();
      } else {
        disableButton(false);
      }
    }).catch((error: string) => {
      console.log('error caught in updateStatus request', error);
    })
  }

  const disableButton = (isDisable: boolean) => {
    let confirmButton = document.getElementsByClassName('confirm-btn')[0];
    let cancelButton = document.getElementsByClassName('cancel-btn')[0];
    if(confirmButton && cancelButton) {
      if (isDisable) {
        confirmButton.setAttribute('disabled', 'disabled');
        cancelButton.setAttribute('disabled', 'disabled');
      } else {
        confirmButton.removeAttribute('disabled');
        cancelButton.removeAttribute('disabled');
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickCancel = () => {
    setOpen(false);
  };

  const handleClickOk = () => {
    updateStatus();
  };

  // double check that the alt property is relevant to the content TODO
  const endCallButton = (
    <div className='end-appointment'>
      <Tooltip title='End Appointment'>
        <img
          className={!disableEndCall ? 'itemCenter' : 'itemCenter disableButton'}
          alt='microphone icon'
          src={MIC_ON_ICON}
          onClick={handleClickOpen}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClickCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'End This Call'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to end this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className='cancel-btn'
            onClick={handleClickCancel}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            className='confirm-btn'
            onClick={handleClickOk}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return endCallButton;
};

export default EndCallControl;
