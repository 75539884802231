import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const SizedDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 370px;
  }
  border-radius: 6px;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);
  .MuiDialogContent-root:first-child {
    padding-top: 2rem;
  }
`;

const ContentContainer = styled(DialogContent)`
  padding-top: 2rem;
`;

export { SizedDialog, ContentContainer };
