// This component is to provide analytical data regarding a Practice's CRM


import React from 'react';
import InvalidTarget from './InvalidTarget/InvalidTarget';
import MarkedAsSpam from './MarkedAsSpam/MarkedAsSpam';
import Performance from './Performance/Performance';
import Unsubcribed from './Unsubscribed/Unsubcribed';
import Unsuccessful from './Unsuccessful/Unsuccess';
import { setTabActiveLocalStorage, getTabActiveLocalStorage } from 'global/localStorage/tabActive';

const AnalyzeCommunication = (): JSX.Element => {
  // get data here? or maybe a step up and throw down to here?
  // function readFilter() {}
  const [activeComponent, setActiveComponent] = React.useState<number>(0);

  const handleActiveLink = (numberActive: number) => {
    setActiveComponent(numberActive);
    setTabActiveLocalStorage(null, numberActive, 0);
  }
  const renderContainer = () => {
    switch (activeComponent) {
      case 0: {
        return <Performance />;
      }
      case 1: {
        return <Unsuccessful />;
      }
      case 2: {
        return <Unsubcribed />;
      }
      case 3: {
        return <MarkedAsSpam />;
      }
      case 4: {
        return <InvalidTarget />;
      }
    }
  };

  React.useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    if (tabActive) {
      setActiveComponent(Number(tabActive.leftMenuContentActive));
    } else {
      setActiveComponent(0);
    }
  }, []);

  const componentValues: { innerText: string, switchValue: number }[] = [
    {
      innerText: "Performance",
      switchValue: 0
    },
    {
      innerText: "Unsuccessful Deliveries",
      switchValue: 1
    },
    {
      innerText: "Unsubscribed",
      switchValue: 2
    },
    {
      innerText: "Marked as Spam",
      switchValue: 3
    },
    {
      innerText: "Invalid Target",
      switchValue: 4
    }
  ];

  return (
    <div className="communication-analyze">
      <div className="communication-analyze__right-modal">
        <ul className="analyze-tab__lists">
          {
            componentValues.map((componentValue, index) => {
              return (
                <li
                  key={"communication-analyze-nav-" + index}
                  tabIndex={0}
                  className={"analyze-tab__item-list " + (activeComponent === componentValue.switchValue ? 'active' : '')}
                  onClick={() => {
                    handleActiveLink(componentValue.switchValue)
                  }}
                >
                  {componentValue.innerText}
                </li>
              )
            })
          }
        </ul>
      </div>

      {renderContainer()}
    </div>
  );
}

export default AnalyzeCommunication;