import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import AlertBanner from 'components/Legwork/AlertBanner';

const DEFAULT_AUTOHIDE = 5000;
export interface AlertSnackBarProps {
  severity: 'error' | 'warning' | 'success' | 'info';
  onClose?: () => void;
  children: React.ReactElement;
  autoHideDuration?: number;
}

const AlertSnackBar = (props: AlertSnackBarProps): React.ReactElement => {
  const { severity = 'info', children, onClose, autoHideDuration } = props;
  const [open, setOpen] = React.useState(true);
  let autoHideTime = autoHideDuration;
  const closeHandler = (): void => {
    setOpen(false);
    if (onClose) onClose();
  };
  if (!autoHideDuration && (severity === 'success' || severity === 'warning')) {
    autoHideTime = DEFAULT_AUTOHIDE;
  }
  return (
    <Snackbar open={open} onClose={closeHandler} autoHideDuration={autoHideTime}>
      <AlertBanner severity={severity} withClose onClose={closeHandler}>
        {children}
      </AlertBanner>
    </Snackbar>
  );
};

export default AlertSnackBar;
