import React, { useState, useEffect } from 'react';
import './TimerComponent.scss';
import CLOCK_ICON from '../../images/clock.svg'
import {Tooltip} from '@material-ui/core';
import {TimeSegments} from '../../types/TimeSegmentsModel';
import axios from "axios"
import { baseUrl } from 'telemed-video-call/environment';

let timeInterval: NodeJS.Timeout;

interface IProps {
  appointmentId: string | null;
  accessToken: string | null;
  isPatientJoined?: boolean;
  hasJoinedRoom?: boolean;
  isEndedCall?: boolean;
}

const TimerComponent: React.FC<IProps> = (props) => {
  let [countTime, setCountTime] = useState<number>(0);
  let [startTime, setStartTime] = useState<string>('');
  let [appointmentId] = useState(props.appointmentId);
  const [hasUpdateTime, setHasUpdateTime] = useState<boolean>(false);
  useEffect(() => {
    if (props.accessToken !== '' && props.appointmentId !== '') {
      getAppointmentDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accessToken]);

  useEffect(() => {
    if (props.isPatientJoined && props.hasJoinedRoom) {
      setHasUpdateTime(false);
      setStartTime(new Date().toISOString().split('.')[0]);
      // @ts-ignore
      timeInterval = setTimeInterval();
    } else if (
      (!props.hasJoinedRoom || !props.isPatientJoined) &&
      !hasUpdateTime
    ) {
      setHasUpdateTime(true);
      updateAppoitmentTime(new Date().toISOString().split('.')[0]);
      clearInterval(timeInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPatientJoined, props.hasJoinedRoom]);

  const setTimeInterval = () => {
    return setInterval(() => {
      setCountTime(countTime++);
    }, 1000);
  };

  const getAppointmentDetail = () => {
    axios
      .get(`${baseUrl}/appointments/V1/${appointmentId}`, {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      })
      .then(
        (response: {
          status: number;
          data: { durationMinutesActual: number };
        }) => {
          if (200 === response.status) {
            setCountTime(
              convertMinutesToSecond(response.data.durationMinutesActual)
            );
          }
        }
      );
  };

  /**
   * Service for update appointment
   * @param endTime
   */
  const updateAppoitmentTime = (endTime: string) => {
    let timeSegments: TimeSegments = {};
    timeSegments.startTimeUtc = startTime;
    timeSegments.endTimeUtc = endTime;
    if (timeSegments.startTimeUtc === '' || timeSegments.endTimeUtc === '') {
      return;
    }
    axios.post(
      `${baseUrl}/appointments/V1/${appointmentId}/telemedTimeSegments`,
      timeSegments,
      {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    );
  };

  const convertMinutesToSecond = (data: number) => {
    return data * 60;
  };

  /**
   * Convert duration of the call to time format HH:MM:SS
   * {value} is duration of the call
   */
  const convertHMS = (value: number) => {
    let hours = Math.floor(value / 3600); // get hours
    let minutes = Math.floor((value - hours * 3600) / 60); // get minutes
    let seconds = value - hours * 3600 - minutes * 60; //  get seconds
    let hh = hours.toString();
    let mm = minutes.toString();
    let ss = seconds.toString();
    // add 0 if value < 10
    if (hours < 10) {
      hh = '0' + hours;
    }
    if (minutes < 10) {
      mm = '0' + minutes;
    }
    if (seconds < 10) {
      ss = '0' + seconds;
    }
    return hh + ':' + mm + ':' + ss; // Return is HH : MM : SS
  };
  return (
    <Tooltip title='Call duration'>
      <div id='timer-container'>
        <div className='clock'>
          <img alt='clock' src={CLOCK_ICON} />
        </div>
        <div className='timer'>{convertHMS(countTime)}</div>
      </div>
    </Tooltip>
  );
};

export default TimerComponent;
