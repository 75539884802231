import React from 'react';
import Row, { IRow } from '../Row/Row';

export type RowCellType = React.ReactElement | ((hover: boolean) => React.ReactElement);
export interface RenderRowsProps {
  rows: IRow[];
  generateRow: (row: IRow) => RowCellType[];
  hoverable?: boolean;
  rowsClickable?: boolean;
  colSpan?: number[];
  rowClick?: (row: IRow) => void;
}

const RenderRows = (props: RenderRowsProps): React.ReactElement => {
  const {
    rows,
    generateRow,
    hoverable = false,
    rowsClickable = false,
    rowClick = (): void => undefined,
  } = props;

  return (
    <>
      {rows.map(
        (row: IRow): React.ReactElement => {
          return (
            <Row
              key={row.id}
              rowId={row.id}
              rowCells={generateRow(row)}
              hasmaxwidth={row.hasmaxwidth}
              hoverable={hoverable}
              isActive={row.isActive}
              clickable={rowsClickable}
              colSpan={row.colSpan}
              onClick={(): void => rowClick(row)}
            />
          );
        },
      )}
    </>
  );
};

export default RenderRows;
