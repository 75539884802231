import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FAIconButtonProps {
  icon: IconProp,
  handleClick: () => void,

  customClasses?: {
    button?: string,
    faIcon?: string
  },
  title?: string
}

const FAIconButton = (props: FAIconButtonProps) => {
  return (
    <button className={props.customClasses?.button ?? ""}
      onClick={props.handleClick}
      title={props.title ?? ""}
    >
      <FontAwesomeIcon icon={props.icon} />
    </button>
  )
}

export default FAIconButton;
