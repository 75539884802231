import * as t from 'io-ts';

// PhoneNumberEntryData
const RequiredPhoneNumberEntryData = t.type({
  number: t.string,
  isPrimary: t.boolean,
  kind: t.string, //TODO standardize ValidKinds types (already exist for frontend data)
});

const NullablePhoneNumberEntryData = t.partial({
  phoneNumberId: t.string,
  extension: t.string,
  isValidated: t.boolean,
  validatedOutput: t.string,
  isText: t.boolean,
  isCall: t.boolean,
  subscribe: t.boolean
});

export const FullPhoneNumberEntryData = t.intersection([
  RequiredPhoneNumberEntryData,
  NullablePhoneNumberEntryData,
]);

export type PhoneNumber = t.TypeOf<typeof FullPhoneNumberEntryData>;
//
