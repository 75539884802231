import styled from 'styled-components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const DownArrow = styled(ArrowDownwardIcon)`
  height: 14px;
  vertical-align: top;
  padding-top: 0px;
`;

const UpArrow = styled(ArrowUpwardIcon)`
  height: 14px;
  vertical-align: top;
  padding-top: 0px;
  &.hidden {
    visibility: hidden;
  }
`;

const SortContainer = styled.div`
  &:hover {
    .Muisrc/components/Legwork/typography-root {
      color: #12253f;
    }
    svg {
      visibility: visible;
      fill: #12253f;
    }
  }
`;

export { DownArrow, UpArrow, SortContainer };
