import styled from 'styled-components';
import { getThemeProp } from '../utils';

const BulletSpan = styled.span`
  padding-left: 0.58rem;
  color: ${getThemeProp('palette.text.secondary')};
  &.active {
    color: ${getThemeProp('palette.text.primary')};
  }
`;

const SubListContainer = styled.div`
  .MuiTypography-root {
    font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
    font-size: 13px;
    color: ${getThemeProp('palette.text.secondary')};
  }
  .MuiListItemIcon-root {
    min-width: 0;
    padding-right: 1.5rem;
  }
  &.active {
    background-color: ${getThemeProp('palette.background.default')};
    .MuiTypography-root {
      color: ${getThemeProp('palette.text.primary')};
      font-weight: 600;
    }
  }
`;

export { BulletSpan, SubListContainer };
