import React, { FC } from 'react';
import { IDetailBooking } from '../BookingRequestVM';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailEditor from 'components/Communications/EmailEditor';
import { NotifyBody } from 'global/requests/RequestTypes';
import { EmailContactWrapper as Wrapper } from './Wrapper';
import { Button } from '@material-ui/core';
interface IEmailContact {
  currentAppointmentSelected: IDetailBooking | null;
  isDirtyForm: boolean;
  toogleConfirmModal: (isOpen: boolean) => void;
  setIsDirtyForm: React.Dispatch<React.SetStateAction<boolean>>;
  setTypeSelect: React.Dispatch<
    React.SetStateAction<'accept' | 'deny' | 'email' | 'view' | null>
  >;
  // sendNotifyAppointmentBooking: (payload: NotifyBody) => void,
  sendNotifyAppointmentBooking: (
    actionKind: number,
    payload: NotifyBody
  ) => void;
}

const EmailContact: FC<IEmailContact> = ({
  currentAppointmentSelected,
  isDirtyForm,
  setTypeSelect,
  toogleConfirmModal,
  setIsDirtyForm,
  sendNotifyAppointmentBooking,
}) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const emailEditorSubmitRef = React.createRef<HTMLButtonElement>();

  return (
    <Wrapper className='react-modal--inside-overflow-container'>
      <div className='contacts--send-communication-modal-body-container'>
        <EmailEditor
          receiverUserIDs={[currentAppointmentSelected?.id ?? '']}
          sendNotifyAppointmentBooking={sendNotifyAppointmentBooking}
          emailRecipient={currentAppointmentSelected?.emailAddress ?? ''}
          setIsDirtyForm={setIsDirtyForm}
          setTypeSelect={setTypeSelect}
          checkNewEmail={() => {}}
          // closeModal={props.setCommunicationModalType}
          hideTemplateName={true}
          useCustomSubmit={{
            innerSubmitRefObject: emailEditorSubmitRef,
            setIsSubmitEnabled: setIsSubmitEnabled,
          }}
          screen='booking'
        />
      </div>

      <div className='contacts--communication-modal-bottom-row'>
        <Button
          variant='contained'
          className='contacts--communication-modal-bottom-button cancel'
          onClick={() =>
            isDirtyForm ? toogleConfirmModal(true) : setTypeSelect(null)
          }
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!isSubmitEnabled}
          className={`contacts--communication-modal-bottom-button confirm ${
            !isSubmitEnabled && 'disabled-button-submit'
          }`}
          onClick={() => emailEditorSubmitRef.current?.click()}
        >
          <FontAwesomeIcon icon={['fas', 'paper-plane']} /> Send Email
        </Button>
      </div>
    </Wrapper>
  );
};

export default EmailContact;
