import { convertMinutes } from 'utils/convertData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  createStyles,
  FormControlLabel,
  Switch,
  Theme,
  withStyles,
} from '@material-ui/core';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { AppointmentTypeSetting } from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import * as Yup from 'yup';
import { DurationType } from '../AppointmentType';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import HoursOfOperator from '../../Availability/components/HoursOfOperator';
import { ListDate } from '../../Availability/AvailabilityVM';
import listDateInitial from '../../Availability/constant/listDateInitial.json';
import { isEmpty } from 'lodash';
import { compareTimePicker } from '../../Availability/helpers/compareTimePicker';
import { AvailabilityWrapper } from '../../Availability/AvailabilityWrapper';
ReactModal.setAppElement('div#root');

type AppointmentTypeData = {
  appointmentType: string | undefined;
  appointmentDurationHour: number | undefined;
  appointmentDurationMinute: number | undefined;
  isBookingActive: boolean;
  isRoundRobinBookingOnly: boolean;
  isLimitAvailability: boolean;
  limitAvailabilityValue?: ListDate[];
};

type AddNewAppointmentTypeProps = {
  displayAppointmentTypeModal: boolean;
  isEdit: boolean;
  appointmentTypeData: AppointmentTypeSetting | undefined;
  listHours: DurationType[];
  listMinutes: DurationType[];
  setDisplayAppointmentTypeModal: (arg: boolean) => void;
  handleReloadAppointmentTypeList: () => void;
};

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#31CC97',
          borderColor: '#31CC97',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

const ErrorMessageTypes = {
  required: 'This field is required.',
  maxAppointmentDuration:
    'Maximum duration for an appointment cannot be greater than 1440 minutes.',
  minAppointmentDuration:
    'Minimum duration for an appointment cannot be less than 1 minute.',
  onlyNumberInput: 'Only numeric characters are allowed.',
};

const newAppointmentValidationSchema = Yup.object().shape({
  appointmentType: Yup.string().required(ErrorMessageTypes.required),
  // appointmentDuration: Yup.number().when('appointmentDurationHour'),
  // appointmentDurationHour: Yup.number()
  //   .required(ErrorMessageTypes.required)
  //   .min(1, ErrorMessageTypes.minAppointmentDuration)
  //   .max(1440, ErrorMessageTypes.maxAppointmentDuration),
});

const AddNewAppointmentType = (props: AddNewAppointmentTypeProps) => {
  const {
    displayAppointmentTypeModal,
    isEdit,
    appointmentTypeData,
    listHours,
    listMinutes,
    setDisplayAppointmentTypeModal,
    handleReloadAppointmentTypeList,
  } = props;

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);
  const [errorDuration, setErrorDuration] = useState<string>('');
  const [isHour, setIsHour] = useState<boolean>(false);
  const [isMinute, setIsMinute] = useState<boolean>(false);
  const [listDate, setListDate] = useState<ListDate[]>(
    // @ts-ignore
    appointmentTypeData?.limitAvailabilityValue ?? listDateInitial
  );
  const [isChange, setIsChange] = useState(false);

  const initialValues: AppointmentTypeData = !isEdit
    ? {
        appointmentType: '',
        appointmentDurationHour: listHours[0],
        appointmentDurationMinute: listMinutes[0],
        isBookingActive: false,
        isRoundRobinBookingOnly: false,
        isLimitAvailability: false,
      }
    : {
        appointmentType: appointmentTypeData?.appointmentType,
        appointmentDurationHour: convertMinutes(
          appointmentTypeData?.appointmentDuration || 0
        ).hour,
        appointmentDurationMinute: convertMinutes(
          appointmentTypeData?.appointmentDuration || 0
        ).minute,
        // @ts-ignore
        isBookingActive: appointmentTypeData?.isBookingActive ?? false,
        isRoundRobinBookingOnly:
          appointmentTypeData?.isRoundRobinBookingOnly ?? false,
        isLimitAvailability: appointmentTypeData?.isLimitAvailability ?? false,
      };

  const handleConfirmPopup = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    setDisplayAppointmentTypeModal(false);
  };

  const handleSubmitData = async (values: any) => {
    try {
      const submitData = {
        appointmentType: values.appointmentType,
        appointmentDuration:
          parseInt(
            !isEdit && !isHour
              ? values.appointmentDurationHour?.value
              : values.appointmentDurationHour
          ) *
            60 +
          parseInt(
            !isEdit && !isMinute
              ? values.appointmentDurationMinute?.value
              : values.appointmentDurationMinute
          ),
        acceptAppointment: values.acceptAppointment,
        isBookingActive: values.isBookingActive,
        isRoundRobinBookingOnly: values.isRoundRobinBookingOnly,
        isLimitAvailability: values.isLimitAvailability,
        limitAvailabilityValue: listDate,
      };
      const res = isEdit
        ? await configuredRequests.PATCH.updateAppointmentType(
            appointmentTypeData?.id,
            submitData
          )
        : await configuredRequests.POST.createAppointmentType(submitData);
      setDisplayAppointmentTypeModal(false);
      handleReloadAppointmentTypeList();
      if (res) {
        setDirtyForm(false);
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const validateDuration = (duration: number) => {
    if (duration <= 0) {
      setErrorDuration(ErrorMessageTypes.minAppointmentDuration);
    } else if (duration > 1440) {
      setErrorDuration(ErrorMessageTypes.maxAppointmentDuration);
    } else {
      setErrorDuration('');
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm || isChange);
    setDisplayAppointmentTypeModal(dirtyForm || isChange);
  };

  const onChangeDateTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTime)
            : compareTimePicker(selectedDate?.startTime, value);
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [name]: value,
                  error: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
        setIsChange(true);
      }
    },
    [listDate]
  );

  const onChangeLucnhTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const convertName =
        name === 'startTime' ? 'startTimeLunch' : 'endTimeLunch';
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTimeLunch)
            : compareTimePicker(selectedDate?.startTimeLunch, value);
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [convertName]: value,
                  errorLunch: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
        setIsChange(true);
      }
    },
    [listDate]
  );

  const onChangeToogle = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTime,
          selectedDate?.endTime
        );
        const isStartLunchAfterEndTimeLunch = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  active: !listDate.active,
                  error:
                    !listDate.active && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                  errorLunch:
                    !listDate.active && isStartLunchAfterEndTimeLunch
                      ? 'The end time must be greater than start time.'
                      : '',
                  takeLunch: !listDate.active ? true : false,
                }
              : { ...listDate }
          );
          return newListDate;
        });
        setIsChange(true);
      }
    },
    [listDate]
  );

  const onChangeToogleLunch = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        // @ts-ignore
        setListDate((preListDate) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  takeLunch: !listDate.takeLunch,
                  errorLunch:
                    !listDate.takeLunch && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                }
              : { ...listDate }
          );
          return newListDate;
        });
        setIsChange(true);
      }
    },
    [listDate]
  );

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-appointment-type'
      id='provider-modal'
      isOpen={displayAppointmentTypeModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={newAppointmentValidationSchema}
        validateOnMount={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const { handleSubmit, handleBlur, setFieldValue, values, dirty } =
            formikProps;
          if (dirty && displayAppointmentTypeModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content custom-scroll'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {isEdit
                    ? 'Edit Appointment Type'
                    : 'Add an Appointment Type'}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment form-add-appointment-type'>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row'>
                      <div className='form-input-settings'>
                        <span className='title-input-form'>
                          Appointment Type<b className='require'> *</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={255}
                            name='appointmentType'
                            required
                            // placeholder='Appointment Type'
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'appointmentType',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='appointmentType' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row'>
                      <span className='title-input-form'>
                        Appointment Duration<b className='require'> *</b>
                      </span>
                      <div className='flex'>
                        <div className='form-input-settings width-50'>
                          <Select
                            classNamePrefix='search-city'
                            //@ts-ignore
                            defaultValue={
                              isEdit
                                ? listHours.find(
                                    (item) =>
                                      Number(item.value) ===
                                      convertMinutes(
                                        appointmentTypeData?.appointmentDuration ||
                                          0
                                      ).hour
                                  )
                                : listHours[0]
                            }
                            name='appointmentDurationHour'
                            className='full-width option-city w-100 select-duration'
                            options={listHours}
                            onChange={(e: any) => {
                              setIsHour(true);
                              const duration =
                                parseInt(e?.value) * 60 +
                                parseInt(
                                  isMinute
                                    ? values.appointmentDurationMinute
                                    : // @ts-ignore
                                      values.appointmentDurationMinute?.value
                                );
                              validateDuration(duration);
                              setFieldValue(
                                'appointmentDurationHour',
                                e?.value
                              );
                            }}
                            isSearchable={false}
                            // maxMenuHeight={150}
                          />
                          <span className='duration-text'>Hours</span>
                        </div>

                        <div className='form-input-settings width-50'>
                          <Select
                            classNamePrefix='search-city'
                            //@ts-ignore
                            defaultValue={
                              isEdit
                                ? listMinutes.find(
                                    (item) =>
                                      Number(item.value) ===
                                      convertMinutes(
                                        appointmentTypeData?.appointmentDuration ||
                                          0
                                      ).minute
                                  )
                                : listMinutes[0]
                            }
                            name='appointmentDurationMinute'
                            className='full-width option-city w-100 select-duration'
                            placeholder={
                              isEdit &&
                              convertMinutes(
                                // @ts-ignore
                                appointmentTypeData?.appointmentDuration
                              ).minute.toString()
                            }
                            options={listMinutes}
                            onChange={(e: any) => {
                              setIsMinute(true);
                              const duration =
                                parseInt(
                                  isHour
                                    ? values.appointmentDurationHour
                                    : // @ts-ignore
                                      values.appointmentDurationHour?.value
                                ) *
                                  60 +
                                parseInt(e?.value);
                              validateDuration(duration);
                              setFieldValue(
                                'appointmentDurationMinute',
                                e?.value
                              );
                            }}
                            isSearchable={false}
                            // maxMenuHeight={150}
                          />
                          <span className='duration-text'>Minutes</span>
                        </div>
                      </div>

                      <p className='error-msg'>
                        {errorDuration}
                        {/* <ErrorMessage name='appointmentDuration' /> */}
                      </p>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <FormControlLabel
                        control={
                          <AntSwitch
                            name='isBookingActive'
                            checked={values.isBookingActive}
                            onChange={() => {
                              values.isBookingActive &&
                                setFieldValue('isRoundRobinBookingOnly', false);
                              setFieldValue(
                                'isBookingActive',
                                !values.isBookingActive
                              );
                              setDirtyForm(true);
                            }}
                          />
                        }
                        label='Accepting Appointments'
                        labelPlacement='start'
                        className='width-50'
                      />
                      <FormControlLabel
                        control={
                          <AntSwitch
                            name='isRoundRobinBookingOnly'
                            checked={values.isRoundRobinBookingOnly}
                            onChange={() => {
                              if (values.isBookingActive) {
                                setFieldValue(
                                  'isRoundRobinBookingOnly',
                                  !values.isRoundRobinBookingOnly
                                );
                                setDirtyForm(true);
                              }
                            }}
                          />
                        }
                        label='Round-robin Booking Only'
                        labelPlacement='start'
                        className={`width-50 ${
                          !values.isBookingActive && 'disabled'
                        }`}
                      />
                    </div>

                    <div className='setting__content--full-row limit-availability'>
                      <FormControlLabel
                        control={
                          <AntSwitch
                            name='isLimitAvailability'
                            checked={values.isLimitAvailability}
                            onChange={() => {
                              if (values.isLimitAvailability) {
                                setListDate(
                                  // @ts-ignore
                                  appointmentTypeData?.limitAvailabilityValue ??
                                    listDateInitial
                                );
                              }
                              setFieldValue(
                                'isLimitAvailability',
                                !values.isLimitAvailability
                              );
                              setDirtyForm(true);
                            }}
                          />
                        }
                        label='Limit Availability'
                        labelPlacement='start'
                        className='width-50'
                      />
                    </div>

                    {values.isLimitAvailability && (
                      <div
                        className={`setting__content--full-row availability-time`}
                      >
                        <AvailabilityWrapper className='hide-height'>
                          <p className='description-header'>
                            Set the hours that you allow online appointments to
                            be booked. You can edit availability for specific
                            providers in the providers settings.
                          </p>

                          <HoursOfOperator
                            listDate={listDate}
                            onChangeDateTime={onChangeDateTime}
                            onChangeLucnhTime={onChangeLucnhTime}
                            onChangeToogle={onChangeToogle}
                            onChangeToogleLunch={onChangeToogleLunch}
                            isLoadingHourOfOperation={false}
                          />
                        </AvailabilityWrapper>
                      </div>
                    )}
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer modal-appointment-type-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    !formikProps.isValid ||
                    !!errorDuration ||
                    (!isEdit && !isHour && !isMinute) ||
                    (isEdit && !dirty && !isChange)
                  }
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        isOpenModal={openPopup}
        title={isEdit ? 'Discard Charges?' : 'Confirmation!'}
        handleCloseModal={setOpenPopup}
        handleConfirmModal={handleConfirmPopup}
      />
    </ReactModal>
  );
};
export default AddNewAppointmentType;
