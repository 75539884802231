import React, { useEffect } from 'react';
import { useRouter } from 'hooks/useRouter';
interface IProps {
  setRouterJWT: any;
}

const MobileStoreConnect: React.FC<IProps> = () => {
  const router = useRouter();
  const iPad =
    window.navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  React.useEffect(() => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.pulse.vethero';
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i) || iPad) {
      window.location.href =
        'https://apps.apple.com/us/app/id1524402065';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('AuthToken');
    if (!token) {
      router.push('/login');
    }
  }, [router]);

  return <div></div>;
};

export default MobileStoreConnect;
