/* eslint-disable no-useless-concat */
// taken from: http://stackoverflow.com/questions/2646385/add-a-thousands-separator-to-a-total-with-javascript-or-jquery
exports.format = function (nStr, numDigits) {
  nStr = parseFloat(nStr || 0, 10).toFixed(numDigits || 0).toString();
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

exports.isInt = function (number) {
  return number % 1 === 0;
};

exports.isFloat = function (number) {
  return number % 1 !== 0;
};