import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyleGridContainer = styled(Grid)`
  a {
    font-size: 13px;
  }
`;

const AvatarContainer = styled.div`
  margin-bottom: 0.3rem;
`;

export { StyleGridContainer, AvatarContainer };
