/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TeleMedModalAppointmentInfo } from 'types/TeleMedT';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment from 'moment';
import { appointmentIdPattern } from '../../../utils/regex';
import {
  AttachmentData,
  NoteData,
  AppointmentNotesResponse,
  AppointmentAttachmentsResponse,
} from 'global/requests/ResponseTypes';
import TelemedJoinButton from './TeleMedJoinButton';
import PopupWindowWithHooks from '../../../hooks/usePopupWindow';
import { getSessionJWTString } from 'global/sessionStorage/SessionJWT';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import {
  convertLocalTimeToAnotherTimeZone,
  convertStringToCamelCase,
} from 'utils/convertData';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';
import PopupShowImageWithHook from 'hooks/usePopupShowImage';
import PopupShowVideoWithHook from 'hooks/usePopupVideo';
import loading_spinner from 'media/images/loading-spinner.svg';
import { getAppBaseUrl } from 'global/constants/url';

ReactModal.setAppElement('div#root');

interface RightModalProps {
  displayTelemedModal: boolean;
  setDisplayTelemedModal: (arg: boolean) => void;
  labelText?: string;
  appointmentInfo: AppointmentSummaryEntry;
  isAlreadyInCall?: boolean;
  setOpenModalError?(a: boolean): void;
  setReload?(a: boolean): void;
  settingsTimezoneData: string;
}

// stolen from the Account component which also renders initials TODO standardize this and reduce duplicate code
const getInitials = (userFirstName: string, userLastName?: string): string => {
  if (!userLastName?.length) {
    if (!userFirstName?.length) {
      return '';
    }
    return userFirstName[0];
  }
  return userFirstName[0] + userLastName[0];
};

const RightModal = (props: RightModalProps) => {
  const [appointment, setAppointment] = React.useState<AppointmentSummaryEntry>(
    props.appointmentInfo
  );
  const [isGetAppointment, setIsGetAppointment] = React.useState<boolean>(true);
  const [isGettingNotes, setIsGettingNotes] = React.useState<boolean>(true);
  const [isGettingImages, setIsGettingImages] = React.useState<boolean>(true);
  const [isGettingRecordings, setIsGettingRecordings] =
    React.useState<boolean>(true);
  const [appointmentNotes, setAppointmentNotes] =
    React.useState<AppointmentNotesResponse>();
  const [appointmentAttachment, setAppointmentAttachment] =
    React.useState<AppointmentAttachmentsResponse>();
  const [videoRecording, setVideoRecording] = useState([]);

  const [isSeeAllScreenShot, setIsSeeAllScreenShot] =
    React.useState<boolean>(false);
  const [showNewWindow, setShowNewWindow] = useState<boolean>(false);
  const [showWindowImage, setShowWindowImage] = useState<boolean>(false);
  const [showWindowVideo, setShowWindowVideo] = useState<boolean>(false);
  const [urlTelemedVideoCall, setUrlTelemedVideoCall] = useState<string>('');

  const {
    displayTelemedModal,
    setDisplayTelemedModal,
    appointmentInfo,
    labelText,
    settingsTimezoneData,
  } = props;

  const fullName = appointmentInfo
    ? appointmentInfo?.attendees?.users[0].name
    : '';
  const userFirstName = (fullName ?? '').split(' ')[0];
  const userLastName = (fullName ?? '').split(' ')[1];
  const userInitials = getInitials(userFirstName, userLastName);

  useEffect(() => {
    if (appointmentInfo && appointmentInfo?.appointmentId.length) {
      setIsSeeAllScreenShot(false);
      getTelemedAppointmentNotes(appointmentInfo?.appointmentId);
      getTelemedAppointmentAttachments(appointmentInfo?.appointmentId);
      getTelemedAppointmentRecording(appointmentInfo?.appointmentId);
    }
    return () => {
      setAppointmentAttachment({
        offset: 0,
        count: 0,
        totalAvailable: 0,
        attachments: [],
      });
      setVideoRecording([]);
      setAppointmentNotes({
        offset: 0,
        count: 0,
        totalAvailable: 0,
        notes: [],
      });
    };
  }, [appointmentInfo]);

  const getAppointment = async () => {
    try {
      const res = await configuredRequests.GET.getAppointmentById(
        props.appointmentInfo.appointmentId
      );
      if (res) {
        // @ts-ignore
        setAppointment(res?.data);
        setIsGetAppointment(false);
      }
    } catch (err) {
      setIsGetAppointment(false);
    }
  };

  useEffect(() => {
    if (!props.displayTelemedModal) {
      setShowNewWindow(false);
    } else {
      getAppointment();
    }
  }, [props.displayTelemedModal]);

  useEffect(() => {
    if (appointmentInfo) {
      const sessionJWTString = getSessionJWTString() ?? undefined;
      const sessionUserData = getSessionUserData();
      setUrlTelemedVideoCall(
        `${getAppBaseUrl()}/Telemedicine/videocall?appointmentId=${
          appointmentInfo.appointmentId
        }&teleMedKey=${
          appointmentInfo.teleMedKey
        }&accessToken=${sessionJWTString}&doctorName=${
          sessionUserData?.name?.givenName
        } ${sessionUserData?.name?.lastName}`
      );
    }
  }, [appointmentInfo]);

  const getTelemedAppointmentNotes = async (appointmentID: string) => {
    try {
      const res = await configuredRequests.GET.currentTenantAppointmentNotes(
        appointmentID
      );
      if (res) {
        setAppointmentNotes(res);
        setIsGettingNotes(false);
      }
    } catch (err) {
      setIsGettingNotes(false);
    }
  };

  const getTelemedAppointmentRecording = async (appointmentID: string) => {
    try {
      const videoRecordings = await configuredRequests.GET.getRecording(
        appointmentID
      );
      if (videoRecordings) {
        setVideoRecording(videoRecordings || []);
        setIsGettingRecordings(false);
      }
    } catch (err) {
      setIsGettingRecordings(false);
    }
  };

  const getTelemedAppointmentAttachments = async (appointmentID: string) => {
    try {
      const screenshots =
        await configuredRequests.GET.currentTenantAppointmentAttachments(
          appointmentID
        );
      if (screenshots) {
        setAppointmentAttachment(screenshots);
        setIsGettingImages(false);
      }
    } catch (err) {
      setIsGettingImages(false);
    }
  };

  const getRelativeAppointmentTime = (
    appointmentInfo: TeleMedModalAppointmentInfo
  ) => {
    const dateTime = appointmentInfo ? appointmentInfo.dateTime : '';
    const appointmentUpdatedTime = appointmentInfo
      ? appointmentInfo.appointmentUpdatedTime
      : '';
    const status = appointmentInfo ? appointmentInfo.status : '';
    if (!appointmentInfo || !dateTime) {
      return '';
    }
    const formattedRelativeAppointmentTime = moment(new Date(dateTime)).format(
      'MM/DD/YY'
    );
    // const formattedAppointmentTime = new Date(dateTime).toLocaleString("en-US", { timeZone: 'Asia/Bangkok' });

    // const formattedTime24h = (moment(formattedAppointmentTime).format('HH:mm A'));
    const formattedTime24h = moment(dateTime).format('hh:mm A');
    const formattedAppointmentCompleteDate = moment(
      appointmentUpdatedTime
    ).format('MM/DD/YY');
    const formattedAppointmentCompleteTime = moment(
      appointmentUpdatedTime
    ).format('hh:mm A');

    if (status && status.toLocaleLowerCase() === 'complete') {
      return `Appointment completed on ${
        appointmentUpdatedTime
          ? formattedAppointmentCompleteDate
          : formattedRelativeAppointmentTime
      }  at ${
        appointmentUpdatedTime
          ? formattedAppointmentCompleteTime
          : formattedTime24h
      }`;
    }
    if (moment().isSame(moment(dateTime), 'date')) {
      return 'Telemedicine appointment today at ' + formattedTime24h;
    }

    return (
      'Telemedicine appointment on ' +
      formattedRelativeAppointmentTime +
      ' at ' +
      formattedTime24h
    );
  };

  const formatAppointmentId = (appointmentID: string) => {
    const result = appointmentID.match(appointmentIdPattern);
    if (result) {
      return result[1] + '-' + result[2] + '-' + result[3];
    }
    return null;
  };

  const sortListSSbyDate = (data: AttachmentData[]) => {
    return data.sort((a: AttachmentData, b: AttachmentData) =>
      Date.parse(a.creationDate) > Date.parse(b.creationDate)
        ? 1
        : Date.parse(b.creationDate) > Date.parse(a.creationDate)
        ? -1
        : 0
    );
  };

  const sortListVideoByDate = (data: any) => {
    return data.sort((a: any, b: any) =>
      a.creationDate > b.creationDate
        ? 1
        : b.creationDate > a.creationDate
        ? -1
        : 0
    );
  };

  const renderRecordings = () => {
    return (
      <>
        {videoRecording.length > 0 ? (
          <div className='section-container-with-content picture'>
            <div>
              <div className='attachment-detail-container'>
                {sortListVideoByDate(
                  videoRecording.map((video: any, index: number) => {
                    const patientName = appointmentInfo.patients.pets[0]?.name
                      ? convertStringToCamelCase(
                          appointmentInfo.patients.pets[0]?.name
                        )
                      : convertStringToCamelCase(
                          appointmentInfo.attendees.users[0].name || ''
                        );
                    const timeStamp = moment(video.creationDate).format(
                      'YYYY-MM-DD[T]HH:mm:ss'
                    );
                    return (
                      <p
                        className='video-link'
                        key={index}
                        onClick={() => {
                          clickPlayVideo(index);
                        }}
                      >
                        {patientName + '_' + timeStamp}
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                      </p>
                    );
                  })
                ).filter((e: any) => e)}
              </div>
            </div>
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={['fas', 'film-alt']}
              className='section-icon'
            />
            <p className='section-container-without-content'>
              Any recordings you take during the appointment will be stored
              here.
            </p>
          </>
        )}
      </>
    );
  };

  const onCloseNewWindow = () => {
    setShowNewWindow(false);
  };

  const onOpenNewWindow = (url: string) => {
    // setNewWindowUrl(url);
    setShowNewWindow(true);
  };

  const sortListNotebyDate = (data: NoteData[]) => {
    return data.sort((a: NoteData, b: NoteData) =>
      a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
    );
  };
  const renderNotes = () => {
    if (appointmentNotes && appointmentNotes.count) {
      return (
        <div className='section-container-with-content'>
          {sortListNotebyDate(appointmentNotes.notes).map(
            (note: NoteData, i: number) => {
              return (
                <div
                  key={'note-detail-container' + i}
                  className='note-detail-container'
                >
                  <div className='note-title'>{note.name}</div>
                  <div className='note-content'>{note.content}</div>
                </div>
              );
            }
          )}
        </div>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon
            icon={['fas', 'notes-medical']}
            className='section-icon'
          />
          <p className='section-container-without-content'>
            Any notes you take during the appointment will be stored here.
          </p>
        </>
      );
    }
  };

  const renderScreenshots = () => {
    if (
      appointmentAttachment?.attachments &&
      appointmentAttachment.attachments.length
    ) {
      return (
        <div className='section-container-with-content picture'>
          <div>
            <div className='attachment-detail-container'>
              {sortListSSbyDate(appointmentAttachment?.attachments)
                .map((attachment: AttachmentData, index: number) => {
                  if (index >= appointmentAttachment.attachments.length - 2) {
                    return (
                      <div className='box-image'>
                        <img
                          key={'img-screen-shot-' + index}
                          onClick={() => {
                            clickViewImage(index);
                          }}
                          src={attachment.url}
                          alt='screen-shot'
                        />
                      </div>
                    );
                  }
                  return null;
                })
                .filter((e) => e)}
            </div>
          </div>
          <div
            className='attachment-icon-section'
            onClick={clickToSeeAllScreenShot}
          >
            <FontAwesomeIcon
              icon={['fas', 'th']}
              className='icon-see-all-image'
            />
            <p>
              {' '}
              See all {appointmentAttachment.attachments.length} screenshots
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon
            icon={['fas', 'image-polaroid']}
            className='section-icon'
          />
          <p className='section-container-without-content'>
            Any screenshots you take during the appointment will be stored here.
          </p>
        </>
      );
    }
  };
  const clickViewImage = (index: number) => {
    setShowWindowImage(!showWindowImage);
    setDisplayTelemedModal(true);
    // setDisplayTelemedModal(!displayTelemedModal);
    // setIsOpenImage(!isOpenImage);
    // setImageIndex(index);
    localStorage.setItem('indexOfOpenedImg', index.toString());
  };

  const clickPlayVideo = (index: number) => {
    setShowWindowVideo(!showWindowVideo);
    setDisplayTelemedModal(true);
    localStorage.setItem('indexOfOpenedVideo', index.toString());
  };

  const onCloseWindowShowImage = () => {
    setDisplayTelemedModal(true);
    setShowWindowImage(false);
  };

  const onCloseWindowShowVideo = () => {
    setDisplayTelemedModal(true);
    setShowWindowVideo(false);
  };

  const clickToSeeAllScreenShot = () => {
    setIsSeeAllScreenShot(!isSeeAllScreenShot);
  };

  const renderScreenShotView = () => {
    return (
      <div className='screen-shot-view-container'>
        <div className='react-modal-close-icon-container'>
          <div className='back-detail' onClick={clickToSeeAllScreenShot}>
            <FontAwesomeIcon
              icon={['fas', 'chevron-left']}
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                props.setDisplayTelemedModal(false);
              }}
              style={{ marginRight: '15px', height: 'unset', width: '0.625em' }}
            />
            Back to Appointment Details
          </div>
          <FontAwesomeIcon
            icon={['far', 'times']}
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              props.setDisplayTelemedModal(false);
            }}
            style={{ marginRight: '15px' }}
          />
        </div>
        <p className='screenshot-title'>Screenshots</p>
        <div className='screenshot-grid'>
          {appointmentAttachment?.attachments.map(
            (attachment: AttachmentData, index: number) => {
              return (
                <div key={index} className='screenshot-image'>
                  <img
                    onClick={() => clickViewImage(index)}
                    src={attachment.url}
                    alt='screen-shot'
                  ></img>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  };

  const currentDateFollowTimezoneSetting =
    convertLocalTimeToAnotherTimeZone(settingsTimezoneData).format(
      'YYYY-MM-DD'
    );
  const appointmentDateTime = moment(appointmentInfo?.dateTime).format(
    'YYYY-MM-DD'
  );
  const isHideButtonStartAppointment =
    appointment?.status === 'complete' &&
    Date.parse(appointmentDateTime) <
      Date.parse(currentDateFollowTimezoneSetting);

  const renderAppointmentDetail = () => {
    if (isSeeAllScreenShot) {
      return renderScreenShotView();
    }
    return (
      <>
        <div className={'react-modal--section-container contact-info'}>
          <button
            className='react-modal-close-icon-container'
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              props.setDisplayTelemedModal(false);
            }}
          >
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>

          <div className='appointment-info-container'>
            {isGetAppointment ? (
              <img
                className='loading-appointment-info'
                src={loading_spinner}
                alt={'Loading'}
              />
            ) : (
              <div className='appointment-info-inner-container'>
                <div className='user-initials'>
                  <div className='user-initial--text'>{userInitials}</div>
                </div>

                <section className='appointment-info'>
                  <div className='appointment-info-entry contact-name'>
                    {fullName ?? 'Contact name'}
                  </div>

                  <div className='appointment-info-entry appointment-time'>
                    {getRelativeAppointmentTime(appointment as any)}
                  </div>

                  <div className='appointment-info-entry appointment-id'>
                    Appointment ID:&nbsp;
                    <div className='appointment-id-number'>
                      {formatAppointmentId(appointmentInfo?.teleMedKey ?? '')}
                    </div>
                  </div>
                </section>

                <section className='start-appointment-container'>
                  {isHideButtonStartAppointment ? (
                    <div
                      key={
                        'telemedicine-inprogress-appointment-' +
                        appointmentInfo.appointmentId
                      }
                    />
                  ) : (
                    <TelemedJoinButton
                      key={'telemedicine-start-appointment'}
                      isAlreadyInCall={props.isAlreadyInCall}
                      teleMedKey={
                        appointmentInfo && appointmentInfo.teleMedKey
                          ? appointmentInfo.teleMedKey
                          : ''
                      }
                      appointmentInformation={appointmentInfo}
                      externalId={
                        appointmentInfo && appointmentInfo.externalId
                          ? appointmentInfo.externalId
                          : ''
                      }
                      dateTime={
                        appointmentInfo && appointmentInfo.dateTime
                          ? appointmentInfo.dateTime
                          : ''
                      }
                      isFromRightModal={true}
                      onOpenNewWindow={onOpenNewWindow}
                      setOpenModalError={props.setOpenModalError}
                      setReload={props.setReload}
                      settingsTimezoneData={settingsTimezoneData}
                    />
                  )}
                </section>

                {showNewWindow && (
                  <PopupWindowWithHooks
                    url={urlTelemedVideoCall}
                    closePopupWindowWithHooks={onCloseNewWindow}
                    appointmentInfo={appointmentInfo}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className={'react-modal--section-container notes'}>
          <p className='section-title'> Notes </p>
          {isGettingNotes ? (
            <img
              className='loading-appointment-info'
              src={loading_spinner}
              alt={'Loading'}
            />
          ) : (
            renderNotes()
          )}
        </div>

        <div className={'react-modal--section-container screenshots'}>
          <p className='section-title'> Screenshots </p>
          {isGettingImages ? (
            <img
              className='loading-appointment-info'
              src={loading_spinner}
              alt={'Loading'}
            />
          ) : (
            renderScreenshots()
          )}
        </div>

        <div className={'react-modal--section-container recordings'}>
          <p className='section-title'> Recordings </p>
          {isGettingRecordings ? (
            <img
              className='loading-appointment-info'
              src={loading_spinner}
              alt={'Loading'}
            />
          ) : (
            renderRecordings()
          )}
        </div>
      </>
    );
  };

  const viewDetail = () => {
    return (
      <>
        <ReactModal
          isOpen={displayTelemedModal}
          className='react-modal'
          onRequestClose={() => {
            setDisplayTelemedModal(false);
            setIsGetAppointment(true);
            setIsGettingNotes(true);
            setIsGettingRecordings(true);
            setIsGettingImages(true);
          }}
          contentLabel={labelText ? labelText : ''}
        >
          <div className='react-modal--inside-overflow-container'>
            {renderAppointmentDetail()}
          </div>
        </ReactModal>
        {showWindowImage && (
          <PopupShowImageWithHook
            url={`${getAppBaseUrl()}/List-image/${
              appointmentInfo?.appointmentId
            }`}
            closeWindowShowImage={onCloseWindowShowImage}
          />
        )}
        {showWindowVideo && (
          <PopupShowVideoWithHook
            url={`${getAppBaseUrl()}/video/${appointmentInfo?.appointmentId}`}
            closeWindowShowVideo={onCloseWindowShowVideo}
          />
        )}
      </>
    );
  };
  return viewDetail();
};

export default RightModal;
