import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      popover: {
        pointerEvents: 'none',
      },
      paper: {
        padding: theme.spacing(1),
        backgroundColor: 'grey',
        color: 'white',
        fontFamily: theme.typography.fontFamily,
        fontSize: '15px',
      },
    }),
);

const DisplayComponentsContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 5px;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
`;
export { Container, useStyles, DisplayComponentsContainer };
