import { ContactMessages } from './ResponseTypes';
import { UserDataResponse } from './ResponseTypes/UserAdministration';

export const convertToTenantUserInfoFromSingleUserData = (
  singleUserData: UserDataResponse
): ContactMessages => {
  return {
    id: singleUserData.userId,
    // enabled: singleUserData.enabled,
    name: singleUserData.name?.givenName + ' ' + singleUserData.name?.lastName,
    lastName: singleUserData.name?.lastName,
    phoneNumbers: singleUserData?.phoneNumbers ?? [],
    // roles: singleUserData.roles || [],
  };
};