import React from 'react';
import Grid from '@material-ui/core/Grid';

import { StyledButton } from './styled-components';

export interface SecondaryButtonProps {
  title: string;
  icon?: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  'data-testid'?: string;
  style?: object;
  className?: string;
}

const SecondaryButton = (props: SecondaryButtonProps): React.ReactElement => {
  const {
    title,
    onClick,
    disabled = false,
    icon,
    'data-testid': dataTestId,
    style = {},
    className = '',
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      className={className}
      style={style}
    >
      <Grid container justify='center'>
        {icon && (
          <Grid item data-testid='display-icon'>
            {icon}
          </Grid>
        )}
        <Grid item>{title}</Grid>
      </Grid>
    </StyledButton>
  );
};

export default SecondaryButton;
