import React, { useState, useCallback, useEffect } from 'react';
import { ContentContactAppointment as Wrapper } from './Wrapper';
import InforAppointMent from './InforAppointMent';
import { Button } from '@material-ui/core';
import { IDetailBooking } from '../BookingRequestVM';
import {
  MessagePayloadKindsEnum,
  NotifyBody,
  SingleUserSendEmailPayload,
} from 'global/requests/RequestTypes';
import { get } from 'lodash';
import MessageEditor from './MessageEditor';

interface IContentContactAppointment {
  type: 'accept' | 'deny';
  currentAppointmentSelected: IDetailBooking | null;
  isDirtyForm: boolean;
  toogleConfirmModal: (isOpen: boolean) => void;
  setIsDirtyForm: React.Dispatch<React.SetStateAction<boolean>>;
  setTypeSelect: React.Dispatch<
    React.SetStateAction<'accept' | 'deny' | 'email' | 'view' | null>
  >;
  onNotifyContact: (actionKind: number) => void;
  sendNotifyAppointmentBooking: (
    actionKind: number,
    payload: NotifyBody
  ) => void;
}

const initPayload: SingleUserSendEmailPayload = {
  content: '',
  kind: MessagePayloadKindsEnum.email,
  subject: '',
  templateId: '',
  scheduleDate: '',
};

const ContentContactAppointment: React.FC<IContentContactAppointment> = ({
  type,
  currentAppointmentSelected,
  isDirtyForm,
  setTypeSelect,
  onNotifyContact,
  sendNotifyAppointmentBooking,
  toogleConfirmModal,
  setIsDirtyForm,
}) => {
  const [payloadToSendMess, setPayloadToSendMess] =
    React.useState<SingleUserSendEmailPayload>(initPayload);

  const [errorEmailSubject, setErrorEmailSubject] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchAPI = async () => {
    await sendNotifyAppointmentBooking(type === 'accept' ? 1 : 2, {
      recipient:
        payloadToSendMess.kind === 'email'
          ? get(currentAppointmentSelected, 'emailAddress', '')
          : get(currentAppointmentSelected, 'phoneNumber', ''),
      ...payloadToSendMess,
    });
    setTypeSelect(null);
    setIsDirtyForm(false);
  };

  const notifyContact = useCallback(async () => {
    let message = payloadToSendMess.content;
    let subject = payloadToSendMess.subject;
    if (payloadToSendMess.kind === 'email') {
      const firstIndex = message.indexOf('<body>');
      const lastIndex = message.lastIndexOf('</body>');
      message = message.slice(firstIndex + 7, lastIndex);
    }

    if (payloadToSendMess.kind === 'email') {
      if (!!message && !!subject) {
        fetchAPI();
      } else {
        !message && setErrorMessage('This field is required.');
        !subject && setErrorEmailSubject(true);
      }
    } else {
      if (!message) {
        setErrorMessage('This field is required.');
      } else {
        fetchAPI();
      }
    }
  }, [
    payloadToSendMess.content,
    payloadToSendMess.subject,
    payloadToSendMess.kind,
    type,
    currentAppointmentSelected,
    sendNotifyAppointmentBooking,
    setTypeSelect,
    setIsDirtyForm,
  ]);

  useEffect(() => {
    setIsDirtyForm(!!payloadToSendMess.content || !!payloadToSendMess.subject);
  }, [
    payloadToSendMess.content,
    payloadToSendMess.subject,
    isDirtyForm,
    setIsDirtyForm,
  ]);

  return (
    <Wrapper>
      <div className='contacts-appointment-body'>
        <div className='row-contact-provider'>
          <InforAppointMent
            title='Contact'
            content={
              currentAppointmentSelected?.contactName ||
              `No contact's name provided.`
            }
          />
          <InforAppointMent
            title='Provider'
            content={currentAppointmentSelected?.providerName || ''}
          />
        </div>
        <div className='row-pet-provider'>
          <InforAppointMent
            title='Pet'
            content={
              currentAppointmentSelected?.petName || `No pet's name provided.`
            }
          />
        </div>
        <div className='row-appointment-provider'>
          <InforAppointMent
            title='Appointment'
            content={currentAppointmentSelected?.appointment ?? ''}
          />
        </div>
        <MessageEditor
          // receiverUserIDs={[currentAppointmentSelected?.id ?? '']}
          // sendNotifyAppointmentBooking={sendNotifyAppointmentBooking}
          // emailRecipient={currentAppointmentSelected?.emailAddress ?? ''}
          // setIsDirtyForm={setIsDirtyForm}
          // setTypeSelect={setTypeSelect}
          // checkNewEmail={() => { }}
          payloadToSendMess={payloadToSendMess}
          setPayloadToSendMess={setPayloadToSendMess}
          errorMessage={errorMessage}
          errorEmailSubject={errorEmailSubject}
          setErrorEmailSubject={setErrorEmailSubject}
          setErrorMessage={setErrorMessage}
          // closeModal={props.setCommunicationModalType}
          screen='booking'
        />
      </div>
      <div className='contacts-appointment-footer'>
        <Button
          onClick={() =>
            payloadToSendMess.subject || payloadToSendMess.content
              ? toogleConfirmModal(true)
              : setTypeSelect(null)
          }
          variant='contained'
          color='default'
          className='cancel-button'
        >
          Cancel
        </Button>
        <div className='action-button-group'>
          <Button
            variant='contained'
            color='primary'
            className={`save-button ${type === 'deny' && 'deny-button'} ${
              type === 'accept' && 'btn-accept-appointment'
            }`}
            onClick={() => {
              type === 'deny' && onNotifyContact(2);
              setTypeSelect(null);
              setIsDirtyForm(false);
            }}
          >
            Don't Notify Contact
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={`save-button ${type === 'deny' && 'deny-button'}`}
            onClick={notifyContact}
          >
            Notify Contact
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContentContactAppointment;
