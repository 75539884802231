import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledDiv = styled.div`
  .tox.tox-tinymce {
    width: 100% !important;
    border-radius: 6px;

    &.tox-fullscreen {
      height: calc(100vh - 75px) !important;
    }
  }
  span.tox-icon {
    path {
      fill: ${getThemeProp('palette.text.secondary', 'grey')};
    }
  }
  span.tox-tbtn__select-label {
    color: ${getThemeProp('palette.text.secondary', 'grey')};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
`;

export { StyledDiv, StyledInputLabel };
