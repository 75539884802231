import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { SettingOption } from './UserNotification';

type Props = {
  settings: Record<string, SettingOption>;
  onSettingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckBoxNotification = ({ settings, onSettingChange }: Props) => {
  return (
    <>
      {Object.keys(settings).map((setting) => (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name={setting}
                color='primary'
                checked={settings[setting].value}
                onChange={onSettingChange}
              />
            }
            className='checkbox--small'
            label={settings[setting].label}
          />
        </div>
      ))}
    </>
  );
};

export default CheckBoxNotification;
