import React from 'react';
import moment from 'moment';

const FORMAT = 'L';

interface DateTextProps {
  dateTime: string | number | Date | undefined;
  locale: string;
  incomingFormat?: string;
}

const DateText = (props: DateTextProps): React.ReactElement | null => {
  const { dateTime, locale, incomingFormat } = props;
  if (!dateTime) {
    return null;
  }
  moment.locale(locale);

  const displayedTime = moment(dateTime, incomingFormat).format(FORMAT);
  return <span>{displayedTime}</span>;
};

DateText.defaultProps = {
  locale: 'en',
};

export default DateText;
