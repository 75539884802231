import moment from 'moment';

export const timeGeneratorPattern = {
    next_7_days: {
      startDate: moment().toISOString(),
      endDate: moment()
        .add(7, 'days')
        .toISOString(),
    },
    next_14_days: {
      startDate: moment().toISOString(),
      endDate: moment()
        .add(14, 'days')
        .toISOString(),
    },
    next_30_days: {
      startDate: moment().toISOString(),
      endDate: moment()
        .add(30, 'days')
        .toISOString(),
    },
    past_7_days: {
      startDate: moment()
        .subtract(7, 'days')
        .toISOString(),
      endDate: moment().toISOString(),
    },
    past_14_days: {
      startDate: moment()
        .subtract(14, 'days')
        .toISOString(),
      endDate: moment().toISOString(),
    },
    past_30_days: {
      startDate: moment()
        .subtract(30, 'days')
        .toISOString(),
      endDate: moment().toISOString(),
    },
    this_week: {
      startDate: moment(moment().startOf('week'))
        .startOf('day')
        .toISOString(),
      endDate: moment(moment().endOf('week'))
        .startOf('day')
        .toISOString(),
    },
    this_month_to_date: {
      startDate: moment()
        .startOf('month')
        .toISOString(),
      endDate: moment().toISOString(),
    },
    this_year_to_date: {
      startDate: moment()
        .startOf('year')
        .toISOString(),
      endDate: moment().toISOString(),
    },
    next_week: {
      startDate: moment()
        .add(1, 'week')
        .startOf('week')
        .toISOString(),
      endDate: moment()
        .add(1, 'week')
        .endOf('week')
        .toISOString(),
    },
    next_month: {
      startDate: moment()
        .add(1, 'month')
        .startOf('month')
        .toISOString(),
      endDate: moment()
        .add(1, 'month')
        .endOf('month')
        .toISOString(),
    },
    last_week: {
      startDate: moment()
        .subtract(1, 'week')
        .startOf('week')
        .toISOString(),
      endDate: moment()
        .subtract(1, 'week')
        .endOf('week')
        .toISOString(),
    },
    last_month: {
      startDate: moment()
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(),
      endDate: moment()
        .subtract(1, 'month')
        .endOf('month')
        .toISOString(),
    },
    last_year: {
      startDate: moment()
        .subtract(1, 'year')
        .startOf('year')
        .toISOString(),
      endDate: moment()
        .subtract(1, 'year')
        .endOf('year')
        .toISOString(),
    },
    reactivation: {
      startDate: moment().toISOString(),
      endDate: moment()
        .add(7, 'days')
        .toISOString(),
    },
    all: undefined,
    _: {
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },
  };
  