import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { getThemeProp } from '../../utils';

const StyledCard = styled(Card)`
  border-radius: 6px !important;
  box-shadow: 0 3px 3px 0 rgba(18, 38, 63, 0.03) !important;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid ${getThemeProp('palette.divider', 'lightgray')};
` as typeof Card;

export default StyledCard;
