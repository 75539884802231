import styled from 'styled-components';

const ColoredCircle = styled.div`
  border-radius: 50%;
  background-color: ${(props: { color: string }): string => props.color};
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.3rem;
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export { StatusContainer, ColoredCircle };
