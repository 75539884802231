import React from 'react';

import Login from 'components/Login/Login';
import { LoginProps } from 'components/Login/Login';

const LoginContainer = (props: LoginProps): JSX.Element => {
  return (
    <>
      <Login {...props} />
    </>
  )
}

export default LoginContainer;
