const styles = {
  timeSlotSelectorContainer: {
    display: 'flex',
    border: '1px solid #ccc',
    padding: '20px',
    minHeight: '160px',
    backgroundColor: 'white',
    borderRadius: '6px'
  },
  inputContainer: {
    flex: '2 1 auto',
    marginBottom: '15px',
  },
  timeSlotContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
    minHeight: '53px',
  },
  readOnly: {
    cursor: 'not-allowed',
    backgroundColor: '#d4d4d4',
    pointerEvents: 'none',
  }
}

export default styles;
