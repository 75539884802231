import { setSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { CancelToken } from 'axios';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import {
  SummaryItemTypes,
  TemplateCategoryKinds,
  TemplateKinds,
  UserKindTypes,
  UserStatusTypes,
} from './api-route-types';
import { apiRoutes } from './api-routes';
import {
  dataMockupBrandColor,
  dataMockupNotification,
  generalSettings,
} from './dataUserSettingResponseError';
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
} from './GenericRequests';
import * as RequestTypes from './RequestTypes';
import {
  Provider,
  SendEmailSupportSubmissionPayload,
  SoftDeleteUserRequestType,
} from './RequestTypes';
import * as ResponseTypeguards from './ResponseTypeguards';
import * as ResponseTypes from './ResponseTypes';
import { Group } from './ResponseTypes';
import {
  AppointmentInfoResponse,
  AppointmentMultipleInfoResponse,
  AppointmentSummaryEntry,
} from './ResponseTypes/Appointments';
import { AppointmentTypeSettingRequestType } from './ResponseTypes/AppointmentTypeSetting';
import { LoginResponse } from './ResponseTypes/Authentication';
import { EmitterRequestType } from './ResponseTypes/Emitter';
import {
  AddFormAutoFillPayload,
  ApproveSubmissionPayloadType,
  CloneFormBuilderRequestType,
  EditFormBuilderRequired,
  MarkAsReadSubmissionPayload,
} from './ResponseTypes/FormBuilder';
import { GroupDataResponse, GroupInfoResponse } from './ResponseTypes/Groups';
import { PaySettingType } from './ResponseTypes/PaySetting';
import {
  PetInfoResponse,
  PetSummaryCollectionResponse,
} from './ResponseTypes/Pets';
import { SyncerSettingGeneralResponseType } from './ResponseTypes/SyncerSettingGeneral';
import {
  TenantInfoResponse,
  TenantUserDataResponse,
  TenantFilteredDataResponse,
} from './ResponseTypes/Tenants';
import { UserDataResponse } from './ResponseTypes/UserAdministration';
import {
  HoursOfOperationDataType,
  ListDateType,
} from './ResponseTypes/GetHoursOfOperation';
import { OfficeUnavailabiltyType } from './ResponseTypes/GetOfficeUnavailabilty';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import { ProviderType, ProviderModal } from './ResponseTypes/GetProvider';
import { ReminderInfoResponse } from './ResponseTypes/Reminders';
import { TwilioSettingType } from './ResponseTypes/TwilioSetting';
import { ProductResponseType } from './ResponseTypes/Product';
import { Medical } from './ResponseTypes/Medical';
import { AddEstimatesRequest } from './ResponseTypes/Estimates';
// import { getSessionJWTString } from "global/sessionStorage/SessionJWT";

const createAxiosResponseError = () => new Error('Axios request returned void');
const createTypeguardError = () =>
  new Error('Typeguard could not validate response');
const createInvalidRouteParameterError = () =>
  new Error('Route could not be generate because the parameters were invalid');
// const sessionJWTString = getSessionJWTString() ?? undefined;

// Store configured request functions here if the data is used in more than one place
// Allows for cleaner code and reduces copy/paste
// We want to encapsulate typeguarding and return either the valid data or nothing
export const configuredRequests = {
  GET: {
    getPostalPrice: async () => {
      const res = await axiosGet(apiRoutes.GET.postalPrice);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return res.data as number;
    },

    getSmsPrice: async () => {
      const res = await axiosGet(apiRoutes.GET.smsPrice);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return res.data;
    },

    getListMangoPhone: async () => {
      const res = await axiosGet(apiRoutes.GET.mangoSetting);

      if (!res) {
        throw createAxiosResponseError();
      }

      const validResponse = ResponseTypeguards.validateMangoSetting(res);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },

    getTimeNow: async () => {
      const response = await axiosGet(apiRoutes.GET.getPracticeTimeNow);

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response.data;
    },
    getSettingDurationTimes: async () => {
      const response = await axiosGet(apiRoutes.GET.getSettingDurationTimes);
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    getDataTwilioSetting: async () => {
      const res = await axiosGet(apiRoutes.GET.twilioSetting);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTwilioSetting(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    approveAppointmentWithSelectedContact: async (
      appointmentId: string,
      contactId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.approveAppointmentWithSelectedContact(
          appointmentId,
          contactId
        )
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approveAppointmentWithSelectedPet: async (
      appointmentId: string,
      contactId: string,
      petId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.approveAppointmentWithSelectedPet(
          appointmentId,
          contactId,
          petId
        )
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approveAppointmentWithNewPet: async (
      appointmentId: string,
      contactId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.approveAppointmentWithNewPet(appointmentId, contactId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approveAppointmentWithNewContact: async (appointmentId: string) => {
      const response = await axiosGet(
        apiRoutes.GET.approveAppointmentWithNewContact(appointmentId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approvalNewChoosePet: async (
      submissionId: string,
      contactId: string,
      petId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.approvalNewChoosePet(submissionId, contactId, petId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    getChangedFields: async (
      submissionId: string,
      contactId: string,
      petId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.getChangedFields(submissionId, contactId, petId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    getDocumentsForSubmission: async (
      submissionId: string,
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.getDocumentsForSubmission(submissionId)
      );
      if(!response || !response.data) {
        throw createAxiosResponseError();
      }
      return {
        status: response.status,
        // @ts-ignore
        data: response.data,
      };
    },
    getOABChangedFields: async (
      submissionId: string,
      contactId: string,
      petId: string
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.getOABChangedFields(submissionId, contactId, petId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    getListPets: async (submissionId: string, contactId: string) => {
      const response = await axiosGet(
        apiRoutes.GET.getListPets(submissionId, contactId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approvalNewPet: async (submissionId: string, contactId: string) => {
      const response = await axiosGet(
        apiRoutes.GET.approvalNewPet(submissionId, contactId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    approvalNewContact: async (submissionId: string) => {
      const response = await axiosGet(
        apiRoutes.GET.approvalNewContact(submissionId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    getCountUnreadSubmissions: async (tenantId: string) => {
      const response = await axiosGet(
        apiRoutes.GET.getCountUnreadSubmissions(tenantId)
      );
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },
    getAppointmentById: async (id: string) => {
      const response = await axiosGet(apiRoutes.GET.singleAppointmentByID(id));
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },
    getListProduct: async (query: {
      limit: number;
      offset: number;
      searchText?: string;
      filter?: string;
    }) => {
      const response = await axiosGet(`${apiRoutes.GET.getListProduct}`);
      // ?${queryString.stringify(query)}`
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      const validResponse = ResponseTypeguards.validateProducts(response);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },
    getListDuration: async () => {
      const response = await axiosGet(apiRoutes.GET.getListDuration);
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },
    allSpeciesByCurrentTenant: async () => {
      const response = await axiosGet(apiRoutes.GET.getListSpecies);
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },

    getListSex: async () => {
      const response = await axiosGet(apiRoutes.GET.getListSex);
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      const validResponse = ResponseTypeguards.validatePetSex(response);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },

    getListBreeds: async (payload: string[]) => {
      // const response = await axiosGet(
      //   `${apiRoutes.GET.getListBreeds}?${queryString.stringify({
      //     selectedPetSpecies: payload,
      //   })}`
      // );
      const response = await axiosGet(`${apiRoutes.GET.getListBreeds}`, {
        axiosOptions: {
          params: {
            selectedPetSpecies: payload.join(','),
          },
        },
      });
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      const validResponse = ResponseTypeguards.validatePetBreed(response);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },

    getProviders: async (): Promise<ProviderType> => {
      const response = await axiosGet(apiRoutes.GET.getProviders);
      if (!response) {
        throw createAxiosResponseError();
      }
      // @ts-ignore
      return response;
    },
    getHoursOfOperation: async (
      tenantId: string
    ): Promise<HoursOfOperationDataType> => {
      const response = await axiosGet(
        apiRoutes.GET.getHoursOfOperation(tenantId)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      // @ts-ignore
      return response.data;
    },
    getListMinimumAvailability: async () => {
      const res = await axiosGet(apiRoutes.GET.getListMinimumAvailability);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return res.data;
    },
    getMinimumAvai: async (): Promise<string> => {
      const response = await axiosGet(apiRoutes.GET.getMinimumAvailability);
      if (!response) {
        throw createAxiosResponseError();
      }
      // @ts-ignore
      return response?.data;
    },
    getTenantOABTimeUnits: async (): Promise<number> => {
      const response = await axiosGet(apiRoutes.GET.getTenantOABTimeUnits);
      if (!response) {
        throw createAxiosResponseError();
      }
      // @ts-ignore
      return response?.data;
    },
    getOfficeUnavailabilty: async (
      tenantId: string
    ): Promise<OfficeUnavailabiltyType> => {
      const response = await axiosGet(
        apiRoutes.GET.getOfficeUnavailabilty(tenantId)
      );
      if (!response) {
        throw createAxiosResponseError();
      }
      // @ts-ignore
      return response?.data;
    },
    getDailySelect: async () => {
      const response = await axiosGet(apiRoutes.GET.getDailySelect);

      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getInvoicingUrl: async () => {
      const response = await axiosGet(apiRoutes.GET.invoicingUrl);
      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getFormById: async (formId: string) => {
      const response = await axiosGet(apiRoutes.GET.getFormById(formId));
      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getFormAppointmentBooking: async () => {
      const response = await axiosGet(apiRoutes.GET.getFormAppointmentBooking);
      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getListFormPractice: async (practiceName: string) => {
      const response = await axiosGet(apiRoutes.GET.getShareForms(practiceName));

      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getAdvandeFields: async () => {
      const response = await axiosGet(apiRoutes.GET.getAdvandeFields);
      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    getBasicFields: async () => {
      const response = await axiosGet(apiRoutes.GET.getBasicFields);
      if (!response) {
        throw createAxiosResponseError();
      }

      return response;
    },
    currentUserData: async (): Promise<UserDataResponse> => {
      const res = await axiosGet(apiRoutes.GET.currentUserData);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },
    getFolderOfUser: async (
      tenantId: string,
      query: Record<string, unknown> = {}
    ) => {
      //@ts-ignore
      const response = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.getListFolder(tenantId)}?${queryString.stringify(
              query
            )}`
          : apiRoutes.GET.getListFolder(tenantId)
      );

      if (!response) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }

      return {
        status: response.status,
        //@ts-ignore
        data: response.data.items,
      };
    },
    getFormOfFolder: async (
      tenantId: string,
      query: Record<string, unknown> = {}
    ) => {
      //@ts-ignore
      const response = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.getListForm(tenantId)}?${queryString.stringify(
              query
            )}`
          : apiRoutes.GET.getListForm(tenantId)
      );

      if (!response) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }

      return {
        status: response.status,
        // @ts-ignore
        data: response.data.items,
      };
    },
    singleUserByID: async (userID: string): Promise<UserDataResponse> => {
      const res = await axiosGet(apiRoutes.GET.singleUserByID(userID));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse as any;
    },

    currentTenantInfo: async (): Promise<TenantInfoResponse> => {
      const res = await axiosGet(apiRoutes.GET.currentTenantInfo);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSingleTenantInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      setSessionTenantInfo(isValidResponse);
      return isValidResponse;
    },

    currentExternalLink: async (): Promise<ResponseTypes.ExternalLinkResponse> => {
      const res = await axiosGet(apiRoutes.GET.currentExternalLink);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      //console.log(res)
      const isValidResponse = ResponseTypeguards.validateExternalLink(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    currentTenantAllGroups: async (): Promise<GroupInfoResponse> => {
      const res = await axiosGet(apiRoutes.GET.currentTenantAllGroups);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGroupInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allUserInfoByCurrentTenant: async (options: {
      limit?: number;
      offset?: number;
      summaryItems?: SummaryItemTypes[];
      withStatus?: UserStatusTypes;
      userKinds: UserKindTypes[];
      withName?: string;
      isMessageList?: boolean;
    }): Promise<TenantUserDataResponse> => {
      const res = await axiosGet(
        apiRoutes.GET.allUsersByCurrentTenant({
          limit: options?.limit ?? undefined,
          offset: options?.offset ?? undefined,
          withStatus: options?.withStatus ?? undefined,
          withName: options?.withName ?? '',
          isMessageList: options?.isMessageList ?? undefined,
          ...options,
        })
      );
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAllUserInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allContactInfoByCurrentTenant: async (
      options: {
        limit?: number;
        offset?: number;
        summaryItems?: SummaryItemTypes[];
        withStatus?: UserStatusTypes;
        userKinds: UserKindTypes[];
        withName?: string;
        isMessageList?: boolean;
      },
      cancelToken?: CancelToken
    ): Promise<TenantUserDataResponse> => {
      const res = await axiosGet(
        apiRoutes.GET.allContactsByCurrentTenant({
          limit: options?.limit ?? undefined,
          offset: options?.offset ?? undefined,
          withStatus: options?.withStatus ?? undefined,
          withName: options?.withName ?? '',
          isMessageList: options?.isMessageList ?? undefined,
          ...options,
        }),
        {
          axiosOptions: cancelToken ? { cancelToken } : {},
        }
      );
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAllUserInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    filterContactInfoByCurrentTenant: async (
      options: {
        apptStartDate?: string;
        apptEndDate?: string;
        petBirthdateStart?: string;
        petBirthdateEnd?: string;
        petSpecies?: string[];
        petBreed?: string[];
        petSex?: string[];
        appointmentType?: string[];
        provider?: string[];
      },
      cancelToken?: CancelToken
    ): Promise<TenantFilteredDataResponse> => {
      const res = await axiosGet(
        apiRoutes.GET.filterContactInfoByCurrentTenant({
          apptStartDate: options?.apptStartDate ?? undefined,
          apptEndDate: options?.apptEndDate ?? undefined,
          petBirthdateStart: options?.petBirthdateStart ?? undefined,
          petBirthdateEnd: options?.petBirthdateEnd ?? undefined,
          petSpecies: options?.petSpecies ?? undefined,
          petBreed: options?.petBreed ?? undefined,
          petSex: options?.petSex ?? undefined,
          appointmentType: options?.appointmentType ?? undefined,
          provider: options?.provider ?? undefined,
        }),
        {
          axiosOptions: cancelToken ? { cancelToken } : {},
        }
      );
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateFilterContactInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    singleGroupDataByID: async (
      groupID: string
    ): Promise<GroupDataResponse> => {
      const res = await axiosGet(apiRoutes.GET.singleGroupDataByID(groupID));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSingleGroupData(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allUserPetsByUserID: async (
      userID: string
    ): Promise<PetSummaryCollectionResponse> => {
      const res = await axiosGet(apiRoutes.GET.allPetsByUserID(userID));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateMultiplePetInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    singlePetInfoByID: async (petID: string): Promise<PetInfoResponse> => {
      const res = await axiosGet(apiRoutes.GET.singleGroupDataByID(petID));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSinglePetInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allUserAppointmentsOfUser: async (
      userID: string,
      queryRangeParameters?: {
        dateStart: string;
        dateEnd: string;
        limit: number;
        offset?: number;
      }
    ): Promise<AppointmentInfoResponse> => {
      const computedRoute = apiRoutes.GET.allUserAppointmentsOfUser(
        userID,
        queryRangeParameters ?? undefined
      );

      if (!computedRoute) {
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAppointmentInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allUserAppointmentsByUserID: async (
      userID: string,
      queryRangeParameters?: {
        dateStart: string;
        dateEnd: string;
        limit: number;
        offset?: number;
        //kind: ["telemed"]
      }
    ): Promise<AppointmentInfoResponse> => {
      const computedRoute = apiRoutes.GET.allUserAppointmentsByUserID(
        userID,
        queryRangeParameters ?? undefined
      );

      if (!computedRoute) {
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAppointmentInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    currentTenantAppointmentsInRange: async (
      startDate: string,
      endDate: string,
      options?: {
        userID?: string;
        limit?: number;
        offset?: number;
        kind?: string;
        withStatus?: string;
        isWithoutEndDate?: boolean;
      }
    ): Promise<AppointmentInfoResponse> => {
      const computedRoute = apiRoutes.GET.currentTenantAppointmentsInDateRange(
        startDate,
        endDate,
        options
      );

      if (!computedRoute) {
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAppointmentInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    currentTenantMultipleAppointmentsInRange: async (
      startDate: string,
      endDate: string,
      options?: {
        userID?: string;
        limit?: number;
        offset?: number;
        kind?: string;
        withStatus?: string;
        isWithoutEndDate?: boolean;
        withName?: string;
      }
    ): Promise<AppointmentMultipleInfoResponse> => {
      const computedRoute = apiRoutes.GET.currentTenantAppointmentsInDateRange(
        startDate,
        endDate,
        options
      );

      if (!computedRoute) {
        //TODO error handling and logging
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateMultipleAppointmentInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    currentTenantAppointmentNotes: async (
      appointmentId: string
    ): Promise<ResponseTypes.AppointmentNotesResponse> => {
      const computedRoute = apiRoutes.GET.appointmentNotesByID(appointmentId);

      if (!computedRoute) {
        //TODO error handling and logging
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAppointmentNotes(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },
    getRecording: async (appointmentId: string) => {
      const res = await axiosGet(apiRoutes.GET.videoRecording(appointmentId));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }

      return get(res.data, 'attachments', []);
    },
    currentTenantAppointmentAttachments: async (
      appointmentId: string
    ): Promise<ResponseTypes.AppointmentAttachmentsResponse> => {
      const computedRoute =
        apiRoutes.GET.appointmentAttachmentsByID(appointmentId);

      if (!computedRoute) {
        //TODO error handling and logging
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateAppointmentAttachments(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    telemedAppointmentAccessDetails: async (appointmentID: string) => {
      const res = await axiosGet(
        apiRoutes.GET.telemedAppointmentAccessDetails(appointmentID)
      );
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAppointmentInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    allUserRemindersByUserID: async (
      userID: string,
      queryRangeParameters?: {
        dateStart?: string;
        dateEnd?: string;
        limit?: number;
        offset?: number;
      }
    ): Promise<ReminderInfoResponse> => {
      const computedRoute = apiRoutes.GET.allUserRemindersByUserID(
        userID,
        queryRangeParameters ?? undefined
      );

      if (!computedRoute) {
        throw createInvalidRouteParameterError();
      }
      const res = await axiosGet(computedRoute);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateReminderInfo(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    generalSettings: async () => {
      try {
        const res = await axiosGet(apiRoutes.GET.generalSettings);
        if (!res) {
          throw createAxiosResponseError();
        }

        const isValidResponse = ResponseTypeguards.validateSettingGeneral(res);
        if (!isValidResponse) {
          throw createTypeguardError();
        }
        return isValidResponse;
      } catch (err) {
        return generalSettings;
      }
    },

    systemSettings: async () => {
      const res = await axiosGet(apiRoutes.GET.tenantSystemSetting);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSystemSettingData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    timezones: async () => {
      const res = await axiosGet(apiRoutes.GET.timeZones);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTimeZoneData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    brandColorSettings: async () => {
      const res = await axiosGet(apiRoutes.GET.brandColorSettings);
      try {
        if (!res) {
          throw createAxiosResponseError();
        }
        const isValidResponse =
          ResponseTypeguards.validateBrandColorSetting(res);
        if (!isValidResponse) {
          throw createTypeguardError();
        }
        return isValidResponse;
      } catch {
        return dataMockupBrandColor;
      }
    },

    notificationSettings: async (isSystem?: boolean) => {
      const res = await axiosGet(
        isSystem
          ? apiRoutes.GET.systemNotificationSetting
          : apiRoutes.GET.notificationsSetting
      );
      try {
        if (!res) {
          throw createAxiosResponseError();
        }
        const isValidResponse =
          ResponseTypeguards.validateNotificationSetting(res);
        if (!isValidResponse) {
          throw createTypeguardError();
        }
        return isValidResponse;
      } catch (err) {
        return dataMockupNotification;
      }
    },

    templateByID: async (templateID: string) => {
      const res = await axiosGet(apiRoutes.GET.templateByID(templateID));
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTemplateByID(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getListImageBySide: async (side: '4x6' | '6x9' | '6x11') => {
      const res = await axiosGet(
        `${apiRoutes.GET.getListImageBySide}?${queryString.stringify({
          folder: side,
        })}`
      );
      if (!res) {
        throw createAxiosResponseError();
      }

      return res;
    },

    listCategory: async (kind: number) => {
      const res = await axiosGet(apiRoutes.GET.listCategory(kind));
      if (!res) {
        throw createAxiosResponseError();
      }

      return res;
    },

    callInputOption: async () => {
      const res = await axiosGet(apiRoutes.GET.callInputOption);
      if (!res) {
        throw createAxiosResponseError();
      }

      return res;
    },

    templateByKind: async (
      templateKind: TemplateKinds[],
      categoryKinds?: TemplateCategoryKinds[] | string[]
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.templateByKind({ templateKind, categoryKinds })
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTemplate(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getTemplateAnalyze: async (query: Record<string, unknown>) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.getTemplateAnaylze}?${queryString.stringify(
              query
            )}`
          : `${apiRoutes.GET.getTemplateAnaylze}`
      );

      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateTemplateAnalyze(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getPerformanceAnalyze: async (
      kindOfTemplate: string,
      query: Record<string, unknown>
    ) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${
              apiRoutes.GET.reportByKind
            }/${kindOfTemplate}?${queryString.stringify(query)}`
          : `${apiRoutes.GET.reportByKind}/${kindOfTemplate}`
      );
      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateTemplatePerformance(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getUnsuccessfulDeliverAnalyze: async (
      kindOfTemplate: string,
      query: Record<string, unknown>
    ) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${
              apiRoutes.GET.reportByKind
            }/${kindOfTemplate}?${queryString.stringify(query)}`
          : `${apiRoutes.GET.reportByKind}/${kindOfTemplate}`
      );

      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateTemplateUnsuccessfulDeliver(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getEmailAnalytic: async () => {
      const res = await axiosGet(apiRoutes.GET.emailAnalytics);

      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateEmailAnalytic(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getPerformingTemplate: async () => {
      const res = await axiosGet(apiRoutes.GET.topPerformingTemplates);
      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validatePerformingTemplate(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    personalizationTokens: async () => {
      const res = await axiosGet(apiRoutes.GET.personalizationTokens);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validatePersonalizationTokens(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    messagesByUserID: async (
      userID: string,
      optionsForGET?: {
        commKinds?: string[];
        commStatus?: string[];
        dateStart?: string;
        dateEnd?: string;
        offset?: number;
        limit?: number;
      }
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.messagesByUserID(userID, optionsForGET)
      );
      if (!res) {
        throw createAxiosResponseError();
      }

      // const isValidResponse = ResponseTypeguards.validateUserDetailSMSResponse(res);
      // if (!isValidResponse) {
      //   throw createTypeguardError();
      // }
      // @ts-ignore
      return res.data;
    },

    getMessagesByUserID: async (
      userID: string,
      allMessages: boolean = false,
      cancelToken?: CancelToken
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.getMessagesByUserID(userID, allMessages),
        {
          axiosOptions: cancelToken ? { cancelToken } : {},
        }
      );
      if (!response) {
        throw createAxiosResponseError();
      }

      // @ts-ignore
      return response?.data?.messages ?? [];
    },

    getTemplateRecipientDetails: async (
      templateId: string,
      query: Record<string, unknown>
    ) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.templateRecipientDetails(
              templateId
            )}?${queryString.stringify(query)}`
          : `${apiRoutes.GET.templateRecipientDetails(templateId)}`
      );

      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateTemplateRecipients(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    templatePerformanceDetail: async (
      templateID: string,
      startTime: string,
      endTime: string
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.templatePerformanceDetail(templateID, startTime, endTime)
      );

      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateTemplatePerformanceDetail(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDataOpenByPlatformAndDevice: async (
      templateID: string,
      startTime: string,
      endTime: string
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.getDataOpenByPlatformAndDevice(
          templateID,
          startTime,
          endTime
        )
      );

      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateDataOpenByPlatformAndDevice(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    automationTriggerByKind: async (
      categoryKind?: TemplateCategoryKinds | string
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.automationTriggerByKind({ categoryKind })
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateAutomationTrigger(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    automationSetting: async () => {
      const res = await axiosGet(apiRoutes.GET.getAutomationSetting);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateAutomationSettingResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    deliverApproximately: async (automationTriggerKey: string) => {
      const res = await axiosGet(
        apiRoutes.GET.deliverApproximately(automationTriggerKey)
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateDeliverApproximately(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getAutomationTriggerList: async (
      categoryKind: string,
      automationTriggerKey: string,
      options?: { offset: number; limit: number }
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.automationTriggerList(
          categoryKind,
          automationTriggerKey,
          options
        )
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      // const isValidResponse = ResponseTypeguards.validateAutomationTriggerList(res);
      // if (!isValidResponse) {
      //   throw createTypeguardError();
      // }
      return res?.data;
    },

    checkIncall: async () => {
      const res = await axiosGet(apiRoutes.GET.checkIncall);
      if (!res) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateCheckIncall(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getCountAlertErrors: async () => {
      const res = await axiosGet(apiRoutes.GET.countAlertErrors);
      if (!res) {
        throw createAxiosResponseError();
      }

      return res;
    },

    getCountSyncerStopPages: async () => {
      const res = await axiosGet(apiRoutes.GET.countSyncerStopPages);
      if (!res) {
        throw createAxiosResponseError();
      }

      return res;
    },

    getRoles: async () => {
      const res = await axiosGet(apiRoutes.GET.roles);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetRole(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getContactListData: async (options: {
      limit?: number;
      offset?: number;
      summaryItems?: SummaryItemTypes[];
      withStatus?: UserStatusTypes;
      userKinds: UserKindTypes[];
      searchText?: string;
      isMessageList?: boolean;
    }) => {
      const res = await axiosGet(
        apiRoutes.GET.contactListData({
          limit: options?.limit ?? undefined,
          offset: options?.offset ?? undefined,
          withStatus: options?.withStatus ?? undefined,
          searchText: options?.searchText ?? '',
          isMessageList: options?.isMessageList ?? undefined,
          ...options,
        })
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateGetContactListData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDrivers: async () => {
      const res = await axiosGet(apiRoutes.GET.drivers);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetDrivers(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getServices: async () => {
      const res = await axiosGet(apiRoutes.GET.services);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetServices(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getConfigurators: async () => {
      const res = await axiosGet(apiRoutes.GET.configurators);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetConfigurators(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDriverConfigurationSetting: async () => {
      const res = await axiosGet(apiRoutes.GET.driverConfigurationSetting);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },

    getDriverInformationData: async (latestDriverId: string) => {
      const res = await axiosGet(apiRoutes.GET.driverInfo(latestDriverId));
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetDriverInfo(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getServiceInformationData: async (latestDriverId: string) => {
      const res = await axiosGet(apiRoutes.GET.serviceInfo(latestDriverId));
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetServiceInfo(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getConfiguratorInformationData: async (latestConfiguratorId: string) => {
      const res = await axiosGet(
        apiRoutes.GET.configuratorInfo(latestConfiguratorId)
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateGetConfiguratorInfo(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getApiCalls: async () => {
      const res = await axiosGet(apiRoutes.GET.apiCalls);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateApiCalls(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getScreens: async () => {
      const res = await axiosGet(apiRoutes.GET.screens);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetScreens(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    groupSystemAdmin: async () => {
      const res = await axiosGet(apiRoutes.GET.groups);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGroupResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    groupInfo: async (groupId: string) => {
      const res = await axiosGet(apiRoutes.GET.groupInfo(groupId));
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGroupInfoResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    singleGroupById: async (groupId: string): Promise<Group> => {
      const res = await axiosGet(apiRoutes.GET.singleGroupById(groupId));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateCreateGroupResponse(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse as any;
    },

    getTenantsByGroupId: async (tenantGroupId: string) => {
      const res = await axiosGet(
        apiRoutes.GET.getTenantsByGroupId(tenantGroupId)
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateListTenantResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getSyncerStopPages: async (query: any) => {
      const res = await axiosGet(
        `${apiRoutes.GET.getSyncerStopPages}?${queryString.stringify(query)}`
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      const validResponse = ResponseTypeguards.validateSyncerStopPages(res);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },

    getPracticeErrors: async (query: {
      tenantId: string;
      limit: number;
      offset: number;
      startTime?: string;
      endTime?: string;
      searchText?: string;
    }) => {
      const res = await axiosGet(
        `${apiRoutes.GET.getPracticeErrors}?${queryString.stringify(query)}`
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateGetPracticeSyncerErrors(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    getSyncerErrors: async (query: {
      limit: number;
      offset: number;
      searchText?: string;
      filter?: string;
    }) => {
      const res = await axiosGet(
        apiRoutes.GET.adminSyncerErrors(
          query.limit,
          query.offset,
          query.filter,
          query.searchText
        )
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateGetAdminSyncerErrors(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    getServiceLogs: async (query: any) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.serviceLogs}?${queryString.stringify(query)}`
          : apiRoutes.GET.serviceLogs
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetServiceLogs(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getListBookingRequest: async (query: any) => {
      const res = await axiosGet(
        !isEmpty(query)
          ? `${apiRoutes.GET.bookingRequest}?${queryString.stringify(query)}`
          : apiRoutes.GET.bookingRequest
      );

      return res;
    },

    getListStatusAppointmentBooking: async () => {
      const res = await axiosGet(
        '/appointmentBookingRequests/v1/appointmentBookingFilterStatus'
      );

      return res;
    },

    getTenantInfo: async (tenantGroupId: string, tenantId: string) => {
      const res = await axiosGet(
        apiRoutes.GET.getTenantInfo(tenantGroupId, tenantId)
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTenantResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getSyncerFilterOptions: async () => {
      const res = await axiosGet(apiRoutes.GET.getSyncerFilterOptions);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateSyncerFilterOption(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getSyncerPractices: async (
      filterOptions: ResponseTypes.SyncerFilterFormType,
      cancelToken?: CancelToken
    ) => {
      const res = await axiosGet(
        apiRoutes.GET.getSyncerPractices(filterOptions),
        {
          axiosOptions: cancelToken ? { cancelToken } : {},
        }
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateSyncerPractice(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getAllForm: async (tenantId: string) => {
      const res = await axiosGet(apiRoutes.GET.getAllForm(tenantId));
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateLeftMenuSubmissionData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getLeftMenuSubmissionData: async (querryData: {
      limit: number;
      offset: number;
      tenantId: string;
    }) => {
      const res = await axiosGet(
        apiRoutes.GET.getLeftMenuSubmissionData(querryData)
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateLeftMenuSubmissionData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getEmitter: async (tenantId: string) => {
      const res = await axiosGet(apiRoutes.GET.emitterSyncer(tenantId));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetEmitter(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getEmitterMethod: async () => {
      const res = await axiosGet(apiRoutes.GET.emitterMethod);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetEmitterMethod(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDriverConfiguration: async (tenantId: string) => {
      const res = await axiosGet(apiRoutes.GET.getDriverConfiguration(tenantId));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetDriverConfiguration(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getEstimatesResponse: async (tenantId: string, query: any) => {
      const res = await axiosGet(apiRoutes.GET.getEstimatesResponse(tenantId, query));
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetEstimatesResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getGeneralSyncer: async () => {
      const res = await axiosGet(apiRoutes.GET.generalSyncer);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateGetGeneralSyncer(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDropdownDataGeneral: async () => {
      const res = await axiosGet(apiRoutes.GET.dropdownDataGeneral);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateDropdownDataGeneral(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getGeneralSettingSyncer: async () => {
      const res = await axiosGet(apiRoutes.GET.syncerSetting);
      if (!res) {
        //TODO error handling and logging
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateSyncerSettingGeneral(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }

      return isValidResponse;
    },

    getBillingModes: async () => {
      const res = await axiosGet(apiRoutes.GET.getBillingModes);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },

    getDataPaySetting: async () => {
      const res = await axiosGet(apiRoutes.GET.paySetting);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validatePaySetting(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getDocumentTypes: async () => {
      const response = await axiosGet(apiRoutes.GET.getDocumentTypes);
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },

    getListRoom: async () => {
      const res = await axiosGet(apiRoutes.GET.getListRoom);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },

    getAppointmentType: async () => {
      const res = await axiosGet(apiRoutes.GET.getAppointmentType);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateAppointmentTypeSetting(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getMedicalServices: async (query?: any) => {
      const res = await axiosGet(
        `${apiRoutes.GET.getMedicalServices}${
          query ? `?${queryString.stringify(query)}` : ''
        }`
      );
      if (!res) {
        throw createAxiosResponseError();
      }
      const validResponse = ResponseTypeguards.validateMedicalServices(res);
      if (!validResponse) {
        throw createTypeguardError();
      }
      return validResponse;
    },

    getOptionDataSubmissionFilter: async () => {
      const res = await axiosGet(apiRoutes.GET.getOptionDataSubmissionFilter);
      if (!res) {
        throw createAxiosResponseError();
      }
      const isValidResponse =
        ResponseTypeguards.validateDropdownDataSubmission(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    getListSubmissionData: async (
      query: {
        limit?: number;
        offset?: number;
        withStatus?: string;
        withName?: string;
        formId?: string;
      },
      cancelToken?: CancelToken
    ) => {
      const response = await axiosGet(
        apiRoutes.GET.getListSubmissionData({
          limit: query?.limit ?? 10,
          offset: query?.offset ?? 0,
          withStatus: query?.withStatus ?? undefined,
          withName: query?.withName ?? undefined,
          formId: query?.formId ?? undefined,
        }),
        {
          axiosOptions: cancelToken ? { cancelToken } : {},
        }
      );
      if (!response) {
        throw createAxiosResponseError();
      }
      return response;
    },

    practiceLoginContact: async (userId: string): Promise<LoginResponse> => {
      const res = await axiosGet(apiRoutes.GET.practiceLoginContact(userId));

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const authDetails = ResponseTypeguards.validateLoginResponse(res);

      if (!authDetails) {
        throw createTypeguardError();
      }

      return authDetails;
    },
    getAllPackages: async () => {
      const res = await axiosGet(apiRoutes.GET.allPackages);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      //@ts-ignore
      return res.data;
    },
    getDealsByGroupId: async (groupId:string) => {
      //@ts-ignore
      const res = await axiosGet(apiRoutes.GET.dealsByGroupId(groupId));
      if(!res || !res.data){
        throw createAxiosResponseError();
      }

      return res.data;
    }

    //TODO error handling and logging
    // TODO update, still using .then
    // unsure if worth updating because we may never use it
    // checkBackendHealth: async() => {
    //   axiosGet(apiRoutes.GET.health).then(res => {console.log("check health response:", res)})
    // }
  }, // end GET requests

  POST: {
    sendEmailForDeal: async(dealId: string, payload: any) => {
      const res = await axiosPost(apiRoutes.POST.sendEmailForDeal(dealId), payload);
      if(!res || !res.data){
        throw createAxiosResponseError(); 
      }
      return res.data;
    },
    createNewDeal: async(payload:any) => {
      const res = await axiosPost(apiRoutes.POST.newDeal, payload);
      if(!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },
    createStripePaymentLink: async (payload:any) =>{
      const res = await axiosPost(apiRoutes.POST.createPaymentLink, payload);
      if(!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data
    },
    updateSubscriptionContact: async (id: string, payload: any) => {
      const res = await axiosPost(
        apiRoutes.POST.updateSubscriptionContact(id),
        payload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return res.data as UserDataResponse;
    },
    updateHideInClientPortal: async (
      payload: RequestTypes.ToggleSpeciePayload
    ) => {
      const response = await axiosPost(
        apiRoutes.POST.toggleHideInClientPortal,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return true;
    },
    createProduct: async (payload: Partial<ProductResponseType>) => {
      const response = await axiosPost(apiRoutes.POST.createProduct, payload);

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    updateFormSubmission: async (payload: any) => {
      const response = await axiosPost(
        apiRoutes.POST.submitSubmissionForm,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    approveFormSubmission: async (payload: any) => {
      const response = await axiosPost(
        apiRoutes.POST.approveFormSubmission,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    submitPdf: async (file: any) => {
      const response = await axiosPost(apiRoutes.POST.submitPdf, file, {
        axiosOptions: { headers: { 'Content-Type': 'multipart/form-data' } },
      });

      return response;
    },
    approveOABSubmission: async (payload: any) => {
      const response = await axiosPost(
        apiRoutes.POST.approveOABSubmission,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response.data;
    },
    createSubmissionContact: async (payload: ApproveSubmissionPayloadType) => {
      const response = await axiosPost(
        apiRoutes.POST.createSubmissionContact,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    sendNotifyContact: async (payload: RequestTypes.NotifyBody) => {
      // sendNotifyContact: async (actionKind: number, payload: RequestTypes.NotifyBody) => {
      const response = await axiosPost(
        apiRoutes.POST.sendNotifyContact,
        payload
      );

      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    updateAppointmentType: async (
      appointmentTypeId: string | undefined,
      payload: AppointmentTypeSettingRequestType
    ) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateAppointmentType(appointmentTypeId ?? ''),
        payload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    createAppointmentTypesOfProvider: async (
      providerId: string,
      payload: string[]
    ) => {
      const response = await axiosPost(
        apiRoutes.POST.createAppointmentTypesOfProvider,
        {
          // @ts-ignore
          providerId,
          appointmentTypeIds: payload,
        }
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    createProvider: async (payload: Provider) => {
      const response = await axiosPost(apiRoutes.POST.createProvider, payload);

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    createProviderUnavailability: async (
      payload: RequestTypes.OutOfOfficeEvent
    ) => {
      const response = await axiosPost(
        apiRoutes.POST.createOutOfOfficeEvent,
        payload
      );

      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    createOutOfOfficeEvent: async (payload: RequestTypes.OutOfOfficeEvent) => {
      const response = await axiosPost(
        apiRoutes.POST.createOutOfOfficeEvent,
        payload
      );

      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    createFolder: async (tenantId: string, folderName: string) => {
      const response = await axiosPost(apiRoutes.POST.createFolder(tenantId), {
        // @ts-ignore
        folder_name: folderName,
      });
      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    createForm: async (folderId: string, formName: string) => {
      const payload = {
        form_name: formName,
        form_folder_id: folderId,
      };
      const response = await axiosPost(apiRoutes.POST.createForm, payload);
      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    confirmAppointment: async (token: string) => {
      const response = await axiosPost(apiRoutes.POST.confirmAppointment, {
        // @ts-ignore
        token,
      });

      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },
    login: async (email: string, password: string): Promise<LoginResponse> => {
      const res = await axiosPost(
        apiRoutes.POST.login,
        {
          username: email,
          password: password,
          grantType: RequestTypes.ValidGrantTypes.password,
        },
        {
          axiosOptions: {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          },
          customOptions: {
            useAuth: false,
          },
        }
      );

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const authDetails = ResponseTypeguards.validateLoginResponse(res);

      if (!authDetails) {
        throw createTypeguardError();
      }

      return authDetails;
    },

    adminLoginPractice: async (
      tenantGroupId: string,
      tenantId: string
    ): Promise<LoginResponse> => {
      const res = await axiosPost(
        apiRoutes.POST.adminLoginPractice(tenantGroupId, tenantId),
        {}
      );

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const authDetails = ResponseTypeguards.validateLoginResponse(res);

      if (!authDetails) {
        throw createTypeguardError();
      }

      return authDetails;
    },

    sendMultipleUserSMS: async (
      payload: RequestTypes.MultipleUserSendTextPayload
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.messageWithMultipleReceivers,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendSMSToAllContacts: async (
      payload: RequestTypes.MultipleContactSendTextPayload,
      searchValue: string,
      filterValue: string
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.sendSMSToAllContacts(searchValue, filterValue),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendSingleUserSMS: async (
      userID: string,
      payload: RequestTypes.SingleUserSendTextPayload
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.messageByUserID(userID),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendingMessageToPhone: async (
      payload: RequestTypes.SingleSendTextToPhonePayload
    ) => {
      const res = await axiosPost(apiRoutes.POST.sendMessageToPhone, payload);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },

    sendSelectedUserEmail: async (
      payload: RequestTypes.SelectedUserSendEmailPayload
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.sendSelectedUserEmail,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendSingleUserEmail: async (
      userID: string,
      payload: RequestTypes.SingleUserSendEmailPayload
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.messageByUserID(userID),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendEmailToAllCustomers: async (
      payload: RequestTypes.SingleUserSendEmailPayload,
      searchValue: string,
      filterValue: string
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.sendSMSToAllContacts(searchValue, filterValue),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendEmailSupport: async (payload: SendEmailSupportSubmissionPayload) => {
      const response = await axiosPost(
        apiRoutes.POST.sendEmailSupport,
        // @ts-ignore
        payload
      );

      if (!response || !response.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      return response;
    },

    sendSingleUserPostal: async (
      userId: string,
      payload: RequestTypes.PostalRequestType
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.postalByUserID(userId),
        // @ts-ignore
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendPostalToAllContacts: async (
      withStatus: string,
      payload: RequestTypes.PostalRequestType
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.postalToAllContacts(withStatus),
        // @ts-ignore
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendPostalToMultipleContacts: async (
      payload: RequestTypes.PostalPayloadType
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.postalWithMultipleContacts,
        // @ts-ignore
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    createTemplate: async (
      templateData: ResponseTypes.TemplateData
    ): Promise<ResponseTypes.TemplateData> => {
      const redefinePayload = { ...templateData };

      const res = await axiosPost(
        apiRoutes.POST.createTemplate,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateUpdateTemplate(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    CreateAutomationTriggerEvent: async (
      eventData: RequestTypes.CreateAutomationTriggerEventRequest
    ): Promise<ResponseTypes.CreateAutomationTriggerEventResponse> => {
      const redefinePayload = { ...eventData };

      const res = await axiosPost(
        apiRoutes.POST.createAutomationTriggerEvent,
        redefinePayload as any,
        undefined,
        'automation'
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateAutomationTriggerEvent(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    copyAutomationTriggerEvent: async (
      eventData: string
    ): Promise<ResponseTypes.CreateAutomationTriggerEventResponse> => {
      const res = await axiosPost(
        apiRoutes.POST.copyAutomationTriggerEvent,
        { automationTriggerId: eventData } as any,
        undefined,
        'automation'
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateAutomationTriggerEvent(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createRole: async (
      roleData: RequestTypes.CreateRoleRequest
    ): Promise<ResponseTypes.Role> => {
      const redefinePayload = { ...roleData };

      const res = await axiosPost(
        apiRoutes.POST.createRole,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateRoleResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createDriver: async (
      driverData: RequestTypes.CreateDriverRequest
    ): Promise<ResponseTypes.DriversResponse> => {
      const redefinePayload = { ...driverData };

      const res = await axiosPost(
        apiRoutes.POST.createDriver,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateDriverRequest(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createService: async (
      serviceData: RequestTypes.CreateServiceRequest
    ): Promise<ResponseTypes.ServiceResponse> => {
      const redefinePayload = { ...serviceData };

      const res = await axiosPost(
        apiRoutes.POST.createService,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateServiceRequest(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createConfigurator: async (
      configuratorData: RequestTypes.CreateConfiguratorRequest
    ): Promise<ResponseTypes.ConfiguratorResponse> => {
      const redefinePayload = { ...configuratorData };

      const res = await axiosPost(
        apiRoutes.POST.createConfigurator,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateConfiguratorRequest(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    setPassword: async (
      userId: string,
      passwordData: RequestTypes.SetPasswordRequest
    ) => {
      const redefinePayload = { ...passwordData };

      const res = await axiosPost(
        apiRoutes.POST.setPassword(userId),
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createGroup: async (
      groupData: RequestTypes.CreateGroupRequest
    ): Promise<ResponseTypes.Group> => {
      const res = await axiosPost(apiRoutes.POST.createGroup, groupData);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateGroupResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createTenantInGroup: async (
      tenantGroupId: string,
      tenantData: RequestTypes.TenantRequest
    ) => {
      const redefinePayload = { ...tenantData };

      const res = await axiosPost(
        apiRoutes.POST.createTenantInGroup(tenantGroupId),
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateTenantResponse(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createEmitter: async (emitterRequestData: EmitterRequestType) => {
      const res = await axiosPost(
        apiRoutes.POST.createEmitter(),
        emitterRequestData
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTenantResponse(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    createTextMessageEmitterExecute: async (emitterRequestData: EmitterRequestType) => {
      const res = await axiosPost(
        apiRoutes.POST.createTextMessageEmitterExecute(),
        emitterRequestData
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      const isValidResponse = ResponseTypeguards.validateTenantResponse(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    resetPassword: async (payload: FormData) => {
      const res = await axiosPost(apiRoutes.POST.resetPassword, payload);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateResetPassword(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    uploadDriver: async (formData: any) => {
      const res = await axiosPost(apiRoutes.POST.uploadDriver, formData);

      const isValidResponse = ResponseTypeguards.validateUploadDriver(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    uploadService: async (formData: any) => {
      const res = await axiosPost(apiRoutes.POST.uploadService, formData);

      const isValidResponse = ResponseTypeguards.validateUploadService(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    uploadConfigurator: async (formData: any) => {
      const res = await axiosPost(apiRoutes.POST.uploadConfigurator, formData);

      const isValidResponse =
        ResponseTypeguards.validateUploadConfigurator(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    uploadImage: async (formData: any) => {
      const res = await axiosPost(apiRoutes.POST.uploadImage, formData);

      const isValidResponse = ResponseTypeguards.validateUploadImage(res);

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    addEstimatesRequest: async (addEstimatesRequestData: AddEstimatesRequest) => {
      const res = await axiosPost(
        apiRoutes.POST.addEstimatesRequest(),
        addEstimatesRequestData
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    deleteSubmissionForm: async (submissionSelectedId: string[]) => {
      const res = await axiosPost(apiRoutes.POST.deleteSubmissionForm, {
        selectedIdToDelete: submissionSelectedId,
      });
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    deleteSubmissionDocument: async (documentId: string) => {
      const res = await axiosPost(apiRoutes.POST.deleteSubmissionDocument, {
        documentId: documentId,
      });
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    softDeleteUser: async (payload: SoftDeleteUserRequestType) => {
      const res = await axiosPost(
        apiRoutes.POST.softDeleteUser(payload.userId),
        payload
      );

      const isValidResponse = ResponseTypeguards.validateSoftDeleteUser(
        res as any
      );

      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateMarkUnread: async (contactID: string, hasRead: boolean) => {
      //@ts-ignore
      const res = await axiosPost(
        apiRoutes.POST.updateMarkUnread(contactID, hasRead)
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    cloneForm: async (payload: CloneFormBuilderRequestType) => {
      const res = await axiosPost(apiRoutes.POST.cloneForm, payload);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    addRedirectLink: async (redirectLink: string) => {
      const res = await axiosPost(apiRoutes.POST.addRedirectLink, 
      {
        // @ts-ignore
        "link": redirectLink
      });
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    createAppointmentType: async (
      payload: AppointmentTypeSettingRequestType
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.createAppointmentType,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    addFormAutoFill: async (
      payload: AddFormAutoFillPayload
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.addFormAutoFill,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    markSubmissionAsRead: async (payload: MarkAsReadSubmissionPayload) => {
      const res = await axiosPost(apiRoutes.POST.markSubmissionAsRead, payload);
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    approveSubmissionForm: async (
      selectedSubmissionId: ApproveSubmissionPayloadType
    ) => {
      const res = await axiosPost(
        apiRoutes.POST.approveSubmissionForm,
        selectedSubmissionId
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },
  }, // end POST requests

  PATCH: {
    updatePhoneNumber: async (payload: { tenantId: string }) => {
      const res = await axiosPatch(
        apiRoutes.POST.updatePhoneNumber(payload.tenantId),
        // @ts-ignore
        payload
      );

      return true;
    },

    updateMangoSetting: async (payload: string[]) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateMangoPhoneSetting,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    updateProduct: async (payload: Partial<ProductResponseType>) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateProduct(payload.productId || ''),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },
    updateMinimumAvailability: async (minimum: string) => {
      const res = await axiosPatch(apiRoutes.PATCH.updateMinimumAvailability, {
        // @ts-ignore
        minimunAvailibility: Number(minimum),
      });
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },
    updateDriverConfiguration: async (payload: any) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateDriverConfiguraion,
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },
    updatePhoneContact: async (userId: string, payload: any) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updatePhoneContact(userId),
        payload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res.data;
    },
    cancelInteraction: async (messageId: string) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.cancelInteraction(messageId),
        // @ts-ignore
        null
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },
    updateProvider: async (provider: ProviderModal) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.updateProvider,
        provider
      );

      return response;
    },
    updateEventOffice: async (payload: OutOfOfficeEvent) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.updateEventOffice,
        payload
      );

      return response;
    },
    // setStatusAppointmentBooking: async (requestId: string) => {
    //   const response = await axiosPatch(apiRoutes.PATCH.setStatusAppointmentBooking(requestId), {});

    //   return response;
    // },
    setStatusAppointmentBooking: async (
      requestId: string,
      actionKind: number
    ) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.setStatusAppointmentBooking(requestId, actionKind),
        {}
      );

      return response;
    },
    updateWorkingHours: async (
      providerId: string,
      detailWorkingHours: ListDateType[],
      isUnavaliableAccepted: boolean,
      isUnavaliablePending: boolean
    ) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.updateHoursOfOperation,
        {
          // @ts-ignore
          providerId,
          value: detailWorkingHours,
          isUnavaliableAccepted,
          isUnavaliablePending,
        }
      );

      return response;
    },
    updateHoursOfOperation: async (
      tenantId: string,
      detailHourOfOperation: ListDateType[]
    ) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.updateHoursOfOperation,
        {
          // @ts-ignore
          tenantId,
          value: detailHourOfOperation,
        }
      );

      return response;
    },
    updateMinimumAvai: async (minimum: string) => {
      // const res = await axiosPatch(apiRoutes.PATCH.)
      // return response;
    },
    updateTenantOABTimeUnits: async (tenantTimeUnits: number) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.updateTenantOABTimeUnits,
        {
          // @ts-ignore
          oABTimeUnits: tenantTimeUnits,
        }
      );

      return response;
    },
    renameForm: async (formId: string, name: string) => {
      const response = await axiosPatch(apiRoutes.PATCH.editForm, {
        id: formId,
        form_name: name,
      });

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    moveForm: async (formId: string, folderId: string) => {
      const response = await axiosPatch(apiRoutes.PATCH.editForm, {
        id: formId,
        form_folder_id: folderId,
      });

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    editForm: async (formDetail: EditFormBuilderRequired) => {
      const response = await axiosPatch(apiRoutes.PATCH.editForm, formDetail);

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },

    editFormBooking: async (formVersion: any) => {
      const response = await axiosPatch(
        apiRoutes.PATCH.editFormBooking,
        formVersion
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },

    renameFolder: async (formId: string, name: string) => {
      const response = await axiosPatch(apiRoutes.PATCH.renameFolder, {
        id: formId,
        folder_name: name,
      });

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }

      return response;
    },
    updateAppointmentByID: async (
      appointment: AppointmentSummaryEntry
    ): Promise<AppointmentSummaryEntry> => {
      const redefinePayload = appointment;
      // @ts-ignore
      delete redefinePayload.patients;

      const res = await axiosPatch(
        apiRoutes.PATCH.updateAppointmentByID(appointment.appointmentId),
        redefinePayload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateAppointmentEntryResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateAppointmentStatus: async (
      appointmentID: string,
      status: string,
      appointmentTypeId?: string
    ): Promise<AppointmentSummaryEntry> => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateAppointmentByID(appointmentID),
        {
          status: status,
          appointmentTypeId
        }
      );

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateAppointmentEntryResponse(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateAppointmentCompletedTask: async (
      appointmentID: string,
      tasks: RequestTypes.CompletedTask[]
    ): Promise<AppointmentSummaryEntry> => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateAppointmentByID(appointmentID),
        {
          tasks,
        }
      );

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateAppointmentEntryResponse(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateSystemSetting: async (
      payload: ResponseTypes.SystemSettingResponseUpdated
    ): Promise<ResponseTypes.SystemSettingResponse> => {
      const res = await axiosPut(
        apiRoutes.PATCH.updateSystemSetting,
        payload as ResponseTypes.SystemSettingResponseUpdated
      );

      if (!res || !res.data) {
        //TODO error handling
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSystemSettingData(res);
      if (!isValidResponse) {
        //TODO error handling and logging
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateUserAdmin: async (userPayload: UserDataResponse) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateUser(userPayload.userId),
        userPayload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateTemplate: async (
      templateId: string,
      templateData: ResponseTypes.TemplateData
    ): Promise<ResponseTypes.TemplateData> => {
      const redefinePayload = { ...templateData };
      delete redefinePayload.templateId;

      const res = await axiosPatch(
        apiRoutes.PATCH.templateByID(templateId),
        redefinePayload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateUpdateTemplate(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateMessageNote: async (messageId: string, note: string) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateMessageNote(messageId),
        {
          notes: note,
        }
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },

    completeUserTask: async (
      userID: string,
      taskIDs: string[]
    ): Promise<UserDataResponse> => {
      const payload: RequestTypes.CompleteUserTaskPayload = {
        tasks: taskIDs.map((taskID) => {
          return {
            taskId: taskID,
            status: 'done',
          };
        }),
      };

      const res = await axiosPatch(apiRoutes.PATCH.updateUser(userID), payload);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    enableAutomationTriggerEvent: async (automationTriggerId: string) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.enableAutomationTriggerEvent(automationTriggerId),
        {},
        undefined,
        'automation'
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    regeneratePendingAutomation: async () => {
      const res = await axiosPatch(
        apiRoutes.PATCH.regeneratePendingAutomation,
        {},
        undefined,
        'automation'
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    UpdateAutomationTriggerEvent: async (
      eventData: RequestTypes.CreateAutomationTriggerEventRequest,
      automationTriggerEventId: string
    ): Promise<ResponseTypes.CreateAutomationTriggerEventResponse> => {
      const redefinePayload = { ...eventData };

      const res = await axiosPatch(
        apiRoutes.PATCH.updateAutomationTriggerEvent(automationTriggerEventId),
        redefinePayload as any,
        undefined,
        'automation'
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateAutomationTriggerEvent(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateRole: async (
      roleId: string,
      roleData: RequestTypes.CreateRoleRequest
    ): Promise<ResponseTypes.Role> => {
      const redefinePayload = { ...roleData };

      const res = await axiosPatch(
        apiRoutes.PATCH.updateRole(roleId),
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateRoleResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateDriver: async (
      driverData: RequestTypes.updateDriverRequest
    ): Promise<ResponseTypes.DriversResponse> => {
      const redefinePayload = { ...driverData };

      const res = await axiosPatch(
        apiRoutes.PATCH.updateDriver,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateDriverResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateService: async (
      serviceData: RequestTypes.updateServiceRequest
    ): Promise<ResponseTypes.ServiceResponse> => {
      const redefinePayload = { ...serviceData };

      const res = await axiosPatch(
        apiRoutes.PATCH.updateService,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateServiceResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateConfigurator: async (
      configuratorData: RequestTypes.updateConfiguratorRequest
    ): Promise<ResponseTypes.ConfiguratorResponse> => {
      const redefinePayload = { ...configuratorData };

      const res = await axiosPatch(
        apiRoutes.PATCH.updateConfigurator,
        redefinePayload as any
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateUpdateConfiguratorResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateGroup: async (
      groupData: RequestTypes.CreateGroupRequest
    ): Promise<ResponseTypes.Group> => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateGroup(groupData?.tenantGroupId ?? ''),
        groupData
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateCreateGroupResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updatePractice: async (
      tenantGroupId: string,
      tenantId: string,
      tenantData: RequestTypes.TenantRequest
    ) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updatePractice(tenantGroupId, tenantId),
        tenantData
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateTenantResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateAppointmentType: async (
      appointmentTypeId: string | undefined,
      payload: AppointmentTypeSettingRequestType
    ) => {
      const res = await axiosPatch(
        apiRoutes.PATCH.updateAppointmentType(appointmentTypeId ?? ''),
        payload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },
  },

  DEL: {
    deleteSyncerErrors: async (selectedTenantId: string[]) => {
      const res = await axiosDelete(apiRoutes.DEL.deleteSyncerErrors, {
        selectedTenantId,
      });

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },
    deleteTenantSyncerError: async (selectedSyncerErrorLogId: string[]) => {
      const res = await axiosDelete(apiRoutes.DEL.deleteTenantSyncerError, {
        selectedSyncerErrorLogId,
      });

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },
    deleteProvider: async (id: string) => {
      const response = await axiosDelete(apiRoutes.DEL.deleteProvider(id));
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response;
    },
    deleteAppointmentBooking: async (id: string) => {
      const response = await axiosDelete(
        apiRoutes.DEL.deleteAppointmentBooking(id)
      );
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response;
    },
    deleteOfficeEvent: async (id: string) => {
      const response = await axiosDelete(apiRoutes.DEL.deleteOfficeEvent(id));
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response;
    },
    removeForm: async (formId: string) => {
      const response = await axiosDelete(apiRoutes.DEL.removeForm(formId));
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response;
    },
    removeFolder: async (folderId: string) => {
      const response = await axiosDelete(apiRoutes.DEL.removeFolder(folderId));
      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return response;
    },
    removeAutomationTrigger: async (automationTriggerId: string) => {
      const res = await axiosDelete(
        apiRoutes.DEL.removeAutomationTriggerEvent(automationTriggerId),
        undefined,
        undefined,
        'automation'
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    removeInteraction: async (messageId: string) => {
      const res = await axiosDelete(apiRoutes.DEL.removeInteraction(messageId));
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    deleteMessage: async (contactID: string) => {
      const res = await axiosDelete(apiRoutes.DEL.deleteMessage(contactID));
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    deleteAppointmentType: async (appointmentTypeID: string) => {
      const res = await axiosDelete(
        apiRoutes.DEL.deleteAppointmentType(appointmentTypeID)
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },

    deleteTemplate: async (templateId: string) => {
      const res = await axiosDelete(apiRoutes.DEL.deleteTemplate(templateId));
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return res;
    },
  },

  PUT: {
    updateCurrentExternalLink: async (payload: {
      buttonText: string;
      externalUrl: string;
      isEnable: boolean;
  }) => {
      const response = await axiosPut(
        apiRoutes.PUT.currentExternalLink,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    updateOrderMedicalServices: async (payload: Partial<Medical>[]) => {
      const response = await axiosPut(
        apiRoutes.PUT.orderMedicalServices,
        payload
      );

      if (!response || !response.data) {
        throw createAxiosResponseError();
      }
      return true;
    },
    updateMedicalService: async (payload: Partial<Medical>) => {
      const res = await axiosPut(apiRoutes.PATCH.updateMedicalService, payload);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    markReadSyncerErrors: async (
      isRead: boolean = true,
      selectedTenantId: string[]
    ) => {
      const res = await axiosPut(apiRoutes.PUT.markReadSyncerErrors, {
        isRead,
        selectedTenantId,
      });

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },
    markReadTenantSyncerError: async (
      isRead: boolean,
      selectedSyncerErrorLogId: string[]
    ) => {
      const res = await axiosPut(apiRoutes.PUT.markReadTenantSyncerError, {
        isRead,
        selectedSyncerErrorLogId,
      });

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      return true;
    },
    updateSettingUserGeneral: async (
      namespace: string,
      payload: ResponseTypes.GeneralSettingResponse
    ) => {
      const payloadRedefine = payload.settings;
      const res = await axiosPut(
        apiRoutes.PATCH.updateUserSetting(namespace),
        payloadRedefine
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSettingGeneral(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateSettingBrandColors: async (
      namespace: string,
      payload: ResponseTypes.BrandColorsResponse
    ) => {
      const payloadRedefine = payload.settings;
      const res = await axiosPut(
        apiRoutes.PATCH.updateUserSetting(namespace),
        payloadRedefine
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateBrandColorSetting(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateNotificationSetting: async (
      namespace: string,
      payload: any,
      isSystem?: boolean
    ) => {
      const res = await axiosPut(
        isSystem
          ? apiRoutes.GET.systemNotificationSetting
          : apiRoutes.PATCH.updateUserSetting(namespace),
        payload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateNotificationSetting(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    // TODO determine how we will set the password
    // the desired flow may include registering the user first
    // and sending a confirmation to the registered email
    // then directing the user to set a password from there
    createTenantUser: async (userPayload: UserDataResponse) => {
      const res = await axiosPost(
        apiRoutes.POST.currentTenantRegisterUser,
        userPayload
      );
      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse = ResponseTypeguards.validateSingleUserData(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateAutomationSetting: async (
      automationSetting: ResponseTypes.AutomationSetting
    ): Promise<ResponseTypes.AutomationSettingResponse> => {
      const res = await axiosPut(
        apiRoutes.POST.updateAutomationSetting,
        automationSetting,
        undefined,
        'automation'
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateAutomationSettingResponse(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateGeneralSettingSyncer: async (
      payload: SyncerSettingGeneralResponseType
    ) => {
      const res = await axiosPut(
        apiRoutes.PUT.updateGeneralSettingSyncer,
        payload,
        undefined,
        'automation'
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }

      const isValidResponse =
        ResponseTypeguards.validateSyncerSettingGeneral(res);
      if (!isValidResponse) {
        throw createTypeguardError();
      }
      return isValidResponse;
    },

    updateDataPaySetting: async (payload: PaySettingType) => {
      const res = await axiosPut(apiRoutes.PUT.updateDataPaySetting, payload);

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    sendPhonePreview: async (templateId: string, previewNumber: string) => {
      const res = await axiosPut(apiRoutes.PUT.sendPhonePreview(templateId), {
        mobile: previewNumber,
      });

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },

    //twilio setting
    updateDataTwilioSetting: async (payload: TwilioSettingType) => {
      const res = await axiosPut(
        apiRoutes.PUT.updateDataTwilioSetting,
        payload
      );

      if (!res || !res.data) {
        throw createAxiosResponseError();
      }
      return true;
    },
  },
};
