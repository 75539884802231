import styled from 'styled-components';
import { getThemeProp } from '../utils';

const ButtonLikeDiv = styled.div`
  display: flex;
`;
const ScoreText = styled.div`
  color: ${(props: { color: string }): string => props.color};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  flex: 1;
  margin-right: 0.5rem;
`;
const BarContainer = styled.div`
  flex: 6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundBar = styled.div`
  background-color: ${(props: { backgroundColor: string }): string => props.backgroundColor};
  width: 100%;
  border-radius: 3px;
`;

interface ProgressBarProps {
  color: string;
  percentage: string;
}
const ProgressBar = styled.div`
  background-color: ${(props: ProgressBarProps): string => props.color};
  width: ${(props: ProgressBarProps): string => props.percentage};
  height: 6px;
  border-radius: ${({ percentage }: ProgressBarProps): string =>
    percentage === '100%' ? '3px' : '3px 0 0 3px'};
`;

export { ButtonLikeDiv, ScoreText, BarContainer, BackgroundBar, ProgressBar };
