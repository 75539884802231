import React from 'react';
import { FormFieldBase, FormFieldInput } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';

export const FormFieldUpload = ({
    field,
    isRenderedField,
    onChange,
    value,
    onBlur,
    validationMessage,
    onUpdate,
    files,
    setFiles,
    readOnly,
    submitted,
}) => {
    const properties = getFieldProperties(field, ['common', 'specific']);

    const options = {
        properties,
        isRenderedField,
        onChange,
        value,
        onUpdate,
        onBlur,
        validationMessage,
        readOnly,
        submitted,
    };

    return <FormFieldInput field={field} options={options} files={files} setFiles={setFiles} submitted={submitted} />;
};

FormFieldUpload.defaultProps = {
    isRenderedField: false,
    onChange: () => { },
    value: '',
    onBlur: () => { },
};

export default FormFieldBase(FormFieldUpload);

