import styled from 'styled-components';
import React from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const IconButton = styled(Button)`
  && {
    padding: 0;
  }
  &&:hover {
  }
`;

const LeftButton = styled(
  ({
    colorLeft,
    textColorLeft,
    disableRipple,
    disableFocusRipple,
    fullWidth,
    ...rest
  }): React.ReactElement => <Card {...rest} />, // eslint-disable-line react/jsx-props-no-spreading
)<{
  textColorLeft: string;
  colorLeft: string;
}>`
  && {
    height: 30px;
    background-color: ${(props): string => props.colorLeft};
    color: ${(props): string => props.textColorLeft};
    flex: 1;
    max-width: 36px;
    padding-top: 6px;
    margin-right: -1px;
    text-transform: none;
    border-right: none;

    @media only screen and (max-width: 480px) {
      border-radius: 6px;
    }
  }
  &&:hover {
    flex: 1;
    background-color: ${(props): string => props.colorLeft};
    color: ${(props): string => props.textColorLeft};
    max-width: 36px;

    @media only screen and (max-width: 480px) {
      border-radius: 6px;
    }
  }
`;

const RightButton = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({
    colorRight,
    textColorRight,
    disableRipple,
    disableFocusRipple,
    fullWidth,
    ...rest
  }): React.ReactElement => <Card {...rest} />, // eslint-disable-line react/jsx-props-no-spreading
)<{
  textColorRight: string;
  colorRight: string;
}>`
  && {
    flex: 2;
    color: ${(props): string => props.textColorRight};
    background-color: ${(props): string => props.colorRight};
    align-items: 'center';
    justify-content: 'center';
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    text-transform: none;
    border-left: none;
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }
  &&:hover {
    flex: 2;
    color: ${(props): string => props.textColorRight};
    background-color: ${(props): string => props.colorRight};
  }
`;
export { IconButton, LeftButton, RightButton };
