import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ServiceResponse } from 'global/requests/ResponseTypes';
import React, { Fragment, useEffect } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import ServiceUpload from './ServiceUpload';
import _ from 'lodash';
import moment from 'moment';
ReactModal.setAppElement('div#root');

interface RightModalSettingUserProps {
  displayRoleModal: boolean;
  setDisplayRoleModal: (arg: boolean) => void;
  titleText?: string;
  handleReloadRoleList?: any;
  isEdit?: boolean;
  idValue: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const serviceValidationSchema = Yup.object().shape({
  version: Yup.string().required(ErrorMessageTypes.incorrectFormat),
  releasedDate: Yup.string().required(ErrorMessageTypes.incorrectFormat),
});

const ServiceModal = (props: RightModalSettingUserProps) => {
  const { displayRoleModal, setDisplayRoleModal, titleText, isEdit, idValue } =
    props;

  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);

  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const [errorUploadFile, setErrorUploadFile] = React.useState<string>('');

  const [serviceInfoData, setServiceInfoData] =
    React.useState<ServiceResponse>();

  const getServiceInformationData = async () => {
    try {
      const res = await configuredRequests.GET.getServiceInformationData(
        idValue
      );
      if (res) {
        setServiceInfoData(res as ServiceResponse);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (idValue && serviceInfoData?.id !== idValue) {
      getServiceInformationData();
    }
  });

  const serviceInitialValues = !isEdit
    ? {
        version: 'N/A',
        releasedDate: '',
        downloadLink: '',
      }
    : {
        id: serviceInfoData?.id,
        version: _.get(serviceInfoData, 'version', 'N/A'),
        releasedDate: moment(serviceInfoData?.releasedDate).format(
          'YYYY-MM-DDTHH:mm'
        ),
        downloadLink: serviceInfoData?.downloadLink,
      };

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = !isEdit
        ? {
            // serviceName: values.serviceName,
            version: values.version,
            releasedDate: values.releasedDate,
            downloadLink: values.downloadLink,
          }
        : {
            id: serviceInfoData?.id,
            version: values.version,
            releasedDate: values.releasedDate,
            downloadLink: values.downloadLink,
          };
      const res = isEdit
        ? await configuredRequests.PATCH.updateService(submitData as any)
        : await configuredRequests.POST.createService(submitData as any);
      setDisplayRoleModal(false);
      await props.handleReloadRoleList();
      getServiceInformationData();
      if (res) {
        setErrorUploadFile('');
        setDirtyForm(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (error) {
      setDisabledSubmit(false);
      if (error.response) {
        setErrorUploadFile(error.response.data.message);
      }
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplayRoleModal(dirtyForm);
    setErrorUploadFile('');
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Your data will be lost. Do you wish to continue?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setDirtyForm(false);
              setOpenPopup(false);
              setDisplayRoleModal(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-driver'
      isOpen={displayRoleModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={serviceInitialValues}
        validationSchema={serviceValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const { handleSubmit, setFieldValue, handleChange, values, dirty } =
            formikProps;
          if (dirty && displayRoleModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' Version' : ''}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Service Version <b className='require'>*</b>
                        </span>
                        <div className='form-input-settings'>
                          <div className='first-name'>
                            <FastField
                              name='version'
                              required
                              disabled
                              value={values.version}
                              className='setting__search-form'
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='version' />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Released Date <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            id='datetime-local'
                            type='datetime-local'
                            component={TextField}
                            name='releasedDate'
                            required
                            disabled
                            className='setting__search-form datetime'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e: any) => {
                              handleChange(e);
                              setFieldValue('releasedDate', e.target.value);
                            }}
                            value={values.releasedDate}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='releasedDate' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <div className='form-input-settings'>
                        <div className='first-name'>
                          <FastField
                            name='downloadLink'
                            required
                            disabled
                            value={values.downloadLink}
                            className='display-none'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Upload File</h2>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        File <b className='require'>*</b>
                      </span>
                      <ServiceUpload
                        setFieldValue={setFieldValue}
                        values={values}
                        setErrorUploadFile={setErrorUploadFile}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='emailAddress' />
                        {errorUploadFile}
                      </p>
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    !isEdit
                      ? !formikProps.isValid ||
                        serviceInitialValues.version === values.version ||
                        disabledSubmit
                      : !formikProps.isValid || disabledSubmit
                  }
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </ReactModal>
  );
};
export default ServiceModal;
