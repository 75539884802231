import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import ModalEditPhone from 'components/Global/Modal/ModalEditPhone';

import { getFieldPropertyValues } from './helpers';
import * as FormFieldInputs from './form-field-inputs';
import ValidationMessage from './validation-message';
// DatePicker: 1,
// Time: 2,
// Number: 3,
// ShortTextEntry: 4,
// LongTextEntry: 5,
// Dropdown: 6,
// SingleChoice: 7,
// MultipleChoice: 8,
// Spinner: 9,
// Signature: 10,
// Optional: 11
// Estimate: 12
// Document Upload: 13

const InputTypes = {
  1: (props) => <FormFieldInputs.FormFieldInputDate {...props} />,
  2: (props) => <FormFieldInputs.FormFieldInputTime {...props} />,
  3: (props) => <FormFieldInputs.FormFieldInputText type='number' {...props} />,
  4: (props) => <FormFieldInputs.FormFieldInputText type='text' {...props} />,
  5: (props) => <FormFieldInputs.FormFieldInputTextArea {...props} />,
  6: (props) => <FormFieldInputs.FormFieldInputSelect {...props} />,
  7: (props) => <FormFieldInputs.FormFieldInputRadio {...props} />,
  8: (props) => <FormFieldInputs.FormFieldInputCheckbox {...props} />,
  9: (props) => (
    <FormFieldInputs.FormFieldInputText
      type='number'
      className='spinner'
      {...props}
    />
  ),
  13: (props) => <FormFieldInputs.FormFieldInputFile type='file' {...props} />,
};

const listMatchInput = {
  Dropdown: 6,
  'Short Text Entry': 4,
  'Long Text Entry': 5,
  'Date Picker': 1,
};
export const FormFieldInput = ({ field, options, children, files, setFiles, submitted }) => {
  const {
    properties,
    subfield,
    placeholder,
    sublabel,
    isRenderedField,
    onChange,
    validationMessage,
    readOnly,
    onUpdate,
    value,
    ...rest
  } = options;

  const [openModalEditPhone, setOpenModalEditPhone] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  useEffect(() => {
    if (field.value) {
      setPhoneNumbers([...field.value]);
    }
  }, [field.value]);

  // detect basic field by key form_field_type_id and match exactly InputTypes by key type_name
  const idMapField = field.form_field_type_id
    ? listMatchInput[field.type_name]
    : field.sort_order;

  const renderInput = (field, props) => {
    let fieldProps = { ...props };
    if (
      ['Contact Phone Number', 'Co-Owner Phone Number'].includes(
        field.display_name
      ) &&
      onUpdate
    ) {
      fieldProps.onClick = handleClick;
      fieldProps.onChange = () => {};
    }
    if (field.type_name == 'Document Upload') {
      fieldProps.files = files;
      fieldProps.setFiles = setFiles;
      fieldProps.submitted = submitted;
    }
    return InputTypes[idMapField](fieldProps);
  };

  const handleClick = () => {
    setOpenModalEditPhone(true);
  };

  const handleSubmitEditPhone = (values) => {
    setPhoneNumbers(values.phoneNumbers);
    onUpdate(field.uuid, values.phoneNumbers);
    setOpenModalEditPhone(false);
  };

  const propertyNames = [
    'label',
    'makeRequired',
    'makeHidden',
    'hideLabel',
    'readOnly',
    'sublabel',
    'placeholder',
  ];
  const defaultValues = {
    label: field.display_name,
    makeRequired: false,
    makeHidden: false,
    hideLabel: false,
    readOnly: false,
    placeholder: '',
  };
  const commonPropValues = getFieldPropertyValues(
    properties.common || properties,
    propertyNames,
    defaultValues
  );
  const containerClasses = classNames({
    'form-field-input-container': true,
    'hide-field': commonPropValues.makeHidden,
    'hide-label': commonPropValues.hideLabel,
    'read-only': commonPropValues.readOnly || readOnly,
  });
  const inputClasses = classNames({
    'form-field-input': true,
    text: field.type_name === 'Short Text Entry',
    textarea: field.type_name === 'Long Text Entry',
    select: field.type_name === 'Dropdown',
    'form-field-file-container': field.type_name === 'Document Upload',
  });
  const validationClasses = classNames({
    'control-group error': validationMessage,
  });
  const id = field.uuid;
  return (
    <div key={id} className={containerClasses}>
      {!subfield ? (
        <div className='form-field-label primary'>{`${commonPropValues.label} ${
          commonPropValues.makeRequired ? '*' : ''
        }`}</div>
      ) : null}
      <div className={inputClasses}>
        <div className={validationClasses}>
          {renderInput(field, {
            id,
            placeholder: placeholder || commonPropValues.placeholder,
            isRenderedField,
            onChange,
            children,
            value,
            disabled: readOnly,
            displayName: field.display_name,
            ...rest,
          })}
          {idMapField === 6 && (
            <FormFieldInputs.FormFieldInputText
              type='text'
              className='span30 replace-select'
              value={field.label || value}
            />
          )}
          {sublabel || commonPropValues.sublabel ? (
            <div className='form-field-label secondary'>
              {sublabel || commonPropValues.sublabel}
            </div>
          ) : null}
          <ValidationMessage message={validationMessage} />
        </div>
      </div>

      {openModalEditPhone && (
        <ModalEditPhone
          isOpenModal={openModalEditPhone}
          setIsOpenModal={setOpenModalEditPhone}
          handleUpdatePhone={handleSubmitEditPhone}
          phoneNumbers={phoneNumbers}
          isInFormBuilder={true}
        />
      )}
    </div>
  );
};

export default FormFieldInput;

FormFieldInput.defaultProps = {
  options: {},
  children: null,
  readOnly: false,
};
