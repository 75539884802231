import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';

const RetrievingNote = styled(Card)`
  padding-top: 35px;
  background-color: grey;
  height: 60px;
  margin-bottom: 10px;
`;

const StyledSnackbar = styled(Snackbar)`
  background-color: none;
`;

const MainUL = styled.ul`
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding: 8px;
  overflow-y: auto;
`;

const MainDiv = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-height: 90vh;
  overflow-y: scroll;
`;

export { RetrievingNote, StyledSnackbar, MainUL, MainDiv };
