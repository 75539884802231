import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const MediumText = styled(Typography)`
  font-size: ${sizeConstants.medium.size};
  line-height: ${sizeConstants.medium.height};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: ${getThemeProp('pallette.text.secondary', '#6E84A3')};
` as typeof Typography;

export default MediumText;
