import { IconName, IconPrefix } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { setTabActiveLocalStorage } from 'global/localStorage/tabActive';
import { UserInformationType } from 'global/requests/RequestTypes';
import {
  FullDataTableCustomType,
  keyColumnFields,
  Group,
  OptionFilterDropdownObject,
} from 'global/requests/ResponseTypes';
import React, { useEffect } from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import parse from 'html-react-parser';
import { saveAs } from 'file-saver';
import StatusSelect from '../StatusSelect/StatusSelect';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import toast from 'components/Global/Toast';
import moment from 'moment';
import WarningPopup from '../WarningPopup/WarningPopup';

interface Column {
  id: keyColumnFields;
  label: string;
  format?: (value: number) => string;
}

interface CommunicationTableProps {
  columns: Column[];
  dataTable: FullDataTableCustomType[];
  isSort?: boolean;
  recordsPerPage?: number;
  isDisplayedSearchAndFilter?: boolean;
  isDisplayedSearch?: boolean;
  isDisplayedFilter?: boolean;
  isDisplayedButton?: boolean;
  keySearch?: string;
  optionsForFilter?: OptionFilterDropdownObject[];
  placeholderSearch?: string;
  disabledFilter?: boolean;
  iconButton?: [IconPrefix, IconName];
  buttonName?: string;
  setDisplaySettingUserModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInformationData?: React.Dispatch<
    React.SetStateAction<UserInformationType>
  >;
  isLoading?: boolean;
  screen?: string;
  router?: string;
  category?: string;
  routerCreation?: string;
  isDisablePagination?: boolean;
  keyParamRouter?: string;
  keySortTerm?: string;
  keySort?: string;
  fullData?: Group[];
  openModal?: (idValue: string) => void;
  reloadData?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
      position: 'absolute',
      right: '-600px',
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
        ) : (
          <FontAwesomeIcon icon={['far', 'chevron-double-left']} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-right']} />
        ) : (
          <FontAwesomeIcon icon={['far', 'chevron-left']} />
        )}
      </IconButton>
      <span className='selected-page'>{page + 1}</span>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-left']} />
        ) : (
          <FontAwesomeIcon icon={['far', 'chevron-right']} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-double-left']} />
        ) : (
          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
        )}
      </IconButton>
    </div>
  );
}

const CommunicationTable = (props: CommunicationTableProps): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const {
    columns,
    isSort,
    recordsPerPage,
    dataTable,
    placeholderSearch,
    disabledFilter,
    isDisplayedSearchAndFilter,
    isDisplayedFilter,
    isDisplayedSearch,
    isDisplayedButton,
    iconButton,
    buttonName,
    isLoading,
    isDisablePagination,
    keySortTerm,
    keySort,
    fullData,
    openModal,
  } = props;
  const optionsForFilter = props.optionsForFilter
    ? props.optionsForFilter
    : [{ label: 'All', value: '' }];
  const setDisplaySettingUserModal = props.setDisplaySettingUserModal
    ? props.setDisplaySettingUserModal
    : () => {};
  // const setUserInformationData = props.setUserInformationData ? props.setUserInformationData : () => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortTerm, setSortTerm] = React.useState(keySort ? keySort : 'name');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderTerm, setOrderTerm] = React.useState(
    keySortTerm ? keySortTerm : 'asc'
  );
  const [dataRender, setDataRender] = React.useState([...dataTable]);
  const recordsPerPageOption = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const handleChangeTerm = (event: string, orderTerm: string) => {
    setFlagSorted(event);
    setOrderTerm(orderTerm);
    setSortTerm(event);
  };
  const [checkSorted, setFlagSorted] = React.useState('');

  const [searchIsInFocus, setSearchIsInFocus] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    recordsPerPage ? recordsPerPage : 10
  );
  const [tableData, setTableData] =
    React.useState<FullDataTableCustomType[]>(dataTable);

  const [openWarningForEmail, setOpenWarningForEmail] = React.useState({open: false, rowId: null});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage: number = parseInt(event.target.value);
    if (newRowsPerPage) {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    }
  };

  const toggleOpenEmailWarning = (isOpen: boolean) => {
    setOpenWarningForEmail(prevState => ({ ...prevState, open: isOpen }));
  };

  const sendDealEmail = async(id: string) => {
    setOpenWarningForEmail({open:false, rowId: null});
    try{
      const sent = await configuredRequests.POST.sendEmailForDeal(id,{})
      if(sent){
        toast.success("Sent successfully");
        if(props.reloadData){
          props.reloadData();
        }
      }
    }catch(err){
      toast.error("Could not send email.")
    }
  }

  const linkRouter = (
    id: string,
    automationTriggerKey: string,
    automationCategoryId: string,
    link: string
  ) => {
    if (props.router) {
      switch (props.screen) {
        case 'USER':
        case 'PERFORMANCE':
        case 'SMS':
        case 'EMAIL':
        case 'CALL':
        case 'POSTAL':
        case 'GROUPS':
        case 'PRACTICES':
        case 'AGREEMENTS':
          return `${props.router}/${id}`;
        case 'SYNCER':
          return `Practices/${id}/Syncer`;
        case 'AUTOMATION':
          return `${props.router}/${props.category}/${automationTriggerKey}/${automationCategoryId}/${id}`;
        case 'TOP_CLICKED_LINK':
        case 'DRIVERMODAL':
        case 'SERVICE':
        case 'CONFIGURATOR':
          return `${link}`;
        default:
          return props.router;
      }
    }
    return '';
  };

  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [valueFilterSelected, setValueFilter] =
    React.useState<OptionFilterDropdownObject>(optionsForFilter[0]);

  const selectFilterValue = (newSelectedItem: OptionFilterDropdownObject) => {
    setValueFilter(newSelectedItem);
    setIsDropdownOpened(false);
  };

  const [valueSearchInput, setValueSearchInput] = React.useState<string>('');

  const handleSearchData = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const userInput = event.currentTarget.value;
    setValueSearchInput(userInput);
  };

  const handleDownload = (e: any, url: string) => {
    e.preventDefault();
    const idx = url.lastIndexOf('/');
    const fileName = `${url.slice(idx + 1)}.zip`;
    saveAs(url, fileName);
  };

  useEffect(() => {
    let dataSearchAndFilter = dataTable;
    if (props.keySearch && valueSearchInput) {
      dataSearchAndFilter = dataSearchAndFilter?.filter((data) => {
        if (data['templateName']) {
          return data?.templateName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
        if (data['name']) {
          if (!data?.name?.length) {
            // need to display something if there's no name
            // without it the doctor can't get to Edit to add a name
            return 'missing contact name'.includes(
              valueSearchInput.toLocaleLowerCase()
            );
          }
          return data?.name
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
        if (data['userFullName']) {
          return data?.userFullName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['contactName']) {
          return data?.contactName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
        if (data['productName']) {
          return data?.productName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
        if (data['roleName']) {
          return data?.roleName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
        if (data['groupName']) {
          return data?.groupName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['practiceName']) {
          return data?.practiceName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['agreementName']) {
          return data?.agreementName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['driverName']) {
          return data?.driverName
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['serviceVersion']) {
          return data?.serviceVersion
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }

        if (data['configuratorVersion']) {
          return data?.configuratorVersion
            ?.toLocaleLowerCase()
            .includes(valueSearchInput.toLocaleLowerCase());
        }
      });
      setPage(0);
      setTableData(dataSearchAndFilter);
    } else {
      setPage(0);
      setTableData(dataTable);
    }

    if (valueFilterSelected.value) {
      dataSearchAndFilter =
        valueFilterSelected.value !== ''
          ? dataSearchAndFilter.filter((data) => {
              if (data['automationLabel']) {
                return data.automationLabel === valueFilterSelected.value;
              }
              if (data['emailStatus']) {
                return data.emailStatus === valueFilterSelected.value;
              }
              if (data['pulseUserStatus']) {
                return data.pulseUserStatus === valueFilterSelected.value;
              }
              if (data['roleStatus']) {
                return data.roleStatus === valueFilterSelected.value;
              }
              if (data['status']) {
                return data.status === valueFilterSelected.value;
              }
              if (data['isPackageProduct'] !== undefined) {
                return (
                  data.isPackageProduct.toString() === valueFilterSelected.value
                );
              }
              return false;
            })
          : dataTable;
      setPage(0);

      return setTableData(dataSearchAndFilter);
    }
  }, [dataTable, valueSearchInput, props.keySearch, valueFilterSelected]);

  useEffect(() => {
    const resultSort = isSort
      ? orderBy(
          tableData,
          [
            (item) =>
              //@ts-ignore
              item[sortTerm]
                ? // @ts-ignore
                  item[sortTerm].toString().toLocaleLowerCase()
                : [],
          ],
          [orderTerm === 'asc' ? 'asc' : 'desc']
        )
      : orderBy(
          tableData,
          [
            (item) =>
              //@ts-ignore
              item[sortTerm]
                ? //@ts-ignore
                  item[sortTerm].toString().toLocaleLowerCase()
                : [],
          ],
          ['asc']
        );
    setDataRender(resultSort);
  }, [tableData, sortTerm, orderTerm, isSort]);

  const CELL_TYPE: { type: string; columns: string[] }[] = [
    {
      type: 'DELETE',
      columns: ['delete'],
    },
    {
      type: 'HTML',
      columns: ['releasedNote'],
    },
    {
      type: 'LINK',
      columns: [
        'name',
        'templateName',
        'automationTrigger',
        'userFullName',
        'contactName',
        'groupName',
        'practiceName',
        'viewDetails',
      ],
    },
    {
      type: 'A_TAG',
      columns: ['link'],
    },
    {
      type: 'A_TAG_DOWNLOAD',
      columns: ['actionDownload'],
    },
    {
      type: 'A_LINK_DOWNLOAD',
      columns: ['downloadFile'],
    },
    {
      type: 'TEXT',
      columns: [
        'externalId',
        'sent',
        'email',
        'requestDate',
        'phoneNumber',
        'userFullName',
        'emailAddress',
        'phoneNumbers',
        'name',
        'value',
        'date',
        'emailStatus',
        'createdDate',
        'userRoles',
        'time',
        'level',
        'logger',
        'message',
        'lastAuth',
        'lastSync',
        'livePractice',
        'deliveryPaused',
        'version',
        'releasedDate',
        'driver',
        'invoiceName',
        'defaultMonthlyFee',
        'agreementPracticeName',
        'agreementDetailVersion',
        'agreementDetailTotal',
        'agreementDetailAccount',
        'agreementDetailStatus',
        'viewDetails',
        'stripePaymentLink',
        'isSignedForDeal'
      ],
    },
    {
      type: 'ARRAY__TEXT--BORDER',
      columns: ['agreementDetailItems'],
    },
    {
      type: 'OPEN_MODAL',
      columns: [
        'roleName',
        'driver',
        'driverName',
        'serviceVersion',
        'configuratorVersion',
        'productName',
        'agreementName',
      ],
    },
    // {
    //   type: 'STATUS_SELECT',
    //   columns: ['status'],
    // },
    {
      type: 'LABEL',
      columns: ['roleStatus', 'status'],
    },
    {
      type: 'ACTION',
      columns: [
        'action',
        'sendEmailForDeals'
      ],
    },
    {
      type: 'ARRAY_TEXT',
      columns: ['rolePermissions'],
    },
  ];

  const SCREEN_MAPPING_ROW_ID = {
    PRODUCT: 'productId',
    ROLE: 'roleId',
    DRIVER: 'id',
    SERVICE: 'id',
    CONFIGURATOR: 'id',
    AGREEMENTS: 'id',
  };

  const renderTableCell = (
    columnId: string,
    value: any,
    row: any,
    valueSearchInput: string,
    rowIndex: number
  ) => {
    switch (getCellType(columnId)) {
      case 'DELETE':
        return (
          openModal && (
            <button
              className='appointment__task-complete-button btn-delete'
              onClick={() => openModal(row['id'])}
            >
              Delete
            </button>
          )
        );
      case 'ARRAY__TEXT--BORDER':
        return (
          <>
            {value.length > 0 &&
              value.map((item: string) => (
                <span className='text--border'>{item}</span>
              ))}
          </>
        );
      case 'HTML':
        return <>{parse(`${value}`)}</>;
      case 'LINK':
        return (
          <Link
            key={'contact-link-'}
            to={{
              pathname: props.screen
                ? linkRouter(
                    row.id ? row.id : '',
                    row.automationTriggerKey ? row.automationTriggerKey : '',
                    row.automationCategoryId ? row.automationCategoryId : '',
                    row.name ? row.name : ''
                  )
                : '',
              state: {
                fromCommunicationTable: true,
              },
            }}
            className='lnk'
          >
            <HighlightText
              text={
                value
                  ? value.toString()
                  : columnId === 'viewDetails'
                  ? 'View Details'
                  : ''
              }
              searchValue={valueSearchInput}
            />
          </Link>
        );
      case 'A_TAG':
        return (
          <a
            className='lnk'
            key={'contact-link-'}
            target={'_blank'}
            href={row.link}
          >
            <HighlightText
              text={value ? value.toString() : ''}
              searchValue={valueSearchInput}
            />
          </a>
        );

      case 'A_TAG_DOWNLOAD':
        return (
          <a
            className='lnk'
            key={'contact-link-'}
            target={'_blank'}
            href={row.actionDownload}
          >
            Link Download
          </a>
        );
      case 'A_LINK_DOWNLOAD':
        return (
          <a
            className='lnk'
            key={'contact-link-'}
            target={'_blank'}
            href={row.actionDownload}
            onClick={(e: any) => handleDownload(e, row.actionDownload)}
          >
            Link Download
          </a>
        );
      case 'TEXT':
        if (columnId === 'defaultMonthlyFee') {
          return `$${value}`;
        }
        return value;
      case 'OPEN_MODAL':
        let mapping =
          props.screen ||
          'ROLE' ||
          'DRIVER' ||
          'SERVICE' ||
          'CONFIGURATOR' ||
          'AGREEMENTS';
        return (
          openModal && (
            <a
              className='lnk'
              //@ts-ignore
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              onClick={() => openModal(row[SCREEN_MAPPING_ROW_ID[mapping]])}
            >
              <HighlightText
                text={value ? value.toString() : ''}
                searchValue={valueSearchInput}
              />
            </a>
          )
        );
      // case 'STATUS_SELECT':
      //   return (
      //     <StatusSelect
      //       id={row['id']}
      //       currentStatus={value.toLowerCase()}
      //       screen={props.screen ?? ''}
      //       getDataAfterChangeStatus={() => {
      //         props.reloadData && props.reloadData();
      //       }}
      //       fullData={fullData ?? []}
      //     />
      //   );
      case 'LABEL':
        return <span className={`status-${value}`}>{value}</span>;
      case 'ACTION':
        if (columnId == 'sendEmailForDeals'){
          let isWithinWeek = moment().subtract(7, 'days') <= moment(row.emailLastSent);
          if (moment(row.emailLastSent).valueOf() < 0) {
            isWithinWeek = false;
          }
          return (
            <>
            <Button
              variant='contained'
              color='primary'
              className={row.isSignedForDeal === 'Signed' ? 'disabled' : 'btn-add-new-template'}
              style={isWithinWeek && row.isSignedForDeal !== 'Signed' ? { "backgroundColor": "white", "color": "grey", "border": "1px solid grey" } : {}}
              onClick={(e:React.MouseEvent)=>{
                e.preventDefault();
                isWithinWeek ? setOpenWarningForEmail({open:true, rowId: row.id}) : sendDealEmail(row.id);
              }}
              disabled={row.isSignedForDeal === 'Signed' ? true : false}
              >
                Send Email
              </Button>

              <WarningPopup
                openPopup={openWarningForEmail.open && openWarningForEmail.rowId === row.id}
                setOpenPopup={toggleOpenEmailWarning}
                title="Confirmation"
                description={`An email was last sent on ${moment(row.emailLastSent).format('dddd MMM Do')}. Would you like to send another?`}
                handleConfirmPopup={() => {
                  sendDealEmail(row.id);
                }}
              />
            </>
          )
        }
        return (
          <Button
            variant='contained'
            color='primary'
            className={
              row.status === 'Inactive'
                ? 'btn-add-new-template-disabled'
                : 'btn-add-new-template'
            }
            disabled={row.status === 'Inactive'}
            onClick={() => {
              const win = window.open(
                `/AdminLoginPractice/group/${row.groupId}/practice/${row.id}`,
                '_blank'
              );
              win && win.focus();
              setTabActiveLocalStorage('', 0, null);
            }}
          >
            Login to Practice
          </Button>
        );
      case 'ARRAY_TEXT':
        let screenNames = value.map(
          (e: { screenId: string; screenName: string }) => e.screenName
        );
        return (
          <div className='text-overflow'>
            <Tooltip
              title={screenNames.map((name: string, index: number) => (
                <span>
                  {name}
                  <br />
                </span>
              ))}
              placement='right'
              classes={{ tooltip: classes.tooltip }}
            >
              <span>{screenNames.join(', ')}</span>
            </Tooltip>
          </div>
        );
      default:
        return value + '%';
    }
  };

  const getCellType = (columnId: string) => {
    for (let i = 0; i < CELL_TYPE.length; i++) {
      if (CELL_TYPE[i].columns.includes(columnId)) {
        return CELL_TYPE[i].type;
      }
    }
    return 'PERCENT_TEXT';
  };

  const renderDataTable = () => {
    if (isLoading) {
      return [
        <div key='data-table-loading'>
          <p className='loading-table' style={{ fontWeight: 'bold' }}>
            Loading...
          </p>
        </div>,
      ];
    }
    return dataRender.length !== 0 ? (
      <>
        {isDisplayedSearchAndFilter && (
          <div className='search-input d-flex custom-postion'>
            {isDisplayedSearch && (
              <div className='search-input'>
                <form
                  className={
                    (isSafari || isMobileSafari
                      ? 'search-safari'
                      : 'search-form search-container') +
                    (searchIsInFocus ? ' open' : '')
                  }
                  onSubmit={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                  }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'search']}
                    onClick={() => {
                      inputRef.current?.focus();
                    }}
                  />
                  <input
                    className={
                      'search-form__input' + (searchIsInFocus ? ' open' : '')
                    }
                    type='search'
                    placeholder={
                      placeholderSearch
                        ? placeholderSearch
                        : 'Search by Template'
                    }
                    ref={inputRef}
                    onFocus={() => {
                      setSearchIsInFocus(true);
                    }}
                    onBlur={() => {
                      setSearchIsInFocus(false);
                    }}
                    value={valueSearchInput}
                    onChange={handleSearchData}
                  />
                </form>
              </div>
            )}
            {isDisplayedFilter && optionsForFilter && valueFilterSelected && (
              <div className='search-filter__global'>
                <div
                  tabIndex={0}
                  role='button'
                  className={'filter-form'}
                  onClick={() => {
                    setIsDropdownOpened(!isDropdownOpened);
                  }}
                  onBlur={() => {
                    setIsDropdownOpened(false);
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    switch (event.key) {
                      case 'Enter': {
                        setIsDropdownOpened(!isDropdownOpened);
                        break;
                      }
                      case ' ': {
                        setIsDropdownOpened(!isDropdownOpened);
                        break;
                      }
                    }
                  }}
                >
                  {disabledFilter ? (
                    'Disable'
                  ) : (
                    <span> {valueFilterSelected?.label} </span>
                  )}
                  <nav
                    className={'filter-nav' + (isDropdownOpened ? ' open' : '')}
                  >
                    <ul
                      className={
                        'list-select-filter' + (isDropdownOpened ? ' open' : '')
                      }
                    >
                      {optionsForFilter.map((item) => {
                        return (
                          <li
                            key={'item-filter' + item.label}
                            className='select-item-filter'
                            onClick={(event: React.SyntheticEvent) => {
                              event.stopPropagation();
                              selectFilterValue(item);
                            }}
                          >
                            {item.label}
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                  <FontAwesomeIcon
                    className='icon-filter-down'
                    icon='chevron-down'
                    style={{ padding: 2 }}
                  />
                </div>
              </div>
            )}
            {isDisplayedButton && (
              <Button
                variant='contained'
                color='primary'
                className='btn-add-new-template'
                onClick={() => {
                  if (
                    props.screen === 'USER' ||
                    props.screen === 'PRACTICES' ||
                    props.screen === 'GROUPS'
                  ) {
                    setDisplaySettingUserModal(true);
                    return;
                  }
                  if (
                    props.screen === 'ROLE' ||
                    props.screen === 'DRIVER' ||
                    props.screen === 'SERVICE' ||
                    props.screen === 'CONFIGURATOR' ||
                    props.screen === 'PRODUCT' ||
                    props.screen === 'AGREEMENTS'
                  ) {
                    openModal && openModal('');
                    return;
                  }
                  if (
                    props.router &&
                    (props.screen === 'EMAIL' ||
                      props.screen === 'SMS' ||
                      props.screen === 'CALL' ||
                      props.screen === 'POSTAL')
                  ) {
                    history.push(`${props.routerCreation}/${props.category}`);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={iconButton ? iconButton : ['far', 'plus']}
                />
                {buttonName ? buttonName : 'New Template'}
              </Button>
            )}
          </div>
        )}
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className='communication-table__header'
                  >
                    {column.label}
                    {isSort ? (
                      <span className='sort-icon' id={`${column.id}`}>
                        <FontAwesomeIcon
                          className={
                            column.id === checkSorted && orderTerm === 'asc'
                              ? 'sorted-color'
                              : ''
                          }
                          icon={['fas', 'caret-up']}
                          onClick={() => {
                            handleChangeTerm(column.id, 'asc');
                          }}
                        />
                        <FontAwesomeIcon
                          className={
                            column.id === checkSorted && orderTerm === 'desc'
                              ? 'sorted-color'
                              : ''
                          }
                          icon={['fas', 'caret-down']}
                          onClick={() => {
                            handleChangeTerm(column.id, 'desc');
                          }}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRender
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        //row is given the any type in the renderTableCell fn
                        //@ts-ignore
                        const value = row[column.id];
                        // const keyParamRouter = props.keyParamRouter ? props.keyParamRouter : 'id';
                        return (
                          <TableCell key={column.id}>
                            {renderTableCell(
                              column.id,
                              value,
                              row,
                              valueSearchInput,
                              index
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              <TableRow>
                {isDisablePagination ? (
                  <></>
                ) : (
                  <TablePagination
                    rowsPerPageOptions={recordsPerPageOption}
                    count={dataRender.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <>
        {isDisplayedSearchAndFilter && (
          <div className='search-input d-flex custom-postion'>
            {isDisplayedSearch && (
              <div className='search-input'>
                <form
                  className={
                    (isSafari || isMobileSafari
                      ? 'search-safari'
                      : 'search-form search-container') +
                    (searchIsInFocus ? ' open' : '')
                  }
                  onSubmit={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                  }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'search']}
                    onClick={() => {
                      inputRef.current?.focus();
                    }}
                  />
                  <input
                    className={
                      'search-form__input' + (searchIsInFocus ? ' open' : '')
                    }
                    type='search'
                    placeholder={
                      placeholderSearch
                        ? placeholderSearch
                        : 'Search by Template'
                    }
                    ref={inputRef}
                    onFocus={() => {
                      setSearchIsInFocus(true);
                    }}
                    onBlur={() => {
                      setSearchIsInFocus(false);
                    }}
                    onChange={handleSearchData}
                  />
                </form>
              </div>
            )}
            {isDisplayedFilter && optionsForFilter && valueFilterSelected && (
              <div className='search-filter__global'>
                <div
                  tabIndex={0}
                  role='button'
                  className={'filter-form'}
                  onClick={() => {
                    setIsDropdownOpened(!isDropdownOpened);
                  }}
                  onBlur={() => {
                    setIsDropdownOpened(false);
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    switch (event.key) {
                      case 'Enter': {
                        setIsDropdownOpened(!isDropdownOpened);
                        break;
                      }
                      case ' ': {
                        setIsDropdownOpened(!isDropdownOpened);
                        break;
                      }
                    }
                  }}
                >
                  {disabledFilter ? (
                    'Disable'
                  ) : (
                    <span> {valueFilterSelected?.label} </span>
                  )}
                  <nav
                    className={'filter-nav' + (isDropdownOpened ? ' open' : '')}
                  >
                    <ul
                      className={
                        'list-select-filter' + (isDropdownOpened ? ' open' : '')
                      }
                    >
                      {optionsForFilter.map((item) => {
                        return (
                          <li
                            key={'item-filter' + item.label}
                            className='select-item-filter'
                            onClick={(event: React.SyntheticEvent) => {
                              event.stopPropagation();
                              selectFilterValue(item);
                            }}
                          >
                            {item.label}
                            {/* <button
                            className='filter-global--dropdown-button'
                          >
                          </button> */}
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                  <FontAwesomeIcon
                    className='icon-filter-down'
                    icon='chevron-down'
                    style={{ padding: 2 }}
                  />
                </div>
              </div>
            )}
            {isDisplayedButton && (
              <Button
                variant='contained'
                color='primary'
                className='btn-add-new-template'
                onClick={() => {
                  if (
                    props.screen === 'USER' ||
                    props.screen === 'PRACTICES' ||
                    props.screen === 'GROUPS'
                  ) {
                    setDisplaySettingUserModal(true);
                    return;
                  }
                  if (
                    props.screen === 'ROLE' ||
                    props.screen === 'DRIVER' ||
                    props.screen === 'SERVICE' ||
                    props.screen === 'CONFIGURATOR' ||
                    props.screen === 'AGREEMENTS'
                  ) {
                    openModal && openModal('');
                    return;
                  }
                  if (
                    props.router &&
                    (props.screen === 'EMAIL' ||
                      props.screen === 'SMS' ||
                      props.screen === 'CALL' ||
                      props.screen === 'POSTAL')
                  ) {
                    history.push(`${props.routerCreation}/${props.category}`);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={iconButton ? iconButton : ['far', 'plus']}
                />
                {buttonName ? buttonName : 'New Template'}
              </Button>
            )}
          </div>
        )}
        <p className='no-data-results' style={{ fontWeight: 'bold' }}>
          No results found
        </p>
      </>
    );
  };

  return <Paper>{renderDataTable()}</Paper>;
};

export default CommunicationTable;
