import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
  }
  .Mui-checked .MuiSvgIcon-root {
    fill: ${getThemeProp('palette.primary.main', 'blue')};
  }
  .MuiCheckbox-colorPrimary.Mui-disabled {
    svg {
      fill: #6e84a3;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledFormControlLabel };
