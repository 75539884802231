/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Button } from '@material-ui/core';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { apiCallsResponse } from 'global/requests/ResponseTypes';
import moment from 'moment';
import _ from 'lodash';
import { GeneralType } from 'global/requests/ResponseTypes/SyncerSettingGeneral';
import loading_spinner from 'media/images/loading-spinner.svg';

const RpcCalls = (): JSX.Element => {
  const [apiCalls, setApiCalls] = React.useState<apiCallsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [seriesData] = React.useState<any>([]);
  const [noData, setNoData] = React.useState<boolean>(false);
  const [dateFormat, setDateFormat] = React.useState<string>();
  const defaultOption = {
    chart: {
      type: 'spline',
      zoomType: 'x',
      spacingBottom: 25,
      spacingTop: 10,
      spacingLeft: 20,
      spacingRight: 10,
    },
    tooltip: {
      valueDecimals: 2,
      shared: true,
      split: false,
      enabled: true,
    },

    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        lineWidth: 1,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
              radius: 5,
            },
          },
        },
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
      },
    },

    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      floating: true,
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 1000 * 3600,
      min: moment.utc(apiCalls?.startDateTime).valueOf(),
      max: moment.utc(apiCalls?.endDateTime).valueOf(),
      labels: {
        //@ts-ignore
        formatter: function () {
          //@ts-ignore
          return Highcharts.dateFormat('%H:%M %p', this.value);
        },
      },
      crosshair: {
        enabled: true,
      },
    },

    yAxis: {
      opposite: false,
      min: 1,
      labels: {
        format: '{value}',
      },
      title: { text: '#Calls' },
    },
    series: seriesData,
  };
  const [options, setOptions] = useState<any>(defaultOption);
  const [generalSettingData, setGeneralSettinData] = useState<GeneralType>();

  const mapData = (data: apiCallsResponse) => {
    const seriesNameArray: any[] = [];
    const seriesData: any[] = [];
    if (data.apiCalls.length) {
      data.apiCalls.forEach((element) => {
        if (element.apiCallsByTime) {
          if (!seriesNameArray.includes(element.apiCallsByTime[0].api_Route)) {
            seriesNameArray.push(element.apiCallsByTime[0].api_Route);
            seriesData.push({
              name: element.apiCallsByTime[0].api_Route,
              data: [
                {
                  time: element.apiCallsByTime[0].callDateTime,
                  count: element.apiCallsByTime[0].count,
                },
              ],
            });
          } else {
            seriesData.forEach((item) => {
              if (
                item.name === _.get(element, 'apiCallsByTime[0].api_Route', '')
              ) {
                item.data.push({
                  time: _.get(element, 'apiCallsByTime[0].callDateTime', ''),
                  count: _.get(element, 'apiCallsByTime[0].count', ''),
                });
              }
            });
          }
        }
      });
    }

    return seriesData.map((series) => {
      return {
        name: series.name,
        type: 'spline',
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            //@ts-ignore
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              //@ts-ignore
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get('rgba'),
            ],
          ],
        },
        data: series.data.map((item: any) => {
          return [moment.utc(item.time).valueOf(), item.count];
        }),
      };
    });
  };

  const getGeneralSettingSyncer = async () => {
    try {
      const res = await configuredRequests.GET.getGeneralSettingSyncer();
      if (res) {
        setGeneralSettinData(res.settings?.general);
      }
    } catch (err) {}
  };

  const getApiCalls = async () => {
    try {
      const res = await configuredRequests.GET.getApiCalls();
      if (res) {
        setApiCalls(res);
        setDateFormat(moment(res.lastUpdateDT).format('hh:mm:HH A'));
      }
      if (!res.apiCalls.length) {
        setNoData(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    Promise.all([getGeneralSettingSyncer(), getApiCalls()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (apiCalls) {
      const mappedData = mapData(apiCalls);
      setOptions({ ...defaultOption, series: mappedData });
    } else {
    }
  }, [apiCalls]);

  const handleReloadApiCall = async () => {
    setIsLoading(true);
    await getApiCalls();
    setIsLoading(false);
  };

  return isLoading ? (
    <img
      className='loading-general-syncer'
      src={loading_spinner}
      alt={'Loading'}
    />
  ) : (
    <div className='chart-data'>
      <div className='flex-center'>
        <p>
          Syncer API Calls Last 24 Hours / {generalSettingData?.interval}{' '}
          Minutes
        </p>
        <span>Last updated {dateFormat}</span>
      </div>
      {noData ? (
        <b className='d-flex-center'>No Syncer API Calls</b>
      ) : (
        <>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </>
      )}
      <Button color='primary' variant='contained' onClick={handleReloadApiCall}>
        Reload
      </Button>
    </div>
  );
};

export default RpcCalls;
