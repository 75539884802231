import * as React from 'react';

const noMargin = {
  margin: 0,
};

const ValidationMessageItems = ({ messages }) => {
  return (
    <div>
      {messages.map((msg, i) => (
        <p key={i} style={noMargin}>
          <span className='text-error text-danger'>{msg}</span>
        </p>
      ))}
    </div>
  );
};

export default ValidationMessageItems;
