import React from "react";
import CAM_ON_ICON from '../../../images/videoOn.svg';
import CAM_OFF_ICON from '../../../images/videoOff.svg';
import { Tooltip } from "@material-ui/core";

interface CameraControlProps {
  camera: boolean,
  onSwitchAppointmentCamera: () => void
}

const CameraControl = (props: CameraControlProps) => {
  const onSwitchCamera = () => {
    props.onSwitchAppointmentCamera();
  }

  let cameraButton = (props.camera) ? (
    <Tooltip title="Turn off camera">
    <img alt="" className="itemCenter" style={{cursor: "pointer"}} src={CAM_ON_ICON} onClick={onSwitchCamera} />
    </Tooltip>
  ) : (
    <Tooltip title="Turn on camera">
      <img alt="" className="itemCenter" style={{cursor: "pointer"}} src={CAM_OFF_ICON} onClick={onSwitchCamera} />
      </Tooltip>
    )

  return (cameraButton)

}

export default CameraControl;