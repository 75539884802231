/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { NoPermissionRouterProps } from "types/RouterT";

export interface PreviewShareFormProps extends NoPermissionRouterProps { }

const PreviewShareForm = (props: PreviewShareFormProps): JSX.Element => {
  const { formId } = useParams<{ formId: string }>();
  const { origin: baseURL } = window.location;


  useEffect(() => {
    const styleRemove = document.head.getElementsByTagName('style');
    const linkRemove = document.head.getElementsByTagName('link');
    while (styleRemove.length > 0) {
      styleRemove[0].remove();
    }
    while (linkRemove.length > 0) {
      linkRemove[0].remove();
    }
    const link = document.createElement('link');
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    link.href = 'https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css';
    script1.src = `${baseURL}/assets/jquery.js`;
    script1.async = true;
    script2.src = `${baseURL}/assets/forms.js`;
    script2.async = true;
    document.body.appendChild(script2);
    document.body.appendChild(script1);
    document.body.appendChild(link);
    return () => {
      document.body.removeChild(script2);
      document.body.removeChild(script1);
      document.body.removeChild(link);
    }
  }, []);

  return (
    <div className="demo-form-container">
      <div id={formId} className="vethero-form-container"></div>
    </div>
  );
};

export default PreviewShareForm;
