import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const PercentCell = styled(TableCell)`
  max-width: 33px;
  min-width: 26px;
`;

// eslint-disable-next-line import/prefer-default-export
export { PercentCell };
