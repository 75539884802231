import React from 'react';
import styles from './styles';
import { Title, Description } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppointmentBookingPlaceholder = () => {
  return (
    <div style={styles.container}>
      <FontAwesomeIcon
          icon={['fas', 'calendar-alt']}
       />
      <p style={styles.title}>{Title}</p>
      <p style={styles.description}>{Description}</p>
    </div>
  );
}

export default AppointmentBookingPlaceholder;
