export enum EmailStatusType {
  bounce = 'bounce',
  click = 'click',
  delay = 'delay',
  delivery = 'delivery',
  generation_failure = 'generation_failure', // eslint-disable-line
  generation_rejection = 'generation_rejection', // eslint-disable-line
  list_unsubscribe = 'list_unsubscribe', // eslint-disable-line
  open = 'open',
  policy_rejection = 'policy_rejection', // eslint-disable-line
  spam_complaint = 'spam_complaint', // eslint-disable-line
  pending = 'pending',
  canceled = 'canceled',
  sent = 'sent',
  failed = 'failed',
}

type EmailStatusMap = { [key in EmailStatusType]: string };

export const getStatusColors = ({
  red,
  yellow,
  green,
}: {
  red: string;
  yellow: string;
  green: string;
}): EmailStatusMap => ({
  [EmailStatusType.bounce]: red,
  [EmailStatusType.click]: green,
  [EmailStatusType.delay]: yellow,
  [EmailStatusType.delivery]: green,
  [EmailStatusType.generation_failure]: red,
  [EmailStatusType.generation_rejection]: red,
  [EmailStatusType.list_unsubscribe]: red,
  [EmailStatusType.open]: green,
  [EmailStatusType.policy_rejection]: red,
  [EmailStatusType.spam_complaint]: red,
  [EmailStatusType.pending]: yellow,
  [EmailStatusType.canceled]: red,
  [EmailStatusType.sent]: green,
  [EmailStatusType.failed]: red,
});
