import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { getThemeProp } from 'components/Legwork/utils';

const StyledButton = styled(Button)`
  padding: 0 14px;
  border: none;
  border-radius: 6px;
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
  color: white;
  height: 36px;
  background-color: ${getThemeProp('palette.error.dark', 'red')};
  &:hover {
    background-color: ${getThemeProp('palette.error.main', 'red')};
  }
  &.Mui-disabled {
    background-color: ${getThemeProp('palette.error.light', 'red')};
  }
  svg {
    margin-right: ${({ theme }): string => theme.spacing(0.5)}px;
    height: 100%;
    width: 1rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledButton };
