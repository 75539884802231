import React from 'react';
import { useState, useRef } from 'react';
import { SecondaryButton, InputTextarea } from 'components/Legwork';
import {
  StyledDescription,
  StyledInputContainer,
  StyledInputField,
  StyledInputAndDescriptionContainer,
  StyledInputDescription,
  StyledInvalidInputWarning,
  StyledButtonAndWarningContainer
} from './style';

interface Props {
  externalLink: string;
  buttonText: string;
  description: string;
  title?: string;
  isError?: boolean;
  setLoading?: (param: boolean) => void;
  editExternalLink?: (payload: {
    buttonText: string;
    externalUrl: string;
    isEnable: boolean;
  }) => void;
}

const ExternalLink = ({
  externalLink,
  buttonText,
  isError = false,
  title = 'External Link',
  description,
  editExternalLink,
  setLoading,
}: Props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const externalLinkRef = useRef<HTMLInputElement>(null);
  const buttonTextRef = useRef<HTMLInputElement>(null);

  // // Get external link & button text inputted
  // const externalLinkInput = typeof externalLinkRef.current?.value == 'string' ? externalLinkRef.current?.value : "" ;
  // const buttonTextInput = typeof buttonTextRef.current?.value == 'string' ? buttonTextRef.current?.value : "" ;

  const handleEditButtonOnClick = () => {
    setIsEditable(true);
  };
  const handleSaveButtonOnClick = async () => {
    // Get external link & button text inputted
    const externalLinkInput =
      typeof externalLinkRef.current?.value == 'string'
        ? externalLinkRef.current?.value
        : '';
    const buttonTextInput =
      typeof buttonTextRef.current?.value == 'string'
        ? buttonTextRef.current?.value
        : '';

    //Validate input
    if (buttonTextInput == '' || externalLinkInput == '') {
      setIsInputValid(true);
      return;
    } else {
      setIsInputValid(false);
      editExternalLink?.({
        buttonText: buttonTextInput,
        externalUrl: externalLinkInput,
        isEnable: true,
      });
      setLoading?.(true);
    }
  };
  return (
    <div className='row'>
      <div className='control-group' style={{ marginBottom: '50px' }}>
        <label htmlFor='shared_link' className='control-label share-title'>
          {title}
        </label>
        <div>
          <StyledDescription>{description}</StyledDescription>
          <StyledInputContainer>
            <StyledInputAndDescriptionContainer>
              <StyledInputField
                id='external-link-input'
                placeholder='External Link'
                disabled={isEditable ? false : true}
                ref={externalLinkRef}
                title='Click Edit to edit'
                required
              />
              <StyledInputDescription>{externalLink}</StyledInputDescription>
            </StyledInputAndDescriptionContainer>

            <StyledInputAndDescriptionContainer>
              <StyledInputField
                id='external-link-button-text'
                placeholder='Button Text'
                disabled={isEditable ? false : true}
                ref={buttonTextRef}
                title='Click Edit to edit'
                required
              />
              <StyledInputDescription>{buttonText}</StyledInputDescription>
            </StyledInputAndDescriptionContainer>
          </StyledInputContainer>
        </div>
        <StyledButtonAndWarningContainer>
        {isEditable ? (
          <SecondaryButton
            title='Save'
            style={{
              marginTop: '15px',
              backgroundColor: 'blue',
              color: 'white',
              cursor: 'poiter',
            }}
            onClick={handleSaveButtonOnClick}
          />
        ) : (
          <SecondaryButton
            title='Edit'
            style={{
              marginTop: '15px',
              backgroundColor: 'white',
              color: 'black',
              cursor: 'poiter',
            }}
            onClick={handleEditButtonOnClick}
          />
        )}
        {isInputValid?(<StyledInvalidInputWarning>Input is invalid</StyledInvalidInputWarning>):null}
        </StyledButtonAndWarningContainer>
      </div>
    </div>
  );
};

export default ExternalLink;
