/* eslint-disable no-useless-escape */
import validator from 'validator';
import moment from 'moment';
import { getFieldPropertyValues } from './common_utils';

export const Messages = {
  VALIDATION_ERRORS: '{{count}} validation errors found. Please fix the errors before submitting.',
  CANT_BE_BLANK: "can't be blank",
  ONE_CHECKED: 'must have at least one option checked',
  INVALID_EMAIL: 'is not a valid email',
  INVALID_DATE: 'must be a valid date',
  INVALID_FORMAT: 'is not in the valid format',
  INVALID_FORMAT_WITH_EXAMPLE: 'is not in the valid format. The expected format is {{format}}.',
  DATE_TOO_EARLY: 'must not be before {{date}}',
  CHARS_TOO_LONG: 'is too long (maximum is {{count}} characters)',
  WORDS_TOO_LONG: 'is too long (maximum is {{count}} words)',
  NOT_IN_LIST: 'value "{{value}}" is not included in the list',
  DATE_NOT_FUTURE: 'must be in the future',
  DATE_NOT_PAST: 'must be in the past',
  NOT_A_NUMBER: 'is not a number',
  NUMBER_NOT_IN_RANGE: 'must be greater than or equal to {{min}} and less than or equal to {{max}}',
  NOT_DIVISIBLE_BY: 'must be an increment of {{step}}',
  NOT_NEGATIVE: 'must be greater than or equal to 0',
};

const YearsBack = 100;

export const EmailAddress = [
  { fn: str => ({ valid: validator.isEmail(str), message: Messages.INVALID_EMAIL }) },
];
export const Birthdate = [
  { fn: (str, options) => str && checkValidDateFormat(str, options) },
  {
    fn: str => ({
      valid: str && validator.isISO8601(safeDateStr(str)),
      message: Messages.INVALID_DATE,
    }),
  },
  { fn: str => str && checkDateIsPast(safeDateStr(str)) },
  { fn: (str, options) => str && checkYearNotOlderThan(str, options) },
];
export const SSN = [
  {
    fn: str => ({
      valid: validator.matches(str, /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/g),
      message: Messages.INVALID_FORMAT,
    }),
  },
];
export const DatePicker = [
  { fn: (str, options) => str && checkValidDateFormat(str, options) },
  {
    fn: str => ({
      valid: str && validator.isISO8601(safeDateStr(str)),
      message: Messages.INVALID_DATE,
    }),
  },
  { fn: (str, options) => checkPastFuture(safeDateStr(str), options) },
  { fn: (str, options) => str && checkYearNotOlderThan(str, options) },
];
export const ShortTextEntry = [{ fn: (str, options) => checkMaxChars(str, options) }];
export const LongTextEntry = [{ fn: (str, options) => checkMaxWords(str, options) }];
export const Dropdown = [{ fn: (str, options) => checkIsIn(str, options) }];
export const Number = [
  { fn: str => ({ valid: validator.isNumeric(str), message: Messages.NOT_A_NUMBER }) },
  { fn: (str, options) => checkNumberInRange(str, options) },
];
export const Spinner = [
  { fn: str => ({ valid: validator.isNumeric(str), message: Messages.NOT_A_NUMBER }) },
  { fn: (str, options) => checkIsDivisible(str, options) },
  { fn: (str, options) => checkNonNegative(str, options) },
];

const checkMaxChars = (str, options) => {
  const { properties } = options;
  const { limit, max } = getFieldPropertyValues(properties.specific, ['limit', 'max']);
  let valid = true;
  let message;

  // return true (valid) if character limit not set
  if (!limit) return { valid };

  valid = validator.isLength(str, { max });

  if (!valid) message = Messages.CHARS_TOO_LONG.replace('{{count}}', max);

  return { valid, message };
};

const checkMaxWords = (str, options) => {
  const { properties } = options;
  const { limit, max } = getFieldPropertyValues(properties.specific, ['limit', 'max']);
  let valid = true;
  let message;

  // return true (valid) if character limit not set
  if (!limit) return { valid };

  valid = str.split(' ').length <= max;

  if (!valid) message = Messages.WORDS_TOO_LONG.replace('{{count}}', max);

  return { valid, message };
};

const checkIsIn = (str, options) => {
  const { properties } = options;
  let { options: list } = getFieldPropertyValues(properties.specific, ['options']);
  let valid = true;
  let message;

  // Dropdown list is array of strings
  // Gender list is a 2D array where the 2nd element is an object w/ a `.value` attribute
  list = list.map(item => {
    if (Array.isArray(item) && item[1].hasOwnProperty('value')) return item[1].value;
    return item;
  });

  valid = validator.isIn(str, list);

  if (!valid) message = Messages.NOT_IN_LIST.replace('{{value}}', str);

  return { valid, message };
};

const checkDateIsPast = str => {
  let valid = true;
  const today = new Date().toISOString();

  // make sure date value is converted to an ISO string before validation
  if (typeof str === 'object' && str instanceof Date) str = str.toISOString();

  valid = moment(str).isBefore(today);

  return { valid, message: Messages.DATE_NOT_PAST };
};

const checkPastFuture = (str, options) => {
  const { properties } = options;
  let valid = true;
  const { pastFuture, includeTime } = getFieldPropertyValues(properties.specific, [
    'pastFuture',
    'includeTime',
  ]);
  const past = new Map(pastFuture).get('Past');
  const future = new Map(pastFuture).get('Future');
  const message = [];

  // pass this validation if date value is blank
  // it will be invalidated elsewhere if appropriate
  if (!str || str === '' || !(str instanceof Date)) return { valid };

  let today = new Date();

  // if date picker doesn't include time, then normalize
  // source date and today's date to common time so we're only comparing date
  if (!includeTime) {
    today = normalizeDateTime(today);
    str = normalizeDateTime(str);
  }

  // convert dates to ISO strings before comparing
  today = today.toISOString();
  str = str.toISOString();

  // past dates are not allowed
  if (!past && validator.isBefore(str, today)) message.push(Messages.DATE_NOT_FUTURE);

  // future dates are not allowed
  if (!future && validator.isAfter(str, today)) message.push(Messages.DATE_NOT_PAST);

  valid = message.length === 0;

  return { valid, message };
};

const normalizeDateTime = date => new Date(date.toISOString().split('T')[0]);

const checkNumberInRange = (str, options) => {
  const { properties } = options;
  const { limit, min, max } = getFieldPropertyValues(properties.specific, ['limit', 'min', 'max']);
  let valid = true;
  let message;

  // return true (valid) if number limit not set
  if (!limit) return { valid };

  valid = validator.isFloat(str, { min, max });

  if (!valid)
    message = Messages.NUMBER_NOT_IN_RANGE.replace('{{min}}', min).replace('{{max}}', max);

  return { valid, message };
};

const checkIsDivisible = (str, options) => {
  const { properties } = options;
  const { step } = getFieldPropertyValues(properties.specific, ['step']);
  let valid = true;
  let message;

  valid = validator.isDivisibleBy(str, step);

  if (!valid) message = Messages.NOT_DIVISIBLE_BY.replace('{{step}}', step);

  return { valid, message };
};

const checkNonNegative = (str, options) => {
  const { properties } = options;
  const { negativeValues } = getFieldPropertyValues(properties.specific, ['negativeValues']);
  let valid = true;
  let message;

  if (!negativeValues) valid = parseFloat(str) >= 0;

  if (!valid) message = Messages.NOT_NEGATIVE;

  return { valid, message };
};

const safeDateStr = value =>
  typeof value === 'object' && value instanceof Date ? value.toISOString() : value;

export const checkValidDateFormat = (str, options) => {
  const dateFormat =
    options.dateFormat ||
    getFieldPropertyValues(options.properties.specific, ['dateFormat']).dateFormat;
  let valid = true;
  let message;

  if (!str || str === '' || (str instanceof Date && !isNaN(str))) return { valid };

  const isDate = str instanceof Date;
  const dateTest = isDate ? moment(str).format(dateFormat) : str;
  const delimiters = ['/', '-', '.'];
  const delimiter = `${delimiters.filter(char => dateFormat.indexOf(char) >= 0)[0]}`;
  const formatParts = dateFormat.split(delimiter);
  const regexParts = formatParts.map(part => `(\\d{${part.length}})`);
  const regexStr = `^${regexParts.join(`\\${delimiter}`)}$`;
  const re = new RegExp(regexStr);

  valid = re.test(dateTest);

  if (!valid) message = Messages.INVALID_FORMAT_WITH_EXAMPLE.replace('{{format}}', dateFormat);

  return { valid, message };
};

const checkYearNotOlderThan = (str, options) => {
  const { dateFormat } = getFieldPropertyValues(options.properties.specific, ['dateFormat']);
  let valid = true;
  let message;
  const earliestDate = moment()
    .subtract(YearsBack + 1, 'y')
    .endOf('year');

  valid = moment(str).isAfter(earliestDate, 'y');

  if (!valid)
    message = Messages.DATE_TOO_EARLY.replace(
      '{{date}}',
      moment()
        .subtract(YearsBack, 'y')
        .startOf('year')
        .format(dateFormat),
    );

  return { valid, message };
};
