const textSize = {
  miniature: { size: '0.68rem', height: '0.9rem' },
  tiny: { size: '0.7rem', height: '1rem' },
  small: { size: '0.8125rem', height: '1.1rem' },
  medium: { size: '0.9375rem', height: '1.2rem' },
  large: { size: '1.3rem', height: '1.3rem' },
  xxlarge: { size: '2.1rem', height: '2.3rem' },
};

export default textSize;
