/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useRouter } from 'hooks/useRouter';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { AttachmentData } from 'global/requests/ResponseTypes';

const sortListSSbyDate = (data: AttachmentData[]) => {
  return data.sort((a: AttachmentData, b: AttachmentData) =>
    Date.parse(a.creationDate) > Date.parse(b.creationDate)
      ? 1
      : Date.parse(b.creationDate) > Date.parse(a.creationDate)
        ? -1
        : 0
  );
};

const PopupShowImage = () => {
  const router = useRouter();
  const [photoIndex, setPhotoIndex] = useState<number>(() => {
    return parseInt(localStorage.getItem('indexOfOpenedImg') || '0');
  });
  const [listImageUrl, setlistImageUrl] = useState<string[]>([]);
  const [listImageTitle, setlistImageTitle] = useState<string[]>([]);

  const getTelemedAppointmentAttachments = async (appointmentID: string) => {
    try {
      const res = await configuredRequests.GET.currentTenantAppointmentAttachments(
        appointmentID
      );
      const sortedListImage = sortListSSbyDate(res.attachments);
      const listImage = sortedListImage.map((items: AttachmentData) => items.url);
      const titleOfImage = sortedListImage.map((items: AttachmentData) => items.name);
      setlistImageUrl(listImage);
      setlistImageTitle(titleOfImage);
    } catch (err) {
    }
  };

  useEffect(() => {
    //@ts-ignore
    getTelemedAppointmentAttachments(router.query.appointmentID);
  }, []);

  const customStyles = {
    content: {
      width: '100%',
    },
  };

  return (
    <Lightbox
      reactModalStyle={customStyles}
      imageTitle={listImageTitle[photoIndex]}
      mainSrc={listImageUrl[photoIndex]}
      nextSrc={listImageUrl[(photoIndex + 1) % listImageUrl.length]}
      prevSrc={listImageUrl[(photoIndex + listImageUrl.length - 1) % listImageUrl.length]}
      onCloseRequest={() => { }}
      onMovePrevRequest={() => setPhotoIndex((photoIndex + listImageUrl.length - 1) % listImageUrl.length)}
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listImageUrl.length)}
      clickOutsideToClose={false}
    />
  );
};

export default PopupShowImage;
