import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, orderBy } from 'lodash';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import loading_spinner from 'media/images/loading-spinner.svg';
import { Column } from 'global/requests/ResponseTypes';
import ProductModal from './ProductModal';
import Table from 'components/Global/Table';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import { Button } from '@material-ui/core';

import {
  ProductCollectionType,
  ProductResponseType,
} from 'global/requests/ResponseTypes/Product';
import { IQuery } from 'components/SyncerErrors/SyncerError';

import './ProductSetting.scss';

const columns: Column[] = [
  { id: 'productName', label: 'Product Name' },
  { id: 'invoiceName', label: 'Invoice Name' },
  { id: 'defaultMonthlyFee', label: 'Monthly Fee' },
  // {id: 'setupFee', label: 'Setup Fee'},
  // {id: 'type', label: 'Type'}
];

// const columns = [
//   { key: 'productName', title: 'ProductName' },
//   { key: 'invoiceName', title: 'Invoice Name' },
//   { key: 'monthlyFee', title: 'Monthly Fee' },
// ];

// const mock: ProductCollectionType = {
//   count: 0,
//   totalAvailable: 100,
//   products: [
//     {
//       productId: '1',
//       productType: 'package',
//       productName: 'Package Bar',
//       invoiceName: 'Package Bar',
//       monthlyFee: '320',
//       isMonthlyFee: false,
//       isSetupFee: false,
//       setupFee: '',
//       monthlyFeeMin: '',
//       monthlyFeeMax: '',
//       monthlyFeeNegotiable: false,
//       taxCode: '',
//     },
//     {
//       productId: '2',
//       productType: 'package',
//       productName: 'Package Bar 1',
//       invoiceName: 'Package Bar 1',
//       monthlyFee: '$320',
//       isMonthlyFee: false,
//       isSetupFee: false,
//       setupFee: '',
//       monthlyFeeMin: '',
//       monthlyFeeMax: '',
//       monthlyFeeNegotiable: false,
//       taxCode: '',
//     },
//     {
//       productId: '3',
//       productType: 'add-ons',
//       productName: 'Add on Bar',
//       invoiceName: 'Add on Bar',
//       monthlyFee: '$20',
//       isMonthlyFee: false,
//       isSetupFee: false,
//       setupFee: '',
//       monthlyFeeMin: '',
//       monthlyFeeMax: '',
//       monthlyFeeNegotiable: false,
//       taxCode: '',
//     },
//   ],
// };

const optionsFilter = [
  { label: 'All Products', value: '' },
  { label: 'Packages', value: 'true' },
  { label: 'Add-ons', value: 'false' },
];

const initialProduct: ProductResponseType = {
  productId: '',
  productName: '',
  isPackageProduct: true,
  invoiceName: '',
};

const url =
  'https://portal.staging.fetchprm.dev/ext-resources/379511be-7ea5-440b-847a-b6b71c733249/public/2ae6774d5fee42f19619776ae2e48aa6';

const ProductsSetting = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listProduct, setListProduct] = useState<ProductCollectionType>({
    count: 0,
    totalAvailable: 0,
    products: [],
  });
  const [selectProduct, setSelectProduct] = useState<ProductResponseType>();
  const [searchName, setSearchName] = useState('');
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
    filter: optionsFilter[0].value,
    isCallApi: true,
  });

  useEffect(() => {
    // if (queryPagination.isCallApi) {
    getListProduct(queryPagination, searchName);
    // }
  }, []);

  const getListProduct = async (query: IQuery, searchText?: string) => {
    try {
      setIsLoading(true);
      const res = await configuredRequests.GET.getListProduct({
        limit: query.limit,
        offset: query.offset,
        searchText,
        filter: query.filter,
      });
      if (res) {
        // const sortedProductDataTable = orderBy(
        //   res,
        //   [(product) => product?.productName?.toLowerCase()],
        //   ['asc']
        // );
        setListProduct(res);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  };

  const handleSearchProduct = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
      setQueryPagination((prev) => ({ ...prev, offset: 0, isCallApi: false }));
      searchProductWithName(e.target.value);
    },
    [queryPagination]
  );

  const searchProductWithName = useCallback(
    debounce((name: string) => {
      getListProduct(queryPagination, name);
    }, 1000),
    [queryPagination]
  );

  const handleSelectFilter = useCallback(
    (filter: string) => {
      if (filter !== queryPagination.filter) {
        setQueryPagination((prev) => ({
          ...prev,
          filter,
          offset: 0,
          isCallApi: true,
        }));
      }
    },
    [queryPagination]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({
        ...preState,
        offset: newPage,
        isCallApi: true,
      }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination((prev) => ({
          ...prev,
          limit: newRowsPerPage,
          offset: 0,
          isCallApi: true,
        }));
      }
    },
    []
  );

  const handleOpenModal = (id: string) => {
    const product =
      listProduct.products.find((p) => p.productId === id) ?? initialProduct;

    setSelectProduct(product);
  };

  return (
    <div className='communication-container user-setting w-300 container-practice container-product setting-scroll--custom'>
      {isLoading ? (
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        <>
          <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
            {/* <div className='contacts--filter-container'> */}
            {/* <div className='contacts--filter-search-container'>
                <FontAwesomeIcon
                  icon={['far', 'search']}
                  onClick={() => searchProductWithName(searchName)}
                />

                <input
                  className='contacts--filter--search-input submission-detail-search-input'
                  value={searchName}
                  type='search'
                  placeholder='Search by product name'
                  onChange={handleSearchProduct}
                />
              </div>
              <TextChevronDropdown
                styleOptions={{
                  width: 270,
                }}
                className='contacts--filter--select-container background-gray height-40'
                //@ts-ignore
                currentSelection={
                  optionsFilter.find(
                    (option) => option.value === queryPagination.filter
                  )?.label
                }
                handleChangeSelection={handleSelectFilter}
                selectionOptions={optionsFilter}
                useBlackText={true}
              />

              <Button
                variant='contained'
                color='primary'
                className='btn-new'
                onClick={() => setSelectProduct(initialProduct)}
              >
                <FontAwesomeIcon icon={['far', 'plus']} />
                New Product
              </Button>
            </div>

            {listProduct.products.length > 0 ? (
              <Table
                columns={columns}
                // @ts-ignore
                rows={listProduct.products.map((item) => ({
                  ...item,
                  productName: (
                    <span
                      className='product-name'
                      onClick={() => setSelectProduct(item)}
                    >
                      {item.name}
                    </span>
                  ),
                }))}
                isHasPagination={true}
                totalAvailable={listProduct?.totalAvailable}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
              />
            ) : (
              <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                No results found
              </p>
            )} */}

            <CommunicationTable
              columns={columns}
              dataTable={listProduct.products}
              isSort={false}
              isDisplayedSearchAndFilter={true}
              isDisplayedSearch={true}
              isDisplayedFilter={true}
              isDisplayedButton={true}
              optionsForFilter={optionsFilter}
              keySearch='productName'
              placeholderSearch='Search by product name'
              buttonName='New Product'
              iconButton={['far', 'plus']}
              isLoading={isLoading}
              screen={'PRODUCT'}
              openModal={(idValue: string) => {
                handleOpenModal(idValue);
              }}
            />
          </div>

          {!!selectProduct && (
            <ProductModal
              handleReloadData={() => {
                getListProduct(queryPagination, searchName);
              }}
              displayModal={!!selectProduct}
              setDisplayModal={() => {
                setSelectProduct(undefined);
              }}
              productData={selectProduct}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProductsSetting;
