import React from 'react';
import Contacts from 'components/Contacts/Contacts';
import { TemplateResponse } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Route, Switch, Redirect } from 'react-router-dom';
import ViewContactContainer from 'components/Contacts/ViewContact/ViewContactContainer';
import { setSessionStorageContactsResponse } from 'global/sessionStorage/SessionContactsResponseCache';
import {
  TemplateKindsEnum,
  CategoryKindsEnum,
} from 'global/requests/api-route-types';
import { TenantUserDataResponse } from 'global/requests/ResponseTypes/Tenants';
import axios, { CancelTokenSource } from 'axios';

export type Query = typeof defaultQuery;

const defaultQuery = {
  limit: 10,
  offset: 0,
};

let source: CancelTokenSource;

const ContactsContainer = () => {
  const [redirect, setRedirect] = React.useState(false);

  const [errorGettingUsers, setErrorGettingUsers] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [tenantAllUsersResponse, setTenantAllUsersResponse] =
    React.useState<TenantUserDataResponse>();
  const [numberOfFailedUserDataRequests, setNumberOfFailedUserDataRequests] =
    React.useState<number>(0);

  const [emailTemplateInfo, setEmailTemplateInfo] =
    React.useState<TemplateResponse>();
  const [textTemplateInfo, setTextTemplateInfo] =
    React.useState<TemplateResponse>();

  const [searchText, setSearchText] = React.useState('');
  const [userSelectedFilterValue, setUserSelectedFilterValue] =
    React.useState('active');
  const [queryPagination, setQueryPagination] = React.useState(defaultQuery);

  React.useEffect(() => {
    sendGetUsersRequest({ withStatus: 'Active' });
  }, []);

  const sendGetEmailTemplateInfoRequest = async () => {
    try {
      const res = await configuredRequests.GET.templateByKind(
        [TemplateKindsEnum.email],
        [CategoryKindsEnum.manual]
      );
      if (res) {
        setEmailTemplateInfo(res);
      }
    } catch (err) {
      console.log('error making request for email template info');
    }
  };

  const sendGetTextTemplateInfoRequest = async () => {
    try {
      const res = await configuredRequests.GET.templateByKind(
        [TemplateKindsEnum.sms],
        [CategoryKindsEnum.manual]
      );
      if (res) {
        setTextTemplateInfo(res);
      }
    } catch (err) {
      console.log('error making request for text (sms) template info');
    }
  };

  const handleOutOfBoundsPageRedirect = (): JSX.Element => {
    setRedirect(false);
    return <Redirect to='/Contacts/1' />;
  };

  const sendGetUsersRequest = async (query: any, isReload?: boolean) => {
    if (numberOfFailedUserDataRequests > 5) {
      setIsLoading(false);
      setErrorGettingUsers(true);
      return;
    }
    setErrorGettingUsers(false);
    try {
      if (isReload) {
        setTenantAllUsersResponse(undefined);
        setIsLoading(true);
      }
      if (source) {
        source.cancel();
      }
      source = axios.CancelToken.source();
      //console.log('1');
      const res = await configuredRequests.GET.allContactInfoByCurrentTenant(
        {
          summaryItems: ['phone', 'email', 'pets'],
          userKinds: ['Customer'],
          limit: query?.limit ?? 10,
          offset: query?.offset ?? 0,
          withStatus: query?.withStatus ?? '',
          withName: query?.withName ? encodeURIComponent(query?.withName) : '',
        },
        source.token
      );
      //console.log('why');
      setSessionStorageContactsResponse(res);
      setTenantAllUsersResponse(res);
      setIsLoading(false);
      return res;
    } catch (err) {
      if (!axios.isCancel(err)) {
        setNumberOfFailedUserDataRequests(numberOfFailedUserDataRequests + 1);
        setErrorGettingUsers(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Switch>
      (
      <Route
        path={`/Contacts/View/:contactID`}
        render={(props) => {
          const { contactID } = props.match.params;
          return <ViewContactContainer userID={contactID} />;
        }}
      />
      ) (
      <Route
        path={`/Contacts/:pageNumber`}
        render={(props) => {
          const { pageNumber } = props.match.params;

          if (redirect || !parseInt(pageNumber) || parseInt(pageNumber) < 1 ) {
            return handleOutOfBoundsPageRedirect();
          }

          return (
            <Contacts
              allContactInfo={tenantAllUsersResponse}
              errorGettingUsers={errorGettingUsers}
              isLoading={isLoading}
              pageNumber={pageNumber}
              // getUsers={getUsers}
              setIsLoading={setIsLoading}
              setRedirect={setRedirect}
              numberOfFailedUserDataRequests={numberOfFailedUserDataRequests}
              sendGetTextTemplateInfoRequest={sendGetTextTemplateInfoRequest}
              sendGetEmailTemplateInfoRequest={sendGetEmailTemplateInfoRequest}
              emailTemplateInfo={emailTemplateInfo}
              textTemplateInfo={textTemplateInfo}
              // @ts-ignore
              sendGetUsersRequest={sendGetUsersRequest}
              searchText={searchText}
              setSearchText={setSearchText}
              userSelectedFilterValue={userSelectedFilterValue}
              setUserSelectedFilterValue={setUserSelectedFilterValue}
              queryPagination={queryPagination}
              setQueryPagination={setQueryPagination}
            />
          );
        }}
      />
      )
    </Switch>
  );
};

export default ContactsContainer;
