import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import { FORM_MODAL_CONSTANTS } from '../constants';

const {
  UpdatePatientFailModalErrorHeader,
  UpdatePatientSuccessModalHeader,
  UpdatePatientFailModalErrorText,
  UpdatePatientModalTitle,
  UpdatePatientModalCloseText,
} = FORM_MODAL_CONSTANTS;

const ContactUpdateModal = ({ onClose, isSuccess }) => {
  const headText = isSuccess ? UpdatePatientSuccessModalHeader : UpdatePatientFailModalErrorHeader;
  const content = !isSuccess ? UpdatePatientFailModalErrorText : null;
  return (
    <Modal
      title={UpdatePatientModalTitle}
      acceptText={false}
      closeText={UpdatePatientModalCloseText}
      onClose={onClose}
    >
      <p>{headText}</p>
      <p>{content}</p>
    </Modal>
  );
};

ContactUpdateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
};
export default ContactUpdateModal;
