/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Highcharts, { Options, LegendOptions } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core';

import { generateSeriesData, generateSeries } from './utils';
import { legend } from './constants';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface DonutChartProps {
  innerRadius: number;
  outerRadius: number;
  values: number[];
  titles: string[];
  colors: string[];
  height?: string;
  legendSize?: 'small' | 'large' | 'normal';
}

const DonutChart = (props: DonutChartProps): React.ReactElement => {
  const {
    innerRadius,
    outerRadius,
    values,
    titles,
    colors,
    height = null,
    legendSize = 'small',
  } = props;
  const theme = useTheme();
  const { fontFamily } = theme.typography;
  const getLegendOptions = (): LegendOptions => {
    if (legendSize === 'large') {
      return { ...legend, itemStyle: { ...legend.itemStyle, fontFamily } };
    }
    if (legendSize === 'small') {
      return {
        ...legend,
        itemStyle: { ...legend.itemStyle, fontSize: '11px', fontFamily },
        symbolHeight: 10,
        symbolRadius: 5,
        itemMarginBottom: 0,
      };
    }
    return {
      ...legend,
      itemStyle: { ...legend.itemStyle, fontSize: '15px', fontFamily },
      symbolHeight: 10,
      symbolRadius: 5,
      itemMarginBottom: 0,
    };
  };
  const generateOptions = (): Options => ({
    title: { text: '' },
    chart: {
      height,
    },
    legend: getLegendOptions(),
    series: generateSeries(generateSeriesData(values, titles, colors), outerRadius, innerRadius),
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter(): string {
        return `<b>${this.key}:${this.y}</b>`;
      },
    },
  });
  return <HighchartsReact highcharts={Highcharts} options={generateOptions()} />;
};

DonutChart.defaultProps = {
  innerRadius: 90,
  outerRadius: 100,
  values: [1, 2, 3, 4, 5],
  titles: ['a', 'b', 'c', 'd', 'e'],
  colors: ['#4C7CFD', '#A6C5F7', '#E1F0FF', '#B8C3D2', '#12253F'],
};
export default DonutChart;
