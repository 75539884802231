import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EventAvailableOutlined from '@material-ui/icons/EventAvailableOutlined';

const border = '1px solid #b8c3d2';

const LeftChevronButton = styled(IconButton)`
  border-radius: 6px 0px 0px 6px;
  border: ${border};
  border-right: none;
  padding: 6px;
`;

const RightChevronButton = styled(IconButton)`
  border-radius: 0px 6px 6px 0px;
  border: ${border};
  border-left: none;
  padding: 6px;
`;

const StyledEventAvailable = styled(EventAvailableOutlined)`
  padding: 6px 0px 6px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${border};
  border-right: none;
`;

const TextWithOutline = styled(Typography)`
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${border};
  border-left: none;
  font-weight: 600;
` as typeof Typography;

const Main = styled.div`
  align-items: center;
  display: inline-block;
`;

export { LeftChevronButton, RightChevronButton, StyledEventAvailable, TextWithOutline, Main };
