/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import LockIcon from '@material-ui/icons/Lock';
import { getFieldProperties, getFieldPropertyValues, preventOverride } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function FormFieldBase(
  Component,
  controls = { edit: true, delete: true, more: true },
  preventClick = false,
) {
  return class FormFieldBase extends React.Component {
    constructor(props) {
      super(props);

      this.debounceDragOver = debounce(this.debounceDragOver, 100);
    }

    handleFieldClick = e => {
      const { field, parent } = this.props;

      e.stopPropagation();

      if (!preventClick) {
        this.props.onFieldClick(field, parent);
      }
    };

    handleDragStart = (e, data) => {
      e.stopPropagation();

      e.dataTransfer.setData('text/plain', typeof data !== 'string' ? JSON.stringify(data) : data);
      // due to inconsistencies in browser behavior with e.dataTransfer.dropEffect,
      // just set custom data attribute to the e.dataTransfer object to indicate we're moving this field
      e.dataTransfer.setData('text/x-move', true);
      e.dataTransfer.dropEffect = 'move'; // doesn't have desired effect

      if (this.props.parent)
        e.dataTransfer.setData('text/x-source', JSON.stringify(this.props.parent));
    };

    handleDragOver = (e, data) => {
      // need to call event (e) methods *before* calling the debounced method
      e.preventDefault();
      e.stopPropagation();
      this.debounceDragOver({ clientX: e.clientX, clientY: e.clientY }, data);
    };

    debounceDragOver = (coords, data) => {
      const { clientX, clientY } = coords;
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight, offsetParent } = this.element || {};
      const containerElement = document.getElementsByClassName('form-item-build-container')[0];
      const offsetMidX =
        this.recurseOffsetParent(offsetParent, 'Left', 0) + offsetLeft + offsetWidth / 2;
      const offsetMidY =
        offsetParent.offsetTop + offsetTop - containerElement.scrollTop + offsetHeight / 2;
      const isNested = this.element.classList.contains('nested');
      let location;

      // if nested, drag/drop placement is left/right
      // otherwise placement is top/bottom
      if (isNested) location = clientX < offsetMidX ? 'left' : 'right';
      else location = clientY < offsetMidY ? 'top' : 'bottom';

      this.props.onFieldHover(this.props.field, location);
    };

    recurseOffsetParent = (offsetParent, offsetAttribute, offsetSum) => {
      if (!offsetParent) return offsetSum;
      offsetSum += offsetParent[`offset${offsetAttribute}`];
      return this.recurseOffsetParent(offsetParent.offsetParent, offsetAttribute, offsetSum);
    };

    handleDragEnd = e => {
      e.stopPropagation();
      e.dataTransfer.clearData();
      this.props.onFieldHover(null, null);
    };

    get shouldPreventOverride() {
      const { field, isReview, readOnly } = this.props;
      return readOnly || (isReview && !readOnly && preventOverride(field));
    }

    render() {
      const {
        field,
        activeField,
        activeNestedField,
        onDeleteField,
        hoverField,
        hoverLocation,
        isNested,
        parent,
        isRenderedField,
        onLockField,
      } = this.props;
      const isActive =
        field.uuid === (activeField || {}).uuid || field.uuid === (activeNestedField || {}).uuid;
      const isHovered = field === hoverField;
      const hoverTop = field === hoverField && hoverLocation === 'top';
      const hoverBottom = field === hoverField && hoverLocation === 'bottom';
      const hoverLeft = field === hoverField && hoverLocation === 'left';
      const hoverRight = field === hoverField && hoverLocation === 'right';
      const hoverClassNames = classNames({
        hover: true,
        [`hover-${hoverLocation}`]: isHovered,
      });
      const formFieldClassNames = classNames({
        'form-field': true,
        active: isActive,
        [`hover-${hoverLocation}`]: isHovered,
        nested: isNested,
        rendered: isRenderedField,
        locked: field.isLocked,
      });
      const properties = getFieldProperties(field, 'common');
      const propertyNames = [
        'label',
        'makeRequired',
        'makeHidden',
        'hideLabel',
        'readOnly',
        'sublabel',
      ];
      const defaultValues = {
        label: field.display_name,
        makeRequired: false,
        makeHidden: false,
        hideLabel: false,
        readOnly: false,
        sublabel: '',
      };
      const commonPropValues = getFieldPropertyValues(
        properties.common,
        propertyNames,
        defaultValues,
      );
      const classes = classNames({
        'form-field-input-container': true,
        'hide-field': commonPropValues.makeHidden,
        'hide-label': commonPropValues.hideLabel,
        'read-only': commonPropValues.readOnly || this.shouldPreventOverride,
      });

      // only include certain DOM element props if this a "builder" field and not a "rendered" field
      const builderProps = {};
      if (!isRenderedField) {
        builderProps.onDragStart = e => this.handleDragStart(e, field);
        builderProps.onDragOver = e => this.handleDragOver(e, field);
        builderProps.onDragEnd = this.handleDragEnd;
        builderProps.onClick = this.handleFieldClick;
      }

      return (
        <div>
          {(hoverTop || hoverLeft) && <div className={hoverClassNames} />}
          <div
            className={formFieldClassNames}
            draggable={!isRenderedField} // rendered fields are not draggable
            ref={el => (this.element = el)}
            {...builderProps}
          >
            {!isRenderedField && (
              <FormFieldControls
                field={field}
                parent={parent}
                controls={controls}
                onDeleteField={onDeleteField}
                onLockClick={onLockField}
              />
            )}
            <Component
              className={classes}
              commonProps={commonPropValues}
              {...this.props}
              readOnly={this.shouldPreventOverride}
            />
            {!isRenderedField && field.isLocked && (
              <span
                style={{
                  width: '100%',
                  paddingTop: '10px',
                }}
              >
                <LockIcon style={{ paddingRight: '15px' }} className='icon icon-lock pull-right' />
              </span>
            )}
          </div>
          {(hoverBottom || hoverRight) && <div className={hoverClassNames} />}
        </div>
      );
    }
  };
}

FormFieldBase.defaultProps = {
  activeField: null,
  activeNestedField: null,
  hoverField: null,
  hoverLocation: null,
  isNested: false,
  isRenderedField: false,
  readOnly: false,
};

const FormFieldControls = ({ field, parent, controls, onDeleteField, onLockClick }) => {
  const { isLocked } = field;
  const isLockAppointmentBooking = field.display_name === 'Appointment Booking';
  return (
    !isLockAppointmentBooking && 
    <div className="hoan">
      <div className='grip-handle'>
        <div />
      </div>
      <div className='form-field-controls'>
        {controls.edit && <CreateIcon title='Edit' />}
        {controls.delete && !isLocked && (
          <DeleteIcon
            className='icon icon-trash'
            title='Delete'
            onClick={() => onDeleteField(field, parent)}
          />
        )}
        <span onClick={() => onLockClick(field, parent)}>
          {
            isLocked ?
              <FontAwesomeIcon icon={["fas", "lock-open"]} />
            : <FontAwesomeIcon icon={["fas", "lock"]}/>
            }
        </span>
      </div>
    </div>
  );
};

FormFieldControls.defaultProps = {
  onDeleteField: () => { },
  onLockClick: () => { },
};
