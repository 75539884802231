/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { NavigationLink, NavigationElementWithIcons } from 'types/NavigationT';
import NavigationLinkElement from './NavLinkElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useRouteMatch } from 'react-router-dom';
import { intersection } from 'lodash';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { setSessionStateSidebarIsOpen } from 'global/sessionStorage/SessionSidebarState';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import logo from '../../../media/images/logo_noti.png';
import { NotificationContext } from 'contexts/Notification';
import { ISettings } from 'components/FormBuilder/services/settingsService/settingsTypes';

interface NavigationTopLevelContainerProps extends NavigationElementWithIcons {
  innerText: string;
  childLinks: NavigationLink[];
  navElementIndex: string;
  shouldRenderOpen?: boolean;
  allowedPath: Array<string>;
  isCrmOpen?: boolean;
  isFormOpen?: boolean;
  setSidebarIsOpen: (isOpen: boolean) => void;
  setCRMIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFormIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// if this function returns true then the nav link will be styled as active
export const isNavLinkActive = (linkTo: string, matchedPath: string) => {
  // remove any parameters or sub-links (eg: "/Contacts/View/:contactID" => "/Contacts")
  const currentPath = '/' + matchedPath.split('/')[1];
  switch (currentPath) {
    case '/Template': {
      return (
        linkTo.startsWith('/Communications') || linkTo.startsWith(currentPath)
      );
    }
    case '/Automation':
    case '/Communications':
    case '/Email':
    case '/SMS': {
      return (
        linkTo.startsWith('/Automation') ||
        linkTo.startsWith('/Communications') ||
        linkTo.startsWith('/Email') ||
        linkTo.startsWith('/SMS') ||
        linkTo.startsWith(currentPath)
      );
    }
    default: {
      return linkTo.startsWith(currentPath);
    }
  }
};

const urlPath = window.location.href.split('/').reduce((acc, item, idx) => {
  if (idx < 3) {
    return acc + '/' + item;
  }
  return acc;
});

const getListBookingRequest = async (
  emailUser: string,
  showNotification: boolean
) => {
  try {
    const response = await configuredRequests.GET.getListBookingRequest({
      status: 'PendingRequests',
    });
    const numberUnreadElement = document.getElementsByClassName('oab-status')[0];
    if (response && response.data && numberUnreadElement) {
      // @ts-ignore
      const totalUnreads = response.data?.totalAvailable ?? 0;
      window.sessionStorage.setItem('countUnreadsOab', totalUnreads.toString());
      if (totalUnreads) {
        numberUnreadElement.classList.add('status--count');
        numberUnreadElement.innerHTML = totalUnreads.toString();
      } else {
        numberUnreadElement.classList.remove('status--count');
      }

      // @ts-ignore
      const listBooking = response.data.items;

      const dataLocal = window.localStorage.getItem(`${emailUser}-oab`);

      const listLastestOab = dataLocal ? dataLocal.trim().split('.') : [];

      const listOabUnNoti = listBooking?.filter(
        (oab: any) =>
          listLastestOab?.findIndex((oabLast) => oabLast === oab.id) === -1
      );

      listOabUnNoti.reverse().forEach((oab: any) => {
        if (showNotification && 'Notification' in window) {
          let notification = new Notification(``, {
            body: `${oab.contactName} has requested to book an appointment.`,
            icon: logo,
          });

          notification.onclick = function () {
            window.sessionStorage.setItem('noti-oab', JSON.stringify(oab));
            window.open(urlPath + '/AppointmentBooking');
            window.sessionStorage.removeItem('noti-oab');
          };
        }
      });
      window.localStorage.setItem(
        `${emailUser}-oab`,
        listBooking.map((oab: any) => oab.id).join('.')
      );
    }
  } catch (error) {
    console.log('error: ', error);
  }
};

const getTotalUnreadSubmissions = async (
  emailUser: string,
  showNotification: boolean
) => {
  try {
    const tenantId = getSessionTenantInfo()?.tenantId ?? '';
    if (tenantId) {
      const res = await configuredRequests.GET.getCountUnreadSubmissions(
        tenantId
      );
      // @ts-ignore
      const totalUnread = res?.data?.totalUnreads ?? 0;
      if (totalUnread) {
        document
          .getElementsByClassName('form-status')[0]
          ?.classList?.add('status--count');

        const dataLocal = window.localStorage.getItem(`${emailUser}-form`);

        const listLastestSubmit = dataLocal ? dataLocal.trim().split('.') : [];

        // @ts-ignore
        const listSubmitUnNoti = res.data?.items?.filter(
          (submission: any) =>
            listLastestSubmit?.findIndex(
              (submitLast) => submitLast === submission.id
            ) === -1
        );

        listSubmitUnNoti.reverse().forEach((submission: any) => {
          // @ts-ignore
          listLastestSubmit.unshift(submission.id);
          // @ts-ignore
          // if (listLastestMess.length > 20) {
          //   listLastestMess?.pop();
          // }

          if (showNotification && 'Notification' in window) {
            let notification = new Notification(``, {
              body: `${submission.name} has submitted a form: ${submission.formName}`,
              icon: logo,
            });

            notification.onclick = function () {
              window.sessionStorage.setItem(
                'noti-form',
                `${JSON.stringify(submission)}`
              );
              window.open(urlPath + '/AllSubmissions');
              window.sessionStorage.removeItem('noti-form');
            };
          }
        });

        window.localStorage.setItem(
          `${emailUser}-form`,
          listLastestSubmit.join('.')
        );
      } else {
        document
          .getElementsByClassName('form-status')[0]
          ?.classList?.remove('status--count');
      }
      var formUnread = document.getElementsByClassName('form-status')[0];
      if (formUnread) formUnread.innerHTML = totalUnread.toString();
      window.sessionStorage.setItem(
        'countUnreadSubmissions',
        totalUnread.toString()
      );
    }
  } catch (err) {
    console.log('err', err);
  }
};

const getContactListData = async (query: any) => {
  try {
    const res = await configuredRequests.GET.getContactListData({
      userKinds: ['Customer'],
      limit: query?.limit ?? 20,
      offset: query?.offset ?? 0,
      searchText: query?.searchText ?? '',
      isMessageList: true,
    });

    const countContactUnRead = res.totalUnreads;
    const numberMessageElement = document.getElementsByClassName('messenger-status')[0];
    if (countContactUnRead) {
      numberMessageElement.classList.add('status--count');

      // Notification.requestPermission();

      const emailUser = query.emailUser;

      const dataLocal = window.localStorage.getItem(emailUser);

      const listLastestMess = dataLocal ? dataLocal.trim().split('.') : [];

      const listMessUnNoti = res.users
        .filter((user) => !user.isMessageRead)
        ?.filter(
          (userUnRead) =>
            listLastestMess?.findIndex(
              (userLast) => userLast === userUnRead.latestMesageId
            ) === -1
        );

      listMessUnNoti.reverse().forEach((user) => {
        // @ts-ignore
        listLastestMess.unshift(user.latestMesageId);
        // @ts-ignore
        if (listLastestMess.length > 20) {
          listLastestMess?.pop();
        }

        if (query.showNotification && 'Notification' in window) {
          let notification = new Notification(``, {
            body: `${user.name} has sent a message: ${user.latestMessage}`,
            icon: logo,
          });

          notification.onclick = function () {
            window.sessionStorage.setItem(
              'message-user',
              `${JSON.stringify(user)}`
            );
            // window.focus();
            // window.location.href = `${urlPath}/Messenger`;
            // this.close();
            window.open(urlPath + '/Messenger');
            window.sessionStorage.removeItem('message-user');
          };
        }
      });

      window.localStorage.setItem(emailUser, listLastestMess.join('.'));
    } else {
      numberMessageElement.classList.remove('status--count');
    }
    numberMessageElement.innerHTML = countContactUnRead.toString();
    window.sessionStorage.setItem(
      'countContactUnread',
      countContactUnRead.toString()
    );
  } catch (err) {
    console.log('err', err);
  }
};

// This component renders a button element that acts as the container for it's links
// The links it contains are rendered with a map and the data is supplied by props
const NavigationTopLevelContainer = (
  props: NavigationTopLevelContainerProps
) => {
  // const { refInterval } = useContext(UnreadContext);
  const {
    refInterval,
    intervalError,
    settings,
    setSettings,
    countErrors,
    setCountErrors,
  } = useContext(NotificationContext);
  const [containerIncludesActiveLink, setContainerIncludesActiveLink] =
    useState<boolean>(false);
  const { leftIcon, allowedPath = [] } = props;

  const userObject = JSON.parse(
    // @ts-ignore
    window.sessionStorage.getItem('tenantInfo--full_response')
  );
  const emailUser = userObject?.emailAddress;

  const getNotificationSetting = async () => {
    try {
      const res = await configuredRequests.GET.notificationSettings();
      if (res) {
        setSettings({
          isLoad: true,
          sendDesktopNotificationsForNewFormSubmitted:
            res.settings.sendDesktopNotificationsForNewFormSubmitted ?? false,
          sendDesktopNotificationsForNewMessages:
            res.settings.sendDesktopNotificationsForNewMessages ?? false,
          sendDesktopNotificationsForOabRequested:
            res.settings.sendDesktopNotificationsForOabRequested ?? false,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if ((allowedPath.includes('/Messenger') || allowedPath.includes("/FormBuilder") || allowedPath.includes("/AppointmentBooking") || allowedPath.includes("/AllSubmissions")) && !settings?.isLoad) {
      getNotificationSetting();
    }
  }, []);

  const handleRequestsToMake = () => {
    const requests: any = []
    if (!settings) return;
    if (allowedPath.includes("/Messenger")) {
      requests.push(getContactListData({
        limit: 20,
        offset: 0,
        isSearch: true,
        emailUser,
        showNotification: settings.sendDesktopNotificationsForNewMessages,
      }))
    }
    if (allowedPath.includes("/FormBuilder") || allowedPath.includes("/AllSubmissions")) {
      requests.push(getTotalUnreadSubmissions(
        emailUser,
        settings.sendDesktopNotificationsForNewFormSubmitted
      ))
    }
    if (allowedPath.includes("/AppointmentBooking")) {
      requests.push(getListBookingRequest(
        emailUser,
        settings.sendDesktopNotificationsForOabRequested
      ))
    }
  return requests;
}

  useEffect(() => {
    if (
      (allowedPath.includes('/Messenger') || 
      allowedPath.includes("/FormBuilder") ||
      allowedPath.includes("/AllSubmissions") || 
      allowedPath.includes("/AppointmentBooking")) &&
      refInterval.current !== null &&
      settings &&
      !!emailUser
    ) {
      clearInterval(refInterval.current);
      refInterval.current = 0;
      refInterval.current = setInterval(function () {
        const requestsToMake = handleRequestsToMake();
        Promise.all(requestsToMake);
      }, 45000);
    }
  }, [settings]);

  useEffect(() => {
    if (
      (allowedPath.includes('/Messenger') || 
      allowedPath.includes("/FormBuilder") || 
      allowedPath.includes("/AllSubmissions") ||
      allowedPath.includes("/AppointmentBooking")) &&
      refInterval.current === null &&
      settings &&
      !!emailUser
    ) {
      if ('Notification' in window) Notification.requestPermission();
      const requestsToMake = handleRequestsToMake();
      Promise.all(requestsToMake);
      refInterval.current = setInterval(function () {
        Promise.all(requestsToMake);
      }, 45000);
    }
  }, [allowedPath, emailUser, settings]);

  useEffect(() => {
    if (countErrors.errors >= 0 && countErrors.stoppages >= 0) {
      const total = countErrors.errors + countErrors.stoppages;
      checkTotalErrors(total);
    }
  }, [countErrors]);

  useEffect(() => {
    if (
      allowedPath.includes('/Alerts') &&
      intervalError.current.error === null
    ) {
      Promise.all([getCountStopPages(), getAlertErrors()]);
      intervalError.current.error = setInterval(() => {
        getAlertErrors();
      }, 5 * 60000);
      intervalError.current.stoppage = setInterval(() => {
        getCountStopPages();
      }, 60 * 60000);
    }
  }, [emailUser]);

  const match = useRouteMatch();

  const getAlertErrors = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getCountAlertErrors();
      // @ts-ignore
      const countErrorsUnRead = res?.data?.totalUnreads || 0;
      // const total = countErrors.stoppages + countErrorsUnRead;
      // checkTotalErrors(total);
      setCountErrors((prev) => ({ ...prev, errors: countErrorsUnRead }));
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const getCountStopPages = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getCountSyncerStopPages();
      if (res) {
        // @ts-ignore
        const countSyncerAlerts = res.data.totalUnreads || 0;
        // const total = countErrors.errors + countSyncerAlerts;
        // checkTotalErrors(total);
        setCountErrors((prev) => ({ ...prev, stoppages: countSyncerAlerts }));
      }
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const checkTotalErrors = useCallback((total: number) => {
    const numberMessageElement = document.getElementsByClassName('status')[0];
    if (total > 0) {
      numberMessageElement.classList.add('status--count');
    } else {
      numberMessageElement.classList.remove('status--count');
    }
    numberMessageElement.innerHTML = total.toString();
  }, []);

  const renderChildLinks = useCallback(() => {
    let includedActive = false;
    const childLinkElements = props.childLinks.map((linkData, linkIndex) => {
      if (typeof linkData.linkTo !== 'string') {
        // compiler gives a warning if this return nothing, though this case should never be hit
        // TODO: find a better solution?
        return <></>;
      }

      const isActiveLink = isNavLinkActive(linkData.linkTo, match.path);
      if (isActiveLink) {
        includedActive = true;
      }

      return (
        allowedPath?.includes(linkData.linkTo) && (
          <NavigationLinkElement
            linkTo={linkData.linkTo}
            innerText={linkData.innerText}
            key={'nav-link-' + props.navElementIndex + '-' + linkIndex}
            isNavLinkActive={isActiveLink}
            setSidebarIsOpen={props.setSidebarIsOpen}
            rightIcon={linkData.rightIcon}
          />
        )
      );
    });

    if (includedActive) {
      if (!containerIncludesActiveLink) {
        setContainerIncludesActiveLink(true);
      }
    } else {
      if (containerIncludesActiveLink) {
        setContainerIncludesActiveLink(false);
      }
    }

    return childLinkElements;
  }, [
    allowedPath,
    match.path,
    props.childLinks,
    props.navElementIndex,
    containerIncludesActiveLink,
  ]);

  const toggleContainerOpenState = (type: string) => {
    if (type == 'CRM') {
      props.setCRMIsOpen(!props.isCrmOpen);
      setSessionStateSidebarIsOpen['CRM'](!props.isCrmOpen);
    } else if (type == 'Form') {
      props.setFormIsOpen(!props.isFormOpen);
      setSessionStateSidebarIsOpen['Form'](!props.isFormOpen);
    }
  };

  const listLinkTo = props.childLinks.map((childLink) => childLink.linkTo);
  const isHasPermissionToAccessChildLink =
    intersection(allowedPath, listLinkTo).length > 0;

  return (
    <>
      {isHasPermissionToAccessChildLink && (
        <li
          className={
            'sidebar__item sidebar__top-level sidebar__link-container' +
            (containerIncludesActiveLink ? ' active' : '')
          }
        >
          <button
            className={
              'sidebar__lnk sidebar__link--main' +
              ((props.innerText == 'CRM' ? props.isCrmOpen : props.isFormOpen)
                ? ' open'
                : '')
            }
            onClick={() =>
              toggleContainerOpenState(
                props.innerText == 'CRM' ? 'CRM' : 'Form'
              )
            }
          >
            {leftIcon ? ( // if there is data then we can render the icon, otherwise render nothing
              <FontAwesomeIcon icon={leftIcon as IconProp} />
            ) : null}

            {props.innerText && (
              <div className={'sidebar__text'}>{props.innerText}</div>
            )}

            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
          </button>

          <ul
            className={
              'sidebar__list' +
              ((props.innerText == 'CRM' ? props.isCrmOpen : props.isFormOpen)
                ? ' open'
                : '')
            }
          >
            {renderChildLinks()}
          </ul>
        </li>
      )}
    </>
  );
};
export default NavigationTopLevelContainer;
