import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { PetSummaryCollectionResponse, PetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterGlobal from 'components/Global/Filter/Filter';
import loading_spinner from 'media/images/loading-spinner.svg';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import SendFormsRenderer from 'components/FormBuilder/components/FormBuilder/forms/send-forms-renderer';
import moment from 'moment'; 
import { toast } from 'react-toastify';
import { getPatientPortalUrl } from 'global/constants/url';
import { AddFormAutoFillPayload } from 'global/requests/ResponseTypes/FormBuilder';
import { SingleUserSendEmailPayload, SingleUserSendTextPayload, MessagePayloadKindsEnum } from 'global/requests/RequestTypes';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import 'components/FormBuilder/components/FormBuilder/forms/send-forms-renderer.scss';

interface SendFormsContainerProps {
  userInfo: UserDataResponse;
  petsInfo: PetSummaryCollectionResponse | undefined;
}

type OptionDropDown = {
  value: string;
  label: string;
};

const SendFormsContainer = (props: SendFormsContainerProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [selectedPet, setSelectedPet] = useState({} as OptionDropDown);
  const [selectedType, setSelectedType] = useState({} as OptionDropDown);
  const [selectedTemplate, setSelectedTemplate] = useState({} as OptionDropDown);
  const [selectedFolder, setSelectedFolder] = useState({} as OptionDropDown);
  const [selectedForm, setSelectedForm] = useState({} as OptionDropDown);
  const [optionsPet, setOptionsPet] = useState<OptionDropDown[]>([]);
  const [optionsType, setOptionsType] = useState<OptionDropDown[]>([{ value: "sms", label: "SMS" }, { value: "email", label: "Email" }]);
  const [optionsSmsTemplate, setOptionsSmsTemplate] = useState<OptionDropDown[]>([]);
  const [optionsEmailTemplate, setOptionsEmailTemplate] = useState<OptionDropDown[]>([]);
  const [optionsFolder, setOptionsFolder] = useState<OptionDropDown[]>([]);
  const [optionsForm, setOptionsForm] = useState<OptionDropDown[]>([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [folders, setFolders] = useState<any>([]);
  const [forms, setForms] = useState<any>([]);
  const [form, setForm] = useState<any>({});
  const autoFillFields = useRef<any>({});
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("Select a communication type to show templates");
  const [formPlaceholder, setFormPlaceholder] = useState<string>("Select a folder to show forms");
  const [sendDisabled, setSendDisabled] = useState<boolean>(true);
  const [autoFillId, setAutoFillId] = useState<string>("");

  const handleSelectPet = (newPetSelect: OptionDropDown) => {
    setSelectedPet(newPetSelect);
  };

  const handleSelectType = (newTypeSelect: OptionDropDown) => {
    setSelectedType(newTypeSelect);
  };


  const handleSelectTemplate = (newTemplateSelect: OptionDropDown) => {
    setSelectedTemplate(newTemplateSelect);
  };

  const handleSelectFolder = (newFolderSelect: OptionDropDown) => {
    setSelectedFolder(newFolderSelect);
  };

  const handleSelectForm = (newFormSelect: OptionDropDown) => {
    setSelectedForm(newFormSelect);
  };

  useEffect(() => {
    setIsLoading(true);
    getTemplates();
    getFolders();
    if(props.petsInfo) {
      let petOptions = [];
      let petInfoOptions = props.petsInfo?.pets.filter((p: PetInfoResponse) => p.status == "active").sort((p1: PetInfoResponse, p2: PetInfoResponse) => (p1.name < p2.name) ? -1 : (p1.name > p2.name) ? 1 : 0).map((p: PetInfoResponse) => { return { value: p.petId, label: p.name } })!;
      petOptions = [{ value: "no-pet", label: "(No Pet)" }, ...petInfoOptions]
      setOptionsPet(petOptions);
    }
  }, [props.petsInfo]);

  useEffect(() => {
    setSelectedForm({} as OptionDropDown);
    if (selectedFolder.label && selectedFolder.label != '') {
      getForms();
      setFormPlaceholder("Select a form");
      return;
    } else {
      setFormPlaceholder("Select a folder to show forms");
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (selectedType.value == "sms" && optionsSmsTemplate.length == 0) {
      setTemplatePlaceholder(`No manual SMS template found with a {{FormLink}} token`);
    } else if (selectedType.value == "email" && optionsEmailTemplate.length == 0) {
      setTemplatePlaceholder(`No manual Email template found with a {{FormLink}} token`);
    } else if (!selectedType.value) {
      setTemplatePlaceholder(`Select a communication type to show templates`);
    } else {
      setTemplatePlaceholder(`Select a ${selectedType.value == "sms" ? "SMS" : "Email"} template`);
    }
    if (!selectedType.value) {
      setSelectedTemplate({} as OptionDropDown);
    } else {
      setSelectedTemplate({ label: "Default Template", value: "Default" } as OptionDropDown);
    }
    setSelectedFolder({} as OptionDropDown);
    setOptionsForm([] as OptionDropDown[]);
  }, [selectedType]);

  useEffect(() => {
    if (selectedForm.label && selectedForm.label != '') {
      setFormLoading(true);
      getFormInfo();
    }
  }, [selectedForm]);

  useEffect(() => {
    setSendDisabled((!selectedForm.label || !selectedTemplate.label));
    setAutoFillId("");
  }, [selectedForm, selectedTemplate]);

  useEffect(() => {
    setSelectedFolder({} as OptionDropDown);
    setOptionsForm([] as OptionDropDown[]);
  }, [selectedTemplate])

  const getFolders = async () => {
    let response = await configuredRequests.GET.getFolderOfUser(props.userInfo.tenant);
    setIsLoading(false);
    if (response.status == 200) {
      setOptionsFolder(response.data.filter((folder:any) => !folder.folder_name.toLowerCase().includes("estimate")).map((folder: any) => { return { value: folder.id, label: folder.folder_name }}));
      setFolders(response.data);
    } else {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Getting form folders failed, please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }

  const getForms = async () => {
    let folder = folders.filter((f: any) => f.id == selectedFolder.value)[0];
    let forms: any = folder.Forms.filter((f: any) => !f.is_deleted && !f.form_name.toLowerCase().includes("estimate"));
    //@ts-ignore
    let formOptions: OptionDropDown[] = forms.sort((a,b)=> a.form_name.toLowerCase() < b.form_name.toLowerCase() ? -1 : 1).map((f: any) => { return { value: f.id, label: f.form_name } });
    setForms(forms);
    setOptionsForm(formOptions);
  }

  const getFormInfo = async () => {
    autoFillFields.current = {};
    let response = await configuredRequests.GET.getFormById(selectedForm.value);
    if (response.status === 200) {
      setFormLoading(false);
      let form = response.data as any;
      if (form == undefined) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Unable to fetch form, please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
        return;
      } else if (form.is_published == false) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Form has not been published</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
        return;
      }
      setForm(form);
    } else {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Getting form information failed, please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }

  const getTemplates = async () => {
    try {
      const res = await configuredRequests.GET.templateByKind(
        ['sms', 'email'],
        ['manual']
      );
      if (res) {
        const listOption = res.items.filter((tem: any) => tem.templateBody.includes("{{FormLink}}"));
        if (listOption.length == 0) {
          setTemplatePlaceholder(`No manual template found with a {{FormLink}} token`);
          return;
        } else {
          let smsTemplates = listOption.filter((tem: any) => tem.templateKind == "sms").map((tem: any) => ({ label: tem.name, value: tem.templateId }));
          let emailemplates = listOption.filter((tem: any) => tem.templateKind == "email").map((tem: any) => ({ label: tem.name, value: tem.templateId }));
          smsTemplates.unshift({ label: "Default Template", value: "Default" });
          emailemplates.unshift({ label: "Default Template", value: "Default" });
          setOptionsSmsTemplate(smsTemplates);
          setOptionsEmailTemplate(emailemplates);
          if (selectedType.value == "sms" && smsTemplates.length == 0) {
            setTemplatePlaceholder(`No manual SMS template found with a {{FormLink}} token`);
          } else if (selectedType.value == "email" && emailemplates.length == 0) {
            setTemplatePlaceholder(`No manual Email template found with a {{FormLink}} token`);
          } else if (!selectedType.value) {
            setTemplatePlaceholder(`Select a communication type to show templates`);
          } else {
            setTemplatePlaceholder(`Select a ${selectedType.value == "sms" ? "SMS" : "Email"} template`);
          };
          return;
        }
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Getting templates failed, please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  }

  const handleSend = async () => {
    setSendDisabled(true);
    let autoFillIdd = autoFillId;
    if (autoFillIdd == "") {
      let autoFillIdResp = await addAutoFillInfo();
      if (autoFillIdResp == undefined) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>No auto fill id found, please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
        return;
      } else if (autoFillIdResp != "") {
        autoFillIdd = autoFillIdResp
        setAutoFillId(autoFillIdResp);
      }
    }
    let templateResponse = undefined;
    if (selectedTemplate.value == "Default") templateResponse = { templateBody: "{{ContactSalutations}},\nPlease review and fill out this form before your pet's appointment.\n{{FormLink}}\nIf you have any questions please call our office at {{AccountOfficeNumber}}\nThank you,\n{{AccountName}}", templateSubject: "Your Form" }
    else templateResponse = await configuredRequests.GET.templateByID(selectedTemplate.value);
    if (templateResponse == undefined) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Getting template failed, please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      return;
    }

    const res = await configuredRequests.GET.currentTenantInfo();
    let urlString: string = `${getPatientPortalUrl()}/${res.micrositeName}/forms/`;
    if (!urlString.startsWith("http")) urlString = "http://" + urlString;
    try {
      if (selectedType.value == "sms") {
        let messageContent = templateResponse.templateBody?.replaceAll("{{FormLink}}", urlString + selectedForm.value + "/" + autoFillIdd);
        if (messageContent == undefined || messageContent == "") return;
        let sendName = "";
        if (getSessionUserData() == undefined) {
          sendName = 'System Admin';
        } else {
          // @ts-ignore
          sendName = Object.values(getSessionUserData()?.name).join(' ');
        }
        const textPayload: SingleUserSendTextPayload = {
          content: messageContent,
          kind: MessagePayloadKindsEnum.sms,
          templateId: selectedTemplate.value,
          scheduleDate: '',
          // @ts-ignore
          senderName: sendName,
          mediaUrls: [],
          isSending: true,
        }
        const res = await configuredRequests.POST.sendSingleUserSMS(props.userInfo.userId, textPayload);
      } else if (selectedType.value == "email") {
        let messageContent = templateResponse.templateBody?.replaceAll("{{FormLink}}", `<a href="${urlString + selectedForm.value + "/" + autoFillIdd}">Form Link</a>`);
        if (messageContent == undefined || messageContent == "") return;
        const emailPayload: SingleUserSendEmailPayload = {
          content: messageContent,
          kind: MessagePayloadKindsEnum.email,
          subject: templateResponse.templateSubject ?? "",
          templateId: selectedTemplate.value,
          scheduleDate: '',
        }
        const responses = await configuredRequests.POST.sendSingleUserEmail(props.userInfo.userId, emailPayload);
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>{"" + err}</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      return;
    }
    toast.success(
      <div className='notify-success d-flex'>
        <FontAwesomeIcon
          icon={['fas', 'check-circle']}
          className='icon-check-status'
        />
        <div className='success-message'>
          <b>Successful</b>
          <p>Your message has been sent.</p>
        </div>
      </div>,
      { className: 'toast-success' }
    );
  }

  const addAutoFillInfo = async () => {
    let payload: AddFormAutoFillPayload = {
      object: JSON.stringify(autoFillFields.current),
      tenantId: props.userInfo.tenant,
      formVersionId: form.form_version_id,
    }
    let resp = await configuredRequests.POST.addFormAutoFill(payload);
    if (resp.status === 200) {
      // @ts-ignore
      return resp.data.autoFillId;
      // const res = await configuredRequests.GET.currentTenantInfo();
      // // @ts-ignore
      // let urlString: string = `${getPatientPortalUrl()}/${res.micrositeName}/forms/`;
      // if (!urlString.startsWith("http")) urlString = "http://" + urlString;
      // setTimeout(() => {
      //   // @ts-ignore
      //   window.open(urlString + selectedForm.value + "/" + resp.data.autoFillId, '_blank', 'noopener,noreferrer');
      // })
    }
  }

  const updateAutoFillFields = (id: string, value: any) => {
    autoFillFields.current[id] = value;
  }

  return (
    <div className='estimates-container'>
      {
        isLoading ? 
        <div className="estimates-loading-container">
          <img src={loading_spinner} alt={"Loading"} /> 
        </div> :
        <>
          <div className="send-forms-options">
              <FilterGlobal
                handleSetNewFilterValue={handleSelectPet}
                optionsForFilter={optionsPet}
                valueSelected={selectedPet}
                placeholder={'Select a pet'}
                classCustom="send-forms-dropdown-filter"
              />
              {
                selectedPet.value && selectedPet.value != '' ? 
                  <>
                    <div>
                        <FilterGlobal
                          handleSetNewFilterValue={handleSelectType}
                          optionsForFilter={optionsType}
                          valueSelected={selectedType}
                          placeholder={'Select a communication type'}
                          classCustom="send-forms-dropdown-filter"
                        />
                        <FilterGlobal
                          handleSetNewFilterValue={handleSelectTemplate}
                          optionsForFilter={selectedType.value == null ? [] : selectedType.value == "sms" ? optionsSmsTemplate : optionsEmailTemplate}
                          valueSelected={selectedTemplate}
                          placeholder={templatePlaceholder}
                          classCustom={(selectedType.value == null ? [] : selectedType.value == "sms" ? optionsSmsTemplate : optionsEmailTemplate).length > 0 ? "send-forms-dropdown-filter" : " send-forms-dropdown-filter send-forms-dropdown-unselected"}
                        />
                    </div>
                    {
                      selectedTemplate.value && selectedTemplate.value != '' ?
                        <>
                          <div>
                            <FilterGlobal
                              handleSetNewFilterValue={handleSelectFolder}
                              optionsForFilter={optionsFolder}
                              valueSelected={selectedFolder}
                              placeholder={'Select a form folder'}
                              classCustom="send-forms-dropdown-filter"
                            />
                            <FilterGlobal
                              handleSetNewFilterValue={handleSelectForm}
                              optionsForFilter={optionsForm}
                              valueSelected={selectedForm}
                              placeholder={formPlaceholder}
                              classCustom={optionsForm.length > 0 ? "send-forms-dropdown-filter" : " send-forms-dropdown-filter send-forms-dropdown-unselected"}
                            />
                          </div>
                          {
                            selectedForm.value && selectedForm.value != '' ?
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handleSend()}
                                disabled={sendDisabled}
                                className="send-form-button"
                              >
                                Send
                              </Button>
                              : ""
                          }
                        </>
                        : ""
                    }
                  </>
                  : ""
              }
          </div>
          {formLoading ?
            <div className="estimates-loading-container">
              <span>Your forms is loading</span>
              <img src={loading_spinner} alt={"Loading"} />
            </div> :
              selectedPet.label && selectedPet.label != '' && selectedTemplate.label && selectedTemplate.label != '' && selectedForm.label && selectedForm.label != '' && form.id ?
                <div
                  className="class-name-form-unique formSubmission"
                  id="sendFormsForm"
                >
                  <SendFormsRenderer
                    formVersion={{ object: JSON.parse(form.object) }}
                    fullData={{
                      formName: form.form_name,
                      versionId: form.form_version_id,
                    }}
                    readOnly={false}
                    allowSubmit={false}
                    handleSubmitSuccess={() => {}}
                    updateAutoFillFields={updateAutoFillFields}
                    userInfo={props.userInfo}
                    petInfo={props.petsInfo?.pets.filter((p: PetInfoResponse) => p.petId == selectedPet.value)[0]}
                  />
                </div>
                : ""
          }
        </>
      }
    </div>
  );
};

export default SendFormsContainer;
