import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Search from '@material-ui/icons/Search';

import { StyledInput } from './styled-components';

interface InputWithIconProps {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  Icon?: React.FunctionComponent;
  removeFilter?: boolean;
  id?: string;
  height?: string;
  padding?: string;
  style?: React.CSSProperties;
  adornmentColor?: string;
  borderColor?: string;
}

const InputWithIcon = (props: InputWithIconProps): React.ReactElement => {
  const {
    title,
    onChange,
    Icon = Search,
    removeFilter,
    id,
    height,
    style,
    padding,
    adornmentColor,
    borderColor,
  } = props;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (removeFilter) {
      setValue("");
    }
  }, [removeFilter]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    setValue(e.target.value);
    onChange(e);
  };

  return (
    <FormControl style={{ margin: 0, ...style }}>
      <StyledInput
        id={id}
        placeholder={title}
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position='start'>
            <Icon />
          </InputAdornment>
        }
        height={height}
        padding={padding}
        adornmentcolor={adornmentColor}
        fullWidth
        bordercolor={borderColor}
      />
    </FormControl>
  );
};

export default InputWithIcon;
