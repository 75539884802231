import React from 'react';
import FAIconButton from '../Buttons/FAIconButton';

interface JoinAppointmentIconProps {
  setModalIsOpen: (arg: boolean) => void,
  customClasses?: {
    button?: string,
    faIcon?: string
  },
  checkIncall: () => void
}

const JoinAppointmentIcon = (props: JoinAppointmentIconProps) => {
  const handleClick = () => {
    props.setModalIsOpen(true);
    // props.checkIncall();
  };

  return (
    <FAIconButton
      icon={['fas', 'video']}
      handleClick={handleClick}
      customClasses={
        props.customClasses
          ? props.customClasses
          : undefined
      }
    // title="Show appointment"
    />
  )
}

export default JoinAppointmentIcon;
