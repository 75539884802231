import React from 'react';
import { RowParent, ComponentContainer } from './styled-components';

export interface CardRowProps {
  left?: React.ReactElement;
  middle?: React.ReactElement;
  right?: React.ReactElement;
  noMargin?: boolean; // Remove all margins
  style?: React.CSSProperties;
  className?: string;
}

const CardRow = (props: CardRowProps): React.ReactElement => {
  const { left, middle, right, noMargin = false, style = {}, className = '' } = props;
  return (
    <RowParent style={style} className={className}>
      <ComponentContainer noMargin={noMargin} className='left'>
        {left}
      </ComponentContainer>
      <ComponentContainer noMargin={noMargin} className='middle'>
        {middle}
      </ComponentContainer>
      <ComponentContainer noMargin={noMargin} className='right'>
        {right}
      </ComponentContainer>
    </RowParent>
  );
};

export default CardRow;
