/*
This component has 2 uses:
1. Get groupId and practiceId from url params to call api to log in Practice
2. Redirect the to 'http://localhost:3000/Settings' when logged in successfully
3. Pass error props to './AdminLoginPracticeVM.tsx' to show if login fails

This component is imported by 'router\PermissionSpecificRouters.tsx' to assign url path and permission

*/



/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import AdminLoginPracticeV from 'containers/AdminLoginPractice/AdminLoginPracticeV';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { setSessionJWT } from 'global/sessionStorage/SessionJWT';
import { useParams } from 'react-router-dom';
import { useRouter } from 'hooks/useRouter';

interface IProps {
  setRouterJWT: any;
}

const AdminLoginPracticeVM: React.FC<IProps> = (props: IProps): JSX.Element => {
  const param: { groupId?: string, practiceId?: string } = useParams();
  const router = useRouter();

  const [loginError, setLoginError] = useState<string>('');

  useEffect(() => {
    handleSubmitData();
  }, []);

  const handleSubmitData = async () => {
    try {
      const groupId = param.groupId || '';
      const practiceId = param.practiceId || '';
      if (!groupId || !practiceId) throw 'No groupId or practiceId';

      const loginResponse = await configuredRequests.POST.adminLoginPractice(groupId, practiceId);
      sessionStorage.clear();

      setSessionJWT(loginResponse);
      router.push(
        {
          pathname: "/Settings",
          state:
          {
            groupId: groupId,
            practiceId: practiceId,
          }
        }
      )

      window.location.href = "/Settings";

    } catch (err) {
      // Ask BA confirm about error message when login fail
      setLoginError('The practice is not available. Please contact your system administrator for support.');
    }
  };


  return (
    <>
      <AdminLoginPracticeV loginError={loginError} />
    </>
  )
}

export default AdminLoginPracticeVM;
