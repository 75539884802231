import styled from 'styled-components';

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 93%;
  @media only screen and (max-height: 600px) {
    max-height: 88%;
  }
`;

const ExpansionContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export { FlexColumnContainer, ExpansionContainer };
