/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';

const React = require('react');
const { Route, Link } = require('react-router-dom');
const qs = require('qs');

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
const AppLink = ({
  to,
  query,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  ariaCurrent,
  ...rest
}) => (
    <Route
      path={typeof to === 'object' ? to.pathname : to}
      exact={exact}
      strict={strict}
      location={location}
      children={({ location, match }) => {
        let isActive = match;
        const hasQuery = typeof query === 'object';

        if (typeof query === 'object') {
          to = {
            pathname: to,
            search: qs.stringify(query, { sort: (a, b) => a > b }),
          };
        }

        if (getIsActive) {
          isActive = getIsActive(match, location);
        } else if (hasQuery && isActive) {
          isActive = location.search.substring(1) == to.search;
        }

        return (
          <Link
            to={to}
            className={isActive ? [className, activeClassName].filter(i => i).join(' ') : className}
            style={isActive ? { ...style, ...activeStyle } : style}
            aria-current={isActive && ariaCurrent}
            {...rest}
          />
        );
      }}
    />
  );

AppLink.propTypes = {
  to: Link.propTypes.to,
  query: PropTypes.object,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  location: PropTypes.object,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  isActive: PropTypes.func,
  ariaCurrent: PropTypes.oneOf(['page', 'step', 'location', 'true']),
};

AppLink.defaultProps = {
  activeClassName: 'active',
  ariaCurrent: 'true',
};

export default AppLink;
