import { createStyles, makeStyles, StyleRules } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      inline: {
        margin: '0 1rem',
        display: 'inline-block',
      },
    }),
);

// eslint-disable-next-line import/prefer-default-export
export { useStyles };
