import { RedirectLink } from 'components/Communications/Analyze/Performance/Performance';
import DateTimePicker from "components/Global/DateTimePicker/DateTimePicker";
import Table from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TemplateRecipient } from 'global/requests/ResponseTypes';
import { useRouter } from 'hooks/useRouter';
import { isEmpty, pickBy, debounce } from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { convertTimeToSecond, convertPhoneNumber } from 'utils/convertData';
import convertDateToTimestamp from 'utils/convertDateToTimestamp';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isWindows } from 'react-device-detect';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import loading_spinner from 'media/images/loading-spinner.svg';

interface DataTableRecipientType {
  date: string;
  contactName: string;
  emailStatus: string;
  phoneNumber: string;
  emailAddress: string;
  id: string;
}

export const WrapperTable = styled.div`
  .MuiTableContainer-root {
    overflow: auto;
    max-height: calc(100vh - 282px);
  }
`;

const listStatus = [
  { value: '2', label: 'Delivered Emails' },
  { value: '4', label: 'Clicked' },
  { value: '3', label: 'Opened Emails' },
  { value: '5', label: 'Unsuccessful Delivered Emails' },
  { value: '0', label: 'Sent Email' }
];

const columns = [
  { key: 'date', title: 'Date' },
  { key: 'contactName', title: 'Contact Name' },
  { key: 'emailStatus', title: 'Status' },
  { key: 'emailAddress', title: 'Email' },
  { key: 'phoneNumber', title: 'Phone Number' },
];

const Recipients = (): JSX.Element => {
  const router = useRouter();
  const { templateId } = useParams<{ templateId: string }>();
  const [templateName, setTemplateName] = useState<string>("");
  const endDateTime = moment(new Date()).endOf("day").toDate();
  const [loading, setLoading] = useState(true);
  const [queryPagination, setQueryPagination] = useState({ limit: 10, offset: 0 });
  const past30days = moment(endDateTime).subtract(29, 'days').toDate();
  const [startDate, setStartDate] = useState<Date | null>(past30days);
  const [endDate, setEndDate] = useState<Date | null>(endDateTime);
  const [templateRecipients, setTemplateRecipients] = useState<TemplateRecipient>();
  const [textSearch, setTextSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string>('2');

  const getTemplateRecipientDetails = useCallback(async (templateId: string, query: Record<string, unknown>) => {
    try {
      if (templateId) {
        const [response, templateName] = await Promise.all([
          configuredRequests.GET.getTemplateRecipientDetails(templateId, {
            ...query,
            limit: query?.limit ?? 10,
            offset: query?.offset ?? 0,
          }),
          configuredRequests.GET.templateByID(templateId)
        ]);
        setTemplateRecipients(response);
        templateName && templateName.name && setTemplateName(templateName.name);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  }, []);

  useEffect(() => {
    const startTime = startDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    const endTime = endDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());

    getTemplateRecipientDetails(templateId, { startTime, endTime, emailStatus: Number(listStatus.find(status => status.value === selectedStatus)?.value) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  const handleChangePage = useCallback(async (event: unknown, newPage: number) => {
    setQueryPagination(preState => ({ ...preState, offset: newPage }));
    await getTemplateRecipientDetails(templateId, {
      ...queryPagination,
      offset: newPage,
      startTime: convertDateToTimestamp(startDate),
      endTime: convertDateToTimestamp(endDate),
      emailStatus: Number(selectedStatus),
      ...pickBy({ contactName: textSearch.trim() })
    });
  }, [getTemplateRecipientDetails, queryPagination, startDate, endDate, textSearch, templateId, selectedStatus]);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage: number = parseInt(event.target.value);
    if (newRowsPerPage) {
      setQueryPagination({ limit: newRowsPerPage, offset: 0 });
      getTemplateRecipientDetails(templateId, {
        limit: newRowsPerPage,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        emailStatus: Number(selectedStatus),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({ contactName: textSearch.trim() })
      });
    }
  }, [getTemplateRecipientDetails, startDate, endDate, textSearch, templateId, selectedStatus]);

  const onSelectStatus = useCallback((newValue: string) => {
    setSelectedStatus(newValue);
    setQueryPagination(preState => ({ limit: preState.limit, offset: 0 }));
    getTemplateRecipientDetails(templateId, {
      limit: queryPagination.limit,
      offset: 0,
      emailStatus: Number(newValue),
      ...pickBy({ contactName: textSearch.trim() }),
      startTime: convertDateToTimestamp(startDate),
      endTime: convertDateToTimestamp(endDate),
    });
  }, [getTemplateRecipientDetails, textSearch, queryPagination, templateId, startDate, endDate]);

  const searchMessageWithName = useCallback(debounce((name: any) => {
    getTemplateRecipientDetails(templateId, {
      ...pickBy({ contactName: name.trim() }),
      emailStatus: Number(selectedStatus),
      startTime: convertDateToTimestamp(startDate),
      endTime: convertDateToTimestamp(endDate)
    });
  }, 1000), [getTemplateRecipientDetails, selectedStatus, startDate, endDate]);

  const onChangeTextSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setTextSearch(value);
    setQueryPagination({ limit: 10, offset: 0 });
    searchMessageWithName(value);
  }, [searchMessageWithName]);

  return (
    <>
      { loading ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> :
        (<div className="template-recipient">
          <p className="title-template"> Template Analytics for "{templateName}" </p>
          <div className="recipients">
            <div className="communication-container ">
              <div className="communication-container__table w-table-cell ">

                <div className="communication-select-filter">
                  <DateTimePicker
                    showDateRange={true}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    getEndDate
                  />
                  <TextChevronDropdown
                    styleOptions={{
                      width: 270
                    }}
                    className="contacts--filter--select-container background-gray height-40"
                    // @ts-ignore
                    currentSelection={listStatus.find(status => status.value === selectedStatus)?.label}
                    handleChangeSelection={onSelectStatus}
                    selectionOptions={listStatus}
                    useBlackText={true}
                  />
                  <div className="contacts--filter-search-container">

                    <FontAwesomeIcon
                      icon={['far', 'search']}
                      onClick={() => searchMessageWithName(textSearch)}
                    />

                    <input
                      className="contacts--filter--search-input"
                      value={textSearch}
                      type="text"
                      placeholder="Search by Contact Name"
                      onChange={onChangeTextSearch}
                    />

                  </div>
                </div>
                {
                  !isEmpty(templateRecipients?.items) ?
                    <WrapperTable><Table
                      columns={columns}
                      // @ts-ignore
                      rows={templateRecipients?.items.map((item: any) => {
                        return {
                          ...item,
                          date: moment(item.date).format('MM/DD/YYYY'),
                          phoneNumber: item.phoneNumber ? convertPhoneNumber(item.phoneNumber) : '',
                          contactName: <RedirectLink onClick={() => router.push(`/Contacts/View/${item.contactId}`)}>
                            <HighlightText
                              text={item.contactName}
                              searchValue={textSearch.trim()}
                            />
                          </RedirectLink>
                        }
                      }) ?? []}
                      isHasPagination={true}
                      // @ts-ignore
                      totalAvailable={templateRecipients?.totalAvailable ?? 0}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      rowsPerPage={queryPagination?.limit ?? 10}
                      page={queryPagination?.offset ?? 0}
                    /></WrapperTable> : <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                        No results found</p>
                }
              </div>
            </div>
          </div>
        </div>)
      }
    </>
  );
}

export default Recipients;