import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import moment from 'moment';

export const convertDataToMultipleArray = (items: Array<any>) => {
  let bigArray = [];
  let res = [];
  const sortedItems = items.sort(
    (a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime)
  );
  for (let i = 0; i < sortedItems.length; i += 1) {
    if (i === 0) res.push(sortedItems[i]);
    else {
      if (
        moment(sortedItems[i - 1].dateTime).isSame(
          moment(sortedItems[i].dateTime),
          'date'
        )
      )
        res.push(sortedItems[i]);
      else {
        bigArray.push(res);
        res = [sortedItems[i]];
      }
    }
  }
  bigArray.push(res);
  return bigArray;
};

// timeZoneOffset: ex ("UTC+07:00")
export const convertLocalTimeToAnotherTimeZone = (timeZoneOffset: string) => {
  const date = new Date();
  const localTime = date.getTime();

  // get local timezone offset and convert to milliseconds
  const localOffset = date.getTimezoneOffset() * 60000;

  // obtain the UTC time in milliseconds
  const utc = localTime + localOffset;

  const strTzOffset = timeZoneOffset.slice(3); // "UTC+07:00" to "+07:00"
  const tzOffset = moment.duration(strTzOffset).asHours();
  const dateTimeBySetting = utc + 3600000 * tzOffset;

  return moment(dateTimeBySetting);
};

export const convertTimeToSecond = (timestamp: number) => {
  return Math.floor(timestamp / 1000).toString();
};

export const convertPhoneNumber = (value: string) => {
  let input = value.replace(/\D/g, '');
  input = input.substring(0, 10);
  var size = input.length;
  // eslint-disable-next-line eqeqeq
  if (size == 0) {
    // eslint-disable-next-line no-self-assign
    input = input;
  } else if (size < 4) {
    input = '(' + input;
  } else if (size < 7) {
    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
  } else {
    input =
      '(' +
      input.substring(0, 3) +
      ') ' +
      input.substring(3, 6) +
      '-' +
      input.substring(6, 10);
  }
  return input;
};

export const convertStringToCamelCase = (
  strValue: string,
  spaceWord?: boolean
) => {
  var splitStr = strValue.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(spaceWord ? ' ' : '');
};

export const convertToCamelCase = (value: string) => {
  var splitStr = value.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      (i === 0 ? splitStr[i].charAt(0) : splitStr[i].charAt(0).toUpperCase()) +
      splitStr[i].substring(1);
  }
  return splitStr.join('');
};

export const convertMinutesToHours = (data: number) => {
  // const hour = Math.floor(data / 60);
  // const minute = data % 60;
  const { hour, minute } = convertMinutes(data);
  return hour === 0
    ? `${minute} Minutes`
    : minute === 0
    ? `${hour} Hours`
    : `${hour} Hours ${minute} Minutes`;
};

export const convertMinutes = (data: number): any => {
  const hour = Math.floor(data / 60);
  const minute = data % 60;
  return {
    hour,
    minute,
  };
};

export const getSettingByNamespace = (
  userInfo: UserDataResponse,
  namespace: string
) => {
  return userInfo.userSettings?.find((f) => f.namespace === namespace);
};

export const getFullUserName = (user: UserDataResponse) => {
  let fullName = '';
  if (user.name?.givenName) {
    fullName += user.name?.givenName;
  }
  if (user.name?.lastName) {
    fullName += ` ${user.name?.lastName}`;
  }
  return fullName.trim();
};

export const getMicrositeName = () => {
  //@ts-ignore
  let tenantString = sessionStorage.getItem('tenantInfo--full_response');
  if (tenantString == null) {
    return '';
  }
  let tenant = JSON.parse(tenantString);
  return tenant?.micrositeName;
};

export const getPracticeName = () => {
  //@ts-ignore
  let tenantString = sessionStorage.getItem('tenantInfo--full_response');
  if (tenantString == null) {
    return '';
  }
  let tenant = JSON.parse(tenantString);
  return tenant?.tenantName;
};

export const getTimeNowForPractice = (
  oldTimeLocal: Date,
  oldDateNow: Date
): Date => {
  const timeStampChange =
    moment(new Date()).unix() - moment(oldTimeLocal).unix();
  const newTimeStampPractice = moment(oldDateNow).unix() + timeStampChange;

  return new Date(newTimeStampPractice * 1000);
};

export const convertDateToTimestamp = (date: Date) => {
  return (
    Date.parse(
      // @ts-ignore
      new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        )
      )
    ) / 1000
  ).toString();
};

export const formatNumber = (n: number) => {
  const numberAfterFormat = Number(n.toFixed(2)).toLocaleString();
  const arr = numberAfterFormat.split('.');
  if (!arr[1]) {
    return `${numberAfterFormat}.00`;
  }
  if (arr[1].length === 1) {
    return `${numberAfterFormat}0`;
  }
  return numberAfterFormat;
};
