import styled from 'styled-components';

const FlexColumnContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
  flex-grow: 0;
  #chatinput {
    margin-bottom: 0;
  }
  box-sizing: border-box;
`;
const ExpansionContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
`;

export { FlexColumnContainer, ExpansionContainer, InputContainer };
