import styled from 'styled-components';
import { Chip, InputLabel, InputBase } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledInput = styled(InputBase)`
  .MuiInputBase-input {
    border-radius: 6px;
    position: relative;
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 6px 8px;
    max-width: 100%;
    min-height: 24px;
  }

  .MuiSelect-icon {
    right: 10;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
`;

const StyledParentContainer = styled.div`
  white-space: normal;
  margin: -0.2rem 0;
`;

const StyledChip = styled(Chip)`
  .MuiSvgIcon-root {
    z-index: 10;
  }
  height: 24px;
  background-color: #e7eef4;
  margin: 0.2rem;
  font-size: 14px;
`;

const Main = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
`;

export { StyledParentContainer, StyledChip, StyledInput, StyledInputLabel, Main };
