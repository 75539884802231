import APIService from '../clientApi';
import {
  GetAppointmentsParams,
  IAppoinmentsResponse,
  SetAppoinmentStatusParams,
  ISetAppoinmentStatusResponse,
  GetAsapAppointmentsParams,
  IAsapAppoimentsResponse,
  GetReferralsParams,
  IGetReferralsResponse,
  GetRecallsParams,
  IGetRecallsResponse,
  GetFailedWritebacksParams,
  IGetFailedWritebacksResponse,
  MarkAppoinmentAsCompleteParams,
  IMarkAppoinmentAsCompleteResponse,
} from './todoListsTypes';

const API = APIService();

const getAppoinments = async (params: GetAppointmentsParams): Promise<IAppoinmentsResponse> =>
  API.rpc('', { method: 'appointment.findPage', params });

const setAppoinmentStatus = async (
  params: SetAppoinmentStatusParams,
): Promise<ISetAppoinmentStatusResponse> =>
  API.rpc('', { method: 'appointment.setStatus', params });

const markAppoinmentAsComplete = async (
  params: MarkAppoinmentAsCompleteParams,
): Promise<IMarkAppoinmentAsCompleteResponse> =>
  API.rpc('', { method: 'appointment.setFollowUpComplete', params });

const getAsapAppoinments = async (
  params: GetAsapAppointmentsParams,
): Promise<IAsapAppoimentsResponse> => API.rpc('', { method: 'asap.getAsapAppointments', params });

const getReferrals = async (params: GetReferralsParams): Promise<IGetReferralsResponse> =>
  API.rpc('', { method: 'contactevent.findReferrals', params });

const getRecalls = async (params: GetRecallsParams): Promise<IGetRecallsResponse> =>
  API.rpc('', { method: 'contact.findRecalls', params });

const getFailedWritebacks = async (
  params: GetFailedWritebacksParams,
): Promise<IGetFailedWritebacksResponse> =>
  API.rpc('', { method: 'writebacks.findFailedAndStale', params });

export default {
  getAppoinments,
  setAppoinmentStatus,
  markAppoinmentAsComplete,
  getAsapAppoinments,
  getReferrals,
  getRecalls,
  getFailedWritebacks,
};
