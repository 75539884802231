import { TemplateTypes } from 'global/constants/API';
import {
  UserInteractionEntryUnionType,
  UserInteractionResponseUnionType,
} from 'global/requests/ResponseTypes';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import loading_spinner from 'media/images/loading-spinner.svg';
import moment from 'moment';
import React from 'react';
import ComponentizedInteractionCountAndToggles from './InteractionCountAndToggles';
import ComponentizedInteractionEntry from './InteractionEntry';

interface InteractionListProps {
  interactionData: UserInteractionResponseUnionType | undefined;
  userInfo: UserDataResponse | undefined;
  refreshMessageData?: any;
  currentActiveView: string;
}

const { KIND_OF_EMAIL_OR_SMS } = TemplateTypes;

const filterInteractionsByMessageType = (
  interactionsList: UserInteractionEntryUnionType[],
  desiredType: string
) => {
  let kindDefault = KIND_OF_EMAIL_OR_SMS.INTERACTION;
  if (desiredType === 'interactions') {
    return interactionsList;
  }
  return interactionsList.filter(
    (interactionEntry) => interactionEntry.kind === kindDefault
  );
};

//getting all interactions within the last month that are in the status list
const getInteractionCount = (
  list: UserInteractionEntryUnionType[],
  statusList: string[]
) =>
  list.filter(
    (interaction) =>
      statusList.some((stat: string) => stat === interaction.status) &&
      (interaction.status == 'Pending' ||
        moment(interaction.sentDate).isAfter(moment().subtract(1, 'M')))
  ).length;

const pendingStatuses = ['Pending'];

const cancelledStatuses = ['Cancelled'];

const failedStatuses = ['Failed', 'Unsent', 'Undelivered', 'Cancelled'];

const sentStatuses = [
  'Called',
  'Completed',
  'Delivered',
  'Sent',
  'Opened',
  'No Answer',
];

const InteractionList = (props: InteractionListProps) => {
  const [showPending, setShowPending] = React.useState(true);
  const [showSent, setShowSent] = React.useState(true);
  const [showFailed, setShowFailed] = React.useState(true);
  const [showCancelled, setShowCancelled] = React.useState(true);
  const renderInteractions = () => {
    if (!props.interactionData) {
      return (
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      );
    }
    if (props.interactionData.length) {
      return (
        <>
          <ComponentizedInteractionCountAndToggles
            pendingCount={getInteractionCount(
              props.interactionData,
              pendingStatuses
            )}
            sentCount={getInteractionCount(props.interactionData, sentStatuses)}
            failedCount={getInteractionCount(
              props.interactionData,
              failedStatuses
            )}
            cancelledCount={getInteractionCount(
              props.interactionData,
              cancelledStatuses
            )}
            setShowPending={setShowPending}
            setShowSent={setShowSent}
            setShowFailed={setShowFailed}
            setShowCancelled={setShowCancelled}
            showPending={showPending}
            showSent={showSent}
            showFailed={showFailed}
            showCancelled={showCancelled}
          />
          {filterInteractionsByMessageType(
            props.interactionData,
            props.currentActiveView.toLocaleLowerCase()
          ).map((interactionEntry, index) => {
            if (
              pendingStatuses.some(
                (stat: string) => stat === interactionEntry.status
              ) &&
              showPending
            ) {
              return (
                <ComponentizedInteractionEntry
                  userInfo={props.userInfo}
                  reloadList={props.refreshMessageData}
                  key={'interaction-entry--' + index}
                  data={interactionEntry}
                />
              );
            } else if (
              sentStatuses.some(
                (stat: string) => stat === interactionEntry.status
              ) &&
              showSent
            ) {
              return (
                <ComponentizedInteractionEntry
                  userInfo={props.userInfo}
                  reloadList={props.refreshMessageData}
                  key={'interaction-entry--' + index}
                  data={interactionEntry}
                />
              );
            } else if (
              failedStatuses.some(
                (stat: string) => stat === interactionEntry.status
              ) &&
              showFailed
            ) {
              return (
                <ComponentizedInteractionEntry
                  userInfo={props.userInfo}
                  reloadList={props.refreshMessageData}
                  key={'interaction-entry--' + index}
                  data={interactionEntry}
                />
              );
            } else if (
              cancelledStatuses.some(
                (stat: string) => stat === interactionEntry.status
              ) &&
              showCancelled
            ) {
              return (
                <ComponentizedInteractionEntry
                  userInfo={props.userInfo}
                  reloadList={props.refreshMessageData}
                  key={'interaction-entry--' + index}
                  data={interactionEntry}
                />
              );
            }
          })}
        </>
      );
    }
    return <p className='text-center'>No Interaction History</p>;
  };

  return (
    <span className='view-contact--overflow-below-nav-container max-width'>
      {renderInteractions()}
    </span>
  );
};

export default InteractionList;
