/* eslint-disable react-hooks/exhaustive-deps */
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column, GroupInfoType } from 'global/requests/ResponseTypes';
import { get, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Agreement.scss';
import AgreementModal from './AgreementModal';

export type AgreementTableDataType = {
  id: string;
  agreementName: string;
  agreementNote?: string;
  agreementContactEmail: string;
  agreementContactName: string;
  agreementHistory: History[];
  groupId: string;
  groupName: string;

  practiceId: string;
  practiceName: string;
  package: Package;
  price: Price;
};

interface History {
  id: string;
  version: string;
  total: string;
  account: number;
  status: string;
  details: string[];
}

interface Contact {
  name: string;
  email: string;
  phone: string;
}

interface Package {
  id: string;
  package: string;
  monthlyFee: string;
  packageSetupFee: string;
  packageStartDate: string;
}

interface Price {
  label: string;
  singlePriceId: string;
  unitAmount: number;
  value: number;
}

interface AddOn {
  id: string;
  addonName: string;
  addonPrice: string;
  addonStartDate: string;
}

const columns: Column[] = [
  { id: 'agreementName', label: 'Package Name' },
  { id: 'isSignedForDeal', label: 'Signed Status' },
  { id: 'stripePaymentLink', label: 'Payment Link' },
  { id: 'sendEmailForDeals', label: 'Email' },
];

const optionsForFilter = [
  { label: 'All Agreements', value: '' },
  { label: 'Active Agreements', value: 'Active' },
  { label: 'Inactive Agreements', value: 'Inactive' },
];

interface AgreementDataTable {
  id: string;
  isSignedForDeal: string;
  agreementName: string;
  stripePaymentLink: string;
  emailLastSent: string;
}

export interface Deal {
  id: string,
  contactName: string;
  practiceEmail: string;
  notes: string;
  package: {
    package: string;
    selectedPrice: string;
    monthlyFee: string;
    trialPeriodDays: string;
    setupFee: string;
  };
  paymentLink: string;
  createdAt: string;
  isSigned: boolean;
  tenantGroupId: string;
  paymentLinkId: string;
  emailLastSent: string;
}

const mock = [
  {
    id: '1',
    agreementName: 'Agreement Test',
    agreementNote: 'This is note demo',
    agreementContactName: 'Join Wick',
    agreementContactEmail: 'demo@yopmail.com',
    agreementHistory: [
      {
        id: '1',
        version: 'v1(Billing)',
        total: '999.03',
        account: 1,
        status: 'Sent 1 hour ago',
        details: ['Send email now', 'Send email schedule'],
      },
      {
        id: '2',
        version: 'v1(Billing)',
        total: '999.03',
        account: 1,
        status: 'Sent 1 hour ago',
        details: ['Send email now', 'Send email schedule'],
      },
      {
        id: '3',
        version: 'v1(Billing)',
        total: '959.03',
        account: 1,
        status: 'Sent 1 hour ago',
        details: ['Send email now', 'Send email schedule'],
      },
    ],
    groupId: 'f0c5a6bc-6047-4ecb-acf5-89dfc8298a6a',
    groupName: '00000',
    practiceId: '27f1815c-4020-4488-806e-c0c04e4e1381',
    practiceName: '00000',
    price: {
      label: "$349",
      singlePriceId: "price_1Oj8CUHZaw9xFTkGPTtXABV0",
      unitAmount: 349,
      value: 349,
    },
    package: {
      id: '1',
      package: 'Test Package1',
      monthlyFee: '999',
      packageSetupFee: '00',
      packageStartDate: '20/07/2021',
    },
    addons: [
      {
        id: '1',
        addonName: 'Grandfathered Features (monthly fee)',
        addonPrice: '0',
        addonStartDate: '20/07/2021',
      },
      {
        id: '1',
        addonName: 'Grandfathered Features (monthly fee)',
        addonPrice: '0',
        addonStartDate: '20/07/2021',
      },
    ],
  },
];

const AgreementsCustomer = () => {
  const params = useParams<{ groupId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [agreementData, setAgreementData] = useState<AgreementTableDataType[]>(
    []
  );
  const [allDeals, setAllDeals] = useState<Deal[]>([]);
  const [agreementDataTable, setAgreementDataTable] = useState<
    AgreementDataTable[]
  >([]);
  const [dataModal, setDataModal] = useState<Deal>(
    {} as Deal
  );
  const [groupInfo, setGroupInfo] = useState<GroupInfoType>();

  const getAllAgreements = async () => {
    try {
      //@ts-ignore
      const res: Deal[] = await configuredRequests.GET.getDealsByGroupId(params?.groupId);
      if (res) {
        const orderedDeals = res.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((d) => {
          //@ts-ignore
          d.package = JSON.parse(d.package);
          return d;
        })
        setAllDeals(orderedDeals);
        const dataForTable = orderedDeals.map(
          (deal) => {
            return {
            id: deal.id,
            agreementName: deal.package.package,
            isSignedForDeal: deal.isSigned ? 'Signed' : 'Not signed',
            stripePaymentLink: deal.paymentLink,
            emailLastSent: deal.emailLastSent
          }}
        );
        setAgreementDataTable(dataForTable);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getGroupInfo = async () => {
    try {
      const res = await configuredRequests.GET.groupInfo(params?.groupId ?? '');
      if (res) {
        setGroupInfo(res);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    Promise.all([getAllAgreements(), getGroupInfo()])
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
    window.localStorage.setItem('isGroupEdited', 'false');
  }, []);

  const openAgreementDetailModal = (idValue: string) => {
    setIsEdit(idValue !== '');
    // setIdValue(idValue);
    const data = allDeals?.find((item) => item.id === idValue);
    if (data) {
      //@ts-ignore
      setDataModal(data);
    }
    setDisplayModal(true);
  };

  return (
    <div className='communication-container user-setting agreement-setting'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        <h2 className='title-practices'>{get(groupInfo, 'name', '')}</h2>
        <CommunicationTable
          columns={columns}
          dataTable={agreementDataTable}
          isSort={false}
          isDisplayedSearchAndFilter={true}
          isDisplayedSearch={true}
          isDisplayedFilter={true}
          isDisplayedButton={true}
          optionsForFilter={optionsForFilter}
          keySearch='agreementName'
          placeholderSearch='Search by agreement name'
          buttonName='New Agreement'
          iconButton={['far', 'plus']}
          isLoading={isLoading}
          screen={'AGREEMENTS'}
          reloadData={getAllAgreements}
          router={`/Groups/${params.groupId}/Agreements`}
          openModal={(idValue: string) => {
            openAgreementDetailModal(idValue);
          }}
        />
      </div>

      <AgreementModal
        handleReloadList={getAllAgreements}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        edit={isEdit}
        infoData={dataModal}
      />
    </div>
  );
};

export default AgreementsCustomer;
