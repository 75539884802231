import PracticesSetting from "components/Settings/PracticesSetting/PracticesSetting";
import { setTabActiveLocalStorage } from "global/localStorage/tabActive";
import React from "react";
import AgreementsCustomer from "./AgreementsCustomer/AgreementsCustomer";
import GroupGeneral from "./GeneralCustomer/CustomerCompaynyDetail/GroupGeneral";

const GroupDetailContainer = (): JSX.Element => {
  // get data here? or maybe a step up and throw down to here?
  // function readFilter() {}
  const [activeComponent, setActiveComponent] = React.useState<number>(3);

  const handleActiveLink = (numberActive: number) => {
    setActiveComponent(numberActive);
    setTabActiveLocalStorage(null, numberActive, 0);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 1: {
        return <GroupGeneral />;
      }
      case 2: {
        return <AgreementsCustomer />;
      }
      case 3: {
        return <PracticesSetting />;
      }
    }
    return <PracticesSetting />;
  };

  const componentValues: { innerText: string; switchValue: number }[] = [
    {
      innerText: "General",
      switchValue: 1,
    },
    {
      innerText: "Agreements",
      switchValue: 2,
    },
    {
      innerText: "Practices",
      switchValue: 3,
    },
  ];

  // React.useEffect(() => {
    // let tabActive = getTabActiveLocalStorage();
    // if (tabActive) {
    //   setActiveComponent(Number(tabActive.leftMenuContentActive));
    // } else {
    //   setActiveComponent(1);
    // }
    // setActiveComponent(3)
  // }, []);

  return (
    <div className="communication-analyze">
      <div className="communication-analyze__right-modal">
        <ul className="analyze-tab__lists">
          {componentValues.map((componentValue, index) => {
            return (
              <li
                role="button"
                key={"communication-templates-nav-" + index}
                tabIndex={0}
                className={
                  "analyze-tab__item-list " +
                  (activeComponent === componentValue.switchValue
                    ? "active"
                    : "")
                }
                onClick={() => {
                  handleActiveLink(componentValue.switchValue);
                }}
              >
                {componentValue.innerText}
              </li>
            );
          })}
        </ul>
      </div>

      {renderContainer()}
    </div>
  );
};

export default GroupDetailContainer;
