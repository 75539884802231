/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { InputCheckbox } from 'components/Legwork';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RadioButtonGroup from '../../../radio-button-group';

const FormItemSettings = props => {
  const {
    groups,
    contacts,
    onChange,
    form_name,
    is_redirect,
    thank_you,
    redirect_url,
    isRestricted,
    formVersion,
  } = props;

  const handleChange = e => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    onChange(name, value);
  };
  const [groupChoicesIds, setGroupChoicesIds] = useState(formVersion?.documentTypeId ?? '');
  const [contactChoicesIds, setContactChoicesIds] = useState(formVersion?.notifications[0]?.id ?? '');

  const handleGroupChoice = groupSelect => {
    setGroupChoicesIds(groupSelect.target.value);
    onChange('documentTypeId', groupSelect.target.value);
  };

  const handleContactChoice = contactSelected => {
    setContactChoicesIds(contactSelected.target.value);
    onChange('formContacts', [contactSelected.target.value]);
  };

  return (
    <div className='form-item-tab-container with-margin'>
      <div className='row'>
        <div className='control-group'>
          <label htmlFor='form_name' className='control-label'>
            <strong className="label-name">Form Name</strong>
          </label>
          <div className='control'>
            <input
              disabled={isRestricted}
              name='form_name'
              id='form_name'
              type='text'
              className='form-name-input'
              autoFocus
              onChange={handleChange}
              value={form_name}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='control-group'>
          <RadioButtonGroup
            group='is_redirect'
            itemClassNames='radio inline pad-right'
            onChange={val => handleChange({ target: { name: 'is_redirect', value: val === 1 } })}
            items={[
              { label: 'Display thank you message', value: 0 },
              { label: 'Redirect to a new page', value: 1 },
            ]}
            selected={is_redirect ? 1 : 0}
          />
        </div>
        <div className='control-group'>
          {!is_redirect && (
            <div className='control'>
              <textarea
                name='thank_you'
                className='enter-message'
                rows={3}
                placeholder='Your submission was sent securely to our office. We will get back to you shortly.'
                onChange={handleChange}
                value={thank_you || ''}
              />
            </div>
          )}
          {is_redirect && (
            <div className='control'>
              <input
                name='redirect_url'
                type='text'
                className='form-name-input'
                placeholder='Enter a URL'
                onChange={handleChange}
                value={redirect_url || ''}
              />
            </div>
          )}
        </div>
      </div>
      <div className='row'>
        <InputCheckbox
          initiallyChecked={formVersion?.isSyncPatientDocument}
          label='Sync PDF Submission to the Patient Document Center'
          onChange={status => onChange('isSyncPatientDocument', status)}
          className="select-settings"
        />
        <div style={{
          borderRadius: '6px',
          flex: '0 1 auto',
          overflow: 'hidden',
          background: 'white',
          border: '1px solid #cccccc',
          height: '40px',
        }}>
          <select
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              outline: 'none',
              marginBottom: '0',
            }}
            disabled={!formVersion?.isSyncPatientDocument}
            id='filter-select'
            value={groupChoicesIds}
            onChange={handleGroupChoice}>
            {groups.map(group => {
              return <option key={group.value} value={group.value}> {group.label} </option>;
            })}
          </select>
        </div>
        {/* <MultiSelectWithTopLabel
          menuItems={filteredGroups()}
          title='Select Group(s)'
          defaultValue={initiallySelectedGroups()}
          reporter={handleGroupChoice}
        /> */}
        {/* <TextChevronDropdown
          styleOptions={{
            width: '100%'
          }}
          className="contacts--filter--select-container background-gray height-40 select-settings"
          currentSelection={''}
          handleChangeSelection={() => {}}
          selectionOptions={groups}
          useBlackText={true}
        /> */}
      </div>
      <div className='row'>
        <InputCheckbox
          className="select-settings"
          initiallyChecked={formVersion?.IsSendSubmissionEmail}
          label='Send submission email notifications'
          onChange={status => onChange('IsSendSubmissionEmail', status)}
        />

        {/* <MultiSelectWithTopLabel
          menuItems={filteredContacts()}
          className={!formVersion.IsSendSubmissionEmail ? '.Mui-disabled' : ''}
          reporter={handleContactChoice}
          defaultValue={initiallySelectedUsers()}
        /> */}

        <div style={{
          borderRadius: '6px',
          flex: '0 1 auto',
          overflow: 'hidden',
          background: 'white',
          border: '1px solid #cccccc',
          height: '40px',
        }}>
          <select
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              outline: 'none',
              marginBottom: '0',
            }}
            disabled={!formVersion?.IsSendSubmissionEmail}
            id='filter-select'
            value={contactChoicesIds}
            onChange={handleContactChoice}>
            {contacts.map(contact => {
              return <option key={contact.value} value={contact.value}> {contact.label} </option>;
            })}
          </select>
        </div>
      </div>
      <div className='row'>
        <InputCheckbox
          initiallyChecked={formVersion?.allowDisplayInMicrosites ?? true}
          label='Allow this form to be displayed in microsites'
          onChange={status => onChange('allowDisplayInMicrosites', status)}
          className="select-settings"
        />
      </div>
    </div>
  );
};

FormItemSettings.propTypes = {
  formGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  formContacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  contacts: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  form_name: PropTypes.string.isRequired,
  is_redirect: PropTypes.bool,
  thank_you: PropTypes.string.isRequired,
  redirect_url: PropTypes.string.isRequired,
  has_group: PropTypes.bool,
  has_contact: PropTypes.bool,
  formId: PropTypes.number.isRequired,
  isRestricted: PropTypes.bool.isRequired,
};

FormItemSettings.defaultProps = {
  groups: [],
  contacts: [],
  is_redirect: false,
  has_group: false,
  has_contact: false,
};

export default FormItemSettings;
