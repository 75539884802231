import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { SMSEntry } from 'global/requests/ResponseTypes';
import styled from 'styled-components';
import toast from 'components/Global/Toast';
import parse from 'html-react-parser';
import { InfoSending } from './MessengerInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/pro-solid-svg-icons';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { createSingleReceiverSMSPayload } from './MessengerInput';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import Lightbox from 'react-image-lightbox';
import { urlPattern } from 'utils/regex';
import { debounce } from 'lodash';
import { Button, TextareaAutosize, TableCell, TableRow } from '@material-ui/core';
import { PetSummaryCollectionResponse, PetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import { TextWritebackEmitterRequestType } from 'global/requests/ResponseTypes/Emitter';
import ModalDialog from 'components/Global/Modal';
import Table from 'components/Global/Table';

const MessageWrapped = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 35px;
  .messenger--chat-msg {
    opacity: ${(props: any) => (props.isError ? '0.5' : '1')};
  }
`;

const ListImagesWrapped = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  img {
    max-width: 200px;
    max-height: 200px;
    margin: 5px;
  }
  flex: 1;
  flex-wrap: wrap;
`;

const InfoSendingWrapped = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props: any) =>
    props.isSender ? 'flex-end' : 'flex-start'};
  div {
    width: ${(props: any) => (props.isError ? '53%' : '51%')};
    display: flex;
    justify-content: ${(props: any) =>
      (props.isSending && props.isError) || (props.isSending && props.isLoading)
        ? 'space-between'
        : props.isSender
        ? 'flex-end'
        : 'flex-start'};
  }
  span {
    margin-top: 10px;
  }
  .resend-message {
    cursor: pointer;
    color: #0a6cff;
    font-size: 13px;
    left: 4px;
  }
  .sending-message {
    color: #0a6cff;
    font-size: 13px;
    left: 4px;
  }
`;

interface Edit {
  show: boolean;
  value: string;
  error: boolean;
}

interface ChatMessageProps extends SMSEntry {
  senderName: string;
  contactID: string;
  infoSending: InfoSending;
  setInfoSending: React.Dispatch<React.SetStateAction<InfoSending>>;
  refreshMessageData: (userID: string) => void;
  id: string;
  isSending?: boolean;
  messageIndex?: number;
  isLastChild?: boolean;
  searchMessageId: string;
  searchTextMemory: string | undefined;
  allowTextMessageWriteback: boolean;
}

const customStyles = {
  content: {
    width: '100%',
  },
};

const columnsPet = [
  { key: 'name', title: 'Name' },
  { key: 'sex', title: 'Sex' },
  { key: 'breed', title: 'Breed' },
];

const styleCellBody: React.CSSProperties = {
  fontFamily: 'Nunito Sans, Calibri, Arial',
  fontSize: '.8125rem',
  fontWeight: 'normal',
  letterSpacing: '0',
  lineHeight: '18px',
};

const ChatMessage = (props: ChatMessageProps) => {
  // determines name shown, color scheme, left/right alignment
  const mediaUrls = props.mediaUrls as any;
  const isMessageOutbound =
    props.createdBy !== '00000000-0000-0000-0000-000000000000' &&
    props.createdBy !== props.contactID;
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const [displayPetsModal, setDisplayPetsModal] = React.useState(false);
  const [noteInfo, setNoteInfo] = useState<Edit>({
    show: props.notes ? true : false,
    value: props.notes || '',
    error: false,
  });
  const [allUserPetData, setAllUserPetData] = React.useState<PetSummaryCollectionResponse>();
  const [selectedPet, setSelectedPet] = useState<string>('');

  useEffect(() => {
    if (props.infoSending.isError && props.isLastChild) {
      setIsError(true);
    } else if (!props.infoSending.isError && props.isLastChild) {
      setIsError(false);
    }
    if (props.infoSending.sending && props.isLastChild) {
      setLoading(true);
    } else if (!props.infoSending.sending && props.isLastChild) {
      setLoading(false);
    }
  }, [props.infoSending, props.isLastChild]);

  const handleToggleEditNote = () => {
    if (noteInfo.show) {
      handleSaveNote('');
    }
    setNoteInfo((prev) => ({ value: '', show: !prev.show, error: false }));
  };

  const handleSaveNote = useCallback(
    async (note: string, isShowResult?: boolean) => {
      try {
        const res = await configuredRequests.PATCH.updateMessageNote(
          props.id,
          note
        );
        if (res) {
          isShowResult &&
            toast.success('Your data has been saved successfully!');
        }
      } catch {
        isShowResult &&
          toast.error('Your data has not been saved yet. Please try again.');
      }
    },
    [props.id]
  );

  const handleUpdateNote = useCallback(
    debounce((value: string) => {
      handleSaveNote(value);
    }, 1000),
    []
  );

  const handleChangeNote = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (value.length > 500) {
        setNoteInfo((prev) => ({ ...prev, error: true }));
      } else {
        handleUpdateNote(value);
        setNoteInfo((prev) => ({ ...prev, value, error: false }));
      }
    },
    [handleUpdateNote]
  );

  const sendMessagePostRequest = async () => {
    setLoading(true);
    setIsError(false);
    // props?.setInfoSending({ sending: true, isError: false });
    try {
      // @ts-ignore
      const payload = createSingleReceiverSMSPayload(
        props.content.trim(),
        props.mediaUrls
      );
      const response = await configuredRequests.POST.sendSingleUserSMS(
        props.contactID,
        payload
      );
      if (response) {
        props.refreshMessageData && props.refreshMessageData(props.contactID);
      }
      setLoading(false);
    } catch (err) {
      setIsError(true);
      setLoading(false);
      console.log('err: ', err);
    }
  };

  const handleDisplayPets = async () => {
    try {
      const res = await configuredRequests.GET.allUserPetsByUserID(props.contactID);
      if (res) {
        const filteredPetArr = res.pets.filter(pet => pet.status === 'active');
        setAllUserPetData({count:res.count, pets:filteredPetArr});
        setDisplayPetsModal(true);
        setIsHover(false);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const handleSyncToPatientRecord = async () => {
    setDisplayPetsModal(false);
    var payload: TextWritebackEmitterRequestType = {
      methodId: "TextWriteback",
      methodName: "Writeback Text Message",
      contactId: props.contactID,
      petId: selectedPet,
      messageId: props.id,
      tenantId: "",
    }
    try {
      const res = await configuredRequests.POST.createTextMessageEmitterExecute(payload);
      if (res) {
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      console.log('error', err);
    }
    setSelectedPet('');
  }

  const renderDataTablePet = (pet: PetInfoResponse, field: string): string => {
    switch (field) {
      case 'name':
        return pet.name;
      case 'sex':
        return pet.sex ?? "";
      case 'breed':
        return pet.breed ?? "";
      default:
        return '';
    }
  };

  const modalBodyPets = (): React.ReactElement => {
    return (
      <>
        <div className='model-body__content custom-scroll'>
          <p className='modal-body__text'>
            Please choose a pet to write back the text message to.
          </p>

          <Table
            columns={columnsPet}
            tableBody={
              <>
                {allUserPetData?.pets.map((pet) => (
                  <TableRow
                    key={pet.petId}
                    className={`${pet.petId === selectedPet ? 'active' : ''}`}
                    onClick={() => setSelectedPet(pet.petId)}
                  >
                    {columnsPet.map((column) => (
                      <TableCell key={column.key} style={styleCellBody} className={pet.name == allUserPetData.pets[0]?.name ? "" : "form-pet-name"}>
                        {renderDataTablePet(pet, column.key)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            }
            isHasPagination={false}
          />
        </div>

        <div className='modal-footer'>
          <button
            className='btn btn-left'
            onClick={() => setDisplayPetsModal(false)}
          >
            Cancel
          </button>
          <div className='btn-group-right'>
            <button
              disabled={selectedPet == ''}
              className={selectedPet == '' ? 'btn btn-right btn-disabled' : 'btn btn-right'}
              onClick={() => handleSyncToPatientRecord()}
            >
              Save Text Message to Patient Record
            </button>
          </div>
        </div>
      </>
    );
  };


  return (
    <MessageWrapped 
      // @ts-ignore
      isError={isError}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ "backgroundColor": isHover ? "white" : ""}}
    >

        <div className="messenger--chat-msg-sync-wrapper">
          <div
            className={`messenger--chat-msg-wrapper ${isMessageOutbound
                ? 'sent messenger--chat-msg-sender'
                : 'received messenger--chat-msg-received'
              }`}
          >
            <>
              <li
                id={props.id}
                className={`messenger--chat-msg ${isMessageOutbound ? 'bg-sender' : 'bg-received'
                  } 
                ${noteInfo.show ? 'hide--border-radius' : ''}
              `}
              >
                <div className='messenger--chat-msg-container'>
                  <span className='btn-add-note'>
                    <FontAwesomeIcon
                      icon={['far', noteInfo.show ? 'minus' : 'plus']}
                      onClick={handleToggleEditNote}
                    />
                  </span>
                  <span className='messenger--chat-msg-title'>
                    {props.id === props.searchMessageId ? (
                      <HighlightText
                        text={props.senderName}
                        searchValue={props.searchTextMemory}
                      />
                    ) : (
                      <span>{props.senderName}</span>
                    )}
                  </span>

                  <div
                    className={`messenger--chat-msg-body  ${isMessageOutbound ? 'bg-sender' : 'bg-received'
                      }`}
                  >
                    {props.content
                      ? props.content.split('\n').map((item, i) => {
                        let isJsx = false;
                        const newMess = item
                          .split(' ')
                          .map((text) => {
                            if (
                              (text.toLowerCase().indexOf('https://') === 0 ||
                                text.toLowerCase().indexOf('http://') === 0 ||
                                text.toLowerCase().indexOf('www.') === 0) &&
                              urlPattern.test(text)
                            ) {
                              isJsx = true;
                              return `<a href="${text.toLowerCase().indexOf('www.') === 0
                                  ? '//'
                                  : ''
                                }${text}" target="_blank" rel="noopener noreferrer" class="lnk--in-mess">${text}</a>`;
                            }
                            return text;
                          })
                          .join(' ');

                        if (props.id === props.searchMessageId) {
                          return (
                            <>
                              <HighlightText
                                text={item}
                                searchValue={props.searchTextMemory}
                                isJsx={isJsx}
                              />
                              <br />
                            </>
                          );
                        }
                        return (
                          <React.Fragment key={i}>
                            {parse(newMess)}
                            <br />
                          </React.Fragment>
                        );
                      })
                      : ''}

                    {
                      // @ts-ignore
                      props?.mediaUrls?.length > 0 && (
                        <ListImagesWrapped>
                          {
                            // @ts-ignore
                            props?.mediaUrls?.map((url: string, index: number) => (
                              <img
                                src={url}
                                alt={url}
                                key={url}
                                onClick={() => {
                                  setIsOpen(true);
                                  setPhotoIndex(index);
                                }}
                              />
                            ))
                          }
                        </ListImagesWrapped>
                      )
                    }
                    {isOpen && (
                      <Lightbox
                        reactModalStyle={customStyles}
                        mainSrc={mediaUrls[photoIndex]}
                        nextSrc={mediaUrls[(photoIndex + 1) % mediaUrls.length]}
                        prevSrc={
                          mediaUrls[
                          (photoIndex + mediaUrls.length - 1) % mediaUrls.length
                          ]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + mediaUrls.length - 1) % mediaUrls.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % mediaUrls.length)
                        }
                        clickOutsideToClose={true}
                      />
                    )}
                  </div>
                </div>
              </li>
              {noteInfo.show && (
                <>
                  <div className='area-input'>
                    <TextareaAutosize
                      aria-label='message-note'
                      className='input-note'
                      value={noteInfo.value}
                      onChange={handleChangeNote}
                    />

                    <Button
                      variant='contained'
                      color='primary'
                      className='cancel-button'
                      onClick={() => handleSaveNote(noteInfo.value, true)}
                    >
                      <span>Save</span>
                    </Button>
                  </div>
                  {noteInfo.error && (
                    <p className='error'>Cannot input more than 500 characters.</p>
                  )}
                </>
              )}
            </>
            {props?.isSending && isError && (
              <FontAwesomeIcon
                icon={['fas', 'exclamation-circle']}
                color='#E02020'
              />
            )}
          </div>
          {props.allowTextMessageWriteback && isHover ?
            <div className="messenger--chat-msg-sync-button-wrapper" style={!isMessageOutbound ? {"left": "65%"} : {"right": "65%"}}>
              <span onClick={handleDisplayPets} className="messenger--chat-msg-sync-button">
                <FontAwesomeIcon
                  style={{"marginRight": "5px"}}
                  icon={['fas', 'upload']}
                />
                Sync to Patient Record
              </span>
            </div>
          : ""}
        </div>

      {
        <InfoSendingWrapped
          // @ts-ignore
          isSender={isMessageOutbound}
          isError={isError}
          isSending={props.isSending}
          isLoading={loading}
        >
          <div>
            {props?.isSending && isError && (
              <span className='resend-message' onClick={sendMessagePostRequest}>
                <FontAwesomeIcon icon={faRedo} color='#0A6CFF' /> Resend
              </span>
            )}
            {props?.isSending && loading && (
              <span style={{ color: '#1974ff', fontSize: '13px' }}>
                Sending...
              </span>
            )}
            <span
              style={{ fontSize: '13px' }}
              className={
                'messenger--chat-msg-datetime' +
                (isMessageOutbound ? ' sent' : ' received')
              }
            >
              {moment(props.sentDate).format('ddd, MMM Do, YYYY hh:mm A')}
            </span>
          </div>
        </InfoSendingWrapped>
      }
      {displayPetsModal && (
        <ModalDialog
          isOpenModal={displayPetsModal}
          title='Select Pet'
          handleCloseModal={() => setDisplayPetsModal(false)}
          isShowButtonModal={false}
          classMore='modal--select-table'
        >
          {modalBodyPets()}
        </ModalDialog>
      )}
    </MessageWrapped>
  );
};

export default React.memo(ChatMessage);
