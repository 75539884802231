import moment from 'moment';

const Months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

const Year: string = moment().format('YYYY');
const Month: number = -1 + parseInt(moment().format('M'), 10);

export { Months, Year, Month };
