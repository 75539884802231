import styled from 'styled-components';
import { getThemeProp } from '../utils';

const ColoredDiv = styled.div`
  background-color: ${getThemeProp('palette.background.default')};
  height: 100%;
  padding: 1rem;
`;

// eslint-disable-next-line import/prefer-default-export
export { ColoredDiv };
