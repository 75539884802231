import React from 'react';

const Loading = () => {
    return (
        <div style={{ margin: '20px' }}>
            <p style={{ fontWeight: 'bold' }}>
                <span>Loading...</span>
            </p>
        </div>
    );
};

export default Loading;