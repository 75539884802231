import React from 'react';
import classNames from 'classnames';

const FormFieldInputRadio = ({
  className,
  label,
  isOther,
  displayAsColumns,
  numColumns,
  group,
  isRenderedField,
  onChange,
  value,
  index,
  ...rest
}) => {
  const handleChange = e => {
    let value = label;

    // set value to sibling <input type="text"/> DOM element
    // if isOther === true and the target element being changed
    // is <input type="radio"/>
    if (isOther && e.target.type === 'radio') value = e.target.nextSibling.value;

    onChange(e, group, value, index);
  };

  const classes = classNames({
    'radio-container': true,
    'inline-block': displayAsColumns,
    [className]: true,
  });
  const style = {
    width: displayAsColumns ? `${(1 / parseInt(numColumns)) * 100}%` : 'auto',
  };

  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = handleChange;
    dynamicProps.name = group;
    dynamicProps.disabled = rest.disabled;
    if (isOther) {
      dynamicProps.placeholder = label;
      dynamicProps.checked = value !== undefined;
    } else dynamicProps.value = label;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  return (
    <div className={classes} style={style}>
      <label>
        <input type='radio' {...dynamicProps} {...rest} />
        {isOther ? (
          <input type='text' placeholder={label} value={value || ''} {...dynamicProps} />
        ) : (
            label
          )}
      </label>
    </div>
  );
};

FormFieldInputRadio.defaultProps = {
  className: '',
  isRenderedField: false,
  onChange: () => { },
};

export default FormFieldInputRadio;
