import $ from 'jquery';

const calculate = (text, classes, escape) => {
  classes = classes || [];
  text = text.replace(/\s/g, '/'); // replace whitespace with slash which most resembles width of a space on the screen, it wasn't counting it

  if (escape === undefined) {
    escape = true;
  }

  classes.push('textDimensionCalculation');

  const div = document.createElement('div');
  div.setAttribute('class', classes.join(' '));

  if (escape) {
    $(div).text(text);
  } else {
    div.innerHTML = text;
  }

  document.body.appendChild(div);

  const width = $(div).outerWidth();

  div.parentNode.removeChild(div);

  return width;
};

export { calculate };
