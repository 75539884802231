import styled from 'styled-components';
import getThemeProp from 'components/Legwork/utils/getThemeProp';
import Card from '@material-ui/core/Card';

const StyledRule = styled.hr`
  color: ${getThemeProp('palette.divider', '#E3EBF6')};
  border-top: 0;
  opacity: 0.4;
  margin: 0;
`;

const StyledCard = styled(Card)`
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 rgba(18, 38, 63, 0.03);
  border: 1px solid ${getThemeProp('palette.divider', '#E3EBF6')};
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledRule, StyledCard };
