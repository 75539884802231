/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment from 'moment';
import loading_spinner from 'media/images/loading-spinner.svg';
import toast from 'components/Global/Toast';

type UploadComponentProps = {
  setFieldValue: any;
  values: any;
  setErrorUploadFile: any;
};

const UploadComponent = (props: UploadComponentProps) => {
  const { setFieldValue, values, setErrorUploadFile } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'application/zip': ['.zip']},
    onDrop: async (acceptedFiles: any) => {
      setFieldValue('files', acceptedFiles);
      let formData = new FormData();
      formData.append('attachment', acceptedFiles[0]);
      setIsLoading(true);
      try {
        setErrorUploadFile('');
        const res = await configuredRequests.POST.uploadConfigurator(formData);
        if (res) {
          setIsLoading(false);
          toast.success('File uploaded successfully');
          setFieldValue(
            'releasedDate',
            moment(res.releasedDate).format('YYYY-MM-DDTHH:mm')
          );
          setFieldValue('version', res.versionName);
          setFieldValue('downloadLink', res.downloadLink);
        }
      } catch (err) {
        setIsLoading(false);
        setFieldValue('releasedDate', '');
        setFieldValue('version', 'N/A');
        setFieldValue('downloadLink', ' ');
        toast.error('File upload failed');
      }
    },
  });

  return (
    <>
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <h5>Add new version</h5>
              <FontAwesomeIcon icon={['fas', 'folder-plus']} />
              <p>
                {' '}
                <span>Add file</span> or Drop your files here
              </p>
              {values.files &&
                values.files.map((file: any, i: any) => (
                  <li key={i}>
                    {
                      //@ts-ignore
                      `File:${file.name} Size:${file.size} bytes`
                    }{' '}
                  </li>
                ))}
            </>
          )}
        </div>
        <div className='spinner-upload'>
          {isLoading ? <img src={loading_spinner} alt={'Loading'} /> : ''}
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
