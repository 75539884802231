// This file and './Router.tsx' are to set up route system for the whole app
// './AuthorizationRouter' is to set up role-based and account-based protected routes

import GroupDetailContainer from 'components/Customers/CustomerDetail/GroupDetailContainer';
import PracticeGaneral from 'components/Customers/CustomerDetail/GroupPractices/PracticeGaneral';
import Groups from 'components/Customers/Groups';
import PopupShowImage from 'components/Global/PopupShowImage/PopupShowImage';
import PopupShowImageIncall from 'components/Global/PopupShowImageIncall/PopupShowImageIncall';
import PopupShowVideo from 'components/Global/PopupShowVideo/PopupShowVideo';
import ResetPassword from 'components/ResetPassword/ResetPassword';
import ViewUserDetailContainer from 'components/Settings/UsersSetting/UsersSettingDetail';
import AdminLoginPracticeVM from 'containers/AdminLoginPractice/AdminLoginPracticeVM';
import DailyAppointmentsContainer from 'containers/Appointments/DailyAppointments';
import BillingPortal from 'containers/BillingPortal/BillingPortal';
import CommunicationPerformanceContainer from 'containers/Communications/Communications';
import ConfirmAppointment from 'containers/ConfirmAppointment';
import AlertSystemAdmin from 'containers/AlertSystemAdmin/AlertSystemAdmin';
import ContactsContainerWithCache from 'containers/Contacts/ContactsContainer';
import DashboardContainer from 'containers/Dashboard/DashboardContainer';
import LoginPage from 'containers/Login/LoginContainer';
import MessengerContainer from 'containers/Messenger/MessengerContainer';
import WithNavigation from 'containers/Navigation/WithNavigation';
import FormBuilder from 'containers/PaperlessForms/FormBuilder';
import SetPasswordPage from 'containers/SetPassword/SetPasswordContainer';
import SettingsContainer from 'containers/Settings/Settings';
import SettingSystemAdmin from 'containers/SettingSystemAdmin/SettingSystemAdmin';
import SyncerContainer from 'containers/SyncerContainer/SyncerContainer';
import TelemedicineContainer from 'containers/Telemedicine/TelemedicineContainer';
import AppointmentBookingVM from 'containers/AppointmentBooking/AppointmentBookingVM';
import TemplateContainer from 'containers/Template/TemplateContainer';
import Invoicing from 'containers/Invoicing/InvoicingVM';
import { getSessionJWT } from 'global/sessionStorage/SessionJWT';
import {
  convertRoleOrScreen,
  listMatchRouterWithScreen,
} from 'hooks/useDecodeToken';
import { get } from 'lodash';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { NoPermissionRouterProps, RouterProps, ValidJWT } from 'types/RouterT';
import MobileStoreConnect from '../containers/MobileStoreConnect/MobileStoreConnect';
import TelemedicineVideoCall from '../containers/TelemedicineVideoCall/TelemedicineVideoCall';
import AuthorizationRouter from './AuthorizationRouter';
import PreviewShareForm from 'components/FormBuilder/components/FormBuilder/forms/components/form-item-preview-share';
import AllSubmissionsContainer from 'components/FormBuilder/components/FormBuilder/AllSubmissions/AllSubmissionsContainer';
import NotAccessHP24 from 'containers/Invoicing/NotAccessHP24';
import AgreementDetail from 'components/Customers/CustomerDetail/AgreementsCustomer/AgreementDetail';
import AgreementGenerate from 'components/Customers/CustomerDetail/AgreementsCustomer/AgreementGenerate';
import PracticeLoginContact from 'containers/PracticeLoginContact/PracticeLoginContactVM';

const NotFoundPage = () => {
  return (
    <div
      style={{
        width: 'max-content',
        margin: 'auto',
        fontSize: '72px',
        color: 'black',
      }}
    >
      404 Not Found
    </div>
  );
};
// used to generate unique keys for React because of the way we share/reuse these route objects
let routeUniqueKeyIndex = Math.random();

// We can define all our routes here and assign the relevant routes to each permission's router
// This will enforce a consistent experience between permissions, simply removing endpoints
// If a route doesn't use <WithNavigation/> make sure to set document.title somewhere
// There will need to be logic implemented to update the sidebar data and remove unavailable routes
// To accomplish that we can store the render data on a lookup and create permission collections (like we're doing here).

const RouteLookup = {
  //Paths are not case sensitive, but we should consistently style them capitalized

  NotAccessHp24: (props: NoPermissionRouterProps) => (
    <Route
      path='/payment/logout'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <NotAccessHP24 />
    </Route>
  ),

  Login: (props: NoPermissionRouterProps) => (
    <Route path='/Login' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <LoginPage setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  SetPassword: (props: NoPermissionRouterProps) => (
    <Route path='/SetPassword' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <SetPasswordPage setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  ResetPassword: (props: NoPermissionRouterProps) => (
    <Route path='/ResetPassword' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <ResetPassword setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  PreviewFormBuilder: (props: NoPermissionRouterProps) => (
    <Route path='/forms/:formId' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <PreviewShareForm setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  Dashboard: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/Dashboard'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Dashboard'}
          mainComponent={DashboardContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />

    // for some reason this is causing an infinite loop of redirecting and breaks the app,
    // spent a few hours researching to make this work but to no avail
    // Edit: may have solved this by changing a redirect dealing with no sidebar data, need to test
    // <ProtectedRoute path="/Dashboard" key={"protect-route-" + routeUniqueKeyIndex}>
    //   <WithNavigation headerTitle={"Dashboard"} mainComponent={DashboardContainer} mainComponentClasses={"horizontal-scroll"}/>
    // </ProtectedRoute>
  ),

  Appointments: (props: RouterProps) => (
    <AuthorizationRouter
      path='/Appointments'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Appointments'}
          mainComponent={DailyAppointmentsContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  Messenger: (props: RouterProps) => (
    <AuthorizationRouter
      path='/Messenger'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Conversations'}
          mainComponent={MessengerContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  AlertsAdmin: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/Alerts'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Alerts'}
          mainComponent={AlertSystemAdmin}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  Telemedicine: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/Telemedicine'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Telemedicine'}
          mainComponent={TelemedicineContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  CommunicationPerformance: (props: RouterProps) => (
    <AuthorizationRouter
      path='/Communications'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Communications'}
          mainComponent={CommunicationPerformanceContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),


  Template: (props: RouterProps) => (
    <AuthorizationRouter
      path='/Dashboard/Template/:urlSlug'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Template'}
          mainComponent={TemplateContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  Settings: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/Settings'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Settings'}
          mainComponent={SettingsContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  AllSubmissions: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/AllSubmissions'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle='All Submissions'
          mainComponent={AllSubmissionsContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  Syncer: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/Syncer'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Syncer'}
          mainComponent={SyncerContainer}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  PracticeDetailSyncer: (props: RouterProps) => (
    <Route
      path='/Practices/:practiceId/Syncer'
      exact
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <WithNavigation
        headerTitle={'Syncer'}
        mainComponent={PracticeGaneral}
        setRouterJWT={props.setRouterJWT}
        routerJWT={props.routerJWT}
      />
    </Route>
  ),

  BillingPortal: (props: RouterProps) => (
    <AuthorizationRouter
      path='/Billing'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Billing'}
          mainComponent={BillingPortal}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  SettingsAdmin: (props: RouterProps) => (
    <AuthorizationRouter
      exact
      path='/SettingsAdmin'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Settings'}
          mainComponent={SettingSystemAdmin}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  UserDetails: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        path='/SettingsAdmin/User/:userId'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Return to Settings'}
            mainComponent={ViewUserDetailContainer}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  UserDetailsSettings: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        path='/Settings/User/:userId'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Return to Settings'}
            mainComponent={ViewUserDetailContainer}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  TelemedVideoCall: () => {
    return (
      <AuthorizationRouter
        path='/Telemedicine/videocall'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => <TelemedicineVideoCall />}
      />
    );
  },

  CustomersSetting: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        exact
        path='/Groups'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Groups'}
            mainComponent={Groups}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  CustomerDetail: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        exact
        path='/Groups/:groupId'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Back to All Groups'}
            mainComponent={GroupDetailContainer}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  PracticeDetail: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        path='/Groups/:groupId/Practices/:practiceId'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Back to All Practices'}
            mainComponent={PracticeGaneral}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  AgreementDetail: (props: RouterProps) => {
    return (
      <AuthorizationRouter
        path='/Groups/:groupId/Agreements/:agreementId'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Back to All Agreements'}
            mainComponent={AgreementDetail}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />
    );
  },

  AgreementGenerate: (props: RouterProps) => (
    <Route
      path='/agreement/:ageementId'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <AgreementGenerate />
    </Route>
  ),

  // Invoicing: (props: RouterProps) => {
  //   return (
  //     // <AuthorizationRouter
  //     //   path="/Invoicing"
  //     //   key={'Router-lookup-' + routeUniqueKeyIndex++}
  //     //   component={() =>
  //     //     <WithNavigation
  //     //       headerTitle={'Invoicing'}
  //     //       mainComponent={() => <div>Invoicing</div>}
  //     //       setRouterJWT={props.setRouterJWT}
  //     //       routerJWT={props.routerJWT}
  //     //     />}
  //     // />
  //     <Route
  //       key={'Router-lookup-' + routeUniqueKeyIndex++}
  //       path='/Invoicing'
  //       component={() => (
  //         <WithNavigation
  //           headerTitle={'Invoicing'}
  //           mainComponent={() => <Invoicing />}
  //           setRouterJWT={props.setRouterJWT}
  //           routerJWT={props.routerJWT}
  //         />
  //       )}
  //     />
  //   );
  // },

  ContactsWithCache: (props: RouterProps) => {
    return [
      <Route
        exact
        path='/Contacts'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
      >
        <Redirect to='/Contacts/1' />
      </Route>,
      <AuthorizationRouter
        path='/Contacts'
        key={'Router-lookup-' + routeUniqueKeyIndex++}
        component={() => (
          <WithNavigation
            headerTitle={'Contacts'}
            mainComponent={ContactsContainerWithCache}
            mainComponentClasses={'horizontal-scroll vertical-scroll'}
            setRouterJWT={props.setRouterJWT}
            routerJWT={props.routerJWT}
          />
        )}
      />,
    ];
  },

  confirmAppointment: () => (
    <Route
      path='/confirmAppointment'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <ConfirmAppointment />
    </Route>
  ),

  RedirectBaseURL: () => {
    const jwt = getSessionJWT();
    const screens = convertRoleOrScreen(get(jwt, 'screens'));

    return (
      <Route exact path='/' key={'Router-lookup-' + routeUniqueKeyIndex++}>
        {screens.length > 0 ? (
          <Redirect push to={`${get(listMatchRouterWithScreen, screens[0])}`} />
        ) : (
          <div>Unauthorized</div>
        )}
      </Route>
    );
  },

  PopupShowImage: () => {
    return (
      <Route
        path={`/List-image/:appointmentID`}
        key={'Router-lookup-' + routeUniqueKeyIndex++}
      >
        <PopupShowImage />
      </Route>
    );
  },

  PopupShowImageIncall: () => {
    return (
      <Route
        path={`/Image-detail/:appointmentID/:imageID`}
        key={'Router-lookup-' + routeUniqueKeyIndex++}
      >
        <PopupShowImageIncall />
      </Route>
    );
  },

  PopupShowVideo: () => {
    return (
      <Route
        path={`/video/:appointmentID`}
        key={'Router-lookup-' + routeUniqueKeyIndex++}
      >
        <PopupShowVideo />
      </Route>
    );
  },

  SystemAdminLoginPractice: (props: NoPermissionRouterProps) => (
    <Route
      exact
      path='/AdminLoginPractice/group/:groupId/practice/:practiceId'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <AdminLoginPracticeVM setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  PracticeLoginContactToSubscription: (props: NoPermissionRouterProps) => (
    <Route
      exact
      path='/PracticeLoginContact/:userId'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <PracticeLoginContact
        setRouterJWT={props.setRouterJWT}
        isSubscription={true}
      />
    </Route>
  ),

  PracticeLoginContactToDashboard: (props: NoPermissionRouterProps) => (
    <Route
      exact
      path='/PracticeLoginContact/ToDashboard/:userId'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <PracticeLoginContact setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  FormItem: (props: RouterProps) => (
    <AuthorizationRouter
      path='/FormBuilder'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle='All Forms'
          mainComponent={FormBuilder}
          mainComponentClasses={''}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  AppointmentBooking: (props: RouterProps) => (
    <AuthorizationRouter
      path='/AppointmentBooking'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
      component={() => (
        <WithNavigation
          headerTitle={'Appointment'}
          mainComponent={AppointmentBookingVM}
          mainComponentClasses={''}
          setRouterJWT={props.setRouterJWT}
          routerJWT={props.routerJWT}
        />
      )}
    />
  ),

  CatchAllNotFound: (props: RouterProps) => (
    <Route path='/*' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <WithNavigation
        headerTitle={'Not Found'}
        mainComponent={NotFoundPage}
        setRouterJWT={props.setRouterJWT}
        routerJWT={props.routerJWT}
      />
    </Route>
  ),

  LoginWithRoute: (props: NoPermissionRouterProps) => (
    <Route path='/Login' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <LoginPage setRouterJWT={props.setRouterJWT} />
    </Route>
  ),

  AlwaysRedirectToLogin: (props: NoPermissionRouterProps) => (
    <Route path='/*' key={'Router-lookup-' + routeUniqueKeyIndex++}>
      <Redirect to='/login' />
    </Route>
  ),

  MobileStoreConnect: (props: NoPermissionRouterProps) => (
    // Need to convert this to a route like "/Telemedicine/:appointmentId"
    // Currently this route takes priority over any route other than "/"
    <Route
      path='/MobileConnect/:appointmentId'
      key={'Router-lookup-' + routeUniqueKeyIndex++}
    >
      <MobileStoreConnect setRouterJWT={props.setRouterJWT} />
    </Route>
  ),
};

//routers

// Here we can define a separate router for each permission level.
// This will determine which endpoints are available for each user access level.
// Requests will need a valid jwt with proper permission to complete the requested action.
// The server will be responsible for verifying this when the requests comes in.

export const NoPermissionRouter = (props: NoPermissionRouterProps) => {
  const routes = [
    RouteLookup.NotAccessHp24,
    RouteLookup.PreviewFormBuilder,
    RouteLookup.SetPassword,
    RouteLookup.ResetPassword,
    RouteLookup.confirmAppointment,
    RouteLookup.LoginWithRoute,
    RouteLookup.MobileStoreConnect,
    RouteLookup.AlwaysRedirectToLogin,
  ].map((f) => f(props));
  return (
    <Router>
      <Switch>{routes}</Switch>
    </Router>
  );
};

export const getRouterFromUserPermission = (
  setRouterJWT: React.Dispatch<React.SetStateAction<ValidJWT | undefined>>,
  routerJWT: ValidJWT
) => (
  <Router>
    <Switch>
      {Object.values(RouteLookup).map((route: any) =>
        route({ routerJWT, setRouterJWT })
      )}
    </Switch>
    {/* <SessionTimeout setRouterJWT={setRouterJWT} /> */}
  </Router>
);
