/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useRouter } from 'hooks/useRouter';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { AttachmentData } from 'global/requests/ResponseTypes';

const PopupShowImageIncall = () => {
  const router = useRouter();
  //console.log('router', router)
  const [listImageUrl, setlistImageUrl] = useState<string[]>([]);
  const [listImageTitle, setlistImageTitle] = useState<string[]>([]);

  const getTelemedAppointmentAttachments = async (appointmentID: string) => {
    try {
      const res = await configuredRequests.GET.currentTenantAppointmentAttachments(
        appointmentID
      );
      //@ts-ignore
      const imageSelected = res.attachments.filter((items: AttachmentData) => items.attachmentId === router.query.imageID);
      const listImage = imageSelected.map((items: AttachmentData) => items.url);
      const titleOfImage = imageSelected.map((items: AttachmentData) => items.name);
      setlistImageUrl(listImage);
      setlistImageTitle(titleOfImage);
    } catch (err) {
    }
  };

  useEffect(() => {
    //@ts-ignore
    getTelemedAppointmentAttachments(router.query.appointmentID);
  }, []);

  const customStyles = {
    content: {
      width: '100%',
    },
  };

  return (
    <Lightbox
      reactModalStyle={customStyles}
      imageTitle={listImageTitle[0]}
      mainSrc={listImageUrl[0]}
      nextSrc={undefined}
      prevSrc={undefined}
      onCloseRequest={() => { }}
      onMovePrevRequest={() => { }}
      onMoveNextRequest={() => { }}
      clickOutsideToClose={false}
    />
  );
};

export default PopupShowImageIncall;
