export const TemplateTypes = {
  NAV_TAB_VALUES: {
    EMAIL: 0,
    SMS: 1,
  },
  KIND_OF_EMAIL_OR_SMS: {
    INTERACTION: 0,
    SMS: 1,
    EMAIL: 2,
    CALL: 3,
    POSTAL: 4,
  }
};

export const capitalizeByNumber = (kind: number) => {
  switch (kind) {
    case TemplateTypes.KIND_OF_EMAIL_OR_SMS.SMS:
      return "Text";
    case TemplateTypes.KIND_OF_EMAIL_OR_SMS.EMAIL:
      return "Email";
    case TemplateTypes.KIND_OF_EMAIL_OR_SMS.CALL:
      return "Call";
    case TemplateTypes.KIND_OF_EMAIL_OR_SMS.POSTAL:
      return "Postal";
    default:
      return 'Text'
  }
};
