import styled from 'styled-components';
import 'react-dates/initialize';
import IconButton from '@material-ui/core/IconButton';

import 'react-dates/lib/css/_datepicker.css';
import { getThemeProp } from 'components/Legwork/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';

const Main = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledDateInput = styled(KeyboardDatePicker)`
  div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    border: 1px solid ${getThemeProp('palette.neutrals.inactive', '#d4dbe4')};
    border-radius: 5px;
    background-color: white;
  }

  .MuiInputBase-input {
    font-size: 14px;
    max-width: 100%;
    font-family: ${({ theme }): string => `${theme.typography.fontFamily}`};
    padding: 3px 0px 3px 8px;
    border: none;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
`;

const Wrapper = styled.div`
  margin: 0 0 0 0.5rem;
  display: flex;
  .Muisrc/components/Legwork/typography-root {
    margin-right: 0.5rem;
    white-space: nowrap;
  }
  .MuiFormLabel-root {
    color: ${getThemeProp('palette.text.secondary', 'grey')};
  }
  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .MuiIconButton-root {
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    padding: 6px 0px 6px 8px;
  }
`;
const StyledMainDay = styled.div`
  min-width: 37px;
  min-height: 40px;
  font-size: 0.8rem;
  padding: 1px;
  padding-left: 2px;
  .MuiButtonBase-root {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    font-size: 0.8rem;
    padding: 0px;
  }
  .MuiPickersDay-dayDisabled {
    color: ${getThemeProp('palette.neutrals.inactive', '#B8C3D2')};
    &:hover {
      background-color: white;
    }
  }
  .MuiPickersDay-daySelected {
    color: white;
  }
`;

const NotInMonthDayIcon = styled(IconButton)`
  color: #b8c3d2;
  cursor: default;
  &:hover {
    background-color: white;
  }
`;

export { Main, Wrapper, StyledDateInput, NotInMonthDayIcon, StyledMainDay };
