import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import loading_spinner from 'media/images/loading-spinner.svg';

type EngagementOverTimeChartDataItem = {
  date: string;
  opened: number;
  clicked: number;
};

type EngagementOverTimeChartProps = {
  data: EngagementOverTimeChartDataItem[];
  loadingEngagementOvertime: boolean;
};
export const EngagementOverTimeChart = (
  props: EngagementOverTimeChartProps
) => {
  const options = {
    chart: {
      type: 'column',
    },

    credits: {
      enabled: false,
    },

    title: {
      text: null,
    },

    xAxis: {
      categories: props.data.map((item) => {
        const dateString = moment(item.date).format("MMM D");
        return dateString === 'Invalid date' ? item.date : dateString;
      }),
      scrollbar: {
        enabled: true,
      },
    },

    yAxis: {
      title: {
        text: null,
      },
    },

    plotOptions: {
      column: {
        grouping: true,
        borderRadius: 5,
        pointWidth: 10,
        pointPadding: 0,
        borderWidth: 0,
        states: {
          inactive: {
            opacity: 0.8,
          },
        },
      },
    },

    series: [
      {
        data: props.data.map((item) => item.opened),
        color: '#1974FF',
        name: 'Opens',
      },
      {
        data: props.data.map((item) => item.clicked),
        color: '#C5CEDA',
        name: 'Clicks',
      },
    ],
  };

  return (
    <div className='w-100'>
      {props.loadingEngagementOvertime ? <img className="loading-open-by-platform" src={loading_spinner} alt={"Loading"} /> :
        <HighchartsReact highcharts={Highcharts} options={options} />
      }
    </div>
  );
};

export default EngagementOverTimeChart;
