import React from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from 'hooks/useRouter';

type CommunicationPerformanceModel = {
  template: string,
  sent: number,
  delivered: number,
  opened: number,
  clicked: number,
  unsubscribed: number,
  spam: number,
  invalid: number,
};

interface ITopPerformingTemplate {
  deliverRate: number
  openRate: number
  sent: number
  templateName: string
  templateId: string
}

interface IProps {
  data: ITopPerformingTemplate[]
}

const TopPerformingTemplates = (props: IProps): JSX.Element => {
  const router = useRouter();
  return (
    <table className="communication__table">
      {props.data.length > 0 ? (
        <tbody>
          <tr className="table-hdr">
            <th className="table-header__template">Template</th>
            <th className="table-header__sent">Sent</th>
            <th className="table-header__delivery-rate">Delivery Rate</th>
            <th className="table-header__open-rate">Open Rate</th>
          </tr>

          {props.data.map((model, i) => {
            return (
              <tr key={"combined-performance--data-map-index-" + i}>
                <td><Link to={`${router.match.path}/Template/${model.templateId}`} title="Template" className="lnk">{model.templateName}</Link></td>
                <td>{model.sent}</td>
                <td>{model.deliverRate}%</td>
                <td>{model.openRate}%</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
          <tbody>
            <tr>
              <th className="no-top-performing-templates">No Top Performing Templates!</th>
            </tr>
          </tbody>
        )}
    </table>
  );
}

export default TopPerformingTemplates;
