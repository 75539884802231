const getMaxPage = (current: number, total: number): number => {
  if (current + 1 <= total) {
    return current + 1;
  }
  return current;
};

const getMinPage = (current: number, min: number): number => {
  if (current - 1 >= min) {
    return current - 1;
  }
  return min;
};
const buttonValues = (
  currentPage: number,
  rows: number,
  rowsPPage: number,
): { value: string | number; goTo: number }[] => {
  const pages = Math.ceil(rows / rowsPPage) - 1;
  const max = getMaxPage(currentPage, pages);
  const min = getMinPage(currentPage, 0);
  let current = min;

  const result = [];
  if (min > 0) {
    result.push({ value: 1, goTo: 1 });
  }
  if (min > 1) {
    const goTo = Math.ceil((min + 1) / 2);
    result.push({ value: goTo === 2 ? 2 : '...', goTo });
  }

  while (current <= max) {
    result.push({
      value: current + 1,
      goTo: current + 1,
    });
    current += 1;
  }
  const lastElementValue = result[result.length - 1].goTo;

  if (lastElementValue < Math.floor(pages)) {
    const goTo = Math.ceil((pages + lastElementValue) / 2);
    result.push({ value: goTo === Math.floor(pages) ? goTo : '...', goTo });
  }
  if (lastElementValue < Math.floor(pages) + 1) {
    result.push({ value: Math.floor(pages) + 1, goTo: Math.floor(pages) + 1 });
  }

  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { buttonValues };
