import styled from 'styled-components';

export const DetailAppointmentBookingWrapper = styled.div`
  width: 700px;
  padding: 20px;
  margin-top: 20px;

  border: 1px solid #E3EBF6;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 3px 0 rgba(18,37,63,0.03);

  .header-appointment {
    display: flex;

    .info-appointment {
      p:first-child {
        margin: 0px 0px 5px 0px;
        color: #12253F;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
      p:not(:first-child){
        margin: 0px 0px 5px 0px;
        color: #617799;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
    .expand-action-appointment {
      margin-left: auto;
      position: relative;
      .content-expanded-appointment {
        border: 1px solid #E3EBF6;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px 0 rgba(18,38,63,0.04);

        position: absolute;
        top: 25px;
        right: -14px;

        width: 130px;

        p {
          color: #12253F;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16px;

          padding-left: 15px;

          cursor: pointer;
        }
      }
      svg {
        color: #CACACA;
      }
    }
  }
  p.exam-cleaning-appointment {
    color: #12253F;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  div.footer-detail-appointment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.group-button {
      .cancel-button {
        height: 35px;
        width: 105px;

        border-radius: 4px;
        background-color: #ECEEF5;
        color: #12253f;

        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;

        text-transform: none;

        margin-left: 10px;
      }
      .save-button {
        height: 35px;
        width: 105px;

        border-radius: 4px;
        background-color: #0074FF;
        box-shadow: 0 1px 3px 0 rgba(0,116,255,0.55);

        text-transform: capitalize;

        color: #FFFFFF;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
      .appointment-icon {
        display: flex;
        align-items: center;

        svg {
          cursor: default;
          height: 16px;
          width: 16px;
        }
        span {
          margin-left: 10px;
          margin-top: 4px;
          color: #617799;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
        }
      }

      .accepted-icon svg {
        color: #31CC97;
      }

      .denied-icon svg {
        color: #E02020;
      }

      .archived-icon {
        svg {
          color: #0074FF;
        }
        .unarchive-button {
          span:first-child {
            margin-left: 0px;
            color: #12253F;
            font-family: "Nunito Sans";
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center
          }
        }
      }
    }
    p.view-submission {
      color: #1974FF;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;

      cursor: pointer;
    }
  }
`;

export const ContentContactAppointment = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  flex-grow: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;
  .contacts-appointment-footer {
    padding: 15px 20px;
    border: 1px solid #DEE0EC;
    margin-top: auto;

    width: 100%;
    display: flex;

    .cancel-button {
      border-radius: 6px;
      background-color: #e0e0e0;

      color: #12253f;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;

      text-transform: none;
    }
    .save-button {
      border-radius: 4px;
      background-color: #0074FF;
      box-shadow: 0 1px 3px 0 rgba(0,116,255,0.55);

      text-transform: capitalize;

      color: #FFFFFF;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
    }

    .deny-button {
      border-radius: 4px;
      background-color: #E03229;
      box-shadow: 0 1px 3px 0 rgba(224,50,41,0.48);
    }

    .btn-accept-appointment {
      /* display: none; */
    }

    .action-button-group {
      margin-left: auto;

      button:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
  .contacts-appointment-body {
    padding: 20px 20px 0px;
    .row-contact-provider {
      display: flex;
      justify-content: space-between;
      div:not(:last-child) {
        width: 40%;
      }
      div:last-child {
        width: 40%;
      }
    }
    .row-pet-provider {
      margin-top: 25px;
      width: 40%;
    }
    .row-appointment-provider {
      margin-top: 25px;
    }
    .row-message-provider {
      /* margin-top: 25px; */
      span {
        color: #6E84A3;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
      }
      textarea {
        height: 150px;
        border: 1px solid #DEE0EC;
        display: block;
        width: 100%;
        resize: vertical;
        /* margin-top: 5px; */
      }
    }
    .contact-email-editor {
      padding: 0;
      margin-bottom: 2rem;

      .select-option-message {
        display: flex;

        .search-filter__global:first-child {
          width: initial;
          margin-right: 10px;
        }
      }

      p {
        margin-top: 25px;
        margin-bottom: 10px;

        &.error-msg {
          margin-top: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const ViewSubmissionWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  .view-submission-body {
    padding: 15px 5px 0px;
    .DayPicker-Day {
      height: 47px;
      font-size: 13px;
    }

    .form-field-input-container {
      .read-only {
        .address {
          & > flex {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .view-submission-footer {
    margin-top: auto;
    padding: 15px 20px;
    border: 1px solid #DEE0EC;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    button:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const EmailContactWrapper = styled.div`
  button.disabled-button-submit {
    background-color: #eceef5 !important;
    color: #6e84a3 !important;
    cursor: not-allowed;
  }

  .contact-email-editor {
    padding: 10px;
    padding-top: 0;
  }
`;
