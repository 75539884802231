import { ValidJWT } from 'types/RouterT';
import JwtDecode from 'jwt-decode';
import moment from 'moment';
import { LoginResponse } from 'global/requests/ResponseTypes/Authentication';

const keyAuthToken = "AuthToken";
const keyAuthTokenType = "AuthTokenType";
const keyAuthTokenExpiry = "AuthTokenExpiresAt";

export const setSessionJWT = (authDetails: LoginResponse): void => {
  const tokenObject: ValidJWT = JwtDecode(authDetails.access_token);
  // console.log('set session JWT tokenObject', tokenObject);
  sessionStorage.setItem("JWT", JSON.stringify(tokenObject));
  // we set this here as well so that they are always in sync
  setSessionJWTString(authDetails);
}

export const getSessionJWT = (): ValidJWT | undefined => {
  // if there is no sessionToken in sessionStorage then the variable below will be a string of "undefined"
  const sessionToken = sessionStorage.getItem("JWT"); // JSON token object or string of "undefined"
  if (sessionToken && typeof sessionToken === "string" && sessionToken.toLocaleLowerCase() !== "undefined") {
    // console.log('sessionToken in getSessionJWT', sessionToken);
    return JSON.parse(sessionToken);
  }
  return undefined
}

export const getSessionJWTString = (): string | undefined => {
  const authToken = sessionStorage.getItem(keyAuthToken);
  if (!authToken) {
    return undefined
  }
  return authToken;
}

export const getSessionAuthorizationHeaderValue = (): string | undefined => {
  const authToken = sessionStorage.getItem(keyAuthToken);
  const tokenType = sessionStorage.getItem(keyAuthTokenType);

  // console.log('got session jwt string', sessionJWTString);
  if (!authToken || !tokenType) {
    return undefined
  }

  // Example: `bearer {auth-token}`
  return tokenType + " " + authToken;
}

const setSessionJWTString = (authDetails: LoginResponse): void => {
  sessionStorage.setItem(keyAuthToken, authDetails.access_token);
  sessionStorage.setItem(keyAuthTokenType, authDetails.token_type);
  sessionStorage.setItem(keyAuthTokenExpiry, moment().add(authDetails.expires_in, 'seconds').unix().toString());
}


// We don't want to use localStorage because it is vulnerable to XSS and other vulnerabilities
/*
export const setLocalJWT = (token: ValidJWT) => {
  localStorage.setItem("JWT", JSON.stringify(token));
}

export const getLocalJWT = (): ValidJWT | undefined => {
  const localToken = localStorage.getItem("JWT");
  if (!localToken) {
    return undefined
  }
  return JSON.parse(localToken)
}
*/
