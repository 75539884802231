import React from 'react';
import moment from 'moment';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { IconDiv, Main, StyledCard } from './styled-components';

export interface WeekDaySelectorProps {
  week: moment.Moment;
  day: moment.Moment;
  updateWeek: (args0: moment.Moment) => unknown;
  daySelect: (args0: moment.Moment) => unknown;
  hasWeekSelector?: boolean | undefined;
}

const getDaysThisWeek = (day: moment.Moment): moment.Moment[] => {
  const tempDay = day.day();
  const result = [moment(day.weekday(0))];
  for (let i = 1; i < 7; i += 1) {
    result.push(moment(day.weekday(i)));
  }
  day.weekday(tempDay);

  return result;
};

const WeekDaySelector = (props: WeekDaySelectorProps): React.ReactElement => {
  const { week, day, updateWeek, daySelect, hasWeekSelector = true } = props;
  const selectDay = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (event.currentTarget.dataset.value) {
      daySelect(moment(parseInt(event.currentTarget.dataset.value, 10)));
    }
  };

  const backOneWeek = (): void => {
    updateWeek(moment(week.subtract(1, 'week')));
  };

  const forwardOneWeek = (): void => {
    updateWeek(moment(week.add(1, 'week')));
  };

  const daysThisWeek = getDaysThisWeek(day);

  return (
    <Main>
      <IconDiv onClick={backOneWeek} data-testid='back-one-week'>
        {hasWeekSelector && <ChevronLeft style={{ color: '#aaaaaa', fontSize: '2.5rem' }} />}
      </IconDiv>

      {daysThisWeek.map(
        (d: moment.Moment): React.ReactElement => {
          return (
            <StyledCard
              key={d.format('ddd-D')}
              onClick={selectDay}
              data-value={d}
              selected={day.format('ddd-D') === d.format('ddd-D')}
            >
              {d.format('ddd - D')}
            </StyledCard>
          );
        },
      )}

      <IconDiv onClick={forwardOneWeek} data-testid='forward-one-week'>
        {hasWeekSelector && <ChevronRight style={{ color: '#aaaaaa', fontSize: '2.5rem' }} />}
      </IconDiv>
    </Main>
  );
};

WeekDaySelector.defaultProps = {
  day: moment(),
  week: moment(),
};

export default WeekDaySelector;
