import styled from 'styled-components';
import { Button } from '@material-ui/core';
import getThemeProp from '../../utils/getThemeProp';

const DialogPrimaryButton = styled(Button)`
  font-size: 16px;
  color: ${getThemeProp('palette.text.hint', 'blue')};
  font-weight: 500;
`;

export default DialogPrimaryButton;
