import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {
  IconLeftButton,
  TextButton,
  StyledTablePagination,
  IconRightButton,
  Main,
  StyledFooter,
  Results,
  ResultsNumber,
} from './styled-components';
import { buttonValues } from './utils';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps): React.ReactElement => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChangePage(event, page + 1);
  };

  const handleSpecificPageClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (event.currentTarget.dataset.value === '...' || !event.currentTarget.dataset.value) {
      return;
    }
    onChangePage(event, parseInt(event.currentTarget.dataset.value, 10) - 1);
  };

  return (
    <Main>
      <IconLeftButton
        onClick={handleBackButtonClick}
        disabled={page <= 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconLeftButton>
      {buttonValues(page, count, rowsPerPage).map(
        (text: { value: number | string; goTo: number }): React.ReactElement => {
          return (
            <TextButton
              selected={text.goTo === page + 1}
              key={text.goTo}
              data-value={text.goTo}
              onClick={handleSpecificPageClick}
            >
              {text.value}
            </TextButton>
          );
        },
      )}

      <IconRightButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconRightButton>
    </Main>
  );
};

export interface PaginationTableFooterProps {
  page: number;
  tableColumns: number;
  totalRows: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  NoResultsComponent?: React.ElementType;
}

const PaginationTableFooter = (props: PaginationTableFooterProps): React.ReactElement => {
  const {
    tableColumns,
    totalRows,
    rowsPerPage,
    handleChangePage,
    page,
    NoResultsComponent,
  } = props;

  return (
    <StyledFooter>
      <StyledTablePagination
        component='div'
        rowsPerPageOptions={[]}
        colSpan={tableColumns}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{}}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={(): React.ReactElement => {
          if (totalRows === 0) {
            return (
              <Results>
                {NoResultsComponent ? (
                  <NoResultsComponent />
                ) : (
                    <span>There are no form in this folder</span>
                  )}
              </Results>
            );
          }
          const getResultsInfo = (): { start: string; end: string } => {
            const start = String(page * rowsPerPage + 1);
            if ((page + 1) * rowsPerPage > totalRows) {
              return { start, end: String(totalRows) };
            }
            return { start, end: String((page + 1) * rowsPerPage) };
          };
          return (
            <Results>
              <ResultsNumber>{getResultsInfo().start}</ResultsNumber>
              <span style={{"display": "inherit"}}> - </span>
              <ResultsNumber>{getResultsInfo().end}</ResultsNumber>

              <span>of</span>
              <ResultsNumber>{totalRows}</ResultsNumber>
              <span>Results</span>
            </Results>
          );
        }}
      />
    </StyledFooter>
  );
};

export default PaginationTableFooter;
