import styled from 'styled-components';
import { getThemeProp } from 'components/Legwork/utils';

const TableContainer = styled.div`
  .MuiTableCell-root {
    border-bottom: 1px solid ${getThemeProp('palette.divider', 'lightgray')};
  }

  .MuiTableCell-root:first-child {
    width:420px;
  }
  .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
    border-bottom: none;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { TableContainer };
