import React from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';

export default class FormFieldPage extends React.Component {
  constructor(props) {
    super(props);

    this.debounceDragOver = debounce(this.debounceDragOver, 100);
  }

  handleDragOver = (e, field) => {
    // need to call event (e) methods *before* calling the debounced method
    e.preventDefault();
    e.stopPropagation();
    this.debounceDragOver({ clientY: e.clientY }, field);
  };

  debounceDragOver = (coords, field) => {
    const { clientY } = coords;
    const { offsetTop, offsetHeight, offsetParent, parentElement } = this.element || {};
    const offsetMidY =
      offsetParent.offsetTop + offsetTop - parentElement.scrollTop + offsetHeight / 2;
    const location = clientY < offsetMidY ? 'top' : 'bottom';

    this.props.onFieldHover(field, location);
  };

  renderButtons = () => {
    const { pageNum, displayPrevious, displayNext, onPageChange } = this.props;

    return (
      <div className='page-break-buttons'>
        {displayPrevious && (
          <input
            type='button'
            className='btn btn-secondary pull-left'
            value='Previous'
            onClick={() => onPageChange(pageNum - 1)}
          />
        )}
        {displayNext && (
          <input
            type='button'
            className='btn btn-primary pull-right'
            value='Next'
            onClick={() => onPageChange(pageNum + 1)}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      field,
      pageNum,
      onDeleteField,
      hoverField,
      hoverLocation,
      isRenderedField,
    } = this.props;
    const isHovered = field === hoverField;
    const hoverTop = field === hoverField && hoverLocation === 'top';
    const hoverBottom = field === hoverField && hoverLocation === 'bottom';
    const hoverClassNames = classNames({
      hover: true,
      [`hover-${hoverLocation}`]: isHovered,
    });

    if (isRenderedField) return this.renderButtons();

    return (
      <div
        className='page-break-container'
        ref={el => (this.element = el)}
        onDragOver={e => this.handleDragOver(e, field)}
      >
        {hoverTop && <div className={hoverClassNames} />}
        <div key={`page-${pageNum}`} className='page-break separator'>
          <span>(Page Break)</span>
        </div>
        <div className='form-field-controls'>
          <i className='icon icon-trash' title='Delete' onClick={() => onDeleteField(field)} />
        </div>
        <div className='page'>{`Page ${pageNum}`}</div>
        {hoverBottom && <div className={hoverClassNames} />}
      </div>
    );
  }
}

FormFieldPage.defaultProps = {
  onDeleteField: () => { },
  onFieldHover: () => { },
  hoverField: null,
  hoverLocation: null,
  isRenderedField: false,
  displayPrevious: false,
  displayNext: false,
  onPageChange: () => { },
};
