const Styles = {
  main: {
    display: 'flex',
    fontWeight: 'normal',
    justifyContent: 'space-between',
    marginBottom: '-30px',
    borderBottom: '1px solid #e3ebf6',
  },
  label: {
    fontSize: '15px',
    fontWeight: 'normal',
    paddingLeft: '15px',
  },
  pager: {
    margin: '0 15px',
  },
  a: {
    color: '#4E4D4B',
    borderRadius: '50%',
    fontSize: '18px',
    padding: '5px 8px',
    marginLeft: '10px',
  },
  'a-disable': {
    color: 'lightgrey',
    cursor: 'not-allowed',
  },
};

export default Styles;
