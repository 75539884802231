import React from 'react';
import { TableCell } from '@material-ui/core';

import { StyledHeaderRow } from './styled-components';

export interface IHeaderRow {
  headCells: (string | React.ReactElement)[];
}

const HeaderRow = (props: IHeaderRow): React.ReactElement => {
  const { headCells } = props;
  return (
    <>
      <StyledHeaderRow>
        {headCells.map(
          (headCell: string | React.ReactElement, i: number): React.ReactElement => (
            // eslint-disable-next-line react/no-array-index-key
            <TableCell key={i}>{headCell}</TableCell>
          ),
        )}
      </StyledHeaderRow>
    </>
  );
};

export default HeaderRow;
