import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { InputWithTopLabel } from '../../../../../components/Legwork';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ErrorMessage } from '../../styled-components';
import { get } from 'lodash';
import toast from 'components/Global/Toast';

export interface RenameFolderModalProps {
  open: boolean;
  folder: any;
  handleClose: () => void;
  updateFolders: () => void;
}

interface IError {
  folderName?: string;
}
const RenameFolderModal = (
  props: RenameFolderModalProps
): React.ReactElement => {
  const { open, folder, handleClose, updateFolders } = props;
  const [currentName, setCurrentName] = useState<string>('');
  const [errors, setErrors] = useState<IError>({ folderName: '' });
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  useEffect(() => {
    if (folder?.folder_name) {
      setCurrentName(folder.folder_name);
    }
  }, [folder]);

  // params [folderId, name]
  const renameFolder = async (
    folderId: string,
    folderName: string
  ): Promise<void> => {
    try {
      const response = await configuredRequests.PATCH.renameFolder(
        folderId,
        folderName
      );
      if (response.status === 200) {
        updateFolders();
        setErrors({ folderName: '' });
        toast.success('Your data has been saved successfully!');
      }
      handleClose();
    } catch (error) {
      if (get(error, 'response.data.message', '')) {
        setErrors({ folderName: get(error, 'response.data.message', '') });
      }
    }
  };

  const resetInitialValue = () => {
    setCurrentName(folder?.folder_name || '');
    setErrors({ folderName: '' });
    handleClose();
  };

  const handleSuccess = async (): Promise<void> => {
    if (currentName) {
      if (!folder.id) handleClose(); // do error stuff?
      setDisabledSubmit(true);
      await renameFolder(folder.id, currentName);
      setDisabledSubmit(false);
      // resetInitialValue();
    } else {
      setErrors({ folderName: 'This field is required.' });
    }
  };

  const handleChange = (folderName: string) => {
    if (!folderName) {
      setErrors({ folderName: 'This field is required.' });
    } else if (!folderName.trim()) {
      setErrors({ folderName: '' });
    } else {
      setErrors({});
    }
    setCurrentName(folderName);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetInitialValue();
      }}
      aria-labelledby='delete-modal'
      aria-describedby='delete-folder-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id='move-form-modal'
    >
      <DialogContentText id='move-modal'>
        <span className='title-move-modal'>Rename Folder</span>
        <IconButton
          aria-label='close'
          className='close-modal'
          onClick={resetInitialValue}
        >
          <CloseIcon />
        </IconButton>
      </DialogContentText>
      <DialogContent>
        <InputWithTopLabel
          required={true}
          title='Name'
          value={currentName}
          reporter={handleChange}
          fullWidth
          error={false}
          type='text'
          maxLength={100}
          focus
        />
        {errors.folderName && <ErrorMessage>{errors.folderName}</ErrorMessage>}
        <br />
      </DialogContent>
      <DialogActions className='footer-move-modal'>
        <Button onClick={() => resetInitialValue()} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={handleSuccess}
          color='primary'
          autoFocus
          disabled={errors?.folderName !== undefined || disabledSubmit}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameFolderModal;
