import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const StyledButton = styled(Button)`
  padding: 0 8px;

  border-radius: 3px;
  text-transform: none;
  svg {
    margin-right: 5px;
    height: 100%;
    width: 1rem;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledButton };
