import React, { useState, useRef, useEffect } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ScreenCapture from '../screen_capture/capture/ScreenCapture';
import VideoRecordingComponent from './video-recording/VideoRecordingComponent';

import './GroupMenuComponent.scss'

interface IProps {
    accessToken: string | null
    appointmentId: string | null
    isPatientJoined?: boolean
    createsMeetingSession: (a: boolean, b: boolean) => void
}

const GroupMenuComponent: React.FC<IProps> = (props) => {
    const [hideGroupMenu, setHideGroupMenu] = useState(false);
    const [openFolderOfGroupMenu, setOpenFolderOfGroupMenu] = React.useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    /**
     * Hide group menu if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent | any) => {
        if(event.target.className === "recording-border") {
            setHideGroupMenu(false);
        }
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setHideGroupMenu(false);
        }
    }

    const showGroupMenu = () => {
        setHideGroupMenu(!hideGroupMenu);
    }

    const isHideGroupMenu = (isShowing: boolean) => {
        if (!isShowing) {
            setHideGroupMenu(!hideGroupMenu);
        } else {
            return;
        }
    }

    return (
        <div ref={wrapperRef} className="vertIconForm">
            <div  className="vertIconFormContainer" onClick={showGroupMenu}>
                <MoreVertIcon fontSize="large" />
            </div>
            <div className={hideGroupMenu ? "groupMenu" : "groupMenu hide"}>

                <ScreenCapture
                openFolderOfGroupMenu={openFolderOfGroupMenu}
                setOpenFolderOfGroupMenu={setOpenFolderOfGroupMenu}
                 accessToken={props.accessToken}
                 appointmentId={props.appointmentId}
                 showGroupMenu={isHideGroupMenu}/>

                <div className="line"></div>

                <VideoRecordingComponent showGroupMenu={isHideGroupMenu} createsMeetingSession={props.createsMeetingSession} isPatientJoined={props.isPatientJoined}/>

            </div>


        </div>
    )
}

export default GroupMenuComponent;
