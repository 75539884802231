/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Select, MenuItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import RightModalDetails from './RightModalDetails';
import {
  AutomationTriggerList,
  DeliverApproximatelyResponse,
  AutomationTrigger,
} from 'global/requests/ResponseTypes';
import { useParams } from 'react-router-dom';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import AutomationEditForm from './AutomationEditForm/AutomationEditForm';
import { TemplateResponse } from '../../../global/requests/ResponseTypes';
import { dataTemplate } from '../../../global/requests/dataUserSettingResponseError';
import { isEmpty } from 'lodash/fp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { isWindows } from 'react-device-detect';
import loading_spinner from 'media/images/loading-spinner.svg';
import {
  AppointmentTypeResponse,
  AppointmentTypeSetting,
} from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import './AutomationDetailNoGlobal.scss';
import { Medical } from 'global/requests/ResponseTypes/Medical';
import { sortBy } from 'lodash';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 20,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#1DC9B7',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 20 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#B8C3D2',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

enum OrganizationType {
  InteractionType,
  Template,
  Date,
}

const AutomationDetail = (): JSX.Element => {
  const [emailTemplateData, setEmailTemplateData] =
    React.useState<TemplateResponse>(dataTemplate);
  const [smsTemplateData, setSmsTemplateData] =
    React.useState<TemplateResponse>(dataTemplate);
  const [callTemplateData, setCallTemplateData] =
    React.useState<TemplateResponse>(dataTemplate);
  const [postalTemplateData, setPostalTemplateData] =
    React.useState<TemplateResponse>(dataTemplate);

  const { triggerId, category, automationTriggerKey, automationCategoryId } =
    useParams<{
      triggerId: string;
      category: string;
      automationTriggerKey: string;
      automationCategoryId: string;
    }>();
  const [state, setState] = React.useState({
    checkedB: true,
  });

  const [dataResponse, setDataResponse] =
    React.useState<AutomationTriggerList>();
  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const [openFragment, setOpenFragment] = React.useState<boolean>(false);
  const [currentAutomationOpen, setCurrentAutomationOpen] = React.useState<
    string | null
  >(null);
  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [triggerEventId, setTriggerEventId] = React.useState<string>('');
  const [deliverApproximatelyData, setdeliverApproximatelyData] =
    React.useState<DeliverApproximatelyResponse>();
  const [listAppointmentTypes, setListAppointmentTypes] = React.useState<
    AppointmentTypeSetting[]
  >([]);
  const [listMedicalServices, setListMedicalServices] = React.useState<
    Medical[]
  >([]);
  const [listSpecies, setListSpecies] = React.useState<SpeciesSettingType[]>(
    []
  );
  const [needsQuery, setNeedsQuery] = React.useState<boolean>(false);
  const [disabledStatusList, setDisabledStatusList] = React.useState<string[]>(
    []
  );
  const [titleData, setTitleData] = React.useState<string>('');
  const [deliveryHoursCheckboxes, setDeliveryHoursCheckboxes] =
    React.useState<any>({});
  const [organizationType, setOrganizationType] =
    React.useState<OrganizationType>(OrganizationType.Date);

  const getEmailTemplates = async () => {
    try {
      const emailTemplatesDataResponse =
        await configuredRequests.GET.templateByKind(
          [TemplateKindsEnum.email],
          [category]
        );
      setEmailTemplateData(emailTemplatesDataResponse);
    } catch (err) {
      console.error('err', err);
    }
  };

  const getSmsTemplates = async () => {
    try {
      const smsTemplatesDataResponse =
        await configuredRequests.GET.templateByKind(
          [TemplateKindsEnum.sms],
          [category]
        );

      setSmsTemplateData(smsTemplatesDataResponse);
    } catch (err) {
      console.error('err', err);
    }
  };

  const getCallTemplates = async () => {
    try {
      const callTemplatesDataResponse =
        await configuredRequests.GET.templateByKind(
          [TemplateKindsEnum.call],
          [category]
        );
      setCallTemplateData(callTemplatesDataResponse);
    } catch (err) {
      console.error('err', err);
    }
  };

  const getPostalTemplates = async () => {
    try {
      const callTemplatesDataResponse =
        await configuredRequests.GET.templateByKind(
          [TemplateKindsEnum.postal],
          [category]
        );
      setPostalTemplateData(callTemplatesDataResponse);
    } catch (err) {
      console.error('err', err);
    }
  };

  const getAutomationList = async () => {
    try {
      const res = await configuredRequests.GET.getAutomationTriggerList(
        category,
        automationTriggerKey
      );
      let newCheckboxes = {};
      // @ts-ignore
      res.items.forEach((item) => {
        // @ts-ignore
        newCheckboxes[item.automationTriggerEventId] =
          item.allowSendOutsideDeliveryHours;
      });
      setDeliveryHoursCheckboxes(newCheckboxes);
      // @ts-ignore
      setDataResponse(res);
    } catch (e) {
      console.log('err', e);
    }
  };

  const getDeliverApproximately = async (automationTriggerKey: string) => {
    try {
      const deliverApproximatelyDataResponse =
        await configuredRequests.GET.deliverApproximately(automationTriggerKey);
      setdeliverApproximatelyData(deliverApproximatelyDataResponse);
    } catch (err) {
      console.error('err', err);
    }
  };

  const getMedicalServices = async () => {
    try {
      const res = await configuredRequests.GET.getMedicalServices({
        sortBy: 'name',
      });
      setListMedicalServices(res.medicalServices);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAppointmentTypes = async () => {
    try {
      const appointmentTypesResponse =
        await configuredRequests.GET.getAppointmentType();
      setListAppointmentTypes(
        appointmentTypesResponse.filter(
          (apptType: AppointmentTypeSetting) => !!apptType.externalId
        )
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSpecies = async () => {
    try {
      const speciesResponse =
        await configuredRequests.GET.allSpeciesByCurrentTenant();
      if (speciesResponse?.data) {
        // @ts-ignore
        const dataAfterSort = sortBy(speciesResponse.data.species, ['name']);
        // @ts-ignore
        setListSpecies(dataAfterSort);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const switchStatus = async (automationTriggerEventId: string) => {
    try {
      setDisabledStatusList((prev) => prev.concat(automationTriggerEventId));
      const res = await configuredRequests.PATCH.enableAutomationTriggerEvent(
        automationTriggerEventId
      );
      if (res) {
        setDisabledStatusList((prev) =>
          prev.filter((item) => item !== automationTriggerEventId)
        );
        //@ts-ignore
        setDataResponse((prev) => ({
          ...prev,
          items: prev
            ? prev.items.map((item) => {
                if (
                  item.automationTriggerEventId === automationTriggerEventId
                ) {
                  return { ...item, enabled: !item.enabled };
                }
                return item;
              })
            : [],
        }));
      }
    } catch (err) {
      setDisabledStatusList((prev) =>
        prev.filter((item) => item !== automationTriggerEventId)
      );
      console.error('err', err);
    }
  };

  const checkNewEvent = (needsQuery: boolean) => {
    setNeedsQuery(needsQuery);
  };

  const getInfoAutomationListItem = async () => {
    try {
      const automationListItemData =
        await configuredRequests.GET.automationTriggerByKind(category);
      if (automationListItemData) {
        const automationItemDataSelected = automationListItemData.items.filter(
          (item) => item.id === triggerId
        );
        setTitleData(automationItemDataSelected[0].name as string);
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'auto';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    Promise.all([
      getEmailTemplates(),
      getSmsTemplates(),
      getCallTemplates(),
      getPostalTemplates(),
      getAutomationList(),
      getDeliverApproximately(automationTriggerKey),
      getAppointmentTypes(),
      getMedicalServices(),
      getSpecies(),
      getInfoAutomationListItem(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (needsQuery) {
      getAutomationList();
      setNeedsQuery(false);
    }
  }, [needsQuery]);

  const handleChangeSwitch =
    (item: AutomationTrigger) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      switchStatus(item.automationTriggerEventId);
    };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Delete Record ?
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          The process cannot be undone. Are you sure you want to delete this
          event?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            {' '}
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              handleDeleteAutomation();
            }}
          >
            {' '}
            Yes{' '}
          </Button>
        </div>
      </div>
    );
  };

  const handleOpenFragment =
    (index: string) => (event?: React.SyntheticEvent) => {
      setOpenFragment(true);
      if (index === currentAutomationOpen) {
        setCurrentAutomationOpen(null);
        return;
      }
      setCurrentAutomationOpen(index);
    };

  const handleDeleteAutomation = async () => {
    setOpenPopup(false);
    try {
      const res = await configuredRequests.DEL.removeAutomationTrigger(
        triggerEventId
      );
      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Deleted successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
        getAutomationList();
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Failed to delete.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const handleOpenPopup =
    (item: AutomationTrigger) => (event?: React.SyntheticEvent) => {
      setTriggerEventId(item.automationTriggerEventId);
      setOpenPopup(true);
      setCurrentAutomationOpen(null);
    };

  const copyTrigger =
    (item: AutomationTrigger) => async (event?: React.SyntheticEvent) => {
      try {
        const res = await configuredRequests.POST.copyAutomationTriggerEvent(
          item.automationTriggerEventId
        );
        if (res) {
          setIsLoading(true);
          Promise.all([
            getEmailTemplates(),
            getSmsTemplates(),
            getCallTemplates(),
            getPostalTemplates(),
            getAutomationList(),
            getDeliverApproximately(automationTriggerKey),
            getAppointmentTypes(),
            getSpecies(),
            getInfoAutomationListItem(),
          ]).then(() => {
            setIsLoading(false);
          });
        }
      } catch (err) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>Your data has not been saved yet. Please try again.</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    };

  const renderAppointmentTypes = (
    kind: string,
    data: AppointmentTypeResponse[]
  ) => {
    if (kind === 'all') {
      return 'All Appointment Types';
    }
    if (kind === 'apply') {
      const listApt = data?.filter((item) => {
        return (
          listAppointmentTypes.findIndex((appointmentType) => {
            return appointmentType.id === item.id;
          }) > -1
        );
      });
      return `Only apply for ${listApt
        .map((aptItem) => aptItem.typeName)
        .join(', ')}`;
    }
    if (kind === 'notApply') {
      const listNotApt = data?.filter((item) => {
        return (
          listAppointmentTypes.findIndex((appointmentType) => {
            return appointmentType.id === item.id;
          }) > -1
        );
      });
      return `Don't apply for ${listNotApt
        .map((aptItem) => aptItem.typeName)
        .join(', ')}`;
    }
  };

  const renderMedicalServices = (kind: string, data: Medical[] = []) => {
    if (kind === 'all') {
      return 'All Medical Services';
    }
    if (kind === 'apply') {
      const listMedical = data?.filter((item) => {
        return (
          listMedicalServices.findIndex((m) => {
            return m.medicalServiceId === item.medicalServiceId;
          }) > -1
        );
      });
      return `Only apply for ${listMedical
        .map((m) => m.medicalServiceName)
        .join(', ')}`;
    }
    if (kind === 'notApply') {
      const listNotMedical = data?.filter((item) => {
        return (
          listMedicalServices.findIndex((m) => {
            return m.medicalServiceId === item.medicalServiceId;
          }) > -1
        );
      });
      return `Don't apply for ${listNotMedical
        .map((m) => m.medicalServiceName)
        .join(', ')}`;
    }
  };

  const renderSpecies = (data: SpeciesSettingType[]) => {
    const speciesSelected = data?.filter((item) => {
      return (
        listSpecies.findIndex((specie) => {
          return specie.id === item.id;
        }) > -1
      );
    });

    return speciesSelected?.length
      ? speciesSelected.map((item) => item.name).join(', ')
      : 'All Species';
  };

  const handleOpenModal = (event?: React.SyntheticEvent) => {
    setDisplayModal(true);
  };

  const getFallbackData = (data: any) => {
    const fallbackData = data.slice(1, data.length);
    return fallbackData.map((item: any, index: number) => (
      <div key={'fallback' + index} className='messageKind'>
        <span>{index + 1}. </span>
        <b> {item.messageKindText} </b>
        using <b> {item.templateName} </b>
      </div>
    ));
  };

  const checkDisableSwitch = (id: string) => {
    return !isEmpty(disabledStatusList.find((item) => id === item));
  };

  const deliveryHoursCheckboxUpdate = async (submitData: any) => {
    try {
      submitData.allowSendOutsideDeliveryHours =
        !deliveryHoursCheckboxes[submitData.automationTriggerEventId];
      const res = await configuredRequests.PATCH.UpdateAutomationTriggerEvent(
        submitData,
        submitData.automationTriggerEventId
      );
      if (res) {
        let copyCheckboxes = { ...deliveryHoursCheckboxes };
        copyCheckboxes[submitData.automationTriggerEventId] =
          !copyCheckboxes[submitData.automationTriggerEventId];
        setDeliveryHoursCheckboxes(copyCheckboxes);
        //@ts-ignore
        setDataResponse((prev) => ({
          ...prev,
          items: prev
            ? prev.items.map((item) => {
                if (
                  item.automationTriggerEventId ===
                  submitData.automationTriggerEventId
                ) {
                  return {
                    ...item,
                    allowSendOutsideDeliveryHours:
                      !item.allowSendOutsideDeliveryHours,
                  };
                }
                return item;
              })
            : [],
        }));
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const renderAutomationEvents = () => {
    if (dataResponse && dataResponse.items.length > 0) {
      let filteredColumns: any[][] = [];
      switch (organizationType) {
        case OrganizationType.InteractionType:
          filteredColumns.push(
            dataResponse.items.filter(
              (res) => res.communicationTemplates[0]?.messageKindText == 'Emails'
            )
          );
          filteredColumns.push(
            dataResponse.items.filter(
              (res) => res.communicationTemplates[0]?.messageKindText == 'SMS'
            )
          );
          filteredColumns.push(
            dataResponse.items.filter(
              (res) =>
                res.communicationTemplates[0]?.messageKindText == 'Postcard'
            )
          );
          filteredColumns.push(
            dataResponse.items.filter(
              (res) => res.communicationTemplates[0]?.messageKindText == 'Call'
            )
          );
          break;
        case OrganizationType.Template:
          let templateNames: Record<string, boolean> = {};
          dataResponse.items.forEach(
            (res) =>
              (templateNames[res.communicationTemplates[0]?.templateName] =
                true)
          );
          Object.keys(templateNames).forEach((name) =>
            filteredColumns.push(
              dataResponse.items.filter(
                (res) => res.communicationTemplates[0]?.templateName == name
              )
            )
          );
          break;
        case OrganizationType.Date:
          let timeAmounts: Record<string, boolean> = {};
          dataResponse.items.forEach(
            (res) =>
              (timeAmounts[res.timeUnit + res.timeQuantity + res.scheduleKind] =
                true)
          );
          Object.keys(timeAmounts).forEach((timeAmount) =>
            filteredColumns.push(
              dataResponse.items.filter(
                (res) =>
                  res.timeUnit + res.timeQuantity + res.scheduleKind ==
                  timeAmount
              )
            )
          );
          break;
      }

      filteredColumns = filteredColumns.filter((col) => col.length > 0);

      return filteredColumns.map((columnVals, colIdx) => {
        return (
          <div className='appointment-scheduled-list-container'>
            {columnVals.map((item, index) => {
              const medicalListSelected = listMedicalServices.filter((m) =>
                item.reminderMedicalServices.includes(m.medicalServiceId)
              );
              return (
                <div
                  key={`${index}-${colIdx}`}
                  className='appointment-scheduled-list'
                >
                  <div
                    className='appointment-scheduled-item'
                    key={'automation-details-' + `${index}-${colIdx}`}
                  >
                    <div className='schedule flex1175'>
                      <div className='appointment-scheduled-show'>
                        <p>
                          Approximately{' '}
                          <b>
                            {item.timeQuantity} {item.timeUnitText}
                          </b>{' '}
                          <b>{item.scheduleKindText}</b>
                          <br />
                          Deliver Contact an{' '}
                          <b>
                            {' '}
                            {
                              item?.communicationTemplates[0]?.messageKindText
                            }{' '}
                          </b>{' '}
                          using{' '}
                          <b>
                            {' '}
                            {item?.communicationTemplates[0]?.templateName}{' '}
                          </b>{' '}
                          <br />
                          {item.communicationTemplates.length > 1 &&
                            'Fallback to:'}
                        </p>
                        <>{getFallbackData(item.communicationTemplates)}</>
                        {category === 'appointment' && (
                          <p>
                            Appointment Types:{' '}
                            <b>
                              {renderAppointmentTypes(
                                item.appointmentTypeApplyKind,
                                item.appointmentTypes
                              )}
                            </b>
                          </p>
                        )}
                        {category === 'reminder' && (
                          <p>
                            Medical Services:{' '}
                            <b>
                              {renderMedicalServices(
                                item.reminderMedicalServiceApplyKind,
                                medicalListSelected
                              )}
                            </b>
                          </p>
                        )}
                        <p>
                          Species: <b>{renderSpecies(item.petSpecies)}</b>
                        </p>
                      </div>
                    </div>

                    <div className='switch-appointment-status'>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={item.enabled}
                            onChange={handleChangeSwitch(item)}
                            name='checkedB'
                            disabled={checkDisableSwitch(
                              item.automationTriggerEventId
                            )}
                          />
                        }
                        label='Active'
                      />
                    </div>
                  </div>

                  {`${index}-${colIdx}` === currentAutomationOpen &&
                  openFragment ? (
                    <>
                      <hr />
                      <AutomationEditForm
                        checkNewEvent={checkNewEvent}
                        smsTemplateData={smsTemplateData}
                        emailTemplateData={emailTemplateData}
                        postalTemplateData={postalTemplateData}
                        callTemplateData={callTemplateData}
                        triggerId={triggerId}
                        setOpenFragment={setOpenFragment}
                        fallbackData={item.communicationTemplates.slice(
                          1,
                          item.communicationTemplates.length
                        )}
                        primaryMessage={
                          item.communicationTemplates[0]
                            ? item.communicationTemplates[0]
                            : {}
                        }
                        {...item}
                        deliverApproximatelyData={
                          deliverApproximatelyData as DeliverApproximatelyResponse
                        }
                        appointmentTypeApplyKind={item.appointmentTypeApplyKind}
                        listAppointmentTypes={listAppointmentTypes}
                        listSpecies={listSpecies}
                        setCurrentAutomationOpen={setCurrentAutomationOpen}
                        categoryKind={category}
                        automationTriggerKey={automationTriggerKey}
                        automationCategoryId={automationCategoryId}
                        automationTriggerEventId={item.automationTriggerEventId}
                        enabledStatus={item.enabled}
                        appointmentTypesSelected={item.appointmentTypes}
                        allowSendOutsideDeliveryHours={
                          deliveryHoursCheckboxes[item.automationTriggerEventId]
                        }
                        speciesSelected={item.petSpecies}
                        listMedicalServices={listMedicalServices}
                        medicalServiceApplyKind={
                          item.reminderMedicalServiceApplyKind
                        }
                        medicalServicesSelected={medicalListSelected}
                      />
                    </>
                  ) : (
                    <>
                      <div className='d-flex allow-sending-outside padding-left-17'>
                        <Checkbox
                          checked={
                            deliveryHoursCheckboxes[
                              item.automationTriggerEventId
                            ]
                          }
                          color='primary'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          onChange={() => deliveryHoursCheckboxUpdate(item)}
                          name='checkedA'
                          className='check-schedule'
                        />
                        <span>Allow sending outside delivery hours</span>
                      </div>
                      <div className='action-schedule'>
                        <button
                          className='action-edit'
                          onClick={handleOpenFragment(
                            `${index}-${colIdx}` as string
                          )}
                        >
                          <FontAwesomeIcon icon={['far', 'edit']} />
                          Edit
                        </button>
                        <button
                          className='action-copy'
                          onClick={copyTrigger(item as AutomationTrigger)}
                        >
                          <FontAwesomeIcon icon={['far', 'copy']} />
                          Copy
                        </button>
                        <button
                          className='action-delete'
                          onClick={handleOpenPopup(item as AutomationTrigger)}
                        >
                          <FontAwesomeIcon icon={['far', 'trash-alt']} />
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        );
      });
    } else {
      return (
        <p
          style={{
            textAlign: 'center',
            transform: 'rotateX(180deg)',
            direction: 'ltr',
          }}
        >
          No event available
        </p>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        <>
          <RightModalDetails
            display={displayModal}
            setDisplay={setDisplayModal}
            labelText='New Automation Event'
            triggerId={triggerId}
            category={category}
            automationTriggerKey={automationTriggerKey}
            automationCategoryId={automationCategoryId}
            checkNewEvent={checkNewEvent}
            smsTemplateData={smsTemplateData}
            emailTemplateData={emailTemplateData}
            callTemplateData={callTemplateData}
            postalTemplateData={postalTemplateData}
            deliverApproximatelyData={
              deliverApproximatelyData as DeliverApproximatelyResponse
            }
            listAppointmentTypes={listAppointmentTypes}
            listSpecies={listSpecies}
            listMedicalServices={listMedicalServices}
          />
          <div
            className='confirmation-email-title'
            key='title-confirmation-details'
          >
            {dataResponse && dataResponse?.items.length > 0 ? (
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
              >
                When {dataResponse?.items[0].automationTriggerName}
              </p>
            ) : (
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
              >
                When {titleData}
              </p>
            )}
            <div className='list-filter-container'>
              <span className='list-filter-text'>Organize By</span>
              <Select
                className='list-filter-dropdown'
                value={organizationType}
                onChange={(e) =>
                  setOrganizationType(e.target.value as OrganizationType)
                }
              >
                <MenuItem value={0}>Interaction Type</MenuItem>
                <MenuItem value={1}>Template</MenuItem>
                <MenuItem value={2}>Date</MenuItem>
              </Select>
            </div>
            <Button
              variant='contained'
              color='primary'
              className='button-form new-event'
              onClick={handleOpenModal}
            >
              <FontAwesomeIcon icon={['far', 'plus']} />
              New Event
            </Button>
          </div>
          <div className='appointment-scheduled-container'>
            {renderAutomationEvents()}
          </div>
          <Modal
            open={openPopup}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
          >
            {renderPopupWarning()}
          </Modal>
        </>
      )}
    </>
  );
};
export default AutomationDetail;
