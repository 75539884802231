import * as t from 'io-ts';

// Tasks
export const TaskResponseData = t.type({
  name: t.string,
  details: t.string,
  status: t.string,
  taskId: t.string,
});
export type TaskData = t.TypeOf<typeof TaskResponseData>;
//
