import React from 'react';
import withComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateTypes, capitalizeByNumber } from 'global/constants/API';
import { UserInteractionEntryUnionType } from 'global/requests/ResponseTypes';
import moment from 'moment';
import { Button, Modal, TextareaAutosize } from '@material-ui/core';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import parse from 'html-react-parser';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { upperFirst } from 'lodash';
import toast from 'components/Global/Toast';
import Toast from 'components/Global/Toast';

const { KIND_OF_EMAIL_OR_SMS } = TemplateTypes;
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const customStyles = {
  content: {
    width: '100%',
  },
};

interface UserInteractionProps {
  data: UserInteractionEntryUnionType;
  reloadList: any;
  userInfo?: UserDataResponse | undefined;
}

const InteractionEntry = (props: UserInteractionProps) => {
  const renderFontAwesomeIcon = (iconType: number) => {
    switch (iconType) {
      case KIND_OF_EMAIL_OR_SMS.SMS:
        return <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} />;
      case KIND_OF_EMAIL_OR_SMS.EMAIL:
        return <FontAwesomeIcon icon={['fas', 'paper-plane']} />;
      case KIND_OF_EMAIL_OR_SMS.CALL:
        return <FontAwesomeIcon icon={['fas', 'mobile-alt']} />;
      case KIND_OF_EMAIL_OR_SMS.POSTAL:
        return <FontAwesomeIcon icon={faEnvelope} />;
      default:
        return null;
    }
  };

  const initialValue = {
    value: props.data.notes || '',
    show: false,
    error: false,
  };

  const [openPopup, setOpenPopup] = React.useState(false);
  const [noteInfo, setNoteInfo] = React.useState(initialValue);
  const [openPopupTemplate, setOpenPopupTemplate] = React.useState(false);
  const [showMoreInfo, setShowMoreInfo] = React.useState<boolean>(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const mediaUrls = props.data.mediaUrls as any;

  React.useEffect(() => {
    const closeModal = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpenPopupTemplate(false);
      }
    };
    if (openPopupTemplate) {
      document.addEventListener('keydown', closeModal);
    }
    return () => {
      document.removeEventListener('keydown', closeModal);
    };
  }, [openPopupTemplate]);

  React.useEffect(() => {
    setNoteInfo((prev) => ({ ...prev, value: props.data.notes || '' }));
  }, [props.data.notes]);

  const handleShowMoreInfoClick = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const handleCancelInteraction = async () => {
    const messageId = props.data.messageId;
    try {
      const res = await configuredRequests.PATCH.cancelInteraction(
        messageId as any
      );
      if (res) {
        toast.success('Cancelled successfully!');
        props.reloadList();
      }
    } catch (err) {
      props.reloadList();
      toast.error('Unable to cancel this Email/SMS. Please try again.');
    }
  };

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    if (value.length > 500) {
      setNoteInfo((prev) => ({ ...prev, error: true }));
    } else {
      setNoteInfo((prev) => ({ ...prev, value, error: false }));
    }
  };

  const handleSaveNote = async () => {
    if (!noteInfo.value) {
      setNoteInfo((prev) => ({ ...prev, show: false }));
    }
    try {
      setNoteInfo((prev) => ({ ...prev, error: false }));
      const res = await configuredRequests.PATCH.updateMessageNote(
        props.data.messageId,
        noteInfo.value
      );
      if (res) {
        Toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      Toast.error('Your data has not been saved yet. Please try again.');
      console.log('err', err);
    }
  };

  const statusColor = () => {
    switch (props.data.status) {
      case 'Pending':
        return 'view-contact--interaction-status style-icon-status pending';
      case 'Failed':
        return 'view-contact--interaction-status style-icon-status failed';
      case 'Undelivered':
        return 'view-contact--interaction-status style-icon-status failed';
      case 'Cancelled':
        return 'view-contact--interaction-status style-icon-status cancelled';
      case 'Unsent':
        return 'view-contact--interaction-status style-icon-status unsent';
      case 'Called':
        return 'view-contact--interaction-status style-icon-status called';
      case 'Completed':
        return 'view-contact--interaction-status style-icon-status completed';
      case 'No Answer':
        return 'view-contact--interaction-status style-icon-status no-answer';
      case 'Busy':
        return 'view-contact--interaction-status style-icon-status busy';
      case 'In Progress':
        return 'view-contact--interaction-status style-icon-status in-progress';
      default:
        return 'view-contact--interaction-status style-icon-status default';
    }
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Confirmation
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          You want to cancel sending this{' '}
          {`${capitalizeByNumber(props.data.kind) ?? ''}`}? This action cannot
          be undone.
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setOpenPopup(false);
              handleCancelInteraction();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  const popupShowTemplate = () => {
    const { messageKind } = props.data;
    let { content } = props.data;
    if (messageKind === 'postal') {
      const parseContent = JSON.parse(props.data.content);
      content = parseContent.PreviewContent;
    }
    return (
      <div
        style={modalStyle}
        className={`insert-modal-style ${
          messageKind === 'postal' ? 'modal-postal' : 'modal-show-template'
        } ${messageKind === 'email' ? 'modal-send-email' : ''}`}
      >
        <h2 id='simple-modal-title'>
          {' '}
          {`${capitalizeByNumber(props.data.kind) ?? ''}`} to{' '}
          {props.userInfo?.name
            ? Object.values(props.userInfo.name).join(' ')
            : ''}
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopupTemplate(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          {parse(`${content}`)}
          {props.data?.mediaUrls
            ? props.data?.mediaUrls?.map((item: any, index: number) => {
                return (
                  <>
                    <div className='img-interaction'>
                      <img
                        className='img-responsive'
                        src={item}
                        alt=''
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(index);
                        }}
                      />
                    </div>
                  </>
                );
              })
            : ''}
        </div>
      </div>
    );
  };

  return (
    <li className='view-contact--interaction-entry'>
      <div className='view-contact--interaction-icon-container'>
        {renderFontAwesomeIcon(props.data.kind ?? 0)}

        <div className='view-contact--interaction-text-container'>
          <div className='view-contact--interaction-text-body title-text-interaction'>
            <div> {capitalizeByNumber(props.data.kind)} </div>
            <div>
              {' '}
              {props.data.sentDate
                ? moment(props.data.sentDate).format(
                    'ddd, MMM Do, YYYY hh:mm A'
                  )
                : moment(props.data.scheduleDate).format(
                    'ddd, MMM Do, YYYY hh:mm A'
                  )}{' '}
            </div>
          </div>
        </div>
      </div>

      <div className='view-contact--interaction-entry-body'>
        <div
          className='view-contact--interaction-title'
          style={{ color: '#1974ff', cursor: 'pointer' }}
        >
          {props.data.kind === KIND_OF_EMAIL_OR_SMS.POSTAL ? (
            <span
              onClick={() => {
                setOpenPopupTemplate(true);
              }}
            >
              {props.data.templateName ?? 'Blank Postal Template'}
            </span>
          ) : props.data.subject ? (
            <span
              onClick={() => {
                setOpenPopupTemplate(true);
              }}
            >
              {props.data.subject}
            </span>
          ) : (
            <span
              className='interaction-text'
              onClick={() => {
                setOpenPopupTemplate(true);
              }}
            >
              {props.data.content ? props.data.content : 'Photo(s)'}
            </span>
          )}
        </div>

        <div className={statusColor()}>{props.data.status}</div>
      </div>

      <div className='view-contact--more-info-container'>
        <button
          className={
            'view-contact--more-info-button' +
            (showMoreInfo ? ' showing-more' : '')
          }
          onClick={handleShowMoreInfoClick}
        >
          Details <FontAwesomeIcon icon={['fas', 'chevron-down']} />
        </button>
        {props.data.status === 'Pending' && (
          <Button
            variant='contained'
            color='default'
            className='cancel-button'
            onClick={() => setOpenPopup(true)}
          >
            <span>Cancel</span>
          </Button>
        )}

        <div
          className={
            'view-contact--more-info-body' +
            (showMoreInfo ? ' showing-more' : '')
          }
        >
          <div className='view-contact--interaction-entry-body padd-0'>
            <div className='details-user'>
              <p className='title-detail'> Recipient </p>
              <div className='line-copy'></div>
              <p className='infor-detail'>
                {props.userInfo?.name
                  ? Object.values(props.userInfo.name).join(' ')
                  : ''}
              </p>
            </div>

            <div className='details-user'>
              <p className='title-detail'> Template </p>
              <div className='line-copy'></div>
              <p className='infor-detail'>
                {' '}
                {props.data.templateName
                  ? props.data.templateName
                  : `Blank ${upperFirst(props.data.messageKind)} Template`}{' '}
              </p>
            </div>

            <div className='details-user'>
              <p className='title-detail'> Creation Date </p>
              <div className='line-copy'></div>
              <p className='infor-detail'>
                {' '}
                {props.data.createdDate
                  ? moment(props.data.createdDate).format(
                      'ddd, MMM Do, YYYY hh:mm A'
                    )
                  : ''}{' '}
              </p>
            </div>

            <div className='details-user'>
              {((props.data.status === 'Pending' &&
                props.data.messageKind !== 'call') ||
                (props.data.status === 'Unsent' &&
                  props.data.messageKind !== 'call') ||
                props.data.messageKind === 'call') &&
                props.data.status !== 'Cancelled' && (
                  <>
                    <p className='title-detail'>
                      {' '}
                      {props.data.messageKind === 'call'
                        ? 'Call Date'
                        : 'Schedule to Send On'}{' '}
                    </p>
                    <div className='line-copy'></div>
                    <p className='infor-detail'>
                      {' '}
                      {props.data.sentDate
                        ? moment(props.data.sentDate).format(
                            'ddd, MMM Do, YYYY hh:mm A'
                          )
                        : moment(props.data.scheduleDate).format(
                            'ddd, MMM Do, YYYY hh:mm A'
                          )}{' '}
                    </p>
                  </>
                )}

              {props.data.status === 'Cancelled' && (
                <>
                  <p className='title-detail'> Cancelled Date </p>
                  <div className='line-copy'></div>
                  <p className='infor-detail'>
                    {' '}
                    {props.data.sentDate
                      ? moment(props.data.sentDate).format(
                          'ddd, MMM Do, YYYY hh:mm A'
                        )
                      : ''}{' '}
                  </p>
                </>
              )}

              {props.data.messageKind !== 'call' &&
                props.data.status !== 'Pending' &&
                props.data.status !== 'Unsent' &&
                props.data.status !== 'Cancelled' && (
                  <>
                    <p className='title-detail'> Sent Date </p>
                    <div className='line-copy'></div>
                    <p className='infor-detail'>
                      {' '}
                      {props.data.sentDate
                        ? moment(props.data.sentDate).format(
                            'ddd, MMM Do, YYYY hh:mm A'
                          )
                        : ''}{' '}
                    </p>
                  </>
                )}
            </div>

            <div className='details-user'>
              <p className='title-detail'> Delivery Status </p>
              <div className='line-copy'></div>
              <p className='infor-detail'> {props.data.status} </p>
            </div>

            <div className='details-user'>
              <p className='title-detail'>Note</p>
              <div className='line-copy'></div>
              {!noteInfo.show ? (
                <>
                  <div className='box-input__new-note'>
                    <TextareaAutosize
                      className='input'
                      rowsMin={5}
                      value={noteInfo.value}
                      onChange={handleChangeNote}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      className='cancel-button'
                      onClick={handleSaveNote}
                    >
                      <span>Save</span>
                    </Button>
                  </div>
                  {noteInfo.error && (
                    <p className='error'>
                      Cannot input more than 500 characters.
                    </p>
                  )}
                </>
              ) : (
                <p
                  className='infor-detail btn-new-note'
                  onClick={() =>
                    setNoteInfo((prev) => ({ ...prev, show: true }))
                  }
                >
                  Add New Note +
                </p>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            reactModalStyle={customStyles}
            mainSrc={mediaUrls[photoIndex]}
            nextSrc={mediaUrls[(photoIndex + 1) % mediaUrls.length]}
            prevSrc={
              mediaUrls[(photoIndex + mediaUrls.length - 1) % mediaUrls.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + mediaUrls.length - 1) % mediaUrls.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % mediaUrls.length)
            }
            clickOutsideToClose={true}
          />
        )}
        <Modal
          open={openPopupTemplate}
          aria-labelledby='simple-modal-title'
          className='modal-show-image'
          aria-describedby='simple-modal-description'
        >
          {popupShowTemplate()}
        </Modal>
      </div>
      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </li>
  );
};

const ComponentizedInteractionEntry =
  withComponentClassAndHeaderText(InteractionEntry);

export default ComponentizedInteractionEntry;
