import APIService from '../clientApi';
import {
  ICategories,
  ITemplateDataForCategory,
  TemplateCategoryParam,
  ISupportedLanguage,
  ITemplateFindById,
  TemplatesFindByIdParam,
  GenerateNewTemplateParam,
  IGenerateNewTemplate,
  TemplateUpsertParam,
  ITemplateUpsert,
  DefaultTemplateForParam,
  IDefaultTemplateFor,
  GetPlaceholdersParam,
  IGetPlaceholders,
  NewUploadParam,
  INewUpload,
  CreateMessagesParam,
  ICreateMessages,
  IGetProof,
  ProofParam,
  RecipientCountForMessageTypeParam,
  IRecipientCountForMessageType,
  MessagePriceParam,
  IMessagePrice,
  ITemplateSelectParams,
  IGiftOption,
} from './templateType';

import { ITemplateSelectOption } from './templateUITypes';

import {
  iServerTemplateSelectOptionToITemplateSelectOption,
} from './transformers';

const API = APIService();

const getCategories = async (params: []): Promise<ICategories> =>
  API.rpc('', { method: 'message_template.getCategories', params });

const getTemplateDataForCategory = async (
  params: TemplateCategoryParam,
): Promise<ITemplateDataForCategory> =>
  API.rpc('', { method: 'template.getTemplateDataForCategory', params });

const getSupportedLanguages = async (params: []): Promise<ISupportedLanguage> =>
  API.rpc('', { method: 'account.getSupportedLanguages', params });

const getTemplateFindById = async (params: TemplatesFindByIdParam): Promise<ITemplateFindById> =>
  API.rpc('', { method: 'template.findById', params });

const generateNewTemplate = async (
  params: GenerateNewTemplateParam,
): Promise<IGenerateNewTemplate> =>
  API.rpc('', { method: 'message_template.generateNewTemplate', params });

const templateUpsert = async (params: TemplateUpsertParam): Promise<ITemplateUpsert> =>
  API.rpc('', { method: 'template.upsert', params });

const deleteTemplate = async (params: [string]): Promise<void> =>
  API.rpc('', { method: 'message_template.delete', params });

const getCallTypes = async (params: []): Promise<any> =>
  API.rpc('', { method: 'account_phone.findAll', params });

const defaultTemplateFor = async (params: DefaultTemplateForParam): Promise<IDefaultTemplateFor> =>
  API.rpc('', { method: 'message_template.defaultTemplateFor', params });

const getPlaceholders = async (params: GetPlaceholdersParam): Promise<IGetPlaceholders> =>
  API.rpc('', { method: 'message_template.getPlaceholders', params });

const getRecipientCountForMessageType = async (
  params: RecipientCountForMessageTypeParam,
): Promise<IRecipientCountForMessageType> =>
  API.rpc('', { method: 'touchpoint.getRecipientCountForMessageType', params });

const initNewUpload = async (params: NewUploadParam): Promise<INewUpload> =>
  API.rpc('', { method: 'fileupload.initNewUpload', params });

const createMessages = async (params: CreateMessagesParam): Promise<ICreateMessages> =>
  API.rpc('', { method: 'messages.create', params });

const getProof = async (params: ProofParam): Promise<IGetProof> =>
  API.rpc('', { method: 'touchpoint.getProof', params });

const getMessagePrice = async (params: MessagePriceParam): Promise<IMessagePrice> =>
  API.rpc('', { method: 'billing.getMessagePrice', params });

const getTemplateSelectOptions = async (
  params: ITemplateSelectParams,
): Promise<ITemplateSelectOption[]> =>
  API.rpc(
    '',
    { method: 'message_template.getTemplateSelectOptions', params: [params] },
    undefined,
    true,
  ).then(t => t.map(iServerTemplateSelectOptionToITemplateSelectOption));

const getGifts = async (): Promise<IGiftOption[]> =>
  API.rpc('', { method: 'messages.getGifts', params: [] }, undefined, true);

const restoreMessageTemplate = async (params: [string, string]): Promise<void> =>
  API.rpc('', { method: 'template.restore', params: ['MessageTemplate', ...params] });

const restoreTemplate = async (params: [string, string, string]): Promise<void> =>
  API.rpc('', { method: 'template.restore', params });

export default {
  restoreTemplate,
  getCategories,
  getTemplateDataForCategory,
  getSupportedLanguages,
  getTemplateFindById,
  generateNewTemplate,
  templateUpsert,
  deleteTemplate,
  getCallTypes,
  defaultTemplateFor,
  getPlaceholders,
  getRecipientCountForMessageType,
  initNewUpload,
  createMessages,
  getProof,
  getMessagePrice,
  getTemplateSelectOptions,
  getGifts,
  restoreMessageTemplate,
};
