import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';

export const loginWithAdmin = () => {
  const strUser = window.sessionStorage.getItem('userData--full_response');

  if (!strUser) return false;

  const user = JSON.parse(strUser) as UserDataResponse;

  // @ts-ignore
  return !!user.roles?.find((role) => role.roleName === 'System Admin');
};
