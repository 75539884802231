import React from 'react';

import PaginationTableFooter from '../components/PaginationTableFooter';
import TableWithHeader from '../components/TableWithHeader';
import RenderRows from '../components/RenderRows';
import { IRow } from '../components/Row/Row';

export interface SplitPaginationTableGeneratorProps {
  headCells: (string | React.ReactElement)[];
  rows: IRow[];
  generateRow: (row: IRow) => React.ReactElement[];
  dense?: boolean;
  rowsPerPage?: number; // prop because API dependent
  page?: number;
  totalRows: number;
  fetchRows: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  hoverable?: boolean;
  NoResultsComponent?: React.ElementType;
}

const SplitPaginationTableGenerator = (
  props: SplitPaginationTableGeneratorProps,
): { Table: () => React.ReactElement; Pagination: () => React.ReactElement } => {
  const {
    headCells,
    rows = [],
    generateRow,
    dense = false,
    rowsPerPage = 1,
    page = 0,
    totalRows,
    fetchRows,
    hoverable = false,
    NoResultsComponent,
  } = props;

  return {
    Table: (): React.ReactElement => (
      <TableWithHeader headCells={headCells} dense={dense}>
        <RenderRows rows={rows} generateRow={generateRow} hoverable={hoverable} />
      </TableWithHeader>
    ),
    Pagination: (): React.ReactElement => (
      <PaginationTableFooter
        page={page}
        tableColumns={headCells.length}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        handleChangePage={fetchRows}
        NoResultsComponent={NoResultsComponent}
      />
    ),
  };
};

export default SplitPaginationTableGenerator;
