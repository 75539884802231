/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@material-ui/core';
import SubscriptionLayer from 'components/Contacts/ViewContact/SubscriptionLayer';
import SubscriptionSetting from 'components/Contacts/ViewContact/SubscriptionSetting';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  ContactListResponse,
  ContactMessages,
} from 'global/requests/ResponseTypes';
import { UserSettingObjectType } from 'global/requests/ResponseTypes/UserAdministration';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import { useRouter } from 'hooks/useRouter';
import { orderBy } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { isSafari, isWindows } from 'react-device-detect';
import styled from 'styled-components';
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import MessengerContactListItem from './MessengerContactListItem';
import MessengerHistory from './MessengerHistory';
import MessengerInput from './MessengerInput';
import NewMessage from './NewMessage';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';

const NoResultWrapped = styled.p`
  margin-left: 20px;
`;

export type Error = typeof initialError;

interface MessengerProps {
  contactEntries: ContactListResponse | undefined;
  getTextMessageData: (
    userID: string,
    disabledLoading?: boolean,
    allMessage?: boolean
  ) => void;
  getContactListData: (query: any, isNotResetList?: boolean) => Promise<void>;
  setQueryPagination: (query: any) => void;
  isMaxMessagers: Boolean;
  isWaiting: Boolean;
  loadingMessenger: Boolean;
  queryPagination: any;
  messageDataLookup: any;
  setMessageDataLookup: any;
  settingsTimezoneData?: string;
  isContactListLoading: boolean;
  setIsContactListLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialError = {
  mount: true,
  text: 'This field is required.',
};

const Messenger = (props: MessengerProps): JSX.Element => {
  const router = useRouter();
  const fullTenantInfoData = getSessionTenantInfo();
  const [currentSelectedContactUUID, setCurrentSelectedContactUUID] =
    React.useState('');
  const [currentSelectedContactData, setCurrentSelectedContactData] =
    React.useState<ContactMessages>();
  const [viewIsChangingUsers, setViewIsChangingUsers] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [selectedContactId, setChangeSelectedContactId] = React.useState('');
  const messageHistoryListRef = React.createRef<HTMLUListElement>();
  const userInputMessageBodyRef = React.createRef<HTMLTextAreaElement>();
  const searchInputRef = React.createRef<HTMLInputElement>();
  const [clickedContactMessenger, setClickedContactMessenger] =
    React.useState(false);
  const [infoSending, setInfoSending] = React.useState({
    sending: false,
    isError: false,
  });
  const sortedMessageData = orderBy(
    props.contactEntries?.users,
    ['latestMessageDate'],
    ['desc']
  );
  const [searchMessageId, setSearchMessageId] = React.useState<string>('');
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [isListSearching, setIsListSearching] = React.useState<boolean>(false);
  const [countClickInputText, setCountClickInputText] =
    React.useState<number>(0);
  const [searchTextMemory, setSearchTextMemory] = React.useState<string>();
  const [viewAllSms, setViewAllSms] = React.useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [openPopupNewMessage, setOpenPopupNewMessage] = React.useState(false);
  const [errorNewMessage, setErrorNewMessage] =
    React.useState<Error>(initialError);
  const [loadingSendMessage, setLoadingSendMessage] = React.useState(false);
  const [openSubscriptionSetting, setOpenSubscriptionSetting] =
    React.useState(false);

  const [allowTextMessageWriteback, setAllowTextMessageWriteback] = React.useState<boolean>(false);

  const isUserLogin = !!getSessionUserData();

  const getSettingByNamespace = (
    userInfo: ContactMessages,
    namespace: string
  ) => {
    return userInfo.userSettings?.find((f) => f.namespace == namespace);
  };

  const getcommunicationTypeStatus = (communicationType: string) => {
    if (
      currentSelectedContactData == null ||
      currentSelectedContactData == undefined
    ) {
      return false;
    }

    let setting = getSettingByNamespace(
      currentSelectedContactData,
      'UserSubscriptionSetting'
    );
    if (communicationType == 'text') {
      return setting?.settings.sms;
    }

    if (communicationType == 'email') {
      return setting?.settings.email;
    }

    if (communicationType == 'postal') {
      return setting?.settings.postal;
    }

    if (communicationType == 'phone') {
      return setting?.settings.phone;
    }

    return false;
  };

  // update visible contact name and history
  const handleNewUserSelected = (newUserID: string) => {
    if (newUserID === currentSelectedContactUUID || !props.contactEntries) {
      return;
    }
    setInfoSending({ sending: false, isError: false });
    setViewIsChangingUsers(true);
    setCurrentSelectedContactUUID(newUserID);
    const newSelectedContactData = props?.contactEntries?.users.filter(
      (e) => e.id === newUserID
    )[0];
    setCurrentSelectedContactData(newSelectedContactData);
    props.getTextMessageData(newUserID);
    // this is a bit of a hack but it shouldn't cause any issues, and it feels way more "buggy" without this
    setImmediate(() => {
      setViewIsChangingUsers(false);
    });
  };

  const updateContactUnRead = async (
    hasRead: boolean,
    isNotResetList?: boolean
  ) => {
    try {
      if (selectedContactId) {
        await configuredRequests.POST.updateMarkUnread(
          selectedContactId,
          hasRead
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const userMessageString = window.sessionStorage.getItem('message-user');
    if (!!userMessageString) {
      const userMessage = JSON.parse(userMessageString);
      setCurrentSelectedContactUUID(userMessage.id);
      setCurrentSelectedContactData({ ...userMessage, isMessageRead: true });
      props.getTextMessageData(userMessage.id);
      setChangeSelectedContactId(userMessage.id);
    }
  }, []);

  // initial data request
  React.useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
    props.getContactListData({ limit: 20, offset: 0 });
    getDriverConfiguration(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverConfiguration = async () => {
    try {
      const res = await configuredRequests.GET.getDriverConfiguration(fullTenantInfoData?.tenantId ?? '');
      if (res) {
        setAllowTextMessageWriteback(res.allowTextMessageWriteback);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  React.useEffect(() => {
    const getNewMessage = setInterval(() => {
      if (infoSending.isError == true || infoSending.sending == true)
        setInfoSending({ sending: false, isError: false });
      if (selectedContactId) {
        if (selectedContactId === currentSelectedContactUUID) {
          updateContactUnRead(true);
        }
        if (viewAllSms) {
          props.getTextMessageData(selectedContactId, true, true);
        } else {
          Promise.all([
            props.getContactListData({
              offset: 0,
              limit: props?.queryPagination?.offset
                ? props?.queryPagination?.offset * props?.queryPagination?.limit
                : props?.queryPagination?.limit,
              isSearch: true,
              searchText,
            }),
            props.getTextMessageData(selectedContactId, true),
          ]);
        }
      } else {
        props.getContactListData({
          offset: 0,
          limit: props?.queryPagination?.offset
            ? props?.queryPagination?.offset * props?.queryPagination?.limit
            : props?.queryPagination?.limit,
          isSearch: true,
          searchText,
        });
      }
    }, 30000);

    return () => clearInterval(getNewMessage);
  });

  const scrollToBottom = useCallback(() => {
    if (!props.isMaxMessagers && !props.isWaiting) {
      if (isListSearching) {
        props.setQueryPagination((preState: any) => ({
          ...preState,
          offset: preState.offset + 1,
        }));
        props.getContactListData({
          ...props.queryPagination,
          offset: props.queryPagination.offset + 1,
          searchText,
        });
      } else {
        props.setQueryPagination((preState: any) => ({
          ...preState,
          offset: preState.offset + 1,
        }));
        props.getContactListData({
          ...props.queryPagination,
          offset: props.queryPagination.offset + 1,
        });
      }
    }
  }, [props]);

  const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);
  const handleFocusSearchInput = () => {
    searchInputRef.current?.focus();
  };

  const getListMessageContact = () => {
    props.setQueryPagination({ limit: 20, offset: 0 });
    props.getContactListData({ isSearch: true });
  };

  const searchMessageWithName = useCallback(
    (name: any) => {
      if (name.trim().length === 0) {
        setIsListSearching(false);
        return;
      }
      if (name) {
        props.setIsContactListLoading(true);
        props.setQueryPagination({ limit: 20, offset: 0 });
        props.getContactListData({
          searchText: name,
          isSearch: true,
          offset: 0,
          limit: 20,
        });
      } else {
        getListMessageContact();
      }
    },
    [props.getContactListData, getListMessageContact]
  );

  const handleUpdateSubscription = (settings: UserSettingObjectType[]) => {
    // @ts-ignore
    setCurrentSelectedContactData((prev) => ({
      ...prev,
      userSettings: settings,
    }));
  };

  const handleAfterSend = () => {
    setLoadingSendMessage(true);
    setOpenPopupNewMessage(false);
    setPhoneNumber('');
    setErrorNewMessage({ mount: true, text: 'This field is required.' });
  };

  const handleSearchInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const userInput = event.currentTarget.value;
    if (userInput === '') {
      props.setIsContactListLoading(true);
      getListMessageContact();
      setIsSearching(false);
      setIsListSearching(false);
    } else {
      if (!isSearching) {
        setIsSearching(true);
      }
    }
    setSearchText(userInput);
  };

  const handleOnBlur = () => {
    setSearchText(searchText.trim());
  };

  const loadingComponent = (
    <div className='spinner-upload'>
      <img src={loading_spinner} alt={'Loading'} />
    </div>
  );

  const renderContactList = (sortedMessageData: any[]) => {
    if (sortedMessageData[0].searchedMesageId && !isListSearching) {
      return loadingComponent;
    }
    if (!sortedMessageData[0].searchedMesageId && isListSearching) {
      return loadingComponent;
    }
    return sortedMessageData.map((contactInfo: any, index) => {
      return (
        <MessengerContactListItem
          key={'messenger-sidebar-list-' + index}
          contactInfo={contactInfo}
          currentSelectedContactData={currentSelectedContactData}
          currentSelectedContactUUID={currentSelectedContactUUID}
          handleNewUserSelected={handleNewUserSelected}
          searchText={searchText}
          getContactListData={props.getContactListData}
          lastMessageTimestamp={
            moment(contactInfo.latestMessageDate).isSame(
              convertLocalTimeToAnotherTimeZone(
                props.settingsTimezoneData ? props.settingsTimezoneData : ''
              ),
              'day'
            )
              ? moment(contactInfo.latestMessageDate).format(
                  '[Today at] hh:mm A'
                )
              : moment(contactInfo.latestMessageDate).format('MMM Do, YYYY')
          }
          setChangeSelectedContactId={setChangeSelectedContactId}
          setInfoSending={setInfoSending}
          setCurrentSelectedContactData={setCurrentSelectedContactData}
          setCurrentSelectedContactUUID={setCurrentSelectedContactUUID}
          setClickedContactMessenger={setClickedContactMessenger}
          setSearchMessageId={setSearchMessageId}
          isSearching={isSearching}
          queryPagination={props.queryPagination}
          isListSearching={isListSearching}
          countClickInputText={countClickInputText}
          setCountClickInputText={setCountClickInputText}
          searchTextMemory={searchTextMemory}
        />
      );
    });
  };

  return (
    <div className='messenger--container'>
      <div className='messenger--contact-sidebar'>
        <div className='messenger--contact-search-container'>
          <div
            className={
              isSafari ? 'search-safari-contact' : 'search-icon-svg-container'
            }
            onClick={handleFocusSearchInput}
          >
            <FontAwesomeIcon icon={['far', 'search']} />
          </div>

          <input
            type='search'
            ref={searchInputRef}
            placeholder='Search by names and more'
            className='messenger--contact-search-input'
            value={searchText}
            onChange={handleSearchInput}
            onBlur={handleOnBlur}
            onKeyPress={(event: React.KeyboardEvent) => {
              switch (event.key) {
                case ' ':
                  break;
                case 'Enter': {
                  if (searchText.trim() !== '') {
                    setIsSearching(false);
                    setIsListSearching(true);
                    searchMessageWithName(searchText.trim());
                  }
                  setSearchText(searchText.trim());
                  //@ts-ignore
                  setSearchTextMemory(event.target.value);
                  break;
                }
              }
            }}
          />
        </div>

        <ul
          className='messenger--contact-list'
          // @ts-ignore
          ref={scrollRef}
        >
          {props.isContactListLoading ? (
            loadingComponent
          ) : sortedMessageData.length > 0 ? (
            renderContactList(sortedMessageData)
          ) : (
            <NoResultWrapped>No results found</NoResultWrapped>
          )}
        </ul>
      </div>

      {/* end of sidebar - beginning of history */}

      <div className='messenger--chat-container'>
        {currentSelectedContactData?.name &&
          !getcommunicationTypeStatus('text') && (
            <SubscriptionLayer
              subscription={!!getcommunicationTypeStatus('text')}
              message={`${currentSelectedContactData?.name} has unsubscribed receiving text message.`}
              userId={currentSelectedContactData?.id ?? ''}
              inMessenger={true}
              onSubscriptionClick={setOpenSubscriptionSetting}
            />
          )}

        <header className='messenger--chat-header'>
          {isUserLogin && (
            <Button
              variant='contained'
              color='primary'
              className='btn-new-message'
              onClick={() => setOpenPopupNewMessage(true)}
            >
              <FontAwesomeIcon icon={['far', 'plus']} />
              New Text Message
            </Button>
          )}
          {sortedMessageData.length > 0 && (
            <h1
              className='messenger--chat-header-contact-name'
              onClick={() =>
                (currentSelectedContactData?.id?.length ?? 0) > 15
                  ? router.push(
                      `/Contacts/View/${currentSelectedContactData?.id}`
                    )
                  : ''
              }
              style={
                (currentSelectedContactData?.id?.length ?? 0) > 15
                  ? {
                      color: '#0074ff',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }
                  : {}
              }
            >
              <HighlightText
                text={
                  currentSelectedContactData?.name
                    ? currentSelectedContactData.name
                    : 'Select a Contact'
                }
                searchValue={searchTextMemory}
              />
            </h1>
          )}
        </header>

        <MessengerHistory
          searchMessageId={searchMessageId}
          messageHistory={props.messageDataLookup}
          currentSelectedContactData={currentSelectedContactData}
          currentSelectedContactUUID={currentSelectedContactUUID}
          messageHistoryListRef={messageHistoryListRef}
          viewIsChangingUsers={viewIsChangingUsers}
          infoSending={infoSending}
          setInfoSending={setInfoSending}
          searchText={searchText}
          // @ts-ignore
          loadingMessenger={props.loadingMessenger}
          clickedContactMessenger={clickedContactMessenger}
          contactEntries={props.contactEntries}
          isListSearching={isListSearching}
          setCurrentSelectedContactData={setCurrentSelectedContactData}
          setCurrentSelectedContactUUID={setCurrentSelectedContactUUID}
          searchTextMemory={searchTextMemory}
          allowTextMessageWriteback={allowTextMessageWriteback}
        />

        {selectedContactId && currentSelectedContactData && (
          <MessengerInput
            userInputMessageBodyRef={userInputMessageBodyRef}
            userInfo={currentSelectedContactData}
            setMessageDataLookup={props.setMessageDataLookup}
            setInfoSending={setInfoSending}
            refreshMessageData={props.getTextMessageData}
            getContactListData={props.getContactListData}
            queryPagination={props.queryPagination}
            isListSearching={isListSearching}
            countClickInputText={countClickInputText}
            setCountClickInputText={setCountClickInputText}
            isSmsSubscription={!!getcommunicationTypeStatus('text')}
            viewAllSms={viewAllSms}
            setViewAllSms={setViewAllSms}
          />
        )}

        {openPopupNewMessage && (
          <NewMessage
            onClosePopup={setOpenPopupNewMessage}
            inputComponent={
              <MessengerInput
                userInputMessageBodyRef={userInputMessageBodyRef}
                userInfo={undefined}
                setMessageDataLookup={props.setMessageDataLookup}
                setInfoSending={setInfoSending}
                refreshMessageData={props.getTextMessageData}
                getContactListData={props.getContactListData}
                queryPagination={props.queryPagination}
                isListSearching={isListSearching}
                isSmsSubscription={false}
                viewAllSms={viewAllSms}
                setViewAllSms={setViewAllSms}
                phoneReceiver={phoneNumber}
                searchText={searchText}
                isErrorPhoneReceiver={!!errorNewMessage.text}
                handleAfterSendToNewPhone={handleAfterSend}
                setLoadingSendMessage={setLoadingSendMessage}
              />
            }
            phoneReceive={phoneNumber}
            onChangePhoneReceive={setPhoneNumber}
            errorPhone={errorNewMessage}
            onChangeError={setErrorNewMessage}
          />
        )}

        <Modal
          open={loadingSendMessage}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <img
            className='loading-appointment-info'
            src={loading_spinner}
            alt={'Loading'}
          />
        </Modal>

        {openSubscriptionSetting && currentSelectedContactData && (
          <Modal
            open={openSubscriptionSetting}
            onClose={() => setOpenSubscriptionSetting(false)}
          >
            <SubscriptionSetting
              settings={currentSelectedContactData.userSettings}
              userId={currentSelectedContactData.id || ''}
              fullName={currentSelectedContactData.name || ''}
              onClose={setOpenSubscriptionSetting}
              onUpdate={handleUpdateSubscription}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default React.memo(Messenger);
