import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { Formik, ErrorMessage, FastField, FieldArray } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import Toast from 'components/Global/Toast';
import {
  CollectionMangoPhoneType,
  MangoPhoneType,
} from 'global/requests/ResponseTypes/MangoSetting';
import { phoneNumberPattern } from 'utils/regex';

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonDelete = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e7eef4;
  cursor: pointer;
`;

const LinkButton = styled.span`
  margin-top: 0 !important;
`;

const initialPhones = [
  { id: '', tenantId: '', phoneNumber: '', createdAt: '' },
];

const ErrorMessageTypes = {
  required: 'This field is required.',
  incorrectFormat: 'Incorrect format.',
};

const validateSchema = Yup.object().shape({
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string()
        .required(ErrorMessageTypes.required)
        .matches(phoneNumberPattern, ErrorMessageTypes.incorrectFormat),
    })
  ),
});

const MangoSetting = (): JSX.Element => {
  const [phones, setPhones] = useState<CollectionMangoPhoneType>(initialPhones);
  const [isLoading, setIsLoading] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const getMangoPhones = async () => {
    try {
      const res = await configuredRequests.GET.getListMangoPhone();
      if (res) {
        setIsLoading(false);
        setPhones(res.length > 0 ? res : initialPhones);
      }
    } catch (err) {
      console.log('err', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMangoPhones();
  }, []);

  const submitData = async (values: { phoneNumbers: MangoPhoneType[] }) => {
    try {
      setDisabledSubmit(true);
      const res = await configuredRequests.PATCH.updateMangoSetting(
        values.phoneNumbers.map((phone) => phone.phoneNumber)
      );
      if (res) {
        setDisabledSubmit(false);
        Toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setDisabledSubmit(false);
      Toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ phoneNumbers: phones }}
      validationSchema={validateSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, handleBlur, setFieldValue } = formikProps;
        return (
          <div className='w-300'>
            {isLoading ? (
              <Loading className='loading-appointment-info' />
            ) : (
              <div className='react-modal--content'>
                <div className='communication-container'>
                  <div className='communication-container__table'>
                    <div className='edit-appointment roleModal'>
                      <Fragment>
                        <form
                          onSubmit={handleSubmit}
                          className='col-12 form-schedule react-modal--settings__content'
                        >
                          <div className='form-input-settings setting__content--full-row block'>
                            <p className='title-header'>Mango Phone Numbers</p>

                            <FieldArray
                              name='phoneNumbers'
                              render={({ remove, push }) => (
                                <>
                                  {values.phoneNumbers.length > 0 &&
                                    values.phoneNumbers.map((phone, index) => {
                                      return (
                                        <div key-={index}>
                                          <Box>
                                            <div
                                              className='first-name width-50'
                                              key={index}
                                            >
                                              <FastField
                                                name={`phoneNumbers.${index}.phoneNumber`}
                                                maxLength={255}
                                                required
                                                className='setting__search-form'
                                                onBlur={(
                                                  e: React.FocusEvent<HTMLInputElement>
                                                ) => {
                                                  handleBlur(e);
                                                  setFieldValue(
                                                    `phoneNumbers.${index}.phoneNumber`,
                                                    e.currentTarget.value.trim()
                                                  );
                                                }}
                                              />
                                            </div>
                                            <ButtonDelete
                                              onClick={() => remove(index)}
                                            >
                                              <FontAwesomeIcon
                                                icon={['fal', 'times']}
                                                className='icon-remove-fallback'
                                              />
                                            </ButtonDelete>
                                          </Box>
                                          <p className='error-msg'>
                                            <ErrorMessage
                                              className='error-msg'
                                              name={`phoneNumbers.${index}.phoneNumber`}
                                            />
                                          </p>
                                        </div>
                                      );
                                    })}

                                  <LinkButton
                                    className='add-fallback'
                                    onClick={() => {
                                      push({
                                        id: '',
                                        tenantId: '',
                                        phoneNumber: '',
                                        createAt: '',
                                      });
                                    }}
                                  >
                                    +Add Phone Number
                                  </LinkButton>
                                </>
                              )}
                            />
                          </div>

                          <Button
                            style={{ marginLeft: '10px' }}
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={!formikProps.isValid || disabledSubmit}
                          >
                            <span>Save</span>
                          </Button>
                        </form>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default MangoSetting;
