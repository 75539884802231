enum SummaryItemTypeEnum {
  "phone", "email", "pets", "tasks"
};

export type SummaryItemTypes = keyof typeof SummaryItemTypeEnum;

enum UserStatusEnum {
  "Active", "InActive", "Potential"
};

export type UserStatusTypes = keyof typeof UserStatusEnum;

export enum UserKindEnum {
  "User", "Customer", "Admin", "Undefined", "Visitor"
  // it could be a good idea to add support for these
  // but for now I will require strictly the capitalized variants
  // "user", "customer", "admin",
  // "USER", "CUSTOMER", "ADMIN"
};

export type UserKindTypes = keyof typeof UserKindEnum;

export enum TemplateKindsEnum {
  all = "sms,email",
  sms = "sms",
  email = "email",
  call = "call",
  postal = "postal"
};

export type TemplateKinds = keyof typeof TemplateKindsEnum;

export enum CategoryKindsEnum {
  all = "appointment,newContacts,marketing,manual",
  appointment = "appointment",
  newContacts = "newContacts",
  marketing = "marketing",
  manual = "manual"
}

export type TemplateCategoryKinds = keyof typeof CategoryKindsEnum;
