import * as t from 'io-ts';

// GroupMemberEntry
const RequiredGroupMemberEntryData = t.type({
  id: t.string,
  enabled: t.boolean,
});

const NullableGroupMemberEntryData = t.partial({
  name: t.string,
  emailAddress: t.string,
});

const FullGroupMemberEntryData = t.intersection([
  RequiredGroupMemberEntryData,
  NullableGroupMemberEntryData,
]);
export type GroupMemberEntry = t.TypeOf<typeof FullGroupMemberEntryData>;
//

// GroupDataResponse
const RequiredGroupData = t.type({
  groupId: t.string,
  tenantId: t.string,
});

const NullableGroupData = t.partial({
  name: t.string,
  members: t.array(FullGroupMemberEntryData),
});

export const FullGroupData = t.intersection([
  RequiredGroupData,
  NullableGroupData,
]);
export type GroupDataResponse = t.TypeOf<typeof FullGroupData>;
//

// Group info gives you the tenant id and the group id which will allow us to request the specific groups
const GroupInfoEntryData = t.type({
  groupId: t.string,
  tenantId: t.string,
  name: t.string,
});
export type GroupInfoEntry = t.TypeOf<typeof GroupInfoEntryData>;
//

// GroupInfoResponse
export const GroupInfoResponseData = t.type({
  groups: t.array(GroupInfoEntryData),
});
export type GroupInfoResponse = t.TypeOf<typeof GroupInfoResponseData>;
//
