import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MediumEmphasizedDarkText } from 'components/Legwork';
import Styles from './styles';

const FormModalTitle = ({
  recordType,
  label,
  currentIndex,
  totalCount,
  onNextClick,
  onPreviousClick,
  onEdit,
}) => {
  const isPreviousDisabled = () => false;
  const isNextDisabled = () => false;

  const handleOnClick = direction => {
    // reset isEditing to false
    onEdit(false);

    if (direction === 'previous' && !isPreviousDisabled()) onPreviousClick();
    else if (direction === 'next' && !isNextDisabled()) onNextClick();
  };

  return (
    <div style={Styles.main}>
      <MediumEmphasizedDarkText style={{ lineHeight: '48px' }}>
        {recordType} {parseInt(currentIndex, 10) + 1} of {totalCount}
      </MediumEmphasizedDarkText>
      <MediumEmphasizedDarkText style={{ lineHeight: '48px' }}> "{label}"</MediumEmphasizedDarkText>
      <div>
        <IconButton onClick={() => handleOnClick('previous')}>
          <ChevronLeftIcon />
        </IconButton>

        <IconButton onClick={() => handleOnClick('next')}>
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
};

FormModalTitle.propTypes = {
  recordType: PropTypes.string,
  label: PropTypes.string,
  currentIndex: PropTypes.number,
  totalCount: PropTypes.number,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  onEdit: PropTypes.func,
};

export default FormModalTitle;
