import styled from 'styled-components';
import { get } from 'lodash';

export const Wrapper = styled.div`
  padding: 0px;

  .editor-postal {
    textarea {
      display: none;
    }
    .front-images {
      display: flex;
      justify-content: space-between;
      /* .selected-image {
        width: 50%;
      } */

      .list-image-templates {
        align-items: flex-start;
        align-content: flex-start;
      }
    }
  }
  .size-postcard {
    border: 1px solid #d4dbe4;
    border-radius: 4px;
    background-color: #e8e8e8;
  }

  .back-poscard {
    display: flex;
    justify-content: space-between;

    margin-top: 25px;

    .content-back {
      width: 48%;

      & > div:first-of-type {
        border-radius: 6px;
      }
    }
  }

  .contacts--filter--select-container {
    border: 1px solid #e3ebf6;
    border-radius: 6px;
    background-color: #ffffff;
    .text-chevron-dropdown--current-selected-text,
    ul li {
      color: #2c3742;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 6.25in;
  height: 4.25in;
  border: 1px solid #dadada;
  border-radius: 6px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 4.25in;
    cursor: default;
  }
`;

export const PostCardWrapper = styled.div`
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  position: relative;
  width: ${(props: any) => get(props, 'bodyWidth', '6.25in')};
  height: ${(props: any) => get(props, 'bodyHeight', '4.25in')};
  margin: 0;
  padding: 0;
  /* If using an image, the background image should have dimensions of 2775x1875 pixels. */
  background: ${(props: any) =>
    props.isHasBackGround
      ? `url('https://portal.dev.fetchprm.dev/ext-resources/07283576-2d45-43aa-bcc5-aceaa89f68cc/public/44a8d22032f54012b70b4f8e6ce2263e.jpeg') no-repeat center center`
      : 'unset'};
  background-size: ${(props: any) =>
    props.isHasBackGround ? '9.25in 6.25in' : 'unset'};
  background-size: ${(props: any) =>
    props.isHasBackGround ? 'cover' : 'unset'};
`;
