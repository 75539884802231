import React, { useEffect } from 'react';
import { Prompt } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import FilterGlobal from 'components/Global/Filter/Filter';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
  TemplateResponse,
} from 'global/requests/ResponseTypes';
import { dataTemplate } from 'global/requests/dataUserSettingResponseError';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  SingleUserSendEmailPayload,
  MessagePayloadKindsEnum,
  SelectedUserSendEmailPayload,
  SendEmailSupportSubmissionPayload,
} from 'global/requests/RequestTypes';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { upperFirst, isEqual, get } from 'lodash';
import { NotifyBody } from 'global/requests/RequestTypes';
import { emailPattern } from 'utils/regex';
import EditorBox from './EditorBox';
import toast from 'components/Global/Toast';
import {
  convertDateToTimestamp,
  getTimeNowForPractice,
} from 'utils/convertData';
import { initialTokenValue } from './EditEmailTemplate';
import {
  getActionType,
  optionsDeliveryDate,
  optionsDeliveryDateNow,
} from 'components/Contacts/ViewContact/SinglePostalEditor';
import WarningPopup from 'components/Contacts/WarningPopup';

interface EmailEditorProps {
  screen?: string;
  hideTemplateName?: boolean;
  hideDeliveryDate?: boolean;
  hideRecipient?: boolean;
  receiverUserIDs: string[];
  checkNewEmail(a: boolean): void;
  searchValue?: string;
  filterValue?: string;
  isSentToAllContact?: boolean;
  closeModal?: () => void;
  useCustomSubmit?: {
    innerSubmitRefObject: React.RefObject<HTMLButtonElement>;
    setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  };
  emailRecipient?: string;
  setIsDirtyForm?: React.Dispatch<React.SetStateAction<boolean>>;
  // sendNotifyAppointmentBooking?: (payload: NotifyBody) => void,
  sendNotifyAppointmentBooking?: (
    actionKind: number,
    payload: NotifyBody
  ) => void;
  setTypeSelect?: React.Dispatch<
    React.SetStateAction<'accept' | 'deny' | 'email' | 'view' | null>
  >;
  sendEmailSupport?: (payload: SendEmailSupportSubmissionPayload) => void;
}

const messageBodyCharacterLimit = 999999;

const EmailEditor = (props: EmailEditorProps): JSX.Element => {
  const initPayload: SingleUserSendEmailPayload = {
    content: '',
    kind: MessagePayloadKindsEnum.email,
    subject: '',
    templateId: '',
    scheduleDate: '',
    recipients: props?.emailRecipient ?? '',
  };

  const {
    receiverUserIDs,
    isSentToAllContact,
    searchValue,
    filterValue,
    hideTemplateName,
    hideDeliveryDate,
    hideRecipient,
    screen,
  } = props;

  const [payloadToSendEmail, setPayloadToSendEmail] =
    React.useState<SingleUserSendEmailPayload>(initPayload);
  const [optionsTemplate, setOptionsTemplate] = React.useState<
    OptionFilterDropdownObject[]
  >([]);
  const [emailTemplate, setEmailTemplate] =
    React.useState<TemplateResponse>(dataTemplate);
  const [errorEmailSubject, setErrorEmailSubject] =
    React.useState<boolean>(false);
  const [errorRecipients, setErrorRecipients] = React.useState<string>('');
  const [errorCharacterLimitReached, setErrorCharacterLimitReached] =
    React.useState<boolean>(false);
  const [errorEmptyEmailBody, setErrorEmptyEmailBody] =
    React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [dateLater, setDateLater] = React.useState<Date | null>(null);
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const [deliveryDate, setDeliveryDate] = React.useState<string>('0');
  const [editorHasUnsavedChanges, setEditorHasUnsavedChanges] =
    React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    label: 'Blank Email Template',
    value: '',
  });
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const timeLocalRef = React.useRef(new Date());

  useEffect(() => {
    if (props.sendNotifyAppointmentBooking || props.sendEmailSupport) {
      const isBlankError =
        !errorEmailSubject &&
        !errorEmptyEmailBody &&
        !errorRecipients &&
        !errorCharacterLimitReached;
      const isFullFillData =
        payloadToSendEmail.content &&
        payloadToSendEmail.recipients &&
        payloadToSendEmail.subject;
      props.useCustomSubmit?.setIsSubmitEnabled(isBlankError && (isFullFillData !== undefined));
    }
  }, [
    props.useCustomSubmit,
    props.sendEmailSupport,
    props.sendNotifyAppointmentBooking,
    errorEmailSubject,
    errorEmptyEmailBody,
    errorRecipients,
    errorCharacterLimitReached,
    payloadToSendEmail,
  ]);

  const getDateTimeNow = async () => {
    try {
      const res = await configuredRequests.GET.getTimeNow();

      if (res) {
        setMinDate(new Date(res.toString()));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getEmailTemplates = async () => {
    try {
      const templateResponse = await configuredRequests.GET.templateByKind([
        TemplateKindsEnum.email,
      ]);
      const blankTemplate = {
        categoryKind: 'email',
        name: 'Blank Email Template',
        targetUserTypeIds: [
          '6008b81b-b018-4981-a132-2426b6bf5481',
          'da5094da-3141-4db4-b501-10d8914436fd',
        ],
        templateBody: '',
        templateId: '',
        templateKind: '',
        templateSubject: '',
      };
      setEmailTemplate({
        ...templateResponse,
        items: [blankTemplate, ...templateResponse.items],
      });
      // add default value to initial array instead of unshifting, more optimal and uses const :)
      const dataSelected: Array<OptionFilterDropdownObject> = [
        { label: 'Blank Email Template', value: '' },
      ];
      templateResponse.items.forEach((item) => {
        dataSelected.push({
          label: item.name ?? '',
          value: item.templateId ?? '',
        });
      });
      setOptionsTemplate(dataSelected);
    } catch (err) {
      console.log('error getting email template', err);
    }
  };

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // This function must do checks before sending the request to ensure payload is valid
  const sendEmailData = async () => {
    try {
      if (!payloadToSendEmail || isDisabled) {
        return;
      }
      if (!payloadToSendEmail.subject.length) {
        setErrorEmailSubject(true);
      }
      if (!payloadToSendEmail?.recipients?.length) {
        setErrorRecipients('This field is required.');
      }
      if (!payloadToSendEmail.content.length) {
        setErrorEmptyEmailBody(true);
      }
      if (payloadToSendEmail.content.length > messageBodyCharacterLimit) {
        setErrorCharacterLimitReached(true);
      }
      // React will use the initial values for the entire render cycle,
      // So even if the setters above were called the values will not reflect the updated values
      if (
        !payloadToSendEmail.subject.length ||
        !payloadToSendEmail.content.length ||
        payloadToSendEmail.content.length > messageBodyCharacterLimit
      ) {
        alert('Invalid Email content');
        return;
      }
      const filterValueConverted =
        filterValue === 'all' ? '' : upperFirst(filterValue);
      if (isSentToAllContact) {
        toast.success('Sending to contacts', true);
        setIsDisabled(true);
        props.useCustomSubmit?.setIsSubmitEnabled(false);
        props.closeModal && props.closeModal();
        configuredRequests.POST.sendEmailToAllCustomers(
          { ...payloadToSendEmail, actionType: getActionType(deliveryDate) },
          //@ts-ignore
          searchValue,
          filterValueConverted
        );
      } else if (props.sendNotifyAppointmentBooking) {
        const b = '<body>';
        const be = '</body>';
        // await props.sendNotifyAppointmentBooking({
        //   kind: "email",
        //   subject: payloadToSendEmail.subject,
        //   recipient: payloadToSendEmail?.recipients ?? '',
        //   sendLaterDate: get(payloadToSendEmail, 'sendLaterDate', ''),
        //   content: payloadToSendEmail.content.substring((payloadToSendEmail.content.indexOf(b) + b.length), (payloadToSendEmail.content.indexOf(be) - 1))
        // });
        props.closeModal && props.closeModal();
        await props.sendNotifyAppointmentBooking(3, {
          kind: 'email',
          subject: payloadToSendEmail.subject,
          recipient: payloadToSendEmail?.recipients ?? '',
          sendLaterDate: get(payloadToSendEmail, 'scheduleDate', ''),
          content: payloadToSendEmail.content.substring(
            payloadToSendEmail.content.indexOf(b) + b.length,
            payloadToSendEmail.content.indexOf(be) - 1
          ),
          actionType: getActionType(deliveryDate),
        });
        props.setTypeSelect && props.setTypeSelect(null);
        props.setIsDirtyForm && props.setIsDirtyForm(false);
      } else if (props.sendEmailSupport) {
        const b = '<body>';
        const be = '</body>';
        props.closeModal && props.closeModal();
        await props.sendEmailSupport({
          kind: 'email',
          recipient: payloadToSendEmail?.recipients ?? '',
          subject: payloadToSendEmail.subject,
          content: payloadToSendEmail.content.substring(
            payloadToSendEmail.content.indexOf(b) + b.length,
            payloadToSendEmail.content.indexOf(be) - 1
          ),
          actionType: getActionType(deliveryDate),
          sendLaterDate: '',
        });
        props.setIsDirtyForm && props.setIsDirtyForm(false);
      } else {
        //@ts-ignore
        const payloadSendEmailToSelectedUser: SelectedUserSendEmailPayload = {
          messageContents: {
            content: payloadToSendEmail.content,
            kind: MessagePayloadKindsEnum.email,
            sendLaterDate: payloadToSendEmail.scheduleDate,
            subject: payloadToSendEmail.subject,
            recipients: payloadToSendEmail.recipients,
            templateId: payloadToSendEmail.templateId ?? '',
            actionType: getActionType(deliveryDate),
          },
          contactIds: receiverUserIDs,
        };
        toast.success(
          `Sending to contact${receiverUserIDs.length === 1 ? '' : 's'}`,
          true
        );
        props.closeModal && props.closeModal();
        const responses = await configuredRequests.POST.sendSelectedUserEmail(
          payloadSendEmailToSelectedUser
        );
        if (responses) {
          props.checkNewEmail(true);
          setIsDisabled(true);
          setPayloadToSendEmail(initPayload);
          props.useCustomSubmit?.setIsSubmitEnabled(false);
          setSelectedTemplate({ label: 'Blank Email Template', value: '' });
          setDeliveryDate('0');
        }
      }
    } catch (err) {}
  };

  const handleSelectDeliveryDate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    if (newSelectedItem.value === '0' || newSelectedItem.value === '1') {
      setPayloadToSendEmail((prev) => ({
        ...prev,
        scheduleDate: '',
      }));
      if (newSelectedItem.value === '1') {
        setOpenWarningPopup(true);
      }
    } else if (deliveryDate === '0' || deliveryDate === '1') {
      if (minDate) {
        const newMinDate = getTimeNowForPractice(timeLocalRef.current, minDate);
        setMinDate(newMinDate);
        setDateLater(newMinDate);
        setPayloadToSendEmail((prev) => ({
          ...prev,
          scheduleDate: convertDateToTimestamp(newMinDate),
        }));
      } else {
        setMinDate(new Date());
        setDateLater(new Date());
        setPayloadToSendEmail((prev) => ({
          ...prev,
          scheduleDate: convertDateToTimestamp(new Date()),
        }));
        timeLocalRef.current = new Date();
      }
    }
    setDeliveryDate(newSelectedItem.value);
  };

  // Need to destructure properties so it can compare the primitive values
  // If you give useEffect an object dependency then the callback will run on every render
  const { content, subject } = payloadToSendEmail;

  React.useEffect(() => {
    if (
      errorRecipients.length ||
      !subject.length ||
      !content.length ||
      content.length > messageBodyCharacterLimit
    ) {
      setIsDisabled(true);
      return;
    }
    if (content.length && subject.length && !errorRecipients.length) {
      setIsDisabled(false);
    }
  }, [subject, content, errorRecipients]);

  useEffect(() => {
    props.setIsDirtyForm &&
      props.setIsDirtyForm(!isEqual(payloadToSendEmail, initPayload));
  }, [payloadToSendEmail, initPayload, props]);

  const handleSelectTemplate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    setSelectedTemplate(newSelectedItem);
    setErrorEmailSubject(false);
    setErrorEmptyEmailBody(false);

    setPayloadToSendEmail((prev) => ({
      ...prev,
      templateId: newSelectedItem.value,
    }));
    if (!newSelectedItem.value) {
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    emailTemplate.items.forEach((item) => {
      if (item.templateId === newSelectedItem.value) {
        setIsDisabled(false);
        setPayloadToSendEmail((prev) => ({
          ...prev,
          subject: item.templateSubject ? item.templateSubject : '',
          content: item.templateBody ? item.templateBody : '',
        }));
      }
    });
  };

  const handleChangeRecipients = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;
    if (targetValue.length) {
      setEditorHasUnsavedChanges(true);
      const pattern = emailPattern;
      if (
        !pattern.test(event.target.value.trim()) &&
        event.target.value.trim()
      ) {
        setErrorRecipients('Incorrect email format.');
      } else {
        setErrorRecipients('');
      }
      if (
        payloadToSendEmail.content.length &&
        pattern.test(event.target.value.trim())
      ) {
        props.useCustomSubmit?.setIsSubmitEnabled(true);
      } else {
        props.useCustomSubmit?.setIsSubmitEnabled(false);
      }
    } else {
      setEditorHasUnsavedChanges(false);
      setErrorRecipients('This field is required.');
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    setPayloadToSendEmail((prev) => ({ ...prev, recipients: targetValue }));
  };

  const handleChangeDataEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;
    if (targetValue.length) {
      setEditorHasUnsavedChanges(true);
      setErrorEmailSubject(false);
      if (
        payloadToSendEmail.content.length &&
        !errorEmptyEmailBody &&
        !errorCharacterLimitReached
      ) {
        props.useCustomSubmit?.setIsSubmitEnabled(true);
      }
    } else {
      setEditorHasUnsavedChanges(false);
      setErrorEmailSubject(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    setPayloadToSendEmail((prev) => ({ ...prev, subject: targetValue }));
  };

  const handleTrimData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (name === 'emailSubject' && event.target.value.trim().length) {
      setErrorEmailSubject(false);
    } else {
      setErrorEmailSubject(true);
    }
    const dataTrim = event.target.value.trim();
    setPayloadToSendEmail((prev) => ({ ...prev, subject: dataTrim }));
  };

  const handleTrimDataRecipients = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    if (name === 'recipients' && event.target.value.trim().length) {
      setErrorRecipients('');
      const pattern = emailPattern;
      if (
        !pattern.test(event.target.value.trim()) &&
        event.target.value.trim()
      ) {
        setErrorRecipients('Incorrect email format.');
      }
    } else {
      setErrorRecipients('This field is required.');
    }
    const dataTrim = event.target.value.trim();
    setPayloadToSendEmail((prev) => ({ ...prev, recipients: dataTrim }));
  };

  const convertTimeToSecond = (timestamp: number) => {
    return (timestamp / 1000).toString();
  };

  const handleSelectedDate = (date: Date | null) => {
    if (date) {
      setDateLater(date);
      setPayloadToSendEmail((prev) => ({
        ...prev,
        scheduleDate: convertDateToTimestamp(date),
      }));
    }
  };

  const warnUserUnsavedChanges = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return true;
  };

  // add beforeunload listener when editorHasUnsavedChanges === true
  React.useEffect(() => {
    if (editorHasUnsavedChanges) {
      window.addEventListener('beforeunload', warnUserUnsavedChanges);
      return () => {
        window.removeEventListener('beforeunload', warnUserUnsavedChanges);
      };
    }
    window.removeEventListener('beforeunload', warnUserUnsavedChanges);
  }, [editorHasUnsavedChanges]);

  React.useEffect(() => {
    Promise.all([getEmailTemplates(), getTokens(), getDateTimeNow()]);
  }, []);

  // This callback is only called after TinyMCE updates the DOM element's content.
  // There is no way to stop the content update inside this callback.
  // Instead we will have to display the error text "Character limit reached"
  // We also need to check the length in the submit function
  const handleOnChangeEditor = (newContent: string) => {
    if (newContent.length > messageBodyCharacterLimit) {
      setErrorCharacterLimitReached(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    } else {
      setErrorCharacterLimitReached(false);
    }

    const b = '<body>';
    const be = '</body>';
    const messageBody = newContent.substring(
      newContent.indexOf(b) + b.length,
      newContent.indexOf(be) - 1
    );

    if (!messageBody.trim()) {
      setErrorEmptyEmailBody(true);
      // setIsDisabled(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    } else {
      setErrorEmptyEmailBody(false);
      if (payloadToSendEmail.subject.length && !errorCharacterLimitReached) {
        props.useCustomSubmit?.setIsSubmitEnabled(true);
      }
    }

    setPayloadToSendEmail((prev) => ({ ...prev, content: newContent }));
  };

  const handleEmptyContent = () => {
    setErrorEmptyEmailBody(true);
    // setIsDisabled(true);
    props.useCustomSubmit?.setIsSubmitEnabled(false);
  };

  return (
    <div className='contact-email-editor'>
      <Prompt
        when={editorHasUnsavedChanges}
        message={'WARNING! You may have unsaved changes'}
      />

      {!hideTemplateName && (
        <>
          <p>Template</p>
          <FilterGlobal
            handleSetNewFilterValue={handleSelectTemplate}
            optionsForFilter={optionsTemplate}
            valueSelected={selectedTemplate}
          />
        </>
      )}
      {!hideRecipient && (
        <>
          <p>
            Recipient <b className='require'>*</b>
          </p>
          <input
            className='input-text'
            name='recipients'
            value={payloadToSendEmail.recipients}
            onChange={handleChangeRecipients}
            onBlur={handleTrimDataRecipients}
            maxLength={255}
          />
          {errorRecipients && <p className='error-msg'>{errorRecipients}</p>}
        </>
      )}

      <p>
        Email Subject <b className='require'>*</b>
      </p>
      <input
        className='input-text'
        name='emailSubject'
        value={payloadToSendEmail.subject}
        onChange={handleChangeDataEmailSubject}
        onBlur={handleTrimData}
        maxLength={255}
      />
      {errorEmailSubject && (
        <p className='error-msg'> This field is required. </p>
      )}

      <p>
        Message Body <b className='require'>*</b>
      </p>
      <EditorBox
        // idTextArea="editor-content-email-communication"
        value={content}
        handleChangeEditor={handleOnChangeEditor}
        handleEmptyContent={handleEmptyContent}
        token={tokenData}
        screen={screen ?? 'manual'}
      />
      {errorEmptyEmailBody && (
        <p className='error-msg'> This field is required. </p>
      )}
      {errorCharacterLimitReached && (
        <p className='error-msg'>
          {' '}
          The character limit has been reached ({
            messageBodyCharacterLimit
          }){' '}
        </p>
      )}

      {!hideDeliveryDate && (
        <div className='delivery-send-mail'>
          <p>Delivery Date</p>
          <div className='d-flex delivery-date-dropdown'>
            <div className='delivery-date'>
              <FilterGlobal
                handleSetNewFilterValue={handleSelectDeliveryDate}
                optionsForFilter={
                  screen === 'booking'
                    ? [
                        {
                          label: 'Send Now',
                          value: '0',
                        },
                      ]
                    : receiverUserIDs.length > 1 || isSentToAllContact
                    ? optionsDeliveryDateNow
                    : optionsDeliveryDate
                }
              />
            </div>

            {deliveryDate === '2' ? (
              <div className='datepicker-email-editor'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container className='grid-custom'>
                    <FontAwesomeIcon
                      icon={['fas', 'calendar-alt']}
                      className='icon-calendar__communication-end-date'
                    />
                    <DateTimePicker
                      value={dateLater}
                      minDate={minDate}
                      onChange={handleSelectedDate}
                      className='date-picker-custom'
                      format='MMMM do hh:mm a'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      <Button
        ref={props.useCustomSubmit?.innerSubmitRefObject}
        variant='contained'
        color='primary'
        className='btn-send-email'
        onClick={sendEmailData}
        disabled={isDisabled}
        style={{
          visibility:
            props.useCustomSubmit !== undefined ? 'hidden' : 'visible',
        }}
      >
        <FontAwesomeIcon icon={['fas', 'paper-plane']} />
        Send Email
      </Button>

      <WarningPopup open={openWarningPopup} onClose={setOpenWarningPopup} />
    </div>
  );
};

export default EmailEditor;
