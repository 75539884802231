import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { InputWithTopLabel, SelectWithTopLabel } from 'components/Legwork';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { get } from 'lodash';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

declare type IdObject = {
  [key: string]: any;
} & {
  id: string | number;
};
interface IMenuItem {
  value: string | IdObject;
  display: string;
  disabled: boolean | undefined;
}

export interface MoveFormModalProps {
  open: boolean;
  form: any;
  handleClose: () => void;
  updateForms: () => void;
  folders: any[];
  folder: any;
}

const MoveFormModal = (props: MoveFormModalProps): React.ReactElement => {
  const { open, form, handleClose, folder, folders, updateForms } = props;
  const [currentFolder, setCurrentFolder] = useState<string>(folder.id);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const moveForm = async (formId: string, folderId: string): Promise<void> => {
    try {
      const response = await configuredRequests.PATCH.moveForm(
        formId,
        folderId
      );
      if (response.status === 200) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
        updateForms();
        handleClose();
      }
    } catch (error) {
      if (get(error, 'response.data.message', '')) {
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>{get(error, 'response.data.message', '')}</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
        handleClose();
      }
    }
  };

  const handleSuccess = async (): Promise<void> => {
    if (form.id || currentFolder) {
      setDisabledSubmit(true);
      await moveForm(form.id, currentFolder);
      setDisabledSubmit(false);
    }
  };

  const listFolderToMoveForm = orderBy(
    folders,
    [(data) => data?.folder_name?.toLowerCase()],
    ['asc']
  ).filter(
    (items) => items.folder_name !== 'Patient Forms' && items.id !== folder.id
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id='move-form-modal'
      className='move-form-modal-form-builder'
    >
      <DialogContentText id='move-modal'>
        <span className='title-move-modal'>Move Form</span>
        <IconButton
          aria-label='close'
          className='close-modal'
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogContentText>
      <DialogContent>
        <InputWithTopLabel
          title='Current folder'
          value={folder?.folder_name.trim() ?? ''}
          reporter={() => {}}
          fullWidth
          error={false}
          type='text'
          disabled
        />
        <br />
        <SelectWithTopLabel
          // @ts-ignore
          reporter={(a: string): void => {
            setCurrentFolder(a);
          }}
          placeholder='Select a Folder'
          menuItems={listFolderToMoveForm.map(
            (f: IdObject): IMenuItem => ({
              value: String(f.id),
              display: f.folder_name,
              disabled: false,
            })
          )}
          title='Move to Folder'
        />
        <br />
      </DialogContent>
      <DialogActions className='footer-move-modal'>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={currentFolder === 'xxxx' || disabledSubmit}
          onClick={handleSuccess}
          color='primary'
          autoFocus
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveFormModal;
