import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptionFilterDropdownObject } from 'global/requests/ResponseTypes';

interface FilterProps {
  handleSetNewFilterValue: (
    newSelectedItem: OptionFilterDropdownObject
  ) => void;
  optionsForFilter: OptionFilterDropdownObject[];
  disabled?: boolean;
  classCustom?: string;
  valueSelected?: OptionFilterDropdownObject;
  setOpenDropdown?: (arg: boolean) => void;
  placeholder?: string;
  icon?: string;
}

const styleIcon = {
  padding: '2px',
  fontSize: '13px',
};

const Filter = (props: FilterProps): JSX.Element => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [valueFilterSelected, setValueFilter] =
    React.useState<OptionFilterDropdownObject>(props.optionsForFilter[0]);

  React.useEffect(() => {
    if (props.valueSelected) {
      setValueFilter(props.valueSelected);
    }
  }, [props.valueSelected]);

  React.useEffect(() => {
    if (!!props.setOpenDropdown) {
      props.setOpenDropdown(isDropdownOpened);
    }
  }, [isDropdownOpened]);

  const handleSetNewFilterValue = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    setValueFilter(newSelectedItem);
    props.handleSetNewFilterValue(newSelectedItem);
    setIsDropdownOpened(false);
  };

  const handleClick = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

const handleBlur = (event: React.FocusEvent) => {
    if (!event.currentTarget.contains(document.activeElement)) {
      setIsDropdownOpened(false);
    }
};

  // useBlurCustomDropdown(
  //   ["filter-form", "filter-global--dropdown-button"],
  //   () => {
  //     setIsDropdownOpened(false);
  //   }
  // );

  return (
    <div className='search-filter__global'>
      <div
        className={'filter-form ' + (props.classCustom ?? '')}
        tabIndex={0}
        onClick={handleClick}
        onBlur={handleBlur}
        onKeyDown={(event: React.KeyboardEvent) => {
          switch (event.key) {
            case ' ':
            case 'Enter': {
              handleClick();
              break;
            }
          }
        }}
      >
        {props.disabled ? (
          'Disable'
        ) : (
          <span
            className={`${
              props.placeholder && !valueFilterSelected?.value && 'placeholder'
            }`}
          >
            {props.icon && <FontAwesomeIcon
              // @ts-ignore
              icon={props.icon}
              style={styleIcon}
            />}
            {' '}
            {props.placeholder && !valueFilterSelected?.value
              ? props.placeholder
              : valueFilterSelected?.label}{' '}
          </span>
        )}
        <nav className={'filter-nav' + (isDropdownOpened ? ' open' : '')}>
          <ul
            className={'list-select-filter' + (isDropdownOpened ? ' open' : '')}
          >
            {props.optionsForFilter.map((item, index) => {
              return (
                <li
                  key={'item-filter-' + item.label + '-' + index}
                  className='select-item-filter'
                  onMouseDown={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSetNewFilterValue(item);
                  }}
                >
                  {/* <button
                    className="filter-global--dropdown-button"

                  >
                  </button> */}
                  {item.label}
                </li>
              );
            })}
          </ul>
        </nav>
        <FontAwesomeIcon
          className='icon-filter-down'
          icon='chevron-down'
          style={styleIcon}
        />
      </div>
    </div>
  );
};

export default Filter;
