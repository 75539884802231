import React from 'react';
import SCREEN_RECORD from '../../../images/screen_recording.svg';

import './VideoRecordingComponent.scss';

interface IProps {
    showGroupMenu(a: boolean): void
    createsMeetingSession: (a: boolean, b: boolean) => void
    isPatientJoined?: boolean
}

const VideoRecordingComponent: React.FC<IProps> = (props) => {
    const [isStartRecording, setIsStartRecording] = React.useState<boolean>(false);

    /**
     * Event click close recording
     */
    const closeRecording = () => {
        props.createsMeetingSession(false, false);
        setIsStartRecording(false);
        props.showGroupMenu(false);
    }

    /**
     * Event click menu take note
     */
    const startRecording = () => {
        if (!isStartRecording && props.isPatientJoined) {
            props.createsMeetingSession(true, false);
            setIsStartRecording(true);
            props.showGroupMenu(false);
        } else {
            return
        }
    }

    const recordingScreen = () => {
        return (
            <div className="recording-border"></div>
        )
    }

    const onMouseOver = () => {
        document.getElementsByClassName("record-menu")[0].classList.add("menu-hover");
    }
    const onMouseLeave = () => {
        document.getElementsByClassName("record-menu")[0].classList.remove("menu-hover");
    }

    return (
        <div className="video-recording" >
            <div className="record-menu" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                {!isStartRecording
                    ? <div className="icon-group" onClick={startRecording}>
                        <img alt="icon menu" src={SCREEN_RECORD} />
                        <span className="menu-title"> Start Screen Recording </span>
                    </div>

                    : <div className="icon-group" onClick={closeRecording}>
                        <img alt="icon menu" src={SCREEN_RECORD} />
                        <span className="menu-title"> Stop Recording </span>
                    </div>
                }
            </div>
            {isStartRecording ? recordingScreen() : null}
        </div>
    )
}

export default VideoRecordingComponent;
