import APIService from '../clientApi';
import { AppointmentsWithTasksParam, IAppointmentsWithTasksResponse } from './taskListType';

const API = APIService();

const getAppointmentsWithTasks = async (
  params: AppointmentsWithTasksParam,
): Promise<IAppointmentsWithTasksResponse> =>
  API.rpc('', { method: 'appointment.getAppointmentsWithTasks', params });

export default {
  getAppointmentsWithTasks,
};
