import { Modal } from '@material-ui/core';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { debounce, get, isEmpty, pickBy } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { isWindows } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookingRequestV from './BookingRequestV';
import { NotifyBody } from 'global/requests/RequestTypes';
import { getPatientPortalUrl } from 'global/constants/url';
import toast from 'components/Global/Toast';

interface Iquery {
  limit: number;
  offset: number;
  status?: string;
  contactName?: string;
  isSearch?: boolean;
}

export interface IDetailBooking {
  id: string;
  appointment: string;
  contactName: string;
  emailAddress: string;
  status: 'pending' | 'denied' | 'archived' | 'accepted';
  object: string;
  jsonStructure: string;
  petName: string;
  phoneNumber: string;
  providerName: string;
  submittedDate: string;
}

export interface IListStatus {
  label: string;
  value: string;
}

export interface OptionContact {
  id: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  phoneNumber: string;
  address: string;
  pets: string;
}

export interface OptionPet {
  id: string;
  petName: string;
  sex: string;
  specie: string;
  breed: string;
}

const listMapStatus = {
  1: 'Accepted',
  2: 'Denied',
  3: 'Archived',
};

interface ChangedField {
  fieldName: string;
  formValue: string;
  dbValue: string;
  shouldUpdate: boolean;
}

export interface IPopup {
  show: boolean;
  type: string;
}

const reducerPopup = (state: IPopup, type: string): IPopup => {
  switch (type) {
    case 'miss_field':
    case 'no_exist_contact_and_no_support':
    case 'no_exist_contact_support':
    case 'exist_contact':
    case 'no_exist_pet_and_no_support':
    case 'no_exist_pet':
    case 'exist_multi_pet':
    case 'no_exist_pet_support':
    case 'no_support_create_appointment':
    case 'no_exist_room':
    case 'update_or_continue':
      return { show: true, type };
    default:
      return { show: false, type: '' };
  }
};

const BookingRequestVM = () => {
  const [nameAppointmentBooking, setNameAppointmentBooking] =
    useState<string>('');
  const [statusSelected, setStatusSelected] =
    useState<string>('PendingRequests');
  const [queryPagination, setQueryPagination] = useState({
    limit: 5,
    offset: 0,
  });
  const [listBookingRequest, setListBookingRequest] = useState<
    IDetailBooking[]
  >([]);
  const [micrositeName, setMicrositeName] = useState<string>('');
  const [isMaxAppointmentBooking, setIsMaxAppointmentBooking] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState<boolean>(false);
  const [isOpenUnArchiveModal, setIsOpenUnArchiveModal] =
    useState<boolean>(false);
  const [currentAppointmentSelected, setCurrentAppointmentSelected] =
    useState<IDetailBooking | null>(null);
  const [listStatus, setListStatus] = useState<IListStatus[]>([
    { label: '', value: '' },
  ]);
  const [typeSelect, setTypeSelect] = useState<
    'accept' | 'deny' | 'email' | 'view' | null
  >(null);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useReducer(reducerPopup, {
    show: false,
    type: '',
  });
  const [optionContacts, setOptionContacts] = useState<OptionContact[]>([]);
  const [changedFields, setChangedFields] = useState<ChangedField[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>("");
  const [isCreatePet, setIsCreatePet] = useState(false);
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [optionPets, setOptionPets] = useState<OptionPet[]>([]);
  const [selectedPet, setSelectedPet] = useState<string>('');
  const [approvalProcessing, setApprovalProcessing] = useState(false);

  useEffect(() => {
    setSelectedContact(optionContacts[0]?.id ?? '');
  }, [optionContacts]);

  useEffect(() => {
    setSelectedPet(optionPets[0]?.id ?? '');
  }, [optionPets]);

  useEffect(() => {
    if (selectedPet != "")
      getChangedFields(false);
    setIsCreatePet(false);
  }, [selectedPet]);

  const getChangedFields = async (contactOnly: boolean) => {
    if (selectedContact == "" || currentAppointmentSelected?.id == "") {
      setChangedFields([]);
      return false;
    }
    const res = await configuredRequests.GET.getOABChangedFields(
      currentAppointmentSelected?.id ?? '',
      selectedContact,
      contactOnly ? "nopet" : selectedPet,
    );
    // @ts-ignore
    if (res) {
      // @ts-ignore
      const fields = res.fields.map(field => ({
        fieldName: field.fieldName,
        formValue: field.formValue,
        dbValue: field.dbValue,
        shouldUpdate: false,
      }));
      setChangedFields(fields);
      setSelectedPhoneNumber(fields.filter((f: ChangedField) => f.fieldName === "phonenumber").map((f: ChangedField) => f.dbValue.split(',')[0])[0]);
      return fields.length > 0;
    }
    return false;
  }

  // handle open view submission when click desktop notification
  useEffect(() => {
    const notiOab = window.sessionStorage.getItem('noti-oab');
    if (notiOab) {
      setCurrentAppointmentSelected(JSON.parse(notiOab));
      setTypeSelect('view');
      window.sessionStorage.removeItem('noti-oab');
    }
  }, []);

  const getListBookingRequest = useCallback(
    async (query: Iquery, disabledLoading?: boolean) => {
      !disabledLoading && setLoading(true);
      try {
        const response = await configuredRequests.GET.getListBookingRequest({
          limit: query?.limit ?? 5,
          offset: query?.offset ?? 0,
          ...pickBy({ contactName: query?.contactName, status: query?.status }),
        });
        setQueryPagination((preState) => ({
          offset: query.limit > 5 ? preState?.offset : query.offset,
          limit: query.limit > 5 ? 5 : query.limit,
        }));
        setMicrositeName(get(response, 'data.micrositeName', ''));
        if (query.isSearch) {
          setListBookingRequest(get(response, 'data.items', []));
          setIsMaxAppointmentBooking(false);
        } else if (get(response, 'data.count', 0) === 0) {
          setIsMaxAppointmentBooking(true);
        } else {
          setListBookingRequest((preState: IDetailBooking[]) => [
            ...preState,
            ...get(response, 'data.items', []),
          ]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error: ', error);
      }
    },
    []
  );

  const getListStatus = useCallback(async () => {
    try {
      const response =
        await configuredRequests.GET.getListStatusAppointmentBooking();
      setListStatus(
        // @ts-ignore
        response?.data.map((status) => ({
          label: status.name,
          value: status.value,
        })) ?? []
      );
    } catch (error) {
      console.log('error: ', error);
    }
  }, []);

  const setStatusAppointmentBooking = useCallback(
    async (actionKind: number) => {
      try {
        if (!isEmpty(currentAppointmentSelected)) {
          const id = currentAppointmentSelected?.id ?? '';
          const response =
            await configuredRequests.PATCH.setStatusAppointmentBooking(
              id,
              actionKind
            );
          if (get(response, 'status', 0) === 200) {
            toast.success(`${get(listMapStatus, actionKind)} successfully!`);
            getListBookingRequest({
              isSearch: true,
              limit:
                queryPagination.offset === 0
                  ? 5
                  : queryPagination.limit * (queryPagination.offset + 1),
              offset: 0,
              contactName: nameAppointmentBooking.trim(),
              status: statusSelected,
            });
          }
        }
      } catch (error) {
        toast.error(`${get(
          error,
          'response.message',
          get(error, 'message', `Failed to ${get(listMapStatus, actionKind)}.`)
        )}
      `);
      }
    },
    [
      currentAppointmentSelected,
      nameAppointmentBooking,
      statusSelected,
      queryPagination,
      getListBookingRequest,
    ]
  );

  const genarateBodyMessage = useCallback(
    (micrositeName: string, content: string) => `
  <!DOCTYPE html>
  <html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    </head>
    <body>
      <div>
        <div>${content}</div>
        <a href="https://${getPatientPortalUrl()}/${micrositeName}/online-booking">Click here to book an appointment</a>
      </div>
    </body>
  </html>
  `,
    []
  );

  const sendNotifyAppointmentBooking = useCallback(
    async (actionKind: number, payload: NotifyBody) => {
      setLoading(true);
      try {
        if (!isEmpty(currentAppointmentSelected)) {
          const id = currentAppointmentSelected?.id ?? '';
          const email = payload.recipient;
          if (!email) {
            toast.error(`
              The message will not be sent to contact due to no contact{" "}
              ${
                payload.kind === 'email' ? 'email' : 'phone'
              } provided in the submission.
            `);
          } else if (actionKind === 3 || actionKind === 1) {
            const response = await configuredRequests.POST.sendNotifyContact({
              kind: payload.kind,
              recipient: email,
              subject: payload.subject,
              content:
                payload.kind === 'sms'
                  ? payload.content
                  : genarateBodyMessage(micrositeName, payload.content),
              sendLaterDate: payload.sendLaterDate || '',
              appointmentBookingId: id,
            });
            if (get(response, 'status', 0) === 200) {
              toast.success('Send email successfully!');
            }
          } else {
            let contentMess = payload.content;
            if (payload.kind === 'email') {
              const firstIndex = contentMess.indexOf('<body>');
              const lastIndex = contentMess.lastIndexOf('</body>');
              contentMess = genarateBodyMessage(
                micrositeName,
                contentMess.slice(firstIndex + 7, lastIndex)
              );
            }
            const response = await Promise.all([
              configuredRequests.PATCH.setStatusAppointmentBooking(
                id,
                actionKind
              ),
              configuredRequests.POST.sendNotifyContact({
                kind: payload.kind,
                recipient: email,
                subject: payload.subject,
                content: contentMess,
                appointmentBookingId: id,
              }),
            ]);
            const isSuccess = response.every(
              (result: any) => result.status === 200
            );
            if (isSuccess) {
              toast.success(`${get(listMapStatus, actionKind)} successfully!`);
            }
          }
          await getListBookingRequest({
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          });
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          `${get(
            error,
            'response.message',
            get(error, 'message', 'Failed to send notify.')
          )}`
        );
      }
    },
    [
      micrositeName,
      currentAppointmentSelected,
      queryPagination,
      nameAppointmentBooking,
      statusSelected,
      getListBookingRequest,
      genarateBodyMessage,
    ]
  );

  const scrollToBottom = useCallback(() => {
    if (!isMaxAppointmentBooking && !isLoading) {
      getListBookingRequest({
        limit: queryPagination.limit,
        offset: queryPagination.offset + 1,
        ...pickBy({
          contactName: nameAppointmentBooking.trim(),
          status: statusSelected,
        }),
      });
    }
  }, [
    isMaxAppointmentBooking,
    queryPagination,
    nameAppointmentBooking,
    statusSelected,
    isLoading,
    getListBookingRequest,
  ]);

  const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);

  const searchMessageWithName = useCallback(
    debounce((name: string) => {
      getListBookingRequest({
        limit: 5,
        offset: 0,
        isSearch: true,
        ...pickBy({ contactName: name.trim(), status: statusSelected }),
      });
    }, 1000),
    [getListBookingRequest, statusSelected]
  );

  const changeNameAppointmentBooking = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      const targetValue = event.currentTarget.value;
      setNameAppointmentBooking(targetValue);
      searchMessageWithName(targetValue.trim());
    },
    [searchMessageWithName]
  );

  const handleChangeOptionsRequest = useCallback(
    (status: string) => {
      getListBookingRequest({
        limit:
          queryPagination.offset === 0
            ? 5
            : queryPagination.limit * (queryPagination.offset + 1),
        offset: 0,
        isSearch: true,
        ...pickBy({
          contactName: nameAppointmentBooking.trim(),
          status: status,
        }),
      });
      setStatusSelected(status);
    },
    [nameAppointmentBooking, queryPagination, getListBookingRequest]
  );

  const getTotalUnread = useCallback(async () => {
    try {
      const response = await configuredRequests.GET.getListBookingRequest({
        status: 'PendingRequests',
      });
      // @ts-ignore
      const totalUnreads = response?.data?.totalAvailable || 0;
      const numberUnreadElement = document.getElementsByClassName('oab-status')[0];
      if (totalUnreads) {
        numberUnreadElement.classList.add('status--count');
      } else {
        numberUnreadElement.classList.remove('status--count');
      }
      numberUnreadElement.innerHTML = totalUnreads.toString();
      window.sessionStorage.setItem(
        'countUnreadSubmissions',
        totalUnreads.toString()
      );
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const toogleModal = useCallback((isOpen: boolean) => {
    setIsOpenArchiveModal(isOpen);
  }, []);

  const toogleModalUnArchive = useCallback((isOpen: boolean) => {
    setIsOpenUnArchiveModal(isOpen);
  }, []);

  const toogleConfirmModal = useCallback((isOpen: boolean) => {
    setIsShowConfirmModal(isOpen);
  }, []);

  useEffect(() => {
    Promise.all([
      getListStatus(),
      getListBookingRequest({ limit: 5, offset: 0, status: 'PendingRequests' }),
    ]).then();

    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  const handleContinueApproveWithSelected = async (isContact: boolean) => {
    try {
      if (isContact) {
        const res =
          await configuredRequests.GET.approveAppointmentWithSelectedContact(
            currentAppointmentSelected?.id ?? '',
            selectedContact || ''
          );
        // @ts-ignore
        if (res.result === 'Success') {
          setOpenPopup('');
          setTypeSelect('accept');
          toast.success('Accepted successfully!');
          getListBookingRequest(
            {
              isSearch: true,
              limit:
                queryPagination.offset === 0
                  ? 5
                  : queryPagination.limit * (queryPagination.offset + 1),
              offset: 0,
              contactName: nameAppointmentBooking.trim(),
              status: statusSelected,
            },
            true
          );
          getTotalUnread();
          setSelectedContact(optionContacts[0].id);
        } else if (
          // @ts-ignore
          res.result ===
          'The appointment does not exist in the system yet, please create this appointment in PMS before you approve the appointment.'
        ) {
          setOpenPopup('no_support_create_appointment');
          setSelectedContact(optionContacts[0].id);
          // @ts-ignore
        } else if (res.result === 'AllPets') {
          // @ts-ignore
          const options = res.pets.filter((pet)=>pet.status.toLowerCase()==="active").map((pet) => ({
            id: pet.petId,
            petName: pet.name,
            sex: pet?.sex ?? '',
            specie: pet?.species ?? '',
            breed: pet?.breed ?? '',
          }));
          if (options.length == 0) {
            setOpenPopup('no_exist_pet');
          } else {
            setOptionPets(options);
            setOpenPopup('exist_multi_pet');
          }
        } else {
          setOpenPopup('no_exist_pet_and_no_support');
          setCurrentAppointmentSelected(null);
          setSelectedContact(optionContacts[0].id);
        }
      } else {
        if (changedFields.length != 0) {
          setOpenPopup('update_or_continue');
        }
        else {
          let payload = { "submissionId": currentAppointmentSelected?.id, "userId": selectedContact, "petId": selectedPet, changedInfo: [] };
          setApprovalProcessing(true);
          const res = await configuredRequests.POST.approveOABSubmission(payload);
          setApprovalProcessing(false);
          // @ts-ignore
          if (res.result === 'Success') {
            setOpenPopup('');
            setTypeSelect('accept');
            toast.success('Accepted successfully!');
            getListBookingRequest(
              {
                isSearch: true,
                limit:
                  queryPagination.offset === 0
                    ? 5
                    : queryPagination.limit * (queryPagination.offset + 1),
                offset: 0,
                contactName: nameAppointmentBooking.trim(),
                status: statusSelected,
              },
              true
            );
            getTotalUnread();
            setSelectedContact(optionContacts[0].id);
            setSelectedPet(optionPets[0].id);
          }
        }
      }
    } catch (error) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      setSelectedContact(optionContacts[0].id);
      if (!isContact) {
        setSelectedPet(optionPets[0].id);
        toast.error(`${get(
          error,
          'response.message',
          get(error, 'message', `Failed to Accepted.`)
        )}
      `);
      }
      setApprovalProcessing(false);
    }
  };

  const handleContinueApproveWithNew = async (isContact: boolean) => {
    try {
      if (!isContact && await getChangedFields(true)) {
        setOpenPopup('update_or_continue');
        setIsCreatePet(true);
        setSelectedPet("");
      } else {
        let payload = { "submissionId": currentAppointmentSelected?.id, "userId": isContact ? "" : selectedContact, "petId": "", changedInfo: [] };
        setApprovalProcessing(true);
        const res = await configuredRequests.POST.approveOABSubmission(payload);
        setApprovalProcessing(false);
        // @ts-ignore
        if (res.result === 'Success') {
          setOpenPopup('');
          setTypeSelect('accept');
          toast.success('Accepted successfully!');
          getListBookingRequest(
            {
              isSearch: true,
              limit:
                queryPagination.offset === 0
                  ? 5
                  : queryPagination.limit * (queryPagination.offset + 1),
              offset: 0,
              contactName: nameAppointmentBooking.trim(),
              status: statusSelected,
            },
            true
          );
          getTotalUnread();
          setSelectedContact(optionContacts[0].id);
          !isContact && setSelectedPet(optionPets[0].id);
        }
      }
    } catch (error) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      setSelectedContact(optionContacts[0].id);
      !isContact && setSelectedPet(optionPets[0].id);
      toast.error(`${get(
        error,
        'response.message',
        get(error, 'message', `Failed to Accepted.`)
      )}
      `);
      setApprovalProcessing(false);
    }
  };

  const handleConfirmCreateNewContact = async () => {
    try {
      let payload = { "submissionId": currentAppointmentSelected?.id, "userId": "", "petId": "", changedInfo: [] };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveOABSubmission(payload);
      setApprovalProcessing(false);
      console.log(res);
      // @ts-ignore
      if (res?.result === 'Success') {
        setOpenPopup('');
        setTypeSelect('accept');
        setSelectedPet("");
        setSelectedContact("");
        toast.success('Accepted successfully!');
        getListBookingRequest(
          {
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          },
          true
        );
        getTotalUnread();
      }
    } catch (error) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      toast.error(`${get(
        error,
        'response.message',
        get(error, 'message', `Failed to Accepted.`)
      )}
      `);
      setApprovalProcessing(false);
    }
  };

  const handleConfirmCreateNewPet = async () => {
    try {
      let payload = { "submissionId": currentAppointmentSelected?.id, "userId": selectedContact, "petId": "", changedInfo: [] };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveOABSubmission(payload);
      setApprovalProcessing(false);
      // @ts-ignore
      if (res?.result === 'Success') {
        setOpenPopup('');
        setTypeSelect('accept');
        setSelectedPet("");
        setSelectedContact("");
        toast.success('Accepted successfully!');
        getListBookingRequest(
          {
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          },
          true
        );
        getTotalUnread();
      }
    } catch (error) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      toast.error(`${get(
        error,
        'response.message',
        get(error, 'message', `Failed to Accepted.`)
      )}
      `);
      setApprovalProcessing(false);
    }
  };

  const handleContinueWithoutUpdating = async () => {
    try {
      let payload = { "submissionId": currentAppointmentSelected?.id, "userId": selectedContact, "petId": isCreatePet ? "" : selectedPet, changedInfo: [] };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveOABSubmission(payload);
      setApprovalProcessing(false);
      // @ts-ignore
      if (res?.result === 'Success') {
        setOpenPopup('');
        setTypeSelect('accept');
        setSelectedPet("");
        setSelectedContact("");
        toast.success('Accepted successfully!');
        getListBookingRequest(
          {
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          },
          true
        );
        getTotalUnread();
      }
    } catch (err) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      toast.error(`${get(
        err,
        'response.message',
        get(err, 'message', `Failed to Accepted.`)
      )}
      `);
      setApprovalProcessing(false);
    }
  };

  const handleContinueWithUpdating = async () => {
    try {
      let payload = { "submissionId": currentAppointmentSelected?.id, "userId": selectedContact, "petId": isCreatePet ? "" : selectedPet, changedInfo: changedFields.filter((cf: ChangedField) => cf.shouldUpdate).map((cf: ChangedField) => { return { "fieldName": cf.fieldName, "additionalInfo": cf.fieldName === "phonenumber" ? selectedPhoneNumber : "" } }) };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveOABSubmission(payload);
      setApprovalProcessing(false);
      // @ts-ignore
      if (res?.result === 'Success') {
        setOpenPopup('');
        setTypeSelect('accept');
        setSelectedPet("");
        setSelectedContact("");
        toast.success('Accepted successfully!');
        getListBookingRequest(
          {
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          },
          true
        );
        getTotalUnread();
      }
    } catch (err) {
      setOpenPopup('');
      setCurrentAppointmentSelected(null);
      toast.error(`${get(
        err,
        'response.message',
        get(err, 'message', `Failed to Accepted.`)
      )}
      `);
      setApprovalProcessing(false);
    }
  };

  const handleApproveAppointment = async (selectedBooking: IDetailBooking) => {
    try {
      setCurrentAppointmentSelected(selectedBooking);
      const res = await configuredRequests.PATCH.setStatusAppointmentBooking(
        selectedBooking.id,
        1
      );
      //@ts-ignore
      if (res.data?.result === 'AllContact') {
        // @ts-ignore
        const options = res.data?.users.map((user: any) => {
          const lastName = user.name?.lastName ?? '';
          const firstName = user.name?.givenName ?? '';
          const emailAddress = user.emailAddress ?? '';
          const phoneNumber =
            (
              user?.phoneNumbers?.find((phone: any) => phone.isPrimary) ??
              user?.phoneNumbers?.[0]
            )?.number ?? '';
          const addressPrimary =
            user?.addresses?.find((address: any) => address.isPrimary) ??
            user?.addresses?.[0] ??
            {};
          let address = '';
          if (addressPrimary) {
            const {
              addressLine1,
              city,
              country,
              postalCode,
              state,
              addressLine2,
            } = addressPrimary?.address;
            const addressSegments = [
              addressLine1 ?? '',
              addressLine2 ?? '',
              city ?? '',
              state ?? '',
              postalCode ?? '',
              country ?? '',
            ];
            address = addressSegments.filter((e) => e.length)?.join(', ');
          }
          // @ts-ignore
          const pets = res.data?.pets
            ?.filter((item: any) => item.guardians.includes(user.userId))
            ?.map((pet: any) => pet?.name)
            .join(', ');

          return {
            id: user.userId,
            lastName,
            firstName,
            emailAddress,
            phoneNumber,
            address,
            pets,
          };
        });
        setOptionContacts(options);
        setOpenPopup('exist_contact');
      } else if (
        // @ts-ignore
        res.data?.result === 'You must designate a room for this provider.'
      ) {
        // @ts-ignore
        setOpenPopup('no_exist_room');
        setCurrentAppointmentSelected(null);
      } else if (
        // @ts-ignore
        res.data?.result ===
        'The contact does not exist in the system yet, please create this contact in PMS before you approve the form.'
      ) {
        setOpenPopup('no_exist_contact_and_no_support');
        setCurrentAppointmentSelected(null);
        // @ts-ignore
      } else if (res.data?.result === 'ConfirmApproveContact') {
        setOpenPopup('no_exist_contact_support');
      } else if (
        // @ts-ignore
        res.data?.result ===
        'The appointment does not exist in the system yet, please create this appointment in PMS before you approve the appointment.'
      ) {
        setOpenPopup('no_support_create_appointment');
        setCurrentAppointmentSelected(null);
        // @ts-ignore
      } else if (res.data?.result === 'Success') {
        setTypeSelect('accept');
        toast.success('Accepted successfully!');
        getListBookingRequest(
          {
            isSearch: true,
            limit:
              queryPagination.offset === 0
                ? 5
                : queryPagination.limit * (queryPagination.offset + 1),
            offset: 0,
            contactName: nameAppointmentBooking.trim(),
            status: statusSelected,
          },
          true
        );
        getTotalUnread();
      } else {
        setOpenPopup('miss_field');
        setCurrentAppointmentSelected(null);
      }
    } catch (err) {
      setCurrentAppointmentSelected(null);
      setOpenPopup('');
      if (
        err.response?.data?.message ===
        'The syncer is turned off, you cannot approve this submission.'
      ) {
        toast.error(
          'The syncer is turned off, you cannot approve this submission.'
        );
      } else if (
        err.response?.data?.message ===
        'You must designate a room for this provider.'
      ) {
        toast.error('You must designate a room for this provider.');
      } else {
        toast.error(`${get(
          err,
          'response.message',
          get(err, 'message', `Failed to Accepted.`)
        )}
      `);
      }
    }
  };

  return (
    <>
      <Modal
        open={isLoading}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      </Modal>
      <BookingRequestV
        isOpenArchiveModal={isOpenArchiveModal}
        isOpenUnArchiveModal={isOpenUnArchiveModal}
        listBookingRequest={listBookingRequest}
        nameAppointmentBooking={nameAppointmentBooking}
        scrollRef={scrollRef}
        statusSelected={statusSelected}
        typeSelect={typeSelect}
        listStatus={listStatus}
        currentAppointmentSelected={currentAppointmentSelected}
        isShowConfirmModal={isShowConfirmModal}
        isDirtyForm={isDirtyForm}
        openPopup={openPopup}
        optionContacts={optionContacts}
        optionPets={optionPets}
        selectedContact={selectedContact}
        selectedPet={selectedPet}
        setSelectedContact={setSelectedContact}
        setSelectedPet={setSelectedPet}
        setOpenPopup={setOpenPopup}
        handleConfirmCreateNewContact={handleConfirmCreateNewContact}
        handleConfirmCreateNewPet={handleConfirmCreateNewPet}
        handleContinueApproveWithSelected={handleContinueApproveWithSelected}
        handleContinueApproveWithNew={handleContinueApproveWithNew}
        handleApproveAppointment={handleApproveAppointment}
        changeNameAppointmentBooking={changeNameAppointmentBooking}
        handleChangeOptionsRequest={handleChangeOptionsRequest}
        toogleModal={toogleModal}
        toogleModalUnArchive={toogleModalUnArchive}
        setStatusAppointmentBooking={setStatusAppointmentBooking}
        setTypeSelect={setTypeSelect}
        setCurrentAppointmentSelected={setCurrentAppointmentSelected}
        sendNotifyAppointmentBooking={sendNotifyAppointmentBooking}
        toogleConfirmModal={toogleConfirmModal}
        setIsDirtyForm={setIsDirtyForm}
        selectedPhoneNumber={selectedPhoneNumber}
        setSelectedPhoneNumber={setSelectedPhoneNumber}
        changedFields={changedFields}
        setChangedFields={setChangedFields}
        handleContinueWithUpdating={handleContinueWithUpdating}
        handleContinueWithoutUpdating={handleContinueWithoutUpdating}
        approvalProcessing={approvalProcessing}
      />
    </>
  );
};

export default BookingRequestVM;
