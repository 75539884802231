import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const LargeDarkText = styled(Typography)`
  font-size: ${sizeConstants.large.size};
  line-height: ${sizeConstants.large.height};
  font-weight: 500;
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: ${getThemeProp('pallette.text.primary', '#12253F')};
` as typeof Typography;
export default LargeDarkText;
