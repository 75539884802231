/**
 * This conponents includes 2 main parts:
 * 1. sharedRoutes : 51-503
 * 2. apiRoutes: 506 - 921
 */







import {
  SummaryItemTypes,
  TemplateCategoryKinds,
  TemplateKinds,
  UserKindTypes,
  UserStatusTypes,
} from './api-route-types';
import { SyncerFilterFormType } from './ResponseTypes';
export const environment = process.env.NODE_ENV; // "development" when in dev

// helper function to validate range before attempting to make request
const validateDateFormat = (dateString: string) => {
  const splitDate = dateString.split('-');
  const validNumbers = splitDate
    .map((dateNum) => (Number.isNaN(parseInt(dateNum)) ? false : true))
    .filter((e) => e);
  if (
    dateString.length !== 10 || // "YYYY-MM-DD"
    splitDate.length !== 3 || // 2 dashes, 3 string segments
    validNumbers.length < 2 // all 3 segments are numbers
  ) {
    // console log in dev
    if (environment === 'development') {
      console.log(
        'Your request could not be completed because you have a malformed date range format',
        dateString,
        splitDate,
        dateString.length,
        splitDate.length
      );
    }
    return;
  }
  return dateString;
};

// "current" version of the API, appended to every endpoint that relies on a version
const apiV = '/v1';

const sharedRoutes = {
  // we need to use the API at /tenant instead of /tenants when making requests as a tenant
  // /tenant is intended for use by system-root only
  // these requests weren't being used anywhere but may be useful still

  // singleTenantByID: (tenantID: string) => `/tenants${apiV}/${tenantID}`,
  // allTenantGroupsByTenantID: (tenantID: string) => `/tenants${apiV}/${tenantID}/groups`,
  // allAppointmentsByTenantID: (tenantID: string, options?: { userID?: string, limit?: number, offset?: number, kind?: string[] }) => `/tenants${apiV}/${tenantID}/appointments${options ? `?${options.userID ? "userId=" + options.userID + "&" : ""}${options.limit ? "limit=" + options.limit + "&" : ""}${options.offset ? "&offset=" + options.offset : ""}${options.kind ? `&kind=${options.kind.join(",")}` : ""}` : ""}`,

  singleAppointmentByID: (appointmentID: string) =>
    `/appointments${apiV}/${appointmentID}`,
  singleGroupByID: (groupID: string) => `/groups${apiV}/${groupID}`,
  singleUserByID: (userID: string) => `/users${apiV}/${userID}`,
  singlePetByID: (petID: string) => `/pets${apiV}/${petID}`,
  singleTelemedResourceByID: (resourceID: string) =>
    `/telemed${apiV}/resources/${resourceID}`,
  singleAgreementByID: (agreementID: string) =>
    `/agreements${apiV}/${agreementID}`,
  singleResourceByID: (resourceID: string) => `/resources${apiV}/${resourceID}`,
  singleAppointmentNoteByNoteID: (appointmentID: string, noteID: string) =>
    `/appointments${apiV}/${appointmentID}/notes/${noteID}`,
  singleGroupById: (groupId: string) => `/tenantGroups${apiV}/${groupId}`,

  allUserPetsByUserID: (userID: string) => `/users${apiV}/${userID}/pets`,
  allUsersByCurrentTenant: (options: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
    withName: string;
    isMessageList?: boolean;
  }) =>
    `/tenant${apiV}/users${
      options
        ? `?${`offset=${options.offset ?? '0'}`}${
            options.withStatus ? `&withStatus=${options.withStatus}` : ''
          }${
            options.isMessageList
              ? `&isMessageList=${options.isMessageList}`
              : ''
          }${options.withName ? `&withName=${options.withName}` : ''}${
            options.summaryItems?.length
              ? `&summaryItems=${options.summaryItems.join(',')}`
              : ''
          }${options.limit ? '&limit=' + options.limit : ''}${
            options.userKinds ? `&userKind=${options.userKinds.join(',')}` : ''
          }`
        : ''
    }`,
  allContactsByCurrentTenant: (options: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
    withName: string;
    isMessageList?: boolean;
  }) =>
    `/tenant${apiV}/contacts${
      options
        ? `?${`offset=${options.offset ?? '0'}`}${
            options.withStatus ? `&withStatus=${options.withStatus}` : ''
          }${
            options.isMessageList
              ? `&isMessageList=${options.isMessageList}`
              : ''
          }${options.withName ? `&withName=${options.withName}` : ''}${
            options.summaryItems?.length
              ? `&summaryItems=${options.summaryItems.join(',')}`
              : ''
          }${options.limit ? '&limit=' + options.limit : ''}${
            options.userKinds ? `&userKind=${options.userKinds.join(',')}` : ''
          }`
        : ''
    }`,
  filterContactInfoByCurrentTenant: (options: {
    apptStartDate?: string;
    apptEndDate?: string;
    petBirthdateStart?: string;
    petBirthdateEnd?: string;
    petSpecies?: string[];
    petBreed?: string[];
    petSex?: string[];
    appointmentType?: string[];
    provider?: string[];
  }) =>
    `/tenant${apiV}/filteredContacts?${
      options.apptStartDate ? `apptStartDate=${options.apptStartDate}` : ''
    }${options.apptEndDate ? `&apptEndDate=${options.apptEndDate}` : ''}${
      options.petBirthdateStart
        ? `&petBirthdateStart=${options.petBirthdateStart}`
        : ''
    }${
      options.petBirthdateEnd
        ? `&petBirthdateEnd=${options.petBirthdateEnd}`
        : ''
    }${
      options.petSpecies?.length
        ? `&selectedPetSpecies=${options.petSpecies.join(',')}`
        : ''
    }${
      options.petBreed?.length
        ? `&selectedPetBreed=${options.petBreed.join(',')}`
        : ''
    }${
      options.petSex?.length
        ? `&selectedPetSex=${options.petSex.join(',')}`
        : ''
    }${
      options.appointmentType?.length
        ? `&selectedAppointmentTypes=${options.appointmentType.join(',')}`
        : ''
    }${
      options.provider?.length
        ? `&selectedProviders=${options.provider.join(',')}`
        : ''
    }`,
  appointmentAttachmentsByID: (
    appointmentID: string,
    options?: { offset?: number }
  ) =>
    `/appointments${apiV}/${appointmentID}/attachments?attachmentKind=screenshot`,
  appointmentNotesByID: (
    appointmentID: string,
    options?: { offset?: number }
  ) =>
    `/appointments${apiV}/${appointmentID}/notes${
      options?.offset ? '?offset=' + options.offset : '?offset=0'
    }`,
  userAgreementsByID: (agreementID: string) =>
    `/user${apiV}/agreements/${agreementID}`,
  userSettingsByID: (userID: string) => `/users${apiV}/${userID}/settings`,
  userSettingsByNamespace: (namespace: string) =>
    `/tenant${apiV}/settings/${namespace}`,
  templateRecipientDetails: (templateId: string) =>
    `/reports${apiV}/${templateId}/templateRecipientDetails`,
  updateMarkUnread: (contactID: string, hasRead: boolean) => {
    let isSender =
      contactID.length < '00000000-0000-0000-0000-000000000000'.length;
    if (isSender) {
      return `/users${apiV}/00000000-0000-0000-0000-000000000000/UnreadMesages?isRead=${hasRead}&sender=${encodeURIComponent(
        contactID
      )}`;
    }
    return `/users${apiV}/${contactID}/UnreadMesages?isRead=${hasRead}`;
  },
  lockoutUserByID: (userID: string) => `/users${apiV}/${userID}/lockout`,
  sendEmailSupport: `/appointmentBookingRequests${apiV}/notifyContact`,
  appointmentsOfUser: (
    userID: string,
    queryParams?: {
      dateStart: string;
      dateEnd: string;
      limit: number;
      offset?: number;
    }
  ) =>
    `/users${apiV}/${userID}/appointmentsOfUser` +
    (queryParams
      ? `?dateStart=${queryParams.dateStart}&dateEnd=${
          queryParams.dateEnd
        }&limit=${queryParams.limit}${
          queryParams.offset ? '&offset=' + queryParams.offset : ''
        }`
      : ''),
  appointmentsByUserID: (
    userID: string,
    queryParams?: {
      dateStart: string;
      dateEnd: string;
      limit: number;
      offset?: number;
    }
  ) =>
    `/users${apiV}/${userID}/appointments` +
    (queryParams
      ? `?dateStart=${queryParams.dateStart}&dateEnd=${
          queryParams.dateEnd
        }&limit=${queryParams.limit}${
          queryParams.offset ? '&offset=' + queryParams.offset : ''
        }`
      : ''),
  remindersByUserID: (
    userID: string,
    queryParams?: {
      dateStart?: string;
      dateEnd?: string;
      limit?: number;
      offset?: number;
    }
  ) =>
    `/users${apiV}/${userID}/reminders` +
    (queryParams
      ? `?dateStart=${queryParams.dateStart}&dateEnd=${
          queryParams.dateEnd
        }&limit=${queryParams.limit}${
          queryParams.offset ? '&offset=' + queryParams.offset : ''
        }`
      : ''),
  templateByID: (templateID: string) => `/templates${apiV}/${templateID}`,
  listCategory: (kind: number) =>
    `/automationCategories${apiV}/getAllCategories?kind=${kind}`,
  interactionByID: (messageId: string) =>
    `/users${apiV}/${messageId}/cancelMessage`,
  deleteMessage: (contactID: string) => `/users${apiV}/${contactID}/messages`,
  templateByKind: (options?: {
    templateKind: TemplateKinds[];
    categoryKinds?: TemplateCategoryKinds[] | string[];
  }) =>
    `/templates${apiV}${
      options
        ? `?templateKind=${options.templateKind}&categoryKind=${
            options.categoryKinds
              ? `${options.categoryKinds.join(',')}`
              : 'manual'
          }`
        : ''
    }`,
  getTemplateAnaylze: `/reports${apiV}/suppressions`,
  reportByKind: `/reports${apiV}`,
  messagesByUserID: (
    userID: string,
    optionsForGET?: {
      commKinds?: string[];
      commStatus?: string[];
      dateStart?: string;
      dateEnd?: string;
      offset?: number;
      limit?: number;
    }
  ) => {
    const isSender =
      userID.length < '00000000-0000-0000-0000-000000000000'.length;
    return `/users${apiV}/${
      isSender ? '00000000-0000-0000-0000-000000000000' : userID
    }/messages${
      optionsForGET
        ? `?commKinds=${
            optionsForGET.commKinds?.length
              ? optionsForGET.commKinds.join(',')
              : [
                  'scheduled',
                  'queued',
                  'inFlight',
                  'delivered',
                  'undelivered',
                  'failed',
                ].join(',')
          }&commStatus=${
            optionsForGET.commStatus?.length
              ? optionsForGET.commStatus.join(',')
              : ['sms', 'email'].join(',')
          }${
            optionsForGET.dateStart
              ? `&dateStart=${optionsForGET.dateStart}`
              : ''
          }${optionsForGET.dateEnd ? `&dateEnd=${optionsForGET.dateEnd}` : ''}${
            optionsForGET.limit ? `&limit=${optionsForGET.limit}` : ''
          }&offset=${optionsForGET.offset ?? 0}
          ${isSender ? '&sender=' + encodeURIComponent(userID) : ''}`
        : `${isSender ? '?sender=' + encodeURIComponent(userID) : ''}`
    }`;
  },
  templatePerformanceDetail: (
    templateID: string,
    startTime: string,
    endTime: string
  ) =>
    `/reports${apiV}/${templateID}/templatePerformanceDetails?startTime=${startTime}&endTime=${endTime}`,
  automationTriggerByKind: (options?: { categoryKind?: string }) =>
    `/automationCategories${apiV}${
      options
        ? `?categoryKind=${
            options.categoryKind
              ? `${options.categoryKind}`
              : 'appointment,newContacts,marketing'
          }`
        : ''
    }`,
  automationTriggerList: (
    categoryKind: string,
    automationTriggerKey: string,
    options?: { offset: number; limit: number }
  ) =>
    `/automationTriggerEvents${apiV}?categoryKind=${categoryKind}&automationTriggerKey=${automationTriggerKey}&limit=${
      options?.limit ? options.limit : ''
    }&offset=${options?.offset ? options.offset : ''}`,
  automationTriggerEvents: (automationTriggerId: string) =>
    `/automationTriggerEvents${apiV}/${automationTriggerId}`,
  statusAutomationTriggerEvent: (automationTriggerId: string) =>
    `/automationTriggerEvents${apiV}/${automationTriggerId}/doActivation`,
  updateAutomationTriggerEvent: (automationTriggerEventId: string) =>
    `/automationTriggerEvents${apiV}/${automationTriggerEventId}`,
  getDataOpenByPlatformAndDevice: (
    templateID: string,
    startTime: string,
    endTime: string
  ) =>
    `/reports${apiV}/${templateID}/templateOpenPerformanceDetails?startTime=${startTime}&endTime=${endTime}`,

  getListSubmissionData: (query: {
    limit?: number;
    offset?: number;
    withStatus?: string;
    withName?: string;
    formId?: string;
  }) =>
    `/tenants${apiV}/formBuilder/listSubmissions${
      query
        ? `?${`offset=${query.offset ?? '0'}`}${
            query.limit ? '&limit=' + query.limit : '10'
          }${query.withStatus ? `&withStatus=${query.withStatus}` : ''}${
            query.withName ? `&withName=${query.withName}` : ''
          }${query.formId ? `&formId=${query.formId}` : ''}`
        : ''
    }`,

  currentUser: `/user${apiV}`,
  currentTenantUsers: `/users${apiV}`,
  userPolicies: `/users${apiV}/policies`,
  currentTenantAllGroups: `/tenant${apiV}/groups`,
  currentTenantAppointments: `/tenant${apiV}/appointments`,
  tenants: `/tenants${apiV}`,
  telemedPublicResources: `/telemed${apiV}/public/resources`,
  telemedResources: `/telemed${apiV}/resources`,
  agreements: `/agreements${apiV}`,
  updateSystemSetting: `/tenant${apiV}/settings/SystemSetting`,
  timeZones: `/settings${apiV}/timezones`,
  allusersSetting: `/tenant${apiV}/users`,
  brandColorSettings: `/tenant${apiV}/settings/BrandColorSettings`,
  generalSettings: `/tenant${apiV}/settings/GeneralSettings`,
  notificationSettings: `/tenant${apiV}/settings/NotificationSettings`,
  callInputOption: `/templates${apiV}/callInputOption`,
  emailAnalytics: `/reports${apiV}/emailAnalytics`,
  topPerformingTemplates: `/reports${apiV}/topPerformingTemplates?limit=5`,
  personalizationTokens: `/settings${apiV}/personalizationTokens`,
  createTemplate: `/templates${apiV}`,
  updateAutomationSetting: `/tenant${apiV}/settings/AutomationTrigger`,
  getAutomationSetting: `/tenant${apiV}/settings/AutomationTrigger`,
  deliverApproximately: (automationTriggerKey: string) =>
    `/automationTriggerEvents${apiV}/filterData?automationTriggerKey=${automationTriggerKey}`,
  createAutomationTriggerEvent: `/automationTriggerEvents${apiV}`,
  copyAutomationTriggerEvent: `/copyAutomationTriggerEvent${apiV}`,
  multipleUsersMessages: `/users${apiV}/messagesForContacts`,
  checkIncall: `/user${apiV}/checkIncall`,
  roles: `/roles${apiV}`,
  contactListData: (options: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
    searchText: string;
    isMessageList?: boolean;
  }) =>
    `/tenant${apiV}/contactMessages${
      options
        ? `?${`offset=${options.offset ?? '0'}`}${
            options.withStatus ? `&withStatus=${options.withStatus}` : ''
          }${
            options.isMessageList
              ? `&isMessageList=${options.isMessageList}`
              : ''
          }${options.searchText ? `&searchText=${options.searchText}` : ''}${
            options.summaryItems?.length
              ? `&summaryItems=${options.summaryItems.join(',')}`
              : ''
          }${options.limit ? '&limit=' + options.limit : ''}${
            options.userKinds ? `&userKind=${options.userKinds.join(',')}` : ''
          }`
        : ''
    }`,
  drivers: `/tenants${apiV}/driver`,
  services: `/syncer${apiV}/service`,
  configurators: `/tenants${apiV}/configurator`,
  driverConfigurationSetting: `/tenants${apiV}/driverSetting`,
  driverInfo: (latestDriverId: string) =>
    `/tenants${apiV}/driver/${latestDriverId}`,
  serviceInfo: (latestServiceId: string) =>
    `/syncer${apiV}/service/${latestServiceId}`,
  configuratorInfo: (latestConfiguratorId: string) =>
    `/tenants${apiV}/configurator/${latestConfiguratorId}`,
  screens: `/roles${apiV}/screens`,
  practiceSyncer: `/tenant${apiV}/users`,
  apiCalls: `/tenants${apiV}/syncer/APICallbyDT`,
  tenantInGroup: (tenantGroupId: string) =>
    `/tenantGroups${apiV}/${tenantGroupId}/tenants`,
  getPracticeErrors: `/tenants${apiV}/syncerErrorLogs`,
  adminSyncerErrors: (
    limit: number,
    offset: number,
    syncerErrorPraticeFilter?: string,
    searchName?: string
  ) =>
    `/tenants${apiV}/tenantsWithSyncerError?limit=${limit}&offset=${offset}&syncerErrorPraticeFilter=${
      syncerErrorPraticeFilter ?? 'ErrorPractices'
    }${searchName ? `&searchText=${searchName}` : ''}`,
  serviceLogs: `/tenants${apiV}/syncer/serviceLogs`,
  bookingRequest: `/appointmentBookingRequests${apiV}`,
  emitterSyncer: (tenantId: string) =>
    `/tenants${apiV}/syncer/lastExecute/${tenantId}`,
  emitterMethod: `/tenants${apiV}/syncer/emitterMethod`,
  getEstimatesResponse: (tenantId: string, query: {
    contactId: string;
    petId: string;
  }) => `/tenants${apiV}/syncer/getEstimatesResponse/${tenantId}?contactId=${query.contactId}&petId=${query.petId}`,
  createEmitter: `/tenants${apiV}/syncer/emitter`,
  createTextMessageEmitterExecute: `/tenants${apiV}/syncer/createTextMessageEmitterExecute`,
  addEstimatesRequest: `/tenants${apiV}/syncer/addEstimatesRequest`,
  syncerSetting: `/tenant${apiV}/settings/SyncerSetting`,
  generalSyncer: `/tenants${apiV}/syncer/general`,
  dropdownDataGeneral: `/settings${apiV}/appointmentStatus`,
  updateGeneralSettingSyncer: `/tenant${apiV}/settings/SyncerSetting`,
  resetPassword: `/users${apiV}/resetPassword`,
  createDriver: `/tenants${apiV}/driver`,
  createService: `/syncer${apiV}/service`,
  createConfigurator: `/tenants${apiV}/configurator`,
  softDeleteUser: (userId: string) => `/users${apiV}/${userId}/archive`,
  getAllForm: (tenantId: string) =>
    `/tenants${apiV}/formBuilder/allSubmissionsForm/${tenantId}`,
  getLeftMenuSubmissionData: (query: {
    limit?: number;
    offset?: number;
    tenantId: string;
  }) =>
    `/tenants${apiV}/formBuilder/allSubmissionsForm/${query.tenantId}${
      query
        ? `?${`offset=${query.offset ?? '0'}`}${
            query.limit ? '&limit=' + query.limit : '20'
          }`
        : ''
    }`,
  sendSMSToAllContacts: (withName: string, withStatus: string) =>
    `/users${apiV}/messages${withStatus ? `?withStatus=${withStatus}` : ''}${
      withName ? `&withName=${withName}` : ''
    }`,
  getBillingModes: `${apiV}/payment/accountBillingMode`,
  paySetting: `/tenant${apiV}/settings/PaySettings`,
  twilioSetting: `/tenant${apiV}/settings/TwilioSettings`,
  cloneForm: `/tenants${apiV}/formBuilder/form`,
  getDocumentTypes: `/formBuilder${apiV}/documentTypes`,
  sendPhonePreview: (templateId: string) =>
    `/templates${apiV}/preview/${templateId}`,
  getListRoom: `/tenant${apiV}/rooms`,
  getAppointmentType: `/tenants${apiV}/appointmentSettingType`,
  getMedicalServices: `/tenant${apiV}/medicalservices`,
  deleteAppointmentType: (appointmentTypeId: string) =>
    `/tenants${apiV}/appointmentSettingType/${appointmentTypeId}`,
  addFormAutoFill: `/tenants${apiV}/formBuilder/addFormAutoFill`,
  createAppointmentType: `/tenants${apiV}/appointmentSettingType`,
  markSubmissionAsRead: `/formBuilder${apiV}/markFormSubmissions`,
  updateAppointmentType: (appointmentTypeId: string) =>
    `/tenants${apiV}/appointmentSettingType/${appointmentTypeId}`,
  getOptionDataSubmissionFilter: `/tenants${apiV}/formBuilder/submissionFilterStatus`,
  deleteSubmissionForm: `/formBuilder${apiV}/deleteFormSubmissions`,
  deleteSubmissionDocument: `/formBuilder${apiV}/deleteSubmissionDocument`,
  approveSubmissionForm: `/formBuilder${apiV}/approveFormSubmissions`,
  markReadTenantSyncerError: `/tenants${apiV}/syncerErrorLogs/mark`,
  markReadSyncerErrors: `/tenants${apiV}/syncerErrorLogs/markByTenants`,
};

export const apiRoutes = {
  GET: {
    dealsByGroupId: (tenantGroupId: string) => `/deals${apiV}?groupId=${tenantGroupId}`,
    allPackages: `/deals${apiV}/packages`,
    postalPrice: `/postal${apiV}/getPostalCost`,
    smsPrice: `/tenant${apiV}/settings/SMSLimitSetting`,
    getListSex: `/pets${apiV}/petsex`,
    getListBreeds: `/pets${apiV}/breeds-for-species`,
    getPracticeTimeNow: `/tenants${apiV}/currentTime`,
    getSettingDurationTimes: `/settings${apiV}/settingDefinition`,
    approveAppointmentWithSelectedPet: (
      appointmentId: string,
      contactId: string,
      petId: string
    ) =>
      `/appointmentBookingRequests${apiV}/${appointmentId}/choosePet/${contactId}/${petId}`,
    approveAppointmentWithSelectedContact: (
      appointmentId: string,
      contactId: string
    ) =>
      `/appointmentBookingRequests${apiV}/${appointmentId}/getPets/${contactId}`,
    approveAppointmentWithNewPet: (appointmentId: string, contactId: string) =>
      `/appointmentBookingRequests${apiV}/${appointmentId}/newPet/${contactId}`,
    approveAppointmentWithNewContact: (appointmentId: string) =>
      `/appointmentBookingRequests${apiV}/${appointmentId}/approve`,
    approvalNewChoosePet: (
      submissionId: string,
      contactId: string,
      petId: string
    ) =>
      `/formBuilder${apiV}/approveFormSubmissionsChoosePet/${submissionId}/${contactId}/${petId}`,
    getChangedFields: (
      submissionId: string,
      contactId: string,
      petId: string
    ) =>
      `/formBuilder${apiV}/getChangedFields/${submissionId}/${contactId}/${petId}`,
    getDocumentsForSubmission: (submissionId: string) => `/formBuilder${apiV}/getDocumentsForSubmission/${submissionId}`,
    getOABChangedFields: (
      submissionId: string,
      contactId: string,
      petId: string
    ) =>
      `/appointmentBookingRequests${apiV}/getChangedFields/${submissionId}/${contactId}/${petId}`,
    getListPets: (submissionId: string, contactId: string) =>
      `/formBuilder${apiV}/approveFormSubmissionsGetPets/${submissionId}/${contactId}`,
    approvalNewPet: (submissionId: string, contactId: string) =>
      `/formBuilder${apiV}/approveFormSubmissionWithNewPet/${submissionId}/${contactId}`,
    approvalNewContact: (submissionId: string) =>
      `/formBuilder${apiV}/confirmApproveFormSubmission/${submissionId}`,
    getCountUnreadSubmissions: (tenantId: string) =>
      `/tenants${apiV}/formBuilder/unreadSubmissions/${tenantId}`,
    getListProduct: `/products${apiV}`,
    getListSpecies: `/pets${apiV}/species`,
    invoicingUrl: `/tenants${apiV}/payment/iframeInfo`,
    getDailySelect: `/appointmentSetting${apiV}/repeats`,
    getListDuration: `/tenants${apiV}/appointmentSettingType/getDurationsBySettings`,
    getHoursOfOperation: (tenantId: string) =>
      `/appointmentSetting${apiV}/hoursOfOperation?tenantId=${tenantId}`,
    getListMinimumAvailability: `/tenant${apiV}/getMinimunAvailibilityDropdownData`,
    getMinimumAvailability: `/tenant${apiV}/GetTenantMinimunAvailibility`,
    getTenantOABTimeUnits: `/tenant${apiV}/getTenantOABTimeUnit`,
    getOfficeUnavailabilty: (tenantId: string) =>
      `/appointmentSetting${apiV}/OutOfOfficeEvent?tenantId=${tenantId}`,
    getProviders: `/appointmentSettingProvider${apiV}/getAppointmentSettingProvider`,
    allTenants: sharedRoutes.tenants,
    getAdvandeFields: `/formBuilder${apiV}/advancedFields`,
    getBasicFields: `/formBuilder${apiV}/basicFields`,
    getFormById: (formId: string) =>
      `/tenants${apiV}/formBuilder/form/${formId}`,
    getFormAppointmentBooking: `/tenants${apiV}/formBuilder/formBooking`,
    getShareForms: (practiceName: string) => `/tenants${apiV}/formBuilder/${practiceName}/shareForms`,
    getListFolder: (tenantId: string) =>
      `/tenants/v1/formBuilder/tenantFolder/${tenantId}`,
    getListForm: (tenantId: string) =>
      `/tenants/v1/formBuilder/tenantForm/${tenantId}`,
    currentUserData: sharedRoutes.currentUser,
    currentTenantInfo: `/tenant${apiV}`,
    currentExternalLink: `/tenant${apiV}/settings/client-portal-external-link`,
    videoRecording: (appointmentID: string) =>
      `/appointments${apiV}/${appointmentID}/attachments?attachmentKind=recording`,
    currentTenantAllGroups: sharedRoutes.currentTenantAllGroups,
    currentUserAgreements: `/user${apiV}/agreements`, // no args used for GET
    currentUserTelemedMeetings: (appointmentKey: string) =>
      `/user${apiV}/telemedMeetings/${appointmentKey}`,
    currentTenantUsers: (options?: { limit: string; offset: number }) =>
      `/tenant${apiV}/users` +
      (options ? `?limit=${options.limit}&offset=${options.offset}` : ''),

    currentTenantAppointmentsInDateRange: (
      startDate: string,
      endDate: string,
      options?: {
        userID?: string;
        limit?: number;
        offset?: number;
        kind?: string;
        isWithoutEndDate?: boolean;
        withStatus?: string;
        withName?: string;
      }
    ) =>
      true //[startDate, endDate].map(validateDateFormat).filter((e) => e).length
        ? sharedRoutes.currentTenantAppointments +
          `?dateStart=${startDate}${
            options?.isWithoutEndDate ? '' : `&dateEnd=${endDate}`
          }${options?.userID ? `&userId=${options.userID}` : ''}${
            options?.limit ? `&limit=${options?.limit}` : ''
          }&offset=${options?.offset ?? '0'}&count=10${
            options?.kind ? `&kind=${options?.kind}` : ''
          }${options?.withStatus ? `&withStatus=${options?.withStatus}` : ''}${
            options?.withName ? `&withName=${options?.withName}` : ''
          }`
        : undefined,
    allAppointmentsInRangeByUserID: (
      startDate: string,
      endDate: string,
      userID: string,
      limit: number,
      offset: number
    ) =>
      [startDate, endDate].map(validateDateFormat).filter((e) => e).length
        ? `/users${apiV}/appointments?dateStart=${startDate}&dateEnd=${endDate}&userId=${userID}&limit=${limit}&offset=${offset}`
        : undefined,

    generalSettings: `/tenant${apiV}/settings/GeneralSettings`,
    notificationsSetting: sharedRoutes.notificationSettings,
    systemNotificationSetting: `${apiV}/settings/notificationSettings`,
    callInputOption: sharedRoutes.callInputOption,
    appointmentAttachmentsByID: sharedRoutes.appointmentAttachmentsByID,
    appointmentNotesByID: sharedRoutes.appointmentNotesByID,

    singleAppointmentNoteByNoteID: sharedRoutes.singleAppointmentNoteByNoteID,
    singleUserByID: sharedRoutes.singleUserByID,
    singlePetDataByID: sharedRoutes.singlePetByID,
    singleGroupDataByID: sharedRoutes.singleGroupByID,
    singleAppointmentByID: sharedRoutes.singleAppointmentByID,
    singleTelemedResourceByID: sharedRoutes.singleTelemedResourceByID,
    singleAgreementByID: sharedRoutes.singleAgreementByID,
    singleResourceByID: sharedRoutes.singleResourceByID,

    allPetsByUserID: sharedRoutes.allUserPetsByUserID,
    allUsersByCurrentTenant: sharedRoutes.allUsersByCurrentTenant,
    allContactsByCurrentTenant: sharedRoutes.allContactsByCurrentTenant,
    filterContactInfoByCurrentTenant:
      sharedRoutes.filterContactInfoByCurrentTenant,
    allUserAppointmentsOfUser: sharedRoutes.appointmentsOfUser,
    allUserAppointmentsByUserID: sharedRoutes.appointmentsByUserID,
    allUserRemindersByUserID: sharedRoutes.remindersByUserID,

    userSettingsByID: sharedRoutes.userSettingsByID,
    userSettingsByNamespace: sharedRoutes.userSettingsByNamespace,
    brandColorSettings: sharedRoutes.brandColorSettings,
    userPolicies: sharedRoutes.userPolicies,

    agreementAcceptances: `/agreementAcceptances${apiV}`,
    allAgreements: sharedRoutes.agreements,

    telemedPublicResources: sharedRoutes.telemedPublicResources,
    telemedAllResources: sharedRoutes.telemedResources,

    tenantServicesByID: (tenantID: string) =>
      `/tenants${apiV}/${tenantID}/services`,

    telemedAppointmentAccessDetails: (appointmentID: string) =>
      `/appointments${apiV}/${appointmentID}/telemedDetails`,
    tenantSystemSetting: `/tenant${apiV}/settings/SystemSetting`,
    timeZones: sharedRoutes.timeZones,
    groups: `/tenantGroups${apiV}`,
    groupInfo: (groupId: string) => `/tenantGroups${apiV}/${groupId}`,
    listCategory: (kind: number) => sharedRoutes.listCategory(kind),
    templateByID: sharedRoutes.templateByID,
    getListImageBySide: `/postal${apiV}/templateImage`,
    countAlertErrors: `/tenants${apiV}/systemAlerts`,
    countSyncerStopPages: `/tenants${apiV}/syncerStopAlerts`,
    templateByKind: sharedRoutes.templateByKind,
    getTemplateAnaylze: sharedRoutes.getTemplateAnaylze,
    reportByKind: sharedRoutes.reportByKind,
    messagesByUserID: sharedRoutes.messagesByUserID,
    getMessagesByUserID: (userID: string, allMessages: boolean) => {
      const isSender =
        userID.length < '00000000-0000-0000-0000-000000000000'.length;
      return !allMessages
        ? `/users/v1/${
            isSender ? '00000000-0000-0000-0000-000000000000' : userID
          }/allMessages?${
            isSender ? `sender=${encodeURIComponent(userID)}` : ''
          }`
        : `/users/v1/${
            isSender ? '00000000-0000-0000-0000-000000000000' : userID
          }/allMessages?isAllMessages=true${
            isSender ? `&sender=${encodeURIComponent(userID)}` : ''
          }`;
    },
    emailAnalytics: sharedRoutes.emailAnalytics,
    topPerformingTemplates: sharedRoutes.topPerformingTemplates,
    personalizationTokens: sharedRoutes.personalizationTokens,
    templatePerformanceDetail: sharedRoutes.templatePerformanceDetail,
    getDataOpenByPlatformAndDevice: sharedRoutes.getDataOpenByPlatformAndDevice,
    templateRecipientDetails: sharedRoutes.templateRecipientDetails,
    automationTriggerByKind: sharedRoutes.automationTriggerByKind,
    getAutomationSetting: sharedRoutes.getAutomationSetting,
    deliverApproximately: sharedRoutes.deliverApproximately,
    automationTriggerList: sharedRoutes.automationTriggerList,
    checkIncall: sharedRoutes.checkIncall,
    roles: sharedRoutes.roles,
    contactListData: sharedRoutes.contactListData,
    drivers: sharedRoutes.drivers,
    services: sharedRoutes.services,
    configurators: sharedRoutes.configurators,
    driverConfigurationSetting: sharedRoutes.driverConfigurationSetting,
    driverInfo: sharedRoutes.driverInfo,
    serviceInfo: sharedRoutes.serviceInfo,
    configuratorInfo: sharedRoutes.configuratorInfo,
    screens: sharedRoutes.screens,
    practiceSyncer: sharedRoutes.practiceSyncer,
    singleGroupById: sharedRoutes.singleGroupById,
    apiCalls: sharedRoutes.apiCalls,
    getTenantsByGroupId: (tenantGroupId: string) =>
      sharedRoutes.tenantInGroup(tenantGroupId),
    adminSyncerErrors: sharedRoutes.adminSyncerErrors,
    getPracticeErrors: sharedRoutes.getPracticeErrors,
    serviceLogs: sharedRoutes.serviceLogs,
    bookingRequest: sharedRoutes.bookingRequest,
    getTenantInfo: (tenantGroupId: string, tenantId: string) =>
      `${sharedRoutes.tenantInGroup(tenantGroupId)}/${tenantId}`,
    getSyncerFilterOptions: `/tenants${apiV}/filterRequest`,
    getSyncerStopPages: `/tenants${apiV}/syncerStop`,
    getSyncerPractices: (filterOptions: SyncerFilterFormType) => {
      let queryParams = new URLSearchParams('');
      filterOptions.driver && queryParams.set('driverId', filterOptions.driver);
      filterOptions.driverVersion &&
        queryParams.set('driverVersionId', filterOptions.driverVersion);
      filterOptions.sortBy && queryParams.set('sortBy', filterOptions.sortBy);
      filterOptions.sortOrder &&
        queryParams.set('sortOrder', filterOptions.sortOrder);
      filterOptions.group &&
        queryParams.set('tenantGroupId', filterOptions.group);
      filterOptions.livePractice &&
        queryParams.set('livePractice', filterOptions.livePractice);
      filterOptions.deliveryPaused &&
        queryParams.set('deliveryPaused', filterOptions.deliveryPaused);
      return `/tenants${apiV}/filter${
        queryParams.toString() ? '?' + queryParams.toString() : ''
      }`;
    },
    getAllForm: sharedRoutes.getAllForm,
    getLeftMenuSubmissionData: sharedRoutes.getLeftMenuSubmissionData,
    emitterSyncer: sharedRoutes.emitterSyncer,
    emitterMethod: sharedRoutes.emitterMethod,
    getDriverConfiguration: (tenantId: string) => `/tenants${apiV}/syncer/getDriverConfiguration/${tenantId}`,
    getEstimatesResponse: sharedRoutes.getEstimatesResponse,
    syncerSetting: sharedRoutes.syncerSetting,
    generalSyncer: sharedRoutes.generalSyncer,
    dropdownDataGeneral: sharedRoutes.dropdownDataGeneral,
    getBillingModes: sharedRoutes.getBillingModes,
    paySetting: sharedRoutes.paySetting,
    mangoSetting: `/tenant${apiV}/mangoPhoneNumber`,
    twilioSetting: sharedRoutes.twilioSetting,
    getDocumentTypes: sharedRoutes.getDocumentTypes,
    getListRoom: sharedRoutes.getListRoom,
    getAppointmentType: sharedRoutes.getAppointmentType,
    getMedicalServices: sharedRoutes.getMedicalServices,
    getListSubmissionData: sharedRoutes.getListSubmissionData,
    getOptionDataSubmissionFilter: sharedRoutes.getOptionDataSubmissionFilter,
    practiceLoginContact: (userId: string) =>
      `/oauth/practiceSignInAsContact/${userId}`,
  },

  POST: {
    sendEmailForDeal: (dealId: string) => `/deals${apiV}/sendEmail/${dealId}`,
    newDeal: `/deals${apiV}`,
    createPaymentLink: `/deals${apiV}/createLink`,
    updateSubscriptionContact: (contactId: string) =>
      `/contacts${apiV}/${contactId}/subscriptions`,
    toggleHideInClientPortal: `/species${apiV}/hideInClientPortal`,
    createProduct: `/products${apiV}`,
    submitSubmissionForm: `/formBuilder${apiV}/updateSubmissionForms`,
    submitPdf: `/tenants${apiV}/formBuilder/uploadFile`,
    createSubmissionContact: `/formBuilder${apiV}/createSubmissionContacts`,
    login: '/oauth/token',
    createAppointmentTypesOfProvider: `/apointmentSettingProvider${apiV}/providerAppointmentType`,
    createProvider: `/appointmentSettingProvider${apiV}/createAppointmentSettingProvider`,
    createOutOfOfficeEvent: `/appointmentSetting${apiV}/OutOfOfficeEvent`,
    createFolder: (tenantId: string) =>
      `/tenants/v1/formBuilder/tenantFolder/${tenantId}`,
    sendNotifyContact: '/appointmentBookingRequests/v1/notifyContact',
    createForm: '/tenants/v1/formBuilder/form',
    confirmAppointment: '/users/v1/appointmentStatus',
    adminLoginPractice: (tenantGroupId: string, tenantId: string) =>
      `${sharedRoutes.tenantInGroup(tenantGroupId)}/${tenantId}/authentication`,
    createTenant: sharedRoutes.tenants,
    // createUser: `/users${apiV}`,
    createGroupCurrentTenant: sharedRoutes.currentTenantAllGroups,
    createAppointment: sharedRoutes.currentTenantAppointments,
    createSystemAgreement: sharedRoutes.agreements,
    createPet: sharedRoutes.allUserPetsByUserID,
    createAppointmentByUserID: sharedRoutes.appointmentsByUserID,
    createAgreementsRevisionByID: sharedRoutes.singleAgreementByID,
    createResource: `/resources${apiV}`,
    createGroup: `/tenantGroups${apiV}`,
    approveFormSubmission: `/formBuilder${apiV}/approveFormSubmission`,
    approveOABSubmission: `/appointmentBookingRequests${apiV}/approveOABSubmission`,
    lockoutUserByID: sharedRoutes.lockoutUserByID,
    updateUser: sharedRoutes.singleUserByID,
    sendEmailSupport: sharedRoutes.sendEmailSupport,

    appointmentAttachmentsByID: sharedRoutes.appointmentAttachmentsByID,
    appointmentNotesByID: sharedRoutes.appointmentNotesByID,

    currentTenantRegisterUser: `/tenant${apiV}/users`,

    registerTelemedResource: sharedRoutes.telemedResources,
    uploadDriver: `/tenants${apiV}/driver/upload`,
    uploadService: `/syncer${apiV}/service/upload?attachment`,
    uploadConfigurator: `/tenants${apiV}/configurator/upload`,
    uploadImage: `/templates${apiV}/uploadImage`,
    currentUserAgreementByID: sharedRoutes.userAgreementsByID,
    updateMarkUnread: sharedRoutes.updateMarkUnread,

    userSettingsByNamespace: sharedRoutes.userSettingsByNamespace,
    userPolicies: sharedRoutes.userPolicies,
    tenantSystemSetting: `/tenant${apiV}/settings/SystemSetting`,
    addRedirectLink: `/redirect${apiV}/createRedirectLink`,
    timeZones: sharedRoutes.timeZones,
    emailAnalytics: sharedRoutes.emailAnalytics,
    messageByUserID: sharedRoutes.messagesByUserID,
    updatePhoneNumber: (tenantId: string) =>
      `/tenant${apiV}/${tenantId}/phoneNumbers`,
    sendMessageToPhone: `/users${apiV}/sendAMessage`,
    messageWithMultipleReceivers: sharedRoutes.multipleUsersMessages,
    postalByUserID: (userId: string) => `/postal${apiV}/${userId}/postcard`,
    postalWithMultipleContacts: `/postal${apiV}/sendPostcardToContacts`,
    postalToAllContacts: (status: string) =>
      `/postal${apiV}/sendPostcardToAll?withStatus=${status}`,
    createTemplate: sharedRoutes.createTemplate,
    updateAutomationSetting: sharedRoutes.updateAutomationSetting,
    createAutomationTriggerEvent: sharedRoutes.createAutomationTriggerEvent,
    copyAutomationTriggerEvent: sharedRoutes.copyAutomationTriggerEvent,
    createRole: sharedRoutes.roles,
    createDriver: sharedRoutes.createDriver,
    createService: sharedRoutes.createService,
    createConfigurator: sharedRoutes.createConfigurator,
    setPassword: (userId: string) => `/users${apiV}/${userId}/password`,
    createTenantInGroup: (tenantGroupId: string) =>
      sharedRoutes.tenantInGroup(tenantGroupId),
    createEmitter: () => sharedRoutes.createEmitter,
    createTextMessageEmitterExecute: () => sharedRoutes.createTextMessageEmitterExecute,
    addEstimatesRequest: () => sharedRoutes.addEstimatesRequest,
    resetPassword: sharedRoutes.resetPassword,
    softDeleteUser: (userId: string) => sharedRoutes.softDeleteUser(userId),
    sendSMSToAllContacts: sharedRoutes.sendSMSToAllContacts,
    sendSelectedUserEmail: sharedRoutes.multipleUsersMessages,
    cloneForm: sharedRoutes.cloneForm,
    addFormAutoFill: sharedRoutes.addFormAutoFill,
    createAppointmentType: sharedRoutes.createAppointmentType,
    markSubmissionAsRead: sharedRoutes.markSubmissionAsRead,
    deleteSubmissionForm: sharedRoutes.deleteSubmissionForm,
    deleteSubmissionDocument: sharedRoutes.deleteSubmissionDocument,
    approveSubmissionForm: sharedRoutes.approveSubmissionForm,
  },

  PUT: {
    orderMedicalServices: `/tenant${apiV}/config-sort-order-medicalservice`,
    currentExternalLink: `/tenant${apiV}/settings/client-portal-external-link`,
    userSettingsByNamespace: sharedRoutes.userSettingsByNamespace,
    updateGeneralSettingSyncer: sharedRoutes.updateGeneralSettingSyncer,
    updateDataPaySetting: sharedRoutes.paySetting,
    sendPhonePreview: sharedRoutes.sendPhonePreview,
    updateDataTwilioSetting: sharedRoutes.twilioSetting,
    markReadSyncerErrors: sharedRoutes.markReadSyncerErrors,
    markReadTenantSyncerError: sharedRoutes.markReadTenantSyncerError,
  },

  PATCH: {
    updateMessageNote: (messageId: string) =>
      `/users${apiV}/${messageId}/notes`,
    updateMangoPhoneSetting: `/tenant${apiV}/mangoPhoneNumber`,
    updateProduct: (productId: string) => `/products${apiV}/${productId}`,
    updatePhoneContact: (userId: string) =>
      `/users${apiV}/${userId}/phoneNumber`,
    regeneratePendingAutomation: `/automationTriggerEvents${apiV}/regenerate`,
    cancelInteraction: sharedRoutes.interactionByID,
    updateProvider: `/appointmentSettingProvider${apiV}/updateAppointmentSettingProvider`,
    updateEventOffice: `/appointmentSetting${apiV}/OutOfOfficeEvent`,
    updateHoursOfOperation: `/appointmentSetting${apiV}/hoursOfOperation`,
    updateTenantOABTimeUnits: `/tenant${apiV}/updateTenantOABTimeUnit`,
    // setStatusAppointmentBooking: (requestId: string) => `/appointmentBookingRequests/v1/${requestId}/updateRequestStatus`,
    setStatusAppointmentBooking: (requestId: string, actionKind: number) =>
      `/appointmentBookingRequests/v1/${requestId}/updateRequestStatus/${actionKind}`,
    editForm: '/tenants/v1/formBuilder/form',
    editFormBooking: '/tenants/v1/formBuilder/formBooking',
    renameFolder: '/tenants/v1/formBuilder/folder',
    updatePet: sharedRoutes.singlePetByID,
    updateAppointmentByID: sharedRoutes.singleAppointmentByID,
    updateGroupDataByID: sharedRoutes.singleGroupByID,
    updateResourceByID: sharedRoutes.singleResourceByID,
    updateUserPassword: sharedRoutes.currentUser,
    updateUserSetting: sharedRoutes.userSettingsByNamespace,
    updateSystemSetting: sharedRoutes.updateSystemSetting,
    createUser: `/users${apiV}`,
    updateUser: (userId: string) => `/users${apiV}/${userId}`,
    templateByID: sharedRoutes.templateByID,
    enableAutomationTriggerEvent: sharedRoutes.statusAutomationTriggerEvent,
    updateAutomationTriggerEvent: sharedRoutes.updateAutomationTriggerEvent,
    updateRole: (roleId: string) => `${sharedRoutes.roles}/${roleId}`,
    updateGroup: (groupId: string) => `/tenantGroups${apiV}/${groupId}`,
    updateMinimumAvailability: `/tenant${apiV}/updateTenantMinimunAvailibility`,
    updateDriverConfiguraion: sharedRoutes.driverConfigurationSetting,
    updateDriver: sharedRoutes.drivers,
    updateService: sharedRoutes.services,
    updateConfigurator: sharedRoutes.configurators,
    updatePractice: (tenantGroupId: string, tenantId: string) =>
      `${sharedRoutes.tenantInGroup(tenantGroupId)}/${tenantId}`,
    updateAppointmentType: sharedRoutes.updateAppointmentType,
    updateMedicalService: `/tenant${apiV}/config-medicalservice`,
  },

  DEL: {
    deleteTenantSyncerError: `/tenants${apiV}/syncerErrorLogs/delete`,
    deleteSyncerErrors: `/tenants${apiV}/syncerErrorLogs/deleteByTenants`,
    removeForm: (formId: string) => `/tenants/v1/formBuilder/form/${formId}`,
    deleteOfficeEvent: (id: string) =>
      `/appointmentSetting${apiV}/OutOfOfficeEvent/${id}`,
    deleteAppointmentBooking: (id: string) => `id`,
    removeFolder: (folderId: string) =>
      `/tenants/v1/formBuilder/folder/${folderId}`,
    deleteProvider: (id: string) =>
      `/appointmentSettingProvider${apiV}/deleteAppointmentSettingProvider/${id}`,
    removePetByID: sharedRoutes.singlePetByID,
    deleteGroupByID: sharedRoutes.singleGroupByID,
    disableUserByID: sharedRoutes.singleUserByID,
    deleteAppointmentByID: sharedRoutes.singleAppointmentByID,
    deleteAppointmentNoteByNoteID: sharedRoutes.singleAppointmentNoteByNoteID,
    deleteTelemedResouce: sharedRoutes.singleTelemedResourceByID,
    deleteAgreementByID: sharedRoutes.userAgreementsByID,
    deleteResourceByID: sharedRoutes.singleResourceByID,
    userSettingsByNamespace: sharedRoutes.userSettingsByNamespace,
    removeAutomationTriggerEvent: sharedRoutes.automationTriggerEvents,
    removeInteraction: sharedRoutes.interactionByID,
    deleteMessage: sharedRoutes.deleteMessage,
    deleteAppointmentType: sharedRoutes.deleteAppointmentType,
    deleteTemplate: sharedRoutes.templateByID,
  },
};
