import React from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';

import { generateTitle, generatePane } from './utils';
import { credits, title, chartType, plotOptions, yAxis, generateSeries } from './constants';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface CircleChartProps {
  subtitle?: string;
  innertitle?: string;
  data: number;
  innerRadius: number;
  outerRadius: number;
  backgroundColor: string;
  lineColor: string;
  titlePosition?: string; // -xrem;
  titleColor?: string;
  subTitleColor?: string;
  height?: string;
}

const CircleChart = (props: CircleChartProps): React.ReactElement => {
  const {
    subtitle,
    innertitle,
    data,
    innerRadius,
    outerRadius,
    backgroundColor,
    lineColor,
    titlePosition,
    titleColor,
    subTitleColor,
    height = null,
  } = props;
  const series = generateSeries();

  series[0].dataLabels.format = generateTitle(
    innertitle,
    subtitle,
    titlePosition,
    titleColor,

    subTitleColor,
  );

  series[0].data[0].innerRadius = `${innerRadius}%`;
  series[0].data[0].radius = `${outerRadius}%`;
  series[0].data[0].y = data;
  series[0].data[0].color = lineColor;

  const generateOptions = (): object => ({
    chart: {
      ...chartType,
      height,
    },
    title,
    credits,
    pane: generatePane(backgroundColor, innerRadius, outerRadius),
    yAxis,
    plotOptions,
    series,
  });
  return <HighchartsReact highcharts={Highcharts} options={generateOptions()} />;
};

CircleChart.defaultProps = {
  innerRadius: 85,
  outerRadius: 100,
  data: 0,
  lineColor: 'rgba(48,104,253,1)',
  backgroundColor: 'rgba(227,235,246,1)',
};
export default CircleChart;
