import { Column } from 'global/requests/ResponseTypes';

export const columnData: Column[] = [
  { id: 'templateName', label: 'Template' },
  { id: 'sent', label: 'Sent' },
  { id: 'deliverRate', label: 'Delivery Rate' },
  { id: 'openRate', label: 'Open Rate' },
  { id: 'clickRate', label: 'Click Rate' },
];

export const columClickedLink: Column[] = [
  { id: 'link', label: 'Link' },
  { id: 'value', label: 'Clicks' },
];

export const columnDataUnsubcribed: Column[] = [
  { id: 'requestDate', label: 'Date' },
  { id: 'userFullName', label: 'Name' },
  { id: 'phoneNumber', label: 'Phone Number' },
  { id: 'email', label: 'Email' },
];

export const columnRecipientsTable: Column[] = [
  { id: 'date', label: 'Date' },
  { id: 'contactName', label: 'Contact Name' },
  { id: 'emailStatus', label: 'Status' },
  { id: 'emailAddress', label: 'Email' },
  { id: 'phoneNumber', label: 'Phone Number' },
];

export const submissionMock = [
  {
    id: -102,
    uuid: "caf2dec0-1ac0-43f1-9221-1e47e550e060",
    value: ""
  },
  {
    id: "4d093a8b-bc64-4435-9cc9-38bfc2b20d2c",
    uuid: "5b17bd10-6844-4a9e-b955-faf2cf6c07d9",
    value: "Julia",
    legwork_table_name: "contact",
    legwork_column_name: "name_first"
  },
  {
    id: "1e4f7f23-78cb-4b77-b304-11533110fd38",
    uuid: "ff95f448-56dc-4e4b-82a2-17a4aff914c1",
    value: "",
    legwork_table_name: "contact",
    legwork_column_name: "name_middle"
  },
  {
    id: "c82d45aa-34dc-4156-9efc-4d1b65b047a1",
    uuid: "5030837a-e07c-42b0-88f6-1203777c01d2",
    value: "Chi111111",
    legwork_table_name: "contact",
    legwork_column_name: "name_last"
  },
  {
    id: "b6b79faa-87d0-4e1b-a7b2-2e6ea72c9b22",
    uuid: "63d3ad50-b58f-4aaf-969c-6490d502248b",
    value: "julia.chi@yopmail.com",
    legwork_table_name: "contact_email",
    legwork_column_name: "address"
  },
  {
    id: 5,
    uuid: "703a1267-c193-41c7-bc0a-f99702677831",
    value: "8002442223",
    legwork_table_name: "contact_phone",
    legwork_column_name: "number"
  },
  {
    id: 10,
    uuid: "4c0ecd88-78b5-4fe1-be24-0f1ea5659ffc",
    value: "8002442223"
  }
]

// export const appointmentBookingMockup = {
//   fields: [
//     {
//       id: 22,
//       uuid: "f114d981-5a78-480a-bbf6-ffd70112bdeb",
//       fields: [
//         {
//           id: 22,
//           uuid: "b49ff242-0835-4676-97c6-38a663e39bba",
//           is_basic: true,
//           type_name: "Dropdown",
//           account_id: null,
//           group_name: "Appointment Booking",
//           is_deleted: false,
//           is_enabled: null,
//           is_visible: true,
//           properties: [
//             [
//               "isVisible",
//               {
//                 type: "checkbox",
//                 label: "Visible Option",
//                 value: true,
//                 isDynamic: true
//               }
//             ],
//             [
//               "sublabel",
//               {
//                 type: "input",
//                 label: "Sublabel",
//                 value: "Appointment Type",
//                 isDynamic: true
//               }
//             ],
//             [
//               "placeholder",
//               {
//                 type: "input",
//                 label: "Placeholder",
//                 value: "",
//                 isDynamic: true
//               }
//             ]
//           ],
//           sort_order: 22,
//           is_syncable: false,
//           display_name: "Appointment Type",
//           legwork_data_type: "string",
//           form_field_type_id: 6,
//           legwork_table_name: "appointment_booking_request",
//           form_field_group_id: 4,
//           legwork_column_name: "appointment_type_id"
//         },
//         {
//           id: 23,
//           uuid: "b263c560-9538-4b5b-841d-298732185edd",
//           is_basic: true,
//           type_name: "Dropdown",
//           account_id: null,
//           group_name: "Appointment Booking",
//           is_deleted: false,
//           is_enabled: null,
//           is_visible: true,
//           properties: [
//             [
//               "isVisible",
//               {
//                 type: "checkbox",
//                 label: "Visible Option",
//                 value: true,
//                 isDynamic: true
//               }
//             ],
//             [
//               "sublabel",
//               {
//                 type: "input",
//                 label: "Sublabel",
//                 value: "Provider",
//                 isDynamic: true
//               }
//             ],
//             [
//               "placeholder",
//               {
//                 type: "input",
//                 label: "Placeholder",
//                 value: "",
//                 isDynamic: true
//               }
//             ]
//           ],
//           sort_order: 23,
//           is_syncable: false,
//           display_name: "Provider",
//           legwork_data_type: "string",
//           form_field_type_id: 6,
//           legwork_table_name: "appointment_booking_request",
//           form_field_group_id: 4,
//           legwork_column_name: "appointment_booking_provider_id"
//         },
//         {
//           id: 25,
//           uuid: "9326de60-8c7e-4f32-94c6-ed7ca8550494",
//           is_basic: true,
//           type_name: "Short Text Entry",
//           account_id: null,
//           group_name: "Appointment Booking",
//           is_deleted: false,
//           is_enabled: null,
//           is_visible: true,
//           properties: [
//             [
//               "isVisible",
//               {
//                 type: "checkbox",
//                 label: "Visible Option",
//                 value: true,
//                 isDynamic: true
//               }
//             ],
//             [
//               "sublabel",
//               {
//                 type: "input",
//                 label: "Sublabel",
//                 value: "Appointment Time",
//                 isDynamic: true
//               }
//             ],
//             [
//               "placeholder",
//               {
//                 type: "input",
//                 label: "Placeholder",
//                 value: "",
//                 isDynamic: true
//               }
//             ]
//           ],
//           sort_order: 25,
//           is_syncable: false,
//           display_name: "Appointment Time",
//           legwork_data_type: "string",
//           form_field_type_id: 4,
//           legwork_table_name: "appointment_booking_request",
//           form_field_group_id: 4,
//           legwork_column_name: "appointment_time"
//         },
//         {
//           id: 24,
//           uuid: "9922710d-4b4f-44a0-a4bd-9fe2a4efd91c",
//           is_basic: true,
//           type_name: "Date Picker",
//           account_id: null,
//           group_name: "Appointment Booking",
//           is_deleted: false,
//           is_enabled: null,
//           is_visible: true,
//           properties: [
//             [
//               "isVisible",
//               {
//                 type: "checkbox",
//                 label: "Visible Option",
//                 value: true,
//                 isDynamic: true
//               }
//             ],
//             [
//               "sublabel",
//               {
//                 type: "input",
//                 label: "Sublabel",
//                 value: "Appointment Date",
//                 isDynamic: true
//               }
//             ],
//             [
//               "placeholder",
//               {
//                 type: "input",
//                 label: "Placeholder",
//                 value: "",
//                 isDynamic: true
//               }
//             ]
//           ],
//           sort_order: 24,
//           is_syncable: false,
//           display_name: "Appointment Date",
//           legwork_data_type: "string",
//           form_field_type_id: 1,
//           legwork_table_name: "appointment_booking_request",
//           form_field_group_id: 4,
//           legwork_column_name: "appointment_date"
//         }
//       ],
//       "isLocked": true,
//       properties: {
//         "common": [
//           [
//             "label",
//             {
//               type: "input",
//               label: "label",
//               value: "Appointment Booking",
//               isDynamic: false
//             }
//           ],
//           [
//             "isVisible",
//             {
//               type: "checkbox",
//               label: "Visible Option",
//               value: false,
//               isDynamic: true
//             }
//           ],
//           [
//             "sublabel",
//             {
//               type: "input",
//               label: "Sublabel",
//               value: "",
//               isDynamic: true
//             }
//           ],
//           [
//             "placeholder",
//             {
//               type: "input",
//               label: "Placeholder",
//               value: "",
//               isDynamic: true
//             }
//           ],
//           [
//             "makeRequired",
//             {
//               type: "checkbox",
//               label: "Make Required",
//               value: false,
//               isDynamic: false
//             }
//           ],
//           [
//             "makeHidden",
//             {
//               type: "checkbox",
//               label: "Make Hidden",
//               value: false,
//               isDynamic: false
//             }
//           ],
//           [
//             "hideLabel",
//             {
//               type: "checkbox",
//               label: "Hide Label",
//               value: false,
//               isDynamic: false
//             }
//           ],
//           [
//             "readOnly",
//             {
//               type: "checkbox",
//               label: "Read-Only",
//               value: false,
//               isDynamic: false
//             }
//           ]
//         ]
//       },
//       sort_order: 22,
//       display_name: "Appointment Booking",
//       "validationMessage": null,
//       form_field_group_id: 4
//     },
//   ]
// }


export const appointmentBookingMockup = {
  "fields": [
    {
      "id": 22,
      "uuid": "f114d981-5a78-480a-bbf6-ffd70112bdeb",
      "fields": [
        {
          "id": 22,
          "uuid": "b49ff242-0835-4676-97c6-38a663e39bba",
          "is_basic": true,
          "type_name": "Dropdown",
          "account_id": null,
          "group_name": "Appointment Booking",
          "is_deleted": false,
          "is_enabled": null,
          "is_visible": true,
          "properties": [
            [
              "isVisible",
              {
                "type": "checkbox",
                "label": "Visible Option",
                "value": true,
                "isDynamic": true
              }
            ],
            [
              "sublabel",
              {
                "type": "input",
                "label": "Sublabel",
                "value": "Appointment Type",
                "isDynamic": true
              }
            ],
            [
              "placeholder",
              {
                "type": "input",
                "label": "Placeholder",
                "value": "",
                "isDynamic": true
              }
            ]
          ],
          "sort_order": 22,
          "is_syncable": false,
          "display_name": "Appointment Type",
          "legwork_data_type": "string",
          "form_field_type_id": 6,
          "legwork_table_name": "appointment_booking_request",
          "form_field_group_id": 4,
          "legwork_column_name": "appointment_type_id"
        },
        {
          "id": 23,
          "uuid": "b263c560-9538-4b5b-841d-298732185edd",
          "is_basic": true,
          "type_name": "Dropdown",
          "account_id": null,
          "group_name": "Appointment Booking",
          "is_deleted": false,
          "is_enabled": null,
          "is_visible": true,
          "properties": [
            [
              "isVisible",
              {
                "type": "checkbox",
                "label": "Visible Option",
                "value": true,
                "isDynamic": true
              }
            ],
            [
              "sublabel",
              {
                "type": "input",
                "label": "Sublabel",
                "value": "Provider",
                "isDynamic": true
              }
            ],
            [
              "placeholder",
              {
                "type": "input",
                "label": "Placeholder",
                "value": "",
                "isDynamic": true
              }
            ]
          ],
          "sort_order": 23,
          "is_syncable": false,
          "display_name": "Provider",
          "legwork_data_type": "string",
          "form_field_type_id": 6,
          "legwork_table_name": "appointment_booking_request",
          "form_field_group_id": 4,
          "legwork_column_name": "appointment_booking_provider_id"
        },
        {
          "id": 25,
          "uuid": "9326de60-8c7e-4f32-94c6-ed7ca8550494",
          "is_basic": true,
          "type_name": "Short Text Entry",
          "account_id": null,
          "group_name": "Appointment Booking",
          "is_deleted": false,
          "is_enabled": null,
          "is_visible": true,
          "properties": [
            [
              "isVisible",
              {
                "type": "checkbox",
                "label": "Visible Option",
                "value": true,
                "isDynamic": true
              }
            ],
            [
              "sublabel",
              {
                "type": "input",
                "label": "Sublabel",
                "value": "Appointment Time",
                "isDynamic": true
              }
            ],
            [
              "placeholder",
              {
                "type": "input",
                "label": "Placeholder",
                "value": "",
                "isDynamic": true
              }
            ]
          ],
          "sort_order": 25,
          "is_syncable": false,
          "display_name": "Appointment Time",
          "legwork_data_type": "string",
          "form_field_type_id": 4,
          "legwork_table_name": "appointment_booking_request",
          "form_field_group_id": 4,
          "legwork_column_name": "appointment_time"
        },
        {
          "id": 24,
          "uuid": "9922710d-4b4f-44a0-a4bd-9fe2a4efd91c",
          "is_basic": true,
          "type_name": "Date Picker",
          "account_id": null,
          "group_name": "Appointment Booking",
          "is_deleted": false,
          "is_enabled": null,
          "is_visible": true,
          "properties": [
            [
              "isVisible",
              {
                "type": "checkbox",
                "label": "Visible Option",
                "value": true,
                "isDynamic": true
              }
            ],
            [
              "sublabel",
              {
                "type": "input",
                "label": "Sublabel",
                "value": "Appointment Date",
                "isDynamic": true
              }
            ],
            [
              "placeholder",
              {
                "type": "input",
                "label": "Placeholder",
                "value": "",
                "isDynamic": true
              }
            ]
          ],
          "sort_order": 24,
          "is_syncable": false,
          "display_name": "Appointment Date",
          "legwork_data_type": "string",
          "form_field_type_id": 1,
          "legwork_table_name": "appointment_booking_request",
          "form_field_group_id": 4,
          "legwork_column_name": "appointment_date"
        }
      ],
      "isLocked": true,
      "properties": {
        "common": [
          [
            "label",
            {
              "type": "input",
              "label": "label",
              "value": "Appointment Booking",
              "isDynamic": false
            }
          ],
          [
            "isVisible",
            {
              "type": "checkbox",
              "label": "Visible Option",
              "value": false,
              "isDynamic": true
            }
          ],
          [
            "sublabel",
            {
              "type": "input",
              "label": "Sublabel",
              "value": "",
              "isDynamic": true
            }
          ],
          [
            "placeholder",
            {
              "type": "input",
              "label": "Placeholder",
              "value": "",
              "isDynamic": true
            }
          ],
          [
            "makeRequired",
            {
              "type": "checkbox",
              "label": "Make Required",
              "value": false,
              "isDynamic": false
            }
          ],
          [
            "makeHidden",
            {
              "type": "checkbox",
              "label": "Make Hidden",
              "value": false,
              "isDynamic": false
            }
          ],
          [
            "hideLabel",
            {
              "type": "checkbox",
              "label": "Hide Label",
              "value": false,
              "isDynamic": false
            }
          ],
          [
            "readOnly",
            {
              "type": "checkbox",
              "label": "Read-Only",
              "value": false,
              "isDynamic": false
            }
          ]
        ]
      },
      "sort_order": 22,
      "display_name": "Appointment Booking",
      "validationMessage": null,
      "form_field_group_id": 4
    },
    {
      "id":-102,
      "uuid":"f27e1912-40ff-4ba8-8df4-63a55883de25",
      "type_name":"Description Area",
      "properties":{
         "common":[
            [
               "label",
               {
                  "type":"input",
                  "label":"Label",
                  "value":"Description Area",
                  "isDynamic":false
               }
            ],
            [
               "isVisible",
               {
                  "type":"checkbox",
                  "label":"Visible Option",
                  "value":false,
                  "isDynamic":true
               }
            ],
            [
               "sublabel",
               {
                  "type":"input",
                  "label":"Sublabel",
                  "value":"",
                  "isDynamic":true
               }
            ],
            [
               "placeholder",
               {
                  "type":"input",
                  "label":"Placeholder",
                  "value":"",
                  "isDynamic":true
               }
            ],
            [
               "makeRequired",
               {
                  "type":"checkbox",
                  "label":"Make Required",
                  "value":false,
                  "isDynamic":false
               }
            ],
            [
               "makeHidden",
               {
                  "type":"checkbox",
                  "label":"Make Hidden",
                  "value":false,
                  "isDynamic":false
               }
            ],
            [
               "hideLabel",
               {
                  "type":"checkbox",
                  "label":"Hide Label",
                  "value":false,
                  "isDynamic":false
               }
            ],
            [
               "readOnly",
               {
                  "type":"checkbox",
                  "label":"Read-Only",
                  "value":false,
                  "isDynamic":false
               }
            ]
         ],
         "specific":[
            [
               "label",
               {
                  
               }
            ],
            [
               "sublabel",
               {
                  
               }
            ],
            [
               "placeholder",
               {
                  
               }
            ],
            [
               "makeRequired",
               {
                  
               }
            ],
            [
               "makeHidden",
               {
                  
               }
            ],
            [
               "hideLabel",
               {
                  
               }
            ],
            [
               "readOnly",
               {
                  
               }
            ],
            [
               "descriptionContent",
               {
                  "type":"rich-text",
                  "label":"Edit Description Area",
                  "value":"<h4>By submitting this form, you agree to receive email and text communications regarding the status of your appointment request.</h4>",
                  "buttonIcon":"pencil"
               }
            ]
         ]
      },
      "display_name":"Description Area",
      "validationMessage":null
   },
   {
    "uuid":"fb2fbd9d-79ff-4c01-9264-dcd2415f04ea",
    "type_name":"Submit",
    "properties":{
       "common":[
          [
             "label",
             {
                "type":"input",
                "label":"Label",
                "value":"Request Appointment",
                "isDynamic":false
             }
          ],
          [
             "isVisible",
             {
                "type":"checkbox",
                "label":"Visible Option",
                "value":false,
                "isDynamic":true
             }
          ],
          [
             "sublabel",
             {
                "type":"input",
                "label":"Sublabel",
                "value":"",
                "isDynamic":true
             }
          ],
          [
             "placeholder",
             {
                "type":"input",
                "label":"Placeholder",
                "value":"",
                "isDynamic":true
             }
          ],
          [
             "makeRequired",
             {
                "type":"checkbox",
                "label":"Make Required",
                "value":false,
                "isDynamic":false
             }
          ],
          [
             "makeHidden",
             {
                "type":"checkbox",
                "label":"Make Hidden",
                "value":false,
                "isDynamic":false
             }
          ],
          [
             "hideLabel",
             {
                "type":"checkbox",
                "label":"Hide Label",
                "value":false,
                "isDynamic":false
             }
          ],
          [
             "readOnly",
             {
                "type":"checkbox",
                "label":"Read-Only",
                "value":false,
                "isDynamic":false
             }
          ]
       ],
       "specific":[
          [
             "sublabel",
             {
                
             }
          ],
          [
             "placeholder",
             {
                
             }
          ],
          [
             "makeRequired",
             {
                
             }
          ],
          [
             "makeHidden",
             {
                
             }
          ],
          [
             "hideLabel",
             {
                
             }
          ],
          [
             "readOnly",
             {
                
             }
          ]
       ]
    },
    "display_name":"Submit",
    "validationMessage":null
  }
  ]
}


export const mockupDataSubmisson = [
  {
    id: "4d093a8b-bc64-4435-9cc9-38bfc2b20d2c",
    form_field_group_id: "4c887edf-d66e-4868-bae7-bf927d4c6bc2",
    display_name: "Name",
    sort_order: 1,
    fields: [
      {
        id: "4d093a8b-bc64-4435-9cc9-38bfc2b20d2c",
        form_field_group_id: "4c887edf-d66e-4868-bae7-bf927d4c6bc2",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 1,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "FirstName",
        group_name: "Name",
        data_type: "String",
        display_name: "First Name",
        uuid: "92d90d04-616c-4bfe-b207-d91907b64f98",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "First Name",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "1e4f7f23-78cb-4b77-b304-11533110fd38",
        form_field_group_id: "4c887edf-d66e-4868-bae7-bf927d4c6bc2",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: false,
        is_enabled: true,
        sort_order: 2,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "MiddleName",
        group_name: "Name",
        data_type: "String",
        display_name: "Middle Name",
        uuid: "770d4181-9df3-4ec3-bc3e-c2281c835ec5",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: false,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Middle Name",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "c82d45aa-34dc-4156-9efc-4d1b65b047a1",
        form_field_group_id: "4c887edf-d66e-4868-bae7-bf927d4c6bc2",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 3,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "LastName",
        group_name: "Name",
        data_type: "String",
        display_name: "Last Name",
        uuid: "6938490f-9d44-4dd6-9faf-7f1e42ffa5f4",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Last Name",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      }
    ],
    uuid: "d8b35956-6ff4-462d-82cb-dec7b0070dda",
    properties: {
      common: [
        [
          "label",
          {
            value: "Name",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "4605769a-9b60-475e-a9b6-0f0a9a8307ce",
    form_field_group_id: "194f8246-5856-4f8a-a2a7-ae964181eb4e",
    form_field_type_id: "2e112c4f-c3bb-4b5a-9d47-a68be2fcf8c8",
    is_deleted: false,
    is_syncable: false,
    is_visible: true,
    is_enabled: true,
    sort_order: 4,
    type_name: "Dropdown",
    table_name: "Users",
    column_name: "Gender",
    group_name: "Ungrouped",
    data_type: "String",
    display_name: "Gender",
    uuid: "bb445d3d-9865-4f42-ae6c-51070f5ad126",
    field_id: 6,
    properties: {
      common: [
        [
          "label",
          {
            value: "Gender",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "options",
          {
            label: "Options",
            type: "option-group",
            value: [
              "Option 1"
            ]
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "91b53fe6-2d27-4edd-803f-767346e6214c",
    is_deleted: false,
    sort_order: 1,
    type_name: "Date Picker",
    display_name: "Date Picker",
    updated_at: "2020-09-18T05:16:47.603",
    updated_by: "926271df-8901-46ce-9737-ad42e1c20720",
    uuid: "395c4353-fffb-4971-ad0a-1496a78d49a5",
    field_id: 1,
    properties: {
      common: [
        [
          "label",
          {
            value: "Date Picker",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "dateFormat",
          {
            value: "MM/DD/YYYY",
            defaultValue: "MM/DD/YYYY",
            label: "Format",
            type: "single",
            "options": [
              "MM/DD/YYYY",
              "DD/MM/YYYY",
              "YYYY/MM/DD"
            ]
          }
        ],
        [
          "separator",
          {
            value: "/",
            defaultValue: "/",
            label: "Separator",
            type: "single",
            "options": [
              "/",
              "-",
              "."
            ],
            helpText: "Select which character to use between the date fields",
            "mappedPropKey": "dateFormat"
          }
        ],
        [
          "includeTime",
          {
            label: "Time Field",
            type: "checkbox",
            value: false,
            helpText: "Allow users to specify a time with date",
            "dateTimeOnly": true
          }
        ],
        [
          "sublabel",
          {
            value: [
              [
                "Date",
                {
                  type: "text",
                  "visible": true,
                  value: "Date",
                  defaultValue: "Date"
                }
              ],
              [
                "Hour",
                {
                  type: "text",
                  "visible": {
                    "mappedPropKey": "includeTime",
                    "mappedPropValue": "value"
                  },
                  value: "Hour",
                  defaultValue: "Hour"
                }
              ],
              [
                "Minutes",
                {
                  type: "text",
                  "visible": {
                    "mappedPropKey": "includeTime",
                    "mappedPropValue": "value"
                  },
                  value: "Minutes",
                  defaultValue: "Minutes"
                }
              ]
            ],
            label: "Sublabels",
            type: "group",
            "dateTimeOnly": true
          }
        ],
        [
          "placeholder",
          {

          }
        ],
        [
          "pastFuture",
          {
            label: "Past & Future",
            type: "multiple",
            value: [
              [
                "Past",
                true
              ],
              [
                "Future",
                true
              ]
            ],
            helpText: "Allow users to select dates in the past or future",
            "dateTimeOnly": true
          }
        ],
        [
          "weekdays",
          {
            label: "Days of the Week",
            type: "multiple",
            value: [
              [
                "Mondays",
                true
              ],
              [
                "Tuesdays",
                true
              ],
              [
                "Wednesdays",
                true
              ],
              [
                "Thursdays",
                true
              ],
              [
                "Fridays",
                true
              ],
              [
                "Saturdays",
                true
              ],
              [
                "Sundays",
                true
              ]
            ],
            helpText: "Allowed days of the week. Unchecking a day will disable all of the same days in the calendar.",
            "dateTimeOnly": true
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "76f077fd-970e-4925-bdac-0ef94bb583a4",
    is_deleted: false,
    sort_order: 3,
    type_name: "Number",
    display_name: "Number",
    updated_at: "2020-09-18T05:16:47.619",
    updated_by: "2a06a95c-4cf2-4029-b954-276a39ac2ba6",
    uuid: "54a94cbf-0485-4a27-9be3-b76d7e4b4347",
    field_id: 3,
    properties: {
      common: [
        [
          "label",
          {
            value: "Number",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "limit",
          {
            label: "Limit Entry",
            type: "checkbox",
            value: false,
            helpText: "Limit the maximum and minimum value allowed in this field"
          }
        ],
        [
          "min",
          {
            value: 0,
            defaultValue: 0,
            label: null,
            type: "input",
            helpText: "Minimum",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "span15"
          }
        ],
        [
          "max",
          {
            value: 100,
            defaultValue: 100,
            label: null,
            type: "input",
            helpText: "Maximum",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "span15"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "1be08b1a-4766-4c16-83d1-c5a6f4b6b353",
    is_deleted: false,
    sort_order: 7,
    type_name: "Single Choice",
    display_name: "Single Choice",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "91e60a4d-c823-46d9-9b26-4e2d006aee01",
    uuid: "ed6913c2-e801-473d-8f09-0f414ba70670",
    field_id: 7,
    properties: {
      common: [
        [
          "label",
          {
            value: "Single Choice",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "options",
          {
            label: "Options",
            type: "option-group",
            value: [
              "Option 1"
            ]
          }
        ],
        [
          "displayOther",
          {
            label: "Display Other Option",
            type: "checkbox",
            value: false,
            helpText: "Allow users to enter text when their selection is not available"
          }
        ],
        [
          "other",
          {
            value: "Other",
            defaultValue: "Other",
            label: null,
            type: "input",
            "visible": {
              "mappedPropKey": "displayOther",
              "mappedPropValue": "value"
            }
          }
        ],
        [
          "displayAsColumns",
          {
            label: "Columns",
            type: "checkbox",
            value: false,
            helpText: "Spread options side by side into a specified number of columns"
          }
        ],
        [
          "numColumns",
          {
            value: "2",
            defaultValue: "2",
            label: null,
            type: "input",
            helpText: "COLUMNS",
            "visible": {
              "mappedPropKey": "displayAsColumns",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: -102,
    display_name: "Description Area",
    type_name: "Description Area",
    uuid: "af6b60d7-b181-4530-9277-da398195721a",
    field_id: 5,
    properties: {
      common: [
        [
          "label",
          {
            value: "Description Area",
            label: "Label",
            type: "input",
            isDynamic: true
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "label",
          {

          }
        ],
        [
          "sublabel",
          {

          }
        ],
        [
          "placeholder",
          {

          }
        ],
        [
          "makeRequired",
          {

          }
        ],
        [
          "makeHidden",
          {

          }
        ],
        [
          "hideLabel",
          {

          }
        ],
        [
          "readOnly",
          {

          }
        ],
        [
          "descriptionContent",
          {
            label: "Edit Description Area",
            type: "rich-text",
            value: "Description Area",
            "buttonIcon": "pencil"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: -101,
    display_name: "Section Break",
    type_name: "Section Break",
    fields: [

    ],
    uuid: "a63cc5e4-e961-460c-a7d2-e5db53e8e5f2",
    properties: {
      specific: [
        [
          "heading",
          {
            value: "Heading",
            defaultValue: "Heading",
            label: "Heading Text",
            type: "input"
          }
        ],
        [
          "subheading",
          {
            label: "Sub-Heading Text",
            type: "input",
            value: ""
          }
        ],
        [
          "alignment",
          {
            value: "left",
            defaultValue: "left",
            label: "Align",
            type: "select",
            "options": [
              {
                label: "Left",
                value: "left"
              },
              {
                label: "Center",
                value: "center"
              },
              {
                label: "Right",
                value: "right"
              }
            ]
          }
        ],
        [
          "cols",
          {
            value: "1",
            defaultValue: "1",
            label: "Columns",
            type: "select",
            "options": [
              {
                label: "1",
                value: 1
              },
              {
                label: "2",
                value: 2
              },
              {
                label: "3",
                value: 3
              },
              {
                label: "4",
                value: 4
              }
            ]
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "0851cfc5-c948-4b5f-b512-4fe1f39152ab",
    is_deleted: false,
    sort_order: 10,
    type_name: "Signature",
    display_name: "Signature",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "b2daf239-af90-4376-b9da-d44e9aa8a143",
    uuid: "b2f95781-295e-4a76-8c4c-00557d8cf55c",
    field_id: 10,
    properties: {
      common: [
        [
          "label",
          {
            value: "Signature",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "sublabel",
          {
            value: "Use your mouse or finger to draw your signature above",
            defaultValue: "Use your mouse or finger to draw your signature above",
            label: "Sublabel",
            type: "input"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "d6326128-39eb-4cfd-8961-e3b8046b1586",
    is_deleted: false,
    sort_order: 9,
    type_name: "Spinner",
    display_name: "Spinner",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "f3e0f1fd-98c4-4bd9-94bc-2fa380df26f3",
    uuid: "2b8c94d4-421f-4d94-85cc-aee01fd48948",
    field_id: 9,
    properties: {
      common: [
        [
          "label",
          {
            value: "Spinner",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "step",
          {
            value: "1",
            defaultValue: "1",
            label: "Stepping",
            type: "input"
          }
        ],
        [
          "negativeValues",
          {
            label: "Negative Values",
            type: "checkbox",
            value: false,
            helpText: "Allow negative values in this field"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "2e112c4f-c3bb-4b5a-9d47-a68be2fcf8c8",
    is_deleted: false,
    sort_order: 6,
    type_name: "Dropdown",
    display_name: "Dropdown",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "f43f8ca7-300a-42db-8425-347407831767",
    uuid: "935a2c6d-4955-4d5a-b359-3e4134a4429b",
    field_id: 6,
    properties: {
      common: [
        [
          "label",
          {
            value: "Dropdown",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "options",
          {
            label: "Options",
            type: "option-group",
            value: [
              "Option 1"
            ]
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "28c9a706-81a0-4220-89fa-8394cf2cacbd",
    is_deleted: false,
    sort_order: 5,
    type_name: "Long Text Entry",
    display_name: "Long Text Entry",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "9a7010d0-c43d-42b3-8d95-06069650f3a4",
    uuid: "c188da3f-170a-446c-9454-8f1c316efdac",
    field_id: 5,
    properties: {
      common: [
        [
          "label",
          {
            value: "Long Text Entry",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "limit",
          {
            label: "Limit Entry",
            type: "checkbox",
            value: false,
            helpText: "Limit the maximum number of words allowed for this field"
          }
        ],
        [
          "max",
          {
            label: null,
            type: "input",
            value: "",
            helpText: "WORDS",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "489ed5b9-8773-4403-88ae-2aef3c714d8c",
    form_field_group_id: "194f8246-5856-4f8a-a2a7-ae964181eb4e",
    form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
    is_deleted: false,
    is_syncable: false,
    is_visible: true,
    is_enabled: true,
    sort_order: 14,
    type_name: "Short Text Entry",
    table_name: "Users",
    column_name: "SSN",
    group_name: "Ungrouped",
    data_type: "String",
    display_name: "SSN",
    uuid: "f8084d70-7d17-4321-9042-d908992bbdb9",
    field_id: 4,
    properties: {
      common: [
        [
          "label",
          {
            value: "SSN",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "acfd7592-a668-41b8-9d6e-2234e5cd7a86",
    is_deleted: false,
    sort_order: 2,
    type_name: "Time",
    display_name: "Time",
    updated_at: "2020-09-18T05:16:47.619",
    updated_by: "543f272b-d710-426c-aa29-69eefb208c8d",
    uuid: "aaf3ba84-4716-48ae-9745-3a70ce4b81c0",
    field_id: 2,
    properties: {
      common: [
        [
          "label",
          {
            value: "Time",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "timeRange",
          {
            label: "Time Range",
            type: "checkbox",
            value: false,
            helpText: "Display two time fields to indicate a range"
          }
        ],
        [
          "until",
          {
            value: "Until",
            defaultValue: "Until",
            label: "Until",
            type: "input",
            helpText: "Add text between selected forms",
            "visible": {
              "mappedPropKey": "timeRange",
              "mappedPropValue": "value"
            }
          }
        ],
        [
          "sublabel",
          {
            value: [
              [
                "startHour",
                {
                  label: "Hour",
                  type: "text",
                  "visible": true,
                  value: "Hour",
                  defaultValue: "Hour"
                }
              ],
              [
                "startMinutes",
                {
                  label: "Minutes",
                  type: "text",
                  "visible": true,
                  value: "Minutes",
                  defaultValue: "Minutes"
                }
              ],
              [
                "endHour",
                {
                  label: "Hour",
                  type: "text",
                  "visible": {
                    "mappedPropKey": "timeRange",
                    "mappedPropValue": "value"
                  },
                  value: "Hour",
                  defaultValue: "Hour"
                }
              ],
              [
                "endMinutes",
                {
                  label: "Minutes",
                  type: "text",
                  "visible": {
                    "mappedPropKey": "timeRange",
                    "mappedPropValue": "value"
                  },
                  value: "Minutes",
                  defaultValue: "Minutes"
                }
              ]
            ],
            label: "Sublabels",
            type: "group"
          }
        ],
        [
          "placeholder",
          {

          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "eddf8cfa-221f-40d1-a242-c9b0f2e318c9",
    form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
    display_name: "Address",
    sort_order: 7,
    fields: [
      {
        id: "eddf8cfa-221f-40d1-a242-c9b0f2e318c9",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 7,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "Address1",
        group_name: "Address",
        data_type: "String",
        display_name: "Address 1",
        uuid: "fe0e3746-05f1-454f-adb6-767aae288dc3",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Address 1",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "74698540-5570-48d9-a9fc-f36ab4714a5e",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 8,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "Address2",
        group_name: "Address",
        data_type: "String",
        display_name: "Address 2",
        uuid: "9a5fe709-4dc7-4ce0-ac74-46b03c8b0c24",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Address 2",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "5b02b041-58df-4ad6-8713-99a4e3c083c3",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 9,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "City",
        group_name: "Address",
        data_type: "String",
        display_name: "City",
        uuid: "31ff1bf9-2614-493f-923c-989a68a81af3",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "City",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "44aec0a1-030f-4caa-8f55-6f6b22faf5b9",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "2e112c4f-c3bb-4b5a-9d47-a68be2fcf8c8",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 10,
        type_name: "Dropdown",
        table_name: "Users",
        column_name: "State / Province",
        group_name: "Address",
        data_type: "String",
        display_name: "State / Province",
        uuid: "b01dcc60-846c-4fb8-ad25-1956bb6f1bf4",
        field_id: 6,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "State / Province",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "bf809111-13e5-4238-819e-b55015020258",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
        is_deleted: false,
        is_syncable: false,
        is_visible: true,
        is_enabled: true,
        sort_order: 11,
        type_name: "Short Text Entry",
        table_name: "Users",
        column_name: "Zip / Postal Code",
        group_name: "Address",
        data_type: "String",
        display_name: "Zip / Postal Code",
        uuid: "9fcf31d6-ec2c-4da4-970f-f4d6069053ac",
        field_id: 4,
        properties: [
          [
            "isVisible",
            {
              value: true,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Zip / Postal Code",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      },
      {
        id: "ad9cb58b-83cf-4148-9e2f-f58829a36ebb",
        form_field_group_id: "438d25b1-7301-4624-a0b0-24a115bbdeed",
        form_field_type_id: "2e112c4f-c3bb-4b5a-9d47-a68be2fcf8c8",
        is_deleted: false,
        is_syncable: false,
        is_visible: false,
        is_enabled: true,
        sort_order: 12,
        type_name: "Dropdown",
        table_name: "Users",
        column_name: "Country",
        group_name: "Address",
        data_type: "String",
        display_name: "Country",
        uuid: "a3f84853-aa4c-4096-bd44-09ece8e3dc1a",
        field_id: 6,
        properties: [
          [
            "isVisible",
            {
              value: false,
              label: "Visible Option",
              type: "checkbox",
              isDynamic: true
            }
          ],
          [
            "sublabel",
            {
              value: "Country",
              label: "Sublabel",
              type: "input",
              isDynamic: true
            }
          ],
          [
            "placeholder",
            {
              label: "Placeholder",
              type: "input",
              value: "",
              isDynamic: true
            }
          ]
        ]
      }
    ],
    uuid: "7f7e5b50-ae3d-4386-a639-3ca87df96a7b",
    properties: {
      common: [
        [
          "label",
          {
            value: "Address",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "3bae101f-875b-4fbf-a4be-421a025275aa",
    form_field_group_id: "194f8246-5856-4f8a-a2a7-ae964181eb4e",
    form_field_type_id: "91b53fe6-2d27-4edd-803f-767346e6214c",
    is_deleted: false,
    is_syncable: false,
    is_visible: true,
    is_enabled: true,
    sort_order: 13,
    type_name: "Date Picker",
    table_name: "Users",
    column_name: "Birthdate",
    group_name: "Ungrouped",
    data_type: "String",
    display_name: "Birthdate",
    uuid: "432d329b-7cb9-4cf3-9cd0-279eb6c4a378",
    field_id: 1,
    properties: {
      common: [
        [
          "label",
          {
            value: "Birthdate",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "dateFormat",
          {
            value: "MM/DD/YYYY",
            defaultValue: "MM/DD/YYYY",
            label: "Format",
            type: "single",
            "options": [
              "MM/DD/YYYY",
              "DD/MM/YYYY",
              "YYYY/MM/DD"
            ]
          }
        ],
        [
          "separator",
          {
            value: "/",
            defaultValue: "/",
            label: "Separator",
            type: "single",
            "options": [
              "/",
              "-",
              "."
            ],
            helpText: "Select which character to use between the date fields",
            "mappedPropKey": "dateFormat"
          }
        ],
        [
          "includeTime",
          {
            label: "Time Field",
            type: "checkbox",
            value: false,
            helpText: "Allow users to specify a time with date",
            "dateTimeOnly": true
          }
        ],
        [
          "sublabel",
          {
            value: [
              [
                "Date",
                {
                  type: "text",
                  "visible": true,
                  value: "Date",
                  defaultValue: "Date"
                }
              ],
              [
                "Hour",
                {
                  type: "text",
                  "visible": {
                    "mappedPropKey": "includeTime",
                    "mappedPropValue": "value"
                  },
                  value: "Hour",
                  defaultValue: "Hour"
                }
              ],
              [
                "Minutes",
                {
                  type: "text",
                  "visible": {
                    "mappedPropKey": "includeTime",
                    "mappedPropValue": "value"
                  },
                  value: "Minutes",
                  defaultValue: "Minutes"
                }
              ]
            ],
            label: "Sublabels",
            type: "group",
            "dateTimeOnly": true
          }
        ],
        [
          "placeholder",
          {

          }
        ],
        [
          "pastFuture",
          {
            label: "Past & Future",
            type: "multiple",
            value: [
              [
                "Past",
                true
              ],
              [
                "Future",
                true
              ]
            ],
            helpText: "Allow users to select dates in the past or future",
            "dateTimeOnly": true
          }
        ],
        [
          "weekdays",
          {
            label: "Days of the Week",
            type: "multiple",
            value: [
              [
                "Mondays",
                true
              ],
              [
                "Tuesdays",
                true
              ],
              [
                "Wednesdays",
                true
              ],
              [
                "Thursdays",
                true
              ],
              [
                "Fridays",
                true
              ],
              [
                "Saturdays",
                true
              ],
              [
                "Sundays",
                true
              ]
            ],
            helpText: "Allowed days of the week. Unchecking a day will disable all of the same days in the calendar.",
            "dateTimeOnly": true
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "381413f2-115f-4ee7-a0cc-56f0c9cfdb8f",
    form_field_group_id: "194f8246-5856-4f8a-a2a7-ae964181eb4e",
    form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
    is_deleted: false,
    is_syncable: false,
    is_visible: true,
    is_enabled: true,
    sort_order: 5,
    type_name: "Short Text Entry",
    table_name: "Users",
    column_name: "PhoneNumber",
    group_name: "Ungrouped",
    data_type: "String",
    display_name: "Phone Number",
    uuid: "8af1ba48-8ae5-41c8-98e5-caeb73a31e11",
    field_id: 4,
    properties: {
      common: [
        [
          "label",
          {
            value: "Phone Number",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "limit",
          {
            label: "Limit Entry",
            type: "checkbox",
            value: false,
            helpText: "Limit the maximum number of characters allowed for this field"
          }
        ],
        [
          "max",
          {
            label: null,
            type: "input",
            value: "",
            helpText: "CHARS",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "b6b79faa-87d0-4e1b-a7b2-2e6ea72c9b22",
    form_field_group_id: "194f8246-5856-4f8a-a2a7-ae964181eb4e",
    form_field_type_id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
    is_deleted: false,
    is_syncable: false,
    is_visible: true,
    is_enabled: true,
    sort_order: 6,
    type_name: "Short Text Entry",
    table_name: "Users",
    column_name: "Email Address",
    group_name: "Ungrouped",
    data_type: "String",
    display_name: "Email Address",
    uuid: "43b229a9-a44c-4385-b09f-2a6f4cdf5a1b",
    field_id: 4,
    properties: {
      common: [
        [
          "label",
          {
            value: "Email Address",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "limit",
          {
            label: "Limit Entry",
            type: "checkbox",
            value: false,
            helpText: "Limit the maximum number of characters allowed for this field"
          }
        ],
        [
          "max",
          {
            label: null,
            type: "input",
            value: "",
            helpText: "CHARS",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "82b4ea4f-8472-4982-862e-d19fa7751e99",
    is_deleted: false,
    sort_order: 4,
    type_name: "Short Text Entry",
    display_name: "Short Text Entry",
    updated_at: "2020-09-18T05:16:47.619",
    updated_by: "c94c85ac-3b77-4789-a733-3fc1eaa8d06d",
    uuid: "be6c79f5-8ae1-49fe-8a40-04e9118ad8cf",
    field_id: 4,
    properties: {
      common: [
        [
          "label",
          {
            value: "Short Text Entry",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "limit",
          {
            label: "Limit Entry",
            type: "checkbox",
            value: false,
            helpText: "Limit the maximum number of characters allowed for this field"
          }
        ],
        [
          "max",
          {
            label: null,
            type: "input",
            value: "",
            helpText: "CHARS",
            "visible": {
              "mappedPropKey": "limit",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    id: "788e1590-e3fb-46d4-a9ae-3f703b3871f9",
    is_deleted: false,
    sort_order: 8,
    type_name: "Multiple Choice",
    display_name: "Multiple Choice",
    updated_at: "2020-09-18T05:16:47.620",
    updated_by: "2ace6670-cc31-49dc-bed4-6001123ab6aa",
    uuid: "225f3f4f-8b12-4d6a-90cc-985b0874c86e",
    field_id: 8,
    properties: {
      common: [
        [
          "label",
          {
            value: "Multiple Choice",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "placeholder",
          {

          }
        ],
        [
          "options",
          {
            label: "Options",
            type: "option-group",
            value: [
              "Option 1"
            ]
          }
        ],
        [
          "displayOther",
          {
            label: "Display Other Option",
            type: "checkbox",
            value: false,
            helpText: "Allow users to enter text when their selection is not available"
          }
        ],
        [
          "other",
          {
            value: "Other",
            defaultValue: "Other",
            label: null,
            type: "input",
            "visible": {
              "mappedPropKey": "displayOther",
              "mappedPropValue": "value"
            }
          }
        ],
        [
          "displayAsColumns",
          {
            label: "Columns",
            type: "checkbox",
            value: false,
            helpText: "Spread options side by side into a specified number of columns"
          }
        ],
        [
          "numColumns",
          {
            value: "2",
            defaultValue: "2",
            label: null,
            type: "input",
            helpText: "COLUMNS",
            "visible": {
              "mappedPropKey": "displayAsColumns",
              "mappedPropValue": "value"
            },
            "className": "inline"
          }
        ]
      ]
    },
    "validationMessage": null
  },
  {
    uuid: "545d90e8-93ca-419a-bc78-aa18a5c80eca",
    type_name: "Submit",
    display_name: "Submit",
    properties: {
      common: [
        [
          "label",
          {
            value: "Submit",
            label: "label",
            type: "input",
            isDynamic: false
          }
        ],
        [
          "isVisible",
          {
            label: "Visible Option",
            type: "checkbox",
            value: false,
            isDynamic: true
          }
        ],
        [
          "sublabel",
          {
            label: "Sublabel",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "placeholder",
          {
            label: "Placeholder",
            type: "input",
            value: "",
            isDynamic: true
          }
        ],
        [
          "makeRequired",
          {
            label: "Make Required",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "makeHidden",
          {
            label: "Make Hidden",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "hideLabel",
          {
            label: "Hide Label",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ],
        [
          "readOnly",
          {
            label: "Read-Only",
            type: "checkbox",
            value: false,
            isDynamic: false
          }
        ]
      ],
      specific: [
        [
          "sublabel",
          {

          }
        ],
        [
          "placeholder",
          {

          }
        ],
        [
          "makeRequired",
          {

          }
        ],
        [
          "makeHidden",
          {

          }
        ],
        [
          "hideLabel",
          {

          }
        ],
        [
          "readOnly",
          {

          }
        ]
      ]
    },
    "validationMessage": null
  }
]
