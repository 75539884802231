import React from 'react';

const renderComponentHeader = (headerText?: string) => {
  if (!headerText) {
    return;
  }
  return (
    <div className="component__title-container">
      <div className="component__title">
        {headerText}
      </div>
    </div>
  )
}

// call this function with a component and the other details, then store the return value of this function
// that returned value can then be called like any react component (<MyComponent />)
const withComponentClassAndHeaderText = function <T>(Component: React.ComponentType<T>, headerText?: string, className?: string) {
  return (props: T) => {
    return (
      <div key={"component-wrapper--with-class-and-header-text-" + headerText} className={"component " + (className ? className : "")}>
        
        {renderComponentHeader(headerText)}
        
        <Component {...props} key={"component-with-class-and-header-text-" + headerText} />
      
      </div>
    )
  }
}

export default withComponentClassAndHeaderText;