import styled from 'styled-components';

const VerticalTabContainer = styled.div`
  height: 100%;
  .MuiBox-root {
    height: 100%;
  }

  .form-search {
    padding: 14px 24px;
    margin: 0;
    width: initial;
    border-bottom: 1px solid #e3ebf6;

    .contacts--filter-search-container {
      margin-right: 0;

      .contacts--filter--search-input {
        text-indent: 0;

        &:focus::placeholder {
          color: transparent;
        }
      }
    }

    /* .MuiInputBase-root {
      padding-left: 10px;

      .MuiInputBase-input {
        padding-left: 5px;
        margin-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus::placeholder {
          color: transparent;
        }
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #d4dbe4;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #d4dbe4;
          border-width: 1px;
        }
      }
    }

    & > .MuiSvgIcon-root {
      display: none;
      position: absolute;
      content: '';
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1rem;
      color: #1976d2;
      user-select: none;

      &.show {
        display: block;
      }
    } */
  }

  button {
    &.MuiTab-root {
      text-align: left;
    }
  }

  .gAaHpi {
    .gMwwSp.right {
      flex-shrink: 0;
    }
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 8px;
  margin-bottom: 0px;
  color: red;
  font-size: 13px;
`;

export default VerticalTabContainer;

