import React, { useState, useEffect } from "react";
import "./GeneralSyncerStyle.scss";
import { configuredRequests } from "global/requests/ConfiguredRequests";
import { SyncerGeneralResponseType } from "global/requests/ResponseTypes/SyncerGeneral";
import loading_spinner from "media/images/loading-spinner.svg";

const GeneralSyncer = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [generalSyncerData, setGeneralSyncerData] = useState<SyncerGeneralResponseType>();

  const getGeneralSyncerData = async () => {
    try {
      const res = await configuredRequests.GET.getGeneralSyncer();
      if (res) {
        setGeneralSyncerData(res);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGeneralSyncerData();
  }, []);

  return isLoading ? (
    <img className="loading-general-syncer" src={loading_spinner} alt={"Loading"} />
  ) : (
    <div className="general-syncer">
      <div className="show-version">
        <div className="title-general">
          <span>Driver</span>
        </div>
        <p className="content">{generalSyncerData?.driver || "N/A"}</p>
        <div className="title-general">
          <span>Protocol</span>
        </div>
        <p className="content">{generalSyncerData?.protocol || "N/A"}</p>
        {/* <div className='title-general'>
            <span>Latest Syncer Version</span>
          </div>
          <p className='content'>{generalSyncerData?.latestSyncerVersion || 'N/A'}</p> */}
        <div className="title-general">
          <span>Latest Driver Version</span>
        </div>
        <p className="content">{generalSyncerData?.latestDriverVersion || "N/A"}</p>
        <div className="title-general">
          <span>Last Auth</span>
        </div>
        <p className="content">{generalSyncerData?.lastAuth || "N/A"}</p>
        <div className="title-general">
          <span>Last Sync</span>
        </div>
        <p className="content">{generalSyncerData?.lastSync || "N/A"}</p>
        <div className="title-general">
          <span>Syncer Location</span>
        </div>
        <p className="content">{generalSyncerData?.location || "N/A"}</p>
      </div>
    </div>
  );
};

export default GeneralSyncer;
