import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
      position: 'absolute',
      right: '-600px',
    },
  })
);

export function PaginationsView(props: TablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
        ) : (
            <FontAwesomeIcon icon={['far', 'chevron-double-left']} />
          )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-right']} />
        ) : (
            <FontAwesomeIcon icon={['far', 'chevron-left']} />
          )}
      </IconButton>
      <span className="selected-page">{page + 1}</span>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-left']} />
        ) : (
            <FontAwesomeIcon icon={['far', 'chevron-right']} />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? (
          <FontAwesomeIcon icon={['far', 'chevron-double-left']} />
        ) : (
            <FontAwesomeIcon icon={['far', 'chevron-double-right']} />
          )}
      </IconButton>
    </div>
  );
}