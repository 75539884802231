import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import styled from 'styled-components';
import loading_spinner from 'media/images/loading-spinner.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalDialog from 'components/Global/Modal';

const WrappedModalContent = styled.div`
  position: absolute;
  top: ${(props) => get(props, 'top', '50%')};
  left: ${(props) => get(props, 'left', '50%')};

  transform: translate(
    ${(props) => get(props, 'top', '-50%')},
    ${(props) => get(props, 'left', '-50%')}
  );

  box-sizing: border-box;
  /* min-width: 350px; */
  width: 550px;
  padding: 0;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);

  .modal-header,
  .modal-body,
  .modal-footer {
    width: 100%;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    margin: 0;

    p {
      margin: 0;

      color: #2c3742;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    svg {
      height: 27px;
      width: 12px;
      flex-shrink: 0;
      color: #6e84a3;

      &:hover {
        color: #2c3742;
      }
    }
  }

  .modal-body {
    height: 250px;
    padding: 20px;
    border-top: 1px solid #dee0ed;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    overflow-y: scroll;

    .modal-body__label {
      display: block;
      margin-bottom: 10px;

      color: #6e84a3;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;

      &:first-child {
        margin-top: 0;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .modal-body__text {
      color: #000;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    .search-filter__global {
      .filter-form {
        width: 500px;
        background-color: #fff;
        border: 1px solid #dee0ed;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #dee0ed;

    .btn {
      min-width: 65px;
      height: 36px;
      padding: 0 10px;
      border: none;
      border-radius: 4px;

      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;

      outline: none;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
          0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }

      &:disabled {
        cursor: default;
        box-shadow: none;
      }

      &-left {
        color: #12253f;
        background-color: #eceef5;

        &:hover {
          /* background-color: #a7a9b2; */
        }
      }

      &-right {
        margin-left: auto;
        color: #fff;
        background-color: #0074ff;

        &:hover {
          /* background-color: #303f9f; */
        }
      }
    }
  }
`;

type SubmittedDocument = {
    id: string;
    link: string;
};

interface IProps {
    isOpenModal: boolean;
    handleCloseModal: () => void;
    submissionId: string;
}

const SubmittedDocumentsModal = (props: IProps) => {
    const {
        isOpenModal,
        handleCloseModal,
        submissionId,
    } = props;

    const [documents, setDocuments] = useState<SubmittedDocument[]>();
    const [documentsLoading, setDocumentsLoading] = useState<boolean>(true);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>("");
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    useEffect(() => {
        if(shouldDelete && idToDelete != "") {
            deleteDocument(idToDelete);
        }
    }, [shouldDelete])

    useEffect(() => {
        getDocuments();
    }, [submissionId]);

    const getDocuments = async () => {
        setDocumentsLoading(true);
        let response = await configuredRequests.GET.getDocumentsForSubmission(submissionId);
        if (response.status == 200) {
            // @ts-ignore
            setDocuments(response.data.documents);
            setDocumentsLoading(false);
        } else {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Getting submission documents failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
            setDocumentsLoading(false);
        }
    }

    const confirmDeleteDocument = async (id: string) => {
        setShouldDelete(false);
        setIdToDelete(id);
        setIsOpenConfirmModal(true);
    }

    const deleteDocument = async (id: string) => {
        let response = await configuredRequests.POST.deleteSubmissionDocument(id);
        if (response.status != 200) {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Deleting document failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
        }

        setIsOpenConfirmModal(false);
        setIdToDelete("");
        setShouldDelete(false);
        getDocuments();
    }
    
    return (
        <>
            <Modal
                open={isOpenModal}
                onClose={() => handleCloseModal()}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                className='submitted-documents-modal-container'
            >
                <WrappedModalContent className={`modal-content`}>
                    <div className='modal-header'>
                        <p>Submitted Documents</p>
                        <FontAwesomeIcon
                            className='close-icon'
                            icon={['far', 'times']}
                            onClick={handleCloseModal}
                        />
                    </div>
                    <div className='modal-body'>
                        {documentsLoading ?
                            <div className="submitted-documents-modal-loading-container">
                                <span>Loading documents</span>
                                <img src={loading_spinner} alt={"Loading"} />
                            </div> :
                            <>
                                {documents?.map(doc => {
                                    let pdfName: string = decodeURIComponent(doc.link.split("/").pop()!);
                                    // @ts-ignore
                                    let shortenedPdfName = pdfName.replace(pdfName.substring(pdfName.length - pdfName.split(".").pop().length - 17, pdfName.length - pdfName.split(".").pop().length), ".");
                                    return (
                                        <div className="submitted-documents-modal-link-container">
                                            <a className="submitted-documents-modal-link" href={doc.link} target="_blank">
                                                {shortenedPdfName}
                                                <FontAwesomeIcon
                                                    icon={['fas', 'up-right-from-square']}
                                                    className='submitted-documents-modal-link-button'
                                                />
                                            </a>
                                            <span className='submitted-documents-modal-link-delete' onClick={() => confirmDeleteDocument(doc.id)}>Remove</span>
                                        </div>
                                    );
                                })}
                                
                            </>
                        }
                    </div>
                    {(
                        <div className='modal-footer'>
                            <Button variant='contained' onClick={handleCloseModal}>
                                Close
                            </Button>
                        </div>
                    )}
                </WrappedModalContent>

                
            </Modal>
                <ModalDialog
                    isOpenModal={isOpenConfirmModal}
                    title={"Remove Document?"}
                    children='Are you sure you want to remove this document? You will not be able to undo this.'
                    classMore='modal--discard-change'
                    handleCloseModal={() => setIsOpenConfirmModal(false)}
                    handleConfirmModal={() => setShouldDelete(true)}
                    textRightButton='Yes'
                />
        </>
    );
};

export default SubmittedDocumentsModal;
