import React from 'react';
import { Table, TableBody, TableHead } from '@material-ui/core';
import HeaderRow from '../HeaderRow';
import { TableContainer } from './styled-components';

interface TableWithHeaderProps {
  headCells: (string | React.ReactElement)[];
  footer?: React.ReactElement;
  children: React.ReactElement;
  dense?: boolean;
  withoutHeader?: boolean;
}

const TableWithHeader = (props: TableWithHeaderProps): React.ReactElement => {
  const { headCells, children, footer, dense = false, withoutHeader = false } = props;
  const size = dense ? 'small' : 'medium';
  return (
    <TableContainer>
      <Table size={size}>
        {!withoutHeader && (
          <TableHead>
            <HeaderRow headCells={headCells} />
          </TableHead>
        )}
        <TableBody>{children}</TableBody>
        {footer}
      </Table>
    </TableContainer>
  );
};
export default TableWithHeader;
