import React from 'react'
import {withStyles, Theme, createStyles} from '@material-ui/core/styles'

import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string
}

interface Props extends SwitchProps {
	classes: Styles
}

const SwitchHOC = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 36,
			height: 20,
			padding: 0,
			// margin: theme.spacing(1),
		},
		switchBase: {
			padding: 1,
			'&$checked': {
				transform: 'translateX(16px)',
				color: theme.palette.common.white,
				'& + $track': {
					backgroundColor: '#1DC9B7',
					opacity: 1,
					border: 'none',
				},
			},
			'&$focusVisible $thumb': {
				color: '#52d869',
				border: '6px solid #fff',
			},
		},
		thumb: {
			width: 18,
			height: 18,
		},
		track: {
			borderRadius: 20 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: '#B8C3D2',
			opacity: 1,
			transition: theme.transitions.create(['background-color', 'border']),
		},
		checked: {},
		focusVisible: {},
	})
)(({classes, ...props}: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	)
})

export default SwitchHOC
