import React from 'react';
import { useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
CustomEvents(Highcharts);

type ProgressBarDataItem = {
  templateId: string;
  name: string;
  value: number;
};

type ProgressBarProps = {
  data: ProgressBarDataItem[];
};

export const ProgressBar = (props: ProgressBarProps) => {
  let history = useHistory();

  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: null,
    },

    tooltip: {
      enabled: false,
    },

    xAxis: {
      categories: props.data.map((item) => item.name),
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: 'normal',
          cursor: 'pointer',
          color: '#1974ff',
        },
        align: 'left',
        reserveSpace: true,
        events: {
          click: function () {
            const self: any = this;
            history.push(`/Dashboard/Template/${props.data[self.pos].templateId}`);
          },
          mouseover: function () {
          },
        },
      },
      lineColor: 'transparent',
    },
    yAxis: {
      min: 0,
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        states: {
          inactive: {
            opacity: 0.8,
          },
        },
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: { fontSize: '13px' },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: props.data.map((item) => item.value),
        color: '#1974FF',
        borderRadius: 10,
        pointWidth: 15,
      },
    ],
  };

  return (
    <div className='w-100'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ProgressBar;
