//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { InputWithTopLabel } from 'components/Legwork';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ErrorMessage } from 'containers/PaperlessForms/FormBuilder/styled-components';
import { get } from 'lodash';
import toast from 'components/Global/Toast';

export interface RenameFormModalProps {
  open: boolean;
  form: any;
  handleClose: (v?: string) => void;
  updateForms: (v?: string) => void;
}
// params [formId, name]

const RenameFormModal = (props: RenameFormModalProps): React.ReactElement => {
  const { open, form, handleClose, updateForms } = props;
  const [currentName, setCurrentName] = useState<string>('');
  const [errors, setErrors] = useState<IError>({ formName: '' });
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  useEffect(() => {
    if (form?.form_name) {
      setCurrentName(form.form_name);
    }
  }, [form]);

  const renameForm = async (
    formId: string,
    formName: string
  ): Promise<void> => {
    try {
      const response = await configuredRequests.PATCH.renameForm(
        formId,
        formName
      );
      if (response.status === 200) {
        updateForms();
        setErrors({ formName: '' });
        toast.success('Your data has been saved successfully!');
      }
      handleClose();
    } catch (error) {
      if (get(error, 'response.data.message', '')) {
        setErrors({ formName: get(error, 'response.data.message', '') });
        // toast.error(get(error, 'response.data.message', ''));
      }
    }
  };

  const resetInitialValue = () => {
    setCurrentName(form?.form_name || '');
    setErrors({ formName: '' });
    handleClose();
  };

  const handleSuccess = async (): Promise<void> => {
    if (currentName.trim()) {
      if (!form.id) handleClose(); // do error stuff?
      setDisabledSubmit(true);
      await renameForm(form.id, currentName.trim());
      setDisabledSubmit(false);
    } else {
      setErrors({ formName: 'This field is required.' });
      setCurrentName('');
    }
  };

  const handleChange = (formName: string) => {
    if (!formName) {
      setErrors({ formName: 'This field is required.' });
    } else if (!formName.trim()) {
      setErrors({ formName: '' });
    } else {
      setErrors({});
    }
    setCurrentName(formName);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetInitialValue();
      }}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id='move-form-modal'
    >
      <DialogContent>
        <DialogContentText id='move-modal' className='modal-edit-form__header'>
          <span className='title-move-modal'>Rename Form</span>
          <IconButton
            aria-label='close'
            className='close-modal'
            onClick={resetInitialValue}
          >
            <CloseIcon />
          </IconButton>
        </DialogContentText>
        <InputWithTopLabel
          required={true}
          title='Name'
          value={currentName}
          reporter={handleChange}
          fullWidth
          error={false}
          type='text'
          maxLength={100}
        />
        {errors.formName && <ErrorMessage>{errors.formName}</ErrorMessage>}
        <br />
      </DialogContent>
      <DialogActions className='footer-move-modal'>
        <Button onClick={() => resetInitialValue()}>Cancel</Button>
        <Button
          onClick={handleSuccess}
          color='primary'
          autoFocus
          disabled={errors?.formName !== undefined || disabledSubmit}
          style={
            errors?.formName !== undefined
              ? { backgroundColor: 'rgba(0, 0, 0, 0.12)', boxShadow: 'none' }
              : {}
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameFormModal;
