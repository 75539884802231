const sidebarCRMSessionKey = "sidebarCRM--is_open";
const sidebarFormSessionKey = "sidebarForm--is_open";

export const setSessionStateSidebarIsOpen = {
  "CRM": (newState: boolean) => sessionStorage.setItem(sidebarCRMSessionKey, newState ? "true" : "false"),
  "Form": (newState: boolean) => sessionStorage.setItem(sidebarFormSessionKey, newState ? "true" : "false")
}

export const getSessionStateSidebarIsOpen = {
  "CRM": () => {
    if(sessionStorage.getItem(sidebarCRMSessionKey) == null) {
      sessionStorage.setItem(sidebarCRMSessionKey, "true");
      return "true";
    }
    else return sessionStorage.getItem(sidebarCRMSessionKey);
  },
  "Form": () => {
    if(sessionStorage.getItem(sidebarFormSessionKey) == null) {
      sessionStorage.setItem(sidebarFormSessionKey, "true");
      return "true";
    }
    else return sessionStorage.getItem(sidebarFormSessionKey);
  }
}