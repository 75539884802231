import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import styled from 'styled-components';
import loading_spinner from 'media/images/loading-spinner.svg';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import FilterGlobal from 'components/Global/Filter/Filter';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { PetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import { getPatientPortalUrl } from 'global/constants/url';
import { AddFormAutoFillPayload } from 'global/requests/ResponseTypes/FormBuilder';
import { SingleUserSendEmailPayload, SingleUserSendTextPayload, MessagePayloadKindsEnum } from 'global/requests/RequestTypes';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WrappedModalContent = styled.div`
  position: absolute;
  top: ${(props) => get(props, 'top', '50%')};
  left: ${(props) => get(props, 'left', '50%')};

  transform: translate(
    ${(props) => get(props, 'top', '-50%')},
    ${(props) => get(props, 'left', '-50%')}
  );

  box-sizing: border-box;
  /* min-width: 350px; */
  width: 500px;
  padding: 0;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);

  .modal-header,
  .modal-body,
  .modal-footer {
    width: 100%;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    margin: 0;

    p {
      margin: 0;

      color: #2c3742;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    svg {
      height: 27px;
      width: 12px;
      flex-shrink: 0;
      color: #6e84a3;

      &:hover {
        color: #2c3742;
      }
    }
  }

  .modal-body {
    height: 170px;
    padding: 20px;
    border-top: 1px solid #dee0ed;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .modal-body__label {
      display: block;
      margin-bottom: 10px;

      color: #6e84a3;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;

      &:first-child {
        margin-top: 0;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .modal-body__text {
      color: #000;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    .search-filter__global {
      .filter-form {
        width: 500px;
        background-color: #fff;
        border: 1px solid #dee0ed;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #dee0ed;

    .btn {
      min-width: 65px;
      height: 36px;
      padding: 0 10px;
      border: none;
      border-radius: 4px;

      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;

      outline: none;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
          0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }

      &:disabled {
        cursor: default;
        box-shadow: none;
      }

      &-left {
        color: #12253f;
        background-color: #eceef5;

        &:hover {
          /* background-color: #a7a9b2; */
        }
      }

      &-right {
        margin-left: auto;
        color: #fff;
        background-color: #0074ff;

        &:hover {
          /* background-color: #303f9f; */
        }
      }
    }
  }
`;

type OptionDropDown = {
    value: string;
    label: string;
};

interface IProps {
    isOpenModal: boolean;
    handleCloseModal: () => void;
    estimate: any;
    userInfo: UserDataResponse;
	petInfo: PetInfoResponse | undefined;
    isHandleClickOutSide?: boolean;
}

const EstimatesFormModal = (props: IProps) => {
    const {
        isOpenModal,
        estimate,
        userInfo,
		petInfo,
        isHandleClickOutSide = false,
        handleCloseModal,
    } = props;

    const [selectedForm, setSelectedForm] = useState({} as OptionDropDown);
    const [useSms, setUseSms] = useState<boolean>(true);
    const [optionsForm, setOptionsForm] = useState<OptionDropDown[]>([]);
    const [formPlaceholder, setFormPlaceholder] = useState<string>("No published forms found in the \"Estimate Forms\" folder");
    const [selectedTemplate, setSelectedTemplate] = useState({} as OptionDropDown);
    const [optionsSmsTemplate, setOptionsSmsTemplate] = useState<OptionDropDown[]>([]);
    const [optionsEmailTemplate, setOptionsEmailTemplate] = useState<OptionDropDown[]>([]);
    const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("No manual template found with a {{EstimateLink}} token");
    const [sendDisabled, setSendDisabled] = useState<boolean>(true);
    const [previewDisabled, setPreviewDisabled] = useState<boolean>(true);
    const [formLoading, setFormLoading] = useState<boolean>(true);
    const [templateLoading, setTemplateLoading] = useState<boolean>(true);
    const [autoFillId, setAutoFillId] = useState<string>("");

    const handleSelectForm = (newFormSelect: OptionDropDown) => {
        setSelectedForm(newFormSelect);
    };

    const handleSelectTemplate = (newTemplateSelect: OptionDropDown) => {
        setSelectedTemplate(newTemplateSelect);
    };

    const handlePreview = async () => {
		let autoFillIdd = autoFillId;
		if (autoFillIdd == "") {
			let autoFillIdResp = await addAutoFillInfo();
			if (autoFillIdResp == undefined) {
                toast.error(
                    <div className='notify-unsuccess d-flex'>
                        <FontAwesomeIcon
                            icon={['fal', 'times-circle']}
                            className='icon-check-status'
                        />
                        <div className='unsuccess-message'>
                            <b>Error</b>
                            <p>No auto fill id found, try again.</p>
                        </div>
                    </div>,
                    { className: 'toast-unsuccess' }
                );
				return;
			} else if (autoFillIdResp != "") {
				autoFillIdd = autoFillIdResp
				setAutoFillId(autoFillIdResp);
			}
		}

        const res = await configuredRequests.GET.currentTenantInfo();
        let urlString: string = `${getPatientPortalUrl()}/${res.micrositeName}/forms/`;
        if(!urlString.startsWith("http")) urlString = "http://" + urlString;
        setTimeout(() => {
            window.open(urlString + selectedForm.value + "/" + autoFillIdd, '_blank', 'noopener,noreferrer');
        })
    }

    const handleSend = async () => {
		setSendDisabled(true);
		let autoFillIdd = autoFillId;
		if (autoFillIdd == "") {
			let autoFillIdResp = await addAutoFillInfo();
			if (autoFillIdResp == undefined) {
                toast.error(
                    <div className='notify-unsuccess d-flex'>
                        <FontAwesomeIcon
                            icon={['fal', 'times-circle']}
                            className='icon-check-status'
                        />
                        <div className='unsuccess-message'>
                            <b>Error</b>
                            <p>No auto fill id found, please try again.</p>
                        </div>
                    </div>,
                    { className: 'toast-unsuccess' }
                );
				return;
			} else if (autoFillIdResp != "") {
				autoFillIdd = autoFillIdResp
				setAutoFillId(autoFillIdResp);
			} 
        }
		const templateResponse = await configuredRequests.GET.templateByID(selectedTemplate.value);
		if(templateResponse == undefined) {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Getting template failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
			return;
		}
        
        const res = await configuredRequests.GET.currentTenantInfo();
        let urlString: string = `${getPatientPortalUrl()}/${res.micrositeName}/forms/`;
		if (!urlString.startsWith("http")) urlString = "http://" + urlString;
        try {
            if (useSms) {
                let messageContent = templateResponse.templateBody?.replaceAll("{{EstimateLink}}", urlString + selectedForm.value + "/" + autoFillIdd);
                if (messageContent == undefined || messageContent == "") return;
                let sendName = "";
                if(getSessionUserData() == undefined) {
                    sendName = 'System Admin';
                } else {
                    // @ts-ignore
                    sendName = Object.values(getSessionUserData()?.name).join(' ');
                }
                const textPayload: SingleUserSendTextPayload = {
                    content: messageContent,
                    kind: MessagePayloadKindsEnum.sms,
                    templateId: selectedTemplate.value,
                    scheduleDate: '',
                    // @ts-ignore
                    senderName: sendName,
                    mediaUrls: [],
                    isSending: true,
                }
                const res = await configuredRequests.POST.sendSingleUserSMS(props.userInfo.userId, textPayload);
            } else if (!useSms) {
                let messageContent = templateResponse.templateBody?.replaceAll("{{EstimateLink}}", `<a href="${urlString + selectedForm.value + "/" + autoFillIdd}">Estimate Link</a>`);
                if (messageContent == undefined || messageContent == "") return;
                const emailPayload: SingleUserSendEmailPayload = {
                    content: messageContent,
                    kind: MessagePayloadKindsEnum.email,
                    subject: templateResponse.templateSubject ?? "",
                    templateId: selectedTemplate.value,
                    scheduleDate: '',
                }
                const responses = await configuredRequests.POST.sendSingleUserEmail(props.userInfo.userId, emailPayload);
            }
        } catch (err) {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>{err}</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
        }
        toast.success(
            <div className='notify-success d-flex'>
                <FontAwesomeIcon
                    icon={['fas', 'check-circle']}
                    className='icon-check-status'
                />
                <div className='success-message'>
                    <b>Successful</b>
                    <p>Your message has been sent.</p>
                </div>
            </div>,
            { className: 'toast-success' }
        );

        handleCloseModal();
    }

    const addAutoFillInfo = async () => {
		let response = await configuredRequests.GET.getFormById(selectedForm.value);
		if (response.status === 200) {
			let form = response.data as any;
			if (form == undefined) {
				setSendDisabled(true);
				setPreviewDisabled(true);
                toast.error(
                    <div className='notify-unsuccess d-flex'>
                        <FontAwesomeIcon
                            icon={['fal', 'times-circle']}
                            className='icon-check-status'
                        />
                        <div className='unsuccess-message'>
                            <b>Error</b>
                            <p>Unable to fetch form, please try again.</p>
                        </div>
                    </div>,
                    { className: 'toast-unsuccess' }
                );
				return;
			} else if (form.is_published == false) {
                toast.error(
                    <div className='notify-unsuccess d-flex'>
                        <FontAwesomeIcon
                            icon={['fal', 'times-circle']}
                            className='icon-check-status'
                        />
                        <div className='unsuccess-message'>
                            <b>Error</b>
                            <p>Form has not been published</p>
                        </div>
                    </div>,
                    { className: 'toast-unsuccess' }
                );
                setSendDisabled(true);
                setPreviewDisabled(true);
                return;
            }
			const formStructure = JSON.parse(form.object);
            const fieldId = formStructure.fields.filter((field: any) => field.type_name == "Estimate")[0].uuid;
            var createdOrDue = (estimate.createdTime == '0001-01-01T00:00:00');
			let object = {
				[fieldId]: {
					"contactFirstName": props.userInfo.name?.givenName,
					"contactLastName": props.userInfo.name?.lastName,
                    "contactEmail": props.userInfo.emailAddress == "NoEmail@thevethero.com" ? "" : props.userInfo.emailAddress,
					"petName": petInfo?.name,
					"petSex": petInfo?.sex,
                    "petBreed": petInfo?.breed,
                    "petDOB": moment(petInfo?.birthDate, '').format("MM/DD/YYYY"),
                    "estimateDate": moment(!createdOrDue ? estimate.createdTime : estimate.dueDate, '').format(!createdOrDue ? "MM/DD/YYYY h:mm a" : "MM/DD/YYYY"),
					"estimateDesc": estimate.description,
					"estimateItems": estimate.estimateItems
				}
			}
			let payload: AddFormAutoFillPayload = {
				object: JSON.stringify(object),
				tenantId: props.userInfo.tenant,
				formVersionId: form.form_version_id,
			}
			let resp = await configuredRequests.POST.addFormAutoFill(payload);
			// @ts-ignore
			if (resp.status === 200) return resp.data.autoFillId;
		} else {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Adding auto fill info failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
		}
    }

    const getForms = async () => {
        let response = await configuredRequests.GET.getFolderOfUser(props.userInfo.tenant);
        if (response.status == 200) {
            let folders = response.data;
            let folder = folders.filter((folder: any) => folder.folder_name == "Estimate Forms")[0];
            setFormLoading(false);
            if (folder == undefined) {
                setFormPlaceholder("No \"Estimate Forms\" folder found");
                return;
            } else if (folder.Forms.length == 0) {
                setFormPlaceholder("No forms found in the \"Estimate Forms\" folder");
                return;
            }
			let forms: any = folder.Forms.filter((f: any) => !f.is_deleted);
            let formOptions: OptionDropDown[] = forms.map((f: any) => { return { value: f.id, label: f.form_name }});
            setFormPlaceholder("Select a form");
			setOptionsForm(formOptions);
        } else {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Getting forms failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
        }
    }

    const getTemplates = async () => {
        try {
            const res = await configuredRequests.GET.templateByKind(
                ['sms', 'email'],
                ['manual']
            );
            if (res) {
                const listOption = res.items.filter((tem: any) => tem.templateBody.includes("{{EstimateLink}}"));
                setTemplateLoading(false);
                if(listOption.length == 0) {
                    setTemplatePlaceholder(`No manual template found with a {{EstimateLink}} token`);
                    return;
                } else {
                    let smsTemplates = listOption.filter((tem: any) => tem.templateKind == "sms").map((tem: any) => ({ label: tem.name, value: tem.templateId }));
                    let emailemplates = listOption.filter((tem: any) => tem.templateKind == "email").map((tem: any) => ({ label: tem.name, value: tem.templateId }));
                    setOptionsSmsTemplate(smsTemplates);
                    setOptionsEmailTemplate(emailemplates);
                    if (useSms && smsTemplates.length == 0) {
                        setTemplatePlaceholder(`No manual SMS template found with a {{EstimateLink}} token`);
                    } else if (!useSms && emailemplates.length == 0) {
                        setTemplatePlaceholder(`No manual Email template found with a {{EstimateLink}} token`);
                    } else {
                        setTemplatePlaceholder(`Select a ${useSms ? "SMS" : "email"} template`);
                    };
                    return;
                }
            }
        } catch (err) {
            toast.error(
                <div className='notify-unsuccess d-flex'>
                    <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-check-status'
                    />
                    <div className='unsuccess-message'>
                        <b>Error</b>
                        <p>Getting templates failed, please try again.</p>
                    </div>
                </div>,
                { className: 'toast-unsuccess' }
            );
        }
    }

    useEffect(() => {
        getForms();
        getTemplates();
    }, []);

    useEffect(() => {
        getForms();
        getTemplates();
        setAutoFillId("");
    }, [estimate]);

    useEffect(() => {
        setSendDisabled((!selectedForm.label || !selectedTemplate.label));
		setAutoFillId("");
    }, [selectedForm, selectedTemplate]);

    useEffect(() => {
        if (selectedForm.label && selectedForm.label != '') setPreviewDisabled(false);
    }, [selectedForm]);

    useEffect(() => {
        if(useSms && optionsSmsTemplate.length == 0) {
            setTemplatePlaceholder(`No manual SMS template found with a {{EstimateLink}} token`);
        } else if (!useSms && optionsEmailTemplate.length == 0) {
            setTemplatePlaceholder(`No manual Email template found with a {{EstimateLink}} token`);
        } else {
            setTemplatePlaceholder(`Select a ${useSms ? "SMS" : "email"} template`);
        }
        setSelectedTemplate({} as OptionDropDown);
    }, [useSms]);

    return (
        <Modal
            open={isOpenModal}
            onClose={() => isHandleClickOutSide && handleCloseModal()}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            className='estimates-modal-container'
        >
            <WrappedModalContent className={`modal-content`}>
                <div className='modal-header'>
                    <p>Choose template and form</p>
                    <FontAwesomeIcon
                        className='close-icon'
                        icon={['far', 'times']}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className='modal-body'>
                    {(formLoading || templateLoading) ?
                    <div className="estimates-modal-loading-container">
                        <span>Loading forms and templates</span>
                        <img src={loading_spinner} alt={"Loading"} />
                    </div> :
                    <>
                        <span>Select form to be sent</span>
                        <div className="estimates-modal-form-container">
                            <FilterGlobal
                                handleSetNewFilterValue={handleSelectForm}
                                optionsForFilter={optionsForm}
                                valueSelected={selectedForm}
                                placeholder={formPlaceholder}
                                classCustom="estimates-modal-form"
                            />
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => handlePreview()}
                                disabled={previewDisabled}
                            >
                                Preview
                            </Button>
                        </div>
                        <div className='estimates-sms-switch-container'>
                            <span style={{ fontWeight: useSms ? "normal" : "bold" }}>Email</span>
                            <AntSwitch
                                checked={useSms}
                                onChange={() => setUseSms(!useSms)}
                                label=''
                                classMore="estimates-sms-switch"
                            />
                            <span style={{ fontWeight: useSms ? "bold" : "normal" }}>SMS</span>
                        </div>
                        <span>Select message template</span>
                        <FilterGlobal
                            handleSetNewFilterValue={handleSelectTemplate}
                            optionsForFilter={useSms ? optionsSmsTemplate : optionsEmailTemplate}
                            valueSelected={selectedTemplate}
                            placeholder={templatePlaceholder}
                            classCustom="estimates-modal-template"
                        />
                    </>
                    }
                </div>
                {(
                    <div className='modal-footer'>
                        <Button variant='contained' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => handleSend()}
                            disabled={sendDisabled}
                        >
                            Send
                        </Button>
                    </div>
                )}
            </WrappedModalContent>
        </Modal>
    );
};

export default EstimatesFormModal;
