import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import getThemeProp from 'components/Legwork/utils/getThemeProp';
import { KeyboardDatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';

const Wrapper = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  font-size: 0.9rem;
  color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
  margin-left: 2px;
  margin-bottom: 10px;
`;

const StyledInput = styled(KeyboardDatePicker)`
  width: 100%;
  div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
    border-radius: 6px;
    background-color: white;
  }

  .MuiInputBase-input {
    font-size: 14px;
    max-width: 100%;
    font-family: ${({ theme }): string => `${theme.typography.fontFamily}`};
    padding: 10px 13px 8px 13px;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
`;

const StyledMainDay = styled.div`
  min-width: 37px;
  min-height: 40px;
  font-size: 0.8rem;
  padding: 1px;
  padding-left: 2px;
`;

const getColor = (props: { isSelectedDay: boolean; inMonth: boolean }): string => {
  if (props.isSelectedDay) {
    return 'white';
  }
  if (!props.inMonth) {
    return '#b8c3d2';
  }
  return '#6984a6';
};
const StyledDayIcon = styled(IconButton)`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  font-size: 0.8rem;
  background-color: ${({ isSelectedDay }: { isSelectedDay: boolean; inMonth: boolean }): string =>
    isSelectedDay ? '#4c7cfd' : 'white'};
  color: ${getColor};

  padding: 0px;
`;
export { Wrapper, StyledInputLabel, StyledInput, StyledMainDay, StyledDayIcon };
