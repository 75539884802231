import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@material-ui/core/Modal';
import Account from 'components/Global/Header/Account/Account';
import Title from 'components/Global/Title/Title';
import { StoredAPIResponseData } from 'containers/Navigation/WithNavigation';
import { getSessionJWT } from 'global/sessionStorage/SessionJWT';
import { setSessionStateNavbarIsOpen } from 'global/sessionStorage/SessionNavbarState';
import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RouterProps } from 'types/RouterT';
import CRM_MOBILE_LOGO from '../../../media/images/favicon_black@32px.png';
import CRM_LOGO from '../../../media/images/Vet-Hero-Frenchie.png';
import { useRouter } from 'hooks/useRouter';
import { Button } from '@material-ui/core';

interface HeaderProps extends RouterProps {
  logoHoverText: string;
  headerTitle: string;
  sidebarIsOpen: boolean;
  setSidebarIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storedAPIResponses?: StoredAPIResponseData;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// React type declarations aren't allowing me to add location type here
const getReturnTextFromPreviousLocationState = (location: any) => {
  if (!location || !location.state) {
    return 'Back to Previous Page';
  }
  if ((location?.state as any)?.fromContacts) {
    return 'Back to All Contacts';
  }
  if ((location?.state as any)?.fromTelemedicine) {
    return 'Back to Telemedicine';
  }
  if ((location?.state as any)?.fromDashboard) {
    return 'Back to Dashboard';
  }
  if ((location?.state as any)?.fromAppointments) {
    return 'Back to All Appointments';
  }
  return 'Back to Previous Page';
};

const Header = ({
  logoHoverText,
  headerTitle,
  routerJWT,
  setRouterJWT,
  setSidebarIsOpen,
  sidebarIsOpen,
  storedAPIResponses,
}: HeaderProps): JSX.Element => {
  const router = useRouter();
  const [modalStyle] = React.useState(getModalStyle);
  const [openPopup, setOpenPopup] = React.useState(false);
  const goBackClickRef = React.createRef<HTMLDivElement>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (storedAPIResponses?.userData && storedAPIResponses.tenantInfo) {
      // console.log('st', storedAPIResponses);
      // @ts-ignore
      window.pendo.initialize({
        visitor: {
          id: storedAPIResponses.userData?.userId,
          firstName: storedAPIResponses.userData?.name?.givenName,
          lastName: storedAPIResponses.userData?.name?.lastName,
          email: storedAPIResponses.userData?.emailAddress,
        },
        account: {
          id: storedAPIResponses.tenantInfo?.tenantId,
          name: storedAPIResponses.tenantInfo?.tenantName,
        },
      });
    }
  }, [storedAPIResponses]);

  const handleGoBackRouter = () => {
    window.localStorage.setItem('isEdited', JSON.stringify('noChange'));
    window.localStorage.setItem('isGroupEdited', 'false');
    window.localStorage.setItem('isAutomationEdited', 'false');
    sessionStorage.setItem('isFormChange', 'false');
    setOpenPopup(false);
    history.goBack();
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Discard Changes?
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          Your data will be lost. Do you wish to continue?
        </div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            {' '}
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              handleGoBackRouter();
            }}
          >
            {' '}
            Yes{' '}
          </Button>
        </div>
      </div>
    );
  };

  const getBackButtonSettingsCurrentPage = () => {
    const urlsWithBackButton = [
      '/Settings/User',
      '/SettingsAdmin/User',
      '/Contacts/View',
      '/Communications/Templates/CreateEmail/appointment',
      '/Communications/Templates/CreatePostal/appointment',
      '/Communications/Templates/CreatePostal/newcontacts',
      '/Communications/Templates/CreatePostal/manual',
      '/Communications/Templates/CreatePostal/marketing',
      '/Communications/Templates/CreatePostal/reminder',
      '/Communications/Templates/Postal/',
      '/Communications/Templates/Email/',
      '/Communications/Templates/CALL/',
      '/Communications/Templates/SMS/',
      '/Communications/Templates/CreateSMS/appointment',
      '/Communications/Templates/CreateEmail/newcontacts',
      '/Communications/Templates/CreateSMS/newcontacts',
      '/Communications/Automation/AutomationDetail/',
      '/Communications/Templates/CreateEmail/marketing',
      '/Communications/Templates/CreateSMS/marketing',
      '/Communications/Templates/CreateEmail/manual',
      '/Communications/Templates/CreateSMS/manual',
      '/Communications/Templates/CreateCall/appointment',
      '/Communications/Templates/CreateCall/marketing',
      '/Communications/Templates/CreateCall/newcontacts',
      '/Communications/Templates/CreateCall/manual',
      '/Communications/Templates/CreateSMS/reminder',
      '/Communications/Templates/CreateCall/reminder',
      '/Communications/Templates/CreateEmail/reminder',
      '/Dashboard/Template',
      '/CustomerDetail',
      '/Practices',
      '/Groups',
    ];

    const matchedURL = urlsWithBackButton
      .map((url) => {
        // this logic can be extended if you have a unique url that won't work
        //@ts-ignore

        if (location.pathname.startsWith(url)) {
          switch (url) {
            case '/SettingsAdmin/User':
            case '/Settings/User': {
              return {
                returnHandler: () => {
                  history.goBack();
                },
                titleText: 'Back to All Users',
              };
            }
            case '/Contacts/View': {
              return {
                returnHandler: () => {
                  history.goBack();
                },
                titleText: getReturnTextFromPreviousLocationState(location),
                documentTitle: 'Contacts',
              };
            }

            case '/Communications/Templates/CreateEmail/appointment':
            case '/Communications/Templates/CreatePostal/appointment':
            case '/Communications/Templates/CreatePostal/marketing':
            case '/Communications/Templates/CreatePostal/newcontacts':
            case '/Communications/Templates/CreatePostal/manual':
            case '/Communications/Templates/CreatePostal/reminder':
            case '/Communications/Templates/Postal/':
            case '/Communications/Templates/CreateCall/appointment':
            case '/Communications/Templates/CALL/':
            case '/Communications/Templates/Email/':
            case '/Communications/Templates/CreateSMS/appointment':
            case '/Communications/Templates/CreateEmail/newcontacts':
            case '/Communications/Templates/CreateSMS/newcontacts':
            case '/Communications/Templates/CreateCall/newcontacts':
            case '/Communications/Templates/CreateEmail/marketing':
            case '/Communications/Templates/CreateCall/marketing':
            case '/Communications/Templates/CreateSMS/marketing':
            case '/Communications/Templates/CreateEmail/manual':
            case '/Communications/Templates/CreateCall/manual':
            case '/Communications/Templates/CreateSMS/manual':
            case '/Communications/Templates/CreateSMS/reminder':
            case '/Communications/Templates/CreateCall/reminder':
            case '/Communications/Templates/CreateEmail/reminder':
            case '/Communications/Templates/SMS/': {
              //@ts-ignore
              return {
                returnHandler: () => {
                  const isEdited = window.localStorage.getItem('isEdited');
                  if (isEdited === '"noChange"') {
                    router.push('/Communications/Templates');
                  } else {
                    setOpenPopup(true);
                  }
                },
                titleText: 'Back to All Templates',
              };
            }

            case '/Communications/Automation/AutomationDetail/': {
              return {
                returnHandler: () => {
                  const isEdited =
                    window.localStorage.getItem('isAutomationEdited');
                  if (isEdited === 'true') {
                    setOpenPopup(true);
                  } else {
                    history.goBack();
                  }
                },
                titleText: 'Back to All Triggers',
              };
            }

            case '/Dashboard/Template': {
              return {
                returnHandler: () => {
                  router.push('/Communications');
                },
                titleText: 'Back to All Templates',
              };
            }

            case '/Groups': {
              if (headerTitle === 'Groups') {
                return null;
              }
              return {
                returnHandler: () => {
                  const isEdited = window.localStorage.getItem('isGroupEdited');
                  const isFormChange = sessionStorage.getItem('isFormChange');
                  if (isEdited === 'true' || isFormChange === 'true') {
                    setOpenPopup(true);
                  } else {
                    history.goBack();
                  }
                },
                titleText: headerTitle,
              };
            }
            case '/Practices': {
              return {
                returnHandler: () => {
                  history.goBack();
                },
                titleText: 'Back to All Practices',
              };
            }
          }
        }
        // for linter
        return undefined;
      })
      .filter((e) => e)[0];
    return matchedURL;
  };

  const handleGoBackKeyPress = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        goBackClickRef.current?.click();
        break;
      }
    }
  };

  const renderTitleTextOrBackButton = () => {
    const backButtonSettings = getBackButtonSettingsCurrentPage();
    if (backButtonSettings) {
      return (
        <div
          ref={goBackClickRef}
          className='header__button-back'
          onClick={backButtonSettings.returnHandler}
          tabIndex={0}
          onKeyPress={handleGoBackKeyPress}
        >
          <FontAwesomeIcon
            className='header__back-icon'
            icon={['fas', 'chevron-left']}
          />
          <Title
            documentTitle={backButtonSettings.documentTitle}
            titleText={backButtonSettings.titleText}
            titleClass={'header__back-text'}
          />
        </div>
      );
    }
    //else
    return <Title titleText={headerTitle} />;
  };

  const checkPermission = () => {
    const jwt = getSessionJWT();
    if (jwt) {
      const roleKey =
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
      if (jwt[roleKey] === 'System Admin') {
        return '/Groups';
      }
    }
    return '/Dashboard';
  };

  return (
    <header className='header'>
      <div className='header__logo'>
        <Link className='header__logo-lnk' to={checkPermission}>
          <img
            src={CRM_LOGO}
            className={'header__logo-img--default'}
            alt={'Fetch logo'}
            title={logoHoverText}
          />
          <img
            src={CRM_MOBILE_LOGO}
            className={'header__logo-img--mobile'}
            alt={'Fetch logo'}
          />
        </Link>

        <button
          className={'header__hamburger' + (sidebarIsOpen ? ' open' : '')}
          onClick={() => {
            setSidebarIsOpen(!sidebarIsOpen);
            setSessionStateNavbarIsOpen(!sidebarIsOpen);
          }}
        >
          <FontAwesomeIcon icon={['fal', 'bars']} />
          <FontAwesomeIcon icon={['far', 'chevron-right']} />
        </button>
      </div>

      <div className='header__utility'>
        <div className={'header__utility--left-content-container'}>
          {renderTitleTextOrBackButton()}
        </div>

        <div className={'header__utility--right-content-container'}>
          <div className={'header__utility--middle-content-container'}>
            <a target='_blank' href='https://www.thevethero.com/contact-us'>
              <Button type='button' variant='contained' color='primary'>
                Support
              </Button>
            </a>
          </div>
          <Account
            setRouterJWT={setRouterJWT}
            routerJWT={routerJWT}
            storedUserData={storedAPIResponses?.userData}
            storedTenantInfo={storedAPIResponses?.tenantInfo}
          />
        </div>
        <Modal
          open={openPopup}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          {renderPopupWarning()}
        </Modal>
      </div>
    </header>
  );
};

export default Header;
