// This component is to show a Practice's appoinments 
// This component is imported by: src\containers\Appointments\DailyAppointments.tsx

/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterGlobal from 'components/Global/Filter/Filter';
import DateFnsUtils from '@date-io/date-fns';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import JoinAppointmentIcon from 'components/Global/TeleMed/JoinAppointmentIcon';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { GetCurrentTenantAppointmentInRangeArguments } from 'global/requests/RequestTypes';
import { OptionFilterDropdownObject } from 'global/requests/ResponseTypes';
import {
  AppointmentMultipleInfoResponse,
  AppointmentSummaryEntry,
} from 'global/requests/ResponseTypes/Appointments';
import moment from 'moment';
import React, { useCallback, useEffect} from 'react';
import { isSafari } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import StatusSelect from '../Global/StatusSelect/StatusSelect';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { cloneDeep, debounce, orderBy } from 'lodash';
import { isWindows } from 'react-device-detect';
import { Grid, Modal } from '@material-ui/core';
import loading_spinner from 'media/images/loading-spinner.svg';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type AppointmentField = {
  children?: {
    before?: JSX.Element[];
    after?: JSX.Element[];
  };
  customClass?: string;
  innerText?: string;
  petName?: JSX.Element;
};

interface FilterDate {
  startDate: Date | null;
  endDate: Date | null;
}

const renderAppointmentEntry = (
  appointmentFields: AppointmentField[],
  rowIndex: number
) => {
  return (
    <tr
      key={'appointment-entry-' + rowIndex}
      className='appointment__entry-row'
    >
      {appointmentFields.map((appointmentFieldData, sectionIndex) => {
        return (
          <td
            className={
              'appointment__entry-row-division' +
              (appointmentFieldData.customClass ?? '')
            }
            key={'appointment-row-' + rowIndex + '-section-' + sectionIndex}
          >
            {appointmentFieldData.children?.before
              ? appointmentFieldData.children?.before
              : null}

            {appointmentFieldData.innerText}

            {appointmentFieldData.petName}

            {appointmentFieldData.children?.after
              ? appointmentFieldData.children?.after
              : null}
          </td>
        );
      })}
    </tr>
  );
};

interface AppointmentQuery {
  startDate: Date;
  endDate: Date;
  options?: {
    userID?: string;
    limit?: number;
    offset?: number;
    kind?: string;
    withStatus?: string;
    withName?: string;
    isWithoutEndDate?: boolean;
  };
  isSearch?: boolean;
}

interface DailyAppointmentProps {
  setDisplayTelemedModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTelemedModalAppointmentData: React.Dispatch<
    React.SetStateAction<AppointmentSummaryEntry>
  >;
  setIsAlreadyInCall(a: boolean): void;
  reload?: boolean;
  setReload?(a: boolean): void;
  settingsTimezoneData: string;
}

const DATE_ERROR = 'The start date must be before the end date';

const Appointment = (props: DailyAppointmentProps): JSX.Element => {
  const defaultDate = moment(new Date()).endOf('day').toDate();
  const defaultStartDate = moment(defaultDate).toDate();
  // .format(
  //   'YYYY-MM-DD'
  // );
  const defaultEndDate = moment(defaultDate).add(1, 'years').toDate();
  // .format('YYYY-MM-DD');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [getDailyAppointmentArguments, setDailyAppointmentArguments] =
    React.useState<AppointmentQuery>({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      isSearch: false,
      options: {
        limit: 50,
        offset: 0,
      },
    });

  const [completedTasksLookup, setCompletedTasksLookup] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [appointmentInfo, setAppointmentInfo] =
    React.useState<AppointmentMultipleInfoResponse>();
  // const [originalAppointmentInfo, setOriginalAppointmentInfo] = React.useState<
  //   AppointmentMultipleInfoResponse
  // >();
  const [appointmentSearchInput, setAppointmentSearchInput] =
    React.useState<string>('');

  const [filterValues, setFilterValues] = React.useState<string>('');
  const [filterDate, setFilterDate] = React.useState<FilterDate>({
    startDate: new Date(),
    endDate: null,
  });
  // const [
  //   appointmentFilterSelected,
  //   setAppointmentFilterSelected,
  // ] = React.useState<string>();
  const [optionsForFilter] = React.useState([
    { value: '', label: 'Upcoming Appointments' },
    { value: 'confirmed', label: 'Confirmed Appointments' },
    { value: 'unconfirmed', label: 'Unconfirmed Appointments' },
  ]);

  const searchInputRef = React.createRef<HTMLInputElement>();

  const [isMaxAppointments, setIsMaxAppointments] = React.useState(false);

  const [isWaiting, setIsWaiting] = React.useState(false); // to handle case scroll then search cause duplicate api 2 times

  const scrollToBottom = useCallback(() => {
    if (!isMaxAppointments && !isWaiting) {
      handleSubmitAppointmentGet(
        transferStringToDate({
          ...getDailyAppointmentArguments,
          options: {
            ...getDailyAppointmentArguments.options,
            offset: getDailyAppointmentArguments.options?.offset
              ? getDailyAppointmentArguments.options.offset + 1
              : 1,
            withName: appointmentSearchInput,
            withStatus: filterValues,
          },
        })
      );
      setDailyAppointmentArguments((preState: any) => ({
        ...preState,
        options: { ...preState.options, offset: preState.options.offset + 1 },
      }));
    }
  }, [
    getDailyAppointmentArguments,
    isMaxAppointments,
    isWaiting,
    filterValues,
    appointmentSearchInput,
    setDailyAppointmentArguments,
  ]);

  const scrollRef = useBottomScrollListener(scrollToBottom, undefined, 1000);

  const handleSelectFilter = (newSelectedItem: OptionFilterDropdownObject) => {
    const selectedValue = newSelectedItem.value;
    setFilterValues(selectedValue);
    handleSubmitAppointmentGet(
      transferStringToDate({
        ...getDailyAppointmentArguments,
        options: {
          ...getDailyAppointmentArguments.options,
          limit: 50,
          offset: 0,
          withStatus: selectedValue,
          withName: appointmentSearchInput,
        },
        isSearch: true,
      })
    );

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
      });
    }
    setDailyAppointmentArguments((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        offset: 0,
      },
    }));
  };

  const transferStringToDate = (
    arg: AppointmentQuery
  ): GetCurrentTenantAppointmentInRangeArguments => {
    return {
      ...arg,
      startDate: moment(arg.startDate.toISOString()).format('YYYY-MM-DD'),
      endDate: moment(arg.endDate.toISOString()).format('YYYY-MM-DD'),
    };
  };

  const getDataAfterChangeStatus = () => {
    if (!filterValues) {
      return;
    }
    handleSubmitAppointmentGet(
      transferStringToDate({
        ...getDailyAppointmentArguments,
        options: {
          ...getDailyAppointmentArguments.options,
          limit: 50,
          offset: 0,
          withStatus: filterValues,
          withName: appointmentSearchInput,
        },
        isSearch: true,
      })
    );
  };

  const searchByContactName = useCallback(
    debounce((name: any) => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
        });
      }
      if (name) {
        setDailyAppointmentArguments((preState) => ({
          ...preState,
          options: {
            ...preState.options,
            offset: 0,
            withName: name,
            withStatus: filterValues,
          },
        }));
        handleSubmitAppointmentGet(
          transferStringToDate({
            ...getDailyAppointmentArguments,
            options: {
              ...getDailyAppointmentArguments.options,
              withName: name,
              withStatus: filterValues,
              limit: 50,
              offset: 0,
            },
            isSearch: true,
          })
        );
      } else {
        setDailyAppointmentArguments((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            offset: 0,
          },
        }));
        handleSubmitAppointmentGet(
          transferStringToDate({
            ...getDailyAppointmentArguments,
            options: {
              limit: 50,
              offset: 0,
              withStatus: filterValues,
            },
            isSearch: true,
          })
        );
      }
    }, 1000),
    [filterValues, scrollRef.current, getDailyAppointmentArguments]
  );

  useEffect(() => {
    if (props.reload) {
      handleSubmitAppointmentGet(
        transferStringToDate({
          ...getDailyAppointmentArguments,
          isSearch: true,
        })
      ).then(() => {
        if (props.setReload) {
          props.setReload(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload]);

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  const getCurrentTenantAppointmentsInRange = async (
    startDate: string,
    endDate: string,
    options?: {
      userID?: string;
      limit?: number;
      offset?: number;
      kind?: string;
      withName?: string;
      isWithoutEndDate?: boolean;
    },
    isSearch?: boolean
  ) => {
    setIsWaiting(true);
    try {
      const res =
        await configuredRequests.GET.currentTenantMultipleAppointmentsInRange(
          startDate,
          endDate,
          {
            ...options,
            isWithoutEndDate: false,
          }
        );
      setIsLoading(false);
      // const statusList: string[] = [];
      // res.appointments.forEach((item) => {
      //   item.forEach((appointmentTask) => {
      //     statusList.push(appointmentTask.status);
      //   });
      // });
      // const isHaveInCall = statusList.filter((item) => item === 'inProgress');
      // props.setIsAlreadyInCall(false);
      if (isSearch) {
        setAppointmentInfo(res);
      } else if (res.count === 0) {
        setIsMaxAppointments(true);
      } else {
        setAppointmentInfo((preState: any) => {
          if (preState?.appointments) {
            const lastAppointmentPreState =
              preState?.appointments[preState?.appointments?.length - 1];
            const lastAppointmentPreStateDateTime =
              lastAppointmentPreState[0].dateTime;
            if (
              moment(lastAppointmentPreStateDateTime).isSame(
                moment(res.appointments[0][0].dateTime),
                'date'
              )
            ) {
              const appointmentsData = cloneDeep(res.appointments);
              const newPreStateAppoinments = cloneDeep(preState.appointments);
              const sameDateAppointments = appointmentsData.shift();
              const preStateAppointmentsData = newPreStateAppoinments.pop();
              const newAppointment = [
                //@ts-ignore
                ...sameDateAppointments,
                ...preStateAppointmentsData,
              ];
              return {
                ...res,
                appointments: [
                  ...newPreStateAppoinments,
                  newAppointment,
                  ...appointmentsData,
                ],
              };
            }
          }
          return {
            ...res,
            appointments: preState?.appointments
              ? [...preState.appointments, ...res.appointments]
              : [...res.appointments],
          };
        });
      }
      setIsWaiting(false);
    } catch (err) {
      setIsLoading(false);
      setIsWaiting(false);
    }
  };

  const handleSubmitAppointmentGet = async (
    query: GetCurrentTenantAppointmentInRangeArguments,
    reloading: boolean = false
  ) => {
    const { startDate, endDate, options, isSearch } = query;

    // if (startDate && startDate.length && endDate && endDate.length) {
    if (startDate && endDate) {
      if (!reloading) {
        setIsLoading(true);
      }
      await getCurrentTenantAppointmentsInRange(
        startDate,
        endDate,
        options,
        isSearch
      );
      return;
    }
    //TODO remove alert in prod
    alert('error in date range');
  };

  const checkIncall = async () => {
    try {
      const res = await configuredRequests.GET.checkIncall();
      if (res) {
        props.setIsAlreadyInCall(res.isInCall);
      }
    } catch (err) {
      props.setIsAlreadyInCall(false);
    }
  };

  React.useEffect(() => {
    handleSubmitAppointmentGet(
      transferStringToDate(getDailyAppointmentArguments)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAppointmentData = (
    appointmentInfo: AppointmentSummaryEntry[]
  ) => {
    if (appointmentInfo.length) {
      const sortedAppointments = appointmentInfo.sort(
        (a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime)
      );
      if (sortedAppointments.length) {
        // this map renders a populated table row element for each appointment object
        return sortedAppointments.map(
          (appointment: AppointmentSummaryEntry, rowIndex: number) => {
            const filteredTasks =
              appointment?.tasks?.filter(
                (taskEntry) => taskEntry.status !== 'done'
              ) ?? [];
            const sortedTask = orderBy(filteredTasks, ['name'], ['asc']);
            const contactData = appointment?.attendees?.users?.length
              ? appointment.attendees.users[0]
              : undefined;

            const contactName = contactData?.name?.length
              ? contactData.name
              : 'Missing Contact Name';

            const contactID = contactData ? contactData.id : '';

            const petData = appointment?.patients?.pets.length
              ? appointment.patients.pets[0]
              : undefined;

            const petName =
              petData && petData.name ? (
                <span>{petData.name}</span>
              ) : (
                <span className='require'>Missing Pet's Name</span>
              );

            // const formattedAppointmentDate = new Date(appointment.dateTime).toLocaleString("en-US", { timeZone: 'Asia/Bangkok' });
            const formattedTime24h = moment(appointment.dateTime).format(
              'hh:mm A'
            );

            const appointmentFieldConfigs: AppointmentField[] = [
              {
                children: {
                  before: [
                    appointment.kind === 'telemed' && appointment.teleMedKey ? (
                      <JoinAppointmentIcon
                        key={
                          'join-appointment-icon-' + appointment.appointmentId
                        }
                        checkIncall={checkIncall}
                        setModalIsOpen={(arg: boolean) => {
                          props.setTelemedModalAppointmentData({
                            appointmentId: appointment.appointmentId,
                            dateTime: appointment?.dateTime ?? '',
                            externalId: appointment.externalId,
                            status: appointment.status,
                            teleMedKey: appointment.teleMedKey,
                            attendees: appointment.attendees,
                            patients: appointment.patients,
                            kind: appointment.kind,
                            durationMinutes: appointment.durationMinutes, // timeSpan?
                            tasks: appointment.tasks,
                            appointmentUpdatedTime:
                              appointment.appointmentUpdatedTime ?? '',
                          });
                          props.setDisplayTelemedModal(arg);
                        }}
                        customClasses={{
                          button: 'telemed-icon-button--join_appointment',
                        }}
                      />
                    ) : (
                      <div
                        key={'non-telemed-appointment--' + appointment.dateTime}
                      ></div>
                    ),
                  ],
                },
              },
              {
                innerText: formattedTime24h,
              },
              {
                children: {
                  before: [
                    <Link
                      key={'contact-link-' + contactID}
                      to={{
                        pathname: contactID
                          ? '/Contacts/View/' + contactID
                          : '',
                        state: {
                          fromAppointments: true,
                        },
                      }}
                      title={`View ${contactName}'s full details in Contacts`}
                      className='lnk'
                    >
                      <HighlightText
                        text={contactName}
                        searchValue={appointmentSearchInput}
                      />
                    </Link>,
                  ],
                },
              },
              {
                petName: petName,
              },
              {
                children: {
                  before: [
                    <StatusSelect
                      key={'status_select--' + appointment.appointmentId}
                      id={appointment.appointmentId}
                      // appointmentInformation={appointment}
                      currentStatus={appointment.status}
                      refreshOnSubmitStatusConfig={{
                        handleSubmitAppointmentGet,
                        getDailyAppointmentArguments: transferStringToDate(
                          getDailyAppointmentArguments
                        ),
                      }}
                      getDataAfterChangeStatus={getDataAfterChangeStatus}
                    />,
                  ],
                },
              },
              {
                petName: appointment.providerName ? (
                  <span>{appointment.providerName}</span>
                ) : (
                  <span className='require'>Missing Provider</span>
                ),
              },
              {
                petName: appointment.appointmentTypeName ? (
                  <span>{appointment.appointmentTypeName}</span>
                ) : (
                  <span className='require'>Missing Appointment Type</span>
                ),
              },
            ];
            return renderAppointmentEntry(appointmentFieldConfigs, rowIndex);
          }
        );
      }
    }
  };

  const handleSelectDate = (
    date: Date | null,
    sub: 'startDate' | 'endDate'
  ) => {
    const formatDate = moment(date).endOf('day').toDate();

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
      });
    }
    setDailyAppointmentArguments((prev) => ({
      ...prev,
      [sub]: formatDate,
      options: {
        ...prev.options,
        offset: 0,
      },
      // ); formatDate.toDateString(),kok;
    }));
    // setFilterDate((prev) => ({ ...prev, [sub]: formatDate }));
    handleSubmitAppointmentGet(
      transferStringToDate({
        ...getDailyAppointmentArguments,
        [sub]: formatDate,
        // formatDate.toDateString(),
        options: {
          ...getDailyAppointmentArguments.options,
          limit: 50,
          offset: 0,
          withStatus: filterValues,
          withName: appointmentSearchInput,
        },
        isSearch: true,
      })
    );
  };

  const handleSearchData = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const userInput = event.currentTarget.value.trim();
    searchByContactName(userInput);
    setAppointmentSearchInput(userInput);
  };

  const showTitleDatetime = (data: AppointmentSummaryEntry[]) => {
    if (data.length && props.settingsTimezoneData) {
      const dateNeedToCompare = data[0].dateTime;
      const dateFollowTimezoneSetting = convertLocalTimeToAnotherTimeZone(
        props.settingsTimezoneData
      );
      const getFullDate = dateFollowTimezoneSetting.format('MM/DD/YY');

      if (dateFollowTimezoneSetting.isSame(moment(dateNeedToCompare), 'date')) {
        return { onlyDate: 'Today', fullDate: getFullDate };
      } else if (
        (moment(dateFollowTimezoneSetting), 'date') + 1 ===
        (moment(dateNeedToCompare), 'date')
      ) {
        return { onlyDate: 'Tomorrow', fullDate: getFullDate };
      } else {
        return {
          onlyDate: moment(dateNeedToCompare).format('dddd'),
          fullDate: moment(dateNeedToCompare).format('MM/DD/YY'),
        };
      }
    } else return { onlyDate: '', fullDate: '' };
  };

  const renderAppointmentTable = () => {
    const isShownAppointments = appointmentInfo?.appointments
      .map((item) => item.length > 0)
      .reduce((prevValue, currentValue) => {
        return prevValue || currentValue;
      }, false);

    if (!isShownAppointments) {
      return [
        <div key='appointment-data-loaded-empty'>
          <p className='no-appointments'>No results found</p>
        </div>,
      ];
    }
    const appointmentData = appointmentInfo?.appointments
      ? appointmentInfo?.appointments
          .map((data) => data.filter((items) => items.status !== 'cancelled'))
          .filter((appoinements) => appoinements.length)
      : [];
    return [
      appointmentData.map(
        (appointments: AppointmentSummaryEntry[], index: number) => {
          return (
            <div
              className='appointments-table-element'
              key={'telemed-table' + index}
            >
              <div className='telemed-container-table'>
                <p className='telemed__table-title'>
                  <span className='telemed__table-title-date'>
                    {' '}
                    {showTitleDatetime(appointments).onlyDate}{' '}
                  </span>
                  <span className='telemed__table-full-date'>
                    {' '}
                    {showTitleDatetime(appointments).fullDate}{' '}
                  </span>
                </p>
                {appointments.length !== 0 && (
                  <table className='table daily-appointment__table'>
                    <tbody>
                      <tr className='telemed__table-header-row'>
                        <th className='appointments__table-header telemed'></th>
                        <th className='appointments__table-header time'>
                          Time
                        </th>
                        <th className='appointments__table-header contact'>
                          Contact
                        </th>
                        <th className='appointments__table-header pet'>Pet</th>
                        <th className='appointments__table-header confirmation-status'>
                          Status
                        </th>
                        <th className='appointments__table-header task'>
                          Provider
                        </th>
                        <th className='appointments__table-header actions'>
                          Appointment Type
                        </th>
                      </tr>
                      {renderAppointmentData(appointments)}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          );
        }
      ),
    ];
  };

  const handleFocusSearchInput = () => {
    searchInputRef.current?.focus();
  };

  return (
    <div className='appointments-table'>
      <div className='daily-appointment__filter' data-testid='Telemed-define'>
        <form
          className={
            isSafari ? 'search-safari' : 'search-form search-filter__global'
          }
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'search']}
            onClick={handleFocusSearchInput}
          />
          <input
            ref={searchInputRef}
            type='search'
            placeholder='Search by contact name'
            className='search-form__input'
            onChange={handleSearchData}
          />
        </form>
        <FilterGlobal
          handleSetNewFilterValue={handleSelectFilter}
          optionsForFilter={optionsForFilter}
        />

        <div className='datepicker-send-filter'>
          <div className='datepicker-filter-text'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container className='grid-custom'>
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className='icon-calendar__communication-end-date filter-calendar-icon'
                />
                <DatePicker
                  format='MM/dd/yy'
                  value={getDailyAppointmentArguments.startDate}
                  minDate={defaultStartDate}
                  maxDate={getDailyAppointmentArguments.endDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    handleSelectDate(date, 'startDate');
                  }}
                  // disableToolbar
                  className='date-picker-custom'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>

          <span className='line'></span>

          <div className='datepicker-filter-text'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container className='grid-custom'>
                <FontAwesomeIcon
                  icon={['fas', 'calendar-alt']}
                  className='icon-calendar__communication-end-date filter-calendar-icon'
                />

                <DatePicker
                  format='MM/dd/yy'
                  value={getDailyAppointmentArguments.endDate}
                  minDate={getDailyAppointmentArguments.startDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    handleSelectDate(date, 'endDate');
                  }}
                  // disableToolbar
                  className='date-picker-custom'
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>

      <div
        className='appointment-div'
        // style={{
        //   overflow: 'auto',
        //   maxHeight: '800px'
        // }}
        //@ts-ignore
        ref={scrollRef}
      >
        {renderAppointmentTable()}
      </div>

      <Modal
        open={isLoading}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      </Modal>
    </div>
  );
};

export default Appointment;
