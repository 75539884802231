import ApiService from '../clientApi';
import { IMonthlyAggregateData, IMonthlyInsightsData } from './types';
export const getTimezone = (): string | undefined => {
  let timezone;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Unable to determine timezone:', e);
  }

  return timezone;
};

const Api = ApiService();

const getThisMonthsInsights = async (): Promise<IMonthlyAggregateData> => {
  const result = await Api.rpc('', {
    method: 'insight.getMonthInsights',
    params: [new Date()],
  });
  return result.p[1];
};

interface IGetGraphDataParams {
  startDate: Date | string;
  endDate: Date | string;
}

const getGraphData = async (params: IGetGraphDataParams): Promise<IMonthlyInsightsData> => {
  const { startDate, endDate } = params;
  const timezone = getTimezone();
  const result = await Api.rpc('', {
    method: 'insight.getMonthlyInsights',
    params: [startDate, endDate, timezone],
  });
  return result.p[1];
};

const getCurrentYearInsights = async (params: string[]): Promise<any> => {
  const result = await Api.rpc('', { method: 'insight.getCurrentYearInsights', params });

  return {
    totalContactCount: result.p[1].total_contact_count.value, // number
    ages: result.p[1].ages.value, // [[string, number]]
    zipcodes: result.p[1].zipcodes.value, // [[string, number]]
    emails: [
      Math.floor(
        (result.p[1].emails.value[0][1] * 100) /
          (result.p[1].emails.value[1][1] + result.p[1].emails.value[0][1]),
      ),
      result.p[1].emails.goal,
    ],
    numbers: [
      Math.floor(
        (result.p[1].numbers.value[0][1] * 100) /
          (result.p[1].numbers.value[1][1] + result.p[1].numbers.value[0][1]),
      ),
      result.p[1].numbers.goal,
    ],
    referrals: [
      Math.floor(
        (result.p[1].referrals.value[0][1] * 100) /
          (result.p[1].referrals.value[1][1] + result.p[1].referrals.value[0][1]),
      ),
      result.p[1].referrals.goal,
    ],
  };
};

export default { getThisMonthsInsights, getGraphData, getCurrentYearInsights };
