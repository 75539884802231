import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';

export default {
  success: (message: React.ReactElement | string, isHiddenTitle?: boolean) => {
    toast.success(
      <div className='notify-success d-flex'>
        <FontAwesomeIcon
          icon={['fas', 'check-circle']}
          className='icon-check-status'
        />
        <div className='success-message'>
          {isHiddenTitle ? null : <b>Successfully</b>}
          <p>{message}</p>
        </div>
      </div>,
      { className: 'toast-success' }
    );
  },
  error: (message: React.ReactElement | string, isHiddenTitle?: boolean) => {
    toast.error(
      <div className='notify-unsuccess d-flex'>
        <FontAwesomeIcon
          icon={['fal', 'times-circle']}
          className='icon-check-status'
        />
        <div className='unsuccess-message'>
          {isHiddenTitle ? null : <b>Error</b>}
          <p>{message}</p>
        </div>
      </div>,
      { className: 'toast-unsuccess' }
    );
  },
};
