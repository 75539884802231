import React from 'react';

import { StyledLink, Container } from './styled-components';

interface CommonLinkProps {
  text: string;
  url?: string;
  preventDefault?: boolean;
  onClick?: () => void;
}

const CommonLink = (props: CommonLinkProps): React.ReactElement => {
  const { url = '/', text, preventDefault = false, onClick = (): void => {} } = props;
  const handleClick = (event: React.MouseEvent): void => {
    if (preventDefault) {
      event.preventDefault();
    }
    onClick();
  };
  return (
    <Container>
      <StyledLink href={url} onClick={handleClick}>
        {text}
      </StyledLink>
    </Container>
  );
};

export default CommonLink;
