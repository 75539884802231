import React from 'react';
import SmallTitle from 'components/Legwork/SmallTitle';
import CommonLink from 'components/Legwork/CommonLink';
import { MainContainer } from './styled-components';

interface TitleAndLinkProps {
  titleText: string;
  linkText?: string;
  url?: string;
  direction: 'row' | 'column';
}

const TitleAndLink = (props: TitleAndLinkProps): React.ReactElement => {
  const { titleText, linkText, url, direction } = props;
  return (
    <MainContainer direction={direction}>
      <SmallTitle text={titleText} inline /> {linkText && <CommonLink text={linkText} url={url} />}
    </MainContainer>
  );
};

TitleAndLink.defaultProps = {
  direction: 'row',
};

export default TitleAndLink;
