import React from 'react';
import { Prompt } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import FilterGlobal from 'components/Global/Filter/Filter';
import {
  OptionFilterDropdownObject,
  PersonalizationTokensResponse,
  TemplateResponse,
} from 'global/requests/ResponseTypes';
import { dataTemplate } from 'global/requests/dataUserSettingResponseError';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import WarningPopup from '../WarningPopup';
import moment from 'moment';
import {
  SingleUserSendEmailPayload,
  MessagePayloadKindsEnum,
} from 'global/requests/RequestTypes';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { useLocation } from 'react-router-dom';
import EditorBox from 'components/Communications/EditorBox';
import toast from 'components/Global/Toast';
import { userInfo } from 'os';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import {
  convertDateToTimestamp,
  getTimeNowForPractice,
} from 'utils/convertData';
import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import { getActionType } from './SinglePostalEditor';

const initPayload: SingleUserSendEmailPayload = {
  content: '',
  kind: MessagePayloadKindsEnum.email,
  subject: '',
  templateId: '',
  scheduleDate: '',
};

interface EmailEditorProps {
  userInfo: UserDataResponse | undefined;
  receiverUserIDs: string[];
  checkNewEmail(a: boolean): void;
  searchValue?: string;
  filterValue?: string;
  isSentToAllContact?: boolean;
  closeModal?: (type: string) => void;
  useCustomSubmit?: {
    innerSubmitRefObject: React.RefObject<HTMLButtonElement>;
    setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  };
  subscription?: boolean;
}

const messageBodyCharacterLimit = 999999;

export const optionsDelivery = [
  {
    value: '0',
    label: 'Send Now',
  },
  // {
  //   value: '1',
  //   label: 'Send Later',
  // },
];

const SingleEmailEditor = (props: EmailEditorProps): JSX.Element => {
  const { receiverUserIDs, subscription } = props;

  const location = useLocation();

  const [payloadToSendEmail, setPayloadToSendEmail] =
    React.useState<SingleUserSendEmailPayload>(initPayload);
  const [optionsTemplate, setOptionsTemplate] = React.useState<
    OptionFilterDropdownObject[]
  >([]);
  const [emailTemplate, setEmailTemplate] =
    React.useState<TemplateResponse>(dataTemplate);
  const [errorEmailSubject, setErrorEmailSubject] =
    React.useState<boolean>(false);
  const [errorCharacterLimitReached, setErrorCharacterLimitReached] =
    React.useState<boolean>(false);
  const [errorEmptyEmailBody, setErrorEmptyEmailBody] =
    React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const [dateLater, setDateLater] = React.useState<Date | null>(null);
  const [deliveryDate, setDeliveryDate] =
    React.useState<OptionFilterDropdownObject>(optionsDelivery[0]);
  const [editorHasUnsavedChanges, setEditorHasUnsavedChanges] =
    React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    label: 'Blank Email Template',
    value: '',
  });
  // const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const timeLocalRef = React.useRef(new Date());

  const getEmailTemplates = async () => {
    try {
      const templateResponse = await configuredRequests.GET.templateByKind([
        TemplateKindsEnum.email,
      ]);
      const blankTemplate = {
        categoryKind: 'email',
        name: 'Blank Email Template',
        targetUserTypeIds: [
          '6008b81b-b018-4981-a132-2426b6bf5481',
          'da5094da-3141-4db4-b501-10d8914436fd',
        ],
        templateBody: '',
        templateId: '',
        templateKind: '',
        templateSubject: '',
      };
      setEmailTemplate({
        ...templateResponse,
        items: [blankTemplate, ...templateResponse.items],
      });
      const dataSelected: Array<OptionFilterDropdownObject> = [
        { label: 'Blank Email Template', value: '' },
      ];
      templateResponse.items.forEach((item) => {
        dataSelected.push({
          label: item.name ?? '',
          value: item.templateId ?? '',
        });
      });
      setOptionsTemplate(dataSelected);
    } catch (err) {
      console.log('error getting email template', err);
    }
  };

  const getDateTimeNow = async () => {
    try {
      const res = await configuredRequests.GET.getTimeNow();

      if (res) {
        setMinDate(new Date(res.toString()));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // This function must do checks before sending the request to ensure payload is valid
  const sendEmailData = async () => {
    try {
      if (!payloadToSendEmail || isDisabled) {
        return;
      }
      if (!payloadToSendEmail.subject.length) {
        setErrorEmailSubject(true);
      }
      if (!payloadToSendEmail.content.length) {
        setErrorEmptyEmailBody(true);
      }
      if (payloadToSendEmail.content.length > messageBodyCharacterLimit) {
        setErrorCharacterLimitReached(true);
      }
      if (
        !payloadToSendEmail.subject.length ||
        !payloadToSendEmail.content.length ||
        payloadToSendEmail.content.length > messageBodyCharacterLimit
      ) {
        alert('Invalid Email content');
        return;
      }
      setIsDisabled(true);
      toast.success(
        <b>{`Sending to ${
          location?.pathname.includes('/User/') ? 'user' : 'contact'
        }`}</b>,
        true
      );
      const responses = await configuredRequests.POST.sendSingleUserEmail(
        receiverUserIDs[0],
        {
          ...payloadToSendEmail,
          // actionType: getActionType(deliveryDate),
        }
      );
      if (responses) {
        props.checkNewEmail(true);
        // setIsDisabled(true);
        setPayloadToSendEmail(initPayload);

        props.useCustomSubmit?.setIsSubmitEnabled(false);
        setSelectedTemplate({ label: 'Blank Email Template', value: '' });
        setDeliveryDate(optionsDelivery[0]);
      }
      props.closeModal && props.closeModal('');
    } catch (err) {
      // toast.error(Sent failed! Please try again.);
    }
  };

  const handleSelectDeliveryDate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    // if (['0', '1'].includes(deliveryDate) && newSelectedItem.value === '2') {
    if (deliveryDate.value === '0' && newSelectedItem.value === '1') {
      if (minDate) {
        const newMinDate = getTimeNowForPractice(timeLocalRef.current, minDate);
        setMinDate(newMinDate);
        setDateLater(newMinDate);
        setPayloadToSendEmail((prev) => ({
          ...prev,
          sendLaterDate: convertDateToTimestamp(newMinDate),
        }));
      } else {
        setMinDate(new Date());
        setDateLater(new Date());
        setPayloadToSendEmail((prev) => ({
          ...prev,
          sendLaterDate: convertDateToTimestamp(new Date()),
        }));
        timeLocalRef.current = new Date();
      }
    }
    // if (newSelectedItem.value === '0' || newSelectedItem.value === '1') {
    if (newSelectedItem.value === '0') {
      setPayloadToSendEmail((prev) => ({
        ...prev,
        sendLaterDate: '',
      }));
      // if (newSelectedItem.value === '1') {
      //   setOpenWarningPopup(true);
      // }
    }
    setDeliveryDate(newSelectedItem);
  };

  const { content, subject } = payloadToSendEmail;

  React.useEffect(() => {
    if (
      !subject.length ||
      errorEmptyEmailBody ||
      content.length > messageBodyCharacterLimit ||
      !content
    ) {
      setIsDisabled(true);
      return;
    }
    if (!errorEmptyEmailBody && subject.length) {
      setIsDisabled(false);
    }
  }, [subject, content]);

  const handleSelectTemplate = (
    newSelectedItem: OptionFilterDropdownObject
  ) => {
    setSelectedTemplate(newSelectedItem);
    setErrorEmailSubject(false);
    setErrorEmptyEmailBody(false);

    setPayloadToSendEmail((prev) => ({
      ...prev,
      templateId: newSelectedItem.value,
    }));
    if (!newSelectedItem.value) {
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    emailTemplate.items.forEach((item) => {
      if (item.templateId === newSelectedItem.value) {
        setIsDisabled(false);
        setPayloadToSendEmail((prev) => ({
          ...prev,
          subject: item.templateSubject ? item.templateSubject : '',
          content: item.templateBody ? item.templateBody : '',
        }));
      }
    });
  };

  const handleChangeDataEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;
    if (targetValue.length) {
      setEditorHasUnsavedChanges(true);
      setErrorEmailSubject(false);
      if (
        payloadToSendEmail.content.length &&
        !errorEmptyEmailBody &&
        !errorCharacterLimitReached
      ) {
        props.useCustomSubmit?.setIsSubmitEnabled(true);
      }
    } else {
      setEditorHasUnsavedChanges(false);
      setErrorEmailSubject(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    }
    setPayloadToSendEmail((prev) => ({ ...prev, subject: targetValue }));
  };

  const handleTrimData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (name === 'emailSubject' && event.target.value.length) {
      setErrorEmailSubject(false);
    } else {
      setErrorEmailSubject(true);
    }
    const dataTrim = event.target.value.trim();
    setPayloadToSendEmail((prev) => ({ ...prev, subject: dataTrim }));
  };

  const handleSelectedDate = (date: Date | null) => {
    if (date) {
      setDateLater(date);
      setPayloadToSendEmail((prev) => ({
        ...prev,
        //@ts-ignore
        sendLaterDate: convertDateToTimestamp(date),
      }));
    }
  };

  const warnUserUnsavedChanges = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return true;
  };

  // add beforeunload listener when editorHasUnsavedChanges === true
  React.useEffect(() => {
    if (editorHasUnsavedChanges) {
      window.addEventListener('beforeunload', warnUserUnsavedChanges);
      return () => {
        window.removeEventListener('beforeunload', warnUserUnsavedChanges);
      };
    }
    window.removeEventListener('beforeunload', warnUserUnsavedChanges);
  }, [editorHasUnsavedChanges]);

  React.useEffect(() => {
    Promise.all([getEmailTemplates(), getTokens(), getDateTimeNow()]);
  }, []);

  // This callback is only called after TinyMCE updates the DOM element's content.
  // There is no way to stop the content update inside this callback.
  // Instead we will have to display the error text "Character limit reached"
  // We also need to check the length in the submit function
  const handleOnChangeEditor = (newContent: string) => {
    if (newContent.length > messageBodyCharacterLimit) {
      setErrorCharacterLimitReached(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    } else {
      setErrorCharacterLimitReached(false);
    }
    const b = '<body>';
    const be = '</body>';
    const messageBody = newContent.substring(
      newContent.indexOf(b) + b.length,
      newContent.indexOf(be) - 1
    );

    if (!messageBody.trim() && payloadToSendEmail.content) {
      setErrorEmptyEmailBody(true);
      setIsDisabled(true);
      props.useCustomSubmit?.setIsSubmitEnabled(false);
    } else {
      setErrorEmptyEmailBody(false);
      if (payloadToSendEmail.subject.length) {
        props.useCustomSubmit?.setIsSubmitEnabled(true);
      }
    }
    setPayloadToSendEmail((prev) => ({ ...prev, content: newContent }));
  };

  const handleEmptyContent = () => {
    setErrorEmptyEmailBody(true);
    setIsDisabled(true);
    props.useCustomSubmit?.setIsSubmitEnabled(false);
  };

  return (
    <div className='contact-email-editor'>
      <Prompt
        when={editorHasUnsavedChanges}
        message={'WARNING! You may have unsaved changes'}
      />

      <p>Template</p>
      <FilterGlobal
        handleSetNewFilterValue={handleSelectTemplate}
        optionsForFilter={optionsTemplate}
        valueSelected={selectedTemplate}
      />

      <p>
        Email Subject <b className='require'>*</b>
      </p>
      <input
        className='input-text'
        name='emailSubject'
        value={payloadToSendEmail.subject}
        onChange={handleChangeDataEmailSubject}
        onBlur={handleTrimData}
        maxLength={50}
      />
      {errorEmailSubject && (
        <p className='error-msg'> This field is required. </p>
      )}

      <p>
        Message Body <b className='require'>*</b>
      </p>
      <EditorBox
        // idTextArea="editor-content-email-single"
        value={content}
        handleChangeEditor={handleOnChangeEditor}
        handleEmptyContent={handleEmptyContent}
        token={tokenData}
        screen={'manual'}
      />
      {errorEmptyEmailBody && (
        <p className='error-msg'> This field is required. </p>
      )}
      {errorCharacterLimitReached && (
        <p className='error-msg'>
          {' '}
          The character limit has been reached ({
            messageBodyCharacterLimit
          }){' '}
        </p>
      )}

      <div className='delivery-send-mail'>
        <p>Delivery Date</p>
        <div className='d-flex delivery-date-dropdown'>
          <div className='delivery-date'>
            <FilterGlobal
              handleSetNewFilterValue={handleSelectDeliveryDate}
              optionsForFilter={optionsDelivery}
            />
          </div>

          {deliveryDate.value === '1' ? (
            <div className='datepicker-email-editor'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className='grid-custom'>
                  <FontAwesomeIcon
                    icon={['fas', 'calendar-alt']}
                    className='icon-calendar__communication-end-date'
                  />
                  <DateTimePicker
                    value={dateLater}
                    minDate={minDate}
                    onChange={handleSelectedDate}
                    className='date-picker-custom'
                    format='MMMM do hh:mm a'
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          ) : (
            ''
          )}

          <Button
            ref={props.useCustomSubmit?.innerSubmitRefObject}
            variant='contained'
            color='primary'
            className='btn-send-email'
            onClick={sendEmailData}
            disabled={isDisabled}
            style={{
              visibility:
                props.useCustomSubmit !== undefined ? 'hidden' : 'visible',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'paper-plane']} />
            Send Email
          </Button>
        </div>
      </div>

      {/* <WarningPopup open={openWarningPopup} onClose={setOpenWarningPopup} /> */}
    </div>
  );
};

export default SingleEmailEditor;
