import React from 'react';
import { getSessionJWTString } from 'global/sessionStorage/SessionJWT';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import moment from 'moment';
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import { AppointmentSummaryEntry } from 'global/requests/ResponseTypes/Appointments';
import { getAppBaseUrl } from 'global/constants/url';
import { Button } from '@material-ui/core';

type TelemedJoinButtonProps = {
  teleMedKey: string;
  externalId: string;
  dateTime: string;
  appointmentInformation: AppointmentSummaryEntry;
  isFromRightModal?: boolean;
  onOpenNewWindow?(url?: string): void;
  isAlreadyInCall?: boolean;
  setOpenModalError?(a: boolean): void;
  setReload?(a: boolean): void;
  setCurrentAppointment?(a: any): void;
  settingsTimezoneData: string;
};

const TelemedJoinButton = (props: TelemedJoinButtonProps) => {
  const { teleMedKey, appointmentInformation, settingsTimezoneData } = props;

  const [isJoinable] = React.useState(false);
  const redefineClassName = props.isFromRightModal
    ? 'start-appointment'
    : 'telemed-button--join_appointment';
  const currentDateFollowTimezoneSetting =
    convertLocalTimeToAnotherTimeZone(settingsTimezoneData).format(
      'YYYY-MM-DD'
    );
  const appointmentDateTime = moment(appointmentInformation.dateTime).format(
    'YYYY-MM-DD'
  );

  const showTextButton = () => {
    const status = appointmentInformation.status;
    if (status === 'inProgress') {
      return 'In Progress';
    }
    if (
      status === 'complete' &&
      Date.parse(currentDateFollowTimezoneSetting) <=
        Date.parse(appointmentDateTime)
    ) {
      return 'Restart Appointment';
    }
    return 'Start Appointment';
  };

  const sendUpdateAppointmentRequest = async (
    appointmentInfo: AppointmentSummaryEntry
  ) => {
    try {
      const res = await configuredRequests.PATCH.updateAppointmentStatus(
        appointmentInfo.appointmentId,
        'inProgress'
      );
      if (res) {
        // console.log('response from successful request to updateAppointment ', res);
      }
    } catch (err) {
      console.log('error updating appointment');
    }
  };

  const startCall = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (appointmentInformation.status === 'inProgress') return null;
    if (props.setCurrentAppointment) {
      props.setCurrentAppointment(props.appointmentInformation);
    }
    // if (props.setReload) {
    //   props.setReload(true)
    // }
    // if (props.isAlreadyInCall) {
    //   if (props.setOpenModalError) {
    //     props.setOpenModalError(true)
    //   }
    //   return
    // }

    if (props.onOpenNewWindow) {
      if (appointmentInformation.status === 'inProgress') return null;
      const baseURL = `${getAppBaseUrl()}/Telemedicine/videocall`;
      const sessionJWTString = getSessionJWTString() ?? undefined;
      const sessionUserData = getSessionUserData();
      sendUpdateAppointmentRequest(appointmentInformation);
      const url =
        baseURL +
        '?appointmentId=' +
        appointmentInformation.appointmentId +
        '&teleMedKey=' +
        teleMedKey +
        '&accessToken=' +
        sessionJWTString +
        '&doctorName=' +
        sessionUserData?.name?.givenName +
        ' ' +
        sessionUserData?.name?.lastName;
      props.onOpenNewWindow(url);
    }
  };

  return (
    <Button
      variant='contained'
      color='primary'
      className={redefineClassName + (isJoinable ? ' joinable ' : '')}
      onClick={startCall}
      title={
        isJoinable
          ? 'Start online Appointment'
          : 'Join up to 30 minutes before your Appointment begins'
      }
    >
      {showTextButton()}
    </Button>
  );
};

export default TelemedJoinButton;
