exports.generateHash = function(args) {
  var hash = args.reduce(function(hash, arg) {
    return hash += JSON.stringify(arg);
  }, "");

  return hash.length ? hash : '__noArgs';
};

exports.promise = function memoize(fn, delay) {
  var self = function () {
    var args = Array.prototype.slice.call(arguments),
        hash = exports.generateHash(args);

    fn._memoize || (fn._memoize = { });

    if (!(hash in fn._memoize)) {
      fn._memoize[hash] = fn.apply(this, args);

      if (typeof delay === 'number') {
        fn._memoize[hash].then(function() {
          setTimeout(function() {
            delete fn._memoize[hash];
          }, delay);
        });
      }
    }

    return fn._memoize[hash];
  };

  self._clearCache = function() {
    if (!fn._memoize) {
      return;
    }

    Object.keys(fn._memoize).forEach(function(hash) {
      delete fn._memoize[hash];
    });
  };


  return self;
};

exports.callback = function(fn, delay) {
  if (typeof fn._memoize === 'undefined') {
    fn._memoize = (function() {
      var self = this;

      // map of hash values
      self.cache = { };
      self.cacheTimeoutIds = { };

      // map of pending hashes
      self.pending = { };

      // map of callbacks awaiting for the first
      // call for a given hash to complete
      self.awaitingCallback = { };

      self.setHashValue = function(hash, value) {
        self.cache[hash] = value;

        if (typeof delay === 'number') {
          self.cacheTimeoutIds[hash] = setTimeout(function() {
            delete self.cache[hash];
          }, delay);
        }

        // iterate over all awaiting callbacks notifying them
        // of the value from the first non cached memoize call
        if (Array.isArray(self.awaitingCallback[hash])) {
          self.awaitingCallback[hash].forEach(function(callback) {
            callback(value);
          });

          delete self.awaitingCallback[hash];
        }
      };

      self.clearHashValue = function(hash) {
        delete self.cache[hash];

        if (self.cacheTimeoutIds[hash]) {
          clearTimeout(self.cacheTimeoutIds[hash]);
        }
      };

      self.awaitCallback = function(hash, callback) {
        if (!Array.isArray(self.awaitingCallback[hash])) {
          self.awaitingCallback[hash] = [ ];
        }

        self.awaitingCallback[hash].push(callback);
      };

      return self;
    }).call({ });
  }

  return function() {
    var args = Array.prototype.slice.call(arguments),
        callback = args.pop(),
        hash = exports.generateHash(args);

    if (typeof callback !== 'function') {
      throw new Error('Last argument to memoize.callback must be a function');
    }

    var memoizeCallContext = {
      memoize: fn._memoize,

      clearCachedValue: function() {
        fn._memoize.clearHashValue(hash);
      },
    };

    var handleCallback = function() {
      callback.apply(memoizeCallContext, fn._memoize.cache[hash]);
    };

    if (fn._memoize.cache[hash]) {
      handleCallback();
    } else {
      if (fn._memoize.pending[hash]) {
        return fn._memoize.awaitCallback(hash, handleCallback);
      }

      fn._memoize.pending[hash] = true;

      fn.apply(memoizeCallContext, args.concat(function() {
        fn._memoize.pending[hash] = false;
        fn._memoize.setHashValue(hash, Array.prototype.slice.call(arguments));
        handleCallback();
      }));
    }
  };
};
