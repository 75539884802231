import * as t from 'io-ts';
import { ListDate } from './GetHoursOfOperation';

export const AppointmentTypeSettingRequestType = t.type({
  appointmentType: t.string,
  appointmentDuration: t.number,
  isBookingActive: t.boolean,
  isRoundRobinBookingOnly: t.boolean,
});

export type AppointmentTypeSettingRequestType = t.TypeOf<
  typeof AppointmentTypeSettingRequestType
>;

const OptionalAppointmetType = t.partial({
  externalId: t.string,
  limitAvailabilityValue: t.array(ListDate),
});

export const RequireAppointmentTypeSetting = t.type({
  id: t.string,
  appointmentType: t.string,
  appointmentDuration: t.number,
  isBookingActive: t.boolean,
  isRoundRobinBookingOnly: t.boolean,
  isLimitAvailability: t.boolean,
});

export const AppointmentTypeSetting = t.intersection([
  RequireAppointmentTypeSetting,
  OptionalAppointmetType,
]);

export const AppointmentTypeResponse = t.type({
  id: t.string,
  typeName: t.string,
  appointmentDuration: t.number,
  externalId: t.string,
});

export type AppointmentTypeResponse = t.TypeOf<typeof AppointmentTypeResponse>;

export type AppointmentTypeSetting = t.TypeOf<typeof AppointmentTypeSetting>;

export const AppointmentTypeSettingResponseType = t.array(
  AppointmentTypeSetting
);

export type AppointmentTypeSettingResponseType = t.TypeOf<
  typeof AppointmentTypeSettingResponseType
>;
