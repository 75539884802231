import * as t from 'io-ts';

export const ServiceLogs = t.partial({
  serviceLogId: t.string,
  locationCode: t.string,
  locationName: t.string,
  tenantId: t.string,
  driver: t.string,
  logger: t.string,
  level: t.string,
  message: t.string,
  createdDate: t.string,
  isRead: t.boolean,
  isDelete: t.boolean,
});
export type ServiceLogs = t.TypeOf<typeof ServiceLogs>;

export const ServiceLogsResponseType = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  serviceLogs: t.array(ServiceLogs),
  from: t.string,
  to: t.string,
});

export type ServiceLogsResponseType = t.TypeOf<typeof ServiceLogsResponseType>;

export const SyncerLogsCollection = t.type({
  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  syncerErrorLogs: t.array(ServiceLogs),
  from: t.string,
  to: t.string,
});

export type SyncerLogsCollectionType = t.TypeOf<typeof SyncerLogsCollection>;
