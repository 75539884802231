import React from 'react';
import { Typography } from '@material-ui/core';

import { StatusContainer, ColoredIcon } from './styled-components';

export interface ConfirmationBadgeProps {
  label: string;
}

const ConfirmationBadge = ({ label }: ConfirmationBadgeProps): React.ReactElement => {
  return (
    <StatusContainer>
      <ColoredIcon />
      <Typography variant='body2'>{label}</Typography>
    </StatusContainer>
  );
};

export default ConfirmationBadge;
