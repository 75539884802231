import * as t from 'io-ts';

const RequiredProduct = t.type({
  productId: t.string,
  isPackageProduct: t.boolean,
  productName: t.string,
  invoiceName: t.string,
});

const NullableProduct = t.partial({
  isMonthlyFeeTaxability: t.boolean,
  isSetupFeeTaxability: t.boolean,
  defaultMonthlyFee: t.number,
  taxCode: t.string,
  isMonthlyFeeNegotiable: t.boolean,
  minimumMonthlyFee: t.number,
  maximumMonthlyFee: t.number,
  defaultSetupFee: t.number,
  isSetupFee: t.boolean,
});

export const ProductEntry = t.intersection([RequiredProduct, NullableProduct]);

export type ProductResponseType = t.TypeOf<typeof ProductEntry>;

export const ProductCollection = t.type({
  count: t.number,
  totalAvailable: t.number,
  products: t.array(ProductEntry),
});

export type ProductCollectionType = t.TypeOf<typeof ProductCollection>;
