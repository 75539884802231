/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback, useEffect, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { getPatientPortalUrl } from 'global/constants/url';
import {
  SingleUserSendTextPayload,
  MessagePayloadKindsEnum,
} from 'global/requests/RequestTypes';
import { getSessionUserData } from 'global/sessionStorage/SessionAPIResponses';
import SelectFiles from 'components/Global/DropFiles/SelectFiles';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import loading_spinner from 'media/images/loading-spinner.svg';
import {
  ContactMessages,
  PersonalizationTokensResponse,
  TemplateData,
  TemplateResponse,
} from 'global/requests/ResponseTypes';
import FilterGlobal from 'components/Global/Filter/Filter';
import { convertLocalTimeToAnotherTimeZone } from 'utils/convertData';
import moment from 'moment';
import ModalInsertPersonalize from 'components/Communications/ModalInsertPersonalize';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ModalEditPhone from 'components/Global/Modal/ModalEditPhone';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import toast from 'components/Global/Toast';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import Toast from 'components/Global/Toast';

const PracticeLogoWrapped = styled.div`
  position: relative;
  max-width: 100px;
  max-height: 100px;
  img {
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    cursor: default;
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const BoxImage = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px 10px;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 90px;
  flex: 1;

  div:not(:nth-child(1)) {
    margin-left: 10px;
  }

  img {
    max-height: 78px;
  }
`;

const SelectFilesWrapped = styled.div`
  position: unset;
  .message-error-select-files {
    color: red;
    position: absolute;
    left: 10px;
    top: ${(props: any) => (props.isHasMessageLimit ? '-20px' : '-40px')};
  }
  svg {
    color: #1974ff;
    width: 35px !important;
    height: 35px;
    padding-left: 10px;
  }
`;

const LayerNoPhone = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1300;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .error-msg {
    font-size: 16px;
    font-weight: 600;
  }
  .MuiButton-root {
    color: #1974ff;
    font-weight: 600;
    text-transform: capitalize;
    .MuiButton-endIcon {
      margin-bottom: 3px;
      svg {
        width: 20px;
      }
    }
  }
`;

const SpinnerWrapped = styled.img`
  width: 30px;
  height: 30px;
`;

interface IParameterUpdatePhone {
  phoneNumbers: PhoneNumber[];
}

type OptionDropDown = {
  value: string;
  label: string;
};

export interface InfoSending {
  sending: boolean;
  isError: boolean;
}
interface MessengerInputProps {
  userInputMessageBodyRef: React.RefObject<HTMLTextAreaElement>;
  userInfo: ContactMessages | undefined;
  refreshMessageData?: (
    contactId: string,
    disabledLoading?: boolean,
    allMessage?: boolean
  ) => void;
  setMessageDataLookup: any;
  setInfoSending?: React.Dispatch<React.SetStateAction<InfoSending>>;
  getContactListData?: (query: any, isNotResetList?: boolean) => Promise<void>;
  queryPagination?: any;
  isListSearching?: boolean;
  countClickInputText?: number;
  setCountClickInputText?: React.Dispatch<React.SetStateAction<number>>;
  refreshPhoneNumbers?: (phoneNumbers: PhoneNumber[]) => void;
  isSmsSubscription: boolean;
  viewAllSms: boolean;
  setViewAllSms: React.Dispatch<SetStateAction<boolean>>;
  phoneReceiver?: string;
  isErrorPhoneReceiver?: boolean;
  searchText?: string;
  setLoadingSendMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAfterSendToNewPhone?: () => void;
}

export const createSingleReceiverSMSPayload = (
  content: string,
  template: OptionDropDown,
  listImageUrl: Array<string>,
  timezone: string
): SingleUserSendTextPayload => {
  return {
    content: content,
    kind: MessagePayloadKindsEnum.sms,
    templateId: template?.value ?? '',
    scheduleDate: '',
    //@ts-ignore
    senderName: `${Object.values(getSessionUserData().name).join(' ')}`,
    mediaUrls: listImageUrl,
    isSending: true,
    sentDate: moment(
      !timezone
        ? new Date()
        : // @ts-ignore
          new Date(convertLocalTimeToAnotherTimeZone(timezone))
    ).format(),
  };
};

// input length is limited to this value
export const characterLimit = 480;

const MessengerInput = (props: MessengerInputProps) => {
  const {
    userInputMessageBodyRef,
    userInfo,
    refreshPhoneNumbers,
    viewAllSms,
    setViewAllSms,
    phoneReceiver,
    isErrorPhoneReceiver = false,
    handleAfterSendToNewPhone,
    setLoadingSendMessage,
  } = props;

  const [messageBody, setMessageBody] = React.useState('');
  const [images, setImages] = React.useState([]);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(
    {} as OptionDropDown
  );
  const [optionsTemplate, setOptionsTemplate] = React.useState<
    OptionDropDown[]
  >([]);
  const [optionsForm, setOptionsForm] = React.useState<
    OptionDropDown[]
  >([]);
  const [listSMSTemplate, setListSMSTemplate] = React.useState<TemplateData[]>(
    []
  );
  const refTimeZone = React.useRef('');
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    setMessageBody(el.value);
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };
  const [openPopupInsert, setOpenPopupInsert] = React.useState(false);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [openPopupEditPhone, setOpenPopupEditPhone] =
    React.useState<boolean>(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState<PhoneNumber[]>([]);

  useEffect(() => {
    setError('');
  }, [userInfo?.id]);

  useEffect(() => {
    setMessageBody('');
    setImages([]);
    if (optionsTemplate?.length) {
      setSelectedTemplate({} as OptionDropDown);
    }
  }, [userInfo?.id]);

  useEffect(() => {
    setViewAllSms(false);
    setPhoneNumbers(userInfo?.phoneNumbers ?? []);
  }, [userInfo]);

  useEffect(() => {
    if (props.countClickInputText === 1) {
      updateContactUnRead(true);
    }
  }, [props.countClickInputText]);

  useEffect(() => {
    getTemplates();
    getTokens();
    getForms();
    getTimeZone();
  }, []);

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getTimeZone = async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res?.settings?.timeZoneOffset) {
        refTimeZone.current = res?.settings?.timeZoneOffset;
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const getTemplates = async () => {
    try {
      const res = await configuredRequests.GET.templateByKind(
        ['sms'],
        ['manual']
      );
      if (res) {
        // @ts-ignore
        setListSMSTemplate(res.items);
        const listOption = res.items.map((item) => ({
          label: item.name,
          value: item.templateId,
        }));

        // @ts-ignore
        listOption && setOptionsTemplate(listOption);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const getForms = async () => {
    try {
      const info = await configuredRequests.GET.currentTenantInfo();
      let urlString: string = `${getPatientPortalUrl()}/${info?.micrositeName}/forms/`;
      if (!urlString.startsWith("http")) urlString = "http://" + urlString;
      const res = await configuredRequests.GET.getListFormPractice(info?.micrositeName ?? '');
      console.log(res);
      if (res) {
        // @ts-ignore
        const listOption = res.data.result.sort((a, b) => a.form_name.localeCompare(b.form_name)).map((item) => ({
          label: item.form_name,
          value: urlString + item.id,
        }));

        // @ts-ignore
        listOption && setOptionsForm(listOption);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const updateContactUnRead = async (
    hasRead: boolean,
    isNotResetList?: boolean
  ) => {
    try {
      if (userInfo?.id) {
        const res = await configuredRequests.POST.updateMarkUnread(
          userInfo?.id,
          hasRead
        );
        if (res && props.getContactListData) {
          props?.getContactListData(
            {
              limit:
                props?.queryPagination?.limit *
                (props?.queryPagination?.offset + 1),
              offset: 0,
              isSearch: true,
            },
            isNotResetList ?? false
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // reset message state to default/inital values. Used after message is sent
  const handleResetToInitial = () => {
    setSelectedTemplate({} as OptionDropDown);
    setMessageBody('');
    setImages([]);
    setIsLoading(false);
    setError('');
  };

  const sendMessagePostRequest = async () => {
    try {
      if (phoneReceiver) {
        if (
          ((messageBody || images.length > 0) &&
            !error &&
            !isErrorPhoneReceiver) ||
          (!isErrorPhoneReceiver &&
            error &&
            ((images.length > 0 && images.length < 10) ||
              messageBody.trim().length <= characterLimit))
        ) {
          const payload = createSingleReceiverSMSPayload(
            messageBody.trim(),
            selectedTemplate,
            images.map((image: any) => image.preview),
            refTimeZone.current
          );
          // handleResetToInitial();
          handleAfterSendToNewPhone && handleAfterSendToNewPhone();
          const res = await configuredRequests.POST.sendingMessageToPhone({
            ...payload,
            recipient: phoneReceiver,
          });
          if (res) {
            toast.success('Message sent successfully.');
            props.getContactListData &&
              (await props.getContactListData({
                limit:
                  props?.queryPagination?.limit *
                  (props?.queryPagination?.offset + 1),
                offset: 0,
                isSearch: true,
                searchText: props.searchText,
              }));
            setLoadingSendMessage && setLoadingSendMessage(false);
          }
        }
      } else {
        // @ts-ignore
        props.setInfoSending({ sending: true, isError: false });
        if (!userInfo) {
          //TODO error handle
          throw new Error(
            `Missing userInfo or messageContent ${userInfo} ${messageBody}`
          );
        }
        if (
          ((messageBody || images.length > 0) && !error) ||
          (error &&
            ((images.length > 0 && images.length < 10) ||
              messageBody.trim().length <= characterLimit))
        ) {
          const payload = createSingleReceiverSMSPayload(
            messageBody.trim(),
            selectedTemplate,
            images.map((image: any) => image.preview),
            refTimeZone.current
          );
          props.setMessageDataLookup((preState: any) => [...preState, payload]);
          handleResetToInitial();
          const res = await configuredRequests.POST.sendSingleUserSMS(
            userInfo.id ? userInfo.id : '',
            payload
          );
          if (res) {
            if (props.refreshMessageData) {
              props.refreshMessageData(userInfo.id ? userInfo.id : '', true);
            }
            if (!props.isListSearching) {
              if (props.getContactListData) {
                props.getContactListData({
                  limit:
                    props?.queryPagination?.limit *
                    (props?.queryPagination?.offset + 1),
                  offset: 0,
                  isSearch: true,
                });
              }
            }
            // @ts-ignore
            props.setInfoSending((preState: InfoSending) => ({
              ...preState,
              sending: false,
            }));
          }
        }
      }
    } catch (err) {
      if (setLoadingSendMessage) {
        setLoadingSendMessage(false);
        toast.error('Message was not able to send.');
      }
      if (!phoneReceiver) {
        // @ts-ignore
        props.setInfoSending({ sending: false, isError: true });
      }
      console.log('err: ', err);
    }
  };

  const handleChangeTextInput = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setError('');
    event.preventDefault();
    const targetValue = event.currentTarget.value;

    if (targetValue && targetValue.length) {
      if (targetValue.length === characterLimit) {
        setError(`Message body cannot exceed ${characterLimit} characters.`);
      } else {
        setError('');
      }
      setMessageBody(targetValue);
      return;
    } else {
      setError('');
    }
    //else
    setMessageBody('');
  };

  // handles adding newline, shows confirmation on "Enter"
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        if (event.shiftKey) {
          //do nothing because by default it will add the newline character
          return;
        }
        if (!messageBody.trim() && images.length <= 0) {
          //do nothing because the entire message body might be whitespace
          event.preventDefault();
          return;
        }
        event.preventDefault();
        //const userConfirmedSendMessage = window.confirm("Do you want to send this message? This action cannot be undone.");
        //if (userConfirmedSendMessage) {
        handleSubmitMessage(event);
        //}
        return;
      }
      case 'Backspace': {
        setError('');
      }
    }
  };

  const handleSubmitMessage = (event: React.SyntheticEvent) => {
    event.preventDefault();
    sendMessagePostRequest();
  };

  const onDrop = useCallback(
    async (files: any) => {
      try {
        setError('');
        const concatFiles = [...images, ...files];
        const totalSize = concatFiles.reduce(
          (accumulator: any, currentValue: any) => {
            accumulator += currentValue?.size;
            return accumulator;
          },
          0
        );
        if (concatFiles.length > 10) {
          setError('Cannot select more than 10 attachments.');
        } else if (totalSize > 1048576 * 4.95) {
          setError('Please select attachments less than 5MB.');
        } else if (totalSize < 1048576 * 4.95) {
          setIsLoading(true);
          const imagesLink = files.map((file: any) => {
            const formData = new FormData();
            formData.append('attachment', file);
            return configuredRequests.POST.uploadImage(formData);
          });
          const response = await Promise.all(imagesLink);
          const newImages = response.map((res: any, index: number) => ({
            preview: res.url,
            name: files[index].name,
            size: files[index]?.size,
          }));
          // @ts-ignore
          setImages((preState) => [...preState, ...newImages]);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('err: ', err);
      }
    },
    [images]
  );

  const listImages = useMemo(() => {
    return images.length > 0
      ? images.map((img: any) => (
          <PracticeLogoWrapped key={`${img.preview}-${Math.random()}`}>
            <img
              src={img.preview}
              alt={`img-${img.name}`}
              key={`${img.preview}-${Math.random()}`}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() =>
                setImages((preState) =>
                  preState.filter((file: any) => file.preview !== img.preview)
                )
              }
            />
          </PracticeLogoWrapped>
        ))
      : null;
  }, [images]);

  const totalSizeImages = useMemo(
    () =>
      images.reduce((accumulator: any, currentValue: any) => {
        accumulator += currentValue?.size;
        return accumulator;
      }, 0),
    [images]
  );

  const handleSelectTemplate = (newTemplateSelect: OptionDropDown) => {
    setSelectedTemplate(newTemplateSelect);
    if (newTemplateSelect.value === '') {
      setMessageBody('');
    } else {
      setMessageBody(
        listSMSTemplate.find(
          (template) => template.templateId === newTemplateSelect.value
        )?.templateBody ?? ''
      );
    }
  };

  const handleSelectForm = (newFormSelect: OptionDropDown) => {
    if (newFormSelect.value === '') {
      return;
    } else {
      typeInTextarea(
        document.getElementById('textArea'),
        newFormSelect.value
      );
    }
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    typeInTextarea(
      document.getElementById('textArea'),
      `{{${personalizationTokenSelected}}}`
    );
    setOpenPopupInsert(false);
  };

  const getFirstName = useMemo(() => {
    if (userInfo?.name) {
      return userInfo?.name;
    } else if (userInfo?.lastName) {
      const idxLastName = userInfo?.name?.indexOf(userInfo.lastName) ?? -1;
      // @ts-ignore
      return userInfo?.name.slice(0, idxLastName).trim() ?? '';
    }
    return '';
  }, [userInfo]);

  const checkPhoneCommunication = useMemo(() => {
    if (!props.isSmsSubscription) {
      return true;
    }

    if (phoneNumbers?.length) {
      const phoneSendText = phoneNumbers.find(
        (phone: PhoneNumber) => phone.isText
      );
      if (phoneSendText) {
        return true;
      }
    }
    return false;
  }, [phoneNumbers]);

  // const handleSubscribe = async (phoneNumber: string) => {
  //   try {
  //     const res = await configuredRequests.PATCH.updateContactSubscribe(
  //       userInfo?.id ?? '',
  //       phoneNumber
  //     );
  //     if (res) {
  //       // setPhoneNumbers(res);
  //       toast.success('Your data has been saved successfully!');
  //     }
  //   } catch (err) {
  //     toast.error('Your data has not been saved yet. Please try again.');
  //   }
  // };

  const handleSubmitEditPhone = async (values: IParameterUpdatePhone) => {
    try {
      if (!phoneNumbers.length) {
        values.phoneNumbers[0].isPrimary = true;
      }
      // if (phoneNumbers.length && values.phoneNumbers.length) {
      //   values.phoneNumbers.forEach((newPhone: PhoneNumber, idx: number) => {
      //     const index = phoneNumbers?.findIndex((oldPhone: PhoneNumber) =>
      //       compareTwoPhone(newPhone.number, oldPhone.number)
      //     );
      //     if (index === -1) {
      //       values.phoneNumbers[idx].isValidated = false;
      //     }
      //   });
      // }
      const res = await configuredRequests.PATCH.updatePhoneContact(
        userInfo?.id ?? '',
        values
      );

      if (res) {
        // @ts-ignore
        setPhoneNumbers(res.phoneNumbers);
        refreshPhoneNumbers &&
          // @ts-ignore
          refreshPhoneNumbers(res.phoneNumbers);
        setOpenPopupEditPhone(false);
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      setOpenPopupEditPhone(false);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  return (
    <div
      className={`messenger--chat-send ${
        !checkPhoneCommunication && 'no-phone--available'
      }`}
      onClick={() => {
        if (props.setCountClickInputText) {
          //@ts-ignore
          props.setCountClickInputText(props.countClickInputText + 1);
        }
      }}
    >
      {!checkPhoneCommunication && (
        <LayerNoPhone className='messenger__phone--no-available'>
          <p className='error-msg'>{`${getFirstName} has no available phone number for communication`}</p>
          <Button
            variant='contained'
            endIcon={
              <FontAwesomeIcon
                icon={['far', 'edit']}
                className='icon--edit-phone'
              />
            }
            onClick={() => setOpenPopupEditPhone(true)}
          >
            Edit Phone Number
          </Button>
        </LayerNoPhone>
      )}
      <button
        id='personalizeButton'
        className={`btn-personalize ${
          !checkPhoneCommunication && 'no-phone--communication'
        }`}
        onClick={() => setOpenPopupInsert(true)}
      >
        {' Personalize '}
      </button>
      <form
        className={`messenger--chat-form ${
          !checkPhoneCommunication && 'no-phone--communication'
        }`}
        onSubmit={handleSubmitMessage}
        // style={{ height: images.length > 0 || isLoading ? 'unset' : '100%' }}
      >
        <div className='messenger--chat-form--left'>
          <div>
            {/* @ts-ignore */}
            <SelectFilesWrapped isHasMessageLimit={!!characterLimit}>
              <SelectFiles
                multiple
                maxFiles={10 - images.length}
                maxSize={1048576 * 4.95 - totalSizeImages}
                accept={'image/png, image/gif, image/jpeg'}
                onDrop={onDrop}
                titleSelect={<FontAwesomeIcon icon={faImage} />}
                setError={setError}
                messageMaxFile='Cannot select more than 10 attachments.'
                messageMaxSize='Please select attachments less than 5MB.'
              />
            </SelectFilesWrapped>

            <FilterGlobal
              handleSetNewFilterValue={handleSelectTemplate}
              optionsForFilter={optionsTemplate}
              valueSelected={{} as OptionDropDown}
              placeholder=''
              icon='file-lines'
            />

            <FilterGlobal
              handleSetNewFilterValue={handleSelectForm}
              optionsForFilter={optionsForm}
              valueSelected={{} as OptionDropDown}
              placeholder=''
              icon='money-check-pen'
            />

            {phoneReceiver === undefined && (
              <AntSwitch
                checked={viewAllSms}
                onChange={() => {
                  props.refreshMessageData &&
                    props.refreshMessageData(
                      userInfo?.id ?? '',
                      false,
                      !viewAllSms
                    );
                  setViewAllSms((prev) => !prev);
                }}
                label='View all SMS Communication'
              />
            )}
          </div>
          <div className='messenger--chat-form--content'>
            <textarea
              id='textArea'
              className='messenger--chat-input'
              placeholder='Type a message...'
              // ref={userInputMessageBodyRef}
              onChange={handleChangeTextInput}
              value={messageBody}
              onKeyDown={handleKeyPress}
              maxLength={characterLimit}
            />
            <BoxImage>
              {isLoading ? (
                <SpinnerWrapped src={loading_spinner} alt={'Loading'} />
              ) : (
                listImages
              )}
            </BoxImage>
          </div>
        </div>

        <div
          className={
            'messenger-character-limit-warning' + (error ? ' visible' : '')
          }
        >
          {error}
        </div>

        <section className='messenger--chat-button-container'>
          <button
            className={
              'messenger--chat-button send-message' +
              ((!messageBody.trim() && images.length <= 0) ||
              !!isErrorPhoneReceiver
                ? ' disabled'
                : '')
            }
            aria-label='Send message'
            disabled={
              (!messageBody.trim() && images.length <= 0) ||
              !!isErrorPhoneReceiver
            }
            type='submit'
          >
            <FontAwesomeIcon icon={['fas', 'paper-plane']} />
          </button>
        </section>
      </form>
      <ModalInsertPersonalize
        openPopupInsertToken={openPopupInsert}
        setOpenPopupInsertToken={setOpenPopupInsert}
        handleConfirmInsertToken={handleInsertData}
        token={tokenData}
        screen={'manual'}
      />
      <ModalEditPhone
        isOpenModal={openPopupEditPhone}
        setIsOpenModal={setOpenPopupEditPhone}
        phoneNumbers={phoneNumbers}
        handleUpdatePhone={handleSubmitEditPhone}
        // handleSubscribe={handleSubscribe}
      />
    </div>
  );
};

export default React.memo(MessengerInput);
