import React from 'react';
import { CountryCode } from 'libphonenumber-js';
import { SmallDarkTextHeavy } from 'components/Legwork/typography';

import formatPhoneNumber from './utils';

export interface FormatPhoneNumberProps {
  number: string;
  countryCode?: CountryCode;
  extension?: string;
  className?: string;
}

const FormatPhoneNumber = (props: FormatPhoneNumberProps): React.ReactElement => {
  const { number, countryCode = 'US', extension, className } = props;
  return (
    <SmallDarkTextHeavy className={className}>
      {formatPhoneNumber(number, countryCode, extension)}
    </SmallDarkTextHeavy>
  );
};

FormatPhoneNumber.defaultProps = {
  countryCode: 'US',
};

export default FormatPhoneNumber;
