import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactModal from 'react-modal';
import DetailsFragment from './DetailsFragment';
import {
  TemplateResponse,
  DeliverApproximatelyResponse,
} from 'global/requests/ResponseTypes';
import { AppointmentTypeSetting } from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import { Medical } from 'global/requests/ResponseTypes/Medical';

ReactModal.setAppElement('div#root');

interface RightModalDetailsProps {
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  labelText?: string;
  triggerId: string;
  category: string;
  automationTriggerKey?: string;
  automationCategoryId?: string;
  checkNewEvent(a: boolean): void;
  emailTemplateData: TemplateResponse;
  postalTemplateData: TemplateResponse;
  smsTemplateData: TemplateResponse;
  callTemplateData: TemplateResponse;
  deliverApproximatelyData: DeliverApproximatelyResponse;
  listAppointmentTypes: AppointmentTypeSetting[];
  listSpecies: SpeciesSettingType[];
  listMedicalServices: Medical[];
}

const RightModalDetails = (props: RightModalDetailsProps) => {
  const {
    display,
    setDisplay,
    labelText,
    category,
    triggerId,
    automationTriggerKey,
    automationCategoryId,
    checkNewEvent,
    smsTemplateData,
    emailTemplateData,
    callTemplateData,
    postalTemplateData,
    deliverApproximatelyData,
    listAppointmentTypes,
    listSpecies,
    listMedicalServices,
  } = props;

  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);

  const renderView = () => {
    return (
      <DetailsFragment
        categoryKind={category}
        triggerId={triggerId}
        setDisplay={setDisplay}
        display={display}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        dirtyForm={dirtyForm}
        setDirtyForm={setDirtyForm}
        automationTriggerKey={automationTriggerKey}
        automationCategoryId={automationCategoryId}
        checkNewEvent={checkNewEvent}
        smsTemplateData={smsTemplateData}
        callTemplateData={callTemplateData}
        emailTemplateData={emailTemplateData}
        postalTemplateData={postalTemplateData}
        deliverApproximatelyData={deliverApproximatelyData}
        handleCloseModal={handleCloseModal}
        listAppointmentTypes={listAppointmentTypes}
        listSpecies={listSpecies}
        listMedicalServices={listMedicalServices}
      />
    );
  };

  const handleCloseModal = (event?: React.SyntheticEvent) => {
    event?.preventDefault();
    setOpenPopup(dirtyForm);
    setDisplay(dirtyForm);
  };

  const handleKeypressCloseModal = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case ' ': {
        handleCloseModal();
      }
    }
  };

  return (
    <ReactModal
      isOpen={display}
      onRequestClose={handleCloseModal}
      contentLabel={labelText ? labelText : ''}
      className='modal-user-settings react-modal'
    >
      <div className='react-modal--inside-overflow-container'>
        <div className='react-modal--settings__title'>
          <p className='title-text'>{labelText}</p>

          <div
            className='react-modal-close-icon'
            tabIndex={0}
            onClick={handleCloseModal}
            onKeyPress={handleKeypressCloseModal}
          >
            <FontAwesomeIcon icon={['far', 'times']} />
          </div>
        </div>

        {renderView()}
      </div>
    </ReactModal>
  );
};

export default RightModalDetails;
