import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { FormFieldBase } from '..';
import styles from './styles';
import { Labels, Defaults } from './constants';
import Api from './api';
import { matchFieldByLabel } from './utils';

import ValidationMessage from '../validation-message';
import AppointmentBookingPlaceholder from './components/appointment-booking-placeholder';
import AppointmentSelector from './components/appointment-selector';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';

export class FormFieldAppointmentBooking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appointmentTypes: [],
      appointmentTypeId: Defaults.AppointmentType.id,
      providers: [],
      providerId: Defaults.Provider.id,
      micrositeName: getSessionTenantInfo()?.micrositeName,
      ...this.defaultDateTimeState,
    };
  }
  async componentDidMount() {
    const appointmentTypes = await Api.getAppointmentTypes(
      this.state.micrositeName
    );
    // appointmentTypes.unshift(Defaults.AppointmentType);

    this.setState({ appointmentTypes }, () => {
      if (this.props.isReview) {
        // true when viewing a submission
        this.loadValues();
      }
    });
  }

  componentDidUpdate(prevProps) {
    // TODO: do we need to account for patient pulling up previous submission?
    // E.g., will they be able to hit the share/microsite link and see their previous appointment request
    if (!this.props.isReview) return;

    if (this.valuesHaveChanged(prevProps)) {
      this.loadValues();
    }
  }

  valuesHaveChanged = prevProps => {
    const { value: prevValue = [] } = prevProps;
    const { value = [] } = this.props;
    let isDiff = false;

    for (let i = 0; i < value.length; i++) {
      isDiff = value[i] !== prevValue[i];
      if (isDiff) return isDiff;
    }

    return isDiff;
  };

  get accountId() {
    return this.props.accountId;
  }

  get defaultDateTimeState() {
    return {
      currentMonth: new Date(),
      selectedDay: null,
      selectedTime: null,
      openDays: [],
      timeSlots: [[], []],
    };
  }

  get selectedAppointmentType() {
    return (
      this.state.appointmentTypes.find(
        type => type.id === this.state.appointmentTypeId
      ) || {}
    );
  }

  loadValues = async () => {
    const { value } = this.props;
    if (!value || (Array.isArray(value) && value.length === 0)) return;

    const fields = this.getFieldsByLabel();
    const appointmentTypeId =
      fields[Labels.AppointmentType].value || Defaults.AppointmentType.id;

    // load appointmentTypeId to state first, so we can properly lookup in state.appointmentTypes (for duration)
    this.setState({ appointmentTypeId }, async () => {
      // now load rest of data from stored values
      const providerId = fields[Labels.Provider].value || Defaults.Provider.id;
      const appointmentDateString = fields[Labels.AppointmentDate].value;
      const selectedDay = appointmentDateString
        ? new Date(moment().format(appointmentDateString))
        : null;
      const currentMonth = selectedDay || new Date();
      const selectedTime = fields[Labels.AppointmentTime].value;

      if (appointmentTypeId)
        await this.loadProviders(appointmentTypeId);
      if (providerId)
        await this.loadOpenDays(appointmentTypeId, providerId, currentMonth);
      if (providerId && selectedDay) {
        await this.loadTimeSlots(selectedDay, providerId);
      }

      this.setState({
        providerId,
        currentMonth,
        selectedDay,
        selectedTime,
      });
    });
  };

  getFieldsByLabel = () => {
    const { fields } = this.props.field;

    return {
      [Labels.AppointmentType]: matchFieldByLabel(
        fields,
        Labels.AppointmentType
      ),
      [Labels.Provider]: matchFieldByLabel(fields, Labels.Provider),
      [Labels.AppointmentDate]: matchFieldByLabel(
        fields,
        Labels.AppointmentDate
      ),
      [Labels.AppointmentTime]: matchFieldByLabel(
        fields,
        Labels.AppointmentTime
      ),
    };
  };

  loadProviders = async appointmentTypeId => {
    const providers = await Api.getProvidersByAppointmentType(
      appointmentTypeId
    );
    // providers.unshift(Defaults.Provider);
    // providers.unshift(Defaults.ProviderMergeAll);

    this.setState({ providers });
  };

  loadOpenDays = async (appointmentTypeId, providerId, date) => {
    const openDays = await Api.getOpenDays(
      appointmentTypeId || this.state.appointmentTypeId,
      providerId || this.state.providerId,
      date
    );
    this.setState(prev => ({ ...prev, openDays }));
  };

  loadTimeSlots = async (day, providerId) => {
    const timeSlots = await Api.getOpenTimeSlots(
      this.state.appointmentTypeId,
      providerId || this.state.providerId,
      day,
      this.selectedAppointmentType.appointmentDuration
    );
    // divide slots into am/pm
    const amSlots = timeSlots.filter(slot => moment(slot).hour() < 12);
    const pmSlots = timeSlots.filter(slot => moment(slot).hour() >= 12);

    this.setState({ timeSlots: [amSlots, pmSlots] });
  };

  createEventTarget = (fieldLabel, value) => ({
    target: {
      id: matchFieldByLabel(this.props.field.fields, fieldLabel).uuid,
      value,
    },
  });

  handleAppointmentTypeChange = async e => {
    const appointmentTypeId = e.target.value;

    this.loadProviders(appointmentTypeId);

    this.setState({
      appointmentTypeId,
      providerId: Defaults.Provider.id,
      ...this.defaultDateTimeState,
    });

    this.handleChange(Labels.AppointmentType, appointmentTypeId);
  };

  handleProviderChange = e => {
    const providerId = e.target.value;

    this.setState(
      {
        providerId,
        ...this.defaultDateTimeState,
      },
      () => this.handleMonthChange(this.state.currentMonth)
    );

    this.handleChange(Labels.Provider, providerId);
  };

  handleMonthChange = async month => {
    const { providerId } = this.state;
    await this.loadOpenDays(providerId, month);

    // TODO: confirm whether or not changing month should clear date selection
    this.setState({
      currentMonth: month,
      selectedDay: null,
      selectedTime: null,
    });

    this.handleChange(Labels.AppointmentDate, null);
    this.handleChange(Labels.AppointmentTime, null);
  };

  handleDayClick = (day, modifiers = {}) => {
    const { selectedTime, providerId } = this.state;

    if (modifiers.disabled) return;

    this.loadTimeSlots(day, providerId);

    this.setState({
      selectedDay: day,
      selectedTime: null,
    });

    this.handleChange(Labels.AppointmentDate, day);

    if (selectedTime) {
      // changing the day cleared the previously selected time, trigger change event to show validation message
      this.handleChange(Labels.AppointmentTime, null);
    }
  };

  handleTimeClick = time => {
    this.setState({
      selectedTime: time,
    });

    this.handleChange(Labels.AppointmentTime, time);
  };

  handleChange = (fieldLabel, value) => {
    this.props.onChange(
      this.createEventTarget(fieldLabel, value),
      /* forceValidation */ true,
      /* requiredOverride */ true
    );
  };

  render() {
    const { field, isRenderedField, readOnly } = this.props;
    const {
      appointmentTypes,
      providers,
      providerId,
      openDays,
      selectedDay,
      timeSlots,
      selectedTime,
    } = this.state;
    const fields = this.getFieldsByLabel();

    if (!isRenderedField) return <AppointmentBookingPlaceholder />;

    const inputContainerClasses = classNames({
      'form-field-input-container': true,
      'read-only': readOnly,
    });

    const appointmentTypeField = fields[Labels.AppointmentType];
    const providerField = fields[Labels.Provider];
    const dayPickerField = fields[Labels.AppointmentDate];
    const timeSelectorField = fields[Labels.AppointmentTime];

    return (
      <div key={field.uuid} style={styles.container}>
        <div className={inputContainerClasses} style={styles.dropdown}>
          <div className='form-field-label primary'>{`${Labels.AppointmentType} *`}</div>
          <div className='form-field-input select'>
            <div
              className={
                appointmentTypeField.validationMessage
                  ? 'control-group error'
                  : ''
              }
            >
              <select
                id={appointmentTypeField.uuid}
                name='appointmentType'
                className='span30'
                value={appointmentTypeField.value}
                onChange={this.handleAppointmentTypeChange}
                disabled={readOnly}
              >
                {appointmentTypes.map(t => (
                  <option key={`appointmentType-${t.id}`} value={t.id}>
                    {t.appointmentType}
                  </option>
                ))}
              </select>
              <ValidationMessage
                message={appointmentTypeField.validationMessage}
              />
            </div>
          </div>
        </div>

          <div className={inputContainerClasses} style={styles.dropdown}>
            <div className='form-field-label primary'>{`${Labels.Provider} *`}</div>
            <div className='form-field-input select'>
              <div
                className={
                  providerField.validationMessage ? 'control-group error' : ''
                }
              >
                <select
                  id={providerField.uuid}
                  name='provider'
                  className='span30'
                  value={providerField.value}
                  onChange={this.handleProviderChange}
                  disabled={readOnly}
                >
                  {providers.map(p => (
                    <option key={`provider-${p.id}`} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </select>
                <ValidationMessage message={providerField.validationMessage} />
              </div>
            </div>
          </div>

        {providerId && (
          <AppointmentSelector
            openDays={openDays}
            timeSlots={timeSlots}
            selectedDay={selectedDay}
            selectedTime={selectedTime}
            onMonthChange={this.handleMonthChange}
            onDayClick={this.handleDayClick}
            onTimeClick={this.handleTimeClick}
            dayPickerField={dayPickerField}
            timeSelectorField={timeSelectorField}
            readOnly={readOnly}
          />
        )}
      </div>
    );
  }
}

const controls = {
  edit: false,
  delete: true,
  more: true,
  // delete: __DEV__,
  // more: __DEV__,
};
export default FormFieldBase(
  FormFieldAppointmentBooking,
  controls,
  /* preventClick */ true
);

FormFieldAppointmentBooking.defaultProps = {
  isRenderedField: false,
  value: [],
  readOnly: false,
};
