import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import { isWindows } from 'react-device-detect';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import UserInformationModal from 'components/Global/SettingsComponent/UserInformationModal';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column } from 'global/requests/ResponseTypes';
import {
  SummaryItemTypes,
  UserStatusTypes,
  UserKindTypes,
} from 'global/requests/api-route-types';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { useDecodeToken } from 'hooks/useDecodeToken';
import { convertPhoneNumber } from 'utils/convertData';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';

type UsersSettingDataConverted = {
  id: string;
  pulseUserStatus?: string;
  emailAddress?: string;
  name?: string;
  phoneNumbers?: string;
  userRoles?: string;
};

interface roleInformationDataType {
  roleId: string;
  roleName: string;
}

const columns: Column[] = [
  { id: 'name', label: 'User Name' },
  { id: 'emailAddress', label: 'Email' },
  { id: 'phoneNumbers', label: 'Phone Number' },
  { id: 'userRoles', label: 'Role Permission' },
];

const optionsForFilter = [
  { label: 'Active Users', value: 'Active' },
  { label: 'Inactive Users', value: 'Inactive' },
  { label: 'All Users', value: '' },
];

const UsersSetting = (): JSX.Element => {
  const { roleUser } = useDecodeToken();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [usersSettingData, setUsersSettingData] = React.useState<
    UsersSettingDataConverted[]
  >([]);
  const [displaySettingUserModal, setDisplaySettingUserModal] =
    React.useState(false);
  const [userInformationData, setUserInformationData] =
    React.useState<UserDataResponse>();
  const [roleInformationData, setRoleInformationData] =
    React.useState<roleInformationDataType[]>();

  const getAllUsersSetting = async (options?: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
  }) => {
    try {
      const res = await configuredRequests.GET.allUserInfoByCurrentTenant({
        ...options,
        summaryItems: ['phone'],
        userKinds: ['Admin', 'User'],
      });

      const convertedUserDataResponse = res.users.map((item) => {
        const phoneNumbers =
          item?.phoneNumbers?.length !== 0
            ? item?.phoneNumbers
                ?.filter((phoneNumber) => {
                  return phoneNumber.isPrimary;
                })
                .map((item) => convertPhoneNumber(item.number))
                .join(', ')
            : '';
        const userRoles =
          item?.roles?.length !== 0
            ? item?.roles?.map((item) => item.roleName).join(', ')
            : '';
        return {
          id: item.id,
          pulseUserStatus: item.pulseUserStatus,
          emailAddress: item.emailAddress,
          // Need to display something so that the doctor can get to Edit to add a name
          name: item.name?.length ? item.name : 'Missing Contact Name',
          phoneNumbers,
          userRoles,
        };
      });

      const sortedUserData = orderBy(
        convertedUserDataResponse,
        [(users) => users.name.toLowerCase()],
        ['asc']
      );

      setUsersSettingData(sortedUserData);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getAllRole = async () => {
    try {
      const res = await configuredRequests.GET.getRoles();
      const activeRolesData = orderBy(res.roles, ['roleName'], ['asc'])
        .filter((items) => items.roleStatus === 'Active')
        .map((items) => {
          return {
            roleName: items.roleName,
            roleId: items.roleId,
          };
        });
      setRoleInformationData(activeRolesData as roleInformationDataType[]);
    } catch (err) {}
  };

  useEffect(() => {
    Promise.all([getAllUsersSetting(), getAllRole()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <div className='communication-container user-setting w-300 setting-detail setting-scroll--custom'>
      {isLoading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <div className='communication-container__table w-table-cell templates-mail-sms m-0 '>
            <CommunicationTable
              columns={columns}
              dataTable={usersSettingData}
              isSort={false}
              isDisplayedSearchAndFilter={true}
              isDisplayedSearch={true}
              isDisplayedFilter={true}
              isDisplayedButton={true}
              optionsForFilter={optionsForFilter}
              keySearch='name'
              placeholderSearch='Search by user name'
              buttonName='New User'
              iconButton={['fas', 'user-plus']}
              setDisplaySettingUserModal={setDisplaySettingUserModal}
              isLoading={isLoading}
              screen={'USER'}
              router={`/${
                roleUser.includes('System Admin') ? 'SettingsAdmin' : 'Settings'
              }/User`}
              keySort='name'
            />
          </div>

          <UserInformationModal
            handleReloadUserList={getAllUsersSetting}
            displaySettingUserModal={displaySettingUserModal}
            setDisplaySettingUserModal={setDisplaySettingUserModal}
            titleText={'Add New'}
            userInformation={userInformationData}
            setUserInformationData={setUserInformationData}
            roleInformationData={roleInformationData || []}
          />
        </>
      )}
    </div>
  );
};

export default UsersSetting;
