/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import InfoColumn from './InfoColumn';
import InteractionList from './InteractionList';
import { UserInteractionResponseUnionType } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment from 'moment';
import TextViewContainer from './TextViewContainer';
import EmailViewContainer from './EmailViewContainer';
import InteractionNav from './InteractionNav';
import { PetSummaryCollectionResponse } from 'global/requests/ResponseTypes/Pets';
import { AppointmentInfoResponse } from 'global/requests/ResponseTypes/Appointments';
import {
  UserDataResponse,
  UserSettingObjectType,
} from 'global/requests/ResponseTypes/UserAdministration';
import EditPostalTemplate from 'components/Communications/EditPostalTemplate';
import PostalViewContainer from './PostalViewContainer';
import { ReminderInfoResponse } from 'global/requests/ResponseTypes/Reminders';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import { getFullUserName, getSettingByNamespace } from 'utils/convertData';
import { Modal } from '@material-ui/core';
import SubscriptionSetting from './SubscriptionSetting';
import EstimatesContainer from './EstimatesContainer';
import SendFormsContainer from './SendFormsContainer';

const ViewContactContainer = (props: { userID: string }) => {
  const [currentActiveView, setCurrentActiveView] =
    React.useState<string>('interactions');

  const [userInfo, setUserInfo] = React.useState<UserDataResponse>();
  const [userAppointments, setUserAppointments] =
  React.useState<AppointmentInfoResponse>();
  const [userReminders, setUserReminders] =
  React.useState<ReminderInfoResponse>();
  const [interactionData, setInteractionData] =
  React.useState<UserInteractionResponseUnionType>();
  const [allUserPetData, setAllUserPetData] =
    React.useState<PetSummaryCollectionResponse>();
    const [textDataContact, setTextDataContact] = React.useState([]);
    const [infoSending, setInfoSending] = React.useState({
      sending: false,
      isError: false,
    });
    const [openSubscriptionSetting, setOpenSubscriptionSetting] =
    React.useState(false);
    
    const [getUserAppointmentArguments] = React.useState<{
      startDate: string;
    endDate: string;
    limit: number;
    offset?: number;
    //kind: ["telemed"]
  }>({
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().add(14, 'days').format('MM-DD-YYYY'),
    limit: 10,
    // offset?: number, // implemented, unused here
    //kind: ["telemed"] // not yet implemented TODO
  });
  const [viewAllSms, setViewAllSms] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [useEstimates, setUseEstimates] = React.useState<boolean>(false);

  const sendGetUserDataRequest = async () => {
    try {
      const res = await configuredRequests.GET.singleUserByID(props.userID);
      if (res) {
        setUserInfo(res);
      }
    } catch (err) {
      console.log('sendGetUserDataRequest: ', err);
    }
  };

  const sendGetTenantInfoRequest = async () => {
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      if (res) {
        // @ts-ignore
        setUseEstimates(res.useEstimates);
      }
    } catch (err) {
      console.log('sendGetTenantInfoRequest: ', err);
    }
  };

  const sendGetUserAppointmentsRequest = async () => {
    try {
      const res = await configuredRequests.GET.allUserAppointmentsOfUser(
        props.userID
      );
      if (res) {
        setUserAppointments(res);
      }
    } catch (err) {
      console.log('error making request for user appointments');
    }
  };

  const sendGetUserRemindersRequest = async () => {
    try {
      const res = await configuredRequests.GET.allUserRemindersByUserID(
        props.userID,
        {
          dateStart: moment(new Date().toISOString()).format('YYYY-MM-DD'),
        }
      );
      if (res) {
        setUserReminders(res);
      }
    } catch (err) {
      console.log('error making request for user reminders');
    }
  };

  const getMessagesByUserID = async (userID: string) => {
    try {
      const res = await configuredRequests.GET.messagesByUserID(userID, {
        commKinds: ['email', 'sms', 'call', 'postal'],
      });
      if (res) {
        setInteractionData(res as UserInteractionResponseUnionType);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const getMessagesByUserIDTwoWay = 
    async (
      userID: string,
      disabledLoading?: boolean,
      allMessages?: boolean
    ) => {
      try {
        !disabledLoading && setLoading(true);
        const res = await configuredRequests.GET.getMessagesByUserID(
          userID,
          allMessages
        );
        if (res) {
          if (!disabledLoading || textDataContact.length != res.length)
            setTextDataContact(res);
          !disabledLoading && setLoading(false);
        }
      } catch (err) {
        console.log('error', err);
        !disabledLoading && setLoading(false);
      }
    }

  const getAllPetsByUserID = async (userID: string) => {
    try {
      const res = await configuredRequests.GET.allUserPetsByUserID(userID);
      if (res) {
        setAllUserPetData(res);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    sendGetUserDataRequest();
    sendGetTenantInfoRequest();
  }, []);

  useEffect(() => {
    if (!userAppointments) {
      sendGetUserAppointmentsRequest();
    }

    if (!userReminders) {
      sendGetUserRemindersRequest();
    }
  }, [userInfo, userAppointments, userReminders]);

  useEffect(() => {
    if (userInfo) {
      getMessagesByUserID(userInfo.userId);
      getAllPetsByUserID(userInfo.userId);
      getMessagesByUserIDTwoWay(userInfo.userId);
    }
  }, [userInfo?.userId]);

  useEffect(() => {
    if (currentActiveView === 'text') {
      const getNewMessage = setInterval(() => {
        if (infoSending.isError == true || infoSending.sending == true)
          setInfoSending({ sending: false, isError: false });
        if (userInfo && userInfo.userId) {
          if (viewAllSms) {
            getMessagesByUserIDTwoWay(userInfo.userId, true, true);
          } else {
            getMessagesByUserIDTwoWay(userInfo.userId, true);
          }
        }
      }, 30000);

      return () => clearInterval(getNewMessage);
    }
  }, [currentActiveView]);

  useEffect(() => {
    if (currentActiveView !== 'interactions') {
      sendGetUserDataRequest();
    }
    if (currentActiveView === 'text' && userInfo && userInfo.userId) {
      getMessagesByUserIDTwoWay(userInfo.userId, false);
    }
  }, [currentActiveView]);

  const refreshMessageData = React.useCallback(() => {
    if (!userInfo) {
      //TODO logging
      return;
    }
    getMessagesByUserID(userInfo.userId);
  }, [userInfo]);

  const refreshPhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    // @ts-ignore
    setUserInfo((prevUser) => ({ ...prevUser, phoneNumbers: phoneNumbers }));
  };

  const checkNewEmail = (hasNewEmail: boolean) => {
    // console.log('hasNewEmail', hasNewEmail);
  };

  const handleUpdateSubscriptionSetting = (
    settings: UserSettingObjectType[]
  ) => {
    // @ts-ignore
    setUserInfo((prev) => ({ ...prev, userSettings: settings }));
    sendGetUserDataRequest();
  };

  const getcommunicationTypeStatus = (communicationType: string) => {
    if (userInfo == null || userInfo == undefined) {
      return false;
    }

    let setting = getSettingByNamespace(userInfo, 'UserSubscriptionSetting');
    if (communicationType == 'text') {
      return setting?.settings.sms;
    }

    if (communicationType == 'email') {
      return setting?.settings.email;
    }

    if (communicationType == 'postal') {
      return setting?.settings.postal;
    }

    if (communicationType == 'phone') {
      return setting?.settings.phone;
    }

    return false;
  };

  const renderActiveView = () => {
    switch (currentActiveView) {
      case 'interactions':
        return (
          <InteractionList
            interactionData={interactionData}
            userInfo={userInfo}
            currentActiveView={currentActiveView}
            refreshMessageData={refreshMessageData}
          />
        );
      case 'text':
        return (
          <TextViewContainer
            userInfo={userInfo}
            interactionData={textDataContact}
            refreshMessageData={getMessagesByUserIDTwoWay}
            setInteractionData={setTextDataContact}
            infoSending={infoSending}
            setInfoSending={setInfoSending}
            refreshPhoneNumbers={refreshPhoneNumbers}
            subscription={getcommunicationTypeStatus('text')}
            viewAllSms={viewAllSms}
            setViewAllSms={setViewAllSms}
            loading={loading}
            onSubscriptionClick={setOpenSubscriptionSetting}
          />
        );
      case 'email':
        return (
          <EmailViewContainer
            checkNewEmail={checkNewEmail}
            userInfo={userInfo}
            interactionData={interactionData}
            subscription={getcommunicationTypeStatus('email')}
            onSubscriptionClick={setOpenSubscriptionSetting}
          />
        );
      case 'postal':
        return (
          <PostalViewContainer
            userInfo={userInfo}
            subscription={getcommunicationTypeStatus('postal')}
            onSubscriptionClick={setOpenSubscriptionSetting}
          />
        );
      case 'estimates':
        return (
          <EstimatesContainer
            userInfo={userInfo!}
            petsInfo={allUserPetData}
          />
        );
      case 'forms':
        return (
          <SendFormsContainer
            userInfo={userInfo!}
            petsInfo={allUserPetData}
          />
        );
      default:
        return (
          <InteractionList
            interactionData={interactionData}
            userInfo={userInfo}
            currentActiveView={currentActiveView}
            refreshMessageData={refreshMessageData}
          />
        );
    }
  };

  return (
    <div className={'view-contact--main-container'}>
      <InfoColumn
        userInfo={userInfo}
        allUserPetData={allUserPetData}
        useViewContactFullInfo={{
          userAppointments: userAppointments,
          sendGetUserAppointmentsRequest: sendGetUserAppointmentsRequest,
          getUserAppointmentArguments: getUserAppointmentArguments,
          userReminders: userReminders,
        }}
        refreshUserData={sendGetUserDataRequest}
        refreshPhoneNumbers={refreshPhoneNumbers}
      />
      <span className={'view-contact--container max-height'}>
        <InteractionNav
          currentActiveView={currentActiveView}
          setCurrentActiveView={setCurrentActiveView}
          reloadList={refreshMessageData}
          registered={userInfo?.registered}
          onSubscriptionClick={setOpenSubscriptionSetting}
          useEstimates={useEstimates}
        />
        <>{renderActiveView()}</>
      </span>

      {openSubscriptionSetting && userInfo && (
        <Modal
          open={openSubscriptionSetting}
          onClose={() => setOpenSubscriptionSetting(false)}
        >
          <SubscriptionSetting
            userId={userInfo.userId}
            fullName={getFullUserName(userInfo)}
            settings={userInfo.userSettings}
            onClose={setOpenSubscriptionSetting}
            onUpdate={handleUpdateSubscriptionSetting}
          />
        </Modal>
      )}
    </div>
  );
};

export default ViewContactContainer;
