import React from 'react';

import PracticeLoginContactComponent from 'components/PracticeLoginContact/PracticLoginContact';

const PracticeLoginContact = (props: { loginError: string }): JSX.Element => {

  return (
    <PracticeLoginContactComponent loginError={props.loginError} />
  )
}

export default PracticeLoginContact;
