/* eslint-disable react-hooks/exhaustive-deps */
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';
import FilterGlobal from 'components/Global/Filter/Filter';
import Table from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { OptionFilterDropdownObject } from 'global/requests/ResponseTypes';
import { debounce, pickBy } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import styled from 'styled-components';
import convertDateToTimestamp from 'utils/convertDateToTimestamp';

const WrapperTable = styled.div`
  width: calc(100% + 20px);
  margin-right: -20px;

  .MuiTableContainer-root {
    padding-right: 20px;
    overflow: auto;
    max-height: calc(100vh - 163px - 20px - 37px - 24px - 37px);

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
const columns = [
  { key: 'time', title: 'Time' },
  { key: 'level', title: 'Level' },
  { key: 'sessionId', title: 'Session ID' },
  { key: 'logger', title: 'Logger' },
  { key: 'message', title: 'Message' },
];
interface IQuery {
  limit: number;
  offset: number;
  startTime?: number | string;
  endTime?: number | string;
  sessionId?: string;
  searchText?: string;
}

const optionsForFilter = [
  { value: 'all', label: 'All Levels' },
  { value: 'Error', label: 'Error' },
  { value: 'Information', label: 'Information' },
];

const ServiceLogs = (): JSX.Element => {
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const [serviceLogsData, setServiceLogsData] = useState({});
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [message, setMessage] = useState('');
  const [sessionIdSearch, setSessionIdSearch] = useState('');
  const [level, setLevel] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(false);
  const [timeZoneOffset, setTimeZoneOffset] = useState<any>();

  const searchByMesage = useCallback(
    debounce((messageSearch: any) => {
      setQueryPagination((preState: any) => ({
        limit: preState.limit,
        offset: 0,
      }));
      getServiceLogsData({
        limit: queryPagination.limit,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({
          searchText: messageSearch.trim(),
          sessionId: sessionIdSearch.trim(),
          level: level === 'all' ? '' : level,
        }),
      });
    }, 1000),
    [queryPagination, sessionIdSearch, startDate, endDate, level]
  );

  const searchBySessionId = useCallback(
    debounce((sessionId: any) => {
      setQueryPagination((preState: any) => ({
        limit: preState.limit,
        offset: 0,
      }));
      getServiceLogsData({
        limit: queryPagination.limit,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionId.trim(),
          level: level === 'all' ? '' : level,
        }),
      });
    }, 1000),
    [queryPagination, message, startDate, endDate, level]
  );

  const getServiceLogsData = useCallback(
    async (
      query: IQuery,
      isMount?: boolean,
      isReload?: boolean,
      isSubReLoad?: boolean
    ) => {
      try {
        const res = await configuredRequests.GET.getServiceLogs(query);
        if (res) {
          setServiceLogsData(res);
          if (isMount) {
            setStartDate(new Date(res.from));
            setEndDate(new Date(res.to));
          }
          if (isReload) {
            setEndDate(new Date(res.to));
          }
          isSubReLoad && setIsSubLoading(false);
          setIsLoading(false);
        }
      } catch (err) {
        isSubReLoad && setIsSubLoading(false);
        setIsLoading(false);
        console.log('error: ', err);
      }
    },
    []
  );

  const getSystemSetting = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res) {
        const { timeZoneOffset: timeZoneOffetSetting = '' } = res.settings;
        setTimeZoneOffset(timeZoneOffetSetting);
        // const dateFollowTimezoneSetting = convertLocalTimeToAnotherTimeZone(timeZoneOffetSetting);
        // // @ts-ignore
        // const endDateTime = moment(new Date(dateFollowTimezoneSetting)).toDate();
        // const past1days = moment(endDateTime).subtract(1, 'days').toDate();
        await getServiceLogsData({
          limit: 10,
          offset: 0,
          startTime: '',
          endTime: '',
        });

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }, [getServiceLogsData]);

  useEffect(() => {
    getServiceLogsData(
      {
        limit: 10,
        offset: 0,
        startTime: '',
        endTime: '',
      },
      true
    );
  }, []);

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({ ...preState, offset: newPage }));
      await getServiceLogsData({
        ...queryPagination,
        offset: newPage,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionIdSearch.trim(),
          level: level === 'all' ? '' : level,
        }),
      });
    },
    [
      getServiceLogsData,
      queryPagination,
      startDate,
      endDate,
      message,
      sessionIdSearch,
      level,
    ]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination({ limit: newRowsPerPage, offset: 0 });
        getServiceLogsData({
          limit: newRowsPerPage,
          offset: 0,
          startTime: convertDateToTimestamp(startDate),
          endTime: convertDateToTimestamp(endDate),
          ...pickBy({
            searchText: message.trim(),
            sessionId: sessionIdSearch.trim(),
            level: level === 'all' ? '' : level,
          }),
        });
      }
    },
    [getServiceLogsData, endDate, startDate, message, sessionIdSearch, level]
  );

  const selectStartDate = useCallback(
    (date: Date | null) => {
      setStartDate(date);
      setQueryPagination((preState: any) => ({
        limit: preState.limit,
        offset: 0,
      }));
      getServiceLogsData({
        limit: queryPagination?.limit ?? 10,
        offset: 0,
        startTime: convertDateToTimestamp(date),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionIdSearch.trim(),
          level: level === 'all' ? '' : level,
        }),
      });
    },
    [
      getServiceLogsData,
      queryPagination,
      endDate,
      message,
      sessionIdSearch,
      level,
    ]
  );

  const selectEndDate = useCallback(
    (date: Date | null) => {
      setEndDate(date);
      setQueryPagination((preState: any) => ({
        limit: preState.limit,
        offset: 0,
      }));
      getServiceLogsData({
        limit: queryPagination?.limit ?? 10,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(date),
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionIdSearch.trim(),
          level: level === 'all' ? '' : level,
        }),
      });
    },
    [
      getServiceLogsData,
      queryPagination,
      startDate,
      message,
      sessionIdSearch,
      level,
    ]
  );

  const handleReloadData = useCallback(() => {
    setIsSubLoading(true);
    setQueryPagination((preState: any) => ({
      limit: preState.limit,
      offset: 0,
    }));
    getServiceLogsData(
      {
        limit: queryPagination?.limit ?? 10,
        offset: 0,
        startTime: '',
        endTime: '',
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionIdSearch.trim(),
          level: level === 'all' ? '' : level,
        }),
      },
      false,
      true,
      true
    );
  }, [
    getServiceLogsData,
    queryPagination,
    startDate,
    message,
    sessionIdSearch,
    level,
  ]);

  const handleSearchDataMessage = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const value = event.currentTarget.value;
    setMessage(value);
    searchByMesage(value);
  };

  const handleSearchSessionID = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const value = event.currentTarget.value;
    setSessionIdSearch(value);
    searchBySessionId(value);
  };

  const handleSelectFilter = useCallback(
    async (newSelectedItem: OptionFilterDropdownObject) => {
      const levelSelected = newSelectedItem.value;
      setQueryPagination((preState: any) => ({
        limit: preState.limit,
        offset: 0,
      }));
      setLevel(levelSelected);
      await getServiceLogsData({
        limit: queryPagination?.limit ?? 10,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({
          searchText: message.trim(),
          sessionId: sessionIdSearch.trim(),
          level: levelSelected === 'all' ? '' : levelSelected,
        }),
      });
    },
    [
      getServiceLogsData,
      queryPagination,
      startDate,
      endDate,
      message,
      sessionIdSearch,
    ]
  );

  return isLoading ? (
    <img
      className='loading-general-syncer'
      src={loading_spinner}
      alt={'Loading'}
    />
  ) : (
    <div className='communication-container service-log'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        <div className='communication-select-filter '>
          <div className='service-log-date-time-picker'>
            <DateTimePicker
              // @ts-ignore
              setStartDate={selectStartDate}
              // @ts-ignore
              setEndDate={selectEndDate}
              showTimePicker={true}
              startDate={startDate}
              endDate={endDate}
              getEndDate={false}
            />
          </div>
          <div>
            <Button
              variant='contained'
              color='primary'
              onClick={handleReloadData}
              //   () => {
              //   const dateFollowTimezoneSetting = convertLocalTimeToAnotherTimeZone(timeZoneOffset);
              //   // @ts-ignore
              //   const endDateTime = moment(new Date(dateFollowTimezoneSetting)).toDate();
              //   selectEndDate(endDateTime);
              // }}
            >
              <FontAwesomeIcon
                icon={faSync}
                className='service-log--refresh-button'
              />
            </Button>
          </div>
        </div>
        <div className='daily-appointment__filter' data-testid='Telemed-define'>
          <form
            className={
              isSafari ? 'search-safari' : 'search-form search-filter__global'
            }
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={['far', 'search']} />
            <input
              type='search'
              placeholder='Search by message'
              className='search-form__input'
              value={message}
              name='message'
              onChange={handleSearchDataMessage}
            />
          </form>

          <form
            className={
              isSafari ? 'search-safari' : 'search-form search-filter__global'
            }
            onSubmit={(event: React.SyntheticEvent) => {
              event.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={['far', 'search']} />
            <input
              type='search'
              placeholder='Search by session ID'
              className='search-form__input'
              name='sessionIdSearch'
              value={sessionIdSearch}
              onChange={handleSearchSessionID}
            />
          </form>
          <FilterGlobal
            handleSetNewFilterValue={handleSelectFilter}
            optionsForFilter={optionsForFilter}
          />
        </div>

        {isSubLoading ? (
          <img
            className='loading-general-syncer'
            src={loading_spinner}
            alt={'Loading'}
          />
        ) : (
          <>
            {
              // @ts-ignore
              serviceLogsData?.serviceLogs?.length > 0 ? (
                <WrapperTable>
                  <Table
                    columns={columns}
                    rows={
                      // @ts-ignore
                      serviceLogsData?.serviceLogs?.map((service: any) => ({
                        time: moment(
                          new Date(service.createdDate || '')
                        ).format('MM/DD/YYYY hh:mm:ss A'),
                        level: service.level,
                        sessionId: service.sessionId,
                        logger: service.logger,
                        message: service.message,
                      })) ?? []
                    }
                    isHasPagination={true}
                    // @ts-ignore
                    totalAvailable={serviceLogsData?.totalAvailable ?? 0}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={queryPagination?.limit ?? 10}
                    page={queryPagination?.offset ?? 0}
                  />
                </WrapperTable>
              ) : (
                <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                  No results found
                </p>
              )
            }
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceLogs;
