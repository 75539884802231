import React, { FC, useMemo, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { PersonalizationTokensResponse } from 'global/requests/ResponseTypes';
import ModalInsertPersonalize from './ModalInsertPersonalize';
import toast from 'components/Global/Toast';

const TINY_MCE_API_KEY = 'a9f6rtq0r7e1k7cgfmrap8d6a97h8lpbl0hngycy1462j6kw';

interface IProps {
  idTextArea?: string;
  value: string | undefined;
  formatType?: string; // html, text
  token: PersonalizationTokensResponse;
  screen?: string;
  handleChangeEditor: (newContent: string) => void;
  handleEmptyContent?: () => void;
}

const EditorBox: FC<IProps> = ({
  idTextArea,
  value = "",
  token,
  screen = "",
  handleChangeEditor,
  handleEmptyContent
}) => {
  const [editorRef, setEditorRef] = useState({
    insertContent: (content: any) => {},
  });
  const [openPopupInsertToken, setOpenPopupInsertToken] = React.useState(false);

  const tinyMCEOption = useMemo(
    () => {
      const settings = {
        branding: false,
        height: ['contacts postal', 'manual postal', 'appointment postal', 'newcontacts postal', 'marketing postal', 'reminder postal'].includes(screen) ? 320 : 420,
        menubar: 'file edit view insert format tools table tc help',
        // selector: `textarea${idTextArea ? `#${idTextArea}` : ''}`,
        content_css:
          '/src/components/Communications/PersonalizationPopupCss.scss',
        plugins: [
          'advlist fullpage autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen emoticons insertdatetime media table paste imagetools wordcount',
        ],
        fullpage_default_encoding: 'UTF-8',
        fullpage_hide_in_source_view: false,
        toolbar:
          'fullscreen | styleselect | bold italic underline strikethrough forecolor backcolor alignment list | link image emoticons table | personalization',
        setup: function (editor: any) {
          setEditorRef(editor);
          editor.ui.registry.addGroupToolbarButton('alignment', {
            icon: 'align-left',
            tooltip: 'Alignment',
            items: 'alignleft aligncenter alignright alignjustify',
          });
          editor.ui.registry.addGroupToolbarButton('list', {
            icon: 'list-bull-square',
            tooltip: 'List',
            items: 'bullist numlist',
          });
          editor.ui.registry.addButton('personalization', {
            text: 'Personalize',
            onAction: function () {
              setOpenPopupInsertToken(true);
            },
          });
          editor.on('blur', function (e: any) {
            const b = '<body>';
            const be = '</body>';
            const t = editor.getContent();
            const templateBody = t.substring(
              t.indexOf(b) + b.length,
              t.indexOf(be) - 1
            );
            if (templateBody.length === 1) {
              handleEmptyContent && handleEmptyContent();
            }
          });
          // editor.on('keyup', function (e: any) {
          //   const b = '<body>';
          //   const be = '</body>';
          //   const t = editor.getContent();
          //   const templateBody = t.substring(
          //     t.indexOf(b) + b.length,
          //     t.indexOf(be) - 1
          //   );
          //   if (templateBody.length === 1) {
          //       setErrorEmptyEmailBody(true)
          //       setIsDisabled(true)
          //     } else {
          //       setErrorEmptyEmailBody(false)
          //   }
          // });
        },
        images_upload_credentials: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb: any, value: any, meta: any) {
          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            //@ts-ignore
            let file = this.files[0];
            let formData = new FormData();
            formData.append('attachment', file);
            let reader = new FileReader();
            reader.onload = async function () {
              try {
                const res = await configuredRequests.POST.uploadImage(formData);
                if (res) {
                  cb(res.url, { title: file.name });
                  toast.success('File uploaded successfully');
                }
              } catch (err) {
                toast.error('File upload failed');
              }
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
      };
      if (['contacts postal', 'manual postal', 'appointment postal', 'newcontacts postal', 'marketing postal', 'reminder postal'].includes(screen)) {
        // @ts-ignore
        settings["font_formats"] = "Nunito Sans=Nunito Sans,sans-serif";
      }

      return settings;
    },
    [idTextArea, screen]
  );

  const handleConfirmInsertToken = (personalizationTokenSelected: string) => {
    editorRef.insertContent(`{{${personalizationTokenSelected}}}`);
    setOpenPopupInsertToken(false);
  };

  return (
    <>
      <Editor
        // id={idTextArea}
        value={value}
        apiKey={TINY_MCE_API_KEY}
        init={tinyMCEOption}
        onEditorChange={handleChangeEditor}
        outputFormat='html' // text | html
      />

      <ModalInsertPersonalize
        openPopupInsertToken={openPopupInsertToken}
        setOpenPopupInsertToken={setOpenPopupInsertToken}
        handleConfirmInsertToken={handleConfirmInsertToken}
        token={token}
        screen={screen}
      />
    </>
  );
};

export default React.memo(EditorBox);
