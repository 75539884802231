import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import FilterGlobal from 'components/Global/Filter/Filter';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import StatusSelect from 'components/Global/StatusSelect/StatusSelect';
import TableComponent from 'components/Global/Table';
import toast from 'components/Global/Toast';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  Group,
  OptionFilterDropdownObject,
} from 'global/requests/ResponseTypes';
import { orderBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { convertPhoneNumber } from 'utils/convertData';
import GroupModal from './GroupModal';

const Loading = styled.p`
  padding: 36px 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .d-flex {
    margin: 15px 0;

    .search-form {
      input {
        text-indent: 10px;
      }
    }
  }

  .btn-new {
    margin-left: auto;
    background-color: #0074ff;
    text-transform: capitalize;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .MuiTable-root {
    & + td {
      display: block;
      margin-bottom: 1.2rem;
    }
  }

  .MuiTableRow-root {
    .status_select-dropdown-container {
      width: 100px;
      svg {
        font-size: 1.25rem;
      }
    }

    .appointment__entry-button {
      &.active {
        background-color: #1dc9b7;
      }

      &.inactive {
        background-color: #22334d;
      }

      .status_select-text {
        width: 90px;
      }
    }

    a {
      color: #1974ff;

      &:active,
      &:visited,
      &:link,
      &:hover {
        color: #1974ff;
      }
    }
  }
`;

type GroupsTableData = {
  id: string;
  groupName: string;
  emailAddress: string;
  phoneNumbers: string;
  status: string;
};

const columns = [
  { key: 'groupName', title: 'Group Name' },
  { key: 'emailAddress', title: 'Email' },
  { key: 'phoneNumber', title: 'Phone Number' },
  { key: 'status', title: 'Status' },
];

const optionsForFilter = [
  { label: 'Active Groups', value: 'Active' },
  { label: 'Inactive Groups', value: 'Inactive' },
  { label: 'All Groups', value: '' },
];

const Groups = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [listAllGroup, setListAllGroup] = React.useState<Group[]>([]);
  const [groupsTableData, setGroupsTableData] = React.useState<
    GroupsTableData[]
  >([]);
  const [displaySettingUserModal, setDisplaySettingUserModal] =
    React.useState(false);
  const [queryPagination, setQueryPagination] = React.useState({
    limit: 10,
    offset: 0,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('Active');
  const [totalVisible, setTotalVisible] = useState<number>(0);

  useEffect(() => {
    const groupsVisible = listAllGroup.filter((group) => {
      if (filterValue === '') {
        return group.name?.toLowerCase()?.includes(searchValue.toLowerCase());
      }
      return (
        group.status === filterValue &&
        group.name?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
    });
    const groups = groupsVisible.slice(0, queryPagination.limit);
    setTotalVisible(groupsVisible.length);
    setQueryPagination((prevState) => ({ ...prevState, offset: 0 }));
    setGroupsTableData(transferToDataTable(groups));
  }, [searchValue, filterValue]);

  useEffect(() => {
    const groupsVisible = listAllGroup.filter((group) => {
      if (filterValue === '') {
        return group.name?.toLowerCase()?.includes(searchValue.toLowerCase());
      }
      return (
        group.status === filterValue &&
        group.name?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
    });
    const groups = groupsVisible.slice(
      queryPagination.offset * queryPagination.limit,
      queryPagination.limit * queryPagination.offset + queryPagination.limit
    );
    setTotalVisible(groupsVisible.length);
    setGroupsTableData(transferToDataTable(groups));
  }, [queryPagination, listAllGroup]);

  useEffect(() => {
    getGroupsData();
  }, []);

  const transferToDataTable = useCallback((groups: Group[]) => {
    const groupDataTable = groups.map((item: Group) => {
      const phoneNumber =
        item?.practicePhoneNumbers?.length !== 0
          ? item?.practicePhoneNumbers
              ?.filter((phoneNumber: any) => {
                return phoneNumber.isPrimary;
              })
              .map((item: any) => convertPhoneNumber(item.number))
              .join(', ')
          : '';
      return {
        id: item.tenantGroupId ?? '',
        status: item.status ?? 'Active',
        emailAddress: item.practiceEmailAddress ?? '',
        groupName: item.name ?? '',
        phoneNumbers: phoneNumber ?? '',
      };
    });
    return groupDataTable;
  }, []);

  const getGroupsData = async () => {
    try {
      // @ts-ignore
      const res = await configuredRequests.GET.groupSystemAdmin();

      const sortedGroupData = orderBy(
        res.roles,
        ['status', (group) => group?.name?.toLowerCase()],
        ['asc', 'asc']
      );

      setListAllGroup([...sortedGroupData]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSearch = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = e.currentTarget.value;
      setSearchValue(value);
    },
    []
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({ ...preState, offset: newPage }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination({ limit: newRowsPerPage, offset: 0 });
      }
    },
    []
  );

  const handleUpdateStatus = async (id: string, status: string) => {
    try {
      const index = listAllGroup.findIndex(
        (group) => group.tenantGroupId?.trim() === id.trim()
      );

      if (index > -1) {
        const newGroup = { ...listAllGroup[index], status };

        // @ts-ignore
        const res = await configuredRequests.PATCH.updateGroup(newGroup);
        if (res) {
          getGroupsData();
          toast.success('Update status successfully!');
        }
      }
    } catch (err) {
      console.log('err', err);
      toast.error('Update status failed. Please try again.');
    }
  };

  return (
    <div className='communication-container customer-table mt-20'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        {isLoading ? (
          <Loading>
            <b>Loading...</b>
          </Loading>
        ) : (
          <Wrapper>
            <div className='d-flex'>
              <form
                className={
                  false ? 'search-safari' : 'search-form search-filter__global'
                }
                onSubmit={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={['far', 'search']} />
                <input
                  type='search'
                  placeholder='Search by group name'
                  className='search-form__input'
                  value={searchValue}
                  onChange={handleSearch}
                />
              </form>
              <FilterGlobal
                handleSetNewFilterValue={(
                  newItemSelected: OptionFilterDropdownObject
                ) => {
                  setFilterValue(newItemSelected.value);
                }}
                optionsForFilter={optionsForFilter}
              />
              <Button
                variant='contained'
                color='primary'
                className='btn-new'
                onClick={() => setDisplaySettingUserModal(true)}
              >
                <FontAwesomeIcon icon={['fas', 'user']} />
                New Group
              </Button>
            </div>
            <TableComponent
              columns={columns}
              rows={groupsTableData.map((group) => ({
                groupName: (
                  <Link to={`/Groups/${group.id}`}>
                    <HighlightText
                      text={group.groupName}
                      searchValue={searchValue}
                    />
                  </Link>
                ),
                emailAddress: group.emailAddress,
                phoneNumber: group.phoneNumbers,
                status: (
                  <StatusSelect
                    currentStatus={group.status.toLowerCase()}
                    id={group.id}
                    screen='GROUPS'
                    handleUpdate={handleUpdateStatus}
                  />
                ),
              }))}
              isHasPagination={true}
              totalAvailable={totalVisible}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryPagination?.limit ?? 10}
              page={queryPagination?.offset ?? 0}
            />
          </Wrapper>
        )}
      </div>

      <GroupModal
        handleReloadGroupList={getGroupsData}
        displaySettingUserModal={displaySettingUserModal}
        setDisplaySettingUserModal={setDisplaySettingUserModal}
        titleText={'Add New'}
      />
    </div>
  );
};

export default Groups;
