import React from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { generateBaseOptions, legend } from './constants';
import { dataSeriesGenerator, plotLineGenerator } from './utils';

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface BarChartProps {
  autorotation?: number[];
  categories: string[];
  color?: string;
  dataSeries: { name: string; data: number[]; color?: string }[];
  goal?: number;
  goalNameOverride?: string;
  height?: string;
  noLegend?: boolean;
  yAxisAddon?: string;
  yAxisLabels?: boolean;
  tooltipFormatter?: () => string;
  xAxisYOffset?: number;
  xAxisXOffset?: number;
}

const BarChart = (props: BarChartProps): React.ReactElement => {
  const {
    autorotation = [-45],
    categories,
    color = '#6E84A3',
    dataSeries,
    goal,
    goalNameOverride,
    height = null,
    noLegend = false,
    yAxisAddon,
    yAxisLabels = true,
    tooltipFormatter,
    xAxisYOffset,
    xAxisXOffset,
  } = props;
  const options = generateBaseOptions();
  const theme = useTheme();
  const { formatMessage: fm } = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatter = function formatter(this: any): string {
    // eslint-disable-next-line react/no-this-in-sfc
    return `${this.value}${yAxisAddon || ''}`;
  };

  const generateOptions = (): object => {
    if (options.chart) options.chart.height = height;
    options.xAxis.categories = categories;
    if (options.xAxis.labels) {
      options.xAxis.labels.autoRotation = autorotation;
      if (options.xAxis.labels.style) options.xAxis.labels.style.color = color;
      if (xAxisXOffset || xAxisXOffset === 0) options.xAxis.labels.x = xAxisXOffset;
      if (xAxisYOffset || xAxisYOffset === 0) options.xAxis.labels.y = xAxisYOffset;
    }
    options.yAxis.plotLines = plotLineGenerator(theme, goal);
    options.series = dataSeriesGenerator(
      theme,
      goalNameOverride ?? fm({ id: 'componentLib.appWide.goal' }),
      dataSeries,
      !!goal,
    );
    if (options.yAxis.labels) {
      options.yAxis.labels.enabled = yAxisLabels;
      options.yAxis.labels.formatter = formatter;
    }
    options.legend = noLegend ? { enabled: false } : (legend as Highcharts.LegendOptions);
    options.yAxis.gridLineWidth = yAxisLabels ? 1 : undefined;
    if (options.tooltip) options.tooltip.formatter = tooltipFormatter;

    return options;
  };
  return <HighchartsReact highcharts={Highcharts} options={generateOptions()} />;
};

export default BarChart;
