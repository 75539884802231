/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, makeStyles, TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import 'components/Settings/PracticeSyncerSetting/GeneralSettings/GeneralSyncerStyle.scss';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { GeneralType } from 'global/requests/ResponseTypes/SyncerSettingGeneral';
import { isEmpty } from 'lodash';
import loading_spinner from 'media/images/loading-spinner.svg';
import moment from 'moment';
import React, {
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { isWindows } from 'react-device-detect';

interface IProps {
  setShowConfigurationTab: React.Dispatch<SetStateAction<boolean>>;
}

interface DropdownOptionType {
  label: string;
  value: string;
}

type AppointmentType = {
  id: string;
  appointmentType: string;
  appointmentDuration: number;
};

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const validationSchema = Yup.object({
  interval: Yup.number()
    .integer(ErrorMessageTypes.incorrectFormat)
    .typeError(ErrorMessageTypes.incorrectFormat)
    .min(1, 'The sync interval must be greater than 0.')
    .max(1440, 'The sync interval can not be greater than 1440.')
    .required(ErrorMessageTypes.required),
  skipAppointmentCodes: Yup.string(),
  startTimePractice: Yup.string(),
  endTimePractice: Yup.string().test(
    'is-greater',
    'End Time have to greater than Start Time.',
    function (value) {
      const { startTimePractice } = this.parent;
      if (startTimePractice === undefined && value === undefined) return true;
      return moment(value, 'HH:mm').isAfter(moment(startTimePractice, 'HH:mm'));
    }
  ),
  allowSchedulingAppointment: Yup.boolean(),
});

// Tooltip
const text = (
  <>
    <b style={{ color: '1A252E', fontWeight: 700 }}>Sync Interval</b>
    <p>
      The number of minutes to wait between syncs (how often we read from your
      database).
    </p>
  </>
);
const skipAppointmentCodes = (
  <>
    <b style={{ color: '1A252E', fontWeight: 700 }}>Skip Appointment</b>
    <p>
      Select any Appointment Types that will be used to indicate that we should
      not do automated communication for these types.
      {/* Enter any codes that will be used to indicate that we should not collect
      an appointment. Use commas to separate multiple codes. In order to skip an appointment one of these codes must
      appear at the very start of your appointment reasons in your software.
      Example codes ** or SKIP" */}
    </p>
  </>
);

const syncSchedule = (
  <>
    <b style={{ color: '1A252E', fontWeight: 700 }}>Active Syncer Time</b>
    <p>This is the time at which the syncer will actively sync.</p>
  </>
);
// end Tooltip
const GeneralSettings = ({ setShowConfigurationTab }: IProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [appointmentStatusOption, setAppointmentStatusOption] = useState<
    DropdownOptionType[]
  >([]);
  const [appointmentTypesSkip, setAppointmentTypesSkip] = useState<
    AppointmentType[]
  >([]);
  const [driverData, setDriverData] = useState<DropdownOptionType[]>([]);
  const [generalSettingData, setGeneralSettinData] = useState<GeneralType>();

  const useStyles = makeStyles((theme) => ({
    customWidth: {
      maxWidth: 200,
    },
    customPading: {
      paddingTop: 30,
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
    },
  }));

  const getDefaultSkipAppoint = (values: string) => {
    return values
      .split(',')
      ?.map((value) => appointmentTypesSkip?.find((item) => item.id === value))
      .filter((appoint) => appoint !== undefined);
  };

  const initialValues = {
    interval: generalSettingData?.interval
      ? parseInt(generalSettingData?.interval || '')
      : undefined,
    skipAppointmentCodes: getDefaultSkipAppoint(
      generalSettingData?.skipAppointmentCodes ?? ''
    ),
    startTimePractice: generalSettingData?.startTimePractice || '',
    endTimePractice: generalSettingData?.endTimePractice || '',
    restartTimePractice: generalSettingData?.restartTimePractice || '06:00',
    driver: !isEmpty(generalSettingData?.driver)
      ? driverData.filter((item) => {
          return item.value === generalSettingData?.driver?.id;
        })[0]
      : driverData[0],
    allowSchedulingAppointment:
      generalSettingData?.allowSchedulingAppointment ?? false,
    patientConfirmStatus: generalSettingData?.patientConfirmStatus
      ? appointmentStatusOption.filter((item) => {
          return item.value === generalSettingData?.patientConfirmStatus;
        })[0]
      : appointmentStatusOption[0],
    practiceConfirmStatus: generalSettingData?.practiceConfirmStatus
      ? appointmentStatusOption.filter((item) => {
          return item.value === generalSettingData?.practiceConfirmStatus;
        })[0]
      : appointmentStatusOption[0],
  };

  const getGeneralSettingSyncer = async () => {
    try {
      const res = await configuredRequests.GET.getGeneralSettingSyncer();
      if (res) {
        setGeneralSettinData({ ...res?.settings?.general });
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDropdownDataGeneral = async () => {
    try {
      const dropdownData =
        await configuredRequests.GET.getDropdownDataGeneral();
      if (dropdownData) {
        // @ts-ignore
        const appointmentStatusData = dropdownData.statues?.map((items) => {
          return {
            label: items.name,
            value: items.value,
          };
        });
        // @ts-ignore
        const driverOptionData = dropdownData.drivers
          ? // @ts-ignore
            dropdownData.drivers.map((items) => {
              return {
                label: items?.driverName ?? '',
                value: items.driverId,
              };
            })
          : [];
        setAppointmentStatusOption(
          appointmentStatusData as DropdownOptionType[]
        );
        setDriverData(driverOptionData as DropdownOptionType[]);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAllAppointmentType = async () => {
    try {
      const res = await configuredRequests.GET.getAppointmentType();
      setAppointmentTypesSkip(
        res
          .sort((itemA: AppointmentType, itemB: AppointmentType) => {
            if (
              itemA.appointmentType.toLowerCase() >
              itemB.appointmentType.toLowerCase()
            ) {
              return 1;
            } else if (
              itemA.appointmentType.toLowerCase() <
              itemB.appointmentType.toLowerCase()
            ) {
              return -1;
            } else {
              return 0;
            }
          })
          .filter((apptType: any) => !!apptType.externalId)
      );
    } catch (err) {
      // console.log('err', err);
    }
  };

  useEffect(() => {
    Promise.all([
      getGeneralSettingSyncer(),
      getDropdownDataGeneral(),
      getAllAppointmentType(),
    ]).then(() => {
      setIsLoading(false);
    });
    const settingMainView = document.getElementById(
      'settings--main-view-container'
    );
    if (settingMainView && isWindows) settingMainView.style.overflow = 'auto';
  }, []);

  const handleSubmitData = async (values: any) => {
    try {
      const submitData = {
        general: {
          interval: values.interval.toString(),
          skipAppointmentCodes: values.skipAppointmentCodes
            .map((item: any) => item.id)
            .join(','),
          startTimePractice: values.startTimePractice,
          endTimePractice: values.endTimePractice,
          restartTimePractice: values.restartTimePractice,
          driver: {
            id: values.driver.value,
            name: values.driver.label,
          },
          allowSchedulingAppointment:
            values.driver.value !== '0'
              ? values.allowSchedulingAppointment
              : undefined,
          patientConfirmStatus: values.patientConfirmStatus.value,
          practiceConfirmStatus: values.practiceConfirmStatus.value,
        },
        appointmentStatuses: [
          {
            patientStatus: 'Schedule',
            practiceStatus: 'broken',
          },
          {
            patientStatus: 'Complete',
            practiceStatus: 'broken',
          },
          {
            patientStatus: 'UnscheList',
            practiceStatus: 'broken',
          },
          {
            patientStatus: 'ASAP',
            practiceStatus: 'broken',
          },
        ],
      };
      const res = await configuredRequests.PUT.updateGeneralSettingSyncer(
        submitData as any
      );
      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );

        getDriverInfo();
      }
    } catch (error) {
      console.log('error', error);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const getDriverInfo = async () => {
    try {
      const res = await configuredRequests.GET.getDriverConfigurationSetting();
      if (res) {
        // @ts-ignore
        const configurationFields = JSON.parse(res.configurationField ?? '[]');
        if (
          configurationFields?.length > 0 &&
          configurationFields?.filter(
            (field: any) => !!field[Object.keys(field)?.[2]]
          )?.length > 0
        ) {
          setShowConfigurationTab(true);
        }
      }
    } catch (err) {
      setShowConfigurationTab(false);
      // setIsLoading(false);
      console.log('err', err);
    }
  };

  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={(values) => handleSubmitData(values)}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          isValid,
          setFieldTouched,
        } = formikProps;

        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <div className='react-box--content'>
                <div className='form-react'>
                  {isLoading ? (
                    <img
                      className='loading-general-syncer'
                      src={loading_spinner}
                      alt={'Loading'}
                    />
                  ) : (
                    <>
                      <p
                        className='title--text ml-10'
                        style={{ marginBottom: '5px' }}
                      >
                        Settings
                      </p>
                      <div className='setting__content--full-row flex'>
                        <div className='form-input-settings w-50'>
                          <div className='pms-driver-setting'>
                            <div className='flex1050'>
                              <span className='title-input-form'>PMS Name</span>
                              <div className='input-options'>
                                <FastField
                                  classNamePrefix='search-city'
                                  component={Select}
                                  isSearchable={false}
                                  defaultValue={
                                    !isEmpty(generalSettingData?.driver)
                                      ? driverData.filter((item) => {
                                          return (
                                            item.value ===
                                            generalSettingData?.driver?.id
                                          );
                                        })[0]
                                      : driverData[0]
                                  }
                                  name='driver'
                                  options={driverData}
                                  onBlur={() => {
                                    setFieldTouched('driver', true);
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue('driver', e);
                                  }}
                                  maxMenuHeight={150}
                                />
                              </div>
                            </div>
                            <div className='flex1050'>
                              <div className='allow-scheduling-appointment'>
                                {values.driver?.value !== '0' && (
                                  <>
                                    <Checkbox
                                      style={{ color: '#1974ff' }}
                                      checked={
                                        values.allowSchedulingAppointment
                                      }
                                      color='primary'
                                      inputProps={{
                                        'aria-label': 'secondary checkbox',
                                      }}
                                      onChange={(event: any) => {
                                        handleChange(event);
                                      }}
                                      name='allowSchedulingAppointment'
                                      className='check-schedule'
                                    />
                                    <span className='label-check-scheduling'>
                                      Allow appointments to be scheduled within
                                      Vet Hero
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='setting__content--full-row flex'>
                        <div className='form-input-settings width-50'>
                          <div className='d-flex flex-align-start'>
                            <div className='flex1050'>
                              <span className='title-input-form'>
                                Sync Interval (Minutes)<b className='red'> *</b>
                                <i
                                  className='fa fa-question-circle'
                                  aria-hidden='true'
                                />
                              </span>
                              <Tooltip
                                placement='right'
                                title={text}
                                arrow
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <span className='questions'>
                                  <FontAwesomeIcon
                                    icon={['fas', 'question-circle']}
                                  />
                                </span>
                              </Tooltip>

                              <div className='input-minutes'>
                                <Field
                                  name='interval'
                                  maxLength={255}
                                  className='setting__search-form'
                                  onChange={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    if (!formikProps.touched.interval) {
                                      formikProps.setFieldTouched(
                                        'interval',
                                        true
                                      );
                                    }
                                    if (e.target.value !== '0') {
                                      handleChange(e);
                                    }
                                  }}
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'interval',
                                      e.target.value.trim()
                                    );
                                  }}
                                />

                                <p className='error-msg'>
                                  <ErrorMessage name='interval' />
                                </p>
                              </div>
                            </div>
                            <div className='flex1050  custom-height'>
                              <span className='title-input-form'>
                                Skip Appointment Codes{' '}
                                <i
                                  className='fa fa-question-circle'
                                  aria-hidden='true'
                                />
                              </span>
                              <Tooltip
                                placement='right'
                                title={skipAppointmentCodes}
                                arrow
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <span className='questions'>
                                  <FontAwesomeIcon
                                    icon={['fas', 'question-circle']}
                                  />
                                </span>
                              </Tooltip>

                              <div className='input-codes mt-0 custom-dropdown'>
                                <Field
                                  // className={formikProps.values.appointmentTypes.length && "autocomplete--padding"}
                                  name='skipAppointmentCodes'
                                  component={Autocomplete}
                                  multiple
                                  options={appointmentTypesSkip}
                                  defaultValue={getDefaultSkipAppoint(
                                    generalSettingData?.skipAppointmentCodes ??
                                      ''
                                  )}
                                  disableCloseOnSelect
                                  getOptionLabel={(option: AppointmentType) =>
                                    option.appointmentType
                                  }
                                  noOptionsText={'No appointment type found'}
                                  onChange={(
                                    e: any,
                                    value: AppointmentType[]
                                  ) => {
                                    formikProps.setFieldValue(
                                      'skipAppointmentCodes',
                                      value
                                    );
                                  }}
                                  getOptionSelected={(
                                    option: AppointmentType,
                                    value: AppointmentType
                                  ) => {
                                    return option.id === value.id;
                                  }}
                                  // renderOption={(option: AppointmentType, { selected }: any) => {
                                  //   return (
                                  //     <React.Fragment>
                                  //       <Checkbox
                                  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  //         style={{ marginRight: 8, color: '#1974ff' }}
                                  //         checked={selected}
                                  //         className="checked-role"
                                  //       />
                                  //       {option.appointmentType}
                                  //     </React.Fragment>
                                  //   )
                                  // }
                                  // }
                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span
                        className='title-input-form ml-10'
                        style={{ width: '10%' }}
                      >
                        Sync Schedule
                        <i
                          className='fa fa-question-circle'
                          aria-hidden='true'
                        ></i>
                      </span>
                      <Tooltip
                        placement='right'
                        title={syncSchedule}
                        arrow
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <span className='questions'>
                          <FontAwesomeIcon icon={['fas', 'question-circle']} />
                        </span>
                      </Tooltip>

                      <div className='setting__content--full-row w-50'>
                        <div className='form-input-settings width-50 '>
                          <div className='d-flex'>
                            <div className=' flex1025'>
                              <span className='title-input-form'>
                                Start Time
                              </span>

                              <Field
                                id='time'
                                type='time'
                                value={values.startTimePractice}
                                name='startTimePractice'
                                className='setting__search-form date-time'
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'startTimePractice',
                                    e.target.value
                                  );
                                }}
                              />
                            </div>

                            <div className='flex1025'>
                              <span className='title-input-form'>End Time</span>

                              <Field
                                id='time'
                                type='time'
                                value={values.endTimePractice}
                                name='endTimePractice'
                                className='setting__search-form date-time'
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'endTimePractice',
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {formikProps.errors.endTimePractice && (
                            <p
                              className='error-msg'
                              style={{ margin: '5px 10px' }}
                            >
                              {formikProps.errors.endTimePractice}
                            </p>
                          )}
                        </div>
                        <div className='w-50'>
                          <div className='form-input-settings'>
                            <div className=' flex1025'>
                              <span className='title-input-form'>
                                Restart Syncer Service
                              </span>
                              <Field
                                id='time'
                                type='time'
                                value={values.restartTimePractice}
                                name='restartTimePractice'
                                className='setting__search-form date-time'
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'restartTimePractice',
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <p className='title--text ml-10' style={{ paddingTop: '25px', paddingBottom: '10px' }}>Writebacks</p>
                      <div className='setting__content--full-row flex'>
                        <div className='form-input-settings width-50'>
                          <div className='d-flex'>
                            <div className='flex1050'>
                              <span className='title-input-form'>
                                A patient confirms an appointment
                              </span>
                              <div className='input-options'>
                                <FastField
                                  classNamePrefix="search-city"
                                  component={Select}
                                  isSearchable={false}
                                  name='patientConfirmStatus'
                                  options={appointmentStatusOption}
                                  defaultValue={(generalSettingData?.patientConfirmStatus ? (appointmentStatusOption.filter(item => {
                                    return item.value === generalSettingData?.patientConfirmStatus;
                                  })[0]) : appointmentStatusOption[0])}
                                  onBlur={() => {
                                    setFieldTouched('patientConfirmStatus', true);
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue("patientConfirmStatus", e);
                                  }}
                                  maxMenuHeight={150}
                                />
                              </div>
                            </div>
                            <div className='flex1050'>
                              <span className='title-input-form'>
                                You confirm an appointment through Vet Hero
                              </span>
                              <div className='input-options'>
                                <FastField
                                  classNamePrefix="search-city"
                                  component={Select}
                                  isSearchable={false}
                                  name='practiceConfirmStatus'
                                  defaultValue={(generalSettingData?.practiceConfirmStatus ? (appointmentStatusOption.filter(item => {
                                    return item.value === generalSettingData?.practiceConfirmStatus;
                                  })[0]) : appointmentStatusOption[0])}
                                  options={appointmentStatusOption}
                                  onBlur={() => {
                                    setFieldTouched('practiceConfirmStatus', true);
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue("practiceConfirmStatus", e);
                                  }}
                                  maxMenuHeight={150}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <Button
                        disabled={!isValid}
                        variant='contained'
                        className='button-form ml-10'
                        color='primary'
                        type='submit'
                      >
                        <span>Save</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default GeneralSettings;
