import React from 'react';
import { FormFieldBase } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';

export const FormFieldEstimate = ({ field }) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { estimateContent } = getFieldPropertyValues(properties.specific, 'estimateContent');

  return (
    <div className='estimate'>
      {(estimateContent != undefined && Object.keys(estimateContent).length != 0) ?
        <div className='estimate-content'>
          <div className='estimate-top-container'>
            <div className='estimate-top-container-left'>
              <span style={{"fontWeight": "bold"}}>Customer</span>
              <span>{estimateContent.contactFirstName} {estimateContent.contactLastName}</span>
              <span>{estimateContent.contactEmail}</span>
            </div>
            <div className='estimate-top-container-right'>
              <span>{estimateContent.estimateDate}</span>
              <span>{estimateContent.estimateDesc}</span>
            </div>
          </div>
          <div className='estimate-top-container-left'>
            <span style={{"fontWeight": "bold"}}>For {estimateContent.petName}</span>
            <span>Sex: {estimateContent.petSex}</span>
            <span>Breed: {estimateContent.petBreed}</span>
            <span>Birthdate: {estimateContent.petDOB}</span>
          </div>
          <div className='estimate-table-container'>
            <table className='estimate-table'>
              <tr>
                <th>Description</th>
                <th>Low quantity</th>
                <th>High quantity</th>
                <th>Low price</th>
                <th>High price</th>
              </tr>
              {estimateContent.estimateItems?.map(ei => {
                return (
                  <tr>
                    <td>{ei.itemDescription}</td>
                    <td>{Number(ei.lowQuantity.toFixed(2))}</td>
                    <td>{Number(ei.highQuantity.toFixed(2))}</td>
                    <td>${ei.lowPrice.toFixed(2)}</td>
                    <td>${ei.highPrice.toFixed(2)}</td>
                  </tr>
                )
              })}
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td>${estimateContent.estimateItems.map(ei => ei.lowPrice).reduce((ei1, ei2) => ei1 + ei2).toFixed(2)}</td>
                <td>${estimateContent.estimateItems.map(ei => ei.highPrice).reduce((ei1, ei2) => ei1 + ei2).toFixed(2)}</td>
              </tr>
            </table>
          </div>
        </div>
        : <span>Treatment estimate field. Will show nothing unless used in tandem with the Estimates feature.</span>}
    </div>
  );
};

const controls = {
  edit: true,
  delete: true,
  more: true,
  // more: __DEV__,
};
export default FormFieldBase(FormFieldEstimate, controls);
