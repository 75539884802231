/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
import React from 'react';
/* Layout and Structure components */
import { TinyMCEEditor as RichTextEditor } from 'components/Legwork';
import PropTypes from 'prop-types';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AppLink from '../../../app-link';
import { getMapFromArray } from './form-field-types/helpers';
import Modal from '../../../modal';
// import RichTextEditor from '../../../rich-text-editor';
import * as ConfigComponents from './config-helper-components';

class FormFieldConfig extends React.Component {
  constructor(props) {
    super(props);

    this.propertyTypeHandlers = {
      checkbox: ConfigComponents.Checkbox,
      input: ConfigComponents.Input,
      select: ConfigComponents.Select,
      single: ConfigComponents.Single,
      multiple: ConfigComponents.Multiple,
      group: ConfigComponents.Group,
      'option-group': ConfigComponents.OptionGroup,
      'rich-text': ConfigComponents.RichText,
      customProps: {
        group: {
          getSpecificProps: () =>
            getMapFromArray(this.props.field.properties.specific || []),
        },
        'rich-text': {
          showModal: this.showRichTextModal,
        },
      },
    };

    this.defaultState = {
      showModal: false,
      modalTitle: '',
      rteContent: '',
      propertyName: '',
    };
    this.state = this.defaultState;
  }

  handleBackClick = (e) => {
    e.preventDefault();
    this.props.onFieldClick(null);
  };

  handleChange = (data, name, index) => {
    const { field, parent } = this.props;
    const { common = [[]], specific = [[]] } = field.properties || {};
    const commonProps = getMapFromArray(common);
    const specificProps = getMapFromArray(specific);
    const propertyName = name || data.target.name;
    const isCommonProp = specificProps.get(propertyName)
      ? false
      : !!commonProps.get(propertyName);
    let value;

    if (typeof data === 'string') value = data;
    else if (data.hasOwnProperty('target')) {
      if (data.target.type === 'checkbox') value = data.target.checked;
      else value = data.target.value;
    } else value = data;

    this.props.onFieldChange(
      field,
      parent,
      isCommonProp,
      propertyName,
      value,
      index
    );
  };

  handleSubfieldChange = (e, subfield) => {
    const { field, parent } = this.props;
    const propertyName = e.target.name.split('-')[0];
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.props.onSubfieldChange(subfield, field, parent, propertyName, value);
  };

  showRichTextModal = (show, name, label, value) => {
    this.setState({
      showModal: show,
      modalTitle: label,
      rteContent: value,
      propertyName: name,
    });
  };

  closeRichTextModal = (accepted, propertyName) => {
    if (accepted) {
      this.handleChange(this.state.rteContent, propertyName);
    }
    this.setState(this.defaultState);
  };

  handleTextChange = (val) => {
    this.setState({ rteContent: val });
  };

  render() {
    const { field } = this.props;
    const { isLocked } = field;
    const commonProps = getMapFromArray(field.properties.common);
    const fieldSpecificProps = getMapFromArray(field.properties.specific);
    const showCommonProps = commonProps && commonProps.size > 0;
    const showFieldSpecificProps =
      fieldSpecificProps && fieldSpecificProps.size > 0;
    const hasSubfields = field.fields && field.fields.length > 0;
    const { showModal, modalTitle, propertyName } = this.state;

    // First, filter out any common props that have a matching named field-specific prop
    // This allows custom overriding of common props, if needed, by a given form field
    const fieldSpecificPropsArray = Array.from(fieldSpecificProps);
    const commonPropsArray = Array.from(commonProps).filter(
      (common) =>
        !fieldSpecificPropsArray.find((specific) => specific[0] === common[0])
    );

    return (
      <div className='form-field-config'>
        {showModal && (
          <Modal
            title={modalTitle}
            closeText='Cancel'
            acceptText='Save'
            renderCloseHead={false}
            backdrop='static'
            className='modal-richtext'
            onClose={(accepted) =>
              this.closeRichTextModal(accepted, propertyName)
            }
          >
            <RichTextEditor
              handleChange={this.handleTextChange}
              initialValue={this.state.rteContent}
            />
          </Modal>
        )}
        <AppLink to='javascript:void 0' onClick={this.handleBackClick}>
          <span className='back-link'>
            <ChevronLeft
              style={{ paddingRight: '6px' }}
              className='icon-angle-left'
            />
            Back to all Fields
          </span>
        </AppLink>
        <div className='title'>
          <strong>{`'${field.display_name}'`}</strong> Field configuration
        </div>
        {/* Render common props */}
        {showCommonProps && (
          <div className='config-pane common'>
            {/* Render checkboxes separately */}
            {commonPropsArray
              .filter((p) => p[1].type !== 'checkbox' || p[1].isDynamic)
              .map((prop, index) => {
                const property = prop[1];

                const commonProps = {
                  key: index,
                  propertyName: prop[0],
                  property,
                  index,
                  onChange: this.handleChange,
                  isLocked,
                };

                // if this is a dynamic property (i.e., can have 1:many) AND this field has sub-fields
                // then call appropriate render method to render an input for each sub-field of this property.type
                if (property.isDynamic && hasSubfields) {
                  return (
                    <ConfigComponents.Dynamic
                      {...commonProps}
                      field={field}
                      onChange={this.handleSubfieldChange}
                    />
                  );
                }
                if (['input', 'select'].includes(property.type)) {
                  const PropertyComponent =
                    this.propertyTypeHandlers[property.type];
                  return <PropertyComponent {...commonProps} />;
                }
              })}
            {/* Render checkboxes separately */}
            <div className='checkbox-container'>
              {commonPropsArray
                .filter((p) => p[1].type === 'checkbox' && !p[1].isDynamic)
                .map((prop, index) => {
                  const property = prop[1];
                  return (
                    <ConfigComponents.Checkbox
                      key={index}
                      propertyName={prop[0]}
                      property={property}
                      index={index}
                      onChange={this.handleChange}
                      isLocked={isLocked}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {/* Render field-specific props */}
        {showFieldSpecificProps && (
          <div className='config-pane field-specific'>
            {fieldSpecificPropsArray.map((prop, index) => {
              const property = prop[1];

              // check if 'visible' property exists and, if it does,
              // if its logic is tied to another property
              let isVisible = true;
              if (property.hasOwnProperty('visible')) {
                if (property.visible.hasOwnProperty('mappedPropKey')) {
                  const { mappedPropKey, mappedPropValue } = property.visible;
                  // use mapped property key and property Map to check visibility
                  isVisible =
                    fieldSpecificProps.get(mappedPropKey)[mappedPropValue];
                } else {
                  isVisible = property.visible;
                }
              }
              if (!isVisible) return null;

              let input;
              let PropertyComponent;
              let customProps;

              if (property.type) {
                PropertyComponent = this.propertyTypeHandlers[property.type];
                customProps =
                  this.propertyTypeHandlers.customProps[property.type];
                input = (
                  <PropertyComponent
                    key={index}
                    propertyName={prop[0]}
                    property={property}
                    index={index}
                    onChange={this.handleChange}
                    isLocked={isLocked}
                    {...customProps}
                  />
                );
              }
              // wrap input element with help text, if present
              if (property.helpText && property.helpText !== '') {
                input = (
                  <div
                    key={prop[0]}
                    className={`input-help-container ${
                      property.className || ''
                    }`}
                  >
                    {input}
                    <p>{property.helpText}</p>
                  </div>
                );
              }
              return input;
            })}
          </div>
        )}
      </div>
    );
  }
}

FormFieldConfig.propTypes = {
  field: PropTypes.object.isRequired,
  parent: PropTypes.object,
  onFieldClick: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSubfieldChange: PropTypes.func,
};

FormFieldConfig.defaultProps = {
  parent: undefined,
  onFieldClick: () => {},
  onFieldChange: () => {},
  onSubfieldChange: () => {},
};

export default FormFieldConfig;
