import styled from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';
import { getThemeProp } from '../utils';

const StyledTooltip = styled(Tooltip)`
  font-family: ${getThemeProp('typography.fontFamily')};
  font-size: 0.8rem;
  color: ${getThemeProp('palette.primary.main')};
  svg {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
`;

export default StyledTooltip;
