import React, { useEffect, useCallback } from "react";
import { orderBy, isEmpty } from 'lodash';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { configuredRequests } from "global/requests/ConfiguredRequests";
import { keyColumnFields } from "global/requests/ResponseTypes";
import { useRouter } from 'hooks/useRouter';
import loading_spinner from 'media/images/loading-spinner.svg';
// import { CategoryKindsEnum } from "global/requests/api-route-types";

interface Column {
  id: keyColumnFields;
  label: string;
}

interface DataAutomationAppointment {
  name: string;
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Automation Trigger',
  }
];

interface IProps {
  kind: string
}

const Appointment = (props: IProps): JSX.Element => {
  const { kind } = props;
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [dataAutomationAppointment, setDataAutomationAppointment] = React.useState<DataAutomationAppointment[]>([]);

  const getAutomationTriggerAppointmentList = useCallback(async () => {
    setIsLoading(true);
    try {
      if (kind) {
        const appointmentListResponse = await configuredRequests.GET.automationTriggerByKind(kind);
        const dataAutomationAppointment = appointmentListResponse.items.map(item => {
          return {
            name: item.name,
            id: item.id,
            automationTriggerKey: item.automationTriggerKey,
            automationCategoryId: item.automationCategoryId
          }
        });
        const dataAppointmentTable = orderBy(dataAutomationAppointment, [item => item.name ? item.name.toLocaleLowerCase() : []], ['asc']);
        setDataAutomationAppointment(dataAppointmentTable as DataAutomationAppointment[]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setDataAutomationAppointment([]);
      console.error("err", err);
    }
  }, [kind]);

  useEffect(() => {
    getAutomationTriggerAppointmentList();
  }, [getAutomationTriggerAppointmentList]);

  return (
    <div className="communication-container  w-300">
      {isLoading ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} /> :
        !isEmpty(dataAutomationAppointment) ? <div className="communication-container__table">
          <div className="automation-appointment-table">
            <CommunicationTable
              columns={columns}
              dataTable={dataAutomationAppointment}
              isLoading={isLoading}
              isSort={false}
              recordsPerPage={10}
              screen={"AUTOMATION"}
              router={`${router.match.path}/AutomationDetail`}
              category={kind}
            />
          </div>
        </div> : <p className='no-data-results' style={{ fontWeight: 'bold' }}>
            No results found</p>
      }
    </div>
  );
}

export default Appointment;