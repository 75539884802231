import React from 'react';
import { FontAwesomeIconProps, FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBlurCustomDropdown } from 'hooks/useBlurCustomDropdown';

interface FAIconDropdownProps {
  iconProps: FontAwesomeIconProps,
  // setIsFocused: React.Dispatch<React.SetStateAction<boolean>>,
  listOptions: {
    label: string,
    value: string,
    disable?: boolean,
  }[],
  handleListItemClick: (listItemValue: string) => void,
  customClasses?: {
    containerDiv?: string,
    FontAwesomeIcon?: string
  }
};

interface CustomDataLIElement extends HTMLLIElement {
  "data-value": string
};

// This component renders a FontAwesome icon as a dropdown menu button
// Options and click handler are taken as props to maximize reuse
const FAIconDropdown = (props: FAIconDropdownProps) => {

  const eventHandlerMenuRef = React.createRef<HTMLUListElement>();
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

  // fire callback when focus moves to something without one of the class strings
  useBlurCustomDropdown(
    ["dropdown--ul", "dropdown--li"],
    () => {
      setIsDropdownOpened(false);
    }
  );

  const handleToggleDropdown = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  return (
    <div
      tabIndex={0}
      className={"font_awesome_icon-dropdown--container " + (props.customClasses?.containerDiv ?? "")}
      onClick={(event: React.SyntheticEvent) => {
        event.stopPropagation();
        handleToggleDropdown();
      }}
      onBlur={(event: any) => {
        // event.stopPropagation();
        setIsDropdownOpened(false);
      }}
      onKeyDown={(event: React.KeyboardEvent) => {
        event.stopPropagation();
        switch (event.key) {
          case " ":
          case "Enter": {
            handleToggleDropdown();
            break;
          }
        }
      }}
    >
      <FontAwesomeIcon
        {...props.iconProps}
        className={props.customClasses?.FontAwesomeIcon ?? undefined}
      />
      <ul
        ref={eventHandlerMenuRef}
        className={"font_awesome_icon-dropdown--ul" + (isDropdownOpened ? " open" : "")}
      // onClick={(event: React.SyntheticEvent) => {
      //   event.stopPropagation();
      //   event.preventDefault();
      //   const currentValue = (event.target as CustomDataLIElement).getAttribute("data-value");
      //   console.log('currentValue', currentValue)
      //   if (currentValue && currentValue.length) {
      //     // props.handleListItemClick(currentValue);
      //     return;
      //   }
      // }}
      >
        {
          props.listOptions.map((option, index) => {
            return (
              <li
                tabIndex={0}
                key={"font_awesome_icon-dropdown-li" + index}
                className="font_awesome_icon-dropdown--li"
                data-value={option.value}
                onMouseDown={(event: any) => {
                  event.stopPropagation();
                  event.preventDefault();
                  props.handleListItemClick(option.value);
                }}
                onClick={(event: React.SyntheticEvent) => {
                  setIsDropdownOpened(false);
                }}
              >
                {option.label}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
};

export default FAIconDropdown;
