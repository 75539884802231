import * as t from 'io-ts';

export const EstimatesResponse = t.type({
    responseId: t.string,
    results: t.string,
    contactId: t.string,
    petId: t.string,
    createdTime: t.string,
});
export type EstimatesResponse = t.TypeOf<typeof EstimatesResponse>;

export const AddEstimatesRequest = t.type({
    tenantId: t.string,
    contactId: t.string,
    petId: t.string,
    externalContactId: t.string,
    externalPetId: t.string,
});
export type AddEstimatesRequest = t.TypeOf<typeof AddEstimatesRequest>;
