const TAB_ACTIVE_LOCAL_STORAGE_DEFAULT_VALUE = {previousLink: '', leftMenuContentActive: 0, rightMenuContentActive: 0};

export const setTabActiveLocalStorage = (previousLink: string | null, leftMenuContentActive: number | null, rightMenuContentActive: number | null) => {
  let tabActiveObject = getTabActiveLocalStorage();
  if (previousLink) tabActiveObject.previousLink = previousLink;
  if (leftMenuContentActive !== null) tabActiveObject.leftMenuContentActive = leftMenuContentActive;
  if (rightMenuContentActive !== null) tabActiveObject.rightMenuContentActive = rightMenuContentActive;
  localStorage.setItem('tab_active', JSON.stringify(tabActiveObject));
}

export const getTabActiveLocalStorage = () => {
  let tabActive = localStorage.getItem('tab_active');
  if (tabActive) {
    return JSON.parse(tabActive);
  }
  setTabActiveDefaultValue();
  return TAB_ACTIVE_LOCAL_STORAGE_DEFAULT_VALUE;
}

export const setTabActiveDefaultValue = () => {
  localStorage.setItem('tab_active', JSON.stringify(TAB_ACTIVE_LOCAL_STORAGE_DEFAULT_VALUE));
}
