/* eslint-disable no-useless-escape */
import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { checkValidDateFormat } from '../validators';
import 'react-day-picker/lib/style.css';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear + 10, 11);
const weekdayMappings = {
  Sundays: 0,
  Mondays: 1,
  Tuesdays: 2,
  Wednesdays: 3,
  Thursdays: 4,
  Fridays: 5,
  Saturdays: 6,
};

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className='DayPicker-Caption'>
      <select name='month' onChange={handleChange} value={date.getMonth()} style={{ width: '50%' }}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name='year'
        onChange={handleChange}
        value={date.getFullYear()}
        style={{ width: '30%' }}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

const isInvalidDateInstance = val => val instanceof Date && isNaN(val);

export default class FormFieldInputDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: new Date(),
      selectedDay: undefined,
      isValidDay: true,
    };
  }

  componentDidMount() {
    const inputDates = document.querySelectorAll(".date-picker input");
    inputDates?.length > 0 && inputDates.forEach(input => input.setAttribute("autocomplete", "off"));
  }

  formatDate = date => {
    const format = this.props.placeholder.toUpperCase();
    const isValidFormat = checkValidDateFormat(date, { dateFormat: format }).valid;
    if (this.state.isValidDay && isValidFormat) {
      return moment(date).format(format);
    }
    return this.state.selectedDay;
  };

  parseDate = (str, format) => {
    const dateTest = moment(str, format, true);
    const isValidFormat = checkValidDateFormat(str, { dateFormat: format }).valid;
    if (isValidFormat && !isInvalidDateInstance(str)) {
      return dateTest.toDate();
    }
    return void 0;
  };

  getDayPickerProps = () => {
    const { minDate, maxDate, disabledWeekdays } = this.props;
    let daysOfWeek;
    const pickerProps = {
      // props for handling month/year selection
      month: this.state.month,
      fromMonth,
      toMonth,
      captionElement: ({ date, localeUtils }) => (
        <YearMonthForm
          date={date}
          localeUtils={localeUtils}
          onChange={this.handleYearMonthChange}
        />
      ),
      modifiers: {},
    };

    const before = { before: minDate || fromMonth };
    const after = { after: maxDate || toMonth };
    if (disabledWeekdays)
      daysOfWeek = {
        daysOfWeek: disabledWeekdays.map(weekday => weekdayMappings[weekday[0]]),
      };

    pickerProps.modifiers.disabled = [before, after, daysOfWeek];

    return pickerProps;
  };

  handleYearMonthChange = month => this.setState({ month });

  handleDayChange = (day, modifiers, dayPickerInput) => {
    const isValidDay = typeof day !== 'undefined';
    this.setState({
      isValidDay,
      selectedDay: isValidDay ? day : dayPickerInput.input.value,
    });
    if (day) this.props.onChange(day);
  };

  handleBlur = ({ target, relatedTarget }) => {
    const { isValidDay, selectedDay } = this.state;
    const dateVal = isValidDay ? selectedDay : target.value;

    // don't call onChange if this blur event was triggered by user
    // selecting either the month or year dropdowns of the date picker component
    if (!relatedTarget || !['year', 'month'].includes(relatedTarget.name))
      this.props.onChange(dateVal);
  };

  handleKeyPress = e => {
    if (!/\d|\/|\-|\./.test(e.key)) e.preventDefault();
  };

  render() {
    const {
      className,
      placeholder,
      sublabel,
      id,
      onBlur,
      value,
      disabled,
      readOnly,
    } = this.props;

    const { classNames } = DayPickerInput.defaultProps;
    return (
      <div className={`${className} date-picker`}>
        <div>
          <DayPickerInput
            classNames={{ ...classNames, container: `${classNames.container} full-width` }}
            placeholder={placeholder}
            format={placeholder.toUpperCase()}
            formatDate={this.formatDate}
            parseDate={this.parseDate}
            dayPickerProps={this.getDayPickerProps()}
            onDayChange={this.handleDayChange}
            onDayPickerHide={onBlur}
            value={value}
            inputProps={{
              id,
              disabled,
              readOnly,
              onKeyPress: this.handleKeyPress,
              onBlur: this.handleBlur,
            }}
          />
          <div className='form-field-label secondary'>{sublabel}</div>
        </div>
      </div>
    );
  }
}

FormFieldInputDate.defaultProps = {
  className: '',
  sublabel: '',
};
