import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledButton } from './styled-components';

export interface PrimaryButtonProps {
  title: string;
  icon?: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  'data-testid'?: string;
  className?: string;
}

const PrimaryButton = (props: PrimaryButtonProps): React.ReactElement => {
  const {
    title,
    onClick,
    disabled = false,
    icon,
    'data-testid': dataTestId,
    className = '',
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      size='medium'
      data-testid={dataTestId}
      className={className}
    >
      <Grid container justify='center'>
        {icon && (
          <Grid item data-testid='display-icon'>
            {icon}
          </Grid>
        )}
        <Grid item>
          <span>{title}</span>
        </Grid>
      </Grid>
    </StyledButton>
  );
};

export default PrimaryButton;
