import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './AppointmentSetting.scss';
import AppointmentType from './AppointmentType/AppointmentType';
import Availability from './Availability/AvailabilityVM';
import AvailabilityProvider from './Availability/context/Availability';
import Providers from './Providers/Providers';

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

const TabContainer = (props: TabContainerProps) => {
  return <Typography component='div'>{props.children}</Typography>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppointmentSetting = (): JSX.Element => {
  const [activeComponent, setActiveComponent] = useState<number>(0);

  const renderContainer = () => {
    switch (activeComponent) {
      case 0: {
        return (
          <TabContainer id={0}>
            <AvailabilityProvider>
              <Availability />
            </AvailabilityProvider>
          </TabContainer>
        );
      }
      case 1: {
        return (
          <TabContainer id={1}>
            <AppointmentType />
          </TabContainer>
        );
      }
      case 2: {
        return (
          <TabContainer id={2}>
            <Providers />
          </TabContainer>
        );
      }
    }
    return (
      <TabContainer id={0}>
        <AvailabilityProvider>
          <Availability />
        </AvailabilityProvider>
      </TabContainer>
    );
  };

  const tabs = ['Availability', 'Appointment Type', 'Provider'];

  return (
    <div className='communication-analyze appointment-booking__setting'>
      <div className='communication-analyze__right-modal'>
        <ul className='analyze-tab__lists'>
          {tabs.map((tab, index) => (
            <li
              role='button'
              key={'communication-templates-nav-' + index}
              tabIndex={0}
              className={
                'analyze-tab__item-list ' +
                (index === activeComponent ? 'active' : '')
              }
              onClick={() => {
                setActiveComponent(index);
              }}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      <div className='template-appointment-email'>{renderContainer()}</div>
    </div>
  );
};

export default AppointmentSetting;
