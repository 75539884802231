/* eslint-disable react-hooks/exhaustive-deps */
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import FolderIcon from '@material-ui/icons/Folder';
import FolderModal from 'components/FormBuilder/components/FormBuilder/forms/components/folder-modal';
import React, { useEffect, useState } from 'react';
import { isWindows } from 'react-device-detect';

import {
  BorderlessButton,
  MediumEmphasizedDarkText,
  VerticalTabMenu,
} from 'components/Legwork';
// import { loadFolders, loadForms } from 'components/FormBuilder/components/FormBuilder/tempData/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import PaginationForFormBuilder from './components/PaginationForFormBuilder';
import VerticalTabContainer from './styled-components';

const filterValidFolders = (folders, filter, forms) => {
  // const result = folders.filter(folder => {
  //   return !filter || forms.findIndex(form => form.form_folder_id === folder.id) >= 0;
  // });
  // return result.filter(folder => {
  return folders.filter((folder) => {
    // folders with 0 forms are valid since they might be new / empty folders
    return (
      folder.Forms.length === 0 ||
      !!folder.Forms.find((form) =>
        forms.find((stateForm) => stateForm.id === form.id)
      )
    );
  });
};

const FormBuilder = (props) => {
  const [forms, setForms] = useState([]);
  const [folders, setFolders] = useState([]);
  const [filter, setFilter] = useState('');
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [areTabsHidden, setAreTabsHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [errorResponse, setErrorResponse] = useState('');

  const folderLoader = async (filter, forms) => {
    setLoading(true);
    try {
      const tenantId = JSON.parse(window.sessionStorage.getItem('JWT') || '{}')
        .aud[0];
      let response;
      if (filter) {
        response = await configuredRequests.GET.getFolderOfUser(tenantId, {
          formName: filter,
        });
        //@ts-ignore
        response.data.map((item)=>{
          //@ts-ignore
          item.Forms?.sort((a,b)=> a.form_name.toLowerCase() < b.form_name.toLowerCase() ? -1 : 1)
        })
      } else {
        response = await configuredRequests.GET.getFolderOfUser(tenantId);
        //@ts-ignore
        response.data.map((item)=>{
          //@ts-ignore
          item.Forms?.sort((a,b)=> a.form_name.toLowerCase() < b.form_name.toLowerCase() ? -1 : 1)
        })
      }
      let folders = response.data;

      if (response.status === 200) {
        folders = filterValidFolders(folders, filter, forms);
        folders.sort((folderA, folderB) => {
          return folderA?.folder_name?.localeCompare(
            folderB.folder_name,
            'en',
            {
              sensitivity: 'base', // case-insensitive sort
            }
          );
        });
        folders = folders.filter((folder) => !folder.is_deleted);
        // if (filter)
        //   folders = folders.filter(folder => {
        //     const regEx = new RegExp(filter, 'gi');
        //     return regEx.test(folder.folder_name);
        //   });
      }
      setLoading(false);
      return folders;
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  const formLoader = async (filter) => {
    setLoading(true);
    try {
      const tenantId = JSON.parse(window.sessionStorage.getItem('JWT') || '{}')
        .aud[0];
      let response;
      if (filter) {
        response = await configuredRequests.GET.getFormOfFolder(tenantId, {
          formName: filter,
        });
      } else {
        response = await configuredRequests.GET.getFormOfFolder(tenantId);
      }
      if (response.status === 200) {
        // let forms = response.data;
        let forms = response.data.filter((form) => !form.is_deleted);
        return forms.sort((a,b)=> a.form_name.toLowerCase() < b.form_name.toLowerCase() ? -1 : 1);
        // if (filter)
        //   forms = forms.filter(form => {
        //     const regEx = new RegExp(filter, 'gi');
        //     return regEx.test(form.form_name);
        //   });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    updateForms();

    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  useEffect(() => {
    updateFolders();
  }, [forms, filter]);

  const updateFolders = () => {
    folderLoader(filter, forms).then((res) => setFolders(res));
  };

  const updateForms = () => {
    formLoader().then((res) => setForms(res));
  };

  const toggleTabs = () => {
    setAreTabsHidden(!areTabsHidden);
  };

  // const handleTextSearchChange = debounce(event => {
  const handleTextSearchChange = (event) => {
    // setRemoveFilter(false);
    // if (!event.target.value) {
    //   setShowIconRemoveSearch(false);
    // } else {
    //   setShowIconRemoveSearch(true);
    // }
    // setFilter(event.target.value);
    setFilter(event);
  };
  // }, 500);

  const handleSearchForm = (event) => {
    event.preventDefault();
    const textSearch = event.target.value;
    setSearchValue(textSearch);
    handleTextSearchChange(textSearch);
  };

  const menuItems = folders?.map((item) => ({
    text: (
      <MediumEmphasizedDarkText
        style={{ marginBottom: '0px', fontSize: '15px', marginTop: '5px' }}
      >
        <FolderIcon
          style={{
            verticalAlign: 'middle',
            marginRight: '8px',
            fontSize: '24px',
            marginTop: '-5px',
          }}
        />
        <HighlightText text={item.folder_name} searchValue={filter.trim()} />
      </MediumEmphasizedDarkText>
    ),
    component: (
      <PaginationForFormBuilder
        forms={forms.filter((form) => item.Forms.find((i) => i.id === form.id))}
        folder={item}
        folders={folders}
        updateFolders={updateFolders}
        updateForms={updateForms}
        toggleVerticalTabs={toggleTabs}
        isRetricted={item.is_restricted}
        loading={loading}
        filter={filter}
      />
    ),
  }));

  const openFolderModal = () => {
    setFolderModalOpen(true);
  };
  const closeFolderModal = () => {
    setErrorResponse('');
    setFolderModalOpen(false);
  };

  const saveFolder = async (folder, folderName) => {
    await handleFolderChange(folder, folderName);
  };

  const handleFolderChange = async (folder, folderName) => {
    const tenantData = JSON.parse(
      window.sessionStorage.getItem('tenantInfo--full_response') || ''
    );
    // TODO: [CHECK]This handle is intended to rename folder but currently it 's treated as handler for create new folder (#L244)
    try {
      const response = await configuredRequests.POST.createFolder(
        tenantData.tenantId,
        folderName
      );
      if (response.status === 200) {
        closeFolderModal();
        await updateFolders();
      }
    } catch (error) {
      if (error?.response?.data?.message === 'The folder name has existed.') {
        setErrorResponse('The folder name has existed.');
      }
    }
  };

  return (
    <>
      <FolderModal
        action='Create'
        closeModal={closeFolderModal}
        onSave={saveFolder}
        openModal={folderModalOpen}
        folder={null}
        isAddFolder
        error={errorResponse}
        setErrorResponse={setErrorResponse}
      />
      <VerticalTabContainer className='custom-scroll'>
        <VerticalTabMenu
          PreTabElement={
            <div className='form-search contacts--filter-container'>
              {/* <OutlinedInputWithIcon
              title='Search Forms'
              removeFilter={removeFilter}
              onChange={handleTextSearchChange}
            />
            <ClearIcon className={showIconRemoveSearch ? "show" : ""} onClick={() => {
              setShowIconRemoveSearch(false);
              setRemoveFilter(true);
              setFilter("");
            }}/> */}
              <div className='contacts--filter-search-container'>
                <FontAwesomeIcon
                  icon={['far', 'search']}
                  onClick={() => handleTextSearchChange(searchValue)}
                />

                <input
                  className='contacts--filter--search-input'
                  // ref={searchInputRef}
                  value={searchValue}
                  type='search'
                  placeholder='Search Forms'
                  onChange={handleSearchForm}
                />
              </div>
            </div>
          }
          filter={filter}
          menuItems={menuItems}
          uniqueId='PaperlessForms'
          hideTabs={areTabsHidden}
          PostTabElement={
            <BorderlessButton
              style={{
                paddingLeft: '28px',
                fontSize: '24px',
                paddingBottom: '8px',
              }}
              justifyContent='flex-start'
              title={
                <MediumEmphasizedDarkText
                  style={{
                    marginBottom: '0px',
                    fontSize: '14px',
                    fontFamily: 'Nunito Sans',
                    marginTop: '17px',
                    color: '#4c7cfd',
                    fontWeight: '600',
                    textTransform: 'none',
                  }}
                >
                  Add a Folder
                </MediumEmphasizedDarkText>
              }
              icon={
                <CreateNewFolderIcon
                  style={{
                    verticalAlign: 'middle',
                    color: '#4c7cfd',
                    fontSize: '24px',
                    marginTop: '3px',
                    marginRight: '8px',
                    width: '24px',
                  }}
                />
              }
              onClick={openFolderModal}
            />
          }
        />
      </VerticalTabContainer>
    </>
  );
};

export default FormBuilder;
