import React from 'react';
import classNames from 'classnames';
import { Multiple } from './properties-config';
import { FormFieldBase, getFieldProperties, getFieldPropertyValues } from '.';
import { FormFieldInputCheckbox } from './form-field-inputs';
import ValidationMessage from './validation-message';

export const FormFieldMultiple = ({
  field,
  className,
  commonProps,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const getOptionValue = (option, value) => {
    if (!isRenderedField) return;

    return value.find(val => Object.keys(val)[0] === option)[option];
  };

  const handleChange = (e, group, label, otherEl) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    // define custom event object
    const event = {
      target: {
        id: group,
        value: { [`${label}`]: value },
        el: e.target,
        otherEl,
      },
    };

    onChange(event);
  };

  const handleBlur = () => {
    onBlur({
      target: {
        id: field.uuid,
        value,
      },
    });
  };

  const localProps = new Map(Multiple);
  const propertyNames = ['options', 'displayOther', 'other', 'displayAsColumns', 'numColumns'];
  const defaultValues = propertyNames.map(propName => ({
    [`${propName}`]: localProps.get(propName).defaultValue,
  }));
  const properties = getFieldProperties(field, 'specific');
  const { options, displayOther, other, displayAsColumns, numColumns } = getFieldPropertyValues(
    properties.specific,
    propertyNames,
    defaultValues,
  );
  const classes = classNames({
    [className]: true,
    multiple: true,
    'control-group error': validationMessage,
  });

  // if this field is being rendered to a published form,
  // we need to add a group prop to the checkboxes to tie
  // back to the parent field
  const group = isRenderedField ? field.uuid : undefined;
  const otherValue = isRenderedField && displayOther ? getOptionValue(other, value) : '';

  return (
    <div className={classes}>
      <div className='form-field-label primary'>{`${commonProps.label} ${
        commonProps.makeRequired ? '*' : ''
        }`}</div>
      <div className='form-field-input'>
        {options.map((option, index) => {
          // render radio input for each option
          return (
            <FormFieldInputCheckbox
              key={index}
              label={option}
              displayAsColumns={displayAsColumns}
              numColumns={numColumns}
              group={group}
              isRenderedField={isRenderedField}
              onChange={handleChange}
              checked={getOptionValue(option, value)}
              onBlur={handleBlur}
              disabled={readOnly}
            />
          );
        })}
        {displayOther && (
          // render an 'Other' option if selected
          <FormFieldInputCheckbox
            className='other'
            isOther
            label={other}
            displayAsColumns={displayAsColumns}
            numColumns={numColumns}
            group={group}
            isRenderedField={isRenderedField}
            onChange={handleChange}
            checked={otherValue}
            value={otherValue}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        )}
        {commonProps.sublabel && (
          <div className='form-field-label secondary'>{commonProps.sublabel}</div>
        )}
        <ValidationMessage message={validationMessage} />
      </div>
    </div>
  );
};

FormFieldMultiple.defaultProps = {
  className: '',
  commonProps: {},
  isRenderedField: false,
  onChange: () => { },
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldMultiple);
