import styled from 'styled-components';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const DetailsText = styled.p`
  color: ${getThemeProp('palette.text.hint', 'blue')};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  font-size: 15px;
`;

const ExpansionContainer = styled.div`
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiExpansionPanelSummary-content {
    margin: 0;
    p {
      margin: 0;
    }
  }
  .MuiExpansionPanelSummary-root {
    display: inline-flex;
    padding: 0;
    svg {
      fill: ${getThemeProp('palette.text.hint', 'blue')};
      width: 1.35rem;
      height: 1.35rem;
    }
    .MuiButtonBase-root {
      padding: 0;
    }
    min-height: 0;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0;
  }
  margin: 1rem;
`;

export { ExpansionContainer, DetailsText };
