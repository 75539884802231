import styled from 'styled-components';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

const CenteredContainer = styled.div`
  text-align: center;
  margin: 1rem;
`;

const StyledCircularProgress = styled(CircularProgress)`
  & svg {
    height: 35px;
    width: 35px;
  }
`;

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  ${({ theme }): string => `
    fill: ${theme.palette.success.main}
 `}
`;

export { CenteredContainer, StyledCircularProgress, StyledCheckCircleOutlineIcon };
