import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
//types
import {
  NavigationElementWithIcons,
  NavigationIconCustomElement,
} from 'types/NavigationT';
import {
  setTabActiveDefaultValue,
  setTabActiveLocalStorage,
  getTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { isMobile } from 'react-device-detect';
import { setSessionStateNavbarIsOpen } from 'global/sessionStorage/SessionNavbarState';

interface NavigationLinkElementProps extends NavigationElementWithIcons {
  innerText: string;
  linkTo: string;
  customClasses?: string[];
  leftIcon?: string[];
  isNavLinkActive: boolean;
  setSidebarIsOpen: (isOpen: boolean) => void;
}

const generateCombinedClassString = (
  defaultClasses: string,
  customClassList: string[]
): string => {
  return defaultClasses + ' ' + customClassList.join(' ');
};

// this is always assigned as a class to Navigation Link rendered with custom classes
const defaultClassString = 'sidebar__item';

// React.memo tells react that this component is pure (same output from same input)
// this enables some React optimizations
const NavigationLinkElement = React.memo(
  (props: NavigationLinkElementProps): JSX.Element => {
    const createCustomIconElement = (
      elementProps: NavigationIconCustomElement
    ) => {
      if (
        !elementProps ||
        !elementProps.className ||
        !elementProps.elementType
      ) {
        return;
      }
      const messageCount = parseInt(
        window.sessionStorage.getItem(
          props.innerText.trim() === 'Conversations'
            ? 'countContactUnread'
            : props.innerText.trim() === 'Appointment Booking'
            ? 'countUnreadsOab'
            : 'countUnreadSubmissions'
        ) || '0'
      );
      const element = React.createElement(
        elementProps.elementType,
        {
          className:
            messageCount > 0
              ? `${elementProps.className} status--count`
              : elementProps.className,
        },
        [messageCount]
      );
      return element;
    };

    return (
      <li
        className={
          Array.isArray(props.customClasses) && props.customClasses.length
            ? generateCombinedClassString(
                defaultClassString,
                props.customClasses
              )
            : defaultClassString
        }
        onClick={() => {
          if (isMobile) {
            props.setSidebarIsOpen(false);
            setSessionStateNavbarIsOpen(false);
          }
          if (props.isNavLinkActive) {
            let tabActive = getTabActiveLocalStorage();
            if (tabActive.previousLink !== props.linkTo) {
              setTabActiveLocalStorage(props.linkTo, 1, 0);
            }
          } else {
            setTabActiveDefaultValue();
          }
        }}
      >
        <NavLink
          to={props.linkTo}
          className={'sidebar__lnk'}
          activeClassName={'selected'}
          isActive={() => props.isNavLinkActive}
        >
          {props.leftIcon ? (
            <FontAwesomeIcon icon={props.leftIcon as IconProp} />
          ) : null}

          {props.innerText}

          {createCustomIconElement(
            props.rightIcon as NavigationIconCustomElement
          )}
        </NavLink>
      </li>
    );
  }
);

export default React.memo(NavigationLinkElement);
