import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from 'components/Global/Switch/Switch';
import TimePickers from 'components/Global/TimePicker/TimePicker';
import toast from 'components/Global/Toast';
import { compareAsc, parse } from 'date-fns';
import { SystemSettingResponseUpdated } from 'global/requests/ResponseTypes';
import { useDecodeToken } from 'hooks/useDecodeToken';
import { find, orderBy } from 'lodash';
import { compose, map, reduce } from 'lodash/fp';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import React, { useEffect, useState } from 'react';
import { configuredRequests } from '../../../global/requests/ConfiguredRequests';
import {
  defaultInitialDeliveryDay,
  IDeliverySetting,
  initialTimeZoneList,
  ITimeZoneList,
} from './SystemSetting.constant';

const transformDateList = (dateArr: IDeliverySetting[]): IDeliverySetting[] =>
  dateArr.map((item, index) => ({ ...item, id: index.toString() }));

interface ITransformedTimezone {
  title: string;
  offset: string;
  timeZoneId: string;
}

interface SettingCheckbox {
  allowSMSSending: boolean;
  allowEmailSending: boolean;
  allowCallSending: boolean;
  allowPostalSending: boolean;
  adjustDaylightTimeAutomatically: boolean;
}

const sortTimeZone = (arrayTime: ITimeZoneList[]) => {
  const sortedTimeZone = [];
  const timeZoneZero = find(arrayTime, ['name', 'UTC±00:00']);
  const sortedTime = orderBy(arrayTime, ['name'], ['desc']);
  let indexTimeStartPositive = 0;

  for (let i = 0; i < sortedTime.length; i++) {
    if (sortedTime[i].name !== 'UTC±00:00') {
      sortedTimeZone.push(sortedTime[i]);
    }
    if (sortedTime[i].name === 'UTC-01:00') {
      sortedTimeZone.push(timeZoneZero);
      indexTimeStartPositive = i;
      break;
    }
  }
  for (let j = sortedTime.length - 1; j > indexTimeStartPositive; j--) {
    sortedTimeZone.push(sortedTime[j]);
  }
  return sortedTimeZone;
};

const sortHOF = (cb: (a: any, b: any) => number) => (arr: any[]) =>
  arr.sort(cb);

const sortCB = (
  a: { offset: string; title: string },
  b: { offset: string; title: string }
) => {
  const re = new RegExp('utc', 'g');
  return (
    parseInt(a.offset.toLocaleLowerCase().replace(re, '').replace(':', '.')) -
    parseInt(b.offset.toLocaleLowerCase().replace(re, '').replace(':', '.'))
  );
};

const transformDropDownList: (arr: any) => ITransformedTimezone[] = compose(
  sortHOF(sortCB),
  reduce((a: ITransformedTimezone[], b: ITransformedTimezone[]) => {
    return a.concat(b);
  }, []),
  map((item: ITimeZoneList) => ({
    title: item.standardDisplayName,
    offset: item.offset,
    timeZoneId: item.standardTimezoneId,
  }))
);

const defaultOptionSettings = {
  allowSMSSending: false,
  allowEmailSending: false,
  allowCallSending: false,
  allowPostalSending: false,
  adjustDaylightTimeAutomatically: true,
};

const SystemSetting = (): JSX.Element => {
  const { roleUser } = useDecodeToken();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTimezone, setSelectedTimezone] =
    useState<ITransformedTimezone>(
      transformDropDownList(sortTimeZone(initialTimeZoneList))[0]
    );
  const [isActiveValidatePhone, setActiveValidatePhone] =
    useState<boolean>(false);
  const [deliveryDayList, setDeliveryDayList] = useState<IDeliverySetting[]>(
    defaultInitialDeliveryDay
  );
  const [timezoneList, setTimeZoneList] = useState<ITransformedTimezone[]>(
    transformDropDownList(sortTimeZone(initialTimeZoneList))
  );

  const [optionSettings, setOptionSettings] = useState<SettingCheckbox>(
    defaultOptionSettings
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [automationEvent, setAutomationEvent] = useState<boolean>(true);
  const [filterReminderByAppointment, setFilterReminderByAppointment] = useState<boolean>(true);

  const getAutomationEvent = async () => {
    try {
      const res = await configuredRequests.GET.automationSetting();
      if (res) {
        // setOptionSettings((prevSettings) => ({
        //   ...prevSettings,
        //   automationEvent: res.settings.isEnable as boolean,
        // }));
        setAutomationEvent(res.settings.isEnable as boolean);
        if (res.settings.filterReminderByAppointment != null) {
          setFilterReminderByAppointment(res.settings.filterReminderByAppointment as boolean);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const fetchData = async () => {
    try {
      const response = await Promise.all([
        configuredRequests.GET.systemSettings(),
        configuredRequests.GET.timezones(),
      ]);

      setActiveValidatePhone(
        response[0].settings.useValidatedPhoneNumbers as boolean
      );

      setOptionSettings((prevSettings) => ({
        ...prevSettings,
        allowSMSSending: response[0].settings.allowSMSSending as boolean,
        allowEmailSending: response[0].settings.allowEmailSending as boolean,
        allowCallSending: response[0].settings.allowCallSending as boolean,
        allowPostalSending: response[0].settings.allowPostalSending as boolean,
        adjustDaylightTimeAutomatically: response[0].settings
          .adjustDaylightTimeAutomatically as boolean,
      }));

      setDeliveryDayList(
        transformDateList(
          response[0].settings.deliveryHours as IDeliverySetting[]
        )
      );

      const transformedTimeZone = transformDropDownList(
        response[1].timeZones
      ) as ITransformedTimezone[];
      setTimeZoneList(
        response[1]
          ? transformedTimeZone
          : transformDropDownList(sortTimeZone(initialTimeZoneList))
      );

      const foundTimezone = transformedTimeZone.find(
        (item) => item.timeZoneId === response[0].settings.timeZoneId
      );

      if (foundTimezone) {
        setSelectedTimezone(foundTimezone);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const regenerateAutomation = async () => {
    try {
      const res = await configuredRequests.PATCH.regeneratePendingAutomation();
      if (res) {
        toast.success('Automation pending is regenerating.', true);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err?.response?.data, true);
      }
    }
  };

  useEffect(() => {
    getAutomationEvent();
    fetchData();
  }, []);

  const handleChangeSwitch = (id: any) => () => {
    setDisabledButton(false);
    setDeliveryDayList((prev) =>
      prev.map((item) => {
        return item.id === id
          ? {
              ...item,
              active: !item.active,
              startTime: !item.active
                ? item.startTime.length > 0
                  ? item.startTime
                  : '07:00'
                : item.startTime.length > 0
                ? item.startTime
                : '',
              endTime: !item.active
                ? item.endTime.length > 0
                  ? item.endTime
                  : '17:00'
                : item.endTime.length > 0
                ? item.endTime
                : '',
            }
          : item;
      })
    );
  };

  const handleOnChangeTimeZone = (
    event: any,
    newValue: ITransformedTimezone | null
  ) => {
    setSelectedTimezone(newValue as ITransformedTimezone);
    setDisabledButton(false);
  };

  const handleDisabledButton = (): boolean => {
    const validDateTime = deliveryDayList.map((item) => ({
      error:
        compareAsc(
          parse(item.startTime, 'HH:mm', new Date()),
          parse(item.endTime, 'HH:mm', new Date())
        ) === 1 && item.active,
    }));

    return validDateTime.filter((item) => item.error).length > 0;
  };

  const handleOnchangeTime =
    (
      id: string,
      start: boolean
    ): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setDisabledButton(false);
      e.persist();

      setDeliveryDayList((prev) =>
        prev.map((item) =>
          item.id === id
            ? {
                ...item,
                startTime: start ? e.target.value : item.startTime,
                endTime: !start ? e.target.value : item.endTime,
              }
            : item
        )
      );
    };

  const handleChangeAutomation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDisabledButton(false);
    setAutomationEvent(event.target.checked);
  };

  const handleChangeFilterReminderByAppointment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    setDisabledButton(false);
    setFilterReminderByAppointment(event.target.checked);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setDisabledButton(false);
    setOptionSettings((prevSettings) => ({
      ...prevSettings,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmitData = async () => {
    try {
      setDisabledButton(true);
      const payload: SystemSettingResponseUpdated = {
        websiteUrl: '',
        websiteUseInFooters: true,
        emailReplyToAddress: '',
        timeZone: selectedTimezone.title as string,
        timeZoneOffset: selectedTimezone.offset,
        // groupAppointmentMessagesByFamily: isActiveGroupPoint,
        useValidatedPhoneNumbers: isActiveValidatePhone,
        deliveryHours: deliveryDayList,
        automationEvent: automationEvent,
        timeZoneId: selectedTimezone.timeZoneId ?? '',
        ...optionSettings,
      };
      await Promise.all([
        configuredRequests.PATCH.updateSystemSetting(payload),
        configuredRequests.PUT.updateAutomationSetting({
          isEnable: automationEvent,
          filterReminderByAppointment: filterReminderByAppointment,
        }),
      ]);
      toast.success('Your data has been saved successfully!');
    } catch (e) {
      // TODO handle when api call error
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  return (
    <div className={`system-setting__main setting-scroll--custom`}>
      {loading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <div className='automation-control'>
            <p className='mb-10'>
              <b>Automation</b>
            </p>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name='allowSendingSMS'
                    color='primary'
                    checked={automationEvent}
                    onChange={handleChangeAutomation}
                  />
                }
                label='Turn on automation'
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    name='allowSendingSMS'
                    color='primary'
                    checked={filterReminderByAppointment}
                    onChange={handleChangeFilterReminderByAppointment}
                  />
                }
                label='Filter reminder automation by future appointments'
              />
            </div>
            {roleUser.includes('Admin') && (
              <Button
                variant='contained'
                color='primary'
                className={`btn-regenerate`}
                onClick={regenerateAutomation}
                disabled={!automationEvent}
              >
                Regenerate Pending Automation
              </Button>
            )}
          </div>

          {roleUser.includes('Admin') && (
            <>
              <p className='mb-10'>
                <b>Delivery Permissions (automated messages only)</b>
              </p>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='allowSMSSending'
                      color='primary'
                      checked={optionSettings.allowSMSSending}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label='Allow sending SMS'
                />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='allowEmailSending'
                      color='primary'
                      checked={optionSettings.allowEmailSending}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label='Allow sending Email'
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='allowCallSending'
                      color='primary'
                      checked={optionSettings.allowCallSending}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label='Allow sending Call'
                />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='allowPostalSending'
                      color='primary'
                      checked={optionSettings.allowPostalSending}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label='Allow sending Postal'
                />
              </div>
            </>
          )}

          <p className='system-setting__header mb-10'>Delivery Hours</p>

          {/* <div className='d-flex mb-20 system-setting__timezone-auto'>
            <div className='switch-section d-flex'>
              <Switch checked={true} onChange={() => { }} />
            </div>
            <div className='fx-half align-center d-flex'>
              <p className='m-0 system-setting__day-text'>
              Adjust for daylight saving time automatically
              </p>
            </div>
          </div> */}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name='adjustDaylightTimeAutomatically'
                  color='primary'
                  checked={optionSettings.adjustDaylightTimeAutomatically}
                  onChange={handleChangeCheckbox}
                />
              }
              label='Adjust for daylight saving time automatically'
            />
          </div>
          {/* <div className='mb-20 system-setting__timezone-auto'>
                <div className='switch-section'>
            <AntSwitch
                label='Adjust for daylight saving time automatically'
                checked={adjustDaylightTimeAutomatically}
                handleChange={() => {
                  setAdjustDaylightTimeAutomatically(
                    (prevAdjustDaylightTimeAutomatically) =>
                      !prevAdjustDaylightTimeAutomatically
                  );
                }}
              />
            </div>
          </div> */}
          <span className='system-setting__label mb-10 mt-10 d-block'>
            Timezone <b className='require'>*</b>
          </span>
          <div className='system-setting__timezone'>
            <Autocomplete
              options={timezoneList}
              getOptionLabel={(option: { title: string; offset: string }) =>
                option.title
              }
              value={selectedTimezone}
              onChange={handleOnChangeTimeZone}
              id='blur-on-select'
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  margin='normal'
                  variant='outlined'
                />
              )}
            />
          </div>
          <p className='system-setting__label mb-20 '>
            Set the days and hours that you will allow automated messages to be
            delivered to contacts.
          </p>

          <div className='system-setting__delivery-list mb-10'>
            {deliveryDayList.map((item) => (
              <div
                key={item.id}
                className='d-flex system-setting__delivery-item'
              >
                <div className='switch-section d-flex'>
                  <Switch
                    checked={item.active}
                    onChange={handleChangeSwitch(item.id)}
                  />
                </div>
                <div className='fx-half align-center d-flex'>
                  <p className='m-0 system-setting__day-text'>{item.day}</p>
                </div>
                <div className='fx-2 mb-10 mt-10 '>
                  <div className='d-flex'>
                    <TimePickers
                      onChange={handleOnchangeTime(item.id, true)}
                      value={item.startTime}
                      disabled={!item.active}
                      showDefault={item.startTime.length === 0 && !item.active}
                      placeholderContainerClass='placeholder-time d-flex'
                      placeholderText='Start Time'
                      placeholderTextClass='placeholder-text m-0'
                    />
                    <TimePickers
                      onChange={handleOnchangeTime(item.id, false)}
                      value={item.endTime}
                      disabled={!item.active}
                      showDefault={item.endTime.length === 0 && !item.active}
                      placeholderContainerClass='placeholder-time d-flex'
                      placeholderText='End Time'
                      placeholderTextClass='placeholder-text m-0'
                    />
                  </div>

                  {item.active &&
                  compareAsc(
                    parse(item.startTime, 'HH:mm', new Date()),
                    parse(item.endTime, 'HH:mm', new Date())
                  ) === 1 ? (
                    <p className='error-text'>
                      The end time must be greater than start time.
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
          </div>

          <Button
            onClick={handleSubmitData}
            type='submit'
            variant='contained'
            color='primary'
            disabled={disabledButton || handleDisabledButton()}
          >
            <span>Save</span>
          </Button>
        </>
      )}
    </div>
  );
};

export default SystemSetting;
