import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  align-items: 'center';
`;

interface TitleProps {
  color?: string;
  flex?: string;
}
const Title = styled.div`
  flex: ${(props: TitleProps): string => props.flex || '1'};
  color: ${(props: TitleProps): string => props.color || 'blue'};
`;

interface BarContainerProps {
  flex?: string;
}
const BarContainer = styled.div`
  flex: ${(props: BarContainerProps): string => props.flex || '7'};
`;

const Score = styled.span`
  padding-left: 10px;
`;

interface ProgressBarProps {
  color?: string;
  percentage: string;
  height?: string;
  bottom?: string;
}
const ProgressBar = styled.div`
  background-color: ${(props: ProgressBarProps): string => props.color || 'lightblue'};
  width: ${(props: ProgressBarProps): string => props.percentage};
  height: ${(props: ProgressBarProps): string => props.height || `6px`};
  border-radius: 3px 3px 3px 3px;
  display: inline-block;
  margin-bottom: ${(props: ProgressBarProps): string => props.bottom || `2px`};
`;

export { Main, Title, BarContainer, ProgressBar, Score };
