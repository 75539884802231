import LoadingPs from 'components/Global/Loading';
import { AlertSnackBar } from 'components/Legwork';
import ExternalLink from './ExternalLink';
import { getPatientPortalUrl } from 'global/constants/url';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React, { useEffect, useRef, useState } from 'react';

const CustomExternalLinkButton = () => {
  const [loading, setLoading] = useState(false);
  const [externalLink, setExternalLink] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('');


  useEffect(() => {
    getCurrentExternalLink();
  }, []);

  const getCurrentExternalLink = async () => {
    try {
      const res = await configuredRequests.GET.currentExternalLink();
      setExternalLink(res.settings.externalUrl);
      setButtonText(res.settings.buttonText)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err)
    }
  };

  const editExternalLink = async (payload : {
    buttonText: string;
    externalUrl: string;
    isEnable: boolean;
}) => {
    try {
      await configuredRequests.PUT.updateCurrentExternalLink(payload);
      setLoading(false);
      getCurrentExternalLink();
    } catch (err) {
      setLoading(false);
      console.log(err)
    }
  };
  

  return (
    <>
      <div
        className='form-item-tab-container with-margin'
        style={{
          padding: `${loading ? '0 25px' : '30px 25px 0px 25px'}`,
        }}
      >
        {loading ? (
          <LoadingPs />
        ) : (
          <ExternalLink
            externalLink = {externalLink}
            buttonText = {buttonText}
            description='A single custom button can be added to the Dashboard on the Client Portal to allow the Pet Parent to open a new browser tab via the external URL of your choice.  This is an optional setting.'
            editExternalLink = {editExternalLink}
            setLoading = {setLoading}
          />
        )}
      </div>
    </>
  );
};

export default CustomExternalLinkButton;
