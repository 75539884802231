import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { getThemeProp } from 'components/Legwork/utils';

const StyledInput = styled(InputBase)`
  .MuiInputBase-input {
    border-radius: 5px;
    position: relative;

    background-color: ${({ theme }): string => theme.palette.background.paper};
    border: 1px solid #b8c3d2;
    color: ${getThemeProp('palette.text.secondary', '#6384a3')};
    padding-top: 0px;
    padding-bottom: 0px;
    height: 29px;
    line-height: 29px;

    font-size: 1rem;
    padding-left: 8px;
  }
` as typeof InputBase;

const StyledInputRightPadding = styled(StyledInput)`
  margin-right: 0.7rem;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-right: 0.7rem;
  margin-left: 0.8rem;
  color: ${getThemeProp('palette.text.secondary', '#6384a3')};
  font-size: 14px;
`;

const MarginRightInputLabel = styled(InputLabel)`
  margin-right: 0.7rem;
  color: ${getThemeProp('palette.text.secondary', '#6384a3')};
  font-size: 14px;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

export { StyledInput, StyledInputRightPadding, Main, StyledInputLabel, MarginRightInputLabel };
