import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const DropFileWrapped = styled.div``;

interface IUploadImage {
  accept?: string;
  maxFiles?: number;
  multiple: boolean;
  onDrop: (files: any) => void;
  files?: any;
  notifyTypeOfFile?: string;
  messageMaxSize?: string;
  messageMaxFile?: string;
  maxSize?: number;
  titleSelect: React.ReactElement;
  setError?: React.Dispatch<React.SetStateAction<string>>;
}
const SelectFiles = (props: IUploadImage) => {
  const {
    accept,
    maxFiles,
    multiple,
    onDrop,
    files,
    titleSelect,
    maxSize,
    messageMaxSize,
    messageMaxFile,
    setError,
  } = props;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: { 'image': ['.png', '.jpg', '.jpeg']},
    maxFiles,
    multiple,
    onDrop,
    maxSize,
    noDrag: false,
  });

  const totalSize = useMemo(
    () =>
      fileRejections.reduce((accumulator, currentValue) => {
        accumulator += currentValue?.file?.size;
        return accumulator;
      }, 0),
    [fileRejections]
  );
  const isFilesTooLarge = useMemo(
    () => fileRejections.length > 0 && maxSize && totalSize > maxSize,
    [fileRejections, maxSize, totalSize]
  );

  useEffect(() => {
    if (maxFiles && fileRejections.length > maxFiles) {
      setError && setError(messageMaxFile || '');
    } else if (isFilesTooLarge) {
      setError && setError(messageMaxSize || '');
    }
  }, [
    fileRejections,
    maxSize,
    maxFiles,
    isFilesTooLarge,
    messageMaxFile,
    messageMaxSize,
    setError,
  ]);

  return (
    <>
      <div>
        {}
        {multiple || (!multiple && !files) ? (
          <DropFileWrapped {...getRootProps()}>
            <input {...getInputProps()} />
            {titleSelect}
          </DropFileWrapped>
        ) : null}
        {files && files}
      </div>
    </>
  );
};

export default SelectFiles;
