import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import EmailEditor from 'components/Communications/EmailEditor';
import axios, { CancelTokenSource } from 'axios';
import ModalInsertPersonalize from 'components/Communications/ModalInsertPersonalize';
import { cutTemplateBody } from 'components/Communications/Templates/PostCard/BackPostCard';
import {
  getContentBackPostCard,
  getContentFrontPostCard,
  getContentToSavePreview,
} from 'components/Communications/Templates/PostCard/getContentPostCard';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import toast from 'components/Global/Toast';
import { characterLimit } from 'components/Messenger/MessengerInput';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  MessagePayloadKindsEnum,
  MultipleContactSendTextPayload,
  MultipleUserSendTextPayload,
  UserSendPostalPayload,
  SelectedUserSendEmailPayload,
} from 'global/requests/RequestTypes';
import {
  PersonalizationTokensResponse,
  TemplateData,
  TemplateResponse,
  TenantResposne,
} from 'global/requests/ResponseTypes';
import { AppointmentTypeSetting } from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import { BreedResponse } from 'global/requests/ResponseTypes/Breed';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import { SexResponse } from 'global/requests/ResponseTypes/Sex';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import { sortBy, upperFirst } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import {
  convertDateToTimestamp,
  convertToCamelCase,
  formatNumber,
  getPracticeName,
  getTimeNowForPractice,
} from 'utils/convertData';
import { capitalize } from 'utils/strings';
import BatchPopup from './BatchPopup';
import { DELIVERY_OPTIONS } from './SendCommunicationModal';
import SinglePostalEditor from './ViewContact/SinglePostalEditor';
import WarningPopup from './WarningPopup';

// this tells the modal which element to aria-hide when the modal is displayed (accessibility)
ReactModal.setAppElement('div#root');

const EmailEditorWrap = styled.div`
  width: 100%;

  .contact-email-editor {
    padding: 10px;
    padding-top: 0;
  }
`;

const PostalEditorWrap = styled.div`
  /* height: 100%; */
  width: 100%;

  & > div {
    height: 100%;
  }
`;

// this component is a little bit unique. We use a string to determine which communication type is desired (SMS or Email)
// it can be extended because most logic is conditional and will do the relevant actions based on the string
// it should probably rely on an enum, currently its just a string type and can be any value

export interface SendCommunicationFilterModalProps {
  labelText?: string;
  communicationModalType: string;
  setCommunicationModalType: React.Dispatch<React.SetStateAction<string>>;
  userSelectedConfig: {
    totalAvailable: number;
    userSelectedContactIDs: { [key: string]: boolean };
  };
  sendGetTextTemplateInfoRequest: () => void;
  sendGetEmailTemplateInfoRequest: () => void;
  emailTemplateInfo?: TemplateResponse;
  textTemplateInfo?: TemplateResponse;
  isSentToAllContact: boolean;
  searchValue: string;
  filterValue: string;
}

interface CommonFilter {
  checked: boolean;
  label: string;
}

interface BirthDateFilter extends CommonFilter {
  startDate: Date;
  endDate: Date;
  error: string;
}

interface SpeciesFilter extends CommonFilter {
  value: SpeciesSettingType[];
}

interface AppointmentTypeFilter extends CommonFilter {
  value: AppointmentTypeSetting[];
}

interface ProviderFilter extends CommonFilter {
  value: ProviderModal[];
}
interface State {
  birthDate: BirthDateFilter;
  species: SpeciesFilter;
  breed: SpeciesFilter;
  sex: SpeciesFilter;
  appointmentDate: BirthDateFilter;
  appointmentType: AppointmentTypeFilter;
  provider: ProviderFilter;
  all: CommonFilter;
}

interface SelectedType {
  selected: boolean;
}
type FilterName = keyof typeof filterDefault;
type FilterSelectName = Exclude<FilterName, 'birthDate' | 'appointmentDate'>;
type SelectValueType =
  | SpeciesSettingType
  | AppointmentTypeSetting
  | ProviderModal;
interface Payload {
  name: FilterName;
  value?: SpeciesSettingType[] | BreedResponse[] | SexResponse[] | Date;
  sub?: 'startDate' | 'endDate';
  error?: string;
}

interface ActionType {
  type: string;
  payload?: Payload;
}

const DATE_ERROR = 'The start date must be before the end date';

const filterDefault = {
  birthDate: {
    checked: false,
    startDate: moment(new Date()).subtract(1, 'y').toDate(),
    endDate: moment(new Date()).toDate(),
    error: '',
    label: 'Pet Birthdate',
  },
  species: {
    checked: false,
    value: [],
    label: 'Species',
  },
  breed: {
    checked: false,
    value: [],
    label: 'Breed',
  },
  sex: {
    checked: false,
    value: [],
    label: 'Sex',
  },
  appointmentDate: {
    checked: false,
    startDate: moment(new Date()).subtract(1, 'y').toDate(),
    endDate: moment(new Date()).toDate(),
    error: '',
    label: 'Appointment Date',
  },
  appointmentType: {
    checked: false,
    value: [],
    label: 'Appointment Type',
  },
  provider: {
    checked: false,
    value: [],
    label: 'Appointment Provider',
  },
  all: {
    checked: false,
    label: 'All Contacts',
  },
};

const filterReducer = (state: State, action: ActionType) => {
  if (!action.payload) {
    if (action.type === 'UNCHECK') {
      return {
        ...state,
        species: {
          ...state.species,
          checked: false,
          value: [],
        },
        breed: {
          ...state.breed,
          checked: false,
          value: [],
        },
      };
    }

    return filterDefault;
  }

  const { name, sub, value, error = '' } = action.payload;

  switch (action.type) {
    case 'TOGGLE':
      if (['birthDate', 'appointmentDate'].includes(name)) {
        return {
          ...state,
          [name]: {
            ...state[name],
            checked: !state[name].checked,
            startDate: moment(new Date()).subtract(1, 'y').toDate(),
            endDate: moment(new Date()).toDate(),
            error: '',
          },
        };
      }
      if (name === 'all') {
        return {
          ...filterDefault,
          [name]: {
            ...state[name],
            checked: !state[name].checked,
          },
        };
      }
      return {
        ...state,
        [name]: {
          ...state[name],
          checked: !state[name].checked,
          value: [],
        },
      };

    case 'CHANGE':
      return {
        ...state,
        [name]: {
          ...state[name],
          value,
        },
      };
    case 'SUB_CHANGE':
      return {
        ...state,
        [name]: {
          ...state[name],
          // @ts-ignore
          [sub]: value,
          error,
        },
      };
    default:
      return state;
  }
};

let source: CancelTokenSource;

const SendCommunicationFilterModal = (
  props: SendCommunicationFilterModalProps
) => {
  const {
    communicationModalType, // "text" | "email"
    sendGetTextTemplateInfoRequest,
    sendGetEmailTemplateInfoRequest,
    emailTemplateInfo,
    textTemplateInfo,
    searchValue,
    filterValue,
  } = props;

  const queryRef = useRef(0);
  const [isMount, setIsMount] = useState(true);
  const [isNoneContacts, setIsNoneContacts] = useState(true);
  const [openBatchPopup, setOpenBatchPopup] = useState(false);
  const [textBody, setTextBody] = React.useState('');
  const [generalSetting, setGeneralSetting] = React.useState<TenantResposne>();
  const [currentSelectedTemplate, setCurrentSelectedTemplate] =
    React.useState<TemplateData>({
      name: 'Blank Text Template',
      templateId: '',
    });
  const [optionsSpecies, setOptionsSpecies] = useState<SpeciesSettingType[]>(
    []
  );
  const [optionsSex, setOptionsSex] = useState<SexResponse[]>([]);
  const [optionsBreed, setOptionsBreed] = useState<BreedResponse[]>([]);
  const [optionsAppointmentType, setOptionsAppointmentType] = useState<
    AppointmentTypeSetting[]
  >([]);
  const [optionsProvider, setOptionsProvider] = useState<ProviderModal[]>([]);

  const [filterState, dispatch] = useReducer(filterReducer, filterDefault);

  const [recipients, setRecipients] = React.useState<string[]>([]);

  const [isSentToAllContact, setIsSentToAllContact] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [scheduledDeliveryDate, setScheduledDeliveryDate] =
    React.useState<MaterialUiPickersDate>();

  const emailEditorSubmitRef = React.createRef<HTMLButtonElement>();
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);

  const [currentDeliveryType, setCurrentDeliveryType] = React.useState<string>(
    DELIVERY_OPTIONS.SEND_DELIVERY
  );
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [error, setError] = useState('');

  const [payloadSendToPostal, setPayloadSendToPostal] =
    React.useState<UserSendPostalPayload>({
      templateId: '',
      front: '',
      back: '',
      size: '4x6',
      previewContent: '',
      actionType: 'sendWithInDeliveryHours',
    });
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [openInsertPersonalize, setOpenInsertPersonalize] =
    React.useState<boolean>(false);
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    setTextBody(el.value);
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const timeLocalRef = React.useRef(new Date());
  const [postalCost, setPostalCost] = useState(0);
  const [smsCost, setSmsCost] = useState({
    SMSCost: 0,
    SMSFreeLimit: 0,
  });
  const refDisabledCall = useRef<boolean>(false);

  useEffect(() => {
    if (communicationModalType === 'text') {
      getSmsPrice();
    }
    if (communicationModalType === 'postal') {
      getPostalPrice();
    }
  }, [communicationModalType]);

  useEffect(() => {
    Promise.all([
      getDateTimeNow(),
      getTokens(),
      getListSpecies(),
      getListPetSex(),
      getListAppointmentType(),
      getListProvider(),
    ]);
  }, []);

  useEffect(() => {
    //TODO convert to enum and decouple these requests
    if (communicationModalType.length) {
      sendGetTextTemplateInfoRequest();
      sendGetEmailTemplateInfoRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationModalType]);

  useEffect(() => {
    !refDisabledCall.current && getFilteredContacts();
  }, [filterState]);

  useEffect(() => {
    if (filterState.all.checked) {
      setIsSentToAllContact(true);
    }
  }, [filterState.all.checked]);

  useEffect(() => {
    if (
      !filterState.appointmentDate.checked &&
      !filterState.appointmentType.checked &&
      !filterState.provider.checked &&
      !filterState.all.checked &&
      !filterState.birthDate.checked &&
      !filterState.species.checked &&
      !filterState.sex.checked
    ) {
      setIsNoneContacts(true);
    } else {
      setIsNoneContacts(false);
    }
  }, [
    filterState.appointmentDate.checked,
    filterState.appointmentType.checked,
    filterState.birthDate.checked,
    filterState.breed.checked,
    filterState.provider.checked,
    filterState.sex.checked,
    filterState.species.checked,
    filterState.all.checked,
  ]);

  const getPostalPrice = async () => {
    try {
      const res = await configuredRequests.GET.getPostalPrice();
      setPostalCost(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSmsPrice = async () => {
    try {
      const res = await configuredRequests.GET.getSmsPrice();
      // @ts-ignore
      setSmsCost(res.settings);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDateTimeNow = async () => {
    try {
      const res = await configuredRequests.GET.getTimeNow();

      if (res) {
        setMinDate(new Date(res.toString()));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getFilteredContacts = async () => {
    try {
      setIsLoading(true);
      const num = ++queryRef.current;
      if (source) {
        source.cancel();
      }
      source = axios.CancelToken.source();
      const res = await configuredRequests.GET.filterContactInfoByCurrentTenant(
        {
          apptStartDate: filterState.appointmentDate.checked
            ? filterState.appointmentDate.startDate
                .toString()
                .split('GMT')[0]
                .trim()
            : undefined,
          apptEndDate: filterState.appointmentDate.checked
            ? filterState.appointmentDate.endDate
                .toString()
                .split('GMT')[0]
                .trim()
            : undefined,
          petBirthdateStart: filterState.birthDate.checked
            ? filterState.birthDate.startDate.toDateString()
            : undefined,
          petBirthdateEnd: filterState.birthDate.checked
            ? filterState.birthDate.endDate.toDateString()
            : undefined,
          petSpecies: filterState.species.checked
            ? filterState.species.value.map((s) => s.id)
            : undefined,
          petBreed: filterState.breed.checked
            ? filterState.breed.value.map((b) => b.id)
            : undefined,
          petSex: filterState.sex.checked
            ? filterState.sex.value.map((s) => s.id)
            : undefined,
          appointmentType: filterState.appointmentType.checked
            ? filterState.appointmentType.value.map((s) => s.id)
            : undefined,
          provider: filterState.provider.checked
            ? filterState.provider.value.map((s) => s.id ?? '')
            : undefined,
        },
        source.token
      );
      if (queryRef.current <= num) {
        if (
          !isMount &&
          (filterState.appointmentDate.checked ||
            filterState.appointmentType.checked ||
            filterState.birthDate.checked ||
            filterState.provider.checked ||
            filterState.sex.checked ||
            filterState.species.checked ||
            filterState.all.checked)
        ) {
          setIsNoneContacts(false);
        }
        setIsSentToAllContact(
          res.users.length === props.userSelectedConfig.totalAvailable
        );
        setRecipients(res.users.map((val) => val.id));
        setIsLoading(false);
      }
      if (res.users.length === 0) {
        setIsNoneContacts(true);
      }
      setIsMount(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getListSpecies = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.allSpeciesByCurrentTenant();

      if (res?.data) {
        // @ts-ignore
        setOptionsSpecies(sortBy(res.data.species, ['name']));
      }
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const getListPetSex = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getListSex();

      if (res) {
        setOptionsSex(sortBy(res.petSex, ['name']));
      }
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const getListBreeds = useCallback(
    async (species: SpeciesSettingType[], isFirst?: boolean) => {
      try {
        const res = await configuredRequests.GET.getListBreeds(
          species.map((s) => s.id)
        );

        if (res) {
          setOptionsBreed(sortBy(res.breeds, ['name']));
          const newBreedValue = filterState.breed.value.filter(
            (b) => res.breeds.findIndex((r) => r.id === b.id) > -1
          );
          refDisabledCall.current = false;
          !isFirst &&
            dispatch({
              type: 'CHANGE',
              payload: {
                name: 'breed',
                value: newBreedValue,
              },
            });
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    [filterState.breed.value]
  );

  const getListAppointmentType = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.getAppointmentType();
      setOptionsAppointmentType(
        // @ts-ignore
        sortBy(res, [(item) => item.appointmentType.toLowerCase()])
      );
    } catch (err) {}
  }, []);

  const getListProvider = useCallback(async () => {
    try {
      const response = await configuredRequests.GET.getProviders();
      if (response?.data) {
        setOptionsProvider(
          // @ts-ignore
          sortBy(response.data, [(item) => item.name.toLowerCase()])
        );
      }
    } catch (e) {}
  }, []);

  const handleSelectDate = (
    date: Date | null,
    name: 'birthDate' | 'appointmentDate',
    sub: 'startDate' | 'endDate'
  ) => {
    const formatDate =
      name === 'birthDate'
        ? moment(date).endOf('day').toDate()
        : moment(date).toDate();

    let error = '';
    if (sub === 'startDate') {
      error = moment(formatDate).isAfter(filterState[name].endDate)
        ? DATE_ERROR
        : '';
    } else {
      error = moment(formatDate).isBefore(filterState[name].startDate)
        ? DATE_ERROR
        : '';
    }

    dispatch({
      type: 'SUB_CHANGE',
      payload: {
        name,
        value: formatDate,
        sub,
        error,
      },
    });
  };

  const handleChangeFilter = (
    name: FilterSelectName,
    value: SelectValueType[]
  ) => {
    dispatch({
      type: 'CHANGE',
      payload: {
        name,
        // @ts-ignore
        value,
      },
    });
  };

  const handleChangeDeliveryType = (newValue: string) => {
    switch (newValue) {
      case DELIVERY_OPTIONS.CUSTOM_TIME: {
        if (
          currentDeliveryType === DELIVERY_OPTIONS.SEND_DELIVERY ||
          currentDeliveryType === DELIVERY_OPTIONS.SEND_IMMEDIATELY
        ) {
          setCurrentDeliveryType(newValue);
          if (minDate) {
            const newMinDate = getTimeNowForPractice(
              timeLocalRef.current,
              minDate
            );
            setMinDate(newMinDate);
            setScheduledDeliveryDate(newMinDate);
          } else {
            setMinDate(new Date());
            setScheduledDeliveryDate(new Date());
          }
          timeLocalRef.current = new Date();
        }
        break;
      }
      case DELIVERY_OPTIONS.SEND_IMMEDIATELY:
        setOpenWarningPopup(true);
        setCurrentDeliveryType(newValue);
        break;
      default: {
        setCurrentDeliveryType(newValue);
        break;
      }
    }
  };

  useEffect(() => {
    if (props.communicationModalType === 'text' && textBody) {
      setIsSubmitEnabled(true);
    } else if (props.communicationModalType === 'text' && !textBody) {
      setIsSubmitEnabled(false);
    }
  }, [props.communicationModalType, textBody]);

  const renderDeliveryDateSelect = (key: string) => {
    return (
      <div className='contacts--send-communication-date-time-container'>
        <TextChevronDropdown
          key={key}
          currentSelection={currentDeliveryType}
          handleChangeSelection={handleChangeDeliveryType}
          selectionOptions={[
            {
              label: DELIVERY_OPTIONS.SEND_DELIVERY,
              value: DELIVERY_OPTIONS.SEND_DELIVERY,
            },
            {
              label: DELIVERY_OPTIONS.SEND_IMMEDIATELY,
              value: DELIVERY_OPTIONS.SEND_IMMEDIATELY,
            },
          ]}
          useBlackText={true}
          useBoldText={true}
          className={'height-40 border-gray'}
          useLabel={{
            labelText: 'Delivery Date',
          }}
        />
        {/* renders datePicker when custom time is desired */}
        {currentDeliveryType === DELIVERY_OPTIONS.CUSTOM_TIME
          ? [
              <div
                className='send-communication--datetime-picker-container'
                key='contacts--send-communication-input--date'
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container className='grid-custom'>
                    <FontAwesomeIcon
                      icon={['fas', 'calendar-alt']}
                      className='icon-calendar__communication-end-date'
                    />
                    <DateTimePicker
                      value={scheduledDeliveryDate}
                      minDate={minDate}
                      onChange={setScheduledDeliveryDate}
                      className='date-picker-custom'
                      format='MMMM do hh:mm a'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>,
            ]
          : null}
      </div>
    );
  };

  const renderEmailForm = () => {
    return (
      //TODO checkNewEmail?
      <EmailEditorWrap>
        <EmailEditor
          hideRecipient={true}
          receiverUserIDs={recipients}
          checkNewEmail={() => {}}
          searchValue={searchValue}
          filterValue={filterValue}
          isSentToAllContact={isSentToAllContact}
          closeModal={handleCloseModal}
          useCustomSubmit={{
            innerSubmitRefObject: emailEditorSubmitRef,
            setIsSubmitEnabled: setIsSubmitEnabled,
          }}
        />
      </EmailEditorWrap>
    );
  };

  const renderPostalForm = () => {
    return (
      <PostalEditorWrap>
        <SinglePostalEditor
          payloadSendToPostal={payloadSendToPostal}
          setPayloadSendToPostal={setPayloadSendToPostal}
          setIsSubmitEnabled={setIsSubmitEnabled}
          receiverUserIDs={recipients}
          isSentToAllContact={isSentToAllContact}
          setGeneralSetting={setGeneralSetting}
        />
      </PostalEditorWrap>
    );
  };

  const handleSelectDropdownOption = (newValue: string) => {
    if (newValue === 'default') {
      setCurrentSelectedTemplate({
        name: 'Blank Text Template',
        templateId: '',
      });
      setTextBody('');
    } else {
      const selectedTemplateInfo: TemplateData | undefined =
        textTemplateInfo?.items.filter(
          (templateInfo) => templateInfo.templateId === newValue
        )[0];
      if (selectedTemplateInfo) {
        setCurrentSelectedTemplate(selectedTemplateInfo);
      }
      if (selectedTemplateInfo?.templateBody) {
        setTextBody(selectedTemplateInfo.templateBody);
      }
    }
  };

  const handleTextAreaInput = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setError('');
    const targetValue = event.currentTarget.value;
    if (targetValue.length === characterLimit) {
      setError(`Message body cannot exceed ${characterLimit} characters.`);
    }
    setTextBody(targetValue);
  };

  const renderTextForm = () => {
    return (
      <div className='contacts--text-form-container'>
        <TextChevronDropdown
          key={'contacts--text-form-template-dropdown'}
          currentSelection={currentSelectedTemplate.name ?? ''}
          handleChangeSelection={handleSelectDropdownOption}
          selectionOptions={generateTemplateSelectOptions([
            {
              value: 'default',
              label: 'Blank Text Template',
            },
          ])}
          useBlackText={true}
          useBoldText={true}
          className={'height-40 border-gray'}
          useLabel={{
            labelText: 'Template',
          }}
        />

        <div className='contacts--text-form-input-container'>
          <label>
            Message Body <b className='require'>*</b>
          </label>
          <div className='contacts--text-form-input-container--content'>
            <textarea
              className='contacts--text-form-textarea'
              onInput={handleTextAreaInput}
              value={textBody}
              id='textArea'
              maxLength={characterLimit}
            />
            <button
              id='personalizeButton'
              className='btn-personalize'
              onClick={() => setOpenInsertPersonalize(true)}
            >
              {' Personalize '}
            </button>
          </div>

          {error && <p className='error-msg'> {error} </p>}
        </div>

        {renderDeliveryDateSelect(
          'contacts--text-form-delivery-date--dropdown'
        )}
      </div>
    );
  };

  const renderCommunicationForm = (communicationType: string) => {
    switch (communicationType) {
      case 'email': {
        return renderEmailForm();
      }
      case 'text': {
        return renderTextForm();
      }
      case 'postal': {
        return renderPostalForm();
      }
    }
  };

  const generateTemplateSelectOptions = (
    defaultOptions: { value: string; label: string }[]
  ) => {
    const output = [...defaultOptions];
    if (communicationModalType === 'email') {
      const generatedOptions = emailTemplateInfo?.items.map((emailTemplate) => {
        return {
          label: emailTemplate.name ?? 'Missing Template Name',
          value: emailTemplate.templateId ?? 'Missing Template ID',
        };
      });
      if (generatedOptions) {
        output.concat(generatedOptions);
      }
    }
    if (communicationModalType === 'text') {
      const generatedOptions = textTemplateInfo?.items.map((textTemplate) => {
        return {
          label: textTemplate.name ?? 'Missing Template Name',
          value: textTemplate.templateId ?? 'Missing Template ID',
        };
      });
      if (generatedOptions) {
        return output.concat(generatedOptions);
      }
    }
    return output;
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as FilterName;
    if (name === 'species') {
      if (filterState.species.checked) {
        dispatch({
          type: 'UNCHECK',
        });
        return;
      }
      getListBreeds([], true);
    }
    if (name === 'all') {
      setIsSentToAllContact(filterState.all.checked ? false : true);
    }
    dispatch({
      type: 'TOGGLE',
      payload: {
        name,
      },
    });
  };

  const handleCloseModal = (
    event?: React.SyntheticEvent | React.KeyboardEvent
  ) => {
    event?.stopPropagation();
    props.setCommunicationModalType('');
    setCurrentDeliveryType(DELIVERY_OPTIONS.SEND_DELIVERY);
    setCurrentSelectedTemplate({ name: 'Blank Text Template', templateId: '' });
    setIsSubmitEnabled(false);
    setError('');
    setIsMount(true);
    setScheduledDeliveryDate(null);
    setPayloadSendToPostal({
      templateId: '',
      front: '',
      back: '',
      size: '4x6',
      previewContent: '',
      actionType: 'sendWithInDeliveryHours',
    });
    dispatch({
      type: 'RESET',
    });
    props.communicationModalType === 'text' && setTextBody('');
  };

  const handleSendCommunication = async (isSendEmail?: boolean) => {
    switch (communicationModalType) {
      case 'email': {
        emailEditorSubmitRef.current?.click();
        break;
      }
      case 'text': {
        try {
          const sendDate =
            currentDeliveryType === DELIVERY_OPTIONS.CUSTOM_TIME
              ? convertDateToTimestamp(scheduledDeliveryDate ?? new Date())
              : '';
          const messageContent = textBody || '';
          if (!messageContent) {
            console.log('could not get message text');
            return;
          }

          const filterValueConverted =
            filterValue === 'all' ? '' : upperFirst(filterValue);

          if (isSendEmail) {
            if (isSentToAllContact) {
              const payloadToSend = {
                content: messageContent,
                kind: MessagePayloadKindsEnum.email,
                subject: `A message from ${getPracticeName() || ''}`,
                scheduleDate: sendDate,
                templateId: currentSelectedTemplate.templateId ?? '',
                actionType: convertToCamelCase(currentDeliveryType),
              };
              // toast.success('Sending to contacts', true);
              configuredRequests.POST.sendEmailToAllCustomers(
                //@ts-ignore
                payloadToSend,
                searchValue,
                filterValueConverted
              );
            } else {
              // @ts-ignore
              const payloadSendEmail: SelectedUserSendEmailPayload = {
                messageContents: {
                  content: messageContent,
                  kind: MessagePayloadKindsEnum.email,
                  subject: `A message from ${getPracticeName() || ''}`,
                  templateId: currentSelectedTemplate.templateId ?? '',
                  actionType: convertToCamelCase(currentDeliveryType),
                  sendLaterDate: sendDate,
                },
                contactIds: recipients,
              };
              // toast.success(
              //   `Sending to contact${recipients.length === 1 ? '' : 's'}`,
              //   true
              // );
              configuredRequests.POST.sendSelectedUserEmail(payloadSendEmail);
            }
          } else {
            if (isSentToAllContact) {
              const payloadTextToAllUser: MultipleContactSendTextPayload = {
                sendLaterDate: sendDate,
                content: messageContent,
                kind: MessagePayloadKindsEnum.sms,
                scheduleDate: sendDate,
                templateId: currentSelectedTemplate.templateId ?? '',
                actionType: convertToCamelCase(currentDeliveryType),
              };
              // setIsSubmitEnabled(false);
              configuredRequests.POST.sendSMSToAllContacts(
                payloadTextToAllUser,
                searchValue,
                filterValueConverted
              );
            } else {
              //@ts-ignore
              const payloadTextToSelectedUser: MultipleUserSendTextPayload = {
                messageContents: {
                  content: messageContent,
                  kind: MessagePayloadKindsEnum.sms,
                  sendLaterDate: sendDate,
                  templateId: currentSelectedTemplate.templateId ?? '',
                  actionType: convertToCamelCase(currentDeliveryType),
                },
                contactIds: recipients,
              };
              configuredRequests.POST.sendMultipleUserSMS(
                payloadTextToSelectedUser
              );
            }
          }

          toast.success(
            <b>{`Sending to contact${
              !isSentToAllContact && recipients.length === 1 ? '' : 's'
            }`}</b>,
            true
          );
          handleCloseModal();
        } catch (err) {
          // toast.error('Sent failed! Please try again.');
        }
        break;
      }
      case 'postal': {
        try {
          const previewContent = getContentToSavePreview({
            frontSideUrl: payloadSendToPostal.front,
            sizePostCard: payloadSendToPostal.size,
            contentBackSide: cutTemplateBody(payloadSendToPostal.back),
            isPostGrid:
              (generalSetting?.postalProvider || '').toLowerCase() ===
              'postgrid',
          });
          const dataSend = {
            // @ts-ignore
            front: getContentFrontPostCard(
              payloadSendToPostal.front,
              payloadSendToPostal.size
            ),
            // @ts-ignore
            back: getContentBackPostCard(
              cutTemplateBody(payloadSendToPostal.back),
              payloadSendToPostal.size,
              (generalSetting?.postalProvider || '').toLowerCase() ===
                'postgrid'
            ),
            size: payloadSendToPostal.size,
            previewContent,
            actionType: payloadSendToPostal.actionType,
            send_date: payloadSendToPostal.sendLaterDate
              ? payloadSendToPostal.sendLaterDate
              : '',
          };
          payloadSendToPostal.templateId &&
            // @ts-ignore
            (dataSend.templateId = payloadSendToPostal.templateId);
          toast.success(
            <b>{`Sending to contact${
              !isSentToAllContact && recipients.length === 1 ? '' : 's'
            }`}</b>,
            true
          );
          handleCloseModal();
          if (isSentToAllContact) {
            configuredRequests.POST.sendPostalToAllContacts('Active', dataSend);
          } else {
            configuredRequests.POST.sendPostalToMultipleContacts({
              postcardRequest: dataSend,
              contactIds: recipients,
            });
          }
        } catch (err) {
          console.log('Error: ', err);
        }
        break;
      }
    }
  };

  const handleKeypressCloseButton = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        handleCloseModal();
        break;
      }
    }
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    typeInTextarea(
      document.getElementById('textArea'),
      `{{${personalizationTokenSelected}}}`
    );
    setOpenInsertPersonalize(false);
  };

  const renderCheckbox = (name: FilterName) => (
    <div className='contacts--send-filter-checkbox-container'>
      <Checkbox
        checked={filterState[name].checked}
        color='primary'
        name={name}
        onChange={handleFilterChange}
        disabled={name !== 'all' && filterState.all.checked ? true : false}
      />
      <span>{filterState[name].label}</span>
    </div>
  );

  const renderSelectDate = (name: 'birthDate' | 'appointmentDate') => {
    return (
      <div className='datepicker-send-filter'>
        <div className='datepicker-filter-text'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className='grid-custom'>
              <FontAwesomeIcon
                icon={['fas', 'calendar-alt']}
                className='icon-calendar__communication-end-date filter-calendar-icon'
              />
              {name === 'birthDate' ? (
                <DatePicker
                  format='MM/dd/yy'
                  value={filterState[name].startDate}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleSelectDate(date, name, 'startDate')
                  }
                  maxDate={new Date()}
                  // disableToolbar
                  className='date-picker-custom'
                />
              ) : (
                <DateTimePicker
                  value={filterState[name].startDate}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleSelectDate(date, name, 'startDate')
                  }
                  className='date-picker-custom'
                  format='hh:mm a MM/dd/yy'
                />
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <span className='line'></span>

        <div className='datepicker-filter-text'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className='grid-custom'>
              <FontAwesomeIcon
                icon={['fas', 'calendar-alt']}
                className='icon-calendar__communication-end-date filter-calendar-icon'
              />
              {name === 'birthDate' ? (
                <DatePicker
                  format='MM/dd/yy'
                  value={filterState[name].endDate}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleSelectDate(date, name, 'endDate')
                  }
                  maxDate={new Date()}
                  // disableToolbar
                  className='date-picker-custom'
                />
              ) : (
                <DateTimePicker
                  value={filterState[name].endDate}
                  onChange={(date: MaterialUiPickersDate) =>
                    handleSelectDate(date, name, 'endDate')
                  }
                  className='date-picker-custom'
                  format='hh:mm a MM/dd/yy'
                />
              )}
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  };

  const renderTitle = useMemo(() => {
    if (isNoneContacts) {
      return `Send ${capitalize(props.communicationModalType)} to 0 Contacts`;
    }
    if (filterState.all.checked) {
      return `Send ${capitalize(props.communicationModalType)} to ${
        props.userSelectedConfig.totalAvailable
      } Contacts`;
    }
    if (isLoading) {
      return 'Loading contacts... this may take a while';
    }
    return isSentToAllContact
      ? `Send ${capitalize(props.communicationModalType)} to ${
          props.userSelectedConfig.totalAvailable
        } Contact${props.userSelectedConfig.totalAvailable > 1 ? 's' : ''}`
      : `Send ${capitalize(props.communicationModalType)} to ${
          recipients.length
        } Contact${recipients.length !== 1 ? 's' : ''}`;
  }, [
    isLoading,
    isNoneContacts,
    isSentToAllContact,
    recipients,
    props.communicationModalType,
    props.userSelectedConfig.totalAvailable,
    filterState.all.checked,
  ]);

  return (
    <>
      <ReactModal
        isOpen={
          props.communicationModalType.length > 0 && props.isSentToAllContact
        }
        onRequestClose={handleCloseModal}
        contentLabel={props.labelText ?? 'empty label'}
        className={`react-modal ${capitalize(
          props.communicationModalType
        ).toLowerCase()}`}
      >
        <div className='react-modal--inside-overflow-container'>
          <div className='contact-communication-modal-top-row'>
            <div className='contact-communication-modal-title-text'>
              {renderTitle}
            </div>

            <div
              className='contacts--modal-exit-button-container'
              tabIndex={0}
              onClick={handleCloseModal}
              onKeyPress={handleKeypressCloseButton}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </div>
          </div>

          <div className='contacts--send-communication-modal-body-container'>
            {renderCommunicationForm(props.communicationModalType)}

            <div
              className={`contacts--send-filter-container ${
                filterState.appointmentDate.checked ? 'select-date' : ''
              }`}
            >
              <div>
                {renderCheckbox('appointmentDate')}

                {filterState.appointmentDate.checked && (
                  <>{renderSelectDate('appointmentDate')}</>
                )}
              </div>

              {filterState.appointmentDate.error && (
                <span className='error-msg'>
                  {filterState.appointmentDate.error}
                </span>
              )}
            </div>

            <div className='contacts--send-filter-container'>
              {renderCheckbox('provider')}

              {filterState.provider.checked && (
                <div className='filter-multi'>
                  <Autocomplete
                    value={filterState.provider.value}
                    multiple
                    options={optionsProvider}
                    disableCloseOnSelect
                    getOptionLabel={(option: ProviderModal) =>
                      option.name || ''
                    }
                    noOptionsText={'No options'}
                    onChange={(
                      e: React.ChangeEvent<{}>,
                      value: ProviderModal[]
                    ) => handleChangeFilter('provider', value)}
                    getOptionSelected={(
                      option: ProviderModal,
                      value: ProviderModal
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: ProviderModal,
                      { selected }: SelectedType
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        placeholder={
                          filterState.provider.value.length > 0
                            ? ''
                            : 'Select Appointment Provider'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className='contacts--send-filter-container'>
              {renderCheckbox('appointmentType')}

              {filterState.appointmentType.checked && (
                <div className='filter-multi'>
                  <Autocomplete
                    value={filterState.appointmentType.value}
                    multiple
                    options={optionsAppointmentType}
                    disableCloseOnSelect
                    getOptionLabel={(option: AppointmentTypeSetting) =>
                      option.appointmentType
                    }
                    noOptionsText={'No options'}
                    onChange={(
                      e: React.ChangeEvent<{}>,
                      value: AppointmentTypeSetting[]
                    ) => handleChangeFilter('appointmentType', value)}
                    getOptionSelected={(
                      option: AppointmentTypeSetting,
                      value: AppointmentTypeSetting
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: AppointmentTypeSetting,
                      { selected }: SelectedType
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.appointmentType}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        placeholder={
                          filterState.appointmentType.value.length > 0
                            ? ''
                            : 'Select Appointment Type'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div
              className={`contacts--send-filter-container ${
                filterState.birthDate.checked ? 'select-date' : ''
              }`}
            >
              <div className='filter-birthdate'>
                {renderCheckbox('birthDate')}

                {filterState.birthDate.checked && (
                  <>{renderSelectDate('birthDate')}</>
                )}
              </div>

              {filterState.birthDate.error && (
                <span className='error-msg'>{filterState.birthDate.error}</span>
              )}
            </div>

            <div className='contacts--send-filter-container'>
              {renderCheckbox('sex')}

              {filterState.sex.checked && (
                <div className='filter-multi'>
                  <Autocomplete
                    value={filterState.sex.value}
                    multiple
                    options={optionsSex}
                    disableCloseOnSelect
                    getOptionLabel={(option: SexResponse) => option.name}
                    noOptionsText={'No options'}
                    onChange={(
                      e: React.ChangeEvent<{}>,
                      value: SexResponse[]
                    ) => {
                      dispatch({
                        type: 'CHANGE',
                        payload: {
                          name: 'sex',
                          value,
                        },
                      });
                    }}
                    getOptionSelected={(
                      option: SexResponse,
                      value: SexResponse
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: SexResponse,
                      { selected }: { selected: boolean }
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        placeholder={
                          filterState.sex.value.length > 0 ? '' : 'Select Sex'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className='contacts--send-filter-container'>
              {renderCheckbox('species')}

              {filterState.species.checked && (
                <div className='filter-multi'>
                  <Autocomplete
                    value={filterState.species.value}
                    multiple
                    options={optionsSpecies}
                    disableCloseOnSelect
                    getOptionLabel={(option: SpeciesSettingType) => option.name}
                    noOptionsText={'No options'}
                    onChange={(
                      e: React.ChangeEvent<{}>,
                      value: SpeciesSettingType[]
                    ) => {
                      refDisabledCall.current = true;
                      dispatch({
                        type: 'CHANGE',
                        payload: {
                          name: 'species',
                          value,
                        },
                      });
                      getListBreeds(value);
                    }}
                    getOptionSelected={(
                      option: SpeciesSettingType,
                      value: SpeciesSettingType
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: SpeciesSettingType,
                      { selected }: { selected: boolean }
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.name}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        placeholder={
                          filterState.species.value.length > 0
                            ? ''
                            : 'Select Species'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
            </div>

            {filterState.species.checked && (
              <div className='contacts--send-filter-container'>
                {renderCheckbox('breed')}

                {filterState.breed.checked && (
                  <div className='filter-multi'>
                    <Autocomplete
                      value={filterState.breed.value}
                      multiple
                      options={optionsBreed}
                      disableCloseOnSelect
                      getOptionLabel={(option: BreedResponse) => option.name}
                      noOptionsText={'No options'}
                      onChange={(
                        e: React.ChangeEvent<{}>,
                        value: BreedResponse[]
                      ) => {
                        dispatch({
                          type: 'CHANGE',
                          payload: {
                            name: 'breed',
                            value,
                          },
                        });
                      }}
                      getOptionSelected={(
                        option: BreedResponse,
                        value: BreedResponse
                      ) => {
                        return option.id === value.id;
                      }}
                      renderOption={(
                        option: BreedResponse,
                        { selected }: { selected: boolean }
                      ) => {
                        return (
                          <React.Fragment>
                            <Checkbox
                              style={{ marginRight: 8, color: '#1974ff' }}
                              checked={selected}
                              className='checked-role'
                            />
                            {option.name}
                          </React.Fragment>
                        );
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          placeholder={
                            filterState.breed.value.length > 0
                              ? ''
                              : 'Select Breed'
                          }
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            )}

            <div className='contacts--send-filter-container'>
              {renderCheckbox('all')}
            </div>
          </div>

          <div className='contacts--communication-modal-bottom-row'>
            <Button
              variant='contained'
              className='contacts--communication-modal-bottom-button cancel'
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                !isSubmitEnabled ||
                isNoneContacts ||
                !!filterState.birthDate.error ||
                !!filterState.appointmentDate.error
              }
              className={
                'contacts--communication-modal-bottom-button confirm' +
                (isSubmitEnabled &&
                !isNoneContacts &&
                !filterState.birthDate.error &&
                !filterState.appointmentDate.error
                  ? ' enabled'
                  : ' disabled')
              }
              onClick={() =>
                props.communicationModalType === 'email' ||
                (props.communicationModalType === 'text' &&
                  recipients.length <= smsCost.SMSFreeLimit)
                  ? handleSendCommunication()
                  : setOpenBatchPopup(true)
              }
            >
              <FontAwesomeIcon icon={['fas', 'paper-plane']} /> Send{' '}
              {capitalize(props.communicationModalType)}
            </Button>
          </div>
        </div>
      </ReactModal>
      <ModalInsertPersonalize
        openPopupInsertToken={openInsertPersonalize}
        setOpenPopupInsertToken={setOpenInsertPersonalize}
        handleConfirmInsertToken={handleInsertData}
        token={tokenData}
        screen={'manual'}
      />
      <BatchPopup
        open={openBatchPopup}
        content={
          <>
            {props.communicationModalType === 'postal' ? (
              <>
                The selected batch Postal job has an estimated cost of $
                <span className='red'>
                  {formatNumber(postalCost * recipients.length)}
                </span>
                . You will be invoiced for the actual cost at the end of the
                monthly billing cycle. Please confirm or cancel the job.
              </>
            ) : (
              <>
                This batch message exceeds{' '}
                <span className='red'>{smsCost.SMSFreeLimit}</span> recipients.
                Your account will be charged for the additional{' '}
                <span className='red'>
                  {recipients.length > smsCost.SMSFreeLimit
                    ? recipients.length - smsCost.SMSFreeLimit
                    : '0'}
                </span>{' '}
                recipients for a total of $
                <span className='red'>
                  {recipients.length > smsCost.SMSFreeLimit
                    ? formatNumber(
                        smsCost.SMSCost *
                          (recipients.length - smsCost.SMSFreeLimit)
                      )
                    : '0'}
                </span>{' '}
                if sent via Text. You also have the option to send via Email at
                no cost. Please confirm your choice.
              </>
            )}
          </>
        }
        onClose={setOpenBatchPopup}
        onSend={handleSendCommunication}
        type={communicationModalType}
      />
      <WarningPopup open={openWarningPopup} onClose={setOpenWarningPopup} />
    </>
  );
};

export default SendCommunicationFilterModal;
