import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledInput = styled(Input)`
  border-radius: 6px;
  background-color: ${getThemeProp('palette.background.paper', 'white')};

  border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
  .MuiInputBase-input {
    position: relative;
    background-color: ${getThemeProp('palette.background.paper', 'white')};
    font-size: 0.9rem;
    max-width: 100%;
    border-radius: 6px;
    padding: 9px 13px;
  }
  &.Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
  .MuiInputAdornment-root {
    padding-left: 8px;
    margin: 0;
    color: ${getThemeProp('palette.text.secondary', 'lightgray')};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledInput };
