import React, { useState, useEffect } from 'react';

import WithComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import DailyAppointments from 'components/Dashboard/DailyAppointment';
import TopPerformingTemplates from 'containers/Dashboard/TopPerformingTemplatesContainer';
import RightModal from 'components/Global/TeleMed/RightModal';
import EmailAnalytic from 'components/EmailAnalytics/emailAnalytics';
import Modal from '@material-ui/core/Modal';
import { SystemSettingResponse } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Button } from '@material-ui/core';

// containers can be sized to pad their container by adding class "h100" as part of the third argument
const ComponentizedDailyAppointments = WithComponentClassAndHeaderText(
  DailyAppointments,
  'Upcoming Appointments',
  'flex11100 handlecss'
);

const ComponentizedTopPerformingTemplates = WithComponentClassAndHeaderText(
  TopPerformingTemplates,
  'Top Performing Templates',
  'flex1160 pb-0'
);

const ComponentizedEmailAnalytics = WithComponentClassAndHeaderText(
  // this is a placeholder, replace it with your component when the time comes!
  EmailAnalytic,
  'Email Analytics',
  'flex1140 emailAnalytics-tablet'
);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DashboardContainer = (): JSX.Element => {
  const [displayTelemedModal, setDisplayTelemedModal] = React.useState(false);
  const [telemedModalAppointmentData, setTelemedModalAppointmentData] =
    React.useState();
  const [isAlreadyInCall, setIsAlreadyInCall] = React.useState<boolean>(false);
  const [openModalError, setOpenModalError] = React.useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [modalStyle] = React.useState(getModalStyle);

  const closeModalError = () => setOpenModalError(false);

  const [systemSetting, setSystemSetting] =
    React.useState<SystemSettingResponse>();

  const getSystemSetting = async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res) {
        setSystemSetting(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSystemSetting();
  }, []);

  const settingsTimezoneData = systemSetting
    ? systemSetting.settings.timeZoneOffset
    : '';

  return (
    <section
      className='dashboard__container-section'
      key='dashboard-container-section'
    >
      <RightModal
        // @ts-ignore
        appointmentInfo={telemedModalAppointmentData}
        displayTelemedModal={displayTelemedModal}
        setDisplayTelemedModal={setDisplayTelemedModal}
        labelText='Label Text Non-Default'
        isAlreadyInCall={isAlreadyInCall}
        setOpenModalError={setOpenModalError}
        setReload={setReload}
        settingsTimezoneData={settingsTimezoneData || ''}
      />

      <Modal
        open={openModalError}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        onBackdropClick={closeModalError}
      >
        <div style={modalStyle} className='insert-modal-style'>
          <div className='modal-error-body'>
            <p>
              You have an appointment that is currently in-progress. Please end
              it to start a new appointment!
            </p>
            <div className='button-group'>
              <Button
                variant='contained'
                color='primary'
                className='btn-insert'
                onClick={() => {
                  setOpenModalError(false);
                }}
              >
                {' '}
                OK{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <ComponentizedDailyAppointments
        setDisplayTelemedModal={setDisplayTelemedModal}
        // @ts-ignore
        setTelemedModalAppointmentData={setTelemedModalAppointmentData}
        setIsAlreadyInCall={setIsAlreadyInCall}
        setReload={setReload}
        reload={reload}
        settingsTimezoneData={settingsTimezoneData || ''}
      />

      <div className='component-row-container'>
        <ComponentizedEmailAnalytics />

        <ComponentizedTopPerformingTemplates />
      </div>
    </section>
  );
};

export default DashboardContainer;
