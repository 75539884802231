import React from 'react';
import CardContent from '@material-ui/core/CardContent';

import { Divider } from '@material-ui/core';
import { StyledCard } from './styled-components';

export interface CardWithTitleProps {
  title: React.ReactElement;
  style?: React.CSSProperties;
  underline?: boolean;
  children?: (React.ReactElement | boolean)[] | React.ReactElement;
  titlePaddingOverride?: string;
}

const CardWithTitle = (props: CardWithTitleProps): React.ReactElement => {
  const { title, underline = false, children, style, titlePaddingOverride = '1rem 1.5rem' } = props;
  return (
    <StyledCard style={style}>
      <CardContent style={{ padding: titlePaddingOverride }}>{title}</CardContent>
      {underline && <Divider />}
      {children}
    </StyledCard>
  );
};

export default CardWithTitle;
