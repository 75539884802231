import EventEmitter2 from 'eventemitter2';

const IdleNotifier = function(options, onInput) {
  this.options = {
    autostart: false,
    timeout: 1000 * 60 * 5, // 5 minutes
    bindingElement: window,
    events: ['click', 'mouseover', 'keydown'],
    ...options,
  };

  // force the context of _onInputEvent to always be
  // the current instance of IdleNotifier to prevent
  // the need to bind event handlers
  this._onInputEvent = this._onInputEvent.bind(this);

  this._inputTimeoutId = null;
  this._isStarted = false;
  this.isIdle = false;

  if (this.options.autostart) {
    this.start();
  }
};

IdleNotifier.prototype = Object.create(
  EventEmitter2.prototype || EventEmitter2.EventEmitter2.prototype,
);

IdleNotifier.prototype._initInputEventHandlers = function() {
  const self = this;

  self.options.events.forEach(function(eventName) {
    self.options.bindingElement.addEventListener(eventName, self._onInputEvent);
  });
};

IdleNotifier.prototype._uninitInputEventHandlers = function() {
  const self = this;

  self.options.events.forEach(function(eventName) {
    self.options.bindingElement.removeEventListener(eventName, self._onInputEvent);
  });
};

IdleNotifier.prototype._onInputEvent = function(evt) {
  this.emit('input', evt);
  this.reset();
};

IdleNotifier.prototype._initInputTimeout = function() {
  this._inputTimeoutId = setTimeout(this._onInputTimeout.bind(this), this.options.timeout);
};

IdleNotifier.prototype._uninitInputTimeout = function() {
  clearTimeout(this._inputTimeoutId);
};

IdleNotifier.prototype._onInputTimeout = function() {
  this.emit('idle');
  this.stop();
};

IdleNotifier.prototype.start = function() {
  if (!this._isStarted) {
    this._initInputEventHandlers();
    this._initInputTimeout();
  }

  this._isStarted = true;
};

IdleNotifier.prototype.stop = function() {
  if (this._isStarted) {
    this._uninitInputTimeout();
    this._uninitInputEventHandlers();
  }

  this._isStarted = false;
};

IdleNotifier.prototype.reset = function() {
  this._uninitInputTimeout();
  this._initInputTimeout();
};

export default IdleNotifier;
