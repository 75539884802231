import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Modal, Tab, Tabs, Typography } from '@material-ui/core';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import { Provider } from 'global/requests/RequestTypes';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import ProviderAppointmentType from './ProviderAppointmentType';
import ProviderAvailabilityVM, {
  ListDate,
  OfficeEvent,
} from './ProviderAvailabilityVM';
import './ProviderModal.scss';
import ProviderProfile from './ProviderProfile';
import listDateInitial from '../Availability/constant/listDateInitial.json';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import WarningPopup from 'components/Global/WarningPopup/WarningPopup';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import AvailabilityProvider from '../Availability/context/Availability';
ReactModal.setAppElement('div#root');

interface RightModalSettingUserProps {
  displayRoleModal: boolean;
  isEdit: boolean;
  providerModalProfileData: ProviderModal;
  setDisplayRoleModal: (arg: boolean) => void;
  getProviderList: () => void;
  setIsEdit: (arg: boolean) => void;
}

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

export interface IProviderUnvailability {
  accepted: boolean;
  requested: boolean;
}

export type AppointmentTypeModal = {
  id: string;
  appointmentType: string;
  appointmentDuration: number;
  externalId?: string;
  isBookingActive: boolean;
};

export interface Room {
  createdAt?: string;
  externalId?: string;
  id: string;
  name: string;
  order?: number;
  providerId?: string;
  staffId?: string;
  updatedAt?: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const TabContainer = (props: TabContainerProps) => {
  return <Typography component='div'>{props.children}</Typography>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProvidersModal = (props: RightModalSettingUserProps) => {
  const {
    displayRoleModal,
    isEdit,
    providerModalProfileData,
    setIsEdit,
    setDisplayRoleModal,
    getProviderList,
  } = props;

  const [openPopup, setOpenPopup] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState<string>('');

  const [titleModal, setTitleModal] = React.useState<string>('');

  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [sumActiveTabs, setSumActiveTabs] = React.useState<number>(1);
  const [providerProfile, setProviderProfile] = React.useState<ProviderModal>(
    providerModalProfileData
  );
  // const [isEditProvider, setIsEditProvider] = React.useState<boolean>(isEdit);
  const [profile, setProfile] = React.useState<Provider>({} as Provider);

  const [statusProviderUnvailability, setStatusProviderUnvailability] =
    React.useState<IProviderUnvailability>({
      accepted:
        // @ts-ignore
        providerModalProfileData?.hoursOfOperationDto?.isUnavaliableAccepted ||
        false,
      requested:
        // @ts-ignore
        providerModalProfileData?.hoursOfOperationDto?.isUnavaliablePending ||
        false,
    });
  const [listOfficeOff, setListOfficeOff] = React.useState<OfficeEvent[]>(
    // @ts-ignore
    providerProfile?.outOfOfficeEventInfos || []
  );
  const [listDate, setListDate] = React.useState<ListDate[]>(
    // @ts-ignore
    providerProfile?.hoursOfOperationDto?.value || listDateInitial
  );
  // @ts-ignore
  const [listAppointmentTypeSelect, setListAppointmentTypeSelect] =
    React.useState<AppointmentTypeModal[]>([]);
  const [listAppointmentType, setListAppointmentType] = React.useState<
    AppointmentTypeModal[]
  >([]);

  const [dirtyProfile, setDirtyProfile] = React.useState<boolean>(false);
  const [dirtyAppointmentType, setDirtyAppointmentType] =
    React.useState<boolean>(false);
  const [dirtyAvailability, setDirtyAvailability] =
    React.useState<boolean>(false);

  const [listRoom, setListRoom] = React.useState<Room[]>([]);
  const [practiceHoursOfOperation, setPracticeHoursOfOperation] =
    React.useState<ListDate[]>([]);

  const [modalStyle] = React.useState(getModalStyle);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    // @ts-ignore
    setProviderProfile((prevProvider: ProviderModal) => {
      switch (activeTab) {
        case 0:
          return { ...prevProvider, ...profile };
        case 1:
          return {
            ...prevProvider,
            hoursOfOperationDto: {
              ...prevProvider.hoursOfOperationDto,
              value:
                prevProvider.hoursOfOperationDto?.value ??
                practiceHoursOfOperation,
            },
          };
        case 2:
          return {
            ...prevProvider,
            appointmentSettingTypeListResponse: {
              ...prevProvider.appointmentSettingTypeListResponse,
              appointmentSettingTypes: listAppointmentTypeSelect,
            },
          };
        default:
          return { ...prevProvider };
      }
    });
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    getAllAppointmentType();
    getListRoom();
    getPracticeHoursOfOperation();
  }, []);

  React.useEffect(() => {
    if (displayRoleModal) {
      setActiveTab(0);
      setListOfficeOff([]);
      setStatusProviderUnvailability({
        accepted:
          // @ts-ignore
          providerModalProfileData?.hoursOfOperationDto?.isUnavaliableAccepted || false,
        requested:
          // @ts-ignore
          providerModalProfileData?.hoursOfOperationDto?.isUnavaliablePending ||
          false,
      });
    }
  }, [displayRoleModal]);

  React.useEffect(() => {
    setSumActiveTabs(isEdit ? 3 : 1);
    // setTitleModal()
    // setIsEditProvider(isEdit);
    // setProviderProfile(isEdit && providerModalProfileData);
  }, [isEdit]);

  React.useEffect(() => {
    if (providerModalProfileData?.id) {
      setProviderProfile(providerModalProfileData);
      setTitleModal(
        // @ts-ignore
        !!providerModalProfileData?.name.trim()
          ? // @ts-ignore
            providerModalProfileData?.name
          : 'No Name'
      );
      // @ts-ignore
      setListAppointmentTypeSelect(
        providerModalProfileData.appointmentSettingTypeListResponse?.appointmentSettingTypes.filter(
          (apt) => listAppointmentType.find((item) => item.id === apt.id)
        ) || []
      );
    }
  }, [providerModalProfileData, listAppointmentType]);

  const getListRoom = async () => {
    try {
      const res = await configuredRequests.GET.getListRoom();
      if (res) {
        setListRoom(
          // @ts-ignore
          res?.rooms.sort((prev, next) => {
            if (prev.name.toLowerCase() > next.name.toLowerCase()) return 1;
            if (prev.name.toLowerCase() < next.name.toLowerCase()) return -1;
            return 0;
          })
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAllAppointmentType = async () => {
    try {
      const res = await configuredRequests.GET.getAppointmentType();
      if (res) {
        // const listApts = res.filter(appointmentType => appointmentType.acceptAppointment);
        setListAppointmentType(res.filter((item) => item.isBookingActive));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getPracticeHoursOfOperation = async () => {
    try {
      const tenantId = getSessionTenantInfo()?.tenantId ?? '';
      if (tenantId) {
        const response = await configuredRequests.GET.getHoursOfOperation(
          tenantId
        );
        if (response?.result?.id) {
          setPracticeHoursOfOperation(
            // @ts-ignore
            response?.result?.value.map((element: ListDate) => ({
              ...element,
              error: '',
              errorLunch: '',
            }))
          );
        } else {
          setListDate(listDateInitial);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleConfirmPopup = () => {
    setDirtyProfile(false);
    setDirtyAvailability(false);
    setDirtyAppointmentType(false);
    // setIsEditProvider(false);
    setOpenPopup(false);
    setDisplayRoleModal(false);
    // @ts-ignore
    setProfile({} as ProviderModal);
    setListDate([]);
    setListAppointmentTypeSelect([]);
    setStatusProviderUnvailability({ accepted: false, requested: false });
  };

  const renderContainer = () => {
    switch (activeTab) {
      case 0: {
        return (
          <TabContainer id={0}>
            <ProviderProfile
              displayRoleModal={displayRoleModal}
              providerModalProfileData={providerModalProfileData}
              sumActiveTabs={sumActiveTabs}
              openPopup={openPopup}
              providerProfile={providerProfile}
              isEdit={isEdit}
              dirtyProfile={dirtyProfile}
              dirtyAvailability={dirtyAvailability}
              dirtyAppointmentType={dirtyAppointmentType}
              listRoom={listRoom}
              setTitleModal={setTitleModal}
              setIsEdit={setIsEdit}
              // isEditProvider={isEditProvider}
              // setIsEditProvider={setIsEditProvider}
              setDirtyProfile={setDirtyProfile}
              setProfile={setProfile}
              setDisplayRoleModal={setDisplayRoleModal}
              setProviderProfile={setProviderProfile}
              setOpenPopup={setOpenPopup}
              handleCloseForm={handleCloseForm}
              setSumActiveTabs={setSumActiveTabs}
              setActiveTab={setActiveTab}
              getProviderList={getProviderList}
            />
          </TabContainer>
        );
      }
      case 1: {
        return (
          <TabContainer id={1}>
            <AvailabilityProvider>
              <ProviderAvailabilityVM
                openPopup={openPopup}
                providerProfile={providerProfile}
                listDate={
                  providerProfile?.hoursOfOperationDto?.value
                    ? listDate
                    : practiceHoursOfOperation
                }
                listOfficeOff={listOfficeOff}
                dirtyProfile={dirtyProfile}
                dirtyAvailability={dirtyAvailability}
                statusProviderUnvailability={statusProviderUnvailability}
                setStatusProviderUnvailability={setStatusProviderUnvailability}
                setListOfficeOff={setListOfficeOff}
                setDirtyAvailability={setDirtyAvailability}
                setListDate={setListDate}
                setProviderProfile={setProviderProfile}
                setSumActiveTabs={setSumActiveTabs}
                setActiveTab={setActiveTab}
                setDisplayRoleModal={setDisplayRoleModal}
                handleCloseForm={handleCloseForm}
                getProviderList={getProviderList}
              />
            </AvailabilityProvider>
          </TabContainer>
        );
      }
      case 2: {
        return (
          <TabContainer id={2}>
            <ProviderAppointmentType
              displayRoleModal={displayRoleModal}
              openPopup={openPopup}
              // @ts-ignore
              providerId={providerProfile?.id}
              listAppointmentType={listAppointmentType}
              listAppointmentTypeSelect={listAppointmentTypeSelect}
              dirtyProfile={dirtyProfile}
              dirtyAvailability={dirtyAvailability}
              dirtyAppointmentType={dirtyAppointmentType}
              getProviderList={getProviderList}
              setDirtyAppointmentType={setDirtyAppointmentType}
              setListAppointmentTypeSelect={setListAppointmentTypeSelect}
              handleCloseForm={handleCloseForm}
              setDisplayRoleModal={setDisplayRoleModal}
              setOpenPopup={setOpenPopup}
              setSumActiveTabs={setSumActiveTabs}
              setActiveTab={setActiveTab}
            />
          </TabContainer>
        );
      }
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyProfile || dirtyAvailability || dirtyAppointmentType);
    setDisplayRoleModal(
      dirtyProfile || dirtyAvailability || dirtyAppointmentType
    );
    setErrorEmail('');
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal providers'
      id='provider-modal'
      isOpen={displayRoleModal}
      onRequestClose={handleCloseForm}
    >
      <div className='react-modal--settings__title'>
        <p className='title-text'>
          {isEdit ? 'Provider Details for ' + titleModal : 'Add New Provider'}
        </p>
        <button className='react-modal-close-icon' onClick={handleCloseForm}>
          <FontAwesomeIcon icon={['far', 'times']} />
        </button>
      </div>
      <AppBar position='static' className='container__table'>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor='primary'
        >
          <Tab label='Profile' />
          <Tab label='Availability' disabled={sumActiveTabs < 2} />
          <Tab label='Appointment Types' disabled={sumActiveTabs < 3} />
        </Tabs>
      </AppBar>
      {renderContainer()}

      <ModalDiscard
        isOpenModal={openPopup}
        title={`${isEdit ? 'Discard Changes?' : 'Confirmation!'}`}
        handleCloseModal={() => setOpenPopup(false)}
        handleConfirmModal={handleConfirmPopup}
      />

      {/* <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal> */}
    </ReactModal>
  );
};
export default ProvidersModal;
