import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { ErrorMessage, FastField, Field, FieldArray, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React, { Fragment, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Deal } from './AgreementsCustomer';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import toast from 'components/Global/Toast';
ReactModal.setAppElement('div#root');

interface PracticeModalProps {
  displayModal: boolean;
  edit: boolean;
  infoData: Deal;
  setDisplayModal: (arg: boolean) => void;
  handleReloadList: () => void;
}

interface OptionDropDown {
  label: string;
  value: string
}

interface OptionAddon {
  label: string;
  value: string;
  unitPrice: string;
}

interface OptionPackage {
  packageId: string;
  priceId: string;
  label: string;
  value: string;
  prices: OptionPrice[];
  monthlyFee: string;
  setupFee: string;
}

interface OptionPrice {
  unitAmount: number | string;
  singlePriceId: string;
  label: string;
  value: number | string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const AgreementValidationSchema = Yup.object().shape({
  packageName: Yup.string().required(ErrorMessageTypes.required),
  price: Yup.string().required(ErrorMessageTypes.required),
  agreementContactEmail: Yup.string().email(ErrorMessageTypes.incorrectFormat).required(ErrorMessageTypes.required),
  agreementContactName: Yup.string().required(ErrorMessageTypes.required),
  packageStartDate: Yup.string().required(ErrorMessageTypes.required),
  packageSetupFee: Yup.string().required(ErrorMessageTypes.required)
});

const AgreementModal = (props: PracticeModalProps) => {
  const { displayModal, edit, infoData, setDisplayModal, handleReloadList } = props;
  const params = useParams<{ groupId: string }>();

  const [modalStyle] = useState(getModalStyle);
  const [openPopup, setOpenPopup] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [optionsPrice, setOptionsPrice] = useState<OptionPrice[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<OptionPrice>();
  const [optionsPackage, setOptionsPackage] = useState<OptionPackage[]>([]);
  const [optionsStartDate, setOptionsStartDate] = useState<OptionDropDown[]>([]);
  const [optionsPractice, setOptionsPractice] = useState<OptionDropDown[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<OptionPackage>();
  const [optionSetupFee, setOptionSetupFee] = useState<OptionPrice[]>();
  const [selectedSetupFee, setSelectedSetupFee] = useState<OptionPrice>();

  const getPackages = async () => {
    try {
      const res = await configuredRequests.GET.getAllPackages();
      //@ts-ignore
      const packs = res?.products?.filter(a=> a?.product?.active).map((singlePackage:any)=>{
        if(singlePackage?.product?.name === "One Time Setup Fee") return {};
        const packageId = singlePackage?.product?.id
        const packagePrice = singlePackage?.price?.find((singlePrice: any) => singlePrice?.id === singlePackage?.product?.default_price)
        const monthlyFee =  packagePrice?.unit_amount ? packagePrice?.unit_amount.toFixed(2) / 100 : 'No default price!';
        const priceId = packagePrice?.id
        const label = singlePackage?.product?.name;
        const value = singlePackage?.product?.name.replace(/\s/g, '');
        const prices = singlePackage?.price?.map((singlePrice:any) => {
          if(!singlePrice?.unit_amount) {
            return { unitAmount: 0, singlePriceId: 'notValidPrice', label: 'Not a valid price', value: 0 }
          }
          const unitAmount = singlePrice?.unit_amount.toFixed(2) / 100;
          const singlePriceId = singlePrice?.id
          const label =`$${unitAmount.toString()}`
          const value = unitAmount;
          return { unitAmount, singlePriceId, label, value }
        })
        const setupFee = 0;
        return {
          packageId,
          priceId,
          label,
          prices,
          value,
          monthlyFee,
          setupFee
        }
      })
      //@ts-ignore
      const setupFeePrices = res?.setupFee?.filter(a=>a?.product?.active).map((sf:any)=>{
        const sfPrices = sf?.price?.map((singlePrice:any)=> {
          const unitAmount = singlePrice?.unit_amount.toFixed(2) / 100;
          const singlePriceId = singlePrice?.id;
          const label = `$${unitAmount.toString()}`;
          const value = unitAmount;
          return { unitAmount, singlePriceId, label, value }
       }) 
       //@ts-ignore
       sfPrices.sort((a,b) => a.unitAmount - b.unitAmount);
       sfPrices.unshift({ unitAmount: 0, singlePriceId: 'noSetupFee', label: 'No Setup Fee', value: 'noSetupFee' })
       return sfPrices 
      })
      setOptionSetupFee(setupFeePrices[0])
      setOptionsPackage(packs);
    } catch (err) {
      console.log('err', err);
    }
  }

  const getStartDates = async () => {
    try {
      const res = [{ label:'No trial', value: '0' }, { label: '30 days', value: '30' }, { label: '60 days', value: '60' }, { label: '90 days', value: '90' }, { label: '120 days', value: '120' }, { label: '150 days', value: '150' }, { label: '180 days', value: '180' }];
      setOptionsStartDate(res);
    } catch (err) {
      console.log('err', err);
    }
  }

  // I can see this potentially being used in the future maybe?
  // const getPractices = async () => {
  //   try {
  //     const res = await configuredRequests.GET.getTenantsByGroupId(params?.groupId ?? '');
  //     const optionsData = res?.tenants.filter(tenant => tenant.status === 'Active').map(item => ({label: item.tenantName, value: item.tenantId}));
  //     // @ts-ignore
  //     setOptionsPractice(optionsData);
  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // }

  useEffect(() => {
    Promise.all([
      // getPractices(),
      getPackages(),
      getStartDates()
    ]).then();
  }, []);


  const cancelPopup = async () => {
    props.setDisplayModal(false);
  };

  const initialData = edit ? {
    id: infoData?.id,
    agreementNote: infoData?.notes,
    agreementContactName: infoData?.contactName,
    agreementContactEmail: infoData?.practiceEmail,
    package: optionsPackage[0],
    monthlyFee: infoData?.package?.monthlyFee,
    price: infoData?.package?.monthlyFee,
  } : {
      practiceName: optionsPractice[0] ? optionsPractice[0] : '',
      package: optionsPackage[0] ? optionsPackage[0] : {
      label: '',
      value: '',
      monthlyFee: '0',
    },
    price: {
      label: "",
      singlePriceId: "",
      unitAmount: 0,
      value: 0,
    },
  };

  const handleSubmitData = async (values: any) => {
    try{
    const data = {
      notes: values?.agreementNote,
      package: JSON.stringify({ 
        package: values?.package?.label, 
        selectedPrice: selectedPrice?.singlePriceId, 
        monthlyFee: selectedPrice?.label, 
        trialPeriodDays: values?.packageStartDate.value,
        setupFee: selectedSetupFee?.label,
        setupFeeId: selectedSetupFee?.singlePriceId
      }),
      practiceEmail: values?.agreementContactEmail,
      tenantGroupId: params?.groupId,
      contactName: values?.agreementContactName,
    }

    const createdDeal = await configuredRequests.POST.createNewDeal(data);
    if(createdDeal){
      toast.success("This deal has been created successfully!");
      handleReloadList();
      setDisplayModal(false);
    }
    } catch (err){
      toast.error("Error creating deal. Please try again later.");
    }
  };

  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (dirtyForm) {
      setOpenPopup(true);
    } else {
      setDisplayModal(false);
    }
  };

  const handleConfirmModal = () => {
    setOpenPopup(false);
    setDisplayModal(false);
  }

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={displayModal}
      onRequestClose={handleCloseModal}
    >
      <Formik
        initialValues={initialData}
        validationSchema={AgreementValidationSchema}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          handleSubmitData(values);
        }}
      >
        {formikProps => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            setFieldTouched,
            dirty,
          } = formikProps;
          setDirtyForm(dirty);
          return (
            <div className='react-modal--content modal--add-agreement'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {edit ? 'View Agreement' : 'Add New Agreement'}{' '}
                </p>

                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment roleModal '>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Agreement Information</h2>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Contact Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='agreementContactName'
                            maxLength={255}
                            required
                            placeholder=''
                            defaultValue={edit? infoData.contactName : ''}
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'agreementContactName',
                                e.currentTarget.value.trim()
                              );
                            }}
                            disabled={edit}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='agreementContactName' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Contact Email <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={255}
                            name='agreementContactEmail'
                            className='setting__search-form'
                            defaultValue={edit? infoData.practiceEmail : ''}
                            onBlur={() => {
                              setFieldTouched('agreementContactEmail', true);
                            }}
                            onChange={(e: any) => {
                              setFieldValue('agreementContactEmail', e.currentTarget.value.trim());
                            }}
                            maxMenuHeight={150}
                            disabled={edit}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='agreementContactEmail' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>Agreement Notes</span>
                      <FastField
                        maxLength={500}
                        name='agreementNote'
                        component='textarea'
                        className='description-input'
                        defaultValue={edit? infoData.notes : ''}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'agreementNote',
                            e.currentTarget.value.trim()
                          );
                        }}
                        disabled={edit}
                      />
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Service Package</h2>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Service Package <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            classNamePrefix='search-city'
                            className='option-city'
                            component={Select}
                            name='package'
                            options={optionsPackage}
                            defaultValue={edit ? {label:infoData.package.package, value: ""} : {label:"Select a Package", value:""}}
                            onBlur={() => {
                              setFieldTouched('package', true);
                            }}
                            onChange={(e: any) => {
                              setFieldValue('package', e);
                              setFieldValue('packageName', e?.label);
                              setSelectedPrice({label:"Select...",value:"", unitAmount:0, singlePriceId: ""});
                              setSelectedPackage(e);
                              setOptionsPrice(e?.prices);
                            }}
                            maxMenuHeight={150}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='package' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Monthly Fee <b className='require'>*</b>
                        </span>
                        <Field
                          name='packageMonthlyFee'
                          classNamePrefix='search-city'
                          className='option-city'
                          component={Select}
                          defaultValue={edit ? { label:infoData.package.monthlyFee, value : '' } : selectedPrice}
                          value={selectedPrice}
                          options={optionsPrice}
                          onBlur={()=>{
                            setFieldTouched("price", true);
                          }}
                          onChange={(e:any)=>{
                            setSelectedPrice(e)
                            setFieldValue('price', e);                         
                          }}
                          required
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='packageMonthlyFee' />
                        </p>
                      </div>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          {' '}
                          Setup Fee <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <Field
                            name='packageSetupFee'
                            component={Select}
                            required
                            defaultValue={edit ? {label: infoData.package.setupFee, value: '' } : {label: "Select a setup fee", value:''}}
                            options={optionSetupFee}
                            className='option-city'
                            classNamePrefix='search-city'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              setFieldTouched("packageSetupFee", true);
                            }}
                            onChange={(e:any)=>{
                              setSelectedSetupFee(e);
                              setFieldValue('packageSetupFee',e.value);
                            }}
                            disabled={edit}
                          />
                          <p className='error-msg'>
                              <ErrorMessage name='packageSetupFee' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Days Free: <b className='require'>*</b>
                        </span>
                        <div className='first-nam'>
                          <FastField
                            classNamePrefix='search-city'
                            className='option-city'
                            component={Select}
                            name='packageStartDate'
                            options={optionsStartDate}
                            defaultValue={edit ? { label: `${infoData.package.trialPeriodDays} days`, value: 0 } : { label: "Select...", value: "default" }}
                            onBlur={() => {
                              setFieldTouched('packageStartDate', true);
                            }}
                            onChange={(e: any) => {
                              setFieldValue('packageStartDate', e);
                            }}
                            maxMenuHeight={150}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={cancelPopup}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid || edit}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        isOpenModal={openPopup}
        title="Confirmation"
        handleCloseModal={setOpenPopup}
        handleConfirmModal={handleConfirmModal}
      />
    </ReactModal>
  );
};
export default AgreementModal;
