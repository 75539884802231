import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { NoPermissionRouterProps } from 'types/RouterT';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Formik, ErrorMessage, FastField } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import headerLogo from 'media/images/Vet-Hero-Frenchie.png';
import { Button } from '@material-ui/core';

export interface LoginProps extends NoPermissionRouterProps {}

const SetPassword = (props: LoginProps) => {
  window.document.title = 'VetHero CRM | Set Password';
  const location = useLocation();

  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState('');
  const [otherError, setOtherError] = React.useState('');

  const handleSubmitSetPassword = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    const searchParam = location.search;
    const query = new URLSearchParams(searchParam.split('+').join('%2B'));
    const token = query.get('token');
    const userId = query.get('userId');
    if (!userId || !token) {
      setOtherError(
        'Set password failed. Please contact administration for support.'
      );
      return;
    }
    try {
      setDisabledSubmit(true);
      const submitData = {
        token: token,
        password: values.password,
      };
      const res = await configuredRequests.POST.setPassword(userId, submitData);
      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your password is set successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
        setDisabledSubmit(false);
        setRedirect(true);
      }
    } catch (err) {
      setDisabledSubmit(false);
      if (err.response.status === 400) {
        const message = err.response.data.message;
        if (message === 'Invalid token.' || message.substr(0, 4) === 'User') {
          setOtherError(
            'Set password failed. Please contact administration for support.'
          );
        } else {
          setErrorPassword(message);
        }
      }
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Set password failed</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  if (redirect) {
    return <Redirect to='/' />;
  }

  const ErrorMessageType = {
    required: 'This field is required.',
    minLengthPassword: 'Please enter at least 10 characters.',
    reEnterConfirmPassword:
      'Your entered password does not match. Please re-enter.',
  };

  const SetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(10, ErrorMessageType.minLengthPassword)
      .matches(
        /(?=.*[^a-zA-Z\d\s])/,
        'Passwords must have at least one non alphanumeric character.'
      )
      .required(ErrorMessageType.required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], ErrorMessageType.reEnterConfirmPassword)
      .required(ErrorMessageType.required),
  });

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={SetPasswordValidationSchema}
      validateOnMount={true}
      // validateOnChange={false}
      onSubmit={(values, actions) => {
        handleSubmitSetPassword(values);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, setFieldValue } = formikProps;

        return (
          <>
            <div className='login__container'>
              <div className='login__form-container'>
                <form onSubmit={handleSubmit} className='login__form'>
                  <div className='login__logo-container set-password-logo'>
                    <img src={headerLogo} alt={'Fetch logo'} />
                  </div>
                  <div className='login__input-area-container'>
                    <div className={'login__input-container'}>
                      <label htmlFor={'email'}>
                        <span>New Password</span>
                      </label>
                      <FastField
                        placeholder='New Password'
                        type='password'
                        name='password'
                        autocomplete='off'
                        maxLength={255}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const trimText = event.target.value.trim();
                          setErrorPassword('');
                          setOtherError('');
                          setFieldValue('password', trimText);
                        }}
                      />
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          padding: '3px 0 1px',
                        }}
                      >
                        {errorPassword || <ErrorMessage name='password' />}
                      </p>
                    </div>

                    <div className={'login__input-container'}>
                      <label htmlFor='password'>
                        <span>Confirm Password</span>
                      </label>
                      <FastField
                        placeholder='Confirm Password'
                        type='password'
                        required
                        name='confirmPassword'
                        autocomplete='off'
                        maxLength={255}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const trimText = event.target.value.trim();
                          setErrorPassword('');
                          setOtherError('');
                          setFieldValue('confirmPassword', trimText);
                        }}
                      />
                      <p
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          padding: '3px 0 1px',
                        }}
                      >
                        <ErrorMessage name='confirmPassword' />
                      </p>
                    </div>
                  </div>

                  <Button
                    variant='contained'
                    color='primary'
                    className='login__input-submit login__input-setpassword'
                    type='submit'
                    disabled={!formikProps.isValid || disabledSubmit}
                  >
                    <span>Set Password</span>
                  </Button>
                  {otherError && (
                    <div
                      style={{
                        lineHeight: 'initial',
                        margin: '20px 0 0 0',
                        fontSize: '15px',
                        color: 'red',
                      }}
                    >
                      {otherError}
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div style={{ width: '100%', height: '10px' }} />
          </>
        );
      }}
    </Formik>
  );
};

export default SetPassword;
