import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Modal } from '@material-ui/core';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { Column } from 'global/requests/ResponseTypes';
import FilterGlobal from 'components/Global/Filter/Filter';
import './AgreementGenerate.scss';
import ModalDialog from 'components/Global/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

type OptionDropDown = {
  value: string;
  label: string;
};

const AgreementGenerate = () => {
  const [modalStyle] = React.useState(getModalStyle);
  const [openModalSigningLink, setOpenModalSigningLink] =
    useState<boolean>(false);

  const renderPopupSigningLink = () => {
    return (
      <div style={modalStyle} className='insert-modal-style modal-generate'>
        <h2 id='simple-modal-title'>
          {' '}
          Signing Link
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            fixedWidth
            onClick={() => {
              setOpenModalSigningLink(false);
            }}
          />
        </h2>

        <div className='insert-body'>
          <a
            className='link-generate'
            href='https://portal.staging.fetchprm.dev/FormBuilder'
            target='_self'
            rel='noreferrer'
          >
            https://portal.staging.fetchprm.dev/FormBuilder
          </a>
        </div>

        <div className='action-modal'>
          <button
            className='btn-cancel'
            onClick={() => {
              setOpenModalSigningLink(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='communication-container'>
        <div className='communication-container__table react-modal--settings__content agreement__generate'>
          <div className='setting__content--full-row '>
            <h1 className='generate-title'>Month to Month Agreement</h1>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-50'>
              <h2 className='practice-title'>Agreement Contact</h2>
            </div>
            <div className='width-50'>
              <h2 className='practice-title'>Sales Contact</h2>
            </div>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Name
              </span>
              <span className='agreement-field--content'>Messi</span>
            </div>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Name
              </span>
              <span className='agreement-field--content'>Join Wick</span>
            </div>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Email
              </span>
              <span className='agreement-field--content'>
                ronaldo@yopmail.com
              </span>
            </div>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Email
              </span>
              <span className='agreement-field--content'>
                ronaldo@yopmail.eu
              </span>
            </div>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Phone Number
              </span>
              <span className='agreement-field--content'>(012) 345-6789</span>
            </div>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Phone Number
              </span>
              <span className='agreement-field--content'>(012) 345-6789</span>
            </div>
          </div>

          <div className='setting__content--full-row flex'>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Address
              </span>
              <span className='agreement-field--content'>
                15 B US, WA 90001
              </span>
            </div>
            <div className='width-50'>
              <span className='title-input-form agreement-field--label'>
                Address
              </span>
              <span className='agreement-field--content'>
                11 A US, WA 90001
              </span>
            </div>
          </div>

          <div className='setting__content--full-row'>
            <span className='agreement-field--content agreement-field--content-bold'>
              Seller agrees to sell to Buyer and Buyer agrees to purchase from
              Seller the following products:
            </span>
          </div>

          <div className='setting__content--full-row'>
            <span className='agreement-field--content agreement-field--content-bold'>
              1. Service Package: Demo Package 1
            </span>
            <span className='agreement-field--content d-block'>
              <span className='agreement-field--content-label'>
                Monthly Fee
              </span>
              <span>:&nbsp;&nbsp;{`$999`}</span>
            </span>
            <span className='agreement-field--content d-block'>
              <span className='agreement-field--content-label'>Setup Fee</span>
              <span>:&nbsp;&nbsp;{`$100`}</span>
            </span>
            <span className='agreement-field--content d-block'>
              <span className='agreement-field--content-label'>Tax Code</span>
              <span>:&nbsp;&nbsp;{`AZ100`}</span>
            </span>
          </div>

          <div className='setting__content--full-row addon-price'>
            <span className='agreement-field--content agreement-field--content-bold'>
              2. Add-on: Demo Add-on 1
            </span>
            <span className='agreement-field--content d-block'>
              <span className='agreement-field--content-label'>Unit Price</span>
              <span>:&nbsp;&nbsp;{`$100`}</span>
            </span>
            <span className='agreement-field--content d-block'>
              <span className='agreement-field--content-label'>Tax Code</span>
              <span>:&nbsp;&nbsp;{`AZ100`}</span>
            </span>
          </div>

          <div className='setting__content--full-row total-price'>
            <span className='agreement-field--content agreement-field--content-bold'>
              Subscription Sub Total
            </span>
            <span className='agreement-field--content'>{`$1.300/1month`}</span>
          </div>

          <div className='setting__content--full-row'>
            <span className='title-input-form info'>
              <span>
                <FontAwesomeIcon icon={['fas', 'info-circle']} />
              </span>
              <span>
                Additional monthly service change may apply (e.g., post cards,
                gifts, and other services).
              </span>
            </span>
          </div>

          <div className='setting__content--full-row policy'>
            <span className='agreement-field--content'>
              <Checkbox />
              <span>I agree to the </span>
              <a href='#'>VetHero Tems of Service</a>
              <span> and </span>
              <a href='#'>Privacy Policy</a>
            </span>
          </div>

          <div className='setting__content--full-row'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setOpenModalSigningLink(true)}
            >
              Next Step
            </Button>
          </div>
        </div>
      </div>

      <ModalDialog
        isOpenModal={openModalSigningLink}
        isFullAction={false}
        title="Signing Link"
        textRightButton="Close"
        classMore="modal--generate-link"
        handleCloseModal={() => setOpenModalSigningLink(false)}
        handleConfirmModal={() => setOpenModalSigningLink(false)}
      >
        <a
            className='link-generate'
            href='https://portal.staging.fetchprm.dev/FormBuilder'
            target='_self'
            rel='noreferrer'
          >
            https://portal.staging.fetchprm.dev/FormBuilder
          </a>
      </ModalDialog>
    </>
  );
};

export default AgreementGenerate;
