import validator from 'validator';
import {
  getFieldValidators,
  getFieldValue,
  getFieldProperties,
  getFieldPropertyValues,
  Messages,
} from './components/form-field-types/helpers';

const validateSubField = (field) => {
  const visibleSubfields = field.fields.map(
    (sub) => sub.properties[0][1].value
  );
  const values = field.value;
  console.log('sub', values);
  return (
    visibleSubfields.findIndex((item, idx) => {
      if (
        ['Co-Owner Address', 'Contact Address'].includes(field.display_name) &&
        idx === 1
      ) {
        return false;
      }
      return item && !values[idx];
    }) === -1
  );
};

export const validateField = (field, requiredOverride = false) => {
  const makeRequired = requiredOverride || isRequired(field);
  const value = getFieldValue(field);
  const validators = getFieldValidators(field);
  const makeHidden = isHidden(field);
  const properties = getFieldProperties(field, 'specific');
  let errors = [];

  if (makeRequired && !makeHidden) {
    const validationError = validateIsBlank(field, value);
    // if (validationError) errors.push(validationError);
    if (
      [
        'Contact Name',
        'Co-Owner Name',
        'Co-Owner Address',
        'Contact Address',
      ].includes(field.display_name) &&
      !validateSubField(field)
    ) {
      errors.push(Messages.CANT_BE_BLANK);
    } else if (
      field.display_name === 'Pet Species & Breed' &&
      !validateSubField(field)
    ) {
      errors.push(Messages.SELECT_DROPDOWN);
    } else if (validationError) {
      errors.push(validationError);
    }
  }

  // only run validations if we either have a value or it is a required field
  // e.g., a blank value shouldn't fail validation unless the field is required
  if (value && errors.length === 0) {
    validators.forEach((validation) => {
      const result = validation.fn(value, { properties });
      if (result && !result.valid) errors.push(result.message);
    });
  }

  if (errors.length > 0) {
    return [`${field.display_name || field.type_name} ${errors[0]}`];
  }
  // if (errors.length > 0) return errors.map(error => `${field.display_name || field.type_name} ${error}`);
};

const isHidden = (field) => {
  return getFieldPropertyValues(
    getFieldProperties(field, 'common').common,
    'makeHidden'
  ).makeHidden;
};

const isRequired = (field) => {
  return getFieldPropertyValues(
    getFieldProperties(field, 'common').common,
    'makeRequired'
  ).makeRequired;
};

const validateIsBlank = (field, value) => {
  if (field.type_name === 'Multiple Choice' && noneTrue(value))
    return Messages.ONE_CHECKED;
  if (!value || validator.isEmpty(value.toString()))
    return Messages.CANT_BE_BLANK;
};

// custom validator for checkbox groups (multiple choice)
// that enforces at least one option should be checked (true), if required.
const noneTrue = (values) =>
  values.filter((value) => Object.values(value)[0]).length === 0;
