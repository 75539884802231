import React from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import { Signature } from './properties-config';
import { FormFieldBase } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';
import ValidationMessage from './validation-message';

export class FormFieldSignature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: 0,
      canvasWidth: 0,
    };

    this.mounted = false; // see this.handleResize for purpose

    this.handleResize = debounce(this.handleResize, 200);
  }

  componentDidMount() {
    this.mounted = true;

    window.addEventListener('resize', this.handleResize);

    this.setState({
      canvasWidth: this.getContainerElement().clientWidth,
      windowWidth: window.innerWidth,
    });
  }

  componentDidUpdate() {
    const { value, readOnly } = this.props;

    if (!this.signatureEl) return;

    // if we have a saved signature value, pass it on to the signature component to render
    if (value) {
      if (!this.signatureEl.isEmpty() && readOnly) this.signatureEl.clear();
      else {
        this.signatureEl.fromDataURL(decodeURIComponent(value), {
          ratio: window.devicePixelRation ? 1 / window.devicePixelRatio : 1,
        });
      }
    }

    if (readOnly) this.signatureEl.off();
    // unbinds all event handlers
    else this.signatureEl.on(); // rebinds all event handlers
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  getContainerElement = () => this.containerEl || {};

  handleResize = () => {
    // Check if component is mounted before proceeding.
    // Potentially as a product of being debounced, this method was sometimes
    // trying to setState on an unmounted component which causes the warning:
    // 'Warning: setState(...): Can only update a mounted or mounting component. This usually means you called setState() on an unmounted component.'
    if (!this.mounted) return;

    const { windowWidth, canvasWidth } = this.state;
    const newWidth = window.innerWidth;
    const delta = newWidth - windowWidth;
    const newCanvasWidth = canvasWidth + delta;

    if (delta !== 0)
      this.setState({
        windowWidth: newWidth,
        canvasWidth: newCanvasWidth,
      });
  };

  handleDrawEnd = e => {
    const { onChange, onBlur } = this.props;
    // customize event object to add a value property
    // NOTE: spreading e (MouseEvent) not working (why?),
    // so just building minimal object needed to pass to onChange method
    const event = {
      target: {
        id: e.target.id,
        value: encodeURIComponent(this.signatureEl.toDataURL()),
      },
    };

    onChange(event);
    onBlur(event);
  };

  handleClear = () => {
    const { field, readOnly, onChange, onBlur } = this.props;
    const event = {
      target: {
        id: field.uuid,
        value: '',
      },
    };
    if (!readOnly) {
      this.signatureEl.clear();
      onChange(event);
      onBlur(event);
    }
  };

  sublabel = field => {
    const localProps = new Map(Signature);
    const propertyNames = ['sublabel'];
    const defaultValues = propertyNames.map(propName => ({
      [`${propName}`]: localProps.get(propName).defaultValue,
    }));
    const properties = getFieldProperties(field, 'specific');
    const { sublabel } = getFieldPropertyValues(properties.specific, propertyNames, defaultValues);

    if (sublabel) return <div className='form-field-label secondary'>{sublabel}</div>;
  };

  render() {
    const { field, className, commonProps, isRenderedField, validationMessage } = this.props;
    const { canvasWidth } = this.state;
    const classes = classNames({
      [className]: true,
      signature: true,
      'control-group error': validationMessage,
    });

    return (
      <div className={classes}>
        <div className='form-field-label primary'>{`${commonProps.label} ${
          commonProps.makeRequired ? '*' : ''
          }`}</div>
        <div className='form-field-input'>
          <div
            className={`signature ${!isRenderedField ? 'span30' : ''}`}
            ref={el => (this.containerEl = el)}
          >
            {!isRenderedField && <span>Signature Area</span>}
            {isRenderedField && (
              <SignatureCanvas
                ref={el => (this.signatureEl = el)}
                canvasProps={{
                  id: field.uuid,
                  width: canvasWidth,
                  height: '150',
                }}
                clearOnResize={false}
                onEnd={this.handleDrawEnd}
              />
            )}
          </div>
          <div className='form-field-footer' style={{ width: '100%' }}>
            {this.sublabel(field)}
            <div className='clear' onClick={this.handleClear}>
              <span title='Clear signature'>Clear</span>
            </div>
          </div>
        </div>
        <ValidationMessage message={validationMessage} />
      </div>
    );
  }
}

FormFieldSignature.defaultProps = {
  className: '',
  commonProps: {},
  isRenderedField: false,
  onChange: () => { },
  onBlur: () => { },
  value: '',
  readOnly: false,
};

export default FormFieldBase(FormFieldSignature);
