import React from 'react';
import PropTypes from 'prop-types';

import FieldGroup from './field-group';
import FieldItem from './field-item';

// TODO: move to database when it makes sense to do so
const pageElements = [
  {
    id: -102,
    display_name: 'Description Area',
    type_name: 'Description Area',
  },
  {
    id: -101,
    display_name: 'Section Break',
    type_name: 'Section Break',
    fields: [],
  },
];

const renderFieldItems = (fields, onFieldHover, setIsDragNewItem) => {
  return fields.map((field, index) => {
    return (
      <FieldItem
        key={index}
        label={field.display_name || field.type_name}
        field={field}
        onHover={onFieldHover}
        setIsDragNewItem={setIsDragNewItem}
      />
    );
  });
};

const FieldList = props => {
  const { basicFields, advancedFields, onFieldHover, setIsDragNewItem } = props;
  // TODO: fix this with flex
  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', minWidth: '252px', background : '#fff',padding: '20px 0', height: 'auto' }}>
      <FieldGroup groupName='Basic Fields'>
        {renderFieldItems(basicFields, onFieldHover, setIsDragNewItem)}
      </FieldGroup>
      <FieldGroup groupName='Advanced Fields'>
        {renderFieldItems(advancedFields, onFieldHover, setIsDragNewItem)}
      </FieldGroup>
      <FieldGroup groupName='Page Elements'>
        {renderFieldItems(pageElements, onFieldHover, setIsDragNewItem)}
      </FieldGroup>
    </div>
  );
};

FieldList.propTypes = {
  basicFields: PropTypes.array.isRequired,
  advancedFields: PropTypes.array.isRequired,
  onFieldHover: PropTypes.func,
  setIsDragNewItem: PropTypes.func
};

FieldList.defaultProps = {
  onFieldHover: () => {},
};

export default FieldList;
