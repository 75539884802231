/* eslint-disable default-case */
exports.messageType = function(type) {
  var className;
  
  switch(type.toUpperCase()) {
    case "SMS": className = "icon-comment"; break;
    case "EMAIL": className = "icon-paper-plane"; break;
    case "GIFT":
    case "POSTAL": className = "icon-envelope"; break;
  }

  return className;
};