/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { CloudDownload, Create, Delete } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { convertPhoneNumber } from 'utils/convertData';
import CustomCheckbox, {
  CustomCheckboxDiv,
} from 'components/Contacts/CustomCheckbox';
import FormRenderer from 'components/FormBuilder/components/FormBuilder/forms/form-renderer';
import { TablePaginationActions } from 'components/Global/CommunicationTable/CommunicationTable';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import WarningPopup from 'components/Global/WarningPopup/WarningPopup';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { LeftMenuSubmissionItemsDataType } from 'global/requests/ResponseTypes/LeftMenuSubmissionDataType';
import { capitalize, debounce, intersection, map, upperFirst } from 'lodash';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import ReactModal from 'react-modal';
import toast from 'components/Global/Toast';
import './AllSubmissionsListTable.scss';
import EmailSupportSubmissionModal from './EmailSupport/EmailSupportSubmissionModal';
import { getSessionTenantInfo } from 'global/sessionStorage/SessionAPIResponses';
import ModalDialog from 'components/Global/Modal';
import Table from 'components/Global/Table';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import axios, { CancelTokenSource } from 'axios';
import SubmittedDocumentsModal from '../../../specify/SubmittedFormContainer/SubmittedDocumentsModal';

const formSubmissionsInitialData = {
  count: 0,
  offset: 0,
  totalAvailable: 0,
  allowApprove: false,
  items: [
    {
      id: '',
      submissionDate: '',
      name: '',
      email: '',
      isApproved: false,
      isRead: false,
      link: '',
    },
  ],
};

interface DropdownOptionType {
  label: string;
  value: string;
}
interface QueryGetListSubmissionType {
  limit?: number;
  offset?: number;
  withStatus?: string;
  withName?: string;
}

interface IOptionPopup {
  show: boolean;
  support: boolean;
}

interface OptionContact {
  id: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  phoneNumber: string;
  address: string;
  pets: string;
}

interface OptionPet {
  id: string;
  petName: string;
  sex: string;
  specie: string;
  breed: string;
}

const headerTableWithApproving = [
  '',
  'Submission Date',
  'Name',
  'Email',
  'Approval',
];
const headerTableWithoutApproving = ['', 'Submission Date', 'Name', 'Email'];

interface SubmissionsDetailProps {
  formInfoData: LeftMenuSubmissionItemsDataType;
  optionDataSubmissionFilter: DropdownOptionType[];
  submissionNoti: string;
  setSubmissionNoti: React.Dispatch<React.SetStateAction<string>>;
  reloadData: () => void;
}

interface ChangedField {
  fieldName: string;
  formValue: string;
  dbValue: string;
  shouldUpdate: boolean;
}

interface Submission {
  id: string;
  JsonStructure: string;
  email?: string;
  submissionsDetail: string;
  submissionDate: string;
  name?: string;
  isApproved?: boolean;
  isRead?: boolean;
  link: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
    },
  })
);

const columnsContact = [
  { key: 'firstName', title: 'First Name' },
  { key: 'lastName', title: 'Last Name' },
  { key: 'phoneNumber', title: 'Phone Number' },
  { key: 'email', title: 'Email' },
  { key: 'address', title: 'Address' },
  { key: 'pets', title: 'Pets' },
];

const columnsPet = [
  { key: 'name', title: 'Name' },
  { key: 'sex', title: 'Sex' },
  { key: 'specie', title: 'Species' },
  { key: 'breed', title: 'Breed' },
];

const columnsChange = [
  { key: 'name', title: 'Field Name' },
  { key: 'formValue', title: 'Form Value' },
  { key: 'systemValue', title: 'System Value' },
  { key: 'shouldUpdate', title: 'Should Update' },
];

const styleCellBody: React.CSSProperties = {
  fontFamily: 'Nunito Sans, Calibri, Arial',
  fontSize: '.8125rem',
  fontWeight: 'normal',
  letterSpacing: '0',
  lineHeight: '18px',
};

let source: CancelTokenSource;

const AllSubmissionsListTable = (props: SubmissionsDetailProps) => {
  const classes = useStyles();
  const {
    formInfoData,
    optionDataSubmissionFilter,
    submissionNoti,
    setSubmissionNoti,
    reloadData,
  } = props;
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [statusSelectedFilterValue, setStatusSelectedFilterValue] = useState(
    optionDataSubmissionFilter[0].value
  );
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<{
    [key: string]: boolean;
  }>({});
  const [openSubmissionForm, setOpenSubmissionForm] = useState(false);
  const [formSubmissionsInfo, setFormSubmissionsInfo] = useState(
    formSubmissionsInitialData
  );
  const [openApprovePopup, setOpenApprovePopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [openDownloadPopup, setOpenDownloadPopup] = useState<boolean>(false);
  const [openDownloadWarningPopup, setOpenDownloadWarningPopup] =
    useState<boolean>(false);
  const [openApproveErrorPopup, setOpenApproveErrorPopup] =
    useState<boolean>(false);
  const [isMarkAsRead, setIsMarkAsRead] = useState<boolean>(false);
  const [
    selectedSubmissionLinkToDownload,
    setSelectedSubmissionLinkToDownload,
  ] = useState<string[]>([]);
  const [selectedSubmissionIdToApprove, setSelectedSubmissionIdToApprove] =
    useState<string>('');
  const [submissionDetail, setSubmissionDetail] = useState<any>();
  const [formVersion, setFormVersion] = useState<any>();
  const [nameSubmisson, setNameSubmission] = useState<any>();
  const [linkDownloadPDF, setLinkDownloadPDF] = useState<string>();
  const [displayEmailSupportModal, setDisplayEmailSupportModal] =
    useState<boolean>(false);
  const [openSingleDownloadPopup, setOpenSingleDownloadPopup] =
    useState<boolean>(false);
  const [statusSelectedSubmission, setStatusSelectedSubmission] =
    useState<string>('');

  const [openErrorExceedRetry, setOpenErrorExceedRetry] = useState<string>('');

  const [openWarningNoExistContact, setOpenWarningNoExistContact] =
    useState<IOptionPopup>({
      show: false,
      support: false,
    });

  const [openWarningNoExistPets, setOpenWarningNoExistPets] =
    useState<IOptionPopup>({
      show: false,
      support: false,
    });

  const [openExistMultiContactPopup, setOpenExistMultiContactPopup] =
    useState<boolean>(false);
  const [optionContacts, setOptionContacts] = useState<OptionContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [openWarningNoExistPet, setOpenWarningNoExistPet] =
    useState<boolean>(false);
  const [openExistMultiPetPopup, setOpenExistMultiPetPopup] =
    useState<boolean>(false);
  const [openUpdateOrContinuePopup, setOpenUpdateOrContinuePopup] =
    useState<boolean>(false);
  const [changedFields, setChangedFields] = useState<ChangedField[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>("");
  const [optionPets, setOptionPets] = useState<OptionPet[]>([]);
  const [selectedPet, setSelectedPet] = useState<string>('');
  const [openPopupRetry, setOpenPopupRetry] = useState(false);
  const [editSubmission, setEditSubmission] = useState({
    allowEdit: true,
    isEdit: false,
  });
  const [isCreatePet, setIsCreatePet] = useState(false);
  const [allowChange, setAllowChange] = useState(true);
  const [openModalDirty, setOpenModalDirty] = useState(false);
  const [openSubmittedDocumentsModal, setOpenSubmittedDocumentsModal] = useState(false);
  const [submissionId, setSubmissionId] = useState('');
  const [approvalProcessing, setApprovalProcessing] = useState(false);
  const [contactModalSearchText, setContactModalSearchText] = useState("");

  useEffect(() => {
    setSelectedContact(optionContacts[0]?.id ?? '');
  }, [optionContacts]);

  useEffect(() => {
    setSelectedPet(optionPets[0]?.id ?? '');
  }, [optionPets]);

  useEffect(() => {
    if(selectedPet != "")
      getChangedFields(false);
    setIsCreatePet(false);
  }, [selectedPet]);

  const getChangedFields = async (contactOnly: boolean) => {
    if(selectedContact == "" || selectedSubmissionIdToApprove == "") {
      setChangedFields([]);
      return false;
    }
    const res = await configuredRequests.GET.getChangedFields(
      selectedSubmissionIdToApprove,
      selectedContact,
      contactOnly ? "nopet" : selectedPet,
    );
    // @ts-ignore
    if (res) {
      // @ts-ignore
      const fields = res.fields.map(field => ({
        fieldName: field.fieldName,
        formValue: field.formValue,
        dbValue: field.dbValue,
        shouldUpdate: false,
      }));
      setChangedFields(fields);
      setSelectedPhoneNumber(fields.filter((f: ChangedField) => f.fieldName === "phonenumber").map((f: ChangedField) => f.dbValue.split(',')[0])[0]);
      return fields.length > 0;
    }
    return false;
  }

  const getListSubmissionData = useCallback(
    async (query: QueryGetListSubmissionType) => {
      setIsLoading(true);
      try {
        if (source) {
          source.cancel();
        }
        source = axios.CancelToken.source();
        const response = await configuredRequests.GET.getListSubmissionData(
          {
            limit: query?.limit ?? 10,
            offset: query?.offset ?? 0,
            withStatus: query?.withStatus ?? '',
            withName: query?.withName ?? '',
            formId: formInfoData.id ?? '',
          },
          source.token
        );
        if (response) setIsLoading(false);
        //@ts-ignore
        setFormSubmissionsInfo(response.data);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setIsLoading(false);
        }
      }
    },
    [formInfoData]
  );

  useEffect(() => {
    if (!!submissionNoti) {
      const submission = JSON.parse(submissionNoti);
      delete submission.formName;
      delete submission.formId;
      openDetailSubmission(submission);
      setSubmissionNoti('');
    }
  }, [submissionNoti]);

  useEffect(() => {
    setSelectedSubmissionId({});
    setQueryPagination({ limit: 10, offset: 0 });
    setSearchText('');
    setStatusSelectedFilterValue(optionDataSubmissionFilter[0]?.value);
    getListSubmissionData({
      limit: 10,
      offset: 0,
      withName: '',
      withStatus: optionDataSubmissionFilter[0].value,
    });
  }, [formInfoData.id]);

  useEffect(() => {
    if (Object.keys(selectedSubmissionId).length === 1) {
      const selectedSubmissionData = Object.keys(selectedSubmissionId)[0];
      const selectedSubmissionFormData = formSubmissionsInfo.items.filter(
        (item) => item.id === selectedSubmissionData
      );
      setIsMarkAsRead(!selectedSubmissionFormData[0].isRead);
      setStatusSelectedSubmission('');
    }
    if (Object.keys(selectedSubmissionId).length === 0) {
      setStatusSelectedSubmission('');
      return setSelectedSubmissionLinkToDownload([]);
    } else {
      const selectedSubmissionList = formSubmissionsInfo.items.filter(
        (item) => {
          return Object.keys(selectedSubmissionId).indexOf(item.id) >= 0;
        }
      );
      const isRead = selectedSubmissionList.find((item) => item.isRead);
      const isUnRead = selectedSubmissionList.find((item) => !item.isRead);
      setStatusSelectedSubmission(
        isRead ? (isUnRead ? 'both' : 'read') : 'unread'
      );
      const selectedSubmissionLinkDownload = selectedSubmissionList.map(
        (item) => item.link
      );
      return setSelectedSubmissionLinkToDownload(
        selectedSubmissionLinkDownload
      );
    }
  }, [selectedSubmissionId, formSubmissionsInfo]);

  const detectSelectedSubmission = useCallback(
    (formSubmissionsInfo: any) => {
      const listIdContactViaPage =
        formSubmissionsInfo?.items.map((item: any) => item.id) || [];
      // @ts-ignore
      const listIdSelected = Object.keys(selectedSubmissionId).map(
        (key: string) => selectedSubmissionId[key] && key
      );
      const listMatch = intersection(listIdContactViaPage, listIdSelected);
      const hasSubmissionSelected = listMatch.length > 0;
      if (hasSubmissionSelected) {
        setSelectedSubmissionId(selectedSubmissionId);
      }
    },
    [setSelectedSubmissionId, selectedSubmissionId]
  );

  const handleChangePage = useCallback(
    async (event: any, newPage: number) => {
      setQueryPagination((preState) => ({ ...preState, offset: newPage }));
      const submissionViaPage = await getListSubmissionData({
        ...queryPagination,
        offset: newPage,
        withName: searchText,
        withStatus: statusSelectedFilterValue,
      });
      detectSelectedSubmission(submissionViaPage);
    },
    [
      getListSubmissionData,
      queryPagination,
      statusSelectedFilterValue,
      searchText,
      detectSelectedSubmission,
    ]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination({ offset: 0, limit: newRowsPerPage });
        getListSubmissionData({
          limit: newRowsPerPage,
          offset: 0,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
      }
    },
    [getListSubmissionData, searchText, statusSelectedFilterValue]
  );

  const handleSubmitSuccess = useCallback(
    (url: string, object: any) => {
      setEditSubmission((prev) => ({ ...prev, isEdit: false }));
      setLinkDownloadPDF(url);
      getListSubmissionData({
        ...queryPagination,
        withName: searchText,
        withStatus: statusSelectedFilterValue,
      });
      setSubmissionDetail(object);
    },
    [queryPagination, searchText, statusSelectedFilterValue]
  );

  const resetSelectionToDefault = (
    event?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    event?.preventDefault();
    setSelectedSubmissionId({});
  };

  const handleSelectAllCurrentPageCheckboxClick = () => {
    const finalFilteredSubmissionCount = formSubmissionsInfo.items.length;
    if (!formSubmissionsInfo) {
      return;
    }
    // match filter and remove only matches
    if (
      formSubmissionsInfo.items.filter((item: any) =>
        selectedSubmissionId.hasOwnProperty(item.id)
      ).length === finalFilteredSubmissionCount
    ) {
      const newSelectedSubmissionIDs = { ...selectedSubmissionId };
      formSubmissionsInfo.items.forEach((data: any) => {
        delete newSelectedSubmissionIDs[data.id];
      });

      setSelectedSubmissionId(newSelectedSubmissionIDs);
      return;
    }
    const newSelectedSubmissionIDs = { ...selectedSubmissionId };
    formSubmissionsInfo.items.forEach((data: any) => {
      newSelectedSubmissionIDs[data.id] = true;
    });

    setSelectedSubmissionId(newSelectedSubmissionIDs);
  };

  const handleAddContactToSelected = (submissionSelectedData: any) => {
    setSelectedSubmissionId({
      ...selectedSubmissionId,
      [submissionSelectedData.id]: true,
    });
  };

  const handleRemoveContactFromSelected = (submissionSelectedData: any) => {
    const newSelectededObject = {
      ...selectedSubmissionId,
    };
    delete newSelectededObject[submissionSelectedData.id];
    setSelectedSubmissionId(newSelectededObject);
  };

  const handleMarkAsRead = async (isMarkAsRead: boolean) => {
    try {
      const response = await configuredRequests.POST.markSubmissionAsRead({
        isRead: isMarkAsRead,
        selectedSubmissionId: Object.keys(selectedSubmissionId),
      });
      if (response) {
        setSelectedSubmissionId({});
        reloadData();
        getTotalUnread();
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setSelectedSubmissionId({});
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const getTotalUnread = async () => {
    try {
      const tenantId = getSessionTenantInfo()?.tenantId ?? '';
      if (tenantId) {
        const res = await configuredRequests.GET.getCountUnreadSubmissions(
          tenantId
        );
        // @ts-ignore
        const totalUnread = res?.data?.totalUnreads ?? 0;
        if (totalUnread) {
          document
            .getElementsByClassName('form-status')[0]
            .classList.add('status--count');
        } else {
          document
            .getElementsByClassName('form-status')[0]
            .classList.remove('status--count');
        }
        document.getElementsByClassName('form-status')[0].innerHTML =
          totalUnread.toString();
        window.sessionStorage.setItem(
          'countUnreadSubmissions',
          totalUnread.toString()
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDownloadSubmission = () => {
    if (Object.keys(selectedSubmissionId).length > 10) {
      setOpenDownloadWarningPopup(true);
    } else {
      setOpenDownloadPopup(true);
    }
  };

  const handleDownloadSelectedSubmission = () => {
    selectedSubmissionLinkToDownload.map((item) => window.open(item));
    setSelectedSubmissionId({});
    setOpenDownloadPopup(false);
  };

  const handleDeleteSubmission = () => {
    setOpenDeletePopup(true);
  };

  const handleConfirmDeleted = async () => {
    try {
      const res = await configuredRequests.POST.deleteSubmissionForm(
        Object.keys(selectedSubmissionId)
      );
      if (res) {
        setSelectedSubmissionId({});
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        reloadData();
        getTotalUnread();
        setOpenDeletePopup(false);
        toast.success('Deleted successfully!');
      }
    } catch (err) {
      setSelectedSubmissionId({});
      setOpenDeletePopup(false);
      toast.error('Failed to delete.');
    }
  };

  const handleApproveSubmission = useCallback((submission: Submission) => {
    const arrFieldSubmit = JSON.parse(submission.submissionsDetail);
    const requireFields = arrFieldSubmit.filter(
      (field: any) =>
        (field.table_name === 'Users' && field.column_name === 'LastName') ||
        (field.table_name === 'Pets' && field.column_name === 'Name')
    );
    if (
      !submission?.email ||
      requireFields.length < 2 ||
      !requireFields?.[0].value ||
      !requireFields?.[1].value
    ) {
      setOpenApproveErrorPopup(true);
    } else {
      setOpenApprovePopup(true);
      setSelectedSubmissionIdToApprove(submission.id);
    }
  }, []);

  const handleRetrySubmission = () => {
    setOpenPopupRetry(false);
    handleApproveSelectedSubmission(selectedSubmissionIdToApprove);
  };

  const handleClosePopupRetry = () => {
    setSelectedSubmissionIdToApprove('');
    setOpenPopupRetry(false);
  };

  const handleRetryApproveSelectedSubmission = async (submissionId: string) => {
    setSelectedSubmissionIdToApprove(submissionId);
    setOpenPopupRetry(true);
    setOptionContacts([]);
  };

  const handleApproveSelectedSubmission = async (submissionId: string) => {
    setSelectedSubmissionIdToApprove(submissionId);
    const payload = { selectedIdToApprove: [submissionId] };
    setOptionContacts([]);
    setContactModalSearchText("");
    try {
      const res = await configuredRequests.POST.approveSubmissionForm(payload);
      setSelectedSubmissionId({});
      // setOpenApprovePopup(false);
      // @ts-ignore
      if (res[0]?.result?.includes('AllContact')) {
        // @ts-ignore
        const options = res[0].users.map((user: any) => {
          const lastName = user.name?.lastName ?? '';
          const firstName = user.name?.givenName ?? '';
          const emailAddress = user.emailAddress ?? '';
          const phoneNumber =
            (
              user?.phoneNumbers?.find((phone: any) => phone.isPrimary) ??
              user?.phoneNumbers?.[0]
            )?.number ?? '';
          const addressPrimary =
            user?.addresses?.find((address: any) => address.isPrimary) ??
            user?.addresses?.[0];
          let address = '';
          if (addressPrimary) {
            const {
              addressLine1,
              city,
              country,
              postalCode,
              state,
              addressLine2,
            } = addressPrimary.address;
            const addressSegments = [
              addressLine1 ?? '',
              addressLine2 ?? '',
              city ?? '',
              state ?? '',
              postalCode ?? '',
              country ?? '',
            ];
            address = addressSegments.filter((e) => e.length)?.join(', ');
          }
          // @ts-ignore
          const pets = res[0]?.pets
            ?.filter((item: any) => item.guardians.includes(user.userId))
            ?.map((pet: any) => pet?.name)
            .join(', ');

          return {
            id: user.userId,
            lastName,
            firstName,
            emailAddress,
            phoneNumber,
            address,
            pets,
          };
        });
        setOptionContacts(options);
        setOpenExistMultiContactPopup(true);
        // @ts-ignore
        if (res[0]?.result === 'AllContactNoChange') setAllowChange(false);
      } else if (
        // @ts-ignore
        res[0]?.result ===
        'The contact does not exist in the system yet, please create this contact in PMS before you approve the form.'
      ) {
        setOpenWarningNoExistContact({ show: true, support: false });
        setSelectedSubmissionIdToApprove('');
        // @ts-ignore
      } else if (res[0]?.result === 'The retries has exceeded {0} time(s).') {
        // @ts-ignore
        setOpenErrorExceedRetry(res[0]?.message);
        setSelectedSubmissionIdToApprove('');
        // @ts-ignore
      } else if (res[0]?.result === 'ConfirmApproveContact') {
        setOpenWarningNoExistContact({ show: true, support: true });
        // @ts-ignore
      } else if (res[0]?.result === 'Success') {
        setSelectedSubmissionIdToApprove('');
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        toast.success('Your data has been saved successfully!');
      } else {
        setOpenApproveErrorPopup(true);
        setSelectedSubmissionIdToApprove('');
      }
    } catch (err) {
      setSelectedSubmissionId({});
      // setOpenApprovePopup(false);
      setSelectedSubmissionIdToApprove('');
      if (
        err.response?.data?.message ===
        'The syncer is turned off, you cannot approve this submission.'
      ) {
        toast.error(
          'The syncer is turned off, you cannot approve this submission.'
        );
      } else {
        toast.error('Your data has not been saved yet. Please try again.');
      }
    }
  };

  const handleSendEmailSupport = () => {
    setDisplayEmailSupportModal(true);
  };

  const handleSelectStatusFilter = useCallback(
    (statusSelected: string) => {
      setStatusSelectedFilterValue(statusSelected);
      setQueryPagination((preState) => ({ limit: preState.limit, offset: 0 }));
      setSelectedSubmissionId({});
      getListSubmissionData({
        limit: queryPagination.limit,
        offset: 0,
        withStatus: statusSelected,
        withName: searchText,
      });
    },
    [getListSubmissionData, searchText, queryPagination]
  );

  const searchSubmissionWithName = useCallback(
    debounce((name: string) => {
      getListSubmissionData({
        limit: 10,
        offset: 0,
        withName: name.trim(),
        withStatus: statusSelectedFilterValue,
      });
    }, 1000),
    [getListSubmissionData, statusSelectedFilterValue]
  );

  const handleSearchSubmission = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const textSearch = event.currentTarget.value;
    setSearchText(textSearch);
    setQueryPagination({ limit: 10, offset: 0 });
    setSelectedSubmissionId({});
    searchSubmissionWithName(textSearch);
  };

  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const isDirty = window.sessionStorage.getItem('isDirty') === 'yes';
    if (isDirty) {
      setOpenModalDirty(true);
    } else {
      setEditSubmission((prev) => ({ ...prev, isEdit: false }));
      setOpenSubmissionForm(false);
    }
    reloadData();
  };

  const handleCloseSubmission = () => {
    window.sessionStorage.setItem('isDirty', 'no');
    setEditSubmission((prev) => ({ ...prev, isEdit: false }));
    setOpenModalDirty(false);
    setOpenSubmissionForm(false);
  };

  const handleMarkAsReadSubmission = async (data: any) => {
    try {
      const res = await configuredRequests.POST.markSubmissionAsRead({
        isRead: true,
        selectedSubmissionId: [data.id],
      });
      if (res) {
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        getTotalUnread();
      }
    } catch (err) {}
  };

  const openDetailSubmission = (data: any) => {
    if (!data.isRead) handleMarkAsReadSubmission(data);
    setSubmissionDetail(JSON.parse(data.submissionsDetail));
    setFormVersion({ object: JSON.parse(data.JsonStructure) });
    setSubmissionId(data.id);
    setLinkDownloadPDF(data.link);
    setNameSubmission(data.name);
    setOpenSubmissionForm(true);
    setEditSubmission((prev) => ({ ...prev, allowEdit: true }));
  };

  const handleDownloadSingleSubmission = () => {
    window.open(linkDownloadPDF);
    setOpenSingleDownloadPopup(false);
  };

  const renderSelectAllCurrentPageCheckbox = () => {
    if (formSubmissionsInfo.items.length === 0) {
      return <CustomCheckbox checked={false} clickHandler={() => {}} />;
    }
    const listIdSubmissionViaPage =
      formSubmissionsInfo?.items.map((item: any) => item.id) || [];
    // @ts-ignore
    const listIdSelected = Object.keys(selectedSubmissionId).map(
      (key: string) => selectedSubmissionId[key] && key
    );
    const listMatch = intersection(listIdSubmissionViaPage, listIdSelected);
    const isListSelected = listMatch.length >= formSubmissionsInfo.items.length;
    return (
      <CustomCheckbox
        checked={isListSelected}
        clickHandler={handleSelectAllCurrentPageCheckboxClick}
      />
    );
  };

  const renderTableHeader = () => {
    const headerTable = formSubmissionsInfo.allowApprove
      ? headerTableWithApproving
      : headerTableWithoutApproving;
    return (
      <tr className='contacts--table-row-container contacts--table-header-row'>
        {headerTable.map((headerText, index) => {
          return (
            <td
              key={headerText + index}
              className={
                'contacts--table-row-division ' +
                (headerText.length
                  ? headerText.toLocaleLowerCase()
                  : 'checkbox')
              }
            >
              {headerText.length
                ? headerText
                : renderSelectAllCurrentPageCheckbox()}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderSelectedActionsRow = () => {
    const renderCurrentSelectionText = () => {
      const numberOfSelectedSubmission =
        Object.keys(selectedSubmissionId).length;
      const totalAvailable = formSubmissionsInfo?.totalAvailable ?? 0;
      if (numberOfSelectedSubmission === totalAvailable) {
        return [
          <div
            className='contacts--selected-select-all-text'
            key='contact-number-selected-container'
          >
            {`All ${numberOfSelectedSubmission} submission${
              numberOfSelectedSubmission !== 1
                ? 's on this page are'
                : ' on this page is'
            } selected.`}
          </div>,
          renderClearSelectionButton(),
        ];
      }
      return [
        <div
          className='contacts--selected-user-selection-text'
          key='contacts--selected-user-selection-text'
        >
          {`${numberOfSelectedSubmission} submission${
            numberOfSelectedSubmission !== 1
              ? 's on this page are'
              : ' on this page is'
          } selected.`}
        </div>,
        renderClearSelectionButton(),
      ];
    };

    return (
      <tr className={'contacts--selected-container'}>
        <td className={'contacts--selected-actions'}>
          {renderSelectAllCurrentPageCheckbox()}
          <div className='contacts--selected-count'>
            {Object.keys(selectedSubmissionId).length} selected
          </div>
        </td>

        <td className={'contacts--selected-select-all-container'}>
          {renderCurrentSelectionText()}
        </td>
      </tr>
    );
  };

  const renderSubmissionTableRows = () => {
    if (!formSubmissionsInfo?.items.length) {
      return (
        <tr
          key='all-submission-table-row--no-users'
          className='contacts--table-row-container no-users'
        >
          <td>No submission available.</td>
        </tr>
      );
    }

    return formSubmissionsInfo.items.map((data: any, index) => {
      return (
        <tr
          className='contacts--table-row-container'
          key={`${data.id}${index}`}
        >
          <td className='contacts--table-row-division checkbox'>
            <CustomCheckbox
              checked={selectedSubmissionId.hasOwnProperty(data.id)}
              clickHandler={(event: React.MouseEvent<CustomCheckboxDiv>) => {
                const customCheckedValue =
                  event.currentTarget.getAttribute('data-checked');
                const userWasSelected = customCheckedValue === 'false';
                if (!customCheckedValue?.length) {
                  return;
                }
                if (userWasSelected) {
                  handleAddContactToSelected(data);
                  return;
                }
                if (!userWasSelected) {
                  handleRemoveContactFromSelected(data);
                  return;
                }
              }}
            />
          </td>

          <td className='contacts--table-row-division contact'>
            <span
              className={
                data.isRead
                  ? 'all-submission-table-text-is-read'
                  : 'all-submission-table-text-is-not-read'
              }
            >
              {data.submissionDate}
            </span>
          </td>

          <td className='contacts--table-row-division pet'>
            <span
              className={
                data.isRead
                  ? data.name
                    ? 'all-submission-table-name all-submission-table-text-is-read'
                    : 'all-submission-table-name all-submission-table-text-is-read missing-email-address'
                  : data.name
                  ? 'all-submission-table-name all-submission-table-text-is-not-read'
                  : 'all-submission-table-name all-submission-table-text-is-not-read missing-email-address'
              }
              onClick={() => openDetailSubmission(data)}
            >
              <Tooltip
                title={data.name}
                placement='bottom-end'
                classes={{ tooltip: classes.tooltip }}
                disableHoverListener={data.name.length < 18}
              >
                <div className='all-submission-table-text-tooltip'>
                  <HighlightText
                    text={data.name ? data.name : 'Missing Name'}
                    searchValue={searchText.trim()}
                  />
                </div>
              </Tooltip>
            </span>
          </td>

          <td className='contacts--table-row-division email'>
            <span
              className={
                data.isRead
                  ? data.email
                    ? 'all-submission-table-text-is-read'
                    : 'all-submission-table-text-is-read missing-email-address'
                  : data.email
                  ? 'all-submission-table-text-is-not-read'
                  : 'all-submission-table-text-is-not-read missing-email-address'
              }
            >
              <Tooltip
                title={data.email}
                placement='bottom-end'
                classes={{ tooltip: classes.tooltip }}
                disableHoverListener={data.email.length < 18}
              >
                <div className='all-submission-table-text-tooltip'>
                  {data.email ? data.email : 'Missing Email Address'}
                </div>
              </Tooltip>
            </span>
          </td>

          {formSubmissionsInfo.allowApprove &&
            (!data.isApproved ? (
              <td className='contacts--table-row-division approval'>
                <button
                  key={index}
                  className='approve-all-submission-table-button'
                  onClick={() => {
                    handleApproveSelectedSubmission(data.id);
                  }}
                >
                  Approve
                </button>
              </td>
            ) : (
              <td className='contacts--table-row-division approve-all-submission-table-icon'>
                <button
                  className='approve-all-submission-table-button btn-retry-approval'
                  onClick={() => handleRetryApproveSelectedSubmission(data.id)}
                >
                  <CheckCircleIcon className='approve-submission-icon' />
                  Approved
                </button>
              </td>
            ))}
        </tr>
      );
    });
  };

  const renderClearSelectionButton = () => {
    return (
      <button
        key='contact-clear-selection-button'
        className='contacts--selected-select-all-button'
        onClick={resetSelectionToDefault}
      >
        Clear selections
      </button>
    );
  };

  const handleConfirmNoExistContact = async () => {
    if (openWarningNoExistContact.support) {
      try {
        let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": "", "petId": "", changedInfo: [] };
        setApprovalProcessing(true);
        const res = await configuredRequests.POST.approveFormSubmission(payload);
        setApprovalProcessing(false);
        // @ts-ignore
        if (res[0].result === 'Success') {
          setOpenWarningNoExistContact({ show: false, support: false });
          setSelectedSubmissionIdToApprove('');
          getListSubmissionData({
            limit: queryPagination.limit,
            offset: queryPagination.offset,
            withName: searchText,
            withStatus: statusSelectedFilterValue,
          });
          toast.success('Your data has been saved successfully!');
        }
      } catch (err) {
        setOpenWarningNoExistContact({ show: false, support: false });
        setSelectedSubmissionIdToApprove('');
        toast.error('Your data has not been saved yet. Please try again.');
        console.log('err', err);
        setApprovalProcessing(false);
      }
    } else {
      setOpenWarningNoExistContact({ show: false, support: false });
      setSelectedSubmissionIdToApprove('');
    }
  };

  const handleConfirmNoExistPets = async () => {
    if (openWarningNoExistPets.support) {
      try {
        let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": selectedContact, "petId": "", changedInfo: [] };
        setApprovalProcessing(true);
        const res = await configuredRequests.POST.approveFormSubmission(payload);
        setApprovalProcessing(false);
        // @ts-ignore
        if (res[0].result === 'Success') {
          setOpenWarningNoExistPets({ show: false, support: false });
          setSelectedSubmissionIdToApprove('');
          setSelectedPet("");
          setSelectedContact("");
          getListSubmissionData({
            limit: queryPagination.limit,
            offset: queryPagination.offset,
            withName: searchText,
            withStatus: statusSelectedFilterValue,
          });
          toast.success('Your data has been saved successfully!');
        }
      } catch (err) {
        setOpenWarningNoExistPets({ show: false, support: false });
        setSelectedSubmissionIdToApprove('');
        toast.error('Your data has not been saved yet. Please try again.');
        console.log('err', err);
        setApprovalProcessing(false);
      }
    } else {
      setOpenWarningNoExistPets({ show: false, support: false });
      setSelectedSubmissionIdToApprove('');
    }
  };

  const handleContinueWithNew = async (isContact: boolean) => {
    try {
      if (!isContact && await getChangedFields(true)) {
        setOpenExistMultiPetPopup(false);
        setOpenUpdateOrContinuePopup(true);
        setIsCreatePet(true);
        setSelectedPet("");
      } else {
        let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": isContact ? "" : selectedContact, "petId": "", changedInfo: []};
        setApprovalProcessing(true);
        const res = await configuredRequests.POST.approveFormSubmission(payload);
        setApprovalProcessing(false);
        // @ts-ignore
        if (res[0].result === 'Success') {
          handleCloseModalSelectContact(isContact);
          setSelectedPet("");
          setSelectedContact("");
          getListSubmissionData({
            limit: queryPagination.limit,
            offset: queryPagination.offset,
            withName: searchText,
            withStatus: statusSelectedFilterValue,
          });
          toast.success('Your data has been saved successfully!');
        }
      }
    } catch (err) {
      handleCloseModalSelectContact(isContact);
      toast.error('Your data has not been saved yet. Please try again.');
      console.log('err', err);
      setApprovalProcessing(false);
    }
  };

  const handleContinueWithSelected = async (isContact: boolean) => {
    try {
      if (isContact) {
        setSelectedPet("");
        const res = await configuredRequests.GET.getListPets(
          selectedSubmissionIdToApprove,
          selectedContact || ''
        );
        setOpenExistMultiContactPopup(false);
        setOpenWarningNoExistContact({ show: false, support: false })
        // @ts-ignore
        if (res.result === 'Success') {
          setSelectedContact(optionContacts[0]?.id ?? '');
          setSelectedSubmissionIdToApprove('');
          getListSubmissionData({
            limit: queryPagination.limit,
            offset: queryPagination.offset,
            withName: searchText,
            withStatus: statusSelectedFilterValue,
          });
          toast.success('Your data has been saved successfully!');
          // @ts-ignore
        } else if (res.result === 'ConfirmApprovePet') {
          setSelectedContact(optionContacts[0]?.id ?? '');
          setSelectedSubmissionIdToApprove('');
          getListSubmissionData({
            limit: queryPagination.limit,
            offset: queryPagination.offset,
            withName: searchText,
            withStatus: statusSelectedFilterValue,
          });
          toast.success('Your data has been saved successfully!');
          // @ts-ignore
        } else if (res.result === 'AllPets') {
          // @ts-ignore
          const options = res.pets.filter((pet)=>pet.status.toLowerCase()==="active").map((pet) => ({
            id: pet.petId,
            petName: pet.name,
            sex: pet?.sex ?? '',
            specie: pet?.species ?? '',
            breed: pet?.breed ?? '',
          }));
          if(options.length == 0) {
            setOpenWarningNoExistPets({ show: true, support: true });
          } else {
            setOptionPets(options);
            setOpenExistMultiPetPopup(true);
          }
        } else {
          setSelectedSubmissionIdToApprove('');
          setSelectedContact(optionContacts[0]?.id ?? '');
          setOpenWarningNoExistPet(true);
        }
      } else {
        if (changedFields.length != 0 && allowChange) {
          setOpenExistMultiPetPopup(false);
          setOpenUpdateOrContinuePopup(true);
        }
        else {
          let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": selectedContact, "petId": selectedPet, changedInfo: [] };
          setApprovalProcessing(true);
          const res = await configuredRequests.POST.approveFormSubmission(payload);
          setApprovalProcessing(false);
          // @ts-ignore
          if (res[0].result === 'Success') {
            setOpenExistMultiPetPopup(false);
            setSelectedSubmissionIdToApprove('');
            setSelectedContact('');
            setSelectedPet('');
            getListSubmissionData({
              limit: queryPagination.limit,
              offset: queryPagination.offset,
              withName: searchText,
              withStatus: statusSelectedFilterValue,
            });
            toast.success('Your data has been saved successfully!');
          }
        }
      }
    } catch (err) {
      setSelectedSubmissionIdToApprove('');
      setSelectedContact(optionContacts[0]?.id ?? '');
      if (isContact) {
        setOpenExistMultiContactPopup(false);
      } else {
        setOpenExistMultiPetPopup(false);
        setSelectedPet(optionPets[0]?.id ?? '');
        toast.error('Your data has not been saved yet. Please try again.');
      }
      console.log('err', err);
      setApprovalProcessing(false);
    }
  };

  const handleContinueWithoutUpdating = async () => {
    try {
      let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": selectedContact, "petId": isCreatePet ? "" : selectedPet, changedInfo: [] };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveFormSubmission(payload);
      setApprovalProcessing(false);
      // @ts-ignore
      if (res[0].result === 'Success') {
        setOpenExistMultiPetPopup(false);
        setOpenUpdateOrContinuePopup(false)
        setSelectedSubmissionIdToApprove('');
        setChangedFields([]);
        setSelectedContact(optionContacts[0]?.id ?? '');
        setSelectedPet(optionPets[0]?.id ?? '');
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setSelectedSubmissionIdToApprove('');
      setSelectedContact(optionContacts[0]?.id ?? '');
      setOpenExistMultiContactPopup(false);
      setChangedFields([]);
      setOpenUpdateOrContinuePopup(false)
      setOpenExistMultiPetPopup(false);
      setSelectedPet(optionPets[0]?.id ?? '');
      toast.error('Your data has not been saved yet. Please try again.');
      console.log('err', err);
      setApprovalProcessing(false);
    }
  };

  const handleContinueWithUpdating = async () => {
    try {
      let payload = { "submissionId": selectedSubmissionIdToApprove, "userId": selectedContact, "petId": isCreatePet ? "" : selectedPet, changedInfo: changedFields.filter((cf: ChangedField) => cf.shouldUpdate).map((cf: ChangedField) => { return { "fieldName": cf.fieldName, "additionalInfo": cf.fieldName === "phonenumber" ? selectedPhoneNumber : "" } }) };
      setApprovalProcessing(true);
      const res = await configuredRequests.POST.approveFormSubmission(payload);
      setApprovalProcessing(false);
      // @ts-ignore
      if (res[0].result === 'Success') {
        setOpenExistMultiPetPopup(false);
        setChangedFields([]);
        setSelectedSubmissionIdToApprove('');
        setSelectedContact(optionContacts[0]?.id ?? '');
        setSelectedPet(optionPets[0]?.id ?? '');
        setOpenUpdateOrContinuePopup(false)
        getListSubmissionData({
          limit: queryPagination.limit,
          offset: queryPagination.offset,
          withName: searchText,
          withStatus: statusSelectedFilterValue,
        });
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setSelectedSubmissionIdToApprove('');
      setSelectedContact(optionContacts[0]?.id ?? '');
      setOpenExistMultiContactPopup(false);
      setOpenExistMultiPetPopup(false);
      setChangedFields([]);
      setOpenUpdateOrContinuePopup(false)
      setSelectedPet(optionPets[0]?.id ?? '');
      toast.error('Your data has not been saved yet. Please try again.');
      console.log('err', err);
      setApprovalProcessing(false);
    }
  };

  const handleCloseModalSelectContact = (isContact: boolean) => {
    setSelectedContact(optionContacts[0]?.id ?? '');
    if (isContact) {
      setOpenExistMultiContactPopup(false);
    } else {
      setOpenExistMultiPetPopup(false);
      setSelectedPet(optionPets[0]?.id ?? '');
    }
    setSelectedSubmissionIdToApprove('');
  };

  const renderDataTableContact = (
    contact: OptionContact,
    field: string
  ): string => {
    switch (field) {
      case 'firstName':
        return contact.firstName;
      case 'lastName':
        return contact.lastName;
      case 'phoneNumber':
        return contact.phoneNumber;
      case 'email':
        return contact.emailAddress === "NoEmail@thevethero.com" ? "" : contact.emailAddress;
      case 'address':
        return contact.address;
      case 'pets':
        return contact.pets;
      default:
        return '';
    }
  };

  const renderDataTablePet = (pet: OptionPet, field: string): string => {
    switch (field) {
      case 'name':
        return pet.petName;
      case 'sex':
        return pet.sex;
      case 'specie':
        return pet.specie;
      case 'breed':
        return pet.breed;
      default:
        return '';
    }
  };
  
  const renderDataTableChangedFields = (changedField: ChangedField, field: string): React.ReactElement => {
    switch (field) {
      case 'name':
        return <span style={{ "fontWeight": "bold" }}>{mapChangedFieldsName(changedField.fieldName)}</span>;
      case 'formValue':
        if(changedField.fieldName === "phonenumber") 
          return <span>{changedField.formValue ? convertPhoneNumber(changedField.formValue) : ""}</span>
        else 
          return <span>{changedField.formValue}</span>;
      case 'systemValue':
        if(changedField.fieldName === "phonenumber") {
          let phoneNumbers: string[] = changedField.dbValue.split(',');
          if(phoneNumbers.length > 0 && changedField.dbValue != "") {
            let phoneNumberOptions: DropdownOptionType[] = phoneNumbers.map((num: string) => {return {label: convertPhoneNumber(num), value: num}})
            phoneNumberOptions.push({label: "Create New Phone Number", value: "createNew"});
            return (
              <TextChevronDropdown
                styleOptions={{
                  width: 210,
                }}
                className='contacts--filter--select-container background-darkgrey height-40'
                //@ts-ignore
                currentSelection={
                  phoneNumberOptions.find(
                    (status) => status.value === selectedPhoneNumber
                  )?.label
                }
                handleChangeSelection={(newVal: string) => setSelectedPhoneNumber(newVal)}
                selectionOptions={phoneNumberOptions}
                useBlackText={true}
              />)
          }
          else return <span></span>
        }
        else {
          return <span>{changedField.dbValue}</span>;
        }
      case 'shouldUpdate':
        return <CustomCheckbox checked={changedField.shouldUpdate} clickHandler={() => updateChangedFieldCheckbox(changedField.fieldName)} />;
      default:
        return <span></span>;
    }
  };

  const updateChangedFieldCheckbox = (changedFieldName: string): void => {
    var newChangedFields: ChangedField[] = changedFields.map((ncf: ChangedField) => {
      if(ncf.fieldName == changedFieldName) ncf.shouldUpdate = !ncf.shouldUpdate;
      return ncf;
    });
    setChangedFields(newChangedFields);
  }

  const mapChangedFieldsName = (name: string): string => {
    switch (name) {
      case 'first_name':
        return "Contact First Name";
      case 'last_name':
        return "Contact Last Name";
      case 'email':
        return "Contact Email";
      case 'phonenumber':
        return "Phone Number";
      case 'address1':
        return "Address Line 1";
      case 'address2':
        return "Address Line 2";
      case 'city':
        return "City";
      case 'state':
        return "State/Province";
      case 'postcode':
        return "Zipcode";
      case 'pet_name':
        return "Pet Name";
      case 'pet_sex':
        return "Pet Sex";
      case 'pet_species':
        return "Pet Species";
      case 'pet_breed':
        return "Pet Breed";
      case 'pet_birthdate':
        return "Pet Birthdate";
      default:
        return '';
    }
  };

  const modalBodyMultiContact = (isContact: boolean): React.ReactElement => {
    return (
      <>
        <div className='model-body__content custom-scroll'>
          <p className='modal-body__text'>
            {`${
              isContact
                ? `We have found ${optionContacts.length} contact(s) that match the form information. Please select the correct contact from the following list and then click "Continue With Selected Contact", or select "Create New Contact & Pet" if the correct contact is not shown/does not exist in your PMS.`
                : `We have found ${optionPets.length} pet(s) for this contact. VetHero has automatically selected the closest match for you.`
            }`}
          </p>

          <Table
            columns={isContact ? columnsContact : columnsPet}
            tableBody={
              <>
                {isContact ? (
                  <>
                    {optionContacts.map((contact) => (
                      <TableRow
                        key={contact.id}
                        className={`${
                          contact.id === selectedContact ? 'active' : ''
                        }`}
                        onClick={() => setSelectedContact(contact.id)}
                      >
                        {columnsContact.map((column) => (
                          <TableCell key={column.key} style={styleCellBody}>
                            {renderDataTableContact(contact, column.key)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {optionPets.map((pet) => (
                      <TableRow
                        key={pet.id}
                        className={`${pet.id === selectedPet ? 'active' : ''}`}
                        onClick={() => setSelectedPet(pet.id)}
                      >
                        {columnsPet.map((column) => (
                          <TableCell key={column.key} style={styleCellBody} className={pet.petName == optionPets[0]?.petName ? "" : "form-pet-name"}>
                            {renderDataTablePet(pet, column.key)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            }
            isHasPagination={false}
          />
        </div>

        <div className='modal-footer'>
          <button
            className='btn btn-left'
            onClick={() => handleCloseModalSelectContact(isContact)}
          >
            Cancel
          </button>
          <div className='btn-group-right'>
            {allowChange ?
              <button
                className='btn new-contact-button'
                onClick={() => handleContinueWithNew(isContact)}
                disabled={approvalProcessing}
              >
                {`Create New ${isContact ? 'Contact & Pet' : 'Pet'}`}
              </button>
            : ""}
            
            <button
              className='btn btn-right'
              onClick={() => handleContinueWithSelected(isContact)}
              disabled={approvalProcessing}
            >
              {`${((changedFields.length > 0 && allowChange) || isContact) ? 'Continue With Selected' : 'Approve Form For'} ${isContact ? 'Contact' : 'Pet'}`}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modalBodyUpdateOrContinue = (): React.ReactElement => {
    return (
      <>
        <div className='model-body__content custom-scroll'>
          <p className='modal-body__text'>
            VetHero has detected that the information in the form does not match the information in your system. Please select which fields you'd like us to update
          </p>
          <Table
            columns={columnsChange}
            tableBody={
              <>
                  {changedFields.map((field: ChangedField) => (
                    <TableRow
                      key={field.fieldName}
                    >
                      {columnsChange.map((column) => (
                        <TableCell key={column.key} style={styleCellBody}>
                          {renderDataTableChangedFields(field, column.key)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
            }
            isHasPagination={false}
          />
        </div>

        <div className='modal-footer'>
          <button
            className='btn btn-left'
            onClick={() => setOpenUpdateOrContinuePopup(false)}
          >
            Cancel
          </button>
          <div className='btn-group-right'>
            <button
              className='btn new-contact-button'
              onClick={() => handleContinueWithoutUpdating()}
              disabled={approvalProcessing}
            >
              Approve Form Without Updating
            </button>
            <button
              className='btn btn-right'
              style={!changedFields.some((cf: ChangedField) => cf.shouldUpdate == true) ? { "backgroundColor": "white", "color": "grey", "border": "1px solid grey" } : {}}
              onClick={() => handleContinueWithUpdating()}
              disabled={!changedFields.some((cf: ChangedField) => cf.shouldUpdate == true) || approvalProcessing}
            >
              Update Selected Information
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleContactModalSearchChange = (e: any) => {
    let val = e.target.value;
    searchForContacts(val);
    setContactModalSearchText(val);
  };

  const getUserSearch = useCallback(async (query: any) => {
    try {
      const res = await configuredRequests.GET.allContactInfoByCurrentTenant(
        {
          summaryItems: ['phone', 'email', 'pets'],
          userKinds: ['Customer'],
          limit: query?.limit ?? 10,
          offset: query?.offset ?? 0,
          withStatus: query?.withStatus ?? '',
          withName: query?.withName ? encodeURIComponent(query?.withName) : '',
        },
        source.token
      );
      const options = res.users.map((user: any) => {
        const lastName = user.name?.split(" ")[1] ?? '';
        const firstName = user.name?.split(" ")[0] ?? '';
        const emailAddress = user.emailAddress ?? '';
        const phoneNumber =
          (
            user?.phoneNumbers?.find((phone: any) => phone.isPrimary) ??
            user?.phoneNumbers?.[0]
          )?.number ?? '';
        const addressPrimary =
          user?.addresses?.find((address: any) => address.isPrimary) ??
          user?.addresses?.[0];
        let address = '';
        if (addressPrimary) {
          const {
            addressLine1,
            city,
            country,
            postalCode,
            state,
            addressLine2,
          } = addressPrimary.address;
          const addressSegments = [
            addressLine1 ?? '',
            addressLine2 ?? '',
            city ?? '',
            state ?? '',
            postalCode ?? '',
            country ?? '',
          ];
          address = addressSegments.filter((e) => e.length)?.join(', ');
        }
        const pets = user?.pets
          ?.filter((item: any) => item.guardians.includes(user.id) && item.status === 'active')
          ?.map((pet: any) => pet?.name)
          .join(', ');
        return {
          id: user.id,
          lastName,
          firstName,
          emailAddress,
          phoneNumber,
          address,
          pets,
        };
      });
      setOptionContacts(options);
      return res;
    } catch (err) {
      if (!axios.isCancel(err)) {
        setIsLoading(false);
      }
    }
  }, []);

  const searchForContacts = useMemo(() => {
    return debounce((name: string) => {
      if(name.trim() === '')
      {
        return setOptionContacts([]);
      }
      let query = {
        offset: 0,
        withName: name.trim(),
        withStatus: 'Active',
        limit: 50,
      };
      getUserSearch(query);
    }, 1000);
  }, [getUserSearch]);

  const renderContactSearchModal = () => {
    let searchModalColumns = [
      { key: 'firstName', title: 'First Name' },
      { key: 'lastName', title: 'Last Name' },
      { key: 'phoneNumber', title: 'Phone Number' },
      { key: 'email', title: 'Email' },
      { key: 'pets', title: 'Pets' },
    ];
    return (<>
      <div className='model-body__content custom-scroll'>
        <p className='modal-body__text'>
          {openWarningNoExistContact.support
            ? 'VetHero cannot find any contacts with Last Name and Email Address in PMS. Do you want to continue approval and create a new contact and pet or select an existing contact?'
            : 'The contact does not exist in the system yet, please create this contact in PMS before you approve the form.'}
        </p>
        {openWarningNoExistContact.support ? <div className='contacts--filter-search-container'>
          <FontAwesomeIcon icon={['far', 'search']} />
          <input
            className='contacts--filter--search-input'
            type='search'
            value={contactModalSearchText}
            placeholder='Search by Contact or Email'
            onChange={handleContactModalSearchChange}
          />
        </div> : ""}
        {openWarningNoExistContact.support ? <Table
          columns={searchModalColumns}
          tableBody={
            <>
              {optionContacts.length > 0 ? (
                optionContacts.map((contact) => (
                  <TableRow
                    key={contact.id}
                    className={`${contact.id === selectedContact ? 'active' : ''
                      }`}
                    onClick={() => {
                      setSelectedContact(contact.id);
                    }}
                  >
                    {columnsContact.map((column) => (
                      <TableCell
                        key={column.key}
                        style={styleCellBody}
                      >
                        {renderDataTableContact(contact, column.key)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key='contacts--table-row--no-users'
                  className='contacts--table-row-container no-users'
                >
                  <TableCell>No contacts match the search criteria.</TableCell>
                </TableRow>
              )}
            </>
          }
          isHasPagination={false}
        /> : ""}
      </div>
      <div className='modal-footer'>
        <button
          className='btn btn-left'
          onClick={() => {
            setContactModalSearchText('');
            setOpenWarningNoExistContact({
              show: false,
              support: false,
            });
          }}
        >
          Cancel
        </button>
        <div className='btn-group-right'>
          {openWarningNoExistContact.support ? <button
            className='btn new-contact-button'
            onClick={handleConfirmNoExistContact}
          >
            Create New Contact & Pet
          </button> : ""}
          <button
            className='btn btn-right'
            onClick={() => {
              setContactModalSearchText('');
              handleContinueWithSelected(true);
            }}
            style={approvalProcessing || selectedContact == '' ? { "backgroundColor": "white", "color": "grey", "border": "1px solid grey" } : {}}
            disabled={approvalProcessing || selectedContact == ''}
          >
            Continue With Selected Contact
          </button>
        </div>
      </div>
    </>)
  }

  const renderAllSubmissionTable = () => {
    return (
      <div className='all-submission-table-container'>
        <div className='all-submission-table-component all-submission-table-paging'>
          <h3 className='form-all-submission-table-title'>
            {formInfoData.allowSync && (
              <SyncAltIcon className='all-submission-table-sync-icon' />
            )}
            {formInfoData.name}
          </h3>
          <div className='action-table-all-submission-table-form-builder'>
            <div className='action-button-all-submission-table-form-builder'>
              {Object.keys(selectedSubmissionId).length === 0 && (
                <button
                  disabled={Object.keys(selectedSubmissionId).length === 0}
                  onClick={() => {}}
                  className={
                    Object.keys(selectedSubmissionId).length > 0
                      ? 'action-all-submission-table-mark-as-read enable'
                      : 'action-all-submission-table-mark-as-read disable'
                  }
                >
                  <Create className='action-all-submission-table-mark-as-read-icon' />
                  <span className='action-all-submission-table-mark-as-read-label'>
                    Mark as
                  </span>
                </button>
              )}

              {((Object.keys(selectedSubmissionId).length === 1 &&
                isMarkAsRead) ||
                statusSelectedSubmission === 'unread' ||
                statusSelectedSubmission === 'both') && (
                <button
                  onClick={() => handleMarkAsRead(true)}
                  className={
                    Object.keys(selectedSubmissionId).length > 0
                      ? 'action-all-submission-table-mark-as-read enable'
                      : 'action-all-submission-table-mark-as-read disable'
                  }
                >
                  <Create className='action-all-submission-table-mark-as-read-icon' />
                  <span className='action-all-submission-table-mark-as-read-label'>
                    Mark as read
                  </span>
                </button>
              )}

              {((Object.keys(selectedSubmissionId).length === 1 &&
                !isMarkAsRead) ||
                statusSelectedSubmission === 'read' ||
                statusSelectedSubmission === 'both') && (
                <button
                  onClick={() => handleMarkAsRead(false)}
                  className={
                    Object.keys(selectedSubmissionId).length > 0
                      ? 'action-all-submission-table-mark-as-read enable'
                      : 'action-all-submission-table-mark-as-read disable'
                  }
                >
                  <Create className='action-all-submission-table-mark-as-read-icon' />
                  <span className='action-all-submission-table-mark-as-read-label'>
                    Mark as unread
                  </span>
                </button>
              )}

              <button
                disabled={Object.keys(selectedSubmissionId).length === 0}
                onClick={handleDownloadSubmission}
                className={
                  Object.keys(selectedSubmissionId).length > 0
                    ? 'action-all-submission-table-download enable'
                    : 'action-all-submission-table-download disable'
                }
              >
                <CloudDownload className='action-all-submission-table-download-icon' />
                <span className='action-all-submission-table-download-label'>
                  Download PDFs
                </span>
              </button>
              <button
                disabled={Object.keys(selectedSubmissionId).length === 0}
                onClick={handleDeleteSubmission}
                className={
                  Object.keys(selectedSubmissionId).length > 0
                    ? 'action-all-submission-table-delete enable'
                    : 'action-all-submission-table-delete disable'
                }
              >
                <Delete className='action-all-submission-table-delete-icon' />
                <span className='action-all-submission-table-delete-label'>
                  Delete
                </span>
              </button>
            </div>
            <div className='contacts--filter-container all-submission-table-search-and-filter'>
              <TextChevronDropdown
                styleOptions={{
                  width: 270,
                }}
                className='contacts--filter--select-container background-gray height-40'
                //@ts-ignore
                currentSelection={
                  optionDataSubmissionFilter.find(
                    (status) => status.value === statusSelectedFilterValue
                  )?.label
                }
                handleChangeSelection={handleSelectStatusFilter}
                selectionOptions={optionDataSubmissionFilter}
                useBlackText={true}
              />
              <div className='contacts--filter-search-container'>
                <FontAwesomeIcon
                  icon={['far', 'search']}
                  onClick={() => searchSubmissionWithName(searchText)}
                />

                <input
                  className='contacts--filter--search-input all-submission-search-input'
                  value={searchText}
                  type='search'
                  placeholder='Search by name'
                  onChange={handleSearchSubmission}
                />
              </div>
            </div>
          </div>

          {!isLoading ? (
            <>
              <span className='contacts--list-container'>
                <table
                  className={
                    formSubmissionsInfo.allowApprove
                      ? 'contact--list-table all-submission-table-body'
                      : 'contact--list-table all-submission-table-body-with-warning-banner'
                  }
                >
                  <tbody>
                    {Object.keys(selectedSubmissionId).length
                      ? renderSelectedActionsRow()
                      : renderTableHeader()}
                    {renderSubmissionTableRows()}
                  </tbody>
                </table>
              </span>

              <nav className='contacts--page-link-container all-submission-table-pagination'>
                <TablePagination
                  rowsPerPageOptions={[
                    10, 20, 30, 40, 50, 100, 200, 300, 400, 500,
                  ]}
                  count={formSubmissionsInfo?.totalAvailable ?? 10}
                  rowsPerPage={queryPagination?.limit ?? 10}
                  page={queryPagination?.offset ?? 0}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </nav>
            </>
          ) : (
            <Loading className='loading-submission-table-container' />
          )}

          {openSubmissionForm && (
            <ReactModal
              id='form-item-style'
              className='modal-user-settings react-modal modal-submission'
              isOpen={openSubmissionForm}
              onRequestClose={handleCloseModal}
            >
              <div className='react-modal--content'>
                <div className='react-modal--settings__title'>
                  <span className='title-text'>
                    Submission of "
                    <b className='name-submission'>
                      {nameSubmisson ? nameSubmisson : 'Unknown'}
                    </b>
                    "
                  </span>
                  <button
                    className='react-modal-close-icon'
                    onClick={handleCloseModal}
                  >
                    <FontAwesomeIcon icon={['far', 'times']} />
                  </button>
                </div>
                <div className='edit-appointment' id='formPrintPDF'>
                  <FormRenderer
                    submission={submissionDetail}
                    formVersion={formVersion}
                    fullData={{
                      formName: formInfoData.name,
                      versionId: formInfoData.id,
                    }}
                    submissionId={submissionId}
                    readOnly={!editSubmission.isEdit}
                    allowSubmit={editSubmission.isEdit}
                    handleSubmitSuccess={handleSubmitSuccess}
                  />
                </div>
                <div className='react-modal-settings-footer'>
                  <div>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setOpenSingleDownloadPopup(true);
                      }}
                    >
                      Download
                    </Button>
                    {editSubmission.allowEdit && !editSubmission.isEdit && (
                      <Button
                        variant='contained'
                        color='primary'
                        className='btn-edit-submission'
                        onClick={() => {
                          setEditSubmission((prev) => ({
                            ...prev,
                            isEdit: !prev.isEdit,
                          }));
                        }}
                      >
                        <span>Edit</span>
                      </Button>
                    )}
                    {formVersion?.object?.fields?.some((field: any) => field.type_name == 'Document Upload') && (
                      <Button
                        variant='contained'
                        color='primary'
                        className='btn-edit-submission'
                        onClick={() => {
                          setOpenSubmittedDocumentsModal(true);
                        }}
                      >
                        <span>View Submitted Documents</span>
                      </Button>
                    )}
                  </div>
                  <Button
                    variant='contained'
                    color='default'
                    className='cancel-button'
                    onClick={handleCloseModal}
                  >
                    <span>Cancel</span>
                  </Button>
                </div>
              </div>
            </ReactModal>
          )}

          {openErrorExceedRetry && (
            <ModalDialog
              isOpenModal={!!openErrorExceedRetry}
              title='Unable to retry approval'
              children={openErrorExceedRetry}
              handleCloseModal={() => setOpenErrorExceedRetry('')}
              isFullAction={false}
              handleConfirmModal={() => setOpenErrorExceedRetry('')}
            />
          )}

          {/* {openApprovePopup && (
            <WarningPopup
              openPopup={openApprovePopup}
              setOpenPopup={setOpenApprovePopup}
              title='Confirmation!'
              description='The information provided in the form will be sent to PMS. Do you still want to continue?'
              handleConfirmPopup={handleApproveSelectedSubmission}
            />
          )} */}

          {openDeletePopup && (
            <WarningPopup
              openPopup={openDeletePopup}
              setOpenPopup={setOpenDeletePopup}
              title='Delete Submission(s)'
              description={`Are you sure you want to delete ${
                Object.keys(selectedSubmissionId).length
              } form submission(s)?`}
              handleConfirmPopup={handleConfirmDeleted}
            />
          )}

          {openDownloadWarningPopup && (
            <WarningPopup
              openPopup={openDownloadWarningPopup}
              setOpenPopup={setOpenDownloadWarningPopup}
              title='Cannot Download'
              description='Sorry! You cannot download more than 10 PDFs at once. Please re-check.'
              isNotify={true}
            />
          )}

          {openDownloadPopup && (
            <WarningPopup
              openPopup={openDownloadPopup}
              setOpenPopup={setOpenDownloadPopup}
              title='Download PDF(s)'
              description={`You are about to download ${
                Object.keys(selectedSubmissionId).length === 1
                  ? 'a file that may contain'
                  : 'files containing'
              } sensitive patient information. Do you wish to continue?`}
              handleConfirmPopup={handleDownloadSelectedSubmission}
            />
          )}

          {openApproveErrorPopup && (
            <WarningPopup
              openPopup={openApproveErrorPopup}
              setOpenPopup={setOpenApproveErrorPopup}
              title='Unable to approve'
              description={`Please make sure you have Last Name and Pet Name fields on your form. We need these fields to sync contact's information into PMS.`}
              isNotify={true}
            />
          )}

          {openSingleDownloadPopup && (
            <WarningPopup
              openPopup={openSingleDownloadPopup}
              setOpenPopup={setOpenSingleDownloadPopup}
              title='Download PDF(s)'
              description='You are about to download a file that may contain sensitive patient information. Do you wish to continue?'
              handleConfirmPopup={handleDownloadSingleSubmission}
            />
          )}

          {openWarningNoExistContact.show && (
            <ModalDialog
              isOpenModal={openWarningNoExistContact.show}
              title='Contact Not Found'
              handleCloseModal={() =>
                setOpenWarningNoExistContact({ show: false, support: false })
              }
              isFullAction={openWarningNoExistContact.support ? true : false}
              isShowButtonModal={false}
              classMore='modal--select-table'
            >
              {renderContactSearchModal()}
            </ModalDialog>
          )}

          {openWarningNoExistPets.show && (
            <ModalDialog
              isOpenModal={openWarningNoExistPets.show}
              title='Pets Not Found'
              children={`${openWarningNoExistPets.support
                  ? 'VetHero cannot find any pets for this contact. Do you want to continue approval and create a new pet for this contact?'
                  : 'The contact does not exist in the system yet, please create this contact in PMS before you approve the form.'
                }`}
              handleCloseModal={() =>
                setOpenWarningNoExistPets({ show: false, support: false })
              }
              handleConfirmModal={handleConfirmNoExistPets}
              isFullAction={openWarningNoExistPets.support ? true : false}
              textLeftButton='Cancel'
              textRightButton={`${openWarningNoExistPets.support
                  ? 'Yes, Create New Pet For Contact'
                  : 'OK'
                }`}
            />
          )}

          {openExistMultiContactPopup && (
            <ModalDialog
              isOpenModal={openExistMultiContactPopup}
              title='Select Contact'
              handleCloseModal={() => setOpenExistMultiContactPopup(false)}
              isShowButtonModal={false}
              classMore='modal--select-table'
            >
              {modalBodyMultiContact(true)}
            </ModalDialog>
          )}

          {openWarningNoExistPet && (
            <WarningPopup
              openPopup={openWarningNoExistPet}
              setOpenPopup={setOpenWarningNoExistPet}
              title='Pet Not Found'
              description='The Pet does not exist in the system yet, please create the Pet for this contact in PMS before you approve the form.'
              isNotify={true}
            />
          )}

          {openExistMultiPetPopup && (
            <ModalDialog
              isOpenModal={openExistMultiPetPopup}
              title='Select Pet'
              handleCloseModal={() => setOpenExistMultiPetPopup(false)}
              isShowButtonModal={false}
              classMore='modal--select-table modal--select-pet'
            >
              {modalBodyMultiContact(false)}
            </ModalDialog>
          )}

          {openUpdateOrContinuePopup && (
            <ModalDialog
              isOpenModal={openUpdateOrContinuePopup}
              title='Update Contact Information'
              handleCloseModal={() => setOpenUpdateOrContinuePopup(false)}
              isShowButtonModal={false}
              classMore='modal--select-table modal--update-continue'
            >
              {modalBodyUpdateOrContinue()}
            </ModalDialog>
          )}

          {openPopupRetry && (
            <ModalDialog
              isOpenModal={openPopupRetry}
              handleCloseModal={handleClosePopupRetry}
              handleConfirmModal={handleRetrySubmission}
              title='Are you sure you would like to resubmit this form?'
              children='If you have not seen the first submission in your PMS or a new contact has not yet been created, please give Vet Hero a few minutes to do so. Resubmitting forms that have already written back into your PMS may cause errors.'
              classMore='modal-retry-submission'
              textLeftButton='Cancel'
              textRightButton='Yes, retry submission'
            />
          )}

          {openModalDirty && (
            <ModalDiscard
              isOpenModal={openModalDirty}
              handleCloseModal={() => setOpenModalDirty(false)}
              handleConfirmModal={handleCloseSubmission}
            />
          )}

          {openSubmittedDocumentsModal && (
            <SubmittedDocumentsModal
              isOpenModal={openSubmittedDocumentsModal}
              handleCloseModal={() => setOpenSubmittedDocumentsModal(false)}
              submissionId={submissionId}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='all-submission-data-table-container'>
      {!isLoading && !formSubmissionsInfo.allowApprove && (
        <div className='all-submission-table-syncer-notify'>
          <div className='all-submission-table-syncer-notify-infomation'>
            <FontAwesomeIcon
              className='all-submission-table-syncer-notify-infomation-icon'
              icon={['fas', 'exclamation-triangle']}
            />
            <div className='all-submission-table-syncer-notify-content-text'>
              <p className='all-submission-table-syncer-notify-content-pms'>
                In order to approve forms your syncer has to be running
              </p>
              &nbsp;
              <p>
                Please contact a support representative and make sure you're
                syncer is running.
              </p>
            </div>
          </div>
          <a href='mailto:support@thevethero.com'>
            <button className='all-submission-table-email-support-button'>
              EMAIL SUPPORT
            </button>
          </a>
        </div>
      )}

      {renderAllSubmissionTable()}

      {displayEmailSupportModal && (
        <EmailSupportSubmissionModal
          displayEmailSupportModal={displayEmailSupportModal}
          setDisplayEmailSupportModal={setDisplayEmailSupportModal}
        />
      )}
    </div>
  );
};

export default AllSubmissionsListTable;
