import React from "react";
import MIC_ON_ICON from '../../../images/micOn.svg';
import MIC_OFF_ICON from '../../../images/micOff.svg';
import { Tooltip } from "@material-ui/core";

interface AudioControlProps {
  audio: boolean,
  onSwitchAppointmentAudio: () => void
}

const AudioControl = (props: AudioControlProps) => {
  const onSwitchAudio = () => {
    props.onSwitchAppointmentAudio();
  }

  let audioButton = (props.audio) ? (
    <Tooltip title="Turn off audio">
    <img alt="" className="itemCenter" style={{cursor: "pointer"}} src={MIC_ON_ICON} onClick={onSwitchAudio} />
    </Tooltip>
  ) : (
    <Tooltip title="Turn on audio">
      <img alt="" className="itemCenter" style={{cursor: "pointer"}} src={MIC_OFF_ICON} onClick={onSwitchAudio} />
      </Tooltip>
    )

  return (audioButton)

}

export default AudioControl;