/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

const SmallAvatar = styled(Avatar)`
  width: 22px;
  height: 22px;
` as typeof Avatar;

export { SmallAvatar };
