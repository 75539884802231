import LoadingPs from 'components/Global/Loading';
import { AlertSnackBar } from 'components/Legwork';
import ShareLink from 'components/Settings/AppointmentSetting/Share/ShareLink';
import ShareRedirectLink from 'components/Settings/AppointmentSetting/Share/ShareRedirectLink';
import { getPatientPortalUrl } from 'global/constants/url';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React, { useEffect, useRef, useState } from 'react';

const Share = () => {
  const [copyToClipboardState, setCopyToClipboardState] = useState('');
  const [loading, setLoading] = useState(true);
  const [sharedLink, setSharedLink] = useState<string>('');
  const [formListLink, setFormListLink] = useState<string>('');

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      //@ts-ignore
      clearTimeout(timeoutRef.current);
    }
    //@ts-ignore
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      if (copyToClipboardState !== '') {
        setCopyToClipboardState('');
      }
    }, 1000);
  }, [copyToClipboardState]);

  useEffect(() => {
    getCurrentTenantInfo();
  }, []);

  const handleCopyLink = (link: string) => {
    try {
      navigator.clipboard.writeText(link);
      setCopyToClipboardState('success');
    } catch (err) {
      console.log('error ' + err);
      setCopyToClipboardState('error');
    }
  };

  const getCurrentTenantInfo = async () => {
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      setSharedLink(
        `https://${getPatientPortalUrl()}/${res.micrositeName}/login`
      );
      setFormListLink(
        `https://${getPatientPortalUrl()}/${res.micrositeName}/forms`
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {copyToClipboardState === 'success' && (
        <AlertSnackBar severity='success'>
          <span>Copied!</span>
        </AlertSnackBar>
      )}
      {copyToClipboardState === 'error' && (
        <AlertSnackBar severity='error'>
          <span>Failed</span>
        </AlertSnackBar>
      )}
      <div
        className='form-item-tab-container with-margin'
        style={{
          padding: `${loading ? '0 25px' : '30px 25px 0px 25px'}`,
        }}
      >
        {loading ? (
          <LoadingPs />
        ) : (
          <>
            <ShareLink
              shareLink={sharedLink}
              description='Send this link to a contact for them to login or register for the Client Portal.'
              onCopyLink={handleCopyLink}
            />
            <ShareLink
              shareLink={formListLink}
              description='Send this link to a contact to give them access to your full list of available forms.'
              onCopyLink={handleCopyLink}
              title={'Form List Link'}
            />
            <ShareRedirectLink
              onCopyLink={handleCopyLink}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Share;
