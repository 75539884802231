import React, { useEffect, useState } from 'react';
import TopPerformingTemplates from '../../components/Dashboard/TopPerformingTemplates';
import { configuredRequests } from "../../global/requests/ConfiguredRequests";
import loading_spinner from 'media/images/loading-spinner.svg';

interface ITopPerformingTemplate {
  deliverRate: number
  openRate: number
  sent: number
  templateName: string
  templateId: string
}

const TopPerformingTemplatesContainer = (): JSX.Element => {

  const [data, setData] = useState<ITopPerformingTemplate[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const sendGetTopPerformingTemplateRequest = async () => {
    try {
      const res = await configuredRequests.GET.getPerformingTemplate();
      setData(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    sendGetTopPerformingTemplateRequest()
  }, [])



  return (
    loading
      ? <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} />
      : (
        <>
          <TopPerformingTemplates data={data} />
        </>
      )
  );
}

export default TopPerformingTemplatesContainer;
