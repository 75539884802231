import React, { useState } from 'react';
import { Drawer, IconButton, Divider } from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import * as CONSTANTS from 'components/Legwork/app-constants';
import { OpenDrawerButton, DrawerHeader, LogoContainer } from './styled-components';

interface CollapsibleDrawerProps {
  anchor: DrawerProps['anchor'];
  children?: React.ReactElement;
  drawerStateChange: (open: boolean) => void;
  icon: () => React.ReactElement;
  openDrawerTitle: string;
  imageAlt: string;
  imageLocation: string;
  imageUrl: string;
}

const CollapsibleDrawer = (props: CollapsibleDrawerProps): React.ReactElement => {
  const { anchor, icon, children, imageUrl, openDrawerTitle, imageAlt } = props;
  const isDesktop = window.innerWidth > CONSTANTS.LARGE_SCREEN;

  const [open, setDrawerState] = useState(isDesktop);
  return (
    <div>
      <OpenDrawerButton
        open={open}
        aria-label={openDrawerTitle}
        title={openDrawerTitle}
        onClick={(): void => setDrawerState(true)}
      >
        {icon()}
      </OpenDrawerButton>
      <Drawer variant='persistent' anchor={anchor} open={open} data-testid='drawer menu'>
        <DrawerHeader>
          <LogoContainer>
            <a href={imageUrl}>
              <img src='images/legwork-2019-logo-01.svg' alt={imageAlt} />
            </a>
          </LogoContainer>
          <IconButton onClick={(): void => setDrawerState(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {children}
      </Drawer>
    </div>
  );
};

CollapsibleDrawer.defaultProps = {
  anchor: 'left',
  icon: (): React.ReactElement => <MenuIcon />,
  drawerStateChange: (): void => {},
  imageLocation: CONSTANTS.LEGWORK_LOGO,
  openDrawerTitle: 'Open Drawer Menu',
  imageAlt: 'Menu Logo',
};

export default CollapsibleDrawer;
