import React, { ReactElement, ChangeEvent, useState, useEffect } from 'react';
import Tab from '@material-ui/core/Tab';
import { StyledTabs, FlexDiv, MainDiv } from './styled-components';
import TabPanel from '../components/TabPanel';

interface IMenuItem {
  text: string | React.ReactElement;
  component: ReactElement;
  key?: string;
}

export interface VerticalTabMenuProps {
  menuItems: IMenuItem[];
  uniqueId: string;
  hideTabs?: boolean;
  initialTab?: number;
  PreTabElement?: React.ReactElement;
  PostTabElement?: React.ReactElement;
  filter?: string;
}

const a11yProps = (uniqueId: string, index: number): object => {
  return {
    id: `${uniqueId}-tab-${index}`,
    'aria-controls': `${uniqueId}-tabpanel-${index}`,
  };
};

const VerticalTabMenu = (props: VerticalTabMenuProps): ReactElement => {
  const {
    menuItems,
    uniqueId,
    hideTabs = false,
    initialTab = 0,
    PreTabElement,
    PostTabElement,
    filter
  } = props;
  const [value, setValue] = useState(initialTab);

  useEffect(() => {
    setValue(0);
  }, [menuItems?.length])

  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <FlexDiv>
      {!hideTabs && (
        <MainDiv>
          {PreTabElement}
          {(!!filter && !menuItems.length) ? <p className="error-msg">No results found</p> :
            <StyledTabs
              orientation='vertical'
              variant='standard'
              value={value}
              onChange={handleChange}
              aria-label={uniqueId}
            >
              {menuItems.map(
                (menuItem, index): ReactElement => (
                  <Tab
                    key={typeof menuItem.text === 'string' ? `${menuItem.text}}` : `${menuItem.key}}` || index + Math.random()}
                    label={menuItem.text}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...a11yProps(uniqueId, index)}
                  />
                ),
              )}
            </StyledTabs>}
          {PostTabElement}
        </MainDiv>
      )}
      {menuItems.map(
        (menuItem, index): ReactElement => (
          <TabPanel
            key={typeof menuItem.text === 'string' ? `${menuItem.text}}` : `${menuItem.key}}` || index + Math.random()}
            value={value}
            index={index}
          >
            {menuItem.component}
          </TabPanel>
        ),
      )}
    </FlexDiv>
  );
};

export default VerticalTabMenu;
