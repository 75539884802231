import React, { useState, useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { useTheme } from '@material-ui/core';
import { StyledCell } from './styled-components';

export interface IRow {
  id: string | number;
  data: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  hasmaxwidth?: boolean;
  isActive?: boolean;
  colSpan?: number[];
}

type RowCellType = React.ReactElement | ((hover: boolean) => React.ReactElement);

export interface RowProps {
  rowId: string | number;
  rowCells: RowCellType[];
  hasmaxwidth: boolean;
  hoverable: boolean;
  clickable?: boolean;
  isActive?: boolean;
  colSpan?: number[];
  onClick?: () => void;
}

const renderComponentByType = (hover: boolean, rowCell: RowCellType): React.ReactElement => {
  if (typeof rowCell === 'function') {
    return rowCell(hover);
  }
  return rowCell;
};

const Row = (props: RowProps): React.ReactElement => {
  const {
    rowId,
    rowCells,
    hasmaxwidth,
    hoverable,
    clickable = false,
    isActive = false,
    colSpan = [],
    onClick = (): void => undefined,
  } = props;
  const [hover, setHover] = useState(false);
  const hoverOn = useCallback((): void => {
    if (hoverable) {
      setHover(true);
    }
  }, [hoverable, setHover]);

  const hoverOff = useCallback((): void => {
    if (hoverable) {
      setHover(false);
    }
  }, [hoverable, setHover]);

  const theme = useTheme();

  const style: React.CSSProperties = {};
  if (hover || isActive) {
    style.backgroundColor = theme.palette.background.default || '#F6F8FC';
  }

  if (clickable) {
    style.cursor = 'pointer';
  }

  return (
    <TableRow data-testid='body-row' onMouseEnter={hoverOn} onMouseLeave={hoverOff} style={style}>
      {rowCells.map(
        (rowCell: RowCellType, index: number): React.ReactElement => (
          <StyledCell
            // eslint-disable-next-line react/no-array-index-key
            key={`${rowId}_${index}`}
            hasmaxwidth={(!!hasmaxwidth).toString()}
            colSpan={colSpan[index] || 1}
            onClick={(): void => onClick()}
          >
            {renderComponentByType(hover, rowCell)}
          </StyledCell>
        ),
      )}
    </TableRow>
  );
};

Row.defaultProps = {
  hasmaxwidth: false,
};

export default Row;
