import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

interface IProps {
	showDefault?: boolean
	placeholderText?: string
	placeholderComponent?: React.FC<any> | React.Component<any>
	placeholderContainerClass?: string
	placeholderTextClass?: string
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 117,
		},
	})
)

export default function TimePickers(props: IProps & TextFieldProps) {
	const classes = useStyles();
	const {
		showDefault,
		placeholderText,
		placeholderContainerClass,
		placeholderTextClass,
		className,
		...rest
	} = props

	return !showDefault ? (
		<TextField
			id="time"
			label={null}
			type="time"
			{...rest}
			value={props.value}
			className={classes.textField}
			InputProps={
				{
					// readOnly: true,
					className
				}
			}
			variant="outlined"
			inputProps={{
				step: 300, // 5 min
				min: '7:00',
			}}
		/>
	) : (
		<div className={placeholderContainerClass}>
			<p className={placeholderTextClass}>{placeholderText}</p>
		</div>
	)
}
