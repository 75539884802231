import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const StyledCell = styled(TableCell)`
  max-width: ${(props: { hasmaxwidth: string }): string =>
    props.hasmaxwidth === 'true' ? '100px' : 'none'};
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledCell };
