import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledHeaderRow = styled(TableRow)`
  & th {
    color: ${getThemeProp('palette.text.secondary', '#6E84A3')};
    font-size: 15px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledHeaderRow };
