import React from 'react';
import { FormFieldBase, FormFieldInput } from '.';
import { getFieldProperties, getFieldPropertyValues } from './helpers';

export const FormFieldDropdown = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  // get options from properties
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { options = [] } = getFieldPropertyValues(properties.specific, 'options');

  return (
    <FormFieldInput
      field={field}
      options={{
        properties,
        isRenderedField,
        onChange,
        value,
        onBlur,
        validationMessage,
        readOnly,
      }}
    >
      {isRenderedField
        ? options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))
        : null}
    </FormFieldInput>
  );
};

FormFieldDropdown.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldDropdown);
