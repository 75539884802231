import React from 'react';
// eslint-disable-next-line
import { TextField } from '@material-ui/core';
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Chat from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import Draggable from 'react-draggable';

import SEND_BTN from '../../images/send.svg';
import HIDE_ICON from '../../images/hide-show-icon.svg';
import SVG_MESSAGE_ACTIVE from '../../images/message_active.svg';
import SVG_MESSAGE_INACTIVE from '../../images/message_inactive.svg';
import './ChatComponent.scss';
import { MessageModel } from '../../types/MessageModel';
import { isAndroid } from "react-device-detect";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: '400px',
      maxWidth: '400px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  onClose(): void;
  id?: string;
}

const DialogTitle = withStyles(styles)((props: Props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let channel: Channel;
let client = null;

interface IProps {
  isEndedCall: boolean;
  token: string;
  isOpen: boolean;
  channelId: string;
  patientName: string;
  username: string;
  onCloseDialog(a: boolean): void;
}

interface IMessage {
  author: string;
  body: string;
}

const ChatComponent: React.FC<IProps> = (props) => {
  const [isShowDialog, setIsShowDialog] = React.useState(props.isOpen);
  const [isTransparent, setIsTransparent] = React.useState(false);
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [text, setText] = React.useState('');
  const [isHasNewMess, setIsHasNewMess] = React.useState(false);
  const chatScreen = React.useRef<HTMLDivElement>(document.createElement('div'));
  const fieldContent = React.useRef<HTMLTextAreaElement>(document.createElement('textarea'));
  const chatDialog = React.useRef<HTMLDivElement>(
    document.createElement('div')
  );

  const handleLeaveChannel = () => {
    channel.leave();
  };

  React.useEffect(() => {
    window.addEventListener('unload', handleLeaveChannel);
    if (props.isEndedCall && channel !== undefined) {
      channel.leave();
    }
    return () => {
      // required to stop memory leak when using window.eventListener
      window.removeEventListener('unload', handleLeaveChannel);
      channel.leave();
    };
  }, [props.isEndedCall]);

  React.useEffect(() => {
    createChatClient(props.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.channelId]);

  React.useEffect(() => {
    if (isShowDialog) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  React.useEffect(() => {
    if (isShowDialog) {
      const last = channel.getMessagesCount.toString();
      channel.updateLastConsumedMessageIndex(parseInt(last));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleClose = () => {
    setIsShowDialog(false);
    setIsHasNewMess(false);
    props.onCloseDialog(true);
  };

  /**
   * Create chat client on twilio
   * @param token is token of chat client get from twilio
   */
  const createChatClient = (token: string) => {
    if (token !== null) {
      Chat.create(token).then((clientProp) => {
        client = clientProp;
        client
          .getChannelBySid(props.channelId)
          .then((channelProp) => {
            channel = channelProp;
            return channel.join();
          })
          .then(() => {
            channel.getMessages().then(loadingMessage);
            channel.on('messageAdded', async (mess) => {
              await eventAddMessage(mess);
            });
          });
      });
    }
  };

  const scrollToBottom = () => {
    chatScreen.current.scrollTop = chatScreen.current.scrollHeight;
  }

  /**
   * Loading old mess from twilio channel
   * @param data
   */
  const loadingMessage = (data: any) => {
    data.items.forEach((message: any) => {
      let newMess = {
        author: message.author,
        body: message.body
      };
      setMessages(prevState => {
        return [...prevState, newMess]
      });
    });
  }

  /**
   * Event listen add new mess to channel twilio
   * @param message
   */
  const eventAddMessage = (message: MessageModel) => {
    if (!isShowDialog) {
      setIsHasNewMess(true);
    }
    let newMess = {
      author: message.author,
      body: message.body,
    };
    setMessages((prevState) => {
      return [...prevState, newMess];
    });
  };

  /**
   * Function send message
   */
  const sendMessage = async () => {
    if (text.length !== 0 && channel !== undefined) {
      await channel.sendMessage(text);
      setText('');
    }
  };

  /**
   * Add event for Enter button to send message
   * @param e Event
   */
  const keyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };
  /**
   * Hide and show chat dialog function
   */
  const hideShowMessDialog = () => {
    let chat = chatDialog.current;
    setIsTransparent(!isTransparent);
    if (!isTransparent) {
      chat.classList.add('hide');
    } else {
      chat.classList.remove('hide');
    }
  };
  /**
   * Handle data when show chat dialog
   * Control read mess
   */
  const handleShowChat = async () => {
    await setIsShowDialog(!isShowDialog);
    setIsHasNewMess(false);
    scrollToBottom();
  };

  /**
   * (Need update this case)
   * Remove tabindex of dialog modal for interactive with all modal render
   */
  const removeTabIndex = () => {
    let dialog = document.getElementsByClassName('MuiDialog-container')[1]; // New Dialog modal render will be bottom of old modal so need get index 1 for new model (only for 2 modal render)
    if (dialog !== undefined) {
      dialog.removeAttribute('tabindex');
    }
  };

  return (
    <div className='chat-container'>
      <div className='icon-mess-group'>
        {isHasNewMess && !isShowDialog ? (
          <div className='blinkingDot'></div>
        ) : null}
        <img
          alt=''
          src={!isShowDialog ? SVG_MESSAGE_INACTIVE : SVG_MESSAGE_ACTIVE}
          onClick={handleShowChat}
        />
      </div>
      <p>Message</p>
      <Draggable
        handle='strong'
        bounds='parent'
        defaultClassNameDragging={isTransparent ? 'hide' : ''}
        defaultClassNameDragged={isTransparent ? 'hide' : ''}
      >
        <Dialog
          onClick={removeTabIndex}
          ref={chatDialog}
          aria-labelledby='customized-dialog-title'
          open={isShowDialog}
          className={isAndroid ? 'isAndroid dialog-container' : 'dialog-container'}
        >
          <strong className='cursor'>
            <img
              alt=''
              className='hideShowIcon'
              src={HIDE_ICON}
              onClick={hideShowMessDialog}
            />
            <DialogTitle id='customized-dialog-title' onClose={handleClose}>
              <span>
                {props.patientName.length !== 0 ? props.patientName : 'Patient'}
              </span>
            </DialogTitle>
          </strong>
          <DialogContent
            ref={chatScreen}
            id="chat-container"
            dividers
            style={{ overflowY: 'scroll', height: '400px', width: 'auto' }}
          >
            <Grid container>
              {messages.map((message, i: number) => (
                <Grid
                  item
                  xs={12}
                  key={i}
                  className={
                    message.author === props.username
                      ? 'localContainer'
                      : 'partnerContainer'
                  }
                >
                  <span
                    className={
                      message.author === props.username
                        ? 'localItem'
                        : 'partnerItem'
                    }
                  >
                    {message.body}
                  </span>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions className='input-chat-container'>
            <textarea
              ref={fieldContent}
              autoFocus
              onFocus={(event) => {
                // Set pointer to end of value
                event.preventDefault();
                const { target } = event;
                const extensionStarts = target.value.lastIndexOf('.');
                target.focus();
                target.selectionStart = extensionStarts;
                target.scrollTop = target.scrollHeight;
              }}
              className='outlined-required field-content'
              style={{ width: '100%' }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={keyPress}
            >
            </textarea>
            <img
              alt=''
              className='sendButton'
              src={SEND_BTN}
              onClick={sendMessage}
            />
          </DialogActions>
        </Dialog>
      </Draggable>
    </div>
  );
};

export default ChatComponent;
