export interface IDeliverySetting {
	id?: string | any
	day: string
	active: boolean
	startTime: Date | any
	endTime: Date | any
	error?: string | any
}

export interface ITimeZoneList {
	name: string;
	offset: string;
	locations?: string[];
	label?: string;
	standardTimezoneId?: string;
	standardDisplayName: string;
}

export const option = [{ label: 'America/Los_Angeles ( GMT-07:00 )' }]

export const initialTimeZoneList: ITimeZoneList[] = [
  {
    name: 'UTC-8:00',
    offset: 'UTC-8:00',
    locations: ['Pacific Standard Time - PST:'],
    standardDisplayName: 'Pacific Standard Time',
  },
  {
    name: 'UTC-07:00',
    offset: 'UTC-07:00',
    locations: ['Pacific Daylight Time - PDT:'],
    standardDisplayName: 'Pacific Daylight Time',
  },
  {
    name: 'UTC-07:00',
    offset: 'UTC-07:00',
    locations: ['Mountain Standard Time - PST:'],
    standardDisplayName: 'Mountain Standard Time',
  },
  {
    name: 'UTC-06:00',
    offset: 'UTC-06:00',
    locations: ['Mountain Daylight Time - PDT:'],
    standardDisplayName: 'Mountain Daylight Time',
  },
  {
    name: 'UTC-06:00',
    offset: 'UTC-06:00',
    locations: ['Central Standard Time - PST:'],
    standardDisplayName: 'Central Standard Time',
  },
  {
    name: 'UTC-05:00',
    offset: 'UTC-05:00',
    locations: ['Central Daylight Time - PDT:'],
    standardDisplayName: 'Central Daylight Time',
  },
  {
    name: 'UTC-05:00',
    offset: 'UTC-05:00',
    locations: ['Eastern Standard Time - PST:'],
    standardDisplayName: 'Eastern Standard Time',
  },
  {
    name: 'UTC-04:00',
    offset: 'UTC-04:00',
    locations: ['Eastern Daylight Time - PDT:'],
    standardDisplayName: 'Eastern Daylight Time',
  },
  {
    name: 'UTC-10:00',
    offset: 'UTC-10:00',
    locations: ['Hawaii Standard Time - PST:'],
    standardDisplayName: 'Hawaii Standard Time',
  },
  {
    name: 'UTC-09:00',
    offset: 'UTC-09:00',
    locations: ['Hawaii Daylight Time - PDT:'],
    standardDisplayName: 'Hawaii Daylight Time',
  },
  {
    name: 'UTC-03:30',
    offset: 'UTC-03:30',
    locations: ['Newfoundland Standard Time - PST:'],
    standardDisplayName: 'Newfoundland Standard Time',
  },
  {
    name: 'UTC-02:30',
    offset: 'UTC-02:30',
    locations: ['Newfoundland Daylight Time - PDT:'],
    standardDisplayName: 'Newfoundland Daylight Time',
  },
  {
    name: 'UTC-09:00',
    offset: 'UTC-09:00',
    locations: ['Alaska Standard Time - PST:'],
    standardDisplayName: 'Alaska Standard Time',
  },
  {
    name: 'UTC-08:00',
    offset: 'UTC-08:00',
    locations: ['Alaska Daylight Time - PDT:'],
    standardDisplayName: 'Alaska Daylight Time',
  },
  {
    name: 'UTC-04:00',
    offset: 'UTC-04:00',
    locations: ['Atlantic Standard Time - PST:'],
    standardDisplayName: 'Atlantic Standard Time',
  },
  {
    name: 'UTC-03:00',
    offset: 'UTC-03:00',
    locations: ['Atlantic Daylight Time - PDT:'],
    standardDisplayName: 'Atlantic Daylight Time',
  },
	// {
	// 	name: 'UTC+08:00',
	// 	offset: 'UTC+08:00',
	// 	locations: [
	// 		'Beijing, Chongqing, Urumqi (China)',
	// 		'Hong Kong',
	// 		'Irkutsk (Russia)',
	// 		'Kuala Lumpur (Malaysia)',
	// 		'Perth (Australia)',
	// 		'Singapore',
	// 		'Taipei (Taiwan)',
	// 		'Ulan Bator (Mongolia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+13:00',
	// 	offset: 'UTC+13:00',
	// 	locations: [
	// 		'Apia (Samoa)',
	// 		'Atafu (Tokelau)',
	// 		"Nuku'alofa (Tonga)",
	// 		'Phoenix Islands (Kiribati)',
	// 	],
	// },
	// {
	// 	name: 'UTC+07:00',
	// 	offset: 'UTC+07:00',
	// 	locations: [
	// 		'Bangkok (Thailand)',
	// 		'Hanoi (Vietnam)',
	// 		'Jakarta (Indonesia)',
	// 		'Krasnoyarsk (Russia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+06:00',
	// 	offset: 'UTC+06:00',
	// 	locations: [
	// 		'Astana, Almaty (Kazakhstan)',
	// 		'Dhaka (Bangladesh)',
	// 		'Novosibirsk (Russia)',
	// 		'Thimphu (Bhutan)',
	// 	],
	// },
	// {
	// 	name: 'UTC+05:30',
	// 	offset: 'UTC+05:30',
	// 	locations: [
	// 		'Chennai, Kolkata, Mumbai, New Delhi (India)',
	// 		'Sri Jayawardenepura (Sri Lanka)',
	// 	],
	// },
	// {
	// 	name: 'UTC+05:00',
	// 	offset: 'UTC+05:00',
	// 	locations: [
	// 		'Islamabad, Karachi (Pakistan)',
	// 		'Malé (Maldives)',
	// 		'Tashkent (Uzbekistan)',
	// 		'Yekaterinburg (Russia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+04:30',
	// 	offset: 'UTC+04:30',
	// 	locations: ['Kabul (Afghanistan)'],
	// },
	// {
	// 	name: 'UTC+04:00',
	// 	offset: 'UTC+04:00',
	// 	locations: [
	// 		'Abu Dhabi (UAE)',
	// 		'Baku (Azerbaijan)',
	// 		'Muscat (Oman)',
	// 		'Port Louis (Mauritius)',
	// 		'Tbilisi (Georgia)',
	// 		'Victoria (Seychelles)',
	// 		'Yerevan (Armenia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+03:30',
	// 	offset: 'UTC+03:30',
	// 	locations: ['Tehran (Iran)'],
	// },
	// {
	// 	name: 'UTC+06:30',
	// 	offset: 'UTC+06:30',
	// 	locations: ['Yangon (Myanmar)'],
	// },
	// {
	// 	name: 'UTC-12:00',
	// 	offset: 'UTC-12:00',
	// 	locations: [
	// 		'International Date Line West',
	// 		'Baker Island (USA, uninhabited)',
	// 		'Howland Island (USA, uninhabited)',
	// 	],
	// },
	// {
	// 	name: 'UTC+14:00',
	// 	offset: 'UTC+14:00',
	// 	locations: ['Line Islands (Kiribati)'],
	// },
	// {
	// 	name: 'UTC+12:45',
	// 	offset: 'UTC+12:45',
	// 	locations: ['Waitangi (Chatham Islands)'],
	// },
	// {
	// 	name: 'UTC-11:00',
	// 	offset: 'UTC-11:00',
	// 	locations: [
	// 		'Alofi (Niue)',
	// 		'Midway Island (USA)',
	// 		'Pago Pago (American Samoa)',
	// 	],
	// },
	// {
	// 	name: 'UTC-10:00',
	// 	offset: 'UTC-10:00',
	// 	locations: ['Hawaii, Aleutian Islands (USA)', 'Most of French Polynesia'],
	// },
	// {
	// 	name: 'UTC-09:30',
	// 	offset: 'UTC-09:30',
	// 	locations: ['Marquesas Islands (French Polynesia)'],
	// },
	// {
	// 	name: 'UTC-09:00',
	// 	offset: 'UTC-09:00',
	// 	locations: ['Alaska (USA)', 'Gambier Islands (French Polynesia)'],
	// },
	// {
	// 	name: 'UTC-08:00',
	// 	offset: 'UTC-08:00',
	// 	locations: ['Pacific Time (USA and Canada)', 'Pacific Time (Mexico)'],
	// },
	// {
	// 	name: 'UTC-07:00',
	// 	offset: 'UTC-07:00',
	// 	locations: ['Mountain Time (USA and Canada)', 'Mountain Time (Mexico)'],
	// },
	// {
	// 	name: 'UTC-06:00',
	// 	offset: 'UTC-06:00',
	// 	locations: [
	// 		'Central Time (USA and Canada)',
	// 		'Central Time (Mexico)',
	// 		'Galápagos Islands (Ecuador)',
	// 		'Guatemala City (Guatemala)',
	// 		'Managua (Nicaragua)',
	// 		'San Jose (Costa Rica)',
	// 		'San Salvador (El Salvador)',
	// 		'Tegucigalpa (Honduras)',
	// 	],
	// },
	// {
	// 	name: 'UTC-05:00',
	// 	offset: 'UTC-05:00',
	// 	locations: [
	// 		'Eastern Time (USA and Canada)',
	// 		'Bogota (Colombia)',
	// 		'Havana (Cuba)',
	// 		'Kingston (Jamaica)',
	// 		'Lima (Peru)',
	// 		'Panama City (Panama)',
	// 		'Port-au-Prince (Haiti)',
	// 		'Quito (Ecuador)',
	// 	],
	// },
	// {
	// 	name: 'UTC-04:00',
	// 	offset: 'UTC-04:00',
	// 	locations: [
	// 		'Atlantic Time (Canada)',
	// 		'Asunción (Paraguay)',
	// 		'Caracas (Venezuela)',
	// 		'La Paz (Bolivia)',
	// 		'Manaus (Brazil)',
	// 		'Port of Spain (Trinidad and Tobago)',
	// 		'San Juan (Puerto Rico)',
	// 		'Santiago (Chile)',
	// 		'Santo Domingo (Dominican Republic)',
	// 	],
	// },
	// {
	// 	name: 'UTC-03:30',
	// 	offset: 'UTC-03:30',
	// 	locations: ['Newfoundland (Canada)'],
	// },
	// {
	// 	name: 'UTC-03:00',
	// 	offset: 'UTC-03:00',
	// 	locations: [
	// 		'Brasilia, Rio de Janeiro, São Paulo (Brazil)',
	// 		'Buenos Aires (Argentina)',
	// 		'Montevideo (Uruguay)',
	// 		'Nuuk (Greenland)',
	// 		'Stanley (Falkland Islands)',
	// 	],
	// },
	// {
	// 	name: 'UTC-02:00',
	// 	offset: 'UTC-02:00',
	// 	locations: [
	// 		'Mid-Atlantic',
	// 		'King Edward Point (South Georgia and the South Sandwich Islands)',
	// 		'Villa dos Remedios (Fernando de Noronha)',
	// 	],
	// },
	// {
	// 	name: 'UTC-01:00',
	// 	offset: 'UTC-01:00',
	// 	locations: ['Azores (Portugal)', 'Praia (Cape Verde)'],
	// },
	// {
	// 	name: 'UTC±00:00',
	// 	offset: 'UTC±00:00',
	// 	locations: [
	// 		'Coordinated Universal Time (UTC)',
	// 		'Greenwich Mean Time (GMT)',
	// 		'In Europe:',
	// 		'Dublin (Ireland)',
	// 		'Edinburgh (Scotland)',
	// 		'Lisbon, Madeira (Portugal)',
	// 		'London (UK)',
	// 		'Reykjavik (Iceland)',
	// 		'Tórshavn (Faroe Islands)',
	// 		'In Africa:',
	// 		"Abidjan (Côte d'Ivoire)",
	// 		'Accra (Ghana)',
	// 		'Canary Islands (Spain)',
	// 		'Casablanca (Morocco)',
	// 		'Dakar (Senegal)',
	// 		'El Aaiún (Western Sahara)',
	// 		'Monrovia (Liberia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+01:00',
	// 	offset: 'UTC+01:00',
	// 	locations: [
	// 		'In Central Europe:',
	// 		'Amsterdam (Netherlands)',
	// 		'Belgrade (Serbia)',
	// 		'Berlin (Germany)',
	// 		'Bern (Switzerland)',
	// 		'Bratislava (Slovakia)',
	// 		'Brussels (Belgium)',
	// 		'Budapest (Hungary)',
	// 		'Copenhagen (Denmark)',
	// 		'Ljubljana (Slovenia)',
	// 		'Madrid (Spain)',
	// 		'Oslo (Norway)',
	// 		'Paris (France)',
	// 		'Prague (Czech Republic)',
	// 		'Pristina (Kosovo)',
	// 		'Rome (Italy)',
	// 		'Sarajevo (Bosnia and Herzegovina)',
	// 		'Skopje (Macedonia)',
	// 		'Stockholm (Sweden)',
	// 		'Tirana (Albania)',
	// 		'Valletta (Malta)',
	// 		'Vienna (Austria)',
	// 		'Warsaw (Poland)',
	// 		'Zagreb (Croatia)',
	// 		'In West Central Africa:',
	// 		'Abuja (Nigeria)',
	// 		'Algiers (Algeria)',
	// 		'Kinshasa (Democratic Republic of the Congo)',
	// 		'Libreville (Gabon)',
	// 		'Luanda (Angola)',
	// 		'Niamey (Niger)',
	// 		'Porto-Novo (Benin)',
	// 		'Tunis (Tunisia)',
	// 		'Windhoek (Namibia)',
	// 		'Yaoundé (Cameroon)',
	// 	],
	// },
	// {
	// 	name: 'UTC+02:00',
	// 	offset: 'UTC+02:00',
	// 	locations: [
	// 		'In East Europe:',
	// 		'Athens (Greece)',
	// 		'Bucharest (Romania)',
	// 		'Chișinău (Moldova)',
	// 		'Helsinki (Finland)',
	// 		'Istanbul (Turkey)',
	// 		'Jerusalem (Israel)',
	// 		'Kiev (Ukraine)',
	// 		'Nicosia (Cyprus)',
	// 		'Riga (Latvia)',
	// 		'Sofia (Bulgaria)',
	// 		'Tallinn (Estonia)',
	// 		'Tripoli (Libya)',
	// 		'Vilnius (Lithuania)',
	// 		'In Africa and Asia:',
	// 		'Amman (Jordan)',
	// 		'Beirut (Lebanon)',
	// 		'Cairo (Egypt)',
	// 		'Damascus (Syria)',
	// 		'Harare (Zimbabwe)',
	// 		'Kaliningrad (Russia)',
	// 		'Lilongwe (Malawi)',
	// 		'Lusaka (Zambia)',
	// 		'Maputo (Mozambique)',
	// 		'Mbabane (Swaziland)',
	// 		'Pretoria (South Africa)',
	// 	],
	// },
	// {
	// 	name: 'UTC+03:00',
	// 	offset: 'UTC+03:00',
	// 	locations: [
	// 		'In East Europe and Asia:',
	// 		'Baghdad (Iraq)',
	// 		'Kuwait City (Kuwait)',
	// 		'Minsk (Belarus)',
	// 		'Riyadh (Saudi Arabia)',
	// 		"Sana'a (Yemen)",
	// 		'In Africa:',
	// 		'Addis Ababa (Ethiopia)',
	// 		'Antananarivo (Madagascar)',
	// 		'Asmara (Eritrea)',
	// 		'Djibouti (Djibouti)',
	// 		'Dodoma (Tanzania)',
	// 		'Juba (South Sudan)',
	// 		'Kampala (Uganda)',
	// 		'Khartoum (Sudan)',
	// 		'Mogadishu (Somalia)',
	// 		'Moscow, St. Petersburg, Volgograd (Russia)',
	// 		'Nairobi (Kenya)',
	// 	],
	// },
	// {
	// 	name: 'UTC+12:00',
	// 	offset: 'UTC+12:00',
	// 	locations: [
	// 		'Auckland, Wellington (New Zealand)',
	// 		'Majuro (Marshall Islands)',
	// 		'Petropavlovsk-Kamchatsky (Russia)',
	// 		'Suva (Fiji)',
	// 		'Tarawa (Kiribati)',
	// 	],
	// },
	// {
	// 	name: 'UTC+11:00',
	// 	offset: 'UTC+11:00',
	// 	locations: [
	// 		'Honiara (Solomon Islands)',
	// 		'Kingston (Norfolk Island)',
	// 		'Nouméa (New Caledonia)',
	// 		'Magadan (Russia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+10:00',
	// 	offset: 'UTC+10:00',
	// 	locations: [
	// 		'Hagåtña (Guam)',
	// 		'Hobart, Melbourne, Canberra (Australia)',
	// 		'Sydney, Brisbane (Australia)',
	// 		'Port Moresby (Papua New Guinea)',
	// 		'Vladivostok (Russia)',
	// 	],
	// },
	// {
	// 	name: 'UTC+09:30',
	// 	offset: 'UTC+09:30',
	// 	locations: ['Adelaide, Darwin (Australia)'],
	// },
	// {
	// 	name: 'UTC+09:00',
	// 	offset: 'UTC+09:00',
	// 	locations: [
	// 		'Dili (East Timor)',
	// 		'Osaka, Sapporo, Tokyo (Japan)',
	// 		'Seoul (South Korea)',
	// 		'Yakutsk (Russia)',
	// 	],
	// },
]

export const defaultInitialDeliveryDay: IDeliverySetting[] = [
	{
		id: '1',
		day: 'Monday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '2',
		day: 'Tuesday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '3',
		day: 'Wednesday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '4',
		day: 'Thursday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '5',
		day: 'Friday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '6',
		day: 'Saturday',
		active: false,
		startTime: '',
		endTime: '',
	},
	{
		id: '7',
		day: 'Sunday',
		active: false,
		startTime: '',
		endTime: '',
	},
]
