import { Button } from '@material-ui/core';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import React from 'react';
import { ListDate } from '../Availability/AvailabilityVM';
import AddOutOfOfficeEvent from '../Availability/components/AddOutOfOfficeEvent';
import HoursOfOperator from '../Availability/components/HoursOfOperator';
import { OfficeEvent } from './ProviderAvailabilityVM';
import { ProviderAvailabilityWrapper } from './ProviderAvailabilityWrapper';
import { IProviderUnvailability } from './ProvidersModal';
import ProviderUnvailability from './ProviderUnvailability';

interface Iprops {
  onChangeDateTime: (dayOrder: number, event: any) => void;
  onChangeLucnhTime: (dayOrder: number, event: any) => void;
  onChangeToogle: (dayOrder: number) => void;
  onChangeToogleLunch: (dayOrder: number) => void;
  handleChangeUnvailability: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onCloseModalConfirmModal: () => void;
  onCloseModalAddOfficeEvent: () => void;
  onChangeIsDirtyForm: (isDirty: boolean) => void;
  onChangeErrorExistEventName: (error: string) => void;
  onShowModalAddOfficeEvent: () => void;
  updateWorkingHours: () => void;
  onShowModalCornfirm: () => void;
  onShowModalEditEvent: (officeEvent: OfficeEvent | null) => void;
  onShowModalDeleteEvent: (officeEvent: OfficeEvent | null) => void;
  createOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  updateOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  deleteOutOfOfficeEvent: (id: string) => void;
  setActiveTab: (tab: number) => void;
  setSumActiveTabs: (tabs: number) => void;
  setDisplayRoleModal: (arg: boolean) => void;
  setIsSelectedDelete: (args: boolean) => void;
  handleCloseForm: any;
  selectedEvent: OfficeEvent | null;
  listDate: ListDate[];
  isLoadingHourOfOperation: boolean;
  isLoadingEvent: boolean;
  isSelectedDelete: boolean;
  listOfficeOff: OfficeEvent[];
  isShowModalAddOfficeEvent: boolean;
  isDirtyForm: boolean;
  isShowConfirmModal: boolean;
  errorExistEventName: string;
  dirtyAvailability: boolean;
  statusProviderUnvailability: IProviderUnvailability;
  // dirtyUnvailability: boolean;
}

const ProviderAvailability = (props: Iprops) => {
  const {
    listDate,
    isSelectedDelete,
    isShowModalAddOfficeEvent,
    selectedEvent,
    isDirtyForm,
    isShowConfirmModal,
    isLoadingHourOfOperation,
    isLoadingEvent,
    listOfficeOff,
    errorExistEventName,
    handleCloseForm,
    dirtyAvailability,
    // isUnavaliablePending,
    // isUnavaliableAccepted,
    // setIsUnavaliablePending,
    // setIsUnavaliableAccepted,
    setIsSelectedDelete,
    setDisplayRoleModal,
    onShowModalAddOfficeEvent,
    onCloseModalAddOfficeEvent,
    onChangeLucnhTime,
    onChangeDateTime,
    onChangeToogle,
    onChangeToogleLunch,
    onShowModalCornfirm,
    onCloseModalConfirmModal,
    onChangeIsDirtyForm,
    onChangeErrorExistEventName,
    onShowModalEditEvent,
    updateWorkingHours,
    onShowModalDeleteEvent,
    createOutOfOfficeEvent,
    updateOutOfOfficeEvent,
    deleteOutOfOfficeEvent,
    handleChangeUnvailability,
    statusProviderUnvailability,
    // dirtyUnvailability
  } = props;

  return (
    <div className='react-modal--content custom-scroll'>
      <div className='edit-appointment'>
        <ProviderAvailabilityWrapper>
          <p className='title-header'>Working Hours</p>
          <p className='description-header'>
            Set the hours that this provider will be available for online
            appointment booking.
          </p>
          <HoursOfOperator
            listDate={listDate}
            onChangeDateTime={onChangeDateTime}
            onChangeLucnhTime={onChangeLucnhTime}
            onChangeToogle={onChangeToogle}
            onChangeToogleLunch={onChangeToogleLunch}
            isLoadingHourOfOperation={isLoadingHourOfOperation}
          />
          <p className='title-header title-header--unavailability'>
            Provider Unavailability
          </p>
          <ProviderUnvailability
            handleChangeUnvailability={handleChangeUnvailability}
            statusProviderUnvailability={statusProviderUnvailability}
          />
          <p className='description-header'>
            Specify the days that this provider will not allow online
            appointment booking.
          </p>
          <AddOutOfOfficeEvent
            maxSize={35}
            isShowModalAddOfficeEvent={isShowModalAddOfficeEvent}
            selectedEvent={selectedEvent}
            isDirtyForm={isDirtyForm}
            isShowConfirmModal={isShowConfirmModal}
            isLoadingEvent={isLoadingEvent}
            isSelectedDelete={isSelectedDelete}
            listOfficeOff={listOfficeOff}
            errorExistEventName={errorExistEventName}
            setIsSelectedDelete={setIsSelectedDelete}
            onCloseModalAddOfficeEvent={onCloseModalAddOfficeEvent}
            onShowModalAddOfficeEvent={onShowModalAddOfficeEvent}
            onShowModalEditEvent={onShowModalEditEvent}
            onChangeIsDirtyForm={onChangeIsDirtyForm}
            onChangeErrorExistEventName={onChangeErrorExistEventName}
            onShowModalCornfirm={onShowModalCornfirm}
            onCloseModalConfirmModal={onCloseModalConfirmModal}
            onShowModalDeleteEvent={onShowModalDeleteEvent}
            createOutOfOfficeEvent={createOutOfOfficeEvent}
            updateOutOfOfficeEvent={updateOutOfOfficeEvent}
            deleteOutOfOfficeEvent={deleteOutOfOfficeEvent}
          />
        </ProviderAvailabilityWrapper>
      </div>

      <div className='react-modal-settings-footer'>
        <Button
          variant='contained'
          color='default'
          className='cancel-button'
          onClick={handleCloseForm}
        >
          <span>Cancel</span>
        </Button>
        <Button
          onClick={() => {
            updateWorkingHours();
          }}
          type='submit'
          variant='contained'
          color='primary'
          disabled={
            !(
              dirtyAvailability &&
              !listDate.some((date: ListDate) => date.error || date.errorLunch)
            )
          }
          // disabled={listDate.some((date: ListDate) => date.error || date.errorLunch)}
        >
          <span>Save</span>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ProviderAvailability);
