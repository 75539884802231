import React, { useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Video, {
  Room,
  RemoteTrackPublication,
  LocalVideoTrackPublication,
  LocalAudioTrackPublication,
  AudioTrack,
  VideoTrack,
  LocalDataTrack,
  LocalDataTrackPublication,
  DataTrack,
  RemoteDataTrack,
  LocalParticipant,
  LocalTrack,
  RemoteTrack,
  LocalTrackPublication,
} from 'twilio-video';
import ChatComponent from '../chat/ChatComponent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import CameraControl from '../communication/camera/Camera';
import AudioControl from '../communication/audio/Audio';
import EndCallControl from '../communication/endcall/EndCall';
import TimerComponent from '../timer/TimerComponent';
import ScreenCapture from '../screen_capture/capture/ScreenCapture';
import GroupMenuComponent from '../group-menu/GroupMenuComponent';
import './VideoComponent.scss';
import {
  MessageDataTrack,
  CLOSE_SESSION,
  START_RECORDING,
  STOP_RECORDING,
} from '../../types/MessageDataTrack';
import NoteComponent from '../group-menu/note/NoteComponent';

interface VideoComponentProps {
  roomId: string;
  token: string;
  eventLeaveRoom: () => void;
  accessToken: string | null;
  appointmentId: string | null;
  channelId: string;
  createsMeetingSession: (a: boolean, b: boolean) => void;
  isStartRecording: boolean;
  isEndRecording: boolean;
  closeSession: (a: boolean, b: boolean) => void;
}

const VideoComponent = (props: VideoComponentProps) => {
  const [hasJoinedRoom, setHasJoinedRoom] = React.useState<boolean>();
  const [activeRoom, setActiveRoom] = React.useState<Room>();
  const [isShowChat, setIsShowChat] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [patientName, setPatientName] = React.useState('');
  const [isReconnecting, setIsReconnecting] = React.useState(false);
  const [camera, setCamera] = React.useState(true);
  const [audio, setAudio] = React.useState(true);
  const [isPatientJoined, setIsPatientJoined] = React.useState<boolean>();
  const [isPatientHasJoinRoom, setIsPatientHasJoinRoom] = React.useState<boolean>(false);
  const [isEndCall, setIsEndCall] = React.useState(false);
  const [
    openFolderOfScreenCapture,
    setOpenFolderOfScreenCapture,
  ] = React.useState(true);
  const [patientCameraOff, setPatientCameraOff] = React.useState(false);
  const [patientLeaveRoom, setPatientLeaveRoom] = React.useState(false);
  const [isUserJoinRoom, setIsUserJoinRoom] = React.useState<boolean>(false);

  const [isProcessingChangeRoom, setIsProcessingChangeRoom] = React.useState(false);

  const localVideoPreview = useRef<HTMLDivElement>(null);
  const partnerVideoPreview = useRef<HTMLDivElement>(null);

  const isChangeRoom = useRef<boolean>(false);

  const { createLocalTracks } = require(`twilio-video`);
  const dataTrack = new LocalDataTrack();

  // useEffect(() => {
  //   // Listen to the "beforeunload" event on window to leave the Room
  //   // when the tab/browser is being closed.
  //   window.addEventListener('unload', () => {
  //     activeRoom && activeRoom.disconnect()
  //   });

  //   // iOS Safari does not emit the "beforeunload" event on window.
  //   // Use "pagehide" instead.
  //   window.addEventListener('pagehide', () => activeRoom && activeRoom.disconnect());
  // }, []);

  //track change of camera property
  useEffect(() => {
    if (props.roomId !== '') {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          return createLocalTracks({ audio: true, video: true });
        })
        .then((localTracks) => {
          localTracks.push(dataTrack);
          let connectOptions = {
            name: props.roomId,
            tracks: localTracks,
          };
          Video.connect(props.token, connectOptions).then(
            joinVideoRoom,
            (error) => {
              alert('Could not connect to Twilio: ' + error.message);
            }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roomId]);

  useEffect(() => {
    activeRoom &&
      activeRoom.localParticipant.videoTracks.forEach(
        (participant: LocalVideoTrackPublication) => {
          participant.track.enable(camera);
        }
      );
    activeRoom &&
      activeRoom.localParticipant.audioTracks.forEach(
        (participant: LocalAudioTrackPublication) => {
          participant.track.enable(audio);
        }
      );
    return () => { };
  }, [camera, audio, activeRoom]);
  /**
   * Handle recording
   */
  useEffect(() => {
    if (props.isStartRecording) {
      isChangeRoom.current = true;
      if(isPatientJoined) {
        setIsProcessingChangeRoom(true);
      }
      let mess: MessageDataTrack = {
        command: START_RECORDING,
      };
      sendMessage(mess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isStartRecording]);

  useEffect(() => {
    if (props.isEndRecording) {
      isChangeRoom.current = true;
      if(isPatientJoined) {
        setIsProcessingChangeRoom(true);
      }
      let mess: MessageDataTrack = {
        command: STOP_RECORDING,
      };
      sendMessage(mess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEndRecording]);

  const fadeOutEffect = () => {
    const fadeTarget = document.getElementById('patientName');
    if (fadeTarget) {
      setTimeout(() => {
        fadeTarget.style.opacity = '0';
        fadeTarget.style.transition = '3000ms';
      }, 30000);
    }
  };

  const trackPublished = (
    publication: RemoteTrackPublication,
    container: HTMLDivElement
  ) => {
    publication.on(
      'subscribed',
      (track: AudioTrack | VideoTrack | RemoteDataTrack) => {
        attachTrack(track, container);
        track.on('disabled', () => {
          /* Hide the associated <video> element and show an avatar image. */
          if (track.kind === 'video') {
            setPatientCameraOff(true);
            console.log("event " + patientCameraOff)
          }
        });
        track.on('enabled', () => {
          /* Hide the associated <video> element and show an avatar image. */
          if (track.kind === 'video') {
            setPatientCameraOff(false);
            console.log("event " + patientCameraOff)
          }
        });
        track.on('message', (data: string) => {
          let message = JSON.parse(data);
          if (message.command === CLOSE_SESSION) {
            // props.closeSession(false);
          }
        });

         /* Hide the associated <video> element and display message. */
        let partner = document.getElementsByClassName('partnerVideo')[0];
        let video = partner.getElementsByTagName('video')[0];
        if (video === undefined) {
          setPatientCameraOff(true);
        } else {
          setPatientCameraOff(false);
        }
      }
    );
    publication.on('unsubscribed', detachTrack);
  };

  const convertNamePatient = (name: string) => {
    return name.split(":", 1)[0];
  }

  const joinVideoRoom = (room: Room) => {
    // Detach track when join new session video recording
    if (activeRoom !== undefined) {
      let tracks = getLocalTracks(activeRoom.localParticipant);
      detachTracks(tracks);
    }
    setIsUserJoinRoom(true);
    setActiveRoom(room);
    // Attach LocalParticipant's Tracks, if not already attached.
    if (
      !(
        localVideoPreview.current &&
        localVideoPreview.current.querySelector('video')
      )
    ) {
      setUsername(room.localParticipant.identity);
      setHasJoinedRoom(true);
      //add my video self
      let tracks = getLocalTracks(room.localParticipant);
      attachTracks(
        tracks,
        localVideoPreview && (localVideoPreview.current as HTMLDivElement)
      );
    } else {
      localVideoPreview.current.remove();
      let tracks = getLocalTracks(room.localParticipant);
      attachTracks(tracks, localVideoPreview && localVideoPreview.current);
    }

    //add another people when start video
    room.participants.forEach((participant) => {
      if (participant.tracks.size > 0) {
        setIsPatientJoined(true);
        setIsPatientHasJoinRoom(true);
        setIsProcessingChangeRoom(false);
        setPatientName(convertNamePatient(participant.identity));
        setTimeout(() => {
          isChangeRoom.current = false;
        },1000);
        fadeOutEffect();
      }
      participant.tracks.forEach((publication) => {
        trackPublished(
          publication,
          partnerVideoPreview.current as HTMLDivElement
        );
      });
      participant.on('trackPublished', (publication) => {
        trackPublished(
          publication,
          partnerVideoPreview.current as HTMLDivElement
        );
      });
    });

    // Listen event when a Participant joins the Room,
    room.on('participantConnected', (participant) => {
      setIsPatientJoined(true);
      setIsPatientHasJoinRoom(true);
      setPatientLeaveRoom(false);
      setIsProcessingChangeRoom(false);
      setPatientName(convertNamePatient(participant.identity));
      participant.tracks.forEach((publication: RemoteTrackPublication) => {
        trackPublished(
          publication,
          partnerVideoPreview.current as HTMLDivElement
        );
      });
      participant.on(
        'trackPublished',
        (publication: RemoteTrackPublication) => {
          trackPublished(
            publication,
            partnerVideoPreview.current as HTMLDivElement
          );
        }
      );
      setTimeout(() => {
        isChangeRoom.current = false;
      },1000);
      fadeOutEffect();
    });

    room.on('reconnecting', () => {
      setIsReconnecting(true);
    });

    room.on('reconnected', () => {
      setIsReconnecting(false);
    });

    room.on('participantDisconnected', (participant) => {
      setIsPatientJoined(false);
      setPatientLeaveRoom(true);
      setPatientCameraOff(false);
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on('disconnected', (room_local: Room) => {
      setIsReconnecting(false);
      setIsUserJoinRoom(false);
      let tracks = getLocalTracks(room_local.localParticipant);
      detachTracks(tracks);
      if (!room_local.localParticipant) {
        setHasJoinedRoom(false);
      }
    });
  };

  /**
   * Using data track of twilio to send a message for patient, notification about recording
   */
  const sendMessage = (message: MessageDataTrack) => {
    let data = JSON.stringify(message);
    activeRoom &&
      activeRoom.localParticipant.dataTracks.forEach(
        (participant: LocalDataTrackPublication) => {
          participant.track.send(data);
        }
      );
  };

  const getLocalTracks = (participant: LocalParticipant) => {
    return Array.from(participant.tracks.values())
      .filter((publication) => {
        return publication.track;
      })
      .map((publication: LocalTrackPublication) => {
        return publication.track;
      });
  };

  //Add or remove track to DOM
  const attachTracks = (
    tracks: Array<LocalTrack | RemoteTrack>,
    container: HTMLDivElement
  ) => {
    tracks.forEach((track: LocalTrack | RemoteTrack) =>
      attachTrack(track, container)
    );
  };

  const attachTrack = (
    track: AudioTrack | VideoTrack | DataTrack,
    container: HTMLDivElement
  ) => {
    if (track.kind === 'audio' || track.kind === 'video') {
      let e = track.attach();
      e.style.height = '100%';
      e.style.width = '100%';
      container.appendChild(e);
    }
  };

  const detachTracks = (tracks: Array<LocalTrack>) => {
    tracks.forEach((track: LocalTrack) => {
      detachTrack(track);
      if (track.kind !== 'data') {
        track.stop();
      }
    });
  };

  const detachTrack = (track: LocalTrack | RemoteTrack) => {
    if (track.kind === 'audio' || track.kind === 'video') {
      track.detach().forEach((detachedElement: HTMLMediaElement) => {
        detachedElement.remove();
      });
    }
  };

  const leaveRoom = async () => {
    setHasJoinedRoom(false);
    await setIsEndCall(true);
    await props.createsMeetingSession(false, true);
    await (activeRoom && activeRoom.disconnect());
    await props.eventLeaveRoom();
    setActiveRoom(undefined);
  };

  return (
    <div className='streamContainer'>
      <Dialog open={isReconnecting}>
        <DialogContent style={{ textAlign: 'center' }}>
          <CircularProgress /> Reconnecting...
        </DialogContent>
      </Dialog>

      <Dialog open={isProcessingChangeRoom && props.isStartRecording}>
        <DialogContent style={{ textAlign: 'center' }}>
          <CircularProgress />
          <p>Processing to start recording...Please wait.</p>
        </DialogContent>
      </Dialog>

      <Dialog open={isProcessingChangeRoom && props.isEndRecording}>
        <DialogContent style={{ textAlign: 'center' }}>
          <CircularProgress />
          <p>Processing to stop recording...Please wait.</p>
        </DialogContent>
      </Dialog>

      <div className='partnerVideo' ref={partnerVideoPreview}></div>
      {patientCameraOff ? (
        <p className='partnerOffCamera'>{patientName}'s camera is off</p>
      ) : null}
      <TimerComponent
        isPatientJoined={isPatientJoined}
        hasJoinedRoom={hasJoinedRoom}
        isEndedCall={isEndCall}
        accessToken={props.accessToken}
        appointmentId={props.appointmentId}
      ></TimerComponent>

      <Grid container item xs={12} className='bottomControl'>
        <Grid item xs={4} className='bottomControlLeft'>
          <ChatComponent
            isOpen={isShowChat}
            onCloseDialog={() => setIsShowChat(false)}
            isEndedCall={isEndCall}
            token={props.token}
            channelId={props.channelId}
            username={username}
            patientName={patientName}
          />
        </Grid>

        <Grid item xs={4} className='bottomControlCenter'>
          <AudioControl
            onSwitchAppointmentAudio={() => {
              setAudio(!audio);
            }}
            audio={audio}
          />
          <EndCallControl
            onClickEndCall={leaveRoom}
            appointmentId={props.appointmentId}
            accessToken={props.accessToken}
            userJoinRoom={isUserJoinRoom}
          />
          <CameraControl
            onSwitchAppointmentCamera={() => {
              setCamera(!camera);
            }}
            camera={camera}
          />
        </Grid>

        <Grid item xs={4} className='bottomControlRight'>
          <NoteComponent appointmentId={props.appointmentId} accessToken={props.accessToken} />
          <ScreenCapture
            appointmentId={props.appointmentId}
            accessToken={props.accessToken}
            partnerVideoPreview={partnerVideoPreview}
            openFolderOfScreenCapture={openFolderOfScreenCapture}
            setOpenFolderOfScreenCapture={setOpenFolderOfScreenCapture}
          ></ScreenCapture>
          <GroupMenuComponent
            appointmentId={props.appointmentId}
            accessToken={props.accessToken}
            createsMeetingSession={props.createsMeetingSession}
            isPatientJoined={isPatientJoined}
          ></GroupMenuComponent>
        </Grid>
      </Grid>
      {/* Display message about patient connect to room */}
      <div className='localVideo' ref={localVideoPreview}>
        {!camera ? <p className='txtOffCamera'>Camera is off</p> : null}
      </div>
      {isPatientJoined && !isChangeRoom.current ? (
        <p id='patientName' className='patientName'>
          {patientName} joined
        </p>
      ) : null}
      {!isPatientJoined && !isPatientHasJoinRoom && !isChangeRoom.current ? (
        <p className='alertPatient'>
          Please wait for the patient to join the appointment.
        </p>
      )
        : null}
      {patientLeaveRoom && !isPatientJoined && !isChangeRoom.current ? (
        <p className='alertPatient'>
          {patientName} has left the call
        </p>
      )
        : null}
    </div>
  );
};

export default VideoComponent;