import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { MainComponentProps } from 'types/NavigationT';
import Analyze from 'components/Communications/Analyze/Analyze';
import Templates from 'components/Communications/Templates/Templates';
import Automation from 'components/Communications/Automation/Automation';
import AutomationDetail from 'components/Communications/Automation/AutomationDetail';
import { useRouter } from 'hooks/useRouter';
import EditEmailTemplate from 'components/Communications/EditEmailTemplate';
import EditSMSTemplate from 'components/Communications/EditSMSTemplate';
import EditCallTemplate from 'components/Communications/Templates/EditCallTemplate';
import EditPostalTemplate from 'components/Communications/EditPostalTemplate';
import { useDecodeToken } from 'hooks/useDecodeToken'

export const CommunicationsContainer = (props?: MainComponentProps) => {

  const setHeaderNavLinks = props?.setHeaderNavLinks;

  const { allowedPath } = useDecodeToken();
  const isConversationsIncluded = allowedPath.includes('/Messenger');

  React.useEffect(() => {
    if (setHeaderNavLinks) {
      if (!isConversationsIncluded) {
        return setHeaderNavLinks([
          { linkTo: '/Communications/Templates', innerText: 'Templates' }
        ])
      } else {
        return setHeaderNavLinks([
          { linkTo: '/Communications', innerText: 'Analyze' },
          { linkTo: '/Communications/Templates', innerText: 'Templates' },
          { linkTo: '/Communications/Automation', innerText: 'Automation' },
        ])
      };
    }
  }, [setHeaderNavLinks]);

  const history = useHistory();
  React.useEffect(()=> {
    if(!isConversationsIncluded && !router.match.path.includes("/Templates")){
      history.replace(`${router.match.path}/Templates`);
    }
  }, [])

  const router = useRouter();
  return (
    <Switch>
      <Route exact path={router.match.path + "/Templates"} component={Templates} />
      <Route exact path={router.match.path + "/Automation"} component={Automation} />
      <Route path={"/Communications/Templates/CreatePostal/:category"} component={EditPostalTemplate} />
      <Route path={"/Communications/Templates/CreateEmail/:category"} component={EditEmailTemplate} />
      <Route path={"/Communications/Templates/CreateSMS/:category"} component={EditSMSTemplate} />
      <Route path={"/Communications/Templates/CreateCall/:category"} component={EditCallTemplate} />
      <Route path={"/Communications/Templates/Email/:templateId"} component={EditEmailTemplate} />
      <Route path={"/Communications/Templates/SMS/:templateId"} component={EditSMSTemplate} />
      <Route path={"/Communications/Templates/CALL/:templateId"} component={EditCallTemplate} />
      <Route path={"/Communications/Templates/Postal/:templateId"} component={EditPostalTemplate} />
      <Route path={router.match.path + "/Automation/AutomationDetail/:category/:automationTriggerKey/:automationCategoryId/:triggerId"} component={AutomationDetail} />
      <Route exact path={router.match.path} component={isConversationsIncluded ? Analyze : Templates} />
      {!isConversationsIncluded && <Redirect from={router.match.path} to={"/Communications/Templates"} />}
    </Switch>
  )
}

export default CommunicationsContainer;