import styled from 'styled-components';

const FullStar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
`;
const EmptyStar = styled.div`
  position: relative;

  left: 0;
  top: 0;
`;
// inline style ratings min-height when hidden to show stars appropriately
const Ratings = styled.div`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  min-height: 20px;
  overflow: hidden;
  .MuiRating-icon {
    color: #f6c343;
    font-size: ${(props: { fontSize?: string }): string => props.fontSize || '1em'};
  }
  .MuiRating-iconEmpty {
    opacity: 35%;
  }
  .backgroundStarsHidden {
    .MuiRating-iconEmpty {
      opacity: 0%;
    }
  }
`;

export { Ratings, FullStar, EmptyStar };
