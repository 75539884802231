import Pay from 'components/Global/Pay/Pay';
import ClientPortalSetting from 'components/Settings/ClientPortal/ClientPortalSetting';
import GeneralSettings from 'components/Settings/GeneralSetting/GeneralSetting';
import MangoSetting from 'components/Settings/MangoSetting/MangoSetting';
import NotificationsSetting from 'components/Settings/NotificationsSetting/Notifications';
import PracticeSyncer from 'components/Settings/PracticeSyncerSetting/PracticeSyncerSetting';
import RoleSetting from 'components/Settings/RoleSetting/RoleSetting';
import SpeciesSetting from 'components/Settings/SpeciesSetting/SpeciesSetting';
import SystemsSetting from 'components/Settings/SystemSettings/SystemSettings';
import TwilioSetting from 'components/Settings/TwilioSetting/TwilioSetting';
import UsersSetting from 'components/Settings/UsersSetting/UsersSetting';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { default as React, useEffect } from 'react';

const SettingsContainer = (): JSX.Element => {
  const [activeComponent, setActiveComponent] = React.useState<number>(0);

  useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    setActiveComponent(tabActive.leftMenuContentActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveLink = (numberActive: number) => {
    setTabActiveLocalStorage('', numberActive, 1);
    setActiveComponent(numberActive);
  };

  const componentValues: { innerText: string; component: JSX.Element }[] = [
    {
      innerText: 'General',
      component: <GeneralSettings />,
    },
    {
      innerText: 'Species',
      component: <SpeciesSetting />,
    },
    {
      innerText: 'Roles',
      component: <RoleSetting />,
    },
    {
      innerText: 'Users',
      component: <UsersSetting />,
    },
    {
      innerText: 'System',
      component: <SystemsSetting />,
    },
    {
      innerText: 'Notifications',
      component: <NotificationsSetting />,
    },

    {
      innerText: 'Syncer',
      component: <PracticeSyncer />,
    },
    {
      innerText: 'Client Portal',
      component: <ClientPortalSetting />,
    },
    {
      innerText: 'Pay',
      component: <Pay />,
    },
    {
      innerText: 'Twilio',
      component: <TwilioSetting />,
    },
    {
      innerText: 'Mango',
      component: <MangoSetting />,
    },
  ];

  return (
    <div
      className='communication-analyze settings--main-view-container'
      // id='settings--main-view-container'
    >
      <div className='communication-analyze__right-modal'>
        <ul className='analyze-tab__lists'>
          {componentValues.map((componentValue, index) => {
            return (
              <li
                key={'communication-templates-nav-' + index}
                tabIndex={0}
                className={
                  'analyze-tab__item-list ' +
                  (activeComponent === index ? 'active' : '')
                }
                onClick={() => {
                  handleActiveLink(index);
                }}
              >
                {componentValue.innerText}
              </li>
            );
          })}
        </ul>
      </div>

      {componentValues[activeComponent].component}
    </div>
  );
};

export default SettingsContainer;
