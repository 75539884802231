/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import toast from 'components/Global/Toast';
import { Field, FieldArray, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  AutomationTrigger,
  DeliverApproximatelyResponse,
  TemplateResponse,
} from 'global/requests/ResponseTypes';
import { AppointmentTypeSetting } from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import { Medical } from 'global/requests/ResponseTypes/Medical';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { useDecodeToken } from 'hooks/useDecodeToken';

const AutomationEventSchema = Yup.object().shape({
  messageType: Yup.string().required(),
  methodMessage: Yup.string().required(),
  timeValue: Yup.number().required(),
  timeType: Yup.string().required(),
  period: Yup.string().required(),
  approx: Yup.array().of(
    Yup.object().shape({
      fallbackMessage: Yup.string().required(),
      methodFallbackType: Yup.string().required(),
    })
  ),
  allowSendOutsideDeliveryHours: Yup.boolean(),
});



interface DetailsFragmentProps {
  openPopup: boolean;
  display: boolean;
  dirtyForm: boolean;
  setDirtyForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  dataRender?: AutomationTrigger;
  checkedAllow?: boolean;
  setCheckAllow?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplay?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenFragment?: React.Dispatch<React.SetStateAction<boolean>>;
  triggerId: string;
  categoryKind: string;
  automationTriggerKey?: string;
  automationCategoryId?: string;
  checkNewEvent(a: boolean): void;
  emailTemplateData: TemplateResponse;
  postalTemplateData: TemplateResponse;
  smsTemplateData: TemplateResponse;
  callTemplateData: TemplateResponse;
  deliverApproximatelyData: DeliverApproximatelyResponse;
  listAppointmentTypes: AppointmentTypeSetting[];
  listSpecies: SpeciesSettingType[];
  listMedicalServices: Medical[];
  handleCloseModal?: () => void;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const TYPE_EMAIL = 'email';
const TYPE_SMS = 'sms';
const TYPE_CALL = 'call';
const TYPE_POSTAL = 'postal';

const DetailsFragment = (props: DetailsFragmentProps) => {
  const {
    emailTemplateData,
    smsTemplateData,
    deliverApproximatelyData,
    callTemplateData,
    display,
    openPopup,
    dirtyForm,
    postalTemplateData,
    listAppointmentTypes,
    listSpecies,
    listMedicalServices,
  } = props;
  const setDisplay = props.setDisplay ? props.setDisplay : () => {};
  const setOpenPopup = props.setOpenPopup ? props.setOpenPopup : () => {};
  const setDirtyForm = props.setDirtyForm ? props.setDirtyForm : () => {};
  const checkNewEvent = props.checkNewEvent ? props.checkNewEvent : () => {};
  const handleCloseModal = props.handleCloseModal
    ? props.handleCloseModal
    : () => {};

  const [modalStyle] = React.useState(getModalStyle);

  const [searchAppointmentTypes, setSearchAppointmentTypes] =
    React.useState(false);
  const [errorApt, setErrorApt] = React.useState(false);
  const [searchNotAppointmentTypes, setSearchNotAppointmentTypes] =
    React.useState(false);
  const [errorNoApt, setErrorNoApt] = React.useState(false);
  const [focusAppointmentTypes, setFocusAppointmentTypes] =
    React.useState(false);
  const [focusNotAppointmentTypes, setFocusNotAppointmentTypes] =
    React.useState(false);
  const [optionAppointmentKind, setOptionAppointmentKind] =
    React.useState('all');

  const [searchMedicalServices, setSearchMedicalServices] =
    React.useState(false);
  const [searchNotMedicalServices, setSearchNotMedicalServices] =
    React.useState(false);
  const [errorMedical, setErrorMedical] = React.useState(false);
  const [errorNoMedical, setErrorNoMedical] = React.useState(false);
  const [focusMedicalServices, setFocusMedicalServices] = React.useState(false);
  const [focusNotMedicalServices, setFocusNotMedicalServices] =
    React.useState(false);
  const [optionMedicalKind, setOptionMedicalKind] = React.useState('all');

  const [searchSpecies, setSearchSpecies] = React.useState(false);
  const [focusSpecies, setFocusSpecies] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  const initialValues = {
    messageType: TYPE_EMAIL,
    methodMessage: emailTemplateData.items[0]?.templateId || '',
    appointmentTypes: [],
    appointmentTypesToNotSend: [],
    medicalServices: [],
    medicalServicesNotApply: [],
    species: [],
    timeValue: 1,
    timeType: 'minutes',
    period: '',
    approx: [],
    allowSendOutsideDeliveryHours: false,
  };
  const { allowedPath } = useDecodeToken();

  const handleFocus = (type: number) => {
    // type : 1 -> apply appointmentTypes
    //        2 -> not apply appointmentTypes
    //        3 -> species
    //        4 -> apply medical services
    //        5 -> not apply medical services
    switch (type) {
      case 1:
        setSearchAppointmentTypes(true);
        setFocusAppointmentTypes(true);
        break;
      case 2:
        setSearchNotAppointmentTypes(true);
        setFocusNotAppointmentTypes(true);
        break;
      case 3:
        setSearchSpecies(true);
        setFocusSpecies(true);
        break;
      case 4:
        setSearchMedicalServices(true);
        setFocusMedicalServices(true);
        break;
      case 5:
        setSearchNotMedicalServices(true);
        setFocusNotMedicalServices(true);
        break;
      default:
        break;
    }
  };

  const handleBlur = (
    type: number,
    arr: AppointmentTypeSetting[] | Medical[] | SpeciesSettingType[]
  ) => {
    switch (type) {
      case 1:
        setFocusAppointmentTypes(false);
        !arr.length && setSearchAppointmentTypes(false);
        !arr.length && setErrorApt(true);
        break;
      case 2:
        setFocusNotAppointmentTypes(false);
        !arr.length && setSearchNotAppointmentTypes(false);
        !arr.length && setErrorNoApt(true);
        break;
      case 3:
        setFocusSpecies(false);
        !arr.length && setSearchSpecies(false);
        break;
      case 4:
        setFocusMedicalServices(false);
        !arr.length && setSearchMedicalServices(false);
        !arr.length && setErrorMedical(true);
        break;
      case 5:
        setFocusNotMedicalServices(false);
        !arr.length && setSearchNotMedicalServices(false);
        !arr.length && setErrorNoMedical(true);
        break;
      default:
        break;
    }
  };

  const renderMethodMessage = (messageType: string) => {
    if (!messageType) {
      return (
        <option value='' className='place-holder'>
          Select a Template
        </option>
      );
    } else if (messageType && messageType === TYPE_SMS) {
      return smsTemplateData.items.length !== 0 ? (
        smsTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No SMS Template
        </option>
      );
    } else if (messageType && messageType === TYPE_EMAIL) {
      return emailTemplateData.items.length !== 0 ? (
        emailTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Email Template
        </option>
      );
    } else if (messageType && messageType === TYPE_CALL) {
      return callTemplateData.items.length !== 0 ? (
        callTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Call Template
        </option>
      );
    } else if (messageType && messageType === TYPE_POSTAL) {
      return postalTemplateData.items.length !== 0 ? (
        postalTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Postal Template
        </option>
      );
    }
  };

  const rederMessageKinds = () => {
    if (deliverApproximatelyData?.messageKinds && allowedPath.includes('/Messenger')) {
      // console.log(deliverApproximatelyData?.messageKinds)
      const messageKindOptions = deliverApproximatelyData?.messageKinds.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      return messageKindOptions;
    } else if(deliverApproximatelyData?.messageKinds && !allowedPath.includes('/Messenger')){
      console.log(deliverApproximatelyData?.messageKinds)
      let newFilterdSelect : {name:string, value: string}[] = [] 
      deliverApproximatelyData?.messageKinds.forEach((item)=>{
        if (item.name !== 'SMS'){
          newFilterdSelect.push({...item})
        }
      })
      return newFilterdSelect.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
    } 
    else {
      return <option value=''>N/A</option>;
    }
  };

  const renderTimeValue = (timeType: string = '', formikProps: any) => {
    if (
      timeType === 'minutes' &&
      deliverApproximatelyData?.definedMinuteValues
    ) {
      if (
        !(
          deliverApproximatelyData.definedMinuteValues.filter(
            (x) => x.value == formikProps.values.timeValue
          )?.length > 0
        )
      ) {
        formikProps.values.timeValue = 1;
      }
      const timeValueOptions =
        deliverApproximatelyData?.definedMinuteValues.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        });
      return timeValueOptions;
    } else if (deliverApproximatelyData?.definedValues) {
      if (
        !(
          deliverApproximatelyData.definedValues.filter(
            (x) => x.value == formikProps.values.timeValue
          )?.length > 0
        )
      ) {
        formikProps.values.timeValue = 1;
      }
      const timeValueOptions = deliverApproximatelyData?.definedValues.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      initialValues.timeValue = timeValueOptions[0].props.value;
      return timeValueOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderTimeType = () => {
    if (deliverApproximatelyData?.unitKinds) {
      const timeTypeOptions = deliverApproximatelyData?.unitKinds.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      initialValues.timeType = timeTypeOptions[0].props.value;
      return timeTypeOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderScheduledKinds = () => {
    if (deliverApproximatelyData?.scheduledKinds) {
      const scheduledKindsOptions =
        deliverApproximatelyData?.scheduledKinds.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        });
      initialValues.period = scheduledKindsOptions[0].props.value;
      return scheduledKindsOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const submitData = async (values: any) => {
    setDisabledSubmit(true);
    const submittingData = {
      enabled: true,
      appointmentTypeApplyKind: optionAppointmentKind,
      appointmentTypes:
        optionAppointmentKind === 'all'
          ? []
          : optionAppointmentKind === 'apply'
          ? values.appointmentTypes?.map((apt: AppointmentTypeSetting) => ({
              id: apt.id,
            }))
          : values.appointmentTypesToNotSend?.map(
              (apt: AppointmentTypeSetting) => ({ id: apt.id })
            ),
      reminderMedicalServiceApplyKind: optionMedicalKind,
      reminderMedicalServices:
        optionMedicalKind === 'all'
          ? []
          : optionMedicalKind === 'apply'
          ? values.medicalServices?.map(
              (medical: Medical) => medical.medicalServiceId
            )
          : values.medicalServicesNotApply?.map(
              (medical: Medical) => medical.medicalServiceId
            ),
      petSpecies: values.species,
      allowSendOutsideDeliveryHours: values.allowSendOutsideDeliveryHours,
      categoryKind: props.categoryKind || '',
      automationTriggerKey: props.automationTriggerKey || '',
      automationCategoryId: props.automationCategoryId || '',
      automationTriggerId: props.triggerId || '',
      timeQuantity: Number(values.timeValue),
      timeUnit: values.timeType || '',
      scheduleKind: values.period || '',
      communicationTemplates: (() => {
        const returnArray = [];
        let count = 0;
        returnArray.push({
          messageKind: values.messageType,
          templateId: values.methodMessage,
          ordinal: count,
        });
        values.approx.forEach(
          (item: { fallbackMessage: string; methodFallbackType: string }) => {
            if (!item.fallbackMessage || !item.methodFallbackType) {
              return;
            }
            count += 1;
            returnArray.push({
              messageKind: item.fallbackMessage,
              templateId: item.methodFallbackType,
              ordinal: count,
            });
          }
        );
        return returnArray;
      })(),
    };

    try {
      const res = await configuredRequests.POST.CreateAutomationTriggerEvent(
        submittingData
      );
      setDisplay(false);
      if (res) {
        checkNewEvent(true);
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setDisplay(false);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleConfirmDiscard = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    setDisplay(false);
  };

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionAppointmentKind(e.target.value);
    setErrorApt(false);
    setErrorNoApt(false);
  };

  const handleOptionMedicalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptionMedicalKind(e.target.value);
    setErrorMedical(false);
    setErrorNoMedical(false);
  };

  const handleDisableSubmitWithAppointment = (values: any) => {
    if (props.categoryKind !== 'appointment') {
      return true;
    }
    if (optionAppointmentKind === 'all') {
      return true;
    } else if (optionAppointmentKind === 'apply') {
      return values.appointmentTypes?.length ? true : false;
    } else {
      return values.appointmentTypesToNotSend?.length ? true : false;
    }
  };

  const handleDisableSubmitWithMedical = (values: any) => {
    if (props.categoryKind !== 'reminder') {
      return true;
    }
    if (optionMedicalKind === 'all') {
      return true;
    } else if (optionMedicalKind === 'apply') {
      return values.medicalServices?.length ? true : false;
    } else {
      return values.medicalServicesNotApply?.length ? true : false;
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          submitData(values);
        }}
        validationSchema={AutomationEventSchema}
        validateOnMount={true}
      >
        {(formikProps) => {
          const { dirty } = formikProps;
          if (dirty && display) {
            setDirtyForm(true);
          }
          return (
            <div className='edit-appointment'>
              <Fragment>
                <form
                  onSubmit={formikProps.handleSubmit}
                  data-testid='form'
                  className='col-12 form-schedule'
                >
                  <div className='form-group'>
                    <p>
                      Message <b className='require'>*</b>
                    </p>
                    <div className='d-flex'>
                      <Field
                        as='select'
                        name='messageType'
                        className='method flex1125'
                        onChange={(event: any) => {
                          formikProps.handleChange(event);
                          let methodMessageValue =
                            smsTemplateData.items[0]?.templateId || ''; // Default message value with message type = sms
                          if (event.target.value === TYPE_EMAIL) {
                            methodMessageValue =
                              emailTemplateData.items[0]?.templateId || '';
                          } else if (event.target.value === TYPE_CALL) {
                            methodMessageValue =
                              callTemplateData.items[0]?.templateId || '';
                          } else if (event.target.value === TYPE_POSTAL) {
                            methodMessageValue =
                              postalTemplateData.items[0]?.templateId || '';
                          }
                          formikProps.setFieldValue(
                            'methodMessage',
                            methodMessageValue
                          );
                        }}
                      >
                        {rederMessageKinds()}
                      </Field>
                      <Field
                        as='select'
                        name='methodMessage'
                        disabled={!formikProps.values.messageType}
                        className='appointment-confirmation-dropdown'
                      >
                        {renderMethodMessage(formikProps.values.messageType)}
                      </Field>
                    </div>
                  </div>

                  {props.categoryKind === 'appointment' && (
                    <div className='form-group setting__content--full-row block autocomplete'>
                      <p className='appointment-types'>
                        Appointment Types <b className='require'>*</b>
                      </p>
                      <RadioGroup
                        value={optionAppointmentKind}
                        onChange={handleChangeOption}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          htmlFor=''
                          label='Apply for all Appointment Types'
                        />
                        <FormControlLabel
                          value='apply'
                          control={<Radio />}
                          htmlFor=''
                          label='Apply to select Appointment Types'
                        />
                        {optionAppointmentKind === 'apply' && (
                          <div className='d-flex input-options'>
                            <Field
                              className={
                                formikProps.values.appointmentTypes.length &&
                                'autocomplete--padding'
                              }
                              name='appointmentTypes'
                              component={Autocomplete}
                              multiple
                              id='select-appointment-type'
                              options={listAppointmentTypes}
                              disableCloseOnSelect
                              defaultValue={formikProps.values.appointmentTypes}
                              getOptionLabel={(
                                option: AppointmentTypeSetting
                              ) => option.appointmentType}
                              noOptionsText={'No options'}
                              onChange={(e: any, value: any[]) => {
                                formikProps.setFieldValue(
                                  'appointmentTypes',
                                  value
                                );
                                !value.length &&
                                  !focusAppointmentTypes &&
                                  setSearchAppointmentTypes(false);
                                setErrorApt(value?.length ? false : true);
                              }}
                              getOptionSelected={(
                                option: AppointmentTypeSetting,
                                value: AppointmentTypeSetting
                              ) => {
                                return option.id === value.id;
                              }}
                              renderOption={(
                                option: AppointmentTypeSetting,
                                { selected }: any
                              ) => {
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize='small' />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize='small' />
                                      }
                                      style={{
                                        marginRight: 8,
                                        color: '#1974ff',
                                      }}
                                      checked={selected}
                                      className='checked-role'
                                    />
                                    {option.appointmentType}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    searchAppointmentTypes
                                      ? 'Search'
                                      : 'Select Appointment Types'
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  fullWidth
                                  onFocus={() => handleFocus(1)}
                                  onBlur={() => {
                                    handleBlur(
                                      1,
                                      formikProps.values.appointmentTypes
                                    );
                                  }}
                                />
                              )}
                            />
                            {errorApt && (
                              <p className='error-msg'>
                                This field is required.
                              </p>
                            )}
                          </div>
                        )}
                        <FormControlLabel
                          value='notApply'
                          control={<Radio />}
                          htmlFor=''
                          label="Don't apply for Appointment Types"
                        />
                        {optionAppointmentKind === 'notApply' && (
                          <div className='d-flex input-options'>
                            <Field
                              className={
                                formikProps.values.appointmentTypesToNotSend
                                  .length && 'autocomplete--padding'
                              }
                              name='appointmentTypesToNotSend'
                              component={Autocomplete}
                              multiple
                              id='select-appointment-type-not-send'
                              options={listAppointmentTypes}
                              disableCloseOnSelect
                              defaultValue={
                                formikProps.values.appointmentTypesToNotSend
                              }
                              getOptionLabel={(
                                option: AppointmentTypeSetting
                              ) => option.appointmentType}
                              noOptionsText={'No options'}
                              onChange={(e: any, value: any[]) => {
                                formikProps.setFieldValue(
                                  'appointmentTypesToNotSend',
                                  value
                                );
                                !value.length &&
                                  !focusNotAppointmentTypes &&
                                  setSearchNotAppointmentTypes(false);
                                setErrorNoApt(value?.length ? false : true);
                              }}
                              getOptionSelected={(
                                option: AppointmentTypeSetting,
                                value: AppointmentTypeSetting
                              ) => {
                                return option.id === value.id;
                              }}
                              renderOption={(
                                option: AppointmentTypeSetting,
                                { selected }: any
                              ) => {
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize='small' />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize='small' />
                                      }
                                      style={{
                                        marginRight: 8,
                                        color: '#1974ff',
                                      }}
                                      checked={selected}
                                      className='checked-role'
                                    />
                                    {option.appointmentType}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    searchNotAppointmentTypes
                                      ? 'Search'
                                      : 'Select Appointment Types'
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  fullWidth
                                  onFocus={() => handleFocus(2)}
                                  onBlur={() => {
                                    handleBlur(
                                      2,
                                      formikProps.values
                                        .appointmentTypesToNotSend
                                    );
                                  }}
                                />
                              )}
                            />
                            {errorNoApt && (
                              <p className='error-msg'>
                                This field is required.
                              </p>
                            )}
                          </div>
                        )}
                      </RadioGroup>
                    </div>
                  )}

                  {props.categoryKind === 'reminder' && (
                    <div className='form-group setting__content--full-row block autocomplete'>
                      <p className='appointment-types'>
                        Medical Services <b className='require'>*</b>
                      </p>
                      <RadioGroup
                        value={optionMedicalKind}
                        onChange={handleOptionMedicalChange}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          htmlFor=''
                          label='Apply for all Medical Services'
                        />
                        <FormControlLabel
                          value='apply'
                          control={<Radio />}
                          htmlFor=''
                          label='Apply to select Medical Services'
                        />
                        {optionMedicalKind === 'apply' && (
                          <div className='d-flex input-options'>
                            <Field
                              className={
                                formikProps.values.medicalServices.length &&
                                'autocomplete--padding'
                              }
                              name='medicalServices'
                              component={Autocomplete}
                              multiple
                              id='select-medical-service'
                              options={listMedicalServices}
                              disableCloseOnSelect
                              defaultValue={formikProps.values.medicalServices}
                              getOptionLabel={(option: Medical) =>
                                option.medicalServiceName
                              }
                              noOptionsText={'No options'}
                              onChange={(e: any, value: any[]) => {
                                formikProps.setFieldValue(
                                  'medicalServices',
                                  value
                                );
                                !value.length &&
                                  !focusMedicalServices &&
                                  setSearchMedicalServices(false);
                                setErrorMedical(value?.length ? false : true);
                              }}
                              getOptionSelected={(
                                option: Medical,
                                value: Medical
                              ) => {
                                return (
                                  option.medicalServiceId ===
                                  value.medicalServiceId
                                );
                              }}
                              renderOption={(
                                option: Medical,
                                { selected }: any
                              ) => {
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize='small' />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize='small' />
                                      }
                                      style={{
                                        marginRight: 8,
                                        color: '#1974ff',
                                      }}
                                      checked={selected}
                                      className='checked-role'
                                    />
                                    {option.medicalServiceName}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    searchMedicalServices
                                      ? 'Search'
                                      : 'Select Medical Services'
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  fullWidth
                                  onFocus={() => handleFocus(4)}
                                  onBlur={() => {
                                    handleBlur(
                                      4,
                                      formikProps.values.medicalServices
                                    );
                                  }}
                                />
                              )}
                            />
                            {errorMedical && (
                              <p className='error-msg'>
                                This field is required.
                              </p>
                            )}
                          </div>
                        )}
                        <FormControlLabel
                          value='notApply'
                          control={<Radio />}
                          htmlFor=''
                          label="Don't apply for Medical Services"
                        />
                        {optionMedicalKind === 'notApply' && (
                          <div className='d-flex input-options'>
                            <Field
                              className={
                                formikProps.values.medicalServicesNotApply
                                  .length && 'autocomplete--padding'
                              }
                              name='medicalServicesNotApply'
                              component={Autocomplete}
                              multiple
                              id='select-medical-service-not-send'
                              options={listMedicalServices}
                              disableCloseOnSelect
                              defaultValue={
                                formikProps.values.medicalServicesNotApply
                              }
                              getOptionLabel={(option: Medical) =>
                                option.medicalServiceName
                              }
                              noOptionsText={'No options'}
                              onChange={(e: any, value: any[]) => {
                                formikProps.setFieldValue(
                                  'medicalServicesNotApply',
                                  value
                                );
                                !value.length &&
                                  !focusNotMedicalServices &&
                                  setSearchNotMedicalServices(false);
                                setErrorNoMedical(value?.length ? false : true);
                              }}
                              getOptionSelected={(
                                option: Medical,
                                value: Medical
                              ) => {
                                return (
                                  option.medicalServiceId ===
                                  value.medicalServiceId
                                );
                              }}
                              renderOption={(
                                option: Medical,
                                { selected }: any
                              ) => {
                                return (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize='small' />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize='small' />
                                      }
                                      style={{
                                        marginRight: 8,
                                        color: '#1974ff',
                                      }}
                                      checked={selected}
                                      className='checked-role'
                                    />
                                    {option.medicalServiceName}
                                  </React.Fragment>
                                );
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    searchNotMedicalServices
                                      ? 'Search'
                                      : 'Select Medical Services'
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  fullWidth
                                  onFocus={() => handleFocus(5)}
                                  onBlur={() => {
                                    handleBlur(
                                      5,
                                      formikProps.values.medicalServicesNotApply
                                    );
                                  }}
                                />
                              )}
                            />
                            {errorNoMedical && (
                              <p className='error-msg'>
                                This field is required.
                              </p>
                            )}
                          </div>
                        )}
                      </RadioGroup>
                    </div>
                  )}

                  <div className='form-group setting__content--full-row block autocomplete'>
                    <p className='appointment-types'>Species</p>
                    <div className='d-flex input-options'>
                      <Field
                        className={
                          formikProps.values.species.length &&
                          'autocomplete--padding'
                        }
                        name='species'
                        component={Autocomplete}
                        multiple
                        options={listSpecies}
                        // defaultValue={listSpecies}
                        disableCloseOnSelect
                        getOptionLabel={(option: SpeciesSettingType) =>
                          option.name
                        }
                        noOptionsText='No options'
                        onChange={(e: any, value: any[]) => {
                          formikProps.setFieldValue('species', value);
                          !value.length &&
                            !focusSpecies &&
                            setSearchSpecies(false);
                        }}
                        getOptionSelected={(
                          option: SpeciesSettingType,
                          value: SpeciesSettingType
                        ) => option.id === value.id}
                        renderOption={(
                          option: SpeciesSettingType,
                          { selected }: any
                        ) => (
                          <React.Fragment>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize='small' />
                              }
                              checkedIcon={<CheckBoxIcon fontSize='small' />}
                              style={{ marginRight: 8, color: '#1974ff' }}
                              checked={selected}
                              className='checked-role'
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder={
                              searchSpecies ? 'Search' : 'All Species'
                            }
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            fullWidth
                            onFocus={() => handleFocus(3)}
                            onBlur={() =>
                              handleBlur(3, formikProps.values.species)
                            }
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <p>
                      {formikProps.values.approx.length > 0 &&
                        'Fallback Message Attempts'}
                    </p>
                    <FieldArray
                      name='approx'
                      render={({ remove, push }) => (
                        <>
                          {formikProps.values.approx.length > 0 &&
                            formikProps.values.approx.map((item, index) => {
                              return (
                                <div
                                  key={index + 1}
                                  className='fallback-message'
                                >
                                  {formikProps.values.approx.length > 0 && (
                                    <div>
                                      {index == 0 ? (
                                        <p>
                                          {index + 1}. If the message cannot be
                                          sent, then fallback to
                                        </p>
                                      ) : (
                                        <p>
                                          {index == 0
                                            ? 'Message failed to send'
                                            : ''}
                                          {index + 1}. If fallback message
                                          attempt {index} cannot be sent, then
                                          fallback to
                                        </p>
                                      )}
                                      <div className='d-flex fallback-message__attempt'>
                                        <Field
                                          as='select'
                                          name={`approx[${index}].fallbackMessage`}
                                          className='method w-15'
                                          onChange={(event: any) => {
                                            formikProps.handleChange(event);
                                            let methodFallbackTypeValue =
                                              smsTemplateData.items[0]
                                                ?.templateId || ''; // Default message value with message type = sms
                                            if (
                                              event.target.value === TYPE_EMAIL
                                            ) {
                                              methodFallbackTypeValue =
                                                emailTemplateData.items[0]
                                                  ?.templateId || '';
                                            } else if (
                                              event.target.value === TYPE_CALL
                                            ) {
                                              methodFallbackTypeValue =
                                                callTemplateData.items[0]
                                                  ?.templateId || '';
                                            } else if (
                                              event.target.value === TYPE_POSTAL
                                            ) {
                                              methodFallbackTypeValue =
                                                postalTemplateData.items[0]
                                                  ?.templateId || '';
                                            }
                                            formikProps.setFieldValue(
                                              `approx[${index}].methodFallbackType`,
                                              methodFallbackTypeValue
                                            );
                                          }}
                                        >
                                          {rederMessageKinds()}
                                        </Field>
                                        <Field
                                          className='appointment-confirmation-fallbacks'
                                          as='select'
                                          disabled={
                                            !formikProps.values.approx[index]
                                              .fallbackMessage
                                          }
                                          name={`approx[${index}].methodFallbackType`}
                                          onChange={(e: React.ChangeEvent) => {
                                            formikProps.handleChange(e);
                                          }}
                                        >
                                          {renderMethodMessage(
                                            formikProps.values.approx[index]
                                              .fallbackMessage
                                          )}
                                        </Field>
                                        <FontAwesomeIcon
                                          icon={['fal', 'times-circle']}
                                          className='icon-remove-fallback'
                                          onClick={() => remove(index)}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}

                          {/* Add fallback message of right modal */}
                          {formikProps.values.approx.length < 3 ? (
                            <span
                              className='add-fallback'
                              onClick={() => {
                                push({
                                  fallbackMessage: TYPE_SMS,
                                  methodFallbackType:
                                    smsTemplateData.items[0]?.templateId || '',
                                });
                              }}
                            >
                              + Add Fallback Attempt
                            </span>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className='form-group'>
                    <p>
                      Deliver Approximately <b className='require'>*</b>
                    </p>
                    <div className='d-flex delivery-approximate'>
                      <Field
                        as='select'
                        name='timeValue'
                        className='hours w-15'
                      >
                        {renderTimeValue(
                          formikProps.values.timeType,
                          formikProps
                        )}
                      </Field>
                      <Field
                        as='select'
                        name='timeType'
                        className='time flex1125'
                      >
                        {renderTimeType()}
                      </Field>
                      <Field as='select' name='period' className='flex1160'>
                        {renderScheduledKinds()}
                      </Field>
                    </div>
                  </div>

                  <div className='d-flex allow-sending-outside'>
                    <Checkbox
                      checked={formikProps.values.allowSendOutsideDeliveryHours}
                      color='primary'
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      onChange={(event: any) => {
                        formikProps.handleChange(event);
                      }}
                      name='allowSendOutsideDeliveryHours'
                      className='check-schedule'
                    />
                    <span>Allow sending outside delivery hours</span>
                  </div>
                  <div className='save-and-cancel__right-modal'>
                    <Button
                      variant='contained'
                      color='default'
                      className='cancel-button'
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={
                        !(
                          formikProps.isValid &&
                          handleDisableSubmitWithAppointment(
                            formikProps.values
                          ) &&
                          handleDisableSubmitWithMedical(formikProps.values)
                        ) || disabledSubmit
                      }
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </Fragment>
              <ModalDiscard
                isOpenModal={openPopup}
                title='Confirmation'
                handleCloseModal={setOpenPopup}
                handleConfirmModal={handleConfirmDiscard}
              />
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default DetailsFragment;
