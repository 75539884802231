import styled from 'styled-components';

const DrawerContainer = styled.div`
  min-width: ${(props: { minWidth?: number }): string => {
    return props.minWidth ? `${props.minWidth}rem` : '30rem';
  }};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FooterContainer = styled.div`
  background-color: white;
  width: 100%;
  flex-grow: 0;
`;

const HeaderContainer = styled.div`
  background-color: white;
  width: 100%;
  flex-grow: 0;
`;

const ChildrenContainter = styled.div`
  overflow: auto;
  height: 100%;
  flex-grow: 1;
`;

export { DrawerContainer, FooterContainer, HeaderContainer, ChildrenContainter };
