import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const DropFileWrapped = styled.div`
  min-height: 170px;
`;

interface IUploadImage {
  accept?: string;
  maxFiles?: number;
  multiple: boolean;
  onDrop: (files: any) => void;
  files?: any;
  dragTitle?: string;
  dropTitle?: string;
  bottomDropTitle?: Record<string, string>;
  notifyTypeOfFile?: string;
  messageMaxSize?: string;
  maxSize?: number;
}
const UploadImage = (props: IUploadImage) => {
  const {
    accept,
    maxFiles,
    multiple,
    onDrop,
    files,
    dragTitle,
    dropTitle,
    bottomDropTitle,
    maxSize,
    messageMaxSize,
    notifyTypeOfFile = 'File type not accepted, sorry!',
  } = props;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: { 'image': ['.png', '.jpg', '.jpeg']},
    maxFiles,
    multiple,
    onDrop,
    maxSize,
  });

  const isFileTooLarge =
    fileRejections.length > 0 &&
    maxSize &&
    fileRejections[0]?.file?.size > maxSize;

  return (
    <>
      <div>
        {}
        {multiple || (!multiple && !files) ? (
          <DropFileWrapped {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>{dragTitle ? dragTitle : 'Drop the file here ...'}</p>
            ) : (
              <>
                <h5>{dropTitle ? dropTitle : 'Add your file'}</h5>
                <FontAwesomeIcon icon={['fas', 'folder-plus']} />
                <p>
                  {' '}
                  <span>
                    {bottomDropTitle?.addFile
                      ? bottomDropTitle?.addFile
                      : 'Add file'}
                  </span>{' '}
                  {bottomDropTitle?.dropFile
                    ? bottomDropTitle?.dropFile
                    : 'or Drop your file here'}
                </p>
              </>
            )}
            {isDragReject && accept && notifyTypeOfFile && notifyTypeOfFile}
            {isFileTooLarge && !isDragActive && messageMaxSize && (
              <span style={{ color: 'red' }}>{messageMaxSize}</span>
            )}
          </DropFileWrapped>
        ) : null}
        {files && files}
      </div>
    </>
  );
};

export default UploadImage;
