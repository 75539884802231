/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import { cloneDeep, orderBy } from 'lodash';

import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import CheckBoxNotification from './CheckBoxNotification';
import Toast from 'components/Global/Toast';
import UserNotification, { SettingOption } from './UserNotification';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  SummaryItemTypes,
  UserStatusTypes,
  UserKindTypes,
} from 'global/requests/api-route-types';
import { NotificationContext } from 'contexts/Notification';

export type UserListOption = {
  id: string;
  name: string;
};

const NotificationsSetting = (): JSX.Element => {
  const { setSettings } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isDisableButton, setDisableButton] = React.useState<boolean>(true);
  const [userListData, setUserListData] = React.useState<UserListOption[]>([]);
  const [userSelected, setUserSelected] = React.useState<UserListOption[]>([]);

  const [userNotifications, setUserNotifications] = React.useState<
    Record<string, SettingOption>
  >({
    sendEmailNotificationsWhenTextReceived: {
      label: 'Send Email Notification When Text Receive',
      value: false,
    },
    sendEmailNotificationsWhenAppointmentConfirmed: {
      label: 'Send Email Notification When Appointment Confirmed',
      value: false,
    },
    sendEmailNotificationsWhenOabRequested: {
      label: 'Send Email Notification When OAB Requested',
      value: false,
    },
    sendEmailNotificationsWhenNewFormSubmitted: {
      label: 'Send Email Notification When New Form Submitted',
      value: false,
    },
  });
  const [desktopNotifications, setDesktopNotifications] = React.useState<
    Record<string, SettingOption>
  >({
    sendDesktopNotificationsForNewMessages: {
      label: 'Send Desktop Notification for New Message',
      value: false,
    },
    sendDesktopNotificationsForNewFormSubmitted: {
      label: 'Send Desktop Notification for New Form Submitted',
      value: false,
    },
    sendDesktopNotificationsForOabRequested: {
      label: 'Send Desktop Notification for New OAB Requested',
      value: false,
    },
  });

  useEffect(() => {
    sendGetAllUsersRequest();
  }, []);

  useEffect(() => {
    getNotificationSetting();
  }, [userListData]);

  const sendGetAllUsersRequest = async (options?: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
  }) => {
    try {
      const allUserDataResponse =
        await configuredRequests.GET.allUserInfoByCurrentTenant({
          ...options,
          userKinds: ['Customer', 'User', 'Admin'],
        });
      if (allUserDataResponse) {
        const userList = allUserDataResponse.users.map((userEntry) => {
          return {
            id: `${userEntry.id}`,
            name: `${userEntry.name}`,
          };
        });

        // using the 'as' keyword will bypass the type-checking and could cause a crash.
        // const userList = convertData(allUserDataResponse.users as ItemDataUserSetting[]);
        const userListSort = orderBy(userList, ['name'], ['asc']);
        setUserListData(userListSort);
      }
    } catch (err) {}
  };

  const getNotificationSetting = async () => {
    try {
      const notificationDataResponse =
        await configuredRequests.GET.notificationSettings();

      const newUserNotifications = cloneDeep(userNotifications);
      Object.keys(userNotifications).forEach((noti) => {
        newUserNotifications[noti].value =
          // @ts-ignore
          notificationDataResponse.settings[noti];
      });
      setUserNotifications(newUserNotifications);

      const newDesktopNotifications = cloneDeep(desktopNotifications);
      Object.keys(desktopNotifications).forEach((noti) => {
        newDesktopNotifications[noti].value =
          // @ts-ignore
          notificationDataResponse.settings[noti];
      });
      setDesktopNotifications(newDesktopNotifications);

      // setNotificationSetting(notificationDataResponse);
      const userSelectedData = userListData.filter((item) =>
        // @ts-ignore
        notificationDataResponse.settings.notificationUsers.includes(item.id)
      );
      setUserSelected(userSelectedData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // console.log('error getting notification settings', err);
    }
  };

  const submitData = async () => {
    try {
      setDisableButton(true);
      const payload = {
        notificationUsers: userSelected.map((user) => user.id),
      };
      Object.keys(userNotifications).forEach((noti) => {
        // @ts-ignore
        payload[noti] = userNotifications[noti].value;
      });
      Object.keys(desktopNotifications).forEach((noti) => {
        // @ts-ignore
        payload[noti] = desktopNotifications[noti].value;
      });
      const res = await configuredRequests.PUT.updateNotificationSetting(
        'NotificationSettings',
        payload
      );
      if (res) {
        setSettings({
          isLoad: true,
          sendDesktopNotificationsForNewFormSubmitted:
            res.settings.sendDesktopNotificationsForNewFormSubmitted ?? false,
          sendDesktopNotificationsForNewMessages:
            res.settings.sendDesktopNotificationsForNewMessages ?? false,
          sendDesktopNotificationsForOabRequested:
            res.settings.sendDesktopNotificationsForOabRequested ?? false,
        });
        // setNotificationSetting(res);
        Toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      setDisableButton(false);
      Toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    isUser?: boolean
  ) => {
    event.persist();
    setDisableButton(false);

    if (isUser) {
      const newUserNotifications = cloneDeep(userNotifications);
      newUserNotifications[event.target.name].value = event.target.checked;
      setUserNotifications(newUserNotifications);
    } else {
      const newDesktopNotifications = cloneDeep(desktopNotifications);
      newDesktopNotifications[event.target.name].value = event.target.checked;
      setDesktopNotifications(newDesktopNotifications);
    }
  };

  const handleSelectValue = (value: UserListOption[]) => {
    setUserSelected(value);
    setDisableButton(false);
    // const idUserSelected = value.map((item) => item.id);
    // setNotificationSetting((prev) => ({
    //   ...prev,
    //   settings: { ...prev.settings, notificationUsers: idUserSelected },
    // }));
  };

  return (
    <div className='notification'>
      {isLoading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <UserNotification
            settings={userNotifications}
            userListData={userListData}
            userSelected={userSelected}
            onSelectUser={handleSelectValue}
            onSettingChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeCheckbox(e, true)
            }
          />

          <h2 className='practice-title'>Desktop Notification</h2>

          <CheckBoxNotification
            settings={desktopNotifications}
            onSettingChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeCheckbox(e)
            }
          />

          <Button
            variant='contained'
            color='primary'
            onClick={submitData}
            disabled={isDisableButton}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};

export default NotificationsSetting;
