import APIService from '../clientApi';

import {
  GetMobileConversationListPayload,
  IConversationResponse,
  ConversationsPagination,
  MessageInfo,
  MessageOption,
  IMessageDetailResponse,
} from './conversationsTypes';

const API = APIService();

const getMobileConversationList = (
  params: [GetMobileConversationListPayload],
): Promise<IConversationResponse> =>
  API.rpc('', { method: 'messages.getMobileConversationList', params });

const getMobileConversationDetail = (
  params: [string, ConversationsPagination],
): Promise<IMessageDetailResponse> =>
  API.rpc('', { method: 'messages.getMobileConversation', params });

const sendMessage = (params: [MessageInfo, MessageOption]): Promise<IConversationResponse> =>
  API.rpc('', { method: 'messages.create', params });

export default { getMobileConversationList, getMobileConversationDetail, sendMessage };
