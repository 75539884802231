import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ColoredIcon = styled(CheckCircleIcon)`
  fill: #31CC97;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.3rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export { StatusContainer, ColoredIcon };
