import React from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import {
  LeftChevronButton,
  RightChevronButton,
  StyledEventAvailable,
  TextWithOutline,
  Main,
} from './styled-components';

export interface WeekPickerProps {
  week: moment.Moment;
  updateWeek: (args0: moment.Moment) => unknown;
}

const WeekPicker = (props: WeekPickerProps): React.ReactElement => {
  const { week, updateWeek } = props;
  const backOneWeek = (): void => {
    updateWeek(moment(week.subtract(1, 'week')));
  };

  const forwardOneWeek = (): void => {
    updateWeek(moment(week.add(1, 'week')));
  };

  return (
    <Main>
      <Grid container spacing={1}>
        <LeftChevronButton onClick={backOneWeek} data-testid='left-chevron'>
          <ChevronLeftIcon />
        </LeftChevronButton>
        <StyledEventAvailable />
        <TextWithOutline>
          {week.format('MMM')} {week.startOf('week').format('D')} -&nbsp;
          {week.endOf('week').format('D')}, {week.format('YYYY')}
        </TextWithOutline>
        <RightChevronButton onClick={forwardOneWeek} data-testid='right-chevron'>
          <ChevronRightIcon />
        </RightChevronButton>
      </Grid>
    </Main>
  );
};

WeekPicker.defaultProps = {
  week: moment(),
};
export default WeekPicker;
