import styled from 'styled-components';

const MetricsContainer = styled.div`
  display: flex;
  margin: 1rem 0;
`;
const BarContainer = styled.div`
  margin: 1rem 0;
`;

const MetricContainer = styled.div`
  flex-basis: 33%;
`;

const Score = styled.p`
  color: #4c506c;
  font-family: ${({ theme }): string => theme.typography.fontFamily};
  font-size: 2.1rem;
  font-weight: 600;
  margin: 1.5rem 0 0.3rem 0;
`;

const ScoreTitle = styled.p`
  color: ${({ theme }): string => theme.palette.text.secondary};
  font-family: ${({ theme }): string => theme.typography.fontFamily};
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.3rem 0 1rem 0;
`;

export { MetricContainer, MetricsContainer, BarContainer, Score, ScoreTitle };
