import styled from 'styled-components';
import React from 'react';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';

const border = '1px solid #e3ebf6';
const TimeTitleTableCell = styled(TableCell)`
  width: 3rem;
  font-weight: 600;
  text-align: 'center';
  border: ${border};
  border-top: none;
  border-left: none;
`;

const TitleTableCell = styled(TableCell)`
  min-width: 200px;
  font-weight: 600;
  text-align: 'center';
  border: ${border};
  border-top: none;
  border-left: none;
`;

const StyledTable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0;
  border: ${border};
  border-radius: 6px;
`;

interface DefaultTableCellProps extends TableCellProps {
  bottom: boolean;
}

const DefaultTableCell = styled(
  ({ bottom, ...otherProps }: DefaultTableCellProps): React.ReactElement => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableCell {...otherProps} />
  ),
)`
  border-bottom: ${(props): string => (props.bottom ? '1px solid #e3ebf6' : 'none')};
  border-right: 1px solid #e3ebf6;
  padding: 6px;
`;

const StartingTimeTableCell = styled(TableCell)`
  padding: 2px;
  border-right: ${border};
`;
const HourTimeTableCell = styled(TableCell)`
  padding: 2px;
  border-right: ${border};
  text-align: center;
  vertical-align: top;
`;

export {
  TimeTitleTableCell,
  StyledTable,
  TitleTableCell,
  DefaultTableCell,
  StartingTimeTableCell,
  HourTimeTableCell,
};
