import { IValidationResult } from 'components/Legwork/validation/types';
import { IAddressData } from './AddressInput';

const address1Required = (input: IAddressData): IValidationResult => {
  if (input.address1.length < 4) {
    return { error: true, messageFragment: 'Address 1: Please enter a valid address' };
  }

  return { error: false };
};

const cityRequired = (input: IAddressData): IValidationResult => {
  if (input.city.length === 0) {
    return { error: true, messageFragment: 'City: Please enter a city' };
  }
  return { error: false };
};

const stateRequired = (input: IAddressData): IValidationResult => {
  if (input.state === 'default' || input.state.length === 0) {
    return { error: true, messageFragment: 'State: Please select a State or Province' };
  }
  return { error: false };
};

const zipCodeRequired = (input: IAddressData): IValidationResult => {
  if (input.zipCode.length === 5 || input.zipCode.length === 10 || input.zipCode.length === 7) {
    return { error: false };
  }

  return {
    error: true,
    messageFragment:
      'Zip Code: Please enter a valid zip or postal code XXXXX, XXXXX-XXXX, or XXX XXX',
  };
};

// from https://howtodoinjava.com/regex/canada-postal-code-validation/
const CA_ZIP_REGEX = new RegExp('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$');

const zipCodeNumeric = (input: IAddressData): IValidationResult => {
  if (input.zipCode.length === 5 || input.zipCode.length === 10) {
    if (input.zipCode.length === 5) {
      if (parseInt(input.zipCode, 10) > 9999) {
        return { error: false };
      }
      return {
        error: true,
        messageFragment: 'Zip Code: Please enter a 5 digit zip code',
      };
    }
    if (input.zipCode.length === 10) {
      if (
        parseInt(input.zipCode.substring(0, 5), 10) > 9999 &&
        parseInt(input.zipCode.substring(7, 10), 10) < 10000
      ) {
        return { error: false };
      }
      return {
        error: true,
        messageFragment: 'Zip Code: Please enter a 10 digit zip code XXXXX-XXXX',
      };
    }
  }

  if (input.zipCode.length === 7) {
    if (!CA_ZIP_REGEX.test(input.zipCode)) {
      return {
        error: true,
        messageFragment: 'Zip Code: Please enter a valid zip or postal code',
      };
    }
  }
  return { error: false };
};

export { address1Required, cityRequired, stateRequired, zipCodeRequired, zipCodeNumeric };

export const requiredValidators = [address1Required, cityRequired, stateRequired, zipCodeRequired];

export const notRequiredValidators = [zipCodeNumeric];

export default [...requiredValidators, ...notRequiredValidators];
