import React from 'react';
import classNames from 'classnames';
import { Single } from './properties-config';
import { FormFieldBase, getFieldProperties, getFieldPropertyValues } from '.';
import { FormFieldInputRadio } from './form-field-inputs';
import ValidationMessage from './validation-message';

export const FormFieldSingle = ({
  field,
  className,
  commonProps,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  // import of 'utils' breaks unit tests so for now, just including
  // local version of check-for-empty logic
  const isEmpty = val => {
    const isObject = typeof val === 'object';
    const isArray = isObject && Array.isArray(val);
    let result = false;

    result =
      val === undefined ||
      val === null ||
      val === '' ||
      (isObject && Object.keys(val).length === 0) ||
      (isArray && val.length === 0);

    return result;
  };

  // if value isn't found in the list of options, then this is an "other" value
  const valueIsOther = (options, value) =>
    !options.find(option => option === value) && !isEmpty(value);

  const handleChange = (e, group, label) => {
    const value = e.target.type === 'radio' ? label : e.target.value;

    // define custom event object
    const event = {
      target: {
        id: group,
        value,
        el: e.target,
      },
    };
    onBlur({
      target: {
        id: group,
        value,
      },
    });

    onChange(event);
  };

  const localProps = new Map(Single);
  const propertyNames = ['options', 'displayOther', 'other', 'displayAsColumns', 'numColumns'];
  const defaultValues = propertyNames.map(propName => ({
    [`${propName}`]: localProps.get(propName).defaultValue,
  }));
  const properties = getFieldProperties(field, 'specific');
  const { options, displayOther, other, displayAsColumns, numColumns } = getFieldPropertyValues(
    properties.specific,
    propertyNames,
    defaultValues,
  );
  const classes = classNames({
    [className]: true,
    single: true,
    'control-group error': validationMessage,
  });

  // if this field is being rendered to a published form,
  // we need to add a group prop to the checkboxes to tie
  // back to the parent field
  const group = isRenderedField ? field.uuid : undefined;
  const isOther = valueIsOther(options, value);
  const otherValue = {};
  if (isOther) otherValue.value = value;

  return (
    <div className={classes}>
      <div className='form-field-label primary'>{`${commonProps.label} ${
        commonProps.makeRequired ? '*' : ''
        }`}</div>
      <div className='form-field-input radio'>
        {options.map((option, index) => {
          // render radio input for each option
          return (
            <FormFieldInputRadio
              key={index}
              label={option}
              displayAsColumns={displayAsColumns}
              numColumns={numColumns}
              group={group}
              isRenderedField={isRenderedField}
              onChange={handleChange}
              checked={value === option || isOther}
              disabled={readOnly}
            />
          );
        })}
        {displayOther && (
          // render an 'Other' option if selected
          <FormFieldInputRadio
            className='other'
            isOther
            label={other}
            displayAsColumns={displayAsColumns}
            numColumns={numColumns}
            group={group}
            isRenderedField={isRenderedField}
            onChange={handleChange}
            disabled={readOnly}
            {...otherValue}
          />
        )}
        {commonProps.sublabel && (
          <div className='form-field-label secondary'>{commonProps.sublabel}</div>
        )}
        <ValidationMessage message={validationMessage} />
      </div>
    </div>
  );
};

FormFieldSingle.defaultProps = {
  className: '',
  commonProps: {},
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldSingle);
