import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import UploadImage from 'components/Global/DropFiles/DropFile';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import toast from 'components/Global/Toast';
import { ErrorMessage, FastField, Field, Formik } from 'formik';
import { dataStateOfUS } from 'global/localStorage/dataStateOfUS';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TenantResposne } from 'global/requests/ResponseTypes';
import { useDecodeToken } from 'hooks/useDecodeToken';
import { get, isEmpty, isEqual } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { loginWithAdmin } from 'utils/check';
import { domainPattern, linkHttp, phonePattern } from 'utils/regex';
import * as Yup from 'yup';

const PracticeLogoWrapped = styled.div`
  position: relative;
  max-width: 200px;
  max-height: 60px;
  text-align: center;
  img {
    max-width: 200px;
    max-height: 60px;
    cursor: default;
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
  errorDomainFormat: `The domain must only contain a-z, 0-9 and '-'.`,
};

const practiceValidationSchema = (isAdmin: boolean) =>
  isAdmin
    ? Yup.object().shape({
        tenantName: Yup.string()
          .max(255, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        status: Yup.string().required(ErrorMessageTypes.required),
        micrositeName: Yup.string()
          .required(ErrorMessageTypes.required)
          .matches(domainPattern, ErrorMessageTypes.errorDomainFormat),
        addresses: Yup.array().of(
          Yup.object().shape({
            address: Yup.object().shape({
              addressLine1: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
              addressLine2: Yup.string().max(
                50,
                ErrorMessageTypes.incorrectFormat
              ),
              state: Yup.object().required(ErrorMessageTypes.required),
              city: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
              postalCode: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
            }),
          })
        ),
        name: Yup.array().of(
          Yup.object().shape({
            givenName: Yup.string()
              .max(15, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
            lastName: Yup.string()
              .max(15, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
          })
        ),
        emailAddress: Yup.string()
          .email('Incorrect email format.')
          .max(50, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        practiceEmailAddress: Yup.string()
          .email('Incorrect email format.')
          .max(50, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        phoneNumbers: Yup.array().of(
          Yup.object().shape({
            number: Yup.string()
              .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
            extension: Yup.number().typeError(
              ErrorMessageTypes.incorrectFormat
            ),
            kind: Yup.string(),
          })
        ),
        practicePhoneNumber: Yup.array().of(
          Yup.object().shape({
            number: Yup.string()
              .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
            extension: Yup.number().typeError(
              ErrorMessageTypes.incorrectFormat
            ),
            kind: Yup.string(),
          })
        ),
        webUrl: Yup.string().matches(
          linkHttp,
          'Not a valid URL format, protocol required (http://, https://)'
        ),
        accountReviewLink: Yup.string().matches(
          linkHttp,
          'Not a valid URL format, protocol required (http://, https://)'
        ),
        useURLInFooter: Yup.boolean(),
        emailReplyToAddress: Yup.string()
          .email('Incorrect email format.')
          .max(50, ErrorMessageTypes.incorrectFormat),
      })
    : Yup.object().shape({
        tenantName: Yup.string()
          .max(255, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        status: Yup.string().required(ErrorMessageTypes.required),
        addresses: Yup.array().of(
          Yup.object().shape({
            address: Yup.object().shape({
              addressLine1: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
              addressLine2: Yup.string().max(
                50,
                ErrorMessageTypes.incorrectFormat
              ),
              state: Yup.object().required(ErrorMessageTypes.required),
              city: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
              postalCode: Yup.string()
                .max(50, ErrorMessageTypes.incorrectFormat)
                .required(ErrorMessageTypes.required),
            }),
          })
        ),
        name: Yup.array().of(
          Yup.object().shape({
            givenName: Yup.string()
              .max(15, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
            lastName: Yup.string()
              .max(15, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
          })
        ),
        emailAddress: Yup.string()
          .email('Incorrect email format.')
          .max(50, ErrorMessageTypes.incorrectFormat)
          .required(ErrorMessageTypes.required),
        phoneNumbers: Yup.array().of(
          Yup.object().shape({
            number: Yup.string()
              .matches(phonePattern, ErrorMessageTypes.incorrectFormat)
              .required(ErrorMessageTypes.required),
            extension: Yup.number().typeError(
              ErrorMessageTypes.incorrectFormat
            ),
            kind: Yup.string(),
          })
        ),
        webUrl: Yup.string().matches(
          linkHttp,
          'Not a valid URL format, protocol required (http://, https://)'
        ),
        accountReviewLink: Yup.string().matches(
          linkHttp,
          'Not a valid URL format, protocol required (http://, https://)'
        ),
        useURLInFooter: Yup.boolean(),
        emailReplyToAddress: Yup.string()
          .email('Incorrect email format.')
          .max(50, ErrorMessageTypes.incorrectFormat),
      });

const PracticeCompany = (props: any) => {
  const { roleUser } = useDecodeToken();
  const { practiceInfo } = props;
  const [images, setImages] = useState([]);
  const [micrositeNameError, setMicrositeNameError] =
    React.useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [isAdmin] = useState(loginWithAdmin());
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (!isEmpty(practiceInfo)) {
      // @ts-ignore
      practiceInfo?.logoUrl && setImages([{ preview: practiceInfo?.logoUrl }]);
    }
  }, [practiceInfo]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      images.forEach((element: any) => URL.revokeObjectURL(element.preview));
    },
    [images]
  );

  const practiceInfoData = (practiceData: TenantResposne) => {
    return {
      tenantName: get(practiceData, 'tenantName', ''),
      status: get(practiceData, 'status', 'Inactive'),
      micrositeName: get(
        practiceData,
        'micrositeName',
        practiceData?.tenantName?.replace(/ /g, '-')
      ),
      addresses: [
        {
          address: {
            addressLine1: get(
              practiceData,
              'addresses[0].address.addressLine1',
              ''
            ),
            addressLine2: get(
              practiceData,
              'addresses[0].address.addressLine2',
              ''
            ),
            city: get(practiceData, 'addresses[0].address.city', ''),
            state: get(practiceData, 'addresses[0].address.state', '')
              ? dataStateOfUS.filter((item) => {
                  return (
                    item.value ===
                    get(practiceData, 'addresses[0].address.state', '')
                  );
                })[0]
              : dataStateOfUS[0],
            postalCode: get(
              practiceData,
              'addresses[0].address.postalCode',
              ''
            ),
          },
        },
      ],
      name: [
        {
          givenName: get(practiceData, 'name[0].givenName', ''),
          lastName: get(practiceData, 'name[0].lastName', ''),
        },
      ],
      emailAddress: get(practiceData, 'emailAddress', ''),
      phoneNumbers: [
        {
          number: get(practiceData, 'phoneNumbers[0].number', ''),
          extension: get(practiceData, 'phoneNumbers[0].extension', ''),
          kind: get(practiceData, 'phoneNumbers[0].kind', ''),
          isPrimary: get(practiceData, 'phoneNumbers[0].isPrimary', true),
        },
      ],
      practicePhoneNumber: [
        {
          number: get(practiceData, 'practicePhoneNumber[0].number', ''),
          extension: get(practiceData, 'practicePhoneNumber[0].extension', ''),
          kind: get(practiceData, 'practicePhoneNumber[0].kind', 'cell'),
          isPrimary: get(
            practiceData,
            'practicePhoneNumber[0].isPrimary',
            true
          ),
        },
      ],
      clientPortal: get(practiceData, 'useClientPortal', false),
      estimates: get(practiceData, 'useEstimates', false),
      usePhoneNumberValidation: get(
        practiceData,
        'usePhoneNumberValidation',
        false
      ),
      practiceEmailAddress: get(practiceData, 'practiceEmailAddress', ''),
      webUrl: get(practiceData, 'webUrl', ''),
      accountReviewLink: get(practiceData, 'accountReviewLink', ''),
      useURLInFooter: get(practiceData, 'useURLInFooter', false),
      emailReplyToAddress: get(practiceData, 'emailReplyToAddress', ''),
      logoUrl: get(practiceData, 'logoUrl', ''),
      customerSource: get(practiceData, 'customerSource', 'Patterson e-Pet'),
      vetHeroStatus: get(practiceData, 'vetHeroStatus', 'Patterson e-Pet'),
    };
  };

  const updateMicroSiteNameSessionStorage = (value: any) => {
    let prevData = JSON.parse(
      //@ts-ignore
      sessionStorage.getItem('tenantInfo--full_response')
    );
    Object.keys(value).forEach(function (val, key) {
      prevData[val] = value[val];
    });
    sessionStorage.setItem(
      'tenantInfo--full_response',
      JSON.stringify(prevData)
    );
  };

  const [formInitData, setFormInitData] = useState<any>(
    practiceInfoData(practiceInfo)
  );
  const submitData = async (values: any) => {
    try {
      setDisabledButton(true);
      const submitData = {
        tenantName: values.tenantName,
        status: values.status,
        micrositeName: !roleUser.includes('System Admin')
          ? values.micrositeName
          : undefined,
        emailAddress: values.emailAddress,
        practiceEmailAddress: values.practiceEmailAddress,
        name: [
          {
            givenName: values.name[0].givenName,
            lastName: values.name[0].lastName,
          },
        ],
        addresses: [
          {
            address: {
              addressLine1: values.addresses[0].address.addressLine1,
              addressLine2: values.addresses[0].address.addressLine2,
              city: values.addresses[0].address.city,
              state: values.addresses[0].address.state.value,
              postalCode: values.addresses[0].address.postalCode,
            },
          },
        ],
        phoneNumbers: [
          {
            number: values.practicePhoneNumber[0].number,
            extension: values.practicePhoneNumber[0].extension,
            kind: values.practicePhoneNumber[0].kind,
            isPrimary: true,
            isPracticePhone: true,
          },
          {
            number: values.phoneNumbers[0].number,
            extension: values.phoneNumbers[0].extension,
            kind: values.phoneNumbers[0].kind,
            isPrimary: true,
            isPracticePhone: false,
          },
        ],
        useClientPortal: values.clientPortal,
        useEstimates: values.estimates,
        usePhoneNumberValidation: values.usePhoneNumberValidation,
        webUrl: values.webUrl,
        accountReviewLink: values.accountReviewLink,
        useURLInFooter: values.useURLInFooter,
        emailReplyToAddress: values.emailReplyToAddress,
        logoUrl: values.logoUrl,
        customerSource: values.customerSource,
        vetHeroStatus: values.vetHeroStatus,
      };
      const res = await configuredRequests.PATCH.updatePractice(
        get(practiceInfo, 'tenantGroupId', ''),
        get(practiceInfo, 'tenantId', ''),
        //@ts-ignore
        submitData
      );
      if (res) {
        if (isAdmin && values.usePhoneNumberValidation) {
          configuredRequests.PATCH.updatePhoneNumber({
            tenantId: get(practiceInfo, 'tenantId', ''),
          });
        }
        updateMicroSiteNameSessionStorage({ micrositeName: res.micrositeName });
        setFormInitData(practiceInfoData(res));
        window.localStorage.setItem('isGroupEdited', 'false');
        window.sessionStorage.setItem('isFormChange', 'false');
        toast.success('Your data has been saved successfully');
        setDisabledButton(true);
      }
    } catch (err) {
      toast.error('Your data has not been saved yet. Please try again.');
      setDisabledButton(false);
      if (
        err.response?.data?.message ===
        'Duplicate PracticeName or MicrositeName'
      ) {
        setNameError(err.response.data.details.practiceName);
        setMicrositeNameError(err.response.data.details.micrositeName);
      }
    }
  };

  const validationSchema = useMemo(() => {
    return practiceValidationSchema(roleUser.includes('Admin'));
  }, [roleUser]);

  return (
    <Formik
      initialValues={formInitData}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const {
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          setFieldTouched,
          errors,
        } = formikProps;
        
        const tailoredHandleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
          // call handleChange of formik
          setDisabledButton(false)
          handleChange(e)
        }

        sessionStorage.setItem(
          'isFormChange',
          String(!isEqual(values, formInitData))
        );

        return (
          <div className='react-modal--content general-setting'>
            <div className='communication-container setting-scroll--custom'>
              <div className='communication-container__table'>
                <div className='edit-appointment roleModal '>
                  <Fragment>
                    <form
                      onSubmit={handleSubmit}
                      className='col-12 form-schedule react-modal--settings__content'
                    >
                      <div className='setting__content--full-row '>
                        <h2 className='practice-title'>Practice Information</h2>
                      </div>
                      <div className='d-flex-wrap'>
                        <div className='form-input-settings setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            Practice Name <b className='require'>*</b>
                          </span>
                          <div className='first-name'>
                            <FastField
                              name='tenantName'
                              maxLength={255}
                              required
                              placeholder='Practice Name'
                              className='setting__search-form'
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // handleChange(e);
                                tailoredHandleChange(e); 
                                setNameError('');
                              }}
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e)
                                setFieldValue(
                                  'tenantName',
                                  e.currentTarget.value.trim()
                                );
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='tenantName' />
                              {!errors.tenantName && nameError}
                            </p>
                          </div>
                        </div>
                        <div className='form-input-settings setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            Status <b className='require'>*</b>
                          </span>
                          <Field
                            className='filter-form'
                            as='select'
                            name={`status`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // handleChange(e);
                              tailoredHandleChange(e); 
                            }}
                          >
                            <option value='Active'>Active</option>
                            <option value='Inactive'>Inactive</option>
                          </Field>
                          <p className='error-msg'>
                            <ErrorMessage name='status' />
                          </p>
                        </div>
                      </div>

                      {!roleUser.includes('System Admin') && (
                        <div
                          className='setting__content--full-row block'
                          style={{ margin: '0px 10px 30px' }}
                        >
                          <span className='title-input-form'>
                            Microsite Domain <b className='require'>*</b>
                          </span>
                          <FastField
                            name='micrositeName'
                            maxLength={255}
                            placeholder='Microsite Domain'
                            className='setting__search-form'
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // handleChange(e);
                              tailoredHandleChange(e); 
                              setNameError('');
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e)
                              setMicrositeNameError('');
                              setFieldValue(
                                'micrositeName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='micrositeName' />
                            {!errors.micrositeName && micrositeNameError}
                          </p>
                        </div>
                      )}

                      <div className='setting__content--full-row block'>
                        <span className='title-input-form'>
                          Address <b className='require'>*</b>
                        </span>
                        <FastField
                          name='addresses[0].address.addressLine1'
                          maxLength={50}
                          className='setting__search-form'
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            setFieldValue(
                              'addresses[0].address.addressLine1',
                              e.currentTarget.value.trim()
                            );
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // handleChange(e);
                            tailoredHandleChange(e); 
                          }}
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='addresses[0].address.addressLine1' />
                        </p>
                        <span className='title-input-form'>
                          Apt / Suite / Other
                        </span>
                        <FastField
                          name='addresses[0].address.addressLine2'
                          maxLength={50}
                          className='setting__search-form '
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            setFieldValue(
                              'addresses[0].address.addressLine2',
                              e.currentTarget.value.trim()
                            );
                          }}

                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // handleChange(e);
                            tailoredHandleChange(e); 
                          }}
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='addresses[0].address.addressLine2' />
                        </p>
                        <div className='d-flex-wrap address-2'>
                          <div className='w-60'>
                            <span className='title-input-form'>
                              City <b className='require'>*</b>
                            </span>
                            <FastField
                              name='addresses[0].address.city'
                              maxLength={50}
                              className='field-input'
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'addresses[0].address.city',
                                  e.currentTarget.value.trim()
                                );
                              }}

                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // handleChange(e);
                                tailoredHandleChange(e); 
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='addresses[0].address.city' />
                            </p>
                          </div>

                          <div className='full-width margin-h-5 w-25'>
                            <span className='title-input-form'>
                              State / Province <b className='require'>*</b>
                            </span>
                            <FastField
                              classNamePrefix='search-city'
                              className='option-city'
                              component={Select}
                              name='addresses[0].address.state'
                              defaultValue={values.addresses[0].address.state}
                              options={dataStateOfUS}
                              onBlur={() => {
                                setFieldTouched(
                                  'addresses[0].address.state',
                                  true
                                );
                              }}
                              onChange={(e: any) => {
                                setFieldValue('addresses[0].address.state', e);
                                setDisabledButton(false)
                              }}
                              maxMenuHeight={150}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='addresses[0].address.state' />
                            </p>
                          </div>

                          <div>
                            <span className='title-input-form'>
                              Zip / Postal Code <b className='require'>*</b>
                            </span>
                            <FastField
                              name='addresses[0].address.postalCode'
                              maxLength={50}
                              className='field-input'
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'addresses[0].address.postalCode',
                                  e.currentTarget.value.trim()
                                );
                              }}

                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // handleChange(e);
                                tailoredHandleChange(e); 
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='addresses[0].address.postalCode' />
                            </p>
                          </div>
                        </div>
                      </div>
                      {roleUser.includes('Admin') && (
                        <>
                          <div className='d-flex-wrap'>
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Practice Email Address{' '}
                                <b className='require'>*</b>
                              </span>
                              <FastField
                                name='practiceEmailAddress'
                                maxLength={50}
                                placeholder='Practice Email Address'
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'practiceEmailAddress',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  // handleChange(e);
                                  tailoredHandleChange(e); 
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='practiceEmailAddress' />
                              </p>
                            </div>
                            <div className='setting__content--full-row block width-50'>
                              <span className='title-input-form'>
                                Practice Phone Number{' '}
                                <b className='require'>*</b>
                              </span>
                              <div className='d-flex'>
                                <div className='w-50 h-60'>
                                  <div className='margin-r-5'>
                                    <FastField
                                      name={`practicePhoneNumber[0].number`}
                                      maxLength={50}
                                      placeholder='Practice Phone Number'
                                      required
                                      className='setting__search-form width-100'
                                      onBlur={(
                                        e: React.FocusEvent<HTMLInputElement>
                                      ) => {
                                        handleBlur(e);
                                        setFieldValue(
                                          `practicePhoneNumber[0].number`,
                                          e.currentTarget.value.trim()
                                        );
                                      }}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // handleChange(e);
                                        tailoredHandleChange(e); 
                                      }}
                                    />
                                  </div>
                                  <p className='error-msg'>
                                    <ErrorMessage
                                      className='error-msg'
                                      name={`practicePhoneNumber[0].number`}
                                    />
                                  </p>
                                </div>
                                <div className='w-25 h-60'>
                                  <div className='margin-r-5'>
                                    <FastField
                                      name={`practicePhoneNumber[0].extension`}
                                      maxLength={50}
                                      placeholder='Ext.'
                                      className='setting__search-form width-50'
                                      onBlur={(
                                        e: React.FocusEvent<HTMLInputElement>
                                      ) => {
                                        handleBlur(e);
                                        setFieldValue(
                                          `practicePhoneNumber[0].extension`,
                                          e.currentTarget.value.trim()
                                        );
                                      }}

                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        // handleChange(e);
                                        tailoredHandleChange(e); 
                                      }}
                                    />
                                  </div>
                                  <p className='error-msg'>
                                    <ErrorMessage
                                      className='error-msg'
                                      name={`practicePhoneNumber[0].extension`}
                                    />
                                  </p>
                                </div>
                                <div className='w-25 search-filter__global h-60'>
                                  <Field
                                    className='filter-form'
                                    as='select'
                                    name={`practicePhoneNumber[0].kind`}
                                  >
                                    <option value='cell'>Mobile Phone</option>
                                    <option value='landline'>Home Phone</option>
                                    <option value='business'>
                                      Office Phone
                                    </option>
                                    <option value='other'>Other</option>
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex-wrap setting__url-web'>
                            <div className='setting__content--full-row w-50'>
                              <span className='title-input-form'>
                                {' '}
                                Website URL{' '}
                              </span>
                              <FastField
                                name='webUrl'
                                className='setting__search-form'
                                maxLength={50}
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'webUrl',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  // handleChange(e);
                                  tailoredHandleChange(e); 
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='webUrl' />
                              </p>
                            </div>
                          </div>

                          <div className='d-flex-wrap setting__account-review-link'>
                            <div className='setting__content--full-row w-50'>
                              <span className='title-input-form'>
                                {' '}
                                Review Link{' '}
                              </span>
                              <FastField
                                name='accountReviewLink'
                                className='setting__search-form'
                                maxLength={50}
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'accountReviewLink',
                                    e.currentTarget.value.trim()
                                  );
                                }}

                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  // handleChange(e);
                                  tailoredHandleChange(e); 
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='accountReviewLink' />
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {/*roleUser.includes('System Admin') && (
                        <div className='setting__content--full-row block'>
                          <h2 className='practice-title'>Customer Source</h2>
                          <div className='w-25 search-filter__global h-60'>
                            <FastField
                              className='filter-form'
                              as='select'
                              name={`customerSource`}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // handleChange(e);
                                tailoredHandleChange(e); 
                              }}
                            >
                              <option value='Patterson e-Pet'>
                                Patterson e-Pet
                              </option>
                              <option value='Patterson new'>
                                Patterson new
                              </option>
                              <option value='VH - new'>VH - new</option>
                            </FastField>
                          </div>
                        </div>
                      )*/}

                      <div className='setting__content--full-row block'>
                        <h2 className='practice-title'>VetHero Status</h2>
                        <div className='w-25 search-filter__global h-60'>
                          <FastField
                            className='filter-form'
                            as='select'
                            name={`vetHeroStatus`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // handleChange(e);
                              tailoredHandleChange(e);
                            }}
                          >
                            <option value='Onboarding'>
                              In Onboarding
                            </option>
                            <option value='Live'>
                              Fully Active/Live
                            </option>
                            <option value='Canceled'>
                              Canceled
                            </option>
                            <option value='OnboardingIgnored'>
                              In Onboarding - Pending Issues
                            </option>
                            <option value='LiveIgnored'>
                              Live - Pending Issues
                            </option>
                            <option value='Test'>
                              Test Account
                            </option>
                          </FastField>
                        </div>
                      </div>

                      <div className='setting__content--full-row '>
                        <h2 className='practice-title'>Practice Contact</h2>
                      </div>

                      <div className='d-flex-wrap'>
                        <div className='setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            First Name <b className='require'>*</b>
                          </span>
                          <div className=' '>
                            <FastField
                              name='name[0].givenName'
                              maxLength={15}
                              required
                              placeholder='First Name'
                              className='setting__search-form'
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'name[0].givenName',
                                  e.currentTarget.value.trim()
                                );
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                // handleChange(e);
                                tailoredHandleChange(e); 
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='name[0].givenName' />
                            </p>
                          </div>
                        </div>
                        <div className='form-input-settings setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            Last Name <b className='require'>*</b>
                          </span>
                          <FastField
                            name='name[0].lastName'
                            maxLength={15}
                            required
                            placeholder='Last Name'
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'name[0].lastName',
                                e.currentTarget.value.trim()
                              );
                            }}

                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // handleChange(e);
                              tailoredHandleChange(e); 
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='name[0].lastName' />
                          </p>
                        </div>
                      </div>
                      <div className='d-flex-wrap'>
                        <div className='setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            {' '}
                            Email Address <b className='require'>*</b>
                          </span>
                          <FastField
                            name='emailAddress'
                            maxLength={50}
                            placeholder='Email Address'
                            required
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'emailAddress',
                                e.currentTarget.value.trim()
                              );
                            }}

                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // handleChange(e);
                              tailoredHandleChange(e); 
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='emailAddress' />
                          </p>
                        </div>
                        <div className='setting__content--full-row block width-50'>
                          <span className='title-input-form'>
                            {' '}
                            Phone Number <b className='require'>*</b>
                          </span>
                          <div className='d-flex'>
                            <div className='w-50 h-60'>
                              <div className='margin-r-5'>
                                <FastField
                                  name={`phoneNumbers[0].number`}
                                  maxLength={50}
                                  placeholder='Phone Number'
                                  required
                                  className='setting__search-form width-100'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e); 
                                    setFieldValue(
                                      `phoneNumbers[0].number`,
                                      e.currentTarget.value.trim()
                                    );
                                  }}

                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    // handleChange(e);
                                    tailoredHandleChange(e); 
                                  }}
                                />
                              </div>
                              <p className='error-msg'>
                                <ErrorMessage
                                  className='error-msg'
                                  name={`phoneNumbers[0].number`}
                                />
                              </p>
                            </div>
                            <div className='w-25 h-60'>
                              <div className='margin-r-5'>
                                <FastField
                                  name={`phoneNumbers[0].extension`}
                                  maxLength={50}
                                  placeholder='Ext.'
                                  className='setting__search-form width-50'
                                  onBlur={(
                                    e: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    handleBlur(e);
                                    setFieldValue(
                                      `phoneNumbers[0].extension`,
                                      e.currentTarget.value.trim()
                                    );
                                  }}

                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    // handleChange(e);
                                    tailoredHandleChange(e); 
                                  }}
                                />
                              </div>
                              <p className='error-msg'>
                                <ErrorMessage
                                  className='error-msg'
                                  name={`phoneNumbers[0].extension`}
                                />
                              </p>
                            </div>
                            <div className='w-25 search-filter__global h-60'>
                              <Field
                                className='filter-form'
                                as='select'
                                name={`phoneNumbers[0].kind`}
                              >
                                <option value='cell'>Mobile Phone</option>
                                <option value='landline'>Home Phone</option>
                                <option value='business'>Office Phone</option>
                                <option value='other'>Other</option>
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isAdmin && (
                        <div className='setting__content--full-row block'>
                          <h2 className='practice-title'>Practice Add-Ons</h2>

                          <div>
                            <AntSwitch
                              label='Client Portal'
                              checked={values.clientPortal}
                              onChange={() => {
                                setDisabledButton(false)
                                setFieldValue(
                                  'clientPortal',
                                  !values.clientPortal
                                )
                              }
                              }
                            />
                          </div>

                          <div className='mt-5'>
                            <AntSwitch
                              label='Estimates'
                              checked={values.estimates}
                              onChange={() => {
                                setDisabledButton(false)
                                setFieldValue(
                                  'estimates',
                                  !values.estimates
                                )
                              }
                              }
                            />
                          </div>

                          <div className='mt-5'>
                            <AntSwitch
                              label='Use Phone Number Validation'
                              checked={values.usePhoneNumberValidation}
                              onChange={() =>{
                                setDisabledButton(false)
                                setFieldValue(
                                  'usePhoneNumberValidation',
                                  !values.usePhoneNumberValidation
                                )
                              }
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className='setting__content--full-row block'>
                        <h2 className='practice-title'>Practice Logo</h2>
                        <span className='title-input-form'>File</span>
                        {images.length > 0 ? (
                          images.map((img: any) => (
                            <PracticeLogoWrapped
                              key={`${img.name}-${Math.random()}`}
                            >
                              <img src={img.preview} alt={`img-${img.name}`} />
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                onClick={() => {
                                  setImages([]);
                                  setFieldValue('logoUrl', '');
                                }}
                              />
                            </PracticeLogoWrapped>
                          ))
                        ) : (
                          <>
                            <UploadImage
                              accept='image/png, image/jpg, image/jpeg'
                              maxFiles={1}
                              multiple={false}
                              onDrop={async (files: any) => {
                                try {
                                  setDisabledButton(false)
                                  const formData = new FormData();
                                  formData.append('attachment', files[0]);
                                  const response =
                                    await configuredRequests.POST.uploadImage(
                                      formData
                                    );
                                  if (response) {
                                    setFieldValue('logoUrl', response.url);
                                    setImages(
                                      files.map((file: any) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                      )
                                    );
                                  }
                                } catch (errors) {
                                  console.log('errors: ', errors);
                                }
                              }}
                              messageMaxSize='The selected file size is too large. Please select a file less than 10 MB.'
                              maxSize={10485760}
                              dragTitle='Drop your image here.'
                              dropTitle='Add logo image'
                              bottomDropTitle={{
                                addFile: 'Add image',
                                dropFile: 'or Drop your image here.',
                              }}
                              notifyTypeOfFile='Incorrect image format. Please select an image in .png, .jpg, .jpeg.'
                            />
                            <p>
                              *Recommend to use an image with 200 x 60 to best
                              fit your email template.
                            </p>
                          </>
                        )}
                      </div>
                      <Button
                        style={{ marginLeft: '10px' }}
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={
                          disabledButton ||
                          !formikProps.isValid ||
                          !!nameError ||
                          !!micrositeNameError
                        }
                      >
                        <span>Save</span>
                      </Button>
                    </form>
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default PracticeCompany;
