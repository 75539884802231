import APIService from '../clientApi';
import {
  GeneralFormsParams,
  ICurentResponse,
  IUserReadyResponse,
  IGetFoldersResponse,
  GetFoldersParams,
  GetByVersionsCurrentParams,
  IGetByVersionsCurrentResponse,
  RenameFolderParams,
  IRenameFolderResponse,
  CreateFolderParams,
  ICreateFolderResponse,
  DeleteFolderParams,
  IDeleteFolderResponse,
  CloneFormParams,
  ICloneFormResponse,
  MoveFormParams,
  IMoveFormResponse,
  RenameFormParams,
  IRenameFormResponse,
  DeleteFormParams,
  IDeleteFormResponse,
  CreateFormParams,
  ICreateFormResponse,
  FormSubmissionQueueParams,
  IFormSubmissionQueueResponse,
  IFormSubmissionAttributeResponse,
  FormSubmissionAttributeParams,
  FormSubmissionQueueCountParams,
  IFormSubmissionQueueCountResponse,
  SubmissionsSyncableStateOfFormParams,
  ISubmissionsSyncableStateOfFormResponse,
  FormSubmissionsHistoryParams,
  IFormSubmissionsHistoryResponse,
  MarkSubmissionsHistoryParams,
  ISubmissionParams,
  ISubmissionParamsResponse,
  ISubmissionApproveParams,
} from './paperlessFormsTypes';

const API = APIService();

const userReady = async (params: []): Promise<IUserReadyResponse> =>
  API.rpc('', { method: 'user.ready', params });

const getGeneralFormData = async (params: GeneralFormsParams): Promise<ICurentResponse> =>
  API.rpc('', { method: 'public.getCurrent', params });

const getFolder = async (params: GetFoldersParams): Promise<IGetFoldersResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getBy', params });

const createFolder = async (params: CreateFolderParams): Promise<ICreateFolderResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const renameFolder = async (params: RenameFolderParams): Promise<IRenameFolderResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const deleteFolder = async (params: DeleteFolderParams): Promise<IDeleteFolderResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const createForm = async (params: CreateFormParams): Promise<ICreateFormResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const getGenericFormBuilderElement = async (params: any): Promise<any> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getBy', params });

const upsertGenericFormBuilderElement = async (params: any): Promise<any> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const getByVersionsCurrent = async (
  params: GetByVersionsCurrentParams,
): Promise<IGetByVersionsCurrentResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getBy', params });

const cloneForm = async (params: CloneFormParams): Promise<ICloneFormResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.cloneFormBy', params });

const renameForm = async (params: RenameFormParams): Promise<IRenameFormResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const moveForm = async (params: MoveFormParams): Promise<IMoveFormResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const deleteForm = async (params: DeleteFormParams): Promise<IDeleteFormResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const getBySubmissionQueue = async (
  params: FormSubmissionQueueParams,
): Promise<IFormSubmissionQueueResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getBy', params });

const getBySubmissionAttribute = async (
  params: FormSubmissionAttributeParams,
): Promise<IFormSubmissionAttributeResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getBy', params });

const getBySubmissionQueueCount = async (
  params: FormSubmissionQueueCountParams,
): Promise<IFormSubmissionQueueCountResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.countBy', params });

const submissionsSyncableStateOfForm = async (
  params: SubmissionsSyncableStateOfFormParams,
): Promise<ISubmissionsSyncableStateOfFormResponse> =>
  API.rpc('', { method: 'FormBuilder.submissions.syncableStateOfForm', params });

const formSubmissionDelete = async (
  params: FormSubmissionsHistoryParams,
): Promise<IFormSubmissionsHistoryResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const markSubmissionsAsRead = async (
  params: MarkSubmissionsHistoryParams,
): Promise<IFormSubmissionsHistoryResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const formSubmissionUpdate = async (
  params: ISubmissionParams,
): Promise<ISubmissionParamsResponse> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params });

const submissionApproveWithContact = async (
  params: ISubmissionApproveParams,
): Promise<ISubmissionParamsResponse> =>
  API.rpc('', { method: 'FormBuilder.submissions.approve', params });

const getShareLink = async (params: [string]): Promise<{ p: [null, string] }> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.getShareLink', params });

export default {
  userReady,
  getGeneralFormData,
  formBuilder: {
    getFolder,
    getGenericFormBuilderElement,
    createFolder,
    renameFolder,
    deleteFolder,
    createForm,
    cloneForm,
    renameForm,
    moveForm,
    deleteForm,
    getByVersionsCurrent,
    getBySubmissionQueue,
    getBySubmissionAttribute,
    getBySubmissionQueueCount,
    submissionsSyncableStateOfForm,
    upsertGenericFormBuilderElement,
    formSubmissionDelete,
    formSubmissionUpdate,
    markSubmissionsAsRead,
    submissionApproveWithContact,
    getShareLink,
  },
};
