import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

const StyledIconButton = styled(IconButton)`
  padding: 0.25rem;
  svg {
    height: 18px;
    width: 18px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledIconButton };
