import CAPTURE_SCREEN from '../../../images/capture-copy.svg';
import React, { RefObject } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Toolbar,
  makeStyles,
  Divider,
  DialogContent,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { ScreenShot } from '../../../types/ScreenShotsModel';
import './ScreenCapture.scss';
import DELETE_ICON from '../../../images/delete.svg';
import PREVIOUS_ACTIVE_BUTTON from '../../../images/back_active.svg';
import PREVIOUS_BUTTON from '../../../images/back.svg';
import SELECT_ALL_BUTTON from '../../../images/select-all-copy.svg';
import UNSELECT_ALL_BUTTON from '../../../images/unselect-all-copy.svg';
import CHECKED_BOX from '../../../images/checkbox-checked.svg';
import UNCHECKED_BOX from '../../../images/checkbox-unchecked.svg';
import OPEN_FOLDER_BUTTON from '../../../images/openFolder.svg';
import CLOSE_BUTTON from '../../../images/close.svg';
import SS_FOLDER from '../../../images/screen_shot_folder.svg';
import { baseUrl } from 'telemed-video-call/environment';
import PopupShowImageWithHook from 'hooks/usePopupShowImage';
import { orderBy } from 'lodash';
import { getAppBaseUrl } from 'global/constants/url';

const FormData = require('form-data');
const axios = require('axios');

//////////////////////////////////////////Mock data image list for view image folder//////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
  dialogPreview: {
    position: 'absolute',
    bottom: '104px',
    right: '15px',
    float: 'right',
  },
  title: {
    textAlign: 'center',
    flex: 1,
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
////////////////////////////////////////////////////////FADEOUT EFFECT/////////////////////////////////////////////////////////////
let timeOutParam: NodeJS.Timeout;
const fadeOutEffect = (timer: number) => {
  const fadeTarget = document.getElementById('screenshot-img');
  if (fadeTarget) {
    // @ts-ignore
    timeOutParam = setTimeout(() => {
      fadeTarget.style.display = 'none';
    }, timer);
  }
};

interface IProps {
  accessToken: string | null;
  appointmentId: string | null;
  openFolderOfGroupMenu?: boolean;
  showGroupMenu?(a?: boolean): void;
  setOpenFolderOfGroupMenu?(a: boolean): void;
  partnerVideoPreview?: RefObject<HTMLDivElement>;
  openFolderOfScreenCapture?: boolean;
  setOpenFolderOfScreenCapture?(value: boolean): void;
}

const ScreenCapture: React.FC<IProps> = (props) => {
  const [openPreview, setopenPreview] = React.useState(false);
  const [stringUrlImage, setStringUrlImage] = React.useState('');
  const [currentImg, setCurrentImg] = React.useState<number>(-1);
  const [isOpenFolder, setIsOpenFolder] = React.useState(false);
  const [listScreenShot, setListScreenShot] = React.useState<Array<ScreenShot>>(
    new Array<ScreenShot>()
  );
  const [listSelectedImg, setListSelectedImg] = React.useState<Array<string>>(
    new Array<string>()
  );
  const [addImageSuccess, setAddImageSuccess] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showWindowImage, setShowWindowImage] = React.useState<boolean>(false);
  const [imageId, setImageId] = React.useState<string>('');
  /**
   * Get list screen shot when click to preview image
   */
  React.useEffect(() => {
    if (props.accessToken !== '') {
      getListScreenShot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accessToken]);

  /**
   * Get list screen shot when click to open screen shot folder
   */
  React.useEffect(() => {
    if (isOpenFolder && props.accessToken !== '') {
      getListScreenShot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenFolder, props.accessToken]);

  React.useEffect(() => {
    if (addImageSuccess) {
      setCurrentImg(listScreenShot.length - 1);
      setAddImageSuccess(false);
      setIsDeleting(false);
    }

    // Handle change status when delete done after get list screenshot
    setIsDeleting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listScreenShot]);

  const getListScreenShot = () => {
    axios.get(`${baseUrl}/appointments/V1/${props.appointmentId}/attachments?attachmentKind=screenshot`, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }).then((response: { status: number, error: string, data: { attachments: ScreenShot[] } }) => {
      if (200 === response.status) {
        const sortedListImage = orderBy(response.data.attachments, ['name', 'creationDate'], ['asc', 'asc']);
        setListScreenShot(sortedListImage);
        // setCurrentImg(response.data.attachments.length - 1);
      } else if (404 === response.status) {
        console.log(response.error);
      }
    });
  };

  const addScreenShot = (formData: FormData) => {
    console.log(listScreenShot.length)
    axios.post(`${baseUrl}/appointments/V1/${props.appointmentId}/attachments`, formData,
      {
        headers: {
          'Authorization': `Bearer ${props.accessToken}`,
          'Content-type': 'multipart/form-data'
        }
      })
      .then((response: { status: number }) => {
        if (201 === response.status) {
          getListScreenShot();
          setAddImageSuccess(true);
          // console.log(listScreenShot.length)
          // setCurrentImg(listScreenShot.length - 1);
        }
      });
  };

  const deleteScreenShot = (attachmentId: string) => {
    setIsDeleting(true);
    axios.delete(`${baseUrl}/appointments/V1/${props.appointmentId}/attachments/${attachmentId}`,
      {
        headers: {
          'Authorization': `Bearer ${props.accessToken}`,
        }
      })
      .then(() => {
        getListScreenShot();
      });
  };

  ////////////////////////////////////////////////////////CLICK OPEN CANCEL/////////////////////////////////////////////////////////////
  const handleClickOpen = async () => {
    // let index = await listScreenShot.length - 1;
    // setCurrentImg(index);
    setopenPreview(true);
  };

  const handleClickOpenFolder = () => {
    setIsOpenFolder(true);
  };

  const handleClickCancel = () => {
    setIsOpenFolder(false);
    setopenPreview(false);
    setStringUrlImage('');
  };

  ////////////////////////////////////////////////////////CLICK DELETE/////////////////////////////////////////////////////////////
  const handleClickDeleteSingleImage = (attachmentId: string) => {
    deleteScreenShot(attachmentId);
    if (currentImg === listScreenShot.length - 1) {
      setCurrentImg(currentImg - 1);
    }
  };

  const handleClickDeleteMultiImage = () => {
    listSelectedImg.forEach((attachmentId) => {
      deleteScreenShot(attachmentId);
    });
    getListScreenShot();
    handleClickUnSelectAll();
  };

  const handleClickSelectAll = () => {
    setListSelectedImg([]);
    listScreenShot.slice(0).forEach((element) => {
      const selectedCheckbox = document.getElementsByClassName(
        listScreenShot.slice(0).indexOf(element).toString()
      )[0];
      selectedCheckbox.setAttribute('src', CHECKED_BOX);
      listSelectedImg.push(element.attachmentId);
    });
    setListSelectedImg(listSelectedImg);
  };

  const handleClickUnSelectAll = () => {
    listScreenShot.forEach((element) => {
      const selectedCheckbox = document.getElementsByClassName(
        listScreenShot.indexOf(element).toString()
      )[0];
      selectedCheckbox.setAttribute('src', UNCHECKED_BOX);
    });
    setListSelectedImg([]);
  };
  ////////////////////////////////////////////////////////CLICK VIEW IMAGE/////////////////////////////////////////////////////////////
  const handleOnceClickEvent = (attachmentId: string, index: number) => {
    const selectedCheckbox = document.getElementsByClassName(
      index.toString()
    )[0];
    if (selectedCheckbox.getAttribute('src') === CHECKED_BOX) {
      selectedCheckbox.setAttribute('src', UNCHECKED_BOX);
      const indexOfSelectImg = listSelectedImg.indexOf(attachmentId);
      if (indexOfSelectImg > -1) {
        listSelectedImg.splice(indexOfSelectImg, 1);
      }
    } else {
      selectedCheckbox.setAttribute('src', CHECKED_BOX);
      listSelectedImg.push(attachmentId);
    }
    setListSelectedImg(listSelectedImg);
  };

  const handleTwiceClickEvent = (index: number) => {
    setCurrentImg(index);
    setIsOpenFolder(false);
  };

  const handleClickPrevious = (i: number) => {
    setCurrentImg(currentImg - 1);
  };

  const handleClickNext = (i: number) => {
    setCurrentImg(currentImg + 1);
  };

  const classes = useStyles();
  ////////////////////////////////////////////////////////CLICK CAPTURE/////////////////////////////////////////////////////////////
  const startCapture = () => {
    clearTimeout(timeOutParam);
    const fadeTarget = document.getElementById('screenshot-img');
    if (fadeTarget) {
      fadeTarget.style.display = 'unset';
      fadeTarget.style.opacity = '9999';
    }
    const img = document.querySelector('#screenshot-img');
    const canvas = document.createElement('canvas');
    const video = props.partnerVideoPreview
      ? props.partnerVideoPreview.current &&
      props.partnerVideoPreview.current.getElementsByTagName('video')[0]
      : null;
    if (video) {
      let patientVideoWidth = video.getBoundingClientRect().width;
      let patientVideoHeight = video.getBoundingClientRect().height;
      canvas.width = patientVideoWidth;
      canvas.height = patientVideoHeight;
      let canvasImage = canvas.getContext('2d');
      canvasImage &&
        canvasImage.drawImage(
          video,
          0,
          0,
          patientVideoWidth,
          patientVideoHeight
        );
      if (img) {
        setStringUrlImage(canvas.toDataURL('image/webp'));
        canvas.toBlob((blob) => {
          let formData = new FormData();
          formData.append('attachmentName', getNameOfScreenShot());
          formData.append(
            'attachmentDescription',
            'Demonstration of a larger image with thumbnail.'
          );
          formData.append('contentType', 'image/jpeg');
          formData.append('attachmentKind', 'screenshot');
          formData.append('attachment', blob, 'image.jpg');
          addScreenShot(formData);
        }, 'image.jpg');

        fadeOutEffect(5000);
      }
    }
  };

  const getNameOfScreenShot = () => {
    let currentTime = new Date();
    let hour =
      currentTime.getHours() < 10
        ? '0' + currentTime.getHours()
        : currentTime.getHours();
    let minute =
      currentTime.getMinutes() < 10
        ? '0' + currentTime.getMinutes()
        : currentTime.getMinutes();
    let second =
      currentTime.getSeconds() < 10
        ? '0' + currentTime.getSeconds()
        : currentTime.getSeconds();
    return hour + ':' + minute + ':' + second;
  };

  const showImageDetail = (selectedImageId: string) => {
    setShowWindowImage(!showWindowImage);
    setImageId(selectedImageId);
  }

  const onCloseWindowShowImage = () => {
    setShowWindowImage(false);
  }
  ////////////////////////////////////////////////////////RENDER ELEMENT/////////////////////////////////////////////////////////////

  let btnChevronNext =
    listScreenShot.length === 1 || (currentImg === listScreenShot.length - 1 &&
      listScreenShot.length !== 0) ? (
        <button className='btnChevron right'>
          <img className='viewButton' src={PREVIOUS_BUTTON} alt='Next button' />
        </button>
      ) : listScreenShot.length === 0 ? (
        <div></div>
      ) : (
          <Tooltip title='Next Screenshot'>
            <button
              className='btnChevron right'
              onClick={() => handleClickNext(currentImg)}
            >
              <img
                className='viewButton'
                src={PREVIOUS_ACTIVE_BUTTON}
                alt='Next active button'
              />
            </button>
          </Tooltip>
        );

  let btnChevronPrevious =
    listScreenShot.length === 1 || currentImg === 0 ? (
      <button className='btnChevron left'>
        <img
          className='viewButton'
          src={PREVIOUS_BUTTON}
          alt='Previous button'
        />
      </button>
    ) : listScreenShot.length === 0 ? (
      <div></div>
    ) : (
          <Tooltip title='Previous Screenshot'>
            <button
              className='btnChevron left'
              onClick={() => handleClickPrevious(currentImg)}
            >
              <img alt='' className='viewButton' src={PREVIOUS_ACTIVE_BUTTON} />
            </button>
          </Tooltip>
        );

  let listItems = listScreenShot.map((screenShot, index) => (
    <Grid key={screenShot.attachmentId} item xs={4}>
      <div
        className='imgItems'
        onClick={() => handleOnceClickEvent(screenShot.attachmentId, index)}
        onDoubleClick={() => handleTwiceClickEvent(index)}
      >
        <img
          src={UNCHECKED_BOX}
          className={index.toString()}
          id='checkbox'
          alt='checkbox'
        />
        <img
          src={screenShot.urlThumbnail}
          className='imgItem'
          alt='screenshot'
        />
        <div className='img-name'>{screenShot.name}</div>
      </div>
    </Grid>
  ));

  let option = !isOpenFolder ? (
    <>
      <Dialog
        className='dialog-sreen-capture'
        open={true || openPreview}
        maxWidth={'lg'}
      >
        <Toolbar>
          <DialogTitle className={classes.title}>
            {'Screenshot Review'}
          </DialogTitle>
          <Tooltip title='Close'>
            <button onClick={handleClickCancel}>
              <img src={CLOSE_BUTTON} alt='close button' />
            </button>
          </Tooltip>
        </Toolbar>
        <Divider />
        <DialogContent className='viewImageSingle'>
          <div className='viewImage'>
            {btnChevronPrevious}

            {currentImg >= 0 &&
              currentImg <= listScreenShot.length - 1 &&
              openPreview ? (
                <div className='image-single-view-container'>
                  <img
                    alt=''
                    className='single-view-image'
                    src={listScreenShot[currentImg].url}
                    onClick={() => showImageDetail(listScreenShot[currentImg].attachmentId)}
                  />
                  <div className='screen-shot-name'>
                    {listScreenShot[currentImg].name}
                  </div>
                </div>
              ) : null}

            {btnChevronNext}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          {isDeleting
            ? <button
              onClick={() =>
                handleClickDeleteSingleImage(
                  listScreenShot[currentImg].attachmentId
                )
              }
              className='btnDelete'
              disabled
            >
              <Tooltip title='Delete'>
                <img style={{ opacity: '0.5' }} src={DELETE_ICON} alt='Delete icon' />
              </Tooltip>
            </button>
            : <button
              onClick={() =>
                handleClickDeleteSingleImage(
                  listScreenShot[currentImg].attachmentId
                )
              }
              className='btnDelete'
            >
              <Tooltip title='Delete'>
                <img src={DELETE_ICON} alt='Delete icon' />
              </Tooltip>
            </button>
          }
          <Tooltip title='Thumbnail View'>
            <button
              onClick={handleClickOpenFolder}
              className='btnViewFolderImage'
            >
              <img src={OPEN_FOLDER_BUTTON} alt='Open folder img' />
            </button>
          </Tooltip>
        </DialogActions>
      </Dialog >
      {showWindowImage && <PopupShowImageWithHook
        url={`${getAppBaseUrl()}/Image-detail/${props.appointmentId}/${imageId}`}
        closeWindowShowImage={onCloseWindowShowImage}
      />
      }
    </>
  ) : (
      <Dialog className='dialog-sreen-capture' open={openPreview} maxWidth={'lg'}>
        <Toolbar>
          <DialogTitle className={classes.title}>
            {'Screenshot Review'}
          </DialogTitle>
          <Tooltip title='Close'>
            <button onClick={handleClickCancel}>
              <img src={CLOSE_BUTTON} alt='Close button img' />
            </button>
          </Tooltip>
        </Toolbar>
        <Divider />
        <DialogContent className='DialogContent'>
          <Grid container spacing={1}>
            {listItems}
          </Grid>
        </DialogContent>
        <Divider />
        <div className='viewFolderAction'>
          <Tooltip title='Delete'>
            <button
              onClick={handleClickDeleteMultiImage}
              className='btnDeleteMulti'
            >
              <img src={DELETE_ICON} alt='Delete icon' />
            </button>
          </Tooltip>
          <div className='btnSelectOption'>
            <button onClick={handleClickSelectAll} className='btn'>
              <img src={SELECT_ALL_BUTTON} alt='Select all button img' />
            </button>
            <button onClick={handleClickUnSelectAll} className='btn right'>
              <img src={UNSELECT_ALL_BUTTON} alt='Unselect all button img' />
            </button>
          </div>
        </div>
      </Dialog>
    );

  if (
    !openPreview &&
    props.openFolderOfScreenCapture &&
    !props.openFolderOfGroupMenu
  ) {
    return (
      <div className='screen-capture-container'>
        <div onClick={startCapture}>
          <img src={CAPTURE_SCREEN} alt='Capture screen icon' />
          <p> Take Screenshot </p>
        </div>
        <img
          alt=''
          id='screenshot-img'
          className='Group-Copy'
          onClick={handleClickOpen}
          src={stringUrlImage}
        />
      </div>
    );
  } else if (openPreview) {
    return (
      <div className='screen-capture-container'>
        <div onClick={startCapture}>
          <img alt='' src={CAPTURE_SCREEN} />
          <p> Take Screenshot </p>
        </div>
        {option}
      </div>
    );
  } else if (
    openPreview &&
    !props.openFolderOfScreenCapture &&
    props.openFolderOfGroupMenu
  ) {
    return (
      <div className='childGroupMenu'>
        <img alt='icon menu' src={SS_FOLDER} />
        <span className='menu-title'> Open Screenshot Folder </span>
      </div>
    );
  } else {
    return (
      <div
        className='childGroupMenu'
        onClick={() => {
          setopenPreview(true);
          setIsOpenFolder(true);
          props.showGroupMenu && props.showGroupMenu();
        }}
      >
        <img alt='icon menu' src={SS_FOLDER} />
        <span className='menu-title'> Open Screenshot Folder </span>
      </div>
    );
  }
};

export default ScreenCapture;
