type seriesData = { name: string; y: number; color: string }[];

interface ISeries {
  type: string;
  size: string;

  showInLegend: boolean;
  dataLabels: {
    enabled: boolean;
  };
  data: seriesData;
}

const generateSeriesData = (data: number[], names: string[], colors: string[]): seriesData => {
  return data.map((datum, i): { name: string; y: number; color: string } => ({
    name: names[i],
    y: datum,
    color: colors[i],
  }));
};

const generateSeries = (data: seriesData, outerRadius: number): ISeries[] => [
  {
    type: 'pie',
    size: `${outerRadius}%`,
    showInLegend: true,
    dataLabels: {
      enabled: false,
    },
    data,
  },
];

// eslint-disable-next-line import/prefer-default-export
export { generateSeries, generateSeriesData };
