import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { PersonalizationTokensResponse } from 'global/requests/ResponseTypes';
import { useRouter } from 'hooks/useRouter';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { get, has, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { isWindows } from 'react-device-detect';
import {
  getContentToSavePreview,
  getContentFrontPostCard,
  getContentBackPostCard,
} from './Templates/PostCard/getContentPostCard';
import {
  Wrapper,
  ImageWrapper,
} from './Templates/PostCard/PostalTemplateWrapper';
import loading_spinner from 'media/images/loading-spinner.svg';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import PreviewPostCard from './Templates/PostCard/PreviewPostCard';
import ListImageTemplates from './Templates/PostCard/ListImageTemplates';
import { TenantResposne } from 'global/requests/ResponseTypes';
import BackPostCard, {
  cutTemplateBody,
} from './Templates/PostCard/BackPostCard';
import EditorBox from './EditorBox';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import Filter from 'components/Global/Filter/Filter';
import {
  initialTokenValue,
  optionTimes,
  optionUnitTimes,
} from './EditEmailTemplate';

interface IErrors {
  templateName: string;
  frontSide: string;
  backSide: string;
  size: string;
}

const listSize = [
  { label: '4x6', value: '4x6' },
  { label: '6x9', value: '6x9' },
  { label: '6x11', value: '6x11' },
];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
interface IPostal {
  templateName: string;
  frontSide: string;
  backSide: string;
  size: '4x6' | '6x9' | '6x11';
  onOnlyOnceEvery: boolean;
  duration: number;
  unitOfDuration: string;
}

const initialPostal: IPostal = {
  templateName: '',
  frontSide: '',
  backSide: '',
  size: '4x6',
  onOnlyOnceEvery: false,
  duration: 1,
  unitOfDuration: 'hours',
};

const Postal = () => {
  const router = useRouter();
  // @ts-ignore
  const [screen, setScreen] = useState<string>(router.query?.category ?? '');
  const [postalData, setPostalData] = useState<IPostal>(initialPostal);
  const [initialPostalData, setInitialPostalData] =
    useState<IPostal>(initialPostal);
  const [error, setError] = useState<IErrors>({
    templateName: '',
    frontSide: '',
    backSide: '',
    size: '',
  });
  const [isEdited, setValueEdited] = React.useState<boolean>(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [generalSetting, setDataGeneralSetting] =
    React.useState<TenantResposne>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listImageTemplates, setListImagesTemplate] = useState<string[]>([]);
  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [optionDurations, setOptionDurations] = useState(optionTimes);
  const [optionUnits, setOptionUnits] = useState(optionUnitTimes);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const isValidToSubmit = useMemo(() => {
    const isClearError = Object.values(error).every((err) => !err);
    const isDataFilled = !!postalData.templateName;

    return isDataFilled && isClearError;
  }, [error, postalData]);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    !value
      ? setError((preState) => ({
          ...preState,
          [name]: 'This field is required.',
        }))
      : setError((preState) => ({ ...preState, [name]: '' }));
    name === 'templateName' &&
      setPostalData((preState: IPostal) => ({ ...preState, [name]: value }));
    setValueEdited(true);
  };

  const onSelectSize = (selectedSize: '4x6' | '6x9' | '6x11') => {
    !selectedSize &&
      setError((preState) => ({
        ...preState,
        size: 'This field is required.',
      }));
    setPostalData((preState: IPostal) => ({ ...preState, size: selectedSize }));
  };

  const handleOnChangeBackSide = (data: string) => {
    setValueEdited(true);
    setPostalData((preState: IPostal) => ({ ...preState, backSide: data }));
    // if (!data) {
    //   setError(preState => ({ ...preState, backSide: 'This field is required.' }));
    // }
  };

  const getTemplateAndTokenDataRequest = useCallback(async () => {
    // @ts-ignore
    const { templateId } = router.query;
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
      if (templateId) {
        const templateResponse = await configuredRequests.GET.templateByID(
          templateId
        );
        setScreen(templateResponse?.categoryKind ?? '');
        setPostalData({
          // @ts-ignore
          frontSide: templateResponse?.url ?? '',
          // @ts-ignore
          size: templateResponse.size || '4x6',
          templateName: templateResponse.name || '',
          // @ts-ignore
          backSide: templateResponse?.backBody ?? '',
          onOnlyOnceEvery: templateResponse?.onOnlyOnceEvery ?? false,
          duration: templateResponse?.duration ?? 1,
          unitOfDuration: templateResponse?.unitOfDuration ?? 'hours',
        });
        setInitialPostalData({
          // @ts-ignore
          frontSide: templateResponse?.url ?? '',
          // @ts-ignore
          size: templateResponse.size || '4x6',
          templateName: templateResponse.name || '',
          // @ts-ignore
          backSide: templateResponse?.backBody ?? '',
          onOnlyOnceEvery: templateResponse?.onOnlyOnceEvery ?? false,
          duration: templateResponse?.duration ?? 1,
          unitOfDuration: templateResponse?.unitOfDuration ?? 'hours',
        });
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, [router.query]);

  const getSettingDurationTimes = async () => {
    try {
      const res = await configuredRequests.GET.getSettingDurationTimes();
      if (res) {
        setOptionDurations(
          // @ts-ignore
          res.definedValues.map((item: any) => ({
            label: item.name,
            value: item.value.toString(),
          }))
        );
        setOptionUnits(
          // @ts-ignore
          res.unitKinds.map((item: any) => ({
            label: item.name,
            value: item.value,
          }))
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getListImageBySide = useCallback(async () => {
    try {
      const response = await configuredRequests.GET.getListImageBySide(
        postalData.size
      );
      if (get(response, 'status', 0) === 200) {
        // @ts-ignore
        setListImagesTemplate(get(response, 'data', []));
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, [postalData.size]);

  const submitData = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      // @ts-ignore
      const { category, templateId } = router.query;
      try {
        setDisabledSubmit(true);
        const previewContent = getContentToSavePreview({
          frontSideUrl: postalData.frontSide,
          sizePostCard: postalData.size,
          contentBackSide: cutTemplateBody(postalData.backSide),
          isPostGrid:
            (generalSetting?.postalProvider || '').toLowerCase() === 'postgrid',
        });
        const dataSubmit = {
          name: postalData.templateName,
          templateKind: 'postal',
          frontSide: getContentFrontPostCard(
            postalData.frontSide,
            postalData.size
          ),
          url: postalData.frontSide,
          backSide: getContentBackPostCard(
            cutTemplateBody(postalData.backSide),
            postalData.size,
            (generalSetting?.postalProvider || '').toLowerCase() === 'postgrid'
          ),
          size: postalData.size,
          categoryKind: category,
          previewContent,
          backBody: postalData.backSide,
          onOnlyOnceEvery: postalData.onOnlyOnceEvery,
          duration: postalData.duration,
          unitOfDuration: postalData.unitOfDuration,
        };
        if (templateId) {
          const response = await configuredRequests.PATCH.updateTemplate(
            templateId,
            dataSubmit
          );
          if (has(response, 'templateKind')) {
            setValueEdited(false);
            toast.success('Your data has been saved successfully!');
            await getTemplateAndTokenDataRequest();
          }
          setDisabledSubmit(false);
        } else {
          const response = await configuredRequests.POST.createTemplate(
            dataSubmit
          );
          if (has(response, 'templateKind')) {
            setValueEdited(false);
            toast.success('Your data has been saved successfully!');
          }
          setDisabledSubmit(false);
          router.history.goBack();
        }
      } catch (error) {
        setDisabledSubmit(false);
        toast.error('Your data has not been saved yet. Please try again.');
      }
    },
    [postalData, router.query, router.history, getTemplateAndTokenDataRequest]
  );

  const handleGoBackRouter = () => {
    setValueEdited(false);
    setOpenPopup(false);
    router.history.goBack();
  };

  const setImage = useCallback((imageUrl: string) => {
    setValueEdited(true);
    setPostalData((preState: IPostal) => {
      // set image for initialPostalData for first time load list image
      !preState.frontSide &&
        setInitialPostalData((preState: IPostal) => ({
          ...preState,
          frontSide: imageUrl,
        }));
      return { ...preState, frontSide: imageUrl };
    });
  }, []);

  const getInfoGeneralSetting = useCallback(async () => {
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      const resTenantInfo = await configuredRequests.GET.getTenantInfo(
        res.tenantGroupId,
        res.tenantId
      );
      setDataGeneralSetting({
        ...resTenantInfo,
        phoneNumbers: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => !phoneNumber.isPracticePhone
        ),
        // @ts-ignore
        practicePhoneNumber: resTenantInfo.phoneNumbers?.filter(
          (phoneNumber: any) => phoneNumber.isPracticePhone
        ),
      });
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  useEffect(() => {
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    Promise.all([
      getTemplateAndTokenDataRequest(),
      getInfoGeneralSetting(),
      getListImageBySide(),
      getSettingDurationTimes(),
    ])
      .then((res) => setIsLoading(false))
      .catch((err) => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!isEqual(postalData, initialPostalData)) {
      setValueEdited(true);
      localStorage.setItem('isEdited', 'Yes');
    } else {
      setValueEdited(false);
      localStorage.setItem('isEdited', JSON.stringify('noChange'));
    }
  }, [postalData, initialPostalData]);

  const backContent = useMemo(() => {
    return (
      <BackPostCard
        generalSetting={generalSetting}
        contentBackSide={postalData.backSide}
      />
    );
  }, [generalSetting, postalData.backSide]);

  const selectedImageList = useMemo(() => {
    return (
      <ListImageTemplates
        listImageTemplates={listImageTemplates}
        setImage={setImage}
        initialSelectedImage={initialPostalData.frontSide}
        selectedImage={postalData.frontSide}
      />
    );
  }, [
    listImageTemplates,
    initialPostalData.frontSide,
    postalData.frontSide,
    setImage,
  ]);

  return (
    <Wrapper className='edit-appointment' id='postal-body'>
      {isLoading ? (
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        <>
          <ModalDiscard
            isOpenModal={openPopup}
            handleCloseModal={setOpenPopup}
            handleConfirmModal={handleGoBackRouter}
          />
          {/* <ModalComponent
        isOpen={isPreview}
        onClose={() => setIsPreview(false)}
        titleHeader=""
      >
        <PreviewPostCard size={postalData.size} frontSide={postalData.frontSide} backSide={postalData.backSide} />
      </ModalComponent> */}
          <form
            onSubmit={submitData}
            data-testid='form'
            className='col-12 form-schedule'
          >
            <div className='confirmation-email-title'>
              <h2>{initialPostalData.templateName || 'Add New Template'}</h2>

              <Button
                variant='contained'
                color='default'
                className='button-form cancel-button'
                onClick={() => {
                  // localStorage.setItem('isEdited', JSON.stringify('noChange'));
                  // setPostalData(initialPostalData);
                  // setError({ backSide: '', frontSide: '', size: '', templateName: '' });
                  if (isEdited === false) {
                    return router.history.goBack();
                  } else {
                    setOpenPopup(true);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!isValidToSubmit || disabledSubmit}
              >
                Save
              </Button>
            </div>
            <div className='confirmation-email-container'>
              <div className='flex'>
                <div className='template--name-duration postal'>
                  <div className='form-input-settings mw-190'>
                    <label htmlFor='templateName'>
                      Template Name <b className='require'>*</b>
                    </label>
                    <input
                      value={postalData.templateName}
                      name='templateName'
                      onChange={onChangeInput}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        const { name, value } = event.target;
                        !value &&
                          setError((preState) => ({
                            ...preState,
                            [name]: 'This field is required.',
                          }));
                      }}
                      maxLength={255}
                    />
                  </div>
                  <div className='select--full-width custom-scroll'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='onOnlyOnceEvery'
                          color='primary'
                          checked={postalData?.onOnlyOnceEvery ?? false}
                          onChange={() =>
                            setPostalData((prev) => ({
                              ...prev,
                              onOnlyOnceEvery: !prev.onOnlyOnceEvery,
                            }))
                          }
                        />
                      }
                      className='checkbox--small'
                      label='Only once every: '
                    />
                    <Filter
                      optionsForFilter={optionDurations}
                      handleSetNewFilterValue={(newTimeValue) =>
                        setPostalData((prev) => ({
                          ...prev,
                          duration: Number(newTimeValue.value),
                        }))
                      }
                      valueSelected={optionDurations.find(
                        (option) =>
                          option.value ===
                          (postalData?.duration.toString() ?? '1')
                      )}
                    />
                    <Filter
                      optionsForFilter={optionUnits}
                      handleSetNewFilterValue={(newUnitTime) =>
                        setPostalData((prev) => ({
                          ...prev,
                          unitOfDuration: newUnitTime.value,
                        }))
                      }
                      valueSelected={optionUnits.find(
                        (option) =>
                          option.value ===
                          (postalData?.unitOfDuration ?? 'hours')
                      )}
                    />
                  </div>
                </div>
                <p className='error-msg'>{error.templateName}</p>
                <div className='form-input-settings mw-190 editor-postal'>
                  <label htmlFor='messageBody'>Front</label>
                  <div className='front-images'>
                    {selectedImageList}
                    <ImageWrapper className='selected-image'>
                      <img src={postalData.frontSide} alt='front-post-card' />
                    </ImageWrapper>
                  </div>
                </div>

                <div className='form-input-settings mw-190 editor-postal back-poscard'>
                  <div className='content-back'>
                    <label htmlFor='messageBody'>Back</label>
                    <EditorBox
                      value={postalData.backSide}
                      handleChangeEditor={handleOnChangeBackSide}
                      token={tokenData}
                      // @ts-ignore
                      screen={`${screen.toLowerCase().trim()} postal`}
                    />
                    <p className='error-msg'>{error.backSide}</p>
                    <label htmlFor='size'>Size</label>
                    <input
                      className='size-postcard'
                      value={postalData.size}
                      name='size'
                      onChange={onChangeInput}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        const { name, value } = event.target;
                        !value &&
                          setError((preState) => ({
                            ...preState,
                            [name]: 'This field is required.',
                          }));
                      }}
                      maxLength={255}
                      disabled
                    />
                    <p className='error-msg'>{error.size}</p>
                  </div>
                  {backContent}
                </div>
                {/* <div className='form-input-settings mw-190'>
              <label htmlFor='messageBody'>Size <b className="require">*</b></label>
              <TextChevronDropdown
                styleOptions={{
                  // @ts-ignore
                  width: '100%'
                }}
                className="contacts--filter--select-container background-gray height-40"
                // @ts-ignore
                currentSelection={listSize.find(status => status.value === postalData.size)?.label}
                // @ts-ignore
                handleChangeSelection={onSelectSize}
                selectionOptions={listSize}
                useBlackText={true}
              />
              <p className="error-msg">{error.size}</p>
            </div> */}
              </div>
            </div>
          </form>
        </>
      )}
    </Wrapper>
  );
};

export default Postal;
