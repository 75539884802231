import React, { useEffect } from 'react';
import { orderBy } from 'lodash';
import { isWindows } from 'react-device-detect';

import RoleModal from 'components/Global/SettingsComponent/RoleModal';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import {
  SummaryItemTypes,
  UserStatusTypes,
  UserKindTypes,
} from 'global/requests/api-route-types';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column, Role, Screen } from 'global/requests/ResponseTypes';

const columns: Column[] = [
  { id: 'roleName', label: 'Role Name' },
  { id: 'roleStatus', label: 'Status' },
  { id: 'rolePermissions', label: 'Access Permission' },
];

const optionsForFilter = [
  { label: 'All Roles', value: '' },
  { label: 'Active Roles', value: 'Active' },
  { label: 'Inactive Roles', value: 'Inactive' },
];

const RoleSetting = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [displayRoleModal, setDisplayRoleModal] = React.useState(false);
  const [roleInformationData, setRoleInformationData] =
    React.useState<Role[]>();
  const [screenData, setScreenData] = React.useState<Screen[]>();
  const [roleModalData, setRoleModalData] = React.useState<Role>();
  const [isOpenEditRoleModal, setIsOpenEditRoleModal] =
    React.useState<boolean>(false);

  const getAllRole = async () => {
    try {
      const res = await configuredRequests.GET.getRoles();
      if (res) {
        const sortedRolesData = orderBy(
          res.roles,
          ['roleStatus', (roles) => roles?.roleName?.toLowerCase()],
          ['asc', 'asc']
        );
        setRoleInformationData(sortedRolesData);
      }
    } catch (err) {}
  };

  const getAllScreen = async (options?: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
  }) => {
    try {
      const res = await configuredRequests.GET.getScreens();
      setScreenData(res.screens);
    } catch (err) {}
  };

  useEffect(() => {
    Promise.all([getAllRole(), getAllScreen()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const openRoleInformationModal = (idValue: string) => {
    const roleData = roleInformationData
      ? roleInformationData.filter((items) => items.roleId === idValue)
      : [];
    setIsOpenEditRoleModal(idValue !== '');
    setRoleModalData(roleData[0]);
    setDisplayRoleModal(true);
  };

  return (
    <div className='communication-container user-setting w-300 roles-table setting-detail setting-scroll--custom'>
      {isLoading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
            <CommunicationTable
              columns={columns}
              dataTable={roleInformationData || []}
              isSort={false}
              isDisplayedSearchAndFilter={true}
              isDisplayedSearch={true}
              isDisplayedFilter={true}
              isDisplayedButton={true}
              optionsForFilter={optionsForFilter}
              keySearch='name'
              placeholderSearch='Search by role name'
              buttonName='New Role'
              iconButton={['fas', 'user-cog']}
              isLoading={isLoading}
              screen={'ROLE'}
              openModal={(idValue: string) => {
                openRoleInformationModal(idValue);
              }}
            />
          </div>

          {displayRoleModal && (
            <RoleModal
              handleReloadRoleList={getAllRole}
              displayRoleModal={displayRoleModal}
              setDisplayRoleModal={setDisplayRoleModal}
              titleText={isOpenEditRoleModal ? 'Edit' : 'Add New'}
              roleData={roleModalData}
              screenData={screenData}
              isEdit={isOpenEditRoleModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RoleSetting;
