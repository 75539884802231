import styled, { InterpolationFunction, ThemeProps } from 'styled-components';

import getThemeProp from 'components/Legwork/utils/getThemeProp';
import Button from '@material-ui/core/Button';

export interface ObjectMap {
  [key: string]: string | number;
}

const IconDiv = styled.div`
  flex: 0.8;
`;
const Main = styled.div`
  display: flex;

  width: 100%;
  min-height: 40px;
  padding: 5px 0px;
  min-width: 100%;
  background-color: ${getThemeProp('palette.background.default')};
`;

const StyledCard = styled(Button)`
  && {
    flex: 3;
    margin: 0px 3px;
    background-color: ${(props: {
      selected: boolean;
    }): string | InterpolationFunction<ThemeProps<ObjectMap>> =>
      props.selected ? getThemeProp('palette.border.main') : 'white'};

    text-transform: none;
  }
`;

const StyledTab = styled.div`
  .MuiButtonBase-root {
    background-color: white;
    border-radius: 4px;
    margin: 0px 4px 0 4px;
    max-width: 50px;

    & .Mui-selected {
      background-color: grey;
      color: white;
    }
  }
`;

// MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary Mui-selected
export { IconDiv, Main, StyledCard, StyledTab };
