import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import { ProviderModal } from 'global/requests/ResponseTypes/GetProvider';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { compareTimePicker } from '../Availability/helpers/compareTimePicker';
import ProviderAvailabilityV from './ProviderAvailabilityV';
import { IProviderUnvailability } from './ProvidersModal';
import toast from 'components/Global/Toast';
import { AvailabilityContext } from '../Availability/context/Availability';

export interface ListDate {
  dayOfWeekName: string;
  active: boolean;
  startTime: string;
  endTime: string;
  takeLunch: boolean;
  startTimeLunch: string;
  endTimeLunch: string;
  dayOrder: number;
  error?: string;
  errorLunch?: string;
}

export interface OfficeEvent {
  id: string;
  name: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  allDayEvent: boolean;
  repeat: number;
  repeatLabel: string;
  repeatEndDate: string;
  repeatEndDateLabel: string;
  tenantId: string;
  providerId: string;
}

interface ProviderAvailabilityVMProps {
  openPopup: boolean;
  providerProfile: ProviderModal;
  listDate: ListDate[];
  listOfficeOff: OfficeEvent[];
  dirtyProfile: boolean;
  dirtyAvailability: boolean;
  statusProviderUnvailability: IProviderUnvailability;
  setStatusProviderUnvailability: (arg: IProviderUnvailability) => void;
  setListOfficeOff: (office: OfficeEvent[]) => void;
  setListDate: (dates: ListDate[]) => void;
  setProviderProfile: (provider: ProviderModal) => void;
  setSumActiveTabs: (tabs: number) => void;
  setActiveTab: (tab: number) => void;
  setDisplayRoleModal: (arg: boolean) => void;
  getProviderList: () => void;
  setDirtyAvailability: (arg: boolean) => void;
  handleCloseForm: any;
}

const ProviderAvailabilityVM = (
  props: ProviderAvailabilityVMProps
): JSX.Element => {
  const {
    setSumActiveTabs,
    setActiveTab,
    setDisplayRoleModal,
    setProviderProfile,
    getProviderList,
    setListDate,
    setDirtyAvailability,
    setListOfficeOff,
    statusProviderUnvailability,
    setStatusProviderUnvailability,
    listOfficeOff,
    listDate,
    openPopup,
    handleCloseForm,
    providerProfile,
    dirtyProfile,
    dirtyAvailability,
  } = props;
  const { setDisabledSubmit } = useContext(AvailabilityContext);

  // @ts-ignore
  // const [listOfficeOff, setListOfficeOff] = useState<OfficeEvent[]>(providerProfile?.outOfOfficeEventInfos || [])
  const [isShowModalAddOfficeEvent, changeShowModalAddOfficeEvent] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<OfficeEvent | null>(null);
  const [isLoadingHourOfOperation, setIsLoadingHourOfOperation] =
    useState<boolean>(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false);
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [isSelectedDelete, setIsSelectedDelete] = useState<boolean>(false);
  // const [dirtyUnvailability, setDirtyUnvailability] = useState<boolean>(false);

  const [errorExistEventName, setErrorExistEventName] =
    React.useState<string>('');

  const onChangeErrorExistEventName = useCallback((error: string) => {
    setErrorExistEventName(error);
  }, []);

  const onChangeIsDirtyForm = useCallback((isDirty: boolean) => {
    setIsDirtyForm(isDirty);
  }, []);

  const sortListFollowDate = (listOffice: OfficeEvent[]): OfficeEvent[] => {
    listOffice.sort((officeA: OfficeEvent, officeB: OfficeEvent) => {
      let a = new Date(officeA.eventDate);
      let b = new Date(officeB.eventDate);
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    return listOffice;
  };

  const getOfficeUnavailabilty = useCallback(async () => {
    // @ts-ignore
    providerProfile?.outOfOfficeEventInfos?.length > 0 &&
      setListOfficeOff(
        sortListFollowDate(
          // @ts-ignore
          providerProfile?.outOfOfficeEventInfos.map(
            // @ts-ignore
            (officeEvent: OfficeEvent) => ({
              ...officeEvent,
            })
          )
        )
      );
  }, [providerProfile, setListOfficeOff]);

  const deleteOutOfOfficeEvent = useCallback(
    async (id: string) => {
      try {
        const response = await configuredRequests.DEL.deleteOfficeEvent(id);
        // @ts-ignore
        if (response.status === 200) {
          toast.success('Your data has been deleted successfully!');
          // setProviderProfile({ ...providerProfile, outOfOfficeEventInfos: [...newListOfficeOff] });
          // @ts-ignore
          setListOfficeOff((prevList) =>
            // @ts-ignore
            [...prevList].filter((item) => item.id !== response?.data.result)
          );
          getProviderList();
          setIsSelectedDelete(false);
          setSelectedEvent(null);
        } else {
          toast.error('Your data has not been deleted yet. Please try again.');
        }
      } catch (error) {
        toast.error('Your data has not been deleted yet. Please try again.');
      }
    },
    [getProviderList, setListOfficeOff]
  );

  const createOutOfOfficeEvent = useCallback(
    async (payload: OutOfOfficeEvent) => {
      // @ts-ignore
      delete payload.tenantId;
      const newPayload = { providerId: providerProfile.id, ...payload };
      try {
        setDisabledSubmit(true);
        const response = await configuredRequests.POST.createOutOfOfficeEvent(
          newPayload
        );
        // @ts-ignore
        if (response.status === 200) {
          setErrorExistEventName('');
          toast.success('Your data has been saved successfully!');
          // setProviderProfile({ ...providerProfile, outOfOfficeEventInfos: [...newListOfficeOff] });
          // @ts-ignore
          setListOfficeOff((prevList: OfficeEvent[]) =>
            sortListFollowDate([...prevList, (response?.data as any).result])
          );
          getProviderList();
          // getOfficeUnavailabilty();
          changeShowModalAddOfficeEvent(false);
          setIsDirtyForm(false);
          setDisabledSubmit(false);
        } else {
          toast.error('Your data has not been saved yet. Please try again.');
          setDisabledSubmit(false);
        }
      } catch (error) {
        setDisabledSubmit(false);
        if (error.response) {
          setErrorExistEventName(error.response.data.errorMsg);
        }
        toast.error('Your data has not been saved yet. Please try again.');
      }
    },
    [getProviderList, providerProfile.id, setListOfficeOff]
  );

  const updateListOfficeOff = (
    newOffice: OfficeEvent,
    listOffice: OfficeEvent[]
  ): OfficeEvent[] => {
    let idx = listOffice.findIndex((item) => item.id === newOffice.id);
    return sortListFollowDate([
      ...listOffice.slice(0, idx),
      newOffice,
      ...listOffice.slice(idx + 1),
    ]);
  };

  const updateOutOfOfficeEvent = useCallback(
    async (payload: OutOfOfficeEvent) => {
      // @ts-ignore
      delete payload.tenantId;
      const newPayload = { providerId: providerProfile.id, ...payload };
      try {
        setDisabledSubmit(true);
        const response = await configuredRequests.PATCH.updateEventOffice(
          newPayload
        );
        // @ts-ignore
        if (response.status === 200) {
          setErrorExistEventName('');
          toast.success('Your data has been updated successfully!');
          // setProviderProfile({ ...providerProfile, outOfOfficeEventInfos: [...newListOfficeOff] });
          // @ts-ignore
          setListOfficeOff((prevList) =>
            // @ts-ignore
            updateListOfficeOff(response?.data?.result, prevList)
          );
          getProviderList();
          changeShowModalAddOfficeEvent(false);
          setIsDirtyForm(false);
          setSelectedEvent(null);

          setDisabledSubmit(false);
        } else {
          toast.error('Your data has not been updated yet. Please try again.');
          setDisabledSubmit(false);
        }
      } catch (error) {
        setDisabledSubmit(false);
        if (error.response) {
          setErrorExistEventName(error.response.data.errorMsg);
        }
        toast.error('Your data has not been updated yet. Please try again.');
      }
    },
    [getProviderList, providerProfile.id, setListOfficeOff, updateListOfficeOff]
  );

  const getHoursOfOperation = useCallback(async () => {
    try {
      setIsLoadingHourOfOperation(true);
      // @ts-ignore
      if (providerProfile?.hoursOfOperationDto?.value.length > 0) {
        // @ts-ignore
        setListDate(providerProfile?.hoursOfOperationDto?.value);
      }
      setIsLoadingHourOfOperation(false);
    } catch (error) {
      setIsLoadingHourOfOperation(false);
    }
  }, [providerProfile, setListDate]);

  const onChangeDateTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTime)
            : compareTimePicker(selectedDate?.startTime, value);
        setDirtyAvailability(true);
        // @ts-ignore
        setListDate((preListDate: ListDate[]) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [name]: value,
                  error: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
      }
    },
    [listDate, setDirtyAvailability, setListDate]
  );

  const onChangeLucnhTime = useCallback(
    (dayOrder: number, event: any) => {
      const { name, value } = event.target;
      const convertName =
        name === 'startTime' ? 'startTimeLunch' : 'endTimeLunch';
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime =
          name === 'startTime'
            ? compareTimePicker(value, selectedDate?.endTimeLunch)
            : compareTimePicker(selectedDate?.startTimeLunch, value);
        setDirtyAvailability(true);
        // @ts-ignore
        setListDate((preListDate: ListDate[]) => {
          const newListDate = preListDate.map((listDate) => {
            return listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  [convertName]: value,
                  errorLunch: isStartTimeAfterEndTime
                    ? 'The end time must be greater than start time.'
                    : '',
                }
              : { ...listDate };
          });
          return newListDate;
        });
      }
    },
    [listDate, setDirtyAvailability, setListDate]
  );

  const onShowModalAddOfficeEvent = useCallback(() => {
    changeShowModalAddOfficeEvent(true);
  }, []);

  const onShowModalEditEvent = useCallback(
    (officeEvent: OfficeEvent | null) => {
      changeShowModalAddOfficeEvent(true);
      setSelectedEvent(officeEvent);
    },
    []
  );

  const onShowModalDeleteEvent = useCallback(
    (officeEvent: OfficeEvent | null) => {
      setIsSelectedDelete(true);
      setSelectedEvent(officeEvent);
    },
    []
  );

  const onShowModalCornfirm = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseModalConfirmModal = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const onCloseModalAddOfficeEvent = useCallback(() => {
    changeShowModalAddOfficeEvent(false);
    setSelectedEvent(null);
    setErrorExistEventName('');
  }, []);

  const onChangeToogle = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTime,
          selectedDate?.endTime
        );
        const isStartLunchAfterEndTimeLunch = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        setDirtyAvailability(true);
        // @ts-ignore
        setListDate((preListDate: ListDate[]) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  active: !listDate.active,
                  error:
                    !listDate.active && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                  errorLunch:
                    !listDate.active && isStartLunchAfterEndTimeLunch
                      ? 'The end time must be greater than start time.'
                      : '',
                  takeLunch: !listDate.active ? true : false,
                }
              : { ...listDate }
          );
          return newListDate;
        });
      }
    },
    [listDate, setDirtyAvailability, setListDate]
  );

  const handleChangeUnvailability = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDirtyAvailability(true);
    // setDirtyUnvailability(true);
    setStatusProviderUnvailability({
      ...statusProviderUnvailability,
      [event.target.name]: event.target.checked,
    });
  };

  const onChangeToogleLunch = useCallback(
    (dayOrder: number) => {
      const selectedDate = listDate.find(
        (date: ListDate) => date.dayOrder === dayOrder
      );
      if (!isEmpty(selectedDate)) {
        setDirtyAvailability(true);
        const isStartTimeAfterEndTime = compareTimePicker(
          selectedDate?.startTimeLunch,
          selectedDate?.endTimeLunch
        );
        // @ts-ignore
        setListDate((preListDate: ListDate[]) => {
          const newListDate = preListDate.map((listDate) =>
            listDate.dayOrder === dayOrder
              ? {
                  ...listDate,
                  takeLunch: !listDate.takeLunch,
                  errorLunch:
                    !listDate.takeLunch && isStartTimeAfterEndTime
                      ? 'The end time must be greater than start time.'
                      : '',
                }
              : { ...listDate }
          );
          return newListDate;
        });
      }
    },
    [listDate, setDirtyAvailability, setListDate]
  );

  const updateWorkingHours = useCallback(async () => {
    try {
      const isNotExistedError = listDate.every(
        (date: ListDate) => !date.error && !date.errorLunch
      );
      if (isNotExistedError) {
        const response = await configuredRequests.PATCH.updateWorkingHours(
          // @ts-ignore
          providerProfile.id,
          listDate,
          statusProviderUnvailability.accepted,
          statusProviderUnvailability.requested
        );
        // @ts-ignore
        if (response.status === 200) {
          setDirtyAvailability(false);
          setProviderProfile({
            ...providerProfile,
            // @ts-ignore
            hoursOfOperationDto: {
              ...providerProfile.hoursOfOperationDto,
              value: listDate,
            },
            outOfOfficeEventInfos: listOfficeOff,
            isUnavaliableAccepted: statusProviderUnvailability.accepted,
            isUnavaliablePending: statusProviderUnvailability.requested,
          });
          getProviderList();
          setActiveTab(dirtyProfile ? 0 : 2);
          toast.success('Your data has been saved successfully!');
        } else {
          toast.error('Your data has not been saved yet. Please try again.');
        }
      }
    } catch (error) {
      toast.error('Your data has not been saved yet. Please try again.');
    }
  }, [
    dirtyProfile,
    getProviderList,
    listDate,
    listOfficeOff,
    providerProfile,
    setActiveTab,
    setDirtyAvailability,
    setProviderProfile,
    statusProviderUnvailability,
  ]);

  useEffect(() => {
    Promise.all([getHoursOfOperation(), getOfficeUnavailabilty()]).then(
      () => {}
    );
  }, [getHoursOfOperation, getOfficeUnavailabilty]);

  return (
    <ProviderAvailabilityV
      onChangeDateTime={onChangeDateTime}
      onChangeLucnhTime={onChangeLucnhTime}
      onChangeToogle={onChangeToogle}
      // dirtyUnvailability={dirtyUnvailability}
      onShowModalEditEvent={onShowModalEditEvent}
      onShowModalDeleteEvent={onShowModalDeleteEvent}
      onChangeToogleLunch={onChangeToogleLunch}
      onShowModalAddOfficeEvent={onShowModalAddOfficeEvent}
      onCloseModalAddOfficeEvent={onCloseModalAddOfficeEvent}
      updateWorkingHours={updateWorkingHours}
      onChangeIsDirtyForm={onChangeIsDirtyForm}
      onChangeErrorExistEventName={onChangeErrorExistEventName}
      onShowModalCornfirm={onShowModalCornfirm}
      onCloseModalConfirmModal={onCloseModalConfirmModal}
      createOutOfOfficeEvent={createOutOfOfficeEvent}
      deleteOutOfOfficeEvent={deleteOutOfOfficeEvent}
      updateOutOfOfficeEvent={updateOutOfOfficeEvent}
      setSumActiveTabs={setSumActiveTabs}
      setActiveTab={setActiveTab}
      setDisplayRoleModal={setDisplayRoleModal}
      handleChangeUnvailability={handleChangeUnvailability}
      setIsSelectedDelete={setIsSelectedDelete}
      statusProviderUnvailability={statusProviderUnvailability}
      handleCloseForm={handleCloseForm}
      isShowModalAddOfficeEvent={isShowModalAddOfficeEvent}
      listDate={listDate}
      isLoadingHourOfOperation={isLoadingHourOfOperation}
      isLoadingEvent={isLoadingEvent}
      isSelectedDelete={isSelectedDelete}
      selectedEvent={selectedEvent}
      isDirtyForm={isDirtyForm}
      isShowConfirmModal={isShowConfirmModal}
      listOfficeOff={listOfficeOff}
      errorExistEventName={errorExistEventName}
      dirtyAvailability={dirtyAvailability}
    />
  );
};

export default React.memo(ProviderAvailabilityVM);
