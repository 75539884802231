const styles = {
  timeSlot: {
    minWidth: '60px',
    height: '36px',
    border: '1px solid #ccc',
    margin: '0 0px 10px 0',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '11px'
  },
  timeSlotHover: {
    backgroundColor: 'rgba(76, 124, 253, .5)',
  },
  timeSlotText: {
    margin: '0',
  },
  timeSlotSelected: {
    backgroundColor: '#4C7CFD',
    color: 'white',
  },
};

export default styles;
