import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

const StyledMainTypography = styled(Typography)`
  font-weight: ${({ read }: { read: boolean }): string => (read ? '500' : '700')};
`;
const StyledMListItem = styled(ListItem)`
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  background-color: ${({ selected }: { selected: boolean }): string =>
    selected ? '#F5F8FB' : 'white'};
`;

const StyledTimeTypography = styled(Typography)`
  color: ${({ read }: { read: boolean | string }): string => (read ? '#606D7E' : '#4C7CFD')};
  font-weight: 400;
  font-size: 0.8rem;
`;

const StyledSubTypography = styled(Typography)`
  color: ${({ read }: { read: boolean | string }): string => (read ? '#606D7E' : 'black')};
  font-weight: 400;
  font-size: 0.9rem;
`;

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 16px;
  margin-bottom: -3px;
  padding-right: 1px;
`;

const StyledItemAvatar = styled(ListItemAvatar)`
  margin-right: -15px;
`;

export {
  StyledCheckIcon,
  StyledMListItem,
  StyledItemAvatar,
  StyledMainTypography,
  StyledSubTypography,
  StyledTimeTypography,
};
