import styled from 'styled-components';
import { Button } from '@material-ui/core';
import getThemeProp from '../../utils/getThemeProp';

const DialogSecondaryButton = styled(Button)`
  font-size: 16px;
  color: ${getThemeProp('palette.text.secondary', 'blue')};
  font-weight: 500;
`;
export default DialogSecondaryButton;
