import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import toast from 'components/Global/Toast';
import { ErrorMessage, FastField, Formik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Medical } from 'global/requests/ResponseTypes/Medical';
import React, { Fragment, SetStateAction, useState } from 'react';
import ReactModal from 'react-modal';
import * as Yup from 'yup';
ReactModal.setAppElement('div#root');

type Props = {
  service: Medical;
  onCloseModal: React.Dispatch<SetStateAction<Medical | undefined>>;
  onReload: () => void;
};

const ErrorMessageTypes = {
  required: 'This field is required.',
};

const validateSchema = Yup.object().shape({
  medicalServiceName: Yup.string().required(ErrorMessageTypes.required),
});

const AddNewService = (props: Props) => {
  const { service, onCloseModal, onReload } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const initialValues = {
    medicalServiceName: service.medicalServiceName,
    isShowInClientPortal: service.isShowInClientPortal,
    isCompliance: service.isCompliance,
  };

  const handleConfirmPopup = () => {
    setDirtyForm(false);
    setOpenPopup(false);
    onCloseModal(undefined);
  };

  const handleSubmitData = async (
    values: Pick<
      Medical,
      'medicalServiceName' | 'isShowInClientPortal' | 'isCompliance'
    >
  ) => {
    try {
      setDisabledSubmit(true);
      const submitData = {
        medicalServiceId: service.medicalServiceId,
        medicalServiceName: values.medicalServiceName,
        isShowInClientPortal: !!values.isShowInClientPortal,
        isCompliance: !!values.isCompliance,
      };
      if (service.order !== undefined) {
        // @ts-ignore
        submitData.order = service.order;
      }
      const res = await configuredRequests.PUT.updateMedicalService(submitData);
      if (res) {
        onReload();
        setDirtyForm(false);
        onCloseModal(undefined);
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      setDisabledSubmit(false);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (dirtyForm) {
      setOpenPopup(true);
    } else {
      onCloseModal(undefined);
    }
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-appointment-type'
      isOpen={!!service.medicalServiceId}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        validateOnMount={true}
        onSubmit={(values) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const { handleSubmit, handleBlur, setFieldValue, values, dirty } =
            formikProps;
          if (dirty) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>Edit Service</p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment form-add-appointment-type'>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row'>
                      <div className='form-input-settings'>
                        <span className='title-input-form'>
                          Service Name<b className='require'> *</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            maxLength={255}
                            name='medicalServiceName'
                            required
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'medicalServiceName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='medicalServiceName' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <AntSwitch
                        name='isShowInClientPortal'
                        checked={values.isShowInClientPortal}
                        onChange={() => {
                          values.isShowInClientPortal &&
                            setFieldValue('isCompliance', false);
                          setFieldValue(
                            'isShowInClientPortal',
                            !values.isShowInClientPortal
                          );
                          setDirtyForm(true);
                        }}
                        label='Display in Client Portal'
                        labelPlacement='start'
                        classMore='width-50'
                      />
                      <AntSwitch
                        name='isCompliance'
                        checked={values.isCompliance}
                        onChange={() => {
                          if (values.isShowInClientPortal) {
                            setFieldValue('isCompliance', !values.isCompliance);
                            setDirtyForm(true);
                          }
                        }}
                        label='Display in Compliance'
                        labelPlacement='start'
                        classMore={`width-50 ${
                          !values.isShowInClientPortal && 'disabled'
                        }`}
                      />
                    </div>
                  </form>
                </Fragment>
              </div>
              <div className='react-modal-settings-footer modal-appointment-type-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid || disabledSubmit}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        isOpenModal={openPopup}
        title='Discard Charges?'
        handleCloseModal={setOpenPopup}
        handleConfirmModal={handleConfirmPopup}
      />
    </ReactModal>
  );
};
export default AddNewService;
