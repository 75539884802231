import styled from 'styled-components';
import { Link } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
`;

const Container = styled.div`
  display: inline-block;
`;

export { StyledLink, Container };
