import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import toast from 'components/Global/Toast';

export interface DeleteFormModalProps {
  open: boolean;
  form: any;
  handleClose: () => void;
  updateForms: () => void;
}

const DeleteFormModal = (props: DeleteFormModalProps): React.ReactElement | null => {
  const { open, form, handleClose, updateForms } = props;

  const deleteForm = async (formId: string): Promise<void> => {
    try {
      const response = await configuredRequests.DEL.removeForm(formId);
      if (response.status === 200) {
        await updateForms();
      } else {
        toast.error("There is an error happening. Please try again later.");
      }
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
      // handle error
    }
  };

  const handleSuccess = async (): Promise<void> => {
    if (!form.id) handleClose(); // do error stuff?
    await deleteForm(form.id);
  };
  if (!form) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id="delete-form-modal"
    >
      <DialogContent>
        <DialogContentText className='delete-modal-text' style={{ fontFamily: 'Nunito Sans, Calibri, Arial', fontSize: '16px', color: '#12253F' }}>
          Are you sure you want to delete '{form.form_name}'? This can't be undone.
        </DialogContentText>
        <br />
        <DialogContentText className='delete-modal-text' style={{ fontFamily: 'Nunito Sans, Calibri, Arial', fontSize: '16px', color: '#12253F' }}>
          Make sure you aren't using the embed code on any external pages.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="footer-delete-modal">
        <Button onClick={handleClose} style={{ color: '#6E84A3', fontSize: '16px' }}>
          Cancel
        </Button>
        <Button onClick={handleSuccess} style={{ color: '#E03229', fontSize: '16px' }} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFormModal;
