import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledInput = styled(InputBase)`
  .MuiInputBase-input {
    position: relative;
    /* background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')}; */
    font-size: 0.9rem;
    max-width: 100%;
    border-radius: 6px !important;
    border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
    padding: 9px 13px;
  }
  &.Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
  .MuiSelect-icon {
    right: 10;
  }
  .Mui-disabled {
    background-color: ${getThemeProp('palette.action.disabled', '#e9eef7')};
    color: ${getThemeProp('palette.text.primary', '#12253f')};
  }
`;
const StyledInputLabel = styled(InputLabel)`
  margin: 1rem 0 0.5rem 1px;
`;

const Main = styled.div``;

export { StyledInput, StyledInputLabel, Main };
