import React from 'react';
import styled from 'styled-components';
import logo from '../../media/images/Vet-Hero-Frenchie.png';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 20rem;
  }

  .message {
    margin-bottom: 5rem;
    font-size: 1.5rem;
    font-style: italic;

    .link-refresh {
      color: #4c7cfd;
      text-decoration: underline;
    }
  }
`;

const NotAccessHP24 = () => {
  return (
    <Wrapper>
      <img src={logo} className="logo" />
      <p className="message">
        You've been logged out of the HealthPay24 provider portal due to inactivity. Click <a className="link-refresh" onClick={() => window.parent.location.reload()}>here</a> to resume.
    </p>
    </Wrapper>
  )
}

export default NotAccessHP24
