import React from 'react';
import withComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import 'react-image-lightbox/style.css';
import {
  Tooltip,
  Checkbox,
  makeStyles
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InteractionCountAndTogglesProps {
  pendingCount: any,
  sentCount: any,
  failedCount: any,
  cancelledCount: any,
  setShowPending: any,
  setShowSent: any,
  setShowFailed: any,
  setShowCancelled: any,
  showPending: any,
  showSent: any,
  showFailed: any,
  showCancelled: any,
}

const statDesc = (
  <>
    <p>
      This pulls data from the previous 30 days and also all future pending communication
    </p>
  </>
)

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 200,
  },
  customPading: {
    paddingTop: 30,
  },
  tooltip: {
    background: '#E3E6EE',
    borderRadius: '8px',
    color: '#1A252E',
    fontSize: '12px'
  },
}));

const InteractionCountAndToggles = (props: InteractionCountAndTogglesProps) => {
  return (
    <div style={{ "display": "flex", "justifyContent": "space-between", "paddingRight": "27px" }} className="view-contact--interaction-entry-body">
      <div>
        <div className="view-contact--interaction-title" style={{ color: "#1974ff" }}>
          <span>
            Interaction Stats
          </span>
          <Tooltip
            placement="right"
            title={statDesc}
            arrow
            classes={{ tooltip: useStyles().tooltip }}
          >
            <span className='questions'>
              <FontAwesomeIcon
                icon={['fas', 'question-circle']}
              />
            </span>
          </Tooltip>
        </div>
        <span className='view-contact--interaction-status style-icon-status default'>Sent Interactions: <b>{props.sentCount}</b></span><br/>
        <span className='view-contact--interaction-status style-icon-status pending'>Pending Interactions: <b>{props.pendingCount}</b></span><br/>
        <span className='view-contact--interaction-status style-icon-status failed'>Unsent/Failed Interactions: <b>{props.failedCount}</b></span><br/>
        <span className='view-contact--interaction-status style-icon-status cancelled'>Cancelled Interactions: <b>{props.cancelledCount}</b></span><br/>
      </div>
      <div>
        <div className="view-contact--interaction-title" style={{ color: "#1974ff" }}>
          <span>Interaction Filters</span>
        </div>
        <div className='d-flex allow-sending-outside padding-left-17' style={{ "padding": "0", "marginTop": "0" }}>
          <Checkbox
            checked={props.showSent}
            color='primary'
            onChange={() => props.setShowSent(!props.showSent)}
            className='check-schedule'
          />
          <span>Show sent messages</span>
        </div>
        <div className='d-flex allow-sending-outside padding-left-17' style={{ "padding": "0", "marginTop": "0" }}>
          <Checkbox
            checked={props.showPending}
            color='primary'
            onChange={() => props.setShowPending(!props.showPending)}
            className='check-schedule'
          />
          <span>Show pending messages</span>
        </div>
        <div className='d-flex allow-sending-outside padding-left-17' style={{ "padding": "0", "marginTop": "0" }}>
          <Checkbox
            checked={props.showFailed}
            color='primary'
            onChange={() => props.setShowFailed(!props.showFailed)}
            className='check-schedule'
          />
          <span>Show failed messages</span>
        </div>
        <div className='d-flex allow-sending-outside padding-left-17' style={{ "padding": "0", "marginTop": "0" }}>
          <Checkbox
            checked={props.showCancelled}
            color='primary'
            onChange={() => props.setShowCancelled(!props.showCancelled)}
            className='check-schedule'
          />
          <span>Show cancelled messages</span>
        </div>
      </div>
    </div>
  )
};

const ComponentizedInteractionCountAndToggles = withComponentClassAndHeaderText(InteractionCountAndToggles);

export default ComponentizedInteractionCountAndToggles;
