import * as t from 'io-ts';

export const DriverConfigurationResponseType = t.type({
    jsonObject: t.string,
    allowAddingContactOrPet: t.boolean,
    allowUpdatingContactOrPet: t.boolean,
    allowAddingAppointment: t.boolean,
    allowUploadingPdf: t.boolean,
    allowTextMessageWriteback: t.boolean,
});
export type DriverConfigurationResponseType = t.TypeOf<typeof DriverConfigurationResponseType>;