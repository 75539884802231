import * as t from 'io-ts';

const PaySettingType = t.partial({
  id: t.string,
  key: t.string,
  hostUrl: t.string,
  enviromentId: t.string,
  webhookId: t.string,
  webhookKey: t.string,
  accountBillingMode: t.string
});

export type PaySettingType = t.TypeOf<typeof PaySettingType>;

export const PaySettingResponseType = t.type({
  namespace: t.string,
  settings: PaySettingType,
});

export type PaySettingResponseType = t.TypeOf<typeof PaySettingResponseType>;