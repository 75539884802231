import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { createAxiosInstance } from './axios';

export interface IHttp {
  instance: AxiosInstance;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, body?: object, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, body?: object, config?: AxiosRequestConfig): Promise<T>;
}

class AxiosHttp implements IHttp {
  public instance: AxiosInstance;

  public constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  public get = async <T extends unknown>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    this.instance.get<T, T>(url, config);

  public post = async <T extends unknown>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.instance.post<T, T>(url, body, config);

  public patch = async <T extends unknown>(
    url: string,
    body?: object,
    config?: AxiosRequestConfig,
  ): Promise<T> => this.instance.patch<T, T>(url, body, config);
}

export const getHttp = (baseURL: string): IHttp => new AxiosHttp(createAxiosInstance(baseURL));
