import React from 'react'
import Header from 'components/Global/Header/Header'
import Sidebar from './Sidebar'
import { sideBarData } from './SidebarDataPermissions'
import {
  NavLinkProps,
  MainComponentProps,
} from 'types/NavigationT'
import { RouterProps } from 'types/RouterT'
import List from 'components/Global/Title/List/List'
import { getSessionStateNavbarIsOpen } from 'global/sessionStorage/SessionNavbarState'
import { configuredRequests } from 'global/requests/ConfiguredRequests'
import {
  getSessionUserData,
  setSessionUserData,
  getSessionTenantInfo,
  setSessionTenantInfo,
} from 'global/sessionStorage/SessionAPIResponses'
import { TenantInfoResponse } from 'global/requests/ResponseTypes/Tenants'
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration'
import { useDecodeToken } from 'hooks/useDecodeToken';
import { useRouter } from 'hooks/useRouter';
import { isMobile } from 'react-device-detect';
import { getSessionStateSidebarIsOpen, setSessionStateSidebarIsOpen } from 'global/sessionStorage/SessionSidebarState';

const listAllowedPage = ['/Communications', '/Communications/Templates', '/Communications/Automation', '/Dashboard/Template/:urlSlug'];
interface NavigationLeftCollapsibleProps extends RouterProps {
  mainComponent: (props?: MainComponentProps) => JSX.Element
  mainComponentClasses?: string
  headerTitle: string,
}

export type StoredAPIResponseData = {
  userData?: UserDataResponse
  tenantInfo?: TenantInfoResponse
}

const NavigationLeftCollapsible = (
  props: NavigationLeftCollapsibleProps
): JSX.Element => {
  const router = useRouter();
  // Maybe not the best way to 'compose' but it works and I'm unaware of reasons that this might be a bad idea
  const Main = props.mainComponent
  const { allowedPath, roleUser } = useDecodeToken();
  const [storedAPIResponses, setStoredAPIResponses] = React.useState<StoredAPIResponseData>()
  const [headerNavLinks, setHeaderNavLinks] = React.useState<NavLinkProps[]>([]);

  const getCurrentUserData = React.useCallback(async () => {
    try {
      const res = await configuredRequests.GET.currentUserData()
      setSessionUserData(res)
      setStoredAPIResponses({
        ...storedAPIResponses,
        userData: res,
      })
      // addUserDataToLookup(res);
    } catch (err) {
      //TODO error handling and retry
    }
  }, [storedAPIResponses, setStoredAPIResponses])

  const getCurrentTenantInfo = React.useCallback(async () => {
    try {
      const res = await configuredRequests.GET.currentTenantInfo()
      setSessionTenantInfo(res)
      setStoredAPIResponses({
        ...storedAPIResponses,
        tenantInfo: res,
      })
    } catch (err) {
      //TODO error handling and retry
    }
  }, [storedAPIResponses, setStoredAPIResponses])

  // fetch the data if we don't have it or set state if we had data already
  React.useEffect(() => {
    const sessionTenantInfo = getSessionTenantInfo()
    const sessionUserData = getSessionUserData()

    const validatedTenantInfo = sessionTenantInfo
      ? sessionTenantInfo
      : undefined

    const validatedUserData = sessionUserData
      ? sessionUserData
      : undefined

    if (validatedTenantInfo && !storedAPIResponses?.tenantInfo) {
      setStoredAPIResponses({
        ...storedAPIResponses,
        tenantInfo: validatedTenantInfo,
      })
    }
    if (!validatedTenantInfo && !storedAPIResponses?.tenantInfo) {
      getCurrentTenantInfo()
    }

    if (validatedUserData && !storedAPIResponses?.userData) {
      setStoredAPIResponses({
        ...storedAPIResponses,
        userData: validatedUserData,
      })
    }
    if (!validatedUserData && !storedAPIResponses?.tenantInfo) {
      getCurrentUserData()
    }
  }, [
    storedAPIResponses,
    setStoredAPIResponses,
    getCurrentTenantInfo,
    getCurrentUserData,
  ])

  // a null value means there is no previous state, so use default (default is open at time of writing)
  const previousSessionNavWasOpen = getSessionStateNavbarIsOpen() // "true" | "false" | null
  const navInitialOpenState = () => {
    if (isMobile) return false;
    return previousSessionNavWasOpen === 'true' || previousSessionNavWasOpen === null
      ? true
      : false;
  }

  const sidebarInitialOpenState = (type: string) => {
    return getSessionStateSidebarIsOpen[type == "CRM" ? "CRM" : "Form"]() == 'true' || getSessionStateSidebarIsOpen[type == "CRM" ? "CRM" : "Form"]() == null
      ? true
      : false;
  }

  // default value will attempt to recover previous state
  const [sidebarIsOpen, setSidebarIsOpen] = React.useState<boolean>(navInitialOpenState());
  const [crmIsOpen, setCrmIsOpen] = React.useState<boolean>(sidebarInitialOpenState("CRM"));
  const [formIsOpen, setFormIsOpen] = React.useState<boolean>(sidebarInitialOpenState("Form"));
  const permittedSidebarData = sideBarData;

  if (!Array.isArray(permittedSidebarData)) {
    // console.log("redirecting", permittedSidebarData)/
    // TODO error handling
    return (
      <div
        style={{
          display: 'flex',
          margin: 'auto',
          width: 'max-content',
          top: '100px',
        }}
      >
        Unable to determine permissions.
        {/* This should never happen, if it does then the backend may be sending mismatched permissions */}
      </div>
    )
  };
  return (
    <>
      <Header
        logoHoverText={'Dashboard'}
        headerTitle={props.headerTitle}
        setRouterJWT={props.setRouterJWT}
        routerJWT={props.routerJWT}
        sidebarIsOpen={sidebarIsOpen}
        setSidebarIsOpen={setSidebarIsOpen}
        storedAPIResponses={storedAPIResponses}
      />{' '}
      {/*End header*/}
      <Sidebar
        sidebarLinkData={permittedSidebarData}
        sidebarIsOpen={sidebarIsOpen}
        allowedPath={allowedPath}
        setSidebarIsOpen={setSidebarIsOpen}
        crmIsOpen={crmIsOpen}
        formIsOpen={formIsOpen}
        setCrmIsOpen={setCrmIsOpen}
        setFormIsOpen={setFormIsOpen}
      />
      {
        // verify that there is data to render, otherwise return null
        headerNavLinks && headerNavLinks.length && (listAllowedPage.includes(router.pathname) || router.match.path === '/Dashboard/Template/:urlSlug') ? (
          <nav className="title__nav">
            <List listItemData={headerNavLinks} />
          </nav>
        ) : null
      }
      <div
        className={
          'navigation__main-view-container '
          + (props.mainComponentClasses ? props.mainComponentClasses : '')
          + (sidebarIsOpen ? ' open' : '')
          + (headerNavLinks && headerNavLinks.length && (listAllowedPage.includes(router.pathname) || router.match.path === '/Dashboard/Template/:urlSlug') ? ' has-header-nav-links' : '')
        }
        id="navigation-main"
      >
        <Main
          setHeaderNavLinks={setHeaderNavLinks}
          roleUser={roleUser}
        />
      </div>
    </>
  ) //end component return
}

export default NavigationLeftCollapsible
