/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';

export const RadioButtonGroupItem = ({ classNames, group, item, selected, onChange, disabled }) => {
  const handleChange = () => {
    onChange(item);
  };

  return (
    <div className={classNames}>
      <label>
        <input
          type='radio'
          name={group}
          value={item.value}
          checked={item.value == selected}
          onChange={handleChange}
          disabled={disabled}
        />
        {item.label}
      </label>
    </div>
  );
};

RadioButtonGroupItem.propTypes = {
  classNames: PropTypes.string,
  group: PropTypes.string.isRequired,
  item: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any.isRequired,
  }),
  onChange: PropTypes.func,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
};

RadioButtonGroupItem.defaultProps = {
  disabled: false,
};
