import React from 'react';
import { useActiveElement } from './useActiveElement';

// use this hook to close custom menus without using a blur event (for accessibility)
// the callback will only fire when the active element changes AND the new target does not have a class matching ANY classes in skipBlurClassList
export const useBlurCustomDropdown = (skipBlurClassList: string[], handleBlur: () => void) => {

  const domFocusTarget = useActiveElement();

  const shouldSkipBlur = () => {
    if (domFocusTarget?.className) {
      return (
        skipBlurClassList
          .filter((classString) => domFocusTarget.className.includes(classString))
          .length > 0
      );
    }
  };

  React.useEffect(() => {

    // console.log("shouldSkipBlur()", shouldSkipBlur());
    // console.log("domFocusTarget.className", domFocusTarget?.className);

    return (
      shouldSkipBlur()
        ? undefined
        : handleBlur()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domFocusTarget]);
};
