import React from 'react';
import { Button } from '@material-ui/core';
import Select from 'react-select';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';

interface Props {
  options: DurationType[];
  defaultValue?: string;
  isLoading: boolean;
  onSave: () => void;
  onChange: (value: string) => void;
  isShowText?: boolean;
}
export interface DurationType {
  value: string;
  label: string;
}
const OABIntervalSetting = (props: Props) => {
  const {
    defaultValue,
    isLoading,
    options,
    isShowText = true,
    onSave,
    onChange,
  } = props;

  return (
    <div className='oab-interval-setting'>
      <>
        {isLoading ? (
          <div className='loading-hours-info'>
            <Loading className='loading-hours-spin' />
          </div>
        ) : (
          <div className='flex1175'>
            <div className='oab-select form-input-settings'>
              <Select
                classNamePrefix='search-city'
                //@ts-ignore
                defaultValue={options.find(
                  (item) => item.value === defaultValue
                )}
                name='appointmentDurationMinute'
                className='full-width option-city w-100 select-duration select-avalability'
                // @ts-ignore
                placeholder={defaultValue.toString()}
                options={options}
                onChange={(e: any) => {
                  onChange(e.value);
                }}
                isSearchable={false}
                // maxMenuHeight={150}
              />
              {isShowText && <span className='duration-text'>Minutes</span>}
            </div>
            &nbsp;
            <Button
              onClick={onSave}
              variant='contained'
              color='primary'
              // disabled={listDate.some((date: ListDate) => date.error || date.errorLunch)}
              className='oab-save-button'
            >
              Save
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

export default OABIntervalSetting;
