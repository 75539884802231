import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';

const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(18, 38, 63, 0.4);
  font-size: 16px;
  .MuiAlert-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.MuiAlert-standardError {
    background-color: #fad2d1;
    .MuiAlert-icon svg {
      fill: #e03229;
    }
  }
  &.MuiAlert-standardInfo {
    background-color: #e1f0ff;
    .MuiAlert-icon svg {
      fill: #3068fd;
    }
  }
  &.MuiAlert-standardWarning {
    background-color: #fbf2d0;
    .MuiAlert-icon svg {
      fill: #f4b312;
    }
  }
  &.MuiAlert-standardSuccess {
    background-color: #e5f9f2;
    .MuiAlert-icon svg {
      fill: #31cc97;
    }
  }
`;

const CustomActionContainer = styled.div`
  button {
    color: inherit;
  }
`;

export { StyledAlert, CustomActionContainer };
