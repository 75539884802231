import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroupItem } from './checkbox-group-item';

export default class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selected: this.props.selected,
      });
    }
  }

  handleChange = (item, value) => {
    const { selected } = this.state;
    const index = selected.findIndex(el => el[0] === item.label);

    const nextState = { selected: [...selected] };
    nextState.selected[index] = [item.label, value];

    this.setState(nextState);
    this.props.onChange(nextState.selected, this.props.group);
  };

  render() {
    const { className, itemClassNames, group, items, disabled } = this.props;

    return (
      <div className={className || ''}>
        {items.map(item => (
          <CheckboxGroupItem
            key={item.label}
            classNames={itemClassNames}
            group={group}
            item={item}
            onChange={this.handleChange}
            disabled={disabled}
          >
            {item.label}
          </CheckboxGroupItem>
        ))}
      </div>
    );
  }
}

/**
 * @param {Array<Array<string, bool>>} selected This should be a 2d array with the inner array in the shape of [key<string>, checked<bool>].
 *                                              For example [['Box 1', true], ['Box 2', false]].
 */
CheckboxGroup.propTypes = {
  group: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemClassNames: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.any.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.array),
  disabled: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  disabled: false,
};
