import APIService from '../clientApi';
import {
  IPatientSurveyList,
  PatientSurveyListParam,
  NpsDataSurveyParam,
  INpsDataSurvey,
  IDataSurveyResult,
  PotentiallyAvailableParam,
  IPotentiallyAvailable,
  ReviewDashboardCountParam,
  IReviewDashboardCount,
  SocialAccountLocationListParam,
  ISocialAccountLocationList,
  GetReviewRequestsParam,
  IGetReviewRequests,
  IReviewAnalyticsChartParam,
  ReviewAnalyticsChart,
  SocialAccountReviewListParam,
  AccountReviewList,
  PotentialCustomerReviewersParam,
  IPotentialCustomerReviewers,
  AverageSurvey,
} from './patientExperienceTypes';

export const getTimezone = (): string | undefined => {
  let timezone;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Unable to determine timezone:', e);
  }

  return timezone;
};

const API = APIService();

// Api for Nps tab
const getPatientSurveyList = async (params: PatientSurveyListParam): Promise<IPatientSurveyList> =>
  API.rpc('', { method: 'nps_survey.getPatientSurveyList', params });

const getCurrentYearSurvey = async (params: NpsDataSurveyParam): Promise<INpsDataSurvey> =>
  API.rpc('', { method: 'nps_survey.getCurrentYearSurvey', params });

const getPreviousYearSurvey = async (params: NpsDataSurveyParam): Promise<INpsDataSurvey> =>
  API.rpc('', { method: 'nps_survey.getPreviousYearSurvey', params });

const npsDataSurveyResult = async (params: NpsDataSurveyParam): Promise<IDataSurveyResult> =>
  API.rpc('', { method: 'nps_survey.npsDataSurveyResult', params });

const npsAverageSurveyResult = async (params: []): Promise<AverageSurvey[]> =>
  API.rpc('', { method: 'nps_survey.averageSurveyResult', params }, undefined, true);

const deleteSurvey = async (params: [number]): Promise<void> =>
  API.rpc('', { method: 'nps_survey.deleteSurveyResult', params });

// Api for Review tab
const potentiallyAvailable = async (
  params: PotentiallyAvailableParam,
): Promise<IPotentiallyAvailable> =>
  API.rpc('', { method: 'social.reviews.arePotentiallyAvailable', params });

const reviewDashboardCount = async (
  params: ReviewDashboardCountParam,
): Promise<IReviewDashboardCount> =>
  API.rpc('', { method: 'social.reviews.reviewDashboardCount', params });

const socialAccountLocationList = async (
  params: SocialAccountLocationListParam,
): Promise<ISocialAccountLocationList> =>
  API.rpc('', { method: 'social.reviews.socialAccountLocationList', params });

const getReviewRequests = async (params: GetReviewRequestsParam): Promise<IGetReviewRequests> =>
  API.rpc('', { method: 'social.reviews.getReviewRequests', params });

const socialAccountReviewList = async (
  params: SocialAccountReviewListParam,
): Promise<AccountReviewList> =>
  API.rpc('', { method: 'social.reviews.socialAccountReviewList', params }, undefined, true);

const reviewAnalyticsChart = async (
  params: IReviewAnalyticsChartParam,
): Promise<ReviewAnalyticsChart> => {
  const timezone = getTimezone();

  return API.rpc(
    '',
    { method: 'social.reviews.reviewAnalyticsChart', params: [{ ...params, timezone }] },
    undefined,
    true,
  );
};

const getPotentialCustomerReviewers = async (
  params: PotentialCustomerReviewersParam,
): Promise<IPotentialCustomerReviewers> =>
  API.rpc('', { method: 'social.reviews.getPotentialCustomerReviewers', params });

export default {
  getPatientSurveyList,
  getCurrentYearSurvey,
  getPreviousYearSurvey,
  npsDataSurveyResult,
  potentiallyAvailable,
  reviewDashboardCount,
  socialAccountLocationList,
  getReviewRequests,
  socialAccountReviewList,
  reviewAnalyticsChart,
  getPotentialCustomerReviewers,
  npsAverageSurveyResult,
  deleteSurvey,
};
