import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import {
  TemplateData,
  PersonalizationTokensResponse,
} from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TemplateKindsEnum } from 'global/requests/api-route-types';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import InputWithLabel from 'components/Global/InputWithLabel/InputWithLabel';
import EditorBox from './EditorBox';
import toast from 'components/Global/Toast';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import Filter from 'components/Global/Filter/Filter';

export interface TypeOption {
  label: string;
  value: string;
}

export const optionTimes = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '1' },
];
export const optionUnitTimes = [
  { label: 'Hour(s)', value: 'hours' },
  { label: 'Day(s)', value: 'days' },
  { label: 'Week(s)', value: 'weeks' },
  { label: 'Month(s)', value: 'months' },
];

// the schema does not exist yet, this is just a placeholder until
export const initialTemplateValue = {
  templateId: '',
  name: '',
  categoryKind: '',
  templateKind: '',
  // targetUserTypeIds: [],
  templateSubject: '',
  templateBody: '',
  onOnlyOnceEvery: false,
  duration: 1,
  unitOfDuration: 'hours',
};

export const initialTokenValue: PersonalizationTokensResponse = {
  personalizationTokens: [
    {
      namespace: '',
      settings: {
        personalizationTokens: [
          {
            categoryId: '',
            categoryName: '',
            tokens: [],
          },
        ],
      },
    },
  ],
};

const EditEmailTemplate = (): JSX.Element => {
  const { templateId } = useParams<{ templateId: string }>();
  const { category } = useParams<{ category: string }>();
  const history = useHistory();

  const [templateData, setTemplateData] =
    React.useState<TemplateData>(initialTemplateValue);
  const [loading, setLoading] = React.useState(true);
  const [isEdited, setValueEdited] = React.useState('noChange');
  const [errorTemplateName, setErrorTemplateName] =
    React.useState<boolean>(false);
  const [errorEmailSubject, setErrorEmailSubject] =
    React.useState<boolean>(false);
  const [errorEmptyEmailBody, setErrorEmptyEmailBody] =
    React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [title, setTitle] = React.useState<string>('');

  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);

  const [openPopup, setOpenPopup] = React.useState(false);
  const [optionDurations, setOptionDurations] = useState(optionTimes);
  const [optionUnits, setOptionUnits] = useState(optionUnitTimes);

  const getTemplateAndTokenDataRequest = async () => {
    try {
      if (templateId) {
        const templateResponse = await configuredRequests.GET.templateByID(
          templateId
        );
        setTemplateData((prevTemplate) => ({
          ...prevTemplate,
          ...templateResponse,
        }));
        setTitle(templateResponse.name || '');
      }
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        // @ts-ignore
        setTokenData(tokenResponse);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getSettingDurationTimes = async () => {
    try {
      const res = await configuredRequests.GET.getSettingDurationTimes();
      if (res) {
        setOptionDurations(
          // @ts-ignore
          res.definedValues.map((item: any) => ({
            label: item.name,
            value: item.value.toString(),
          }))
        );
        setOptionUnits(
          // @ts-ignore
          res.unitKinds.map((item: any) => ({
            label: item.name,
            value: item.value,
          }))
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleGoBackRouter = () => {
    setValueEdited('noChange');
    setOpenPopup(false);
    history.goBack();
  };

  const handleOnChangeInput = (event: any) => {
    switch (event.target.name) {
      case 'templateName':
        if (event.target.value.trim().length) {
          setErrorTemplateName(false);
        } else {
          setErrorTemplateName(true);
          // break; // seems like this should break here
        }
        setTemplateData({ ...templateData, name: event.target.value });
        setValueEdited('hasChanged');
        break;

      case 'emailSubject':
        if (event.target.value.trim().length) {
          setErrorEmailSubject(false);
        } else {
          setErrorEmailSubject(true);
        }
        setTemplateData({
          ...templateData,
          templateSubject: event.target.value,
        });
        setValueEdited('hasChanged');
        break;

      default:
        break;
    }
  };

  const handleOnblur = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case 'templateName':
        if (value.trim().length) {
          setErrorTemplateName(false);
        } else {
          setErrorTemplateName(true);
        }
        setTemplateData({ ...templateData, name: value.trim() });
        break;

      case 'emailSubject':
        if (value.trim().length) {
          setErrorEmailSubject(false);
        } else {
          setErrorEmailSubject(true);
        }
        setTemplateData({ ...templateData, templateSubject: value.trim() });
        break;

      default:
        break;
    }
  };

  const handleOnClickSave = async () => {
    try {
      setIsDisabled(true);
      if (!templateId) {
        const createResponse = await configuredRequests.POST.createTemplate({
          ...templateData,
          name: templateData.name?.trim() ?? '',
          templateSubject: templateData.templateSubject
            ? templateData.templateSubject.trim()
            : '',
          templateBody: templateData.templateBody
            ? templateData.templateBody.trim()
            : '',
          templateKind: TemplateKindsEnum.email,
          categoryKind: category,
        });
        if (createResponse) {
          setValueEdited('noChange');
          toast.success('Your data has been saved successfully!');
          history.goBack();
        }
      } else {
        const updateResponse = await configuredRequests.PATCH.updateTemplate(
          templateId,
          {
            ...templateData,
            name: templateData.name?.trim() ?? '',
            templateSubject: templateData.templateSubject
              ? templateData.templateSubject.trim()
              : '',
            templateBody: templateData.templateBody
              ? templateData.templateBody.trim()
              : '',
          }
        );

        if (updateResponse) {
          setValueEdited('noChange');
          setTitle(updateResponse.name || '');
          toast.success('Your data has been saved successfully!');
          setIsDisabled(false);
        }
      }
    } catch (err) {
      setIsDisabled(false);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleOnChangeEditor = (data: string) => {
    const b = '<body>';
    const be = '</body>';
    const messageBody = data.substring(
      data.indexOf(b) + b.length,
      data.indexOf(be) - 1
    );

    if (!messageBody.trim()) {
      setErrorEmptyEmailBody(true);
    } else {
      setErrorEmptyEmailBody(false);
    }
    setValueEdited('hasChanged');
    setTemplateData({
      ...templateData,
      templateBody: data,
    });
  };

  const handleEmptyContent = () => {
    setErrorEmptyEmailBody(true);
    setIsDisabled(true);
  };

  React.useEffect(() => {
    if (
      !templateData.templateSubject ||
      !templateData.name ||
      !templateData.templateBody ||
      errorEmptyEmailBody
    )
      setIsDisabled(true);
    if (
      templateData.name &&
      templateData.name.trim().length &&
      templateData.templateSubject &&
      templateData.templateSubject.trim().length &&
      templateData.templateBody &&
      templateData.templateBody.length &&
      !errorEmptyEmailBody
    )
      setIsDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateData?.templateSubject,
    templateData?.name,
    templateData?.templateBody,
  ]);

  React.useEffect(() => {
    getTemplateAndTokenDataRequest();
    getSettingDurationTimes();
    window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('isEdited', JSON.stringify(isEdited));
  }, [isEdited]);

  return (
    <>
      {loading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <div className='confirmation-email-title'>
            <h2>{templateId ? title : 'Add New Template'}</h2>
            <Button
              variant='contained'
              color='default'
              className='button-form cancel-button'
              onClick={() => {
                if (isEdited === 'noChange') {
                  return history.goBack();
                } else {
                  setOpenPopup(true);
                }
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              className='button-form'
              onClick={handleOnClickSave}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
          <div className='confirmation-email-container'>
            <div className='flex'>
              <div className='template--name-duration'>
                <InputWithLabel
                  htmlFor='templateName'
                  type='text'
                  labelText='Template Name'
                  value={templateData.name}
                  onChange={handleOnChangeInput}
                  maxLength={255}
                  onBlur={handleOnblur}
                  isRequired={true}
                />
                <div className='select--full-width custom-scroll'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='onOnlyOnceEvery'
                        color='primary'
                        checked={templateData.onOnlyOnceEvery}
                        onChange={() => {
                          setTemplateData((prev) => ({
                            ...prev,
                            onOnlyOnceEvery: !prev.onOnlyOnceEvery,
                          }));

                          setValueEdited('hasChanged');
                        }}
                      />
                    }
                    className='checkbox--small'
                    label='Only once every: '
                  />
                  <Filter
                    optionsForFilter={optionDurations}
                    handleSetNewFilterValue={(newTimeValue) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        duration: Number(newTimeValue.value),
                      }));

                      setValueEdited('hasChanged');
                    }}
                    valueSelected={optionDurations.find(
                      (option) =>
                        option.value ===
                        (templateData?.duration?.toString() ?? '1')
                    )}
                  />
                  <Filter
                    optionsForFilter={optionUnits}
                    handleSetNewFilterValue={(newUnitTime) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        unitOfDuration: newUnitTime.value,
                      }));

                      setValueEdited('hasChanged');
                    }}
                    valueSelected={optionUnits.find(
                      (option) =>
                        option.value ===
                        (templateData?.unitOfDuration ?? 'hours')
                    )}
                  />
                </div>
              </div>
              {errorTemplateName && (
                <p className='error-msg'> This field is required. </p>
              )}
            </div>
            <InputWithLabel
              htmlFor='emailSubject'
              type='text'
              labelText='Email Subject'
              value={templateData.templateSubject}
              onChange={handleOnChangeInput}
              maxLength={50}
              onBlur={handleOnblur}
              isRequired={true}
            />
            {errorEmailSubject && (
              <p className='error-msg'> This field is required. </p>
            )}

            <label htmlFor='messageBody'>
              Message Body <b className='require'>*</b>
            </label>
            <EditorBox
              value={templateData.templateBody}
              handleChangeEditor={handleOnChangeEditor}
              handleEmptyContent={handleEmptyContent}
              token={tokenData}
              screen={(category ?? templateData.categoryKind)
                .toLowerCase()
                .trim()}
            />
            {errorEmptyEmailBody && (
              <p className='error-msg'> This field is required. </p>
            )}
          </div>
        </>
      )}
      <ModalDiscard
        isOpenModal={openPopup}
        handleCloseModal={setOpenPopup}
        handleConfirmModal={handleGoBackRouter}
      />
    </>
  );
};

export default EditEmailTemplate;
