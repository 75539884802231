export const PAGE_SIZE = 15;
export const FILTER_OPTIONS = {
  is_read: 'Read',
  not_read: 'Unread',
  is_approved: 'Approved',
  not_approved: 'Not Approved',
  all: 'All Submissions',
};

export const FILTER_ORDER = [
  'all','is_read', 'not_read', 'is_approved', 'not_approved',
];

export const FILTER_VALUES = {
  is_read: {is_read: true},
  not_read: {is_read: false},
  is_approved: {is_approved: true},
  not_approved: {is_approved: false},
  all: void 0,
};

export const FORM_MODAL_CONSTANTS = {
  DeleteModalTitle: 'Are you sure you want to delete this form submission?',
  DeleteModalAcceptText: 'Delete Submission',
  DeleteModalCloseText: 'Cancel',
  DeleteModalBodyStrings: [
    'You are about to delete a form submission and all of its data.',
    'This can\'t be undone.',
  ],
  DownloadModalTitle: 'Download this form submission?',
  DownloadModalAcceptText: 'Download Submission',
  DownloadModalCloseText: 'Cancel',
  DownloadModalBodyStrings: [
    `You are about to download a file containing sensitive patient information, do you wish to continue?`
  ],
  NoPatientModalTitle: 'No patient found',
  NoPatientModalCloseText: 'Close',
  NoPatientModalBodyText: 'We couldn\'t find a record of this patient. Please create the new patient\'s record in your Practice Management Software for it to appear in Legwork.',
  UpdatePatientModalTitle: 'Update patient records',
  UpdatePatientModalCloseText: 'Okay',
  UpdatePatientFailModalErrorHeader: 'Sorry, something went wrong',
  UpdatePatientFailModalErrorText: 'We were unable to update the patient records, due to a connection error. Please try again. If this problem persists, please reach out to Legwork support.',
  UpdatePatientSuccessModalHeader: 'Patient records have been updated successfully',
  UpdatePatientSuccessText: 'A copy of this form has also been added to the patient document center in your practice management system',
  VerifyPatientTitle: 'Verify Patient',
  VerifyPatientFooter: 'Select a patient to update',
  PendingModalTitle: 'Searching For Matches...',
  PendingModalBodyText: 'This can take a few minutes.  Please hang tight.',
  PendingModalCloseText: 'Close',
};

export const CONTACT_FIELDS = {
  Name: [
    'name_first',
    'name_middle',
    'name_last',
  ],
  Email: 'address',
};