import React, { useState, useEffect, useRef, useCallback } from 'react';
import BlueTextButton from 'components/Legwork/buttons/BlueTextButton';

import { MainUL, MainDiv } from './styled-components';
import ConversationSnackBar from './components/ConversationSnackBar';

const pollSpeed = 2000; // speed of polling responses (2 seconds)
export interface ConversationScrollContainerProps {
  messages: React.ReactElement[];
  getMore: () => Promise<boolean | undefined>; // side effect in container adds to messages [...messages, ...newMessages]
  pollForMore: () => Promise<boolean | undefined>; // side effect add in container to messages [...newMessages, ...messages]
}

const usePrevious = (value: number): number => {
  const ref = useRef(0);
  useEffect((): void => {
    if (value) ref.current = value;
  });
  return ref.current;
};

const useDeltaValue = (val: number): number => {
  const previousVal = usePrevious(val);
  return val - previousVal;
};

const ConversationScrollContainer = (
  props: ConversationScrollContainerProps,
): React.ReactElement => {
  const { messages, getMore, pollForMore } = props;

  // STATE
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [allMessagesRetrieved, setAllMessagesRetrieved] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const [height, setHeight] = useState(0);

  const [scroll, setScroll] = useState(0);

  const heightChange = useDeltaValue(height);

  const scrollChange = useDeltaValue(scroll);

  const mainRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = (): void => {
    if (mainRef && mainRef.current && mainRef.current.scrollTo) {
      mainRef.current.scrollTo({ top: mainRef.current.scrollHeight, left: 0, behavior: 'smooth' });
    }
  };

  const listener = async (): Promise<void> => {
    if (!mainRef || !mainRef.current) {
      return;
    }
    if (mainRef && mainRef.current) {
      setScroll(mainRef.current.scrollTop);
    }
  };

  const getNewMessages = useCallback(async (): Promise<void> => {
    const isComplete = await getMore().catch((): boolean => false);

    if (isComplete) {
      setAllMessagesRetrieved(true);
    }
  }, [getMore]);

  useEffect((): (() => void) => {
    if (scroll < 10 && scrollChange < 0 && scrollChange > -300) {
      setSnackBarOpen(false);

      if (!loadingMessages && !allMessagesRetrieved) {
        setLoadingMessages(true);
        const isComplete = setTimeout(async () => {
          await getNewMessages();
        }, 300);
        setLoadingMessages(false);
        return (): void => {
          clearTimeout(isComplete);
        };
      }
    }
    return (): void => undefined;
  }, [scroll, loadingMessages, allMessagesRetrieved, scrollChange, getNewMessages]);

  useEffect((): (() => void) => {
    const current = mainRef?.current;

    if (current) current.addEventListener('scroll', listener);

    return (): void => {
      if (current) current.removeEventListener('scroll', listener);
    };
  }, []);

  useEffect((): void => {
    scrollToBottom();
  }, []);

  const handleCloseSnackBar = (): void => {
    setSnackBarOpen(false);
  };

  const handleSnackBarButtonClick = (): void => {
    handleCloseSnackBar();
    scrollToBottom();
  };

  const adjustScrollPosition = useCallback((): void => {
    if (mainRef && mainRef.current) {
      if (heightChange !== 0 && snackBarOpen) {
        mainRef.current.scrollTo({ top: mainRef.current.scrollTop - heightChange, left: 0 });
      }
      if (heightChange !== 0 && !snackBarOpen) {
        mainRef.current.scrollTo({ top: mainRef.current.scrollTop + heightChange, left: 0 });
      }
    }
  }, [heightChange, snackBarOpen]);

  useEffect(() => {
    adjustScrollPosition();
  }, [adjustScrollPosition]);

  const pollForMoreCallback = useCallback(pollForMore, []);

  const poll = useCallback(async (): Promise<void> => {
    const isNewMessage = await pollForMoreCallback().catch(() => {
      return false;
    });

    if (isNewMessage) {
      if (mainRef && mainRef.current) {
        if (mainRef.current.scrollHeight - mainRef.current.scrollTop < window.innerHeight) {
          scrollToBottom();
          return;
        }
      }
      if (!snackBarOpen) {
        setSnackBarOpen(true);
      }
    }
  }, [pollForMoreCallback, snackBarOpen]);

  useEffect((): (() => void) => {
    if (mainRef && mainRef.current) {
      setHeight(mainRef.current.scrollHeight);
    }
    const pollTimer = setInterval(async () => {
      await poll();
    }, pollSpeed);
    return (): void => {
      clearInterval(pollTimer);
    };
  }, [poll]);

  return (
    <MainDiv ref={mainRef} id='message_container' style={{}}>
      <ConversationSnackBar
        open={snackBarOpen}
        onClose={handleCloseSnackBar}
        message={<BlueTextButton title='New Message(s)!' onClick={handleSnackBarButtonClick} />}
      />
      <MainUL>
        {messages.map(
          (child, i): React.ReactElement => {
            return (
              <li
                style={{ minHeight: '10px', marginTop: '10px' }}
                key={`child${messages.length - (i + 1)}`}
              >
                {child}
              </li>
            );
          },
        )}
      </MainUL>
    </MainDiv>
  );
};

export default ConversationScrollContainer;
