import React, { useState } from 'react';
import InputWithTopLabel from 'components/Legwork/inputs/InputWithTopLabel';
import SelectWithTopLabel from 'components/Legwork/inputs/SelectWithTopLabel';
import Grid from '@material-ui/core/Grid';
import Validator from 'components/Legwork/validation/Validator';
import { IValidationResult } from 'components/Legwork/validation/types';
import { isError, getError } from 'components/Legwork/validation/validators';

import { InputDiv, ErrorText } from './styled-components';
import validators from './validators';
import { States, StateCountryHash } from './constants';

export interface IAddressData {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
}
export interface AddressInputProps {
  initialData?: IAddressData;
  validationActive?: boolean;
  reporter?: (data: IAddressData) => void;
  errorReporter?: (errors: IValidationResult[]) => void;
  error?: boolean;
  errors?: IValidationResult[];
  renderErrors?: boolean;
}

const AddressInput = (props: AddressInputProps): React.ReactElement => {
  const {
    initialData,
    reporter,
    validationActive = false,
    errorReporter,
    error,
    errors,
    renderErrors = false,
  } = props;

  const [data, setStateData] = useState<IAddressData>({
    address1: initialData?.address1 ?? '',
    address2: initialData?.address2 ?? '',
    city: initialData?.city ?? '',
    zipCode: initialData?.zipCode ?? '',
    state: initialData?.state ?? '',
    country: initialData?.country ?? '',
  });

  const setData = (newData: IAddressData): void => {
    setStateData(newData);

    if (reporter) {
      const reportData = { ...data };
      if (reportData.state === 'default') {
        reportData.state = '';
      }
      reporter(reportData);
    }
  };

  const reportErrors = (e: IValidationResult[]): void => {
    if (errorReporter) {
      errorReporter(e);
    }
  };
  const reportAddress1 = (args0: string): void => {
    data.address1 = args0;
    setData({ ...data });
  };
  const reportAddress2 = (args0: string): void => {
    data.address2 = args0;
    setData({ ...data });
  };
  const reportCity = (args0: string): void => {
    data.city = args0;
    setData({ ...data });
  };

  const reportZipCode = (args0: string): void => {
    data.zipCode = args0;
    setData({ ...data });
  };

  const reportState = (args0: string | object): void => {
    data.state = (args0 as string) || '';
    data.country = StateCountryHash[data.state] ?? 'US';
    setData({ ...data });
  };

  return (
    <Validator
      validators={validators}
      data={data}
      shouldValidate={validationActive}
      reportErrors={reportErrors}
      error={error}
      errors={errors}
      renderChild={(_errorProp: boolean, errorsProp: IValidationResult[]): React.ReactElement => (
        <div>
          <InputDiv>
            <InputWithTopLabel
              title='Address'
              value={data.address1}
              reporter={reportAddress1}
              error={isError('Address 1', errorsProp)}
              placeholder='Address 1'
            />
            {renderErrors && <ErrorText>{getError('Address 1', errorsProp)}</ErrorText>}
          </InputDiv>
          <InputDiv>
            <InputWithTopLabel
              value={data.address2}
              reporter={reportAddress2}
              error={false}
              placeholder='Address 2'
            />
            {renderErrors && <ErrorText>{getError('Address 2', errorsProp)}</ErrorText>}
          </InputDiv>
          <Grid container spacing={1}>
            <Grid xs={7} item>
              <InputWithTopLabel
                value={data.city}
                reporter={reportCity}
                error={isError('City', errorsProp)}
                placeholder='City'
              />
              {renderErrors && <ErrorText>{getError('City', errorsProp)}</ErrorText>}
            </Grid>
            <Grid xs={3} item style={{ paddingLeft: '3px', paddingRight: '3px' }}>
              <SelectWithTopLabel
                title=''
                menuItems={States}
                reporter={reportState}
                defaultValue={data.state}
                error={isError('State', errorsProp)}
              />
              {renderErrors && <ErrorText>{getError('State', errorsProp)}</ErrorText>}
            </Grid>
            <Grid xs={2} item>
              <InputWithTopLabel
                value={data.zipCode}
                reporter={reportZipCode}
                error={isError('Zip Code', errorsProp)}
                placeholder='Zip/Postal'
              />
              {renderErrors && <ErrorText>{getError('Zip Code', errorsProp)}</ErrorText>}
            </Grid>
            <Grid container />
          </Grid>
        </div>
      )}
    />
  );
};

export default AddressInput;
