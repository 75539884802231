import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IQuery } from 'components/Alerts/SyncerStopPage/SyncerStopPage';
import { WrapperTable } from 'components/Communications/Analyze/InvalidTarget/InvalidTarget';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import LoadingPs from 'components/Global/Loading';
import AntSwitch from 'components/Global/Switch/AntSwitch';
import Table from 'components/Global/Table';
import toast from 'components/Global/Toast';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  Medical,
  MedicalCollection,
} from 'global/requests/ResponseTypes/Medical';
import { debounce } from 'lodash';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import React, { useCallback, useEffect, useState } from 'react';
import AddNewService from './AddNewService';
import './MedicalServices.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tooltip: {
      background: '#E3E6EE',
      borderRadius: '8px',
      color: '#1A252E',
      fontSize: '12px',
    },
  })
);

const columns = [
  { key: 'serviceName', title: 'Service Name' },
  { key: 'vaccine', title: 'Vaccine' },
  { key: 'species', title: 'Species' },
  { key: 'clientPortal', title: 'Display in Client Portal' },
  { key: 'compliance', title: 'Display in Compliance' },
  { key: 'editButton', title: '' },
];

const MedicalServices = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedService, setSelectedService] = useState<Medical>();
  const [medicalCollection, setMedicalCollection] = useState<MedicalCollection>(
    {
      count: 0,
      totalAvailable: 0,
      medicalServices: [],
    }
  );
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState<IQuery>({ limit: 10, offset: 0 });

  useEffect(() => {
    getMedicalServices(query, searchValue);
  }, []);

  const getMedicalServices = async (
    query?: IQuery,
    search?: string,
    disabledLoading?: boolean
  ) => {
    !disabledLoading && setIsLoading(true);
    try {
      const res = await configuredRequests.GET.getMedicalServices({
        key: search,
        ...query,
      });

      setMedicalCollection({
        count: res.count,
        totalAvailable: res.totalAvailable,
        medicalServices: res.medicalServices,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const switchStatus = (
    serviceId: string,
    field: string,
    isBothField?: boolean
  ) => {
    // @ts-ignore
    const dataAfterSwitchStatus = medicalCollection.medicalServices.map(
      (item: Medical) => {
        if (item.medicalServiceId === serviceId) {
          if (isBothField) {
            return {
              ...item,
              isShowInClientPortal: !item.isShowInClientPortal,
              isCompliance: !item.isCompliance,
            };
          }
          // @ts-ignore
          return { ...item, [field]: !item[field] };
        }
        return item;
      }
    );
    setMedicalCollection((prev) => ({
      ...prev,
      medicalServices: dataAfterSwitchStatus,
    }));
  };

  const handleChangeToggle = async (medicalService: Medical, field: string) => {
    try {
      const payload = {
        medicalServiceId: medicalService.medicalServiceId,
        medicalServiceName: medicalService.medicalServiceName,
        isShowInClientPortal: medicalService.isShowInClientPortal,
        isCompliance: medicalService.isCompliance,
      };
      if (medicalService.order !== undefined) {
        // @ts-ignore
        payload.order = medicalService.order;
      }
      // @ts-ignore
      payload[field] = !medicalService[field];

      if (
        field === 'isShowInClientPortal' &&
        medicalService.isShowInClientPortal &&
        medicalService.isCompliance
      ) {
        switchStatus(medicalService.medicalServiceId, field, true);
        payload.isCompliance = false;
      } else {
        switchStatus(medicalService.medicalServiceId, field);
      }
      await configuredRequests.PUT.updateMedicalService(payload);
    } catch (error) {
      toast.error('Failed to change status.');
      switchStatus(medicalService.medicalServiceId, field);
      if (
        field === 'isShowInClientPortal' &&
        medicalService.isShowInClientPortal &&
        medicalService.isCompliance
      ) {
        switchStatus(medicalService.medicalServiceId, 'isCompliance');
      }
    }
  };

  const searchMedicalWithName = useCallback(
    debounce((name: string) => {
      setQuery((prev) => ({ ...prev, offset: 0 }));
      getMedicalServices({ limit: query.limit, offset: 0 }, name, true);
    }, 1000),
    [query]
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setSearchValue(value);
      searchMedicalWithName(value);
    },
    [query]
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQuery((prev) => ({ ...prev, offset: newPage }));
      getMedicalServices(
        { limit: query.limit, offset: newPage },
        searchValue,
        true
      );
    },
    [searchValue, query]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQuery((prev) => ({ ...prev, limit: newRowsPerPage, offset: 0 }));
        getMedicalServices(
          { limit: newRowsPerPage, offset: 0 },
          searchValue,
          true
        );
      }
    },
    [searchValue]
  );

  const handleReloadData = useCallback(() => {
    getMedicalServices(query, searchValue);
  }, [query, searchValue]);

  return isLoading ? (
    <LoadingPs />
  ) : (
    <>
      <div className='setting__content--full-row appointment-type-syncer-setting setting-appointment-type setting-medical-services setting-scroll--custom'>
        <div className='custom-position'>
          <h2 className='appointment-type-syncer-setting-title'>
            Medical Services
          </h2>
        </div>
        <p className='appointment-type-syncer-setting-description'>
          Specify the services you want to show in the Client Portal. These will
          be shown to clients when viewing the status of Medical Services.
        </p>

        <form
          className={
            false ? 'search-safari' : 'search-form search-filter__global'
          }
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
          }}
        >
          <FontAwesomeIcon icon={['far', 'search']} />
          <input
            type='search'
            placeholder='Search by service name'
            className='search-form__input'
            value={searchValue}
            onChange={handleSearch}
          />
        </form>
        {medicalCollection.medicalServices.length > 0 ? (
          <WrapperTable>
            <Table
              columns={columns}
              rows={
                medicalCollection.medicalServices.map(
                  (item: Medical, index: number) => {
                    return {
                      serviceName: (
                        <div className='appointment-type-colunm'>
                          <Tooltip
                            title={item.medicalServiceName}
                            placement='bottom-start'
                            classes={{ tooltip: classes.tooltip }}
                            disableHoverListener={
                              item.medicalServiceName.length < 35
                            }
                          >
                            <HighlightText
                              text={item.medicalServiceName}
                              searchValue={searchValue}
                            />
                          </Tooltip>
                        </div>
                      ),
                      vaccine: item.isVaccine.toString(),
                      species:
                        (item.speciesNames ?? []).length > 0
                          ? (item.speciesNames ?? []).join(', ')
                          : 'All',
                      clientPortal: (
                        <AntSwitch
                          checked={item.isShowInClientPortal}
                          onChange={() => {
                            handleChangeToggle(item, 'isShowInClientPortal');
                          }}
                          label=''
                        />
                      ),
                      compliance: (
                        <AntSwitch
                          checked={item.isCompliance}
                          onChange={() => {
                            item.isShowInClientPortal &&
                              handleChangeToggle(item, 'isCompliance');
                          }}
                          label=''
                          classMore={`${
                            !item.isShowInClientPortal && 'disabled'
                          }`}
                        />
                      ),
                      editButton: (
                        <button
                          key={index}
                          className='appointment__task-complete-button edit-appointment-type-button'
                          onClick={() => setSelectedService(item)}
                        >
                          Edit
                        </button>
                      ),
                    };
                  }
                ) || []
              }
              isHasPagination={true}
              rowsPerPage={query.limit}
              page={query.offset}
              totalAvailable={medicalCollection.totalAvailable}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </WrapperTable>
        ) : (
          <p
            className='no-data-results'
            style={{ fontWeight: 'bold', marginLeft: '15px' }}
          >
            No results found
          </p>
        )}
      </div>

      {selectedService && (
        <AddNewService
          service={selectedService}
          onCloseModal={setSelectedService}
          onReload={handleReloadData}
        />
      )}
    </>
  );
};

export default MedicalServices;
