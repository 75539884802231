/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import Input from '@material-ui/core/Input';

const StyledSpan = styled.span`
  color: grey;
  font-size: 1.8rem;
`;

const Main = styled.div`
  width: 100%;
  background-color: white;
  display: 'flex';

  flex: 1;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputContainer = styled.div`
  margin: 1px;
  flex-grow: 1;
`;
const ButtonContainer = styled.div`
  padding-left: 10px;
  display: flex1px;
  padding-right: 10px;
`;

const StyledInput = styled(Input)`
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 20px;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
`;

export { StyledSpan, Main, StyledInput, FlexContainer, InputContainer, ButtonContainer };
