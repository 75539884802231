import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { detectRoleAndScreen } from 'hooks/useDecodeToken';
import { capitalize } from 'utils/strings';

//@ts-nocheck
export interface InteractionNavProps {
  currentActiveView: string;
  reloadList?: any;
  registered?: boolean;
  setCurrentActiveView: React.Dispatch<React.SetStateAction<string>>;
  onSubscriptionClick: React.Dispatch<React.SetStateAction<boolean>>;
  useEstimates?: boolean;
}

const InteractionNav = (props: InteractionNavProps) => {
  const {
    currentActiveView,
    reloadList,
    registered,
    setCurrentActiveView,
    onSubscriptionClick,
    useEstimates,
  } = props;

  const location = useLocation();
  const isUserScreen = location.pathname.includes('/Settings/User');
  const userId = location.pathname.replace(
    isUserScreen ? '/Settings/User/' : '/Contacts/View/',
    ''
  );
  const { features } = detectRoleAndScreen(
    window.sessionStorage.getItem('AuthToken') || ''
  );
  const [reloadData, setReloadData] = React.useState<any>(reloadList);

  let navInnerTextCollection = ['interactions', 'email'];

  if (features.includes('Conversations')) {
    navInnerTextCollection.splice(1, 0, 'text');
  }

  if (!isUserScreen) {
    navInnerTextCollection.push('postal');
  }

  navInnerTextCollection.push('forms');

  if(useEstimates) {
    navInnerTextCollection.push('estimates')
  }

  const renderNavListItem = (innerText: string, index: number) => {
    return (
      <li
        key={'view-contact-nav-item--' + index}
        className={
          'view-contact--nav-item' +
          (currentActiveView === innerText ? ' active' : '')
        }
        onClick={() => {
          setCurrentActiveView(innerText);
          if (index === 0) {
            setReloadData(reloadList);
            reloadData && reloadList();
          }
        }}
      >
        <button>{capitalize(innerText)}</button>
      </li>
    );
  };

  return (
    <nav className={'view-contact--interactions-nav-container'}>
      <ul>
        {navInnerTextCollection.map(renderNavListItem)}
        <li>
          {registered && (
            <span
              onClick={() =>
                window.open(`/PracticeLoginContact/ToDashboard/${userId}`)
              }
            >
              <FontAwesomeIcon
                icon={['fas', 'up-right-from-square']}
                className='btn-redirect'
              />
            </span>
          )}
          <span onClick={() => onSubscriptionClick(true)}>
            <FontAwesomeIcon
              icon={['far', 'list-alt']}
              className='icon-subscription'
            />
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default InteractionNav;
