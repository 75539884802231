import * as t from 'io-ts';

const TwilioSettingType = t.type({
  twilioSID: t.string,
  smsOutGoingNumber: t.string,
  callOutGoingNumber: t.string  
});

export type TwilioSettingType = t.TypeOf<typeof TwilioSettingType>;

export const TwilioSettingResponseType = t.type({
  namespace: t.string,
  settings: TwilioSettingType,
});

export type TwilioSettingResponseType = t.TypeOf<typeof TwilioSettingResponseType>;