/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import {
  SecondaryButton,
  InputTextarea,
  AlertSnackBar,
} from 'components/Legwork';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import styled from 'styled-components';
import loading_spinner from 'media/images/loading-spinner.svg';
import {
  getAppBaseUrl,
  getPatientPortalUrl,
  getRootDomain,
} from 'global/constants/url';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import ShareLink from './ShareLink';

export const InputContainer = styled.div`
  width: 70%;
`;

export const ErrorMsg = styled.div`
  color: red;
  font-size: 13px;
  margin: 5px 0 0;
`;

const Share = () => {
  const [copyToClipboardState, setCopyToClipboardState] = useState('');
  const [errorLocation, setErrorLocation] = useState(0);
  const [micrositeName, setMicrositeName] = React.useState<any>();
  const [loading, setLoading] = useState(true);
  const [neverPublish, setNeverPublish] = useState(false);
  const [sharedLink, setSharedLink] = useState<string>('');

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current !== null) {
      //@ts-ignore
      clearTimeout(timeoutRef.current);
    }
    //@ts-ignore
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      //@ts-ignore
      if (copyToClipboardState !== '') {
        //@ts-ignore
        setCopyToClipboardState();
      }
    }, 1000);
  }, [copyToClipboardState]);

  useEffect(() => {
    Promise.all([getCurrentTenantInfo(), getAppointmentBooking()])
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
  }, []);

  const handleCopyToClipboard = (value: any) => {
    try {
      if (neverPublish) {
        navigator.clipboard.writeText('');
        setCopyToClipboardState('error');
      } else {
        navigator.clipboard.writeText(value);
        setCopyToClipboardState('success');
      }
    } catch (err) {
      setCopyToClipboardState('error');
    }
  };

  const getCurrentTenantInfo = async () => {
    setLoading(true);
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      setMicrositeName(res.micrositeName);
      setSharedLink(
        `https://${getPatientPortalUrl()}/${res.micrositeName}/online-booking`
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getAppointmentBooking = async () => {
    try {
      const res = await configuredRequests.GET.getFormAppointmentBooking();
      if (res) {
        // @ts-ignore
        setNeverPublish(res?.data?.never_published);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const embedCode = React.useMemo(() => {
    return (
      '<link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css">' +
      '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap" rel="stylesheet">' +
      `<div id="appointment-booking-form" class="form-container ` +
      `${getRootDomain()} ${micrositeName}"></div>` +
      `<script src="${getAppBaseUrl()}/assets/jquery.js"></script>` +
      `<script src="${getAppBaseUrl()}/assets/forms.js" charset="utf-8" defer></script>`
    );
  }, [micrositeName]);

  const handleCopyLink = () => {
    setErrorLocation(1);
    handleCopyToClipboard(sharedLink);
  };

  return (
    <>
      {copyToClipboardState === 'success' && (
        <AlertSnackBar severity='success'>
          <span>Copied!</span>
        </AlertSnackBar>
      )}
      {copyToClipboardState === 'error' && (
        <AlertSnackBar severity='error'>
          <span>Failed</span>
        </AlertSnackBar>
      )}
      <div
        className='form-item-tab-container with-margin'
        style={{ padding: `${loading ? '50px 25px' : '30px 25px'}` }}
      >
        {loading ? (
          <img
            className='loading-appointment-info'
            src={loading_spinner}
            alt={'Loading'}
          />
        ) : (
          <>
            <ShareLink
              shareLink={sharedLink}
              isError={neverPublish && errorLocation === 1}
              description='Send this link to contacts for them to book an appointment through
            your microsite.'
              onCopyLink={handleCopyLink}
            />
            {/* <div className='row'>
              <div className='control-group' style={{ marginBottom: '50px' }}>
                <label
                  htmlFor='shared_link'
                  className='control-label share-title'
                >
                  Share Link
                </label>
                <InputContainer>
                  <span className='sub-label-share'>
                    Send this link to contacts for them to book an appointment
                    through your microsite.
                  </span>
                  <InputTextarea
                    id='shared-link-textarea'
                    initialValue={sharedLink || ''}
                    //@ts-ignore
                    readOnly
                    disabled={!!sharedLink}
                  />
                </InputContainer>
                {neverPublish && errorLocation === 1 && (
                  <ErrorMsg>This form has not been publish yet.</ErrorMsg>
                )}
                <SecondaryButton
                  icon={<FileCopyRoundedIcon />}
                  title='Copy'
                  style={{
                    marginTop: '15px',
                    backgroundColor: 'white',
                  }}
                  disabled={!sharedLink}
                  onClick={() => {}}
                />
              </div>
            </div> */}
            <div className='row'>
              <div className='control-group'>
                <label
                  htmlFor='embed_code'
                  className='control-label share-title'
                >
                  Appointment Booking Widget
                </label>
                <InputContainer>
                  <span className='sub-label-share'>
                    Have your web developer embed this code on your website in
                    order to receive online form submissions.
                  </span>
                  <InputTextarea
                    id='embed-code-textarea'
                    initialValue={embedCode || ''}
                    resize='vertical'
                    disabled={!!embedCode}
                    //@ts-ignore
                    readOnly
                  />
                </InputContainer>
                {neverPublish && errorLocation === 2 && (
                  <ErrorMsg>This form has not been publish yet.</ErrorMsg>
                )}
                <SecondaryButton
                  icon={<FileCopyRoundedIcon />}
                  title='Copy'
                  style={{
                    marginTop: '15px',
                    backgroundColor: 'white',
                  }}
                  disabled={!embedCode}
                  onClick={() => {
                    setErrorLocation(2);
                    handleCopyToClipboard(embedCode);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Share;
