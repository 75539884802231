import React from 'react';
import loading_spinner from 'media/images/loading-spinner.svg';

const PracticeLoginContact = (props: { loginError: string }): JSX.Element => {
  return (
    <div
      style={{
        margin: '0 auto',
        width: '300px',
        top: '40%',
        textAlign: 'center',
      }}
    >
      {props.loginError ? (
        <p>{props.loginError}</p>
      ) : (
        <>
          <img src={loading_spinner} alt={'Loading'} />
          <p>Redirecting to the Client portal page</p>
        </>
      )}
    </div>
  );
};

export default PracticeLoginContact;
