import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const TinyTitleText = styled(Typography)`
  font-size: ${sizeConstants.tiny.size};
  line-height: ${sizeConstants.tiny.height};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: ${getThemeProp('pallette.text.secondary', '#6e84a3')};
` as typeof Typography;
export default TinyTitleText;
