import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { Column, ConfiguratorResponse } from 'global/requests/ResponseTypes';
import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import ConfiguratorModal from './ConfiguratorModal';
import './Configurator.scss';

const columns: Column[] = [
  { id: 'configuratorVersion', label: 'Configurator Version' },
  { id: 'releasedDate', label: 'Released Date' },
  { id: 'downloadFile', label: 'Action' },
  { id: 'releasedNote', label: 'Released Note' },
];

interface ConfiguratorDataType {
  id: string;
  releasedDate: string;
  actionDownload: string;
  configuratorVersion: string;
  releasedNote: string;
}

const Configurator = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [displayRoleModal, setDisplayRoleModal] = React.useState(false);
  const [configuratorData, setConfiguratorData] =
    React.useState<ConfiguratorDataType[]>();
  const [isOpenEditRoleModal, setIsOpenEditRoleModal] =
    React.useState<boolean>(false);
  const [idValue, setIdValue] = React.useState<string>('');

  const getAllConfigurators = async () => {
    try {
      const res = await configuredRequests.GET.getConfigurators();
      if (res) {
        const configuratorDataTable = res.methods.map(
          (item: ConfiguratorResponse) => {
            return {
              id: item.configuratorId,
              releasedDate: moment(item.releasedDate).format(
                'MM/DD/YYYY hh:mm A'
              ),
              actionDownload: item.downloadLink,
              configuratorVersion: item.versionName,
              releasedNote: item?.releaseNotes ?? '',
            };
          }
        );
        const sortedConfiguratorsData = orderBy(
          configuratorDataTable,
          [(configurator) => configurator?.configuratorVersion?.toLowerCase()],
          ['desc']
        );
        setConfiguratorData(sortedConfiguratorsData as ConfiguratorDataType[]);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllConfigurators();
  }, []);

  const openRoleInformationModal = (idValue: string) => {
    setIsOpenEditRoleModal(idValue !== '');
    setIdValue(idValue);
    setDisplayRoleModal(true);
  };

  return (
    <div className='communication-container user-setting w-300 roles-table setting-detail syncer__configurator setting-scroll--custom'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        <CommunicationTable
          columns={columns}
          dataTable={configuratorData || []}
          isSort={false}
          isDisplayedSearchAndFilter={true}
          isDisplayedSearch={true}
          isDisplayedFilter={false}
          isDisplayedButton={true}
          keySearch='name'
          placeholderSearch='Search by configurator version'
          buttonName='New Version'
          iconButton={['far', 'plus']}
          isLoading={isLoading}
          screen={'CONFIGURATOR'}
          openModal={(idValue: string) => {
            openRoleInformationModal(idValue);
          }}
        />
      </div>

      <ConfiguratorModal
        handleReloadList={getAllConfigurators}
        displayRoleModal={displayRoleModal}
        setDisplayRoleModal={setDisplayRoleModal}
        titleText={isOpenEditRoleModal ? 'Edit' : 'Add New'}
        isEdit={isOpenEditRoleModal}
        idValue={idValue}
        setConfiguratorData={setConfiguratorData}
      />
    </div>
  );
};

export default Configurator;
