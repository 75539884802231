import React, { useState, useEffect } from 'react';

const FormFieldInputText = ({
  type,
  className,
  isRenderedField,
  onChange,
  value,
  displayName,
  ...rest
}) => {
  const dynamicProps = {};

  // const [valueInput, setValueInput] = useState(value);

  // useEffect(() => {
  //   if (
  //     ['Contact Phone Number', 'Co-Owner Phone Number'].includes(displayName)
  //   ) {
  //     setValueInput(
  //       value?.length > 0 ? value.map((phone) => phone.number).join(', ') : ''
  //     );
  //   } else {
  //     setValueInput(value);
  //   }
  // }, [value, displayName]);

  if (isRenderedField) {
    dynamicProps.onChange = onChange;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  const getAutoComplete = () => {
    switch (displayName) {
      case 'First Name':
        return 'given-name';
      case 'Middle Name':
        return 'additional-name';
      case 'Last Name':
        return 'family-name';
      case 'Contact Phone Number':
      case 'Co-Owner Phone Number':
        return 'tel';
      case 'Contact Email Address':
      case 'Co-Owner Email Address':
        return 'email';
      case 'Address 1':
      case 'Address 2':
        return 'street-address';
      // case 'City':
      //   return 'city';
      case 'Zip / Postal Code':
        return 'postal-code';
      default:
        return 'off';
    }
  };

  return (
    <input
      autoComplete={getAutoComplete()}
      type={type}
      className={className}
      {...dynamicProps}
      {...rest}
      value={
        ['Contact Phone Number', 'Co-Owner Phone Number'].includes(displayName)
          ? Array.isArray(value)
            ? value?.length > 0
              ? value.map((phone) => phone.number).join(', ')
              : ''
            : value
          : value
      }
    />
  );
};

FormFieldInputText.defaultProps = {
  className: '',
  isRenderedField: false,
  onChange: () => {},
};

export default FormFieldInputText;
