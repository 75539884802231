import * as t from 'io-ts';
import { FullUserInfoResponse } from './Tenants';
import { PetSummaryCollection } from './Pets';
import { TaskResponseData } from './_Task';



// AppointmentInfoResponse
export const UserSummaryCollection = t.type({
  count: t.number,
  users: t.array(FullUserInfoResponse),
});

const AppointmentSummaryRequired = t.type({
  externalId: t.string,
  attendees: UserSummaryCollection,
  patients: PetSummaryCollection,
  dateTime: t.string, // dateTime?
  appointmentId: t.string,
  status: t.string,
  kind: t.string,
});

const AppointmentSummaryNullable = t.partial({
  kind: t.string,
  durationMinutes: t.number, // timeSpan?
  tasks: t.array(TaskResponseData),
  teleMedKey: t.string,
  externalId: t.string,
  appointmentUpdatedTime: t.string,
  providerName: t.string,
  appointmentTypeName: t.string,
  appointmentTypeId: t.string
});

export const FullAppointmentSummary = t.intersection([
  AppointmentSummaryRequired,
  AppointmentSummaryNullable,
]);
export type AppointmentSummaryEntry = t.TypeOf<typeof FullAppointmentSummary>;

export const AppointmentSummaryCollection = t.type({
  //  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  appointments: t.array(FullAppointmentSummary),
});

export const MyAppointmentSummaryCollection = t.type({
  //  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  appointments: t.array(t.array(FullAppointmentSummary)),
});

export type AppointmentInfoResponse = t.TypeOf<
  typeof AppointmentSummaryCollection
>;
//

export type AppointmentMultipleInfoResponse = t.TypeOf<
  typeof MyAppointmentSummaryCollection
>;
//
