import { pane } from './constants';

const generateTitle = (
  title?: string,
  subTitle?: string,
  titlePosition?: string,
  titleColor?: string,
  subTitleColor?: string,
): string => {
  if (title) {
    return `<div style="text-align: center; margin-top: ${titlePosition || '-7rem'};">
  <p style="font-size: 4rem; color: ${titleColor ||
    '#4C506C'}; font-family: TT Commons,Arial,sans-serif;font-weight: 400;">
    ${title}
  </p>
  <p style="font-size: 0.95rem;color: ${subTitleColor ||
    '#6E84A3'}; font-family: TT Commons,Arial,sans-serif;font-weight: 500;margin-top: -4.5rem">
    ${subTitle}
  </p>
</div>`;
  }
  return '';
};

const generatePane = (
  backgroundColor: string,
  innerRadius: number,
  outerRadius: number,
): object => {
  pane.background[0].backgroundColor = backgroundColor;
  return {
    ...pane,
    background: [
      { ...pane.background[0], innerRadius: `${innerRadius}%`, outerRadius: `${outerRadius}%` },
    ],
  };
};
// eslint-disable-next-line import/prefer-default-export
export { generateTitle, generatePane };
