import React from 'react';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import HIDE_ICON from '../../../images/hide-show-icon.svg';
import LIST_NOTE from '../../../images/list_note.svg';

import './NoteComponent.scss'
import { NoteModel } from '../../../types/NoteModel';
import { baseUrl } from 'telemed-video-call/environment';
import { isAndroid } from "react-device-detect";

require('dotenv').config();

interface NoteComponentProps {
  appointmentId: string | null;
  accessToken: string | null;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      width: '400px',
      maxWidth: '400px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;

  onClose(): void;

  id?: string;
}

const DialogTitle = withStyles(styles)((props: Props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const NoteComponent = (props: NoteComponentProps) => {
  const [appointmentId] = React.useState(props.appointmentId);
  const [isShowNote, setIsShowNote] = React.useState(false);
  const [isTransparent, setIsTransparent] = React.useState(false);
  const [hasInputNote, setHasInputNote] = React.useState(false); // Check data changed when add note
  const [isEditingNote, setIsEditingNote] = React.useState(false); // check is editing note
  const [isChangeNoteEdit, setIsChangeNoteEdit] = React.useState(false); // using for check input has change when editing note
  const [noteTitle, setNoteTitle] = React.useState<string>(''); // Note title value of text field
  const [noteContent, setNoteContent] = React.useState<string>(''); // Note content value of text field
  const [noteEditing, setNoteEditing] = React.useState<NoteModel>(); // Value of note what is editing
  const [appoitmentNotes, setAppoitmentNotes] = React.useState<Array<NoteModel>>(new Array<NoteModel>());
  const noteDialog = React.useRef(document.createElement("div"));

  React.useEffect(() => {
    getAppoitmentNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAppoitmentNotes = () => {
    axios.get(`${baseUrl}/appointments/V1/${appointmentId}/notes?offset=0`, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }).then((response: { status: number, data: { notes: NoteModel[] } }) => {
      if (200 === response.status) {
        extractNotesData(response.data.notes);
      }
    })
  }

  /**
   * Service for add note
   * @param noteId
   */
  const addAppoitmentNote = () => {
    let data = {
      name: noteTitle,
      content: noteContent
    };
    let addButton = document.getElementsByClassName('save-btn')[0];
    addButton.setAttribute("disabled", "disabled");
    axios.post(`${baseUrl}/appointments/V1/${appointmentId}/notes`, data, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }).then((response: { status: number }) => {
      if (201 === response.status) {
        getAppoitmentNotes();
        document.getElementsByClassName('save-btn')[0].removeAttribute("disabled");
        clearNoteData();
      }
    })
  }

  /**
   * Service for delete note
   * @param noteIndex
   */
  const deleteAppoitmentNote = (noteIndex: number) => {
    let noteId = appoitmentNotes[noteIndex].noteId;
    let deleteButton = document.getElementsByClassName('delete-btn')[noteIndex];
    deleteButton.setAttribute("disabled", "disabled");
    axios.delete(`${baseUrl}/appointments/V1/${appointmentId}/notes/${noteId}`, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }).then((response: { status: number }) => {
      if (200 === response.status) {
        getAppoitmentNotes();
        document.getElementsByClassName('delete-btn')[noteIndex].removeAttribute("disabled");
      }
      getAppoitmentNotes();
    })
  }

  /**
   * Service for update note
   * @param noteId
   */
  const updateAppoitmentNote = (noteId: string) => {
    let data = {
      name: noteTitle,
      content: noteContent
    }
    let saveButton = document.getElementsByClassName('save-btn')[0];
    saveButton.setAttribute("disabled", "disabled");
    axios.patch(`${baseUrl}/appointments/V1/${appointmentId}/notes/${noteId}`, data, {
      headers: {
        'Authorization': `Bearer ${props.accessToken}`,
      }
    }).then((response: { status: number }) => {
      if (200 === response.status) {
        getAppoitmentNotes();
        document.getElementsByClassName('save-btn')[0].removeAttribute("disabled");
        clearNoteData();
        setIsEditingNote(false);
      }
    })
  }

  /**
   * Extract data note
   */
  const extractNotesData = (listNotes: Array<NoteModel>) => {
    let notes: Array<NoteModel> = [];
    listNotes.sort((a: NoteModel, b: NoteModel) => (a.modifiedDate > b.modifiedDate) ? 1 : -1);
    listNotes.forEach((note: NoteModel, i: number) => {
      let tempNote = {
        noteId: note.noteId,
        name: note.name,
        content: note.content,
        createdAt: fomatToTime(note.createdAt),
        modifiedDate: fomatToTime(note.modifiedDate),
      }
      notes.push(tempNote);
    });
    setAppoitmentNotes(notes);
  }

  /**
   * Convert date time to time (HH:MM:AM(PM))
   */
  const fomatToTime = (data: string) => {
    let date = new Date(data);
    let hours;
    let minutes;
    let ap = date.getHours() >= 12 ? 'PM' : 'AM';
    let convertHours = (date.getHours() % 12) || 12;
    if (convertHours < 10) {
      hours = '0' + convertHours;
    } else {
      hours = convertHours;
    }
    if (date.getMinutes() < 10) {
      minutes = "0" + date.getMinutes();
    } else {
      minutes = date.getMinutes();
    }
    return hours + ':' + minutes + ' ' + ap;
  };

  const handleClose = () => {
    setIsShowNote(false);
  };

  /**
   * Event click menu take note
   */
  const showTakeNote = () => {
    if (!isShowNote) {
      setIsShowNote(!isShowNote);
    }
  };

  /**
   * Hide and show note dialog function
   */
  const hideShowNoteDialog = () => {
    let note = noteDialog.current;
    setIsTransparent(!isTransparent);
    if (!isTransparent) {
      note.classList.add('hide');
    } else {
      note.classList.remove('hide');
    }
  };

  /**
   * Event click clear button
   */
  const clearNoteData = () => {
    setNoteTitle('');
    setNoteContent('');
    setIsEditingNote(false);
    setIsChangeNoteEdit(false);
    setChangedData(0, 0);
  };

  /**
   * Event click edit button
   * @param i index of note
   */
  const editNote = (index: number) => {
    setNoteEditing(appoitmentNotes[index]);
    setIsEditingNote(true);
    setNoteTitle(appoitmentNotes[index].name);
    setNoteContent(appoitmentNotes[index].content);
  };

  /**
   * Event click save note button (Update edit note)
   */
  const saveNote = () => {
    updateAppoitmentNote(noteEditing!.noteId);
  };

  /**
   * Handle event note title field change when adding note (for show/hide button)
   */
  const _handleNoteTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoteTitle(event.target.value);
    setChangedData(event.target.value.length, noteContent.length);
  };

  /**
   * Handle event note content field change when adding note (for show/hide button)
   */
  const _handleNoteContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteContent(event.target.value);
    setChangedData(noteTitle.length, event.target.value.length);
  };

  /**
   * Check data has change when adding note
   * @param noteTitleLength
   * @param noteContentLength
   */
  const setChangedData = (
    noteTitleLength: number,
    noteContentLength: number
  ) => {
    if (noteTitleLength !== 0 || noteContentLength !== 0) {
      setHasInputNote(true);
    } else if (noteTitleLength === 0 && noteContentLength === 0) {
      setHasInputNote(false);
    }
  };

  /**
   * Handle event note title change when user editing note (for show/hide button)
   */
  const _handleChangeTitleEditing = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoteTitle(event.target.value);
    if (event.target.value !== noteEditing!.name) {
      setIsChangeNoteEdit(true);
    } else {
      setIsChangeNoteEdit(false);
    }
  };

  /**
   * Handle event note content change when user editing note (for show/hide button)
   */
  const _handleChangeContentEditing = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteContent(event.target.value);
    if (event.target.value !== noteEditing!.content) {
      setIsChangeNoteEdit(true);
    } else {
      setIsChangeNoteEdit(false);
    }
  };

  /**
   * Handle event mouse enter a note in note list
   * @param event
   * @param index
   */
  const _handleMouseEnterNote = (index: number) => {
    let groupButton = document.getElementsByClassName(`group-btn${index}`)[0]; // getElementsByClassName is a collection need get first element
    groupButton.classList.add('visible');
  };

  /**
   * Handle event mouse leave a note in note list
   * @param event
   * @param index
   */
  const _handleMouseLeaveNote = (index: number) => {
    let groupButton = document.getElementsByClassName(`group-btn${index}`)[0]; // getElementsByClassName is a collection need get first element
    groupButton.classList.remove('visible');
  };

  /**
   * Remove tabindex of dialog
   */
  const removeTabIndex = () => {
    let dialog: HTMLElement;
    dialog = document.getElementsByClassName(
      'MuiDialog-container'
    )[1] as HTMLElement;
    if (dialog !== undefined) {
      dialog.removeAttribute('tabindex');
    }
  };

  return (
    <div className='note-container' onClick={showTakeNote}>
      <div className='menu-note-present'>
        <img alt='icon menu' src={LIST_NOTE} />
        <p className='menu-title'> Take Notes </p>
      </div>
      <Draggable
        handle='strong'
        bounds='parent'
        defaultClassNameDragging={isTransparent ? 'hide' : ''}
        defaultClassNameDragged={isTransparent ? 'hide' : ''}
      >
        <Dialog
          onClick={removeTabIndex}
          ref={noteDialog}
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={isShowNote}
          className={isAndroid ? 'isAndroid note-dialog-container' : 'note-dialog-container'}
          style={{ left: '0 !important', top: '0 !important', width: '500px' }}
        >
          <strong className='cursor'>
            <img
              alt='hide'
              className='hideShowIcon'
              src={HIDE_ICON}
              onClick={hideShowNoteDialog}
            />
            <DialogTitle id='customized-dialog-title' onClose={handleClose}>
              <span>Appointment Notes</span>
            </DialogTitle>
          </strong>
          <DialogContent
            dividers
            style={{
              padding: 0,
              overflowY: 'scroll',
              height: '400px',
              width: 'auto',
            }}
            id="note-component"
          >
            <Grid container>
              {appoitmentNotes.map((note: NoteModel, i: number) => (
                <Grid item key={i} className='note-list-container'>
                  <div className={i !== 0 ? 'devider' : ''}></div>
                  <div
                    className='note-detail'
                    onMouseEnter={(e) => _handleMouseEnterNote(i)}
                    onMouseLeave={(e) => _handleMouseLeaveNote(i)}
                  >
                    <div className='note-title'>
                      <div className='note-name'>{note.name}</div>
                      <div className='note-time'>{note.modifiedDate}</div>
                    </div>
                    <span className='note-content'>{note.content}</span>
                    <div className={'group-note-func group-btn' + i}>
                      <button className='edit-btn' onClick={() => editNote(i)}>
                        Edit
                      </button>
                      <button
                        className='delete-btn'
                        onClick={() => deleteAppoitmentNote(i)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          {!isEditingNote ? (
            <DialogActions className='group-field-container'>
              <div className='group-field'>
                <TextField
                  className='outlined-required field-title'
                  style={{ width: '95%' }}
                  size='small'
                  autoComplete='off'
                  placeholder='Title'
                  value={noteTitle}
                  onInput={_handleNoteTitleChange}
                />
                <textarea
                  className='outlined-required field-content'
                  style={{ width: '95%' }}
                  placeholder='Take a note'
                  value={noteContent}
                  onChange={_handleNoteContentChange}
                >
                  {' '}
                </textarea>
                {hasInputNote ? (
                  <div className='group-note-button'>
                    <button className='cancel-btn' onClick={clearNoteData}>
                      Clear
                    </button>
                    <button className='save-btn' onClick={addAppoitmentNote}>
                      Add Note
                    </button>
                  </div>
                ) : null}
              </div>
            </DialogActions>
          ) : (
              <DialogActions className='group-field-container'>
                <div className='group-field'>
                  <TextField
                    className='outlined-required field-title'
                    style={{ width: '95%' }}
                    size='small'
                    autoComplete='off'
                    placeholder='Title'
                    value={noteTitle}
                    onInput={_handleChangeTitleEditing}
                  />
                  <textarea
                    className='outlined-required field-content'
                    style={{ width: '95%' }}
                    placeholder='Take a note'
                    value={noteContent}
                    onChange={_handleChangeContentEditing}
                  >
                    {' '}
                  </textarea>
                  {isChangeNoteEdit ? (
                    <div className='group-note-button'>
                      <button className='cancel-btn' onClick={clearNoteData}>
                        Cancel
                    </button>
                      <button className='save-btn' onClick={saveNote}>
                        Save
                    </button>
                    </div>
                  ) : null}
                </div>
              </DialogActions>
            )}
        </Dialog>
      </Draggable>
    </div>
  );
};

export default NoteComponent;
