import * as t from 'io-ts';

const RequiredMedical = t.type({
  medicalServiceId: t.string,
  medicalServiceName: t.string,
  isVaccine: t.boolean,
  isShowInClientPortal: t.boolean,
  isCompliance: t.boolean,
});

const NullableMedical = t.partial({
  tenantId: t.string,
  externalId: t.string,
  speciesNames: t.array(t.string),
  order: t.number,
});

export const MedicalEntry = t.intersection([RequiredMedical, NullableMedical]);

export type Medical = t.TypeOf<typeof MedicalEntry>;

export const MedicalCollectionEntry = t.type({
  count: t.number,
  totalAvailable: t.number,
  medicalServices: t.array(MedicalEntry),
});

export type MedicalCollection = t.TypeOf<typeof MedicalCollectionEntry>;
