/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment from 'moment';
import { toast } from 'react-toastify';
import loading_spinner from 'media/images/loading-spinner.svg';

type UploadComponentProps = {
  setFieldValue: any;
  values: any;
  setErrorUploadFile: any;
};

const UploadComponent = (props: UploadComponentProps) => {
  const { setFieldValue, values, setErrorUploadFile } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'application/zip': ['.zip']},
    onDrop: async (acceptedFiles: any) => {
      setFieldValue('files', acceptedFiles);
      let formData = new FormData();
      formData.append('attachment', acceptedFiles[0]);
      setIsLoading(true);
      try {
        setErrorUploadFile('');
        const res = await configuredRequests.POST.uploadService(formData);
        if (res) {
          setIsLoading(false);
          toast.success(
            <div className='notify-success d-flex'>
              <FontAwesomeIcon
                icon={['fas', 'check-circle']}
                className='icon-check-status'
              />
              <div className='success-message'>
                <b>Successfully</b>
                <p>File uploaded successfully</p>
              </div>
            </div>,
            { className: 'toast-success' }
          );
          setFieldValue(
            'releasedDate',
            moment(res.releasedDate).format('YYYY-MM-DDTHH:mm')
          );
          setFieldValue('version', res.version);
          setFieldValue('downloadLink', res.downloadLink);
        }
      } catch (err) {
        setIsLoading(false);
        setFieldValue('releasedDate', '');
        setFieldValue('version', 'N/A');
        setFieldValue('downloadLink', ' ');
        toast.error(
          <div className='notify-unsuccess d-flex'>
            <FontAwesomeIcon
              icon={['fal', 'times-circle']}
              className='icon-check-status'
            />
            <div className='unsuccess-message'>
              <b>Error</b>
              <p>File upload failed</p>
            </div>
          </div>,
          { className: 'toast-unsuccess' }
        );
      }
    },
  });

  return (
    <>
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <h5>Add new version</h5>
              <FontAwesomeIcon icon={['fas', 'folder-plus']} />
              <p>
                {' '}
                <span>Add file</span> or Drop your files here
              </p>
              {values.files &&
                values.files.map((file: any, i: any) => (
                  <li key={i}>
                    {
                      //@ts-ignore
                      `File:${file.name} Size:${file.size} bytes`
                    }{' '}
                  </li>
                ))}
            </>
          )}
        </div>
        <div className='spinner-upload'>
          {isLoading ? <img src={loading_spinner} alt={'Loading'} /> : ''}
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
