/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { UserInteractionResponseUnionType } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import InteractionList from 'components/Contacts/ViewContact/InteractionList';
import TextViewContainer from 'components/Contacts/ViewContact/TextViewContainer';
import EmailViewContainer from 'components/Contacts/ViewContact/EmailViewContainer';
import CommunicationNav from 'components/Contacts/ViewContact/InteractionNav';
import InfoColumn from 'components/Contacts/ViewContact/InfoColumn';
import UserInformationModal from 'components/Global/SettingsComponent/UserInformationModal';
import { useParams } from 'react-router-dom';
import {
  UserDataResponse,
  UserSettingObjectType,
} from 'global/requests/ResponseTypes/UserAdministration';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import { getFullUserName, getSettingByNamespace } from 'utils/convertData';
import { Modal } from '@material-ui/core';
import SubscriptionSetting from 'components/Contacts/ViewContact/SubscriptionSetting';

interface roleInformationDataType {
  roleId: string;
  roleName: string;
}

const ViewUserDetailContainer = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [displaySettingUserModal, setDisplaySettingUserModal] =
    React.useState(false);
  const [currentActiveView, setCurrentActiveView] =
    React.useState<string>('interactions');
  const [userInfo, setUserInfo] = React.useState<UserDataResponse>();
  const [userInfoForEdit, setUserInfoForEdit] =
    React.useState<UserDataResponse>();
  const [hasNewEmail, setValueHasNewEmail] = React.useState<boolean>(false);
  const [interactionData, setInteractionData] =
    React.useState<UserInteractionResponseUnionType>();
  const [roleInformationData, setRoleInformationData] =
    React.useState<roleInformationDataType[]>();
  const [textDataContact, setTextDataContact] = React.useState();
  const [infoSending, setInfoSending] = React.useState({
    sending: false,
    isError: false,
  });
  const [viewAllSms, setViewAllSms] = React.useState<boolean>(false);
  const [openSubscriptionSetting, setOpenSubscriptionSetting] =
    React.useState(false);
  const [useEstimates, setUseEstimates] = React.useState<boolean>(false);

  const sendGetTenantInfoRequest = async () => {
    try {
      const res = await configuredRequests.GET.currentTenantInfo();
      if (res) {
        // @ts-ignore
        setUseEstimates(res.useEstimates);
      }
    } catch (err) {
      console.log('sendGetTenantInfoRequest: ', err);
    }
  };

  const getMessagesByUserID = async (userID: string) => {
    try {
      const res = await configuredRequests.GET.messagesByUserID(userID, {
        commKinds: ['email', 'sms', 'call', 'postal'],
      });
      if (res) {
        setInteractionData(res as UserInteractionResponseUnionType);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const getMessagesByUserIDTwoWay = useCallback(
    async (
      userID: string,
      disabledLoading?: boolean,
      allMessages?: boolean
    ) => {
      try {
        setLoading(true);
        const res = await configuredRequests.GET.getMessagesByUserID(
          userID,
          allMessages
        );
        if (res) {
          setTextDataContact(res);
          setLoading(false);
        }
      } catch (err) {
        console.log('error', err);
        setLoading(false);
      }
    },
    [setTextDataContact]
  );

  const sendGetUserDataRequest = async () => {
    try {
      const res = await configuredRequests.GET.singleUserByID(userId);
      if (res) {
        setUserInfo(res);
        setUserInfoForEdit(res);
      }
    } catch (err) {}
  };

  const getAllRole = async () => {
    try {
      const res = await configuredRequests.GET.getRoles();
      const activeRolesData = res.roles
        .filter((items) => items.roleStatus === 'Active')
        .map((items) => {
          return {
            roleName: items.roleName,
            roleId: items.roleId,
          };
        });
      setRoleInformationData(activeRolesData as roleInformationDataType[]);
    } catch (err) {}
  };

  const refreshMessageData = React.useCallback(
    (userId: string, disabledLoading?: boolean, allMessengers?: boolean) => {
      if (!userInfo) {
        //TODO logging
        return;
      }
      getMessagesByUserIDTwoWay(userId, disabledLoading, allMessengers);
      getMessagesByUserID(userId);
    },
    [userInfo]
  );

  const refreshUserInfo = React.useCallback(() => {
    sendGetUserDataRequest();
  }, [userInfo]);

  useEffect(() => {
    Promise.all([
      sendGetUserDataRequest(),
      getMessagesByUserID(userId),
      getAllRole(),
      sendGetTenantInfoRequest(),
      getMessagesByUserIDTwoWay(userId, false, false),
    ]).then(() => {
      // setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (hasNewEmail) {
      getMessagesByUserID(userId);
      setValueHasNewEmail(false);
    }
  }, [hasNewEmail]);

  useEffect(() => {
    const getNewMessage = setInterval(() => {
      setInfoSending({ sending: false, isError: false });
      if (userInfo && userInfo.userId) {
        if (viewAllSms) {
          getMessagesByUserIDTwoWay(userInfo.userId, false, true);
        } else {
          getMessagesByUserIDTwoWay(userInfo.userId);
        }
      }
    }, 30000);

    return () => clearInterval(getNewMessage);
  });

  useEffect(() => {
    if (currentActiveView === 'text') {
      getMessagesByUserIDTwoWay(userId, false, false);
    }
  }, [currentActiveView]);

  const checkNewEmail = (hasNewEmail: boolean) => {
    setValueHasNewEmail(hasNewEmail);
  };

  const refreshPhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    // @ts-ignore
    setUserInfo((prevUser) => ({ ...prevUser, phoneNumbers: phoneNumbers }));
    sendGetUserDataRequest();
  };

  const handleUpdateSubscriptionSetting = (
    settings: UserSettingObjectType[]
  ) => {
    // @ts-ignore
    setUserInfo((prev) => ({ ...prev, userSettings: settings }));
  };

  const getcommunicationTypeStatus = (communicationType: string) => {
    if (userInfo == null || userInfo == undefined) {
      return false;
    }

    let setting = getSettingByNamespace(userInfo, 'UserSubscriptionSetting');
    if (communicationType == 'text') {
      return setting?.settings.sms;
    }

    if (communicationType == 'email') {
      return setting?.settings.email;
    }

    if (communicationType == 'postal') {
      return setting?.settings.postal;
    }

    if (communicationType == 'phone') {
      return setting?.settings.phone;
    }

    return false;
  };

  const renderActiveView = (input?: string) => {
    switch (input ?? currentActiveView) {
      case 'interactions':
        return (
          <InteractionList
            userInfo={userInfo}
            interactionData={interactionData}
            currentActiveView={currentActiveView}
            refreshMessageData={refreshMessageData}
          />
        );
      case 'text':
        return (
          <TextViewContainer
            userInfo={userInfo}
            interactionData={textDataContact}
            refreshMessageData={refreshMessageData}
            setInteractionData={setTextDataContact}
            infoSending={infoSending}
            setInfoSending={setInfoSending}
            refreshPhoneNumbers={refreshPhoneNumbers}
            subscription={getcommunicationTypeStatus('text')}
            viewAllSms={viewAllSms}
            setViewAllSms={setViewAllSms}
            loading={loading}
            onSubscriptionClick={setOpenSubscriptionSetting}
          />
        );
      case 'email':
        return (
          <EmailViewContainer
            checkNewEmail={checkNewEmail}
            userInfo={userInfo}
            interactionData={interactionData}
            subscription={getcommunicationTypeStatus('email')}
            onSubscriptionClick={setOpenSubscriptionSetting}
          />
        );
      default:
        return (
          <InteractionList
            userInfo={userInfo}
            interactionData={interactionData}
            currentActiveView={currentActiveView}
            refreshMessageData={refreshMessageData}
          />
        );
    }
  };

  return (
    <div className={'view-contact--main-container'}>
      <InfoColumn
        userInfo={userInfo}
        useEditUserSettings={{
          setDisplaySettingUserModal: setDisplaySettingUserModal,
        }}
      />

      <span className={'view-contact--container max-height'}>
        <CommunicationNav
          currentActiveView={currentActiveView}
          setCurrentActiveView={setCurrentActiveView}
          reloadList={refreshMessageData}
          registered={userInfo?.registered}
          onSubscriptionClick={setOpenSubscriptionSetting}
          useEstimates={useEstimates}
        />
        {renderActiveView()}
      </span>

      <UserInformationModal
        displaySettingUserModal={displaySettingUserModal}
        setDisplaySettingUserModal={setDisplaySettingUserModal}
        isEdit={true}
        titleText={'Edit'}
        userInformation={userInfoForEdit}
        setUserInformationData={setUserInfoForEdit}
        refreshUserInfo={refreshUserInfo}
        roleInformationData={roleInformationData || []}
      />

      {openSubscriptionSetting && userInfo && (
        <Modal
          open={openSubscriptionSetting}
          onClose={() => setOpenSubscriptionSetting(false)}
        >
          <SubscriptionSetting
            userId={userInfo.userId}
            fullName={getFullUserName(userInfo)}
            settings={userInfo.userSettings}
            onClose={setOpenSubscriptionSetting}
            onUpdate={handleUpdateSubscriptionSetting}
          />
        </Modal>
      )}
    </div>
  );
};

export default ViewUserDetailContainer;
