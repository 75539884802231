import styled from 'styled-components';
import { getThemeProp } from 'components/Legwork/utils';

const ListSelectContainer = styled.div`
  padding: 0.5rem;
  border-radius: 6px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
  .option {
    cursor: pointer;
  }
  .option:hover {
    background-color: ${getThemeProp('palette.background.default', '#F6F8FC')};
  }
  .option {
    &.selected {
      background-color: lightgray; /* TODO: update with theme color */
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { ListSelectContainer };
