import React from 'react';
import { AttributeStatusResponse } from 'global/requests/ResponseTypes';

interface AttributeStatusProps {
  attribute: AttributeStatusResponse
  haveRatio?: boolean
}

const AttributeStatus = (props: AttributeStatusProps): JSX.Element => {
  return (
    <div key={"communication-analyze" + props.attribute.name}
      className="communication-container__attribute-status"
    >
      <div className="attribute-title"> {props.attribute.name} </div>
      <div className="attribute-value"> {props.attribute.value} </div>
      {
        props.haveRatio && props.haveRatio === true
        ? <div className="attribute-ratio"> {props.attribute.ratio ? props.attribute.ratio + "%" : ''} </div>
        : null
      }
    </div>
  );
}

export default AttributeStatus;