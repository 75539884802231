import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

interface DatePickerProps {
  showDateRange?: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  showTimePicker?: boolean;
  getEndDate: boolean;
}

const DateTimePickerComponent = (props: DatePickerProps): JSX.Element => {
  const [dateRange, setDateRange] = React.useState<string>('30');
  const [error, setError] = useState('');
  const maxEndDate = moment(new Date()).endOf('day').toDate();
  const handleSelectStartDate = (date: Date | null) => {
    setError('');
    const formatDate = props.getEndDate ? moment(date).endOf('day').toDate() : moment(date).toDate();
    if (props.showTimePicker) {
      moment(formatDate).isAfter(props.endDate, 'minutes') ? setError('The start date must be before the end date') : props.setStartDate(formatDate);
    } else {
      props.setStartDate(formatDate);
    }
  };

  const handleSelectEndDate = (date: Date | null) => {
    setError('');
    const formatSelectDate = props.getEndDate ? moment(date).endOf('day').toDate() : moment(date).toDate();
    if (props.showTimePicker) {
      moment(formatSelectDate).isBefore(props.startDate, 'minutes') ? setError('The start date must be before the end date') : props.setEndDate(formatSelectDate);
    } else {
      props.setEndDate(formatSelectDate);
    }
  };

  React.useEffect(() => {
    if (props.endDate && props.startDate) {
      const date = Math.abs(
        props.endDate?.getTime() - props.startDate?.getTime()  
      );
      const oneDay = 24 * 60 * 60 * 1000;
      setDateRange((Math.round(date / oneDay) + 1).toString());
    } else {
      setDateRange('0');
    }
  }, [props.startDate, props.endDate]);

  return (
    <>
      {props.showDateRange ? (
        <select className='date-range' disabled>
          <option value={dateRange}>Range: {dateRange} day(s)</option>
        </select>
      ) : (
          ''
        )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container className='grid-custom' style={props.showTimePicker ? { width: 'auto' } : {}}>
          <FontAwesomeIcon
            icon={['fas', 'calendar-alt']}
            className='icon-calendar__communication-start-date'
          />
          {
            props.showTimePicker ? <DateTimePicker
              format={'MM/dd/yy hh:mm a'}
              id='date-picker-inline'
              value={props.startDate}
              onChange={handleSelectStartDate}
              maxDate={props.endDate}
              className={'datetime-picker-custom date-picker-custom'}
              autoOk={true}
            /> : <DatePicker
                format={'MM/dd/yy'}
                id='date-picker-inline'
                value={props.startDate}
                onChange={handleSelectStartDate}
                maxDate={props.endDate}
                disableToolbar
                className={'date-picker-custom'}
                autoOk={true}
              />
          }

          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          <FontAwesomeIcon
            icon={['fas', 'calendar-alt']}
            className='icon-calendar__communication-end-date'
            style={props.showTimePicker ? { left: '210px' } : {}}
          />
          {
            props.showTimePicker ? <DateTimePicker
              format={'MM/dd/yy hh:mm a'}
              id='date-picker-inline-2'
              value={props.endDate}
              onChange={handleSelectEndDate}
              maxDate={maxEndDate}
              className={'datetime-picker-custom date-picker-custom'}
              autoOk={true}
              minDate={props.startDate}
            /> : <DatePicker
                format={'MM/dd/yy'}
                id='date-picker-inline-2'
                value={props.endDate}
                onChange={handleSelectEndDate}
                maxDate={maxEndDate}
                disableToolbar
                className={'date-picker-custom'}
                autoOk={true}
                minDate={props.startDate}
              />
          }
        </Grid>
      </MuiPickersUtilsProvider>
      {error && <span style={{ color: '#F64F45', marginTop: '10px' }}>{error}</span>}
    </>
  );
};
export default DateTimePickerComponent;
