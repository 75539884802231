/* eslint-disable @typescript-eslint/naming-convention */
import APIService from '../clientApi';
import {
  IAppointmentType,
  IHoursOfOperationForDay,
  IProviderHoursOfOperation,
  IHoliday,
  IUnavailabilityEvent,
  IProviderUnavailabilityEvent,
  IProvider,
  IAppointmentBookingShareLinks,
  IAppointmentBookingForm,
  IAppointmentBookingRequestParams,
  IAppointmentBookingRequest,
  IFormSubmissionContactResponse,
  AppointmentBookingRequestUpdateParams,
} from '.';
import {
  IGenericForm,
  IFormSubmissionQueue,
  IFormSubmissionQueueResponse,
} from '../paperlessFormsService/paperlessFormsTypes';
import {
  appointmentTypesTestData,
  hoursOfOperationTestData,
  providerHoursOfOperationTestData,
  holidaysAvailableTestData,
  unavailabilityEventTestData,
  providerUnavailabilityEventTestData,
  providersTestData,
  bookingRequestsTestData,
  formSubmissionContactTestData,
  IFormSubmissionContactTest,
  bookingRequestSubmissionsTestData,
} from './constants';

import {
  transformHoursOfOperationResult,
  transformHoursOfOperationRequest,
  transformFormContactRequest,
  transformFormContactResponse,
} from './utils';

const API = APIService();

export interface IFormContact {
  id?: number;
  formId: number;
  contactId: string;
  isDeleted?: boolean;
}

export type WeekdaysType =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export interface IDayHours {
  active: boolean;
  from: string | null; // 24hr 23:00
  to: string | null; // 24hr `19:00
  increments?: number;
}

export interface IAvailabilitySettings {
  hoursOfOperation: {
    [key in WeekdaysType]: IDayHours;
  };
}

export const randomId = (): string =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fakeGet = (path: string, params: any, testData: any[]): Promise<any> => {
  const { id, idKey } = params;
  if (id) {
    return Promise.resolve(testData.filter((item): boolean => item[idKey ?? 'id'] === id));
  }
  return Promise.resolve(testData);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fakePost = (path: string, params: any): Promise<any> =>
  Promise.resolve({ ...params, id: randomId() });
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fakePut = (path: string, params: any): Promise<any> => Promise.resolve(params);
const fakeDelete = (path: string, id: number | string): Promise<number | string> =>
  Promise.resolve(id);

const getAppointmentTypes = async (testData?: IAppointmentType[]): Promise<IAppointmentType[]> =>
  fakeGet('/appointment-booking/appointment-types', {}, testData ?? appointmentTypesTestData);

const postAppointmentType = async (params: IAppointmentType): Promise<IAppointmentType> =>
  fakePost('/appointment-booking/appointment-types', params);

const putAppointmentType = async (params: IAppointmentType): Promise<IAppointmentType> =>
  fakePut(`/appointment-booking/appointment-types/${params.id}`, params);

const deleteAppointmentType = async (id: number): Promise<number> =>
  Number(fakeDelete(`/appointment-booking/appointment-types/${id}`, id));

const getHoursOfOperation = async (
  testData?: IHoursOfOperationForDay[],
): Promise<IAvailabilitySettings['hoursOfOperation']> => {
  const res = await fakeGet(
    '/appointment-booking/office/hours-of-operation',
    {},
    testData ?? hoursOfOperationTestData,
  );
  return transformHoursOfOperationResult(res);
};

const putHoursOfOperation = async (
  params: IAvailabilitySettings['hoursOfOperation'],
): Promise<IHoursOfOperationForDay[]> => {
  const transformedRequest = transformHoursOfOperationRequest(params);
  return fakePut('/appointment-booking/office/hours-of-operation', transformedRequest);
};

const getHolidaysAvailable = (): Promise<IHoliday[]> =>
  fakeGet('/appointment-booking/holidays/available', {}, holidaysAvailableTestData);

const getUnavailability = async (
  testData?: IUnavailabilityEvent[],
): Promise<IUnavailabilityEvent[]> =>
  fakeGet(
    '/appointment-booking/office/unavailability',
    {},
    testData ?? unavailabilityEventTestData,
  );

const postUnavailability = async (params: IUnavailabilityEvent): Promise<IUnavailabilityEvent> =>
  fakePost('/appointment-booking/office/unavailability', params);

const putUnavailability = async (params: IUnavailabilityEvent): Promise<IUnavailabilityEvent> =>
  fakePut(`/appointment-booking/office/unavailability/${params.id}`, params);

const deleteUnavailabilityEvent = async (id: number): Promise<number> =>
  Number(fakeDelete(`/appointment-booking/office/unavailability/${id}`, id));

const getProviders = async (testData?: IProvider[]): Promise<IProvider[]> =>
  fakeGet('/appointment-booking/office/unavailability', {}, testData ?? providersTestData);

const postProvider = async (params: IProvider): Promise<IProvider> =>
  fakePost('/appointment-booking/providers', params);

const putProvider = async (params: IProvider): Promise<IProvider> =>
  fakePut(`/appointment-booking/providers/${params.id}`, params);

const updateProvider = async (params: IProvider): Promise<IProvider> => {
  // if no provider id, then this is a "new" provider
  // "new" = newly synced and not in our AB providers table yet
  if (!params.id) return postProvider(params);
  return putProvider(params);
};

const getProviderHoursOfOperation = async (
  id: number | string,
  testData?: IProviderHoursOfOperation[],
): Promise<IAvailabilitySettings['hoursOfOperation']> => {
  const res = await fakeGet(
    `/appointment-booking/providers/${id}/hours-of-operation`,
    { id, idKey: 'providerId' },
    testData ?? providerHoursOfOperationTestData,
  );
  return transformHoursOfOperationResult(res);
};

const putProviderHoursOfOperation = async (
  id: number,
  params: IAvailabilitySettings['hoursOfOperation'],
): Promise<IHoursOfOperationForDay[]> => {
  const transformedRequest = transformHoursOfOperationRequest(params);
  return fakePut(`/appointment-booking/providers/${id}/hours-of-operation`, transformedRequest);
};

const getProviderUnavailability = async (
  id: number | string,
  testData?: IProviderUnavailabilityEvent[],
): Promise<IProviderUnavailabilityEvent[]> =>
  fakeGet(
    `/appointment-booking/providers/${id}/hours-of-operation`,
    { id, idKey: 'providerId' },
    testData ?? providerUnavailabilityEventTestData,
  );

const postProviderUnavailability = async (
  params: IProviderUnavailabilityEvent,
): Promise<IProviderUnavailabilityEvent> =>
  fakePost(`/appointment-booking/providers/${params.providerId}/unavailability`, params);

const putProviderUnavailability = async (
  params: IProviderUnavailabilityEvent,
): Promise<IProviderUnavailabilityEvent> =>
  fakePut(
    `/appointment-booking/providers/${params.providerId}/unavailability/${params.id}`,
    params,
  );

// TODO: need providerId
const deleteProviderUnavailabilityEvent = async (id: number): Promise<number> =>
  Number(fakeDelete(`/appointment-booking/providers/{providerId}/unavailability/${id}`, id));

const putProviderAppointmentTypes = async (id: number, params: number[]): Promise<number[]> =>
  fakePut(`/appointment-booking/providers/${id}/appointment-types`, params);

const getAppointmentBookingShareLinks = async (): Promise<IAppointmentBookingShareLinks> =>
  API.rpc('', { method: 'appointment_booking_request.getShareLinks', params: [] }).then(
    res => res.p[1],
  );

const getAppointmentBookingFormId = async (): Promise<number> =>
  API.rpc('', { method: 'appointment_booking_request.getFormId', params: [] }).then(
    res => res.p[1],
  );

const getAppointmentBookingForm = async (): Promise<IGenericForm> => {
  const formId = await getAppointmentBookingFormId();
  const rpc = await API.rpc('', {
    method: 'FormBuilder.FormsInterface.getBy',
    params: ['Forms', { id: formId }],
  });
  return rpc.p[1][0];
};

const getAppointmentBookingFormSettings = async (): Promise<IAppointmentBookingForm> => {
  const form = await getAppointmentBookingForm();
  const formContacts = await API.rpc('', {
    method: 'FormBuilder.FormsInterface.getBy',
    params: ['FormContacts', { form_id: form.id }],
  });
  return {
    form,
    formContacts: transformFormContactResponse(formContacts.p[1]),
  };
};

const saveAppointmentBookingNotificationUsers = async (
  params: IFormContact[],
): Promise<IFormContact[]> => {
  const promises = transformFormContactRequest(params).map(
    (contact): Promise<any> =>
      API.rpc('', {
        method: 'FormBuilder.FormsInterface.upsertBy',
        params: ['FormContacts', contact],
      }),
  );
  return Promise.all(promises).then((results): any[] => {
    return results.map((result: { p: any[] }): IFormContact => result.p[1]);
  });
};

const saveForm = async (params: IGenericForm): Promise<IGenericForm> =>
  API.rpc('', { method: 'FormBuilder.FormsInterface.upsertBy', params: ['Forms', params] });

const getAppointmentBookingRequests = async (
  params: IAppointmentBookingRequestParams,
  testData?: IAppointmentBookingRequest[],
): Promise<IAppointmentBookingRequest[]> => {
  const res: IAppointmentBookingRequest[] = await fakeGet(
    '/appointment-booking/requests',
    { params },
    testData ?? bookingRequestsTestData,
  );
  // TODO: remove this filtering once tied in to real API
  const { nameSearch, type } = params;
  return res
    .filter((request): boolean =>
      nameSearch
        ? `${request.nameFirst} ${request.nameLast}`
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        : true,
    )
    .filter((request): boolean => (type ? request.acceptanceStatus === type : true));
};

const getFormSubmissionContact = async (
  requestId: number,
): Promise<IFormSubmissionContactResponse | undefined> => {
  // TODO: test data logic should be replaced with below rpc call when ready:
  // API.rpc('', { method: 'appointment_booking_request.getFormSubmissionContact', params: [requestId] })
  // (response shape should be fine w/o mods)
  const result = await formSubmissionContactTestData.find(
    (f: IFormSubmissionContactTest): boolean => f.requestId === requestId,
  );
  if (!result) return undefined;

  const { data } = result;
  const { id, name_first, name_last } = data;
  return {
    id,
    contactName: `${name_first} ${name_last}`,
  };
};

const getFormSubmission = async (id: number): Promise<IFormSubmissionQueue | undefined> => {
  // TODO: API.rpc({ method: 'FormBuilder.FormsInterface.getBy', params: ['FormSubmissionQueue', { id }] });
  // for now, return test data. make call to real API.rpc when ready
  const res: IFormSubmissionQueueResponse = bookingRequestSubmissionsTestData;
  const submission = await res.p[1].find((r): boolean => r.id === id);
  return submission;
};

const updateBookingRequest = async (
  params: AppointmentBookingRequestUpdateParams,
  testData?: IAppointmentBookingRequest,
): Promise<IAppointmentBookingRequest> =>
  API.fakeCall(
    '',
    { method: 'appointment_booking_request.updateOnlineAppointmentRequestStatus', params },
    undefined,
    testData,
  ).then(res => res.p[1].results);

const archiveBookingRequest = async (requestId: string | number): Promise<void> => {
  fakeDelete('/appointment-booking-requests/', requestId);
};

export default {
  getAppointmentTypes,
  postAppointmentType,
  putAppointmentType,
  deleteAppointmentType,
  getHoursOfOperation,
  putHoursOfOperation,
  getHolidaysAvailable,
  getUnavailability,
  postUnavailability,
  putUnavailability,
  deleteUnavailabilityEvent,
  getProviders,
  postProvider,
  putProvider,
  updateProvider,
  getProviderHoursOfOperation,
  putProviderHoursOfOperation,
  getProviderUnavailability,
  postProviderUnavailability,
  putProviderUnavailability,
  deleteProviderUnavailabilityEvent,
  putProviderAppointmentTypes,
  getAppointmentBookingShareLinks,
  getAppointmentBookingFormId,
  getAppointmentBookingForm,
  getAppointmentBookingFormSettings,
  saveAppointmentBookingNotificationUsers,
  saveForm,
  getAppointmentBookingRequests,
  getFormSubmissionContact,
  getFormSubmission,
  updateBookingRequest,
  archiveBookingRequest,
};
