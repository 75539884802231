import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledTabs = styled(Tabs)`
  width: 270px;
  max-height: calc(100vh - 193px);
  overflow-y: auto !important;
  margin-right: -5px;

  .MuiTabs-scroller {
    overflow: initial !important;
  }

  .MuiTabs-indicator {
    left: 0;
    width: 4px;
    background-color: ${getThemeProp('palette.primary.main', '#4C7CFD')};
    border-radius: 0 4px 4px 0;
  }

  .Mui-selected {
    background-color: ${getThemeProp('palette.background.default', '#F6F8FC')};
    .MuiTab-wrapper {
      font-weight: 500;
    }
  }

  .MuiTab-root {
    border-bottom: 1px solid ${getThemeProp('palette.divider', '#E3EBF6')};
    text-transform: initial;
  }

  .MuiTab-wrapper {
    padding-left: 1rem;

    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    color: ${getThemeProp('palette.text.primary', '#4C7CFD')};
  }
`;

const FlexDiv = styled.div`
  ${({ theme }): string => `
    display: flex;
    flex-grow: 1;
    background-color: ${theme?.palette?.background?.default ?? '#F6F8FC'};
    height: 100%;
  `}
`;

const MainDiv = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  border-right: 1px solid #e3ebf6;
  background-color: white;
  padding-right: 0px;
  margin-right: 0px;

  .error-msg {
    margin-bottom: 5px;
    margin-left: 24px;
    font-size: 16px;
    color: #000 !important;
    font-weight: bold;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledTabs, FlexDiv, MainDiv };
