import { configForSize as configForFrontSize } from './PostCardTemplate';

const configForBackSize = {
  '4x6': {
    bodyWidth: '6.25in',
    bodyHeight: '4.25in',
    safeAreaWidth: {
      lob: '5.875in',
      postGrid: 'calc(3.85in - 20px)',
    },
    safeAreaHeight: {
      lob: '3.875in',
      postGrid: 'calc(3.625in - 20px)',
    },

    inkwidth: '3.2835in',
    inkHeight: '2.375in',
    textWidth: {
      lob: '200px',
      postGrid: '3.475in',
    },
    textFontSize: '13px',
  },
  '6x9': {
    bodyWidth: '9.25in',
    bodyHeight: '6.25in',
    safeAreaWidth: {
      lob: '8.875in',
      postGrid: '8.875in',
    },
    safeAreaHeight: {
      lob: '5.875in',
      postGrid: '5.875in',
    },
    inkwidth: '3.2835in',
    inkHeight: '2.375in',
    textWidth: {
      lob: '500px',
      postGrid: '500px',
    },
    textFontSize: '23px',
  },
  '6x11': {
    bodyWidth: '11.25in',
    bodyHeight: '6.25in',
    safeAreaWidth: {
      lob: '10.875in',
      postGrid: '10.875in',
    },
    safeAreaHeight: {
      lob: '5.875in',
      postGrid: '5.875in',
    },
    inkwidth: '4in',
    inkHeight: '2.375in',
    textWidth: {
      lob: '600px',
      postGrid: '600px',
    },
    textFontSize: '28px',
  },
};

export const getContentFrontPostCard = (
  urlImage: string,
  sizePostCard: '4x6' | '6x9' | '6x11'
) => {
  return `<!DOCTYPE html>
  <html>
  <head>
    <meta charset="UTF-8">
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
      
      *,
      *:before,
      *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    </style>
  </head>
  <!-- <cut> -->
  <body style="position: relative; width: ${configForFrontSize[sizePostCard].bodyWidth}; height: ${configForFrontSize[sizePostCard].bodyHeight}; margin: 0; padding: 0; background-image: url(${urlImage}); background-size: ${configForFrontSize[sizePostCard].bodyWidth} ${configForFrontSize[sizePostCard].bodyHeight}; background-repeat: no-repeat;background-color: #FFFFFF; box-shadow: 0 0 20px 0 rgba(0,0,0,0.12);">
    <div id="safe-area" style="position: absolute; width: ${configForFrontSize[sizePostCard].safeAreaWidth}; height: ${configForFrontSize[sizePostCard].safeAreaHeight}; left: 0.1875in; top: 0.1875in;">
      <!-- All text should appear within the safe area. -->
      <div class="text" style="margin: 30px 10px 10px 50px; line-height: 25px; font-family: 'Nunito Sans'; font-weight: 600; font-size: ${configForFrontSize[sizePostCard].textFontSize}; color: #12253F;">
      </div>
    </div>
  </body>
  <!-- </cut> -->
  </html>
  `;
};

export const getContentBackPostCard = (
  contentBackSide: string,
  sizePostCard: '4x6' | '6x9' | '6x11',
  isPostGrid?: boolean
) => {
  return `
  <!DOCTYPE html>
  <html>

  <head>
    <meta charset="UTF-8">
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

      *,
      *:before,
      *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    </style>
  </head>
  <!-- <cut> -->
  <body>
  <div
    style="width: ${configForBackSize[sizePostCard].bodyWidth}; height: ${
    configForBackSize[sizePostCard].bodyHeight
  }; background-color: #FFFFFF; position: relative; padding: 20px 0px 0px 20px;">
    <div id="safe-area"
      style="position: absolute; width: ${
        configForBackSize[sizePostCard].safeAreaWidth.lob
      }; height: ${
    configForBackSize[sizePostCard].safeAreaHeight.lob
  }; left: 0.1875in; top: 0.1875in; background-color: rgba(255, 255, 255, 0.5); overflow: hidden;">
      <div className="header-back-postcard" style="${
        isPostGrid
          ? 'display: flex; justify-content: space-between'
          : 'width: 5.875in'
      }">
     <div style="display: ${isPostGrid ? 'none' : 'inline-block;'}">
          <p
            style="margin-right: 10px; word-break: break-word; white-space: unset !important;font-size: 13px; font-weight: bold; font-family: 'Nunito Sans', sans-serif; letter-spacing: 0; line-height: 16px; color: #000">
            {{AccountName}}</p>
          <p
            style="margin-right: 10px; word-break: break-word; white-space: unset !important;font-size: 13px; font-weight: bold; font-family: 'Nunito Sans', sans-serif; letter-spacing: 0; line-height: 16px; color: #000;">
            {{AccountOfficeNumber}}</p>
        </div>
      
        <div style="display: inline-block; float: right">
          {{AccountLogo}}
        </div>
      </div>
      <div className="text"
        style="width: ${
          configForBackSize[sizePostCard].textWidth[
            isPostGrid ? 'postGrid' : 'lob'
          ]
        }; font-family: 'Nunito Sans', sans-serif; font-size: ${
    configForBackSize[sizePostCard].textFontSize
  }; word-break: break-word; line-height: 1.1; font-weight: 300;">
        ${contentBackSide}
      </div>
    </div>

    <!-- Delete this div before submitting your postcard! -->
    <!-- Do not place any artwork or text in the ink free area. Address and postage will be automatically printed here by Lob. -->
  </div>
  </body>
  <!-- </cut> -->

  </html>
  `;
};

export const getContentToSavePreview = ({
  frontSideUrl,
  sizePostCard,
  contentBackSide,
  isPostGrid,
}: {
  frontSideUrl: string;
  contentBackSide: string;
  sizePostCard: '4x6' | '6x9' | '6x11';
  isPostGrid?: boolean;
}) => {
  return `
  <!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

        *, *:before, *:after {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
      </style>
    </head>
    <!-- <cut> -->
    <body>
      <div style="position: relative; width: ${
        configForFrontSize[sizePostCard].bodyWidth
      }; height: ${
    configForFrontSize[sizePostCard].bodyHeight
  }; margin: 0; padding: 0; background-image: url(${frontSideUrl}); background-size: 6.25in 4.25in; background-repeat: no-repeat;background-color: #FFFFFF; border: 1px solid #DEE0EC; box-shadow: 0 5px 10px 0 rgba(0,0,0,0.12);">
        <div id="safe-area" style="position: absolute; width: ${
          configForFrontSize[sizePostCard].safeAreaWidth
        }; height: ${
    configForFrontSize[sizePostCard].safeAreaHeight
  }; left: 0.1875in; top: 0.1875in;">
        </div>
      </div>
      <div
        style="width: ${configForBackSize[sizePostCard].bodyWidth}; height: ${
    configForBackSize[sizePostCard].bodyHeight
  }; margin-top: 10px; border: 1px solid #DEE0EC; background-color: #ffffff; position: relative; padding: 20px 0px 0px 20px; box-shadow: 0 5px 10px 0 rgba(0,0,0,0.12);">
        <div id="safe-area"
          style="position: absolute; width: ${
            configForBackSize[sizePostCard].safeAreaWidth.lob
          }; height: ${
    configForBackSize[sizePostCard].safeAreaHeight.lob
  }; left: 0.1875in; top: 0.1875in; background-color: rgba(255, 255, 255, 0.5); font-weight: 400; overflow: hidden;">
          <div className="header-back-postcard" style="${
            isPostGrid
              ? 'display: flex; justify-content: space-between'
              : 'width: 5.875in'
          }">
          <div style="display: ${isPostGrid ? 'none' : 'inline-block;'}">
              <p
                style="margin-right: 10px; word-break: break-word; white-space: unset !important;font-size: 13px; font-weight: bold; font-family: 'Nunito Sans', sans-serif; letter-spacing: 0; line-height: 16px; color: #000">
                {{AccountName}}</p>
              <p
                style="margin-right: 10px; word-break: break-word; white-space: unset !important;font-size: 13px; font-weight: bold; font-family: 'Nunito Sans', sans-serif; letter-spacing: 0; line-height: 16px; color: #000;">
                {{AccountOfficeNumber}}</p>
            </div>

            <div style="display: inline-block; float: right">
              {{AccountLogo}}
            </div>
          </div>
          <div className="text"
            style="width: ${
              configForBackSize[sizePostCard].textWidth[
                isPostGrid ? 'postGrid' : 'lob'
              ]
            }; font-family: 'Nunito Sans', sans-serif; font-size: ${
    configForBackSize[sizePostCard].textFontSize
  }; word-break: break-word; line-height: 1.1;">
            ${contentBackSide}
          </div>
        </div>

        <!-- Delete this div before submitting your postcard! -->
        <!-- Do not place any artwork or text in the ink free area. Address and postage will be automatically printed here by Lob. -->
        ${
          isPostGrid
            ? `<div
        style="
          position: absolute;
          right: 0;
          top: 0;
          height: calc(4.25in - 1px);
          width: 2.4in;
          padding: 10px;
          background-color: pink;
          font-family: 'Nunito Sans', sans-serif;
          font-size: 13px;
          line-height: 1.1;
        "
      >
        This red area is the address and indicia zone.
        <br />
        <br />
        It is 2.4in wide.<br />
        <br />
        Make sure you do not have any artwork or ink here
      </div>
      <div
        style="
          position: absolute;
          right: 0;
          bottom: 0;
          height: 0.625in;
          width: 4.75in;
          padding: 10px;
          background-color: rgb(233, 187, 233);
          font-family: 'Nunito Sans', sans-serif;
          font-size: 13px;
          line-height: 1.1;
        "
      >
        This is the USPS barcode zone. It is 4.75in wide and 0.625in tall. Make
        sure there is no artwork or ink here.
      </div>`
            : `<div id="ink-free" style="position: absolute; width: ${configForBackSize[sizePostCard].inkwidth}; height: ${configForBackSize[sizePostCard].inkHeight}; right: 16px; bottom: 16px; background-color:#ED7D31; display: flex; justify-content: center; align-items: center;">
          Contact Address
        </div>`
        }
      </div>
    </body>
    <!-- </cut> -->
  </html>
  `;
};
