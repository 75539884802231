import styled from 'styled-components';
import { makeStyles, createStyles, Theme, lighten } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/core/styles/withStyles';
import { COLORS, HEIGHT, BORDER_RADIUS, IProgressBarColor } from './constants';

const useStyles = (color: IProgressBarColor = COLORS, height: number = HEIGHT): Function => {
  return makeStyles(
    (theme: Theme): StyleRules => {
      return createStyles({
        root: {
          backgroundColor: color.rootColor || lighten(color.barColor, color.opacity ?? 0.5),
          height,
          borderRadius: BORDER_RADIUS,
        },
        bar: {
          backgroundColor: color.barColor,
        },
        label: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
          maxHeight: `${height}px`,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          '& span': {
            width: '100%',
            '& p': {
              color: color.labelColor,
              fontFamily: theme.typography.fontFamily,
            },
          },
        },
      });
    },
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export { useStyles, Container };
