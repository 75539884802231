import React from 'react';
import { PostCardWrapper as Wrapper } from './PostalTemplateWrapper';

export const configForSize = {
  "4x6": {
    bodyWidth: '6.25in',
    bodyHeight: '4.25in',
    safeAreaWidth: '5.875in',
    safeAreaHeight: '3.875in',
    textFontSize: '13px',
  },
  "6x9": {
    bodyWidth: '9.25in',
    bodyHeight: '6.25in',
    safeAreaWidth: '8.875in',
    safeAreaHeight: '5.875in',
    textFontSize: '40px',
  },
  "6x11": {
    bodyWidth: '11.25in',
    bodyHeight: '6.25in',
    safeAreaWidth: '10.875in',
    safeAreaHeight: '5.875in',
    textFontSize: '60px',
  },
};

interface IBackPostCard {
  size: "4x6" | "6x9" | "6x11";
  content: string;
  isHasBackGround?: boolean;
}

const FrontPostCard: React.FC<IBackPostCard> = (props) => {
  const { size, content, isHasBackGround = false} = props;
  // @ts-ignore
  return <Wrapper {...configForSize[size]} dangerouslySetInnerHTML={{ __html: content }} isHasBackGround={isHasBackGround}>
  </Wrapper>
}

export default FrontPostCard;