import ModalDialog from 'components/Global/Modal';
import React, {
  ChangeEvent,
  FocusEvent,
  SetStateAction,
  useState,
} from 'react';
import { phoneNumberPattern } from 'utils/regex';
import { Error } from './Messenger';
import MessengerInput from './MessengerInput';

type Props = {
  // inputComponent: React.FunctionComponent<Record<string, unknown>>;
  inputComponent: JSX.Element;
  phoneReceive: string;
  errorPhone: Error;
  onChangeError: React.Dispatch<SetStateAction<Error>>;
  onChangePhoneReceive: React.Dispatch<SetStateAction<string>>;
  onClosePopup: React.Dispatch<SetStateAction<boolean>>;
};

const NewMessage = (props: Props) => {
  const {
    inputComponent,
    phoneReceive,
    errorPhone,
    onChangeError,
    onChangePhoneReceive,
    onClosePopup,
  } = props;

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value;
    onChangePhoneReceive(phone);
    setError(phone, true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const phone = e.target.value;
    if (errorPhone.mount) {
      setError(phone);
    }
  };

  const setError = (phone: string, isMount?: boolean) => {
    if (!phone) {
      onChangeError((prev) => ({
        mount: isMount ? prev.mount : false,
        text: 'This field is required.',
      }));
    } else if (!phoneNumberPattern.test(phone)) {
      onChangeError((prev) => ({
        mount: isMount ? prev.mount : false,
        text: 'Incorrect format.',
      }));
    } else
      onChangeError((prev) => ({
        mount: isMount ? prev.mount : false,
        text: '',
      }));
  };

  const handleCloseModal = () => {
    onChangeError({ mount: true, text: 'This field is required.' });
    onChangePhoneReceive('');
    onClosePopup(false);
  };

  return (
    <ModalDialog
      isOpenModal={true}
      title='New Message'
      isShowButtonModal={false}
      handleCloseModal={handleCloseModal}
      classMore='modal-new-message'
    >
      <>
        <span className='label'>
          Phone Number <b className='require'>*</b>
        </span>
        <input
          type='text'
          className='form-control'
          value={phoneReceive}
          onChange={handleChangePhone}
          onBlur={handleBlur}
        />
        <p className='error-msg'>{errorPhone.mount ? '' : errorPhone.text}</p>

        <span className='label'>
          Message Body <b className='require'>*</b>
        </span>
        {inputComponent}
      </>
    </ModalDialog>
  );
};

export default NewMessage;
