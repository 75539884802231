import APIService from '../clientApi';
import { ICalendarRangeResponse } from './types';

const API = APIService();

const getRangeData = (
  params: [
    {
      appointments: { endDate?: Date; startDate?: Date };
      unconfirmed: { endDate?: Date; startDate?: Date };
    },
  ],
): Promise<ICalendarRangeResponse> => API.rpc('', { method: 'calendar.getRangedData', params });

const getAllCalendarRangeData = (
  params: [
    {
      appointments: { endDate?: Date; startDate?: Date };
      unconfirmed: { endDate?: Date; startDate?: Date };
    },
  ],
): Promise<ICalendarRangeResponse> =>
  API.rpc('', { method: 'calendar.getRangedDataNoChair', params });

const getAppointment = (params: [string]): Promise<any> =>
  API.rpc('appointment', { method: 'findById', params });

const getTouchpoints = (params: [number, number, { 'contact_events.appointment': string }]) =>
  API.rpc('touchpoint', { method: 'findPaged', params });

export default { getRangeData, getAllCalendarRangeData, getAppointment, getTouchpoints };
