import React, { useCallback, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import ContactAppointmentStatusSelect from './ContactAppointmentStatusSelect';
import { extractFirstLetter } from 'utils/strings';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { PetSummaryCollectionResponse } from 'global/requests/ResponseTypes/Pets';
import { AddressEntry } from 'global/requests/ResponseTypes/_Address';
import { PhoneNumber } from 'global/requests/ResponseTypes/_PhoneNumber';
import { GetCurrentUserAppointmentInRangeArguments } from 'global/requests/RequestTypes';
import { TaskData } from 'global/requests/ResponseTypes/_Task';
import {
  AppointmentInfoResponse,
  AppointmentSummaryEntry,
} from 'global/requests/ResponseTypes/Appointments';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { convertPhoneNumber } from 'utils/convertData';
import loading_spinner from 'media/images/loading-spinner.svg';
import { Tooltip, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ReminderInfoResponse,
  ReminderSummaryEntry,
} from 'global/requests/ResponseTypes/Reminders';
import { useLocation } from 'react-router-dom';
import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import toast from 'components/Global/Toast';
import ModalEditPhone from 'components/Global/Modal/ModalEditPhone';

interface IParameterUpdatePhone {
  phoneNumbers: PhoneNumber[];
}

interface InfoColumnProps {
  userInfo: UserDataResponse | undefined;
  allUserPetData?: PetSummaryCollectionResponse;
  refreshUserData?: () => void;
  useEditUserSettings?: {
    setDisplaySettingUserModal?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  useViewContactFullInfo?: {
    userAppointments: AppointmentInfoResponse | undefined;
    sendGetUserAppointmentsRequest: () => void;
    getUserAppointmentArguments: GetCurrentUserAppointmentInRangeArguments;
    userReminders: ReminderInfoResponse | undefined;
  };
  refreshPhoneNumbers?: (phoneNumbers: PhoneNumber[]) => void;
}

const renderLoading = () => {
  return (
    <img
      className='loading-appointment-info'
      src={loading_spinner}
      alt={'Loading'}
    />
  );
};

type UserInfoField = {
  headerText: React.ReactElement | string;
  bodyText?: any;
  children?: JSX.Element[];
  customClasses?: {
    container?: string;
    header?: string;
    body?: string;
  };
};

const typesPhone = [
  { label: 'Mobile Phone', value: 'cell' },
  { label: 'Home Phone', value: 'landline' },
  { label: 'Office Phone', value: 'business' },
  { label: 'Other Phone', value: 'other' },
];

const regexFormatPhone = /[^0-9]/gi;

const renderInfoField = (
  infoData: UserInfoField,
  index: number
): JSX.Element => {
  return (
    <div
      className={
        'view-contact--info-field ' + (infoData.customClasses?.container ?? '')
      }
      key={'contact-view-info-field-' + index}
    >
      <span
        className={
          'view-contact--info-field-header ' +
          (infoData.customClasses?.header ?? '')
        }
      >
        {infoData.headerText}
      </span>

      {/* render the children in the body if there is no bodyText */}
      {infoData.bodyText ? (
        <span
          style={{
            color:
              infoData.bodyText === 'Missing Email Address'
                ? '#F64F45'
                : 'unset',
          }}
          className={
            'view-contact--info-field-body ' +
            (infoData.customClasses?.body ?? '')
          }
        >
          {infoData.bodyText}

          {/* working as intended but not in the spec or approved at time of adding */}
          {/* {
              infoData.altText
                ? <div className="hover-tooltip">{infoData.altText}</div>
                : "no alt text"
            } */}
        </span>
      ) : (
        <span
          className={
            'view-contact--info-field-body ' +
            (infoData.customClasses?.body ?? '')
          }
        >
          {infoData.children ? infoData.children : 'None available'}
        </span>
      )}
      {/* render the children below the body if there is bodyText */}
      {infoData.bodyText && infoData.children ? infoData.children : []}
    </div>
  );
};

const getUserStatusText = (userStatus: string | number | undefined) => {
  switch (userStatus) {
    case 'Active': {
      return 'Active';
    }
    case 'inActive': {
      return 'Inactive';
    }
    default:
      return 'Inactive';
  }
};

const InfoColumn = (props: InfoColumnProps) => {
  const { userInfo, refreshPhoneNumbers } = props;
  const location = useLocation();

  const previousPhoneNumbers = useRef<PhoneNumber[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [openPopupWarning, setOpenPopupWarning] = useState<boolean>(false);
  const [openModalEditPhone, setOpenModalEditPhone] = useState<boolean>(false);

  React.useEffect(() => {
    setPhoneNumbers(userInfo?.phoneNumbers ?? []);
    previousPhoneNumbers.current = userInfo?.phoneNumbers ?? [];
  }, [userInfo]);

  const renderAppointments = (
    appointmentCollection: AppointmentSummaryEntry[],
    sendGetUserAppointmentsRequest: () => void,
    getUserAppointmentArguments: GetCurrentUserAppointmentInRangeArguments
  ): JSX.Element[] => {
    const orderedAppointmentCollectionWithLimit = appointmentCollection
      .filter((appointments) => appointments.status !== 'cancelled')
      .sort((firstApp, secondApp) =>
        Math.sign(moment(firstApp.dateTime).diff(secondApp.dateTime)) >= 0
          ? 1
          : -1
      );

    return orderedAppointmentCollectionWithLimit.map(
      (singleAppointment, index) => {
        return (
          <div
            key={'view-contact-info--appointment-' + index}
            className={'view-contact--appointment-container'}
          >
            <div className={'view-contact--appointment-time'}>
              {moment(singleAppointment.dateTime, 'YYYY-MM-DD hh:mm').format(
                'MMM Do, YYYY hh:mm A'
              )}{' '}
              <Tooltip
                placement='top'
                title={
                  <>
                    <p>
                      {singleAppointment.appointmentTypeName
                        ? `${singleAppointment.appointmentTypeName} for `
                        : ''}
                      {singleAppointment.patients.pets[0]?.name}
                      {singleAppointment.providerName
                        ? ` with provider ${singleAppointment.providerName}`
                        : ''}
                    </p>
                  </>
                }
                arrow
                style={{
                  borderRadius: '8px',
                  color: '#1A252E',
                  fontSize: '12px',
                }}
              >
                <span className='questions'>
                  <FontAwesomeIcon icon={['fas', 'question-circle']} />
                </span>
              </Tooltip>
            </div>
            <ContactAppointmentStatusSelect
              key={'view-contact-info--appointment-status-' + index}
              appointmentInformation={singleAppointment}
              currentStatus={singleAppointment.status}
              getCurrentUserAppointmentsInRange={sendGetUserAppointmentsRequest}
              getDailyAppointmentArguments={getUserAppointmentArguments}
            />
          </div>
        );
      }
    );
  };

  const renderReminders = (
    reminderCollection: ReminderSummaryEntry[]
  ): JSX.Element[] => {
    const orderedRemimderCollection = reminderCollection
      .filter((rem) => rem.reminderStatus != 'completed')
      .sort((firstRem, secondRem) =>
        Math.sign(moment(firstRem.recallDate).diff(secondRem.recallDate)) >= 0
          ? 1
          : -1
      );

    return orderedRemimderCollection.map((singleReminder, index) => {
      return (
        <div
          key={'view-contact-info--appointment-' + index}
          className={'view-contact--appointment-container'}
        >
          <span>
            {moment(singleReminder.recallDate, 'YYYY-MM-DD hh:mm').format(
              'MMM Do, YYYY'
            )}
          </span>
          <span>
            {singleReminder.reminderItem} for {singleReminder.pet.name}
          </span>
        </div>
      );
    });
  };

  const handleCompleteTask = async (
    event: React.SyntheticEvent<HTMLUListElement>
  ) => {
    try {
      if (!userInfo?.userId) {
        // shouldn't happen
        throw Error(
          "No userID available (how are you completing a task without the user's tasks?!)"
        );
      }
      const taskID = (event.target as HTMLButtonElement).getAttribute(
        'data-value'
      );
      if (!taskID) {
        throw Error('No TaskID available');
      }

      const res = await configuredRequests.PATCH.completeUserTask(
        userInfo.userId,
        [taskID]
      );

      if (res && props.refreshUserData) {
        props.refreshUserData();
      }
    } catch (err) {
      console.log('erroring completing user task', err);
    }
  };

  const renderTasks = (allUserTasks: TaskData[]) => {
    const filteredTaskCollection = allUserTasks.filter(
      (task) => task.status !== 'done'
    );
    return (
      <div
        className='view-contact--info-field'
        key='info-column--task-container'
      >
        <span className='view-contact--info-field-header'>Tasks</span>

        <span className='view-contact--info-field-body'>
          <ul onClick={handleCompleteTask} className='view-contact--task-ul'>
            {
              filteredTaskCollection.length
                ? filteredTaskCollection.map((singleTaskData, taskIndex) => {
                    return (
                      <li
                        className='view-contact--task-li'
                        key={'user-task-' + taskIndex + singleTaskData.name}
                      >
                        <div className='view-contact--task-entry-text'>
                          <div>{singleTaskData.name}</div>
                          <div>Details: {singleTaskData.details}</div>
                          {/* <div>Status: {singleTaskData.status}</div> */}
                        </div>
                        <div className='view-contact--task-entry-button-container'>
                          <button data-value={singleTaskData.taskId}>
                            Complete
                          </button>
                        </div>
                      </li>
                    );
                  })
                : null //<div style={{ color: "green" }}>No Pending Tasks</div>
            }
          </ul>
        </span>
      </div>
    );
  };

  const getPhysicalAddressList = (physicalAddressEntries: AddressEntry[]) => {
    const detailAddress = physicalAddressEntries
      .map((addressEntry) => {
        const { addressLine1, city, country, postalCode, state, addressLine2 } =
          addressEntry.address;
        const addressSegments = [
          addressLine1 ?? '',
          addressLine2 ?? '',
          city ?? '',
          state ?? '',
          postalCode ?? '',
          country ?? '',
        ];
        return addressSegments.filter((e) => e.length).join(', ');
      })
      .filter((e) => e);

    return detailAddress.length > 0
      ? detailAddress.map((addressString, index) => (
          <div
            key={'view-contact--physical-address--' + index}
            className='view-contact--physicaly-address-entry'
          >
            {addressString}
          </div>
        ))
      : [
          <div className='view-contact--physicaly-address-entry'>
            <span className='require'>Missing Address</span>
          </div>,
        ];
  };

  const compareTwoPhone = useCallback(
    (newPhone: string, oldPhone: string): boolean => {
      if (newPhone && oldPhone) {
        const newPhoneFormat = newPhone.replaceAll(regexFormatPhone, '');
        const oldPhoneFormat = oldPhone.replaceAll(regexFormatPhone, '');
        if (newPhoneFormat === oldPhoneFormat) {
          return true;
        }
        return false;
      }
      return false;
    },
    []
  );

  const handleSubmitEditPhone = async (values: IParameterUpdatePhone) => {
    try {
      // values.phoneNumbers.forEach((phone: PhoneNumber) => {
      //   if (phone.validatedOutput === "invalid") {
      //     phone.isText = false;
      //     phone.isCall = false;
      //   }
      // });
      if (!phoneNumbers.length) {
        values.phoneNumbers[0].isPrimary = true;
      }
      if (phoneNumbers.length && values.phoneNumbers.length) {
        values.phoneNumbers.forEach((newPhone: PhoneNumber, idx: number) => {
          const index = phoneNumbers?.findIndex((oldPhone: PhoneNumber) =>
            compareTwoPhone(newPhone.number, oldPhone.number)
          );
          if (index === -1) {
            values.phoneNumbers[idx].isValidated = false;
          }
        });
      }
      setPhoneNumbers(values.phoneNumbers);
      setOpenModalEditPhone && setOpenModalEditPhone(false);
      const res = await configuredRequests.PATCH.updatePhoneContact(
        userInfo?.userId ?? '',
        values
      );

      if (res) {
        // @ts-ignore
        setPhoneNumbers(res.phoneNumbers);
        // @ts-ignore
        refreshPhoneNumbers && refreshPhoneNumbers(res.phoneNumbers);
        // @ts-ignore
        previousPhoneNumbers.current = res.phoneNumbers;
        toast.success('Your data has been saved successfully!');
      }
    } catch (error) {
      setPhoneNumbers(previousPhoneNumbers.current);
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleConfirmPopupWarning = () => {
    setOpenPopupWarning(false);
    setOpenModalEditPhone && setOpenModalEditPhone(false);
  };

  // const handleSubscribe = async (phoneNumber: string) => {
  //   try {
  //     const res = await configuredRequests.PATCH.updateContactSubscribe(
  //       userInfo?.userId ?? '',
  //       phoneNumber
  //     );
  //     if (res) {
  //       // setPhoneNumbers(res);
  //       toast.success('Your data has been saved successfully!');
  //     }
  //   } catch (err) {
  //     toast.error('Your data has not been saved yet. Please try again.');
  //   }
  // };

  const getPhoneNumberList = (phoneNumberEntries: PhoneNumber[]): string => {
    return phoneNumberEntries
      .map((phoneEntry) => convertPhoneNumber(phoneEntry.number))
      .join(', ');
  };

  const renderPhones = (): React.ReactElement => (
    <>
      {phoneNumbers.map((phone: PhoneNumber, idx: number) => (
        <div className='info__phone-detail' key={idx}>
          <span
            className={`${
              phone.isValidated ? 'phone--validate' : 'phone--not-validated'
            } ${phone.isValidated ? phone.validatedOutput : ''}`}
          >
            {phone.isValidated ? (
              <>
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    phone.validatedOutput === 'invalid'
                      ? 'exclamation-triangle'
                      : phone.validatedOutput === 'unknown'
                      ? 'question'
                      : 'check',
                  ]}
                />
                {/* {phone.validatedOutput === 'invalid' ? (
                ) : (
                  <FontAwesomeIcon icon={['fas', 'check']} />
                )} */}
                {phone.validatedOutput?.toUpperCase()}
              </>
            ) : (
              <>
                NOT VALIDATED{' '}
                <Tooltip
                  placement='top'
                  title={
                    <>
                      Additional Feature Required. Please contact your VetHero
                      rep to upgrade.
                    </>
                  }
                  arrow
                  style={{
                    borderRadius: '8px',
                    color: '#1A252E',
                    fontSize: '12px',
                  }}
                >
                  <span
                    className='questions'
                    style={{
                      position: 'relative',
                      right: '0px',
                      color: 'white',
                    }}
                  >
                    <FontAwesomeIcon icon={['fas', 'question-circle']} />
                  </span>
                </Tooltip>
              </>
            )}
          </span>
          <span className='phone--number'>
            {convertPhoneNumber(phone.number)}
          </span>
          <span className='phone--type'>
            {typesPhone.find((type) => type.value === phone.kind)?.label}
          </span>
          <span className='phone--used'>
            {phone.isText && <FontAwesomeIcon icon={['fas', 'comment']} />}
            {phone.isCall && <FontAwesomeIcon icon={['fas', 'phone']} />}
          </span>
        </div>
      ))}
    </>
  );

  // long function that contains most of the markup
  // changes to the column will likely go in here
  const renderUserDataView = (userInfo: UserDataResponse): JSX.Element => {
    // multiple sections need to be rendered for different kinds of phone
    // const mobilePhoneNumbers: PhoneNumber[] = [];
    // const homePhoneNumbers: PhoneNumber[] = [];
    // const officeNumbers: PhoneNumber[] = [];
    // const otherNumbers: PhoneNumber[] = [];

    const haveEmailAddress = () => {
      if (userInfo.emailAddress) {
        if (userInfo.emailAddress === 'NoEmail@thevethero.com') {
          return 'Missing Email Address';
        }
        return userInfo.emailAddress;
      } else {
        return 'Missing Email Address';
      }
    };

    // userInfo.phoneNumbers?.forEach((phoneNumberEntry) => {
    //   switch (phoneNumberEntry.kind) {
    //     case "cell": {
    //       mobilePhoneNumbers.push(phoneNumberEntry);
    //       break;
    //     }
    //     case "landline": {
    //       homePhoneNumbers.push(phoneNumberEntry);
    //       break;
    //     }
    //     case "other": {
    //       otherNumbers.push(phoneNumberEntry);
    //       break;
    //     }
    //     case "business": {
    //       officeNumbers.push(phoneNumberEntry);
    //       break;
    //     }
    //   }
    // });

    // describes what gets rendered, in order, and what data is used
    const infoFieldsToRender: UserInfoField[] = [
      {
        headerText: 'Email Address',
        bodyText: haveEmailAddress(),
        customClasses: {
          body: 'email',
        },
      },
    ];

    // if(!mobilePhoneNumbers.length && !homePhoneNumbers.length && !officeNumbers.length && !otherNumbers.length)
    infoFieldsToRender.push({
      headerText: (
        <>
          Phone Number{' '}
          {location.pathname.indexOf('Contacts') !== -1 && (
            <FontAwesomeIcon
              icon={['far', 'edit']}
              className='icon--edit-phone'
              onClick={() =>
                setOpenModalEditPhone && setOpenModalEditPhone(true)
              }
            />
          )}
        </>
      ),
      bodyText: phoneNumbers.length ? (
        renderPhones()
      ) : (
        <span className='require'>Missing Phone Number</span>
      ),
    });

    // if(mobilePhoneNumbers.length)
    //   infoFieldsToRender.push({
    //     headerText: mobilePhoneNumbers.length == 1 ? "Mobile Phone" : "Mobile Phones",
    //     bodyText: getPhoneNumberList(mobilePhoneNumbers)
    //   });

    // if(homePhoneNumbers.length)
    //   infoFieldsToRender.push({
    //     headerText: homePhoneNumbers.length == 1 ? "Home Phone" : "Home Phones",
    //     bodyText: getPhoneNumberList(homePhoneNumbers)
    //   });

    // if(officeNumbers.length)
    //   infoFieldsToRender.push({
    //     headerText: officeNumbers.length == 1 ? "Office Phone" : "Office Phones",
    //     bodyText: getPhoneNumberList(officeNumbers)
    //   });

    // if(otherNumbers.length)
    //   infoFieldsToRender.push({
    //     headerText: otherNumbers.length == 1 ? "Other Number" : "Other Numbers",
    //     bodyText: getPhoneNumberList(otherNumbers)
    //   });

    infoFieldsToRender.push({
      headerText: 'Address',
      children: userInfo.addresses
        ? getPhysicalAddressList(userInfo.addresses)
        : undefined,
    });
    infoFieldsToRender.push({
      headerText: 'Birthdate',
      bodyText: userInfo.details?.birthDate ? (
        moment(userInfo.details.birthDate).format('MM/DD/YYYY')
      ) : (
        <span className='require'>Missing Birthdate</span>
      ),
      // working as intended but not in the spec or approved at time of adding
      // altText: moment(userInfo.details?.birthDate).format("MMMM Do YYYY") ?? undefined
    });
    infoFieldsToRender.push({
      headerText: 'Contact Since',
      bodyText: userInfo?.createdAt ? (
        moment(userInfo.createdAt).format('MM/DD/YYYY')
      ) : (
        <span className='require'>Missing Contact Since</span>
      ),
    });
    infoFieldsToRender.push({
      headerText: 'Contact Status',
      bodyText: getUserStatusText(userInfo.pulseUserStatus),
    });

    const userInitials = userInfo.name
      ? Object.values(userInfo.name).map((str) => extractFirstLetter(str))
      : '';

    const handleOpenRightModal = () => {
      if (props.useEditUserSettings?.setDisplaySettingUserModal) {
        props.useEditUserSettings?.setDisplaySettingUserModal(true);
      }
    };

    return (
      <>
        <div className='view-contact--account-initials-container'>
          <div className='view-contact--account-initials-circle'>
            <div className='view-contact--account-initials-initial-text'>
              {userInitials}
            </div>
          </div>
          <div className='view-contact--account-initials-full-name'>
            {userInfo.name ? Object.values(userInfo.name).join(' ') : ''}
          </div>
        </div>

        {
          // the EditUserSettings page doesn't display user tasks and appointments
          props.useEditUserSettings ? (
            <p className='text-center' onClick={handleOpenRightModal}>
              Edit User
            </p>
          ) : (
            [
              <div
                className='view-contact--info-field upcoming-appointments'
                key='info-column-pets'
              >
                <span className='view-contact--info-field-header'>Pet(s)</span>

                <span className='view-contact--info-field-body'>
                  <ul className='view-contact--pet-ul'>
                    {/* double conditional here because we need 3 behaviors */}
                    {
                      // no data means we're loading
                      props.allUserPetData ? (
                        // data with no length means no registered pets
                        props?.allUserPetData?.pets?.filter(
                          (pet: any) => pet.status === 'active'
                        )?.length ? (
                          // otherwise list all pets
                          props?.allUserPetData?.pets
                            .filter((pet: any) => pet.status === 'active')
                            .map((petData) => {
                              return (
                                <li
                                  key={petData.petId}
                                  className='view-contact--pet-li'
                                >
                                  <div className='view-contact--pet-name'>
                                    {petData.name ? (
                                      petData.name
                                    ) : (
                                      <span className='require'>
                                        Missing Pet's Name
                                      </span>
                                    )}
                                  </div>
                                  <div className='view-contact--pet-species'>
                                    Breed:{' '}
                                    {petData.breed ? petData.breed : 'N/A'}
                                  </div>
                                  <div className='view-contact--pet-species'>
                                    Sex: {petData.sex ? petData.sex : 'N/A'}
                                  </div>
                                  <div className='view-contact--pet-species'>
                                    Birthdate:{' '}
                                    {petData.birthDate
                                      ? moment(petData.birthDate).format(
                                          'MM/DD/YYYY'
                                        )
                                      : 'N/A'}
                                  </div>
                                </li>
                              );
                            })
                        ) : (
                          <div className='view-contact--no-pets'>
                            No Registered Pets
                          </div>
                        )
                      ) : (
                        <img
                          className='loading-pet-info'
                          src={loading_spinner}
                          alt={'Loading'}
                        />
                      )
                    }
                  </ul>
                </span>
              </div>,
              <div
                className='view-contact--info-field upcoming-appointments'
                key='info-column-upcoming-appointments'
              >
                <span className='view-contact--info-field-header'>
                  Upcoming Appointments
                </span>

                <span className='view-contact--info-field-body'>
                  {props.useViewContactFullInfo &&
                  props.useViewContactFullInfo.userAppointments?.count ? (
                    renderAppointments(
                      props.useViewContactFullInfo.userAppointments
                        .appointments,
                      props.useViewContactFullInfo
                        .sendGetUserAppointmentsRequest,
                      props.useViewContactFullInfo.getUserAppointmentArguments
                    )
                  ) : (
                    // <div className={'view-contact--appointment-container'}>
                    <>No Upcoming Appointments</>
                    // </div>
                  )}
                </span>
              </div>,
              <div
                className='view-contact--info-field upcoming-appointments'
                key='info-column-upcoming-medical-reminders'
              >
                <span className='view-contact--info-field-header'>
                  Upcoming Medical Reminders
                </span>

                <span className='view-contact--info-field-body'>
                  {props.useViewContactFullInfo &&
                  props.useViewContactFullInfo.userReminders &&
                  props.useViewContactFullInfo.userReminders?.reminders?.filter(
                    (rem) => rem.reminderStatus != 'completed'
                  )?.length > 0 ? (
                    renderReminders(
                      props.useViewContactFullInfo.userReminders.reminders
                    )
                  ) : (
                    <>No Upcoming Medical Reminders</>
                  )}
                </span>
              </div>,
              userInfo.tasks &&
              userInfo.tasks.filter((task) => task.status !== 'done').length
                ? renderTasks(userInfo.tasks)
                : null,
            ]
          )
        }

        {infoFieldsToRender.map(renderInfoField)}
      </>
    );
  }; // end renderUserDataView function

  return (
    <div className={'view-contact--info-container'}>
      {userInfo ? renderUserDataView(userInfo) : renderLoading()}
      {openModalEditPhone && setOpenModalEditPhone && (
        <ModalEditPhone
          isOpenModal={openModalEditPhone}
          setIsOpenModal={setOpenModalEditPhone}
          phoneNumbers={phoneNumbers}
          handleUpdatePhone={handleSubmitEditPhone}
          // handleSubscribe={handleSubscribe}
        />
      )}
      {openPopupWarning && (
        <ModalDiscard
          isOpenModal={openPopupWarning}
          handleCloseModal={setOpenPopupWarning}
          handleConfirmModal={handleConfirmPopupWarning}
        />
      )}
    </div>
  );
};

export default InfoColumn;
