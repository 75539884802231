import { SeriesPieOptions } from 'highcharts';

type seriesData = { name: string; y: number; color: string }[];

const generateSeriesData = (data: number[], names: string[], colors: string[]): seriesData => {
  return data.map((datum, i): { name: string; y: number; color: string } => ({
    name: names[i],
    y: datum,
    color: colors[i],
  }));
};

const generateSeries = (
  data: seriesData,
  outerRadius: number,
  innerRadius: number,
): SeriesPieOptions[] => [
  {
    type: 'pie',
    size: `${outerRadius}%`,
    innerSize: `${innerRadius}%`,
    showInLegend: true,
    dataLabels: {
      enabled: false,
    },
    data,
  },
];

// eslint-disable-next-line import/prefer-default-export
export { generateSeries, generateSeriesData };
