import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { UserListOption } from './Notifications';
import CheckBoxNotification from './CheckBoxNotification';

export interface SettingOption {
  label: string;
  value: boolean;
}

type Props = {
  userSelected: UserListOption[];
  userListData: UserListOption[];
  settings: Record<string, SettingOption>;
  onSelectUser: (user: UserListOption[]) => void;
  onSettingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UserNotification = (props: Props) => {
  const {
    userSelected,
    userListData,
    settings,
    onSelectUser,
    onSettingChange,
  } = props;

  return (
    <>
      <h2 className='practice-title'>User Notification</h2>
      <div>
        <p className='notification-title'>Notification Users</p>
        <div className='autocomplete'>
          <Autocomplete
            value={userSelected}
            options={userListData}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onFocus={() => {
              // setDisableButton(false);
            }}
            onChange={(event, value) => onSelectUser(value)}
            noOptionsText='No user matched'
            multiple
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  margin='normal'
                  variant='outlined'
                  fullWidth
                />
              );
            }}
          />
        </div>

        <CheckBoxNotification
          settings={settings}
          onSettingChange={onSettingChange}
        />
      </div>
    </>
  );
};

export default UserNotification;
