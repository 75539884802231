import React, { useEffect } from 'react';
import { TitleProps } from 'types/NavigationT';

const Title = (props: TitleProps): JSX.Element => {
  useEffect(() => {
    if (props.documentTitle) {
      document.title = `VetHero CRM | ${props.documentTitle}`;
      return;
    }
    // sets the title of the tab in the browser when component has mounted && props.title has changed
    document.title =
      props.titleText === 'Back to All Users' ||
      props.titleText === 'Back to All Templates' ||
      props.titleText === 'Back to All Triggers' ||
      props.titleText === 'Back to All Practices' ||
      props.titleText === 'Back to Previous Page' ||
      props.titleText === 'Back to All Contacts' ||
      props.titleText === 'Back to Dashboard' ||
      props.titleText === 'Back to All Appointments' ||
      props.titleText === 'Back to All Groups'
        ? `${'VetHero CRM'} | ${props.titleText.slice(11)}`
        : `${'VetHero CRM'} | ${props.titleText}`;
  }, [props.titleText]);

  return (
    <>
      <div className={'title__above-nav'}>
        <h1 className={`${props.titleClass ?? 'title'}`}>{props.titleText}</h1>
      </div>
      <br />
    </>
  );
};

export default Title;
