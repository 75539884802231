import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttributeStatus from 'components/Communications/AttributeStatus/AttributeStatus';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';
import Table from 'components/Global/Table';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  AttributeStatusResponse, PerformanceAnalyzeResponse
} from 'global/requests/ResponseTypes';
import { useRouter } from 'hooks/useRouter';
import { debounce, isEmpty, pickBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { convertTimeToSecond } from 'utils/convertData';
import convertDateToTimestamp from 'utils/convertDateToTimestamp';
import { isWindows } from 'react-device-detect';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import loading_spinner from 'media/images/loading-spinner.svg';
import { orderBy } from 'lodash';

export const WrapperTable = styled.div`
  .MuiTableContainer-root {
    overflow: auto;
    max-height: calc(100vh - 450px);

    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root:nth-child(2),
        .MuiTableCell-root:nth-child(3),
        .MuiTableCell-root:nth-child(4),
        .MuiTableCell-root:nth-child(5) {
          width: 12%;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-body {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }
`;

export const RedirectLink = styled.p`
  margin: 0px;
  color: #1974ff;
  font-size: .8125rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
`;

const columns = [
  { key: 'templateName', title: 'Template' },
  { key: 'sent', title: 'Sent' },
  { key: 'deliverRate', title: 'Delivery Rate' },
  { key: 'openRate', title: 'Open rate' },
  { key: 'clickRate', title: 'Click Rate' },
];

const Performance = (): JSX.Element => {
  const router = useRouter();
  const [dataResponse, setDataResponse] = React.useState<
    PerformanceAnalyzeResponse
  >();
  const [dataAttribute, setDataAttribute] = React.useState<
    Array<AttributeStatusResponse>
  >([]);

  const endDateTime = moment(new Date()).endOf('day').toDate();
  const past30days = moment(endDateTime).subtract(29, 'days').toDate();
  const [startDate, setStartDate] = React.useState<Date | null>(past30days);
  const [endDate, setEndDate] = React.useState<Date | null>(endDateTime);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [queryPagination, setQueryPagination] = useState({ limit: 10, offset: 0 });
  const [textSearch, setTextSearch] = useState('');
  const refOriginData = React.useRef<PerformanceAnalyzeResponse>();

  const getPerformanceAnalyze = useCallback(async (
    kindOfTemplate: string,
    query: Record<string, unknown>,
  ) => {
    try {
      const res = await configuredRequests.GET.getPerformanceAnalyze(
        kindOfTemplate,
        {
          ...query,
          limit: query?.limit ?? 10,
          offset: query?.offset ?? 0,
        }
      );

      setDataResponse(res);
      refOriginData.current = res;
      setDataAttribute([
        { name: 'Sent Emails', value: res.sent },
        {
          name: 'Delivered Emails',
          value: res.delivered,
          ratio: tranformDataRatio(res.sent, res.delivered),
        },
        {
          name: 'Opened Emails',
          value: res.opened,
          ratio: tranformDataRatio(res.delivered, res.opened),
        },
        {
          name: 'Clicked Emails',
          value: res.clicked,
          ratio: tranformDataRatio(res.sent, res.clicked),
        },
      ]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('error', err);
    }
  }, []);

  const tranformDataRatio = (
    mainPercent: number,
    dataNeedToTransorm: number
  ) => {
    return mainPercent === 0 ? "0" : ((dataNeedToTransorm / mainPercent) * 100).toFixed(2);
  };

  useEffect(() => {
    const startTime = startDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    const endTime = endDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    getPerformanceAnalyze('performanceAnalyzerInfo', { startTime, endTime, limit: 10, offset: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'unset';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  const handleChangePage = useCallback(async (event: unknown, newPage: number) => {
    setQueryPagination(preState => ({ ...preState, offset: newPage }));
    await getPerformanceAnalyze('performanceAnalyzerInfo', {
      ...queryPagination,
      offset: newPage,
      startTime: convertDateToTimestamp(startDate),
      endTime: convertDateToTimestamp(endDate),
      ...pickBy({ templateName: textSearch.trim() })
    });
  }, [getPerformanceAnalyze, queryPagination, startDate, endDate, textSearch]);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage: number = parseInt(event.target.value);
    if (newRowsPerPage) {
      setQueryPagination({ limit: newRowsPerPage, offset: 0 });
      getPerformanceAnalyze('performanceAnalyzerInfo', {
        limit: newRowsPerPage,
        offset: 0,
        startTime: convertDateToTimestamp(startDate),
        endTime: convertDateToTimestamp(endDate),
        ...pickBy({ templateName: textSearch.trim() })
      });
    }
  }, [getPerformanceAnalyze, startDate, endDate, textSearch]);

  const searchMessageWithName = useCallback(debounce((name: any) => {
    getPerformanceAnalyze('performanceAnalyzerInfo', {
      limit: 10,
      offset: 0,
      startTime: convertDateToTimestamp(startDate),
      endTime: convertDateToTimestamp(endDate),
      templateName: name ? name.trim() : undefined,
    });
  }, 1000), [getPerformanceAnalyze,]);

  const onChangeTextSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setTextSearch(value);
    setQueryPagination({ limit: 10, offset: 0 });
    searchMessageWithName(value);
  }, [searchMessageWithName]);

  const handleSort = (sortField: string, isAsc: boolean) => {
    if (sortField === '') {
      setDataResponse(refOriginData.current);
      return;
    }
    let resultSort = orderBy(
      //@ts-ignore
      [...dataResponse?.emailsSent.items],
      sortField === 'templateName' ? [item => item[sortField].toString().toLowerCase()] : [sortField, item => item['templateName'].toString().toLowerCase()],
      [isAsc ? 'asc' : 'desc', 'asc']
    );
    // @ts-ignore
    setDataResponse((prevData: PerformanceAnalyzeResponse) => ({...prevData, emailsSent: {...prevData.emailsSent, items: resultSort}}));
  }

  return (
    <div className='communication-container Performance w-300'>
      {loading ? (
        <img className="loading-appointment-info" src={loading_spinner} alt={"Loading"} />
      ) : (
          <>
            <div className='communication-select-filter'>
              <DateTimePicker
                showDateRange={true}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                getEndDate={true}
              />
            </div>

            <div className='communication-analyze-attribute'>
              {dataAttribute.map((item) => {
                return (
                  <AttributeStatus
                    key={'attribute-item-' + item.name}
                    attribute={item}
                    haveRatio={true}
                  />
                );
              })}
            </div>

            <div className='communication-container__table'>
              <div className='email-sent__table-title'> Emails Sent </div>
              <div className="contacts--filter-search-container">

                <FontAwesomeIcon
                  icon={['far', 'search']}
                  onClick={() => searchMessageWithName(textSearch)}
                />

                <input
                  className="contacts--filter--search-input"
                  value={textSearch}
                  type="text"
                  placeholder="Search by Template"
                  onChange={onChangeTextSearch}
                />

              </div>
              {
                !isEmpty(dataResponse?.emailsSent?.items) ? <WrapperTable><Table
                  isSort={true}
                  handleSort={handleSort}
                  columns={columns}
                  // @ts-ignore
                  rows={dataResponse?.emailsSent?.items.map((item: any) => {
                    return {
                      ...item,
                      templateName: <RedirectLink onClick={() => router.push(`/Dashboard/Template/${item.templateId}`)}>
                        <HighlightText
                          text={item.templateName}
                          searchValue={textSearch.trim()}
                        />
                      </RedirectLink>
                    }
                  }) ?? []}
                  isHasPagination={true}
                  // @ts-ignore
                  totalAvailable={dataResponse?.emailsSent?.totalAvailable ?? 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={queryPagination?.limit ?? 10}
                  page={queryPagination?.offset ?? 0}
                /></WrapperTable> : <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                    No results found</p>
              }
            </div>
          </>
        )}

    </div>
  );
};

export default Performance;
