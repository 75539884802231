import React, { useEffect } from 'react';
import WithComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import EngagementOverTimeChart from 'components/Global/EngagementOverTimeChart/EngagementOverTimeChart';
import OpensByDeviceType from 'components/Global/OpensByDeviceType/OpensByDeviceType';
import OpensByPlatform from 'components/Global/OpensByPlatform/OpensByPlatform';
import { useParams } from 'react-router-dom';
import { columClickedLink } from 'utils/mockupData';
import AttributeStatus from '../AttributeStatus/AttributeStatus';
import CommunicationTable from 'components/Global/CommunicationTable/CommunicationTable';
import { Column, TemplatePerformanceDetailResponse, AttributeStatusResponse, DataTypeOpenByPlatformAndDevice } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import DateTimePicker from 'components/Global/DateTimePicker/DateTimePicker';
import moment from 'moment';
import { isWindows } from 'react-device-detect';
import loading_spinner from 'media/images/loading-spinner.svg';

interface DataTopClickedLink {
  link: string,
  value: number
}

const ComponentizedEngagementOverTimeChart = WithComponentClassAndHeaderText(
  EngagementOverTimeChart,
  'Engagement Over Time',
  'flex1140'
);

const ComponentizedOpensByDeviceType = WithComponentClassAndHeaderText(
  OpensByDeviceType,
  'Opens By Device Type',
  'flex1140'
);

const ComponentizedOpensByPlatform = WithComponentClassAndHeaderText(
  OpensByPlatform,
  'Opens By Platform',
  'flex1140'
);

const initialPerformanceDetailData = {
  sent: 0,
  delivered: 0,
  opened: 0,
  clicked: 0,
  unsuccessfulDeliveries: 0,
  engagementOverTime: [
    {
      date: 'Oct 23',
      opened: 0,
      clicked: 0,
    },
  ],
  topClickedLinks: [],
};

const initialOpenByPlatformAndDeviceData = {
  openByPlatform: [
    {
      name: 'iPhone',
      value: 0,
    },
    {
      name: 'iPad',
      value: 0,
    },
    {
      name: 'Firefox',
      value: 0,
    },
    {
      name: 'Outlook',
      value: 0,
    },
    {
      name: 'Chrome',
      value: 0,
    },
    {
      name: 'Android',
      value: 0,
    },
    {
      name: 'Apple Mail',
      value: 0,
    },
    {
      name: 'Other',
      value: 0,
    }
  ],
  openByDeviceType: [
    {
      name: 'Mobile',
      value: 0,
    },
    {
      name: 'Desktop',
      value: 0,
    },
  ],
};

const TemplatePerformanceView = (): JSX.Element => {
  const { templateId } = useParams<{ templateId: string }>();
  const [templateName, setTemplateName] = React.useState<string>("");
  const columns: Column[] = columClickedLink;
  const [loadingTemplateName, setLoadingTemplateName] = React.useState(true);
  const [loadingTopClickedLink, setLoadingTopClickedLink] = React.useState(true);
  const [loadingOpenByPlatformAndDevice, setLoadingOpenByPlatformAndDevice] = React.useState(true);
  const endDateTime = moment(new Date()).endOf('day').toDate();
  const past30days = moment(endDateTime).subtract(29, 'days').toDate();
  const [startDate, setStartDate] = React.useState<Date | null>(past30days);
  const [endDate, setEndDate] = React.useState<Date | null>(endDateTime);
  const [
    templatePerformanceDetail,
    setTemplatePerformanceDetail,
  ] = React.useState<TemplatePerformanceDetailResponse>(initialPerformanceDetailData);
  const [
    dataOpenByPlatformAndDevice,
    setDataTemplatePerformanceDetail,
  ] = React.useState<DataTypeOpenByPlatformAndDevice>(initialOpenByPlatformAndDeviceData);
  const [dataAttribute, setDataAttribute] = React.useState<Array<AttributeStatusResponse>>([]);
  const [
    dataTopClickedLink,
    setDataTopClickedLink,
  ] = React.useState<DataTopClickedLink[]>();

  const tranformDataRatio = (mainPercent: number, dataNeedToTransorm: number) => {
    return mainPercent === 0 ? "0" : (dataNeedToTransorm / mainPercent * 100).toFixed(2);
  }

  useEffect(() => {
    // @ts-ignore
    const rootDiv = document.getElementById('root');
    if (rootDiv && isWindows) rootDiv.style.overflow = 'auto';
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'auto';
  }, []);

  const getTemplatePerformanceDetail = async (
    templateId: string,
    startTime: string,
    endTime: string
  ) => {
    try {
      if (templateId) {
        const templateName = await configuredRequests.GET.templateByID(templateId);
        setTemplateName(templateName.name || '');
        setLoadingTemplateName(false);

        const response = await configuredRequests.GET.templatePerformanceDetail(
          templateId,
          startTime,
          endTime
        );
        setTemplatePerformanceDetail(response);
        const dataTopClicked = response.topClickedLinks.slice(0, 5).map(item => {
          return ({
            link: item.name,
            value: item.value
          });
        });
        
        setDataTopClickedLink(dataTopClicked as DataTopClickedLink[]);
        setDataAttribute([
          { name: 'Sent', value: response.sent },
          { name: 'Delivered', value: response.delivered, ratio: tranformDataRatio(response.sent, response.delivered) },
          { name: 'Opened', value: response.opened, ratio: tranformDataRatio(response.delivered, response.opened) },
          { name: 'Clicked', value: response.clicked, ratio: tranformDataRatio(response.sent, response.clicked) },
          { name: 'Unsuccessful Deliveries', value: response.unsuccessfulDeliveries, ratio: tranformDataRatio(response.sent, response.unsuccessfulDeliveries) },
        ]);
        setLoadingTopClickedLink(false);

        const dataOpenByPlatformAndDevice = await configuredRequests.GET.getDataOpenByPlatformAndDevice(
          templateId,
          startTime,
          endTime
        );
        setDataTemplatePerformanceDetail(dataOpenByPlatformAndDevice || []);
        setLoadingOpenByPlatformAndDevice(false);
      }
    } catch (err) {
      setLoadingTopClickedLink(false);
      setLoadingTemplateName(false);
      setLoadingOpenByPlatformAndDevice(false);
    }
  };

  const convertTimeToSecond = (timestamp: number) => {
    return (timestamp / 1000).toString();
  }

  React.useEffect(() => {
    const startTime = startDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    const endTime = endDate
      // @ts-ignore
      ? convertTimeToSecond(Date.parse(new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()))))
      : convertTimeToSecond(new Date().getTime());
    getTemplatePerformanceDetail(templateId, startTime, endTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <>
      { loadingTemplateName ? <img className="loading-title-template" src={loading_spinner} alt={"Loading"} /> :
        <p className="title-template"> Template Analytics for "{templateName}" </p>
      }
      <div className='template-performance'>
        <div className='communication-container '>
          <div className='communication-select-filter'>
            <DateTimePicker
              showDateRange={true}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              getEndDate
            />
          </div>

          {loadingTopClickedLink ? <img className="loading-performance-template" src={loading_spinner} alt={"Loading"} /> :
            (<div className='communication-analyze-attribute'>
              {
                dataAttribute.map((item) => {
                  return <AttributeStatus key={"attribute-item-" + item.name} attribute={item} haveRatio={true} />
                })
              }
            </div>)
          }

          <div>
            <ComponentizedEngagementOverTimeChart
              data={templatePerformanceDetail.engagementOverTime}
              loadingEngagementOvertime={loadingTopClickedLink}
            />
          </div>

          <div>
            <ComponentizedOpensByPlatform
              data={dataOpenByPlatformAndDevice.openByPlatform}
              loadingOpenByPlatform={loadingOpenByPlatformAndDevice}
            />
          </div>

          <div className='flex'>
              <ComponentizedOpensByDeviceType
              data={dataOpenByPlatformAndDevice.openByDeviceType}
              loadingOpenByDeviceType={loadingOpenByPlatformAndDevice}
              />
            
            <div className='top-clicked-link'>
              <div className='component__title'>Top Clicked Links</div>
              {loadingTopClickedLink ? <img className="loading-top-clicked-link" src={loading_spinner} alt={"Loading"} /> :
                <CommunicationTable
                  isDisablePagination={true}
                  columns={columns}
                  dataTable={dataTopClickedLink || []}
                  isSort={true}
                  keySortTerm={'desc'}
                  keySort={'value'}
                  screen={'TOP_CLICKED_LINK'}
                  router={"CLICKED_LINK"}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatePerformanceView;
