/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import Filter from 'components/Global/Filter/Filter';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import PracticesModal from 'components/Global/SettingsComponent/PracticesModal';
import StatusSelect from 'components/Global/StatusSelect/StatusSelect';
import TableComponent from 'components/Global/Table';
import toast from 'components/Global/Toast';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  GroupInfoType,
  OptionFilterDropdownObject,
  TenantResposne,
} from 'global/requests/ResponseTypes';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { convertPhoneNumber } from 'utils/convertData';

const Loading = styled.p`
  margin: 0;
  padding: 36px 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .no-data {
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }

  .title-practices {
    padding: 0;
    margin-bottom: 2rem;
  }

  .d-flex {
    margin: 15px 0;

    .search-form {
      input {
        text-indent: 10px;
      }
    }
  }

  .btn-new,
  .btn-login-practice {
    margin-left: auto;
    background-color: #0074ff;
    text-transform: capitalize;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background-color: #0074ff;
    }

    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .btn-login-practice {
    &:disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
      box-shadow: none;
      cursor: default;
      pointer-events: none;
    }
  }

  .MuiTable-root {
    & + td {
      display: block;
      margin-bottom: 1.2rem;
    }

    .MuiTableHead-root {
      .MuiTableRow-head {
        .MuiTableCell-root:not(:first-child) {
          min-width: auto;
        }
      }
    }
  }

  .MuiTableRow-head {
    .MuiTableCell-head {
      min-width: 200px !important;

      &:first-child {
        min-width: 250px !important;
      }
    }
  }

  .MuiTableRow-root {
    .status_select-dropdown-container {
      width: 100px;
      svg {
        font-size: 1.25rem;
      }
    }

    .appointment__entry-button {
      &.active {
        background-color: #1dc9b7;
      }

      &.inactive {
        background-color: #22334d;
      }

      &.disabled {
        cursor: default;
      }

      .status_select-text {
        width: 90px;
      }
    }

    a {
      color: #1974ff;

      &:active,
      &:visited,
      &:link,
      &:hover {
        color: #1974ff;
      }
    }
  }
`;

type PracticeTableDataType = {
  groupId?: string;
  id?: string;
  practiceName?: string;
  emailAddress?: string;
  phoneNumbers?: string;
  status?: string;
  action?: string;
};

const columns = [
  { key: 'practiceName', title: 'Practice Name' },
  { key: 'emailAddress', title: 'Email' },
  { key: 'phoneNumber', title: 'Phone Number' },
  { key: 'status', title: 'Status' },
  { key: 'action', title: '' },
];

const optionsForFilter = [
  { label: 'Active Practices', value: 'Active' },
  { label: 'Inactive Practices', value: 'Inactive' },
  { label: 'All Practices', value: '' },
];

const PracticesSetting = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [listAllPractice, setListAllPractice] = useState<TenantResposne[]>([]);
  const [displayPracticeModal, setDisplayPracticeModal] = React.useState(false);
  const [practiceTableData, setPracticeTableData] = useState<
    PracticeTableDataType[]
  >([]);
  const [userInformationData, setUserInformationData] =
    React.useState<UserDataResponse>();
  const [groupInfo, setGroupInfo] = useState<GroupInfoType>();
  const params = useParams<{ groupId: string }>();
  const [queryPagination, setQueryPagination] = React.useState({
    limit: 10,
    offset: 0,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('Active');
  const [totalVisible, setTotalVisible] = useState<number>(0);

  useEffect(() => {
    const practicesVisible = listAllPractice.filter((practice) => {
      if (filterValue === '') {
        return practice.tenantName
          ?.toLowerCase()
          ?.includes(searchValue.toLowerCase());
      }
      return (
        practice.status === filterValue &&
        practice.tenantName?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
    });
    const practices = practicesVisible.slice(0, queryPagination.limit);
    setTotalVisible(practicesVisible.length);
    setQueryPagination((prevState) => ({ ...prevState, offset: 0 }));
    setPracticeTableData(transferToDataTable(practices));
  }, [searchValue, filterValue]);

  useEffect(() => {
    const practicesVisible = listAllPractice.filter((practice) => {
      if (filterValue === '') {
        return practice.tenantName
          ?.toLowerCase()
          ?.includes(searchValue.toLowerCase());
      }
      return (
        practice.status === filterValue &&
        practice.tenantName?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
    });
    const practices = practicesVisible.slice(
      queryPagination.offset * queryPagination.limit,
      queryPagination.limit * queryPagination.offset + queryPagination.limit
    );
    setTotalVisible(practicesVisible.length);
    setPracticeTableData(transferToDataTable(practices));
  }, [queryPagination, listAllPractice]);

  useEffect(() => {
    Promise.all([getAllTenantInGroup(), getGroupInfo()])
      .then((res) => setIsLoading(false))
      .catch((err) => setIsLoading(false));
    window.localStorage.setItem('isGroupEdited', 'false');
  }, []);

  const transferToDataTable = useCallback((practices: TenantResposne[]) => {
    const dataTable = practices.map((tenant: TenantResposne) => {
      return {
        groupId: get(tenant, 'tenantGroupId', ''),
        id: get(tenant, 'tenantId', ''),
        practiceName: get(tenant, 'tenantName', ''),
        emailAddress: get(tenant, 'emailAddress', ''),
        phoneNumbers: convertPhoneNumber(
          get(tenant, 'phoneNumbers[0].number', '')
        ),
        status: get(tenant, 'status', 'Inactive'),
        action: 'Login',
      };
    });
    return dataTable;
  }, []);

  const getAllTenantInGroup = async () => {
    try {
      const res = await configuredRequests.GET.getTenantsByGroupId(
        params?.groupId ?? ''
      );

      // setTotalVisible(res.totalAvailable);
      setListAllPractice([...res.tenants]);
      // setPracticeTableData([
      //   ...transferToDataTable(res.tenants.slice(0, queryPagination.limit)),
      // ]);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getGroupInfo = async () => {
    try {
      const res = await configuredRequests.GET.groupInfo(params?.groupId ?? '');
      setGroupInfo(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSearch = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    setSearchValue(value);
  };

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({ ...preState, offset: newPage }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination({ limit: newRowsPerPage, offset: 0 });
      }
    },
    []
  );

  const handleUpdateStatus = async (id: string, status: string) => {
    try {
      const index = listAllPractice.findIndex(
        (practice) => practice.tenantId?.trim() === id.trim()
      );

      if (index > -1) {
        const newPractice = { ...listAllPractice[index], status };

        const res = await configuredRequests.PATCH.updatePractice(
          newPractice.tenantGroupId ?? '',
          id,
          // @ts-ignore
          newPractice
        );
        if (res) {
          getAllTenantInGroup();
          toast.success('Update status successfully!');
        }
      }
    } catch (err) {
      console.log('err', err);
      toast.error('Update status failed. Please try again.');
    }
  };

  return (
    <div className='communication-container w-300 container-practice'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        {isLoading ? (
          <Loading>
            <b>Loading...</b>
          </Loading>
        ) : (
          <Wrapper>
            <h2 className='title-practices'>{get(groupInfo, 'name', '')}</h2>
            <div className='d-flex'>
              <form
                className={
                  false ? 'search-safari' : 'search-form search-filter__global'
                }
                onSubmit={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={['far', 'search']} />
                <input
                  type='search'
                  placeholder='Search by practice name'
                  className='search-form__input'
                  value={searchValue}
                  onChange={handleSearch}
                />
              </form>
              <Filter
                handleSetNewFilterValue={(
                  newItemSelected: OptionFilterDropdownObject
                ) => {
                  setFilterValue(newItemSelected.value);
                }}
                optionsForFilter={optionsForFilter}
              />
              <Button
                variant='contained'
                color='primary'
                className='btn-new'
                onClick={() => setDisplayPracticeModal(true)}
              >
                <FontAwesomeIcon icon={['fas', 'hospital-alt']} />
                New Practice
              </Button>
            </div>
            {practiceTableData.length > 0 ? (
              <TableComponent
                columns={columns}
                rows={practiceTableData.map((practice) => ({
                  practiceName: (
                    <Link
                      to={`/Groups/${practice.groupId}/Practices/${practice.id}`}
                    >
                      <HighlightText
                        text={practice.practiceName ?? ''}
                        searchValue={searchValue}
                      />
                    </Link>
                  ),
                  emailAddress: practice.emailAddress ?? '',
                  phoneNumber: practice.phoneNumbers ?? '',
                  status: (
                    <StatusSelect
                      currentStatus={(
                        practice.status ?? 'Active'
                      ).toLowerCase()}
                      id={practice.id ?? ''}
                      screen='GROUPS'
                      handleUpdate={handleUpdateStatus}
                      disabled={groupInfo?.status === 'Inactive'}
                    />
                  ),
                  action: (
                    <Button
                      variant='contained'
                      color='primary'
                      className='btn-login-practice'
                      onClick={() =>
                        window.open(
                          `/AdminLoginPractice/group/${practice.groupId}/practice/${practice.id}`
                        )
                      }
                      disabled={practice.status === 'Inactive'}
                    >
                      Login to Practice
                    </Button>
                  ),
                }))}
                isHasPagination={true}
                totalAvailable={totalVisible}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
              />
            ) : (
              <p className='no-data'>No results found</p>
            )}
          </Wrapper>
        )}
      </div>

      <PracticesModal
        handleReloadTenantList={getAllTenantInGroup}
        displayPracticeModal={displayPracticeModal}
        setDisplayPracticeModal={setDisplayPracticeModal}
        titleText={'Add New'}
        userInformation={userInformationData}
        setUserInformationData={setUserInformationData}
      />
    </div>
  );
};

export default PracticesSetting;
