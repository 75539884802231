import Configurator from 'components/Configurator/Configurator';
import Driver from 'components/Drivers/Driver';
import Service from 'components/Service/Service';
import SyncerError from 'components/SyncerErrors/SyncerError';
import SyncerAdmin from 'containers/SyncerAdmin/SyncerAdmin';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { default as React, useEffect } from 'react';

const SyncerContainer = (): JSX.Element => {
  const [activeComponent, setActiveComponent] = React.useState<number>(1);
  useEffect(() => {
    let tabActive = getTabActiveLocalStorage();
    setActiveComponent(
      tabActive.leftMenuContentActive === 0
        ? 1
        : tabActive.leftMenuContentActive
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveLink = (numberActive: number) => {
    setTabActiveLocalStorage('', numberActive, 0);
    setActiveComponent(numberActive);
  };

  const renderContainer = () => {
    switch (activeComponent) {
      case 1: {
        return <SyncerAdmin />;
      }
      case 2: {
        return <Driver />;
      }
      case 3: {
        return <Service />;
      }
      case 4: {
        return <Configurator />;
      }
      case 5: {
        return <SyncerError />;
      }
    }
    return <SyncerAdmin />;
  };

  const componentValues: { innerText: string; switchValue: number }[] = [
    {
      innerText: 'Dashboard',
      switchValue: 1,
    },
    {
      innerText: 'Driver',
      switchValue: 2,
    },
    {
      innerText: 'Service',
      switchValue: 3,
    },
    {
      innerText: 'Configurator',
      switchValue: 4,
    },
    {
      innerText: 'Errors',
      switchValue: 5,
    },
  ];

  return (
    <div className='communication-analyze settings--main-view-container'>
      <div className='communication-analyze__right-modal'>
        <ul className='analyze-tab__lists'>
          {componentValues.map((componentValue, index) => {
            return (
              <li
                key={'communication-templates-nav-' + index}
                tabIndex={0}
                className={
                  'analyze-tab__item-list ' +
                  (activeComponent === componentValue.switchValue
                    ? 'active'
                    : '')
                }
                onClick={() => {
                  handleActiveLink(componentValue.switchValue);
                }}
              >
                {componentValue.innerText}
              </li>
            );
          })}
        </ul>
      </div>

      {renderContainer()}
    </div>
  );
};

export default SyncerContainer;
