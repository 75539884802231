import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import loading_spinner from 'media/images/loading-spinner.svg';

type OpensByDeviceChartDataItem = {
  name: string;
  value: number;
};

type OpensByDeviceChartProps = {
  data: OpensByDeviceChartDataItem[];
  loadingOpenByDeviceType: boolean;
};

const colorOption = ['#1974FF', '#C5CEDA'];

export const OpensByDeviceType = (props: OpensByDeviceChartProps) => {
  const total: any = props.data.length ? props.data.reduce((prev: any, curr: any) => {
    return prev.value + curr.value;
  }) : 0;

  const options = {
    chart: {
      renderTo: 'container',
      type: 'pie',
    },

    credits: {
      enabled: false,
    },

    title: {
      text: null,
    },

    yAxis: {},

    plotOptions: {
      pie: {
        borderWidth: 10,
        states: {
          inactive: {
            opacity: 0.8,
          },
        },
      },
    },

    legend: {
      layout: 'vertical',
      verticalAlign: 'middle',
    },

    tooltip: {
      formatter: function () {
        const self: any = this;
        return `<b>${self.key}</b>: ${self.y.toFixed(2)}%`;
      },
    },

    series: [
      {
        data: props.data.map((item, index) => {
          return { name: item.name, y: total === 0 ? 0.001 : (item.value * 100 / total), color: colorOption[index] };
        }),
        size: '75%',
        innerSize: '80%',
        showInLegend: true,

        dataLabels: {
          enabled: false,
        },

        point: {
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        }
      },
    ],
  };

  return (
    <>
      {props.loadingOpenByDeviceType ? <img className="loading-open-by-device-type" src={loading_spinner} alt={"Loading"} /> : 
        <div className='w-100'>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      }
    </>
  );
};

export default OpensByDeviceType;
