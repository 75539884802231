import React from 'react';
import PropTypes from 'prop-types';

export const CheckboxGroupItem = ({ classNames, group, item, onChange, disabled }) => {
  const handleChange = ({ target }) => onChange(item, target.checked);
  return (
    <div className={classNames}>
      <label>
        <input
          type='checkbox'
          name={group}
          checked={item.checked}
          onChange={handleChange}
          disabled={disabled}
        />
        {item.label}
      </label>
    </div>
  );
};

CheckboxGroupItem.propTypes = {
  classNames: PropTypes.string,
  group: PropTypes.string.isRequired,
  item: PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.any.isRequired,
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckboxGroupItem.defaultProps = {
  disabled: false,
};
