/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { cloneDeep, orderBy } from 'lodash';

import UserNotification, { SettingOption } from './UserNotification';
import { ReactComponent as Loading } from 'media/images/loading-spinner.svg';
import Toast from 'components/Global/Toast';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  SummaryItemTypes,
  UserStatusTypes,
  UserKindTypes,
} from 'global/requests/api-route-types';
import { UserListOption } from './Notifications';

const SystemNotification = (): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDisableButton, setDisableButton] = React.useState(true);
  const [userListData, setUserListData] = React.useState<UserListOption[]>([]);
  const [userSelected, setUserSelected] = React.useState<UserListOption[]>([]);

  const [userNotifications, setUserNotifications] = React.useState<
    Record<string, SettingOption>
  >({
    sendEmailNotificationsWhenSyncerStops: {
      label: 'Send Email Notification When Syncer Stops',
      value: false,
    },
  });

  useEffect(() => {
    sendGetAllUsersRequest();
  }, []);

  useEffect(() => {
    getNotificationSetting();
  }, [userListData]);

  const sendGetAllUsersRequest = async (options?: {
    limit?: number;
    offset?: number;
    summaryItems?: SummaryItemTypes[];
    withStatus?: UserStatusTypes;
    userKinds: UserKindTypes[];
  }) => {
    try {
      const allUserDataResponse =
        await configuredRequests.GET.allUserInfoByCurrentTenant({
          ...options,
          userKinds: ['Customer', 'User', 'Admin'],
        });
      if (allUserDataResponse) {
        const userList = allUserDataResponse.users.map((userEntry) => {
          return {
            id: `${userEntry.id}`,
            name: `${userEntry.name}`,
          };
        });

        // using the 'as' keyword will bypass the type-checking and could cause a crash.
        // const userList = convertData(allUserDataResponse.users as ItemDataUserSetting[]);
        const userListSort = orderBy(userList, ['name'], ['asc']);
        setUserListData(userListSort);
      }
    } catch (err) {}
  };

  const getNotificationSetting = async () => {
    try {
      const notificationDataResponse =
        await configuredRequests.GET.notificationSettings(true);

      const newUserNotifications = cloneDeep(userNotifications);
      Object.keys(userNotifications).forEach((noti) => {
        newUserNotifications[noti].value =
          // @ts-ignore
          notificationDataResponse.settings[noti];
      });
      setUserNotifications(newUserNotifications);

      const userSelectedData = userListData.filter((item) =>
        // @ts-ignore
        notificationDataResponse.settings.notificationUsers.includes(item.id)
      );
      setUserSelected(userSelectedData);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // console.log('error getting notification settings', err);
    }
  };

  const submitData = async () => {
    try {
      const payload = {
        notificationUsers: userSelected.map((user) => user.id),
      };
      Object.keys(userNotifications).forEach((noti) => {
        // @ts-ignore
        payload[noti] = userNotifications[noti].value;
      });

      const res = await configuredRequests.PUT.updateNotificationSetting(
        'NotificationSettings',
        payload,
        true
      );
      if (res) {
        setDisableButton(true);
        // setNotificationSetting(res);
        Toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      Toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
    // isUser?: boolean
  ) => {
    event.persist();
    setDisableButton(false);

    // if (isUser) {
    const newUserNotifications = cloneDeep(userNotifications);
    newUserNotifications[event.target.name].value = event.target.checked;
    setUserNotifications(newUserNotifications);
    // }
  };

  const handleSelectValue = (value: UserListOption[]) => {
    setUserSelected(value);
    setDisableButton(false);
  };

  return (
    <div className='notification'>
      {isLoading ? (
        <Loading className='loading-appointment-info' />
      ) : (
        <>
          <UserNotification
            settings={userNotifications}
            userListData={userListData}
            userSelected={userSelected}
            onSelectUser={handleSelectValue}
            onSettingChange={handleChangeCheckbox}
          />

          <Button
            variant='contained'
            color='primary'
            onClick={submitData}
            disabled={isDisableButton}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};

export default SystemNotification;
