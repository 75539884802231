const FormItemResults = [
  [
    {
      id: 2,
      account_id: '5ecc4d57f08bc1940000007a',
      form_id: 2,
      form_folder_id: 1,
      form_version_id: 2,
      folder_name: 'Patient Forms',
      form_name: 'Medical History',
      thank_you: null,
      redirect_url: null,
      is_redirect: false,
      has_group: false,
      has_contact: false,
      share_link: null,
      embed_code: null,
      number: '1.0',
      object: {
        fields: [
          {
            id: -101,
            uuid: '170da2b7-ea85-4880-8b73-d03ecc19efc9',
            fields: [],
            type_name: 'Section Break',
            properties: {
              specific: [
                [
                  'heading',
                  {
                    type: 'input',
                    label: 'Heading Text',
                    value: 'Medical History',
                    defaultValue: 'Heading',
                  },
                ],
                [
                  'subheading',
                  {
                    type: 'input',
                    label: 'Sub-Heading Text',
                    value: '',
                  },
                ],
                [
                  'alignment',
                  {
                    type: 'select',
                    label: 'Align',
                    value: 'center',
                    options: [
                      {
                        label: 'Left',
                        value: 'left',
                      },
                      {
                        label: 'Center',
                        value: 'center',
                      },
                      {
                        label: 'Right',
                        value: 'right',
                      },
                    ],
                    defaultValue: 'left',
                  },
                ],
                [
                  'cols',
                  {
                    type: 'select',
                    label: 'Columns',
                    value: '1',
                    options: [
                      {
                        label: '1',
                        value: 1,
                      },
                      {
                        label: '2',
                        value: 2,
                      },
                      {
                        label: '3',
                        value: 3,
                      },
                      {
                        label: '4',
                        value: 4,
                      },
                    ],
                    defaultValue: '1',
                  },
                ],
              ],
            },
            display_name: 'Section Break',
            validationMessage: null,
          },
          {
            id: 1,
            uuid: '8d226da1-2162-4aad-aa74-07e38d016cd0',
            fields: [
              {
                id: 1,
                uuid: '3b23676c-3655-489a-b062-2f55c484e65b',
                is_basic: true,
                type_name: 'Short Text Entry',
                account_id: null,
                group_name: 'Name',
                is_deleted: false,
                is_enabled: null,
                is_visible: true,
                properties: [
                  [
                    'isVisible',
                    {
                      type: 'checkbox',
                      label: 'Visible Option',
                      value: true,
                      isDynamic: true,
                    },
                  ],
                  [
                    'sublabel',
                    {
                      type: 'input',
                      label: 'Sublabel',
                      value: 'First Name',
                      isDynamic: true,
                    },
                  ],
                  [
                    'placeholder',
                    {
                      type: 'input',
                      label: 'Placeholder',
                      value: '',
                      isDynamic: true,
                    },
                  ],
                ],
                sort_order: 1,
                is_syncable: false,
                display_name: 'First Name',
                legwork_data_type: 'string',
                form_field_type_id: 4,
                legwork_table_name: 'contact',
                form_field_group_id: 2,
                legwork_column_name: 'name_first',
              },
              {
                id: 2,
                uuid: '44a1377a-30db-4243-be4c-7ae2370ead49',
                is_basic: true,
                type_name: 'Short Text Entry',
                account_id: null,
                group_name: 'Name',
                is_deleted: false,
                is_enabled: null,
                is_visible: false,
                properties: [
                  [
                    'isVisible',
                    {
                      type: 'checkbox',
                      label: 'Visible Option',
                      value: false,
                      isDynamic: true,
                    },
                  ],
                  [
                    'sublabel',
                    {
                      type: 'input',
                      label: 'Sublabel',
                      value: 'Middle Name',
                      isDynamic: true,
                    },
                  ],
                  [
                    'placeholder',
                    {
                      type: 'input',
                      label: 'Placeholder',
                      value: '',
                      isDynamic: true,
                    },
                  ],
                ],
                sort_order: 2,
                is_syncable: false,
                display_name: 'Middle Name',
                legwork_data_type: 'string',
                form_field_type_id: 4,
                legwork_table_name: 'contact',
                form_field_group_id: 2,
                legwork_column_name: 'name_middle',
              },
              {
                id: 3,
                uuid: 'e15c99c8-a58f-49f6-b009-0c520dbd6db0',
                is_basic: true,
                type_name: 'Short Text Entry',
                account_id: null,
                group_name: 'Name',
                is_deleted: false,
                is_enabled: null,
                is_visible: true,
                properties: [
                  [
                    'isVisible',
                    {
                      type: 'checkbox',
                      label: 'Visible Option',
                      value: true,
                      isDynamic: true,
                    },
                  ],
                  [
                    'sublabel',
                    {
                      type: 'input',
                      label: 'Sublabel',
                      value: 'Last Name',
                      isDynamic: true,
                    },
                  ],
                  [
                    'placeholder',
                    {
                      type: 'input',
                      label: 'Placeholder',
                      value: '',
                      isDynamic: true,
                    },
                  ],
                ],
                sort_order: 3,
                is_syncable: false,
                display_name: 'Last Name',
                legwork_data_type: 'string',
                form_field_type_id: 4,
                legwork_table_name: 'contact',
                form_field_group_id: 2,
                legwork_column_name: 'name_last',
              },
            ],
            isLocked: true,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value: 'Name',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: true,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
            },
            sort_order: 1,
            display_name: 'Name',
            validationMessage: null,
            form_field_group_id: 2,
          },
          {
            id: -101,
            uuid: '7af8d357-d1ea-4440-a206-2f3cb1b583b8',
            fields: [
              {
                id: 6,
                uuid: 'ee8ed8d8-6c62-4404-9b49-1dc7abe45214',
                isLocked: true,
                is_basic: true,
                type_name: 'Short Text Entry',
                account_id: null,
                group_name: 'Ungrouped',
                is_deleted: false,
                is_enabled: null,
                is_visible: true,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Email Address',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: true,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [],
                },
                sort_order: 6,
                is_syncable: false,
                display_name: 'Email Address',
                legwork_data_type: 'string',
                form_field_type_id: 4,
                legwork_table_name: 'contact_email',
                form_field_group_id: 1,
                legwork_column_name: 'address',
              },
              {
                id: 5,
                uuid: 'f6f62a61-af49-436f-a7aa-2181244cf709',
                isLocked: true,
                is_basic: true,
                type_name: 'Short Text Entry',
                account_id: null,
                group_name: 'Ungrouped',
                is_deleted: false,
                is_enabled: null,
                is_visible: true,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Phone Number',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: true,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [],
                },
                sort_order: 5,
                is_syncable: false,
                display_name: 'Phone Number',
                legwork_data_type: 'string',
                form_field_type_id: 4,
                legwork_table_name: 'contact_phone',
                form_field_group_id: 1,
                legwork_column_name: 'number',
              },
            ],
            type_name: 'Section Break',
            properties: {
              specific: [
                [
                  'heading',
                  {
                    type: 'input',
                    label: 'Heading Text',
                    value: '',
                    defaultValue: 'Heading',
                  },
                ],
                [
                  'subheading',
                  {
                    type: 'input',
                    label: 'Sub-Heading Text',
                    value: '',
                  },
                ],
                [
                  'alignment',
                  {
                    type: 'select',
                    label: 'Align',
                    value: 'left',
                    options: [
                      {
                        label: 'Left',
                        value: 'left',
                      },
                      {
                        label: 'Center',
                        value: 'center',
                      },
                      {
                        label: 'Right',
                        value: 'right',
                      },
                    ],
                    defaultValue: 'left',
                  },
                ],
                [
                  'cols',
                  {
                    type: 'select',
                    label: 'Columns',
                    value: '2',
                    options: [
                      {
                        label: '1',
                        value: 1,
                      },
                      {
                        label: '2',
                        value: 2,
                      },
                      {
                        label: '3',
                        value: 3,
                      },
                      {
                        label: '4',
                        value: 4,
                      },
                    ],
                    defaultValue: '1',
                  },
                ],
              ],
            },
            display_name: 'Section Break',
            validationMessage: null,
          },
          {
            id: -101,
            uuid: '9fca5778-f1cf-4f80-819f-429865bee307',
            fields: [
              {
                id: 7,
                uuid: '849e533e-987c-4416-846d-30855e2f0da0',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value:
                          'Are you currently being treated by a physician for a specific condition?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'ef83f7a0-e677-4b63-a3ab-a85f21083fe2',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'If so, please tell us about your treatment',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: 'e2b0cf5a-99c8-4c15-9a98-4084f3d4edb5',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Have you recently been hospitalized or had a major operation?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'a819e530-3435-4bd8-bfb8-c4fae3c91254',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'If so, please tell us about the hospitalization',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '96d44488-172d-4adc-b718-b47176944ae2',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Have you ever had a serious head or neck injury?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: '2c859d9f-ee49-4632-8b50-4cafee20590b',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'If so, please tell us about the head/neck injury',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '51e4bdfa-22ef-4967-baf9-5baa2fded212',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Are you taking any medications, pills, or drugs?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: '42948c7d-9685-4c05-b47b-25542d1d24ff',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Please list all medications and dosage',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '62ad18fd-88b6-4be1-8acf-9be87a270098',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Are you on a special diet?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: '557f1546-8b3c-483f-a82a-00286619c54b',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Please tell us about your diet',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: 'f1969adb-c48e-4e26-9230-6e12c077939c',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Do you use tobacco?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'c4278bff-9968-4b43-958a-2866276362bb',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Please tell us how often and what type of tobacco consumption',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
            ],
            type_name: 'Section Break',
            properties: {
              specific: [
                [
                  'heading',
                  {
                    type: 'input',
                    label: 'Heading Text',
                    value: '',
                    defaultValue: 'Heading',
                  },
                ],
                [
                  'subheading',
                  {
                    type: 'input',
                    label: 'Sub-Heading Text',
                    value: '',
                  },
                ],
                [
                  'alignment',
                  {
                    type: 'select',
                    label: 'Align',
                    value: 'left',
                    options: [
                      {
                        label: 'Left',
                        value: 'left',
                      },
                      {
                        label: 'Center',
                        value: 'center',
                      },
                      {
                        label: 'Right',
                        value: 'right',
                      },
                    ],
                    defaultValue: 'left',
                  },
                ],
                [
                  'cols',
                  {
                    type: 'select',
                    label: 'Columns',
                    value: '2',
                    options: [
                      {
                        label: '1',
                        value: 1,
                      },
                      {
                        label: '2',
                        value: 2,
                      },
                      {
                        label: '3',
                        value: 3,
                      },
                      {
                        label: '4',
                        value: 4,
                      },
                    ],
                    defaultValue: '1',
                  },
                ],
              ],
            },
            display_name: 'Section Break',
            validationMessage: null,
          },
          {
            id: -101,
            uuid: 'b4f1c73b-2d43-4230-acab-30dfcb5fb366',
            fields: [],
            type_name: 'Section Break',
            properties: {
              specific: [
                [
                  'heading',
                  {
                    type: 'input',
                    label: 'Heading Text',
                    value: '',
                    defaultValue: 'Heading',
                  },
                ],
                [
                  'subheading',
                  {
                    type: 'input',
                    label: 'Sub-Heading Text',
                    value:
                      'Recreational drug and/or alcohol use, combined with local anesthesia may cause a life-threatening emergency. ',
                  },
                ],
                [
                  'alignment',
                  {
                    type: 'select',
                    label: 'Align',
                    value: 'center',
                    options: [
                      {
                        label: 'Left',
                        value: 'left',
                      },
                      {
                        label: 'Center',
                        value: 'center',
                      },
                      {
                        label: 'Right',
                        value: 'right',
                      },
                    ],
                    defaultValue: 'left',
                  },
                ],
                [
                  'cols',
                  {
                    type: 'select',
                    label: 'Columns',
                    value: '1',
                    options: [
                      {
                        label: '1',
                        value: 1,
                      },
                      {
                        label: '2',
                        value: 2,
                      },
                      {
                        label: '3',
                        value: 3,
                      },
                      {
                        label: '4',
                        value: 4,
                      },
                    ],
                    defaultValue: '1',
                  },
                ],
              ],
            },
            display_name: 'Section Break',
            validationMessage: null,
          },
          {
            id: -101,
            uuid: '09067454-a8a1-471d-be9d-63a2a14cf250',
            fields: [
              {
                id: 7,
                uuid: '468af9c2-829a-4217-8c71-b4aa1cb34a86',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value:
                          'Have you ever been advised that you require antibiotics prior to a dental appointment?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'eb395981-b9ae-4078-a6c5-5a288e66f6c0',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Please tell us about the antibiotics',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '452eca9f-d85c-4415-a21e-d24ca233f7c4',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Do you take, or have you taken, PhenFen or Redux?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'c2937c53-8cb9-4921-9a5e-25b2716f2a45',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'If so, please tell us about your PhenFen/Redux usage',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: 'c40796e6-e29e-4c3b-8d86-e2b7d492c190',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value:
                          'Have you ever taken Fosomax, Boniva, Actonel or any other medications containing bisphosphonates?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: '69df48bc-a8e5-4758-87ae-713f3059c42c',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'If so, please tell us about your bisphosphonate usage',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '464ccd9a-e483-4a92-8924-dfb6d8e72de8',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Have you recently used controlled substances?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: 'db0af17c-90c4-4742-9f8a-5d2deab45a17',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value:
                          'If so, please tell us which controlled substances and amount/frequency',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
              {
                id: 7,
                uuid: '0efc2786-4f87-4f75-8db3-5eb8e8790d13',
                type_name: 'Single Choice',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Have you recently consumed alcohol?',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value:
                          'Please answer if filling this form out on the day of your appointment',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    ['placeholder', {}],
                    [
                      'options',
                      {
                        type: 'option-group',
                        label: 'Options',
                        value: ['Yes', 'No'],
                      },
                    ],
                    [
                      'displayOther',
                      {
                        type: 'checkbox',
                        label: 'Display Other Option',
                        value: false,
                        helpText: 'Allow users to enter text when their selection is not available',
                      },
                    ],
                    [
                      'other',
                      {
                        type: 'input',
                        label: null,
                        value: 'Other',
                        visible: {
                          mappedPropKey: 'displayOther',
                          mappedPropValue: 'value',
                        },
                        defaultValue: 'Other',
                      },
                    ],
                    [
                      'displayAsColumns',
                      {
                        type: 'checkbox',
                        label: 'Columns',
                        value: true,
                        helpText: 'Spread options side by side into a specified number of columns',
                      },
                    ],
                    [
                      'numColumns',
                      {
                        type: 'input',
                        label: null,
                        value: '2',
                        visible: {
                          mappedPropKey: 'displayAsColumns',
                          mappedPropValue: 'value',
                        },
                        helpText: 'COLUMNS',
                        className: 'inline',
                        defaultValue: '2',
                      },
                    ],
                  ],
                },
                sort_order: 7,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Single Choice',
              },
              {
                id: 4,
                uuid: '1e07ab0a-0310-4769-8b8e-142118e631a5',
                type_name: 'Short Text Entry',
                is_deleted: false,
                properties: {
                  common: [
                    [
                      'label',
                      {
                        type: 'input',
                        label: 'Label',
                        value: 'Please tell how much alcohol and how recently',
                        isDynamic: false,
                      },
                    ],
                    [
                      'isVisible',
                      {
                        type: 'checkbox',
                        label: 'Visible Option',
                        value: false,
                        isDynamic: true,
                      },
                    ],
                    [
                      'sublabel',
                      {
                        type: 'input',
                        label: 'Sublabel',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'placeholder',
                      {
                        type: 'input',
                        label: 'Placeholder',
                        value: '',
                        isDynamic: true,
                      },
                    ],
                    [
                      'makeRequired',
                      {
                        type: 'checkbox',
                        label: 'Make Required',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'makeHidden',
                      {
                        type: 'checkbox',
                        label: 'Make Hidden',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'hideLabel',
                      {
                        type: 'checkbox',
                        label: 'Hide Label',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                    [
                      'readOnly',
                      {
                        type: 'checkbox',
                        label: 'Read-Only',
                        value: false,
                        isDynamic: false,
                      },
                    ],
                  ],
                  specific: [
                    [
                      'limit',
                      {
                        type: 'checkbox',
                        label: 'Limit Entry',
                        value: false,
                        helpText: 'Limit the maximum number of characters allowed for this field',
                      },
                    ],
                    [
                      'max',
                      {
                        type: 'input',
                        label: null,
                        value: '',
                        visible: {
                          mappedPropKey: 'limit',
                          mappedPropValue: 'value',
                        },
                        helpText: 'CHARS',
                        className: 'inline',
                      },
                    ],
                  ],
                },
                sort_order: 4,
                updated_at: '2019-12-02T17:20:35.583Z',
                updated_by: '5ca516994291bf5601000078',
                display_name: 'Short Text Entry',
              },
            ],
            type_name: 'Section Break',
            properties: {
              specific: [
                [
                  'heading',
                  {
                    type: 'input',
                    label: 'Heading Text',
                    value: '',
                    defaultValue: 'Heading',
                  },
                ],
                [
                  'subheading',
                  {
                    type: 'input',
                    label: 'Sub-Heading Text',
                    value: '',
                  },
                ],
                [
                  'alignment',
                  {
                    type: 'select',
                    label: 'Align',
                    value: 'left',
                    options: [
                      {
                        label: 'Left',
                        value: 'left',
                      },
                      {
                        label: 'Center',
                        value: 'center',
                      },
                      {
                        label: 'Right',
                        value: 'right',
                      },
                    ],
                    defaultValue: 'left',
                  },
                ],
                [
                  'cols',
                  {
                    type: 'select',
                    label: 'Columns',
                    value: '2',
                    options: [
                      {
                        label: '1',
                        value: 1,
                      },
                      {
                        label: '2',
                        value: 2,
                      },
                      {
                        label: '3',
                        value: 3,
                      },
                      {
                        label: '4',
                        value: 4,
                      },
                    ],
                    defaultValue: '1',
                  },
                ],
              ],
            },
            display_name: 'Section Break',
            validationMessage: null,
          },
          {
            id: 8,
            uuid: '11a59781-d1bf-401b-bddf-4e8ab38eab6b',
            type_name: 'Multiple Choice',
            is_deleted: false,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value: 'Women (Please check all that apply)',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['placeholder', {}],
                [
                  'options',
                  {
                    type: 'option-group',
                    label: 'Options',
                    value: [
                      'Pregnant',
                      'Trying to get pregnant',
                      'Currently nursing',
                      'Taking oral contraceptives',
                      'None of the above',
                    ],
                  },
                ],
                [
                  'displayOther',
                  {
                    type: 'checkbox',
                    label: 'Display Other Option',
                    value: false,
                    helpText: 'Allow users to enter text when their selection is not available',
                  },
                ],
                [
                  'other',
                  {
                    type: 'input',
                    label: null,
                    value: 'Other',
                    visible: {
                      mappedPropKey: 'displayOther',
                      mappedPropValue: 'value',
                    },
                    defaultValue: 'Other',
                  },
                ],
                [
                  'displayAsColumns',
                  {
                    type: 'checkbox',
                    label: 'Columns',
                    value: true,
                    helpText: 'Spread options side by side into a specified number of columns',
                  },
                ],
                [
                  'numColumns',
                  {
                    type: 'input',
                    label: null,
                    value: '2',
                    visible: {
                      mappedPropKey: 'displayAsColumns',
                      mappedPropValue: 'value',
                    },
                    helpText: 'COLUMNS',
                    className: 'inline',
                    defaultValue: '2',
                  },
                ],
              ],
            },
            sort_order: 8,
            updated_at: '2019-12-02T17:20:35.583Z',
            updated_by: '5ca516994291bf5601000078',
            display_name: 'Multiple Choice',
            validationMessage: null,
          },
          {
            id: 8,
            uuid: '4cd7124e-9ce5-475f-92c8-e0aa76d4daed',
            isLocked: true,
            type_name: 'Multiple Choice',
            is_deleted: false,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value:
                      'Have you ever had an adverse reaction or allergies to any medication or substance? (Please check if allergic)',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: 'If other, please list',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: true,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['placeholder', {}],
                [
                  'options',
                  {
                    type: 'option-group',
                    label: 'Options',
                    value: [
                      'Aspirin',
                      'Acrylic',
                      'Erythromycin',
                      'Iodine',
                      'Latex',
                      'Local Anesthetics',
                      'Metal',
                      'Novocaine',
                      'Nitrous Oxide',
                      'Penicillin',
                      'Sulfa Drugs',
                      'Tetracycline',
                      'Valium',
                      'Xylocaine',
                      'None of the Above',
                    ],
                  },
                ],
                [
                  'displayOther',
                  {
                    type: 'checkbox',
                    label: 'Display Other Option',
                    value: true,
                    helpText: 'Allow users to enter text when their selection is not available',
                  },
                ],
                [
                  'other',
                  {
                    type: 'input',
                    label: null,
                    value: 'Other',
                    visible: {
                      mappedPropKey: 'displayOther',
                      mappedPropValue: 'value',
                    },
                    defaultValue: 'Other',
                  },
                ],
                [
                  'displayAsColumns',
                  {
                    type: 'checkbox',
                    label: 'Columns',
                    value: true,
                    helpText: 'Spread options side by side into a specified number of columns',
                  },
                ],
                [
                  'numColumns',
                  {
                    type: 'input',
                    label: null,
                    value: '3',
                    visible: {
                      mappedPropKey: 'displayAsColumns',
                      mappedPropValue: 'value',
                    },
                    helpText: 'COLUMNS',
                    className: 'inline',
                    defaultValue: '2',
                  },
                ],
              ],
            },
            sort_order: 8,
            updated_at: '2019-12-02T17:20:35.583Z',
            updated_by: '5ca516994291bf5601000078',
            display_name: 'Multiple Choice',
            validationMessage: null,
          },
          {
            id: 8,
            uuid: 'eddf20a6-faa0-4c5c-9375-bb2e468bee42',
            isLocked: true,
            type_name: 'Multiple Choice',
            is_deleted: false,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value:
                      'Do you have, or have you ever had any of the following medical conditions? (Please select all that apply)',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: true,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['placeholder', {}],
                [
                  'options',
                  {
                    type: 'option-group',
                    label: 'Options',
                    value: [
                      'Anemia',
                      'Chemotherapy',
                      'Cold Sores/Fever Blisters',
                      'Cortisone Medication',
                      'Excessive Bleeding',
                      'Frequent Cough',
                      'Frequent Headaches',
                      'Hay Fever',
                      'Hemophilia',
                      'High Blood Pressure',
                      'Hives or Rash',
                      'Kidney Problems',
                      'Liver Disease',
                      'Parathyroid Disease',
                      'Recent Weight Loss',
                      'Rheumatism',
                      'Shingles',
                      'Spina Bifida',
                      'Stroke',
                      'Thyroid Disease',
                      'Venereal Disease',
                      'Arthritis or Gout',
                      'Blood Disease',
                      'Bruise Easily',
                      'Congenital Heart Problems',
                      'Diabetes',
                      'Drug/Alcohol Addiction',
                      'Emphysema',
                      'Frequent Urination',
                      'Heart Murmur',
                      'Heart Valve or Pacemaker',
                      'Herpes',
                      'Hypoglycemia',
                      'Lung Disease',
                      'Rheumatic Fever',
                      'Tuberculosis',
                      'Ulcers or GI Problems',
                      'Asthma',
                      'Chest Pains',
                      'Convulsions',
                      'Easily Winded',
                      'Excessive Thirst',
                      'Frequent Diarrhea',
                      'Genital Herpes',
                      'Heart Attack/Heart Failure',
                      'Hepatitis (B or C)',
                      'Low Blood Pressure',
                      'Irregular Heartbeat',
                      'Leukemia',
                      'Mitral Valve Prolapse',
                      'Radiation Treatments',
                      'Renal Disease',
                      'Scarlet Fever',
                      'Sickle Cell Disease',
                      'Stomach/Intestinal Disease',
                      'Swelling of Limbs',
                      'Tonsillitis',
                      'Yellow Jaundice',
                      'Artificial Joint',
                      'Blood Transfusion',
                      'Cancer',
                      'Currently Pregnant',
                      'Dizziness or Fainting',
                      'Eating Disorder',
                      'Epilepsy or Seizures',
                      'Glaucoma',
                      'Heart Trouble',
                      'Hepatitis (A)',
                      'HIV-AIDS-ARC',
                      'Jaw Joint Pain',
                      'Psychiatric Care',
                      'Sinus Problems',
                      'Tumor or Growth',
                      'X-ray/Chemotherapy',
                      'No to All',
                    ],
                  },
                ],
                [
                  'displayOther',
                  {
                    type: 'checkbox',
                    label: 'Display Other Option',
                    value: false,
                    helpText: 'Allow users to enter text when their selection is not available',
                  },
                ],
                [
                  'other',
                  {
                    type: 'input',
                    label: null,
                    value: 'Other',
                    visible: {
                      mappedPropKey: 'displayOther',
                      mappedPropValue: 'value',
                    },
                    defaultValue: 'Other',
                  },
                ],
                [
                  'displayAsColumns',
                  {
                    type: 'checkbox',
                    label: 'Columns',
                    value: true,
                    helpText: 'Spread options side by side into a specified number of columns',
                  },
                ],
                [
                  'numColumns',
                  {
                    type: 'input',
                    label: null,
                    value: '3',
                    visible: {
                      mappedPropKey: 'displayAsColumns',
                      mappedPropValue: 'value',
                    },
                    helpText: 'COLUMNS',
                    className: 'inline',
                    defaultValue: '2',
                  },
                ],
              ],
            },
            sort_order: 8,
            updated_at: '2019-12-02T17:20:35.583Z',
            updated_by: '5ca516994291bf5601000078',
            display_name: 'Multiple Choice',
            validationMessage: null,
          },
          {
            id: 5,
            uuid: 'ab7552a5-0781-4f14-b63b-60ffd120d6cf',
            type_name: 'Long Text Entry',
            is_deleted: false,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value:
                      'Do you have any condition or problem, not listed, which we should know about? Please explain',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                [
                  'limit',
                  {
                    type: 'checkbox',
                    label: 'Limit Entry',
                    value: false,
                    helpText: 'Limit the maximum number of words allowed for this field',
                  },
                ],
                [
                  'max',
                  {
                    type: 'input',
                    label: null,
                    value: '',
                    visible: {
                      mappedPropKey: 'limit',
                      mappedPropValue: 'value',
                    },
                    helpText: 'WORDS',
                    className: 'inline',
                  },
                ],
              ],
            },
            sort_order: 5,
            updated_at: '2019-12-02T17:20:35.583Z',
            updated_by: '5ca516994291bf5601000078',
            display_name: 'Long Text Entry',
            validationMessage: null,
          },
          {
            id: -102,
            uuid: 'b85afe84-6210-49cb-aa65-d2dfec8dcbc1',
            type_name: 'Description Area',
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value: 'Description Area',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['label', {}],
                ['sublabel', {}],
                ['placeholder', {}],
                ['makeRequired', {}],
                ['makeHidden', {}],
                ['hideLabel', {}],
                ['readOnly', {}],
                [
                  'descriptionContent',
                  {
                    type: 'rich-text',
                    label: 'Edit Description Area',
                    value:
                      "<p>To the best of my knowledge, the questions on this form have been accurately answered.&nbsp; I understand that providing incorrect information can be dangerous to my (or patient's) health.&nbsp; It is my responsibility to inform the dental office of any changes in medical status.</p>",
                    buttonIcon: 'pencil',
                  },
                ],
              ],
            },
            display_name: 'Description Area',
            validationMessage: null,
          },
          {
            id: 10,
            uuid: 'd83840fe-99b3-4264-9b75-0fccb5427ce5',
            type_name: 'Signature',
            is_deleted: false,
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value: 'Signature',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: true,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['placeholder', {}],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: 'Use your mouse or finger to draw your signature above',
                    defaultValue: 'Use your mouse or finger to draw your signature above',
                  },
                ],
              ],
            },
            sort_order: 10,
            updated_at: '2019-12-02T17:20:35.583Z',
            updated_by: '5ca516994291bf5601000078',
            display_name: 'Signature',
            validationMessage: null,
          },
          {
            uuid: '8c50069c-c3bf-4704-92bc-99ff9cd579e6',
            type_name: 'Submit',
            properties: {
              common: [
                [
                  'label',
                  {
                    type: 'input',
                    label: 'Label',
                    value: 'Submit',
                    isDynamic: false,
                  },
                ],
                [
                  'isVisible',
                  {
                    type: 'checkbox',
                    label: 'Visible Option',
                    value: false,
                    isDynamic: true,
                  },
                ],
                [
                  'sublabel',
                  {
                    type: 'input',
                    label: 'Sublabel',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'placeholder',
                  {
                    type: 'input',
                    label: 'Placeholder',
                    value: '',
                    isDynamic: true,
                  },
                ],
                [
                  'makeRequired',
                  {
                    type: 'checkbox',
                    label: 'Make Required',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'makeHidden',
                  {
                    type: 'checkbox',
                    label: 'Make Hidden',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'hideLabel',
                  {
                    type: 'checkbox',
                    label: 'Hide Label',
                    value: false,
                    isDynamic: false,
                  },
                ],
                [
                  'readOnly',
                  {
                    type: 'checkbox',
                    label: 'Read-Only',
                    value: false,
                    isDynamic: false,
                  },
                ],
              ],
              specific: [
                ['sublabel', {}],
                ['placeholder', {}],
                ['makeRequired', {}],
                ['makeHidden', {}],
                ['hideLabel', {}],
                ['readOnly', {}],
              ],
            },
            display_name: 'Submit',
            validationMessage: null,
          },
        ],
      },
      created_at: '2020-05-25T22:57:31.894Z',
      created_by: '5dadec570b9554950000007a',
      is_restricted: true,
      is_published: true,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:31.894Z',
      updated_by: '5de547e2a510c7970000007a',
      never_published: false,
      actions: 'sync_form',
      secure_account_id: 'f3e95b266680f3ff48206a146eb87db0fb807529',
      secure_form_id: '7247e8076a860ef5cf45e0ca700fc3b78ee35a37',
      secure_form_version_id: '7247e8076a860ef5cf45e0ca700fc3b78ee35a37',
    },
  ],
  [
    {
      id: 4,
      form_field_group_id: 1,
      form_field_type_id: 6,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Sex',
      sort_order: 4,
      legwork_table_name: 'contact',
      legwork_column_name: 'gender',
      legwork_data_type: 'string',
      type_name: 'Dropdown',
      group_name: 'Ungrouped',
      is_deleted: false,
    },
    {
      id: 5,
      form_field_group_id: 1,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Phone Number',
      sort_order: 5,
      legwork_table_name: 'contact_phone',
      legwork_column_name: 'number',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Ungrouped',
      is_deleted: false,
    },
    {
      id: 6,
      form_field_group_id: 1,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Email Address',
      sort_order: 6,
      legwork_table_name: 'contact_email',
      legwork_column_name: 'address',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Ungrouped',
      is_deleted: false,
    },
    {
      id: 14,
      form_field_group_id: 1,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'SSN',
      sort_order: 14,
      legwork_table_name: 'contact',
      legwork_column_name: 'ssn',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Ungrouped',
      is_deleted: false,
    },
    {
      id: 13,
      form_field_group_id: 1,
      form_field_type_id: 1,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Birthdate',
      sort_order: 13,
      legwork_table_name: 'contact',
      legwork_column_name: 'birthdate',
      legwork_data_type: 'date',
      type_name: 'Date Picker',
      group_name: 'Ungrouped',
      is_deleted: false,
    },
    {
      id: 1,
      form_field_group_id: 2,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'First Name',
      sort_order: 1,
      legwork_table_name: 'contact',
      legwork_column_name: 'name_first',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Name',
      is_deleted: false,
    },
    {
      id: 2,
      form_field_group_id: 2,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: false,
      display_name: 'Middle Name',
      sort_order: 2,
      legwork_table_name: 'contact',
      legwork_column_name: 'name_middle',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Name',
      is_deleted: false,
    },
    {
      id: 3,
      form_field_group_id: 2,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Last Name',
      sort_order: 3,
      legwork_table_name: 'contact',
      legwork_column_name: 'name_last',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Name',
      is_deleted: false,
    },
    {
      id: 10,
      form_field_group_id: 3,
      form_field_type_id: 6,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'State / Province',
      sort_order: 10,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Dropdown',
      group_name: 'Address',
      is_deleted: false,
    },
    {
      id: 12,
      form_field_group_id: 3,
      form_field_type_id: 6,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: false,
      display_name: 'Country',
      sort_order: 12,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Dropdown',
      group_name: 'Address',
      is_deleted: false,
    },
    {
      id: 7,
      form_field_group_id: 3,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Address 1',
      sort_order: 7,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Address',
      is_deleted: false,
    },
    {
      id: 8,
      form_field_group_id: 3,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Address 2',
      sort_order: 8,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Address',
      is_deleted: false,
    },
    {
      id: 9,
      form_field_group_id: 3,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'City',
      sort_order: 9,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Address',
      is_deleted: false,
    },
    {
      id: 11,
      form_field_group_id: 3,
      form_field_type_id: 4,
      account_id: null,
      is_syncable: false,
      is_enabled: null,
      is_basic: true,
      is_visible: true,
      display_name: 'Zip / Postal Code',
      sort_order: 11,
      legwork_table_name: 'contact_postal_address',
      legwork_column_name: 'postal_address_object',
      legwork_data_type: 'string',
      type_name: 'Short Text Entry',
      group_name: 'Address',
      is_deleted: false,
    },
  ],
  [
    {
      id: 1,
      type_name: 'Date Picker',
      sort_order: 1,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Date Picker',
    },
    {
      id: 2,
      type_name: 'Time',
      sort_order: 2,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Time',
    },
    {
      id: 3,
      type_name: 'Number',
      sort_order: 3,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Number',
    },
    {
      id: 4,
      type_name: 'Short Text Entry',
      sort_order: 4,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Short Text Entry',
    },
    {
      id: 5,
      type_name: 'Long Text Entry',
      sort_order: 5,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Long Text Entry',
    },
    {
      id: 6,
      type_name: 'Dropdown',
      sort_order: 6,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Dropdown',
    },
    {
      id: 7,
      type_name: 'Single Choice',
      sort_order: 7,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Single Choice',
    },
    {
      id: 8,
      type_name: 'Multiple Choice',
      sort_order: 8,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Multiple Choice',
    },
    {
      id: 9,
      type_name: 'Spinner',
      sort_order: 9,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Spinner',
    },
    {
      id: 10,
      type_name: 'Signature',
      sort_order: 10,
      is_deleted: false,
      updated_at: '2020-05-25T22:57:27.060Z',
      updated_by: '5ca516994291bf5601000078',
      display_name: 'Signature',
    },
  ],
  [
    {
      name_slug: 'customer',
      _id: '51b97b73bf0e0fa047000004',
      name: 'Customer',
      description: 'Current Customer',
      marketing: false,
      primary: true,
    },
    {
      name_slug: 'lead',
      _id: '51b97b73bf0e0fa047000005',
      name: 'Lead',
      description: 'Potential customers',
      marketing: false,
      primary: true,
    },
    {
      name_slug: 'whitening',
      _id: '51b97b74bf0e0fa047000013',
      name: 'Whitening',
      description: 'Smile Whitening',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'straightening',
      _id: '523380d3b87d7e2f2c51ab1f',
      name: 'Straightening',
      description: 'Smile Straightening',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'replacement',
      _id: '52338de70aa0959690cd5825',
      name: 'Replacement',
      description: 'Tooth Replacement',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'prevention',
      _id: '52338e8e0aa0959690cd5826',
      name: 'Prevention',
      description: 'Decay Prevention',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'fear',
      _id: '52338f240aa0959690cd5827',
      name: 'Fear',
      description: 'Reduce Dental Fear',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'cosmetic',
      _id: '5282c426d91f0e1766f63c96',
      name: 'Cosmetic',
      description: 'Cosmetic Dentistry',
      marketing: true,
      primary: false,
    },
    {
      name_slug: 'appointment',
      _id: '529ceb95a78596efc6570fa0',
      name: 'Appointment',
      description: 'Appointment Lead',
      marketing: false,
      primary: true,
    },
    {
      name_slug: 'non-customer',
      _id: '53a333a9bb5ec974135a10c6',
      name: 'Non-Customer',
      description: 'For contacts that are not customers',
      marketing: false,
      primary: true,
    },
  ],
  [
    {
      id: '5ecc4d57f08bc1940000007b',
      name_first: 'Dorothy',
      name_last: 'Fisher',
      email_address: 'user1@legworksocial.com',
      status: 'active',
      business_name: null,
      language_id: null,
      is_customer: false,
    },
    {
      id: '5ecc4d57f08bc19400000086',
      name_first: 'Hettie',
      name_last: 'Senger',
      email_address: 'user2@legworksocial.com',
      status: 'active',
      business_name: null,
      language_id: null,
      is_customer: false,
    },
  ],
  [],
  [],
];

export default FormItemResults;
