/* eslint-disable @typescript-eslint/naming-convention */
import APIService from '../clientApi';
import {
  GeneralSettingParams,
  Account,
  IContactParams,
  IUserDetailResponse,
  IUpdateUserResponse,
  UpdateUserPayload,
  UpdateAccountColorPayload,
  IUpdateAccountColorResponse,
  GroupPayload,
  IAddUserResponse,
  ISettings,
  IMarketingSettings,
  IContactInternal,
} from './settingsTypes';

import { INpsSettings } from './settingsUITypes';

import { defaultNpsSettings } from './defaults';

import { iServerNpsSettingsToINpsSettings, iNpsSettingsToIServerNpsSettings } from './transformers';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z 0-9])/gi, ($1: string): string => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = (a: any): boolean => {
  return Array.isArray(a);
};

const isObject = (o: any): boolean => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = (o: any): any => {
  if (isObject(o)) {
    const transformed = {} as any;

    Object.keys(o).map((k: any): void => transformed[toCamel(k)] = keysToCamel(o[k]));

    return transformed;
  }
  if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i));
  }

  return o;
};



const API = APIService();

const getGroupContent = async (params: [string], isResultOnly?: boolean): Promise<any> =>
  API.rpc('', { method: 'group.getContents', params }, undefined, isResultOnly);

const getGeneralSettingData = async (params: GeneralSettingParams): Promise<ISettings> =>
  API.rpc('', { method: 'public.getCurrent', params }, undefined, true);

const getContactList = async (params: IContactParams): Promise<IContactInternal[]> => {
  return API.rpc('', { method: 'contact.search', params }).then(res => keysToCamel(res.p[1]));
};

const getUserDetail = async (params: [string]): Promise<IUserDetailResponse> =>
  API.rpc('', { method: 'contact.findById', params });

const updateUser = (params: [UpdateUserPayload]): Promise<IUpdateUserResponse> =>
  API.rpc('', { method: 'contact.update', params });

const addUser = (params: [UpdateUserPayload]): Promise<IUpdateUserResponse> =>
  API.rpc('', { method: 'contact.insert', params });

const updateAccountColors = (
  params: [UpdateAccountColorPayload],
): Promise<IUpdateAccountColorResponse> =>
  API.rpc('', { method: 'account.updateColors', params }, undefined, true);

const addNewGroup = (params: [GroupPayload]): Promise<IAddUserResponse> =>
  API.rpc('', { method: 'group.insert', params });

const updateGroup = (params: [GroupPayload]): Promise<IAddUserResponse> =>
  API.rpc('', { method: 'group.update', params });

const updateAccount = (params: [Account]): Promise<Account> =>
  API.rpc('', { method: 'account.update', params }, undefined, true);

// TODO: Connect these with real data
const getSystemSettingData = (params: [], testData: any): Promise<any> =>
  API.fakeCall('', { method: 'account.getSystemSettingData', params }, undefined, testData).then(
    res => res.p[1].results,
  );
const updateSystemSettings = (params: [any]): Promise<any> =>
  API.fakeCall('', { method: 'account.updateSystemSettings', params }, undefined);
const getTimezones = async (testData: any): Promise<any> =>
  API.fakeCall('', { method: 'public.getTimezones', params: [] }, undefined, testData).then(
    res => res.p[1].results,
  );
const getMarketingSettingData = (): Promise<IMarketingSettings> =>
  API.rpc('', { method: 'settings.marketing.getMarketingSettings', params: [] }, undefined, true);

const updateMarketingSettings = (params: IMarketingSettings): Promise<void> =>
  API.rpc(
    '',
    { method: 'settings.marketing.setMarketingSettings', params: [params] },
    undefined,
    true,
  );

// TODO: Connect these with real data
const getNotificationSettingData = (params: [], testData: any): Promise<any> =>
  API.fakeCall(
    '',
    { method: 'settings.getNotificationSettings', params },
    undefined,
    testData,
  ).then(res => res.p[1].results);
const updateNotificationSettings = (params: [any]): Promise<any> =>
  API.fakeCall('', { method: 'settings.saveNotificationSettings', params }, undefined);
const getUsers = async (testData: any): Promise<any> => {
  return API.fakeCall(
    '',
    { method: 'contact.search', params: [{ is_user: true }] },
    undefined,
    testData,
  ).then(res => res.p[1].results);
};

const findAllPhoneNumbers = async (testData: any): Promise<any> => {
  return API.fakeCall(
    '',
    { method: 'account_phone.findAll', params: [] },
    undefined,
    testData,
  ).then(res => res.p[1].results);
};
const updatePhoneNumber = async (params: [any]): Promise<any> => {
  // TODO: convert phoneNumber from internal format to the format expected
  // by the api
  return API.fakeCall('', { method: 'account_phone.update', params }, undefined);
};
const getAvailableNumbers = async (params: [any], testData: any): Promise<any> => {
  return API.fakeCall(
    '',
    { method: 'account_phone.getAvailableNumbers', params },
    undefined,
    testData,
  ).then(res => res.p[1].results);
};

const purchaseNumber = async (params: [string, string]): Promise<any> => {
  return API.fakeCall('', { method: 'account_phone.purchaseNumber', params }, undefined);
};

const verifyNewNumber = async (params: [any]): Promise<any> => {
  return API.fakeCall('', { method: 'account_phone.verifyNewNumber', params }, undefined);
};

const getNpsSettings = async (): Promise<INpsSettings> =>
  API.rpc('', { method: 'settings.nps.get', params: [] }, undefined, true).then(serverNpsSettings =>
    iServerNpsSettingsToINpsSettings({ ...defaultNpsSettings, ...serverNpsSettings }),
  );

const saveNpsSettings = (params: INpsSettings): Promise<void> =>
  API.rpc(
    '',
    { method: 'settings.nps.save', params: [iNpsSettingsToIServerNpsSettings(params)] },
    undefined,
    true,
  );

const getBillingPortalLink = (): Promise<string> =>
  API.rpc('', { method: 'billing.getBillingPortalLink', params: [] }, undefined, true);

/*
In cases where you want to update the parameters with a fixed value, or you need to transform
the response to a different shape you can use this pattern.

const additionalExample = async (params: IUserParams): Promise<IUserResponse> => {
  // if you wanted to transform the parameters you could do it here.
  const response = await API.rpc('', { method: 'public.getCurrent', params });
  // if you wanted to transform data, you could do it here.
  return response;
};
*/

const deleteGroup = (params: [string]): Promise<IAddUserResponse> =>
  API.rpc('', { method: 'group.delete', params });

export default {
  getGeneralSettingData,
  getContactList,
  getUserDetail,
  updateUser,
  addUser,
  updateAccountColors,
  addNewGroup,
  updateGroup,
  updateAccount,
  getSystemSettingData,
  updateSystemSettings,
  getTimezones,
  getMarketingSettingData,
  updateMarketingSettings,
  getNotificationSettingData,
  updateNotificationSettings,
  getUsers,
  deleteGroup,
  findAllPhoneNumbers,
  updatePhoneNumber,
  getAvailableNumbers,
  purchaseNumber,
  verifyNewNumber,
  getNpsSettings,
  saveNpsSettings,
  getGroupContent,
  getBillingPortalLink,
};
