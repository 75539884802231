import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const getColorFrom = (status: string): string => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'lightyellow';
    case 'sent':
      return '#4C7CFD';
    case 'received':
      return '#DEE7EE';
    case 'canceled':
      return 'mistyrose';
    case 'scheduled':
      return 'powderblue';
    default:
      return 'lightgreen';
  }
};
const StyledCard = styled(Card)`
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
`;
const TitleTypography = styled(Typography)`
  display: inline-block;
  padding: 8px;
`;
const BodyTypography = styled(Typography)`
  background-color: ${({ status }: { status: string }): string => getColorFrom(status)};
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  color: ${({ status }: { status: string }): string => (status === 'sent' ? 'white' : 'black')};
`;

const LightTextSpan = styled.span`
  font-weight: 200;
  color: #1a252e;
  opacity: 50%;
`;
const StyledCircularProgress = styled(CircularProgress)`
  display: inline-block;
`;
export { StyledCard, TitleTypography, BodyTypography, LightTextSpan, StyledCircularProgress };
