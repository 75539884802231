// export const dataStateOfUS = [
//   {"label": "Alabama", "value": "AL"},
//   {"label": "Alaska", "value": "AK"},
//   {"label": "American Samoa", "value": "AS"},
//   {"label": "Arizona", "value": "AZ"},
//   {"label": "Arkansas", "value": "AR"},
//   {"label": "California", "value": "CA"},
//   {"label": "Colorado", "value": "CO"},
//   {"label": "Connecticut", "value": "CT"},
//   {"label": "Delaware", "value": "DE"},
//   {"label": "District of Columbia", "value": "DC"},
//   {"label": "Federated States of Micronesia", "value": "FM"},
//   {"label": "Florida", "value": "FL"},
//   {"label": "Georgia", "value": "GA"},
//   {"label": "Guam", "value": "GU"},
//   {"label": "Hawaii", "value": "HI"},
//   {"label": "Idaho", "value": "ID"},
//   {"label": "Illinois", "value": "IL"},
//   {"label": "Indiana", "value": "IN"},
//   {"label": "Iowa", "value": "IA"},
//   {"label": "Kansas", "value": "KS"},
//   {"label": "Kentucky", "value": "KY"},
//   {"label": "Louisiana", "value": "LA"},
//   {"label": "Maine", "value": "ME"},
//   {"label": "Marshall Islands", "value": "MH"},
//   {"label": "Maryland", "value": "MD"},
//   {"label": "Massachusetts", "value": "MA"},
//   {"label": "Michigan", "value": "MI"},
//   {"label": "Minnesota", "value": "MN"},
//   {"label": "Mississippi", "value": "MS"},
//   {"label": "Missouri", "value": "MO"},
//   {"label": "Montana", "value": "MT"},
//   {"label": "Nebraska", "value": "NE"},
//   {"label": "Nevada", "value": "NV"},
//   {"label": "New Hampshire", "value": "NH"},
//   {"label": "New Jersey", "value": "NJ"},
//   {"label": "New Mexico", "value": "NM"},
//   {"label": "New York", "value": "NY"},
//   {"label": "North Carolina", "value": "NC"},
//   {"label": "North Dakota", "value": "ND"},
//   {"label": "Northern Mariana Islands", "value": "MP"},
//   {"label": "Ohio", "value": "OH"},
//   {"label": "Oklahoma", "value": "OK"},
//   {"label": "Oregon", "value": "OR"},
//   {"label": "Palau", "value": "PW"},
//   {"label": "Pennsylvania", "value": "PA"},
//   {"label": "Rhode Island", "value": "RI"},
//   {"label": "South Carolina", "value": "SC"},
//   {"label": "South Dakota", "value": "SD"},
//   {"label": "Tennessee", "value": "TN"},
//   {"label": "Texas", "value": "TX"},
//   {"label": "Utah", "value": "UT"},
//   {"label": "Vermont", "value": "VT"},
//   {"label": "Virgin Islands", "value": "VI"},
//   {"label": "Virginia", "value": "VA"},
//   {"label": "Washington", "value": "WA"},
//   {"label": "West Virginia", "value": "WV"},
//   {"label": "Wisconsin", "value": "WI"},
//   {"label": "Wyoming", "value": "WY"},
//   {"label": "Armed Forces Africa", "value": "AE"},
//   {"label": "Armed Forces Americas", "value": "AA"},
//   {"label": "Armed Forces Canada", "value": "AE"},
//   {"label": "Armed Forces Europe", "value": "AE"},
//   {"label": "Armed Forces Middle East", "value": "AE"},
//   {"label": "Armed Forces Pacific", "value": "AP"},
// ];

export const dataStateOfUS = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "District of Columbia", value: "District of Columbia" },
  { label: "Federated States of Micronesia", value: "Federated States of Micronesia" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Guam", value: "Guam" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Palau", value: "Palau" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virgin Islands", value: "Virgin Islands" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
  { label: "Armed Forces Africa", value: "Armed Forces Africa" },
  { label: "Armed Forces Americas", value: "Armed Forces Americas" },
  { label: "Armed Forces Canada", value: "Armed Forces Canada" },
  { label: "Armed Forces Europe", value: "Armed Forces Europe" },
  { label: "Armed Forces Middle East", value: "Armed Forces Middle East" },
  { label: "Armed Forces Pacific", value: "Armed Forces Pacific" },
]