//Libraries (node_modules)
import React from 'react';

//Components
import {
  getRouterFromUserPermission,
  NoPermissionRouter,
} from './PermissionSpecificRouters';

//Sass
import '../styles/sass/base.scss';

//types
import {
  // Permissions,
  ValidJWT,
} from 'types/RouterT';

// helper functions
import { getSessionJWT } from '../global/sessionStorage/SessionJWT';

interface IUnreadContext {
  refInterval: any;
}

export const UnreadContext = React.createContext<IUnreadContext>(
  {} as IUnreadContext
);




// main component

const AppRouter: React.FC = () => {
  // This is where we store login JWT within app (updated/lost when re-rendering)
  const [routerJWT, setRouterJWT] = React.useState<ValidJWT | undefined>();

  // This is login JWT stored in session storage
  const sessionJWT = getSessionJWT();
  const refInterval = React.useRef(null);

  // If there is no routerJWT & no sessionJWT, user is only allowed to access routes under <noPermissionRouter/>
  // Some of the routes under <noPermissionRouter/> can help the user login. That's why we pass 'setRouterJWT' as the props
  if (!routerJWT && !sessionJWT) {
    return <NoPermissionRouter setRouterJWT={setRouterJWT} />;
  }

  // If there is no routerJWT, but there is sessionJWT, we set the routerJWt to be equal to sessionJWT
  // And then we determine what routes that user is allowed to access by 'getRouterFromUserPermission'.
  // If that user is not allowed to any specific route, then we only allow routes under <NoPermissionRouter />
  if (!routerJWT && sessionJWT) {
    setRouterJWT(sessionJWT);
    const PermittedRouter = getRouterFromUserPermission(
      setRouterJWT,
      sessionJWT
    );
    if (
      !PermittedRouter
    ) {
      return <NoPermissionRouter setRouterJWT={setRouterJWT} />;
    }
    return PermittedRouter;
  }


  // If there is already JWT stored in state, we determine what routes that user is allowed to access by 'getRouterFromUserPermission'.
  // If that user is not allowed to any specific route, then we only allow routes under <NoPermissionRouter /> 
  if (routerJWT) {
    const PermittedRouter = getRouterFromUserPermission(
      setRouterJWT,
      routerJWT
    );
    if (
      !PermittedRouter
    ) {
      return <NoPermissionRouter setRouterJWT={setRouterJWT} />;
    }
    return (
      <UnreadContext.Provider value={{ refInterval }}>
        {PermittedRouter}
      </UnreadContext.Provider>
    );
  }

  // This is only default return.
  // This should never actually be reachable because above covers all cases
  return <NoPermissionRouter setRouterJWT={setRouterJWT} />;
};

export default AppRouter;
