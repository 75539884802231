import React from 'react';

import styled from 'styled-components';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

interface IProps extends InputBaseProps {
  tall: boolean;
}
const InputStyle = ({ tall, ...rest }: IProps): React.ReactElement => (
  <InputBase {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
);

const StyledInput = styled(InputStyle)`
  .MuiInputBase-input {
    border-radius: 5px;
    position: relative;
    background-color: ${({ theme }): string =>
      theme.palette ? theme.palette.background.paper : 'rgba(0,0,0,1)'};
    border: 1px solid #b8c3d2;

    font-size: 1rem;
    padding: 2px 2rem 6px 8px;
    height: ${(props: { tall: boolean }): string => (props.tall ? '19px' : '17px')};
    padding-top: ${(props: { tall: boolean }): string => (props.tall ? '8px' : '6px')};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  margin-right: 5px;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

export { StyledInput, StyledInputLabel, Main };
