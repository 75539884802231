import React from 'react';
import TemplatePerformanceView from 'components/Communications/TemplatePerformanceComponents/TemplatePerformance';
import TemplateCommunicationRecipients from 'components/Communications/TemplateRecipientsComponents/TemplateRecipients';
import { Switch, Route, useParams } from 'react-router-dom';

//Types
import {
  MainComponentProps,
  // NavLinkProps
} from 'types/NavigationT';

export const TemplateContainer = (props?: MainComponentProps) => {
  // @ts-ignore
  const { urlSlug } = useParams();
  const setHeaderNavLinks = props?.setHeaderNavLinks;

  React.useEffect(() => {
    if (setHeaderNavLinks) {
      return setHeaderNavLinks([
        { linkTo: `/Dashboard/Template/${urlSlug}`, innerText: 'Performance' },
        { linkTo: `/Dashboard/Template/${urlSlug}/Recipients`, innerText: 'Recipients' },
      ]);
    }
  }, [setHeaderNavLinks, urlSlug]);

  // data call and pass the information
  return (
    <>
      {/* <h2 className="margin-10">{urlSlug?.substring(1)}</h2>
      
      <Link to="/Dashboard" title="Back" className="lnk margin-10 back-to-performance">&lt; Back to Dashboard</Link> */}
      <Switch>
        <Route path={`/Dashboard/Template/:templateId/Recipients`}>
          <TemplateCommunicationRecipients />
        </Route>

        <Route path={'/Dashboard/Template/:templateId'}>
          <TemplatePerformanceView />
        </Route>
      </Switch>
    </>
  );
};

export default TemplateContainer;
