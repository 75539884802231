import styled from 'styled-components';
import React from 'react';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

interface IProps extends InputBaseProps {
  targetWidth: 'extrasmall' | 'small' | 'medium' | 'large' | 'fullwidth';
  tall: boolean;
}

const getSizeFrom = (targetWidth: string): string => {
  switch (targetWidth) {
    case 'extrasmall':
      return '30px';
    case 'small':
      return '38px';
    case 'medium':
      return '58px';
    case 'large':
      return '101px';
    case 'fullwidth':
      return 'none';
    default:
      return 'none';
  }
};

const InputStyle = ({ targetWidth, tall, ...rest }: IProps): React.ReactElement => (
  <InputBase {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
);

const StyledInput = styled(InputStyle)`
  border-radius: 6px;
  border: 1px solid ${getThemeProp('palette.border.main', '#d4dbe4')};
  .MuiInputBase-input {
    position: relative;
    background-color: ${getThemeProp('palette.background.paper', 'rgba(0,0,0,1)')};
    font-size: 0.9rem;
    width: ${({ targetWidth }): string => getSizeFrom(targetWidth)};

    border-radius: 6px;
    padding: 9px 13px;
    min-height: ${(props: { tall: boolean }): string => (props.tall ? '20px' : '17px')};
  }
  &.Mui-error {
    border-color: ${getThemeProp('palette.error.main', 'red')};
  }
  .MuiSelect-icon {
    right: 10;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  margin: 1rem 0 0.5rem 1px;
`;

const Main = styled.div``;

export { StyledInput, StyledInputLabel, Main };
