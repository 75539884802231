/* eslint-disable array-callback-return */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Field, FieldArray, Formik, ErrorMessage, FastField } from 'formik';
import { dataStateOfUS } from 'global/localStorage/dataStateOfUS';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { phoneNumberPattern } from 'utils/regex';
import { getSessionTenantInfo } from '../../../global/sessionStorage/SessionAPIResponses';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as Yup from 'yup';
import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import ModalDiscard from '../Modal/ModalDiscard';

ReactModal.setAppElement('div#root');

interface roleInformationDataType {
  roleId: string;
  roleName: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface UserInformationModalProps {
  displaySettingUserModal: boolean;
  setDisplaySettingUserModal: (arg: boolean) => void;
  titleText?: string;
  userInformation: UserDataResponse | undefined;
  setUserInformationData: React.Dispatch<
    React.SetStateAction<UserDataResponse | undefined>
  >;
  handleReloadUserList?: any;
  isEdit?: boolean;
  refreshUserInfo?: () => void;
  roleInformationData: roleInformationDataType[];
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const UserValidationSchema = Yup.object().shape({
  name: Yup.object().shape({
    givenName: Yup.string().required(ErrorMessageTypes.required),
    lastName: Yup.string().required(ErrorMessageTypes.required),
  }),
  emailAddress: Yup.string()
    .email('Incorrect email format.')
    .max(50, ErrorMessageTypes.incorrectFormat)
    .required(ErrorMessageTypes.required),
  roles: Yup.array().required(ErrorMessageTypes.required),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      number: Yup.string().matches(
        phoneNumberPattern,
        ErrorMessageTypes.incorrectFormat
      ),
      extension: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
      kind: Yup.string(),
    })
  ),
  addresses: Yup.array().of(
    Yup.object().shape({
      address: Yup.object().shape({
        addressLine1: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
        addressLine2: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
        city: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
        postalCode: Yup.string().max(50, ErrorMessageTypes.incorrectFormat),
      }),
    })
  ),
  birthDate: Yup.date().max(
    new Date(),
    `Value must be ${moment().format('MM/DD/YYYY')} or earlier.`
  ),
});

const typesPhone = [
  { label: 'Mobile Phone', value: 'cell' },
  { label: 'Home Phone', value: 'landline' },
  { label: 'Office Phone', value: 'business' },
  { label: 'Other Phone', value: 'other' },
];

const UserInformationModal = (props: UserInformationModalProps) => {
  const {
    displaySettingUserModal,
    setDisplaySettingUserModal,
    titleText,
    userInformation,
    roleInformationData,
    isEdit,
  } = props;

  const [modalStyle] = React.useState(getModalStyle);

  const history = useHistory();

  const [openPopup, setOpenPopup] = React.useState(false);
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState<string>('');
  const [dirtyForm, setDirtyForm] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(false);

  const userInfoData = !isEdit
    ? {
        userId: '',
        pulseUserStatus: 'Active',
        roles: [],
        name: {
          givenName: '',
          lastName: '',
        },
        emailAddress: '',
        addresses: [
          {
            address: {
              state: dataStateOfUS[0],
            },
            isPrimary: true,
            kind: 'home',
          },
        ],
        phoneNumbers: [
          {
            number: '',
            kind: 'cell',
            isValidated: false,
            isText: false,
            isCall: false,
            isPrimary: false,
            validateOutput: '',
            subscribe: true,
          },
        ],
        enabled: true,
        tenant: '',
      }
    : {
        userId: userInformation?.userId || '',
        pulseUserStatus: userInformation?.pulseUserStatus || true,
        name: {
          givenName: userInformation?.name?.givenName || '',
          lastName: userInformation?.name?.lastName || '',
        },
        roles: userInformation?.roles || [],
        emailAddress: userInformation?.emailAddress || '',
        addresses: [
          {
            address: {
              addressLine1: get(
                userInformation,
                'addresses[0].address.addressLine1',
                ''
              ),
              addressLine2: get(
                userInformation,
                'addresses[0].address.addressLine2',
                ''
              ),
              city: get(userInformation, 'addresses[0].address.city', ''),
              state: get(userInformation, 'addresses[0].address.state', '')
                ? dataStateOfUS.filter((item) => {
                    return (
                      //@ts-ignore
                      item.value === userInformation?.addresses[0].address.state
                    );
                  })
                : dataStateOfUS[0].value,
              postalCode: get(
                userInformation,
                'addresses[0].address.postalCode',
                ''
              ),
              country: '',
            },
            isPrimary: get(
              userInformation,
              'addresses[0].address.isPrimary',
              true
            ),
            kind: get(userInformation, 'addresses[0].address.kind', 'home'),
          },
        ],
        phoneNumbers: userInformation?.phoneNumbers || [],
        birthDate: userInformation?.details?.birthDate
          ? moment(userInformation.details.birthDate).format('YYYY-MM-DD')
          : undefined,
        enabled: userInformation?.enabled || true,
        tenant: userInformation?.tenant || '',
      };

  const [tenantIdData, setTenantIdData] = React.useState<string>('');

  useEffect(() => {
    const tenantId = getSessionTenantInfo()?.tenantId;
    if (!tenantId) {
      //TODO logging
      return;
    }
    // else
    setTenantIdData(tenantId);
  }, []);

  const handleSubmitData = async (values: any) => {
    try {
      setDisabledButton(true);
      const submitData = {
        userId: isEdit ? userInformation?.userId : '',
        pulseUserStatus: values.pulseUserStatus,
        roles: (() => {
          let roleValues: { roleId: string }[] = [];
          values.roles.map((e: { roleId: string; roleName: string }) => {
            roleValues.push({ roleId: e.roleId });
          });
          return roleValues;
        })(),
        name: {
          givenName: values.name.givenName,
          lastName: values.name.lastName,
        },
        emailAddress: values.emailAddress,
        addresses: [
          {
            address: {
              addressLine1: values.addresses[0].address.addressLine1,
              addressLine2: values.addresses[0].address.addressLine2,
              city: values.addresses[0].address.city,
              state: values.state ? values.state.value : dataStateOfUS[0].value,
              postalCode: values.addresses[0].address.postalCode,
              country: '',
            },
            isPrimary: true,
            kind: 'home',
          },
        ],
        phoneNumbers: (() => {
          const phoneNumbersArray: {
            number: number;
            extension: number;
            kind: string;
            isPrimary: boolean;
            ordinal: number;
            isText: boolean;
            isCall: boolean;
            isValidated: boolean;
            validateOutput: string;
            subscribe: boolean;
          }[] = [];
          let isPrimary = true;
          values.phoneNumbers.forEach(
            (
              item: {
                number: number;
                extension: number;
                kind: string;
                isPrimary: boolean;
                isText: boolean;
                isCall: boolean;
                isValidated: boolean;
                validateOutput: string;
                subscribe: boolean;
              },
              index: number
            ) => {
              if (item.number) {
                phoneNumbersArray.push({
                  number: item.number,
                  extension: item.extension,
                  kind: item.kind,
                  isPrimary: isPrimary,
                  ordinal: index,
                  isText: item.isText,
                  isCall: item.isCall,
                  isValidated: item.isValidated,
                  validateOutput: item.validateOutput ?? '',
                  subscribe: item.subscribe,
                });
                isPrimary && (isPrimary = false);
              }
            }
          );
          return phoneNumbersArray;
        })(),
        details: {
          birthDate: values.birthDate,
        },
        enabled: true,
        tenant: tenantIdData,
      };
      const res = isEdit
        ? //@ts-ignore
          await configuredRequests.PATCH.updateUserAdmin(submitData)
        : //@ts-ignore
          await configuredRequests.PUT.createTenantUser(submitData);
      setDisplaySettingUserModal(false);
      if (!isEdit) {
        await props.handleReloadUserList();
      }
      if (res) {
        setErrorEmail('');
        setDirtyForm(false);
        if (props.refreshUserInfo) {
          props.refreshUserInfo();
        }
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
      setDisabledButton(false);
    } catch (err) {
      setDisabledButton(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
      if (err.response) {
        setErrorEmail(err.response.data.message);
      }
    }
  };

  const handleCloseModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenWarningPopup(dirtyForm);
    setDisplaySettingUserModal(dirtyForm);
    setErrorEmail('');
  };

  const handleCloseAddModal = () => {
    setDirtyForm(false);
    setOpenWarningPopup(false);
    setDisplaySettingUserModal(false);
  };

  const handleSoftDeleteUser = async () => {
    const payload = { userId: userInformation?.userId };
    try {
      const res = await configuredRequests.POST.softDeleteUser(payload as any);
      if (res) {
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>User has been deleted successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );

        history.goBack();
      }
    } catch (err) {
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>User has not been deleted yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  const renderPopupWarning = () => {
    return (
      <div style={modalStyle} className='insert-modal-style'>
        <h2 id='simple-modal-title'>
          {' '}
          Are you sure?
          <FontAwesomeIcon
            className='close-icon'
            icon={['far', 'times']}
            onClick={() => {
              setOpenPopup(false);
            }}
          />
        </h2>

        <div className='insert-body'>Do you want to archive this user?</div>

        <div className='action-modal'>
          <Button
            variant='contained'
            className='btn-cancel'
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='btn-insert'
            onClick={() => {
              setOpenPopup(false);
              handleSoftDeleteUser();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal modal-edit-user'
      isOpen={displaySettingUserModal}
      onRequestClose={handleCloseModal}
    >
      <Formik
        initialValues={userInfoData}
        validationSchema={UserValidationSchema}
        validateOnMount={true}
        // validateOnChange={false}
        onSubmit={(values, actions) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
            dirty,
          } = formikProps;
          if (dirty && displaySettingUserModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {' '}
                  {titleText ? titleText + ' User' : ''}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>

              <div className='edit-appointment'>
                <Fragment>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          First Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            name='name.givenName'
                            maxLength={15}
                            required
                            placeholder='First Name'
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'name.givenName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='name.givenName' />
                          </p>
                        </div>
                      </div>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Last Name <b className='require'>*</b>
                        </span>
                        <FastField
                          name='name.lastName'
                          maxLength={15}
                          required
                          placeholder='Last Name'
                          className='setting__search-form'
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            handleBlur(e);
                            setFieldValue(
                              'name.lastName',
                              e.currentTarget.value.trim()
                            );
                          }}
                        />
                        <p className='error-msg'>
                          <ErrorMessage name='name.lastName' />
                        </p>
                      </div>
                    </div>

                    <div className='setting__content--full-row block '>
                      <span className='title-input-form'>Status</span>
                      <Field
                        as='select'
                        name='pulseUserStatus'
                        value={values.pulseUserStatus}
                        className='full-width custom-color'
                      >
                        <option value='Active'> Active</option>
                        <option value='Inactive'>Inactive</option>
                      </Field>
                      <p className='error-msg'>
                        <ErrorMessage name='pulseUserStatus' />
                      </p>
                    </div>
                    <div className='setting__content--full-row block autocomplete'>
                      <span className='title-input-form'>
                        Role Permission <b className='require'>*</b>
                      </span>
                      <Field
                        name='roles'
                        component={Autocomplete}
                        value={values.roles}
                        multiple
                        id='checkboxes-tags-demo'
                        options={roleInformationData}
                        disableCloseOnSelect
                        getOptionLabel={(option: any) => option.roleName}
                        noOptionsText={'No options'}
                        onChange={(e: any, value: any[]) => {
                          setFieldValue('roles', value);
                        }}
                        onBlur={() => {
                          if (!touched.roles)
                            formikProps.setFieldTouched('roles');
                        }}
                        getOptionSelected={(
                          option: roleInformationDataType,
                          value: roleInformationDataType
                        ) => {
                          return option.roleId === value.roleId;
                        }}
                        renderOption={(
                          option: roleInformationDataType,
                          { selected }: any
                        ) => {
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize='small' />
                                }
                                checkedIcon={<CheckBoxIcon fontSize='small' />}
                                style={{ marginRight: 8, color: '#1974ff' }}
                                checked={selected}
                                className='checked-role'
                              />
                              {option.roleName}
                            </React.Fragment>
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder='Search'
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            fullWidth
                          />
                        )}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='roles' />
                      </p>
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>
                        {' '}
                        Email Address <b className='require'>*</b>
                      </span>
                      <FastField
                        name='emailAddress'
                        placeholder='Email Address'
                        required
                        disabled={isEdit}
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setErrorEmail('');
                          setFieldValue(
                            'emailAddress',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='emailAddress' />
                        {!errors.emailAddress && errorEmail}
                      </p>
                    </div>

                    <div className='setting__content--full-row block'>
                      {values.phoneNumbers.length > 0 && (
                        <span className='title-input-form block'>
                          Phone Numbers
                        </span>
                      )}
                      <FieldArray
                        name='phoneNumbers'
                        render={({ remove, push }) => (
                          <>
                            {values.phoneNumbers.length > 0 &&
                              (values.phoneNumbers as any[]).map(
                                (item, index) => {
                                  return (
                                    <div
                                      key={index + 1}
                                      className='add-phoneNumber'
                                    >
                                      <div className='d-flex'>
                                        <div className='margin-r-5'>
                                          <FastField
                                            name={`phoneNumbers.${index}.number`}
                                            max={10}
                                            className={`setting__search-form width-100 ${
                                              values.phoneNumbers[index]
                                                ?.subscribe === false &&
                                              'unsubscribe'
                                            }`}
                                            disabled={
                                              !values.phoneNumbers[index]
                                                ?.subscribe
                                            }
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleBlur(e);
                                              setFieldValue(
                                                `phoneNumbers.${index}.number`,
                                                e.currentTarget.value.trim()
                                              );
                                            }}
                                          />
                                          <p className='error-msg'>
                                            <ErrorMessage
                                              className='error-msg'
                                              name={`phoneNumbers.${index}.number`}
                                            />
                                          </p>
                                        </div>
                                        <div className='margin-r-5 phone-ext'>
                                          <FastField
                                            maxLength={50}
                                            name={`phoneNumbers.${index}.extension`}
                                            placeholder='Ext.'
                                            className={`setting__search-form width-50 margin-r-0 ${
                                              values.phoneNumbers[index]
                                                ?.subscribe === false &&
                                              'unsubscribe'
                                            }
                                           `}
                                            disabled={
                                              !values.phoneNumbers[index]
                                                ?.subscribe
                                            }
                                            onBlur={(
                                              e: React.FocusEvent<HTMLInputElement>
                                            ) => {
                                              handleBlur(e);
                                              setFieldValue(
                                                `phoneNumbers.${index}.extension`,
                                                e.currentTarget.value.trim()
                                              );
                                            }}
                                          />
                                          <p className='error-msg'>
                                            <ErrorMessage
                                              className='error-msg'
                                              name={`phoneNumbers.${index}.extension`}
                                            />
                                          </p>
                                        </div>
                                        <div className='search-filter__global'>
                                          {!values.phoneNumbers[index]
                                            .subscribe ? (
                                            <FastField
                                              maxLength={50}
                                              name={`phoneNumbers.${index}.kind`}
                                              className={`setting__search-form width-50 margin-r-0 ${
                                                values.phoneNumbers[index]
                                                  ?.subscribe === false &&
                                                'unsubscribe'
                                              }
                                            `}
                                              value={
                                                typesPhone.find(
                                                  (type: any) =>
                                                    type.value ===
                                                    values.phoneNumbers[index]
                                                      .kind
                                                )?.label
                                              }
                                              disabled={
                                                !values.phoneNumbers[index]
                                                  ?.subscribe
                                              }
                                            />
                                          ) : (
                                            <Field
                                              className='filter-form'
                                              as='select'
                                              name={`phoneNumbers[${index}].kind`}
                                            >
                                              <option value='cell'>
                                                Mobile Phone
                                              </option>
                                              <option value='landline'>
                                                Home Phone
                                              </option>
                                              <option value='business'>
                                                Office Phone
                                              </option>
                                              <option value='other'>
                                                Other Phone
                                              </option>
                                            </Field>
                                          )}
                                        </div>
                                        <div className='btn-group'>
                                          {/* {values.phoneNumbers[index]
                                            .subscribe === false ? (
                                            <button
                                              className='btn-subscribe'
                                              onClick={
                                                () =>
                                                  setFieldValue(
                                                    `phoneNumbers.${index}.subscribe`,
                                                    true
                                                  )
                                                // handleSubscribe(
                                                //   values.phoneNumbers[index].number
                                                // )
                                              }
                                            >
                                              Subscribe
                                            </button>
                                          ) : (
                                            <> */}
                                          <button
                                            type='button'
                                            className={`${
                                              values.phoneNumbers[index].isText
                                                ? 'btn-selected'
                                                : ''
                                            }`}
                                            disabled={
                                              !values.phoneNumbers[index]
                                                .subscribe
                                            }
                                            onClick={() => {
                                              if (
                                                !values.phoneNumbers[index]
                                                  .isText
                                              ) {
                                                const idxOldText =
                                                  values.phoneNumbers.findIndex(
                                                    (item: any) => item.isText
                                                  );
                                                idxOldText > -1 &&
                                                  setFieldValue(
                                                    `phoneNumbers.${idxOldText}.isText`,
                                                    false
                                                  );
                                              }
                                              setFieldValue(
                                                `phoneNumbers.${index}.isText`,
                                                !values.phoneNumbers[index]
                                                  .isText
                                              );
                                            }}
                                          >
                                            Text
                                          </button>
                                          <button
                                            type='button'
                                            className={`${
                                              values.phoneNumbers[index].isCall
                                                ? 'btn-selected'
                                                : ''
                                            }`}
                                            disabled={
                                              !values.phoneNumbers[index]
                                                .subscribe
                                            }
                                            onClick={() => {
                                              if (
                                                !values.phoneNumbers[index]
                                                  .isCall
                                              ) {
                                                const idxOldCall =
                                                  values.phoneNumbers.findIndex(
                                                    (item: any) => item.isCall
                                                  );
                                                idxOldCall > -1 &&
                                                  setFieldValue(
                                                    `phoneNumbers.${idxOldCall}.isCall`,
                                                    false
                                                  );
                                              }
                                              setFieldValue(
                                                `phoneNumbers.${index}.isCall`,
                                                !values.phoneNumbers[index]
                                                  .isCall
                                              );
                                            }}
                                          >
                                            Call
                                          </button>
                                          {/* </>
                                          )} */}
                                        </div>
                                        <span
                                          className='btn-remove'
                                          onClick={() => remove(index)}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'times']}
                                            className='icon-remove-fallback'
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {values.phoneNumbers.length < 5 && (
                              <span
                                className='add-fallback'
                                onClick={() => {
                                  push({
                                    number: '',
                                    extension: '',
                                    kind: 'cell',
                                    isValidated: false,
                                    isText: false,
                                    isCall: false,
                                    isPrimary: false,
                                    validateOutput: '',
                                    subscribe: true,
                                  });
                                }}
                              >
                                +Add Phone Number
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className='setting__content--full-row block'>
                      <span className='title-input-form'>Address</span>
                      <FastField
                        maxLength={50}
                        name='addresses[0].address.addressLine1'
                        className='setting__search-form'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addresses[0].address.addressLine1',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addresses[0].address.addressLine1' />
                      </p>
                      <span className='title-input-form'>
                        Apt / Suite / Other
                      </span>
                      <FastField
                        maxLength={50}
                        name='addresses[0].address.addressLine2'
                        className='setting__search-form '
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          handleBlur(e);
                          setFieldValue(
                            'addresses[0].address.addressLine2',
                            e.currentTarget.value.trim()
                          );
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='addresses[0].address.addressLine2' />
                      </p>
                      <div className='flex address-2'>
                        <div>
                          <span className='title-input-form'>City</span>
                          <FastField
                            maxLength={50}
                            name='addresses[0].address.city'
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'addresses[0].address.city',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.city' />
                          </p>
                        </div>

                        <div className='margin-h-5 w-50'>
                          <span className='title-input-form'>
                            State / Province
                          </span>
                          <Select
                            classNamePrefix='search-city'
                            //@ts-ignore
                            defaultValue={values.addresses[0].address.state}
                            name='state'
                            className='full-width option-city w-100'
                            options={dataStateOfUS}
                            onChange={(e: any) => {
                              setFieldValue('state', e);
                            }}
                            maxMenuHeight={150}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.state' />
                          </p>
                        </div>

                        <div>
                          <span className='title-input-form'>
                            Zip / Postal Code
                          </span>
                          <FastField
                            maxLength={50}
                            name='addresses[0].address.postalCode'
                            className='field-input'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'addresses[0].address.postalCode',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='addresses[0].address.postalCode' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='flex-col ml-10 birth-date'>
                      <span className='date-label'>Birthdate</span>
                      <TextField
                        id='date'
                        type='date'
                        name='birthDate'
                        value={values.birthDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event: any) => {
                          if (!touched.birthDate)
                            formikProps.setFieldTouched('birthDate');
                          handleChange(event);
                        }}
                        inputProps={{
                          max: moment(new Date().toISOString()).format(
                            'YYYY-MM-DD'
                          ),
                        }}
                      />
                      <p className='error-msg'>
                        <ErrorMessage name='birthDate' />
                      </p>
                    </div>
                    <br />
                  </form>
                </Fragment>
              </div>

              <div className='react-modal-settings-footer'>
                {props.titleText === 'Edit' ? (
                  <Button
                    variant='contained'
                    color='primary'
                    className='btn-archive'
                    onClick={() => setOpenPopup(true)}
                  >
                    <FontAwesomeIcon icon={['fas', 'archive']} /> Archive
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='default'
                    className='cancel-button'
                    onClick={handleCloseModal}
                  >
                    <span>Cancel</span>
                  </Button>
                )}
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid || disabledButton}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        isOpenModal={openWarningPopup}
        handleCloseModal={() => setOpenWarningPopup(false)}
        title='Confirmation'
        handleConfirmModal={handleCloseAddModal}
      />
      <Modal
        open={openPopup}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {renderPopupWarning()}
      </Modal>
    </ReactModal>
  );
};

export default UserInformationModal;
