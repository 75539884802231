import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export interface DeleteFormModalProps {
  open: boolean;
  handleClose: () => void;
  onRedirectToForm: () => void;
}
// params [formId, formId, name]


const WarningDefaultForm = (props: DeleteFormModalProps): React.ReactElement | null => {
  const { open, handleClose, onRedirectToForm } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent>
        <DialogContentText id='delete-modal' style={{ fontFamily: 'Nunito Sans, Calibri, Arial', fontSize: '16px', color: '#12253F' }}>
          You are editing a form that is set up to provide your pratice management system with important data.
          Because this form is set up to collect data for your practice management system and VetHero database, some fields can not be deleted and modified.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='default'>
          Cancel
        </Button>
        <Button onClick={onRedirectToForm} color='primary' autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDefaultForm;
