import styled from 'styled-components';

export const AvailabilityWrapper = styled.div`
  /* max-width: 1100px; */
  height: calc(100vh - 163px);
  overflow-y: auto;
  padding: 20px;

  &.hide-height {
    height: initial;
  }

  .title-header {
    color: #12253f;

    font-size: 15px;
    font-weight: bold;

    letter-spacing: 0;
    line-height: 20px;

    &.title-header--unavailability {
      margin-top: 40px;
    }

    &:first-child {
      margin-top: 18px;
    }
  }

  .description-header {
    color: #617799;

    font-size: 13px;

    letter-spacing: 0;
    line-height: 21px;
  }

  .oab-interval-setting {
    .select-avalability.select-duration {
      .search-city__menu div {
        // min-height: 160px;
        overflow: auto;
      }
    }
  }

  .hours-of-operation {
    max-width: 1100px;
  }

  .hours-of-operation,
  .oab-interval-setting {
    .loading-hours-info {
      text-align: center;
      img {
        width: 70px;
      }

      svg {
        width: 70px;
        height: 70px;
      }
    }
    .save-button {
      margin-top: 10px;
    }
    .oab-save-button {
      margin-top: 0px;
      margin-left: 15px;
    }
    .oab-select {
      width: 120px;
    }
    .save-button:disabled,
    .save-button[disabled] {
      color: #ffffff;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }

    .group-hours-settings {
      border: 1px solid #e3ebf6;
      border-bottom: unset;
      border-radius: 6px;
      .setting-content {
        border-bottom: 1px solid #e3ebf6;
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0 3px 3px 0 rgba(18, 37, 63, 0.03);

        display: flex;
        align-items: center;
        justify-content: space-around;

        padding: 10px;

        > div:first-child,
        > div:nth-child(2) {
          width: 50%;
        }

        p.error-text {
          text-align: center;
        }
      }
    }
  }
  .office-unavailability {
    // margin-top: 40px;
    .add-out-of-office {
      display: inline-block;
      color: #4c7cfd;

      font-size: 13px;
      font-weight: 600;

      letter-spacing: 0;
      line-height: 18px;

      cursor: pointer;
    }
  }
  .select-duration {
    & > div {
      height: initial !important;
    }

    .search-city__indicator {
      right: 0;
    }

    .search-city__control {
      box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
      font-size: 13px;

      .search-city__placeholder {
        color: hsl(0, 0%, 25%);
      }
    }

    .search-city__control--menu-is-open,
    .search-city__control--is-focused {
      border-color: hsl(0, 0%, 80%);
    }

    .search-city__menu div {
      // min-height: 160px;
      overflow: visible;
    }

    .search-city__indicator-separator {
      background-color: initial;
    }

    .search-city__indicator {
      right: 0;
    }
  }
  .duration-text {
    position: absolute;
    content: '';
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 13px;
    color: #6e84a3;
    pointer-events: none;
  }
`;
