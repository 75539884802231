//libraries
import qs from 'qs';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
//helper functions
import { getSessionAuthorizationHeaderValue } from 'global/sessionStorage/SessionJWT';

import { ValidAxiosResponses } from './ResponseTypes';
import {
  AxiosPostRequestDataObjects,
  AxiosPutRequestDataObjects,
  AxiosPatchRequestDataObjects,
} from './RequestTypes';
import {
  getRootDomain,
  getAutomationApiUrl,
  getRealTimeUrl,
} from 'global/constants/url';

type ApiType = 'core' | 'automation' | 'realtime';

// const windowURL = window.location.href.toLocaleLowerCase();
// const NOT_IN_DEVELOPMENT = process.env.NODE_ENV !== "development";
const NOT_IN_DEVELOPMENT = true;
// const DEPLOYED_API_SUBDOMAIN = windowURL.includes("staging.fetchprm.dev") ? "staging.fetchprm.dev" : "https://api.fetchprm.dev";

// error handler where we should do things like log in production TODO implement production logic here
const axiosErrorHandler = (error: AxiosError) => {
  console.log('error in your request. error:', error);
  console.log('error.config', error.config);
  // console.log("error.isAxiosError", error.isAxiosError);
  // console.log("error.toJson", error.toJSON());
  if (error.response) {
    // Request made and server responded
    console.log('Error has a response, server responded with error');
    console.log('error.response', error.response);
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received or request was blocked by browser
    console.log(
      'Error has no response, request format was valid but no response from server. This could be a CORS error'
    );
    console.log('error.request', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error making request', error.message);
  }
  throw error;
  // we can chain catch calls if we re-throw the error,
  // this allows generic logging to be bound without silencing the error in configuredRequests
};

enum ValidContentTypes {
  'application/x-www-form-urlencoded' = 'application/x-www-form-urlencoded',
}
// some payloads will require encoding, we can use content-type to determine what kind of encoding to use
const transformRequestDataByContentType = (
  data: AxiosPostRequestDataObjects,
  headers?: { 'content-type': ValidContentTypes }
) => {
  if (!headers) {
    return data;
  }
  const contentType = headers['content-type'];
  // console.log("content-type", contentType);

  //return will be whatever this switch returns
  switch (contentType) {
    case 'application/x-www-form-urlencoded':
      return qs.stringify(data);
    default:
      return data;
  }
};

const getDomainApi = (type: ApiType = 'core'): string => {
  switch (type) {
    case 'automation':
      return getAutomationApiUrl();
    case 'realtime':
      return getRealTimeUrl();
    default:
      return getRootDomain();
  }
};

// allows us to use custom fields in our options, add new fields here
interface SharedCustomAxiosRequestOptions {
  axiosOptions?: AxiosRequestConfig;
  customOptions?: {
    useAuth?: boolean;
  };
}

interface CustomAxiosGetOptions extends SharedCustomAxiosRequestOptions {}

interface CustomAxiosPostOptions extends SharedCustomAxiosRequestOptions {}
interface CustomAxiosPatchOptions extends SharedCustomAxiosRequestOptions {}
interface CustomAxiosPutOptions extends SharedCustomAxiosRequestOptions {}

interface CustomAxiosDeleteOptions extends SharedCustomAxiosRequestOptions {}

export const axiosGet = async (
  url: string,
  options?: CustomAxiosGetOptions,
  apiType?: ApiType
): Promise<void | AxiosResponse<ValidAxiosResponses>> => {
  // Default to always use authentication. There are very few calls that are non-authenticated.
  const useAuth =
    options?.customOptions?.useAuth === false
      ? {}
      : {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
        };

  // console.log('computed route', (NOT_IN_DEVELOPMENT ? DEPLOYED_API_SUBDOMAIN + url : url))

  return await axios
    .get(NOT_IN_DEVELOPMENT ? `${getDomainApi(apiType)}` + url : url, {
      ...options?.axiosOptions,
      ...useAuth,
      method: 'GET',
    })
    .catch(axiosErrorHandler);
};

export const axiosPost = async (
  url: string,
  requestData: AxiosPostRequestDataObjects,
  options?: CustomAxiosPostOptions,
  apiType?: ApiType
): Promise<void | AxiosResponse<ValidAxiosResponses>> => {
  // Must encode the payload differently for form-encoded content.
  const payload = transformRequestDataByContentType(
    requestData,
    options?.axiosOptions?.headers
  );

  // Default to always use authentication. There are very few calls that are non-authenticated.
  const useAuth =
    options?.customOptions?.useAuth === false
      ? {}
      : {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
        };

  // console.log('computed route', (NOT_IN_DEVELOPMENT ? DEPLOYED_API_SUBDOMAIN + url : url))

  return axios
    .post(
      NOT_IN_DEVELOPMENT ? `${getDomainApi(apiType)}` + url : url,
      payload,
      {
        ...options?.axiosOptions,
        ...useAuth,
        method: 'POST',
      }
    )
    .catch(axiosErrorHandler);
};

export const axiosPut = async (
  url: string,
  requestData: AxiosPutRequestDataObjects,
  options?: CustomAxiosPutOptions,
  apiType?: ApiType
): Promise<void | AxiosResponse<ValidAxiosResponses>> => {
  // Default to always use authentication. There are very few calls that are non-authenticated.
  const useAuth =
    options?.customOptions?.useAuth === false
      ? {}
      : {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
        };

  // console.log('computed route', (NOT_IN_DEVELOPMENT ? DEPLOYED_API_SUBDOMAIN + url : url))

  return axios
    .put(
      NOT_IN_DEVELOPMENT ? `${getDomainApi(apiType)}` + url : url,
      requestData,
      {
        ...options?.axiosOptions,
        ...useAuth,
        method: 'PUT',
      }
    )
    .catch(axiosErrorHandler);
};

export const axiosPatch = async (
  url: string,
  requestData: AxiosPatchRequestDataObjects,
  options?: CustomAxiosPatchOptions,
  apiType?: ApiType
): Promise<void | AxiosResponse<ValidAxiosResponses>> => {
  // Default to always use authentication. There are very few calls that are non-authenticated.
  const useAuth =
    options?.customOptions?.useAuth === false
      ? {}
      : {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
        };

  // console.log('computed route', (NOT_IN_DEVELOPMENT ? DEPLOYED_API_SUBDOMAIN + url : url))

  return axios
    .patch(
      NOT_IN_DEVELOPMENT ? `${getDomainApi(apiType)}` + url : url,
      requestData,
      {
        ...options?.axiosOptions,
        ...useAuth,
        method: 'PATCH',
      }
    )
    .catch(axiosErrorHandler);
};

export const axiosDelete = async (
  url: string,
  payload?: any,
  options?: CustomAxiosDeleteOptions,
  apiType?: ApiType
): Promise<void | AxiosResponse<ValidAxiosResponses>> => {
  // Default to always use authentication. There are very few calls that are non-authenticated.
  const useAuth =
    options?.customOptions?.useAuth === false
      ? {}
      : payload
      ? {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
          data: payload,
        }
      : {
          headers: {
            Authorization: getSessionAuthorizationHeaderValue(),
          },
        };

  return axios
    .delete(NOT_IN_DEVELOPMENT ? `${getDomainApi(apiType)}` + url : url, {
      ...options?.axiosOptions,
      ...useAuth,
      method: 'DELETE',
    })
    .catch(axiosErrorHandler);
};
