import { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { isSafari } from 'react-device-detect';

interface IProps {
  closeWindowShowImage(): void;
  url?: string;
}

const PopupShowImageWithHook: FC<IProps> = (props) => {
  const [containerShowImg] = useState(document.createElement('div'));
  let externalWindow: any = null;

  useEffect(
    () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      externalWindow = isSafari ? window.location.assign(
        //@ts-ignore
        props.url,
      ) : window.open(props.url, 'window2', 'width=1200,height=800');

      externalWindow.document.body.appendChild(containerShowImg);
      externalWindow.addEventListener('load', () => {
        externalWindow.document.title = 'VetHero CRM | Screenshots Review';
      });
      externalWindow.addEventListener('beforeunload', () => {
        props.closeWindowShowImage();
      }
      );
      return () => {
        externalWindow = null;
      }
    },
    // Only re-renders this component if the variable changes
    []
  );
  return ReactDOM.createPortal(props.children, containerShowImg);
};

export default PopupShowImageWithHook;
