const RESTRICTED_FORM_TEXT = {
  title:
    'You are editing a form that is set up to provide your practice management system with important data',
  closeText: 'Ok, got it.',
  text: 'Because this form is set up to collect data for your practice management system and VetHero database, some fields cannot be deleted or modified.',
};

const dateFormat = 'MM-dd-yy';

const actions = {
  clone: { action: 'clone', type: 'modal' },
  delete: { action: 'delete', type: 'modal' },
  edit: { action: 'edit', type: 'redirect' },
  move: { action: 'move', type: 'modal' },
  preview: { action: 'preview', type: 'redirect' },
  rename: { action: 'rename', type: 'modal' },
  share: { action: 'share', type: 'redirect' },
  submissions: { action: 'submissions', type: 'redirect' },
};

const BASIC_FIELDS = [
  'Contact Name',
  'Contact Gender',
  'Contact Phone Number',
  'Contact Email Address',
  'Contact Address',
  'Birthdate',
  'SSN',
  'Pet Name',
  'Pet Species & Breed',
  'Pet Sex',
  'Co-Owner Name',
  'Co-Owner Gender',
  'Co-Owner Phone Number',
  'Co-Owner Email Address',
  'Co-Owner Address',
];
const LIST_ITEM_URL_BASE = '/form-builder/forms/form/';
export {
  RESTRICTED_FORM_TEXT,
  dateFormat,
  actions,
  LIST_ITEM_URL_BASE,
  BASIC_FIELDS,
};
