/**
 * Import specific icons from `Font Awesome`.
 *
 * @description fal = light
 * @description far = regular
 * @description fas = solid
 * @description fad = doutone
 * @description fab = free
 *
 * @see https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons
 * @see https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons#packages
 */
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faInfoCircle,
  faCheck as fasFaCheck,
  faExclamationTriangle as fasFaExclamationTriangle,
  faSpinner,
  faSortDown,
  faSortUp,
  faComment as fasFaComment,
  faUpRightFromSquare,
  faQuestion,
  faPhone,
  faX,
} from '@fortawesome/free-solid-svg-icons';

/**
 * @description fal = light
 */
import {
  faFileInvoiceDollar,
  faHome,
  faBars,
  faCommentAltLines,
  faComment,
  faStar,
  faVideo as falVideo,
  faEllipsisH,
  faMailbox,
  faTimesCircle,
  faPlusCircle,
  faFileAlt,
  faTimes as falFaTimes,
} from '@fortawesome/pro-light-svg-icons';

/**
 * @description far = regular
 */
import {
  faClock,
  faBell,
  faCalendarCheck,
  faTimes,
  faSearch,
  // faEdit,
  faEllipsisV,
  faPaperPlane as faPaperPlaneRegular,
  faSyncAlt,
  faCog,
  faPlus,
  faCheck,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faChevronDoubleLeft as faChevronDoubleLeftRegular,
  faChevronDoubleRight as faChevronDoubleRightRegular,
  faMinus,
  faEllipsisVAlt,
  faTrashAlt,
  faEdit,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronRight,
  faListAlt,
  faCopy,
  faFile, 
  faFileWord, 
  faFilePdf,
  faImage as faImageRegular,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * @description (`default`) fas = solid
 */
import {
  faLock,
  faLockOpen,
  faVolume,
  faExternalLinkAlt,
  faExclamationCircle,
  faQuestionCircle,
  faChevronLeft,
  faChevronDown,
  faPaperPlane,
  faUser,
  faPaperclip,
  faVideo as fasVideo,
  faFlag,
  faEnvelopeOpen,
  faCommentAltLines as fasCommentAltLines,
  faArrowRight,
  faChevronUp,
  faCaretUp,
  faCaretDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faNotesMedical,
  faImagePolaroid,
  faFilmAlt,
  faCalendarAlt,
  faTh,
  faUserPlus,
  faArchive,
  faCheckCircle,
  faHospitalAlt,
  faUserCog,
  faCreditCard,
  faFolderPlus,
  faImage,
  faMobileAlt,
  faExclamationTriangle,
  faUpload,
  faMoneyCheckPen,
  faFileLines,
} from '@fortawesome/pro-solid-svg-icons';

/**
 * @description Register imported icons to the global library.
 *
 * @see https://github.com/FortAwesome/react-fontawesome#frequent-questions
 *
 * @returns Array of Icons.
 * @type {Array<IconDefinition>}
 */
export const RegisterIcons = async (): Promise<Array<IconDefinition>> => {
  const icons: Array<IconDefinition> = [
    faHome,
    faBars,
    faComment,
    faCommentAltLines,
    faStar,
    falVideo,
    faQuestionCircle,
    faUpRightFromSquare,
    faBell,
    faCalendarCheck,
    faChevronLeft,
    faChevronDown,
    faSearch,
    faTimes,
    faPaperPlaneRegular,
    faQuestion,
    faPaperPlane,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faSyncAlt,
    faUser,
    faMailbox,
    faCog,
    faPaperclip,
    faPlus,
    fasVideo,
    faCheck,
    faChevronLeftRegular,
    faChevronRightRegular,
    faChevronDoubleLeftRegular,
    faChevronDoubleRightRegular,
    faFlag,
    faEnvelopeOpen,
    fasCommentAltLines,
    faArrowRight,
    faChevronUp,
    faCaretUp,
    faCaretDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faNotesMedical,
    faMinus,
    faImagePolaroid,
    faFilmAlt,
    faCalendarAlt,
    faTh,
    faUserPlus,
    faEllipsisVAlt,
    faArchive,
    faTimesCircle,
    faTrashAlt,
    faCopy,
    faCheckCircle,
    faChevronRight,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faHospitalAlt,
    faUserCog,
    faCreditCard,
    faFolderPlus,
    faExternalLinkAlt,
    faFileAlt,
    faImage,
    faExclamationCircle,
    faFileInvoiceDollar,
    faVolume,
    faPlusCircle,
    faMobileAlt,
    faExclamationTriangle,
    faLock,
    faLockOpen,
    faClock,
    faInfoCircle,
    fasFaCheck,
    fasFaExclamationTriangle,
    faSpinner,
    falFaTimes,
    faSortDown,
    faSortUp,
    fasFaComment,
    faPhone,
    faListAlt,
    faUpload,
    faImage,
    faX,
    faFile,
    faFileWord,
    faFilePdf,
    faImageRegular,
    faFileLines,
    faMoneyCheckPen,
  ];

  await library.add(...icons);

  return icons;
};
