/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-image-lightbox/style.css';
import { useRouter } from 'hooks/useRouter';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import ReactPlayer from "react-player";
import '../../Global/PopupShowVideo/PopupShowVideo.scss';



interface listVideoURL {
  attachmentId: string;
  name: string;
  description: string;
  creationDate: string;
  attachmentKind: string;
  url: string;
  contentType: string;
}

const PopupShowVideo = () => {
  const router = useRouter();
  const [videoIndex] = useState<number>(() => {
    return parseInt(localStorage.getItem('indexOfOpenedVideo') || '0');
  });
  const [listVideoUrl, setListVideoUrl] = useState<string[]>([]);

  const getTelemedAppointmentAttachments = async (appointmentID: string) => {
    try {
      const res = await configuredRequests.GET.getRecording(appointmentID);
      const listVideo = res.map((items: listVideoURL) => items.url);
      setListVideoUrl(listVideo);
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    //@ts-ignore
    getTelemedAppointmentAttachments(router.query.appointmentID);
  }, []);

  return (
    <ReactPlayer // this would create a new instance when the source is changed and would solve the issue of mounting and unmouting when changing the hls ...
      url={listVideoUrl[videoIndex]}
      className="react-player"
      controls={true}
      playing={true}
      width="100%"
      height="100%"
      muted={true}
      playsinline={true}
    />
  );
};

export default PopupShowVideo;
