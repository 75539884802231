export enum AppointmentRequestStatusType {
  sentNoResponse = 'sentNoResponse',
  readyForBooking = 'readyForBooking',
}

type EmailStatusMap = { [key in AppointmentRequestStatusType]: string };

export const getStatusColors = ({
  yellow,
  green,
}: {
  yellow: string;
  green: string;
}): EmailStatusMap => ({
  [AppointmentRequestStatusType.sentNoResponse]: yellow,
  [AppointmentRequestStatusType.readyForBooking]: green,
});
