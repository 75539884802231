import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Optional } from './properties-config';
import { FormFieldBase } from './form-field-base';
import {
  getFieldProperties,
  getFieldPropertyValues,
  getComponent,
} from '.';
import { isExistsBasicField } from './helpers';
import { FormFieldInputRadio } from './form-field-inputs';
import ValidationMessage from './validation-message';

const style = {
  padding: '15px 10px',
  margin: '20px 0',
  border: '2px solid #d4dbe4',
  borderRadius: '5px',
};

const styleText = {
  fontSize: '13px',
};

const SectionColumn = ({
  field,
  className,
  commonProps,
  idxOption,
  value,
  readOnly,
  ...rest
}) => {
  // eslint-disable-line no-unused-vars
  const getValue = (uuid) => {
    const idxField = field.fields.findIndex((item) => item.uuid === uuid);
    return value[idxField];
  };
  const propertyNames = [
    'label',
    'makeRequired',
    'makeHidden',
    'hideLabel',
    'readOnly',
    'sublabel',
  ];
  const defaultValues = {
    label: field.display_name,
    makeRequired: false,
    makeHidden: false,
    hideLabel: false,
    readOnly: false,
    sublabel: '',
  };

  return (
    <div
      className={`col-container ${
        !field.fields ||
        field.fields.length === 0 ||
        field.fields.filter((item) => item.of_option === idxOption).length === 0
          ? 'hidden'
          : ''
      }`}
    >
      {field.fields
        .filter((item) => item.of_option === idxOption)
        .map((f, index) => {
          const properties = getFieldProperties(f, 'common');
          const commonPropValues = getFieldPropertyValues(
            properties.common,
            propertyNames,
            defaultValues
          );
          const classes = classNames({
            'form-field-input-container': true,
            'hide-field': commonPropValues.makeHidden,
            'hide-label': commonPropValues.hideLabel,
            'read-only': readOnly || commonPropValues.readOnly || commonPropValues.makeHidden,
          });
          const props = {
            key: f.uuid,
            field: f,
            isNested: true,
            parent: field,
            className: classes,
            commonProps: commonPropValues,
            value: getValue(f.uuid),
            ...rest,
            readOnly:
              readOnly ||
              commonPropValues.readOnly ||
              commonPropValues.makeHidden,
          };

          const component = getComponent(f, props);

          return (
            <div key={f.uuid} className='col'>
              {component}
            </div>
          );
        })}
    </div>
  );
};

class SubOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverCounter: 0,
    };
  }

  handleDragHover = (e, isOver) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.el) return;

    let { hoverCounter } = this.state;

    if (isOver) ++hoverCounter;
    else --hoverCounter;

    if (hoverCounter > 0) this.el.classList.add('over');
    else this.el.classList.remove('over');

    this.setState({ hoverCounter });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // make sure to remove hover css class
    if (this.el) this.el.classList.remove('over');

    let data = e.dataTransfer.getData('text/plain');

    try {
      // attempt to parse data as JSON
      data = JSON.parse(data);

      if (
        this.props.isDragNewItem &&
        isExistsBasicField(
          this.props.versionObject?.fields ?? [],
          data?.display_name ?? ''
        )
      ) {
        this.props.handleShowErrorDuplicate();
        return;
      }
    } catch (e) {
      // if JSON parsing fails, leave data as-is
    }

    const move = e.dataTransfer.getData('text/x-move');
    let source = e.dataTransfer.getData('text/x-source');

    source = source && JSON.parse(source);

    data.of_option = this.props.idxOption;
    data.uuid_option = this.props.field.uuid;

    this.props.onNestedDrop(this.props.field, data, move, source);
  };

  render() {
    const { field, index } = this.props;
    let properties;
    let propMap;
    let cols = 1;

    const isEmpty =
      field.fields.filter((item) => item.of_option === this.props.idxOption)
        .length === 0;

    // try {
    //   properties = getFieldProperties(field, 'specific');
    //   propMap = properties.specific;

    //   cols = propMap.get('cols').value;

    //   if (!cols || cols === '') cols = propMap.get('cols').defaultValue;
    // } catch (ex) {
    //   if (!cols || cols === '') cols = 1;
    // }

    return (
      <div
        className={`form-field-section col-${cols}`}
        style={style}
        ref={(el) => (this.el = el)}
        onDragEnter={(e) => this.handleDragHover(e, true)}
        onDragLeave={(e) => this.handleDragHover(e, false)}
        onDrop={(e) => this.handleDrop(e, this.props)}
      >
        {isEmpty && (
          <span
            style={styleText}
          >{`Drag fields for ${this.props.option}`}</span>
        )}

        <SectionColumn {...this.props} />
      </div>
    );
  }
}

export const FormFieldOptional = (props) => {
  const {
    field,
    className,
    commonProps,
    isRenderedField,
    onChange,
    value,
    onBlur,
    validationMessage,
    readOnly,
  } = props;
  const [selectedOption, setSelectedOption] = useState(-1);

  useEffect(() => {
    if (isRenderedField && field?.value) {
      setSelectedOption(options.findIndex((item) => item === field.value));
    }
  }, [field]);

  // import of 'utils' breaks unit tests so for now, just including
  // local version of check-for-empty logic
  const isEmpty = (val) => {
    const isObject = typeof val === 'object';
    const isArray = isObject && Array.isArray(val);
    let result = false;

    result =
      val === undefined ||
      val === null ||
      val === '' ||
      (isObject && Object.keys(val).length === 0) ||
      (isArray && val.length === 0);

    return result;
  };

  // if value isn't found in the list of options, then this is an "other" value
  const valueIsOther = (options, value) =>
    !options.find((option) => option === value) && !isEmpty(value);

  const handleChange = (e, group, label, index) => {
    const value = e.target.type === 'radio' ? label : e.target.value;

    // define custom event object
    const event = {
      target: {
        id: group,
        value,
        el: e.target,
      },
    };
    onBlur({
      target: {
        id: group,
        value,
      },
    });

    onChange(event);
    setSelectedOption(index);
  };

  const localProps = new Map(Optional);
  const propertyNames = ['options', 'displayAsColumns', 'numColumns'];
  const defaultValues = propertyNames.map((propName) => ({
    [`${propName}`]: localProps.get(propName).defaultValue,
  }));
  const properties = getFieldProperties(field, 'specific');
  const { options, displayAsColumns, numColumns } = getFieldPropertyValues(
    properties.specific,
    propertyNames,
    defaultValues
  );

  const classes = classNames({
    [className]: true,
    optional: true,
    'control-group error': validationMessage,
  });

  // if this field is being rendered to a published form,
  // we need to add a group prop to the checkboxes to tie
  // back to the parent field
  const group = isRenderedField ? field.uuid : undefined;
  const isOther = valueIsOther(options, value[0]);
  const otherValue = {};
  if (isOther) otherValue.value = value;

  return (
    <div className={classes}>
      <div className='form-field-label primary'>{`${commonProps.label} ${
        commonProps.makeRequired ? '*' : ''
      }`}</div>
      <div className='form-field-input radio'>
        {options.map((option, index) => {
          // render radio input for each option
          return (
            <FormFieldInputRadio
              key={index}
              label={option}
              displayAsColumns={displayAsColumns}
              numColumns={numColumns}
              group={group}
              isRenderedField={isRenderedField}
              onChange={handleChange}
              checked={field?.value === option}
              disabled={readOnly}
              index={index}
            />
          );
        })}

        <ValidationMessage message={validationMessage} />
      </div>
      <div>
        {isRenderedField ? (
          selectedOption > -1 &&
          field.fields.filter((item) => item.of_option === selectedOption)
            .length > 0 && (
            <SectionColumn {...props} idxOption={selectedOption} />
          )
        ) : (
          <>
            {options.map((option, index) => {
              return <SubOption {...props} idxOption={index} option={option} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

FormFieldOptional.defaultProps = {
  className: '',
  commonProps: {},
  isRenderedField: false,
  onChange: () => {},
  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldOptional);
