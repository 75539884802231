import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBlurCustomDropdown } from 'hooks/useBlurCustomDropdown';
import useClickOutSide from 'hooks/useClickOutSide';

interface TextChevronDropdownProps {
  currentSelection: string,
  selectionOptions: Array<{
    value: string,
    label: string
  }>,
  handleChangeSelection: (newValue: string) => void,
  useBlackText?: boolean,
  useBoldText?: boolean,
  className?: string,
  styleOptions?: {
    width?: number
  }
  useLabel?: {
    labelText: string,
    customClass?: string
  }
}

const capitzalizeIfLabelIsWord = (label: string) => {
  if (!label.length) {
    return ""
  }
  const startsWithNumber = !(Number.isNaN(parseInt(label[0])));
  if (startsWithNumber) {
    return label
  }
  return ((label[0].toUpperCase()) + label.slice(1));
}

const TextChevronDropdown = (props: TextChevronDropdownProps) => {

  const [isDropdownOpened, setIsDropdownOpened] = React.useState<boolean>();
  const clickOutSideRef = useRef();
  useClickOutSide(clickOutSideRef, () => setIsDropdownOpened(false));

  const handleNewSelectedFilterValue = (eventTarget: EventTarget) => {
    const targetValue = (eventTarget as HTMLElement).getAttribute("data-value");
    // console.log('setting new value', targetValue)
    if (targetValue) {
      props.handleChangeSelection(targetValue.toString());
      setIsDropdownOpened(false);
      return;
    }
    setIsDropdownOpened(!isDropdownOpened);
  };

  const toggleDropdownOpenedState = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  useBlurCustomDropdown(
    ["text-chevron-dropdown--container", "text-chevron-dropdown--li"],
    () => {
      setIsDropdownOpened(false);
    }
  );

  return (
    <>
      {props.useLabel
        ? (
          <label
            className={"text-chevron-dropdown--label"}
            htmlFor={"text-chevron-dropdown-label--" + props.currentSelection.split(" ").join("-")}
          >
            {props.useLabel.labelText}
          </label>
        )
        : null
      }
      <div
        className={
          "text-chevron-dropdown--container "
          + (props.className?.length ? props.className : "")
          + (" text-chevron-dropdown-label--" + props.currentSelection.split(" ").join("-"))
          + (props.useLabel ? " margin-top-label" : "")
        }

        style={props.styleOptions}
        tabIndex={0}
        onClick={toggleDropdownOpenedState}
        onKeyDown={(event: React.KeyboardEvent) => {
          switch (event.key) {
            case " ":
            case "Enter": {
              toggleDropdownOpenedState();
              break;
            }
          }
        }}
        //@ts-ignore
        ref={clickOutSideRef}
      >
        <span className="text-chevron-dropdown--list-container">

          <div className={
            "text-chevron-dropdown--current-selected-text"
            + (props.useBlackText ? " color-black" : "")
            + (props.useBoldText ? " bold-text" : "")
          }>
            {props.currentSelection}
          </div>
          <ul
            className={"text-chevron-dropdown--ul" + (isDropdownOpened ? " open" : "")}
          >
            {props.selectionOptions.map((dropdownEntry, index) => {
              return (
                <li
                  tabIndex={0}
                  className="text-chevron-dropdown--li"
                  key={"text-chevron-dropdown--li" + index}
                  value={dropdownEntry.value}
                  data-value={dropdownEntry.value}
                  onClick={(event: React.SyntheticEvent<HTMLLIElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleNewSelectedFilterValue(event.target)
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    switch (event.key) {
                      case " ":
                      case "Enter": {
                        handleNewSelectedFilterValue(event.target)
                        break;
                      }
                    }
                  }}
                >
                  {capitzalizeIfLabelIsWord(dropdownEntry.label)}
                </li>
              )
            })}
          </ul>

        </span>
        <div className="text-chevron-dropdown--chevron-container">
          <FontAwesomeIcon icon={["fas", "chevron-down"]} />
        </div>
      </div>
    </>
  )
};

export default TextChevronDropdown;
