import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  flex-direction: ${(props: { direction: string }): string => props.direction};
  align-items: ${({ direction }): string => (direction === 'row' ? 'baseline' : 'center')};
  justify-content: ${({ direction }): string => (direction === 'row' ? 'flex-start' : 'center')};
`;

// eslint-disable-next-line import/prefer-default-export
export { MainContainer };
