/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import {
  getTabActiveLocalStorage,
  setTabActiveLocalStorage,
} from 'global/localStorage/tabActive';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { TemplateResponse } from 'global/requests/ResponseTypes';
import PropTypes from 'prop-types';
import TemplateCommon from './TemplateCommon';
import loading_spinner from 'media/images/loading-spinner.svg';
import LoadingPs from 'components/Global/Loading';
import { useDecodeToken } from 'hooks/useDecodeToken';
import { string } from 'yup';
import { any } from 'lodash/fp';

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

function TabContainer(props: TabContainerProps) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

interface SimpleTabsProps {
  templateLayout: any;
}

export default function SimpleTabs(props: SimpleTabsProps) {
  const templateDefaultDate = {
    offset: 0,
    count: 0,
    totalAvailable: 0,
    items: [],
  };

  const [templateData, setTemplateData] =
    React.useState<TemplateResponse>(templateDefaultDate);
  const { templateLayout } = props;
  const { allowedPath } = useDecodeToken();
  let allowedTab : {name:string, value: string}[] = [];

  if (!allowedPath.includes('/Messenger')){
    templateLayout.templateKinds.forEach((item: {name:string, value: string})=>{
      // if (item.name !== 'SMS'){
        allowedTab.push({...item})
      // }
    })
  } else {
    allowedTab = [...templateLayout.templateKinds]
  }

  // console.log('templateLayout.templateKinds', templateLayout.templateKinds);
  // console.log('allowedPath', allowedPath);

  let tabActive =
    (getTabActiveLocalStorage() &&
      getTabActiveLocalStorage().rightMenuContentActive) ||
    0;

  const [indexTab, setIndexTab] = React.useState<number>(tabActive);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    if (indexTab == newValue) {
      return;
    }
    setIsLoading(true);
    setTabActiveLocalStorage(null, null, newValue);
    setIndexTab(newValue);
  }

  const getTemplateData = async () => {
    setIsLoading(true);
    try {
      const response = await configuredRequests.GET.templateByKind(
        //@ts-ignore
        [allowedTab[tabActive].value],
        [templateLayout.categoryKind]
      );
      setTemplateData(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tabActive !== indexTab) {
      setIndexTab(tabActive);
    }
  }, [tabActive]);

  useEffect(() => {
    getTemplateData();
  }, [indexTab, templateLayout, tabActive]);

  return (
    <div className='template-appointment-email'>
      <AppBar position='static' className='container__table'>
        <Tabs
          value={tabActive}
          onChange={handleChange}
          indicatorColor='primary'
        >
          {templateLayout &&
            allowedTab.map(
              (template: { name: string; value: string }) => {
                // console.log('allowedPath ne', allowedPath)
                // console.log('template ne', template.name)
                return <Tab key={template.value} label={template.name} />;
              }
            )}
        </Tabs>
      </AppBar>

      <TabContainer id={1}>
        {isLoading ? (
          <LoadingPs />
        ) : (
          <TemplateCommon
            dataTable={templateData.items?.map((template: any) => {
              return {
                name: template.name,
                id: template.templateId,
              };
            })}
            templateKinds={allowedTab[tabActive].value}
            isLoading={isLoading}
            categoryKind={templateLayout.categoryKind}
            reloadData={getTemplateData}
          />
        )}
      </TabContainer>
    </div>
  );
}
