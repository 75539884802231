// This component is to delegate access right to private routes in PermissionSpecificRouter.tsx

import { useDecodeToken } from 'hooks/useDecodeToken';
import React from 'react';
import { Route } from 'react-router-dom';

interface IAuthorization {
  path: string;
  component: React.FunctionComponent<Record<string, unknown>>;
  exact?: boolean;
  key: string;
}

const AuthorizationRouter = ({
  path,
  component: Component,
  exact,
  key,
}: IAuthorization) => {
  const { allowedPath } = useDecodeToken();
  let isHasPermissionToAccessPage = allowedPath.includes(path);
  if (path.includes('/:') || path.split('/').length > 2) {
    const firstPath = path.split('/')[1];
    isHasPermissionToAccessPage = allowedPath.includes(`/${firstPath}`);
  }

  return (
    <Route
      path={path}
      exact={exact}
      key={key}
      render={() =>
        isHasPermissionToAccessPage ? (
          <Component />
        ) : (
          <div>You don't have permission to access this site</div>
        )
      }
    />
  );
};

export default AuthorizationRouter;
