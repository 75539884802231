import styled from 'styled-components';
import { Button } from '@material-ui/core';
import getThemeProp from 'components/Legwork/utils/getThemeProp';

const StyledButton = styled(Button)`
  padding: 4px 8px;
  border: none !important;
  border-radius: 6px !important;
  height: 36px;
  background: ${getThemeProp('palette.primary.main', '#4C7CFD')} !important;
  color: white !important;
  text-transform: none !important;
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};
  font-family: ${getThemeProp('typography.fontFamily', 'Arial, sans-serif')};
  box-shadow: 0 1px 3px 0 #9ebcf5 !important;
  svg {
    margin-right: 5px;
    height: 100%;
    width: 1rem;
  }
  &:hover {
    background: ${getThemeProp('palette.primary.dark', '#4C7CFD')};
  }
  &&.Mui-disabled {
    color: white;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { StyledButton };
