import { UserDataResponse } from 'global/requests/ResponseTypes/UserAdministration';
import { PetSummaryCollectionResponse, PetInfoResponse } from 'global/requests/ResponseTypes/Pets';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import EstimatesFormModal from './EstimatesFormModal'
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterGlobal from 'components/Global/Filter/Filter';
import loading_spinner from 'media/images/loading-spinner.svg';
import moment from 'moment';

interface EstimatesContainerProps {
  userInfo: UserDataResponse;
  petsInfo: PetSummaryCollectionResponse | undefined;
}

type OptionDropDown = {
  value: string;
  label: string;
};

const EstimatesContainer = (props: EstimatesContainerProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<string>("{}");
  const [lastPulled, setLastPulled] = React.useState<string[]>([]);
  // const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedPet, setSelectedPet] = useState({} as OptionDropDown);//useState<PetInfoResponse>({} as PetInfoResponse);
  const [optionsPet, setOptionsPet] = useState<OptionDropDown[]>([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState({});
  const requestCountRef = useRef(0);

  const getClientEstimates = async () => {
    setIsLoading(true);
    const newRequest = await configuredRequests.POST.addEstimatesRequest({
      tenantId: props.userInfo.tenant ?? "",
      contactId: props.userInfo.userId ?? "",
      externalContactId: props.userInfo.externalId ?? "",
      petId: selectedPet.value ?? "",
      externalPetId: props.petsInfo?.pets.filter((p: PetInfoResponse) => p.petId == selectedPet.value)[0].externalId ?? ""
    });
    repeatGetEstimatesResponse();
  }

  const repeatGetEstimatesResponse = async () => {
    requestCountRef.current = 0;
    const loopGetEstimatesResponse = setInterval(async () => {
      if (requestCountRef.current > 30) {
        clearInterval(loopGetEstimatesResponse);
        setIsLoading(false);
      } 
      requestCountRef.current = requestCountRef.current + 1;
      const results = await configuredRequests.GET.getEstimatesResponse(props.userInfo.tenant ?? "", { contactId: props.userInfo.userId ?? "", petId: selectedPet.value ?? ""});
      if (results != null && lastPulled.length > 0 && lastPulled[0] != results.responseId) {
        setResults(results.results);
        setLastPulled([results.responseId, results.createdTime]);
        setIsLoading(false);
        clearInterval(loopGetEstimatesResponse);
      }
    }, 1000);

    return () => clearInterval(loopGetEstimatesResponse);
  }

  const getEstimatesResponse = async () => {
    const results = await configuredRequests.GET.getEstimatesResponse(props.userInfo.tenant ?? "", { contactId: props.userInfo.userId ?? "", petId: selectedPet.value ?? "" });
    if (results != null) {
      setResults(results.results);
      setLastPulled([results.responseId, results.createdTime]);
      setIsLoading(false);
    }
  };

  const handleSelectPet = (newPetSelect: OptionDropDown) => {
    setSelectedPet(newPetSelect);
  };

  const handleOpenModal = async (estimate: any) => {
    setSelectedEstimate(estimate);
    setOpenFormModal(true);
  }

  useEffect(() => {
    // @ts-ignore
    props.petsInfo && setOptionsPet(props.petsInfo?.pets.filter((p: PetInfoResponse) => p.status == "active").sort((p1: PetInfoResponse, p2: PetInfoResponse) => (p1.name < p2.name) ? -1 : (p1.name > p2.name) ? 1 : 0).map((p: PetInfoResponse) => { return { value: p.petId, label: p.name } })!)
  }, []);

  useEffect(() => {
    if(selectedPet.label && selectedPet.label != '') {
      setIsLoading(true);
      getEstimatesResponse();
    }
  }, [selectedPet]);

  useEffect(() => {
    props.petsInfo && setOptionsPet(props.petsInfo?.pets.filter((p: PetInfoResponse) => p.status == "active").sort((p1: PetInfoResponse, p2: PetInfoResponse) => (p1.name < p2.name) ? -1 : (p1.name > p2.name) ? 1 : 0).map((p: PetInfoResponse) => { return { value: p.petId, label: p.name } })!)
  }, [props.petsInfo])

  const renderEstimateData = () => {
    let resultsJson = JSON.parse(results);
    if (requestCountRef.current > 30) {
      return (<span className="estimates-not-found">Error pulling new estimates. Please make sure your syncer is running and contact your VetHero support rep</span>);
    } else if (lastPulled[1] == "01/01/0001 00:00:00") { 
      return (<span className="estimates-not-found">No estimates have been pulled yet for {selectedPet.label}</span>) ;
    } else if (Object.keys(resultsJson).length == 0 && selectedPet.label && selectedPet.label != '') { 
      return (<span className="estimates-not-found">No estimates found for {selectedPet.label}</span>);
    } else if (Object.keys(resultsJson).length == 0) return;
    
    var createdOrDue = true;
    // @ts-ignore
    if (Object.values(resultsJson)[0].createdTime == '0001-01-01T00:00:00') {
      createdOrDue = false;
    }
    
    return (<table className='estimates-table'>
      <tbody>
        <tr className='estimates--table-row-container'>
          <td className='estimates--table-row-division estimates--table-title'>Title</td>
          <td className='estimates--table-row-division estimates--table-createdat'>{createdOrDue ? "Created At" : "Estimate Date"}</td>
          <td className='estimates--table-row-division estimates--table-total'>Total</td>
          <td className='estimates--table-row-division estimates--table-button'></td>
        </tr>

        {Object.values(resultsJson).sort((obj1: any, obj2: any) => createdOrDue ? (new Date(obj2.createdTime).getTime() - new Date(obj1.createdTime).getTime()) : (new Date(obj2.dueDate).getTime() - new Date(obj1.dueDate).getTime())).map((obj: any) =>
          <tr className='estimates--table-row-container'>
            <td className='estimates--table-row-division estimates--table-title'>{obj.description}</td>
            <td className='estimates--table-row-division estimates--table-createdat'>{moment(createdOrDue ? obj.createdTime : obj.dueDate, '').format(createdOrDue ? "MM/DD/YYYY h:mm a" : "MM/DD/YYYY")}</td>
            <td className='estimates--table-row-division estimates--table-total'>${obj.estimateItems.map((e: any) => e.highPrice).reduce((e1: any, e2: any) => e1 + e2, 0).toFixed(2)}</td>
            <td className='estimates--table-row-division estimates--table-button'>
              <Button
                className='btn-estimates-preview-send'
                onClick={() => handleOpenModal(obj)}
              >
                Preview and send
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>)
  }

  return (
    <div className='estimates-container'>
      {
        isLoading ? 
        <div className="estimates-loading-container">
          <span>Your estimates are loading... this may take a while</span>
          <img src={loading_spinner} alt={"Loading"} /> 
        </div> :
        <>
            <FilterGlobal
              handleSetNewFilterValue={handleSelectPet}
              optionsForFilter={optionsPet}
              valueSelected={selectedPet}
              placeholder={'Select a pet'}
              classCustom="estimates-dropdown-filter"
            />
          {renderEstimateData()}
          {selectedPet.label && selectedPet.label != '' ?
            <div className='estimates-pull-container'>
              <Button
                variant='contained'
                color='primary'
                className='btn-get-estimates'
                disabled={!selectedPet.label || selectedPet.label == ""}
                onClick={getClientEstimates}
              >
                <FontAwesomeIcon
                  icon={faSync}
                  className='sync-icon'
                  style={{"marginRight": "10px"}}
                />
                Pull Estimates
              </Button>
              {lastPulled.length > 1 && lastPulled[1] != "01/01/0001 00:00:00" ? 
              <span className='estimates-last-pulled'>Estimates last pulled {moment.utc(lastPulled[1]).local().format("MM/DD/YYYY h:mm a")}</span> : ""}
            </div>
            : ""
          }
        </>
      }
      <EstimatesFormModal
        isOpenModal={openFormModal}
        handleCloseModal={() => setOpenFormModal(false)}
        userInfo={props.userInfo}
        estimate={selectedEstimate}
        petInfo={props.petsInfo?.pets.filter((p: PetInfoResponse) => p.petId == selectedPet.value)[0]}
      />
    </div>
  );
};

export default EstimatesContainer;
