import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './styles';

const FormItemStatus = ({ formVersion, isDirty }) => {
  const { number, is_published, updated_at } = formVersion;
  const version = `Version: ${number || '(new)'}`;
  let status;

  if (isDirty) status = `Unsaved edits`;
  else if (!number) status = 'New';
  else if (is_published) status = `Published ${moment(updated_at).format('L')}`;
  else status = `Saved, not published`;

  return (
    <div style={styles.main}>
      <p style={styles.p}>{status}</p>
      <p style={styles.p}>{version}</p>
    </div>
  );
};

FormItemStatus.propTypes = {
  formVersion: PropTypes.object.isRequired,
  isDirty: PropTypes.bool,
};

FormItemStatus.defaultProps = {
  isDirty: false,
};

export default FormItemStatus;
