import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Emitter from './Emitter/Emitter';
import GeneralSyncer from './GeneralSyncer/GeneralSyncer';
import RpcCalls from './RpcCalls/RpcCalls';
import ServiceLogs from './ServiceLogs/ServiceLogs';
import SettingPracticeSyncer from './SettingPracticeSyncer/SettingPracticeSyncer';

interface TabContainerProps {
  id: number;
  children?: React.ReactNode;
}

function TabContainer(props: TabContainerProps) {
  return <Typography component='div'>{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function PracticeSyncer() {
  const [value, setValue] = React.useState(0);
  const [showConfigurationTab, setShowConfigurationTab] =
    React.useState<boolean>(false);

  useEffect(() => {
    getDriverInfo();
  }, []);

  const getDriverInfo = async () => {
    try {
      const res = await configuredRequests.GET.getDriverConfigurationSetting();
      if (res) {
        // @ts-ignore
        const configurationFields = JSON.parse(res.configurationField ?? '[]');
        if (
          configurationFields?.length > 0 &&
          configurationFields?.filter(
            (field: any) => !!field[Object.keys(field)?.[2]]
          )?.length > 0
        ) {
          setShowConfigurationTab(true);
        }
      }
    } catch (err) {
      // setIsLoading(false);
      console.log('err', err);
    }
  };

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  return (
    <div className='w-300 template-appointment-email container-practice'>
      <AppBar position='static' className='container__table'>
        <Tabs value={value} onChange={handleChange} indicatorColor='primary'>
          <Tab label='General' />
          <Tab label='API Calls' />
          <Tab label='Service Logs' />
          <Tab label='Emitter' />
          <Tab label='Settings' />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer id={1}>
          <GeneralSyncer />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer id={2}>
          <RpcCalls />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer id={3}>
          <ServiceLogs />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer id={4}>
          <Emitter />
        </TabContainer>
      )}

      {value === 4 && (
        <TabContainer id={5}>
          <SettingPracticeSyncer
            showConfigurationTab={showConfigurationTab}
            setShowConfigurationTab={setShowConfigurationTab}
          />
        </TabContainer>
      )}
    </div>
  );
}
