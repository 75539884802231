import HighCharts from 'highcharts';

const generateLegend = (): HighCharts.LegendOptions => ({
  align: 'left',
  verticalAlign: 'middle',
  layout: 'vertical',
  itemMarginBottom: 10,
  itemStyle: {
    font: '15px "TT Commons", Arial, sans-serif',
  },
});

// eslint-disable-next-line import/prefer-default-export
export { generateLegend };
