import React from "react";

type InputWithLabelProps = {
  htmlFor: string;
  type: string;
  classNames?: {
    section?: string;
    label?: string;
    input?: string;
  };
  labelText: string;
  value?: string;
  children?: JSX.Element[];
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
};

const InputWithLabel = (props: InputWithLabelProps) => {
  return (
    <section
      className={props.classNames?.section}
      key={"contact__section-" + props.htmlFor}
    >
      <label
        className={props.classNames?.label}
        htmlFor={props.htmlFor}
        key={"contact__label-" + props.htmlFor}
      >
        {props.labelText}
        {props.isRequired && <b className="require"> *</b>}
      </label>

      <input
        className={props.classNames?.input}
        type={props.type}
        name={props.htmlFor}
        value={props.value}
        key={"contact__input-" + props.htmlFor}
        ref={props.inputRef}
        maxLength={props.maxLength ? props.maxLength : undefined}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />

      {props.children ? props.children : <></>}
    </section>
  );
};

export default InputWithLabel;
