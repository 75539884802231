import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoopIcon from '@material-ui/icons/Loop';
import { InputWithTopLabel } from 'components/Legwork';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ErrorMessage } from 'containers/PaperlessForms/FormBuilder/styled-components';
// import { ErrorMessage } from ''

const InputWrapped = styled.div`
  input {
    background-color: unset !important;
  }
`;
export default class FolderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folderName: '',
      isSaving: false,
      saveError: null,
      errors: {},
      disabledSubmit: false,
    };

    this.removeErrors = this.removeErrors.bind(this);
  }

  componentDidMount() {
    const { folder } = this.props;
    let folderName = '';

    if (folder) folderName = folder.folder_name;

    this.setState({
      folderName,
    });
  }

  setCurrentName = (e) => {
    // this.setState({
    //   folderName: e,
    // });
    this.setState((prevState) => ({
      ...prevState,
      folderName: e,
      errors: !e ? { folderName: 'This field is required.' } : {},
    }));
  };

  handleSaveClick = async (e) => {
    e.preventDefault();
    if (!this.state.folderName) {
      this.setState({ errors: { folderName: 'This field is required.' } });
    } else {
      this.setState({
        disabledSubmit: true,
      });
      await this.props.onSave(this.props.folder, this.state.folderName.trim());
      this.setState({
        errors: {},
        folderName: '',
        isSaving: true,
        disabledSubmit: false,
      });
    }
  };

  removeErrors() {
    const newErrors = { ...this.state.errors };
    delete newErrors.folderName;

    this.setState({ errors: newErrors });
  }

  handleCloseModal = () => {
    this.setState({ errors: {}, folderName: '' });
    this.props.closeModal();
  };

  render() {
    const {
      action,
      openModal,
      closeModal,
      isAddFolder,
      error,
      setErrorResponse,
    } = this.props;

    return (
      <Dialog
        open={openModal}
        onClose={this.handleCloseModal}
        aria-labelledby='delete-modal'
        aria-describedby='delete-form-item-modal'
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xl'
        id='move-form-modal'
      >
        {isAddFolder ? (
          <DialogContentText id='move-modal'>
            <span className='title-move-modal'>Add a Folder</span>
            <IconButton
              aria-label='close'
              className='close-modal'
              onClick={this.handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogContentText>
        ) : (
          <DialogContentText id='move-modal'>
            <h3 className='modal-title'>{action} Folder</h3>
          </DialogContentText>
        )}

        <DialogContent>
          <InputWrapped>
            <InputWithTopLabel
              required={true}
              title='Folder Name'
              value={this.state.folderName}
              reporter={this.setCurrentName}
              removeErrors={this.removeErrors}
              fullWidth
              error={false}
              type='text'
              maxLength={100}
              focus
              setErrorResponse={setErrorResponse}
            />
          </InputWrapped>
          {this.state.errors.folderName && (
            <ErrorMessage>{this.state.errors.folderName}</ErrorMessage>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <br />
        </DialogContent>
        <DialogActions
          className='footer-move-modal'
          style={
            isAddFolder
              ? { justifyContent: 'space-between', padding: '8px 24px' }
              : {}
          }
        >
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button
            onClick={this.handleSaveClick}
            disabled={
              !this.state.folderName.trim() ||
              this.state.errors.folderName ||
              this.state.disabledSubmit
            }
            style={
              (!this.state.folderName.trim() ||
                this.state.errors.folderName) && {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                boxShadow: 'none',
              }
            }
          >
            {/* {this.state.isSaving && <LoopIcon />}  */}
            {action}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FolderModal.propTypes = {
  action: PropTypes.oneOf(['Create', 'Rename']),
  folder: PropTypes.shape({
    id: PropTypes.number,
    folder_name: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

FolderModal.defaultProps = {
  action: 'Create',
  folder: null,
};
