import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {
  InputWithTopLabel,
  SelectWithTopLabel,
} from '../../../../../components/Legwork';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

declare type IdObject = {
  [key: string]: any;
} & {
  id: string | number;
};
interface IMenuItem {
  value: string | IdObject;
  display: string;
  disabled: boolean | undefined;
}

export interface CloneFormModalProps {
  open: boolean;
  form: any;
  handleClose: () => void;
  updateForms: () => void;
  folders: any[];
  folder: any;
}

const CloneFormModal = (
  props: CloneFormModalProps
): React.ReactElement | null => {
  const { open, form, handleClose, updateForms, folder, folders = [] } = props;
  const [currentName, setCurrentName] = useState<string>('');
  const [currentFolder, setCurrentFolder] = useState<string>(folder.id);
  const [errorResponse, setErrorResponse] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleCloneForm = async (): Promise<void> => {
    const payload = {
      isClone: true,
      form_name: currentName,
      form_folder_id: currentFolder,
      formId: form.id,
    };

    try {
      setDisabledSubmit(true);
      const res = await configuredRequests.POST.cloneForm(payload);
      if (res) {
        handleClose();
        // toast.success(
        //   <div className="notify-success d-flex">
        //     <FontAwesomeIcon icon={["fas", "check-circle"]} className="icon-check-status" />
        //     <div className="success-message">
        //       <b>Successfully</b>
        //       <p>Your data has been saved successfully!</p>
        //     </div>
        //   </div>,
        //   { className: 'toast-success' }
        // );
        await updateForms();
      }
    } catch (err) {
      setDisabledSubmit(false);
      if (get(err, 'response.data.message', '')) {
        setErrorResponse(get(err, 'response.data.message', ''));
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-modal'
      aria-describedby='delete-form-item-modal'
      disableBackdropClick
      disableEscapeKeyDown
      id='clone-form-modal'
    >
      <DialogContentText id='clone-modal'>
        <span className='title-clone-modal'>Clone Form</span>
        <IconButton
          aria-label='close'
          className='close-modal'
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogContentText>
      <DialogContent>
        <InputWithTopLabel
          title='Current Form Name'
          value={form?.form_name ?? ''}
          reporter={() => {}}
          fullWidth
          error={false}
          type='text'
          disabled
        />
        <InputWithTopLabel
          title='New Form Name'
          value={currentName}
          reporter={setCurrentName}
          fullWidth
          error={false}
          type='text'
          required={true}
          setErrorResponse={setErrorResponse}
        />
        {errorResponse && <p className='error-msg'>{errorResponse}</p>}
        <br />
        <SelectWithTopLabel
          reporter={(a: any): void => {
            setCurrentFolder(a);
          }}
          defaultValue={String(folder.id)}
          menuItems={folders.map(
            (f: IdObject): IMenuItem => ({
              value: String(f.id),
              display: f.folder_name,
              disabled: false,
            })
          )}
          title='Clone to Folder'
        />
        <br />
      </DialogContent>
      <DialogActions className='footer-clone-modal'>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={currentName === '' || disabledSubmit || !!errorResponse}
          onClick={handleCloneForm}
          color='primary'
          autoFocus
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneFormModal;
