// This shows loading icon, error and redirect message when 'log in to Practice' in SystemAdmin -> Group -> Practices -> Login to practice
// This component is imported by containers/AdminLoginPractice/AdminLoginPracticeV.tsx

import React from 'react';
import loading_spinner from 'media/images/loading-spinner.svg'
const AdminLoginPractice = (props: { loginError: string }): JSX.Element => {

  return (
    <div style={{ margin: '0 auto', width: '300px', top: '40%', textAlign: 'center' }}>

      {props.loginError
        ? <p>{props.loginError}</p>
        : <>
          <img src={loading_spinner} alt={"Loading"} />
          <p>Redirecting to the Practice page</p>
        </>
      }
    </div>
  );
};

export default AdminLoginPractice;
