import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { Formik, ErrorMessage, FastField } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { linkHttp } from 'utils/regex';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import loading_spinner from 'media/images/loading-spinner.svg';
import Filter from '../Filter/Filter';

type PaySettingDataType = {
  id: string;
  key: string;
  hostUrl: string;
  enviromentId: string;
  webhookId: string;
  webhookKey: string;
  accountBillingMode: string;
};

interface DropDownOption {
  label: string;
  value: string;
}

const options = [
  { label: 'Per Patient', value: 'PerPatient' },
  { label: 'Per Account', value: 'PerAccount' },
];

const Pay = (): JSX.Element => {
  const [paySettingData, setPaySettingData] = useState<PaySettingDataType>();
  const [isLoading, setIsLoading] = useState(true);
  const [optionBillingModes, setOptionBillingModes] =
    useState<DropDownOption[]>(options);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const ErrorMessageTypes = {
    required: 'This field is required.',
    incorrectUrlFormat:
      'Not a valid URL format, protocol required (http://, https://, www.)',
  };

  const initialValues = {
    id: paySettingData ? paySettingData.id : '',
    key: paySettingData ? paySettingData.key : '',
    hostUrl: paySettingData ? paySettingData.hostUrl : '',
    enviromentId: paySettingData ? paySettingData.enviromentId : '',
    webhookId: paySettingData ? paySettingData.webhookId : '',
    webhookKey: paySettingData ? paySettingData.webhookKey : '',
    accountBillingMode: paySettingData?.accountBillingMode
      ? paySettingData.accountBillingMode
      : 'PerPatient',
  };

  const UserValidationSchema = Yup.object().shape({
    id: Yup.string().required(ErrorMessageTypes.required),
    key: Yup.string().required(ErrorMessageTypes.required),
    webhookId: Yup.string().required(ErrorMessageTypes.required),
    webhookKey: Yup.string().required(ErrorMessageTypes.required),
    enviromentId: Yup.string().required(ErrorMessageTypes.required),
    hostUrl: Yup.string()
      .matches(linkHttp, ErrorMessageTypes.incorrectUrlFormat)
      .required(ErrorMessageTypes.required),
  });

  const getListModeBilling = async () => {
    try {
      const res = await configuredRequests.GET.getBillingModes();
      // @ts-ignore
      if (res?.result?.accountBillingModes) {
        // @ts-ignore
        const options = res.result.accountBillingModes.map((item: any) => ({
          label: item.value,
          value: item.name,
        }));
        setOptionBillingModes(options);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDataPaySetting = async () => {
    try {
      const res = await configuredRequests.GET.getDataPaySetting();
      if (res) {
        // @ts-ignore
        setPaySettingData(res.settings);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    Promise.all([getDataPaySetting(), getListModeBilling()])
      .then((res) => setIsLoading(false))
      .catch((err) => setIsLoading(false));
  }, []);

  const submitData = async (values: any) => {
    try {
      setDisabledSubmit(true);
      const submitData = {
        id: values.id,
        key: values.key,
        hostUrl: values.hostUrl,
        enviromentId: values.enviromentId,
        webhookId: values.webhookId,
        webhookKey: values.webhookKey,
        accountBillingMode: values.accountBillingMode,
      };

      const res = await configuredRequests.PUT.updateDataPaySetting(submitData);
      if (res) {
        setDisabledSubmit(false);
        toast.success(
          <div className='notify-success d-flex'>
            <FontAwesomeIcon
              icon={['fas', 'check-circle']}
              className='icon-check-status'
            />
            <div className='success-message'>
              <b>Successfully</b>
              <p>Your data has been saved successfully!</p>
            </div>
          </div>,
          { className: 'toast-success' }
        );
      }
    } catch (err) {
      setDisabledSubmit(false);
      toast.error(
        <div className='notify-unsuccess d-flex'>
          <FontAwesomeIcon
            icon={['fal', 'times-circle']}
            className='icon-check-status'
          />
          <div className='unsuccess-message'>
            <b>Error</b>
            <p>Your data has not been saved yet. Please try again.</p>
          </div>
        </div>,
        { className: 'toast-unsuccess' }
      );
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={UserValidationSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        submitData(values);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, handleBlur, setFieldValue, values } = formikProps;
        return (
          <div className='w-300'>
            {isLoading ? (
              <img
                className='loading-appointment-info'
                src={loading_spinner}
                alt={'Loading'}
              />
            ) : (
              <div className='react-modal--content'>
                <div className='communication-container'>
                  <div className='communication-container__table'>
                    <div className='edit-appointment roleModal'>
                      <Fragment>
                        <form
                          onSubmit={handleSubmit}
                          className='col-12 form-schedule react-modal--settings__content'
                        >
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              ID <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='id'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'id',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='id' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Key <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='key'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'key',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='key' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Host URL <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='hostUrl'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'hostUrl',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='hostUrl' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Environment ID <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='enviromentId'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'enviromentId',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='enviromentId' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Webhook ID <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='webhookId'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'webhookId',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='webhookId' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50'>
                            <span className='title-input-form'>
                              Webhook Key <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <FastField
                                name='webhookKey'
                                maxLength={255}
                                required
                                className='setting__search-form'
                                onBlur={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  handleBlur(e);
                                  setFieldValue(
                                    'webhookKey',
                                    e.currentTarget.value.trim()
                                  );
                                }}
                              />
                              <p className='error-msg'>
                                <ErrorMessage name='webhookKey' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings setting__content--full-row block width-50 select--full-width'>
                            <span className='title-input-form'>
                              Account Billing Mode <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <Filter
                                optionsForFilter={optionBillingModes}
                                handleSetNewFilterValue={(newMode) =>
                                  setFieldValue(
                                    'accountBillingMode',
                                    newMode.value
                                  )
                                }
                                valueSelected={optionBillingModes.find(
                                  (option) =>
                                    option.value === values.accountBillingMode
                                )}
                              />
                            </div>
                          </div>
                          <Button
                            style={{ marginLeft: '10px' }}
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={!formikProps.isValid || disabledSubmit}
                          >
                            <span>Save</span>
                          </Button>
                        </form>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default Pay;
