import styled from 'styled-components';

const Main = styled.div`
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  & > button {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { Main };
