import React, { ChangeEvent } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { ErrorMessage, FastField, Formik } from 'formik';

import ModalDiscard from 'components/Global/Modal/ModalDiscard';
import toast from 'components/Global/Toast';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { ProductResponseType } from 'global/requests/ResponseTypes/Product';

ReactModal.setAppElement('div#root');

const FormModalContainer = styled.div`
  form {
    & > .setting__content--full-row {
      &:first-child {
        .practice-title {
          margin-bottom: 8px;
        }
      }

      &:nth-child(2) {
        margin-bottom: 5px;
      }

      &:nth-child(3) {
        margin-top: 5px;
      }
    }
  }

  .MuiFormControlLabel-root {
    margin-left: -7px;

    &.monthly-fee--negotiable {
      margin-top: 7px;
    }

    .MuiCheckbox-root {
      padding: 5px;

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }

    .MuiRadio-root {
      padding: 5px;

      &.Mui-checked {
        color: #1974ff;
      }

      .MuiSvgIcon-root {
        width: 0.9em;
        height: 0.9em;

        /* &.Mui-checked {
          color: #1974ff;
        } */
      }
    }

    .MuiFormControlLabel-label {
      font-weight: 400 !important;
    }
  }

  .link__avalara-tax {
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #1974ff;
  }

  .practice-title {
    margin-bottom: -10px;
  }

  .first-name {
    /* input[type='number']::before {
      position: absolute;
      content: '$';
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 999;
    } */
    .symbol--dollar {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-weight: 600;
      z-index: 999;

      & + .setting__search-form {
        padding-left: 23px;
      }
    }
  }
`;

interface RightModalSettingUserProps {
  displayModal: boolean;
  setDisplayModal: () => void;
  productData: ProductResponseType;
  handleReloadData: () => void;
}

interface FormikValueType
  extends Partial<Omit<ProductResponseType, 'isPackageProduct'>> {
  isPackageProduct: string;
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const validationSchema = Yup.object().shape({
  productName: Yup.string().required(ErrorMessageTypes.required),
  invoiceName: Yup.string().required(ErrorMessageTypes.required),
  taxCode: Yup.string().when('isMonthlyFeeTaxability', (value: boolean) => {
    if (value) {
      return Yup.string().required(ErrorMessageTypes.required);
    } else {
      return Yup.string().notRequired();
    }
  }),
  defaultMonthlyFee: Yup.number().required(ErrorMessageTypes.required),
  defaultSetupFee: Yup.number().required(ErrorMessageTypes.required),
  maximumMonthlyFee: Yup.number().when(
    'isMonthlyFeeNegotiable',
    (value: boolean) => {
      if (value) {
        return Yup.number().required(ErrorMessageTypes.required);
      }
      return Yup.number().notRequired();
    }
  ),
  // .when('minimumMonthlyFee', (value: string) => {
  //   return Yup.number().min(
  //     Number(value),
  //     'Maximum has greater than minimum.'
  //   );
  // }),
  minimumMonthlyFee: Yup.number().when(
    'isMonthlyFeeNegotiable',
    (value: boolean) => {
      if (value) {
        return Yup.number().required(ErrorMessageTypes.required);
        // .when('maximumMonthlyFee', (value: string) => {
        //   if (Number(value) > 0) {
        //     return Yup.number().max(
        //       Number(value),
        //       'Minimum has less than maximum.'
        //     );
        //   }
        // });
      }
      return Yup.number().notRequired();
    }
  ),
});

const ProductModal = (props: RightModalSettingUserProps) => {
  const { displayModal, setDisplayModal, productData, handleReloadData } =
    props;

  const [openPopup, setOpenPopup] = React.useState(false);
  const [dirtyForm, setDirtyForm] = React.useState(false);

  const initialValues = !!productData.productId
    ? {
        isPackageProduct: productData.isPackageProduct.toString(),
        productName: productData.productName,
        invoiceName: productData.invoiceName,
        isMonthlyFeeTaxability: productData?.isMonthlyFeeTaxability,
        isSetupFeeTaxability: productData?.isSetupFeeTaxability,
        defaultMonthlyFee: productData?.defaultMonthlyFee,
        maximumMonthlyFee: productData?.maximumMonthlyFee,
        minimumMonthlyFee: productData?.minimumMonthlyFee,
        isMonthlyFeeNegotiable: productData?.isMonthlyFeeNegotiable,
        defaultSetupFee: productData?.defaultSetupFee,
        taxCode: productData?.taxCode,
      }
    : {
        isPackageProduct: 'true',
        productName: '',
        invoiceName: '',
        isMonthlyFeeTaxability: false,
        isSetupFeeTaxability: false,
        defaultMonthlyFee: 0,
        maximumMonthlyFee: 0,
        minimumMonthlyFee: 0,
        isMonthlyFeeNegotiable: false,
        defaultSetupFee: 0,
        taxCode: '',
      };

  const handleSubmitData = async (values: FormikValueType) => {
    try {
      const value = {
        ...values,
        isPackageProduct: values.isPackageProduct === 'true',
      };

      const res = !productData.productId
        ? await configuredRequests.POST.createProduct(value)
        : await configuredRequests.PATCH.updateProduct({
            ...value,
            productId: productData.productId,
          });

      if (res) {
        handleReloadData();
        toast.success('Your data has been saved successfully!');
        setDisplayModal();
      }
    } catch (err) {
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleCloseForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenPopup(dirtyForm);
    !dirtyForm && setDisplayModal();
  };

  return (
    <ReactModal
      className='modal-user-settings react-modal'
      isOpen={displayModal}
      onRequestClose={handleCloseForm}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={(values: FormikValueType) => {
          handleSubmitData(values);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            setFieldValue,
            values,
            errors,
            setFieldError,

            dirty,
          } = formikProps;
          if (dirty && displayModal) {
            setDirtyForm(true);
          }

          return (
            <div className='react-modal--content'>
              <div className='react-modal--settings__title'>
                <p className='title-text'>
                  {!!productData.productId ? 'Edit Product' : 'Add New Product'}{' '}
                </p>
                <button
                  className='react-modal-close-icon'
                  onClick={handleCloseForm}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </div>
              <div className='edit-appointment '>
                <FormModalContainer>
                  <form
                    onSubmit={handleSubmit}
                    className='col-12 form-schedule react-modal--settings__content'
                  >
                    <div className='setting__content--full-row'>
                      <h2 className='practice-title'>Type of Product</h2>
                      <div className='first-name'>
                        <RadioGroup
                          aria-label='Product Type'
                          name='isPackageProduct'
                          // defaultValue='package'
                          value={values.isPackageProduct}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('isPackageProduct', e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value={'true'}
                            control={<Radio color='primary' />}
                            label='Package'
                            labelPlacement='end'
                          />
                          <FormControlLabel
                            value={'false'}
                            control={<Radio color='primary' />}
                            label='Add-ons'
                            labelPlacement='end'
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Product Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            // maxLength={15}
                            name='productName'
                            required
                            placeholder=''
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'productName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='productName' />
                          </p>
                        </div>
                      </div>

                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Invoice Name <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <FastField
                            // maxLength={15}
                            name='invoiceName'
                            required
                            placeholder=''
                            className='setting__search-form'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              handleBlur(e);
                              setFieldValue(
                                'invoiceName',
                                e.currentTarget.value.trim()
                              );
                            }}
                          />
                          <p className='error-msg'>
                            <ErrorMessage name='invoiceName' />
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Taxability</h2>
                    </div>
                    <div className='setting__content--full-row'>
                      <div className='form-input-settings'>
                        <div className='first-name'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='isMonthlyFeeTaxability'
                                color='primary'
                                checked={values.isMonthlyFeeTaxability}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (!e.target.checked) {
                                    setFieldValue(
                                      'isMonthlyFeeNegotiable',
                                      false
                                    );
                                    setFieldValue(
                                      'isSetupFeeTaxability',
                                      false
                                    );
                                  } else {
                                    setFieldValue(
                                      'isMonthlyFeeNegotiable',
                                      true
                                    );
                                  }
                                  setFieldValue(
                                    'isMonthlyFeeTaxability',
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label='Monthly fee is subject to taxes'
                          />
                        </div>

                        <div className='first-name'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name='isSetupFeeTaxability'
                                color='primary'
                                checked={values.isSetupFeeTaxability}
                                disabled={!values.isMonthlyFeeTaxability}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setFieldValue(
                                    'isSetupFeeTaxability',
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label='Setup fee is subject to taxes'
                          />
                        </div>
                        {console.log('test')}
                      </div>
                    </div>

                    {values.isMonthlyFeeTaxability && (
                      <div className='setting__content--full-row'>
                        <div className='form-input-settings width-50'>
                          <span className='title-input-form'>
                            Avalara Tax Code <b className='require'>*</b>
                          </span>
                          <div className='first-name'>
                            <FastField
                              maxLength={15}
                              name='taxCode'
                              required
                              placeholder=''
                              className='setting__search-form'
                              onBlur={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleBlur(e);
                                setFieldValue(
                                  'taxCode',
                                  e.currentTarget.value.trim()
                                );
                              }}
                            />
                            <p className='error-msg'>
                              <ErrorMessage name='taxCode' />
                            </p>
                          </div>
                        </div>
                        <a
                          href='https://taxcode.avatax.avalara.com/'
                          target='_blank'
                          className='link__avalara-tax'
                        >
                          Avalara Tax Code List
                        </a>
                      </div>
                    )}

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Monthly Fee</h2>
                    </div>
                    <div className='setting__content--full-row'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Default Monthly Fee <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <div>
                            <span className='symbol--dollar'>$</span>
                            <FastField
                              maxLength={15}
                              type='number'
                              min={0}
                              name='defaultMonthlyFee'
                              required
                              placeholder=''
                              className='setting__search-form'
                            />
                          </div>
                          <p className='error-msg'>
                            <ErrorMessage name='defaultMonthlyFee' />
                          </p>
                        </div>
                      </div>

                      <FormControlLabel
                        className='monthly-fee--negotiable'
                        control={
                          <Checkbox
                            name='isMonthlyFeeNegotiable'
                            color='primary'
                            checked={values.isMonthlyFeeNegotiable}
                            // disabled={!values.isMonthlyFeeTaxability}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (e.target.checked) {
                                setFieldValue('isMonthlyFeeTaxability', true);
                              }
                              setFieldValue(
                                'isMonthlyFeeNegotiable',
                                e.target.checked
                              );
                            }}
                          />
                        }
                        label='Monthly fee is negotiable'
                      />
                    </div>

                    {values.isMonthlyFeeTaxability &&
                      values.isMonthlyFeeNegotiable && (
                        <div className='setting__content--full-row flex'>
                          <div className='form-input-settings width-50'>
                            <span className='title-input-form'>
                              Minimum Monthly Fee <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <div>
                                <span className='symbol--dollar'>$</span>
                                <FastField
                                  min={0}
                                  type='number'
                                  name='minimumMonthlyFee'
                                  required
                                  placeholder=''
                                  className='setting__search-form'
                                />
                              </div>
                              <p className='error-msg'>
                                <ErrorMessage name='minimumMonthlyFee' />
                              </p>
                            </div>
                          </div>
                          <div className='form-input-settings width-50'>
                            <span className='title-input-form'>
                              Maximum Monthly Fee <b className='require'>*</b>
                            </span>
                            <div className='first-name'>
                              <div>
                                <span className='symbol--dollar'>$</span>
                                <FastField
                                  min={0}
                                  type='number'
                                  name='maximumMonthlyFee'
                                  required
                                  placeholder=''
                                  className='setting__search-form'
                                />
                              </div>
                              <p className='error-msg'>
                                <ErrorMessage name='maximumMonthlyFee' />
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                    <div className='setting__content--full-row '>
                      <h2 className='practice-title'>Setup Fee</h2>
                    </div>
                    <div className='setting__content--full-row flex'>
                      <div className='form-input-settings width-50'>
                        <span className='title-input-form'>
                          Default Setup Fee <b className='require'>*</b>
                        </span>
                        <div className='first-name'>
                          <div>
                            <span className='symbol--dollar'>$</span>
                            <FastField
                              maxLength={15}
                              type='number'
                              min={0}
                              name='defaultSetupFee'
                              required
                              placeholder=''
                              className='setting__search-form'
                            />
                          </div>
                          <p className='error-msg'>
                            <ErrorMessage name='defaultSetupFee' />
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </FormModalContainer>
              </div>
              <div className='react-modal-settings-footer'>
                <Button
                  variant='contained'
                  color='default'
                  className='cancel-button'
                  onClick={handleCloseForm}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => handleSubmitData(values)}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!formikProps.isValid}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>

      <ModalDiscard
        isOpenModal={openPopup}
        handleCloseModal={() => {
          setOpenPopup(false);
        }}
        handleConfirmModal={() => {
          setDirtyForm(false);
          setOpenPopup(false);
          setDisplayModal();
        }}
      />
    </ReactModal>
  );
};
export default ProductModal;
