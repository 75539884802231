import styled from 'styled-components';

export const HoursWrapper = styled.div`
.toogle-date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  .title-day {
    // margin-left: 15px;

    color: #12253F;

    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .toogle-operation {
    width: 10%;
    margin-left: -15px;
  }
  .day-operation {
    width: 15%;
  }

  & div.MuiFormControl-root {
    margin-left: 20px;
    margin-right: 10px;
  }

  .select-start-time, .select-end-time {
    min-width: 130px;
    height: 37px;

    border: 1px solid #D4DBE4;
    border-radius: 6px;

    background-color: #FFFFFF;

    margin-left: 15px;

    input {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

p.error-text {
  text-align: center;
  margin-right: -12rem;
}
`;