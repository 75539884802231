import React from 'react';
import { saveForm, saveFormVersion } from './utils';

import FormItem from '../../../../src/components/FormBuilder/components/FormBuilder/forms/form-item';

export default class FormBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formId: null,
    };
  }

  async componentDidMount() {
    // const form = await ss.rpc('FormBuilder.FormsInterface.getBy', 'FormVersionsCurrent', {
    //   is_deleted: false,
    //   search: {
    //     actions: ['create_appointment_booking_request'],
    //   },
    // });

    // // for OAB, result should only ever be an array of length 0 or 1
    // // but just in case there is more than one, just grab the first element from the array
    // if (form.length > 0) {
    //   this.setState({ formId: String(form[0].form_id) });
    // }
  }

  handleFormSave = async (form, formGroups, formContacts, callback) => {
    const results = await saveForm(form, formGroups, formContacts);
    callback({ newData: results });
  }

  handleVersionSave = async (formVersion, callback) => {
    const newVersion = await saveFormVersion(formVersion);
    callback(newVersion);
  }

  render() {
    const { formId } = this.state;
    return (
      <FormItem
        // formId={'729ee47a-968f-446a-a099-a899188327be'}
        hideChrome={true}
        isAppointmentForm={true}
        // onFormSave={this.handleFormSave}
        // onVersionSave={this.handleVersionSave}
      />
    );
  }
};

const { PropTypes } = React;
FormBuilder.propTypes = {};
FormBuilder.defaultProps = {};
