import React from 'react';
import classNames from 'classnames';
import { FormFieldBase } from '.';
import { DateConfig } from './properties-config';
import { getFieldProperties, getFieldPropertyValues } from './helpers';
import { FormFieldInputDate, FormFieldInputTime } from './form-field-inputs';
import ValidationMessage from './validation-message';

export const FormFieldDate = ({
  field,
  className,
  commonProps,
  isRenderedField,
  value,
  onChange,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const handleDateChange = (newDate, includeTime) => {
    const isValidDate = newDate instanceof Date;
    const value = newDate;
    const id = field.uuid;

    // get selected time if this field is set to include time selection
    if (isValidDate && includeTime) {
      let hours = document.getElementById(`${id}-hour`).value || '00';
      const minutes = document.getElementById(`${id}-minute`).value || '00';
      const ampm = document.getElementById(`${id}-ampm`).value || 'AM';

      // set hours to 24 hour format
      hours = convertTo24Hour(parseInt(hours, ampm));

      value.setHours(hours, minutes);
    }

    // create custom event object to pass on
    const event = {
      target: {
        id,
        value,
      },
    };

    onChange(event, /* forceValidation */ true);
  };

  const handleTimeChange = (e) => {
    // time change needs to set the hours and minutes to the selected date
    // or to current date if no date has been selected
    const { value } = e.target;
    const ampm = value.split(' ')[1];
    const hoursMinutes = value.split(' ')[0].split(':');
    const hours = convertTo24Hour(parseInt(hoursMinutes[0]), ampm); // convert to 24 hour
    const minutes = parseInt(hoursMinutes[1]);
    const id = field.uuid;
    const dateValue = document.getElementById(id).value;
    const date = dateValue ? new Date(dateValue) : new Date();

    date.setHours(hours, minutes);

    // create custom event object to pass on
    const event = {
      target: {
        id,
        value: date,
      },
    };

    onChange(event, /* forceValidation */ true);
  };

  const handleBlur = () => {
    onBlur({ target: { id: field.uuid, value } });
  };

  const localProps = new Map(DateConfig);
  const convertTo24Hour = (sourceHour, ampm) => {
    let hour = sourceHour;

    if (ampm === 'PM' && hour < 12) hour += 12;
    else if (ampm === 'AM' && hour === 12) hour -= 12;

    return hour;
  };

  const getTimeElements = (dateValue) => {
    const elements = {};

    if (dateValue) {
      let hour = dateValue.getHours();

      elements.minute = dateValue.getMinutes().toString().padStart(2, '0');
      elements.ampm = hour >= 12 ? 'PM' : 'AM';

      if (hour > 12) hour -= 12;
      elements.hour = hour.toString().padStart(2, '0');
    }

    return elements;
  };

  const propertyNames = [
    'dateFormat',
    'includeTime',
    'sublabel',
    'pastFuture',
    'weekdays',
  ];
  const defaultValues = propertyNames.map((propName) => ({
    [`${propName}`]: localProps.get(propName).defaultValue,
  }));
  const properties = getFieldProperties(field, 'specific');
  const propValues = getFieldPropertyValues(
    properties.specific,
    propertyNames,
    defaultValues
  );
  const classes = classNames({
    [className]: true,
    date: true,
    'control-group error': validationMessage,
  });
  const id = field.uuid;

  // get sublabels
  // "basic" Date Picker fields should get sublabel from common props
  // otherwise, this is stored in field-specific props
  const sublabels = field.is_basic
    ? { Date: commonProps.sublabel }
    : getFieldPropertyValues(new Map(propValues.sublabel), [
        'Date',
        'Hour',
        'Minutes',
      ]);

  // use default date format value for placeholder if a value isn't specified
  let placeholder = propValues.dateFormat || '';
  if (
    (!placeholder || placeholder === '') &&
    properties.hasOwnProperty('specific')
  )
    placeholder = properties.specific.get('dateFormat').defaultValue;

  const dynamicProps = {};
  let timeProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = (newDate) =>
      handleDateChange(newDate, propValues.includeTime);
    dynamicProps.onBlur = handleBlur;
    dynamicProps.disabled = readOnly;

    // customize calendar based on property settings
    if (field.is_basic) dynamicProps.maxDate = new Date();
    else {
      const { pastFuture, weekdays } = propValues;

      // past/future
      const pastProp = pastFuture
        ? pastFuture.find((val) => val[0] === 'Past')
        : null;
      const futureProp = pastFuture
        ? pastFuture.find((val) => val[0] === 'Future')
        : null;

      if (pastProp && pastProp[1] === false) dynamicProps.minDate = new Date();
      if (futureProp && futureProp[1] === false)
        dynamicProps.maxDate = new Date();

      // weekdays
      if (weekdays)
        dynamicProps.disabledWeekdays = weekdays.filter(
          (weekday) => !weekday[1]
        );
    }

    if (value) {
      if (!(value instanceof Date)) {
        value = new Date(value);
        field.value = new Date(value);
      }

      // if we have a valid date value, and this field should include time, then
      // we need to split out the time into hour, minute, and AM/PM values
      if (propValues.includeTime) timeProps = getTimeElements(value);
    }

    timeProps.disabled = readOnly;
    timeProps.onBlur = handleBlur;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  return (
    <div className={classes}>
      <div className='form-field-label primary'>{`${commonProps.label} ${
        commonProps.makeRequired ? '*' : ''
      }`}</div>
      <div className='form-field-input date'>
        <FormFieldInputDate
          placeholder={placeholder.toLowerCase()}
          sublabel={sublabels.Date}
          id={id}
          isRenderedField={isRenderedField}
          value={value || null}
          {...dynamicProps}
        />
        {propValues.includeTime && (
          <FormFieldInputTime
            prefix='at'
            sublabels={{ ...sublabels }}
            id={id}
            isRenderedField={isRenderedField}
            onChange={handleTimeChange}
            {...timeProps}
          />
        )}
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

FormFieldDate.defaultProps = {
  commonProps: {},
  isRenderedField: false,

  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldDate);
