import React from 'react';
import { AvailabilityWrapper } from './AvailabilityWrapper';
import { ListDate, OfficeEvent } from './AvailabilityVM';
import HoursOfOperator from './components/HoursOfOperator';
import AddOutOfOfficeEvent from './components/AddOutOfOfficeEvent';
import { OutOfOfficeEvent } from 'global/requests/RequestTypes';
import OABIntervalSetting from './components/OABIntervalSetting';
import { DurationType } from './components/OABIntervalSetting';

interface Iprops {
  onChangeDateTime: (dayOrder: number, event: any) => void;
  onChangeLucnhTime: (dayOrder: number, event: any) => void;
  onChangeToogle: (dayOrder: number) => void;
  onChangeToogleLunch: (dayOrder: number) => void;
  onCloseModalConfirmModal: () => void;
  onCloseModalAddOfficeEvent: () => void;
  onChangeIsDirtyForm: (isDirty: boolean) => void;
  onChangeErrorExistEventName: (error: string) => void;
  onShowModalAddOfficeEvent: () => void;
  updateHoursOfOperation: () => void;
  updateTenantOABTimeUnits: () => void;
  onShowModalCornfirm: () => void;
  onShowModalEditEvent: (officeEvent: OfficeEvent | null) => void;
  onShowModalDeleteEvent: (officeEvent: OfficeEvent | null) => void;
  createOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  updateOutOfOfficeEvent: (payload: OutOfOfficeEvent) => void;
  deleteOutOfOfficeEvent: (id: string) => void;
  setIsSelectedDelete: (args: boolean) => void;
  onChangeTenantOABTimeUnits: (timeUnit: string) => void;
  selectedEvent: OfficeEvent | null;
  listDate: ListDate[];
  isLoadingHourOfOperation: boolean;
  isLoadingOABTimeUnits: boolean;
  isLoadingEvent: boolean;
  isSelectedDelete: boolean;
  listOfficeOff: OfficeEvent[];
  isShowModalAddOfficeEvent: boolean;
  isDirtyForm: boolean;
  isShowConfirmModal: boolean;
  errorExistEventName: string;
  tenantOABTimeUnits: number;
  minimumAvailability: string;
  onChangeMinimumAvailability: (value: string) => void;
  updateMinimumAvailability: () => void;
  isLoadingMinimumAvailability: boolean;
  optionsMinimumAvailability: DurationType[];
}

const AvailabilityV = (props: Iprops) => {
  const {
    isLoadingMinimumAvailability,
    optionsMinimumAvailability,
    minimumAvailability,
    listDate,
    isSelectedDelete,
    isShowModalAddOfficeEvent,
    selectedEvent,
    isDirtyForm,
    isShowConfirmModal,
    isLoadingHourOfOperation,
    isLoadingOABTimeUnits,
    isLoadingEvent,
    listOfficeOff,
    tenantOABTimeUnits,
    onChangeTenantOABTimeUnits,
    setIsSelectedDelete,
    onShowModalAddOfficeEvent,
    onCloseModalAddOfficeEvent,
    onChangeLucnhTime,
    onChangeDateTime,
    onChangeToogle,
    onChangeToogleLunch,
    onShowModalCornfirm,
    onCloseModalConfirmModal,
    onChangeIsDirtyForm,
    onChangeErrorExistEventName,
    onShowModalEditEvent,
    updateHoursOfOperation,
    updateTenantOABTimeUnits,
    onShowModalDeleteEvent,
    createOutOfOfficeEvent,
    updateOutOfOfficeEvent,
    deleteOutOfOfficeEvent,
    onChangeMinimumAvailability,
    updateMinimumAvailability,
    errorExistEventName,
  } = props;

  return (
    <AvailabilityWrapper className='appointment-availability'>
      <p className='title-header'>Availability Buffer</p>
      <OABIntervalSetting
      defaultValue={minimumAvailability.toString()}
        options={optionsMinimumAvailability}
        onChange={onChangeMinimumAvailability}
        onSave={updateMinimumAvailability}
        isLoading={isLoadingMinimumAvailability}
        isShowText={false}
      />
      <p className='title-header'>Appointment Booking Time Interval</p>
      <OABIntervalSetting
        defaultValue={tenantOABTimeUnits.toString()}
        options={[
          { value: '5', label: '5' },
          { value: '10', label: '10' },
          { value: '15', label: '15' },
          { value: '20', label: '20' },
          { value: '30', label: '30' },
          { value: '60', label: '60' },
        ]}
        onChange={onChangeTenantOABTimeUnits}
        onSave={updateTenantOABTimeUnits}
        isLoading={isLoadingOABTimeUnits}
      />
      <p className='title-header'>Hours of Operation</p>
      <p className='description-header'>
        Set the hours that you allow online appointments to be booked. You can
        edit availability for specific providers in the providers settings.
      </p>
      <HoursOfOperator
        listDate={listDate}
        onChangeDateTime={onChangeDateTime}
        onChangeLucnhTime={onChangeLucnhTime}
        onChangeToogle={onChangeToogle}
        onChangeToogleLunch={onChangeToogleLunch}
        isLoadingHourOfOperation={isLoadingHourOfOperation}
        updateHoursOfOperation={updateHoursOfOperation}
      />

      <p className='title-header title-header--unavailability'>
        Office Unavailability
      </p>
      <p className='description-header'>
        Specify days or holidays that you will not allow online appointments to
        be booked. You can edit unavailability for specific providers in the
        providers settings.
      </p>
      <AddOutOfOfficeEvent
        maxSize={55}
        isShowModalAddOfficeEvent={isShowModalAddOfficeEvent}
        selectedEvent={selectedEvent}
        isDirtyForm={isDirtyForm}
        isShowConfirmModal={isShowConfirmModal}
        isLoadingEvent={isLoadingEvent}
        isSelectedDelete={isSelectedDelete}
        listOfficeOff={listOfficeOff}
        setIsSelectedDelete={setIsSelectedDelete}
        onCloseModalAddOfficeEvent={onCloseModalAddOfficeEvent}
        onShowModalAddOfficeEvent={onShowModalAddOfficeEvent}
        onShowModalEditEvent={onShowModalEditEvent}
        onChangeIsDirtyForm={onChangeIsDirtyForm}
        onChangeErrorExistEventName={onChangeErrorExistEventName}
        onShowModalCornfirm={onShowModalCornfirm}
        onCloseModalConfirmModal={onCloseModalConfirmModal}
        onShowModalDeleteEvent={onShowModalDeleteEvent}
        createOutOfOfficeEvent={createOutOfOfficeEvent}
        updateOutOfOfficeEvent={updateOutOfOfficeEvent}
        deleteOutOfOfficeEvent={deleteOutOfOfficeEvent}
        errorExistEventName={errorExistEventName}
      />
    </AvailabilityWrapper>
  );
};

export default React.memo(AvailabilityV);
