/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { SmallDarkText } from 'components/Legwork/typography';
import { Main, StyledTextField } from './styled-components';
import { ISelectMenuItem } from '../index';

export interface AsyncSelectWithTopLabelProps {
  defaultValue?: ISelectMenuItem;
  disableClearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  fetchData: () => Promise<ISelectMenuItem[]>;
  placeholder?: string;
  reporter: (event: React.ChangeEvent<{}>, value: ISelectMenuItem | null) => void;
  title?: string;
}

const AsyncSelectWithTopLabel = (props: AsyncSelectWithTopLabelProps): React.ReactElement => {
  const {
    defaultValue = null,
    disableClearable = false,
    disabled: disabledInput = false,
    error = false,
    fetchData,
    placeholder = '',
    reporter,
    title,
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ISelectMenuItem[]>([]);
  const [selected, setSelected] = useState<ISelectMenuItem | null>(defaultValue);
  const loading = open && options.length === 0;
  const onChange = (event: React.ChangeEvent<{}>, value: ISelectMenuItem | null): void => {
    setSelected(value);
    reporter(event, value);
  };
  useEffect(() => {
    let active = true;
    if (!loading) return undefined;
    (async (): Promise<void> => {
      const results = await fetchData();
      if (active) setOptions(results || []);
    })();
    return (): void => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Main>
      {typeof title === 'string' && title.length > 0 && <SmallDarkText>{title}</SmallDarkText>}
      <Autocomplete
        id='asynchronous-select'
        open={open}
        disabled={disabledInput}
        onOpen={(): void => {
          setOpen(true);
        }}
        onClose={(): void => {
          setOpen(false);
        }}
        getOptionSelected={(option, value): boolean => option.display === value.display}
        getOptionLabel={(option): string => option.display}
        disableClearable={disableClearable}
        options={options}
        loading={loading}
        popupIcon={<KeyboardArrowDownIcon />}
        value={selected}
        onChange={onChange}
        renderOption={(option): React.ReactElement => (
          <div className={option.disabled ? 'disabled' : ''}>{option.display}</div>
        )}
        getOptionDisabled={(option): boolean => option.disabled || false}
        renderInput={(params): React.ReactElement => {
          return (
            <StyledTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              error={error}
              placeholder={placeholder}
              variant='filled'
              disabled={disabledInput}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color='inherit' size={17} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </Main>
  );
};

export default AsyncSelectWithTopLabel;
