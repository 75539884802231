/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface StyledDivProps {
  top: string;
  overrideWidth: number;
  left: string;
}
const StyledDiv = styled.div`
  position: absolute;
  top: ${(props: StyledDivProps): string => `${props.top}`};
  left: ${(props: StyledDivProps): string => `${props.left}`};
  width: ${(props: StyledDivProps): string => `${props.overrideWidth}%`};
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);
  padding: 20px 25px;
  outline: 0;
`;

export { StyledDiv };
