import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';

export interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps): React.ReactElement => {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: '100%' }}
    >
      {value === index && <Box style={{ width: '100%', boxSizing: 'border-box' }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
