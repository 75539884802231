import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TableWithHeader from '../components/TableWithHeader';
import RenderRows from '../components/RenderRows';
import {
  CenteredContainer,
  StyledCheckCircleOutlineIcon,
  StyledCircularProgress,
} from './styled-components';

import { IRow } from '../components/Row';

export interface IFetchReturn {
  totalPages: number;
  data: IRow[];
}

export interface InfiniteScrollTableProps {
  headCells: (string | React.ReactElement)[];
  generateRow: (row: IRow) => React.ReactElement[];
  fetchMore: (pageNumber: number) => Promise<IFetchReturn>;
  dense: boolean;
  scrollableTarget?: ReactNode;
  height?: number | string;
  hoverable?: boolean;
  shouldResetState?: boolean;
}

interface IState {
  page: number;
  rows: IRow[];
  hasMore: boolean;
}

const InfiniteScrollTable = (props: InfiniteScrollTableProps): React.ReactElement => {
  const {
    headCells,
    generateRow,
    fetchMore,
    dense,
    height,
    scrollableTarget,
    hoverable = false,
    shouldResetState = false,
  } = props;
  const [initialFetch, setInitialFetch] = useState(false);
  const [{ page, rows, hasMore }, setState] = useState<IState>({
    page: 1,
    rows: [],
    hasMore: true,
  });
  const fetchData = useCallback(async (): Promise<void> => {
    const { totalPages, data: newRows } = await fetchMore(page);
    const resultingRows: IRow[] = [...rows, ...newRows];
    const nextPage = page + 1;
    const moreData = !(nextPage > totalPages);
    setState({
      page: nextPage,
      rows: resultingRows,
      hasMore: moreData,
    });
  }, [rows, page, fetchMore]);

  useEffect((): void => {
    if (shouldResetState) {
      setState({
        page: 1,
        rows: [],
        hasMore: true,
      });
      setInitialFetch(false);
    }
  }, [shouldResetState]);

  useEffect((): void => {
    if (!initialFetch) {
      fetchData();
      setInitialFetch(true);
    }
  }, [initialFetch, fetchData]);

  const scrollProps = {
    dataLength: rows.length, // This is important field to render the next data
    next: fetchData,
    hasMore,
    loader: (
      <CenteredContainer>
        <StyledCircularProgress size='35' />
      </CenteredContainer>
    ),
    endMessage: (
      <CenteredContainer data-testid='end-message'>
        <StyledCheckCircleOutlineIcon fontSize='large' />
      </CenteredContainer>
    ),
    height,
    scrollableTarget,
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <InfiniteScroll {...scrollProps}>
      <TableWithHeader headCells={headCells} dense={dense}>
        <RenderRows rows={rows} generateRow={generateRow} hoverable={hoverable} />
      </TableWithHeader>
    </InfiniteScroll>
  );
};

InfiniteScrollTable.defaultProps = {
  dense: false,
};

export default InfiniteScrollTable;
