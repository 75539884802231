import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroupItem } from './radio-button-group-item';

export default class RadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.selected,
    });
  }

  getData = () => {
    return this.state.selected;
  };

  handleChange = item => {
    this.setState({
      selected: item.value,
    });
    this.props.onChange(item.value);
  };

  render() {
    const { className, itemClassNames, group, items, disabled } = this.props;

    return (
      <div className={className}>
        {items.map(item => {
          return (
            <RadioButtonGroupItem
              key={item.label}
              classNames={itemClassNames}
              group={group}
              item={item}
              selected={this.state.selected}
              onChange={this.handleChange}
              disabled={disabled}
            >
              {item.label}
            </RadioButtonGroupItem>
          );
        })}
      </div>
    );
  }
}

RadioButtonGroup.propTypes = {
  group: PropTypes.string.isRequired,
  itemClassNames: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any.isRequired,
    }),
  ),
  onChange: PropTypes.func,
  selected: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  onChange: () => { },
  className: '',
  disabled: false,
};
