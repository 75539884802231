import styled from 'styled-components';

const TableContainer = styled.div`
  padding-bottom: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledLabel = styled.label`
  cursor: pointer;
`;

const HiddenInput = styled.input`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden !important;
`;

const AttachmentButtonContainer = styled.div`
  /* These rules allow the label to be keyboard selected */
  input:focus + div > label {
    outline: medium solid #e1f0ff;
  }
  input:focus-within + div > label {
    outline: medium solid #e1f0ff;
  }
`;

export { TableContainer, LabelContainer, StyledLabel, HiddenInput, AttachmentButtonContainer };
