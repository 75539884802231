import styled from 'styled-components';

const FormRender = styled.div`
  input.span30,
  textbox.span30 {
    max-width: 40vw;
  }
  max-height: calc(100vh - 176px);
  display: flex;
  flex-direction: column;
  max-width: 84vw;
`;

const InnerFormRender = styled.div`
  display: flex;
  flex-direction: column;
`;

export { FormRender, InnerFormRender };
