import { Button } from '@material-ui/core';
import { AxiosError } from 'axios';
import { setTabActiveDefaultValue } from 'global/localStorage/tabActive';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { getSessionJWT, setSessionJWT } from 'global/sessionStorage/SessionJWT';
import { convertRoleOrScreen, getPaths } from 'hooks/useDecodeToken';
import { useRouter } from 'hooks/useRouter';
import { get } from 'lodash';
import headerLogo from 'media/images/Vet-Hero-Frenchie.png';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NoPermissionRouterProps } from 'types/RouterT';
import './Login.scss';

const determineErrorTypeFromAxiosResponse = (err: AxiosError) => {
  const errCodeString = err.response?.status.toString()[0];
  switch (errCodeString) {
    case '5':
      return process.env.NODE_ENV === 'development'
        ? 'Could not establish a connection with the login server'
        : 'Something went wrong on our side, please try again. If the issue persists try again later or contact us directly';
    case '4':
      return 'Authentication failed. Double check that you correctly entered your email and password';
    default:
      return 'Unable to determine the type of failure';
  }
};

export interface LoginProps extends NoPermissionRouterProps {}

const Login = (props: LoginProps) => {
  window.document.title = 'VetHero CRM | Login';
  const router = useRouter();
  const [displayErrorLoggingIn, setDisplayErrorLoggingIn] =
    React.useState(false);

  const [loginErrorType, setLoginErrorType] = React.useState<string>('');

  // renders Redirect element when true, only happens after a valid, successful login response is received
  const [redirect, setRedirect] = React.useState(false);

  const [invalidEmailInput, setInvalidEmailInput] = React.useState(false);
  const [invalidPasswordInput, setInvalidPasswordInput] = React.useState(false);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  const getContactListData = async (query: any) => {
    try {
      const res = await configuredRequests.GET.allUserInfoByCurrentTenant({
        userKinds: ['Customer'],
        limit: query?.limit ?? 20,
        offset: query?.offset ?? 0,
        withName: query?.withName ?? '',
      });
      // @ts-ignore
      const countContactUnRead = res.totalUnreads;
      if (countContactUnRead) {
        document
          .getElementsByClassName('status')[0]
          ?.classList.add('status--count');
        document.getElementsByClassName('status--count')[0].innerHTML =
          countContactUnRead.toString();
        sessionStorage.setItem(
          'countContactUnread',
          countContactUnRead.toString()
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  
  const redirectToDefaultPage = useCallback(async () => {
    const jwt = getSessionJWT();
    if (jwt) {
      const screens = convertRoleOrScreen(get(jwt, 'screens'));
      //console.log('screens ne', screens)
      const features = convertRoleOrScreen(get(jwt, 'features'));
      //console.log('features ne', features)
      const paths = getPaths(screens, features);
      const newPaths = paths.filter((item) => {return item !==""})
      console.log(newPaths)
      if (newPaths.includes('/Dashboard')){
        router.push('/Dashboard')
      } else if (newPaths.includes('/Appointments')){
        router.push('/Appointments')
      } else if (newPaths.includes('/Contacts')){
        router.push('/Contacts')
      } else if (newPaths.includes('/Communications')){
        router.push('/Communications')
      } else if (newPaths.includes('/Messenger')){
        router.push('/Messenger')
      } else if (newPaths.includes('/Telemedicine')){
        router.push('/Telemedicine')
      } else if (newPaths.includes('/AllSubmissions')){
        router.push('/AllSubmissions')
      } else if (newPaths.includes('/FormBuilder')){
        router.push('/FormBuilder')
      } else if (newPaths.includes('/Invoicing')){
        router.push('/Invoicing')
      } else if (newPaths.includes('/AppointmentBooking')){
        router.push('/AppointmentBooking')
      } else if (newPaths.includes('/Groups')){
        router.push('/Groups')
      } else if (newPaths.includes('/Billing')){
        router.push('/Billing')
      } else if (newPaths.includes('/Syncer')){
        router.push('/Syncer')
      } else if (newPaths.includes('/Alerts')){
        router.push('/Alerts')
      } else if (newPaths.includes('/SettingsAdmin')){
        router.push('/SettingsAdmin')
      }
      else {
        router.push(newPaths[0])
      }
      await getContactListData({ limit: 20, offset: 0 });
    }
  }, [router]);

  useEffect(() => {
    redirectToDefaultPage();
  }, [redirectToDefaultPage]);

  useEffect(() => {
    // redirect is set when there is a successful response
    if (redirect) {
      const jwt = getSessionJWT();
      // check the token for validity TODO
      if (jwt) {
        props.setRouterJWT(jwt);
      }

      // if a user's session expired then they will be redirected to login to refresh
      // we want to send them to the last page they were on, which will be the url
      const attemptedPath = window.location.pathname;
      // remove potential query params, does nothing if none are present
      const sanitizedPath = attemptedPath.split('?')[0];
      // USE CAUTION with attemptedPath. If we ever use query parameters for a POST request then somebody could
      // send a user to that page while no session existed, then when the user logs in it would automatically fire the request
      if (
        attemptedPath &&
        attemptedPath !== '' &&
        attemptedPath !== '/' &&
        !attemptedPath.includes('?')
      ) {
        router.push(sanitizedPath);
      }
      redirectToDefaultPage();
      // if there was no path or a dangerous path, send user to dash
    }
  }, [redirect, props, router, redirectToDefaultPage]);

  const hideErrorMessage = () => {
    setDisplayErrorLoggingIn(false);
    setLoginErrorType('');
  };

  /* Function to handle login */
  const handleSubmitLoginRequest = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {

    // default setup
    event.preventDefault();
    hideErrorMessage();
    window.scrollTo(0, 0);

    // Get email & password inputted
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    // Alert if either email or password has not been input
    if (!email || !password) {
      if (!email) {
        setInvalidEmailInput(true);
      } else {
        setInvalidEmailInput(false);
      }
      if (!password) {
        setInvalidPasswordInput(true);
      } else {
        setInvalidPasswordInput(false);
      }
      return false;
    }

    
    try {
      const loginResponse = await configuredRequests.POST.login(
        email,
        password
      );

      setSessionJWT(loginResponse);
      window.localStorage.setItem('isAutomationEdited', 'false');
      //TODO try sending request for UserInfo before we redirect
      setRedirect(true);
      setTabActiveDefaultValue();
      window.scrollTo(0, 0);
    } catch (err) {

      setDisplayErrorLoggingIn(true);
      const erroredGeneric = (err as Error).message;
      const erroredAxios = (err as AxiosError).isAxiosError;

      if (erroredAxios) {
        // @ts-ignore
        setLoginErrorType(determineErrorTypeFromAxiosResponse(err));
        return;
      }

      // only happens if we couldn't validate the response, so it means the server sent back invalid data for client
      if (erroredGeneric) {
        setLoginErrorType(
          'Internal Server Error. Please try again. Contact support if this error persists.'
        );
        return;
      }

      // shouldn't be necessary, but we want to always display something with 100% certainty
      setLoginErrorType(
        'Unable to determine the type of failure. Please try again'
      );
    }
  }; //end handleSubmitLoginRequest

  return (
    <>
      <div className='login__container'>
        <div className='login__form-container'>

          {/* Login input form */}
          <form
            onSubmit={handleSubmitLoginRequest}
            className='login__form'
            onChange={hideErrorMessage}
          >
            <div className='login__logo-container'>
              <img src={headerLogo} alt={'Fetch logo'} />
            </div>
            <div className='login__input-area-container'>
              <div
                className={
                  'login__input-container' +
                  (invalidEmailInput ? ' invalid' : '')
                }
              >
                <label htmlFor={'email'}>Email</label>
                <input
                  required
                  ref={emailRef}
                  type='email'
                  placeholder='Email'
                  name='email'
                  defaultValue={
                    process.env.NODE_ENV === 'development'
                      ? 'Loraine.Jacobi76@gmail.com'
                      : ''
                  }
                />
                <p></p>
              </div>

              <div
                className={
                  'login__input-container' +
                  (invalidPasswordInput ? ' invalid' : '')
                }
              >
                <label htmlFor='password'>Password</label>
                <input
                  required
                  ref={passwordRef}
                  type='password'
                  placeholder='Password'
                  name='password'
                  defaultValue={
                    process.env.NODE_ENV === 'development' ? '12!aBC-7890' : ''
                  }
                />
                <p></p>
              </div>
            </div>

            <Link className='forgot-password' to='/ResetPassword'>
              Forgot Password?
            </Link>

            <Button
              variant='contained'
              color='primary'
              className='login__input-submit log-in-button'
              type='submit'
            >
              Log in
            </Button>
           
          </form>
          {/* End of login input form */}
          

        </div>
      </div>


      {/* Display error if login fails */}
      {displayErrorLoggingIn ? (
        <div
          style={{
            width: '100%',
            margin: 'auto',
            fontSize: '1.25rem',
            bottom: '100px',
            zIndex: 999,
            textAlign: 'center',
            position: 'absolute',
          }}
        >
          {loginErrorType}
        </div>
      ) : (
        <></>
      )}
      {/* End of display error if login fails */}

      <div style={{ width: '100%', height: '10px' }} />
    </>
  );
};

export default Login;
