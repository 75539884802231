import styled from 'styled-components';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import getThemeProp from '../../utils/getThemeProp';

const BlackCardHeader = styled(CardHeader)`
  background-color: ${getThemeProp('palette.text.primary', 'black')};
  height: 30px;
  padding: 0.1rem 1rem;
  .MuiTypography-root {
    color: white;
    font-size: 15px;
    font-family: ${getThemeProp('typography.fontFamily', 'black')};
  }
`;
const SizedCard = styled(Card)`
  width: 293px;
  height: 121px;
`;
export { BlackCardHeader, SizedCard };
