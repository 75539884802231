import { ContactMessages } from 'global/requests/ResponseTypes';
import React from 'react';
import ChatMessage from './ChatMessage';
import { InfoSending } from './MessengerInput';
import loading_spinner from 'media/images/loading-spinner.svg';

interface MessengerHistoryProps {
  searchMessageId: string;
  messageHistoryListRef: React.RefObject<HTMLUListElement>;
  currentSelectedContactUUID: string;
  currentSelectedContactData: ContactMessages | undefined;
  messageHistory: any;
  viewIsChangingUsers: boolean;
  loadingMessenger?: boolean;
  loading?: boolean;
  infoSending?: InfoSending;
  searchText?: string;
  clickedContactMessenger?: boolean;
  refreshMessageData?: (userId: string) => void;
  setInfoSending?: React.Dispatch<React.SetStateAction<InfoSending>>;
  contactEntries: any;
  isListSearching: boolean;
  setCurrentSelectedContactData: React.Dispatch<React.SetStateAction<any>>;
  setCurrentSelectedContactUUID: React.Dispatch<React.SetStateAction<any>>;
  searchTextMemory: string | undefined;
  allowTextMessageWriteback: boolean;
}

const MessengerHistory = (props: MessengerHistoryProps) => {
  const {
    currentSelectedContactData,
    currentSelectedContactUUID,
    messageHistoryListRef,
    viewIsChangingUsers,
    messageHistory,
    allowTextMessageWriteback,
  } = props;

  // scroll user to bottom of history
  React.useEffect(() => {
    //TODO add state to track if we've already scrolled a user to the bottom
    // to preserve any scrolling the user has done since.

    if (props.clickedContactMessenger) {
      if (
        props.currentSelectedContactData?.searchedMesageId &&
        !props.loadingMessenger
      ) {
        const element = document.getElementById(props.searchMessageId);
        if (element) element?.scrollIntoView({ behavior: 'auto' });
      } else {
        const element = document.getElementById('end-of-message');
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }
    } else {
      const element = document.getElementById('end-of-message');
      if (element) {
        element.scrollIntoView({ behavior: 'auto' });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHistory, viewIsChangingUsers, props]);

  const defaultTextOfHistoryMessage = (): React.ReactElement => {
    if (
      props.searchText &&
      props.isListSearching &&
      props.contactEntries.users.length === 0
    ) {
      props.setCurrentSelectedContactData('');
      props.setCurrentSelectedContactUUID('');
      return (
        <p style={{ margin: 'auto', fontSize: '22px', color: '22334d' }}>
          Check out your search results on the left
        </p>
      );
    }
    if (!currentSelectedContactData) {
      return (
        <p style={{ margin: 'auto', fontSize: '22px', color: '22334d' }}>
          Please select a conversation from the list to the left.
        </p>
      );
    }
    return <></>;
  };

  const renderChatHistory = () => {
    if (
      currentSelectedContactUUID.length &&
      currentSelectedContactData &&
      Array.isArray(messageHistory) &&
      messageHistory.length &&
      !props.loadingMessenger
    ) {
      // const filteredMessagedHistory = Array.from(messageHistory).filter((message) => !((message as EmailEntry).subject));
      const dateSortedMessageHistory = messageHistory.sort((a, b) => {
        // @ts-ignore
        // TODO convert to use moment.diff comparison
        return new Date(a.sentDate) - new Date(b.sentDate);
      });
      return (
        <div className='messenger--chat-history-align-ul-container'>
          <ul className={'messenger--chat-history'} ref={messageHistoryListRef}>
            {dateSortedMessageHistory
              .map((contactMessageData, messageIndex) => {
                return (
                  <ChatMessage
                    id={contactMessageData.messageId}
                    key={'messenger-contact-chat-message-' + messageIndex}
                    {...contactMessageData}
                    contactName={currentSelectedContactData.name ?? ''}
                    contactID={currentSelectedContactUUID}
                    infoSending={props.infoSending}
                    refreshMessageData={props.refreshMessageData}
                    setInfoSending={props.setInfoSending}
                    isLastChild={
                      messageIndex === dateSortedMessageHistory.length - 1
                    }
                    searchMessageId={props.searchMessageId}
                    searchTextMemory={props.searchTextMemory}
                    note={contactMessageData.notes}
                    allowTextMessageWriteback={allowTextMessageWriteback}
                  />
                );
              })
              .concat([
                <div
                  id='end-of-message'
                  key='messenger-contact-chat-message-spacer'
                  style={{ minHeight: '40px' }}
                />,
              ])}
          </ul>
        </div>
      );
    }

    if (props.loadingMessenger) {
      return (
        <div className='spinner-upload'>
          <img src={loading_spinner} alt={'Loading'} />
        </div>
      );
    }
    if (!currentSelectedContactUUID.length && !currentSelectedContactData) {
      return null;
    }
    //else
    return <p className='messenger__no-text'>No Text History</p>;
  };

  return (
    <section
      className={
        'messenger--chat-history-container' +
        (viewIsChangingUsers ? ' hidden' : '')
      }
    >
      {defaultTextOfHistoryMessage()}
      {props.loading ? (
        <img
          className='loading-appointment-info'
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        renderChatHistory()
      )}
    </section>
  );
};

export default MessengerHistory;
