import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { initialTokenValue } from 'components/Communications/EditEmailTemplate';
import EmailEditor from 'components/Communications/EmailEditor';
import ModalInsertPersonalize from 'components/Communications/ModalInsertPersonalize';
import { cutTemplateBody } from 'components/Communications/Templates/PostCard/BackPostCard';
import {
  getContentBackPostCard,
  getContentFrontPostCard,
  getContentToSavePreview,
} from 'components/Communications/Templates/PostCard/getContentPostCard';
import TextChevronDropdown from 'components/Global/TextChevronDropdown/TextChevronDropdown';
import toast from 'components/Global/Toast';
import { characterLimit } from 'components/Messenger/MessengerInput';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import {
  MessagePayloadKindsEnum,
  MultipleContactSendTextPayload,
  MultipleUserSendTextPayload,
  UserSendPostalPayload,
} from 'global/requests/RequestTypes';
import {
  PersonalizationTokensResponse,
  TemplateData,
  TemplateResponse,
  TenantResposne,
} from 'global/requests/ResponseTypes';
import { upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import {
  convertDateToTimestamp,
  convertToCamelCase,
  formatNumber,
  getPracticeName,
  getTimeNowForPractice,
} from 'utils/convertData';
import { capitalize } from 'utils/strings';
import BatchPopup from './BatchPopup';
import SinglePostalEditor, {
  optionsDeliveryDateNow,
} from './ViewContact/SinglePostalEditor';
import WarningPopup from './WarningPopup';

// this tells the modal which element to aria-hide when the modal is displayed (accessibility)
ReactModal.setAppElement('div#root');

const EmailEditorWrap = styled.div`
  width: 100%;

  .contact-email-editor {
    padding: 10px;
    padding-top: 0;
  }
`;

const PostalEditorWrap = styled.div`
  height: 100%;
  width: 100%;

  & > div {
    height: 100%;
  }
`;

// TODO should potentially be an enum
export const DELIVERY_OPTIONS = {
  CUSTOM_TIME: 'Send Later',
  SEND_IMMEDIATELY: 'Send Immediately',
  SEND_DELIVERY: 'Send Within Delivery Hours',
};

// this component is a little bit unique. We use a string to determine which communication type is desired (SMS or Email)
// it can be extended because most logic is conditional and will do the relevant actions based on the string
// it should probably rely on an enum, currently its just a string type and can be any value

export interface SendCommunicationModalProps {
  labelText?: string;
  communicationModalType: string;
  setCommunicationModalType: React.Dispatch<React.SetStateAction<string>>;
  userSelectedConfig: {
    totalAvailable: number;
    userSelectedContactIDs: { [key: string]: boolean };
  };
  sendGetTextTemplateInfoRequest: () => void;
  sendGetEmailTemplateInfoRequest: () => void;
  emailTemplateInfo?: TemplateResponse;
  textTemplateInfo?: TemplateResponse;
  isSentToAllContact: boolean;
  searchValue: string;
  filterValue: string;
}

const SendCommunicationModal = (props: SendCommunicationModalProps) => {
  const {
    communicationModalType, // "text" | "email"
    sendGetTextTemplateInfoRequest,
    sendGetEmailTemplateInfoRequest,
    emailTemplateInfo,
    textTemplateInfo,
    isSentToAllContact,
    searchValue,
    filterValue,
  } = props;

  const [tokenData, setTokenData] =
    React.useState<PersonalizationTokensResponse>(initialTokenValue);
  const [textBody, setTextBody] = React.useState('');
  const [currentSelectedTemplate, setCurrentSelectedTemplate] =
    React.useState<TemplateData>({
      name: 'Blank Text Template',
      templateId: '',
    });
  const [generalSetting, setGeneralSetting] = React.useState<TenantResposne>();

  const [scheduledDeliveryDate, setScheduledDeliveryDate] =
    React.useState<MaterialUiPickersDate>();

  const { userSelectedContactIDs } = props.userSelectedConfig;
  // the list is determined on the initial load
  // TODO verify this works as expected when closing modal and updating selection
  const receiverList: string[] = React.useMemo(
    () => Object.keys(userSelectedContactIDs),
    [userSelectedContactIDs]
  );

  const emailEditorSubmitRef = React.createRef<HTMLButtonElement>();
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);

  const [currentDeliveryType, setCurrentDeliveryType] = React.useState<string>(
    DELIVERY_OPTIONS.SEND_DELIVERY
  );
  const [error, setError] = React.useState('');

  const [payloadSendToPostal, setPayloadSendToPostal] =
    React.useState<UserSendPostalPayload>({
      templateId: '',
      front: '',
      back: '',
      size: '4x6',
      actionType: 'sendWithInDeliveryHours',
      previewContent: '',
    });
  const [openInsertPersonalize, setOpenInsertPersonalize] =
    React.useState<boolean>(false);
  const typeInTextarea = (el: any, newText: string) => {
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const text = el.value;
    const before = text.substring(0, start);
    const after = text.substring(end, text.length);
    el.value = before + newText + ' ' + after;
    setTextBody(el.value);
    el.selectionStart = el.selectionEnd = start + newText.length;
    el.focus();
  };
  const [openWarningPopup, setOpenWarningPopup] = React.useState(false);
  const [minDate, setMinDate] = React.useState<Date | null>(null);
  const timeLocalRef = React.useRef(new Date());

  const [openBatchPopup, setOpenBatchPopup] = useState(false);
  const [postalCost, setPostalCost] = useState(0);
  const [smsCost, setSmsCost] = useState({
    SMSCost: 0,
    SMSFreeLimit: 0,
  });

  const getTokens = async () => {
    try {
      const tokenResponse =
        await configuredRequests.GET.personalizationTokens();
      if (tokenResponse) {
        setTokenData(tokenResponse);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDateTimeNow = async () => {
    try {
      const res = await configuredRequests.GET.getTimeNow();

      if (res) {
        setMinDate(new Date(res.toString()));
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (communicationModalType === 'text') {
      getSmsPrice();
    }
    if (communicationModalType === 'postal') {
      getPostalPrice();
    }
  }, [communicationModalType]);

  useEffect(() => {
    Promise.all([getDateTimeNow(), getTokens()]);
  }, []);

  useEffect(() => {
    //TODO convert to enum and decouple these requests
    if (communicationModalType.length) {
      sendGetTextTemplateInfoRequest();
      sendGetEmailTemplateInfoRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationModalType]);

  const handleChangeDeliveryType = (newValue: string) => {
    switch (newValue) {
      case DELIVERY_OPTIONS.CUSTOM_TIME:
        if (
          currentDeliveryType === DELIVERY_OPTIONS.SEND_DELIVERY ||
          currentDeliveryType === DELIVERY_OPTIONS.SEND_IMMEDIATELY
        ) {
          setCurrentDeliveryType(newValue);
          if (minDate) {
            const newMinDate = getTimeNowForPractice(
              timeLocalRef.current,
              minDate
            );
            setMinDate(newMinDate);
            setScheduledDeliveryDate(newMinDate);
          } else {
            setMinDate(new Date());
            setScheduledDeliveryDate(new Date());
          }
          timeLocalRef.current = new Date();
        }
        break;
      case DELIVERY_OPTIONS.SEND_IMMEDIATELY:
        setOpenWarningPopup(true);
        setCurrentDeliveryType(newValue);
        break;
      default: {
        setCurrentDeliveryType(newValue);
        break;
      }
    }
  };

  useEffect(() => {
    if (props.communicationModalType === 'text' && textBody) {
      setIsSubmitEnabled(true);
    } else if (props.communicationModalType === 'text' && !textBody) {
      setIsSubmitEnabled(false);
    }
  }, [props.communicationModalType, textBody]);

  const renderDeliveryDateSelect = (key: string) => {
    return (
      <div className='contacts--send-communication-date-time-container'>
        <TextChevronDropdown
          key={key}
          currentSelection={currentDeliveryType}
          handleChangeSelection={handleChangeDeliveryType}
          selectionOptions={[
            {
              label: DELIVERY_OPTIONS.SEND_DELIVERY,
              value: DELIVERY_OPTIONS.SEND_DELIVERY,
            },
            {
              label: DELIVERY_OPTIONS.SEND_IMMEDIATELY,
              value: DELIVERY_OPTIONS.SEND_IMMEDIATELY,
            },
          ]}
          useBlackText={true}
          useBoldText={true}
          className={'height-40 border-gray'}
          useLabel={{
            labelText: 'Delivery Date',
          }}
        />
        {/* renders datePicker when custom time is desired */}
        {currentDeliveryType === DELIVERY_OPTIONS.CUSTOM_TIME
          ? [
              <div
                className='send-communication--datetime-picker-container'
                key='contacts--send-communication-input--date'
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container className='grid-custom'>
                    <FontAwesomeIcon
                      icon={['fas', 'calendar-alt']}
                      className='icon-calendar__communication-end-date'
                    />
                    <DateTimePicker
                      value={scheduledDeliveryDate}
                      minDate={minDate}
                      onChange={setScheduledDeliveryDate}
                      className='date-picker-custom'
                      format='MMMM do hh:mm a'
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>,
            ]
          : null}
      </div>
    );
  };

  const renderEmailForm = () => {
    return (
      //TODO checkNewEmail?
      <EmailEditorWrap>
        <EmailEditor
          hideRecipient={true}
          receiverUserIDs={receiverList}
          checkNewEmail={() => {}}
          searchValue={searchValue}
          filterValue={filterValue}
          isSentToAllContact={isSentToAllContact}
          closeModal={handleCloseModal}
          useCustomSubmit={{
            innerSubmitRefObject: emailEditorSubmitRef,
            setIsSubmitEnabled: setIsSubmitEnabled,
          }}
        />
      </EmailEditorWrap>
    );
  };

  const renderPostalForm = () => {
    return (
      <PostalEditorWrap>
        <SinglePostalEditor
          payloadSendToPostal={payloadSendToPostal}
          setPayloadSendToPostal={setPayloadSendToPostal}
          setIsSubmitEnabled={setIsSubmitEnabled}
          receiverUserIDs={receiverList}
          isSentToAllContact={isSentToAllContact}
          setGeneralSetting={setGeneralSetting}
        />
      </PostalEditorWrap>
    );
  };

  const handleSelectDropdownOption = (newValue: string) => {
    if (newValue === 'default') {
      setCurrentSelectedTemplate({
        name: 'Blank Text Template',
        templateId: '',
      });
      setTextBody('');
    } else {
      const selectedTemplateInfo: TemplateData | undefined =
        textTemplateInfo?.items.filter(
          (templateInfo) => templateInfo.templateId === newValue
        )[0];
      if (selectedTemplateInfo) {
        setCurrentSelectedTemplate(selectedTemplateInfo);
      }
      if (selectedTemplateInfo?.templateBody) {
        setTextBody(selectedTemplateInfo.templateBody);
      }
    }
  };

  const handleTextAreaInput = (
    event: React.SyntheticEvent<HTMLTextAreaElement>
  ) => {
    setError('');
    const targetValue = event.currentTarget.value;
    if (targetValue.length === characterLimit) {
      setError(`Message body cannot exceed ${characterLimit} characters.`);
    }
    setTextBody(targetValue);
  };

  const renderTextForm = () => {
    return (
      <div className='contacts--text-form-container'>
        <TextChevronDropdown
          key={'contacts--text-form-template-dropdown'}
          currentSelection={currentSelectedTemplate.name ?? ''}
          handleChangeSelection={handleSelectDropdownOption}
          selectionOptions={generateTemplateSelectOptions([
            {
              value: 'default',
              label: 'Blank Text Template',
            },
          ])}
          useBlackText={true}
          useBoldText={true}
          className={'height-40 border-gray'}
          useLabel={{
            labelText: 'Template',
          }}
        />

        <div className='contacts--text-form-input-container'>
          <label>
            Message Body <b className='require'>*</b>
          </label>
          <div className='contacts--text-form-input-container--content'>
            <textarea
              className='contacts--text-form-textarea'
              onInput={handleTextAreaInput}
              value={textBody}
              id='textArea'
              maxLength={characterLimit}
            />
            <button
              id='personalizeButton'
              className='btn-personalize'
              onClick={() => setOpenInsertPersonalize(true)}
            >
              {' Personalize '}
            </button>
          </div>

          {error && <p className='error-msg'> {error} </p>}
        </div>

        {renderDeliveryDateSelect(
          'contacts--text-form-delivery-date--dropdown'
        )}
      </div>
    );
  };

  const renderCommunicationForm = (communicationType: string) => {
    switch (communicationType) {
      case 'email': {
        return renderEmailForm();
      }
      case 'text': {
        return renderTextForm();
      }
      case 'postal': {
        return renderPostalForm();
      }
    }
  };

  const generateTemplateSelectOptions = (
    defaultOptions: { value: string; label: string }[]
  ) => {
    const output = [...defaultOptions];
    if (communicationModalType === 'email') {
      const generatedOptions = emailTemplateInfo?.items.map((emailTemplate) => {
        return {
          label: emailTemplate.name ?? 'Missing Template Name',
          value: emailTemplate.templateId ?? 'Missing Template ID',
        };
      });
      if (generatedOptions) {
        output.concat(generatedOptions);
      }
    }
    if (communicationModalType === 'text') {
      const generatedOptions = textTemplateInfo?.items.map((textTemplate) => {
        return {
          label: textTemplate.name ?? 'Missing Template Name',
          value: textTemplate.templateId ?? 'Missing Template ID',
        };
      });
      if (generatedOptions) {
        return output.concat(generatedOptions);
      }
    }
    return output;
  };

  const getPostalPrice = async () => {
    try {
      const res = await configuredRequests.GET.getPostalPrice();
      setPostalCost(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getSmsPrice = async () => {
    try {
      const res = await configuredRequests.GET.getSmsPrice();
      // @ts-ignore
      setSmsCost(res.settings);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getNumberOfSelectedContacts = () => {
    return Object.values(props.userSelectedConfig.userSelectedContactIDs)
      .length;
  };

  const getNumberOfAllContacts = () => {
    return props.userSelectedConfig.totalAvailable;
  };

  const handleCloseModal = (
    event?: React.SyntheticEvent | React.KeyboardEvent
  ) => {
    event?.stopPropagation();
    props.setCommunicationModalType('');
    setCurrentDeliveryType(DELIVERY_OPTIONS.SEND_DELIVERY);
    setCurrentSelectedTemplate({ name: 'Blank Text Template', templateId: '' });
    setIsSubmitEnabled(false);
    setError('');
    setScheduledDeliveryDate(null);
    setPayloadSendToPostal({
      templateId: '',
      front: '',
      back: '',
      size: '4x6',
      previewContent: '',
      actionType: 'sendWithInDeliveryHours',
    });
    props.communicationModalType === 'text' && setTextBody('');
  };

  const handleSendCommunication = async (isSendEmail?: boolean) => {
    switch (communicationModalType) {
      case 'email': {
        emailEditorSubmitRef.current?.click();
        break;
      }
      case 'text': {
        try {
          const sendDate =
            currentDeliveryType === DELIVERY_OPTIONS.CUSTOM_TIME
              ? convertDateToTimestamp(scheduledDeliveryDate ?? new Date())
              : '';
          const messageContent = textBody || '';
          if (!messageContent) {
            //TODO toast.error
            console.log('could not get message text');
            return;
          }

          const filterValueConverted =
            filterValue === 'all' ? '' : upperFirst(filterValue);

          if (isSendEmail) {
            if (isSentToAllContact) {
              const payloadToSend = {
                content: messageContent,
                kind: MessagePayloadKindsEnum.email,
                subject: `A message from ${getPracticeName() || ''}`,
                scheduleDate: sendDate,
                templateId: currentSelectedTemplate.templateId ?? '',
                actionType: convertToCamelCase(currentDeliveryType),
              };
              // toast.success('Sending to contacts', true);
              configuredRequests.POST.sendEmailToAllCustomers(
                //@ts-ignore
                payloadToSend,
                searchValue,
                filterValueConverted
              );
            } else {
              // @ts-ignore
              const payloadSendEmail: SelectedUserSendEmailPayload = {
                messageContents: {
                  content: messageContent,
                  kind: MessagePayloadKindsEnum.email,
                  subject: `A message from ${getPracticeName() || ''}`,
                  templateId: currentSelectedTemplate.templateId ?? '',
                  actionType: convertToCamelCase(currentDeliveryType),
                  sendLaterDate: sendDate,
                },
                contactIds: receiverList,
              };
              // toast.success(
              //   `Sending to contact${recipients.length === 1 ? '' : 's'}`,
              //   true
              // );
              configuredRequests.POST.sendSelectedUserEmail(payloadSendEmail);
            }
          } else {
            if (isSentToAllContact) {
              const payloadTextToAllUser: MultipleContactSendTextPayload = {
                sendLaterDate: sendDate,
                content: messageContent,
                kind: MessagePayloadKindsEnum.sms,
                scheduleDate: sendDate,
                templateId: currentSelectedTemplate.templateId ?? '',
                actionType: convertToCamelCase(currentDeliveryType),
              };
              // setIsSubmitEnabled(false);
              configuredRequests.POST.sendSMSToAllContacts(
                payloadTextToAllUser,
                searchValue,
                filterValueConverted
              );
            } else {
              //@ts-ignore
              const payloadTextToSelectedUser: MultipleUserSendTextPayload = {
                messageContents: {
                  content: messageContent,
                  kind: MessagePayloadKindsEnum.sms,
                  sendLaterDate: sendDate,
                  templateId: currentSelectedTemplate.templateId ?? '',
                  actionType: convertToCamelCase(currentDeliveryType),
                },
                contactIds: receiverList,
              };
              configuredRequests.POST.sendMultipleUserSMS(
                payloadTextToSelectedUser
              );
            }
          }

          toast.success(
            <b>{`Sending to contact${
              !isSentToAllContact && receiverList.length === 1 ? '' : 's'
            }`}</b>,
            true
          );
          handleCloseModal();
        } catch (err) {
          // toast.error("Sent failed! Please try again.");
        }
        break;
      }
      case 'postal': {
        try {
          const previewContent = getContentToSavePreview({
            frontSideUrl: payloadSendToPostal.front,
            sizePostCard: payloadSendToPostal.size,
            contentBackSide: cutTemplateBody(payloadSendToPostal.back),
            isPostGrid:
              (generalSetting?.postalProvider || '').toLowerCase() ===
              'postgrid',
          });
          const dataSend = {
            // @ts-ignore
            front: getContentFrontPostCard(
              payloadSendToPostal.front,
              payloadSendToPostal.size
            ),
            // @ts-ignore
            back: getContentBackPostCard(
              cutTemplateBody(payloadSendToPostal.back),
              payloadSendToPostal.size,
              (generalSetting?.postalProvider || '').toLowerCase() ===
                'postgrid'
            ),
            size: payloadSendToPostal.size,
            previewContent,
            actionType: payloadSendToPostal.actionType,
            send_date: payloadSendToPostal.sendLaterDate
              ? payloadSendToPostal.sendLaterDate
              : '',
          };
          payloadSendToPostal.templateId &&
            // @ts-ignore
            (dataSend.templateId = payloadSendToPostal.templateId);
          toast.success(
            <b>{`Sending to contact${
              !isSentToAllContact && receiverList.length === 1 ? '' : 's'
            }`}</b>,
            true
          );
          handleCloseModal();
          if (isSentToAllContact) {
            configuredRequests.POST.sendPostalToAllContacts('Active', dataSend);
          } else {
            configuredRequests.POST.sendPostalToMultipleContacts({
              postcardRequest: dataSend,
              contactIds: receiverList,
            });
          }
        } catch (err) {
          console.log('Error: ', err);
        }
        break;
      }
    }
  };

  const handleKeypressCloseButton = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        handleCloseModal();
        break;
      }
    }
  };

  const handleInsertData = (personalizationTokenSelected: string) => {
    typeInTextarea(
      document.getElementById('textArea'),
      `{{${personalizationTokenSelected}}}`
    );
    setOpenInsertPersonalize(false);
  };

  return (
    <>
      <ReactModal
        isOpen={props.communicationModalType.length > 0 && !isSentToAllContact}
        onRequestClose={handleCloseModal}
        contentLabel={props.labelText ?? 'empty label'}
        className={`react-modal ${capitalize(
          props.communicationModalType
        ).toLowerCase()}`}
      >
        <div className='react-modal--inside-overflow-container'>
          <div className='contact-communication-modal-top-row'>
            <div className='contact-communication-modal-title-text'>
              {isSentToAllContact
                ? `Send ${capitalize(
                    props.communicationModalType
                  )} to ${getNumberOfAllContacts()} Contact${
                    getNumberOfAllContacts() > 1 ? 's' : ''
                  }`
                : `Send ${capitalize(
                    props.communicationModalType
                  )} to ${getNumberOfSelectedContacts()} Contact${
                    getNumberOfSelectedContacts() > 1 ? 's' : ''
                  }`}
            </div>

            <div
              className='contacts--modal-exit-button-container'
              tabIndex={0}
              onClick={handleCloseModal}
              onKeyPress={handleKeypressCloseButton}
            >
              <FontAwesomeIcon icon={['far', 'times']} />
            </div>
          </div>

          <div className='contacts--send-communication-modal-body-container'>
            {renderCommunicationForm(props.communicationModalType)}
          </div>

          <div className='contacts--communication-modal-bottom-row'>
            <Button
              variant='contained'
              className='contacts--communication-modal-bottom-button cancel'
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={!isSubmitEnabled}
              className={
                'contacts--communication-modal-bottom-button confirm' +
                (isSubmitEnabled ? ' enabled' : ' disabled')
              }
              onClick={() =>
                props.communicationModalType === 'email' ||
                (props.communicationModalType === 'text' &&
                  getNumberOfSelectedContacts() <= smsCost.SMSFreeLimit)
                  ? handleSendCommunication()
                  : setOpenBatchPopup(true)
              }
            >
              <FontAwesomeIcon icon={['fas', 'paper-plane']} /> Send{' '}
              {capitalize(props.communicationModalType)}
            </Button>
          </div>
        </div>
      </ReactModal>
      <ModalInsertPersonalize
        openPopupInsertToken={openInsertPersonalize}
        setOpenPopupInsertToken={setOpenInsertPersonalize}
        handleConfirmInsertToken={handleInsertData}
        token={tokenData}
        screen={'manual'}
      />
      <BatchPopup
        open={openBatchPopup}
        content={
          <>
            {props.communicationModalType === 'postal' ? (
              <>
                The selected batch Postal job has an estimated cost of $
                <span className='red'>
                  {formatNumber(postalCost * getNumberOfSelectedContacts())}
                </span>
                . You will be invoiced for the actual cost at the end of the
                monthly billing cycle. Please confirm or cancel the job.
              </>
            ) : (
              <>
                This batch message exceeds{' '}
                <span className='red'>{smsCost.SMSFreeLimit}</span> recipients.
                Your account will be charged for the additional{' '}
                <span className='red'>
                  {getNumberOfSelectedContacts() > smsCost.SMSFreeLimit
                    ? getNumberOfSelectedContacts() - smsCost.SMSFreeLimit
                    : '0'}
                </span>{' '}
                recipients for a total of $
                <span className='red'>
                  {getNumberOfSelectedContacts() > smsCost.SMSFreeLimit
                    ? formatNumber(
                        smsCost.SMSCost *
                          (getNumberOfSelectedContacts() - smsCost.SMSFreeLimit)
                      )
                    : '0'}
                </span>{' '}
                if sent via Text. You also have the option to send via Email at
                no cost. Please confirm your choice.
              </>
            )}
          </>
        }
        onClose={setOpenBatchPopup}
        onSend={handleSendCommunication}
        type={communicationModalType}
      />
      <WarningPopup open={openWarningPopup} onClose={setOpenWarningPopup} />
    </>
  );
};

export default SendCommunicationModal;
