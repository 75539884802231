import * as t from 'io-ts';
import { TenantResposne } from '../ResponseTypes';
import { FullPetInfoResponse } from './Pets';
import { FullPhoneNumberEntryData } from './_PhoneNumber';

const RolePermissionType = t.partial({
  roleId: t.string,
  roleName: t.string,
});

// TenantUserDataResponse
const RequiredUserInfo = t.type({
  id: t.string,
  enabled: t.boolean,
  roles: t.array(RolePermissionType),
});

const NullableUserInfo = t.partial({
  emailAddress: t.string,
  name: t.string,
  pets: t.array(FullPetInfoResponse),
  phoneNumbers: t.array(FullPhoneNumberEntryData),
  externalStatus: t.string,
  externalId: t.string,
  pulseUserStatus: t.string, // TODO make enum
  isMessageRead: t.boolean,
  latestMessageDate: t.string,
});
export const FullUserInfoResponse = t.intersection([
  RequiredUserInfo,
  NullableUserInfo,
]);
export type FullUserInfo = t.TypeOf<typeof FullUserInfoResponse>;

export const TenantAllUserData = t.type({
  count: t.number,
  totalAvailable: t.number,
  offset: t.number,
  users: t.array(FullUserInfoResponse),
});
export type TenantUserDataResponse = t.TypeOf<typeof TenantAllUserData>;
export const TenantFilteredContactData = t.type({
  count: t.number,
  totalAvailable: t.number,
  users: t.array(FullUserInfoResponse),
});
export type TenantFilteredDataResponse = t.TypeOf<
  typeof TenantFilteredContactData
>;
//
// TenantInfoResponse
const RequiredTenantInfo = t.type({
  tenantId: t.string,
  createdBy: t.string,
  createdAt: t.string,
  tenantGroupId: t.string,
});

const NullableTenantInfo = t.partial({
  tenantName: t.string,
  micrositeName: t.string,
  postalProvider: t.string,
});
export const FullTenantInfoResponse = t.intersection([
  RequiredTenantInfo,
  NullableTenantInfo,
]);
export type TenantInfoResponse = t.TypeOf<typeof FullTenantInfoResponse>;
//

const NullableSyncerError = t.partial({
  status: t.string,
  errorCount: t.number,
  latestErrorDate: t.string,
});

export const TenantSyncerError = t.intersection([
  TenantResposne,
  NullableSyncerError,
]);

export const TenantSyncerErrorCollection = t.type({
  count: t.number,
  totalAvailable: t.number,
  tenants: t.array(TenantSyncerError),
});

export type TenantSyncerErrorType = t.TypeOf<typeof TenantSyncerError>;

export type TenantSyncerErrorCollectionType = t.TypeOf<
  typeof TenantSyncerErrorCollection
>;
