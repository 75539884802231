import styled from 'styled-components';

const StyledH3 = styled.h3`
  background-color: white;
  margin-left: -10%;
  margin-right: -10%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: -4%;
  padding-top: 4%;
  padding-bottom: 1rem;
`;
// eslint-disable-next-line import/prefer-default-export
export { StyledH3 };
