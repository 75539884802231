import APIService from '../clientApi';
import {
  INewGroupDataResponse,
  IAppointmentResponse,
  IActiveTaskResponse,
  IGroupInfo,
} from './types';

const API = APIService();

/*
 * Params:
 * 1: groupId
 * 2: start time
 * 3: end time
 * 4: timezone
 * */
const getNewMembersForGroupByDateRange = (
  params: [string, string | Date, string | Date, string?],
): Promise<INewGroupDataResponse> =>
  API.rpc('', { method: 'insight.getNewMembersForGroupByDateRange', params });

/*
 * Params:
 * 1: page
 * 2: number per page
 * 3: filter Obj
 */
const getAppointmentByPage = (
  params: [
    number,
    string,
    {
      filter: {
        status?: { $in: string[] };
        contact?: string;
        appointment_time?: { $gt: string; $lt: string };
      };
    },
  ],
): Promise<IAppointmentResponse> => API.rpc('', { method: 'appointment.findPage', params });

/*
 * Params:
 * contactId
 * */
const getActiveTasksForContactId = (params: [string]): Promise<IActiveTaskResponse> =>
  API.rpc('', { method: 'contact_task.getActiveTasksForContactId', params });

/*
 * Params:
 * 1: appointmentId
 * 2: status (confirm: "confirmed", cancel - remove: "canceled")
 * */
const setAppointmentStatus = (params: [string, string]): Promise<{ p: [null] }> =>
  API.rpc('', { method: 'appointment.setStatus', params });

// const getTouchPoint = (params: []): Promise<void> => API.rpc('', { method: '', params });

/*
 * contact_id
 * contact_task_definition_id
 * */
const setTaskCompleted = (params: [string, string]): Promise<{ p: [null, number[]] }> =>
  API.rpc('', { method: 'contact_task.setTasksCompletedForContactId', params });

/*
 * Params
 * Touchpoint ID
 */
const cancelTouchPoint = (params: [string]): Promise<void> =>
  API.rpc('', { method: 'touchpoint.cancelDelivery', params });

const getPagedTouchpoints = (
  params: [
    number,
    number,
    { child_sent_count?: any; groups?: string | string[]; recipients?: string | string[] },
  ],
): Promise<{ p: [null, any] }> => API.rpc('touchpoint', { method: 'findPaged', params });

const getGroup = (
  params: [string],
  isResultOnly?: boolean,
): Promise<{ p: [null, IGroupInfo] } | IGroupInfo> =>
  API.rpc('group', { method: 'findById', params }, undefined, isResultOnly);

const countContactsInGroup = (
  params: [string, any],
  isResultOnly?: boolean,
): Promise<{ p: [null, any] }> =>
  API.rpc('group', { method: 'countContactsInGroup', params }, undefined, isResultOnly);

const setContactStatus = (params: [string, string]): Promise<{ p: [null, any] }> =>
  API.rpc('contact', { method: 'setStatus', params });

const setAddGroup = (params: [string, string]): Promise<{ p: [null, any] }> =>
  API.rpc('contact', { method: 'addGroup', params });

const setRemoveGroup = (params: [string, string]): Promise<{ p: [null, any] }> =>
  API.rpc('contact', { method: 'removeGroup', params });

const findContactGroups = (params: [string]): Promise<{ p: [null, any] }> =>
  API.rpc('contact', { method: 'contactFindGroups', params });

const setAppointment = (
  params: [{ contact: string; appointment_time: string | Date; _id: any }],
): Promise<{ p: [null, any] }> => API.rpc('appointment', { method: 'insert', params });

export default {
  findContactGroups,
  setAppointment,
  getNewMembersForGroupByDateRange,
  getAppointmentByPage,
  getActiveTasksForContactId,
  setAppointmentStatus,
  setTaskCompleted,
  cancelTouchPoint,
  getPagedTouchpoints,
  getGroup,
  countContactsInGroup,
  setContactStatus,
  setAddGroup,
  setRemoveGroup,
};
