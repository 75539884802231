import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import getThemeProp from '../../utils/getThemeProp';
import sizeConstants from '../sizeConstants';

const SmallDarkTextHeavy = styled(Typography)`
  font-size: ${sizeConstants.small.size};
  line-height: ${sizeConstants.small.height};
  font-family: ${getThemeProp('typography.fontFamily', 'sans-serif')};
  color: ${getThemeProp('pallette.text.primary', '#12253F')};
  font-weight: 500;
` as typeof Typography;

export default SmallDarkTextHeavy;
