/* eslint-disable no-underscore-dangle */
// As this file grows it could be split into other files and then those
// can be imported into this one.
export const SESSION_TOKEN = 'lwsid';
export const UI_ROOT = `/practice`;
export const LARGE_SCREEN = 1100; // Greater thant this is consider large
export const API_DOMAIN = 'http://localhost:3001';
export const SUPPORT_EMAIL = 'support@legwork.com';
export const getRootDomain = (includeApi = true): string => {
  // for local development outside of the docker container can change to http://localhost:3001
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let baseUrl = (window as any)._env_?.LEGWORK_BASE_URL || window.location.origin;
  if (!includeApi) {
    baseUrl = baseUrl.replace(/(api\.)/gi, '');
  }
  return baseUrl;
};
export const LOGIN_URL = getRootDomain(false);
export const LEGWORK_LOGO = `${getRootDomain(false)}/dso/images/legwork-2019-logo-01.svg`;
export const ACCOUNT_SERVICE_ROOT = `${getRootDomain()}/account/v1/`;
export const STORAGE_SERVICE_ROOT = `${getRootDomain()}/storage/v1/`;
export const LOGIN_DOMAIN = LOGIN_URL;
export const RPC_PATH = `${getRootDomain()}/rpc`;
export const API_PATH = `${getRootDomain()}/api/`;
export const DATA_SERIES_LIMITS = {
  default: 5,
  topPages: 8,
};
// better way to make this more data-driven?
export const REPORTS = {
  allAccounts: 1,
  recareRate: 2,
  averageProductionPerVisit: 4,
  netPromoterScore: 5,
  averageReview: 6,
};
