/* eslint-disable default-case */
import $ from 'jquery';
import React from 'react';

import { debounce } from 'lodash';

const ReactDOM = require('react-dom');

const main = {};

main.json = require('./general/json.js');
main.shared = require('./general/shared.js');
main.IdleNotifier = require('./idle-notifier');
main.number = require('./number.js');
main.string = require('./general/string.js');
main.memoize = require('./general/memoize.js');
main.icons = require('./icons');
main.charWidth = require('./charWidth.jsx');

main.isUndefined = function isUndefined(obj) {
  return typeof obj === 'undefined';
};

main.isNone = function isNoneF(obj) {
  return obj === null || obj === void 0;
};

main.isNotNone = function isNotNoneF(obj) {
  return !main.isNone(obj);
};

main.isEmpty = function isEmptyF(obj) {
  return main.isNone(obj) || (obj.length === 0 && typeof obj !== 'function');
};

main.isNotEmpty = function isNotNoneF(obj) {
  return !main.isEmpty(obj);
};

main.isEmptyObject = function isEmptyObjectF(obj) {
  return Object.keys(obj).length === 0;
};

main.isFunc = function isFuncF(obj) {
  return typeof obj === 'function';
};

main.isNum = function isNumF(obj) {
  return typeof obj === 'number';
};

main.isObject = function isObjectF(obj) {
  return !main.isNone(obj) && typeof obj === 'object';
};

main.isArray = function isArrayF(obj) {
  return Array.isArray(obj);
};

// test if n represents a valid number, not necessarily a Number type
main.isNumber = function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

// Capitalize the first letter in a string
main.capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

main.cleanString = function cleanString(str) {
  return str
    .split('-')
    .map(function capitalize(part) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(' ');
};

main.get = function (obj, path) {
  const keys = Array.prototype.slice
    .call(arguments, 1)
    .join('.')
    .split('.');

  for (let i = 0; i < keys.length; i++) {
    if (!main.isObject(obj)) {
      return;
    }

    if (main.isArray(obj) && /^[0-9]+$/.test(keys[i])) {
      obj = obj[parseInt(keys[i], 10)];
    } else {
      obj = obj[keys[i]];
    }

    if (typeof obj === 'undefined') {
      return;
    }
  }

  return obj;
};

main.set = function (obj, path, value) {
  const keys = path.split('.');
  const base = obj;

  if (!main.isObject(base)) {
    return;
  }

  for (let i = 0; i < keys.length - 1; i++) {
    if (!main.isObject(obj[keys[i]])) {
      obj = obj[keys[i]] = {};
    } else {
      obj = obj[keys[i]];
    }
  }

  obj[keys[keys.length - 1]] = value;

  return base;
};

main.isOverflowedWidth = function (element) {
  return element.scrollWidth > element.clientWidth;
};

main.isOverflowedHeight = function (element) {
  return element.scrollHeight > element.clientHeight;
};

main.isOverflowed = function (element) {
  return main.isOverflowedWidth(element) || main.isOverflowedHeight(element);
};

main.renderModal = function (modal, options, callback) {
  callback = callback || function () { };

  const container = document.body.appendChild(document.createElement('div'));
  let component;

  const props = {
    ...options,
    onClose(closeAction) {
      ReactDOM.unmountComponentAtNode(container);
      container.remove();
      callback(closeAction);
    },
  };

  component = ReactDOM.render(React.createElement(modal, props), container);
  return component;
};

main.renderReactComponent = function (component, props) {
  main.renderModal(component, props);
};

main.createFilledArray = function (filler, length) {
  const filledArray = [];

  while (length) {
    filledArray[--length] = filler;
  }

  return filledArray;
};

// TODO: Need to remove this when when the contact detail view is replaced with a React view
// main.makeContactTransitioner = function (contactId) {
//   return function () {
//     App.__container__.lookup('router:main').transitionTo(`/contacts/Customer/${contactId}`);
//   };
// };

main.promiseFromCallback = function (callback) {
  return new Promise(function (resolve, reject) {
    callback(function (err, result) {
      !err ? resolve(result) : reject(err);
    });
  });
};

main.mash = function (arr, key) {
  const mapHash = {};

  if (!main.isArray(arr)) {
    return mapHash;
  }

  arr.forEach(function (obj) {
    if (!main.isObject(obj) || main.isEmpty(obj[key])) {
      return;
    }

    mapHash[obj[key]] = obj;
  });

  return mapHash;
};

main.reverse = function (str) {
  return str
    .split('')
    .reverse()
    .join('');
};

main.onceFn = function (fn) {
  const self = this;
  let didCall = false;

  return function () {
    if (!didCall) {
      didCall = true;
      fn.apply(self, arguments);
    }
  };
};

main.loadCachedScript = function (url) {
  return $.ajax({
    dataType: 'script',
    cache: true,
    url,
  });
};

main.loadCachedScripts = function (arr) {
  const _arr = arr.map(main.loadCachedScript);

  _arr.push(
    $.Deferred(function (deferred) {
      $(deferred.resolve);
    }),
  );

  return $.when.apply($, _arr);
};

main.dataURLToBlob = function (dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

// debounceEventHandler creates a debounce function that persists events
// that are passed to it. See: https://github.com/facebook/react/issues/7778
main.debounceEventHandler = function (fn, wait, immediate) {
  const debounceFn = debounce(fn, wait, immediate);

  return function debounceEventHandlerF(evt) {
    const args = Array.prototype.slice.call(arguments);
    if (evt && evt.persist) {
      evt.persist();
    }
    debounceFn.apply(this, args);
  };
};

main.touchpointExternalStatusLabel = function (status) {
  switch (status) {
    case 'list_unsubscribe':
      status = 'unsubscribed';
      break;
    case 'spam_complaint':
      status = 'spammed';
      break;
    case 'click':
      status = 'clicked';
      break;
    case 'open':
      status = 'opened';
      break;
    case 'delivery':
      status = 'delivered';
      break;
    case 'delay':
      status = 'delayed';
      break;
    case 'bounce':
      status = 'bounced';
      break;
    case 'policy_rejection':
      status = 'policy rejected';
      break;
    case 'generation_failure':
      status = 'message failed';
      break;
    case 'generation_rejection':
      status = 'message rejected';
      break;
  }
  return status.capitalize();
};

main.formatDate = function (date, format) {
  return new Date(date).toString(format);
};

main.formatPhoneNumber = function (phone) {
  let extension;
  let number = phone;

  if (phone instanceof Object) {
    let obj = phone;

    // check if ember object
    const attributes = main.get(phone, '_attributes');
    if (attributes) {
      obj = attributes;
    }

    extension = main.get(obj, 'extension');
    number = main.get(obj, 'number');
  }
  if (main.isNone(number)) {
    return;
  }
  number = number.replace(/\D/g, '');
  if (typeof extension !== 'undefined') {
    extension = extension.replace(/\D/g, '');
  }
  return number.replace(/^([0-9]{3})?([0-9]{3})([0-9]{4})([0-9]{5})?$/, function (
    $0,
    $1,
    $2,
    $3,
    $4,
  ) {
    extension = extension || $4;
    return `${(typeof $1 !== 'undefined' ? `(${$1}) ` : '') + $2}-${$3}${
      typeof extension !== 'undefined' ? ` x${extension}` : ''
      }`;
  });
};

main.dayNumberToString = function (number) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return days[parseInt(number, 10)];
};

/*
  const add1 = n => n + 1;
  const double = n => n * 2;
 
  const doubleThenAdd1 = main.pipe(
    double,
    add1
  );
 
  doubleThenAdd1(2); // 5
 */
main.pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

main.negatePredicate = function (predicate) {
  return function () {
    return !predicate.apply(this, arguments);
  };
};

main.getTimezone = function () {
  let timezone;

  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.log('Unable to determine timezone:', e);
  }

  return timezone;
};

export default main;
