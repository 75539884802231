import React from 'react';
import Switch from 'components/Global/Switch/Switch';
import TimePickers from 'components/Global/TimePicker/TimePicker';
import { HoursWrapper } from './HoursWrapper';

interface IProps {
  startTime: string;
  endTime: string;
  enabled: boolean;
  titleDay: string;
  onChangeToogle: (dayOrder: number) => void;
  onChangePicker: (dayOrder: number, event: any) => void;
  dayOrder: number;
  error: string;
  disabledAll?: boolean;
}

const SelectDateToogle = (props: IProps) => {
  const {
    startTime,
    endTime,
    titleDay,
    enabled = false,
    onChangePicker,
    onChangeToogle,
    dayOrder,
    error,
    disabledAll = false,
  } = props;
  return (
    <HoursWrapper>
      <div className='toogle-date-picker'>
        <div className='toogle-operation'>
          <Switch
            checked={enabled}
            onChange={() => !disabledAll && onChangeToogle(dayOrder)}
          />
        </div>
        <div className='day-operation'>
          <span className='title-day'>{titleDay}</span>
        </div>
        <TimePickers
          onChange={(event) => onChangePicker(dayOrder, event)}
          value={startTime}
          disabled={disabledAll || !enabled}
          name='startTime'
          className='select-start-time'
          // showDefault={item.endTime.length === 0 && !item.active}
          placeholderContainerClass='placeholder-time d-flex'
          placeholderText='Start Time'
          placeholderTextClass='placeholder-text m-0'
        />

        <TimePickers
          onChange={(event) => onChangePicker(dayOrder, event)}
          value={endTime}
          name='endTime'
          className='select-end-time'
          disabled={disabledAll || !enabled}
          data-set=''
          // showDefault={item.endTime.length === 0 && !item.active}
          placeholderContainerClass='placeholder-time d-flex'
          placeholderText='End Time'
          placeholderTextClass='placeholder-text m-0'
        />
      </div>
      <div>{error && <p className='error-text'>{error}</p>}</div>
    </HoursWrapper>
  );
};

export default SelectDateToogle;
