import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import loading_spinner from 'media/images/loading-spinner.svg';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import toast from 'components/Global/Toast';
import { DriversResponse } from 'global/requests/ResponseTypes';

const ConfigurationSettingStyle = styled.div`
  width: 100%;
  margin: 0;

  .MuiButton-root {
    margin-top: 25px;
  }
`;

interface ConfigurationFieldType {
  Key?: string;
  Value?: boolean;
  Configurable?: boolean;
  Description?: string;
}

const ConfigurationSetting = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [configurationFields, setConfigurationFields] = useState<
    ConfigurationFieldType[]
  >([]);

  useEffect(() => {
    getDriverInfo();
  }, []);

  const getDriverInfo = async () => {
    try {
      const res = await configuredRequests.GET.getDriverConfigurationSetting();
      if (res) {
        // @ts-ignore
        setConfigurationFields(JSON.parse(res.configurationField ?? '[]'));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  };

  const saveSettingConfiguration = async () => {
    try {
      const submitData = {
        configurationField: JSON.stringify(configurationFields),
      };
      const res = await configuredRequests.PATCH.updateDriverConfiguration(
        submitData as any
      );
      if (res) {
        toast.success('Your data has been saved successfully!');
      }
    } catch (err) {
      toast.error('Your data has not been saved yet. Please try again.');
    }
  };

  const handleChange = (field: ConfigurationFieldType) => {
    const idx = configurationFields.findIndex((item) => item.Key === field.Key);
    setConfigurationFields((prevFields: ConfigurationFieldType[]) => {
      return [
        ...prevFields.slice(0, idx),
        { ...prevFields[idx], Value: !prevFields[idx].Value },
        ...prevFields.slice(idx + 1),
      ];
    });
  };

  return (
    <ConfigurationSettingStyle className='system-setting__main'>
      {isLoading ? (
        <img
          className='loading-general-syncer'
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        <>
          {configurationFields?.length > 0 &&
            configurationFields?.filter(
              (field: any) => !!field[Object.keys(field)?.[2]]
            )?.length > 0 && (
              <>
                {configurationFields
                  // @ts-ignore
                  .filter((field: any) => field['Configurable '])
                  .map((item: any) => (
                    <div key={item.Key ?? ''}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            checked={item.Value ?? false}
                            onChange={() => handleChange(item)}
                          />
                        }
                        // @ts-ignore
                        label={item[Object.keys(item)?.[3]] ?? ''}
                      />
                    </div>
                  ))}

                <Button
                  // disabled={!isValid}
                  variant='contained'
                  color='primary'
                  type='submit'
                  onClick={saveSettingConfiguration}
                >
                  Save
                </Button>
              </>
            )}
        </>
      )}
    </ConfigurationSettingStyle>
  );
};

export default ConfigurationSetting;
