/*
  This component is to call API to get syncer stoppage that happens to Vet Hero's client
*/



import React, { useCallback, useEffect, useState } from 'react';
import Table from 'components/Global/Table';
import Button from '@material-ui/core/Button';

import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { CollectionSyncerStopPageType } from 'global/requests/ResponseTypes';
import './SyncerStopPage.scss';

export interface IQuery {
  limit: number;
  offset: number;
}

const columns = [
  { key: 'tenantName', title: 'Group/Practice Name' },
  { key: 'lastAuth', title: 'Last Auth' },
  { key: 'lastSync', title: 'Last Sync' },
  { key: 'driverName', title: 'Driver' },
  { key: 'driverVersion', title: 'Driver Version' },
  { key: 'action', title: '' },
];

const SyncerStopPage = () => {
  const [queryPagination, setQueryPagination] = useState({
    limit: 10,
    offset: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [syncerErrors, setSyncerErrors] =
    useState<CollectionSyncerStopPageType>({
      count: 0,
      totalAvailable: 0,
      driverTenants: [],
    });

  useEffect(() => {
    getSyncerErrors(queryPagination);
  }, [queryPagination]);

  const getSyncerErrors = useCallback(
    async (query: IQuery, disabledLoading?: boolean) => {
      !disabledLoading && setIsLoading(true);
      try {
        const res = await configuredRequests.GET.getSyncerStopPages({
          limit: query.limit,
          offset: query.offset,
        });
        if (res) {
          setSyncerErrors(res);
          !disabledLoading && setIsLoading(false);
        }
      } catch (err) {
        !disabledLoading && setIsLoading(false);
        console.log('error: ', err);
      }
    },
    []
  );

  const handleChangePage = useCallback(
    async (event: unknown, newPage: number) => {
      setQueryPagination((preState) => ({
        ...preState,
        offset: newPage,
      }));
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage: number = parseInt(event.target.value);
      if (newRowsPerPage) {
        setQueryPagination((prev) => ({
          ...prev,
          limit: newRowsPerPage,
          offset: 0,
        }));
      }
    },
    []
  );

  return (
    <div className='communication-container user-setting w-300 container-practice container-alert-syncer setting-scroll--custom'>
      <div className='communication-container__table w-table-cell templates-mail-sms m-0'>
        {isLoading ? (
          <b className='loading'>Loading...</b>
        ) : (
          <>
            {syncerErrors.driverTenants?.length > 0 ? (
              <Table
                columns={columns}
                // @ts-ignore
                rows={syncerErrors.driverTenants.map((item) => ({
                  ...item,
                  tenantName: (
                    <span
                      className='practice-name'
                    >
                      {item.tenantName ?? ''}
                    </span>
                  ),
                  action: (
                    <Button
                      variant='contained'
                      color='primary'
                      className='btn-login-practice'
                      onClick={() =>
                        window.open(
                          `/AdminLoginPractice/group/${item.groupId}/practice/${item.tenantId}`
                        )
                      }
                    >
                      Login to Practice
                    </Button>
                  ),
                }))}
                isHasPagination={true}
                // @ts-ignore
                totalAvailable={syncerErrors.totalAvailable ?? 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={queryPagination?.limit ?? 10}
                page={queryPagination?.offset ?? 0}
              />
            ) : (
              <p className='no-data-results' style={{ fontWeight: 'bold' }}>
                No results found
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SyncerStopPage;
