
/**
* Returns querystring regex pattern.
 */
export const queryStringPattern: RegExp = /([^?=&]+)(=([^&]*))?/g;

/**
* Returns email regex pattern.
 */
export const emailPattern: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
* Returns password regex pattern.
 */
export const passwordPattern: RegExp = /^(?=.*[a-z])(?=.*\d)[a-z\d]{8,}$/;

/**
* Returns phone regex pattern.
 */
export const phonePattern: RegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const phoneNumberPattern: RegExp = /^[0-9]{10}$/;
/**
* Returns appointment id pattern
 */
export const appointmentIdPattern: RegExp = /^(\d{3})(\d{3})(\d{3})$/;

export const colorPickerPattern: RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export const datePickerPattern: RegExp = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

export const linkHttp: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

export const domainPattern: RegExp = /^[a-z0-9]*[a-z0-9-][a-z0-9-]*$/;

export const urlPattern: RegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

export const urlHeadPattern: RegExp = /https:\/\/|http:\/\/|www/;