import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormik } from 'formik';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import React from 'react';
import {
  DeliverApproximatelyResponse,
  ScheduledKindsData,
  TemplateResponse,
} from '../../../../global/requests/ResponseTypes';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  AppointmentTypeResponse,
  AppointmentTypeSetting,
} from 'global/requests/ResponseTypes/AppointmentTypeSetting';
import { SpeciesSettingType } from 'global/requests/ResponseTypes/SpeciesSetting';
import toast from 'components/Global/Toast';
import { Medical } from 'global/requests/ResponseTypes/Medical';
import { useDecodeToken } from 'hooks/useDecodeToken';

interface IProps {
  triggerId: string;
  checkedAllow?: boolean;
  setOpenFragment?: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckAllow?: React.Dispatch<React.SetStateAction<boolean>>;
  emailTemplateData: TemplateResponse;
  postalTemplateData: TemplateResponse;
  smsTemplateData: TemplateResponse;
  callTemplateData: TemplateResponse;
  allowSendOutsideDeliveryHours: boolean;
  fallbackData: any[];
  primaryMessage: any;
  deliverApproximatelyData: DeliverApproximatelyResponse;
  appointmentTypeApplyKind: string;
  medicalServiceApplyKind: string;
  listAppointmentTypes: AppointmentTypeSetting[];
  listSpecies: SpeciesSettingType[];
  listMedicalServices: Medical[];
  timeQuantity: number;
  timeUnit: string;
  scheduleKind: string;
  setCurrentAutomationOpen?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  categoryKind: string;
  automationTriggerKey?: string;
  automationCategoryId?: string;
  automationTriggerEventId: string;
  enabledStatus?: boolean;
  appointmentTypesSelected: AppointmentTypeResponse[];
  medicalServicesSelected: Medical[];
  speciesSelected: SpeciesSettingType[];
  checkNewEvent(a: boolean): void;
}

const AutomationEventSchema = Yup.object().shape({
  messageType: Yup.string().required(),
  methodMessage: Yup.string().required(),
  timeValue: Yup.number().required(),
  timeType: Yup.string().required(),
  period: Yup.string().required(),
  approx: Yup.array().of(
    Yup.object().shape({
      messageKind: Yup.string().required(),
      templateId: Yup.string().required(),
    })
  ),
  allowSendOutsideDeliveryHours: Yup.boolean(),
});

const TYPE_EMAIL = 'email';
const TYPE_SMS = 'sms';
const TYPE_CALL = 'call';
const TYPE_POSTAL = 'postal';

const AutomationEditForm: React.FC<IProps> = ({
  scheduleKind,
  timeQuantity,
  timeUnit,
  appointmentTypeApplyKind,
  appointmentTypesSelected,
  medicalServiceApplyKind,
  medicalServicesSelected,
  speciesSelected,
  deliverApproximatelyData,
  listAppointmentTypes,
  listMedicalServices,
  listSpecies,
  primaryMessage,
  emailTemplateData,
  fallbackData,
  allowSendOutsideDeliveryHours,
  smsTemplateData,
  callTemplateData,
  postalTemplateData,
  setOpenFragment,
  setCurrentAutomationOpen,
  categoryKind,
  automationTriggerKey,
  automationCategoryId,
  automationTriggerEventId,
  triggerId,
  enabledStatus,
  checkNewEvent,
}): JSX.Element => {
  const [isDirtyForm, setIsDirtyForm] = React.useState<boolean>(false);

  const [searchAppointmentTypes, setSearchAppointmentTypes] =
    React.useState<boolean>(
      appointmentTypeApplyKind === 'apply' && !!appointmentTypesSelected.length
    );
  const [searchNotAppointmentTypes, setSearchNotAppointmentTypes] =
    React.useState<boolean>(
      appointmentTypeApplyKind === 'notApply' &&
        !!appointmentTypesSelected.length
    );
  const [focusAppointmentTypes, setFocusAppointmentTypes] =
    React.useState<boolean>(false);
  const [errorApt, setErrorApt] = React.useState<boolean>(false);
  const [focusNotAppointmentTypes, setFocusNotAppointmentTypes] =
    React.useState<boolean>(false);
  const [errorNoApt, setErrorNoApt] = React.useState<boolean>(false);
  const [optionAppointmentKind, setOptionAppointmentKind] =
    React.useState<string>(appointmentTypeApplyKind);

  const [searchMedicalServices, setSearchMedicalServices] = React.useState(
    medicalServiceApplyKind === 'apply' && !!medicalServicesSelected.length
  );
  const [searchNotMedicalServices, setSearchNotMedicalServices] =
    React.useState(
      medicalServiceApplyKind === 'notApply' && !!medicalServicesSelected.length
    );
  const [errorMedical, setErrorMedical] = React.useState(false);
  const [errorNoMedical, setErrorNoMedical] = React.useState(false);
  const [focusMedicalServices, setFocusMedicalServices] = React.useState(false);
  const [focusNotMedicalServices, setFocusNotMedicalServices] =
    React.useState(false);
  const [optionMedicalKind, setOptionMedicalKind] = React.useState(
    medicalServiceApplyKind
  );

  const [searchSpecies, setSearchSpecies] = React.useState<boolean>(
    !!speciesSelected.length
  );
  const [focusSpecies, setFocusSpecies] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.localStorage.setItem('isAutomationEdited', isDirtyForm.toString());
  }, [isDirtyForm]);

  const getAppointmentTypesSelected = () => {
    if (!!listAppointmentTypes.length) {
      const appointmentTypesOptionSelected = appointmentTypesSelected
        ?.filter((item) => {
          return (
            listAppointmentTypes.findIndex((appointmentType) => {
              return item.id === appointmentType.id;
            }) > -1
          );
        })
        .map((apt) => ({
          id: apt.id,
          appointmentType: apt.typeName,
          appointmentDuration: apt.appointmentDuration,
        }));
      return appointmentTypesOptionSelected;
    }
    return [];
  };

  const getMedicalServicesSelected = () => {
    if (!!listMedicalServices.length) {
      const medicalServicesOptionSelected = medicalServicesSelected?.filter(
        (item) => {
          return (
            listMedicalServices.findIndex((medicalService) => {
              return item.medicalServiceId === medicalService.medicalServiceId;
            }) > -1
          );
        }
      );

      return medicalServicesOptionSelected;
    }
    return [];
  };

  const getSpeciesSelected = () => {
    if (!!listSpecies.length) {
      const speciesOptionSelected = speciesSelected?.filter((item) => {
        return (
          listSpecies.findIndex((specie) => {
            return item.id === specie.id;
          }) > -1
        );
      });
      return speciesOptionSelected;
    }
    return [];
  };

  const initialValues = {
    appointmentTypeApplyKind: appointmentTypeApplyKind,
    appointmentTypes:
      appointmentTypeApplyKind === 'apply' ? getAppointmentTypesSelected() : [],
    appointmentTypesToNotSend:
      appointmentTypeApplyKind === 'notApply'
        ? getAppointmentTypesSelected()
        : [],
    medicalServiceApplyKind: medicalServiceApplyKind,
    medicalServices:
      medicalServiceApplyKind === 'apply' ? getMedicalServicesSelected() : [],
    medicalServicesNotApply:
      medicalServiceApplyKind === 'notApply'
        ? getMedicalServicesSelected()
        : [],
    species: getSpeciesSelected(),
    messageType: primaryMessage.messageKind,
    methodMessage: primaryMessage.templateId,
    timeValue: timeQuantity,
    timeType: timeUnit,
    period: scheduleKind,
    approx: fallbackData,
    allowSendOutsideDeliveryHours: allowSendOutsideDeliveryHours,
  };

  const handleFocus = (type: number) => {
    // type : 1 -> apply appointmentTypes
    //        2 -> not apply appointmentTypes
    //        3 -> species
    switch (type) {
      case 1:
        setSearchAppointmentTypes(true);
        setFocusAppointmentTypes(true);
        break;
      case 2:
        setSearchNotAppointmentTypes(true);
        setFocusNotAppointmentTypes(true);
        break;
      case 3:
        setSearchSpecies(true);
        setFocusSpecies(true);
        break;
      case 4:
        setSearchMedicalServices(true);
        setFocusMedicalServices(true);
        break;
      case 5:
        setSearchNotMedicalServices(true);
        setFocusNotMedicalServices(true);
        break;
      default:
        break;
    }
  };

  const handleBlur = (
    type: number,
    arr: AppointmentTypeSetting[] | Medical[] | SpeciesSettingType[]
  ) => {
    switch (type) {
      case 1:
        setFocusAppointmentTypes(false);
        !arr.length && setSearchAppointmentTypes(false);
        !arr.length && setErrorApt(true);
        break;
      case 2:
        setFocusNotAppointmentTypes(false);
        !arr.length && setSearchNotAppointmentTypes(false);
        !arr.length && setErrorNoApt(true);
        break;
      case 3:
        setFocusSpecies(false);
        !arr.length && setSearchSpecies(false);
        break;
      case 4:
        setFocusMedicalServices(false);
        !arr.length && setSearchMedicalServices(false);
        !arr.length && setErrorMedical(true);
        break;
      case 5:
        setFocusNotMedicalServices(false);
        !arr.length && setSearchNotMedicalServices(false);
        !arr.length && setErrorNoMedical(true);
        break;
      default:
        break;
    }
  };

  const validDropdownObject: { [key: string]: boolean } = {
    primary: true,
  };
  fallbackData.forEach((data, index) => {
    validDropdownObject[`fallback${index + 1}`] = true;
  });

  const { handleSubmit, handleChange, values, setFieldValue, isValid } =
    useFormik({
      initialValues,
      onSubmit: async (values) => {
        const submittingData = {
          enabled: enabledStatus || true,
          appointmentTypeApplyKind: optionAppointmentKind,
          appointmentTypes:
            optionAppointmentKind === 'all'
              ? []
              : optionAppointmentKind === 'apply'
              ? values.appointmentTypes?.map(
                  (apt: Partial<AppointmentTypeSetting>) => ({
                    id: apt.id,
                  })
                )
              : values.appointmentTypesToNotSend?.map(
                  (apt: Partial<AppointmentTypeSetting>) => ({ id: apt.id })
                ),
          reminderMedicalServiceApplyKind: optionMedicalKind,
          reminderMedicalServices:
            optionMedicalKind === 'all'
              ? []
              : optionMedicalKind === 'apply'
              ? values.medicalServices?.map(
                  (medical: Partial<Medical>) => medical.medicalServiceId
                )
              : values.medicalServicesNotApply?.map(
                  (medical: Partial<Medical>) => medical.medicalServiceId
                ),
          petSpecies: values.species || [],
          allowSendOutsideDeliveryHours: values.allowSendOutsideDeliveryHours,
          categoryKind: categoryKind || '',
          automationTriggerKey: automationTriggerKey || '',
          automationCategoryId: automationCategoryId || '',
          automationTriggerId: triggerId || '',
          timeQuantity: Number(values.timeValue),
          timeUnit: values.timeType || '',
          scheduleKind: values.period || '',
          communicationTemplates: (() => {
            const returnArray = [];
            let count = 0;
            returnArray.push({
              messageKind: values.messageType,
              triggerTemplateId: primaryMessage.triggerTemplateId,
              ordinal: count,
              templateId: values.methodMessage,
            });

            values.approx.forEach(
              (item: {
                messageKind: any;
                triggerTemplateId: any;
                ordinal: any;
                templateId: any;
              }) => {
                if (!item.messageKind || !item.templateId) {
                  return;
                }
                count += 1;
                returnArray.push({
                  ordinal: count,
                  templateId: item.templateId,
                  messageKind: item.messageKind,
                  triggerTemplateId: item.triggerTemplateId
                    ? item.triggerTemplateId
                    : '',
                });
              }
            );
            return returnArray;
          })(),
        };
        try {
          const res =
            await configuredRequests.PATCH.UpdateAutomationTriggerEvent(
              // @ts-ignore
              submittingData,
              automationTriggerEventId
            );
          if (res) {
            setIsDirtyForm(false);
            setCurrentAutomationOpen && setCurrentAutomationOpen(null);
            setOpenFragment && setOpenFragment(false);
            checkNewEvent(true);
            toast.success('Your data has been saved successfully!');
          }
        } catch (err) {
          toast.error('Your data has not been saved yet. Please try again.');
        }
      },
      validationSchema: AutomationEventSchema,
      validateOnMount: true,
    });

  const renderMethodMessageEdit = (messageTypeEdit: string) => {
    if (!messageTypeEdit) {
      return (
        <option value='' selected className='place-holder'>
          Select a Template
        </option>
      );
    } else if (messageTypeEdit && messageTypeEdit === TYPE_SMS) {
      return smsTemplateData.items.length !== 0 ? (
        smsTemplateData.items.map((item) => (
          <option
            key={`methodFallbackTypeEdit ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Template SMS
        </option>
      );
    } else if (messageTypeEdit && messageTypeEdit === TYPE_EMAIL) {
      return emailTemplateData.items.length !== 0 ? (
        emailTemplateData.items.map((item) => (
          <option
            key={`methodFallbackTypeEdit ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Template Email
        </option>
      );
    } else if (messageTypeEdit && messageTypeEdit === TYPE_CALL) {
      return callTemplateData.items.length !== 0 ? (
        callTemplateData.items.map((item) => (
          <option
            key={`methodFallbackTypeEdit ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Call Template
        </option>
      );
    } else if (messageTypeEdit && messageTypeEdit === TYPE_POSTAL) {
      return postalTemplateData.items.length !== 0 ? (
        postalTemplateData.items.map((item) => (
          <option
            key={`methodFallbackTypeEdit ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Postal Template
        </option>
      );
    }
  };

  const { allowedPath } = useDecodeToken();
  const rederMessageKinds = () => {
    let filterdMessageKinds:any[] = []
    if(!allowedPath.includes('/Messenger')){
      filterdMessageKinds = deliverApproximatelyData?.messageKinds.filter((item)=>{
        return item.name !== 'SMS'
    })
    } else {
      filterdMessageKinds = [...deliverApproximatelyData?.messageKinds]
    }
  
    
    if (deliverApproximatelyData?.messageKinds) {
      const messageKindOptions = filterdMessageKinds.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      return messageKindOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderTimeValue = () => {
    if (
      values.timeType === 'minutes' &&
      deliverApproximatelyData?.definedMinuteValues
    ) {
      const timeValueOptions =
        deliverApproximatelyData?.definedMinuteValues.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        });
      return timeValueOptions;
    } else if (deliverApproximatelyData?.definedValues) {
      const timeValueOptions = deliverApproximatelyData?.definedValues.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      return timeValueOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderTimeType = () => {
    if (deliverApproximatelyData?.unitKinds) {
      const timeTypeOptions = deliverApproximatelyData?.unitKinds.map(
        (item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        }
      );
      return timeTypeOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderScheduledKinds = () => {
    if (deliverApproximatelyData?.scheduledKinds) {
      const scheduledKindsOptions =
        deliverApproximatelyData?.scheduledKinds.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          );
        });
      return scheduledKindsOptions;
    } else {
      return <option value=''>N/A</option>;
    }
  };

  const renderMethodMessage = (messageType: string) => {
    if (!messageType) {
      return (
        <option value='' selected className='place-holder'>
          Select a Template
        </option>
      );
    } else if (messageType && messageType === TYPE_SMS) {
      return smsTemplateData.items.length !== 0 ? (
        smsTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Template SMS
        </option>
      );
    } else if (messageType && messageType === TYPE_EMAIL) {
      return emailTemplateData.items.length !== 0 ? (
        emailTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Template Email
        </option>
      );
    } else if (messageType && messageType === TYPE_CALL) {
      return callTemplateData.items.length !== 0 ? (
        callTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Call Template
        </option>
      );
    } else if (messageType && messageType === TYPE_POSTAL) {
      return postalTemplateData.items.length !== 0 ? (
        postalTemplateData.items.map((item) => (
          <option
            key={`methodFallbackType ${item.templateId}`}
            value={item.templateId}
          >
            {item.name}
          </option>
        ))
      ) : (
        <option value='' className='place-holder'>
          No Call Template
        </option>
      );
    }
  };

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionAppointmentKind(e.target.value);
    setErrorApt(false);
    setErrorNoApt(false);
  };

  const handleOptionMedicalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptionMedicalKind(e.target.value);
    setErrorMedical(false);
    setErrorNoMedical(false);
  };

  const handleDisableSubmitWithAppointment = (values: any) => {
    if (categoryKind !== 'appointment') {
      return true;
    }
    if (optionAppointmentKind === 'all') {
      return true;
    } else if (optionAppointmentKind === 'apply') {
      return values.appointmentTypes?.length ? true : false;
    } else {
      return values.appointmentTypesToNotSend?.length ? true : false;
    }
  };

  const handleDisableSubmitWithMedical = (values: any) => {
    if (categoryKind !== 'reminder') {
      return true;
    }
    if (optionMedicalKind === 'all') {
      return true;
    } else if (optionMedicalKind === 'apply') {
      return values.medicalServices?.length ? true : false;
    } else {
      return values.medicalServicesNotApply?.length ? true : false;
    }
  };

  

  return (
    <div className='edit-appointment'>
      <form
        onSubmit={handleSubmit}
        data-testid='form'
        className='col-12 form-schedule'
      >
        <div className='form-group'>
          <p>
            Primary Message <b className='require'>*</b>
          </p>
          <div className='d-flex'>
            <select
              value={values.messageType}
              name='messageType'
              className='method flex1125'
              onChange={(event) => {
                setIsDirtyForm(true);
                handleChange(event);
                let methodMessage =
                  emailTemplateData.items[0]?.templateId || '';
                if (event.target.value === TYPE_SMS) {
                  methodMessage = smsTemplateData.items[0]?.templateId || '';
                } else if (event.target.value === TYPE_CALL) {
                  methodMessage = callTemplateData.items[0]?.templateId || '';
                } else if (event.target.value === TYPE_POSTAL) {
                  methodMessage = postalTemplateData.items[0]?.templateId || '';
                }
                setFieldValue('methodMessage', methodMessage);
              }}
            >
              {rederMessageKinds()}
            </select>

            <select
              value={values.methodMessage}
              disabled={!values.messageType}
              name='methodMessage'
              className='appointment-confirmation-dropdown'
              onChange={(event) => {
                setIsDirtyForm(true);
                handleChange(event);
              }}
            >
              {renderMethodMessage(values.messageType)}
            </select>
          </div>
        </div>

        {categoryKind === 'appointment' && (
          <div className='form-group setting__content--full-row block autocomplete'>
            <p className='appointment-types'>
              Appointment Types <b className='require'>*</b>
            </p>
            <RadioGroup
              value={optionAppointmentKind}
              onChange={handleChangeOption}
            >
              <FormControlLabel
                value='all'
                control={<Radio />}
                htmlFor=''
                label='Apply for all Appointment Types'
              />
              <FormControlLabel
                value='apply'
                control={<Radio />}
                htmlFor=''
                label='Apply to select Appointment Types'
              />
              {optionAppointmentKind === 'apply' && (
                <div className='d-flex input-options'>
                  <Autocomplete
                    // className={formikProps.values.appointmentTypes.length && "autocomplete--padding"}
                    // @ts-ignore
                    name='appointmentTypes'
                    // value={values?.appointmentTypes || undefined}
                    // @ts-ignore
                    defaultValue={
                      appointmentTypeApplyKind === 'apply'
                        ? getAppointmentTypesSelected()
                        : []
                    }
                    // @ts-ignore
                    value={values.appointmentTypes}
                    multiple
                    // id="checkboxes-tags-demo"
                    options={listAppointmentTypes}
                    disableCloseOnSelect
                    // @ts-ignore
                    getOptionLabel={(option: AppointmentTypeSetting) =>
                      option.appointmentType
                    }
                    noOptionsText={'No options'}
                    onChange={(e: any, value: any[]) => {
                      setIsDirtyForm(true);
                      setFieldValue('appointmentTypes', value);
                      !value.length &&
                        !focusAppointmentTypes &&
                        setSearchAppointmentTypes(false);
                      setErrorApt(value?.length ? false : true);
                    }}
                    // onBlur={() => {
                    //   if (!touched.roles) formikProps.setFieldTouched('roles');
                    // }}
                    getOptionSelected={(
                      option: AppointmentTypeSetting,
                      value: AppointmentTypeSetting
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: AppointmentTypeSetting,
                      { selected }: any
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.appointmentType}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        style={{ color: 'red' }}
                        {...params}
                        placeholder={
                          searchAppointmentTypes
                            ? 'Search'
                            : 'Select Appointment Types'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                        onFocus={() => handleFocus(1)}
                        // @ts-ignore
                        onBlur={() => handleBlur(1, values.appointmentTypes)}
                      />
                    )}
                  />
                  {errorApt && (
                    <p className='error-msg'>This field is required.</p>
                  )}
                </div>
              )}
              <FormControlLabel
                value='notApply'
                control={<Radio />}
                htmlFor=''
                label="Don't apply for Appointment Types"
              />
              {optionAppointmentKind === 'notApply' && (
                <div className='d-flex input-options'>
                  <Autocomplete
                    // className={formikProps.values.appointmentTypes.length && "autocomplete--padding"}
                    // @ts-ignore
                    name='appointmentTypesToNotSend'
                    // value={values?.appointmentTypes || undefined}
                    // @ts-ignore
                    defaultValue={
                      appointmentTypeApplyKind === 'notApply'
                        ? getAppointmentTypesSelected()
                        : []
                    }
                    // @ts-ignore
                    value={values.appointmentTypesToNotSend}
                    multiple
                    // id="checkboxes-tags-demo"
                    options={listAppointmentTypes}
                    disableCloseOnSelect
                    // @ts-ignore
                    getOptionLabel={(option: AppointmentTypeSetting) =>
                      option.appointmentType
                    }
                    noOptionsText={'No options'}
                    onChange={(e: any, value: any[]) => {
                      setIsDirtyForm(true);
                      setFieldValue('appointmentTypesToNotSend', value);
                      !value.length &&
                        !focusNotAppointmentTypes &&
                        setSearchNotAppointmentTypes(false);
                      setErrorNoApt(value?.length ? false : true);
                    }}
                    // onBlur={() => {
                    //   if (!touched.roles) formikProps.setFieldTouched('roles');
                    // }}
                    getOptionSelected={(
                      option: AppointmentTypeSetting,
                      value: AppointmentTypeSetting
                    ) => {
                      return option.id === value.id;
                    }}
                    renderOption={(
                      option: AppointmentTypeSetting,
                      { selected }: any
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.appointmentType}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={
                          searchNotAppointmentTypes
                            ? 'Search'
                            : 'Select Appointment Types'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                        onFocus={() => handleFocus(2)}
                        onBlur={() =>
                          // @ts-ignore
                          handleBlur(2, values.appointmentTypesToNotSend)
                        }
                      />
                    )}
                  />
                  {errorNoApt && (
                    <p className='error-msg'>This field is required.</p>
                  )}
                </div>
              )}
            </RadioGroup>
          </div>
        )}

        {categoryKind === 'reminder' && (
          <div className='form-group setting__content--full-row block autocomplete'>
            <p className='appointment-types'>
              Medical Services <b className='require'>*</b>
            </p>
            <RadioGroup
              value={optionMedicalKind}
              onChange={handleOptionMedicalChange}
            >
              <FormControlLabel
                value='all'
                control={<Radio />}
                htmlFor=''
                label='Apply for all Medical Services'
              />
              <FormControlLabel
                value='apply'
                control={<Radio />}
                htmlFor=''
                label='Apply to select Medical Services'
              />
              {optionMedicalKind === 'apply' && (
                <div className='d-flex input-options'>
                  <Autocomplete
                    // @ts-ignore
                    name='medicalServices'
                    // @ts-ignore
                    defaultValue={
                      optionMedicalKind === 'apply'
                        ? getMedicalServicesSelected()
                        : []
                    }
                    // @ts-ignore
                    value={values.medicalServices}
                    multiple
                    options={listMedicalServices}
                    disableCloseOnSelect
                    // @ts-ignore
                    getOptionLabel={(option: Medical) =>
                      option.medicalServiceName
                    }
                    noOptionsText={'No options'}
                    onChange={(e: any, value: any[]) => {
                      setIsDirtyForm(true);
                      setFieldValue('medicalServices', value);
                      !value.length &&
                        !focusMedicalServices &&
                        setSearchMedicalServices(false);
                      setErrorMedical(value?.length ? false : true);
                    }}
                    getOptionSelected={(option: Medical, value: Medical) => {
                      return option.medicalServiceId === value.medicalServiceId;
                    }}
                    renderOption={(option: Medical, { selected }: any) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.medicalServiceName}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        style={{ color: 'red' }}
                        {...params}
                        placeholder={
                          searchMedicalServices
                            ? 'Search'
                            : 'Select Medical Services'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                        onFocus={() => handleFocus(4)}
                        // @ts-ignore
                        onBlur={() => handleBlur(4, values.medicalServices)}
                      />
                    )}
                  />
                  {errorMedical && (
                    <p className='error-msg'>This field is required.</p>
                  )}
                </div>
              )}
              <FormControlLabel
                value='notApply'
                control={<Radio />}
                htmlFor=''
                label="Don't apply for Medical Services"
              />
              {optionMedicalKind === 'notApply' && (
                <div className='d-flex input-options'>
                  <Autocomplete
                    // @ts-ignore
                    name='medicalServicesNotApply'
                    // @ts-ignore
                    defaultValue={
                      medicalServiceApplyKind === 'notApply'
                        ? getMedicalServicesSelected()
                        : []
                    }
                    // @ts-ignore
                    value={values.medicalServicesNotApply}
                    multiple
                    options={listMedicalServices}
                    disableCloseOnSelect
                    // @ts-ignore
                    getOptionLabel={(option: Medical) =>
                      option.medicalServiceName
                    }
                    noOptionsText={'No options'}
                    onChange={(e: any, value: any[]) => {
                      setIsDirtyForm(true);
                      setFieldValue('medicalServicesNotApply', value);
                      !value.length &&
                        !focusNotMedicalServices &&
                        setSearchNotMedicalServices(false);
                      setErrorNoMedical(value?.length ? false : true);
                    }}
                    getOptionSelected={(option: Medical, value: Medical) => {
                      return option.medicalServiceId === value.medicalServiceId;
                    }}
                    renderOption={(option: Medical, { selected }: any) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8, color: '#1974ff' }}
                            checked={selected}
                            className='checked-role'
                          />
                          {option.medicalServiceName}
                        </React.Fragment>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={
                          searchNotMedicalServices
                            ? 'Search'
                            : 'Select Medical Services'
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        fullWidth
                        onFocus={() => handleFocus(5)}
                        // @ts-ignore
                        onBlur={() =>
                          handleBlur(5, values.medicalServicesNotApply)
                        }
                      />
                    )}
                  />
                  {errorNoMedical && (
                    <p className='error-msg'>This field is required.</p>
                  )}
                </div>
              )}
            </RadioGroup>
          </div>
        )}

        <div className='form-group setting__content--full-row block autocomplete'>
          <p className='appointment-types'>Species</p>
          <div className='d-flex input-options'>
            <Autocomplete
              // className={formikProps.values.appointmentTypes.length && "autocomplete--padding"}
              // @ts-ignore
              name='species'
              // @ts-ignore
              defaultValue={getSpeciesSelected()}
              multiple
              options={listSpecies}
              disableCloseOnSelect
              // @ts-ignore
              getOptionLabel={(option: SpeciesSettingType) => option.name}
              noOptionsText={'No options'}
              onChange={(e: any, value: any[]) => {
                setIsDirtyForm(true);
                setFieldValue('species', value);
                !value.length && !focusSpecies && setSearchSpecies(false);
              }}
              getOptionSelected={(
                option: SpeciesSettingType,
                value: SpeciesSettingType
              ) => {
                return option.id === value.id;
              }}
              renderOption={(option: SpeciesSettingType, { selected }: any) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                      style={{ marginRight: 8, color: '#1974ff' }}
                      checked={selected}
                      className='checked-role'
                    />
                    {option.name}
                  </React.Fragment>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder={searchSpecies ? 'Search' : 'All Species'}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  fullWidth
                  onFocus={() => handleFocus(3)}
                  // @ts-ignore
                  onBlur={() => handleBlur(3, values.species)}
                />
              )}
            />
          </div>
        </div>

        <div className='form-group'>
          {values.approx.length > 0 && <p>Fallback Message Attempts</p>}
          {values.approx.length > 0 && (
            <>
              {values.approx.map((item, index) => {
                return (
                  <div key={index + 1} className='fallback-message'>
                    {index == 0 ? (
                      <p>
                        {index + 1}. If the message cannot be sent, then
                        fallback to
                      </p>
                    ) : (
                      <p>
                        {index == 0 ? 'Message failed to send' : ''}
                        {index + 1}. If fallback message attempt {index} cannot
                        be sent, then fallback to
                      </p>
                    )}
                    <div className='d-flex mb-20'>
                      <select
                        name={`approx[${index}].messageKind`}
                        value={values.approx[index].messageKind}
                        className='method w-15'
                        onChange={(event) => {
                          setIsDirtyForm(true);
                          handleChange(event);
                          let templateId =
                            emailTemplateData.items[0]?.templateId || '';
                          if (event.target.value === TYPE_SMS) {
                            templateId =
                              smsTemplateData.items[0]?.templateId || '';
                          } else if (event.target.value === TYPE_CALL) {
                            templateId =
                              callTemplateData.items[0]?.templateId || '';
                          } else if (event.target.value === TYPE_POSTAL) {
                            templateId =
                              postalTemplateData.items[0]?.templateId || '';
                          }
                          setFieldValue(
                            `approx[${index}].templateId`,
                            templateId
                          );
                        }}
                      >
                        {rederMessageKinds()}
                      </select>

                      <select
                        name={`approx[${index}].templateId`}
                        value={values.approx[index].templateId}
                        className='appointment-confirmation-dropdown'
                        disabled={!values.approx[index].messageKind}
                        onChange={(event) => {
                          setIsDirtyForm(true);
                          handleChange(event);
                        }}
                      >
                        {renderMethodMessageEdit(
                          values.approx[index].messageKind
                        )}
                      </select>

                      <FontAwesomeIcon
                        icon={['fal', 'times-circle']}
                        className='icon-remove-fallback'
                        onClick={() => {
                          setIsDirtyForm(true);
                          const clonedApproxList = JSON.parse(
                            JSON.stringify(values.approx)
                          );
                          clonedApproxList.splice(index, 1);
                          setFieldValue('approx', clonedApproxList);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {values.approx.length < 3 ? (
            <span
              className='add-fallback'
              onClick={() => {
                setIsDirtyForm(true);
                const clonedApproxList = JSON.parse(
                  JSON.stringify(values.approx)
                );
                clonedApproxList.push({
                  messageKind: 'sms',
                  templateId: smsTemplateData.items[0]?.templateId || '',
                });
                setFieldValue('approx', clonedApproxList);
              }}
            >
              + Add Fallback Attempt
            </span>
          ) : (
            ''
          )}
        </div>
        <div className='form-group'>
          <p>
            Deliver Approximately <b className='require'>*</b>
          </p>
          <div className='d-flex delivery-approximate'>
            <select
              value={values.timeValue}
              name='timeValue'
              className='hours w-15'
              onChange={(event) => {
                setIsDirtyForm(true);
                handleChange(event);
              }}
            >
              {renderTimeValue()}
            </select>

            <select
              value={values.timeType}
              name='timeType'
              className='time flex1125'
              onChange={(event) => {
                if (
                  event.target.value === 'minutes' &&
                  deliverApproximatelyData?.definedMinuteValues &&
                  !(
                    deliverApproximatelyData.definedMinuteValues.filter(
                      (x) => x.value == values.timeValue
                    )?.length > 0
                  )
                ) {
                  setFieldValue('timeValue', 1);
                } else if (
                  deliverApproximatelyData?.definedValues &&
                  !(
                    deliverApproximatelyData.definedValues.filter(
                      (x) => x.value == values.timeValue
                    )?.length > 0
                  )
                ) {
                  setFieldValue('timeValue', 1);
                }
                setIsDirtyForm(true);
                handleChange(event);
              }}
            >
              {renderTimeType()}
            </select>

            <select
              value={values.period}
              name='period'
              className='flex1160'
              onChange={(event) => {
                setIsDirtyForm(true);
                handleChange(event);
              }}
            >
              {renderScheduledKinds()}
            </select>
          </div>
        </div>
        <div className='d-flex allow-sending-outside'>
          <Checkbox
            checked={values.allowSendOutsideDeliveryHours}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              setIsDirtyForm(true);
              handleChange(event);
            }}
            name='allowSendOutsideDeliveryHours'
            className='check-schedule'
          />
          <span>Allow sending outside delivery hours</span>
        </div>

        <div className={'save-and-cancel'}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={'margintop-30'}
            disabled={
              !(
                isValid &&
                handleDisableSubmitWithAppointment(values) &&
                handleDisableSubmitWithMedical(values)
              )
            }
          >
            Save
          </Button>

          <Button
            variant='contained'
            color='default'
            className={'margintop-30 cancel-button'}
            onClick={() => {
              setCurrentAutomationOpen && setCurrentAutomationOpen(null);
              setOpenFragment && setOpenFragment(false);
              setIsDirtyForm(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AutomationEditForm;
