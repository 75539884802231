export enum SmsStatusType {
  sent = 'sent',
  pending = 'pending',
  canceled = 'canceled',
  failed = 'failed',
  received = 'received',
  invalid = 'invalid',
}

type SmsStatusMap = { [key in SmsStatusType]: string };

export const getStatusColors = ({
  red,
  yellow,
  green,
}: {
  red: string;
  yellow: string;
  green: string;
}): SmsStatusMap => ({
  [SmsStatusType.failed]: red,
  [SmsStatusType.sent]: green,
  [SmsStatusType.pending]: yellow,
  [SmsStatusType.canceled]: red,
  [SmsStatusType.received]: green,
  [SmsStatusType.invalid]: red,
});
