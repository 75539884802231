import * as t from 'io-ts';

export const SpeciesSettingType = t.type({
  id: t.string,
  name: t.string,
  externalId: t.string,
  practiceId: t.string,
  hideInClientPortal: t.boolean,
});

export type SpeciesSettingType = t.TypeOf<typeof SpeciesSettingType>;
