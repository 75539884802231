import React, { useEffect, useState } from 'react';
import { isWindows } from 'react-device-detect';

import PropTypes from 'prop-types';
import FieldList from './field-list';
import FormFieldConfig from './form-field-config';
import FormItemBuild from './form-item-build';

const FormBuildTab = ({
  formVersionObject,
  activeField,
  activeNestedField,
  hoverField,
  hoverLocation,
  showConfigPane,
  basicFields,
  advancedFields,
  onFieldHover,
  onFieldClick,
  onFieldChange,
  onSubfieldChange,
  onDropField,
  onDeleteField,
  onNestedDrop,
  onNewPage,
  onLockField,
}) => {
  const [isDragNewItem, setIsDragNewItem] = useState(false);

  useEffect(() => {
    // @ts-ignore
    const navigationDiv = document.getElementById('navigation-main');
    if (navigationDiv && isWindows) navigationDiv.style.overflow = 'unset';
  }, []);

  return (
    <div className='row-fluid columns form-builder__build'>
      {!activeField && (
        <FieldList
          basicFields={basicFields}
          advancedFields={advancedFields}
          onFieldHover={onFieldHover}
          setIsDragNewItem={setIsDragNewItem}
        />
      )}
      {showConfigPane && (
        <FormFieldConfig
          field={activeNestedField || activeField}
          parent={activeNestedField ? activeField : undefined}
          onFieldClick={onFieldClick}
          onFieldChange={onFieldChange}
          onSubfieldChange={onSubfieldChange}
        />
      )}
      <FormItemBuild
        versionObject={formVersionObject}
        onDropField={onDropField}
        activeField={activeField}
        activeNestedField={activeNestedField}
        onFieldClick={onFieldClick}
        onDeleteField={onDeleteField}
        hoverField={hoverField}
        hoverLocation={hoverLocation}
        onFieldHover={onFieldHover}
        onNestedDrop={onNestedDrop}
        onNewPage={onNewPage}
        onLockField={onLockField}
        isDragNewItem={isDragNewItem}
      />
    </div>
  );
};

FormBuildTab.propTypes = {
  formVersionObject: PropTypes.object,
  activeField: PropTypes.object,
  activeNestedField: PropTypes.object,
  hoverField: PropTypes.object,
  hoverLocation: PropTypes.string,
  showConfigPane: PropTypes.bool,
  basicFields: PropTypes.arrayOf(PropTypes.object),
  advancedFields: PropTypes.arrayOf(PropTypes.object),
  onFieldHover: PropTypes.func.isRequired,
  onFieldClick: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubfieldChange: PropTypes.func.isRequired,
  onDropField: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onNestedDrop: PropTypes.func.isRequired,
  onNewPage: PropTypes.func.isRequired,
  onLockField: PropTypes.func.isRequired,
};

FormBuildTab.defaultProps = {
  formVersionObject: {},
  activeField: null,
  activeNestedField: null,
  hoverField: null,
  hoverLocation: null,
  showConfigPane: false,
  basicFields: [],
  advancedFields: [],
};

export default FormBuildTab;
