/* eslint-disable react/prefer-es6-class */
import $ from 'jquery';

import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const React = require('react');
const ReactDOM = require('react-dom');
const classNames = require('classnames');

const modals = [];
let modalBackdrop;

const Modal = createReactClass({
  propTypes: {
    backdrop: PropTypes.oneOfType([
      PropTypes.bool, // wether or not to include a modal background
      PropTypes.oneOf(['static']), // a static background will not close the modal when clicked
    ]),
    show: PropTypes.bool, // wether or not to show the modal when it's initialized
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, // the title of the modal
    footer: PropTypes.bool, // wether to display the footer
    footerText: PropTypes.oneOfType([
      PropTypes.string, // the text to display in the footer
      PropTypes.bool, // false to not display the footer text
      PropTypes.element, // render your own header
    ]),
    closeText: PropTypes.oneOfType([
      PropTypes.string, // the display text for the close button
      PropTypes.bool, // false to not display the close button
    ]),
    acceptText: PropTypes.oneOfType([
      PropTypes.string, // the display text for the accept button
      PropTypes.bool, // false to not display the accept button
    ]),
    onClose: PropTypes.func, // a function to call when the modal is closed
    onDidMount: PropTypes.func, // a function to call when the modal is rendered
    height: PropTypes.number, // the height of the body
    renderCloseHead: PropTypes.bool, // wether to display the X in the header
    renderHTML: PropTypes.bool, // if true render children in dangerouslySetInnerHTML
    allowClose: PropTypes.bool, // wether to allow the end user to close the modal
    modalBodyStyle: PropTypes.object, // style objectto add styles to modal-body div
    handleScroll: PropTypes.func,
  },
  statics: {
    CANCEL: 0,
    APPROVE: true,
    closeActions: {
      CANCELED: 0,
      APPROVED: 1,
    },
  },

  getDefaultProps() {
    return {
      backdrop: true,
      fade: true,
      show: true,

      footer: true,
      footerText: false,
      closeText: 'Close',
      closeButtonClassName: 'btn-default',
      acceptText: 'Ok',
      acceptButtonClassName: 'btn-primary',
      renderCloseHead: true,
      onClose() { },
      onDidMount() { },
      allowClose: true,
    };
  },

  getInitialState() {
    return {
      pendingCloseAction: null,
    };
  },

  componentDidMount() {
    this.props.onDidMount();

    modals.forEach(function (modal) {
      $(ReactDOM.findDOMNode(modal)).hide();
    });

    if (modals.length === 0) {
      modalBackdrop = $('<div class="lw-modal-backdrop">');

      if (this.props.allowClose && this.props.backdrop !== 'static') {
        modalBackdrop.on('click', function () {
          modals[modals.length - 1].close(Modal.closeActions.CANCELED);
        });
      }

      $('body').append(modalBackdrop);
    }

    modals.push(this);
  },

  componentWillUnmount() {
    const index = modals.indexOf(this);

    modals.splice(index, 1);

    if (modals.length === 0) {
      modalBackdrop.remove();
    }

    if (!this.__closed) {
      this.close(Modal.closeActions.CANCEL);
    }
  },

  close(closeAction) {
    if (
      this.__closed ||
      (this.state.isPendingClose && closeAction === Modal.closeActions.APPROVED)
    ) {
      return;
    }

    this.__closed = true;

    const promise = this.props.onClose(closeAction);

    const closeModal = () => {
      const index = modals.indexOf(this);

      modals.splice(index, 1);

      if (modals.length !== 0) {
        $(ReactDOM.findDOMNode(modals[modals.length - 1])).show();
      }
    };

    if (promise instanceof Promise) {
      this.setState({
        isPendingClose: true,
        pendingCloseAction: closeAction,
      });

      promise.then(closeModal);
      promise.catch(() => this.setState({ isPendingClose: false, pendingCloseAction: null }));
    } else {
      closeModal();
    }
  },

  render() {
    let footer;

    const btnCloseClassName = classNames({
      btn: true,
      [this.props.closeButtonClassName]: true,
    });

    const acceptButtonClassName = classNames({
      btn: true,
      [this.props.acceptButtonClassName]: true,
    });

    const customFooter = this.props.footerText && typeof this.props.footerText === 'object';
    if (
      this.props.allowClose !== false &&
      this.props.footer !== false &&
      (this.props.footerText || this.props.closeText || this.props.acceptText)
    ) {
      footer = (
        <div className='modal-footer'>
          {customFooter && this.props.footerText}
          {this.props.footerText && !customFooter && (
            <div className='pull-left'>{this.props.footerText}</div>
          )}

          {this.props.closeText && !customFooter && (
            <button
              className={btnCloseClassName}
              onClick={this.close.bind(this, Modal.closeActions.CANCELED)}
            >
              {this.props.closeText}
            </button>
          )}

          {this.props.acceptText && !customFooter && (
            <button
              disabled={this.state.isPendingClose}
              className={acceptButtonClassName}
              onClick={this.close.bind(this, Modal.closeActions.APPROVED)}
            >
              {this.state.pendingCloseAction === Modal.closeActions.APPROVED && (
                <span>
                  <i className='icon icon-spin icon-spinner' /> &nbsp;
                </span>
              )}
              {this.props.acceptText}
            </button>
          )}
        </div>
      );
    }

    const { className, height, title, ...otherProps } = this.props;

    const modalClasses = classNames(className, {
      'lw-modal': true,
    });

    let modalBodyStyle = {};

    if (height) {
      modalBodyStyle.maxHeight = height;
      modalBodyStyle.height = height;
    }

    modalBodyStyle = { ...modalBodyStyle, ...this.props.modalBodyStyle };

    return (
      <div {...otherProps} className={modalClasses}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              {this.props.allowClose && this.props.renderCloseHead && (
                <button
                  type='button'
                  className='close'
                  onClick={this.close.bind(this, Modal.closeActions.CANCELED)}
                >
                  &times;
                </button>
              )}
              <h3 className='modal-title'>{typeof title === 'string' ? title : title()}</h3>
            </div>
            <div className='modal-body' style={modalBodyStyle} onScroll={this.props.handleScroll}>
              {this.props.children}
            </div>
            {footer}
          </div>
        </div>
      </div>
    );
  },
});

export default Modal;
