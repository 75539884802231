import React from 'react';
import { COLORS } from './constants';
import { getTypeOfScore, getPercentage, isEnterKey } from './utils';
import {
  ButtonLikeDiv,
  ScoreText,
  BarContainer,
  BackgroundBar,
  ProgressBar,
} from './styled-components';

interface RatingBarProps {
  score: number;
  onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
}

const RatingBar = (props: RatingBarProps): React.ReactElement => {
  const { score, onClick } = props;

  const percentage = getPercentage(score);
  const forScoreValue: 'high' | 'medium' | 'low' = getTypeOfScore(score);

  const { background, foreground } = COLORS[forScoreValue];

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isEnterKey(e.charCode)) {
      e.preventDefault(); // Ensure it is only this code that runs

      onClick();
    }
  };

  return (
    <ButtonLikeDiv
      role='button'
      aria-pressed='false'
      tabIndex={0}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <ScoreText color={background}>{score}</ScoreText>
      <BarContainer>
        <BackgroundBar backgroundColor={foreground}>
          <ProgressBar color={background} percentage={percentage} />
        </BackgroundBar>
      </BarContainer>
    </ButtonLikeDiv>
  );
};

RatingBar.defaultProps = {
  onClick: (): void => {},
};

export default RatingBar;
