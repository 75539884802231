import React from 'react';

import SetPassword from 'components/SetPassword/SetPassword';
import { LoginProps } from 'components/SetPassword/SetPassword';

const SetPasswordContainer = (props: LoginProps): JSX.Element => {
  return (
    <>
      <SetPassword {...props} />
    </>
  )
}

export default SetPasswordContainer;
