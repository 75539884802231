/*= ===========================================================
  IMPORTS / SET UP
============================================================ */
import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import { FORM_MODAL_CONSTANTS } from '../constants';

export default class VerifyPatientModal extends React.Component {
  /*= ================ LIFECYCLE METHODS ==================== */
  constructor(props) {
    super(props);

    this.state = {
      selectedPatient: 0,
    };
  }

  handlePatientSelect = selectedPatient => {
    if (this.state.selectedPatient === selectedPatient) {
      selectedPatient = 0;
    }
    this.setState({ selectedPatient });
  };

  handleClose = closeAction => {
    if (closeAction === Modal.APPROVE) {
      const id = this.state.selectedPatient;
      this.props.onClose(id);
    } else this.props.onClose();
  };

  getAcceptText = () => {
    if (this.state.selectedPatient === 0) return false;
    return `Approve and Update in PMS`; // TODO: update with actual PMS for account when available
  };

  render() {
    const { patientName, contacts } = this.props;

    const {
      VerifyPatientTitle,
      VerifyPatientFooter,
      NoPatientModalTitle,
      NoPatientModalCloseText,
      NoPatientModalBodyText,
    } = FORM_MODAL_CONSTANTS;

    return (
      <div>
        {contacts.length > 0 && (
          <Modal
            title={VerifyPatientTitle}
            acceptText={this.getAcceptText()}
            footerText={VerifyPatientFooter}
            onClose={this.handleClose}
            closeText={false}
          >
            <div className='verifyPatientHeader'>{patientName}</div>
            <table className='table table-bordered table-condensed table-striped table-hover form-builder-list'>
              <thead>
                <tr>
                  <th key='selectPatient' className='th-checkbox' />
                  <th key='name' className='th-name'>
                    Name
                  </th>
                  <th key='email' className='th-email'>
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {contacts.map(contact => {
                  const handleSelectClick = () => {
                    this.handlePatientSelect(contact.id);
                  };
                  const isChecked = this.state.selectedPatient === contact.id;
                  return (
                    <tr key={contact.id}>
                      <td key={`${contact.id}-checkbox`}>
                        <input
                          type='checkbox'
                          checked={isChecked}
                          onClick={handleSelectClick}
                          value={contact.id}
                        />
                      </td>
                      <td key={`${contact.id}-name`}>{contact.name}</td>
                      <td key={`${contact.id}-email`}>{contact.address}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal>
        )}
        {contacts.length === 0 && (
          <Modal
            title={NoPatientModalTitle}
            closeText={NoPatientModalCloseText}
            acceptText={false}
            onClose={this.handleClose}
          >
            {NoPatientModalBodyText}
          </Modal>
        )}
      </div>
    );
  }
}

VerifyPatientModal.propTypes = {
  onSelectPatient: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func,
};

VerifyPatientModal.defaultProps = {
  contacts: [],
};
