// This component has route '/Appointment' in Practice
// Shows appoinments of a Practice


import React, { useState, useEffect } from 'react';
import RightModal from 'components/Global/TeleMed/RightModal';
import Appointment from 'components/Appointments/Appointments';
import withComponentClassAndHeaderText from 'components/Wrappers/WithComponentClassAndHeaderText';
import { Button, Modal } from '@material-ui/core';
import { SystemSettingResponse } from 'global/requests/ResponseTypes';
import { configuredRequests } from 'global/requests/ConfiguredRequests';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const ComponentizedAppointment = withComponentClassAndHeaderText(Appointment);

const DailyAppointmentsContainer = () => {
  const [displayTelemedModal, setDisplayTelemedModal] = React.useState(false);
  const [telemedModalAppointmentData, setTelemedModalAppointmentData] =
    React.useState<any>();
  const [isAlreadyInCall, setIsAlreadyInCall] = React.useState<boolean>(false);
  const [openModalError, setOpenModalError] = React.useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  const [modalStyle] = React.useState(getModalStyle);

  const closeModalError = () => setOpenModalError(false);

  const [systemSetting, setSystemSetting] =
    React.useState<SystemSettingResponse>();

  const getSystemSetting = async () => {
    try {
      const res = await configuredRequests.GET.systemSettings();
      if (res) {
        setSystemSetting(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSystemSetting();
  }, []);

  const settingsTimezoneData = systemSetting
    ? systemSetting.settings.timeZoneOffset
    : '';

  return (
    <div className='appointment'>
      <Modal
        open={openModalError}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        onBackdropClick={closeModalError}
      >
        <div style={modalStyle} className='insert-modal-style'>
          <div className='modal-error-body'>
            <p>
              You have an appointment that is currently in-progress. Please end
              it to start a new appointment!
            </p>
            <div className='button-group'>
              <Button
                variant='contained'
                className='btn-insert'
                onClick={() => {
                  setOpenModalError(false);
                }}
              >
                {' '}
                OK{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <RightModal
        // @ts-ignore
        appointmentInfo={telemedModalAppointmentData}
        displayTelemedModal={displayTelemedModal}
        setDisplayTelemedModal={setDisplayTelemedModal}
        labelText='Label Text Non-Default'
        isAlreadyInCall={isAlreadyInCall}
        setOpenModalError={setOpenModalError}
        setReload={setReload}
        settingsTimezoneData={settingsTimezoneData || ''}
      />

      <ComponentizedAppointment
        setDisplayTelemedModal={setDisplayTelemedModal}
        // @ts-ignore
        setTelemedModalAppointmentData={setTelemedModalAppointmentData}
        setIsAlreadyInCall={setIsAlreadyInCall}
        reload={reload}
        setReload={setReload}
        settingsTimezoneData={settingsTimezoneData || ''}
      />
    </div>
  );
};

export default DailyAppointmentsContainer;
