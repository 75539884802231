import React, { useState } from 'react';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { InputTextarea, SecondaryButton } from 'components/Legwork';
import { InputContainer } from './Share';
import { configuredRequests } from 'global/requests/ConfiguredRequests';
import { getRedirectUrl, getRootDomain } from 'global/constants/url';

interface Props {
    onCopyLink: (link: string) => void;
}

const ShareRedirectLink = ({
    onCopyLink,
}: Props) => {
    const [inputLink, setInputLink] = useState<string>("");
    const [generatedLink, setGeneratedLink] = useState<string>("");

    const generateNewLink = async () => {
        const res = await configuredRequests.POST.addRedirectLink(inputLink);
        if (res.status == 200) {
            let redirectUrl = getRedirectUrl();
            if (redirectUrl == "") redirectUrl = getRootDomain() + "/r";
            // @ts-ignore
            let newLink = redirectUrl + "/" + res.data?.token;
            setGeneratedLink(newLink);
            onCopyLink(newLink);
        } 
    }

    return (
        <div className='row'>
            <div className='control-group' style={{ marginBottom: '50px' }}>
                <label htmlFor='shared_link' className='control-label share-title'>
                    Generate Shortened Redirect Links
                </label>
                <InputContainer>
                    <span className='sub-label-share'>Use this input field to generate a VetHero redirect link for whatever you put in</span>
                    <InputTextarea
                        id='shared-link-edit'
                        initialValue={inputLink}
                        disabled={false}
                        onChange={val => setInputLink(val)}
                    />
                </InputContainer>
                <InputContainer>
                    <span className='sub-label-share'>Output Link:</span>
                    <InputTextarea
                        id='shared-link-textarea'
                        initialValue={generatedLink}
                        // @ts-ignore
                        readonly
                        disabled={true}
                    />
                </InputContainer>
                <SecondaryButton
                    icon={<FileCopyRoundedIcon />}
                    title='Generate & Copy'
                    style={{
                        marginTop: '15px',
                        backgroundColor: 'white',
                    }}
                    onClick={() => generateNewLink()}
                />
            </div>
        </div>
    );
};

export default ShareRedirectLink;