import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  SelectWithTopLabel,
  CardRow,
  PrimaryButton,
  BorderlessButton,
  LargeDarkText,
  SplitPaginationTableGenerator,
} from '../../../../../components/Legwork';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import FormItem from 'components/FormBuilder/components/FormBuilder/forms/form-item';
import CloneFormModal from '../CloneFormModal';
import MoveFormModal from '../MoveFormModal';
import RenameFormModal from '../RenameFormModal';
import DeleteFormModal from '../DeleteFormModal';
import DeleteFolderModal from '../DeleteFolderModal';
import WarningDefaultForm from '../WarningDefaultForm';
import RenameFolderModal from '../RenameFolderModal';
import CreateFormModal from '../CreateFormModal/NewCreateFormModal';
import loading_spinner from 'media/images/loading-spinner.svg';
import styled from 'styled-components';
import HighlightText from 'components/Global/HighlightText/HighlightText';

const FormNameWrapped = styled.p`
  color: #4c7cfd;
  cursor: pointer;
  font-size: 13px;
`;

const SelectActions = styled.div`
  margin-right: 10px;

  .MuiSelect-select {
    &:focus {
      border-radius: 6px;
      background-color: inherit;
    }
  }
`;

const FormsItems = [
  { value: 'Actions', display: 'Actions', disabled: true },
  { value: 'Edit', display: 'Edit Form', disabled: false },
  { value: 'Preview', display: 'Preview Form', disabled: false },
  { value: 'Rename', display: 'Rename Form', disabled: false },
  { value: 'Move', display: 'Move Form', disabled: false },
  { value: 'Clone', display: 'Clone Form', disabled: false },
  { value: 'Submissions', display: 'View Submissions', disabled: false },
  { value: 'Share', display: 'Share Form', disabled: false },
  { value: 'Delete', display: 'Delete Form', disabled: false },
];

const OptionsForm = [
  { label: 'Actions', value: 'Actions' },
  { label: 'Edit Form', value: 'Edit' },
  { label: 'Preview Form', value: 'Preview' },
  { label: 'Rename Form', value: 'Rename' },
  { label: 'Move Form', value: 'Move' },
  { label: 'Clone Form', value: 'Clone' },
  { label: 'View Submissions', value: 'Submissions' },
  { label: 'Share Form', value: 'Share' },
  { label: 'Delete Form', value: 'Delete' },
];

const FolderItems = [
  { value: 'Actions', display: 'Actions', disabled: true },
  { value: 'RenameFolder', display: `Rename`, disabled: false },
  { value: 'DeleteFolder', display: `Delete`, disabled: false },
];

const SelectionsThatLoadViews = [
  'Settings',
  'Edit',
  'Preview',
  'Share',
  'Submissions',
];

const AllowedFields = ['Actions', 'Edit', 'Preview', 'Share', 'Submissions'];

const FormsPerPage = 10;

const PaginationForFormBuilder = ({
  forms = [],
  folder = {},
  toggleVerticalTabs,
  folders = [],
  isRetricted = false,
  updateForms,
  updateFolders,
  loading,
  filter,
}) => {
  const [r, setR] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentView, setCurrentView] = useState('Overview');
  const [currentId, setCurrentId] = useState(0);
  const [currentForm, setCurrentForm] = useState(null);
  const [action, setAction] = useState('');
  const [isShowWarning, setIsShowWarning] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);
  const [optionActionForm, setOptionActionForm] = useState({
    value: 'Actions',
    label: 'Actions',
  });

  const onToogleWarning = useCallback(() => {
    setIsShowWarning((isShowWarning) => !isShowWarning);
  }, [setIsShowWarning]);

  const openTab = (value, form) => {
    setCurrentView(value);
    setCurrentId(form.id);
    setCurrentForm(form.data);
  };
  useEffect(() => {
    if (forms.length >= 0) {
      setR(forms.slice(0, FormsPerPage).map((f) => ({ id: f.id, data: f })));
    } else {
      setR([]);
    }
  }, [forms]);
  const reporter = useCallback(
    (value, form) => {
      if (SelectionsThatLoadViews.includes(value)) {
        openTab(value, form);
        toggleVerticalTabs();
        return;
      }
      if (!form) {
        setAction(value);
        return;
      }
      setCurrentId(form.id);
      setCurrentForm(form.data);
      setAction(value);
    },
    [toggleVerticalTabs]
  );

  const onRedirectToForm = useCallback(() => {
    setIsShowWarning(false);
    reporter('Edit', { id: currentForm.id, data: { ...currentForm } });
  }, [reporter, currentForm, setIsShowWarning]);

  const getFormItems = (restricted) => {
    if (restricted) {
      return FormsItems.filter((item) => AllowedFields.includes(item.value));
    }
    return FormsItems;
  };

  const handleChangeActionForm = (newOption) => {
    setOptionActionForm(newOption);
  };

  const fetchRows = useCallback(
    (e, value) => {
      setPages(value);
      setR(
        forms
          .slice(value * FormsPerPage, (value + 1) * FormsPerPage)
          .map((f) => ({ id: f.id, data: f }))
      );
    },
    [forms]
  );

  const generateRow = useCallback(
    (row) => {
      const {
        form_name: formName,
        updated_at: lastEditted,
        created_at: createdAt,
        totalCount: totalSubmission,
        unReadCount: unReadSubmission,
      } = row.data;
      return [
        (hover) => (
          <div style={{ display: 'flex', width: '100%' }}>
            <FormNameWrapped
              style={{
                flexGrow: '1',
                maxWidth: '250px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                WebkitLineClamp: '2',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
              onClick={() => {
                if (row.data.is_restricted) {
                  setIsShowWarning(true);
                } else {
                  reporter('Edit', row);
                }
              }}
            >
              <HighlightText text={formName} searchValue={filter.trim()} />
            </FormNameWrapped>
            <div style={{ alignSelf: 'center', marginLeft: '5px' }}>
              {hover && (
                <SelectWithTopLabel
                  menuItems={getFormItems(row.data.is_restricted)}
                  reporter={(i) => reporter(i, row)}
                  Icon={() => (
                    <ExpandMore style={{ color: '#6E84A3', right: '7px' }} />
                  )}
                />
              )}
              {/* {hover && (
                <Select
                  options={OptionsForm}
                  onChange={handleChangeActionForm}
                  value={optionActionForm}
               />
              )} */}
            </div>
          </div>
        ),
        <span
          style={{
            color: '#12253e',
            fontSize: '13px',
          }}
        >
          {moment(lastEditted).format('MM/DD/YY')}
        </span>,
        <span
          style={{
            color: '#12253e',
            fontSize: '13px',
          }}
        >
          {moment(createdAt).format('MM/DD/YY')}
        </span>,
        <div style={{ display: 'flex' }}>
          <BorderlessButton
            title={`${unReadSubmission} Unread`}
            size='large'
            style={{
              backgroundColor: '#9be2ca',
              color: '#12253e',
              paddingTop: '4px',
              padding: '5px 15px',
              fontSize: '13px',
              fontWeight: '600',
              textTransform: 'capitalize',
              borderRadius: '3px',
            }}
            disabled
          />
          <BorderlessButton
            title={`${totalSubmission} Total`}
            size='large'
            style={{
              backgroundColor: '#e1f0ff',
              color: '#12253e',
              paddingTop: '4px',
              marginRight: '20px',
              marginLeft: '10px',
              padding: '5px 15px',
              fontSize: '13px',
              fontWeight: '600',
              textTransform: 'capitalize',
              borderRadius: '3px',
            }}
            disabled
          />
        </div>,
      ];
    },
    [folder, reporter]
  );

  const { Table, Pagination } = useMemo(() => {
    const { Table, Pagination } = SplitPaginationTableGenerator({
      headCells: ['Name', 'Last Edited', 'Date Created', 'Submissions'],
      rows: r,
      rowsPerPage: FormsPerPage,
      totalRows: forms.length,
      page: pages,
      fetchRows,
      generateRow,
      hoverable: true,
    });
    return { Table, Pagination };
  }, [fetchRows, forms.length, generateRow, r, pages]);

  return (
    <>
      {isShowWarning && (
        <WarningDefaultForm
          handleClose={onToogleWarning}
          open={isShowWarning}
          onRedirectToForm={onRedirectToForm}
        />
      )}
      {currentView === 'Overview' && (
        <>
          <CloneFormModal
            open={action && action.toLowerCase() === 'clone'}
            form={currentForm}
            handleClose={() => setAction('')}
            folders={folders}
            folder={folder}
            updateForms={updateForms}
          />

          <MoveFormModal
            open={action && action.toLowerCase() === 'move'}
            form={currentForm}
            handleClose={() => setAction('')}
            folders={folders}
            folder={folder}
            updateForms={updateForms}
          />

          <RenameFormModal
            open={action && action.toLowerCase() === 'rename'}
            form={currentForm}
            updateForms={updateForms}
            handleClose={() => setAction('')}
          />

          <DeleteFormModal
            open={action && action.toLowerCase() === 'delete'}
            form={currentForm}
            handleClose={() => setAction('')}
            updateForms={updateForms}
          />

          <DeleteFolderModal
            open={action && action.toLowerCase() === 'deletefolder'}
            folder={folder}
            handleClose={() => {
              setAction('');
              setResetSelect(true);
            }}
            updateForms={updateForms}
            updateFolders={updateFolders}
          />

          <RenameFolderModal
            open={action && action.toLowerCase() === 'renamefolder'}
            folder={folder}
            handleClose={() => {
              setAction('');
              setResetSelect(true);
            }}
            updateFolders={updateFolders}
          />

          <CreateFormModal
            isOpen={action && action.toLowerCase() === 'create'}
            handleCloseModal={() => setAction('')}
            folders={folders}
            folder={folder}
            updateForms={updateForms}
          />

          <CardRow
            left={
              <LargeDarkText style={{ fontWeight: '600', fontSize: '18px' }}>
                <FolderOpenIcon
                  style={{ verticalAlign: 'bottom', marginRight: '12px' }}
                />
                {folder.folder_name}
              </LargeDarkText>
            }
            right={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SelectActions>
                  <SelectWithTopLabel
                    className='dropdown-folder-items'
                    menuItems={FolderItems}
                    reporter={(i) => reporter(i)}
                    Icon={ExpandMore}
                    fullWidth
                    style={{ marginRight: '8px' }}
                    resetSelect={resetSelect}
                    setResetSelect={setResetSelect}
                  />
                </SelectActions>
                <PrimaryButton
                  title='New Form'
                  onClick={() => setAction('create')}
                />
              </div>
            }
          />
          <div className='custom-scroll'>
            <div
              style={{
                padding: '0 18px',
              }}
            >
              {loading ? (
                <div className='spinner-upload'>
                  <img src={loading_spinner} alt={'Loading'} />
                </div>
              ) : (
                <>
                  <Card
                    style={{
                      maxHeight: 'calc(100vh - 196px)',
                      overflowY: 'auto',
                    }}
                  >
                    <Table
                      headCells={[
                        'Name',
                        'Last Edited',
                        'Date Created',
                        'Submissions',
                      ]}
                      rows={r}
                      rowsPerPage={FormsPerPage}
                      totalRows={forms.length}
                      fetchRows={fetchRows}
                      generateRow={generateRow}
                      hoverable
                    />
                  </Card>
                  <Pagination />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {currentView !== 'Overview' && currentId && (
        <FormItem
          formId={currentId}
          folder={folder}
          folders={folders}
          // hideChrome
          onFormSave={() => {}}
          onVersionSave={() => {}}
          form={currentForm}
          activeTab={currentView.toLowerCase()}
          goToOverview={() => {
            toggleVerticalTabs();
            setCurrentView('Overview');
            updateFolders();
            updateForms();
          }}
        />
      )}
    </>
  );
};

export default PaginationForFormBuilder;
