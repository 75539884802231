import React from 'react';
import { Grid, GridSize } from '@material-ui/core';

import { StyledGridContainer, GridRightBorder } from './styled-components';

export interface VerticalSplitLayoutProps {
  leftComponent: React.ReactElement;
  rightComponent: React.ReactElement;
  leftGridSize: GridSize;
  rightGridSize: GridSize;
}

const VerticalSplitLayout = (props: VerticalSplitLayoutProps): React.ReactElement => {
  const { leftComponent, rightComponent, leftGridSize, rightGridSize } = props;
  return (
    <StyledGridContainer container>
      <GridRightBorder item xs={12} md={leftGridSize}>
        {leftComponent}
      </GridRightBorder>
      <div />
      <Grid item xs={12} md={rightGridSize}>
        {rightComponent}
      </Grid>
    </StyledGridContainer>
  );
};

VerticalSplitLayout.defaultProps = {
  leftGridSize: 6,
  rightGridSize: 6,
};

export default VerticalSplitLayout;
