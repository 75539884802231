import React, { useState, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDetailBooking } from '../BookingRequestVM';
import {
  faEllipsisV,
  faCheckCircle,
  faTimes,
  faArchive,
} from '@fortawesome/free-solid-svg-icons';
import { DetailAppointmentBookingWrapper as Wrapper } from './Wrapper';
import { convertPhoneNumber } from 'utils/convertData';
import useOnClickOutside from 'hooks/useClickOutSide';
import HighlightText from 'components/Global/HighlightText/HighlightText';
import { Button } from '@material-ui/core';

interface IDetailAppointmentBooking {
  detailBooking: IDetailBooking;
  nameAppointmentBooking: string;
  handleApproveAppointment: (selectedBooking: IDetailBooking) => void;
  showPopupArchive: (isOpen: boolean) => void;
  showPopupUnArchive: (isOpen: boolean) => void;
  setCurrentAppointmentSelected: React.Dispatch<
    React.SetStateAction<IDetailBooking | null>
  >;
  setTypeSelect: React.Dispatch<
    React.SetStateAction<'accept' | 'deny' | 'email' | 'view' | null>
  >;
}

const DetailAppointmentBooking: React.FC<IDetailAppointmentBooking> = ({
  detailBooking,
  nameAppointmentBooking,
  handleApproveAppointment,
  showPopupArchive,
  setTypeSelect,
  setCurrentAppointmentSelected,
  showPopupUnArchive,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setExpanded(false));

  const listStatus = useMemo(
    () => ({
      accepted: () => (
        <span className='appointment-icon accepted-icon'>
          <FontAwesomeIcon icon={faCheckCircle} />
          <span>Accepted</span>
        </span>
      ),
      denied: () => (
        <span className='appointment-icon denied-icon'>
          <FontAwesomeIcon icon={faTimes} />
          <span>Denied</span>
        </span>
      ),
      archived: () => (
        <div className='appointment-icon archived-icon'>
          <FontAwesomeIcon icon={faArchive} />
          <span>Archived</span>
          {/* <Button
          onClick={() => {
            showPopupUnArchive(true);
            setCurrentAppointmentSelected(detailBooking);
          }}
          variant='contained'
          color='primary'
          className='unarchive-button cancel-button'
        >
          Unarchive
        </Button> */}
        </div>
      ),
      pending: () => (
        <>
          <Button
            variant='contained'
            color='default'
            className='save-button'
            onClick={() => {
              handleApproveAppointment(detailBooking);
            }}
          >
            Accept
          </Button>
          <Button
            onClick={() => {
              setTypeSelect('deny');
              setCurrentAppointmentSelected(detailBooking);
            }}
            variant='contained'
            color='primary'
            className='cancel-button'
          >
            Deny
          </Button>
        </>
      ),
    }),
    [
      setTypeSelect,
      setCurrentAppointmentSelected,
      showPopupUnArchive,
      detailBooking,
    ]
  );

  return (
    <Wrapper>
      <div className='header-appointment'>
        <div className='info-appointment'>
          <p>
            {detailBooking.contactName ? (
              <HighlightText
                text={detailBooking.contactName}
                searchValue={nameAppointmentBooking.trim()}
              />
            ) : (
              'No Name'
            )}
          </p>
          <p>{detailBooking.petName || 'Missing pet name'}</p>
          <p>{detailBooking.emailAddress || 'Missing email address'}</p>
          <p>
            {detailBooking.phoneNumber
              ? detailBooking.phoneNumber
                  .split(',')
                  .map((phone) => convertPhoneNumber(phone))
                  .join(', ')
              : 'Missing phone number'}
          </p>
          <p>Submitted on: {new Date(detailBooking.submittedDate).toDateString()}</p>
        </div>
        {/* @ts-ignore */}
        <div className='expand-action-appointment' ref={ref}>
          {detailBooking.status !== 'archived' && (
            <FontAwesomeIcon
              icon={faEllipsisV}
              onClick={() => setExpanded((preState: boolean) => !preState)}
            />
          )}
          {expanded && (
            <div className='content-expanded-appointment'>
              <p
                onClick={() => {
                  setTypeSelect('email');
                  setCurrentAppointmentSelected(detailBooking);
                }}
              >
                Email Contact
              </p>
              {/* <p onClick={() => {
              showPopupArchive(true);
              setCurrentAppointmentSelected(detailBooking);
            }}>Archive</p> */}
            </div>
          )}
        </div>
      </div>
      <p className='exam-cleaning-appointment'>
        {detailBooking?.appointment ?? ''}
      </p>
      <div className='footer-detail-appointment'>
        <div className='group-button'>
          {listStatus[detailBooking.status] &&
            listStatus[detailBooking.status]()}
        </div>
        <p
          className='view-submission'
          onClick={() => {
            setTypeSelect('view');
            setCurrentAppointmentSelected(detailBooking);
          }}
        >
          View Submission
        </p>
      </div>
    </Wrapper>
  );
};

export default DetailAppointmentBooking;
