import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronRight from '@material-ui/icons/ChevronRight';
import 'components/FormBuilder/components/FormBuilder/form-builder.scss';

export default class FieldGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  handleClick = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { groupName, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div key={groupName} id="form-item-style">
        <div className='field-group-header' onClick={this.handleClick}>
          <div style={{ display:'flex', alignItems: 'center' }}>
            {isOpen ? <ChevronRight /> : <KeyboardArrowDownIcon />} {groupName}
          </div>
        </div>
        {isOpen && <div className='field-group-children'>{children}</div>}
      </div>
    );
  }
}

FieldGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  children: PropTypes.node,
};
