import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { StyledFormControlLabel } from './styled-components';

export interface InputCheckboxProps {
  value?: string | object;
  initiallyChecked?: boolean;
  disabled?: boolean;
  label?: string | React.ReactElement;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  controlled?: boolean;
  'data-testid'?: string;
}

const InputCheckbox = (props: InputCheckboxProps): React.ReactElement => {
  const {
    value = 'checked',
    disabled = false,
    initiallyChecked = false,
    label = '',
    onChange = (): void => {},
    controlled = false,
    'data-testid': dataTestId = 'checkbox',
  } = props;
  const [currentCheck, setCheck] = useState(initiallyChecked);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheck(event.target.checked);
    onChange(event.target.checked, event);
  };
  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          checked={controlled ? initiallyChecked : currentCheck}
          disabled={disabled}
          value={value}
          color='primary'
          data-testid={dataTestId}
        />
      }
      label={label}
    />
  );
};

export default InputCheckbox;
